import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth-guard.service';
import { UnsavedDataGuard } from './../guards/unsaved-data.guard';
import { CobbleComponent } from './cobble/cobble.component';
import { NewCobbleComponent } from './new-cobble/new-cobble.component';
import { WorkareaComponent } from './workarea.component';

const workareaRoutes: Routes = [
  {
    path: '',
    component: WorkareaComponent,
    canActivate: [AuthGuard, UnsavedDataGuard],
    children: [
      { path: '', component: NewCobbleComponent, data: { state: 'home' } },
      {
        path: ':id',
        component: CobbleComponent,
        canActivate: [UnsavedDataGuard],
        data: { state: 'workarea', animationState: 'app' },
      },
    ],
    data: {
      title: 'Leap',
      permissions: ['CobbleDevelopment'],
      state: 'Leap',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(workareaRoutes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class WorkareaRoutingModule {
}
