import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { RuntimeService } from '../../../running-area/services/runtime.service';
import { CobbleService } from '../../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../../shared/services/communication.service';
import { FactoryParticleService } from '../../../shared/services/factory-particle.service';
import { CacheService } from '../../services/cache.service';
import { TemplateService } from '../../services/template.service';
import { ToolsService } from '../../services/tools.service';
import { BusService } from './bus.service';
import { EventsService } from './events.service';
import { ProcessorService } from './processor.service';

@Injectable({ providedIn: 'root' })
export class MolecularEngineConnectorService {
  
  subscriptions = {};
  
  constructor(
    private cacheService: CacheService,
    private toolsService: ToolsService,
    private busService: BusService,
    private cobbleService: CobbleService,
    private eventsService: EventsService,
    private communicationService: CommunicationService,
    private templateService: TemplateService,
    private runtimeService: RuntimeService,
    private processorService: ProcessorService,
    private factoryParticleService: FactoryParticleService,
  ) {
  
  }
  
  RunMolecule(moleculeType: string, data: any, dataElements = [], repMoleculeId = null): Observable<any> {
    const subject = new Subject<any>();
    
    console.log('run');
    setTimeout(() => {
      this.templateService.GetActionMoleculeProperties([moleculeType]).subscribe(properties => {
        
        const processKey = this.toolsService.GenerateGuid();
        const moleculeProperties = properties[0];
        const actionMolecule = this.factoryParticleService.GenerateActionMolecule(moleculeProperties, repMoleculeId, dataElements);
        const repMolecule = repMoleculeId ? this.busService.Get(repMoleculeId) : null;
        
        this.processorService.Process(processKey, actionMolecule, repMolecule, data, '', true);
        this.subscriptions[processKey] = this.communicationService.Event.Runtime.MolecularEngine.$ConnectorParticleProcessCompleted.subscribe(process => {
          
          if (process.key && +process.elementId === repMoleculeId) {
            this.subscriptions[processKey].unsubscribe();
            delete this.subscriptions[processKey];
            console.log(' done processing molecule', process);
            
            subject.next({
              data: process.data,
              repMoleculeId: repMoleculeId,
            });
          }
        });
        
      });
    }, 100);
    
    return subject.asObservable();
  }
}
