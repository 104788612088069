export class HierarchyLevel {
  name: string;
  value: string;
  description: string;
  nodes: HierarchyLevel[];
  users: any[];
  id: number;
  
  constructor() {
    this.nodes = [];
    this.users = [];
  }
  
  get canBeAttached() {
    return this.nodes.length === 0;
  }
  
  get canBeSubLeveled() {
    return this.users.length === 0;
  }
}
