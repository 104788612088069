import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { map, startWith } from 'rxjs/operators';
import { ApiThematicService } from '../../../workarea/services/api-thematic.service';
import { Theme } from '../../interfaces/theme.interface';
import { CommunicationService } from '../../services/communication.service';

@Component({
  selector: 'theme-search',
  templateUrl: './theme-search.component.html',
  styleUrls: ['./theme-search.component.scss'],
})
export class ThemeSearchComponent implements OnInit, OnDestroy {
  @Input() label: string = 'Choose Theme';
  @Input() selectedThemeName: string = '';
  @Input() loadOnlyUsersTheme = true;
  @Input() disabled = false;
  @Output() themeSelected: EventEmitter<Theme | null> = new EventEmitter<Theme | null>();
  themeInput = new FormControl('');
  themes: Theme[] = [];
  themeOptions: Observable<Theme[]>;
  subscriptions = new Subscription();
  selectedTheme: Theme;
  
  constructor(
    private apiThematic: ApiThematicService,
    private communicationService: CommunicationService,
  ) {
  }
  
  ngOnInit() {
    this.subscriptions.add(this.communicationService.Event.Editor.$ReloadThemes.subscribe(() => {
      this.LoadThemes();
    }));
    
    this.LoadThemes();
    
    if (this.disabled) {
      this.themeInput.disable();
    }
    
    this.themeOptions = this.themeInput.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value || '')),
    );
  }
  
  LoadThemes() {
    this.themes = [];
    const themesSource = this.loadOnlyUsersTheme ? this.apiThematic.GetThemesByUser() : this.apiThematic.GetThemesByCompany();
    themesSource.subscribe(themes => {
      this.themes = themes;
      this.themeInput.setValue(this.selectedThemeName);
    });
  }
  
  filter(value: string): Theme[] {
    const filterValue = value.toLowerCase();
    return value && value !== '' ? this.themes.filter(theme => theme.name.toLowerCase().includes(filterValue)) : this.themes;
  }
  
  themeOptionSelected(name: string) {
    this.selectedTheme = this.themes.find(theme => theme.name === name);
    this.themeSelected.emit(this.selectedTheme);
  }
  
  clear() {
    this.themeInput.setValue('');
    this.themeSelected.emit(null);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
