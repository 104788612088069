export enum Browser {
  EdgeHtml = 'MS Edge Html',
  EdgeChromium = 'MS Edge Chromium',
  Opera = 'Opera',
  Chrome = 'Chrome',
  InternetExplorer = 'Internet Explorer',
  Firefox = 'Firefox',
  Safari = 'Safari',
  Unknown = 'Unknown',
}
