import { animate, AnimationBuilder, AnimationPlayer, keyframes, style } from '@angular/animations';
import { Injectable } from '@angular/core';
import { RepresentativeMoleculesType } from '../../shared/enums/representative-molecules-types.enum';
import { BusService } from '../molecular/services/bus.service';

@Injectable()
export class AnimationsService {
  
  constructor(
    private animationBuilder: AnimationBuilder,
    private busService: BusService,
  ) {
  }
  
  MoveElement(newLeft: number, newTop: number, _element: any, time = 500) {
    _element.doneMoving = false;
    
    const animator = this.initAnimator(_element.id, [
      animate(
        time + 'ms cubic-bezier(.35, 0, .25, 1)',
        keyframes([
          style({
            transform: 'translateX(0%)',
            left: newLeft,
            top: newTop,
            offset: 0.5,
          }),
          style({
            transform: 'translateX(0%)',
            left: newLeft - 10,
            top: newTop,
            offset: 0.6,
          }),
          style({
            transform: 'translateX(0%)',
            left: newLeft - 20,
            top: newTop,
            offset: 0.7,
          }),
          style({
            transform: 'translateX(0%)',
            left: newLeft + 10,
            top: newTop,
            offset: 0.8,
          }),
          style({
            transform: 'translateX(0%)',
            left: newLeft,
            top: newTop,
            offset: 0.9,
          }),
          style({
            transform: 'translateX(0%)',
            left: newLeft,
            top: newTop,
            offset: 1,
          }),
        ]),
      ),
    ]);
    
    animator.onDone(() => {
      _element.position.top = newTop;
      _element.position.left = newLeft;
      animator.destroy();
    });
    animator.play();
  }
  
  RemoveElement(_elementId: string, fireEvents = true, type = 'element') {
    const animator = this.initAnimator(_elementId, [
      style({ width: '*' }),
      animate(
        '300ms ease-in-out',
        style({ opacity: 0, transform: 'scale(0.0)' }),
      ),
    ]);
    
    animator.onDone(() => {
      const elementRef = <HTMLElement>document.getElementById(_elementId);
      
      if (elementRef) {
        elementRef.style.opacity = '0';
        
        animator.destroy();
        
        if (fireEvents) {
          if (type === RepresentativeMoleculesType.WorkGroup) {
          } else {
          }
        }
      }
    });
    animator.play();
  }
  
  PulseElement(_elementId: string, type = 'element') {
    if (document.getElementsByClassName('wrapper-element-' + _elementId)[0]) {
      const animator = this.initAnimator(_elementId, [
        style({ width: '*' }),
        animate(
          '200ms',
          keyframes([
            style({ transform: 'scale3d(1, 1, 1)', offset: 0 }),
            style({ transform: 'scale3d(1.05, 1.05, 1.05)', offset: 0.5 }),
            style({ transform: 'scale3d(1, 1, 1)', offset: 1.0 }),
          ]),
        ),
      ]);
      
      animator.onDone(() => {
        animator.destroy();
      });
      
      animator.play();
    }
  }
  
  JellyMoveElement(newLeft: number, newTop: number, _element: any) {
    const times = [
      { offset: 0.14, translate: 1, scale: 1 },
      { offset: 0.15, translate: 1, scale: 1 },
      { offset: 0.198, translate: 2.061, scale: 1 },
      { offset: 0.246, translate: 2.321, scale: 1 },
      { offset: 0.315, translate: 2.029, scale: 1 },
      { offset: 0.325, translate: 1.961, scale: 1 },
      { offset: 0.341, translate: 1.771, scale: 1.062 },
      { offset: 0.42, translate: 1.06, scale: 1.303 },
      { offset: 0.434, translate: 0.982, scale: 1.333 },
      { offset: 0.469, translate: 0.822, scale: 1.398 },
      { offset: 0.528, translate: 0.672, scale: 1.469 },
      { offset: 0.613, translate: 0.612, scale: 1.501 },
      { offset: 0.621, translate: 0.612, scale: 1.501 },
      { offset: 0.831, translate: 0.736, scale: 1.372 },
      { offset: 0.866, translate: 0.736, scale: 1.338 },
      { offset: 0.997, translate: 0.856, scale: 1.211 },
      { offset: 0.1114, translate: 0.923, scale: 1.114 },
      { offset: 0.1251, translate: 0.979, scale: 1.03 },
      { offset: 0.1366, translate: 1.009, scale: 0.987 },
      { offset: 0.1372, translate: 1.01, scale: 0.986 },
      { offset: 0.1614, translate: 1.029, scale: 0.958 },
      { offset: 0.165, translate: 1.029, scale: 0.958 },
      { offset: 0.1662, translate: 1.029, scale: 0.959 },
      { offset: 0.2115, translate: 1.007, scale: 0.99 },
      { offset: 0.2615, translate: 0.998, scale: 1.004 },
      { offset: 0.2773, translate: 0.998, scale: 1.003 },
      { offset: 0.3616, translate: 1, scale: 1 },
      { offset: 0.3884, translate: 1, scale: 1 },
      { offset: 0.4325, translate: 1, scale: 1 },
      { offset: 0.6106, translate: 1, scale: 1 },
      { offset: 0.8328, translate: 1, scale: 1 },
      { offset: 1, translate: 1, scale: 1 },
    ];
    
    const targetX = _element.originalPosition.left;
    const targetY = _element.originalPosition.top;
    const x = _element.position.left;
    const y = _element.position.top;
    
    const frames = [];
    
    frames.push(
      style({
        transform: `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, ${ x }, ${ y }, 0, 1)`,
        offset: 0,
      }),
    );
    
    times.forEach(time => {
      frames.push(
        style({
          transform: `matrix3d(${ time.translate }, 0, 0, 0, 0, ${
            time.scale
          }, 0, 0, 0, 0, 1, 0, ${ x }, ${ y }, 0, 1)`,
          offset: time.offset,
        }),
      );
    });
    
    // animate(
    //   '500ms cubic-bezier(.35, 0, .25, 1)',
    //   style({ transform: 'translateX(0%)', left: newLeft, top: newTop })
    // )
    
    const animator = this.initAnimator(_element.id, [
      style({ width: '*' }),
      animate(
        '500ms cubic-bezier(.35, 0, .25, 1)',
        keyframes([
          style({
            transform: 'translateX(0%)',
            left: newLeft,
            top: newTop,
            offset: 0,
          }),
        ]),
      ),
    ]);
    
    animator.onDone(() => {
      _element.context.position.top = newTop;
      _element.context.position.left = newLeft;
      animator.destroy();
    });
    
    animator.play();
  }
  
  initAnimator(_elementId: string, animation: any[]): AnimationPlayer {
    let animator: AnimationPlayer;
    
    const elementRef = <HTMLElement>document.getElementById(_elementId);
    
    const animationFactory = this.animationBuilder.build(animation);
    animator = animationFactory.create(elementRef, {});
    
    return animator;
  }
  
  PulseElementByRef(elementRef: HTMLElement, type = 'element') {
    const animator = this.initAnimatorByRef(elementRef, [
      style({ width: '*' }),
      animate(
        '200ms',
        keyframes([
          style({ transform: 'scale3d(1, 1, 1)', offset: 0 }),
          style({ transform: 'scale3d(1.05, 1.05, 1.05)', offset: 0.5 }),
          style({ transform: 'scale3d(1, 1, 1)', offset: 1.0 }),
        ]),
      ),
    ]);
    
    animator.onDone(() => {
      animator.destroy();
    });
    
    animator.play();
  }
  
  initAnimatorByRef(
    elementRef: HTMLElement,
    animation: any[],
  ): AnimationPlayer {
    let animator: AnimationPlayer;
    
    const animationFactory = this.animationBuilder.build(animation);
    animator = animationFactory.create(elementRef, {});
    
    return animator;
  }
  
  PulseHTMLElement(_elementId: string, time = 50, scale = 0.05) {
    setTimeout(() => {
      let animator: AnimationPlayer;
      const elementRef = <HTMLElement>document.getElementById(_elementId);
      
      const animation = [
        animate(
          time + 'ms',
          keyframes([
            style({
              transform: `${ elementRef.style.transform } scale3d(1, 1, 1)`,
              offset: 0,
            }),
            style({
              transform: `${ elementRef.style.transform } scale3d(${ 1 +
              scale }, ${ 1 + scale }, ${ 1 + scale })`,
              offset: 0.5,
            }),
            style({
              transform: `${ elementRef.style.transform } scale3d(1, 1, 1)`,
              offset: 1.0,
            }),
          ]),
        ),
      ];
      
      const animationFactory = this.animationBuilder.build(animation);
      animator = animationFactory.create(elementRef, {});
      
      animator.onDone(() => {
        animator.destroy();
      });
      
      animator.play();
    }, 10);
  }
  
  ExpandHTMLElement(_elementId: string, time = 1000) {
    setTimeout(() => {
      let animator: AnimationPlayer;
      const elementRef = <HTMLElement>document.getElementById(_elementId);
      
      const times = [
        { offset: 0.034, translate: 1.407, scale: 1 },
        { offset: 0.047, translate: 1.599, scale: 1 },
        { offset: 0.0681, translate: 1.893, scale: 1 },
        { offset: 0.0941, translate: 1.9, scale: 1 },
        { offset: 0.1021, translate: 1.92, scale: 1 },
        { offset: 0.1361, translate: 1.8, scale: 1 },
        { offset: 0.1411, translate: 1.7, scale: 1 },
        { offset: 0.1752, translate: 1.6, scale: 1 },
        { offset: 0.1872, translate: 1.62, scale: 1 },
        { offset: 0.2132, translate: 1.5, scale: 1 },
        { offset: 0.2434, translate: 1.4, scale: 1 },
        { offset: 0.2523, translate: 1.3, scale: 1 },
        { offset: 0.2903, translate: 1.2, scale: 1 },
        { offset: 0.2993, translate: 1.1, scale: 1 },
        { offset: 0.3554, translate: 1, scale: 1 },
        { offset: 0.3674, translate: 1, scale: 1 },
        { offset: 0.4104, translate: 1, scale: 1 },
        { offset: 0.4444, translate: 1, scale: 1 },
        { offset: 0.5215, translate: 1, scale: 1 },
        { offset: 0.5986, translate: 1, scale: 1 },
        { offset: 0.6326, translate: 1, scale: 1 },
        { offset: 0.7528, translate: 1, scale: 1 },
        { offset: 0.8549, translate: 1, scale: 1 },
        { offset: 0.9069, translate: 1, scale: 1 },
        { offset: 1, translate: 1, scale: 1 },
      ];
      
      const frames = [];
      
      frames.push(
        style({
          transform: `${
            elementRef.style.transform
          } matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)`,
          offset: 0,
        }),
      );
      
      times.forEach(_time => {
        frames.push(
          style({
            transform: `${ elementRef.style.transform } matrix3d(${
              _time.translate
            }, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)`,
            offset: _time.offset,
          }),
        );
      });
      
      const animation = [animate(time + 'ms', keyframes(frames))];
      
      const animationFactory = this.animationBuilder.build(animation);
      animator = animationFactory.create(elementRef, {});
      
      animator.onDone(() => {
        animator.destroy();
      });
      
      animator.play();
    }, 10);
  }
  
  FadeHTMLElement(_elementId: string, callback?: any) {
    setTimeout(() => {
      let animator: AnimationPlayer;
      const elementRef = <HTMLElement>document.getElementById(_elementId);
      
      const animation = [
        animate(
          '200ms ease-in-out',
          keyframes([
            style({
              opacity: 1,
              transform: `${ elementRef.style.transform } scale(1)`,
              offset: 0,
            }),
            style({
              opacity: 0.5,
              transform: `${ elementRef.style.transform } scale(0.5)`,
              offset: 0.5,
            }),
            style({
              opacity: 0,
              transform: `${ elementRef.style.transform } scale(0.0)`,
              offset: 1.0,
            }),
          ]),
        ),
      ];
      
      const animationFactory = this.animationBuilder.build(animation);
      animator = animationFactory.create(elementRef, {});
      
      animator.onDone(() => {
        if (callback) {
          callback();
        }
        animator.destroy();
      });
      
      animator.play();
    }, 10);
  }
  
  ResizeHTMLElement(_elementId: string, callback?: any) {
    setTimeout(() => {
      let animator: AnimationPlayer;
      const elementRef = <HTMLElement>document.getElementById(_elementId);
      
      const animation = [
        animate(
          '150ms ease-in',
          keyframes([
            style({
              opacity: 1,
              height: '70px',
              // width: '80%',
              offset: 0,
            }),
            style({
              opacity: 0.75,
              height: '50px',
              // width: '65%',
              offset: 0.5,
            }),
            style({
              opacity: 0.5,
              height: '40px',
              // width: '50%',
              offset: 1.0,
            }),
          ]),
        ),
      ];
      
      const animationFactory = this.animationBuilder.build(animation);
      animator = animationFactory.create(elementRef, {});
      
      animator.onDone(() => {
        if (callback) {
          callback();
        }
        animator.destroy();
      });
      
      animator.play();
    }, 10);
  }
}
