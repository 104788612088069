import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApplicationSettings } from '../../shared/interfaces/application-settings.interface';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';
import { ApiDataService } from './api-data.service';
import { ClientStorageService } from './client-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ApiApplicationSettingsService extends ApiDataService {
  
  constructor(http: HttpClient, errorMessengerService: ErrorMessengerService, private clientStorageService: ClientStorageService, private router: Router) {
    super('ApplicationSettings', http, errorMessengerService);
  }
  
  ApplicationSettings(applicationId: number): Observable<ApplicationSettings> {
    return this.http.get(this.apiEndpointUrl + `/${ applicationId }`).pipe(
      map(response => <any>response),
      catchError(error =>
        this.errorMessengerService.HandleError(error, 'Error getting settings for app'),
      ),
    );
  }
  
  UpdateApplicationSettings(applicationId: number, settings: ApplicationSettings): Observable<any> {
    return this.http.put(this.apiEndpointUrl + `/${ applicationId }`, settings).pipe(
      map(response => <any>response),
      catchError(error =>
        this.errorMessengerService.HandleError(error, 'Error updating settings for app'),
      ),
    );
  }
}
