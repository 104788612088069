import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { BusService } from '../../core/molecular/services/bus.service';
import { TemplateService } from '../../core/services/template.service';
import { ToolsService } from '../../core/services/tools.service';
import { ActionMolecule } from '../../shared/representative-molecule/interfaces/action-molecules';
import { Particle } from '../../shared/representative-molecule/interfaces/particle';
import { RepresentativeMolecule } from '../../shared/representative-molecule/interfaces/representative-molecule';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { SpreadsheetService } from '../../spreadsheet/spreadsheet.service';
import { WorkAreaService } from '../../workarea/workarea.service';
import { DynamicFormComponent } from '../components/dynamic-form.component';
import { FieldConfig } from '../models/field-config.model';

@Component({
  selector: 'molecule-settings',
  templateUrl: './molecule-settings.component.html',
  styleUrls: ['./molecule-settings.component.scss'],
})
export class MoleculeSettingsComponent implements OnInit, AfterViewInit {
  @ViewChild(DynamicFormComponent, { static: true })
  form: DynamicFormComponent;
  
  @Input()
  public particle: ActionMolecule;
  @Input()
  public busId: string;
  @Input()
  public eventMolecule: string;
  @Input()
  public parameter: any;
  @Input()
  public repMolecule: RepresentativeMolecule;
  @Input()
  public moleculeSettingIndex: number;
  @Input()
  public eventListener = false;
  @Input() dragHover = false;
  config: FieldConfig[];
  private previousValues: any;
  
  constructor(
    private fb: FormBuilder,
    private templateService: TemplateService,
    private spreadsheetService: SpreadsheetService,
    private cobbleService: CobbleService,
    private workAreaService: WorkAreaService,
    private busService: BusService,
    private toolsService: ToolsService,
  ) {
    this.config = [];
  }
  
  ngAfterViewInit() {
    this.previousValues = Object.assign({}, this.form.value);
    
    const form = this.form.form;
    Object.keys(form.controls).forEach((key) => {
      form
      .get(key)
      .valueChanges.pipe(debounceTime(1000))
      .subscribe((value) => {
        // console.log('value changes');
        if (form.dirty) {
          form.markAsPristine();
          
          if (this.workAreaService.elementsSelected.length > 1) {
            const shadowElements = this.workAreaService.elementsSelectedShadowParticleAssociation[this.particle.ParticleId] as {
              particleId: string;
              busId: string;
              repMolecule: RepresentativeMolecule;
              particles: Particle[];
            }[];
            
            shadowElements.forEach((se) => {
              const originalRepMolecule = this.busService.Get(se.repMolecule.Id.toString());
              
              se.particles.forEach((p) => {
                const actionMolecule = originalRepMolecule.GetActionMoleculeParticleByParticleId(p.ParticleId) as ActionMolecule;
                actionMolecule.UpdateParameter(se.repMolecule, se.busId, key, value, this.previousValues[key]).subscribe((result) => {
                  this.previousValues = Object.assign({}, this.form.value);
                });
              });
            });
          } else {
            this.particle.UpdateParameter(this.repMolecule, this.busId, key, value, this.previousValues[key]).subscribe((result) => {
              this.previousValues = Object.assign({}, this.form.value);
            });
          }
          
          this.repMolecule.MarkAsTouched();
        }
      });
    });
  }
  
  getViews() {
    const options = [];
    this.cobbleService.Cobble.properties.views.forEach((view) => {
      options.push({
        name: view.name,
        id: view.id,
      });
    });
    return options;
  }
  
  getWorkgroups() {
    const options = [];
    this.busService.DirectChildrenElements(this.cobbleService.Cobble.id).forEach((wg: RepresentativeMolecule) => {
      options.push({
        name: wg.Properties.name,
        id: wg.Id,
      });
    });
    return options;
  }
  
  submit(value: { [name: string]: any }) {
  }
  
  ngOnInit() {
    if (typeof this.parameter === 'undefined') {
      this.templateService.GetActionMoleculeProperties([this.particle.InternalMoleculeName]).subscribe((moleculeProperties) => {
        this.config = [
          {
            type: 'label',
            busId: this.busId,
            particleId: this.particle.ParticleId,
            label: this.particle.DisplayName,
            name: 'molecule',
            icon: this.particle.Icon || 'calendar_view_day',
            placeholder: this.particle.InternalMoleculeName,
            value: {
              type: 'parameter',
              event: this.eventMolecule,
              molecule: this.particle.InternalMoleculeName,
              element: this.repMolecule,
              name: this.particle.DisplayName,
              properties: moleculeProperties[0],
            },
            validation: [],
            isMoleculeSetting: true,
            isEventListener: this.eventListener,
          },
        ];
        
        if (moleculeProperties[0].parameters.length > 0) {
          for (const moleculeParam of moleculeProperties[0].parameters) {
            let options = [];
            
            if (moleculeParam.type === 'select') {
              if (
                moleculeParam.name === 'workgroup' ||
                moleculeParam.name === 'workgroupToReplaceId' ||
                moleculeParam.name === 'replaceWithId' ||
                moleculeParam.name === 'workgroupIds'
              ) {
                options = this.getWorkgroups();
              } else if (moleculeParam.name === 'view') {
                options = this.getViews();
              }
            } else if (moleculeParam.type === 'dropdown') {
              options = moleculeParam.options;
            }
            
            this.config.push({
              type: moleculeParam.type,
              particleId: this.particle.ParticleId,
              busId: this.busId,
              label: moleculeParam.label,
              name: moleculeParam.name,
              placeholder: moleculeParam.placeholder,
              validation: [],
              disabled: this.toolsService.RunningMode,
              options: options,
              isMoleculeSetting: true,
              isMultiple: moleculeParam.isMultiple,
              tooltip: moleculeParam.tooltip,
              tooltipPosition: moleculeParam.tooltipPosition,
              value: Array.isArray(this.particle.Rule[moleculeParam.name])
                ? this.particle.Rule[moleculeParam.name]
                : isNaN(Number(this.particle.Rule[moleculeParam.name]))
                  ? this.particle.Rule[moleculeParam.name]
                  : Number(this.particle.Rule[moleculeParam.name]),
            });
          }
        }
        
        this.form.done.subscribe((done) => {
          if (this.particle.Rule && Object.keys(this.particle.Rule).length !== 0 && this.particle.Rule.constructor === Object) {
            Object.keys(this.particle.Rule).forEach((key) => {
              this.form.setValue(key, this.particle.Rule[key]);
            });
          }
        });
      });
    } else {
      this.config = [
        {
          type: 'label',
          particleId: this.particle.ParticleId,
          busId: this.busId,
          label: this.parameter.name,
          name: this.parameter.name,
          icon: this.particle.Icon || 'calendar_view_day',
          placeholder: this.parameter.placeholder,
          value: {
            event: this.eventMolecule,
            molecule: {},
            element: this.repMolecule,
          },
          validation: [],
          isMoleculeSetting: false,
        },
        {
          type: this.parameter.type,
          particleId: this.particle.ParticleId,
          busId: this.busId,
          label: this.repMolecule[this.parameter.name],
          name: this.parameter.name,
          placeholder: this.parameter.placeholder,
          value: this.repMolecule[this.parameter.name],
          validation: [],
          isMoleculeSetting: false,
          isMultiple: this.parameter.isMultiple,
        },
      ];
      
      this.form.done.subscribe((done) => {
        this.form.setValue(this.parameter.name, this.repMolecule[this.parameter.name]);
      });
    }
  }
}
