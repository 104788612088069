import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';
import { ApiDataService } from './api-data.service';

@Injectable({
  providedIn: 'root',
})
export class ApiLeapxlExportService extends ApiDataService {
  
  constructor(
    http: HttpClient,
    errorMessengerService: ErrorMessengerService,
  ) {
    super('export', http, errorMessengerService);
  }
  
  ExportApplication(readOnly: boolean, encrypt: boolean, encryptPassword: string, importPassword: string, applicationId: number) {
    const options = this.AddBackgroundTask('Exporting Application', 'get_app', {
      reportProgress: true,
      responseType: 'blob',
      observe: 'events',
    });
    
    return this.http.post(
      this.apiEndpointUrl + '/Application/' + applicationId,
      {
        readOnly, encrypt, encryptPassword, importPassword,
      }, options
      ,
    ).pipe(
      map((events) => {
        return events;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error exporting app ${ applicationId }`,
        );
        console.error('Error exporting app', err);
        return of(err);
      }),
    );
  }
}
