import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeService } from '../../running-area/services/runtime.service';
import { ToolsService } from '../services/tools.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class LeapxlMetadataInterceptor implements HttpInterceptor {
  constructor(
    private runtimeService: RuntimeService,
    private toolsService: ToolsService,
  ) {
  }
  
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    req.headers.append('LeapXL-AppRuntimeId', this.runtimeService.AppRuntimeId());
    // req.headers.append('LeapXL-AppId', this.runtimeService.AppId());
    // console.log(req.headers);
    
    const clonedReq = req.clone({
      headers: req.headers
      .append('LeapXL-AppRuntimeId', this.runtimeService.AppRuntimeId())
      .append('LeapXL-AppId', this.runtimeService.AppId())
      .append('LeapXL-ProductionApp', this.runtimeService.ProductionApp.toString())
      .append('LeapXL-SessionId', this.runtimeService.GetSessionId())
      .append('LeapXL-RequestId', this.toolsService.GenerateGuid()),
    });
    return next.handle(clonedReq);
  }
}
