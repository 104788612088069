import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class DatasourcePaginateDataMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    data?: any,
    dataElementsData?: any,
  ) {
    console.log('paginate moelcule');
    try {
      const pagination =
        +(rule.paginate ||
          this.toolsService.ExtractValuesByType(data).number ||
          50);
      
      const repMolecule = this.busService.Get(repMoleculeId);
      const busProcessor = this.busService.GetBusToRunFromProcessorChannel(busProcessorKey);
      this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id] = this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id] || {};
      this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id].pageSize = pagination;
      
      console.log('pagination');
      if (repMolecule.ValueMetaData && repMolecule.ValueMetaData.PageSize) {
        repMolecule.ValueMetaData.PageSize = pagination;
        repMolecule.ValueMetaData.PageCount = Math.ceil(repMolecule.ValueMetaData.TotalItemCount / repMolecule.ValueMetaData.PageSize);
      } else {
        const dataValue = this.toolsService.ExtractValuesByType(data).object;
        
        if (dataValue && dataValue.dataKey) {
          repMolecule.SetValueMetaData(this.dataSourcesService.dataSourceValueMetadataKey[dataValue.dataKey]);
          repMolecule.ValueMetaData.PageSize = pagination;
          repMolecule.ValueMetaData.PageCount = Math.ceil(repMolecule.ValueMetaData.TotalItemCount / repMolecule.ValueMetaData.PageSize);
        }
      }
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    } catch (error) {
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
