import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Receptor } from '../../core/molecular/receptors.enum';
import { BusService } from '../../core/molecular/services/bus.service';
import { ApiDataSourcesService } from '../../core/services/api-data-sources.service';
import { DevToolsService } from '../../core/services/dev-tools.service';
import { ToolsService } from '../../core/services/tools.service';
import { Constants } from '../../shared/constants';
import { DatasourceType } from '../../shared/enums/datasource-type.enum';
import { DragType } from '../../shared/enums/drag-type.enum';
import { MoleculesType } from '../../shared/enums/molecules-type.enum';
import { RepresentativeMoleculesType } from '../../shared/enums/representative-molecules-types.enum';
import { ActionMolecule } from '../../shared/representative-molecule/interfaces/action-molecules';
import { Bus } from '../../shared/representative-molecule/interfaces/bus';
import { DataElement } from '../../shared/representative-molecule/interfaces/data-element';
import {
  IRepresentativeMolecule,
} from '../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { DragService } from '../../shared/representative-molecule/services/drag.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { FactoryParticleService } from '../../shared/services/factory-particle.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { SpreadsheetService } from '../../spreadsheet/spreadsheet.service';
import { WorkAreaService } from '../../workarea/workarea.service';
import { FieldConfig } from '../models/field-config.model';

@Component({
  exportAs: 'dynamicForm',
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['dynamic-form.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ transform: 'scale(0)', opacity: 0 }),
        animate('150ms', style({ transform: 'scale(1)', opacity: 1 }))]),
    ]),
  ],
})
export class DynamicFormComponent implements OnChanges, OnInit, OnDestroy {
  @Input() config: FieldConfig[] = [];
  
  @Input() particle: ActionMolecule;
  @Input() dragHover = false;
  @Input() repMolecule: IRepresentativeMolecule;
  
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  
  @Output() done: EventEmitter<any> = new EventEmitter<any>();
  
  form: FormGroup;
  hovering = false;
  inDebounce = null;
  subscriptions: Subscription;
  bus: Bus;
  activeParent = false;
  groupedDataElements: DataElement[][] = [];
  rangedParticles = [];
  dataElementBeingDragged: DataElement = null;
  dataDestructive = false;
  repMolecules = [];
  destroy$ = new Subject();
  definitions = [];
  definitionsToDisplay = [];
  isMoleculeClosed = false;
  
  constructor(
    private fb: FormBuilder,
    public dragService: DragService,
    private spreadsheetService: SpreadsheetService,
    private busService: BusService,
    private snackerService: SnackerService,
    public workareaService: WorkAreaService,
    private communicationService: CommunicationService,
    public toolsService: ToolsService,
    private particleFactoryService: FactoryParticleService,
    private dataSourceService: ApiDataSourcesService,
    private cobbleService: CobbleService,
    public devToolsService: DevToolsService,
  ) {
  }
  
  get controls() {
    return this.config.filter(({ type }) => type !== 'button');
  }
  
  get changes() {
    return this.form.valueChanges;
  }
  
  get valid() {
    return this.form.valid;
  }
  
  get value() {
    return this.form.value;
  }
  
  ViewName(viewId: number) {
    return this.cobbleService.Cobble.properties.views.find(v => v.id === viewId).name;
  }
  
  EvaluateDataAssociationDefinitions() {
    const moleculeTypes = this.cobbleService.Cobble.moleculeDataAssociationDefinitions.map(d => d.molecule);
    
    console.log('evaluation');
    // exist definitions for molecule
    if (moleculeTypes.includes(this.repMolecule.Type)) {
      // is value input bus
      if (this.bus.Receptor === Receptor.ValueInput) {
        // is get data source or take data element
        // allowed molecules order for data
        const orderMolecules = ['GetElementsDatasourceDataMolecule', 'FilterByDataElementReferenceMolecule'];
        if (orderMolecules.includes(this.particle.InternalMoleculeName)) {
          // is last molecule on bus
          if (this.bus.Particles.findIndex(
            p => p.id === this.particle.id) === this.bus.Particles.length - 1) {
            // if data elements is first position
            this.definitions = this.cobbleService.Cobble.moleculeDataAssociationDefinitions
            .filter(d => d.molecule === this.repMolecule.Type)
            .sort((a, b) => {
              return a.dataIndex - b.dataIndex;
            });
            
            this.definitionsToDisplay = this.definitions.slice(this.groupedDataElements.length);
          }
        }
      }
    }
  }
  
  ngOnInit() {
    this.isMoleculeClosed = this.workareaService.moleculeState?.[this.particle.ParticleId] ?? false;
    this.dataDestructive = false;
    this.form = this.createGroup();
    this.repMolecule = this.repMolecule || this.busService.Get(this.particle.MoleculeId.toString());
    if (this.repMolecule) {
      this.bus = this.repMolecule.GetBus(this.config[0].busId);
    }
    this.done.emit();
    this.LoadDataElementsParticles();
    
    this.subscriptions = this.communicationService.Event.Editor.$DataElementsChange.pipe(
      takeUntil(this.destroy$))
    .subscribe(refreshDSTree => {
      console.log('$DataElementsChange');
      this.LoadDataElementsParticles();
      if (refreshDSTree) {
        this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanel.emit();
      }
      this.communicationService.Event.Editor.EventsTree.$RefreshEventsTree.emit();
      this.EvaluateDataDestructiveMolecule();
    });
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.$ParticlesOrderParentEnabled.pipe(takeUntil(this.destroy$))
      .subscribe(enabled => {
        this.activeParent = enabled;
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.$ParticleRemoved.pipe(takeUntil(this.destroy$))
      .subscribe(enabled => {
        this.EvaluateDataDestructiveMolecule();
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.$ToggleBusMolecule.subscribe(
        ({ isMoleculeClosed, particleId }) => {
          if (this.particle.ParticleId === particleId) {
            this.isMoleculeClosed = isMoleculeClosed;
          }
        }),
    );
    
    this.EvaluateDataDestructiveMolecule();
    this.RefreshRepMoleculesAttached();
  }
  
  EvaluateDataDestructiveMolecule() {
    if (this.particle.InternalMoleculeName === 'UpdateDatasourceDataMolecule') {
      let takeDataElementExists = false;
      let takeDataElementWarning = true;
      let updatedeleteDataElementWarning = false;
      
      // only evaluate for spreadsheets
      if (this.particle.DataElements.length === 0 || this.particle.DataElements[0].DataSourceType !== DatasourceType.Spreadsheet) {
        return;
      }
      
      if (this.repMolecule) {
        const valueInputBus = this.repMolecule.GetBusByReceptorAndMoleculeNameType([Receptor.ValueInput],
          'FilterByDataElementReferenceMolecule');
        const sameBusTakeDataElements = this.bus.GetActionMoleculeParticlesByInternalName(
          'FilterByDataElementReferenceMolecule');
        
        if (valueInputBus.length > 0 || sameBusTakeDataElements.length > 0) {
          let takeDataElementMolecule = null;
          
          if (valueInputBus.length > 0) {
            takeDataElementMolecule = valueInputBus[0].GetActionMoleculeParticle(
              'FilterByDataElementReferenceMolecule');
          } else {
            takeDataElementMolecule = sameBusTakeDataElements[0];
          }
          
          if (takeDataElementMolecule && takeDataElementMolecule.DataElements.length > 0) {
            takeDataElementExists = true;
            const dataElementsEvaluated = [];
            const problemDataElements = [];
            
            takeDataElementMolecule.DataElements.forEach(de => {
              const brokenContext = this.toolsService.BreakContext(de.Context);
              if (brokenContext.dataSourceType !== DatasourceType.Spreadsheet) {
                dataElementsEvaluated.push(de);
              } else {
                // problemDataElements.push(de);
              }
            });
            
            takeDataElementWarning = problemDataElements.length > 0;
          }
        }
      }
      
      this.particle.GetDataElementsContext()
      .forEach(c => {
        if (c.split(Constants.ContextSeparator).length === 2) {
          // spreadsheet, do not warn
        } else {
          if (this.toolsService.ExtractRowFromContext(c)) {
          } else {
            updatedeleteDataElementWarning = true;
          }
        }
      });
      
      if (takeDataElementExists) {
        this.dataDestructive = takeDataElementWarning;
      } else {
        this.dataDestructive = updatedeleteDataElementWarning;
      }
    }
  }
  
  LoadDataElementsParticles() {
    this.groupedDataElements = [];
    this.rangedParticles = [];
    this.particle.DataElements.forEach(de => {
      if (de.RangeParticleId === '' || de.RangeParticleId === null || de.RangeParticleId === undefined) {
        this.groupedDataElements.push([de]);
        this.rangedParticles.push(de.ParticleId);
      } else if (!this.rangedParticles.includes(de.RangeParticleId)) {
        this.rangedParticles.push(de.ParticleId);
        const rangeParticle = [];
        rangeParticle.push(de);
        const secondParticle = this.particle.DataElements.find(sde => sde.ParticleId === de.RangeParticleId);
        
        if (secondParticle) {
          rangeParticle.push(secondParticle);
          this.rangedParticles.push(secondParticle.ParticleId);
        }
        this.groupedDataElements.push(rangeParticle);
      }
    });
    
    this.EvaluateDataAssociationDefinitions();
  }
  
  ngOnChanges() {
    if (this.form) {
      const controls = Object.keys(this.form.controls);
      const configControls = this.controls.map(item => item.name);
      
      controls.filter(control => !configControls.includes(control))
      .forEach(control => this.form.removeControl(control));
      
      configControls
      .filter(control => !controls.includes(control))
      .forEach(name => {
        const config = this.config.find(control => control.name === name);
        this.form.addControl(name, this.createControl(config));
      });
    }
  }
  
  createGroup() {
    const group = this.fb.group({});
    this.controls.forEach(control => group.addControl(control.name, this.createControl(control)));
    return group;
  }
  
  createControl(config: FieldConfig) {
    const { disabled, validation, value } = config;
    return this.fb.control({ disabled, value }, validation);
  }
  
  handleSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.submit.emit(this.value);
  }
  
  setDisabled(name: string, disable: boolean) {
    if (this.form.controls[name]) {
      const method = disable ? 'disable' : 'enable';
      this.form.controls[name][method]();
      return;
    }
    
    this.config = this.config.map(item => {
      if (item.name === name) {
        item.disabled = disable;
      }
      return item;
    });
  }
  
  async DropData(positionIndex = 0) {
    // console.log('config', this.config);
    // console.log('molecule', this.molecule);
    
    console.log('drag data', this.dragService.dragData, this.spreadsheetService.SpreadSheetRangeSelected);
    this.hovering = false;
    let compatibilityResult = null;
    if (this.dragService.dragData) {
      if (this.dragService.dragData.dragType !== DragType.Spreadsheet &&
        this.dragService.dragData.dragType !== DragType.RepresentativeMolecule &&
        this.dragService.dragData.dragType !== DragType.Molecule) {
        this.workareaService.SaveLastUsedElement(
          this.dragService.dragData.dragType,
          this.dragService.dragData.data[0].DataSourceType,
          this.dragService.dragData.data[0].InternalName,
        );
      }
      
      switch (this.dragService.dragData.dragType) {
        case DragType.DataElement:
          compatibilityResult = await this.EvaluateDataSourceCompatibility(this.dragService.dragData.data[0]);
          
          if (!compatibilityResult.compatible) {
            this.snackerService.ShowMessageOnBottom(compatibilityResult.message, 'front_hand', 7000, false, {
              text: 'Add Anyways',
              icon: 'add_circle',
              callback: () => {
                this.dragService.AssignDataElementToMolecule(
                  this.config[0].busId,
                  this.particle.MoleculeId,
                  this.particle.ParticleId,
                  this.dragService.dragData.data,
                  positionIndex,
                  false,
                  false,
                );
              },
            });
            return;
          }
          
          this.dragService.AssignDataElementToMolecule(
            this.config[0].busId,
            this.particle.MoleculeId,
            this.particle.ParticleId,
            this.dragService.dragData.data,
            positionIndex,
            false,
            false,
          );
          break;
        
        case DragType.Spreadsheet:
          // console.log(this.spreadsheetService.dataSourceIdOpened);
          
          // compatibilityResult = await this.EvaluateDataSourceCompatibility(new DataElement(this.spreadsheetService.SpreadSheetRangeSelected));
          //
          // if (!compatibilityResult.compatible) {
          //   this.snackerService.ShowMessageOnBottom(compatibilityResult.message, 'front_hand', 7000, false, {
          //     text: 'Add Anyways',
          //     icon: 'add_circle',
          //     callback: () => {
          //       this.dragService.ConvertAndAssignDataSourceToMolecule(
          //         this.config[0].busId,
          //         this.particle.MoleculeId,
          //         this.particle.ParticleId,
          //         this.spreadsheetService.SpreadSheetRangeSelected,
          //         positionIndex,
          //         false,
          //         this.dragService.dragData.fromTree,
          //       );
          //     },
          //   });
          //   return;
          // }
          
          this.dragService.ConvertAndAssignDataSourceToMolecule(
            this.config[0].busId,
            this.particle.MoleculeId,
            this.particle.ParticleId,
            this.spreadsheetService.SpreadSheetRangeSelected,
            positionIndex,
            false,
            this.dragService.dragData.fromTree,
          );
          break;
        
        case DragType.RepresentativeMolecule:
          this.dragService.AssignRepMoleculeToActionMolecule(
            this.config[0].busId,
            this.particle.MoleculeId,
            this.particle.ParticleId,
            this.dragService.dragData.data,
          );
          this.RefreshRepMoleculesAttached();
          this.repMolecule.SaveProperty('buses', 'Rep molecule added')
          .subscribe();
          break;
      }
      this.dragService.dragData.dragType = '';
    }
  }
  
  RefreshRepMoleculesAttached() {
    this.repMolecules = [];
    
    if (this.particle) {
      this.particle.RepMoleculeIds.forEach(repMoleculeId => {
        const repMolecule = this.busService.Get(repMoleculeId.toString());
        if (repMolecule) {
          this.repMolecules.push(repMolecule);
        }
      });
    }
  }
  
  Throttle(func, delay, context, args) {
    clearTimeout(this.inDebounce);
    this.inDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }
  
  setValue(name: string, value: any) {
    const control = this.form.controls[name];
    if (control) {
      control.setValue(value, { emitEvent: true });
    }
  }
  
  onSorting(isParent: boolean) {
    this.communicationService.Event.Editor.$ParticlesOrderParentEnabled.emit(isParent);
  }
  
  ReorderDataElements(event: MouseEvent) {
    console.log('reorder', event);
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    
    this.dragService.StopDragging();
    this.dragService.dragData = null;
    this.particle.DataElements = [];
    this.groupedDataElements.forEach(groupedDataElement => {
      groupedDataElement.forEach(dataElement => {
        this.particle.DataElements.push(dataElement);
      });
    });
    
    this.repMolecule.FireDataSourceBus();
    
    // updating data for tables in case it has button on headers
    if (this.repMolecule.Type === RepresentativeMoleculesType.Table) {
      this.repMolecule.$UpdateValue.emit(true);
    }
    this.communicationService.Event.Editor.$DataElementsChange.emit(true);
    this.repMolecule.SaveProperty('buses', 'Data elements Reordered')
    .subscribe();
    this.repMolecule.RefreshUI();
    if (this.repMolecule.Type === RepresentativeMoleculesType.Table) {
      this.repMolecule.$UpdateValue.emit();
    }
  }
  
  ToggleDropPlaceholderState(show: boolean, particleIdElement: string, elementRef: HTMLElement) {
    if (show) {
      if (elementRef) {
        elementRef.classList.add('show-particle-drop-placeholder');
      }
    } else {
      const elements = document.querySelectorAll('#drop-placeholder-particle-' + particleIdElement);
      
      setTimeout(() => {
        if (elements) {
          elements.forEach(element => element.classList.remove('show-particle-drop-placeholder'));
        }
      }, 50);
    }
  }
  
  DragDataElementStart(dataElement: DataElement) {
    this.dataElementBeingDragged = dataElement;
    this.particle.DataElements = this.particle.DataElements.filter(
      de => de.ParticleId !== this.dataElementBeingDragged.ParticleId);
    
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
    // console.log('DragDataElementStart');
    this.dragService.StopDragging();
    this.dragService.dragData = null;
    this.dragService.dragginDataElementParticle = true;
  }
  
  IdentifyElement(repMolecule: IRepresentativeMolecule) {
    this.HighlightRepMolecule(repMolecule);
  }
  
  HighlightRepMolecule(repMolecule: IRepresentativeMolecule) {
    if (repMolecule.Type === RepresentativeMoleculesType.WorkGroup) {
      this.communicationService.Event.Editor.Views.$SwitchToRepresentativeMoleculeView.emit(repMolecule);
    } else {
      const parentRepMolecule = this.busService.Get(repMolecule.ParentId.toString());
      this.communicationService.Event.Editor.Views.$SwitchToRepresentativeMoleculeView.emit(
        parentRepMolecule);
    }
    
    setTimeout(() => {
      this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(null);
      this.workareaService.ShowElementFocusedMenu(repMolecule);
    }, 200);
  }
  
  RemoveRepMolecule(repMoleculeId: number, event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.particle.RemoveRepMolecules();
    this.repMolecule.SaveProperty('buses', 'Rep molecules removed')
    .subscribe();
    this.RefreshRepMoleculesAttached();
  }
  
  async AddParticleFromSuggestion(value: { type: DragType; data: IRepresentativeMolecule | DataElement }) {
    switch (value.type) {
      case DragType.DataElement:
        const compatibilityResult = (await this.EvaluateDataSourceCompatibility(
          value.data as DataElement)) as any;
        
        if (!compatibilityResult.compatible) {
          this.snackerService.ShowMessageOnBottom(compatibilityResult.message, 'front_hand', 7000, false, {
            text: 'Add Anyways',
            icon: 'add_circle',
            callback: () => {
              // this.dragService.AssignDataElementToMolecule(
              //   this.config[0].busId,
              //   this.particle.MoleculeId,
              //   this.particle.ParticleId,
              //   this.dragService.dragData.data,
              //   positionIndex,
              // );
            },
          });
          return;
        } else {
          const dataElement = value.data as DataElement;
          this.dragService.AssignDataElementToMolecule(
            this.config[0].busId,
            this.particle.MoleculeId,
            this.particle.ParticleId,
            [dataElement],
            (this.particle as ActionMolecule).DataElements.length,
          );
          this.snackerService.ShowMessageOnBottom(`Data Source added`, 'add_circle', null, true);
          this.workareaService.SaveLastUsedElement(dataElement.ParticleType, dataElement.DataSourceType,
            dataElement.InternalName);
        }
        break;
      case DragType.RepresentativeMolecule:
        this.dragService.AssignRepMoleculeToActionMolecule(this.config[0].busId, this.particle.MoleculeId,
          this.particle.ParticleId, {
            id: (value.data as IRepresentativeMolecule).Id,
          });
        this.snackerService.ShowMessageOnBottom(`Element added`, 'add_circle', null, true);
        this.repMolecule.SaveProperty('buses', 'Representative Molecule added to Molecule')
        .subscribe();
        this.RefreshRepMoleculesAttached();
        break;
    }
  }
  
  OnDrop() {
    if (this.dragService.dragData && this.dragService.dragData.dragType === DragType.Molecule) {
      if (this.dragService.dragData.moleculeType === MoleculesType.Representative) {
        this.snackerService.ShowMessageOnBottom(
          `Behavior molecules can't be replaced with Representative molecule`, 'do_not_disturb_on');
        return;
      }
      this.repMolecule.ReplaceActionMolecules(this.particle.ParticleId, this.dragService.dragData.type);
      this.snackerService.ShowMessageOnBottom(`Molecule Replaced`, 'swap_horizontal_circle', null, true);
      
      this.dragService.dragData = null;
    }
  }
  
  EvaluateDataSourceCompatibility(dataElement: DataElement) {
    return new Promise((resolve, reject) => {
      const result = {
        message: '',
        compatible: true,
      };
      
      this.dataSourceService.ObtainAcceptedDataSourceForParticle(this.particle)
      .subscribe(response => {
        result.compatible = !!response.dataElements.find(de => de.Context === dataElement.Context);
        result.message = response.message.ALTERNATE;
        return resolve(result);
      });
    });
    
    // region OLD_METHOD
    // const track = new ParticleTrackFlow(this.particle);
    // track.TrackUp();
    //
    // switch (this.particle.InternalMoleculeName) {
    //   case 'FilterByDataElementReferenceMolecule':
    //
    //     console.log(track.TrackUpResult);
    //     if (dataElement.DataSourceType === DatasourceType.Api &&
    //       dataElement.SubDomain.split(Constants.ContextSeparator)[2].toLowerCase() === 'data') {
    //       result.message = 'DataSource reserved for data submission';
    //       result.compatible = false;
    //     } else {
    //       if (track.TrackUpResult.DataSources.find(de => de.Domain === dataElement.Domain)) {
    //
    //       } else {
    //         result.message = 'There is no previous DataSource related to this one';
    //         result.compatible = false;
    //       }
    //     }
    //
    //     break;
    //
    //   case 'AddToDatasourceMolecule':
    //   case 'UpdateToDatasourceMolecule':
    //   case 'DeleteToDatasourceMolecule':
    //     if ((dataElement.DataSourceType === DatasourceType.Api && dataElement.SubDomain.split(Constants.ContextSeparator)[2].toLowerCase() !== 'data') ||
    //       dataElement.DataSourceType === DatasourceType.LeapXL ||
    //       dataElement.DataSourceType === DatasourceType.Custom ||
    //       dataElement.DataSourceType === DatasourceType.System
    //     ) {
    //       result.message = 'DataSource reserved for data response';
    //       result.compatible = false;
    //     }
    //
    //     break;
    //
    //   default:
    //
    //     if ((dataElement.DataSourceType === DatasourceType.Api && dataElement.SubDomain.split(Constants.ContextSeparator)[2].toLowerCase() === 'data')) {
    //       result.message = 'DataSource reserved for data submission';
    //       result.compatible = false;
    //     } else if (dataElement.DataSourceType === DatasourceType.Api ||
    //       dataElement.DataSourceType === DatasourceType.InternetMessaging ||
    //       dataElement.DataSourceType === DatasourceType.Database ||
    //       dataElement.DataSourceType === DatasourceType.UnifiedDatabase) {
    //       result.message = `This DataSource requires a data process molecule`;
    //       result.compatible = false;
    //     }
    //
    //     break;
    // }
    // endregion
  }
  
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
