import { Component, OnDestroy, OnInit } from '@angular/core';
import { CompactType, DisplayGrid, GridType } from '@leapxl/gridster';
import { BadgeMoleculeComponent } from '../../shared/representative-molecule/badge-molecule/badge-molecule.component';
import { IRepresentativeMolecule } from '../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { BreadcrumbMoleculeComponent } from '../../shared/representative-molecule/molecules/breadcrumb-molecule/breadcrumb-molecule.component';
import { ButtonMoleculeComponent } from '../../shared/representative-molecule/molecules/button-molecule/button-molecule.component';
import { ChartMoleculeComponent } from '../../shared/representative-molecule/molecules/chart-molecule/chart-molecule.component';
import { CheckboxMoleculeComponent } from '../../shared/representative-molecule/molecules/checkbox-molecule/checkbox-molecule.component';
import { DatepickerMoleculeComponent } from '../../shared/representative-molecule/molecules/datepicker-molecule/datepicker-molecule.component';
import { DropdownMoleculeComponent } from '../../shared/representative-molecule/molecules/dropdown-molecule/dropdown-molecule.component';
import { H1MoleculeComponent } from '../../shared/representative-molecule/molecules/h1-molecule/h1-molecule.component';
import { H2MoleculeComponent } from '../../shared/representative-molecule/molecules/h2-molecule/h2-molecule.component';
import { H3MoleculeComponent } from '../../shared/representative-molecule/molecules/h3-molecule/h3-molecule.component';
import { H4MoleculeComponent } from '../../shared/representative-molecule/molecules/h4-molecule/h4-molecule.component';
import { H5MoleculeComponent } from '../../shared/representative-molecule/molecules/h5-molecule/h5-molecule.component';
import { IconMoleculeComponent } from '../../shared/representative-molecule/molecules/icon-molecule/icon-molecule.component';
import { IframeMoleculeComponent } from '../../shared/representative-molecule/molecules/iframe-molecule/iframe-molecule.component';
import { ImageMoleculeComponent } from '../../shared/representative-molecule/molecules/image-molecule/image-molecule.component';
import { LabelMoleculeComponent } from '../../shared/representative-molecule/molecules/label-molecule/label-molecule.component';
import { ProgressMoleculeComponent } from '../../shared/representative-molecule/molecules/progress-molecule/progress-molecule.component';
import { QrCodeMoleculeComponent } from '../../shared/representative-molecule/molecules/qrcode-molecule/qrcode-molecule.component';
import { RadioMoleculeComponent } from '../../shared/representative-molecule/molecules/radio-molecule/radio-molecule.component';
import { SliderMoleculeComponent } from '../../shared/representative-molecule/molecules/slider-molecule/slider-molecule.component';
import { StepperMoleculeComponent } from '../../shared/representative-molecule/molecules/stepper-molecule/stepper-molecule.component';
import { TableMoleculeComponent } from '../../shared/representative-molecule/molecules/table-molecule/table-molecule.component';
import { TextareaMoleculeComponent } from '../../shared/representative-molecule/molecules/textarea-molecule/textarea-molecule.component';
import { TextboxMoleculeComponent } from '../../shared/representative-molecule/molecules/textbox-molecule/textbox-molecule.component';
import { WorkgroupMoleculeComponent } from '../../shared/representative-molecule/molecules/workgroup-molecule/workgroup-molecule.component';
import { DraggableWindowService } from '../../shared/services/draggable-window.service';

@Component({
  selector: 'app-preview-representative-molecule',
  templateUrl: './preview-representative-molecule.component.html',
  styleUrls: ['./preview-representative-molecule.component.scss'],
})
export class PreviewRepresentativeMoleculeComponent implements OnInit, OnDestroy {
  repMolecule: IRepresentativeMolecule = null;
  public moleculeComponents = {
    Button: ButtonMoleculeComponent,
    Badge: BadgeMoleculeComponent,
    Breadcrumb: BreadcrumbMoleculeComponent,
    WorkGroup: WorkgroupMoleculeComponent,
    Textbox: TextboxMoleculeComponent,
    Textarea: TextareaMoleculeComponent,
    Label: LabelMoleculeComponent,
    Datepicker: DatepickerMoleculeComponent,
    Dropdown: DropdownMoleculeComponent,
    H1: H1MoleculeComponent,
    H2: H2MoleculeComponent,
    H3: H3MoleculeComponent,
    H4: H4MoleculeComponent,
    H5: H5MoleculeComponent,
    Chart: ChartMoleculeComponent,
    Image: ImageMoleculeComponent,
    Iframe: IframeMoleculeComponent,
    Checkbox: CheckboxMoleculeComponent,
    Table: TableMoleculeComponent,
    Icon: IconMoleculeComponent,
    Radio: RadioMoleculeComponent,
    QrCode: QrCodeMoleculeComponent,
    Stepper: StepperMoleculeComponent,
    Slider: SliderMoleculeComponent,
    Progress: ProgressMoleculeComponent,
    Custom: ProgressMoleculeComponent,
  };
  
  gridsterConfig = {
    collision: false,
    gridType: GridType.Fit,
    compactType: CompactType.None,
    margin: 0,
    outerMargin: true,
    outerMarginTop: null,
    outerMarginRight: null,
    outerMarginBottom: null,
    outerMarginLeft: null,
    useTransformPositioning: true,
    mobileBreakpoint: 0,
    minCols: 130,
    maxCols: 130,
    minRows: 80,
    maxRows: 80,
    maxItemCols: 130,
    maxItemRows: 80,
    minItemRows: 1,
    maxItemArea: 200000,
    minItemArea: 1,
    defaultItemCols: 3,
    defaultItemRows: 3,
    fixedColWidth: 20,
    fixedRowHeight: 20,
    keepFixedHeightInMobile: false,
    keepFixedWidthInMobile: false,
    scrollSensitivity: 0,
    scrollSpeed: 0,
    enableEmptyCellClick: false,
    enableEmptyCellContextMenu: false,
    enableEmptyCellDrop: false,
    enableEmptyCellDrag: false,
    emptyCellDragMaxCols: 50,
    emptyCellDragMaxRows: 50,
    ignoreMarginInRow: false,
    draggable: {
      enabled: false,
    },
    resizable: {
      enabled: false,
    },
    swap: false,
    pushItems: false,
    disablePushOnDrag: false,
    disablePushOnResize: false,
    pushDirections: {
      north: false,
      east: false,
      south: false,
      west: false,
    },
    pushResizeItems: false,
    displayGrid: DisplayGrid.Always,
    disableWindowResize: false,
    disableWarnings: false,
    scrollToNewItems: false,
  };
  
  constructor(private draggableWindowService: DraggableWindowService) {
    const data = this.draggableWindowService.GetData();
    
    if (data && data.repMolecule) {
      // extend rep molecule size
      // data.repMolecule.Properties.responsive.desktop.cols = data.repMolecule.Properties.responsive.desktop.cols * 2;
      // data.repMolecule.Properties.responsive.desktop.rows = data.repMolecule.Properties.responsive.desktop.rows * 2;
      //
      
      // limit rep molecule size
      data.repMolecule.Properties.responsive.desktop.cols =
        data.repMolecule.Properties.responsive.desktop.cols >= 130 ? 120 : data.repMolecule.Properties.responsive.desktop.cols;
      data.repMolecule.Properties.responsive.desktop.rows =
        data.repMolecule.Properties.responsive.desktop.rows >= 80 ? 70 : data.repMolecule.Properties.responsive.desktop.rows;
      //
      
      // center rep molecule
      data.repMolecule.Properties.responsive.desktop.x = 65 - data.repMolecule.Properties.responsive.desktop.cols / 2;
      data.repMolecule.Properties.responsive.desktop.y = 35 - data.repMolecule.Properties.responsive.desktop.rows / 2;
      //
      
      this.repMolecule = data.repMolecule;
    }
  }
  
  ngOnDestroy(): void {
    this.repMolecule = null;
  }
  
  ngOnInit(): void {
  }
}
