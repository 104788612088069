import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class SaveDatasourceDataMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(particleId: string, repMoleculeId: string, busProcessorKey: string, rule: any, logic: any, data: any) {
    try {
      data = data === undefined || data === null || data === {} ? '' : data;
      
      // console.log(`molecule working, adding data to datasources`);
      
      if (data && data.datasourcesData) {
        const dataSourceData = [];
        
        data.datasourcesData.forEach((dd) => {
          const ds = dataSourceData.find((d) => d.dataSourceId === dd.dataSourceId && d.collection === dd.collection);
          
          if (ds) {
            ds.dataItems = ds.dataItems.concat(dd.dataItems);
            ds.populatedDataItems = ds.populatedDataItems.concat(dd.populatedDataItems);
          } else {
            dataSourceData.push(dd);
          }
        });
        
        this.Done(particleId, busProcessorKey, repMoleculeId, data);
      }
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
