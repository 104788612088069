import { Component, OnInit } from '@angular/core';
import { ApiMoleculesService } from '../../core/services/api-molecules.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { DraggableWindowService } from '../../shared/services/draggable-window.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-clone-application',
  templateUrl: './clone-application.component.html',
  styleUrls: ['./clone-application.component.scss'],
})
export class CloneApplicationComponent implements OnInit {
  
  appId = 0;
  appName = '';
  cloning = false;
  dsData = [
    {
      id: 234,
      name: 'spreadsheet 1',
      exist: true,
      newName: '',
      replaceData: false,
      useNewInstead: false,
    },
    {
      id: null,
      name: 'spreadsheet 2',
      exist: false,
      newName: '',
      replaceData: false,
      useNewInstead: true,
    },
    {
      id: 234234,
      name: 'spreadsheet 3',
      exist: true,
      newName: '',
      replaceData: false,
      useNewInstead: false,
    },
    {
      id: null,
      name: 'spreadsheet 4',
      exist: false,
      newName: '',
      replaceData: false,
      useNewInstead: true,
    },
    {
      id: 345,
      name: 'spreadsheet 5',
      exist: true,
      newName: '',
      replaceData: false,
      useNewInstead: false,
    },
  ];
  
  constructor(private draggableWindowService: DraggableWindowService,
              private moleculesService: ApiMoleculesService,
              private communicationService: CommunicationService,
              private workAreaService: WorkAreaService,
              private snackerService: SnackerService) {
    const cloneData = this.draggableWindowService.GetData() || {};
    this.appId = cloneData.appId;
    this.appName = cloneData.appName;
  }
  
  ngOnInit() {
    
    this.moleculesService.GetAppSpreadsheetDataInfo(this.appId).subscribe(spreadsheetData => {
      
      spreadsheetData.forEach(sd => {
        sd.useNewInstead = false;
        sd.newName = sd.name;
      });
      
      this.dsData = spreadsheetData;
    });
    
  }
  
  ContinueClone() {
    this.cloning = true;
    
    this.moleculesService.CloneCobble(this.appId, this.appName, this.dsData).subscribe(result => {
      this.cloning = false;
      this.workAreaService.$cobbleCreated.next(null);
      this.communicationService.Event.System.Update.$UpdateDashboardApps.emit(true);
      this.communicationService.Event.Editor.WorkArea.$CloseAllDraggableWindows.emit();
      this.snackerService.ShowMessageOnBottom('App cloned sucessfully!', 'file_copy', null, true);
    });
  }
}
