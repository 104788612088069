import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TemplateService } from '../core/services/template.service';
import { ToolsService } from '../core/services/tools.service';

@Component({
  selector: 'init-db',
  templateUrl: './init-db.component.html',
  styleUrls: ['./init-db.component.css'],
})
export class InitDbComponent implements OnInit {
  constructor(
    private router: Router,
    private templateService: TemplateService,
    private toolsService: ToolsService,
  ) {
  }
  
  ngOnInit() {
    // console.log('--[initializing database]--');
    const downloadParam = this.toolsService.GetQueryParams('download');
    const downloadTemplates = downloadParam === null ? true : downloadParam === 'true';
    this.templateService.UpdateTemplates(downloadTemplates).subscribe((result) => {
      (window.location as any) = `${ this.toolsService.GetDomain() }/workarea`;
    });
  }
}
