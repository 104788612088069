import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Permissions } from '../../../admin/models/permissions.enum';
import { ApiMoleculesService } from '../../../core/services/api-molecules.service';
import { ApiPropertiesService } from '../../../core/services/api-properties.service';
import { ClientStorageService } from '../../../core/services/client-storage.service';
import { GenericDialogService } from '../../../core/services/generic-dialog.service';
import { ToolsService } from '../../../core/services/tools.service';
import { UserMenuService } from '../../../core/services/user-menu.service';
import { PropertyVersioningDto } from '../../../shared/dtos/versioning-dto';
import { CobbleService } from '../../../shared/representative-molecule/services/cobble.service';
import { ApiPublishedCobblesService } from '../../../shared/services/api-published-cobbles.service';
import { CommunicationService } from '../../../shared/services/communication.service';
import { ConnectionStateService } from '../../../shared/services/connection-state.service';
import { DraggableWindowManagerService } from '../../../shared/services/draggable-window-manager.service';
import { DraggableWindowService, DraggableWindowType } from '../../../shared/services/draggable-window.service';
import { SnackerService } from '../../../shared/services/snacker.service';
import { RemoveCobbleDialogComponent } from '../../remove-cobble-dialog/remove-cobble-dialog.component';
import { WorkAreaService } from '../../workarea.service';

@Component({
  selector: 'app-cobble-tree-node-menu',
  templateUrl: './cobble-tree-node-menu.component.html',
  styleUrls: ['./cobble-tree-node-menu.component.scss'],
})
export class CobbleTreeNodeMenuComponent implements OnInit {
  @ViewChild(MatMenuTrigger, { static: true })
  trigger: MatMenuTrigger;
  
  @Input('cobbleNode')
  node: any;
  
  @Output() nodeRemoved: EventEmitter<any> = new EventEmitter<any>();
  
  
  userPermissions = [];
  adminApplications = false;
  deletingApp = false;
  
  constructor(
    private moleculesService: ApiMoleculesService,
    private workAreaService: WorkAreaService,
    private dialogService: GenericDialogService,
    private publishedCobbleService: ApiPublishedCobblesService,
    private snackerService: SnackerService,
    private cobbleService: CobbleService,
    private connectionStateService: ConnectionStateService,
    private toolsService: ToolsService,
    private communicationService: CommunicationService,
    private draggableWindowManagerService: DraggableWindowManagerService,
    private draggableWindowService: DraggableWindowService,
    private userMenuService: UserMenuService,
    private clientStorageService: ClientStorageService,
    private propertiesService: ApiPropertiesService,
  ) {
    this.userPermissions = clientStorageService.getPermissions();
    this.adminApplications = userMenuService.checkPermission(Permissions.AdminApplications);
  }
  
  ngOnInit() {
    // console.log(this.node);
  }
  
  public openContextMols($event: MouseEvent): void {
    this.trigger.openMenu();
    this.communicationService.Event.Editor.SidePanels.$KeepOpen.emit(1);
    $event.preventDefault();
    $event.stopPropagation();
  }
  
  runCobble() {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    const path = `/run/${ this.node.cobble.slug }`;
    window.open(path, '_blank');
  }
  
  DeleteCobble(): void {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    if (this.cobbleService.Cobble.id === this.node.cobble.id) {
      this.snackerService.ShowMessageOnBottom('Close the App before deleting', 'disabled_by_default');
      return;
    }
    
    this.dialogService.openDialog({ cobbleName: this.node.name }, RemoveCobbleDialogComponent).then(result => {
      this.deletingApp = true;
      
      if (result) {
        if (this.node.cobble.isRunnable) {
          this.publishedCobbleService.UnpublishCobble(this.node.cobble.id).subscribe(r => {
            this.moleculesService.DeleteRepresentativeMolecule([this.node.cobble.id], true).subscribe(_result => {
              this.workAreaService.$cobbleCreated.next(null);
              this.snackerService.ShowMessageOnBottom('App deleted sucessfully!', 'do_not_disturb_on', null, true);
              this.deletingApp = false;
            });
          });
        } else {
          this.moleculesService.DeleteRepresentativeMolecule([this.node.cobble.id], true).subscribe(_result => {
            this.workAreaService.$cobbleCreated.next(null);
            this.snackerService.ShowMessageOnBottom('App deleted sucessfully!', 'do_not_disturb_on', null, true);
            this.deletingApp = false;
          });
        }
      } else {
        this.deletingApp = false;
      }
    });
  }
  
  CloneCobble(event: MouseEvent) {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    this.draggableWindowService.OpenDraggableWindow(
      'Clone Application',
      DraggableWindowType.CloneApplicationComponent,
      event,
      {
        appId: this.node.id,
        appName: this.node.name,
      },
    );
  }
  
  ExportApp(event: MouseEvent) {
    this.draggableWindowService.OpenDraggableWindow(
      'Export LeapXL Application (Beta Test)',
      DraggableWindowType.LeapxlExport,
      event,
      {
        export: {
          type: 'app',
          name: this.node.cobble.name,
          id: this.node.cobble.id,
          isApplication: this.node.cobble.isApplication,
        },
      },
    );
  }
  
  RemoveStandardExtension() {
    console.log(this.node);
    
    this.nodeRemoved.emit(this.node);
    
    this.propertiesService.SaveProperty(
      new PropertyVersioningDto({
        elementId: this.node.id.toString(),
        property: this.node.isApplication ? 'isStandardApplication' : 'isStandardComponent',
        value: false,
        path: '',
        change: ``,
        name: this.node.name,
      }),
    ).subscribe();
    
    this.snackerService.ShowMessageOnBottom(this.node.isApplication ? 'App removed from library' : 'Component removed from library', 'do_not_disturb_on');
  }
}
