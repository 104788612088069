import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BuilderService } from '../../../core/builder/builder.service';
import { ProcessorService } from '../../../core/molecular/services/processor.service';
import { WorkAreaService } from '../../../workarea/workarea.service';
import { RepresentativeMoleculesType } from '../../enums/representative-molecules-types.enum';
import { BaseMoleculeComponent } from '../molecules/base-molecule/base-molecule.component';
import { DragService } from '../services/drag.service';

@Component({
  selector: 'app-badge-molecule',
  templateUrl: './badge-molecule.component.html',
  styleUrls: ['./badge-molecule.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BadgeMoleculeComponent extends BaseMoleculeComponent
  implements OnInit {
  MoleculeType = 'Representative';
  Type = RepresentativeMoleculesType.Badge;
  
  @ViewChild('BadgeWrapper', { static: true })
  BadgeWrapper: ElementRef;
  
  constructor(
    public builderService: BuilderService,
    public dragService: DragService,
    public workAreaService: WorkAreaService,
    public processorService: ProcessorService,
    bottomSheet: MatBottomSheet,
    public el: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
    super(bottomSheet, el, changeDetectorRef);
    
  }
  
  ngOnInit() {
    super.ngOnInit();
    if (!this.context.RunningMode) {
      this.DetachChangeDetection();
    }
    this.context.Type = RepresentativeMoleculesType.Badge;
    this.RefreshGridsterConfiguration();
    this.Subscriptions = this.context.$UpdateTextValue.subscribe((update) => {
      // this.RefreshText();
    });
  }
  
  AttachEditorEventListeners() {
    const dragoverEventListener = this.renderer.listen(this.BadgeWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, true);
    });
    const dragleaveEventListener = this.renderer.listen(this.BadgeWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, false);
    });
    const dropEventListener = this.renderer.listen(this.BadgeWrapper.nativeElement, 'drop', (evt) => {
      this.DataDropped(evt);
    });
  }
  
  UpdateData(): void {
    this.Animate();
    console.log('update');
    
  }
  
  Animate() {
    const element = document.querySelector(`#gridsterItem-${ this.context.Id } label`);
    
    if (element) {
      element.classList.add(this.context.Properties.badge.badgeAnimate);
      
      setTimeout(() => {
        element.classList.remove(this.context.Properties.badge.badgeAnimate);
      }, 2000);
    }
  }
  
  FireClickEvent(e: any) {
    this.FireRepresentativeMoleculeEvent('click', null, true);
  }
}
