import { Component, OnInit, ViewChild } from '@angular/core';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { cloneDeep } from 'lodash-es';
import { ToolsService } from '../../core/services/tools.service';
import { BusService } from './../../core/molecular/services/bus.service';
import { WorkAreaService } from './../workarea.service';

@Component({
  selector: 'app-json-tree',
  templateUrl: './json-tree.component.html',
  styleUrls: ['./json-tree.component.scss'],
})
export class JsonTreeComponent implements OnInit {
  public editorOptions: JsonEditorOptions;
  public data: any;
  @ViewChild(JsonEditorComponent, { static: true }) editor: JsonEditorComponent;
  
  constructor(
    private busService: BusService,
    private toolsService: ToolsService,
    private workAreaService: WorkAreaService,
  ) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.sortObjectKeys = false;
    this.editorOptions.history = false;
    this.editorOptions.mode = 'view';
    
    if (this.workAreaService.jsonTreeData) {
      this.data = this.workAreaService.jsonTreeData;
    } else if (this.workAreaService.primaryElementsSelected.length > 0) {
      const molecule = this.workAreaService.primaryElementsSelected[0];
      const propertiesToShow = {
        Children: molecule.Children,
        DragOver: molecule.DragOver,
        Id: molecule.Id,
        ParentId: molecule.ParentId,
        SubParentId: molecule.SubParentId,
        Versioning: molecule.Versioning,
        Buses: molecule.Buses,
        EditableProperties: molecule.EditableProperties,
        RunningMode: molecule.RunningMode,
        PopulatedData: molecule.PopulatedData,
        MoleculeType: molecule.MoleculeType,
        MoleculeSubType: molecule.MoleculeSubType,
        Type: molecule.Type,
        Required: molecule.Required || false,
        Value: molecule.Value,
        StartValue: molecule.StartValue,
        EndValue: molecule.EndValue,
        InitValue: molecule.InitValue,
        OldValue: molecule.OldValue,
        SearchFilter: molecule.SearchFilter,
        ValueOptions: molecule.ValueOptions,
        HeaderValue: molecule.HeaderValue,
        CellDataElement: molecule.CellDataElement,
        ValueMetaData: molecule.ValueMetaData,
        Icon: molecule.Icon,
        ReplaceableByRepresentative: molecule.ReplaceableByRepresentative,
        MoleculesAllow: molecule.MoleculesAllow,
        Loading: molecule.Loading,
        OptionsMenu: molecule.OptionsMenu,
        IsSelected: molecule.IsSelected,
        Properties: molecule.Properties,
      };
      this.data = this.toolsService.ClearLeapXLObjectFromCircularDependencies(cloneDeep(propertiesToShow));
    }
  }
  
  ngOnInit() {
  }
}
