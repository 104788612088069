import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { ToolsService } from '../core/services/tools.service';
import { RuntimeService } from '../running-area/services/runtime.service';

@Component({
  selector: 'app-requests-log',
  templateUrl: './requests-log.component.html',
  styleUrls: ['./requests-log.component.scss'],
  animations: [trigger('enterRight', [
    transition(':enter', [
      style({ transform: 'translateX(15%)', opacity: 0 }),
      animate('400ms', style({ transform: 'translateX(0)', opacity: 1 })),
    ]),
    transition(':leave', [
      style({ transform: 'translateX(0)', opacity: 1 }),
      animate('200ms', style({ transform: 'translateX(-5%)', opacity: 0 })),
    ]),
  ])],
})
export class RequestsLogComponent implements OnInit {
  public editorOptions: JsonEditorOptions;
  
  getDataElementsEnabled = true;
  processDataItemsEnabled = true;
  
  constructor(public runtimeService: RuntimeService, private toolsService: ToolsService) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.sortObjectKeys = false;
    this.editorOptions.history = false;
    this.editorOptions.mode = 'view';
    this.editorOptions.search = false;
    this.editorOptions.mainMenuBar = false;
    this.editorOptions.navigationBar = false;
    this.editorOptions.modes = ['view', 'text'];
    
  }
  
  ngOnInit() {
  
  }
  
  CopyData(data: any) {
    this.toolsService.CopyToClipboard(JSON.stringify(data));
  }
  
  Clear() {
    this.runtimeService.ClearRequestLogs();
  }
}
