import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiVersioningService } from '../../../core/services/api-versioning.service';
import { PropertyVersioningDto } from '../../../shared/dtos/versioning-dto';
import { CobbleService } from '../../../shared/representative-molecule/services/cobble.service';

@Component({
  selector: 'history-list',
  templateUrl: 'history-list.component.html',
  styles: [
    `
          .version-history-table-container {
              min-width: 950px;
          }

          .no-history-container {
              text-align: center;
              margin: 40px;
          }
      `,
  ],
})
export class HistoryListComponent implements AfterViewInit {
  displayedColumns = [
    'version',
    'name',
    'change',
    'property',
    'username',
    'date',
    'enabled',
  ];
  dataSource: MatTableDataSource<PropertyVersioningDto>;
  title: string;
  
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private versioningService: ApiVersioningService,
    private cobbleService: CobbleService,
  ) {
    this.dataSource = new MatTableDataSource(data.dataSource);
    this.title = data.title;
  }
  
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
  ToggleVersionChange(version: PropertyVersioningDto, activate: boolean) {
    if (activate) {
      this.versioningService.ActivateChange({
        versionId: version.versionId,
        cobbleId: this.cobbleService.Cobble.id,
      }).subscribe();
    } else {
      this.versioningService.DeactivateChange({
        versionId: version.versionId,
        cobbleId: this.cobbleService.Cobble.id,
      }).subscribe();
    }
    
    this.EnableDisableVersions(activate, version);
  }
  
  EnableDisableVersions(state: boolean, version?: PropertyVersioningDto) {
    if (version) {
      const allVersions = this.dataSource.data.filter(
        _version =>
          _version.property === version.property &&
          _version.path === version.path &&
          _version.version !== version.version,
      );
      
      // activate
      if (state) {
        allVersions.forEach(_version => {
          _version.active = !state;
        });
      } else {
        allVersions.forEach(_version => {
          _version.active =
            _version.version === version.version - 1 ? true : false;
        });
      }
      version.active = state;
    } else {
    }
  }
}
