import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Constants } from '../../shared/constants';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';

import { ErrorMessengerService } from '../../shared/services/error-messenger.service';

export abstract class ApiDataService {
  public RunningMode = false;
  protected apiUrl = Constants.Environment.apiUrl;
  protected apiEndpointUrl = this.apiUrl;
  protected cobbleService: CobbleService;
  
  constructor(
    endPoint: string = '',
    protected http: HttpClient,
    protected errorMessengerService: ErrorMessengerService,
  ) {
    this.apiEndpointUrl += `${ endPoint }`;
  }
  
  getByType(type: string) {
    if (this.RunningMode) {
      return;
    }
    return this.http.get(this.apiEndpointUrl + `/getbytype/${ type }`).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error getting user ${ type }`, type),
      ),
    );
  }
  
  GetAppsByCompany(companyId: number) {
    let options = {};
    if (companyId || companyId === 0) {
      const headers = new HttpHeaders().set(
        'cobbler-company-id',
        `${ companyId }`,
      );
      options = { headers: headers };
    }
    return this.http.get(this.apiEndpointUrl + `/getbytype/cobble/grouped`, options).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(
          error,
          `Error getting Apps for company ${ companyId }.`,
          companyId,
        ),
      ),
    );
  }
  
  getById(id: any): Observable<any> {
    return this.http.get(this.apiEndpointUrl + `/${ id }`).pipe(
      // .map(response => <any>response)
      catchError((error) => this.errorMessengerService.HandleError(error, 'Error getting resource')),
    );
  }
  
  
  getSharedWithMe() {
    return this.http.get(this.apiEndpointUrl + `/tree/shared`).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error getting Apps shared with me.`),
      ),
    );
  }
  
  getCobbleIsPublished(id: number) {
    return this.http.get(this.apiEndpointUrl + `/waspublished/${ id }`).pipe(
      map((response) => <any>response),
      catchError((error) => this.errorMessengerService.HandleError(error)),
    );
  }
  
  getChildrenById(id: number): Observable<any> {
    return this.http.get(this.apiEndpointUrl + `/GetCobbleItem/${ id }`).pipe(catchError((error) => this.errorMessengerService.HandleError(error)));
  }
  
  getAny(id: number) {
    if (this.RunningMode) {
      return;
    }
    return this.http.get(this.apiEndpointUrl + `/getany/${ id }`).pipe(
      map((response) => <any>response),
      catchError((error) => this.errorMessengerService.HandleError(error)),
    );
  }
  
  getAll() {
    if (this.RunningMode) {
      return;
    }
    return this.http.get(this.apiEndpointUrl).pipe(
      map((response) => <any>response),
      catchError((error) => this.errorMessengerService.HandleError(error)),
    );
  }
  
  insert(resource) {
    if (this.RunningMode) {
      return;
    }
    // console.log('inserting...', resource);
    return this.http.post(this.apiEndpointUrl, resource).pipe(catchError((error) => this.errorMessengerService.HandleError(error)));
  }
  
  update(resource) {
    if (this.RunningMode) {
      return;
    }
    // console.log('updating...', resource);
    
    return (
      this.http
      // .put(this.apiUrl, JSON.stringify(resource))
      .put(this.apiEndpointUrl, resource).pipe(catchError((error) => this.errorMessengerService.HandleError(error)))
    );
  }
  
  delete(id) {
    if (this.RunningMode) {
      return;
    }
    // console.log('deleting...', id);
    
    return this.http.delete(this.apiEndpointUrl + '/' + id).pipe(catchError((error) => this.errorMessengerService.HandleError(error)));
  }
  
  AddBackgroundTask(message: string, icon = 'information', options?: any) {
    const taskHeader = this.GenerateHttpHeaders([{
      name: 'LeapXL-BackgroundTaskMessage',
      value: message,
    }, {
      name: 'LeapXL-BackgroundTaskIcon',
      value: icon,
    }]);
    
    if (options) {
      return Object.assign({ headers: taskHeader }, options);
    } else {
      return { headers: taskHeader };
    }
  }
  
  GenerateHttpHeaders(headers: { name: string, value: string }[]) {
    let newHeaders = new HttpHeaders();
    
    headers.forEach(header => {
      newHeaders = newHeaders.append(header.name, header.value);
    });
    
    return newHeaders;
  }
  
  protected handleLoginError(error) {
    console.log(error);
    this.errorMessengerService.ShowMessageOnTop(
      'Your authentication information is incorrect. Please try again.',
      '',
    );
    
    return observableThrowError(new HttpErrorResponse(error));
  }
  
  protected AddAuditInfoToRequest(requestData: any = null) {
    requestData = requestData || {};
    
    requestData.applicationId = this.cobbleService.Cobble.id || null;
    requestData.applicationName = this.cobbleService.Cobble.properties.name || '';
    requestData.applicationVersion = this.cobbleService.Cobble.version || '';
    requestData.leapxlVersion = this.cobbleService.leapxlVersion || '';
    requestData.url = this.cobbleService.systemInformation.referrer || '';
    requestData.platform = this.cobbleService.systemInformation.browserPlatform || '';
    requestData.browser = `${ this.cobbleService.systemInformation.browserEngine } ${ this.cobbleService.systemInformation.clientInformation.vendor }`;
    
    return requestData;
  }
}
