import { Constants } from '../../../shared/constants';
import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class DisallowMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    dataBus: any,
    dataElementsData: any,
  ) {
    try {
      let disallow = Constants.Defaults.DataItemValue;
      
      if (dataElementsData) {
        dataElementsData.forEach(de => {
          disallow = this.toolsService.ExtractValuesByType(de).string || Constants.Defaults.DataItemValue;
          dataBus = this.DataActionModifierApply(dataBus, this.Modifier, { disallow, repMoleculeId });
        });
      } else {
        disallow = rule.disallow;
        dataBus = this.DataActionModifierApply(dataBus, this.Modifier, { disallow, repMoleculeId });
      }
      
      
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    }
  }
  
  public AfterRemove(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  private Modifier(value: string, data: { disallow: string, repMoleculeId: number }): string {
    
    console.log('disallow');
    for (let disallowValue of data.disallow.split('|')) {
      if (value.indexOf(disallowValue) > -1) {
        
        // escaping backslash for regex to work
        // if (disallowValue === '\\') {
        //   disallowValue = '\\\\';
        // }
        //
        
        const element = <Element>(
          document.getElementById(data.repMoleculeId.toString()).parentNode.parentNode
        );
        element.classList.add('disallow');
        
        // const re = new RegExp(disallowValue, 'g');
        // value = value.replace(re, '');
        value = (value as any).replaceAll(disallowValue, '');
        setTimeout(() => {
          element.classList.remove('disallow');
        }, 300);
      }
    }
    
    return value;
  }
}
