import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiAuthService } from '../core/services/api-auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: ApiAuthService, private router: Router) {
  }
  
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    console.log('evaluating guard');
    
    if (this.authService.loggedIn()) {
      if (this.authService.EvaluateDefaultApp()) {
        // exists default app, redirect
      } else {
        return true;
      }
    } else {
      this.router.navigate(['/login']);
    }
    
    return false;
  }
}
