import { Component, OnInit } from '@angular/core';
import { Constants } from '../shared/constants';

@Component({
  selector: 'app-debug-toggle',
  templateUrl: './debug-toggle.component.html',
  styleUrls: ['./debug-toggle.component.scss'],
})
export class DebugToggleComponent implements OnInit {
  
  constructor() {
  }
  
  ngOnInit() {
    let debug = localStorage.getItem(`${ Constants.LocalStoragePrefix }debug`);
    
    try {
      debug = JSON.parse(debug);
      
      if (debug) {
        localStorage.setItem(`${ Constants.LocalStoragePrefix }debug`, 'false');
      } else {
        localStorage.setItem(`${ Constants.LocalStoragePrefix }debug`, 'true');
      }
      
      
    } catch (e) {
      localStorage.setItem(`${ Constants.LocalStoragePrefix }debug`, 'true');
    }
    
    const url = `${ document.location.protocol }//${ document.location.hostname }${
      document.location.hostname.search('localhost') > -1 ? ':4200' : ''
    }/dashboard`;
    
    window.location.href = url;
    
  }
  
}
