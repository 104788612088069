import { Component, OnInit } from '@angular/core';
import { ApiDataSourcesService } from '../../core/services/api-data-sources.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-unified-db-setup',
  templateUrl: './unified-db-setup.component.html',
  styleUrls: ['./unified-db-setup.component.scss'],
})
export class UnifiedDbSetupComponent implements OnInit {
  
  DraggableWindowType = 'UnifiedDbConnection';
  
  systemList = [];
  applicationList = [];
  tableList = [];
  
  systemSelected = '';
  applicationSelected = '';
  tableSelected = '';
  
  constructor(private dsService: ApiDataSourcesService, private communicationService: CommunicationService, private workAreaService: WorkAreaService) {
  }
  
  ngOnInit() {
    this.getUnificationContext('');
  }
  
  dropdownSelection(dropdown: string) {
    // console.log('dropdown change', dropdown);
    switch (dropdown) {
      case 'system':
        this.applicationSelected = '';
        this.tableSelected = '';
        setTimeout(() => {
          this.getUnificationContext(dropdown);
        }, 200);
        break;
      
      case 'application':
        this.tableSelected = '';
        setTimeout(() => {
          this.getUnificationContext(dropdown);
        }, 200);
        break;
      
      case 'table':
        break;
    }
  }
  
  getUnificationContext(dropdown: string) {
    this.dsService.GetUnificationContext(this.systemSelected, this.applicationSelected, this.tableSelected).subscribe(Response => {
      // console.log(Response);
      
      if ((Response as any).error) {
        Response = [];
      }
      
      switch (dropdown) {
        case 'system':
          this.applicationList = Response;
          break;
        
        case 'application':
          this.tableList = Response;
          break;
        
        case '':
          this.systemList = Response;
          break;
      }
    }, error => {
      // console.log(error);
    });
  }
  
  AddUnifiedDb() {
    this.dsService.SetUnificationDbCollection(this.systemSelected, this.applicationSelected, this.tableSelected).subscribe(Response => {
      this.communicationService.Event.Editor.DataSource.$ReloadDataSourcePanel.emit([]);
      if (this.workAreaService.apiSetupDraggableWindow) {
        this.workAreaService.unifiedDbSetupDraggableWindow.Hide();
      }
    });
  }
}
