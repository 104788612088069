import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class GetValueFromRangeDataMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(particleId: string, repMoleculeId: string, busProcessorKey: string, rule: any, logic: any, data: any) {
    try {
      data = data === undefined || data === null || data === {} ? '' : data;
      
      // console.log(rule);
      
      if (data && data.relatedData) {
        if (rule && rule.searchType && rule.searchType !== '' && rule && rule.index && rule.index !== '') {
          let value = '';
          const cellIndex =
            rule.searchType === 'Row' ? +rule.index : Number.isInteger(+rule.index) ? +rule.index : this.toolsService.ColumnNameToIndex(rule.index);
          
          data.relatedData.forEach((cell) => {
            if (rule.searchType === 'Row') {
              if (cell.row === cellIndex) {
                value = cell.value;
              }
            } else {
              if (cell.col === cellIndex) {
                value = cell.value;
              }
            }
          });
          data.value = value;
          data.valueCompare = data.valueCompare || [];
          data.valueCompare.push(value);
        }
      }
      
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
