import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TreeNode } from 'angular-tree-component';
import { FileUploader } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { ApiMoleculesService } from '../../core/services/api-molecules.service';
import { AutoGenerationTranslatorService } from '../../core/services/autogeneration-translator.service';
import { AutoGenerationService } from '../../core/services/autogeneration.service';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { ToolsService } from '../../core/services/tools.service';
import { LeapXLFileFormat } from '../../shared/enums/leapxl-file-format.enum';
import { CommunicationService } from '../../shared/services/communication.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { GenerationAppProcessComponent } from '../generation-app-process/generation-app-process.component';
import { AutoGenDirective } from '../interfaces/autogen-directive.interface';
import { AutoGenProcess } from '../interfaces/autogen-process.interface';

@Component({
  selector: 'app-generation-app',
  templateUrl: './generation-app.component.html',
  styleUrls: ['./generation-app.component.scss'],
})
export class GenerationAppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  processes: AutoGenProcess[] = [];
  nodes: TreeNode[] = [];
  subscription: Subscription;
  disableAddProcess = true;
  uploader: FileUploader = new FileUploader({});
  supportedFileType = `.${ LeapXLFileFormat.AutoGen }`;
  generatingApp = false;
  importingApp = false;
  componentsRef: ComponentRef<GenerationAppProcessComponent>[] = [];
  navigation: {
    enabled: boolean;
    componentId: number;
  } = {
    enabled: false,
    componentId: null,
  };
  
  translationText = `
  {
    [
      [
        Api::34 Payrolls Employees Create::data::date_of_birth,
        Api::34 Payrolls Employees Create::data::department_id,
        Api::34 Payrolls Employees Create::data::employee_id,
        Api::34 Payrolls Employees Create::data::first_name,
      ]
      [type-create:1]
      [subject-form]
      []
      [repMolSplit]
      [model-component:255706]
      [padding:4,separation:2,orientation:vertical,modelOptions:standalone,columns:0,
        addons.header:true,
        addons.autoStart:false,
        addons.hidden:false,
      ]]
  }
  `;
  
  test = `
  {
    [[Custom::test::literal::test1,Custom::test::literal::test2] [create:10] [repMolecule:20] [join] [component] []] ->
    [[Custom::test::literal::test1,Custom::test::literal::test2] [create] [repMolecule] [join] [component] []]
  }
  {
    [[Custom::test::literal::test1,Custom::test::literal::test2] [create] [repMolecule] [join] [component] []] ->
    [[Custom::test::literal::test1,Custom::test::literal::test2] [create] [repMolecule] [join] [component] []]
  }`;
  
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private dialogService: GenericDialogService,
    private communicationService: CommunicationService,
    public autoGenerationService: AutoGenerationService,
    private toolsService: ToolsService,
    private snackerService: SnackerService,
    private apiMoleculesService: ApiMoleculesService,
    private autogenerationTranslatorService: AutoGenerationTranslatorService,
    private dialogRef: MatDialogRef<GenerationAppComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { nodes: TreeNode[]; directive: AutoGenDirective },
  ) {
    if (data) {
      if (data.nodes) {
        this.nodes = data.nodes;
      }
      
      if (data.directive) {
        this.importingApp = true;
        setTimeout(() => {
          this.ImportDirective(data.directive);
        }, 300);
      }
    }
  }
  
  ngOnInit(): void {
    this.autoGenerationService.Clear();
    this.subscription = this.communicationService.Event.Editor.DataSource.$ApiNodesSelected.subscribe(
      nodes => (this.nodes = nodes));
    
    this.subscription.add(
      this.communicationService.Event.Editor.DataSource.$DisableAddProcess.subscribe(
        disableAddProcess => (this.disableAddProcess = disableAddProcess),
      ),
    );
    
    this.apiMoleculesService.GetUserComponents()
    .subscribe(components => {
      this.autoGenerationService.components = components;
      this.autoGenerationService.components.sort(this.toolsService.CompareValues('name'));
    });
  }
  
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
  
  ngAfterViewInit(): void {
    this.CreateProcess();
  }
  
  CreateProcess(process: AutoGenProcess = null) {
    this.communicationService.Event.Editor.DataSource.$DisableAddProcess.emit(true);
    this.autoGenerationService.processId++;
    const factory = this.componentFactoryResolver.resolveComponentFactory(GenerationAppProcessComponent);
    const component = this.container.createComponent(factory);
    this.componentsRef.push(component);
    component.instance.id = this.autoGenerationService.processId;
    component.instance.nodes = this.nodes;
    component.instance.process.subscribe(process => {
      const existingProcessIndex = this.processes.findIndex(p => p.id === process.id);
      
      if (process.actions.length > 0) {
        if (existingProcessIndex !== -1) {
          this.processes[existingProcessIndex] = process;
        } else {
          this.processes.push(process);
        }
      } else {
        // Remove empty actions
        this.processes = this.processes.filter(p => p.id !== process.id);
      }
      
      this.autoGenerationService.processesIsEmpty = this.processes.length === 0;
      
      if (this.importingApp) {
        this.importingApp = false;
        this.toolsService.RedrawBrowser();
      }
      
      console.warn(this.processes);
    });
    
    component.instance.remove.subscribe(id => {
      this.componentsRef = this.componentsRef.filter(c => c.instance.id !== id);
      component.destroy();
      this.autoGenerationService.processId--;
      
      if (this.processes.length === 0) {
        this.CreateProcess();
      }
    });
    
    if (process) {
      component.instance.processImport = process;
    }
  }
  
  ExportDirective() {
    this.autoGenerationService.ExportDirective({
      id: 0,
      separateView: true,
      processes: this.processes,
      navigation: this.navigation,
    });
    this.snackerService.ShowMessageOnBottom('Directive downloaded', 'download');
  }
  
  async SelectAutoGenFileToImport(ref: HTMLInputElement, event: MouseEvent) {
    if (this.processes.length > 0) {
      const result = await this.dialogService.OpenConfirmDialog({
        title: 'Import Schema',
        message: 'Import new schema? You will loss the current work',
        confirmText: 'Select File',
        cancelText: 'Cancel',
        maxWidth: '700px',
      });
      
      if (!result) {
        return;
      }
      
      event.preventDefault();
      event.stopPropagation();
      setTimeout(() => {
        ref.click();
      }, 100);
    } else {
      event.preventDefault();
      event.stopPropagation();
      setTimeout(() => {
        ref.click();
      }, 100);
    }
  }
  
  HandleFile(files: FileList) {
    this.autoGenerationService.ImportAutogenFile(files)
    .subscribe(directive => {
      if (directive) {
        console.log(directive);
        this.importingApp = true;
        this.ImportDirective(directive);
      } else {
        this.snackerService.ShowMessageOnBottom('Error Importing File', 'error');
      }
    });
  }
  
  ImportDirective(directive: AutoGenDirective) {
    this.navigation = directive.navigation || {
      enabled: false,
      componentId: null,
    };
    this.CreateProcesses(directive);
  }
  
  CreateProcesses(directive: AutoGenDirective) {
    this.componentsRef.forEach(component => component.destroy());
    this.autoGenerationService.Clear();
    directive.processes.forEach(process => this.CreateProcess(process));
  }
  
  TranslateText() {
    const translatedProcesses = this.autogenerationTranslatorService.TranslateText(this.translationText);
    this.ImportDirective({
      id: 0,
      processes: translatedProcesses,
      separateView: true,
      navigation: {
        enabled: false,
        componentId: null,
      },
    });
  }
  
  async CloseDialog() {
    if (this.processes.length > 0) {
      const result = await this.dialogService.OpenConfirmDialog({
        title: 'Close Auto Generation App Window',
        message: 'Close window? You will lose the current work',
        confirmText: 'Close',
        cancelText: 'Cancel',
        maxWidth: '700px',
      });
      
      if (!result) {
        return;
      }
      
      if (this.importingApp) {
        return;
      }
      this.dialogRef.close();
    } else {
      if (this.importingApp) {
        return;
      }
      this.dialogRef.close();
    }
  }
  
  GenerateApp() {
    
    const processesClone = [...this.processes];
    
    // processesClone.forEach(p => {
    //   p.actions.forEach(a => {
    //     a.dataElements = a.dataElements.map(de => de.GetRawObject());
    //   });
    // });
    
    const directive = {
      id: 0,
      separateView: false,
      processes: this.processes,
      navigation: this.navigation,
    };
    
    // this.toolsService.ExportToTextFile('directive', {
    //   ...directive,
    //   processes: processesClone,
    // });
    
    this.generatingApp = true;
    console.log(this.processes);
    this.autoGenerationService
    .Process(directive)
    .then(resul => {
      this.generatingApp = false;
      console.log('completed');
      this.dialogRef.close();
    });
  }
  
  ToggleNavigation(event) {
    this.navigation.enabled = event.checked;
    
    if (!this.navigation.enabled) {
      this.navigation.componentId = null;
    }
  }
  
  NavigationComponentSelected(e: any) {
    console.log(e);
  }
}
