import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcGridSheet from '@grapecity/wijmo.grid';
import { Subscription } from 'rxjs';
import { ApiDataSourcesService } from '../../../core/services/api-data-sources.service';
import { EditorStateService } from '../../../core/services/editor-state.service';
import { GenericDialogService } from '../../../core/services/generic-dialog.service';
import { ToolsService } from '../../../core/services/tools.service';
import { Constants } from '../../../shared/constants';
import { CommunicationService } from '../../../shared/services/communication.service';
import { DraggableWindowService, DraggableWindowType } from '../../../shared/services/draggable-window.service';

@Component({
  selector: 'app-data-drill-down',
  templateUrl: './data-drill-down.component.html',
  styleUrls: ['./data-drill-down.component.scss'],
})
export class DataDrillDownComponent implements OnInit, OnDestroy {
  @ViewChild('flexGrid', { static: false })
  flexGrid: wjcGridSheet.FlexGrid;
  
  data: any;
  table: any;
  columns: string[];
  dataTable: wjcCore.CollectionView;
  title: string;
  contexts: string[] = [];
  contextDataElements = [];
  subscriptions: Subscription = new Subscription;
  response = [];
  loadingDrillDownData = false;
  Constants = Constants;
  sources = [];
  
  constructor(
    private datasourceService: ApiDataSourcesService,
    private draggableWindowService: DraggableWindowService,
    private communicationService: CommunicationService,
    private toolsService: ToolsService,
    private editorStateService: EditorStateService,
    private genericDialogService: GenericDialogService,
  ) {
    this.contexts = this.draggableWindowService.GetData().contexts;
    this.table = [];
    this.columns = [];
  }
  
  ngOnInit() {
    
    this.loadingDrillDownData = true;
    this.subscriptions.add(this.communicationService.Event.System.$WindowResized.subscribe(() => {
      setTimeout(() => {
        // console.log('update grid');
        // console.log(this.flexGrid);
        
        if (this.flexGrid) {
          this.flexGrid.invalidate();
        }
      }, 200);
    }));
    
    const tempCols = [];
    
    const tempArray = new wjcCore.ObservableArray();
    setTimeout(() => {
      this.datasourceService.GetContextReferences(this.contexts).subscribe((dataElements) => {
        
        this.response = dataElements;
        this.contextDataElements = dataElements.filter(de => this.contexts.includes(de.context));
        // console.log('contextDataElements', this.contextDataElements);
        
        this.contextDataElements.forEach(de => {
          if (!this.sources.includes(de.context)) {
            this.sources.push(de.context);
          }
        });
        
        dataElements.forEach((cell) => {
          const row = this.table.find((_row) => _row.index === cell.row);
          if (row) {
            const col = tempCols.find((_col) => _col === cell.col);
            if (!col) {
              tempCols.push(cell.col);
            }
            row.columns.push({
              col: cell.col,
              row: cell.row,
              value: cell.formattedValue,
              formula: cell.formula,
              comment: cell.comment,
              context: cell.context,
              affectedBy: cell.affectedBy,
              affectingTo: cell.affectingTo,
              canDrillDown: cell.formula && !!this.contextDataElements.find(c => c.context === cell.context),
            });
          } else {
            const newRow = {
              index: cell.row,
              columns: [],
            };
            const col = tempCols.find((_col) => _col === cell.col);
            if (!col) {
              tempCols.push(cell.col);
            }
            newRow.columns.push({
              col: cell.col,
              row: cell.row,
              value: cell.formattedValue,
              formula: cell.formula,
              comment: cell.comment,
              context: cell.context,
              affectedBy: cell.affectedBy,
              affectingTo: cell.affectingTo,
              canDrillDown: cell.formula && !!this.contextDataElements.find(c => c.context === cell.context),
            });
            this.table.push(newRow);
          }
        });
        this.table.forEach((row) => {
          const rowValues = {};
          row.columns.forEach((cell) => {
            rowValues[this.toolsService.ColumnIndexToName(cell.col)] = cell;
          });
          tempArray.push(rowValues);
        });
        // // console.log(tempArray);
        tempCols.forEach((_col) => {
          this.columns.push(this.toolsService.ColumnIndexToName(_col));
        });
        setTimeout(() => {
          this.loadingDrillDownData = false;
          this.dataTable = new wjcCore.CollectionView(tempArray);
          this.dataTable.refresh();
        }, 150);
      });
    }, 50);
  }
  
  ShowDrillDown(contexts: string) {
    this.draggableWindowService.OpenDraggableWindow(
      'Drill Down: ' + contexts,
      DraggableWindowType.DataDrillDown,
      null,
      {
        contexts: [contexts],
      },
    );
  }
  
  ShowDrillDownForValue(context: string) {
    
    const contextDataElement = this.contextDataElements.find(c => c.context === context);
    
    if (contextDataElement) {
      
      if (contextDataElement.affectedBy && contextDataElement.affectedBy.length > 10) {
        this.genericDialogService.OpenConfirmDialog({
          title: 'Warning Drill Down References',
          message: `You are about to drill down on to ${ contextDataElement.affectedBy.length } cell references, this might take SEVERAL minutes to complete, we recommend selecting the references
            you wish to look at from the list above to speed the proccess. Do you still want to continue?`,
          confirmText: 'Continue',
          cancelText: 'Cancel',
        }).then((confirm) => {
          if (confirm) {
            contextDataElement.affectedBy.forEach((contextAffecting, index) => {
              setTimeout(() => {
                  
                  this.toolsService.DragWindowConfig = {
                    changeLayout: false,
                    x: 60 * (index + 1),
                    y: 60 * (index + 1),
                    width: 600,
                    height: 500,
                  };
                  
                  this.ShowDrillDown(contextAffecting.context);
                },
                400 * index,
              );
            });
          }
        });
      } else {
        contextDataElement.affectedBy.forEach((contextAffecting, index) => {
          setTimeout(() => {
              
              this.toolsService.DragWindowConfig = {
                changeLayout: false,
                x: 60 * (index + 1),
                y: 60 * (index + 1),
                width: 600,
                height: 500,
              };
              
              this.ShowDrillDown(contextAffecting.context);
            },
            400 * index,
          );
        });
      }
    }
    
  }
  
  Formatter(p, r, c, cell) {
    const toolTip = new wjcCore.Tooltip();
    toolTip.showDelay = 10;
    toolTip.hideDelay = 100000;
    
    if (p.rows[r]._data) {
      const cells = Object.values(p.rows[r]._data) as Array<any>;
      
      if (p.cellType === wjcGrid.CellType.RowHeader) {
        cell.textContent = cells[0].row;
      }
      
      if (p.cellType === wjcGrid.CellType.Cell) {
        const data = cells[c];
        
        if (data && data.formula && data.canDrillDown) {
          toolTip.setTooltip(cell, `Drill Down Formula: ${ data.formula }`);
        }
      }
    }
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
