import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiCompaniesService } from '../core/services/api-companies.service';
import { MaterialModule } from '../shared/material/material.module';
import { AuthRoutingModule } from './auth-routing.module';
import { ExternalLoginComponent } from './externalLogin/externalLogin.component';
import { ForgotPasswordComponent } from './forgotPassword/forgotPassword.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { RegisterComponent } from './register/register.component';
import { SetPasswordComponent } from './setPassword/setPassword.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatPasswordStrengthModule,
    AuthRoutingModule,
  ],
  declarations: [
    LoginComponent,
    RegisterComponent,
    SetPasswordComponent,
    ForgotPasswordComponent,
    ExternalLoginComponent,
    LogoutComponent,
    HomeComponent,
  ],
  providers: [ApiCompaniesService],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AuthModule {
}
