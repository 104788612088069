import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';
import { Constants } from './../../../shared/constants';

export class LoginUsernameMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    data: any,
    dataElementsData: any,
  ) {
    try {
      let username = data;
      
      if (dataElementsData) {
        username =
          this.toolsService.ExtractValuesByType(dataElementsData).string || Constants.Defaults.DataItemValue;
      }
      
      this.authService.loginUsername = username;
      // console.log(`molecule working, password`);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    } catch (error) {
      // console.log(error);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
