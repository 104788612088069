import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BuilderService } from '../../../../core/builder/builder.service';
import { ProcessorService } from '../../../../core/molecular/services/processor.service';
import { WorkAreaService } from '../../../../workarea/workarea.service';
import { RepresentativeMoleculesType } from '../../../enums/representative-molecules-types.enum';
import { DragService } from '../../services/drag.service';
import { BaseMoleculeComponent } from '../base-molecule/base-molecule.component';

@Component({
  selector: 'app-qrcode-molecule',
  templateUrl: './qrcode-molecule.component.html',
  styleUrls: ['./qrcode-molecule.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class QrCodeMoleculeComponent extends BaseMoleculeComponent
  implements OnInit {
  MoleculeType = 'Representative';
  Type = RepresentativeMoleculesType.QrCode;
  Text = '';
  NoTextSet = 'Leap App';
  noQrCodeImgPath = '/assets/images/qrcode-placeholder.png';
  qrCodeImage = '';
  
  @ViewChild('qrcodeWrapper', { static: true })
  qrcodeWrapper: ElementRef;
  
  constructor(
    public builderService: BuilderService,
    public dragService: DragService,
    public workAreaService: WorkAreaService,
    public processorService: ProcessorService,
    bottomSheet: MatBottomSheet,
    public el: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
    super(bottomSheet, el, changeDetectorRef);
  }
  
  ngOnInit() {
    super.ngOnInit();
    if (!this.context.RunningMode) {
      this.DetachChangeDetection();
    }
    this.context.Type = RepresentativeMoleculesType.QrCode;
    this.RefreshGridsterConfiguration();
    this.UpdateData();
  }
  
  AttachEditorEventListeners() {
    const dragoverEventListener = this.renderer.listen(this.qrcodeWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, true);
    });
    const dragleaveEventListener = this.renderer.listen(this.qrcodeWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, false);
    });
    const dropEventListener = this.renderer.listen(this.qrcodeWrapper.nativeElement, 'drop', (evt) => {
      this.DataDropped(evt);
    });
  }
  
  UpdateData() {
    if (this.context.Value && this.context.Value !== '') {
      this.CreateQrCode(this.context.Value);
    } else if (
      this.context.Properties.source &&
      this.context.Properties.source !== ''
    ) {
      this.CreateQrCode(this.context.Properties.source);
    } else {
      this.qrCodeImage = this.noQrCodeImgPath;
      this.StopLoading();
    }
  }
  
  CreateQrCode(text: string = '') {
    this.context.Loading = true;
    if (text !== '') {
      try {
        this.toolsService.GenerateLeapXLQRCode(text, 400).subscribe(image => {
          this.qrCodeImage = image;
          this.StopLoading();
        });
      } catch (error) {
        this.Text = this.NoTextSet;
        this.StopLoading();
      }
    } else {
      this.StopLoading();
    }
  }
  
  StopLoading() {
    this.context.Loading = false;
    this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(this.context.ParentId);
    this.RefreshUI();
  }
  
  FireClickEvent(e: any) {
    this.FireRepresentativeMoleculeEvent('click', null, true);
  }
}
