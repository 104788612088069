import { EventEmitter, NgZone } from '@angular/core';
import { CompactType, DisplayGrid, GridsterConfig, GridType } from '@leapxl/gridster';
import { Receptor } from 'app/core/molecular/receptors.enum';
import { cloneDeep } from 'lodash-es';
import { Observable, of, Subscription } from 'rxjs';
import { AppInjector } from '../../../app-injector';
import { BusService } from '../../../core/molecular/services/bus.service';
import {
  MolecularEngineConnectorService,
} from '../../../core/molecular/services/molecular-engine-connector.service';
import { ProcessorService } from '../../../core/molecular/services/processor.service';
import { ApiDataSourcesService } from '../../../core/services/api-data-sources.service';
import { ApiPropertiesService } from '../../../core/services/api-properties.service';
import { EditorStateService } from '../../../core/services/editor-state.service';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { TemplateService } from '../../../core/services/template.service';
import { ToolsService } from '../../../core/services/tools.service';
import { tableTemplate } from '../../../shared/templates/table';
import { AutoGenInfo } from '../../../workarea/interfaces/autogen-info.interface';
import { ThematicService } from '../../../workarea/services/thematic.service';
import { Constants } from '../../constants';
import { PropertyVersioningDto } from '../../dtos/versioning-dto';
import { AutoGenType } from '../../enums/autogen-type.enum';
import { DatasourceType } from '../../enums/datasource-type.enum';
import { LeapXLEventType } from '../../enums/leapxl-event-type.enum';
import { MoleculesType } from '../../enums/molecules-type.enum';
import { RepresentativeMoleculesType } from '../../enums/representative-molecules-types.enum';
import { IChartOptions } from '../../interfaces/chart-options.interface';
import { DatasourceDataResponse } from '../../interfaces/datasource-data-response';
import { IRepresentativeMoleculeStyleData } from '../../interfaces/rep-mol-style.interface';
import { CommunicationService } from '../../services/communication.service';
import { FactoryParticleService } from '../../services/factory-particle.service';
import { SnackerService } from '../../services/snacker.service';
import { WindowStoreService } from '../../services/window-store.service';
import { CobbleService } from '../services/cobble.service';
import { ActionMoleculeProperties } from './action-molecule-properties';
import { ActionMolecule } from './action-molecules';
import { Bus } from './bus';
import { DataElement } from './data-element';
import { LeapXLEvent } from './leapxl-event';
import { Particle } from './particle';
import { IRepresentativeMolecule } from './representative-molecule.interface';

export class RepresentativeMolecule implements IRepresentativeMolecule {
  public $UpdateValue = new EventEmitter<any>();
  public $FireEvent = new EventEmitter<any>();
  public $RefreshParentUI = new EventEmitter<any>();
  public $Resized = new EventEmitter<any>();
  public $UpdateTextValue = new EventEmitter<any>();
  public Id: number;
  public ParentId: number;
  public Renderized: boolean;
  public DatasourceUpdated: boolean;
  public BadgeValue: string;
  public Filter: string;
  public SubParentId: number;
  public EditorVisible: boolean;
  public LastSelected: any;
  public RuntimeData: any;
  public SubParentContext: string;
  public Placeholders: string[];
  public StyleMetadata: {
    styles: number[];
    specificStyles: {
      dimension: { styleId: number; path: string };
      font: { styleId: number; path: string };
      hover: { styleId: number; path: string };
      frame: { styleId: number; path: string };
      appearance: { styleId: number; path: string };
    };
    manualAdjustedProperties: { property: string; path: string }[];
  };
  public EnableDragBetweenWG: boolean;
  public InputReceptorProcessed: string;
  public Contexts: string[];
  public Events: string[];
  public Initialized: boolean;
  public DefaultEvent: string;
  public PreviewParticleValue?: {
    ParticleName: string;
    ParticleValues: any;
  };
  public ElementsOnRight: IRepresentativeMolecule[] = [];
  public ElementsOnLeft: IRepresentativeMolecule[] = [];
  public LimitOnRight: boolean;
  public DisplayCustomGuidelines = true;
  public BackendProcess: number;
  AutoGenerationInfo: AutoGenInfo[];
  public SearchFilter: string;
  public PageNumber: number;
  public ProcessedValue: any;
  public PageChanged: boolean;
  public CriticBackendProcess: boolean;
  public LimitOnLeft: boolean;
  public DataSourceConnected: boolean;
  public PasswordMasking: boolean;
  public CreationFromTemplate: boolean;
  public TemplateKeepDatasources: boolean;
  public HeaderValue: any[] = [];
  public DisplayTabOrder: boolean;
  public InitValue: any;
  public OriginalRepMoleculeId: number;
  public Required: boolean;
  public CellDataElement: any;
  public RowDataElements: any[];
  public ValueMetaData: {
    TranslationIds: number[][];
    Contexts: string[][];
    SearchFilter: string;
    Offset: number;
    Limits: number[];
    Value: string;
    OperationType: string;
    PageNumber: number;
    PageSize: number;
    PageCount: number;
    TotalItemCount: number;
    FilterIndex: number;
    FilterValue: string;
  };
  public Type: string;
  public Children: any[] = [];
  public ValueOptions: any[] = [];
  public OldValue: any;
  public Versioning: any;
  public MoleculesAllow: string[];
  public Icon: string;
  public ReplaceableByRepresentative: boolean;
  public Buses: Bus[] = [];
  public Receptors: string[];
  ExtraData: any;
  public PopulatedData: {
    collection: string;
    finalCol: number;
    finalRow: number;
    firstCol: number;
    firstRow: number;
    value: any;
  };
  public Loading: boolean;
  public DataKey: string;
  public DragOver: boolean;
  public RunningMode: boolean;
  public PreviewMode: boolean;
  public Debounce: { events: { name: string, time: number }[] };
  public IsSelected: boolean;
  public IsMoving: boolean;
  public MoleculeType: string;
  public MoleculeSubType: string;
  public TemplateId: number;
  public TemplateVersion: string;
  public TemplateOwnerId: number;
  public OriginalPosition: {
    desktop: {
      cols: number;
      rows: number;
      x: number;
      y: number;
    };
    smartphone: {
      cols: number;
      rows: number;
      x: number;
      y: number;
    };
  };
  public GridsterConfig: GridsterConfig;
  public Disallow: boolean;
  public Touched: boolean;
  public Value: any;
  public StartValue: any;
  public EndValue: any;
  public ValueSource: any;
  public ContextMenu: any;
  public GridsterItem: any;
  public OptionsMenu: {
    configuration: boolean;
    molecules: boolean;
    compound: boolean;
    cobblet: boolean;
    remove: boolean;
    versioning: any;
    dataSource: boolean;
    duplicate: boolean;
  };
  public EditableProperties: string[];
  public Properties: {
    id: number;
    options: any[];
    name: string;
    separator: string;
    textToDisplay: string;
    altText: string;
    wrapText: string;
    alwaysRenderComponent: boolean;
    enable: boolean;
    dateRange: boolean;
    horizontalPadding: number,
    verticalPadding: number,
    search: boolean;
    multiSelect: boolean;
    autoDataRefresh: boolean;
    location: string;
    allowManualEntry: boolean,
    maskType: string;
    mask: string;
    appearance: string;
    squareCorners: boolean;
    minValue: any;
    maxValue: any;
    customControl: {
      receptors: { name: string }[],
      events: { name: string }[],
      modified: boolean,
      html: string,
      css: string,
      js: string,
      options: {
        documentation: {
          info: string,
          events: { name: string, info: string }[],
          receptors: { name: string, info: string }[],
        },
        isolatedTransparentBackground: boolean,
        jsModuleType: boolean,
        encapsulate: boolean,
        noBounds: boolean,
        previewOnEditor: boolean,
        allowImportTemplate: boolean,
        allowEdit: boolean,
        isolated: boolean,
        obfuscateJs: boolean,
        externalJs: { url: string, type: string, module: boolean, valid: boolean }[],
        externalCss: { url: string, type: string, module: boolean, valid: boolean }[]
      },
    };
    viewNames: any[];
    lockWidth: boolean;
    lockHeight: boolean;
    lockImageSize: boolean;
    originalQuality: boolean;
    originalSize: boolean;
    sliderVertical: boolean;
    sliderStep: number;
    sliderThumbLabel: boolean;
    sliderTicks: boolean;
    sliderColor: string;
    progressType: string;
    progressColor: string;
    progressBackgroundColor: string;
    progressDiameter: number;
    progressMode: string;
    progressStrokeWidth: number;
    badge: {
      badgeEnable: boolean;
      badgeBackgroundColor: string;
      badgeIcon: boolean;
      badgeAnimate: string;
      badgeFontFamily: string;
      badgeFontColor: string;
      badgeStyle: string;
      badgePosition: string;
    };
    stepper: {
      stepperLabelPosition: string;
      stepperSequential: boolean;
      stepperScrollbars: boolean;
      stepperOrientation: string;
    };
    cssFilter: 'none';
    showAltText: boolean;
    loadingText: string;
    margin: number;
    tooltip: string;
    borders: boolean;
    align: string;
    allowEmptyValues: boolean;
    textDecoration: string;
    orientation: 'col' | 'row';
    icon: {
      iconType: string;
      iconColor: string;
      iconSize: number;
    };
    view: number;
    filter: boolean;
    show: boolean;
    source: any;
    maxIncrement: number;
    increment: number;
    maxLimit: number;
    limit: number;
    ignoreValueDataIndex: boolean;
    maxEmptyCellsLimit: number;
    emptyCellsLimit: number;
    headerText: string;
    chartLibrary: string;
    chartType: string;
    selectFirstOption: boolean;
    skipFirstOption: boolean;
    labelPosition: 'after' | 'before';
    chartShowLines: boolean;
    chartLegend: boolean;
    chartScales: boolean;
    chartLabels: boolean;
    chartOrientation: 'left' | 'top';
    minItemCols: number;
    maxItemCols: number;
    dragEnabled: boolean;
    resizeEnabled: boolean;
    inOutAnimation: string;
    offsetRows: number;
    offsetCols: number;
    hover: {
      hoverBackground: string;
      hoverBackgroundOpacity: number;
      hoverBorderRadius: number;
      hoverBorderStyle: string;
      hoverBorderColor: string;
      hoverBorderWidth: number;
      hoverFontColor: string;
      hoverTextDecoration: string;
      hoverPointer: string;
    };
    bordersValues: {
      borderRadius: number;
      borderWidth: number;
      borderStyle: string;
      borderColor: string;
    };
    background: {
      backgroundColor: string;
      backgroundTypeImage: boolean;
      backgroundImageUrl: string;
      backgroundOpacity: number;
    };
    preferences: {
      hiddenFor: number[];
    };
    shadowValues: {
      hShadow: number;
      vShadow: number;
      shadowColor: string;
      shadowBlur: number;
    };
    opacity: number;
    tabindex: number;
    placeholder: string;
    responsive: {
      smartphone: {
        itemsPerPage: number;
        customGuidelines: {
          x: [];
          y: [];
        };
        header: {
          headerHeight: number;
          headerFontSize: number;
          headerFontColor: string;
          headerFontFamily: string;
          headerFontStyle: string;
          headerBackgroundColor: string;
          stickyHeaders: boolean;
        };
        body: {
          bodyFontFamily: string;
          bodyFontStyle: string;
          bodyFontSize: number;
          bodyHeight: number;
          bodyFontColor: string;
          bodyBackgroundColor: string;
          isBodyStriped: boolean;
          firstBodyStripedColor: string;
          secondBodyStripedColor: string;
          bodyHighlightRowOnHover: boolean;
          stickyFooters: boolean;
        };
        chartOptions: IChartOptions;
        tableOptions: {
          search: boolean;
          collapse: boolean;
          bodyRoundCorners: boolean;
          header: boolean;
          verticallyCenter: boolean;
          rowSeparator: boolean;
          columnSeparator: boolean;
          rowPadding: boolean;
          tableOrder: {
            col: number;
            position: number;
          }[];
          tableWidth: {};
        };
        id: any;
        cols: any;
        rows: any;
        colsQty: any;
        rowsQty: any;
        xOffset: number;
        yOffset: number;
        x: any;
        y: any;
        centerPositioning: boolean;
        layer: number;
        font: {
          fontSize: number;
          fontColor: string;
          fontFamily: string;
          fontStyle: string;
        };
      };
      desktop: {
        itemsPerPage: number;
        customGuidelines: {
          x: [];
          y: [];
        };
        header: {
          headerTextAlignment: string;
          headerHeight: number;
          headerFontSize: number;
          headerFontColor: string;
          headerFontFamily: string;
          headerFontStyle: string;
          headerBackgroundColor: string;
          stickyHeaders: boolean;
        };
        body: {
          bodyCenterText: boolean;
          bodyFontFamily: string;
          bodyFontStyle: string;
          bodyFontSize: number;
          bodyHeight: number;
          bodyFontColor: string;
          bodyBackgroundColor: string;
          isBodyStriped: boolean;
          firstBodyStripedColor: string;
          secondBodyStripedColor: string;
          bodyHighlightRowOnHover: boolean;
          stickyFooters: boolean;
        };
        chartOptions: IChartOptions;
        tableOptions: {
          search: boolean;
          collapse: boolean;
          bodyRoundCorners: boolean;
          header: boolean;
          verticallyCenter: boolean;
          rowSeparator: boolean;
          columnSeparator: boolean;
          rowPadding: boolean;
          tableOrder: {
            col: number;
            position: number;
          }[];
          tableWidth: {};
        };
        id: number;
        cols: number;
        rows: number;
        colsQty: number;
        rowsQty: number;
        xOffset: number;
        yOffset: number;
        x: number;
        y: number;
        layer: number;
        centerPositioning: boolean;
        font: {
          fontSize: number;
          fontColor: string;
          fontFamily: string;
          fontStyle: string;
        };
      };
    };
  };
  Debug = false;
  public PropertiesBackup = {};
  PreventOffsetToApply = false;
  private defaultPropertiesValues = {
    id: 0,
    name: '',
    show: true,
    headerText: '',
    viewNames: [],
    altText: '',
    horizontalPadding: 0,
    verticalPadding: 0,
    dateRange: false,
    search: false,
    multiSelect: false,
    autoDataRefresh: false,
    minValue: null,
    maxValue: null,
    enable: true,
    showAltText: true,
    squareCorners: true,
    textToDisplay: '',
    loadingText: '',
    separator: '/',
    chartType: 'line',
    icon: {
      iconType: 'stars',
      iconColor: 'black',
      iconSize: 45,
    },
    chartShowLines: true,
    source: '',
    textDecoration: 'none',
    allowEmptyValues: false,
    customControl: {
      receptors: [],
      events: [],
      modified: false,
      html: '',
      css: '',
      js: '',
      options: {
        documentation: {
          info: '',
          events: [],
          receptors: [],
        },
        isolatedTransparentBackground: false,
        jsModuleType: false,
        encapsulate: true,
        previewOnEditor: false,
        isolated: false,
        allowImportTemplate: false,
        allowEdit: false,
        obfuscateJs: false,
        externalJs: [],
        externalCss: [],
      },
    },
    chartLegend: true,
    badge: {
      badgeEnable: true,
      badgeBackgroundColor: '#f22525',
      badgeFontFamily: 'Source Sans Pro',
      badgeFontColor: 'white',
      badgeIcon: true,
      badgeAnimate: 'pulse',
      badgeStyle: 'circle',
      badgePosition: 'right',
    },
    orientation: 'col',
    location: null,
    chartScales: true,
    lockWidth: false,
    offsetRows: 0,
    offsetCols: 0,
    stepper: {
      stepperLabelPosition: 'end',
      stepperSequential: false,
      stepperScrollbars: false,
      stepperOrientation: 'horizontal',
    },
    lockHeight: false,
    lockImageSize: false,
    originalQuality: false,
    originalSize: false,
    cssFilter: 'none',
    selectFirstOption: true,
    skipFirstOption: false,
    chartLegacy: true,
    chartLabels: true,
    chartOrientation: 'top',
    borders: true,
    options: [],
    dragEnabled: true,
    filter: true,
    ignoreValueDataIndex: false,
    maxIncrement: 10,
    increment: 5,
    maxLimit: 10,
    limit: 5,
    maxEmptyCellsLimit: 2,
    emptyCellsLimit: 1,
    resizeEnabled: true,
    inOutAnimation: 'slideUp',
    bordersValues: {
      borderWidth: 1,
      borderStyle: 'none',
      borderColor: '#212529',
      borderRadius: 5,
    },
    background: {
      backgroundColor: '#ffffff',
      backgroundTypeImage: false,
      backgroundImageUrl: '',
      backgroundOpacity: 1,
    },
    preferences: {
      hiddenFor: [],
    },
    shadowValues: {
      hShadow: 0,
      vShadow: 0,
      shadowColor: '#212529',
      shadowBlur: 0,
    },
    hover: {
      hoverBackground: 'transparent',
      hoverBorderRadius: 0,
      hoverBorderStyle: 'none',
      hoverBorderColor: 'transparent',
      hoverBorderWidth: 0,
      hoverFontColor: 'black',
      hoverTextDecoration: 'none',
      hoverPointer: null,
    },
    tooltip: '',
    tabindex: 0,
    opacity: 1,
    responsive: {
      desktop: {
        itemsPerPage: 5,
        id: 0,
        cols: 0,
        rows: 0,
        colsQty: 45,
        rowsQty: 45,
        x: 0,
        y: 0,
        layer: 0,
        centerPositioning: false,
        font: {
          fontSize: 16,
          fontColor: '#000000',
          fontFamily: 'inherit',
          fontStyle: 'inherit',
        },
        header: {
          headerTextAlignment: 'center',
          headerHeight: 40,
          headerFontSize: 15,
          headerFontColor: 'black',
          headerFontFamily: 'inherit',
          headerFontStyle: 'normal',
          headerBackgroundColor: 'white',
          stickyHeaders: true,
        },
        body: {
          bodyCenterText: true,
          bodyFontFamily: 'inherit',
          bodyFontStyle: 'normal',
          bodyFontSize: 12,
          bodyHeight: 34,
          bodyFontColor: 'black',
          bodyBackgroundColor: 'white',
          isBodyStriped: false,
          firstBodyStripedColor: '#ffffff',
          secondBodyStripedColor: '#c0c0c0',
          bodyHighlightRowOnHover: true,
          stickyFooters: true,
        },
        chartOptions: {},
        tableOptions: {
          search: true,
          collapse: true,
          bodyRoundCorners: false,
          header: true,
          verticallyCenter: true,
          rowSeparator: true,
          columnSeparator: false,
          rowPadding: true,
          tableOrder: [],
          tableWidth: {},
        },
      },
      smartphone: {
        itemsPerPage: null,
        id: 0,
        cols: 0,
        rows: 0,
        colsQty: 45,
        rowsQty: 45,
        x: 0,
        y: 0,
        layer: 0,
        centerPositioning: false,
        font: {
          fontSize: null,
          fontColor: null,
          fontFamily: null,
          fontStyle: null,
        },
        header: {
          headerHeight: null,
          headerFontSize: null,
          stickyHeaders: null,
          headerFontColor: null,
          headerFontFamily: null,
          headerFontStyle: null,
          headerBackgroundColor: null,
        },
        body: {
          bodyFontSize: null,
          bodyHeight: null,
          
          bodyFontStyle: null,
          bodyFontFamily: null,
          bodyFontColor: null,
          bodyBackgroundColor: null,
          isBodyStriped: null,
          firstBodyStripedColor: null,
          secondBodyStripedColor: null,
          bodyHighlightRowOnHover: null,
          stickyFooters: null,
        },
        chartOptions: {},
        tableOptions: {
          search: true,
          header: true,
          collapse: true,
          bodyRoundCorners: false,
          verticallyCenter: true,
          rowSeparator: null,
          columnSeparator: null,
          rowPadding: null,
          tableOrder: [],
          tableWidth: {},
        },
      },
    },
  };
  private toolsService: ToolsService;
  private busService: BusService;
  private communicationService: CommunicationService;
  private windowStoreService: WindowStoreService;
  private templateService: TemplateService;
  private processorService: ProcessorService;
  private angularZone: NgZone;
  private cobbleService: CobbleService;
  private factoryParticleService: FactoryParticleService;
  private datasourcesService: ApiDataSourcesService;
  private editorStateService: EditorStateService;
  private propertiesService: ApiPropertiesService;
  private molecularEngineConnectorService: MolecularEngineConnectorService;
  private localStorageService: LocalStorageService;
  private thematicService: ThematicService;
  private snackerService: SnackerService;
  private oldValueSet = false;
  private inDebounce = null;
  private subscriptions = new Subscription();
  
  constructor(molecule: any = {}, runningMode = false) {
    runningMode = window.location.href.includes('/run/');
    
    this.InjectServices();
    this.Id = molecule.id || molecule.Id || 0;
    this.Debug = this.localStorageService.IsDebug();
    this.ContextMenu = null;
    this.DragOver = false;
    this.Renderized = false;
    this.EditorVisible = true;
    this.PasswordMasking = false;
    this.InputReceptorProcessed = null;
    this.BadgeValue = null;
    this.BackendProcess = 0;
    this.Initialized = false;
    this.RowDataElements = [];
    this.CriticBackendProcess = false;
    this.RuntimeData = {};
    this.DisplayTabOrder = false;
    this.Events = molecule.Events || molecule.events || [];
    this.Placeholders = molecule.placeholders || molecule.Placeholders || [];
    this.Contexts = molecule.Contexts || molecule.contexts || [];
    this.DefaultEvent = molecule.DefaultEvent || molecule.defaultEvent || 'init';
    this.StyleMetadata = molecule.styleMetadata ||
      molecule.StyleMetadata || {
        styles: [],
        manualAdjustedProperties: [],
        specificStyles: {
          dimension: null,
          font: null,
          hover: null,
          frame: null,
          appearance: null,
        },
      };
    this.ParentId = molecule.parentId || molecule.ParentId || 0;
    this.OldValue = null;
    this.TemplateVersion = molecule.templateVersion || molecule.TemplateVersion || 0;
    this.TemplateOwnerId = molecule.templateOwnerId || molecule.TemplateOwnerId || 0;
    this.TemplateId = molecule.templateId || molecule.TemplateId || 0;
    this.Required = molecule.required || molecule.Required;
    this.Touched = molecule.touched || molecule.Touched;
    this.PageNumber = molecule.pageNumber || molecule.PageNumber || 1;
    this.PageChanged = false;
    this.SubParentId = molecule.subParentId || molecule.SubParentId || 0;
    this.SubParentContext = molecule.SubParentContext || molecule.subParentContext || molecule.subparentContext || molecule.subparentcontext || '';
    this.Versioning = molecule.versioning || molecule.Versioning || {};
    this.Type = molecule.type || molecule.Type || '';
    this.EnableDragBetweenWG = !(this.Type === RepresentativeMoleculesType.WorkGroup || this.Type === RepresentativeMoleculesType.Stepper);
    const buses: [] = molecule.buses || molecule.Buses || [];
    buses.forEach(b => {
      const newBus = new Bus(b, this.Id);
      if (newBus.Enabled || !runningMode) {
        this.Buses.push(newBus);
      }
    });
    this.Receptors = molecule.receptors || molecule.Receptors || [];
    this.Children = molecule.children || molecule.Children || [];
    this.SearchFilter = molecule.SearchFilter || molecule.searchFilter || '';
    this.AutoGenerationInfo = molecule.autoGenerationInfo || molecule.AutoGenerationInfo || [{ translation: null }];
    this.EditableProperties = molecule.editableProperties || molecule.EditableProperties || [];
    this.RunningMode = runningMode;
    this.PreviewMode = window.location.href.includes('/run/preview/');
    this.PopulatedData = null;
    this.Debounce = molecule.Debounce || molecule.debounce || { events: [] };
    this.MoleculeType = molecule.moleculeType || molecule.MoleculeType || '';
    this.CreationFromTemplate = molecule.creationFromTemplate || molecule.CreationFromTemplate || false;
    this.TemplateKeepDatasources = molecule.templateKeepDatasources === true || molecule.TemplateKeepDatasources === true;
    this.MoleculeSubType = molecule.moleculeSubType || molecule.MoleculeSubType || '';
    this.Value = molecule.value || molecule.Value || null;
    this.Icon = molecule.icon || molecule.Icon || '';
    this.ReplaceableByRepresentative = molecule.replaceableByRepresentative || molecule.ReplaceableByRepresentative || false;
    this.MoleculesAllow = molecule.moleculesAllow ||
      molecule.MoleculesAllow || ['Behavior', 'DataAction', 'Compounds', 'Cobblet', 'Configuration',
        'Representative'];
    this.Loading = molecule.loading || molecule.Loading || false;
    this.OptionsMenu = molecule.optionsMenu ||
      molecule.OptionsMenu || {
        configuration: true,
        molecules: true,
        compound: true,
        cobblet: false,
        remove: true,
        versioning: true,
        dataSource: true,
        duplicate: true,
      };
    this.IsSelected = molecule.isSelected || molecule.IsSelected || false;
    this.GridsterConfig =
      molecule.type === RepresentativeMoleculesType.Table || molecule.type === RepresentativeMoleculesType.WorkGroup
        ? molecule.gridsterConfig ||
        molecule.GridsterConfig || {
          gridType: GridType.Fit,
          compactType: CompactType.None,
          margin: 0,
          outerMargin: true,
          outerMarginTop: null,
          outerMarginRight: null,
          outerMarginBottom: null,
          outerMarginLeft: null,
          useTransformPositioning: true,
          mobileBreakpoint: 0,
          minCols: 1,
          maxCols: 1,
          minRows: 1,
          maxRows: 1,
          maxItemCols: 1,
          minItemCols: 1,
          maxItemRows: 1,
          minItemRows: 1,
          maxItemArea: 2500,
          minItemArea: 1,
          defaultItemCols: 1,
          defaultItemRows: 1,
          fixedColWidth: 105,
          fixedRowHeight: 105,
          keepFixedHeightInMobile: false,
          keepFixedWidthInMobile: false,
          scrollSensitivity: 10,
          scrollSpeed: 20,
          enableEmptyCellClick: false,
          enableEmptyCellContextMenu: false,
          enableEmptyCellDrag: true,
          emptyCellDragMaxCols: 50,
          emptyCellDragMaxRows: 50,
          ignoreMarginInRow: false,
          draggable: {
            enabled: false,
          },
          resizable: {
            enabled: true,
          },
          swap: false,
          pushItems: true,
          disablePushOnDrag: false,
          disablePushOnResize: false,
          pushDirections: { north: true, east: true, south: true, west: true },
          pushResizeItems: false,
          displayGrid: DisplayGrid.None,
          disableWindowResize: false,
          disableWarnings: false,
          scrollToNewItems: false,
        }
        : null;
    this.Properties = molecule.properties ||
      molecule.Properties || {
        id: 0,
        name: '',
        horizontalPadding: 0,
        verticalPadding: 0,
        show: true,
        headerText: '',
        altText: '',
        dateRange: false,
        search: false,
        multiSelect: false,
        autoDataRefresh: false,
        minValue: null,
        maxValue: null,
        enable: true,
        maskType: 'none',
        mask: '',
        showAltText: true,
        allowManualEntry: false,
        squareCorners: true,
        textToDisplay: '',
        loadingText: '',
        chartType: 'line',
        icon: {
          iconType: 'stars',
          iconColor: 'black',
          iconSize: 45,
        },
        chartShowLines: true,
        source: '',
        textDecoration: 'none',
        allowEmptyValues: false,
        chartLegend: true,
        orientation: 'col',
        location: null,
        chartScales: true,
        lockWidth: false,
        offsetRows: 0,
        offsetCols: 0,
        stepper: {
          stepperLabelPosition: 'end',
          stepperSequential: false,
          stepperScrollbars: false,
          stepperOrientation: 'horizontal',
        },
        lockHeight: false,
        lockImageSize: false,
        originalQuality: false,
        originalSize: false,
        cssFilter: 'none',
        selectFirstOption: true,
        skipFirstOption: false,
        chartLegacy: true,
        chartLabels: true,
        chartOrientation: 'top',
        borders: true,
        options: [],
        dragEnabled: true,
        filter: true,
        ignoreValueDataIndex: false,
        maxIncrement: 10,
        increment: 5,
        maxLimit: 10,
        limit: 5,
        maxEmptyCellsLimit: 2,
        emptyCellsLimit: 1,
        resizeEnabled: true,
        inOutAnimation: 'slideUp',
        sliderVertical: false,
        sliderStep: 1,
        sliderThumbLabel: false,
        sliderTicks: false,
        progressType: 'progress',
        progressColor: '#EA5C2F',
        progressBackgroundColor: '#E5957C',
        progressDiameter: 50,
        progressMode: 'indeterminate',
        progressStrokeWidth: 5,
        bordersValues: {
          borderWidth: 1,
          borderStyle: 'none',
          borderColor: '#212529',
          borderRadius: 5,
        },
        background: {
          backgroundColor: '#ffffff',
          backgroundTypeImage: false,
          backgroundImageUrl: '',
          backgroundOpacity: 1,
        },
        preferences: {
          hiddenFor: [],
        },
        shadowValues: {
          hShadow: 0,
          vShadow: 0,
          shadowColor: '#212529',
          shadowBlur: 0,
        },
        hover: {
          hoverBackground: 'transparent',
          hoverBorderRadius: 0,
          hoverBorderStyle: 'none',
          hoverBorderColor: 'transparent',
          hoverBorderWidth: 0,
          hoverFontColor: 'black',
          hoverTextDecoration: 'none',
          hoverPointer: null,
        },
        tooltip: '',
        tabindex: 0,
        opacity: 1,
        responsive: {
          desktop: {
            itemsPerPage: 5,
            id: 0,
            cols: 0,
            rows: 0,
            colsQty: 45,
            rowsQty: 45,
            x: 0,
            y: 0,
            layer: 0,
            centerPositioning: false,
            font: {
              fontSize: 16,
              fontColor: '#000000',
              fontFamily: 'inherit',
              fontStyle: 'inherit',
            },
            header: {
              headerTextAlignment: 'center',
              headerHeight: 40,
              headerFontSize: 15,
              headerFontColor: 'black',
              headerFontFamily: 'inherit',
              headerFontStyle: 'normal',
              headerBackgroundColor: 'white',
              stickyHeaders: true,
            },
            body: {
              bodyCenterText: true,
              bodyFontFamily: 'inherit',
              bodyFontStyle: 'normal',
              bodyFontSize: 12,
              bodyHeight: 34,
              bodyFontColor: 'black',
              bodyBackgroundColor: 'white',
              isBodyStriped: false,
              firstBodyStripedColor: '#ffffff',
              secondBodyStripedColor: '#c0c0c0',
              bodyHighlightRowOnHover: true,
              stickyFooters: true,
            },
            chartOptions: {},
            tableOptions: {
              search: true,
              collapse: true,
              bodyRoundCorners: false,
              header: true,
              verticallyCenter: true,
              rowSeparator: true,
              columnSeparator: false,
              rowPadding: true,
              tableOrder: [],
              tableWidth: {},
            },
          },
          smartphone: {
            itemsPerPage: null,
            id: 0,
            cols: 0,
            rows: 0,
            colsQty: 45,
            rowsQty: 45,
            x: 0,
            y: 0,
            layer: 0,
            centerPositioning: false,
            font: {
              fontSize: null,
              fontColor: null,
              fontFamily: null,
              fontStyle: null,
            },
            header: {
              headerHeight: null,
              headerFontSize: null,
              stickyHeaders: null,
              headerFontColor: null,
              headerFontFamily: null,
              headerFontStyle: null,
              headerBackgroundColor: null,
            },
            body: {
              bodyFontSize: null,
              bodyHeight: null,
              
              bodyFontStyle: null,
              bodyFontFamily: null,
              bodyFontColor: null,
              bodyBackgroundColor: null,
              isBodyStriped: null,
              firstBodyStripedColor: null,
              secondBodyStripedColor: null,
              bodyHighlightRowOnHover: null,
              stickyFooters: null,
            },
            chartOptions: {},
            tableOptions: {
              search: true,
              header: true,
              collapse: true,
              bodyRoundCorners: false,
              verticallyCenter: true,
              rowSeparator: null,
              columnSeparator: null,
              rowPadding: null,
              tableOrder: [],
              tableWidth: {},
            },
          },
        },
      };
    
    this.GridsterConfig =
      molecule.type === RepresentativeMoleculesType.Table || molecule.type === RepresentativeMoleculesType.WorkGroup
        ? molecule.gridsterConfig ||
        molecule.GridsterConfig || {
          gridType: GridType.Fit,
          compactType: CompactType.None,
          margin: 0,
          outerMargin: true,
          outerMarginTop: null,
          outerMarginRight: null,
          outerMarginBottom: null,
          outerMarginLeft: null,
          useTransformPositioning: true,
          mobileBreakpoint: 0,
          minCols: 1,
          maxCols: 1,
          minRows: 1,
          maxRows: 1,
          maxItemCols: 1,
          minItemCols: 1,
          maxItemRows: 1,
          minItemRows: 1,
          maxItemArea: 2500,
          minItemArea: 1,
          defaultItemCols: 1,
          defaultItemRows: 1,
          fixedColWidth: 105,
          fixedRowHeight: 105,
          keepFixedHeightInMobile: false,
          keepFixedWidthInMobile: false,
          scrollSensitivity: 10,
          scrollSpeed: 20,
          enableEmptyCellClick: false,
          enableEmptyCellContextMenu: false,
          enableEmptyCellDrag: true,
          emptyCellDragMaxCols: 50,
          emptyCellDragMaxRows: 50,
          ignoreMarginInRow: false,
          draggable: {
            enabled: false,
          },
          resizable: {
            enabled: true,
          },
          swap: false,
          pushItems: true,
          disablePushOnDrag: false,
          disablePushOnResize: false,
          pushDirections: { north: true, east: true, south: true, west: true },
          pushResizeItems: false,
          displayGrid: DisplayGrid.None,
          disableWindowResize: false,
          disableWarnings: false,
          scrollToNewItems: false,
        }
        : null;
    
    if (molecule.properties) {
      this.Properties = molecule.properties;
    } else {
      (this.Properties as any) = this.defaultPropertiesValues;
    }
    
    // in case property does not exist on object
    Object.keys(this.defaultPropertiesValues)
    .forEach(key => {
      if (this.Properties[key] !== null && this.Properties[key] !== undefined) {
        this.Properties[key] = this.Properties[key];
      } else {
        this.Properties[key] = this.defaultPropertiesValues[key];
      }
    });
    //
    
    if (this.Type !== MoleculesType.Cobble) {
      this.Properties.id = this.Properties.responsive.desktop.id = this.Properties.responsive.smartphone.id = molecule.id || molecule.Id || 0;
      
      this.Properties.responsive.smartphone.colsQty = this.Properties.responsive.smartphone.colsQty || this.Properties.responsive.desktop.colsQty;
      
      this.Properties.responsive.smartphone.rowsQty = this.Properties.responsive.smartphone.rowsQty || this.Properties.responsive.desktop.rowsQty;
      
      this.Properties.responsive.smartphone.cols = this.Properties.responsive.smartphone.cols || this.Properties.responsive.desktop.cols;
      
      this.Properties.responsive.smartphone.rows = this.Properties.responsive.smartphone.rows || this.Properties.responsive.desktop.rows;
      
      if (this.Properties.responsive.smartphone.x === null) {
        this.Properties.responsive.smartphone.x = this.Properties.responsive.desktop.x;
      }
      
      if (this.Properties.responsive.smartphone.y === null) {
        this.Properties.responsive.smartphone.y = this.Properties.responsive.desktop.y;
      }
      
      this.Properties.responsive.smartphone.layer = this.Properties.responsive.smartphone.layer || this.Properties.responsive.desktop.layer;
      
      if (this.Properties.responsive.smartphone.font) {
        this.Properties.responsive.smartphone.font.fontSize =
          this.Properties.responsive.smartphone.font.fontSize || this.Properties.responsive.desktop.font.fontSize;
        
        this.Properties.responsive.smartphone.font.fontColor =
          this.Properties.responsive.smartphone.font.fontColor || this.Properties.responsive.desktop.font.fontColor;
        
        this.Properties.responsive.smartphone.font.fontFamily =
          this.Properties.responsive.smartphone.font.fontFamily || this.Properties.responsive.desktop.font.fontFamily;
        
        this.Properties.responsive.smartphone.font.fontStyle =
          this.Properties.responsive.smartphone.font.fontStyle || this.Properties.responsive.desktop.font.fontStyle;
      } else {
        this.Properties.responsive.smartphone.font = this.Properties.responsive.desktop.font;
      }
      
      this.OriginalPosition = {
        desktop: {
          cols: this.Properties.responsive.desktop.cols,
          rows: this.Properties.responsive.desktop.rows,
          x: this.Properties.responsive.desktop.x,
          y: this.Properties.responsive.desktop.y,
        },
        smartphone: {
          cols: this.Properties.responsive.smartphone.cols,
          rows: this.Properties.responsive.smartphone.rows,
          x: this.Properties.responsive.smartphone.x,
          y: this.Properties.responsive.smartphone.y,
        },
      };
      
      // Setting maximum size value for table head children
      if (this.SubParentId && this.SubParentId > 0) {
        if (this.Properties.responsive.smartphone.cols > 15) {
          this.Properties.responsive.smartphone.cols = 15;
        }
        
        if (this.Properties.responsive.smartphone.rows > 15) {
          this.Properties.responsive.smartphone.rows = 15;
        }
        
        if (this.Properties.responsive.desktop.cols > 15) {
          this.Properties.responsive.desktop.cols = 15;
        }
        
        if (this.Properties.responsive.desktop.rows > 15) {
          this.Properties.responsive.desktop.rows = 15;
        }
      }
      //
    }
    
    if (this.Type === RepresentativeMoleculesType.Table) {
      if (this.Properties.responsive.smartphone.tableOptions) {
      } else {
        this.Properties.responsive.smartphone.tableOptions = tableTemplate[0].properties.responsive.smartphone.tableOptions as any;
      }
      
      if (this.Properties.responsive.desktop.tableOptions) {
      } else {
        this.Properties.responsive.desktop.tableOptions = tableTemplate[0].properties.responsive.desktop.tableOptions as any;
      }
      
      if (this.Properties.responsive.smartphone.header) {
      } else {
        this.Properties.responsive.smartphone.header = tableTemplate[0].properties.responsive.smartphone.header as any;
      }
      
      if (this.Properties.responsive.desktop.header) {
      } else {
        this.Properties.responsive.desktop.header = tableTemplate[0].properties.responsive.desktop.header as any;
      }
      
      if (this.Properties.responsive.smartphone.body) {
      } else {
        this.Properties.responsive.smartphone.body = tableTemplate[0].properties.responsive.smartphone.body as any;
      }
      
      if (this.Properties.responsive.desktop.body) {
      } else {
        this.Properties.responsive.desktop.body = tableTemplate[0].properties.responsive.desktop.body as any;
      }
      
      if (this.Properties.responsive.smartphone.tableOptions.search === null) {
        this.Properties.responsive.smartphone.tableOptions.search = this.Properties.responsive.desktop.tableOptions.search;
      }
      
      if (this.Properties.responsive.smartphone.tableOptions.header === null) {
        this.Properties.responsive.smartphone.tableOptions.header = this.Properties.responsive.desktop.tableOptions.header;
      }
      
      if (this.Properties.responsive.smartphone.tableOptions.verticallyCenter === null) {
        this.Properties.responsive.smartphone.tableOptions.verticallyCenter = this.Properties.responsive.desktop.tableOptions.verticallyCenter;
      }
      
      if (this.Properties.responsive.smartphone.tableOptions.rowSeparator === null) {
        this.Properties.responsive.smartphone.tableOptions.rowSeparator = this.Properties.responsive.desktop.tableOptions.rowSeparator;
      }
      
      if (this.Properties.responsive.smartphone.tableOptions.columnSeparator === null) {
        this.Properties.responsive.smartphone.tableOptions.columnSeparator = this.Properties.responsive.desktop.tableOptions.columnSeparator;
      }
      
      this.Properties.responsive.smartphone.tableOptions.tableOrder =
        this.Properties.responsive.smartphone.tableOptions.tableOrder || this.Properties.responsive.desktop.tableOptions.tableOrder;
      
      if (
        Object.entries(this.Properties.responsive.smartphone.tableOptions.tableWidth).length === 0 &&
        this.Properties.responsive.smartphone.tableOptions.tableWidth.constructor === Object
      ) {
        this.Properties.responsive.smartphone.tableOptions.tableWidth = this.Properties.responsive.desktop.tableOptions.tableWidth;
      }
      
      this.Properties.responsive.smartphone.itemsPerPage =
        this.Properties.responsive.smartphone.itemsPerPage || this.Properties.responsive.desktop.itemsPerPage;
      
      this.Properties.responsive.smartphone.header.headerHeight =
        this.Properties.responsive.smartphone.header.headerHeight || this.Properties.responsive.desktop.header.headerHeight;
      
      this.Properties.responsive.smartphone.header.headerFontSize =
        this.Properties.responsive.smartphone.header.headerFontSize || this.Properties.responsive.desktop.header.headerFontSize;
      
      this.Properties.responsive.smartphone.body.bodyFontSize =
        this.Properties.responsive.smartphone.body.bodyFontSize || this.Properties.responsive.desktop.body.bodyFontSize;
      
      this.Properties.responsive.smartphone.body.bodyHeight =
        this.Properties.responsive.smartphone.body.bodyHeight || this.Properties.responsive.desktop.body.bodyHeight;
      
      // KEEPING THE SAME AS DESKTOP
      // this.Properties.responsive.smartphone.header.headerFontColor = this.Properties.responsive.desktop.header.headerFontColor;
      //
      // this.Properties.responsive.smartphone.header.headerFontFamily = this.Properties.responsive.desktop.header.headerFontFamily;
      //
      // this.Properties.responsive.smartphone.header.headerFontStyle = this.Properties.responsive.desktop.header.headerFontStyle;
      //
      // this.Properties.responsive.smartphone.header.headerBackgroundColor = this.Properties.responsive.desktop.header.headerBackgroundColor;
      //
      // this.Properties.responsive.smartphone.body.bodyFontStyle = this.Properties.responsive.desktop.body.bodyFontStyle;
      //
      // this.Properties.responsive.smartphone.body.bodyFontFamily = this.Properties.responsive.desktop.body.bodyFontFamily;
      //
      // this.Properties.responsive.smartphone.body.bodyFontColor = this.Properties.responsive.desktop.body.bodyFontColor;
      //
      // this.Properties.responsive.smartphone.body.bodyBackgroundColor = this.Properties.responsive.desktop.body.bodyBackgroundColor;
      //
      // this.Properties.responsive.smartphone.body.isBodyStriped = this.Properties.responsive.desktop.body.isBodyStriped;
      //
      // this.Properties.responsive.smartphone.body.firstBodyStripedColor = this.Properties.responsive.desktop.body.firstBodyStripedColor;
      //
      // this.Properties.responsive.smartphone.body.secondBodyStripedColor = this.Properties.responsive.desktop.body.secondBodyStripedColor;
      //
    }
    
    if (this.Type === RepresentativeMoleculesType.Chart) {
      for (const chartOptionsKey in this.Properties.responsive.smartphone.chartOptions) {
        this.Properties.responsive.smartphone.chartOptions[chartOptionsKey] =
          this.Properties.responsive.smartphone.chartOptions[chartOptionsKey] === null
            ? this.Properties.responsive.desktop.chartOptions[chartOptionsKey]
            : this.Properties.responsive.smartphone.chartOptions[chartOptionsKey];
      }
    }
    
    this.InitValue = this.GetValue;
    this.RefreshDatasourceConnected();
    // console.log('loading');
    
    this.subscriptions.add(
      this.communicationService.Event.Runtime.System.$DataSourceCRUDUpdate.subscribe(data => {
        console.log('=event=');
        if (this.Renderized) {
          return;
        }
        
        if (this.Type !== RepresentativeMoleculesType.Custom && !!data.find(
          d => this.ContainsDataElementsWithContexts((d as any).contexts))) {
          // to avoid loop with rep molecules sending data and receiving data
          let existsDataModification = false;
          const initBuses = this.GetBusesByEventType('init');
          
          initBuses.forEach(initBus => {
            const initBusEvents = initBus.GetEvents();
            // initBusEvents.shift();
            const busesListening: Bus[] = [];
            
            initBusEvents.forEach(ibe => {
              busesListening.push(this.GetBusInitiatedByEventName(ibe.EventName));
            });
            
            const updatedContexts = (data.map(d => d.contexts) as any).flat();
            
            //
            // const contextAffected = [];
            //
            // updatedContexts.forEach(uc => {
            //   let context = uc.split(Constants.ContextSeparator);
            //   context.pop();
            //   context = context.join(Constants.ContextSeparator);
            //
            //   if (contextAffected.find(ca => ca.includes(context))) {
            //   } else {
            //     contextAffected.push(context);
            //   }
            // });
            
            busesListening.forEach(bl => {
              if (
                bl &&
                (bl.GetActionMoleculeParticle('AddToDatasourceMolecule') ||
                  bl.GetActionMoleculeParticle('UpdateDatasourceDataMolecule') ||
                  bl.GetActionMoleculeParticle('DeleteDatasourceDataMolecule'))
              ) {
                updatedContexts.forEach(context => {
                  if (initBus.ContainsSpecificContext(context)) {
                    existsDataModification = true;
                  }
                });
              }
            });
          });
          //
          
          // to avoid loop
          if (!existsDataModification) {
            // console.log('$DataSourceCRUDUpdate');
            this.UnsetOldValue();
            this.DatasourceUpdated = true;
          }
        }
      }),
    );
    
    // region STYLES
    this.angularZone.runOutsideAngular(() => {
      if (!this.RunningMode || this.PreviewMode || this.cobbleService.Cobble.onEmulator) {
        console.log('init molecule styles');
        
        // only in editor
        if (!this.RunningMode) {
          this.BackupProperties();
          this.subscriptions.add(
            this.communicationService.Event.Editor.WorkArea.$RefreshStyles.subscribe(styleId => {
              if (styleId && styleId !== null) {
                if (this.StyleMetadata.styles.includes(styleId)) {
                  this.ApplyAssignedStyle();
                }
              } else {
                this.ApplyAssignedStyle();
              }
            }),
          );
        }
        this.ApplyAssignedStyle(false);
      }
    });
    // endregion
  }
  
  public get GetValue() {
    // console.log('getting value');
    // console.log(this.Id);
    let value = null;
    
    switch (this.Type) {
      case RepresentativeMoleculesType.H1:
      case RepresentativeMoleculesType.H2:
      case RepresentativeMoleculesType.H3:
      case RepresentativeMoleculesType.H4:
      case RepresentativeMoleculesType.H5:
      case RepresentativeMoleculesType.Button:
      case RepresentativeMoleculesType.Badge:
      case RepresentativeMoleculesType.Label:
        value = this.GetTextValue;
        break;
      case RepresentativeMoleculesType.Checkbox:
        value = this.Value === true ? 'Y' : 'N';
        break;
      case RepresentativeMoleculesType.Datepicker:
        this.Value = this.Value || '';
        value = this.Value.toString();
        break;
      case RepresentativeMoleculesType.Breadcrumb:
        this.Value = this.Value || '';
        value = this.Value;
        break;
      case RepresentativeMoleculesType.Radio:
      case RepresentativeMoleculesType.Dropdown:
        if (this.Value) {
          value = this.Value.toString();
        } else {
          value = this.Value;
        }
        break;
      case RepresentativeMoleculesType.Image:
        value = this.Value || this.Properties.source;
        break;
      case RepresentativeMoleculesType.Textbox:
      case RepresentativeMoleculesType.Textarea:
      case RepresentativeMoleculesType.QrCode:
        value = this.Value || null;
        break;
      default:
        value = this.Value;
        break;
    }
    return value;
  }
  
  public get GetTextValue() {
    let value = null;
    
    switch (this.Type) {
      case RepresentativeMoleculesType.H1:
      case RepresentativeMoleculesType.H2:
      case RepresentativeMoleculesType.H3:
      case RepresentativeMoleculesType.H4:
      case RepresentativeMoleculesType.H5:
      case RepresentativeMoleculesType.Button:
      case RepresentativeMoleculesType.Badge:
      case RepresentativeMoleculesType.Label:
      case RepresentativeMoleculesType.Checkbox:
        value = this.Properties.textToDisplay;
        break;
      default:
        value = this.Properties.textToDisplay;
        break;
    }
    return cloneDeep(value);
  }
  
  public get GetValueOptions() {
    let value = null;
    
    switch (this.Type) {
      default:
        value = this.ValueOptions;
        break;
    }
    return value;
  }
  
  public SetInitValue() {
    switch (this.Type) {
      case RepresentativeMoleculesType.Textbox:
      case RepresentativeMoleculesType.Textarea:
        this.Value = null;
        break;
      case RepresentativeMoleculesType.Button:
      case RepresentativeMoleculesType.Badge:
      case RepresentativeMoleculesType.Breadcrumb:
        break;
      case RepresentativeMoleculesType.Table:
        break;
      default:
        this.SetValue(this.InitValue);
        break;
    }
  }
  
  public ClearValue() {
    switch (this.Type) {
      case RepresentativeMoleculesType.Radio:
        this.Value = null;
        break;
      case RepresentativeMoleculesType.Dropdown:
      case RepresentativeMoleculesType.Table:
        this.SetValue(null);
        break;
      default:
        this.SetValue('');
        break;
    }
  }
  
  public SetValue(value: any, bus?: Bus) {
    const originalValue = value;
    // console.log('molecule', this.Id);
    
    // console.log('set value', value);
    
    const valueByType = {};
    
    console.log('type', this.toolsService.GetObjectType(value));
    
    switch (this.toolsService.GetObjectType(value)) {
      case 'filelist':
        this.Value = value[0].name;
        valueByType['string'] = this.Value;
        break;
      case 'string':
        this.Value = value;
        valueByType['string'] = this.Value;
        valueByType['array'] = [
          {
            row: 0,
            col: 0,
            context: '',
            value: value,
            formattedValue: value,
          },
        ];
        break;
      case 'number':
        this.Value = value;
        valueByType['number'] = +this.Value;
        valueByType['array'] = [
          {
            row: 0,
            col: 0,
            context: '',
            value: value,
            formattedValue: value,
          },
        ];
        break;
      case 'object':
        this.DataKey = value.dataKey || null;
        // this.Value = value.formattedValue || value.value || '';
        const preValue = value.formattedValue || value.value || '';
        valueByType['object'] = preValue;
        valueByType['string'] = preValue.toString();
        valueByType['array'] = [value];
        break;
      case 'array':
        valueByType['array'] = value;
        value = value[0];
        // console.log('array type', this.toolsService.GetObjectType(value));
        
        switch (this.toolsService.GetObjectType(value)) {
          case 'string':
            this.Value = value;
            valueByType['string'] = this.Value;
            break;
          case 'number':
            this.Value = +value;
            valueByType['number'] = this.Value;
            
            break;
          case 'object':
            // console.log('value.value', value.value);
            
            valueByType['object'] = this.Value;
            valueByType['string'] =
              value.value === null || value.value === undefined ? value.name || '' : value.formattedValue || value.value.toString();
            this.DataKey = value.dataKey || null;
            break;
          
          default:
            this.Value = '';
            break;
        }
        break;
      default:
        this.Value = '';
        break;
    }
    
    console.log('values', valueByType);
    
    switch (this.Type) {
      case RepresentativeMoleculesType.H1:
      case RepresentativeMoleculesType.H2:
      case RepresentativeMoleculesType.H3:
      case RepresentativeMoleculesType.H4:
      case RepresentativeMoleculesType.H5:
      case RepresentativeMoleculesType.Button:
      case RepresentativeMoleculesType.Badge:
      case RepresentativeMoleculesType.Textbox:
      case RepresentativeMoleculesType.QrCode:
      case RepresentativeMoleculesType.Textarea:
      case RepresentativeMoleculesType.Label:
        this.Value = valueByType['string'] || valueByType['number'];
        this.Value = this.Value === null || this.Value === undefined ? '' : this.Value.toString();
        this.SetTextValue(value);
        this.$UpdateValue.emit(true);
        break;
      case RepresentativeMoleculesType.Icon:
        this.Value = valueByType['string'] || valueByType['number'];
        this.Value = this.Value === null || this.Value === undefined ? '' : this.Value.toString();
        if (this.Value && this.Value !== '') {
          this.Properties.icon.iconType = this.Value;
        }
        this.$UpdateValue.emit(true);
        break;
      case RepresentativeMoleculesType.Iframe:
        this.Value = null;
        setTimeout(() => {
          this.Value = valueByType['string'] || valueByType['number'];
          this.Value = this.Value === null || this.Value === undefined ? '' : this.Value.toString();
          this.SetTextValue(value);
          this.$UpdateValue.emit(true);
        }, 100);
        break;
      case RepresentativeMoleculesType.Breadcrumb:
        break;
      case RepresentativeMoleculesType.Table:
      case RepresentativeMoleculesType.Stepper:
        this.LastSelected = null;
        const tableValue = valueByType['array'] || [];
        this.DataKey = tableValue.length > 0 ? tableValue[0].dataKey || this.DataKey : this.DataKey;
        
        let paginateMolecules = [];
        
        const originalBus = bus ? this.GetBusByClone(bus.ClonedFromId) || this.GetBus(bus.id) : null;
        if (originalBus) {
          const paginateMolecule = originalBus.GetActionMoleculeParticle('DatasourcePaginateDataMolecule');
          
          if (paginateMolecule) {
            paginateMolecules.push(paginateMolecule);
          }
        } else {
          paginateMolecules = this.GetMolecules('DatasourcePaginateDataMolecule');
        }
        
        if (paginateMolecules.length > 0) {
          const pageSize = +paginateMolecules[0].Rule.paginate || 999999;
          const rows = this.toolsService.DatasourceDataByRows(tableValue);
          
          if (rows.length > pageSize) {
            if (this.ValueMetaData) {
              this.ValueMetaData.PageSize = pageSize;
            }
            this.GetPaginatedData()
            .subscribe(data => {
              if (data) {
                this.SetValueMetaData(data.metaData, data.dataKey);
                
                const valueBus = bus ? this.GetBus(bus.ClonedFromId) : this.GetBusByReceptor(
                  Receptor.ValueInput);
                const datasourceMolecule =
                  valueBus.GetActionMoleculeParticle('GetElementsDatasourceDataMolecule') ||
                  valueBus.GetActionMoleculeParticle('FilterByDataElementReferenceMolecule');
                
                if (datasourceMolecule && datasourceMolecule.DataElements[0].DataSourceType !== DatasourceType.LocalDataStore) {
                  this.molecularEngineConnectorService
                  .RunMolecule('FilterByDataElementReferenceMolecule', data.dataElements,
                    datasourceMolecule.DataElements, this.Id)
                  .subscribe(processData => {
                    if (processData.repMoleculeId === this.Id) {
                      this.Value = processData.data;
                      this.ProcessedValue = null;
                      this.$UpdateValue.emit(true);
                      this.communicationService.Event.System.App.$RefreshUI.emit();
                    }
                  });
                } else {
                  this.Value = data.dataElements;
                  this.ProcessedValue = null;
                  this.$UpdateValue.emit(true);
                  this.communicationService.Event.System.App.$RefreshUI.emit();
                }
              } else {
                this.Value = [];
                this.ProcessedValue = null;
              }
              
              // this.$UpdateValue.emit(true);
              this.communicationService.Event.System.App.$RefreshUI.emit();
            });
          } else {
            this.Value = tableValue;
            this.ProcessedValue = null;
            this.$UpdateValue.emit(true);
          }
        } else {
          console.log('set table value no paginate');
          if (this.PageChanged) {
            this.Value = tableValue;
            this.ProcessedValue = null;
          } else {
            if (this.ValueMetaData === null && tableValue.length > 0) {
              const dataValue = tableValue[0];
              const dataKey = dataValue.dataKey;
              this.SetValueMetaData(this.datasourcesService.dataSourceValueMetadataKey[dataKey]);
            }
            
            if (this.ValueMetaData) {
              const rows = this.toolsService.DatasourceDataByRows(tableValue);
              const maxSize = rows.length <= this.ValueMetaData.PageSize ? rows.length - 1 : this.ValueMetaData.PageSize - 1;
              this.Value = tableValue.filter(v => v.row <= rows[maxSize][0].row);
              this.ProcessedValue = null;
            } else {
              this.Value = tableValue;
              this.ProcessedValue = null;
            }
          }
          this.$UpdateValue.emit(true);
        }
        
        if (!this.PageChanged) {
          this.communicationService.Event.Runtime.System.$RemoveOldChildren.emit(this.Id);
        }
        break;
      case RepresentativeMoleculesType.Chart:
        this.Value = valueByType['array'] || [];
        this.$UpdateValue.emit(true);
        break;
      case RepresentativeMoleculesType.Report:
        break;
      case RepresentativeMoleculesType.Radio:
      case RepresentativeMoleculesType.Dropdown:
        const dropdownValue = valueByType['string'] || valueByType['number'] || this.Value || '';
        
        // clear dropdown
        if (originalValue === null) {
          this.Value = null;
          this.$UpdateValue.emit(true);
          break;
        }
        
        if (dropdownValue === '' && !this.Properties.allowEmptyValues) {
          break;
        }
        
        this.Value = dropdownValue.toString();
        
        if (this.Properties.multiSelect && this.Value) {
          const spplitedValue = this.Value.replaceAll(', ', ',')
          .split(',');
          
          spplitedValue.forEach(v => {
            if (!this.ValueOptions.map(vo => vo.value)
            .includes(v)) {
              this.ValueOptions.push({
                row: 0,
                col: 0,
                context: '',
                value: v.toString(),
                formattedValue: v,
              });
            }
          });
        } else {
          if (!this.ValueOptions.map(vo => vo.value)
          .includes(this.Value)) {
            this.ValueOptions.push({
              row: 0,
              col: 0,
              context: '',
              value: this.Value.toString(),
              formattedValue: this.Value,
            });
          }
        }
        
        this.$UpdateValue.emit(true);
        break;
      case RepresentativeMoleculesType.Checkbox:
        this.Value = valueByType['string'] || valueByType['number'] || this.Value || '';
        this.Value = this.Value.toString();
        
        const trueValues = ['Y', 'YES', 'T', 'TRUE', 'true', '1'];
        this.Value = trueValues.includes(this.Value.toUpperCase());
        
        this.$UpdateValue.emit(true);
        break;
      case RepresentativeMoleculesType.Image:
        this.Value = valueByType['string'] || '';
        this.$UpdateValue.emit(true);
        break;
      case RepresentativeMoleculesType.Datepicker:
        this.Value = valueByType['string'] || valueByType['number'];
        this.Value = this.Value === null || this.Value === undefined ? '' : this.Value.toString();
        
        if (!this.toolsService.StringIsDate(this.Value)) {
          this.Value = null;
        }
        
        if (this.Properties.dateRange) {
          this.StartValue = null;
          this.EndValue = null;
        } else {
          if (this.toolsService.StringIsDate(this.Value)) {
            if (this.Value.includes('/') && this.Value.includes('-')) {
              this.Value = this.Value.split('-')[0];
            } else if (this.Value !== '') {
              this.Value = this.toolsService.GetFormattedDate(new Date(this.Value));
            }
          }
        }
        
        if (
          this.Properties.minValue &&
          this.toolsService.StringIsDate(this.Properties.minValue) &&
          new Date(this.Value) < new Date(this.Properties.minValue)
        ) {
          this.Value = this.Properties.minValue;
        }
        
        if (
          this.Properties.maxValue &&
          this.toolsService.StringIsDate(this.Properties.maxValue) &&
          new Date(this.Value) > new Date(this.Properties.maxValue)
        ) {
          this.Value = this.Properties.maxValue;
        }
        
        this.$UpdateValue.emit(true);
        break;
      case RepresentativeMoleculesType.Progress:
        this.Value = value;
        if (this.toolsService.IsNumeric(this.Value) && Number(this.Value) >= 100) {
          this.FireEvent(LeapXLEventType.ProgressCompleted, this.Value);
        }
        break;
      case RepresentativeMoleculesType.Slider:
        this.Value = this.toolsService.ExtractValuesByType(value).number;
        this.$UpdateValue.emit(true);
        break;
      default:
        this.Value = value;
        this.$UpdateValue.emit(true);
        break;
    }
    
    if (!this.oldValueSet) {
      this.OldValue = cloneDeep(this.Value);
      this.oldValueSet = true;
    }
    
    if (this.PageChanged) {
      this.PageChanged = false;
    }
    
    // this.$UpdateValue.emit(true);
    // this.RefreshParent();
    // this.$RefreshParentUI.emit();
    // this.RefreshUI();
    this.communicationService.Event.System.App.$RefreshUI.emit();
  }
  
  public SetTooltipValue(value: any) {
    // console.log('molecule', this.Id);
    
    // console.log('set value', value);
    
    const valueByType = this.toolsService.ExtractValuesByType(value);
    
    // console.log('values', valueByType);
    
    switch (this.Type) {
      case RepresentativeMoleculesType.H1:
      case RepresentativeMoleculesType.H2:
      case RepresentativeMoleculesType.H3:
      case RepresentativeMoleculesType.H4:
      case RepresentativeMoleculesType.H5:
      case RepresentativeMoleculesType.Button:
      case RepresentativeMoleculesType.Badge:
      case RepresentativeMoleculesType.Breadcrumb:
      case RepresentativeMoleculesType.Textbox:
      case RepresentativeMoleculesType.QrCode:
      case RepresentativeMoleculesType.Textarea:
      case RepresentativeMoleculesType.Label:
      case RepresentativeMoleculesType.Table:
      case RepresentativeMoleculesType.Chart:
      case RepresentativeMoleculesType.Report:
      case RepresentativeMoleculesType.Radio:
      case RepresentativeMoleculesType.Dropdown:
      case RepresentativeMoleculesType.Checkbox:
      case RepresentativeMoleculesType.Datepicker:
      case RepresentativeMoleculesType.Image:
        this.Properties.altText = valueByType.string || '';
        break;
      default:
        this.Properties.altText = valueByType.string || '';
        break;
    }
    
    this.$UpdateValue.emit(true);
  }
  
  public SetTextValue(value: any) {
    // avoid on editor
    if (!this.RunningMode) {
      return;
    }
    
    const valueByType = {};
    
    switch (this.toolsService.GetObjectType(value)) {
      case 'string':
        this.Properties.textToDisplay = value;
        valueByType['string'] = value;
        break;
      case 'number':
        this.Properties.textToDisplay = value;
        valueByType['number'] = +this.Value;
        break;
      case 'object':
        this.Properties.textToDisplay = value.formattedValue || value.value || '';
        valueByType['object'] = this.Properties.textToDisplay;
        break;
      case 'array':
        valueByType['array'] = value;
        value = value[0];
        switch (this.toolsService.GetObjectType(value)) {
          case 'string':
            this.Properties.textToDisplay = value;
            valueByType['string'] = this.Properties.textToDisplay;
            break;
          case 'number':
            this.Properties.textToDisplay = value.toString();
            valueByType['number'] = this.Properties.textToDisplay;
            
            break;
          case 'object':
            valueByType['object'] = this.Value;
            valueByType['string'] = value.formattedValue || value.value || '';
            
            break;
          
          default:
            this.Properties.textToDisplay = '';
            break;
        }
        break;
      default:
        this.Properties.textToDisplay = '';
        break;
    }
    
    switch (this.Type) {
      case RepresentativeMoleculesType.H1:
      case RepresentativeMoleculesType.H2:
      case RepresentativeMoleculesType.H3:
      case RepresentativeMoleculesType.H4:
      case RepresentativeMoleculesType.H5:
      case RepresentativeMoleculesType.Button:
      case RepresentativeMoleculesType.Badge:
      case RepresentativeMoleculesType.Textbox:
      case RepresentativeMoleculesType.Textarea:
      case RepresentativeMoleculesType.Label:
        this.Properties.textToDisplay = valueByType['string'] || valueByType['number'] || this.Properties.textToDisplay.toString();
        break;
      case RepresentativeMoleculesType.Table:
      case RepresentativeMoleculesType.Stepper:
        this.Properties.textToDisplay = valueByType['array'] || [];
        break;
      case RepresentativeMoleculesType.Report:
        break;
      case RepresentativeMoleculesType.Dropdown:
        break;
      case RepresentativeMoleculesType.Checkbox:
        this.Properties.textToDisplay = valueByType['string'] || valueByType['number'] || this.Properties.textToDisplay || '';
        break;
      case RepresentativeMoleculesType.Radio:
        break;
      case RepresentativeMoleculesType.Iframe:
        break;
      case RepresentativeMoleculesType.Datepicker:
        break;
      case RepresentativeMoleculesType.Image:
        break;
      
      default:
        this.Properties.textToDisplay = value;
        break;
    }
    
    this.$UpdateTextValue.emit(true);
    setTimeout(() => {
      this.communicationService.Event.System.App.$RefreshUI.emit();
      this.communicationService.Event.Editor.$RepresentativeMoleculeDetection.emit({
        repMoleculeId: this.Id,
        state: true,
      });
      this.communicationService.Event.Editor.$RepresentativeMoleculeDetection.emit({
        repMoleculeId: this.ParentId,
        state: true,
      });
      // this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(this.ParentId);
    }, 1000);
  }
  
  SetLoadingTextValue(value: any) {
    if (!this.RunningMode) {
      return;
    }
    
    const valueByType = {};
    
    switch (this.toolsService.GetObjectType(value)) {
      case 'string':
        this.Properties.textToDisplay = value;
        valueByType['string'] = value;
        break;
      case 'number':
        this.Properties.textToDisplay = value;
        valueByType['number'] = +this.Value;
        break;
      case 'object':
        this.Properties.textToDisplay = value.formattedValue || value.value || '';
        valueByType['object'] = this.Properties.textToDisplay;
        break;
      case 'array':
        valueByType['array'] = value;
        value = value[0];
        switch (this.toolsService.GetObjectType(value)) {
          case 'string':
            this.Properties.textToDisplay = value;
            valueByType['string'] = this.Properties.textToDisplay;
            break;
          case 'number':
            this.Properties.textToDisplay = value.toString();
            valueByType['number'] = this.Properties.textToDisplay;
            
            break;
          case 'object':
            valueByType['object'] = this.Value;
            valueByType['string'] = value.formattedValue || value.value || '';
            
            break;
          
          default:
            this.Properties.textToDisplay = '';
            break;
        }
        break;
      default:
        this.Properties.textToDisplay = '';
        break;
    }
    
    this.Properties.loadingText = valueByType['string'] || '';
  }
  
  public SetValueOptions(values: any) {
    this.ValueOptions = [];
    
    const valueType = this.toolsService.GetObjectType(values);
    
    switch (this.Type) {
      case RepresentativeMoleculesType.H1:
      case RepresentativeMoleculesType.H2:
      case RepresentativeMoleculesType.H3:
      case RepresentativeMoleculesType.H4:
      case RepresentativeMoleculesType.H5:
      case RepresentativeMoleculesType.Button:
      case RepresentativeMoleculesType.Badge:
      case RepresentativeMoleculesType.Textbox:
      case RepresentativeMoleculesType.QrCode:
      case RepresentativeMoleculesType.Textarea:
      case RepresentativeMoleculesType.Label:
      case RepresentativeMoleculesType.Dropdown:
      case RepresentativeMoleculesType.Checkbox:
      case RepresentativeMoleculesType.Radio:
        switch (valueType) {
          case 'string':
          case 'number':
            if (this.Properties.multiSelect) {
              const spplitedValue = values.replaceAll(', ', ',')
              .split(',');
              
              const valueOptions = [];
              spplitedValue.forEach(v => {
                valueOptions.push({
                  value: v.toString(),
                });
              });
              
              this.ValueOptions = valueOptions;
            } else {
              this.ValueOptions = [
                {
                  value: values,
                },
              ];
            }
            break;
          case 'object':
            if (this.Properties.multiSelect) {
              const dropdownValues = values.value || '';
              const spplitedValue = dropdownValues.replaceAll(', ', ',')
              .split(',');
              
              const valueOptions = [];
              spplitedValue.forEach(v => {
                valueOptions.push({
                  value: v.toString(),
                });
              });
              
              this.ValueOptions = valueOptions;
            } else {
              this.ValueOptions = [
                {
                  value: values.value || '',
                },
              ];
            }
            break;
          case 'array':
            this.ValueOptions = values;
            // values.forEach((value) => {
            //   switch (this.toolsService.GetObjectType(value)) {
            //     case 'string':
            //     case 'number':
            //       this.ValueOptions.push({
            //         value: value
            //       });
            //       break;
            //     case 'object':
            //       this.ValueOptions.push({
            //         value: value.value || ''
            //       });
            //       break;
            //     default:
            //       this.ValueOptions.push(value);
            //       break;
            //   }            // });
            
            const repValue = this.GetValue;
            if (
              !this.ValueOptions.map(vo => vo.value)
              .includes(repValue) &&
              repValue !== null &&
              !(this.Properties.multiSelect && repValue.includes(','))
            ) {
              this.ValueOptions.push({
                row: 0,
                col: 0,
                context: '',
                value: repValue.toString(),
                formattedValue: repValue,
              });
            }
            
            // disable for multiselect
            if (this.Properties.selectFirstOption && !this.Properties.multiSelect) {
              if (repValue && repValue !== '') {
              } else if (this.ValueOptions.length > 0) {
                this.SetValue(this.ValueOptions[0]);
                this.FireEvent(LeapXLEventType.OptionSelected, repValue, true);
              }
            }
            break;
          
          default:
            this.ValueOptions = [];
            break;
        }
        
        break;
      case RepresentativeMoleculesType.Table:
        break;
      case RepresentativeMoleculesType.Report:
        break;
      case RepresentativeMoleculesType.Datepicker:
        break;
      case RepresentativeMoleculesType.Image:
        break;
      
      default:
        break;
    }
    
    if (this.Properties.allowEmptyValues) {
      this.ValueOptions = this.ValueOptions.map(vo => {
        if (vo.value === null) {
          vo.value = '';
        }
        
        return vo;
      });
    } else {
      this.ValueOptions = this.ValueOptions.filter(option => option.value !== '');
    }
    
    this.$UpdateValue.emit(true);
  }
  
  public OnContextMenu(event: MouseEvent, data: { completeContexts: string[]; contexts: string[] }) {
    console.log('context menu');
    this.communicationService.Event.Runtime.System.$OpenContextMenu.emit({ event, data });
  }
  
  public UpdateProperty(
    propertyName: string = null,
    value: any = null,
    versioning: {
      elementId: number;
      path: string;
      property: string;
      value: any;
    } = null,
    busService: BusService = null,
  ) {
    // console.log(propertyName, value, versioning, busService);
    
    if (propertyName && value) {
      switch (propertyName.toLowerCase()) {
        case 'isselected':
          this.IsSelected = value;
          break;
        case 'loading':
          this.Loading = value;
          break;
        case 'buses':
          this.Buses = value;
          break;
        case 'children':
          this.Children = value;
          break;
        
        default:
          this.navigateProperty(this.Properties, propertyName, value);
          break;
      }
    } else if (versioning) {
      if (!versioning.path || versioning.path === '') {
        this.UpdateProperty(versioning.property, versioning.value, null, busService);
      } else {
        const cleanPath = versioning.path
        .replace('properties', 'Properties')
        .replace('molecules', 'Molecules')
        .replace('children', 'Children')
        .substring(versioning.path.indexOf('-') + 1, versioning.path.length);
        
        this.navigateVersioningPath(cleanPath === '' ? [] : cleanPath.split('.'), this, versioning.property,
          versioning.value);
      }
    }
    
    const molecule = this.busService.Get(this.ParentId.toString());
    if (molecule.Type === RepresentativeMoleculesType.WorkGroup && molecule.GridsterConfig.api) {
      molecule.GridsterConfig.api.optionsChanged();
    }
  }
  
  public SetValueMetaData(
    metadata: {
      translationIds: number[][];
      contexts: string[][];
      offset: number;
      operationType?: string;
      value?: string;
      searchFilter?: string;
      limits?: number[];
      pageNumber?: number;
      pageSize?: number;
      pageCount?: number;
      totalItemCount?: number;
      filterIndex?: number;
      filterValue?: string;
    },
    dataKey: string = null,
  ) {
    if (metadata) {
      this.ValueMetaData = {
        TranslationIds: metadata.translationIds,
        Contexts: metadata.contexts,
        SearchFilter: metadata.searchFilter,
        Offset: metadata.offset,
        Limits: metadata.limits,
        Value: metadata.value,
        OperationType: metadata.operationType,
        PageNumber: metadata.pageNumber,
        PageSize: metadata.pageSize || 999999999,
        PageCount: metadata.pageCount || 1,
        TotalItemCount: metadata.totalItemCount,
        FilterIndex: metadata.filterIndex,
        FilterValue: metadata.filterValue,
      };
      
      if (dataKey) {
        this.datasourcesService.dataSourceValueMetadataKey[dataKey] = metadata;
      }
    }
  }
  
  public GetPosition(
    event?: any,
    leftPanelOpen = false,
  ): {
    x: number;
    y: number;
  } {
    // console.log(this.SubParentId);
    let left = 0;
    let top = 0;
    
    const htmlElement = document.querySelector(`#gridsterItem-${ this.Id }`) as any;
    
    if (this.SubParentId > 0 && event) {
      left = (leftPanelOpen ? event.screenX : event.clientX) - 90;
      top = event.clientY - 80;
    } else {
      if (htmlElement) {
        const leftRegex = /(\d+)px,\s/;
        const leftTransform = leftRegex.exec(htmlElement.style.transform);
        left = leftTransform ? +leftTransform[1] : 0;
        
        // left = +leftRegex.exec(htmlElement.style.transform)[1];
        
        const topRegex = /,\s(\d+)px,\s/g;
        const topTransform = topRegex.exec(htmlElement.style.transform);
        top = topTransform ? +topTransform[1] : 0;
        
        // top = +topRegex.exec(htmlElement.style.transform)[1];
      }
    }
    
    return {
      x: left,
      y: top,
    };
  }
  
  public async ProcessReceptor(receptor: string, bus?: Bus, data?: any): Promise<any> {
    return new Promise(async(resolve, reject) => {
      this.InputReceptorProcessed = receptor;
      let receptorValueData = null;
      let processedData = null;
      
      const dataByType = this.toolsService.ExtractValuesByType(data);
      
      switch (receptor) {
        case Receptor.MinDateInput:
          if (dataByType.string.includes('/') && dataByType.string.includes('-')) {
            dataByType.string = dataByType.string.split('-')[0];
          } else {
            dataByType.string = this.toolsService.GetFormattedDate(new Date(dataByType.string));
          }
          
          this.Properties.minValue = dataByType.string;
          this.$UpdateValue.emit(true);
          receptorValueData = data;
          return resolve(receptorValueData);
        case Receptor.MaxDateInput:
          if (dataByType.string.includes('/') && dataByType.string.includes('-')) {
            const splittedValue = dataByType.string.split('-');
            dataByType.string = splittedValue[splittedValue.length - 1];
          } else {
            dataByType.string = this.toolsService.GetFormattedDate(new Date(dataByType.string));
          }
          
          this.Properties.maxValue = dataByType.string;
          this.$UpdateValue.emit(true);
          receptorValueData = data;
          return resolve(receptorValueData);
        case Receptor.StartDateInput:
          // range
          if (dataByType.string.includes('/') && dataByType.string.includes('-')) {
            dataByType.string = dataByType.string.split('-')[0];
          } else {
            dataByType.string = this.toolsService.GetFormattedDate(new Date(dataByType.string));
          }
          
          if (this.Properties.minValue && new Date(dataByType.string) < new Date(this.Properties.minValue)) {
            dataByType.string = this.Properties.minValue;
          }
          
          this.StartValue = dataByType.string;
          
          if (this.Properties.dateRange) {
            this.Value = '';
            this.$UpdateValue.emit(true);
          } else {
            this.SetValue(data);
          }
          
          receptorValueData = data;
          return resolve(receptorValueData);
        case Receptor.EndDateInput:
          if (dataByType.string.includes('/') && dataByType.string.includes('-')) {
            const splittedValue = dataByType.string.split('-');
            dataByType.string = splittedValue[splittedValue.length - 1];
          } else {
            dataByType.string = this.toolsService.GetFormattedDate(new Date(dataByType.string));
          }
          
          if (this.Properties.maxValue && new Date(dataByType.string) > new Date(this.Properties.maxValue)) {
            dataByType.string = this.Properties.maxValue;
          }
          
          this.EndValue = dataByType.string;
          if (this.Properties.dateRange) {
            this.Value = '';
            this.$UpdateValue.emit(true);
          } else {
            this.SetValue(data);
          }
          receptorValueData = data;
          return resolve(receptorValueData);
        case Receptor.MinInput:
          switch (this.Type) {
            case RepresentativeMoleculesType.Slider:
              processedData = this.toolsService.ExtractValuesByType(data).number;
              break;
            
            default:
              processedData = +data;
              break;
          }
          
          this.Properties.minValue = processedData;
          this.$UpdateValue.emit(true);
          receptorValueData = processedData;
          return resolve(receptorValueData);
        case Receptor.MaxInput:
          switch (this.Type) {
            case RepresentativeMoleculesType.Slider:
              processedData = this.toolsService.ExtractValuesByType(data).number;
              break;
            default:
              processedData = +data;
              break;
          }
          
          this.Properties.maxValue = processedData;
          this.$UpdateValue.emit(true);
          receptorValueData = processedData;
          return resolve(receptorValueData);
        case Receptor.MinOutput:
          receptorValueData = this.Properties.minValue;
          return resolve(receptorValueData);
        case Receptor.MaxOutput:
          receptorValueData = this.Properties.maxValue;
          return resolve(receptorValueData);
        case Receptor.ValueInput:
        case Receptor.ValuePreviewInput:
          if (this.Type === RepresentativeMoleculesType.Table) {
            this.Throttle(
              (func, delay, context) => {
                this.SetValue(data, bus);
              },
              50,
              this,
              data,
            );
          } else {
            this.SetValue(data);
          }
          receptorValueData = data;
          return resolve(receptorValueData);
        case Receptor.BadgeValueInput:
          if (!this.Properties.badge.badgeEnable) {
            return resolve('');
          }
          
          receptorValueData = this.toolsService.ExtractValuesByType(data).string;
          this.BadgeValue = receptorValueData;
          return resolve(receptorValueData);
        case Receptor.IconInput:
          receptorValueData = this.toolsService.ExtractValuesByType(data).string;
          this.Properties.icon.iconType = receptorValueData;
          return resolve(receptorValueData);
        case Receptor.IconOutput:
          receptorValueData = this.Properties.icon.iconType || '';
          return resolve(receptorValueData);
        case Receptor.BadgeValueOutput:
          if (!this.Properties.badge.badgeEnable) {
            return resolve('');
          }
          receptorValueData = this.BadgeValue || '';
          return resolve(receptorValueData);
        case Receptor.PageValueOutput:
          receptorValueData = this.GetValue;
          return resolve(receptorValueData);
        case Receptor.SelectStepOutput:
          receptorValueData = this.LastSelected;
          resolve(receptorValueData);
          break;
        case Receptor.ValueOutput:
          if (this.Type === RepresentativeMoleculesType.Table) {
            // todo: if not paginated return same getvalue
            if (this.DataKey) {
              return this.datasourcesService.GetDatasourceDataSetByKey(this.DataKey)
              .subscribe(dataResponse => {
                if (dataResponse) {
                  return resolve(dataResponse.dataElements);
                } else {
                  return resolve([]);
                }
              });
            } else {
              receptorValueData = this.GetValue;
              return resolve(receptorValueData);
            }
          } else if (this.Type === RepresentativeMoleculesType.Stepper) {
            return resolve(this.ProcessedValue);
          } else {
            receptorValueData = this.GetValue;
            return resolve(receptorValueData);
          }
        case Receptor.SearchInput:
          const valuesSearchInput = this.toolsService.ExtractValuesByType(data);
          this.SearchFilter = valuesSearchInput.string;
          // this.$UpdateValue.emit(true);
          this.communicationService.Event.Runtime.System.$TableSearch.emit(
            { repMoleculeId: this.Id, value: this.SearchFilter });
          receptorValueData = this.SearchFilter;
          return resolve(receptorValueData);
        case Receptor.TooltipInput:
          this.SetTooltipValue(data);
          return resolve(receptorValueData);
        case Receptor.TextInput:
          this.SetTextValue(data);
          receptorValueData = data;
          return resolve(receptorValueData);
        case Receptor.SelectStepInput:
          if (this.Type === RepresentativeMoleculesType.Stepper) {
            this.LastSelected = dataByType.number;
            this.communicationService.Event.Runtime.System.$SelectStepperStep.emit({
              repMoleculeId: this.Id,
              stepIndex: dataByType.number,
            });
            // set step
          }
          receptorValueData = data;
          return resolve(receptorValueData);
        case Receptor.TextLoading:
          this.SetLoadingTextValue(data);
          receptorValueData = data;
          return resolve(receptorValueData);
        case Receptor.HeaderInput:
          const values = this.toolsService.ExtractValuesByType(data);
          const firstRowIndex = Math.min.apply(
            Math,
            values.array.map(o => {
              return o.row;
            }),
          );
          const row = values.array.filter(a => a.row === firstRowIndex);
          this.HeaderValue = row; // _.uniqBy(values.array, 'row');
          this.$UpdateValue.emit();
          setTimeout(() => {
            this.communicationService.Event.System.App.$RefreshUI.emit();
            this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(this.ParentId);
          }, 1000);
          receptorValueData = data;
          return resolve(receptorValueData);
        case Receptor.TextOutput:
          receptorValueData = this.GetTextValue;
          return resolve(receptorValueData);
        case Receptor.SearchOutput:
          receptorValueData = this.SearchFilter;
          return resolve(receptorValueData);
        case Receptor.OptionsListInput:
          this.SetValueOptions(data);
          receptorValueData = data;
          return resolve(receptorValueData);
        case Receptor.OptionsListOutput:
          receptorValueData = this.GetValueOptions;
          return resolve(receptorValueData);
        case Receptor.MinDateOutput:
          receptorValueData = this.Properties.minValue;
          return resolve(receptorValueData);
        case Receptor.MaxDateOutput:
          receptorValueData = this.Properties.maxValue;
          return resolve(receptorValueData);
        case Receptor.StartDateOutput:
          receptorValueData = this.StartValue;
          return resolve(receptorValueData);
        case Receptor.EndDateOutput:
          receptorValueData = this.EndValue;
          return resolve(receptorValueData);
        default:
          receptorValueData = null;
          return resolve(receptorValueData);
      }
    });
  }
  
  GetObjectType(obj) {
    return (
      Object.prototype.toString.call(obj)
      .replace(/^\[object (.+)\]$/, '$1')
      .toLowerCase() || ''
    );
  }
  
  ExtractStringData(data) {
    
    if (data) {
      switch (this.GetObjectType(data)) {
        case 'string':
        case 'number':
          return data.toString();
        case 'array':
          
          if (data.length > 1) {
            
            const cleanData = [];
            
            data.forEach(d => {
              cleanData.push(this.ExtractStringData(d));
            });
            
            return cleanData;
            
          } else {
            return this.ExtractStringData(data[0]);
          }
          break;
        case 'object':
          return data['value'] ? data['value'] || JSON.stringify(data) : '';
        default:
          return '';
      }
    } else {
      return '';
    }
    
  }
  
  GetOutputEvents(): LeapXLEvent[] {
    const busesWithOutputEvent = this.Buses.filter(bus => bus.GetLastParticle()
    .IsEvent());
    const events = [];
    
    busesWithOutputEvent.forEach(bus => {
      events.push(bus.GetLastParticle());
    });
    
    return events;
  }
  
  public GetBusesWithSimilarDataSourceName(dataSourceName: string, busReceptors: Receptor[] = []): Bus[] {
    const buses: Bus[] = [];
    let busesToEvaluate = [];
    
    if (busReceptors.length > 0) {
      busReceptors.forEach(receptor => {
        busesToEvaluate.push(...this.GetBusesByReceptor(receptor));
      });
    } else {
      busesToEvaluate = this.Buses;
    }
    
    busesToEvaluate.forEach(bus => {
      if (bus.GetDataElementsWithSimilarName(dataSourceName).length > 0) {
        buses.push(bus);
      }
    });
    
    return buses;
  }
  
  IsAutoGenTranslationId(translationId: number, autogenerationId?: string, triggerType?: AutoGenType): boolean {
    let isAutogen = this.AutoGenerationInfo && this.AutoGenerationInfo.length > 0 && this.AutoGenerationInfo.filter(
      a => a.translation !== null)
    .map(
      a => a.translation.id)
    .includes(translationId);
    
    if (isAutogen && autogenerationId) {
      isAutogen = triggerType ? this.AutoGenerationInfo.filter(
        a => !!a.translation.onTrigger.find(t => t.type === triggerType))
      .map(a => a.id)
      .includes(autogenerationId) : this.AutoGenerationInfo.map(a => a.id)
      .includes(autogenerationId);
    }
    
    return isAutogen;
  }
  
  GetAutoGenInfo(translationId: string | number, triggerType?: AutoGenType): AutoGenInfo {
    return triggerType ? this.AutoGenerationInfo.find(
      a => !!a.translation.onTrigger.find(t => t.type === triggerType) &&
        a.translation.id.toString() === translationId.toString()) : this.AutoGenerationInfo.find(
      a => a.translation.id.toString() === translationId.toString());
  }
  
  public async ProcessCustomReceptor(receptor: string, bus?: Bus, data?: any): Promise<any> {
    data = this.toolsService.ConvertDataElementsToRows(data);
    return new Promise(async(resolve, reject) => {
      const customReceptorsDomain = 'custom-receptors';
      const customReceptors = this.windowStoreService.Read(customReceptorsDomain) || {};
      const receptorCallback = customReceptors[`${ this.Id }-${ receptor }`];
      let receptorValueData = null;
      
      if (receptorCallback) {
        if (receptor.includes('input')) {
          this.InputReceptorProcessed = receptor;
          
          receptorValueData = this.ExtractStringData(data);
          receptorCallback(receptorValueData);
          setTimeout(() => {
            this.RefreshParent();
          }, 50);
        } else if (receptor.includes('output')) {
          receptorValueData = this.ExtractStringData(receptorCallback());
        }
      } else {
        console.error('[Custom control Error]: Receptor not found');
      }
      return resolve(receptorValueData);
    });
  }
  
  public ScrollIntoFocus() {
    this.communicationService.Event.Editor.WorkArea.$ShowFocusedMenu.emit(this);
    const elHtml = document.querySelector(`#gridsterItem-${ this.Id }`);
    const elBoundings = elHtml.getBoundingClientRect();
    
    if (elHtml) {
      const top = elBoundings.y - window.innerHeight / 2;
      const left = elBoundings.x - window.innerWidth / 2;
      
      const contentEl = document.querySelector('.work-area-content');
      this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
      this.communicationService.Event.Editor.WorkArea.$Zoom.emit(
        { level: 0.8, xOrigin: elBoundings.x, yOrigin: elBoundings.y });
      this.toolsService.ScrollToPosition(contentEl, top, left)
      .then(result => {
        this.communicationService.Event.Editor.WorkArea.$Zoom.emit(
          { level: 1, xOrigin: elBoundings.x, yOrigin: elBoundings.y, setLevel: true });
        // $Zoom.emit({ level: 1.19, xOrigin: elBoundings.x, yOrigin: elBoundings.y });
        setTimeout(() => {
          this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
        }, 200);
      });
      this.Pulse();
    }
  }
  
  public UpdateValue(value: any) {
    switch (this.Type) {
      case RepresentativeMoleculesType.Label:
      case RepresentativeMoleculesType.H1:
      case RepresentativeMoleculesType.H2:
      case RepresentativeMoleculesType.H3:
      case RepresentativeMoleculesType.H4:
      case RepresentativeMoleculesType.H5:
        this.Properties.placeholder = value.toString();
        
        this.Properties.textToDisplay = value.toString();
        break;
      case RepresentativeMoleculesType.Checkbox:
        const trueValues = ['Y', 'YES', 'T', 'TRUE', 'true', '1'];
        this.Value = value && trueValues.includes(value.toString()
        .toUpperCase());
        break;
      
      default:
        this.Value = value;
        break;
    }
    
    this.$UpdateValue.emit(value);
  }
  
  public Pulse(duration = 3000) {
    const item = document.querySelector(`#gridsterItem-${ this.Id }`);
    
    if (item) {
      item.classList.add('pulse');
      setTimeout(() => {
        item.classList.remove('pulse');
      }, duration);
    }
  }
  
  UnsetOldValue(): void {
    this.oldValueSet = false;
  }
  
  // TODO: Refactor
  ContainsDataElementsWithContexts(contexts: string[], busReceptors: string[] = []): boolean {
    if (contexts.length === 0) {
      return false;
    }
    
    const contextsSplitted = [];
    
    contexts.forEach(c => {
      const contextSplitted = c.split(Constants.ContextSeparator);
      contextSplitted.pop();
      contextsSplitted.push(contextSplitted.join(Constants.ContextSeparator));
    });
    
    const dataElementsContexts = [];
    
    this.GetDataElements(busReceptors)
    .map(de => de.Context)
    .forEach(c => {
      const deContextSplitted = c.split(Constants.ContextSeparator);
      deContextSplitted.pop();
      dataElementsContexts.push(deContextSplitted.join(Constants.ContextSeparator));
    });
    
    return !!dataElementsContexts.find(dec => contextsSplitted.includes(dec));
    // return !!this.GetDataElements().find(de => contextsSplitted.includes(de.Context));
  }
  
  public ContainsDataElement(context: string): boolean {
    return !!this.GetDataElement(context);
  }
  
  public ContainsBus(busId: string): boolean {
    return !!this.Buses.find(b => b.id === busId);
  }
  
  public GetParticle(particleId: string): Particle {
    let particle = null;
    this.Buses.forEach(b => {
      b.Particles.forEach(p => {
        if (p.ParticleId === particleId) {
          particle = p;
        }
      });
    });
    
    return particle;
  }
  
  public AddBus(bus: Bus) {
    const busExists = this.GetBus(bus.id);
    if (!busExists) {
      bus.RepresentativeMoleculeId = this.Id;
      
      if (!this.Receptors.includes(bus.Receptor)) {
        bus.Receptor = '';
      }
      
      this.Buses.push(bus);
    }
  }
  
  public AddBuses(buses: Bus[]) {
    buses.forEach(bus => {
      this.AddBus(bus);
    });
  }
  
  public RemoveBus(busId: string) {
    const bus = this.Buses.find(b => b.id === busId);
    
    if (bus) {
      bus.GetActionMolecules()
      .forEach(actionMolecule => {
        this.processorService.GetActionMoleculeFunctionInstance(actionMolecule)
        .AfterRemove(this);
        this.communicationService.Event.Editor.$RepresentativeMoleculeDetection.emit(
          { repMoleculeId: +this.Id, state: true });
      });
    }
    
    this.Buses = this.Buses.filter(b => b.id !== busId);
    this.RefreshDatasourceConnected();
    this.HighlightDatasourcesPath();
    this.FireDataSourceBus();
  }
  
  public GetAllEvents(): LeapXLEvent[] {
    const busesEvents = (this.Buses as any).flatMap(b => b.GetEventParticles());
    return busesEvents;
  }
  
  public ToggleVisibilityFromEditorLayer(condition = null) {
    if (condition === null) {
      this.EditorVisible = !this.EditorVisible;
    } else {
      this.EditorVisible = condition;
    }
    
    this.communicationService.Event.Editor.$DeselectRepresentativeMolecule.emit(this.Id);
    this.communicationService.Event.System.App.$RefreshUI.emit();
    this.communicationService.Event.Editor.$RepresentativeMoleculeVisibleChange.emit(
      { repMoleculeId: this.Id, status: this.EditorVisible });
    
    if (this.Type === RepresentativeMoleculesType.WorkGroup && this.EditorVisible) {
      this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(this);
    }
  }
  
  public GetBus(busId: string): Bus {
    return this.Buses.find(b => b.id === busId) || null;
  }
  
  public GetBusByClone(clonedId: string): Bus {
    return this.Buses.find(b => b.id === clonedId) || null;
  }
  
  public GetBusEquals(bus: Bus): Bus {
    return this.Buses.find(b => this.IsSameBus(b, bus)) || null;
  }
  
  public GenerateNewBus(defaultEvent = false, name = null, receptor = null): Bus {
    const parent = this.busService.GetParent(this.Id.toString());
    let busName = name || `Process ${ parent.Properties.name } - ${ this.Properties.name }`;
    busName = this.GenerateBusName(this.Buses, busName, 0);
    
    const newbus = new Bus({
      id: this.toolsService.GenerateGuid(),
      Name: `${ busName }`,
      Receptor: receptor || this.Receptors[0],
      Particles: [],
    });
    
    if (defaultEvent) {
      const defaultEventParticle = this.GenerateEvent(this.DefaultEvent);
      newbus.AddParticle(defaultEventParticle);
    }
    
    this.AddBus(newbus);
    return newbus;
  }
  
  ToggleTabOrderDisplay() {
    this.DisplayTabOrder = !this.DisplayTabOrder;
  }
  
  ShowTabOrderDisplay() {
    this.DisplayTabOrder = true;
  }
  
  HideTabOrderDisplay() {
    this.DisplayTabOrder = false;
  }
  
  public GenerateBusName(buses: Bus[], newBusName: string, busCount: number): string {
    const exists = buses.find(b => b.Name === `${ newBusName }` + (busCount === 0 ? '' : ` (${ busCount })`));
    if (exists) {
      return this.GenerateBusName(buses, newBusName, busCount + 1);
    } else {
      return newBusName + (busCount === 0 ? '' : ` (${ busCount })`);
    }
  }
  
  public GetBusByParticleId(particleId: string): Bus {
    return this.Buses.find(b => !!b.GetParticle(particleId)) || null;
  }
  
  public GetBusByReceptorAndParticleEventType(receptor: string, eventType: string): Bus {
    return (
      this.Buses.find(b => b.Receptor === receptor && !!b.Particles.find(
        p => p.IsEvent() && (p as LeapXLEvent).EventType === eventType)) || null
    );
  }
  
  public GetBusByReceptor(receptor: string): Bus {
    return this.Buses.find(b => b.Receptor === receptor) || null;
  }
  
  public GetOrCreateOwnBusTriggerBy(eventType: string): Bus {
    const buses = this.GetBusesByEventType(eventType);
    let bus: Bus = buses.find(b => b.FirstParticle() && b.FirstParticle()
    .IsEvent() && (b.FirstParticle() as LeapXLEvent).SourceId === this.Id);
    
    if (bus) {
    } else {
      const triggerEvent = this.GenerateEvent(eventType);
      bus = this.GenerateNewBus(false, eventType + ' Bus', Receptor.None);
      bus.AddParticle(triggerEvent);
    }
    
    return bus;
  }
  
  public GetBusesByReceptor(receptor: string): Bus[] {
    return this.Buses.filter(b => b.Receptor === receptor) || null;
  }
  
  public GetBusByReceptorAndMoleculeNameType(receptor: string[], moleculeType: string): Bus[] {
    return (
      this.Buses.filter(b => receptor.includes(b.Receptor) && !!b.Particles.find(
        p => (p as ActionMolecule).InternalMoleculeName === moleculeType)) ||
      null
    );
  }
  
  public ContainsDataElementsWithDatasourceId(datasourceId: number) {
    const dataElements = [];
    
    this.Buses.forEach(b => {
      b.Particles.forEach(particle => {
        if (particle.IsDataElement() && (particle as DataElement).DataSourceId === datasourceId) {
          dataElements.push(particle);
        } else if (particle.IsActionMolecule() && (particle as ActionMolecule).DataElements.length > 0) {
          (particle as ActionMolecule).DataElements.forEach(de => {
            if (de.DataSourceId === datasourceId) {
              dataElements.push(particle);
            }
          });
        }
      });
    });
    
    return !!dataElements;
  }
  
  public GetDataElement(context: string): DataElement {
    let dataelement = null;
    
    this.Buses.forEach(b => {
      b.Particles.forEach(particle => {
        if (particle.IsDataElement() && (particle as DataElement).Context.toLowerCase()
        .includes(context.toLowerCase())) {
          dataelement = particle;
        } else if (particle.IsActionMolecule() && (particle as ActionMolecule).DataElements.length > 0) {
          (particle as ActionMolecule).DataElements.forEach(de => {
            if (de.Context.toLowerCase()
            .includes(context.toLowerCase())) {
              dataelement = de;
            }
          });
        }
      });
    });
    
    return dataelement;
  }
  
  public GetDataElements(busReceptors: string[] = []): DataElement[] {
    let dataelements = [];
    let buses = this.Buses;
    
    if (busReceptors.length > 0) {
      buses = this.Buses.filter(b => busReceptors.includes(b.Receptor));
    }
    
    buses.forEach(b => {
      dataelements = dataelements.concat(b.GetDataElements());
    });
    return dataelements;
  }
  
  public GetDataElementsByBusEvent(event: LeapXLEvent): DataElement[] {
    let dataelements = [];
    this.Buses.forEach(b => {
      dataelements = dataelements.concat(b.GetDataElementsByBusEvent(event));
    });
    return dataelements;
  }
  
  public GetActionMolecules(): ActionMolecule[] {
    let actionMolecules = [];
    this.Buses.forEach(b => {
      actionMolecules = actionMolecules.concat(b.GetActionMolecules());
    });
    return actionMolecules;
  }
  
  public GetEvents(): LeapXLEvent[] {
    let events = [];
    this.Buses.forEach(b => {
      events = events.concat(b.GetEvents());
    });
    return events;
  }
  
  public GetPopulatingBus(): Bus {
    return (
      this.GetBusByReceptorAndParticleEventType(Receptor.ValueInput, LeapXLEventType.AppBroadcast) ||
      this.GetBusByReceptorAndParticleEventType(Receptor.ValuePreviewInput, LeapXLEventType.AppBroadcast) ||
      this.GetBusByReceptorAndParticleEventType(Receptor.OptionsListInput, LeapXLEventType.AppBroadcast)
    );
  }
  
  public GetMolecules(moleculeName: string): ActionMolecule[] {
    const molecules = [];
    
    this.Buses.forEach(b => {
      b.Particles.forEach(p => {
        if (p.IsActionMolecule() && (p as ActionMolecule).InternalMoleculeName === moleculeName) {
          molecules.push(p);
        }
      });
    });
    
    return molecules;
  }
  
  public GetBusByEventType(eventType: string): Bus {
    const bus =
      this.Buses.length > 0
        ? this.Buses.find(
          b =>
            b.FirstParticle() &&
            b.FirstParticle()
            .IsEvent() &&
            (b.FirstParticle() as LeapXLEvent).EventType.toLowerCase() === eventType.toLowerCase(),
        )
        : null;
    return bus;
  }
  
  public GetBusesByEventType(eventType: string): Bus[] {
    const buses =
      this.Buses.length > 0
        ? this.Buses.filter(
          b =>
            b.Particles.length > 0 &&
            b.FirstParticle()
            .IsEvent() &&
            (b.FirstParticle() as LeapXLEvent).EventType.toLowerCase() === eventType.toLowerCase(),
        )
        : [];
    return buses;
  }
  
  public GetBusInitiatedByEventName(eventName: string): Bus {
    const bus =
      this.Buses.length > 0
        ? this.Buses.find(
          b =>
            b.FirstParticle() &&
            b.FirstParticle()
            .IsEvent() &&
            (b.FirstParticle() as LeapXLEvent).EventName.toLowerCase() === eventName.toLowerCase(),
        )
        : null;
    return bus;
  }
  
  public AddPlaceholder(placeholder: string) {
    if (!this.Placeholders.includes(placeholder)) {
      this.Placeholders.push(placeholder);
    }
  }
  
  public RemovePlaceholder(placeholder: string) {
    if (this.Placeholders.includes(placeholder)) {
      this.Placeholders = this.Placeholders.filter(p => p !== placeholder);
    }
  }
  
  public GetBusesInitiatedBySpecificEventName(eventName: string, sourceId: any): Bus[] {
    const buses =
      this.Buses.length > 0
        ? this.Buses.filter(
          b =>
            b.FirstParticle() &&
            b.FirstParticle()
            .IsEvent() &&
            (b.FirstParticle() as LeapXLEvent).EventName.toLowerCase() === eventName.toLowerCase() &&
            (b.FirstParticle() as LeapXLEvent).SourceId.toString() === sourceId.toString(),
        )
        : [];
    return buses;
  }
  
  public GetBusInitiatedBySpecificEvent(eventName: string, sourceId: any): Bus {
    const bus =
      this.Buses.length > 0
        ? this.Buses.find(
          b =>
            b.FirstParticle() &&
            b.FirstParticle()
            .IsEvent() &&
            (b.FirstParticle() as LeapXLEvent).EventName.toLowerCase() === eventName.toLowerCase() &&
            (b.FirstParticle() as LeapXLEvent).SourceId.toString() === sourceId.toString(),
        )
        : null;
    return bus;
  }
  
  public GetBusesWithEvent(eventName: string): Bus[] {
    const buses =
      this.Buses.length > 0
        ? this.Buses.filter(b => !!b.Particles.find(
          p => p.IsEvent() && (p as LeapXLEvent).EventName.toLowerCase() === eventName.toLowerCase()))
        : [];
    return buses;
  }
  
  public GetBusesWithSpecificEvent(eventName: string, sourceId: any): Bus[] {
    const buses =
      this.Buses.length > 0
        ? this.Buses.filter(
          b =>
            !!b.Particles.find(
              p =>
                p.IsEvent() &&
                (p as LeapXLEvent).EventName.toLowerCase() === eventName.toLowerCase() &&
                (p as LeapXLEvent).SourceId.toString() === sourceId.toString(),
            ),
        )
        : [];
    return buses;
  }
  
  public GetEventByEventName(eventName: string): LeapXLEvent {
    let event = null;
    
    this.Buses.forEach(b => {
      const eventFound = b.GetEvents()
      .find(e => e.EventName.toLowerCase() === eventName.toLowerCase());
      
      if (eventFound) {
        event = eventFound;
      }
    });
    
    return event;
  }
  
  public GetOutEventByEventName(eventName: string): LeapXLEvent {
    let event = null;
    
    this.Buses.forEach(b => {
      b.Particles.forEach((p, index) => {
        if (index > 0 && p.IsEvent() && (p as LeapXLEvent).EventName.toLowerCase() === eventName.toLowerCase()) {
          event = p as LeapXLEvent;
        }
      });
    });
    
    return event;
  }
  
  public GetBusesByEventName(eventName: string): Bus[] {
    const eventBuses = [];
    
    this.Buses.forEach(b => {
      const events = b.GetEvents();
      const event = events.find(e => e.EventName.toLowerCase() === eventName.toLowerCase());
      
      if (event) {
        eventBuses.push(b);
      }
    });
    
    return eventBuses;
  }
  
  public ContainsEvents(): boolean {
    return !!this.Buses.find(b => !!b.Particles.find(p => p.IsEvent()));
  }
  
  public ContainsParticles(): boolean {
    return !!this.Buses.find(b => b.Particles.length > 0);
  }
  
  public ReplaceOwnEventIds(toReplaceId: number, replaceWithId: number) {
    this.Buses.forEach(b => {
      b.Particles.forEach(p => {
        if (p.IsEvent() && (p as LeapXLEvent).SourceId === toReplaceId) {
          (p as LeapXLEvent).SourceId = replaceWithId;
        }
      });
    });
  }
  
  public AddParticles(particles: Particle[]) {
    const bus = this.GetFirstBus() || this.GenerateNewBus();
    particles.forEach(p => {
      bus.AddParticle(p);
    });
  }
  
  public GetFirstBus(): Bus {
    return this.Buses[0] || null;
  }
  
  public IsSameBus(bus1: Bus, bus2: Bus): boolean {
    return (
      bus1.Receptor === bus2.Receptor &&
      ((bus1.Particles.length === 0 && bus2.Particles.length === 0) ||
        (bus1.HasParticles() &&
          bus2.HasParticles() &&
          bus1.FirstParticle()
          .IsEvent() &&
          bus2.FirstParticle()
          .IsEvent() &&
          (bus1.FirstParticle() as LeapXLEvent).EventType === (bus2.FirstParticle() as LeapXLEvent).EventType))
    );
  }
  
  RefreshParent(): void {
    this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(this);
  }
  
  SavePropertyFromVersioning(versioning: PropertyVersioningDto): Observable<any> {
    return this.propertiesService.SaveProperty(versioning);
  }
  
  SaveProperty(propertyName: string, changeDescription: string): Observable<any> {
    this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(this.Id);
    setTimeout(() => {
      this.communicationService.Event.Editor.$RepresentativeMoleculeDetection.emit(
        { repMoleculeId: this.Id, state: true });
    }, 50);
    
    // region BUSES PRIORITY SET
    if (propertyName === 'buses') {
      this.SetBusesPriority();
      this.MarkAsTouched();
    }
    // endregion
    
    return this.propertiesService.SaveOwnProperty(this.Id.toString(), propertyName, changeDescription);
  }
  
  SavePropertyPromise(propertyName: string, changeDescription: string): Promise<any> {
    return new Promise(resolve => {
      this.SaveProperty(propertyName, changeDescription)
      .subscribe(result => {
        return resolve(result);
      });
    });
  }
  
  GetBusByName(busName: string): Bus {
    return this.Buses.find(b => b.Name === busName);
  }
  
  SetBusesPriority() {
    this.Buses.forEach(bus => {
      this.SetBusPriority(bus);
    });
  }
  
  SetBusPriority(bus: Bus) {
    bus.SetParticlesPriority();
  }
  
  public GetBusesWithOutputEvents(): Bus[] {
    return this.Buses.filter(b => b.GetEvents().length > 1);
  }
  
  public HighlightDatasourcesPath() {
    let dataElements = [];
    const valueBus = this.GetBusByReceptor(Receptor.ValueInput);
    
    if (valueBus && valueBus.GetDataElements().length > 0) {
      dataElements = valueBus.GetDataElements();
    } else {
      dataElements = this.GetDataElements();
    }
    
    if (dataElements.length > 0) {
      dataElements[0].HighlightDatasourcePathOnEditor();
      return;
    }
    
    dataElements.forEach(de => {
      de.HighlightDatasourcePathOnEditor();
    });
  }
  
  public HighlightEventsPath() {
    const path = this.GetParentsPath();
    this.communicationService.Event.Editor.EventsTree.$Highlight.emit(path);
  }
  
  public HighlightViewsPath() {
    const path = this.GetParentsPath();
    this.communicationService.Event.Editor.Views.$Highlight.emit(path);
  }
  
  FireEvent(eventName: string, data = this.Value, triggeredByUser = false) {
    this.communicationService.Event.Runtime.MolecularEngine.$LeapXLEvent.emit(
      new LeapXLEvent({
        particleId: this.toolsService.GenerateGuid(),
        id: this.toolsService.GenerateGuid(),
        sourceId: this.Id,
        eventName: `${ this.Properties.name } - ${ eventName.toUpperCase() }`,
        eventType: eventName,
        eventSource: 'Molecule',
        data: data,
        triggeredByUser: triggeredByUser,
      }),
    );
  }
  
  RefreshUI(): void {
    this.communicationService.Event.Editor.$RefreshRepresentativeMoleculeUI.emit(this.Id);
  }
  
  FireDataSourceBus() {
    const inputValueReceptors = [Receptor.ValueInput, Receptor.ValuePreviewInput, Receptor.OptionsListInput,
      Receptor.HeaderInput];
    const dataSourceBuses = this.GetBusByReceptorAndMoleculeNameType(inputValueReceptors,
      'GetElementsDatasourceDataMolecule');
    let eventFired = false;
    
    if (dataSourceBuses.length > 0) {
      for (let i = 0; i < dataSourceBuses.length; i++) {
        const bus = dataSourceBuses[i];
        const firstParticle = bus.FirstParticle();
        if (firstParticle.IsEvent()) {
          this.FireEvent((firstParticle as LeapXLEvent).EventName);
          eventFired = true;
          break;
        }
      }
      
      if (!eventFired) {
        const bus = dataSourceBuses[0];
        this.FireEvent(`${ this.Id }${ bus.id }`);
      }
    } else {
      this.SetInitValue();
    }
  }
  
  ContainsDataElements(): boolean {
    return !!this.GetDataElements();
  }
  
  GetParentsPath(): number[] {
    const path = [];
    let parent = this.Id;
    
    while (parent > 0) {
      path.push(parent);
      const paretnRepMolecule = this.busService.GetParent(parent.toString());
      
      if (paretnRepMolecule) {
        parent = paretnRepMolecule.Id;
      } else {
        parent = 0;
      }
    }
    return path.reverse();
  }
  
  GenerateActionMolecule(properties: ActionMoleculeProperties): ActionMolecule {
    return this.factoryParticleService.GenerateActionMolecule(properties, this.Id);
  }
  
  RefreshDatasourceConnected(): void {
    if (this.cobbleService.Cobble.running) {
      this.DataSourceConnected = false;
      return;
    }
    this.DataSourceConnected = this.GetDataElements().length > 0;
    this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(this.ParentId);
  }
  
  IsWorkgroup(): boolean {
    return this.Type === RepresentativeMoleculesType.WorkGroup;
  }
  
  IsOnlyWorkgroupInWorkArea(): boolean {
    return this.IsWorkgroup() && this.busService.GetSiblings(this.Id.toString()).length === 0;
  }
  
  UpdateBackupProperties(path: string[], property: string, value: any) {
    console.log('update backup');
    path.shift();
    const object = this.toolsService.NavigateObjectByPath(path, this.PropertiesBackup);
    object[property] = value;
  }
  
  GetStyle(section = 'all', offsetRepresentativeMolecule: IRepresentativeMolecule = null): IRepresentativeMoleculeStyleData {
    const styleProperties = this.thematicService.ObtainRepresentativeMoleculeStyle(this, section,
      offsetRepresentativeMolecule);
    return styleProperties;
  }
  
  DuplicateParticle(particleId: string) {
    const bus = this.GetBusByParticleId(particleId);
    bus.DuplicateParticle(particleId);
    this.communicationService.Event.Editor.$RecreateProcessBuses.emit();
    this.SaveProperty('buses', 'Molecule Duplicated')
    .subscribe();
  }
  
  ReplaceActionMolecules(particleId: string, moleculeType: string) {
    const bus = this.GetBusByParticleId(particleId);
    const actionMoleculeToReplace = bus.GetParticle(particleId) as ActionMolecule;
    
    this.factoryParticleService.CreateActionMoleculeParticle(moleculeType, this.Id)
    .then(actionMolecule => {
      actionMolecule.AddDataElements(actionMoleculeToReplace.DataElements);
      bus.ReplaceActionMolecule(actionMolecule, particleId);
      this.communicationService.Event.Editor.$RecreateProcessBuses.emit();
      this.SaveProperty('buses', 'Molecule Replaced')
      .subscribe();
    });
  }
  
  SetStyle(stylesData: IRepresentativeMoleculeStyleData[]) {
    stylesData.forEach(style => {
      this.thematicService.SetStyle(style, this);
    });
  }
  
  ApplyStyle(
    stylesData: IRepresentativeMoleculeStyleData[],
    specificSection = 'all',
    ignoreCalcProperties = false,
    considerManualAdjustedProperties = false,
  ) {
    if (stylesData.length === 0) {
      return;
    }
    
    const sectionsToAvoid = [];
    Object.keys(this.StyleMetadata.specificStyles)
    .forEach(section => {
      if (this.StyleMetadata.specificStyles[section] && this.StyleMetadata.specificStyles[section].styleId === null) {
        sectionsToAvoid.push(section);
      }
    });
    
    stylesData.forEach(style => {
      this.thematicService.ApplyStyle(
        style,
        this,
        specificSection,
        style.styleId === null ? [] : sectionsToAvoid,
        ignoreCalcProperties,
        considerManualAdjustedProperties,
      );
    });
  }
  
  public GetActionMoleculeParticleByParticleId(particleId: string): ActionMolecule {
    let actionMolecule = null;
    
    this.Buses.forEach(b => {
      const molecule = b.GetActionMoleculeByParticleId(particleId);
      if (molecule) {
        actionMolecule = molecule;
      }
    });
    
    return actionMolecule;
  }
  
  MarkAsTouched() {
    this.Touched = true;
    return;
    this.SaveProperty('touched', 'Mark as touched')
    .subscribe();
  }
  
  Select(): void {
    this.IsSelected = true;
    
    const gridtsterItemElement = document.querySelector(`#gridsterItem-${ this.Id }`);
    if (gridtsterItemElement) {
      gridtsterItemElement.classList.add('element-on-top');
    }
    const parentGridtsterItemElement = document.querySelector(`#gridsterItem-${ this.ParentId }`);
    if (parentGridtsterItemElement) {
      parentGridtsterItemElement.classList.add('element-on-top');
    }
    
    this.communicationService.Event.Editor.$RepMoleculeSelected.emit(this);
  }
  
  Deselect(): void {
    this.IsSelected = false;
    
    const gridtsterItemElement = document.querySelector(`#gridsterItem-${ this.Id }`);
    if (gridtsterItemElement) {
      gridtsterItemElement.classList.remove('element-on-top');
      gridtsterItemElement.classList.remove('element-selected');
    }
    const parentGridtsterItemElement = document.querySelector(`#gridsterItem-${ this.ParentId }`);
    if (parentGridtsterItemElement) {
      parentGridtsterItemElement.classList.remove('element-on-top');
    }
  }
  
  GenerateEvent(eventType: string): LeapXLEvent {
    return this.factoryParticleService.GenerateEvent(eventType, 'Molecule', this.Id);
  }
  
  RepresentativeMoleculeFitOnX(x: number, cols: number): boolean {
    return x + cols < this.ResponsiveProperties().cols;
  }
  
  RepresentativeMoleculeFitOnY(y: number, rows: number): boolean {
    return y + rows < this.ResponsiveProperties().rows;
  }
  
  GetPaginatedData(searchFilter?: string, pageSize = null): Observable<DatasourceDataResponse> {
    if (pageSize === null) {
      if (this.ValueMetaData && this.ValueMetaData.PageSize) {
        pageSize = this.ValueMetaData.PageSize;
      } else {
        const paginateMolecules = this.GetMolecules('DatasourcePaginateDataMolecule');
        
        if (paginateMolecules && paginateMolecules.length > 0) {
          pageSize = +paginateMolecules[0].Rule.paginate;
        }
      }
    }
    
    if (this.DataKey && (pageSize > 0 || searchFilter)) {
      return this.datasourcesService.PaginateDatasourceData(this.DataKey, this.PageNumber, pageSize || 999999,
        searchFilter);
    } else {
      return of(null);
    }
  }
  
  Throttle(func, delay, context, args) {
    clearTimeout(this.inDebounce);
    this.inDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }
  
  AddCustomGuideline(position: number, orientation: 'x' | 'y') {
    this.ResponsiveProperties().customGuidelines = this.ResponsiveProperties().customGuidelines || {
      x: [],
      y: [],
    };
    
    let customGuidelines = null;
    customGuidelines = orientation === 'x' ? this.ResponsiveProperties().customGuidelines.x : this.ResponsiveProperties().customGuidelines.y;
    
    if (customGuidelines.find(g => g.position === position)) {
      // already exists
    } else {
      customGuidelines.push({ position });
      this.SaveCustomGuidelines();
    }
  }
  
  RemoveCustomGuideline(position: number, orientation: 'x' | 'y') {
    this.ResponsiveProperties().customGuidelines = this.ResponsiveProperties().customGuidelines || {
      x: [],
      y: [],
    };
    
    this.Properties.responsive[this.cobbleService.Cobble.deviceType].customGuidelines[orientation] = this.Properties.responsive[
      this.cobbleService.Cobble.deviceType
      ].customGuidelines[orientation].filter(cg => cg.position !== position);
    
    this.SaveCustomGuidelines();
  }
  
  RefreshDataSourceRelationships() {
    this.HighlightEventsPath();
    this.HighlightDatasourcesPath();
    this.FireDataSourceBus();
  }
  
  AddChildren(childrenIds: number[]) {
    childrenIds.forEach(childId => this.AddChild(childId));
  }
  
  AddChild(childId: number) {
    if (this.Children.find(c => c.id === childId)) {
      return;
    }
    
    this.Children.push({ id: childId });
  }
  
  ResponsiveProperties(): {
    itemsPerPage: number;
    customGuidelines: {
      x: any[];
      
      y: any[];
    };
    header: {
      headerHeight: number;
      headerFontSize: number;
      headerFontColor: string;
      headerFontFamily: string;
      headerFontStyle: string;
      headerBackgroundColor: string;
    };
    body: {
      bodyFontFamily: string;
      bodyFontStyle: string;
      bodyFontSize: number;
      bodyHeight: number;
      bodyFontColor: string;
      bodyBackgroundColor: string;
      isBodyStriped: boolean;
      firstBodyStripedColor: string;
      secondBodyStripedColor: string;
    };
    chartOptions: IChartOptions;
    tableOptions: {
      collapse: boolean;
      bodyRoundCorners: boolean;
      search: boolean;
      header: boolean;
      centerPagination: boolean;
      itemsCount: boolean;
      
      selectRow: boolean;
      paginationBackgroundColor: string;
      tableOrder: {
        col: number;
        position: number;
      }[];
      tableWidth: {};
    };
    id: number;
    cols: number;
    rows: number;
    colsQty: number;
    rowsQty: number;
    xOffset: number;
    yOffset: number;
    x: number;
    y: number;
    layer: number;
    centerPositioning: boolean;
    font: {
      fontSize: number;
      fontColor: string;
      fontFamily: string;
      fontStyle: string;
    };
  } {
    const properties = this.Properties.responsive[this.cobbleService.Cobble.deviceType];
    properties['customGuidelines'] = properties['customGuidelines'] || { x: [], y: [] };
    properties['type'] = this.Type;
    return properties;
  }
  
  IsMolecularProcessTouched(): boolean {
    return !!this.GetActionMoleculeNeedingParameters()
    .find(m => m.Touched);
  }
  
  GetActionMoleculeNeedingParameters(): ActionMolecule[] {
    return this.GetActionMolecules()
    .filter(
      m => !this.templateService.GetActionMoleculePropertie(
        m.InternalMoleculeName).bypassGetDataElements || m.DataElements.length > 0,
    );
  }
  
  HasDataElements() {
    return (this.GetActionMolecules()
    .map(m => m.DataElements) as any).flat().length > 0;
  }
  
  DisplayTemplatePlaceholder(): boolean {
    // console.log('DisplayTemplatePlaceholder');
    
    if (!this.CreationFromTemplate) {
      return false;
    }
    
    if (this.TemplateKeepDatasources) {
      return false;
    }
    
    if (!this.HasDataElements()) {
      return false;
    }
    
    // if molecule touched
    return !(this.Touched === true);
    
    const busMoleculesTouched = this.GetActionMolecules()
    .filter(m => m.Touched);
    
    if (busMoleculesTouched.length > 0) {
      return false;
    }
    
    const datasourceMolecules = this.GetActionMolecules()
    .filter(
      m => m.DataElements.length > 0 && m.DataElements.find(de => de.DataSourceType !== 'Api') && !m.Touched,
    );
    
    if (datasourceMolecules.length > 0) {
      return false;
    }
    
    const eventsTouched = this.GetEvents()
    .filter(e => e.Touched);
    
    if (eventsTouched.length > 0) {
      return false;
    }
    
    return true;
  }
  
  HighlightRepMoleculeDebug(type = 3) {
    if (!this.Debug) {
      return;
    }
    
    const types = ['receive-event-debug', 'fire-event-debug', 'run-molecule-debug'];
    // type
    // 1 - receive event
    // 2 - fire event
    // 3 - run molecule
    
    const item = document.querySelector(`#gridsterItem-${ this.Id }`);
    const typeName = types[type - 1];
    
    if (item) {
      item.classList.add(typeName);
      setTimeout(() => {
        item.classList.remove(typeName);
      }, 150);
    }
  }
  
  public BackupProperties() {
    this.PropertiesBackup = cloneDeep(this.Properties);
  }
  
  public SetBackupStyle() {
    console.log('SetBackupStyle');
    this.ApplyStyle(
      [
        {
          properties: this.PropertiesBackup,
          styleId: null,
          name: '',
          representativeMoleculeType: '',
          section: 'all',
        },
      ],
      'all',
      true,
      true,
    );
  }
  
  ResetStyles() {
    this.StyleMetadata.styles.forEach(styleId => {
      this.RemoveStyle(styleId);
    });
    
    setTimeout(() => {
      this.StyleMetadata.manualAdjustedProperties = [];
      this.StyleMetadata.specificStyles = {
        dimension: null,
        font: null,
        hover: null,
        frame: null,
        appearance: null,
      };
      this.SaveProperty('styleMetadata', 'Clear style')
      .subscribe();
      
      this.ApplyAssignedStyle();
    }, 100);
    
    return;
    this.StyleMetadata.styles = [];
    this.StyleMetadata.manualAdjustedProperties = [];
    this.StyleMetadata.specificStyles = {
      dimension: null,
      font: null,
      hover: null,
      frame: null,
      appearance: null,
    };
    
    this.ApplyAssignedStyle();
    this.SaveProperty('styleMetadata', 'Clear style')
    .subscribe();
  }
  
  IsPropertyValueFromStyle(property: string, value: any) {
    const styles = this.thematicService.GetStylesById(this.StyleMetadata.styles);
    for (let i = 0; i < styles.length; i++) {
      if (this.toolsService.ExistsInObject(styles[i].properties, property, value)) {
        return true;
      }
    }
  }
  
  AddStyle(styleId: number) {
    if (!this.StyleMetadata.styles.includes(styleId)) {
      this.StyleMetadata.styles.push(styleId);
      this.thematicService.AssignStyleToRepresentativeMolecule(styleId, this.Id);
    } else {
      this.StyleMetadata.styles.push(
        this.StyleMetadata.styles.splice(this.StyleMetadata.styles.indexOf(styleId), 1)[0]);
    }
    
    this.SavePropertyFromVersioning(
      new PropertyVersioningDto({
        elementId: this.Id.toString(),
        property: 'styleMetadata',
        value: this.StyleMetadata,
        path: '',
        change: 'Style edited',
        name: 'Styles',
      }),
    )
    .subscribe();
  }
  
  RemoveStyle(styleId: number) {
    this.thematicService.UnAssignStyleFromRepresentativeMolecule(styleId, this.Id);
    this.StyleMetadata.styles = this.StyleMetadata.styles.filter(styleP => styleP !== styleId);
    
    Object.keys(this.StyleMetadata.specificStyles)
    .forEach(section => {
      if (this.StyleMetadata.specificStyles[section] && !this.StyleMetadata.styles.includes(
        this.StyleMetadata.specificStyles[section])) {
        this.StyleMetadata.specificStyles[section] = null;
      }
    });
    
    if (this.StyleMetadata.styles.length === 0) {
      this.StyleMetadata.manualAdjustedProperties = [];
      this.StyleMetadata.specificStyles = {
        dimension: null,
        font: null,
        hover: null,
        frame: null,
        appearance: null,
      };
    }
    
    this.SavePropertyFromVersioning(
      new PropertyVersioningDto({
        elementId: this.Id.toString(),
        property: 'styleMetadata',
        value: this.StyleMetadata,
        path: '',
        change: 'Style edited',
        name: 'Styles',
      }),
    )
    .subscribe();
  }
  
  PasteStyle() {
    this.thematicService.PasteStyle(this);
  }
  
  async GetDimensions(): Promise<{ cols: number; rows: number; }> {
    const size = {
      cols: 0,
      rows: 0,
    };
    
    let styleCols = null;
    let styleRows = null;
    
    if (this.StyleMetadata && this.StyleMetadata.styles.length > 0) {
      const styleId = this.StyleMetadata.styles[0];
      
      const styleData = await this.thematicService.GetStylePromised(styleId);
      
      if (styleData && (styleData.section === 'all' || styleData.section === 'dimension')) {
        styleCols = styleData.properties.responsive[this.cobbleService.Cobble.deviceType].cols;
        styleRows = styleData.properties.responsive[this.cobbleService.Cobble.deviceType].rows;
      }
    }
    
    size.cols = this.Properties.responsive[this.cobbleService.Cobble.deviceType].cols;
    size.rows = this.Properties.responsive[this.cobbleService.Cobble.deviceType].rows;
    
    if (styleCols && styleRows) {
      size.cols = styleCols;
      size.rows = styleRows;
    }
    
    return size;
  }
  
  IsFiringEvent(event: LeapXLEvent) {
    return !!this.Buses.find(
      b => b.HasParticles() && b.Particles.length > 1 && b.Particles[b.Particles.length - 1] &&
        b.Particles[b.Particles.length - 1].IsEvent() && (b.Particles[b.Particles.length - 1] as LeapXLEvent).IsSameEvent(
          event));
  }
  
  ApplyAssignedStyle(setBackupStyle = true, ignoreCalcProperties = false) {
    if (setBackupStyle) {
      this.SetBackupStyle();
    }
    
    if (this.StyleMetadata.styles.length > 0) {
      console.log('ApplyAssignedStyle', this.Id);
      // const stylesToApply = uniqBy(this.thematicService.GetStylesByIdFromApp(this.toolsService.Unique(this.StyleMetadata.styles)), 'section');
      const stylesToApply = this.thematicService.GetStylesByIdFromApp(
        this.toolsService.Unique(this.StyleMetadata.styles));
      console.warn(stylesToApply);
      this.ApplyStyle(stylesToApply, 'all', ignoreCalcProperties, true);
      
      Object.keys(this.StyleMetadata.specificStyles)
      .forEach(section => {
        if (this.StyleMetadata.specificStyles[section] && this.StyleMetadata.specificStyles[section].styleId !== 'none') {
          this.ApplyStyle(
            this.thematicService.GetStylesByIdFromApp([this.StyleMetadata.specificStyles[section].styleId]),
            section,
            ignoreCalcProperties,
            true,
          );
        }
      });
    }
  }
  
  SetAppDefaultTheme() {
    
    if (this.StyleMetadata.styles.length > 0) {
      return;
    }
    
    const style = this.cobbleService.Cobble.appTheme.representativeMoleculeStyles
      ? this.cobbleService.Cobble.appTheme.representativeMoleculeStyles[this.Type]
      : null;
    
    if (style) {
      const styleData = this.thematicService.GetStyle(style.styleId);
      
      if (styleData) {
        if (style.asStyle) {
          this.AddStyle(style.styleId);
          this.ApplyStyle([styleData]);
        } else {
          if (styleData) {
            this.ApplyStyle([styleData]);
            this.SaveProperty('properties', `Style set`)
            .subscribe();
          }
        }
        this.snackerService.ShowMessageOnBottom('Default theme applied');
      }
    }
  }
  
  AutoRefreshEvent(): { type: string, sourceId: string } {
    const initBusExists = this.GetBusByReceptorAndParticleEventType(Receptor.ValueInput,
      LeapXLEventType.Init);
    
    if (initBusExists) {
      const molecules = initBusExists.GetActionMoleculeParticlesByInternalName(
        'GetElementsDatasourceDataMolecule');
      if (molecules.length > 0) {
        return {
          type: LeapXLEventType.Init,
          sourceId: null,
        };
      }
    }
    
    const reloadBusExists = this.GetBusByReceptorAndParticleEventType(Receptor.ValueInput,
      LeapXLEventType.Reload);
    
    if (reloadBusExists) {
      return {
        type: LeapXLEventType.Reload,
        sourceId: null,
      };
    }
    
    const dataBus = this.GetBusByReceptor(Receptor.ValueInput);
    
    if (dataBus) {
      
      if (dataBus.GetActionMoleculeParticle('AddToDatasourceMolecule') ||
        dataBus.GetActionMoleculeParticle('UpdateDatasourceDataMolecule') ||
        dataBus.GetActionMoleculeParticle('DeleteDatasourceDataMolecule')) {
        return {
          type: null,
          sourceId: null,
        };
      }
      
      const molecules = dataBus.GetActionMoleculeParticlesByInternalName(
        'GetElementsDatasourceDataMolecule');
      
      if (molecules.length > 0) {
        const firstParticle = dataBus.FirstParticle();
        const omitEvents = [
          LeapXLEventType.RowSelected,
        ];
        
        if (firstParticle.IsEvent() && !omitEvents.includes(
          (firstParticle as LeapXLEvent).EventType as any)) {
          
          return {
            type: (firstParticle as LeapXLEvent).EventType,
            sourceId: (firstParticle as LeapXLEvent).SourceId,
          };
        }
      }
    }
    
    return {
      type: null,
      sourceId: null,
    };
  }
  
  protected InjectServices() {
    const injector = AppInjector.getInjector();
    this.propertiesService = injector.get(ApiPropertiesService);
    this.toolsService = injector.get(ToolsService);
    this.busService = injector.get(BusService);
    this.communicationService = injector.get(CommunicationService);
    this.templateService = injector.get(TemplateService);
    this.windowStoreService = injector.get(WindowStoreService);
    this.cobbleService = injector.get(CobbleService);
    this.factoryParticleService = injector.get(FactoryParticleService);
    this.datasourcesService = injector.get(ApiDataSourcesService);
    this.editorStateService = injector.get(EditorStateService);
    this.molecularEngineConnectorService = injector.get(MolecularEngineConnectorService);
    this.localStorageService = injector.get(LocalStorageService);
    this.processorService = injector.get(ProcessorService);
    this.thematicService = injector.get(ThematicService);
    this.snackerService = injector.get(SnackerService);
    this.angularZone = injector.get(NgZone);
  }
  
  private SaveCustomGuidelines() {
    this.SavePropertyFromVersioning(
      new PropertyVersioningDto({
        elementId: this.Id.toString(),
        property: 'customGuidelines',
        value: this.ResponsiveProperties().customGuidelines,
        path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
        change: 'Guideline added',
        name: 'Guideline',
      }),
    )
    .subscribe();
  }
  
  private navigateProperty(obj: any, propertyName: string, value) {
    for (const property in obj) {
      if (property === propertyName) {
        obj[property] = value;
        this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(this);
        break;
      } else {
        if (typeof obj[property] === 'object') {
          this.navigateProperty(obj[property], propertyName, value);
        }
      }
    }
  }
  
  private navigateVersioningPath(path: string[], container: any, property: string, value: any) {
    if (path.length > 0) {
      const actualPath = path[0];
      
      if (actualPath.indexOf('[') > -1 && actualPath.indexOf(']') > -1) {
        let id = actualPath.substring(actualPath.lastIndexOf('[') + 1);
        id = id.substring(0, id.indexOf(']'));
        const containerProperty = actualPath.substring(0, actualPath.indexOf('['));
        
        const index = container[containerProperty].findIndex(element => element.id === id);
        
        path.splice(0, 1);
        this.navigateVersioningPath(path, container[containerProperty][index], property, value);
      } else {
        const _path = path[0];
        path.splice(0, 1);
        this.navigateVersioningPath(path, container[_path], property, value);
      }
    } else {
      container[property] = value;
      this.angularZone.runOutsideAngular(() => {
        this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(this);
      });
    }
  }
}
