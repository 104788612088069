import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { EventsService } from '../../../core/molecular/services/events.service';
import { ApiPropertiesService } from '../../../core/services/api-properties.service';
import { DevToolsService } from '../../../core/services/dev-tools.service';
import { EditorStateService } from '../../../core/services/editor-state.service';
import { ToolsService } from '../../../core/services/tools.service';
import { PropertyVersioningDto } from '../../../shared/dtos/versioning-dto';
import { DragType } from '../../../shared/enums/drag-type.enum';
import { Bus } from '../../../shared/representative-molecule/interfaces/bus';
import { LeapXLEvent } from '../../../shared/representative-molecule/interfaces/leapxl-event';
import { Particle } from '../../../shared/representative-molecule/interfaces/particle';
import {
  RepresentativeMolecule,
} from '../../../shared/representative-molecule/interfaces/representative-molecule';
import { CobbleService } from '../../../shared/representative-molecule/services/cobble.service';
import { DragService } from '../../../shared/representative-molecule/services/drag.service';
import { CommunicationService } from '../../../shared/services/communication.service';
import { DraggableWindowManagerService } from '../../../shared/services/draggable-window-manager.service';
import {
  DraggableWindowService,
  DraggableWindowType,
} from '../../../shared/services/draggable-window.service';
import { SnackerService } from '../../../shared/services/snacker.service';
import { WorkAreaService } from '../../workarea.service';
import { BusService } from './../../../core/molecular/services/bus.service';

@Component({
  selector: 'app-event-particle',
  templateUrl: './event-particle.component.html',
  styleUrls: ['./event-particle.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EventParticleComponent implements OnInit {
  @Input() event: LeapXLEvent;
  @Input() bus: Bus;
  @Input() positionIndex: number;
  @Input() repMolecule: RepresentativeMolecule;
  @Input() isAutoRefresh = false;
  eventName = '';
  
  constructor(
    private propertiesService: ApiPropertiesService,
    public workAreaService: WorkAreaService,
    private snackerService: SnackerService,
    private busService: BusService,
    private dragService: DragService,
    public toolsService: ToolsService,
    private editorStateService: EditorStateService,
    private draggableWindowService: DraggableWindowService,
    private communicationService: CommunicationService,
    private cobbleService: CobbleService,
    private draggableWindowManagerService: DraggableWindowManagerService,
    private eventsService: EventsService,
    public devToolsService: DevToolsService,
  ) {
  }
  
  ngOnInit() {
    let sourceName = '';
    this.eventName = this.event.EventName;
    
    if (this.event.EventSource === 'Molecule') {
      const repMolecule = this.busService.Get(this.event.SourceId.toString());
      sourceName = repMolecule ? repMolecule.Properties.name + '-' : '';
      this.eventName = sourceName + this.event.EventName;
      
    } else if (this.event.EventSource === 'View') {
      const view = this.cobbleService.Cobble.properties.views.find(v => v.id === this.event.SourceId);
      
      if (view) {
        sourceName = `-${ view.name }`;
        this.eventName = this.event.EventName + sourceName;
        
      }
    }
    
  }
  
  RemoveEvent() {
    if (this.isAutoRefresh) {
      this.repMolecule.Properties.autoDataRefresh = false;
      this.propertiesService.SaveProperty(new PropertyVersioningDto({
        elementId: this.repMolecule.Id.toString(),
        property: 'autoDataRefresh',
        value: this.repMolecule.Properties.autoDataRefresh,
        path: `properties`,
        change: 'Disable auto refresh',
        name: this.repMolecule.Properties.name,
      }))
      .subscribe();
    } else {
      this.RemoveParticleFromAllElements(this.event, this.bus);
    }
  }
  
  // to do: refactor repeated code
  RemoveParticleFromAllElements(particle: Particle, bus: Bus) {
    this.workAreaService.RemoveParticleFromAllElements(particle.ParticleId, bus.id);
  }
  
  ReplaceEvent() {
    console.log('replace');
    if (
      this.dragService.dragData &&
      this.dragService.dragData.dragType === DragType.Event &&
      this.event.ParticleId !== this.dragService.dragData.data.ParticleId
    ) {
      // console.log(this.dragService.dragData);
      
      if (this.workAreaService.elementsSelected.length > 1) {
        this.workAreaService.ReplaceParticleForAllElements(
          new LeapXLEvent(this.dragService.dragData.data),
          this.event.ParticleId,
          this.positionIndex,
        );
      } else {
        this.bus.AddParticle(new LeapXLEvent(this.dragService.dragData.data), this.positionIndex,
          this.repMolecule.Id.toString());
        this.bus.RemoveParticle(this.event.ParticleId);
        this.repMolecule.SaveProperty('buses', `Event removed`)
        .subscribe();
        this.snackerService.ShowMessageOnBottom('Event Replaced', 'swap_horizontal_circle', null, true);
      }
    }
  }
  
  ShowEventSource(event: MouseEvent) {
    if (this.toolsService.RunningMode) {
      return;
    }
    
    this.draggableWindowService.OpenDraggableWindow(
      `${ this.event.EventName } Connections`,
      DraggableWindowType.EventSource,
      event,
      { event: this.event },
    );
  }
  
  GenerateEventFlowForEvent() {
    const eventTransactionWindow = document.getElementsByClassName('events-transaction-container');
    
    if (eventTransactionWindow.length > 0) {
      this.communicationService.Event.Editor.$ConstructEventsFlow.emit({
        repMoleculeId: this.repMolecule ? this.repMolecule.Id : null,
        viewId: null,
        eventName: this.event.EventName,
      });
    } else {
      this.draggableWindowService.OpenDraggableWindow(
        'Event Process Design (Beta Test)',
        DraggableWindowType.EventsTransaction,
        null,
        {
          repMoleculeId: this.repMolecule ? this.repMolecule.Id : null,
          eventName: this.event.EventName,
        },
      );
    }
  }
  
  HighlightRepMolecule() {
    if (this.event && this.event.EventSource === 'Molecule') {
      const sourceRepMolecule = this.busService.Get(this.event.SourceId);
      if (sourceRepMolecule) {
        sourceRepMolecule.Pulse();
      }
    }
  }
  
  HighlightEvent() {
    if (this.toolsService.RunningMode) {
      return;
    }
    
    if (this.event.EventSource === 'Custom') {
      this.communicationService.Event.Editor.EventsTree.$OpenEventsSection.emit('custom');
    } else {
      this.communicationService.Event.Editor.EventsTree.$OpenEventsSection.emit('elements');
    }
    
    const timeout = this.workAreaService.leftPanelIndexTab === 1 ? 100 : 400;
    
    this.workAreaService.leftPanelIndexTab = 1;
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    
    setTimeout(() => {
      const highlitedEvents = document.querySelectorAll(`.event.selected-element-node`) as unknown as any[];
      highlitedEvents.forEach((hl) => hl.classList.remove('selected-element-node'));
      
      let identifier = '';
      
      if (this.event.EventSource === 'Custom') {
        identifier = 'event-custom-' + this.event.Identifier;
      } else {
        identifier = 'event-' + this.repMolecule.Id + '-' + this.event.EventName;
      }
      
      const treeEventNodeElement = document.querySelector(`.${ identifier }`);
      
      if (treeEventNodeElement) {
        treeEventNodeElement.classList.add('selected-element-node');
        treeEventNodeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        if (this.event.EventSource === 'Custom') {
          this.snackerService.ShowMessageOnBottom('Custom event not found', 'warning');
        }
      }
    }, timeout);
  }
}
