import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DraggableWindowManagerService } from 'app/shared/services/draggable-window-manager.service';
import { ApiMoleculesService } from '../../core/services/api-molecules.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { EditorStateService } from '../../core/services/editor-state.service';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { ToolsService } from '../../core/services/tools.service';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { ApiPublishedCobblesService } from '../../shared/services/api-published-cobbles.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { ConnectionStateService } from '../../shared/services/connection-state.service';
import { DraggableWindowService, DraggableWindowType } from '../../shared/services/draggable-window.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { CobblePublishedDialogComponent } from '../../workarea/cobble-published-dialog/cobble-published-dialog.component';
import { RemoveCobbleDialogComponent } from '../../workarea/remove-cobble-dialog/remove-cobble-dialog.component';
import { WorkAreaService } from '../../workarea/workarea.service';

@Component({
  selector: 'app-cobble-details',
  templateUrl: './cobble-details.component.html',
  styleUrls: ['./cobble-details.component.scss'],
})
export class CobbleDetailsComponent implements OnInit {
  cobbleInfo = {
    owner: '',
    creationDate: '',
    deviceType: '',
    deletionLocked: true,
    isPublic: false,
    company: '',
    id: 0,
    totalMolecules: 0,
    isShared: false,
    isApplication: false,
    isRunnable: false,
    description: '',
    name: '',
  };
  
  userPermissions = [];
  cloningApp = false;
  publishingApp = false;
  deletingApp = false;
  
  constructor(
    private connectionStateService: ConnectionStateService,
    private dialog: MatDialog,
    private dialogService: GenericDialogService,
    private publishedCobbleService: ApiPublishedCobblesService,
    private snackerService: SnackerService,
    private moleculesService: ApiMoleculesService,
    private cobbleService: CobbleService,
    private draggableWindowService: DraggableWindowService,
    private communicationService: CommunicationService,
    private toolsService: ToolsService,
    private dragableWindowManagerService: DraggableWindowManagerService,
    private workAreaService: WorkAreaService,
    private clientStorageService: ClientStorageService,
    private editorStateService: EditorStateService,
  ) {
    this.userPermissions = clientStorageService.getPermissions();
  }
  
  ngOnInit() {
    const id = this.draggableWindowService.GetData().id;
    
    if (id) {
      this.GetCobbleInfo(id);
    }
  }
  
  GetCobbleInfo(cobbleId: number) {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    if (cobbleId === this.cobbleInfo.id) {
      return;
    }
    this.moleculesService.CobbleData(cobbleId).subscribe(data => {
      this.cobbleInfo = data;
    });
  }
  
  updateCobbles() {
    this.communicationService.Event.System.Update.$UpdateDashboardApps.emit();
  }
  
  // refactor -------------------------------------
  
  UnpublishCobble(cobble: any) {
    if (this.deletingApp) {
      return;
    }
    
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    this.publishedCobbleService.UnpublishCobble(cobble.id).subscribe(r => {
      this.updateCobbles();
      this.communicationService.Event.Editor.WorkArea.$CloseAllDraggableWindows.emit();
      
      
      this.snackerService.ShowMessageOnBottom('App published version removed', 'do_not_disturb_on');
    });
  }
  
  PublishCobble(cobble: any) {
    if (this.deletingApp) {
      return;
    }
    
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    if (this.publishingApp) {
      return;
    }
    
    this.snackerService.ShowMessageOnBottom('Publishing App', 'rocket_launch');
    this.publishingApp = true;
    this.moleculesService.GetCobbleStages(cobble.id).subscribe(stages => {
      this.moleculesService.ChangeCobbleStage(stages[1].id, cobble.id).subscribe(result => {
        this.communicationService.Event.Editor.WorkArea.$CloseAllDraggableWindows.emit();
        
        
        this.publishingApp = false;
        this.updateCobbles();
        const dialogRef = this.dialog.open(CobblePublishedDialogComponent, {
          data: {
            cobble: cobble,
          },
        });
      });
    });
  }
  
  DeleteCobble(cobble: any): void {
    if (this.deletingApp) {
      return;
    }
    
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    if (this.cobbleInfo.deletionLocked) {
      return;
    }
    
    this.dialogService.openDialog({ cobbleName: cobble.name }, RemoveCobbleDialogComponent).then(result => {
      this.deletingApp = true;
      
      if (result) {
        if (cobble.isRunnable) {
          this.publishedCobbleService.UnpublishCobble(cobble.id).subscribe(r => {
            this.moleculesService.DeleteRepresentativeMolecule([cobble.id], true).subscribe(_result => {
              this.updateCobbles();
              
              this.communicationService.Event.Editor.WorkArea.$CloseAllDraggableWindows.emit();
              
              this.snackerService.ShowMessageOnBottom('App deleted sucessfully!', 'do_not_disturb_on');
              this.deletingApp = false;
            });
          });
        } else {
          this.moleculesService.DeleteRepresentativeMolecule([cobble.id], true).subscribe(_result => {
            this.updateCobbles();
            this.communicationService.Event.Editor.WorkArea.$CloseAllDraggableWindows.emit();
            
            this.snackerService.ShowMessageOnBottom('App deleted sucessfully!', 'do_not_disturb_on');
            this.deletingApp = false;
          });
        }
        this.communicationService.Event.System.App.$RemoveAppFromFavorites.emit(cobble);
      } else {
        this.deletingApp = false;
      }
    });
  }
  
  CloneCobble(cobble: any, event: any) {
    if (this.deletingApp) {
      return;
    }
    
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    this.draggableWindowService.OpenDraggableWindow(
      'Clone Application',
      DraggableWindowType.CloneApplicationComponent,
      event,
      {
        appId: cobble.id,
        appName: cobble.name,
      });
  }
  
  ExportApp(event: MouseEvent) {
    if (this.deletingApp) {
      return;
    }
    
    this.draggableWindowService.OpenDraggableWindow(
      'Export LeapXL Application (Beta Test)',
      DraggableWindowType.LeapxlExport,
      event,
      {
        export: {
          type: 'app',
          name: this.cobbleInfo.name,
          id: this.cobbleInfo.id,
          isApplication: this.cobbleInfo.isApplication,
        },
      },
    );
  }
  
  ShareApp(event: MouseEvent) {
    this.toolsService.DragWindowConfig = this.editorStateService.WindowSize('HierarchyShared');
    
    this.draggableWindowService.OpenDraggableWindow(
      `Share`,
      DraggableWindowType.HierarchyShared,
      null,
      {
        data: {
          entityIdToShare: this.cobbleInfo.id,
          entityNameToShare: this.cobbleInfo.name,
        },
      },
    );
  }
}
