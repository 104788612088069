import { LicenseRole } from './licenserole.model';
import { User } from './user';

export class CompanyLicense {
  id: number;
  name: string;
  description: string;
  registerUser: User;
  roles: LicenseRole[];
  other: any;
  
  constructor() {
    this.roles = [];
  }
}
