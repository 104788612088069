import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class SelectFileMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    dataBus: any,
  ) {
    try {
      const subscription = this.fileService.fileSelected$.subscribe((file) => {
        // console.log('file', file);
        
        this.fileService.fileServiceRunning = false;
        if (subscription) {
          subscription.unsubscribe();
        }
        console.log(file);
        
        if (file && this.toolsService.GetObjectType(file) && (file as FileList).length > 0) {
          dataBus = file;
          this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
        } else {
          const moleculesChannel = this.busService.GetBusToRunFromProcessorChannel(
            busProcessorKey,
          );
          if (moleculesChannel) {
            moleculesChannel.bus.Particles.length = 1;
          }
          this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
        }
      });
      
      // limit choices disabled
      // if (this.fileService.mimetypes.length === 0) {
      //   this.fileService.mimetypes = Constants.MimeTypes.Image;
      //   this.fileService.mimetypes = this.fileService.mimetypes.concat(Constants.MimeTypes.PDF);
      // }
      
      this.fileService.fileServiceRunning = true;
      document.getElementById('moleculeFileSelector').setAttribute('accept', this.fileService.mimetypes.join(','));
      this.fileService.OpenFileDialog();
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
