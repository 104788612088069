export class LicenseRole {
  other: any;
  name: string;
  title: string;
  list: any[];
  id: number;
  availableQty: number;
  degradableLimit: number;
  
  constructor() {
    this.list = [];
  }
}
