import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WjGridModule } from '@grapecity/wijmo.angular2.grid';
import { WjGridSheetModule } from '@grapecity/wijmo.angular2.grid.sheet';
import { WjNavModule } from '@grapecity/wijmo.angular2.nav';
import { GridsterModule } from '@leapxl/gridster';
import { AngularSplitModule } from 'angular-split';
import { TreeModule } from 'angular-tree-component';
import { AngularDraggableModule } from 'angular2-draggable';
import { ClickOutsideModule } from 'ng-click-outside';
import { DynamicModule } from 'ng-dynamic-component';
import { DndModule } from 'ng2-dnd';
import { FileUploadModule } from 'ng2-file-upload';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { ApiRegisterComponent } from '../admin/api-register/api-register.component';
import { GenericDialogService } from '../core/services/generic-dialog.service';
import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
import { ConfirmDialogComponent } from '../shared/components/confirm-dialog/confirm-dialog.component';
import { HierarchySharedComponent } from '../shared/components/hierarchy-shared/hierarchy-shared.component';
import { InputsDialogComponent } from '../shared/components/inputs-dialog/inputs-dialog.component';
import { MaterialModule } from '../shared/material/material.module';
import { RepresentativeMoleculeModule } from '../shared/representative-molecule/representative-molecule.module';
import { SharedModule } from '../shared/shared.module';
import { SpreadsheetModule } from '../spreadsheet/spreadsheet.module';
import { ApiSetupComponent } from './api-setup/api-setup.component';
import { AppThemeConfigurationComponent } from './app-theme-configuration/app-theme-configuration.component';
import { BottomSheetOptionsComponent } from './bottom-sheet/bottom-sheet-options/bottom-sheet-options.component';
import { BusLoopComponent } from './bus-loop/bus-loop.component';
import { AddParticleToBusComponent } from './bus/add-particle-to-bus/add-particle-to-bus.component';
import { BusFrameComponent } from './bus/bus-frame/bus-frame.component';
import { BusComponent } from './bus/bus.component';
import { EventParticleComponent } from './bus/event-particle/event-particle.component';
import { CobblePropertiesComponent } from './cobble-properties/cobble-properties.component';
import { CobblePublishedDialogComponent } from './cobble-published-dialog/cobble-published-dialog.component';
import { CobbleStagesComponent } from './cobble-stages/cobble-stages.component';
import { CobbleComponent } from './cobble/cobble.component';
import { CobbleTreeNodeMenuComponent } from './cobbles-tree/cobble-tree-node-menu/cobble-tree-node-menu.component';
import { CobblesTreeComponent } from './cobbles-tree/cobbles-tree.component';
import { ComponentTreeNodeMenuComponent } from './cobbles-tree/component-tree-node-menu/component-tree-node-menu.component';
import { ShareCobbleConfirmationComponent } from './cobbles-tree/share-cobble-confirmation/share-cobble-confirmation.component';
import { CreateCobbletComponent } from './create-cobblet/create-cobblet.component';
import { CreateCompoundComponent } from './create-compound/create-compound.component';
import { CreateStyleComponent } from './create-style/create-style.component';
import { CustomControlHelpComponent } from './custom-control-help/custom-control-help.component';
import { ApiDataQualityDataService } from './data-quality/api-data-quality-data.service';
import { DataAuditComponent } from './data-quality/data-audit/data-audit.component';
import { DataDrillDownComponent } from './data-quality/data-drill-down/data-drill-down.component';
import { DataProvenanceComponent } from './data-quality/data-provenance/data-provenance.component';
import { DataQualityDialogService } from './data-quality/data-quality-dialog.service';
import { DataQualityComponent, LogDataDialogComponent } from './data-quality/data-quality/data-quality.component';
import { DataSourcesPanelMenuComponent } from './data-sources-panel-menu/data-sources-panel-menu.component';
import { DataSourcesPanelComponent } from './data-sources-panel/data-sources-panel.component';
import { DatasourceTreeNameComponent } from './data-sources-panel/datasource-tree-name/datasource-tree-name.component';
import { DatasourceConnectedComponent } from './datasource-connected/datasource-connected.component';
import { DatasourceDialogComponent } from './datasource-dialog/datasource-dialog.component';
import { DatasourceRearrangeComponent } from './datasource-rearrange/datasource-rearrange.component';
import { DatasourceRenameSheetComponent } from './datasource-rename-sheet/datasource-rename-sheet.component';
import { DatasourceRenameComponent } from './datasource-rename/datasource-rename.component';
import { DevToolsDataComponent } from './dev-tools/dev-tools-data/dev-tools-data.component';
import { DevToolsToolbarComponent } from './dev-tools/dev-tools-toolbar/dev-tools-toolbar.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { EmptyComponent } from './empty-component/empty-component.component';
import { EventSourceComponent } from './event-source/event-source.component';
import { EventTrackComponent } from './event-track/event-track.component';
import { EventsPanelComponent } from './events-panel/events-panel.component';
import { JsonTreeComponent } from './json-tree/json-tree.component';
import { MobileLayoutsPanelComponent } from './mobile-layouts-panel/mobile-layouts-panel.component';
import { NewCobbleComponent } from './new-cobble/new-cobble.component';
import { NewDatasourceDialogComponent } from './new-datasource-dialog/new-datasource-dialog.component';
import { OverrideStyleComponent } from './override-style/override-style.component';
import { ClearTextPipe } from './pipes/clear-text.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { SafePipe } from './pipes/safe-url.pipe';
import { ViewMoleculesVisiblePipe } from './pipes/view-molecules-visible.pipe';
import { PreferencesPanelComponent } from './preferences-panel/preferences-panel.component';
import { PreviewRepresentativeMoleculeComponent } from './preview-representative-molecule/preview-representative-molecule.component';
import { ProcessComponent } from './process/process.component';
import { RemoveCobbleDialogComponent } from './remove-cobble-dialog/remove-cobble-dialog.component';
import { RemoveDatasourceDialogComponent } from './remove-datasource-dialog/remove-datasource-dialog.component';
import { RepresentativePropertiesComponent } from './representative-properties/representative-properties.component';
import { SelectIconDialogComponent } from './select-icon-dialog/select-icon-dialog.component';
import { SelectIconComponent } from './select-icon/select-icon.component';
import { SmartTemplateWizardComponent } from './smart-template-wizard/smart-template-wizard.component';
import { SnapshotsComponent } from './snapshots/snapshots.component';
import { StylePropertiesComponent } from './style-properties/style-properties.component';
import { TextComponent } from './text-component/text-component.component';
import { ThemesPanelComponent } from './themes-panel/themes-panel.component';
import { ToolbarPanelComponent } from './toolbar-panel/toolbar-panel.component';
import { UnifiedDbSetupComponent } from './unified-db-setup/unified-db-setup.component';
import { HistoryListComponent } from './versioning-history/history-list/history-list.component';
import { ViewsPanelComponent } from './views-panel/views-panel.component';
import { ViewsPropertiesComponent } from './views-properties/views-properties.component';
import { WorkareaRoutingModule } from './workarea-routing.module';
import { WorkareaComponent } from './workarea.component';
import { SelectNodesComponent } from './select-nodes/select-nodes.component';
import { GenerationAppActionComponent } from './generation-app-action/generation-app-action.component';
import { GenerationAppProcessComponent } from './generation-app-process/generation-app-process.component';
import { LogPipe } from './pipes/log.pipe';
import { GenerationAppComponent } from './generation-app/generation-app.component';
import { GenerationAppWizardComponent } from './generation-app-wizard/generation-app-wizard.component';
import { InitialAssistComponent } from './initial-assist/initial-assist.component';

@NgModule({
  declarations: [
    CobbleComponent,
    WorkareaComponent,
    CobblesTreeComponent,
    NewCobbleComponent,
    CobblePropertiesComponent,
    MobileLayoutsPanelComponent,
    ToolbarPanelComponent,
    HistoryListComponent,
    DataSourcesPanelComponent,
    CobbleStagesComponent,
    DatasourceDialogComponent,
    EmptyComponent,
    NewDatasourceDialogComponent,
    DataQualityComponent,
    LogDataDialogComponent,
    RemoveCobbleDialogComponent,
    RemoveDatasourceDialogComponent,
    CobbleTreeNodeMenuComponent,
    DataSourcesPanelMenuComponent,
    ShareCobbleConfirmationComponent,
    RepresentativePropertiesComponent,
    JsonTreeComponent,
    ConfirmDialogComponent,
    ProcessComponent,
    DevToolsToolbarComponent,
    BusComponent,
    EventParticleComponent,
    CreateCobbletComponent,
    DatasourceRenameComponent,
    DatasourceRenameSheetComponent,
    CreateCompoundComponent,
    ViewsPanelComponent,
    HierarchySharedComponent,
    EventsPanelComponent,
    CobblePublishedDialogComponent,
    ViewMoleculesVisiblePipe,
    SafePipe,
    ReplacePipe,
    BottomSheetOptionsComponent,
    BusFrameComponent,
    ViewsPropertiesComponent,
    ClearTextPipe,
    DatasourceTreeNameComponent,
    ApiSetupComponent,
    BusLoopComponent,
    UnifiedDbSetupComponent,
    DataProvenanceComponent,
    DataAuditComponent,
    DataDrillDownComponent,
    DocumentationComponent,
    TextComponent,
    EventSourceComponent,
    EventTrackComponent,
    ComponentTreeNodeMenuComponent,
    SmartTemplateWizardComponent,
    PreferencesPanelComponent,
    DatasourceConnectedComponent,
    ApiRegisterComponent,
    PreviewRepresentativeMoleculeComponent,
    CreateStyleComponent,
    ThemesPanelComponent,
    StylePropertiesComponent,
    DatasourceRearrangeComponent,
    AppThemeConfigurationComponent,
    AddParticleToBusComponent,
    SnapshotsComponent,
    InputsDialogComponent,
    DevToolsToolbarComponent,
    DevToolsDataComponent,
    OverrideStyleComponent,
    SelectIconComponent,
    SelectIconDialogComponent,
    CustomControlHelpComponent,
    SelectNodesComponent,
    GenerationAppActionComponent,
    GenerationAppProcessComponent,
    LogPipe,
    GenerationAppComponent,
    GenerationAppWizardComponent,
    InitialAssistComponent,
  ],
  imports: [
    DynamicModule,
    RepresentativeMoleculeModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    VirtualScrollerModule,
    ClickOutsideModule,
    RouterModule,
    WorkareaRoutingModule,
    TreeModule.forRoot(),
    DndModule,
    GridsterModule,
    DynamicFormModule,
    MaterialModule,
    WjNavModule,
    AngularSplitModule,
    FileUploadModule,
    SpreadsheetModule,
    WjGridSheetModule,
    WjGridModule,
    AngularDraggableModule,
    SharedModule,
  ],
  providers: [GenericDialogService, DataQualityDialogService, ApiDataQualityDataService],
  entryComponents: [
    HistoryListComponent,
    DatasourceDialogComponent,
    DataQualityComponent,
    NewDatasourceDialogComponent,
    LogDataDialogComponent,
    ApiSetupComponent,
    UnifiedDbSetupComponent,
    DataProvenanceComponent,
    DataAuditComponent,
    StylePropertiesComponent,
    AppThemeConfigurationComponent,
    DataDrillDownComponent,
    RepresentativePropertiesComponent,
    CreateStyleComponent,
    BusLoopComponent,
    JsonTreeComponent,
    PreferencesPanelComponent,
    EventParticleComponent,
    ApiRegisterComponent,
    BusFrameComponent,
    BusComponent,
    ProcessComponent,
    CreateCobbletComponent,
    DatasourceRenameComponent,
    DatasourceRenameSheetComponent,
    BottomSheetOptionsComponent,
    CreateCompoundComponent,
    RemoveCobbleDialogComponent,
    DevToolsToolbarComponent,
    ShareCobbleConfirmationComponent,
    RemoveDatasourceDialogComponent,
    ConfirmDialogComponent,
    ViewsPanelComponent,
    HierarchySharedComponent,
    EventsPanelComponent,
    ViewsPropertiesComponent,
    DocumentationComponent,
    TextComponent,
    CobblePropertiesComponent,
    EmptyComponent,
    MobileLayoutsPanelComponent,
    DatasourceRearrangeComponent,
    CobblePublishedDialogComponent,
    ToolbarPanelComponent,
    EventSourceComponent,
    EventTrackComponent,
    DatasourceConnectedComponent,
    SmartTemplateWizardComponent,
    SnapshotsComponent,
    InputsDialogComponent,
  ],
  exports: [SmartTemplateWizardComponent, ReplacePipe],
})
export class WorkareaModule {
}
