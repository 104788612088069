import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { Observable, of as observableOf, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IApiAuth } from '../../admin/models/IApiAuth.interface';
import { CobbleUser } from '../../shared/models/cobble-user.model';
import { Company } from '../../shared/models/company';
import { User } from '../../shared/models/user';
import { CommunicationService } from '../../shared/services/communication.service';
import { DraggableWindowManagerService } from '../../shared/services/draggable-window-manager.service';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';
import { WorkAreaService } from '../../workarea/workarea.service';
import { BusService } from '../molecular/services/bus.service';
import { ApiDataSourcesService } from './api-data-sources.service';
import { ApiDataService } from './api-data.service';
import { ClientStorageService } from './client-storage.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ApiAuthService extends ApiDataService {
  userRole = -1;
  aadInterval = null;
  loginUsername = '';
  loginPassword = '';
  subscriptions = new Subscription();
  private usersList: User[] = [];
  private companyList: Company[] = [];
  
  constructor(
    http: HttpClient,
    private router: Router,
    private busService: BusService,
    private dataSourcesService: ApiDataSourcesService,
    errorMessengerService: ErrorMessengerService,
    private clientStorageService: ClientStorageService,
    private workareaService: WorkAreaService,
    private dragableManager: DraggableWindowManagerService,
    private adalSvc: MsAdalAngular6Service,
    private communicationService: CommunicationService,
    private localStorageService: LocalStorageService,
  ) {
    super('accounts', http, errorMessengerService);
    
    // if adal logged renew token when expired
    if (this.clientStorageService.getUserType() === 'external' && !this.adalSvc.isAuthenticated) {
      this.RenewADALToken();
    }
    
    this.subscriptions.add(
      this.communicationService.Event.System.Auth.$LogOutUser.subscribe(data => {
        console.log('=event=');
        console.log('logout user through event');
        this.logout();
      }),
    );
  }
  
  get IsLoggedIn(): boolean {
    return this.loggedIn();
  }
  
  get loggedUser() {
    return this.clientStorageService.getUserName();
  }
  
  getUserId() {
    return this.clientStorageService.getUserId() || 0;
  }
  
  getUser(userName: string): User {
    return this.usersList.find(u => u.userName === userName);
  }
  
  getCompany(companyName: string): Observable<Company> {
    return observableOf(this.companyList.find(c => c.companyName === companyName));
  }
  
  registerCompany(company: Company): Observable<boolean> {
    this.companyList.push(company);
    return observableOf(true);
  }
  
  registerUser(user: User): Observable<boolean> {
    return this.http.post(this.apiEndpointUrl + '/register', user).pipe(
      map(response => <boolean>response),
      catchError(error => this.errorMessengerService.HandleError(error)),
    );
  }
  
  Resend2FACode(userId: number, type: string): Observable<boolean> {
    return this.http
    .post(this.apiEndpointUrl + '/Send2FA', {
      userId,
      type,
    })
    .pipe(
      map(response => <boolean>response),
      catchError(error => this.errorMessengerService.HandleError(error)),
    );
  }
  
  Validate2FACode(userId: number, type: string, code: string): Observable<boolean> {
    return this.http
    .post(this.apiEndpointUrl + '/Validate2FA', {
      userId,
      type,
      token: code,
    })
    .pipe(
      catchError(error => this.handleLoginError(error)),
      map(response => <any>response),
      map(response => {
        const token = response.jwt;
        const user = response.user;
        user.type = 'native';
        if (token) {
          this.clientStorageService.setSessionData(token, user.email, user);
          this.clientStorageService.set2faLogged();
          return user;
        } else if (response.successful) {
          return user;
        } else {
          return false;
        }
      }),
    );
  }
  
  registerCobbleUser(user: CobbleUser): any {
    return this.http
    .post(this.apiEndpointUrl + '/cobbleruserregister', user)
    .pipe(catchError(error => this.errorMessengerService.HandleError(error, `Error registering user ${ user.firstName }.`, user)));
  }
  
  login(userName: string, password: string): Observable<boolean> {
    return this.http
    .post(this.apiEndpointUrl + '/login', {
      email: userName,
      password: password,
    })
    .pipe(
      catchError(error => this.handleLoginError(error)),
      map(response => <any>response),
      map(response => {
        const token = response.jwt;
        const user = response.user;
        user.type = 'native';
        if (token) {
          this.clientStorageService.setSessionData(token, userName, user);
          this.clientStorageService.set2faLogged();
          this.busService.Clear();
          this.communicationService.Event.System.Auth.$UserLoggedIn.emit();
          return user;
        } else if (response.successful) {
          return user;
        } else {
          return false;
        }
      }),
    );
  }
  
  loginCobble() {
    return this.http
    .post(this.apiEndpointUrl + '/CobbleLogin', {
      email: this.loginUsername,
      password: this.loginPassword,
    })
    .pipe(
      map((response: any) => {
        // console.log(response);
        
        if (response.successful) {
          const token = response.jwt;
          const user = response.user;
          this.clientStorageService.setSessionData(token, this.loginUsername, user);
          this.communicationService.Event.System.Auth.$UserLoggedIn.emit();
          if (token) {
            this.clientStorageService.set2faLogged();
          }
        }
        
        return response;
      }),
    );
  }
  
  IsADALAuthenticated() {
    return this.adalSvc.isAuthenticated;
  }
  
  IsADALTokenExpired() {
    if (this.adalSvc.isAuthenticated) {
      return this.IsTokenExpired(this.adalSvc.accessToken);
    } else {
      return true;
    }
  }
  
  DecodeToken(token: string) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  }
  
  externalLogin(token: string): Observable<boolean> {
    this.localStorageService.Set('jwt', this.adalSvc.accessToken);
    return this.http.post(this.apiEndpointUrl + '/ExternalLogin', {}).pipe(
      catchError(error => this.errorMessengerService.HandleError(error, `Error login with external authentication, token: ${ token }.`, token)),
      map(response => <any>response),
      map(response => {
        if (response.successful) {
          response.user.type = 'external';
          if (token) {
            this.clientStorageService.setSessionData(token, response.user.email, response.user);
            this.busService.Clear();
          }
          return response;
        } else {
          this.LogOutUser();
          return response;
        }
      }),
    );
  }
  
  RenewADALToken() {
    const adalKeys = localStorage.getItem('adal.token.keys');
    
    if (adalKeys) {
      const clientId = adalKeys.split('|')[0];
      if (clientId) {
        // custom implementation
        // this.RefreshToken().subscribe(response => {
        //   this.SetADALToken(clientId, (response as any).jwt);
        // });
        
        // adal library implementation
        this.adalSvc
        .acquireToken(clientId)
        .toPromise()
        .then(data => {
          console.log('renew adal token', data);
          this.SetADALToken(clientId, data);
        });
      }
    }
  }
  
  SetADALToken(clientId: string, token: string) {
    localStorage.setItem('adal.access.token.key' + clientId, token);
  }
  
  loggedIn() {
    // console.log(this.clientStorageService.getUserId());
    // console.log(this.clientStorageService.getToken());
    // console.log(this.clientStorageService.isTokenValid());
    
    const evaluate = this.clientStorageService.getUserId() > 0 && this.clientStorageService.isTokenValid() && this.clientStorageService.get2faState();
    
    if (evaluate) {
      return true;
    } else {
      console.log('user not logged');
      return false;
    }
  }
  
  EvaluateDefaultApp() {
    const defaultApp: string = this.clientStorageService.getDefaultApp();
    
    if (defaultApp && defaultApp !== '') {
      let defaultAppUrl = `${ document.location.protocol }//${ document.location.hostname }${
        document.location.hostname.search('localhost') > -1 ? ':4200' : ''
      }/run`;
      
      if (defaultApp && defaultApp != '') {
        if (defaultApp.startsWith('/')) {
          defaultAppUrl += defaultApp;
        } else {
          defaultAppUrl += `/${ defaultApp }`;
        }
      } else {
        return false;
      }
      
      window.location.href = defaultAppUrl;
      return true;
    } else {
      return false;
    }
  }
  
  EvaluateAADLogin() {
    this.aadInterval = setInterval(() => {
      // console.log('evaluating');
      
      if (this.adalSvc.isAuthenticated) {
        clearInterval(this.aadInterval);
        this.aadInterval = null;
        this.communicationService.Event.System.Auth.$AADUserLogged.emit(true);
      }
    }, 500);
  }
  
  LogOutUser(logoutADALPlatform = false): void {
    console.log('logout user');
    
    this.dataSourcesService.setDataSources([]);
    this.dataSourcesService.dataSourcesTree = null;
    this.workareaService.cobbleNodes = [];
    this.dragableManager.hideAll();
    this.clientStorageService.clearSession();
    
    this.LogOutADAL(logoutADALPlatform);
  }
  
  logout(logoutADALPlatform = false): void {
    // console.log('logout');
    
    this.clientStorageService.clearSession();
    
    this.router.navigate(['/']);
    
    console.log('logout');
    setTimeout(() => {
      this.dataSourcesService.setDataSources([]);
      this.dataSourcesService.dataSourcesTree = null;
      this.workareaService.cobbleNodes = [];
      this.dragableManager.hideAll();
      
      this.LogOutADAL(logoutADALPlatform);
    }, 150);
  }
  
  LogOutADAL(logoutADALPlatform = false) {
    if (this.adalSvc.isAuthenticated) {
      if (logoutADALPlatform) {
        this.adalSvc.logout();
      }
    }
    this.ClearAdalData();
  }
  
  ClearAdalData() {
    const values = [];
    const keys = Object.keys(localStorage);
    let i = keys.length;
    
    while (i--) {
      const key = keys[i];
      if (key.includes('adal')) {
        this.localStorageService.Remove(keys[i]);
      }
    }
  }
  
  emailExists(email: string) {
    return this.http.get(this.apiEndpointUrl + '/checkemail/' + email).pipe(
      map(response => <boolean>response),
      catchError(error => this.errorMessengerService.HandleError(error, `Error verifing email existence.`, email)),
    );
  }
  
  getUserByToken(token: string) {
    return this.http.get(this.apiEndpointUrl + `/getUserByToken/${ token }`).pipe(
      map(response => <any>response),
      catchError(error => this.errorMessengerService.HandleError(error, `Error getting user with token: ${ token }.`, token)),
    );
  }
  
  getUserById(id: number) {
    return this.http.get(this.apiEndpointUrl + `/getUserById/${ id }`).pipe(
      map(response => <any>response),
      catchError(error => this.errorMessengerService.HandleError(error, `Error getting user ${ id }.`, id)),
    );
  }
  
  setUserPassword(password: string, token: string) {
    return this.http
    .post(this.apiEndpointUrl + '/setUserPassword', {
      password: password,
      token: token,
    })
    .pipe(
      catchError(error =>
        this.errorMessengerService.HandleError(error, `Error setting password for user with token: ${ token }.`, {
          password: password,
          token: token,
        }),
      ),
    );
  }
  
  changePassword(currentPassword: string, password: string) {
    return this.http
    .post(this.apiEndpointUrl + '/changePassword', {
      currentPassword: currentPassword,
      newPassword: password,
    })
    .pipe(
      catchError(error =>
        this.errorMessengerService.HandleError(error, `Error changing password.`, {
          currentPassword: currentPassword,
          newPassword: password,
        }),
      ),
    );
  }
  
  recoverPassword(email: string) {
    return this.http
    .post(this.apiEndpointUrl + '/recoverPassword', { email: email })
    .pipe(catchError(error => this.errorMessengerService.HandleError(error, 'Error recovering password.', email)));
  }
  
  DeleteAPIAuthentication(authId: number) {
    return this.http.delete(this.apiEndpointUrl + `/DeleteApi/${ authId }`).pipe(
      map(response => <any>response),
      catchError(error => this.errorMessengerService.HandleError(error, `Error deleting api auth`)),
    );
  }
  
  APIRegistration(apiInfo: IApiAuth) {
    // apiInfo.creationDate = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
    // apiInfo.username = 'luis@leapxl.com';
    // apiInfo.secretKey = 'MHj8bfk03UyrX3FJWadKiQ==';
    // apiInfo.secretPassword = 'MHj8bfk03UyrXdd8e82d4-9be7-4a1d-85eb-6c339be0eaff';
    //
    // return of(apiInfo);
    
    return this.http.post(`${ this.apiEndpointUrl }/ApiRegistration`, apiInfo).pipe(
      map(response => <IApiAuth>response),
      catchError(error => this.errorMessengerService.HandleError(error, `Error creating license for user`)),
    );
  }
  
  SetTermsOfServiceAgreement(agreed: boolean) {
    return this.http.put(`${ this.apiEndpointUrl }/HasReadTermsOfServiceAgreement/` + agreed, {}).pipe(
      map(response => <IApiAuth>response),
      catchError(error => this.errorMessengerService.HandleError(error, `Error setting agreement`)),
    );
  }
  
  RegisteredAPIS() {
    // return of([]);
    //
    // return of([{
    //   id: 1,
    //   name: 'LeapXL Interoperability',
    //   description: 'Brian weird connection',
    //   domain: 'autho.interoperability.com:4200',
    //   secretKey: '@#U($c#$@C$R(9#$CcooeCR',
    //   secretPassword: 'V$I#(V$((V$#WVUT($T$(VU($W#V$',
    //   creationDate: '',
    //   username: 'luis@leapxl.com'
    // }, {
    //   id: 2,
    //   name: 'LeapXL Interoperability',
    //   description: 'Brian weird connection',
    //   domain: 'autho.interoperability.com:4200',
    //   secretKey: '@#U($c#$@C$R(9#$CcooeCR',
    //   secretPassword: 'V$I#(V$((V$#WVUT($T$(VU($W#V$',
    //   creationDate: '',
    //   username: 'luis@leapxl.com'
    // }]);
    
    return this.http.get(`${ this.apiEndpointUrl }/RegisteredApis`).pipe(
      map(response => <IApiAuth[]>response),
      catchError(error => this.errorMessengerService.HandleError(error, `Error creating license for user`)),
    );
  }
  
  private IsTokenExpired(token) {
    const decoded = this.DecodeToken(token);
    const isExpired = decoded.exp - Math.floor(Date.now() / 1000) < 0;
    return isExpired;
  }
}
