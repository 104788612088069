import { Injectable } from '@angular/core';
import { AutoGenDEBehavior } from '../../shared/enums/autogen-DEBehavior.enum';
import { AutoGenModelOption } from '../../shared/enums/autogen-model-options.enum';
import { AutoGenSubject } from '../../shared/enums/autogen-subject.enum';
import { AutoGenType } from '../../shared/enums/autogen-type.enum';
import { Orientation } from '../../shared/enums/orientation.enum';
import { DataElement } from '../../shared/representative-molecule/interfaces/data-element';
import { AutoGenAction } from '../../workarea/interfaces/autogen-action.interface';
import { AutoGenDirective } from '../../workarea/interfaces/autogen-directive.interface';
import { AutoGenProcess } from '../../workarea/interfaces/autogen-process.interface';
import { AutoGenTranslation } from '../../workarea/interfaces/autogen-translation.interface';
import { AutoGenTrigger } from '../../workarea/interfaces/autogen-trigger.interface';
import { TemplateService } from './template.service';
import { ToolsService } from './tools.service';

@Injectable({
  providedIn: 'root',
})
export class AutoGenerationTranslatorService {
  constructor(private templateService: TemplateService, private toolsService: ToolsService) {
  }
  
  TranslateDirective(directive: AutoGenDirective) {
    let translations = [];
    directive.processes.forEach(process => {
      this.TranslateProcess(process, translations, directive);
    });
    
    return translations;
  }
  
  TranslateProcess(process: AutoGenProcess, translation: AutoGenTranslation[] = [], directive: AutoGenDirective = null): AutoGenTranslation[] {
    Object.values(AutoGenType)
    .forEach(type => {
      switch (type) {
        case AutoGenType.Create:
          translation = this.TranslateTypeCreate(process, translation, directive);
          break;
        case AutoGenType.Submit:
          translation = this.TranslateTypeSubmit(process, translation, directive);
          break;
        case AutoGenType.Add:
          translation = this.TranslateTypeAdd(process, translation, directive);
          break;
        case AutoGenType.Use:
          translation = this.TranslateTypeUse(process, translation, directive);
          break;
        case AutoGenType.Populate:
          translation = this.TranslateTypePopulate(process, translation, directive);
          break;
        case AutoGenType.Receive:
          translation = this.TranslateTypeReceive(process, translation, directive);
          break;
        case AutoGenType.Show:
          translation = this.TranslateTypeHide(process, translation, directive);
          break;
        case AutoGenType.Hide:
          translation = this.TranslateTypeShow(process, translation, directive);
          break;
        case AutoGenType.Value:
          translation = this.TranslateTypeValue(process, translation, directive);
          break;
        case AutoGenType.Navigate:
          translation = this.TranslateTypeNavigate(process, translation, directive);
          break;
        case AutoGenType.Store:
          translation = this.TranslateTypeStore(process, translation, directive);
          break;
      }
    });
    
    this.SetViewsForTranslations(translation, directive);
    console.log(translation);
    return translation;
  }
  
  SetViewsForTranslations(translations: AutoGenTranslation[], directive: AutoGenDirective) {
    const actionsArray = directive.processes.map(p => p.actions);
    
    actionsArray.forEach(actions => {
      let viewToSet = null;
      
      actions.reverse()
      .forEach((action: AutoGenAction) => {
        if (action.subject.type === AutoGenSubject.View) {
          viewToSet = action.type.type === AutoGenType.Create ? action.id : action.subject.id;
        }
        
        const translation = this.GetTranslation(translations, action.id);
        
        if (translation && viewToSet) {
          translation.view = viewToSet;
        }
      });
    });
  }
  
  CreateSubjectForTranslation(action: AutoGenAction, process: AutoGenProcess, translation: AutoGenTranslation[], addHistory = true) {
    const skipSendToTypes = [AutoGenSubject.View];
    
    const nextAction = this.GetActionByOrder(process, action.order + 1);
    
    const createTranslation: AutoGenTranslation = this.GenerateTranslation({
      process: process.id,
      id: action.id,
      create: action.subject.type,
      entityId: null,
      modelId: action.model?.id,
      createWith: action.dataElements,
      options: action.options,
      submit: [],
      populate: [],
      sendTo: nextAction ? [nextAction.id] : [],
      parentId: null,
      history: [],
      ui: action.ui,
    });
    
    switch (action.subject.type) {
      case AutoGenSubject.Form:
        break;
      case AutoGenSubject.Process:
        break;
      case AutoGenSubject.View:
        const actionsToApplyView = this.GetActionsForViewAction(action, process);
        
        translation
        .filter(t => actionsToApplyView.map(a => a.id)
        .includes(t.id))
        .forEach(t => {
          t.view = action.id;
        });
        
        createTranslation.sendTo = [];
        break;
      case AutoGenSubject.Component:
        break;
      case AutoGenSubject.RepMolecule:
        const repMoleculeTemplate = this.templateService.GetMoleculeTemplateByMoleculeType(
          action.subject.entity);
        createTranslation.entityId = repMoleculeTemplate.id;
        break;
    }
    
    if (nextAction && skipSendToTypes.includes(nextAction.subject.type)) {
      const nextNextAction = this.GetActionByOrder(process, nextAction.id + 1);
      
      if (nextNextAction) {
        createTranslation.sendTo.push(nextNextAction.id);
      }
    }
    
    return addHistory ? this.AddTranslationHistory(createTranslation, createTranslation,
      AutoGenType.Create) : createTranslation;
  }
  
  //region type handlers
  TranslateTypeUse(process: AutoGenProcess, translation: AutoGenTranslation[], directive: AutoGenDirective = null) {
    const useActions = this.GetActionsByType(process, AutoGenType.Use);
    const actionsProcessed = [];
    
    useActions.forEach((action, index) => {
      if (!actionsProcessed.includes(action.id)) {
        switch (action.subject.type) {
          case AutoGenSubject.Action:
            const historyTranslation = this.GenerateTranslation();
            const actionToTrigger = this.GetActionById(process, action.subject.id);
            const previousAction = this.GetActionByOrder(process, action.order - 1);
            
            if (actionToTrigger) {
              const actions = process.actions.filter(a => a.order >= actionToTrigger.order);
              const trigger = this.GetActionTrigger(actions.sort((a1, a2) => a1.order - a2.order));
              console.log(trigger);
              
              const subjectTranslation = this.GetTranslationByIdOrReference(translation, previousAction.id);
              subjectTranslation.onTrigger.push(trigger);
              historyTranslation.onTrigger.push(trigger);
              
              this.AddTranslationHistory(subjectTranslation, historyTranslation, AutoGenSubject.Action);
            }
            
            break;
          case AutoGenSubject.Process:
            break;
          case AutoGenSubject.View:
            const actionsToApplyView = this.GetActionsForViewAction(action, process);
            
            translation
            .filter(t => actionsToApplyView.map(a => a.id)
            .includes(t.id))
            .forEach(t => {
              t.view = action.subject.id > 0 ? action.subject.id : null;
            });
            
            break;
          case AutoGenSubject.Component:
            break;
          case AutoGenSubject.RepMolecule:
          case AutoGenSubject.Actionable:
          case AutoGenSubject.Form:
            if (action.order === 1) {
              let subject: AutoGenTranslation = null;
              
              if (action.subject.type === AutoGenSubject.Actionable) {
                // const subject = this.GetActionByIdFromDirective(directive, action.subject.id);
                
                const actionableTranslation = this.GenerateTranslation();
                actionableTranslation.id = action.subject.id;
                actionableTranslation.process = process.id;
                actionableTranslation.actionDataElements = action.dataElements;
                translation.push(actionableTranslation);
                subject = actionableTranslation;
                
              } else {
                subject = this.GetTranslationByIdOrReference(translation, action.subject.id);
              }
              
              if (subject) {
                const nextOrder = action.order + 1;
                
                const nextAction = this.GetActionByOrder(process, nextOrder);
                if (!nextAction) return;
                
                actionsProcessed.push(nextAction.id);
                
                const historyTranslation = this.GenerateTranslation();
                this.GenerateAndAddTrigger(subject, nextAction, historyTranslation);
                subject.actionDataElements = nextAction.dataElements;
                
                const nextNextAction = this.GetActionByOrder(process, nextAction.order + 1);
                const isNextNextUseAction = nextNextAction?.type.type === AutoGenType.Use;
                
                if (isNextNextUseAction) {
                  actionsProcessed.push(nextNextAction.id);
                  this.GenerateAndAddTrigger(subject, nextNextAction, historyTranslation);
                }
                
                this.AddTranslationHistory(subject, historyTranslation, AutoGenType.Use);
              }
            }
            break;
          case AutoGenSubject.Store:
            break;
        }
      }
    });
    
    return translation;
  }
  
  GenerateAndAddTrigger(subject: AutoGenTranslation, action: AutoGenAction, historyTranslation: AutoGenTranslation) {
    const trigger = {
      id: null,
      type: action.type.type,
      toSubject: action.subject.id || action.id,
      trigger: [],
    };
    
    if (action.type.type === AutoGenType.Submit) {
      subject.submit = subject.submit.concat(action.dataElements);
    }
    
    subject.onTrigger.push(trigger);
    historyTranslation.onTrigger.push(trigger);
  }
  
  GetActionTrigger(actions: AutoGenAction[], triggersAdded: AutoGenTrigger[] = [], directive: AutoGenDirective = null) {
    const actionTrigger: AutoGenTrigger = {
      id: null,
      type: null,
      toSubject: null,
      trigger: [],
    };
    
    const behaviorActions = [AutoGenType.Use, AutoGenType.Navigate, AutoGenType.Show, AutoGenType.Hide,
      AutoGenType.Submit];
    const action = actions.shift();
    
    if (behaviorActions.includes(action.type.type)) {
      actionTrigger.type = action.type.type;
      actionTrigger.toSubject = action.subject?.id || action.id;
    } else {
      actionTrigger.type = AutoGenType.Use;
      actionTrigger.toSubject = action.subject?.id || action.id;
    }
    
    triggersAdded.push(actionTrigger);
    
    if (actions.length > 0 && actions[0].subject.type !== AutoGenSubject.Action && actions[0].subject.type !== AutoGenSubject.Process) {
      const nextActionId = actions[0].subject?.id || actions[0].id;
      
      if (triggersAdded.map(t => t.toSubject)
      .includes(nextActionId)) {
        return actionTrigger;
      }
      
      const newTrigger = this.GetActionTrigger(actions, triggersAdded);
      actionTrigger.trigger.push(newTrigger);
      return actionTrigger;
    } else {
      return actionTrigger;
    }
  }
  
  TranslateTypeStore(process: AutoGenProcess, translation: AutoGenTranslation[], directive: AutoGenDirective = null) {
    const storeActions = this.GetActionsByType(process, AutoGenType.Store);
    
    storeActions.forEach(action => {
      const previousAction = this.GetActionByOrder(process, action.order - 1);
      const actionTranslation = this.GetTranslationByIdOrReference(translation, previousAction.id);
      
      if (actionTranslation) {
        actionTranslation.referenceActionIds.push(action.id);
      }
    });
    
    return translation;
  }
  
  TranslateTypeCreate(process: AutoGenProcess, translation: AutoGenTranslation[], directive: AutoGenDirective = null) {
    const createActions = this.GetActionsByType(process, AutoGenType.Create);
    
    const nonViewActions = createActions.filter(a => a.subject.type !== AutoGenSubject.View);
    const viewActions = createActions.filter(a => a.subject.type === AutoGenSubject.View);
    
    nonViewActions.forEach((action, index) => {
      translation.push(this.CreateSubjectForTranslation(action, process, translation));
    });
    viewActions.forEach((action, index) => {
      translation.push(this.CreateSubjectForTranslation(action, process, translation));
    });
    
    return translation;
  }
  
  TranslateTypeHide(process: AutoGenProcess, translation: AutoGenTranslation[], directive: AutoGenDirective = null) {
    const toggleActions = this.GetActionsByType(process, AutoGenType.Hide);
    
    toggleActions.forEach((action, index) => {
      this.TranslateTriggerProcess(action, process, translation);
    });
    
    return translation;
  }
  
  TranslateTypeShow(process: AutoGenProcess, translation: AutoGenTranslation[], directive: AutoGenDirective = null) {
    const toggleActions = this.GetActionsByType(process, AutoGenType.Show);
    
    toggleActions.forEach((action, index) => {
      this.TranslateTriggerProcess(action, process, translation);
    });
    
    return translation;
  }
  
  TranslateTypeValue(process: AutoGenProcess, translation: AutoGenTranslation[], directive: AutoGenDirective = null) {
    const toggleActions = this.GetActionsByType(process, AutoGenType.Value);
    
    toggleActions.forEach((action, index) => {
      this.TranslateTriggerProcess(action, process, translation);
    });
    
    return translation;
  }
  
  TranslateTypeNavigate(process: AutoGenProcess, translation: AutoGenTranslation[], directive: AutoGenDirective = null) {
    const navigateActions = this.GetActionsByType(process, AutoGenType.Navigate);
    
    navigateActions.forEach((action, index) => {
      const previousAction = this.GetActionByOrder(process, action.order - 1);
      
      if (previousAction && previousAction.type.type === AutoGenType.Use) {
        this.TranslateTriggerProcess(action, process, translation);
      }
    });
    
    return translation;
  }
  
  TranslateTypeSubmit(process: AutoGenProcess, translation: AutoGenTranslation[], directive: AutoGenDirective = null) {
    const submitActions = this.GetActionsByType(process, AutoGenType.Submit);
    
    submitActions.forEach((action, index) => {
      const subject = this.GetActionByOrder(process, action.order - 1);
      if (subject.type.type !== AutoGenType.Use) {
        let actionToTrigger = action.subject.id ? this.GetActionByIdFromDirective(directive,
          action.subject.id) : subject;
        const subjectTranslation = this.GetTranslation(translation, subject.id, subject.subject.type);
        const actionToTriggerTranslation = this.GetTranslation(translation, actionToTrigger.id,
          actionToTrigger.subject.type);
        const translationChange = this.GenerateTranslation();
        
        if (subjectTranslation) {
          switch (subjectTranslation.create) {
            case AutoGenSubject.Form:
              subjectTranslation.submit = subjectTranslation.submit.concat(action.dataElements);
              translationChange.submit = subjectTranslation.submit.concat(action.dataElements);
              break;
            case AutoGenSubject.Process:
              break;
            case AutoGenSubject.View:
              break;
            case AutoGenSubject.Component:
              break;
            case AutoGenSubject.RepMolecule:
              subjectTranslation.onTrigger.push({
                id: null,
                trigger: [],
                type: AutoGenType.Submit,
                toSubject: actionToTriggerTranslation.id,
              });
              subjectTranslation.submit = subjectTranslation.submit.concat(action.dataElements);
              actionToTriggerTranslation.sendTo = actionToTriggerTranslation.sendTo.concat(
                [action.order + 1]);
              translationChange.submit = subjectTranslation.submit.concat(action.dataElements);
              break;
          }
          
          subjectTranslation.referenceActionIds.push(action.id);
          subjectTranslation.sendTo = [action.order + 1];
          translationChange.sendTo = [action.order + 1];
          this.AddTranslationHistory(subjectTranslation, translationChange, AutoGenType.Submit);
          
          const nextAction = this.GetActionByOrder(process, action.order + 1);
          const updateTranslation = this.GenerateTranslation();
          
          if (nextAction && nextAction.type.type === AutoGenType.Store) {
            subjectTranslation.sendTo = subjectTranslation.sendTo.concat(nextAction.order + 1);
            subjectTranslation.saveResultIn = nextAction.id;
            updateTranslation.saveResultIn = nextAction.id;
            subjectTranslation.referenceActionIds.push(nextAction.id);
            
            this.AddTranslationHistory(subjectTranslation, updateTranslation, AutoGenType.Store);
          }
        }
      }
    });
    
    return translation;
  }
  
  TranslateTypeAdd(process: AutoGenProcess, translation: AutoGenTranslation[], directive: AutoGenDirective = null) {
    const addActions = this.GetActionsByType(process, AutoGenType.Add);
    
    addActions.forEach((action, index) => {
      const createTranslation = this.CreateSubjectForTranslation(action, process, translation, false);
      
      if (action.subject.id) {
        createTranslation.parentId = action.subject.id;
      } else {
        const previousAction = this.GetActionByOrder(process, action.order - 1);
        createTranslation.parentId = previousAction?.id;
      }
      
      createTranslation.referenceActionIds.push(action.id);
      translation.push(this.AddTranslationHistory(createTranslation, createTranslation, AutoGenType.Add));
    });
    
    return translation;
  }
  
  TranslatePopulateProcess(
    action: AutoGenAction,
    process: AutoGenProcess,
    translation: AutoGenTranslation[],
    directive: AutoGenDirective = null,
    addHistory = true,
  ) {
    const subject = this.GetActionByIdFromDirective(directive, action.subject.id);
    
    if (subject) {
      const createTranslation = this.GetTranslation(translation, subject.id, subject.subject.type);
      const translationChange = this.GenerateTranslation();
      
      switch (action.subject.type) {
        case AutoGenSubject.Form:
          createTranslation.populate = createTranslation.populate.concat(action.dataElements);
          translationChange.populate = translationChange.populate.concat(action.dataElements);
          break;
        case AutoGenSubject.Process:
          break;
        case AutoGenSubject.View:
          break;
        case AutoGenSubject.Component:
          break;
        case AutoGenSubject.RepMolecule:
          createTranslation.createWith = [];
          translationChange.createWith = [];
          createTranslation.populate = createTranslation.populate.concat(action.dataElements);
          translationChange.populate = translationChange.populate.concat(action.dataElements);
          break;
      }
      
      createTranslation.referenceActionIds.push(action.id);
      
      // if populate a different subject then do not move data forward for that subject
      if (subject.id === action.id) {
        createTranslation.sendTo = [action.order + 1];
        translationChange.sendTo = [action.order + 1];
      }
      
      if (addHistory) {
        this.AddTranslationHistory(createTranslation, translationChange, AutoGenType.Populate);
      }
      
      // translation.push(addHistory ? this.AddTranslationHistory(createTranslation, translationChange, AutoGenType.Populate) : createTranslation);
    } else {
      const createTranslation = this.CreateSubjectForTranslation(action, process, translation, false);
      createTranslation.referenceActionIds.push(action.id);
      createTranslation.populate = action.dataElements;
      createTranslation.createWith = [];
      translation.push(addHistory ? this.AddTranslationHistory(createTranslation, createTranslation,
        AutoGenType.Populate) : createTranslation);
    }
  }
  
  TranslateTriggerProcess(action: AutoGenAction, process: AutoGenProcess, translation: AutoGenTranslation[], directive: AutoGenDirective = null) {
    const previousAction = this.GetActionByOrder(process, action.order - 1);
    const subjectTranslation = this.GetTranslation(translation, previousAction.id);
    const changeTranslation = this.GenerateTranslation();
    
    if (subjectTranslation && subjectTranslation.create === AutoGenSubject.RepMolecule) {
      subjectTranslation.referenceActionIds.push(action.id);
      subjectTranslation.onTrigger.push({
        id: null,
        type: action.type.type,
        toSubject: action.subject.id,
        trigger: [],
      });
      subjectTranslation.actionDataElements = action.dataElements;
      
      this.AddTranslationHistory(subjectTranslation, changeTranslation, action.type.type);
    }
    
    return translation;
  }
  
  TranslateTypePopulate(process: AutoGenProcess, translation: AutoGenTranslation[], directive: AutoGenDirective = null) {
    const populateActions = this.GetActionsByType(process, AutoGenType.Populate);
    
    populateActions.forEach((action, index) => {
      this.TranslatePopulateProcess(action, process, translation, directive);
    });
    
    return translation;
  }
  
  TranslateTypeReceive(process: AutoGenProcess, translation: AutoGenTranslation[], directive: AutoGenDirective = null) {
    let receiveActions = this.GetActionsByType(process, AutoGenType.Receive);
    
    // if (directive) {
    //   receiveActions = [];
    //
    //   directive.processes.forEach(process => {
    //     receiveActions = receiveActions.concat(this.GetActionsByType(process, AutoGenType.Receive));
    //   });
    //
    // }
    
    receiveActions.forEach((action, index) => {
      const receiveFrom = this.GetActionByIdFromDirective(directive, action.type.id);
      const changeTranslation = this.GenerateTranslation();
      
      if (receiveFrom) {
        const translateReceiveFrom = this.GetTranslation(translation, receiveFrom.type.id);
        translateReceiveFrom.sendTo = translateReceiveFrom.sendTo.concat(action.subject.id || action.id);
        translateReceiveFrom.referenceActionIds.push(action.id);
        changeTranslation.sendTo = changeTranslation.sendTo.concat(action.subject.id);
        this.AddTranslationHistory(translateReceiveFrom, changeTranslation, AutoGenType.Receive);
      }
      
      const nextAction = this.GetActionByOrder(process, action.order + 1);
      
      // if receive does not have DE selected and next action is use, it's considered to trigger some action when
      // response is received
      if (action.dataElements.length === 0 && nextAction && nextAction.type.type === AutoGenType.Use) {
        const receiving = this.GetTranslation(translation, nextAction.type.id);
        const changeTranslation = this.GenerateTranslation();
        
        const receive = {
          id: null,
          type: nextAction.type.type,
          toSubject: nextAction.subject.id || nextAction.id,
          trigger: [],
        };
        
        receiving.onReceive.push(receive);
        changeTranslation.onReceive.push(receive);
        this.AddTranslationHistory(receiving, changeTranslation, AutoGenType.Receive);
      }
      
      this.TranslatePopulateProcess(action, process, translation, directive, false);
    });
    
    return translation;
  }
  
  //endregion
  
  //region tools
  GetActionsForViewAction(action: AutoGenAction, process: AutoGenProcess): AutoGenAction[] {
    const orderEnd = action.order;
    let previousViewFound = false;
    let orderStart = 1;
    
    process.actions
    .slice()
    .reverse()
    .forEach(action => {
      if (action.order < orderEnd) {
        if (action.subject.type === AutoGenSubject.View) {
          previousViewFound = true;
        }
        
        if (!previousViewFound) {
          orderStart = action.order;
        }
      }
    });
    
    return process.actions.filter(a => a.order >= orderStart && a.order < orderEnd);
  }
  
  AddTranslationHistory(translation: AutoGenTranslation, update: AutoGenTranslation, modifier: string) {
    translation.history.push({
      modifier: modifier,
      change: update,
    });
    
    return translation;
  }
  
  GenerateTranslation(translation: any = {}): AutoGenTranslation {
    const emptyTranslation: AutoGenTranslation = {
      process: null,
      id: null,
      create: null,
      modelId: null,
      entityId: null,
      createWith: [],
      submit: [],
      populate: [],
      sendTo: [],
      parentId: null,
      history: [],
      view: 0,
      onTrigger: [],
      onReceive: [],
      saveResultIn: null,
      actionable: [],
      referenceActionIds: [],
      actionDataElements: [],
      options: {
        dataElementsBehavior: AutoGenDEBehavior.Join,
      },
      ui: {
        modelOptions: AutoGenModelOption.Standalone,
        orientation: Orientation.Vertical,
        padding: 7,
        separation: 4,
        columns: 0,
        addons: {
          hidden: false,
          autoStart: false,
          header: false,
          readOnly: false,
        },
      },
    };
    
    return {
      ...emptyTranslation,
      ...translation,
    };
  }
  
  GetActionsByType(process: AutoGenProcess, type: AutoGenType) {
    return process.actions.filter(a => a.type.type === type);
  }
  
  GetActionById(process: AutoGenProcess, id: number) {
    return process.actions.find(a => a.id === id);
  }
  
  GetActionByIdFromDirective(directive: AutoGenDirective, id: number) {
    const actions: AutoGenAction[] = (directive.processes.map(p => p.actions) as any).flat();
    return actions.find(a => a.id === id);
  }
  
  GetActionByOrder(process: AutoGenProcess, order: number) {
    return process.actions.find(a => a.order === order);
  }
  
  GetTranslationByIdOrReference(translation: AutoGenTranslation[], id: number, type: AutoGenSubject = null): AutoGenTranslation {
    let translationFound = this.GetTranslation(translation, id, type);
    
    if (translationFound) {
      return translationFound;
    }
    
    translationFound = translation.find(t => t.referenceActionIds.includes(id));
    
    if (translationFound) {
      return translationFound;
    }
    
    for (let i = id; i > 0; i--) {
      translationFound = this.GetTranslation(translation, i, type);
      
      if (translationFound) {
        return translationFound;
      }
    }
  }
  
  GetTranslation(translation: AutoGenTranslation[], id: number, type: AutoGenSubject = null) {
    if (type) {
      return translation.find(t => t.create === type && t.id === id);
    } else {
      return translation.find(t => t.id === id);
    }
  }
  
  //endregion
  
  //region raw auto generation translators
  
  TranslateText(instructions: string): AutoGenProcess[] {
    const processArr = instructions.match(/\{([^}]+)\}/g)
    ?.map(match => match.slice(1, -1));
    const processes = this.TranslateTextProcess(processArr ? processArr : [instructions]);
    console.warn(processes);
    return processes;
  }
  
  TranslateTextProcess(processArr: string[]): AutoGenProcess[] {
    const process = processArr.map(process => {
      const actionArr = process.split('->');
      const actions = this.TranslateTextAction(actionArr);
      
      return {
        id: 1,
        actions,
      } as AutoGenProcess;
    });
    
    return process;
  }
  
  TranslateTextAction(actionArr: string[]): AutoGenAction[] {
    let order = 0;
    
    const actions = actionArr.map(action => {
      order++;
      
      const cleanAction = (action as any).replaceAll(' ', '')
      .replaceAll('\n', '')
      .trim();
      const withoutOuterBrackets = cleanAction.slice(1, -1);
      const parts = withoutOuterBrackets.split('][');
      
      const result = parts.map(part => {
        const withoutBrackets = part.replace(/\[|\]/g, '');
        const elements = withoutBrackets.split(', ');
        return elements[0];
      });
      
      const dataElementsStr = result[0].split(',');
      const dataElements = dataElementsStr.map(dataElement => new DataElement().FromContext(dataElement));
      
      const type = result[1].split(':');
      const typeId = type[1];
      
      const subject = result[2].split(':');
      const subjectType = subject[0];
      const subjectId = subject[1];
      
      const repMoleculeType = result[3] || null;
      
      const dataElementsBehavior = result[4];
      
      const model = result[5].split(':');
      const modelId = model[1];
      const modelType = model[0];
      
      const ui = result[6];
      
      const uiProperties = ui.replace('[', '')
      .replace(']', '')
      .split(',');
      const uiObject = {};
      
      uiProperties.forEach(p => {
        const pSplitted = p.split(':');
        const pName = pSplitted[0];
        const pValue = pSplitted[1];
        
        this.toolsService.AddPathValueToObject(pName, pValue, uiObject);
      });
      
      return {
        id: 1,
        order,
        type: {
          id: typeId ? Number(typeId) : null,
          type: type[0],
        },
        subject: {
          id: subjectId ? Number(subjectId) : null,
          type: subjectType,
          entity: repMoleculeType,
        },
        options: {
          dataElementsBehavior: dataElementsBehavior,
        },
        model: {
          id: modelId ? +modelId : null,
          type: modelType,
        },
        ui: uiObject,
        dataElements,
      } as AutoGenAction;
    });
    
    return actions;
  }
  
  //endregion
}
