import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableRowEmptyValueGenerator',
})
export class TableRowEmptyValueGeneratorPipe implements PipeTransform {
  transform(
    row: {
      row: number;
      col: number;
      value: string;
      formula: any;
      inRange: boolean;
    }[],
    cols: number,
    colsCount: {
      firstCol: number,
      lastCol: number,
    },
    header: boolean = false,
  ): any {
    if (row === undefined) {
      return row;
    }
    
    if (header && cols === 0) {
      const colsHeaders = {};
      const headers = row.filter(r => r.row === 0);
      let rowHeaders = null;
      if (headers.length > 0) {
        rowHeaders = headers;
      } else {
        rowHeaders = row.filter(r => r.row === 1);
      }
      return rowHeaders;
    }
    
    
    if (row.length === cols) {
      return row;
    } else {
      const newRow = [];
      
      
      for (let colIdx = colsCount.firstCol; colIdx <= colsCount.lastCol; colIdx++) {
        const rowValue = row.find(
          (r) => r !== undefined && r.col === colIdx,
        );
        
        if (rowValue) {
          newRow.push(rowValue);
        } else {
          newRow.push({
            row: row[0] ? row[0].row : 0,
            col: colIdx,
            value: '',
            context: '',
            formattedValue: '',
            formula: '',
          });
        }
      }
      return newRow;
    }
  }
}
