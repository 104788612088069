import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { faDatabase, faFileExcel, faServer } from '@fortawesome/free-solid-svg-icons';
import * as wjcCore from '@grapecity/wijmo';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import * as wjcGridSheet from '@grapecity/wijmo.grid.sheet';
import { IActionMapping, KEYS, TREE_ACTIONS, TreeComponent, TreeModel } from 'angular-tree-component';
import { ITreeNode, TreeNode } from 'angular-tree-component/dist/defs/api';
import { CommunicationService } from 'app/shared/services/communication.service';
import { cloneDeep } from 'lodash-es';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, flatMap, map } from 'rxjs/operators';
import { BusService } from '../../core/molecular/services/bus.service';
import { ApiDataSourcesService } from '../../core/services/api-data-sources.service';
import { AutoGenerationService } from '../../core/services/autogeneration.service';
import { CacheService } from '../../core/services/cache.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { EditorStateService } from '../../core/services/editor-state.service';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { ToolsService } from '../../core/services/tools.service';
import { Constants } from '../../shared/constants';
import { DataElementDirection } from '../../shared/enums/data-element-direction.enum';
import { DatasourceTypeId } from '../../shared/enums/datasource-type-id.enum';
import { DatasourceType } from '../../shared/enums/datasource-type.enum';
import { DragType } from '../../shared/enums/drag-type.enum';
import { ParticleType } from '../../shared/enums/particle-type.enum';
import { DataSourceTreeNodeElement } from '../../shared/interfaces/datasource-tree-node';
import { DataElement } from '../../shared/representative-molecule/interfaces/data-element';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { DragService } from '../../shared/representative-molecule/services/drag.service';
import { ConnectionStateService } from '../../shared/services/connection-state.service';
import { DraggableWindowManagerService } from '../../shared/services/draggable-window-manager.service';
import { DraggableWindowService, DraggableWindowType } from '../../shared/services/draggable-window.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { DataSource, SpreadsheetService } from '../../spreadsheet/spreadsheet.service';
import { DatasourceDialogComponent } from '../datasource-dialog/datasource-dialog.component';
import { GenerationAppWizardComponent } from '../generation-app-wizard/generation-app-wizard.component';
import { GenerationAppComponent } from '../generation-app/generation-app.component';
import { NewDatasourceDialogComponent } from '../new-datasource-dialog/new-datasource-dialog.component';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'data-sources-panel',
  templateUrl: './data-sources-panel.component.html',
  styleUrls: ['./data-sources-panel.component.scss'],
})
export class DataSourcesPanelComponent implements OnInit, OnDestroy {
  @ViewChild('newCustomValueInput', { static: false })
  newCustomValueInput: ElementRef;
  @ViewChild('newLocalStoreInput', { static: false })
  newLocalStoreInput: ElementRef;
  @ViewChild('newCustomValueNameInput', { static: false })
  newCustomNameValueInput: ElementRef;
  @ViewChild('editNodeCategory', { static: false })
  editNodeCategory: ElementRef;
  @ViewChild('leapXLTree', { static: false })
  leapxlTreeComponent: TreeComponent;
  @ViewChild('systemTree', { static: false })
  systemTreeComponent: TreeComponent;
  @ViewChild('internetMessagingTree', { static: false })
  internetMessagingTreeComponent: TreeComponent;
  @ViewChild('apiTree', { static: false })
  apiTreeComponent: TreeComponent;
  @ViewChild('dsTree', { static: false })
  spreadsheetTreeComponent: TreeComponent;
  @ViewChild('unifiedDbTree', { static: false })
  unifiedDbTreeComponent: TreeComponent;
  @ViewChild('customTree', { static: false })
  customTreeComponent: TreeComponent;
  @ViewChild('localDataStoreTree', { static: false })
  localDataStoreTreeComponent: TreeComponent;
  @ViewChild(MatMenuTrigger, { static: false })
  apiTreeMenuTrigger: MatMenuTrigger;
  
  DatasourceTypeId = DatasourceTypeId;
  
  dsTrees = {};
  
  systemSectionLoading = false;
  apiSectionLoading = false;
  leapXLSectionLoading = false;
  customSectionLoading = false;
  spreadsheetsSectionLoading = false;
  databaseSectionLoading = false;
  unifiedDatabaseSectionLoading = false;
  localDataStoreSectionLoading = false;
  internetMessagingSectionLoading = false;
  
  pinnedDsId = 0;
  pinnedDsNode = null;
  apiFilteringSearch = false;
  spreadsheetIcon = faFileExcel;
  databaseIcon = faDatabase;
  unifiedDbIcon = faServer;
  spreadsheetNodes: any[] = [];
  inDebounce = null;
  systemNodes: any[] = [];
  leapXLNodes: any[] = [];
  apiNodes: any[] = [];
  customNodes: any[] = [];
  dbNodes: any[] = [];
  unifiedDbNodes: any[] = [];
  internetMessagingNodes: any[] = [];
  localDataStoreNodes: any[] = [];
  loading = true;
  customValues: DataSourceTreeNodeElement[] = [];
  localDataStoreValues: DataSourceTreeNodeElement[] = [];
  subscription: Subscription;
  public datasourceSearch = new Subject<any>();
  systemVariables = [];
  renameCategory = false;
  createNewCustomValue = false;
  createNewLocalStoreValue = false;
  focusedNode: TreeNode;
  editCustomValue = false;
  editLocalStoreValue = false;
  editCustomValueId = 0;
  newLocalStoreValue = '';
  newCustomValue = {
    name: '',
    value: '',
  };
  newCustomValueName = '';
  public apiSearch = new Subject<any>();
  categoryAddBtnEnable = true;
  editNode = null;
  editCategoryName = '';
  newNode = null;
  newNodeId = 919191919191919;
  addingCategory = false;
  DataElementDirection = DataElementDirection;
  dataSourceTypeIdsEnabled = [];
  DataSourceType = DatasourceType;
  DataSourceTypeId = DatasourceTypeId;
  usedDataSourcesIds: { dataSourceId: number; context: string }[] = [];
  highlightClassIdentifier = 'ds-highlighted';
  dataSourcesLoaded = {};
  isSelfProvisioning = false;
  nodesToExpand = {};
  scrollToNode = null;
  reopenTree = {
    enable: false,
    found: null,
    id: null,
    name: '',
    path: '',
  };
  userId = null;
  hideNotOwnedDataSources = false;
  hideUnusedDataSources = false;
  windowHeight = 0;
  customDatasourceContext = '';
  lastUsedDataSources = {
    spreadsheet: '',
    database: '',
    api: '',
    system: '',
    leapxl: '',
    custom: '',
    localdatastore: '',
    unifiedatabase: '',
    internetmessaging: '',
  };
  
  actionMapping: IActionMapping = {
    mouse: {
      click: (tree, node, $event) => {
        this.RefreshUI();
        this.communicationService.Event.Editor.$WorkAreaDetectionDisableFor.emit(300);
        node.expand();
        this.RefreshUI();
        // console.log('node', node.data);
        
        if (node.data.isBeingUsed) {
          const nodeContext = `${ node.data.path }${ Constants.ContextSeparator }${ node.data.name }`;
          this.busService.PulseRepresentativeMoleculesWithContext(nodeContext);
          this.RefreshUI();
        }
        
        if (
          node.data.dataSourceId &&
          node.data.dataSourceId > 0 &&
          node.data.isLastBranchParent &&
          node.data.dataSourceType !== DatasourceType.Spreadsheet
        ) {
          this.OpenNodeInSpreadhseetPanel(node);
          this.RefreshUI();
        }
      },
      dragStart: (tree, node, $event) => {
        this.RefreshUI();
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
        console.log('node', node);
        const contexts = [];
        
        // if (node.data.isLastBranchParent) {
        if (
          (!node.data.isDatasource && node.data.children.length > 0) ||
          (node.data.index === 0 && node.data.dataSourceType === DatasourceType.InternetMessaging)
        ) {
          node.data.children.forEach(child => {
            contexts.push({
              context: `${ child.path }${ Constants.ContextSeparator }${ child.name }`,
              dataSourceId: child.dataSourceId,
              required: child.required,
              isTemplate: child.isTemplate,
              isVolunteer: child.isVolunteer,
            });
          });
          this.EventDragStartHandler(contexts, node.data.dataSourceType);
          this.RefreshUI();
        } else {
          if (node.data.dataSourceType === DatasourceType.Spreadsheet) {
            const dataKey = 'externalDatasource';
            this.dataSourcesService.dataSourceDataRequest[dataKey] = this.dataSourcesService.GetDataSourceRequestForKey(
              dataKey);
            
            this.dataSourcesService.dataSourceDataRequest[dataKey].contexts = [
              [`${ node.data.path }${ Constants.ContextSeparator }${ node.data.name }${ Constants.ContextSeparator }1`],
            ];
            this.dataSourcesService.dataSourceDataRequest[dataKey].translationIds = [];
            this.dataSourcesService.GetDataSourceData(dataKey)
            .subscribe(response => {
              response.dataElements.forEach(de => {
                de.col = de.col - 1;
              });
              const range = `A1:${ this.toolsService.ColumnIndexToName(response.dataElements.length) }1`;
              
              const dataSource = new DataSource();
              dataSource.collection = node.data.name;
              dataSource.isTemplate = node.data.isTemplate;
              dataSource.cols = response.dataElements.length;
              dataSource.context = `${ node.data.path }${ Constants.ContextSeparator }${ node.data.name }${ Constants.ContextSeparator }${ range }`;
              dataSource.dataItems = response.dataElements;
              dataSource.dataSourceId = node.data.dataSourceId;
              dataSource.dataSourceName = node.data.path.split(Constants.ContextSeparator)[1];
              dataSource.dataSourceType = DatasourceType.Spreadsheet;
              dataSource.dataSourceTypeName = DatasourceType.Spreadsheet;
              dataSource.firstColIndex = 0;
              dataSource.lastColIndex = response.dataElements.length - 1;
              dataSource.firstRowIndex = 0;
              dataSource.lastRowIndex = 0;
              dataSource.rows = 1;
              dataSource.range = range;
              
              this.SetDragStartForSpreadsheet(dataSource);
              this.RefreshUI();
            });
          } else if (node.data.path.split(Constants.ContextSeparator)[1] === node.data.name) {
            contexts.push({
              context: `${ node.data.path }`,
              dataSourceId: node.data.dataSourceId,
              required: node.data.required,
              isTemplate: node.data.isTemplate,
              isVolunteer: node.data.isVolunteer,
            });
            this.EventDragStartHandler(contexts, node.data.dataSourceType);
            this.RefreshUI();
          } else {
            contexts.push({
              context: `${ node.data.path }${ Constants.ContextSeparator }${ node.data.name }`,
              dataSourceId: node.data.dataSourceId,
              required: node.data.required,
              isTemplate: node.data.isTemplate,
              isVolunteer: node.data.isVolunteer,
            });
            this.EventDragStartHandler(contexts, node.data.dataSourceType);
            this.RefreshUI();
          }
        }
      },
      dragEnd: (tree, node, $event) => {
        this.RefreshUI();
        this.communicationService.Event.Editor.$WorkAreaDetectionDisableFor.emit(200);
        this.SetDragEnd();
        this.RefreshUI();
      },
      drop: (tree: TreeModel, node: TreeNode, $event: any, { from, to }) => {
        to.parent.expand();
        this.RefreshUI();
        
        console.log(from, to);
        const nodeId = from.data.id;
        if (to.parent.data.id !== nodeId) {
          this.dataSourcesService.AssingNodeToNode(to.parent.data.id, nodeId, from.data.dataSourceId)
          .subscribe(Response => {
            if (to.parent.data.dataSourceType === DatasourceType.Api) {
              const apiNode = this.CreateChildrenNode(Response.node, [], DatasourceTypeId.Api, '');
              this.apiNodes = apiNode.children;
              this.reopenTree.enable = true;
              this.reopenTree.id = from.data.id;
              this.reopenTree.name = from.data.name;
              this.reopenTree.path = '';
              this.RefreshDataSource(DatasourceTypeId.Api);
            }
            
            this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
            this.RefreshUI();
          });
          return TREE_ACTIONS.MOVE_NODE(tree, node, $event, { from, to });
        }
      },
    },
    keys: {
      [KEYS.RIGHT]: null,
      [KEYS.LEFT]: null,
      [KEYS.DOWN]: null,
      [KEYS.UP]: null,
      [KEYS.SPACE]: null,
      [KEYS.ENTER]: null,
    },
  };
  options = {
    actionMapping: this.actionMapping,
    animateExpand: false,
    animateSpeed: 20,
    animateAcceleration: 1.2,
    scrollContainer: document.querySelector(`#leftSplitArea .mat-tab-body-content`),
    allowDrop: (node, event) => {
      return event.parent.data.dataSourceId === null;
    },
    allowDrag: node => true,
  };
  
  apiTreeOptions = {
    ...this.options,
    useCheckbox: true,
  };
  selectedNodes: TreeNode[] = [];
  removeAPIsLabel = '';
  @ViewChild(TreeComponent, { static: true })
  private tree: TreeComponent;
  
  constructor(
    public dataSourcesService: ApiDataSourcesService,
    private spreadsheetService: SpreadsheetService,
    private cobbleService: CobbleService,
    private snackerService: SnackerService,
    private connectionStateService: ConnectionStateService,
    private busService: BusService,
    private toolsService: ToolsService,
    private dragService: DragService,
    private autoGenerationService: AutoGenerationService,
    private genericDialogService: GenericDialogService,
    private draggableWindowService: DraggableWindowService,
    private draggableWindowManagerService: DraggableWindowManagerService,
    public workareaService: WorkAreaService,
    private changeDetectorRef: ChangeDetectorRef,
    public communicationService: CommunicationService,
    private cacheService: CacheService,
    private editorStateService: EditorStateService,
    private clientStorageService: ClientStorageService,
    private angularZone: NgZone,
    private renderer: Renderer2,
    private dialogService: GenericDialogService,
    private dialog: MatDialog,
  ) {
    this.userId = clientStorageService.getUserId();
    
    changeDetectorRef.detach();
    this.datasourceSearch
    .pipe(
      map(event => event.target.value),
      debounceTime(350),
      flatMap(search => of(search)
      .pipe(delay(50))),
    )
    .subscribe(value => {
      // this.tree.treeModel.filterNodes(value);
      this.RefreshUI();
    });
    
    this.isSelfProvisioning = clientStorageService.getDataSourceProvisioning();
  }
  
  get PinnedDataSource() {
    return this.pinnedDsId > 0;
  }
  
  RefreshUI() {
    this.angularZone.run(() => {
      this.changeDetectorRef.detectChanges();
    });
  }
  
  RefreshUIDelayed() {
    this.angularZone.run(() => {
      this.changeDetectorRef.detectChanges();
      setTimeout(() => {
        this.changeDetectorRef.detectChanges();
      }, 200);
    });
  }
  
  ngOnInit() {
    this.windowHeight = window.innerHeight;
    this.SetDataSourceVisibilityOptions();
    
    console.log('ds init');
    this.apiSearch
    .pipe(
      map(event => event.target.value),
      debounceTime(600),
      flatMap(search => of(search)
      .pipe(delay(50))),
    )
    .subscribe(value => {
      if (value === '') {
        this.apiFilteringSearch = true;
        this.RefreshUI();
        this.apiTreeComponent.treeModel.clearFilter();
        this.apiTreeComponent.treeModel.collapseAll();
        this.apiFilteringSearch = false;
        this.RefreshUI();
      }
      
      if (value.length > 1) {
        this.apiFilteringSearch = true;
        this.RefreshUI();
        
        const foundResults: ITreeNode[] = [];
        this.apiTreeComponent.treeModel.filterNodes((node: ITreeNode) => {
          const nodeName = node.data.name.toLowerCase()
          .replace(/\s/g, '')
          .replace(/_/g, '');
          const searchValue = value.toLowerCase()
          .replace(/\s/g, '');
          const nodeFound = nodeName.includes(searchValue);
          if (nodeFound && node.hasChildren) {
            console.log(node.children);
            for (const child of node.children) {
              if (child.hasChildren) {
                foundResults.push(child);
              }
            }
            foundResults.push(node);
          }
          return nodeFound;
        });
        foundResults.forEach(item => {
          item.children.forEach(child => {
            child.show();
            // child.ensureVisible();
          });
        });
        
        // this.apiTreeComponent.treeModel.filterNodes(value, true);
        this.apiFilteringSearch = false;
        this.RefreshUI();
      }
    });
    
    this.subscription = this.communicationService.Event.Editor.DataSource.$dataSourceUploadState.subscribe(
      event => {
        console.log('=event='); // console.log('$dataSourceUploadState');
        this.loading = event;
        this.RefreshUI();
      });
    
    this.subscription.add(
      this.communicationService.Event.Editor.DataSource.$dataSourceUploaded.subscribe(datasource => {
        console.log('=event=');
        this.RefreshUI();
        // console.log('$dataSourceUploaded');
        this.spreadsheetNodes.push(datasource);
        this.tree.treeModel.update();
        this.RefreshUI();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.DataSource.$ReloadDataSourcePanel.pipe(debounceTime(300))
      .subscribe(dataSourceTypeIds => {
        console.log('=event=');
        console.log('$ReloadDataSourcePanel');
        this.CreateDatasourceTrees(dataSourceTypeIds || []);
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.DataSource.$ReloadAPIDataSource.pipe(debounceTime(300))
      .subscribe(condition => {
        console.log('=event=');
        console.log('$ReloadAPIDataSource');
        this.GetDataSourcesSection([DatasourceTypeId.Api]);
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.DataSource.$DataSourceVisibilityChanged.subscribe(condition => {
        console.log('=event=');
        this.SetDataSourceVisibilityOptions();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.DataSource.$OpenOneSectionAtTime.subscribe(condition => {
        console.log('=event=');
        this.OpenOneSectionAtTime();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.DataSource.$RemoveDataSourceHighlight.subscribe(context => {
        console.log('=event='); // console.log('$RemoveDataSourceHighlight');
        this.RemoveDatasourceHighlight(context);
        this.RefreshUI();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanel.pipe(debounceTime(100))
      .subscribe(context => {
        console.log('=event=');
        console.log('$RefreshDataSourcePanel');
        this.RefreshTree();
        this.RefreshUI();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.DataSource.$RefreshSectionDataSourcePanel.pipe(debounceTime(100))
      .subscribe(data => {
        console.log('=event=');
        console.log('$RefreshDataSourcePanelSection');
        this.RefreshDataSource(data.datasourceTypeId, null, false);
        this.RefreshUI();
        
        if (data.nodeIdsToExpand && data.nodeIdsToExpand.length > 0) {
          this.AddToExpandNodesList(this.toolsService.GetDataSourceTypeFromId(data.datasourceTypeId),
            data.nodeIdsToExpand);
        }
        
        if (data.focusNodeDatasourceId) {
          this.scrollToNode = '.ds-' + data.focusNodeDatasourceId;
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanelUI.pipe(debounceTime(100))
      .subscribe(context => {
        console.log('=event='); // console.log('$RefreshDataSourcePanelUI');
        this.RefreshUI();
      }),
    );
    this.subscription.add(
      this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoaded.pipe(debounceTime(100))
      .subscribe(context => {
        console.log('=event='); // console.log('$RefreshDataSourcePanelUI');
        this.RefreshUI();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.DataSource.ToggleSection.subscribe(info => {
        console.log('=event=');
        // console.log('ToggleSection');
        this.ToggleDataSourceSection(info.dataSourceTypeId, info.open);
        if (this.dataSourcesLoaded[info.dataSourceTypeId] || !info.open) {
          // not getting data
        } else {
          this.GetDataSourcesSection([info.dataSourceTypeId]);
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.Preferences.$PreferenceChange.subscribe(preference => {
        console.log('=event=');
        if (preference === 'pinDS' && !this.workareaService.editorPreferences.pinDS) {
          if (this.pinnedDsId) {
            const element = document.querySelector(`.pinned-${ this.pinnedDsId }`);
            if (element) {
              element.remove();
            }
          }
          
          this.pinnedDsId = 0;
          if (this.pinnedDsNode) {
            this.pinnedDsNode.pinned = false;
            this.pinnedDsNode = null;
          }
          
          this.RefreshUI();
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.DataSource.$Highlight.pipe(debounceTime(0))
      .subscribe(
        (data: {
          context: string;
          dataSourceId: number;
          dataSourceName: string;
          spreadsheetReferences: {
            firstRow: number;
            firstCol: number;
            lastRow: number;
            lastCol: number;
            collection: string;
            dataSourceId: number;
            show: boolean;
          };
        }) => {
          console.log('=event=');
          console.log(data);
          this.angularZone.runOutsideAngular(() => {
            this.OpensAndHighlightDatasourcePathWithContext(data.context);
          });
          this.RefreshUI();
        },
      ),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.DataSource.$HighlightOnSpreadsheetPanel.subscribe(
        (data: {
          context: string;
          dataSourceType: string;
          dataSourceId: number;
          dataSourceName: string;
          spreadsheetReferences: {
            firstRow: number;
            firstCol: number;
            lastRow: number;
            lastCol: number;
            collection: string;
            dataSourceId: number;
            dataSourceName: string;
            show: boolean;
          };
        }) => {
          console.log('=event=');
          this.RefreshUI();
          // console.log('$HighlightOnSpreadsheetPanel');
          const s = data.context.split(Constants.ContextSeparator);
          this.spreadsheetService.dataSourceOpened = this.dataSourcesService.GetDataSourceNodeFromTree(
            `${ s[0] }${ Constants.ContextSeparator }${ s[1] }`);
          this.angularZone.runOutsideAngular(() => {
            switch (data.dataSourceType) {
              case DatasourceType.Spreadsheet:
                if (data.dataSourceId !== this.dataSourcesService.openedDataSourceId) {
                  this.spreadsheetService.LoadDataSource(data.dataSourceId, data.dataSourceName, 0, false);
                  this.RefreshUI();
                  const subscription = this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoaded.subscribe(
                    loaded => {
                      // console.log('$SpreadsheetLoaded');
                      if (loaded) {
                        setTimeout(() => {
                          this.communicationService.Event.Editor.DataSource.Spreadsheet.$SelectRangeOnSpreadsheet.emit(
                            data.spreadsheetReferences);
                        }, 500);
                        subscription.unsubscribe();
                      }
                    });
                } else {
                  this.communicationService.Event.Editor.DataSource.Spreadsheet.$SelectRangeOnSpreadsheet.emit(
                    data.spreadsheetReferences);
                }
                break;
              
              default:
                this.dataSourcesService.GetPath(data.context)
                .subscribe(path => {
                  if (!path) {
                    return;
                  }
                  
                  if (path.length === 0) {
                    this.snackerService.ShowMessageOnBottom('This datasource does not exist', 'warning');
                    return;
                  }
                  
                  const treeComponent = this.GetTreecomponentForContext(data.context);
                  path.shift();
                  
                  const firstNodeName = path.shift();
                  
                  let previousNode = treeComponent.treeModel.getNodeBy(n => n.data.name === firstNodeName);
                  let node = null;
                  let nodeIndex = 0;
                  for (let i = 0; i <= path.length; i++) {
                    node = previousNode.children.find(n => {
                      return n.data.name === path[i];
                    });
                    
                    if (node && node.data.isLeaf) {
                      node = previousNode;
                      nodeIndex = node.data.children.findIndex(
                        c => `${ c.path }${ Constants.ContextSeparator }${ c.name }` === data.context) || 0;
                      break;
                    }
                    
                    previousNode = node;
                  }
                  
                  if (node) {
                    let timeoutToShow = 100;
                    if (this.spreadsheetService.dataSourceIdOpened !== node.data.dataSourceId) {
                      this.OpenNodeInSpreadhseetPanel(node);
                      this.RefreshUI();
                      timeoutToShow = 2000;
                    }
                    setTimeout(() => {
                      this.communicationService.Event.Editor.DataSource.Spreadsheet.$SelectRangeOnSpreadsheet.emit(
                        {
                          firstRow: 0,
                          firstCol: nodeIndex,
                          lastRow: 0,
                          lastCol: nodeIndex,
                          dataSourceId: 0,
                          dataSourceName: '',
                          collection: '',
                          show: true,
                        });
                    }, timeoutToShow);
                  }
                  
                  this.RefreshUI();
                });
                break;
            }
          });
        },
      ),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.Preferences.$PreferenceChange.subscribe(preference => {
        if (preference === 'compactToolBarPosition') {
          this.setTreeViewportHeight();
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$LastUsedElement.subscribe(lastUsedElements => {
        console.log(lastUsedElements);
        if (!lastUsedElements.dataelement) {
          return;
        }
        
        for (const [key, value] of Object.entries(this.lastUsedDataSources)) {
          if (lastUsedElements.dataelement[key]) {
            this.lastUsedDataSources[key] = lastUsedElements.dataelement[key];
          }
        }
      }),
    );
    
    this.setLastUsedElements();
    this.setTreeViewportHeight();
  }
  
  RemoveDatasourceHighlight(context: string) {
    this.RefreshUI();
    // console.log('remove ds highlight');
    let highlitedDs = [];
    
    if (context) {
      const spplitedContext = context.split(Constants.ContextSeparator);
      spplitedContext.forEach(sc => {
        const identifier = `.ds-${ sc.replace(/[#(){} ]/g, '') }`;
        const el = document.querySelector(identifier);
        if (el) {
          highlitedDs.push(el);
        }
      });
    } else {
      highlitedDs = document.querySelectorAll(`.${ this.highlightClassIdentifier }`) as unknown as any[];
    }
    this.RefreshUI();
    highlitedDs.forEach(hl => hl.classList.remove(this.highlightClassIdentifier));
    this.RefreshUI();
  }
  
  RemoveHighlightedDatasources() {
    const highlitedDs = document.querySelectorAll(`.${ this.highlightClassIdentifier }`) as unknown as any[];
    this.RefreshUI();
    highlitedDs.forEach(hl => hl.classList.remove(this.highlightClassIdentifier));
    this.RefreshUI();
  }
  
  GetTreecomponentForContext(context: string): TreeComponent {
    const spplitedContext = context.split(Constants.ContextSeparator);
    let treeComponent: TreeComponent = null;
    
    switch (spplitedContext.shift()) {
      case DatasourceType.Api:
        this.toggleSection({ dataSourceTypeId: DatasourceTypeId.Api, open: true });
        treeComponent = this.apiTreeComponent;
        break;
      case DatasourceType.Custom:
        this.toggleSection({ dataSourceTypeId: DatasourceTypeId.Custom, open: true });
        treeComponent = this.customTreeComponent;
        break;
      case DatasourceType.Database:
        this.toggleSection({ dataSourceTypeId: DatasourceTypeId.Database, open: true });
        break;
      case DatasourceType.LeapXL:
        this.toggleSection({ dataSourceTypeId: DatasourceTypeId.LeapXL, open: true });
        treeComponent = this.leapxlTreeComponent;
        break;
      case DatasourceType.Spreadsheet:
        this.toggleSection({ dataSourceTypeId: DatasourceTypeId.Spreadsheet, open: true });
        treeComponent = this.spreadsheetTreeComponent;
        break;
      case DatasourceType.System:
        this.toggleSection({ dataSourceTypeId: DatasourceTypeId.System, open: true });
        treeComponent = this.systemTreeComponent;
        break;
      case DatasourceType.UnifiedDatabase:
        this.toggleSection({ dataSourceTypeId: DatasourceTypeId.UnifiedDatabase, open: true });
        treeComponent = this.unifiedDbTreeComponent;
        break;
      case DatasourceType.InternetMessaging:
        this.toggleSection({ dataSourceTypeId: DatasourceTypeId.InternetMessaging, open: true });
        treeComponent = this.internetMessagingTreeComponent;
        break;
      case DatasourceType.LocalDataStore:
        this.toggleSection({ dataSourceTypeId: DatasourceTypeId.LocalDataStore, open: true });
        treeComponent = this.localDataStoreTreeComponent;
        break;
    }
    
    return treeComponent;
  }
  
  GetTreeComponent(dataSourceType: string): TreeComponent {
    let treeComponent: TreeComponent = null;
    
    switch (dataSourceType) {
      case DatasourceType.Api:
        treeComponent = this.apiTreeComponent;
        break;
      case DatasourceType.Custom:
        treeComponent = this.customTreeComponent;
        break;
      case DatasourceType.Database:
        break;
      case DatasourceType.LeapXL:
        treeComponent = this.leapxlTreeComponent;
        break;
      case DatasourceType.Spreadsheet:
        treeComponent = this.spreadsheetTreeComponent;
        break;
      case DatasourceType.System:
        treeComponent = this.systemTreeComponent;
        break;
      case DatasourceType.UnifiedDatabase:
        treeComponent = this.unifiedDbTreeComponent;
        break;
      case DatasourceType.InternetMessaging:
        treeComponent = this.internetMessagingTreeComponent;
        break;
      case DatasourceType.LocalDataStore:
        treeComponent = this.localDataStoreTreeComponent;
        break;
    }
    
    return treeComponent;
  }
  
  ToggleUnusedNodesVisibility(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (this.hideUnusedDataSources || this.hideNotOwnedDataSources) {
      this.hideNotOwnedDataSources = false;
      this.hideUnusedDataSources = false;
    } else {
      this.SetDataSourceVisibilityOptions();
    }
    
    this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanel.emit();
    console.log('editor');
    
    this.editorStateService.SaveEditorState();
  }
  
  SetDataSourceVisibilityOptions() {
    this.hideNotOwnedDataSources = this.workareaService.hideNotOwnedDataSources;
    this.hideUnusedDataSources = this.workareaService.hideUnusedDataSources;
  }
  
  OpenPreferencesPanel(event: any) {
    this.draggableWindowService.OpenDraggableWindow(
      'Preferences Panel',
      DraggableWindowType.PreferencesPanel,
      event,
    );
  }
  
  OpensAndHighlightDatasourcePathWithContext(context: string, highlight = true) {
    console.log('highlight by context');
    
    if (this.PinnedDataSource) {
      const selector = document.querySelector(`.pinned-${ this.pinnedDsId }`);
      
      if (selector) {
        selector.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }
    
    this.RemoveHighlightedDatasources();
    this.RefreshUI();
    const treeComponent = this.GetTreecomponentForContext(context);
    
    let node: ITreeNode = null;
    
    if (treeComponent) {
      this.dataSourcesService.GetPath(context)
      .subscribe(path => {
        if (path && path.length > 0) {
          const firstNodeName = path[1];
          const pathToSearch = path.slice(0, 2)
          .join(Constants.ContextSeparator);
          node = treeComponent.treeModel.getNodeBy(n => {
            return (
              `${ n.data.path }${ Constants.ContextSeparator }${ n.data.name }` === pathToSearch ||
              n.data.path === pathToSearch ||
              n.data.name === firstNodeName
            );
          });
          
          if (node) {
            node.expand();
            this.RefreshUI();
            
            const identifier = `.ds-${ node.data.name.replace(/[#(){}.: ]/g, '') }`;
            
            if (node.data.isLeaf && node.data.dataSourceType !== DatasourceType.Spreadsheet) {
              node.scrollIntoView();
              this.RefreshUI();
            }
            
            if (highlight) {
              const el = document.querySelector(identifier);
              if (el) {
                el.classList.add(this.highlightClassIdentifier);
              }
              this.RefreshUI();
            }
          }
          
          if (path.length === 2) {
            if (node) {
              const identifier = `.ds-${ node.data.dataSourceId }.ds-${ node.data.name.replace(/[#(){}.: ]/g,
                '') }`;
              const nodeElement = document.querySelector(identifier);
              if (nodeElement) {
                nodeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }
              this.RefreshUI();
            }
            
            return;
          }
          
          path.shift();
          path.shift();
          
          path.forEach((sc, index) => {
            if (node) {
              node = node.children.find(c => c.data.name === sc);
            }
            
            if (node) {
              node.expand();
              this.RefreshUI();
              
              const identifier = `.ds-${ node.data.dataSourceId }.ds-${ node.data.name.replace(/[#(){}.: ]/g,
                '') }`;
              
              if (node.data.isLeaf && node.data.dataSourceType !== DatasourceType.Spreadsheet) {
                node.scrollIntoView();
              }
              
              const nodeElement = document.querySelector(identifier);
              if (nodeElement) {
                nodeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }
              this.RefreshUI();
              
              if (highlight) {
                const el = document.querySelectorAll(identifier)[0];
                if (el) {
                  el.classList.add(this.highlightClassIdentifier);
                }
                this.RefreshUI();
              }
            }
          });
        } else {
          this.snackerService.ShowMessageOnBottom(`Datasource [${ context }] does not exist`, 'warning');
        }
      });
    }
  }
  
  HighlightDatasourcePathWithName(name: string) {
    console.log('highlight by name');
    
    this.RemoveHighlightedDatasources();
    this.RefreshUI();
    const treeComponent = this.GetTreecomponentForContext('Api');
    
    let node: ITreeNode = null;
    
    if (treeComponent) {
      if (name) {
        node = treeComponent.treeModel.getNodeBy(n => n.data.name === name);
        
        console.log(node);
        
        node.path.forEach(nodeId => {
          const pathNode = treeComponent.treeModel.getNodeById(nodeId);
          pathNode.expand();
          this.RefreshUI();
          const identifier = `.ds-${ pathNode.data.name.replace(/[#(){}.: ]/g, '') }`;
          
          const el = document.querySelector(identifier);
          if (el) {
            el.classList.add(this.highlightClassIdentifier);
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
          this.RefreshUI();
        });
        
        // const firstNodeName = path[1];
        // const pathToSearch = path.slice(0, 2).join(Constants.ContextSeparator);
        // node = treeComponent.treeModel.getNodeBy(n => {
        //   return `${n.data.path}${Constants.ContextSeparator}${n.data.name}` === pathToSearch || (n.data.path === pathToSearch) || n.data.name === firstNodeName;
        // });
        //
        // if (node) {
        //   node.expand();
        //   this.RefreshUI();
        //
        //   const identifier = `.ds-${node.data.name.replace(/[#(){}.: ]/g, '')}`;
        //
        //   if (node.data.isLeaf && node.data.dataSourceType !== DatasourceType.Spreadsheet) {
        //     node.scrollIntoView();
        //     this.RefreshUI();
        //   }
        //
        //   const el = document.querySelector(identifier);
        //   if (el) {
        //     el.classList.add(this.highlightClassIdentifier);
        //   }
        //   this.RefreshUI();
        // }
        //
        // path.shift();
        // path.shift();
        //
        // path.forEach((sc, index) => {
        //
        //   if (node) {
        //     node = node.children.find(c => c.data.name === sc);
        //   }
        //
        //   if (node) {
        //     node.expand();
        //     this.RefreshUI();
        //
        //     const identifier = `.ds-${node.data.dataSourceId}.ds-${node.data.name.replace(/[#(){}.: ]/g, '')}`;
        //
        //     if (node.data.isLeaf && node.data.dataSourceType !== DatasourceType.Spreadsheet) {
        //       node.scrollIntoView();
        //     }
        //
        //     const nodeElement = document.querySelector(identifier);
        //     if (nodeElement) {
        //       nodeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        //     }
        //     this.RefreshUI();
        //
        //     const el = document.querySelectorAll(identifier)[0];
        //     if (el) {
        //       el.classList.add(this.highlightClassIdentifier);
        //     }
        //     this.RefreshUI();
        //   }
        // });
      }
    }
  }
  
  CreateChildrenNode(
    parent: DataSourceTreeNodeElement,
    usedDataSourcesIds: { dataSourceId: number; context: string }[],
    dataSourceTypeId: number,
    path: string,
  ) {
    if (parent.dataSourceId && parent.dataSourceId > 0) {
      path =
        path === ''
          ? `${ this.toolsService.GetDataSourceTypeFromId(
            parent.dataSourceType) }${ Constants.ContextSeparator }${ parent.name }`
          : `${ path }${ Constants.ContextSeparator }${ parent.name }`;
    } else {
      switch (parent.dataSourceType) {
        case DatasourceTypeId.System:
          path = DatasourceType.System;
          break;
      }
    }
    
    const parentNode = {
      id: parent.id,
      parentId: parent.parentId,
      dataSourceType: this.toolsService.GetDataSourceTypeFromId(dataSourceTypeId),
      isDatasource: parent.isDataSource,
      isBeingUsed: !!usedDataSourcesIds.find(
        ud => path !== '' && ud.context.indexOf(`${ path }${ Constants.ContextSeparator }`) > -1),
      isDeprecated: parent.isDeprecated,
      isLeaf: parent.isLeaf,
      isVolunteer: parent.isVolunteer,
      isTemplate: parent.isTemplate,
      name: parent.name,
      dataSourceId: parent.dataSourceId,
      index: parent.index,
      hasAuthoredGuide: parent.hasAuthoredGuide,
      path: path,
      children: [],
      pinned: this.IsPinned(parent.id),
      ownerId: parent.ownerId,
      companyId: parent.companyId,
      fullAudit: parent.fullAudit,
      sharedType: parent.sharedType,
      isLastBranchParent: false,
      datasourceOwnerId: parent.ownerId,
      isRoot: true,
    };
    
    if (this.reopenTree.enable && this.reopenTree.path === '') {
      let nodePath = this.reopenTree.id && parent.id === this.reopenTree.id ? path : this.reopenTree.name === parent.name ? path : '';
      
      if (nodePath !== '') {
        if (parent.dataSourceId) {
        } else {
          nodePath = parent.dataSourceType + Constants.ContextSeparator + parent.name;
        }
      }
      
      this.reopenTree.path = nodePath;
      this.reopenTree.found = parent;
    }
    
    if (parent.nodes === null || (parent.nodes && parent.nodes.length === 0)) {
      return parentNode;
    } else {
      parent.nodes.forEach(dsNode => {
        if (dsNode.nodes === null || (dsNode.nodes && dsNode.nodes.length === 0)) {
          const child = cloneDeep(dsNode) as any;
          child.dataSourceType = this.toolsService.GetDataSourceTypeFromId(dataSourceTypeId);
          child.isBeingUsed = !!usedDataSourcesIds.find(
            ud => ud.context.indexOf(`${ path }${ Constants.ContextSeparator }${ child.name }`) > -1);
          child.isLastBranchParent = false;
          child.isDeprecated = dsNode.isDeprecated;
          child.isVolunteer = dsNode.isVolunteer;
          child.isTemplate = dsNode.isTemplate;
          child.children = [];
          (child.pinned = this.IsPinned(child.id)), (child.companyId = dsNode.companyId);
          child.fullAudit = dsNode.fullAudit;
          child.datasourceOwnerId = parentNode.datasourceOwnerId;
          child.parentId = parent.dataSourceId;
          child.path =
            path === ''
              ? (parentNode.path === '' ? parentNode.dataSourceType : parentNode.path) +
              (child.dataSourceId && child.dataSourceId > 0 ? `${ Constants.ContextSeparator }${ child.name }` : '')
              : path;
          parentNode.children.push(child);
          parentNode.isLastBranchParent = dsNode.nodes.length === 0;
          
          if (this.reopenTree.enable && this.reopenTree.path === '') {
            let nodePath = this.reopenTree.id && child.id === this.reopenTree.id ? child.path : this.reopenTree.name === child.name ? child.path : '';
            
            if (nodePath !== '') {
              if (child.dataSourceId) {
              } else {
                nodePath = child.dataSourceType + Constants.ContextSeparator + child.name;
              }
            }
            
            this.reopenTree.path = nodePath;
            this.reopenTree.found = child;
          }
        } else {
          parentNode.children.push(
            this.CreateChildrenNode(dsNode, usedDataSourcesIds, dataSourceTypeId, path));
        }
      });
      return parentNode;
    }
  }
  
  IsPinned(dsId: number) {
    return this.pinnedDsId === dsId;
  }
  
  EvaluateUnusedNodes(node: any) {
    if (this.hideUnusedDataSources) {
      node.children = node.children.filter(c => c.isBeingUsed);
    }
    
    if (this.hideNotOwnedDataSources) {
      node.children = node.children.filter(c => c.ownerId === this.userId);
    }
    
    return node;
  }
  
  OpenSpreadsheet(e, node) {
    this.RefreshUI();
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    if (node.data.dataSourceId === null) {
      return;
    }
    
    if (this.cobbleService.Cobble.id > 0) {
      const dsId = node.data.dataSourceId;
      
      // console.log(dsId);
      if (dsId) {
        if (node.data.isDatasource) {
          if (dsId === this.dataSourcesService.openedDataSourceId) {
            return;
          } else {
            this.spreadsheetService.dataSourceOpened = node.data;
            this.loadDataSource(dsId, node.data.name, 0, false);
            this.RefreshUI();
          }
        } else {
          if (this.dataSourcesService.openedDataSourceId === dsId) {
            this.spreadsheetService.flexSheet.selectedSheetIndex = node.data.index - 1;
            this.spreadsheetService.sName = node.data.name;
          } else {
            const splittedContext = node.data.path.split(Constants.ContextSeparator);
            this.loadDataSource(dsId, splittedContext[splittedContext.length - 1], node.data.index - 1,
              false);
            this.RefreshUI();
          }
        }
      }
      this.RefreshUI();
    } else {
      this.snackerService.ShowMessageOnBottom('No App loaded!');
    }
  }
  
  EventDragStartHandler(
    contexts: {
      context: string;
      dataSourceId: number;
      required: boolean;
      isTemplate: boolean;
      isVolunteer: boolean;
    }[],
    dataSourceType: string,
  ) {
    this.RefreshUI();
    const dataElements = [];
    
    contexts.forEach(context => {
      const splittedContext = context.context.split(Constants.ContextSeparator);
      
      const dataElement = new DataElement({
        translationId: 0,
        id: this.toolsService.GenerateGuid(),
        particleId: this.toolsService.GenerateGuid(),
        particleType: ParticleType.DataElement,
        context: `${ context.context }`,
        dataSourceType: dataSourceType,
        dataSourceId: context.dataSourceId,
        dataSourceName: dataSourceType,
        collection: '',
        isTemplate: context.isTemplate,
        isVolunteer: context.isVolunteer,
        internalName: splittedContext[splittedContext.length - 1],
        reference: splittedContext[splittedContext.length - 1],
        applicationId: this.cobbleService.Cobble.id,
        required: context.required,
      });
      
      dataElements.push(dataElement);
    });
    
    // console.log('dataElements', dataElements);
    this.SetDragStart(dataElements);
    this.RefreshUI();
  }
  
  SetDragStart(dataElements: DataElement[]) {
    this.RefreshUI();
    this.dragService.dragData = {
      dragType: DragType.DataElement,
      data: dataElements,
    };
    this.dragService.dragginDataElement = true;
    this.dragService.dragText = `Attatch Data element`;
    this.RefreshUI();
  }
  
  SetDragStartForSpreadsheet(datasource: any) {
    this.RefreshUI();
    this.spreadsheetService.SpreadSheetRangeSelected = datasource;
    this.dragService.dragData = {
      fromTree: true,
      dragType: DragType.Spreadsheet,
      data: datasource,
    };
    this.dragService.dragginDataElement = true;
    this.dragService.dragText = `Attatch Data element`;
    this.RefreshUI();
  }
  
  SetDragEnd() {
    this.RefreshUI();
    this.dragService.dragginDataElement = false;
    this.RefreshUI();
  }
  
  loadDataSource(dataSourceId: number, filename: string, sheetIndex, production: boolean) {
    this.loading = true;
    this.RefreshUI();
    this.spreadsheetService.LoadDataSource(dataSourceId, filename, sheetIndex, false);
    this.loading = false;
    this.RefreshUI();
  }
  
  NewCustomValue(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.RefreshUI();
    setTimeout(() => {
      this.createNewCustomValue = true;
      this.RefreshUI();
      setTimeout(() => {
        this.RefreshUI();
        this.newCustomNameValueInput.nativeElement.focus();
        this.newCustomNameValueInput.nativeElement.select();
        this.RefreshUI();
      }, 100);
    }, 50);
  }
  
  NewLocalStoreValue(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.RefreshUI();
    setTimeout(() => {
      this.createNewLocalStoreValue = true;
      this.RefreshUI();
      setTimeout(() => {
        this.RefreshUI();
        this.newLocalStoreInput.nativeElement.focus();
        this.newLocalStoreInput.nativeElement.select();
        this.RefreshUI();
      }, 100);
    }, 50);
  }
  
  AddCustomValue() {
    this.RefreshUI();
    if (this.editCustomValue) {
      if (this.editNode.dataSourceType === 'Custom') {
        this.customDatasourceContext = this.editNode.path;
      }
      this.dataSourcesService.UpdateCustomValue(this.newCustomValue.name, this.newCustomValue.value)
      .subscribe(result => {
        this.cacheService.Clear();
        this.busService.GetWorkareaViewMolecules(this.cobbleService.Cobble,
          this.workareaService.actualEditorViews)
        .forEach(repMolecule => {
          console.log(repMolecule);
          repMolecule.FireEvent('init', null);
          setTimeout(() => {
            this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(repMolecule.Id);
          }, 2000);
        });
        this.CreateDatasourceTrees();
        this.StopCustomValueCreation();
        this.RefreshUI();
      });
    } else {
      if (this.newCustomValue.name === '') {
        this.snackerService.ShowMessageOnBottom('Need to specify a Name for the value',
          'drive_file_rename_outline');
      } else {
        if (this.customValues.map(cv => cv.name)
        .includes(this.newCustomValue.name)) {
          this.snackerService.ShowMessageOnBottom('A custom value with the same Name already exists',
            'repeat_one_on');
        } else {
          this.dataSourcesService
          .CreateCustomValue(`Custom${ Constants.ContextSeparator }${ this.newCustomValue.name }`,
            this.newCustomValue.value)
          .subscribe(result => {
            this.CreateDatasourceTrees();
            this.RefreshUI();
          });
          this.StopCustomValueCreation();
          this.RefreshUI();
        }
      }
    }
  }
  
  AddLocalStoreValue() {
    this.RefreshUI();
    if (this.editLocalStoreValue) {
      this.dataSourcesService.UpdateLocalDataStore(this.newLocalStoreValue)
      .subscribe(result => {
        this.cacheService.Clear();
        this.busService.GetWorkareaViewMolecules(this.cobbleService.Cobble,
          this.workareaService.actualEditorViews)
        .forEach(repMolecule => {
          setTimeout(() => {
            this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(repMolecule.Id);
          }, 2000);
        });
        this.CreateDatasourceTrees();
        this.StopLocalStoreValueCreation();
        this.RefreshUI();
      });
    } else {
      if (this.newLocalStoreValue === '') {
        this.snackerService.ShowMessageOnBottom('Need to specify a Name for the value',
          'drive_file_rename_outline');
      } else {
        if (this.localDataStoreValues.map(cv => cv.name)
        .includes(this.newLocalStoreValue)) {
          this.snackerService.ShowMessageOnBottom('A Data Store with the same Name already exists',
            'repeat_one_on');
        } else {
          this.dataSourcesService
          .CreateLocalDataStore(
            `${ DatasourceType.LocalDataStore }${ Constants.ContextSeparator }${ this.newLocalStoreValue }`)
          .subscribe(result => {
            this.CreateDatasourceTrees();
            this.RefreshUI();
          });
          this.StopLocalStoreValueCreation();
          this.RefreshUI();
        }
      }
    }
  }
  
  StopLocalStoreValueCreation() {
    this.RefreshUI();
    this.createNewLocalStoreValue = false;
    this.RefreshUI();
    
    if (this.editLocalStoreValue) {
      // console.log('context', `${this.editNode.path}.${this.editNode.name}`);
      this.busService
      .GetRepresentativeMoleculesWithContext(
        `${ this.editNode.path }${ Constants.ContextSeparator }${ this.editNode.name }`)
      .forEach(repMolecule => repMolecule.FireDataSourceBus());
      
      this.editNode = null;
      this.editLocalStoreValue = false;
      this.RefreshUI();
    }
    
    this.newLocalStoreValue = '';
    this.RefreshUI();
  }
  
  StopCustomValueCreation() {
    this.RefreshUI();
    this.createNewCustomValue = false;
    this.RefreshUI();
    
    if (this.editCustomValue) {
      // console.log('context', `${this.editNode.path}.${this.editNode.name}`);
      this.busService
      .GetRepresentativeMoleculesWithContext(
        `${ this.editNode.path }${ Constants.ContextSeparator }${ this.editNode.name }`)
      .forEach(repMolecule => repMolecule.FireDataSourceBus());
      
      this.editNode = null;
      this.editCustomValue = false;
      this.RefreshUI();
    }
    
    this.newCustomValue.name = '';
    this.newCustomValue.value = '';
    this.RefreshUI();
  }
  
  DeleteCustomValue(customValueName: string) {
    this.dataSourcesService.DeleteCustomValue(customValueName)
    .subscribe(result => {
      this.customValues = this.customValues.filter(cv => cv.name !== customValueName);
    });
  }
  
  EditCustomValue(nodeData: any) {
    this.RefreshUI();
    if (nodeData.isLeaf && nodeData.dataSourceId && nodeData.dataSourceId > 0) {
      this.editNode = nodeData;
      const dataKey = 'editCustomValue';
      const literalContext = nodeData.path + (nodeData.parentId ? Constants.ContextSeparator + nodeData.name : '');
      this.dataSourcesService.dataSourceDataRequest[dataKey] = this.dataSourcesService.GetDataSourceRequestForKey(
        dataKey);
      
      this.dataSourcesService.dataSourceDataRequest[dataKey].translationIds = [];
      this.dataSourcesService.dataSourceDataRequest[dataKey].contexts = [[literalContext]];
      this.RefreshUI();
      this.dataSourcesService.GetDataSourceData(dataKey)
      .subscribe(result => {
        this.RefreshUI();
        
        const literalValue = this.toolsService.ExtractValuesByType(result.dataElements).string;
        this.editCustomValueId = nodeData.id;
        this.newCustomValue.name = literalContext;
        this.newCustomValue.value = literalValue;
        this.editCustomValue = true;
        this.RefreshUI();
        setTimeout(() => {
          this.RefreshUI();
          this.newCustomValueInput.nativeElement.focus();
          this.newCustomValueInput.nativeElement.select();
          this.RefreshUI();
        }, 200);
      });
    }
  }
  
  Throttle(func, delay, context, args) {
    clearTimeout(this.inDebounce);
    this.inDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }
  
  OpenNodeInSpreadhseetPanel(node: TreeNode) {
    this.RefreshUI();
    // console.log('node open', node);
    const data = [];
    const path = node.data.path;
    const contexts = [];
    
    node.data.children.forEach(child => {
      contexts.push(`${ path }${ Constants.ContextSeparator }${ child.name }`);
    });
    
    const dataKey = 'externalDatasource';
    this.dataSourcesService.dataSourceDataRequest[dataKey] = this.dataSourcesService.GetDataSourceRequestForKey(
      dataKey);
    
    this.communicationService.Event.Editor.DataSource.$dataSourceLoaded.emit(true);
    this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoading.emit(true);
    this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoaded.emit(false);
    this.dataSourcesService.dataSourceDataRequest[dataKey].contexts = [contexts];
    this.dataSourcesService.dataSourceDataRequest[dataKey].translationIds = [];
    this.RefreshUI();
    
    setTimeout(() => {
      this.dataSourcesService.GetDataSourceData(dataKey)
      .subscribe(dataResponse => {
        // console.log('response', dataResponse);
        
        if (dataResponse.dataElements.length > 0) {
          const rows = Math.max.apply(
            Math,
            dataResponse.dataElements.map(de => {
              return de.row;
            }),
          );
          
          for (let row = 1; row <= rows; row++) {
            const rowData = {};
            node.data.children.forEach((child, index) => {
              this.spreadsheetService.apiDataElementsOpened[child.name.toLowerCase()
              .replace(/ /g, '')] = child.name;
              const dataElement = dataResponse.dataElements.find(
                de => de.context === `${ child.path }${ Constants.ContextSeparator }${ child.name }` && de.row === row,
              );
              
              if (dataElement) {
                rowData[child.name] = dataElement.value || '';
              } else {
                rowData[child.name] = '';
              }
            });
            data.push(rowData);
          }
          
          // console.log('data', data);
        } else {
          const row = {};
          node.data.children.forEach((child, index) => {
            this.spreadsheetService.apiDataElementsOpened[child.name.toLowerCase()
            .replace(/ /g, '')] = child.name;
            row[child.name] = '';
          });
          data.push(row);
        }
        this.RefreshUI();
        
        this.spreadsheetService.dataSourceOpened = node.data;
        this.spreadsheetService.dataSourceIdOpened = node.data.dataSourceId;
        this.dataSourcesService.openedDataSourceId = node.data.dataSourceId;
        this.spreadsheetService.dataSourceTypeOpened = node.data.dataSourceType;
        this.spreadsheetService.flexSheet.sheets.clear();
        this.spreadsheetService.flexSheet.sheets.push(new wjcGridSheet.Sheet());
        this.spreadsheetService.sName = '';
        this.spreadsheetService.editorDbModeVisibility = false;
        this.spreadsheetService.flexSheet.headersVisibility = HeadersVisibility.None;
        this.spreadsheetService.editorDbMode = true;
        this.spreadsheetService.wName = `${ node.data.path }`;
        this.spreadsheetService.flexSheet.selectedSheet.name = node.data.name;
        this.spreadsheetService.flexSheet.selectedSheet.itemsSource = new wjcCore.CollectionView(data);
        this.RefreshUI();
        
        this.spreadsheetService.flexSheet.collectionView.refresh();
        this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoading.emit(false);
        this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoaded.emit(true);
        this.spreadsheetService.SetSheetHash(0);
        this.RefreshUI();
      });
    }, 300);
  }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  
  SetCategoryRename(node: TreeNode) {
    this.RefreshUI();
    if (node.data.dataSourceId && node.data.dataSourceId > 0) {
      return;
    }
    
    this.editCategoryName = node.data.name;
    this.renameCategory = true;
    node.data.editMode = true;
    this.RefreshUI();
    setTimeout(() => {
      this.RefreshUI();
      this.editNodeCategory.nativeElement.focus();
      this.editNodeCategory.nativeElement.select();
      this.RefreshUI();
    }, 200);
  }
  
  AddCategory(treeComponent: TreeComponent, focusedNode: TreeNode, datasourceType: string, event: MouseEvent) {
    // console.log('add category');
    this.RefreshUI();
    // console.log('treeModel', treeComponent.treeModel);
    // console.log('focused', focusedNode);
    if (event) {
      event.stopPropagation();
      event.preventDefault();
      this.RefreshUI();
    }
    this.focusedNode = focusedNode;
    // let nodes = [];
    
    // console.log('adding', this.addingCategory);
    if (this.addingCategory) {
      return;
    }
    this.addingCategory = true;
    this.RefreshUI();
    
    if (focusedNode) {
      this.focusedNode.expand();
      this.RefreshUI();
      if (this.focusedNode.data.dataSourceId && this.focusedNode.data.dataSourceId > 0) {
        const parentNode = this.focusedNode.parent;
        // console.log('parent', parentNode);
        
        const nodePosition = parentNode.data.children.findIndex(c => c.id === this.focusedNode.id);
        
        // console.log('position', nodePosition);
        // console.log('parent', this.focusedNode.parent);
        
        parentNode.data.children = parentNode.data.children.filter(c => c.id !== this.focusedNode.id);
        
        this.newNode = {
          id: this.newNodeId,
          parentId: this.focusedNode.data.parentId,
          dataSourceType: datasourceType,
          isDatasource: false,
          isBeingUsed: false,
          isDeprecated: false,
          isLeaf: false,
          editMode: true,
          name: '',
          dataSourceId: this.focusedNode.data.dataSourceId,
          path: '',
          children: [this.focusedNode.data],
          isLastBranchParent: false,
        };
        this.RefreshUI();
        
        parentNode.data.children.splice(nodePosition, 0, this.newNode);
        
        if (this.focusedNode.parent.data.virtual) {
          switch (datasourceType) {
            case DatasourceType.Api:
              this.apiNodes = parentNode.data.children;
              break;
            case DatasourceType.Custom:
              this.customNodes = parentNode.data.children;
              break;
            case DatasourceType.Database:
              this.dbNodes = parentNode.data.children;
              break;
            case DatasourceType.LeapXL:
              this.leapXLNodes = parentNode.data.children;
              break;
            case DatasourceType.Spreadsheet:
              this.spreadsheetNodes = parentNode.data.children;
              break;
            case DatasourceType.System:
              this.systemNodes = parentNode.data.children;
              break;
            case DatasourceType.UnifiedDatabase:
              this.unifiedDbNodes = parentNode.data.children;
              break;
            case DatasourceType.InternetMessaging:
              this.internetMessagingNodes = parentNode.data.children;
              break;
            case DatasourceType.LocalDataStore:
              this.localDataStoreNodes = parentNode.data.children;
              break;
          }
        }
        this.RefreshUI();
        this.setTreeViewportHeight();
        
        treeComponent.treeModel.update();
        this.RefreshUI();
        setTimeout(() => {
          this.RefreshUI();
          this.editNodeCategory.nativeElement.focus();
          this.editNodeCategory.nativeElement.select();
          this.RefreshUI();
        }, 200);
      } else {
        this.newNode = {
          id: this.newNodeId,
          parentId: this.focusedNode.data.id,
          dataSourceType: datasourceType,
          isDatasource: false,
          isBeingUsed: false,
          isDeprecated: false,
          isLeaf: false,
          name: '',
          dataSourceId: null,
          path: this.focusedNode.data.path,
          editMode: true,
          children: [],
          isLastBranchParent: false,
        };
        
        this.focusedNode.data.children.splice(0, 0, this.newNode);
        // nodes = treeComponent.treeModel.nodes;
        
        this.RefreshUI();
        treeComponent.treeModel.update();
        this.RefreshUI();
        setTimeout(() => {
          this.focusedNode.expand();
          this.RefreshUI();
          treeComponent.treeModel.getNodeById(this.focusedNode.id)
          .expand();
          this.RefreshUI();
          setTimeout(() => {
            this.RefreshUI();
            this.editNodeCategory.nativeElement.focus();
            this.editNodeCategory.nativeElement.select();
            this.RefreshUI();
          }, 50);
        }, 200);
      }
    } else {
      this.newNode = {
        id: this.newNodeId,
        parentId: null,
        dataSourceType: datasourceType,
        isDatasource: false,
        isBeingUsed: false,
        isDeprecated: false,
        isLeaf: false,
        name: '',
        dataSourceId: null,
        path: '',
        editMode: true,
        children: [],
        isLastBranchParent: false,
      };
      
      switch (datasourceType) {
        case DatasourceType.Api:
          this.apiNodes.splice(0, 0, this.newNode);
          break;
        case DatasourceType.Custom:
          this.customNodes.splice(0, 0, this.newNode);
          break;
        case DatasourceType.Database:
          this.dbNodes.splice(0, 0, this.newNode);
          break;
        case DatasourceType.LeapXL:
          this.leapXLNodes.splice(0, 0, this.newNode);
          break;
        case DatasourceType.Spreadsheet:
          this.spreadsheetNodes.splice(0, 0, this.newNode);
          break;
        case DatasourceType.System:
          this.systemNodes.splice(0, 0, this.newNode);
          break;
        case DatasourceType.UnifiedDatabase:
          this.unifiedDbNodes.splice(0, 0, this.newNode);
          break;
        case DatasourceType.InternetMessaging:
          this.internetMessagingNodes.splice(0, 0, this.newNode);
          break;
        case DatasourceType.LocalDataStore:
          this.localDataStoreNodes.splice(0, 0, this.newNode);
          break;
      }
      this.RefreshUI();
      this.setTreeViewportHeight();
      
      treeComponent.treeModel.update();
      this.RefreshUI();
      setTimeout(() => {
        this.RefreshUI();
        this.editNodeCategory.nativeElement.focus();
        this.editNodeCategory.nativeElement.select();
        this.RefreshUI();
      }, 100);
    }
  }
  
  GetNodeFromDataSourceTreeNodes(dataSourceNodes: DataSourceTreeNodeElement[], nodeId: number) {
    let foundNode = null;
    
    for (let i = 0; i < dataSourceNodes.length; i++) {
      const node = dataSourceNodes[i];
      
      if (node.id === nodeId) {
        foundNode = node;
        break;
      } else {
        if (node.nodes.length > 0) {
          foundNode = this.GetNodeFromDataSourceTreeNodes(node.nodes, nodeId);
        }
      }
      
      if (foundNode) {
        break;
      }
    }
    
    return foundNode;
  }
  
  SetNewCategory(treeComponent: TreeComponent, node: TreeNode) {
    this.RefreshUI();
    
    console.log(this.newNode, node, this.focusedNode);
    
    if (this.renameCategory) {
      this.RefreshUI();
      this.dataSourcesService.RenameNode(node.data.id, this.editCategoryName)
      .subscribe(Result => {
        node.data.name = this.editCategoryName;
        node.data.editMode = false;
        this.renameCategory = false;
        this.RefreshUI();
      });
    } else {
      if (this.editCategoryName === '') {
        this.StopCategoryEdit(treeComponent, node);
        this.RefreshUI();
      } else {
        node.data.name = this.editCategoryName;
        node.data.editMode = false;
        this.editCategoryName = '';
        this.RefreshUI();
        
        this.dataSourcesService
        .AddVirtualNodeToDatasource(
          this.newNode.dataSourceType,
          this.newNode.parentId,
          this.focusedNode && this.focusedNode.data.isDatasource ? this.focusedNode.data.id : null,
          node.data.name,
          node.data.dataSourceId,
        )
        .subscribe(Response => {
          switch (this.newNode.dataSourceType) {
            case DatasourceType.Api:
              const apiNode = this.CreateChildrenNode(Response.node, [], DatasourceTypeId.Api, '');
              this.apiNodes = apiNode.children;
              this.reopenTree.enable = true;
              this.reopenTree.id = null;
              this.reopenTree.name = node.data.name;
              this.reopenTree.path = '';
              this.RefreshDataSource(DatasourceTypeId.Api);
              break;
            case DatasourceType.Custom:
              const customNode = this.CreateChildrenNode(Response.node, [], DatasourceTypeId.Custom, '');
              this.customNodes = customNode.children;
              break;
            case DatasourceType.Database:
              const dbNode = this.CreateChildrenNode(Response.node, [], DatasourceTypeId.Database, '');
              this.dbNodes = dbNode.children;
              break;
            case DatasourceType.LeapXL:
              const leapxlNode = this.CreateChildrenNode(Response.node, [], DatasourceTypeId.LeapXL, '');
              this.leapXLNodes = leapxlNode.children;
              break;
            case DatasourceType.Spreadsheet:
              const spreadsheetNode = this.CreateChildrenNode(Response.node, [], DatasourceTypeId.Api, '');
              this.spreadsheetNodes = spreadsheetNode.children;
              break;
            case DatasourceType.System:
              const systemNode = this.CreateChildrenNode(Response.node, [], DatasourceTypeId.System, '');
              this.systemNodes = systemNode.children;
              break;
            case DatasourceType.UnifiedDatabase:
              const unifiedDbNode = this.CreateChildrenNode(Response.node, [],
                DatasourceTypeId.UnifiedDatabase, '');
              this.unifiedDbNodes = unifiedDbNode.children;
              break;
            case DatasourceType.InternetMessaging:
              const internetMessagingNode = this.CreateChildrenNode(Response.node, [],
                DatasourceTypeId.InternetMessaging, '');
              this.internetMessagingNodes = internetMessagingNode.children;
              break;
            case DatasourceType.LocalDataStore:
              const localDataStoreNode = this.CreateChildrenNode(Response.node, [],
                DatasourceTypeId.LocalDataStore, '');
              this.localDataStoreNodes = localDataStoreNode.children;
              break;
          }
          this.RefreshUI();
          this.setTreeViewportHeight();
          this.addingCategory = false;
          this.RefreshUI();
          treeComponent.treeModel.update();
          this.RefreshUI();
          setTimeout(() => {
            if (Response.parentId) {
              this.RefreshUI();
              treeComponent.treeModel.getNodeById(Response.parentId)
              .expand();
              this.RefreshUI();
            }
            this.categoryAddBtnEnable = true;
            this.focusedNode = null;
            this.RefreshUI();
          }, 200);
        });
      }
    }
  }
  
  StopCategoryEdit(treeComponent: TreeComponent, node: TreeNode) {
    this.RefreshUI();
    // console.log('stop category', node);
    node.data.editMode = false;
    this.renameCategory = false;
    this.editCategoryName = '';
    this.RefreshUI();
    const parentNode = node.realParent ? node.realParent : node.treeModel.virtualRoot;
    parentNode.data.children = parentNode.data.children.filter(c => c.id !== node.id);
    
    if (node && node.parent && node.parent.data && node.parent.data.children.length === 0) {
      node.parent.data.hasChildren = false;
      this.RefreshUI();
    } else {
      switch (node.data.dataSourceType) {
        case DatasourceType.Api:
          this.apiNodes = this.apiNodes.filter(n => n.id !== this.newNodeId);
          break;
        case DatasourceType.Custom:
          this.customNodes = this.customNodes.filter(n => n.id !== this.newNodeId);
          break;
        case DatasourceType.Database:
          this.dbNodes = this.dbNodes.filter(n => n.id !== this.newNodeId);
          break;
        case DatasourceType.LeapXL:
          this.leapXLNodes = this.leapXLNodes.filter(n => n.id !== this.newNodeId);
          break;
        case DatasourceType.Spreadsheet:
          this.spreadsheetNodes = this.spreadsheetNodes.filter(n => n.id !== this.newNodeId);
          break;
        case DatasourceType.System:
          this.systemNodes = this.systemNodes.filter(n => n.id !== this.newNodeId);
          break;
        case DatasourceType.UnifiedDatabase:
          this.unifiedDbNodes = this.unifiedDbNodes.filter(n => n.id !== this.newNodeId);
          break;
        case DatasourceType.InternetMessaging:
          this.internetMessagingNodes = this.internetMessagingNodes.filter(n => n.id !== this.newNodeId);
          break;
        case DatasourceType.LocalDataStore:
          this.localDataStoreNodes = this.localDataStoreNodes.filter(n => n.id !== this.newNodeId);
          break;
      }
    }
    
    this.addingCategory = false;
    this.focusedNode = null;
    this.RefreshUI();
    this.setTreeViewportHeight();
    treeComponent.treeModel.update();
    this.RefreshUI();
  }
  
  RefreshTree(datasources: DataSourceTreeNodeElement[] = null, scrollToPath = true) {
    this.selectedNodes = [];
    datasources = datasources || this.dataSourcesService.datasourcesTreeCache.nodes;
    this.dataSourceTypeIdsEnabled = this.dataSourcesService.datasourcesTreeCache.enabledDataSourceTypeIds;
    
    this.usedDataSourcesIds = this.dataSourcesService.UpdateUsedDatasourcesForApp();
    this.RefreshUI();
    
    const spreadsheetDatasources = datasources.find(ds => ds.dataSourceType === DatasourceTypeId.Spreadsheet);
    
    const systemDatasources = datasources.find(ds => ds.dataSourceType === DatasourceTypeId.System);
    
    const leapXLDatasources = datasources.find(ds => ds.dataSourceType === DatasourceTypeId.LeapXL);
    
    const apiDatasources = datasources.find(ds => ds.dataSourceType === DatasourceTypeId.Api);
    
    const customDatasources = datasources.find(ds => ds.dataSourceType === DatasourceTypeId.Custom);
    
    const dbDatasources = datasources.find(ds => ds.dataSourceType === DatasourceTypeId.Database);
    
    const unifiedDbDatasources = datasources.find(
      ds => ds.dataSourceType === DatasourceTypeId.UnifiedDatabase);
    
    const internetMessagingDatasources = datasources.find(
      ds => ds.dataSourceType === DatasourceTypeId.InternetMessaging);
    
    const localDataStoreDatasources = datasources.find(
      ds => ds.dataSourceType === DatasourceTypeId.LocalDataStore);
    
    if (customDatasources) {
      this.dataSourcesLoaded[customDatasources.dataSourceType] = true;
      customDatasources.nodes = customDatasources.nodes.sort(this.toolsService.CompareValues('name'));
      
      console.log('custom evaluate');
      
      const customNode = this.EvaluateUnusedNodes(
        this.CreateChildrenNode(customDatasources, this.usedDataSourcesIds, DatasourceTypeId.Custom, ''));
      this.customNodes = customNode.children;
    }
    
    if (spreadsheetDatasources) {
      this.dataSourcesLoaded[spreadsheetDatasources.dataSourceType] = true;
      spreadsheetDatasources.nodes = spreadsheetDatasources.nodes.sort(
        this.toolsService.CompareValues('name'));
      
      const spreadSheetNode = this.EvaluateUnusedNodes(
        this.CreateChildrenNode(spreadsheetDatasources, this.usedDataSourcesIds, DatasourceTypeId.Spreadsheet,
          ''),
      );
      
      // console.log('spradsheet nodes', spreadSheetNode);
      this.spreadsheetNodes = spreadSheetNode.children;
      this.RefreshUI();
    }
    
    if (systemDatasources) {
      this.dataSourcesLoaded[systemDatasources.dataSourceType] = true;
      systemDatasources.nodes = systemDatasources.nodes.sort(this.toolsService.CompareValues('name'));
      
      // systemDatasources.nodes.push({
      //   id: 0,
      //   name: 'IsMobile',
      //   level: 0,
      //   isLeaf: true,
      //   parentId: 0,
      //   dataSourceId: 0,
      //   isDataSource: false,
      //   reliability: true,
      //   required: false,
      //   direction: DataElementDirection.Inbound,
      //   index: 0,
      //   dataSourceType: 0,
      //   nodes: [],
      //   path: '',
      //   ownerId: 0,
      //   sharedType: 1
      // });
      const systemNode = this.EvaluateUnusedNodes(
        this.CreateChildrenNode(systemDatasources, this.usedDataSourcesIds, DatasourceTypeId.System, ''));
      this.systemNodes = systemNode.children;
    }
    
    if (leapXLDatasources) {
      this.dataSourcesLoaded[leapXLDatasources.dataSourceType] = true;
      leapXLDatasources.nodes = leapXLDatasources.nodes.sort(this.toolsService.CompareValues('name'));
      
      const leapXLNode = this.EvaluateUnusedNodes(
        this.CreateChildrenNode(leapXLDatasources, this.usedDataSourcesIds, DatasourceTypeId.LeapXL, ''));
      
      this.leapXLNodes = leapXLNode.children;
    }
    
    if (apiDatasources) {
      this.dataSourcesLoaded[apiDatasources.dataSourceType] = true;
      apiDatasources.nodes = apiDatasources.nodes.sort(this.toolsService.CompareValues('name'));
      
      const apiNode = this.EvaluateUnusedNodes(
        this.CreateChildrenNode(apiDatasources, this.usedDataSourcesIds, DatasourceTypeId.Api, ''));
      this.apiNodes = apiNode.children;
      this.RefreshUI();
    }
    
    if (dbDatasources) {
      this.dataSourcesLoaded[dbDatasources.dataSourceType] = true;
      dbDatasources.nodes = dbDatasources.nodes.sort(this.toolsService.CompareValues('name'));
      
      const dbNode = this.EvaluateUnusedNodes(
        this.CreateChildrenNode(dbDatasources, this.usedDataSourcesIds, DatasourceTypeId.Database, ''));
      this.dbNodes = dbNode.children;
    }
    
    if (unifiedDbDatasources) {
      this.dataSourcesLoaded[unifiedDbDatasources.dataSourceType] = true;
      unifiedDbDatasources.nodes = unifiedDbDatasources.nodes.sort(this.toolsService.CompareValues('name'));
      
      const unifiedDbNode = this.EvaluateUnusedNodes(
        this.CreateChildrenNode(unifiedDbDatasources, this.usedDataSourcesIds,
          DatasourceTypeId.UnifiedDatabase, ''),
      );
      this.unifiedDbNodes = unifiedDbNode.children;
    }
    
    if (internetMessagingDatasources) {
      this.dataSourcesLoaded[internetMessagingDatasources.dataSourceType] = true;
      internetMessagingDatasources.nodes = internetMessagingDatasources.nodes.sort(
        this.toolsService.CompareValues('name'));
      
      const internetMessagingNode = this.EvaluateUnusedNodes(
        this.CreateChildrenNode(internetMessagingDatasources, this.usedDataSourcesIds,
          DatasourceTypeId.InternetMessaging, ''),
      );
      this.internetMessagingNodes = internetMessagingNode.children;
      this.RefreshUI();
    }
    
    if (localDataStoreDatasources) {
      this.dataSourcesLoaded[localDataStoreDatasources.dataSourceType] = true;
      localDataStoreDatasources.nodes = localDataStoreDatasources.nodes.sort(
        this.toolsService.CompareValues('name'));
      
      const localDataStoreDatasourcesNode = this.EvaluateUnusedNodes(
        this.CreateChildrenNode(localDataStoreDatasources, this.usedDataSourcesIds,
          DatasourceTypeId.LocalDataStore, ''),
      );
      this.localDataStoreNodes = localDataStoreDatasourcesNode.children;
      this.RefreshUI();
    }
    
    this.loading = false;
    this.RefreshUI();
    
    setTimeout(() => {
      if (!this.reopenTree.enable && scrollToPath) {
        this.workareaService.elementsSelected.forEach(es => es.HighlightDatasourcesPath());
        this.RefreshUI();
      } else if (this.reopenTree.enable) {
        this.HighlightDatasourcePathWithName(this.reopenTree.name);
        // avoid searching by context, instead using name for the moment
        // this.HighlightDatasourcePathWithContext(this.reopenTree.path);
        this.reopenTree = {
          enable: false,
          id: null,
          found: null,
          name: '',
          path: '',
        };
      }
      this.RefreshUI();
      this.setTreeViewportHeight();
    }, 300);
    
  }
  
  HideUnusedNodes(treeComponent: TreeComponent) {
    treeComponent.treeModel.filterNodes(node => node.data.isBeingUsed, false);
  }
  
  AddToExpandNodesList(dataSourceType: string, nodeIds: number[]) {
    const expandNodesForDatasource = this.nodesToExpand[dataSourceType];
    
    if (expandNodesForDatasource) {
      this.nodesToExpand[dataSourceType] = expandNodesForDatasource.concat(nodeIds);
    } else {
      this.nodesToExpand[dataSourceType] = nodeIds;
    }
  }
  
  ExpandNodesAfterTreeCreated() {
    Object.keys(this.nodesToExpand)
    .forEach(dataSourceType => {
      this.ExpandNodes(dataSourceType, this.nodesToExpand[dataSourceType]);
      delete this.nodesToExpand[dataSourceType];
    });
  }
  
  ExpandNodes(dataSourceType: string, nodeIds: number[]) {
    const treeComponent = this.GetTreeComponent(dataSourceType);
    
    nodeIds.forEach(nodeId => {
      const nodeToExpand = treeComponent.treeModel.getNodeById(nodeId);
      nodeToExpand.expand();
    });
  }
  
  InitTree(type: string, treeComponent: TreeComponent) {
    this.dsTrees[type] = treeComponent;
    
    const apiTree = this.dsTrees['Api'];
    if (apiTree) {
      this.dataSourcesService.apiTreeNodes = (apiTree.treeModel as TreeModel).getVisibleRoots();
    }
  }
  
  openUploadDialog(dataSourceType: string, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.RefreshUI();
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    this.genericDialogService.openDialog(
      {
        type: dataSourceType,
      },
      DatasourceDialogComponent,
    );
    this.RefreshUI();
  }
  
  DeleteCategory(treeComponent: TreeComponent, node: TreeNode) {
    this.dataSourcesService.RemoveVirtualNode(node.id)
    .subscribe(Response => {
      switch (Response.node.dataSourceType) {
        case DatasourceTypeId.Api:
          const apiNode = this.CreateChildrenNode(Response.node, [], DatasourceTypeId.Api, '');
          this.apiNodes = apiNode.children;
          break;
        case DatasourceTypeId.Custom:
          const customNode = this.CreateChildrenNode(Response.node, [], DatasourceTypeId.Custom, '');
          this.customNodes = customNode.children;
          break;
        case DatasourceTypeId.Database:
          const dbNode = this.CreateChildrenNode(Response.node, [], DatasourceTypeId.Database, '');
          this.dbNodes = dbNode.children;
          break;
        case DatasourceTypeId.LeapXL:
          const leapxlNode = this.CreateChildrenNode(Response.node, [], DatasourceTypeId.LeapXL, '');
          this.leapXLNodes = leapxlNode.children;
          break;
        case DatasourceTypeId.Spreadsheet:
          const spreadsheetNode = this.CreateChildrenNode(Response.node, [], DatasourceTypeId.Api, '');
          this.spreadsheetNodes = spreadsheetNode.children;
          break;
        case DatasourceTypeId.System:
          const systemNode = this.CreateChildrenNode(Response.node, [], DatasourceTypeId.System, '');
          this.systemNodes = systemNode.children;
          break;
        case DatasourceTypeId.UnifiedDatabase:
          const unifiedDbNode = this.CreateChildrenNode(Response.node, [], DatasourceTypeId.UnifiedDatabase,
            '');
          this.unifiedDbNodes = unifiedDbNode.children;
          break;
        case DatasourceTypeId.InternetMessaging:
          const internetMessagingNode = this.CreateChildrenNode(Response.node, [],
            DatasourceTypeId.InternetMessaging, '');
          this.internetMessagingNodes = internetMessagingNode.children;
          break;
        case DatasourceTypeId.LocalDataStore:
          const localDataStoreNode = this.CreateChildrenNode(Response.node, [],
            DatasourceTypeId.LocalDataStore, '');
          this.localDataStoreNodes = localDataStoreNode.children;
          break;
      }
      
      this.RefreshUI();
      treeComponent.treeModel.update();
      this.RefreshUI();
      this.setTreeViewportHeight();
    });
  }
  
  openCreateDataSourceDialog(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.RefreshUI();
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    this.genericDialogService.openDialog(null, NewDatasourceDialogComponent);
    this.RefreshUI();
  }
  
  AddApiConnection(event: MouseEvent) {
    this.draggableWindowService.OpenDraggableWindow(
      `Api Connection`,
      DraggableWindowType.ApiSetup,
      event,
    );
  }
  
  AddUnifiedDbConnection(event: MouseEvent) {
    this.draggableWindowService.OpenDraggableWindow(
      `Unified DB Connection`,
      DraggableWindowType.UnifiedDbConnection,
      event,
    );
  }
  
  ToggleDataSourceSection(dataSourceTypeId: number, state: boolean) {
    switch (dataSourceTypeId) {
      case DatasourceTypeId.System:
        this.workareaService.systemSectionOpen = state;
        break;
      case DatasourceTypeId.Api:
        this.workareaService.apiSectionOpen = state;
        break;
      case DatasourceTypeId.Custom:
        this.workareaService.customSectionOpen = state;
        break;
      case DatasourceTypeId.Database:
        this.workareaService.databaseSectionOpen = state;
        break;
      case DatasourceTypeId.InternetMessaging:
        this.workareaService.internetMessagingSectionOpen = state;
        break;
      case DatasourceTypeId.LeapXL:
        this.workareaService.leapXLSectionOpen = state;
        break;
      case DatasourceTypeId.Spreadsheet:
        this.workareaService.spreadsheetsSectionOpen = state;
        break;
      case DatasourceTypeId.UnifiedDatabase:
        this.workareaService.unifiedDatabaseSectionOpen = state;
        break;
      case DatasourceTypeId.LocalDataStore:
        this.workareaService.localDataStoreSectionOpen = state;
        break;
    }
  }
  
  RefreshDataSource(datasourceTypeId: number, event?: MouseEvent, refresh = true) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.GetDataSourcesSection([datasourceTypeId], false, refresh);
  }
  
  GetDataSourcesSection(dataSourceTypeIds: number[] = [], scrollToPath = false, refresh = false) {
    this.RefreshUI();
    
    if (dataSourceTypeIds.length > 0) {
      dataSourceTypeIds.forEach(id => {
        switch (id) {
          case DatasourceTypeId.System:
            this.systemSectionLoading = true;
            break;
          case DatasourceTypeId.Api:
            this.apiSectionLoading = true;
            break;
          case DatasourceTypeId.Custom:
            this.customNodes = [];
            this.customSectionLoading = true;
            break;
          case DatasourceTypeId.Database:
            this.databaseSectionLoading = true;
            break;
          case DatasourceTypeId.InternetMessaging:
            this.internetMessagingSectionLoading = true;
            break;
          case DatasourceTypeId.LeapXL:
            this.leapXLSectionLoading = true;
            break;
          case DatasourceTypeId.Spreadsheet:
            this.spreadsheetsSectionLoading = true;
            break;
          case DatasourceTypeId.UnifiedDatabase:
            this.unifiedDatabaseSectionLoading = true;
            break;
          case DatasourceTypeId.LocalDataStore:
            this.localDataStoreSectionLoading = true;
            break;
        }
      });
    }
    
    this.dataSourcesService.GetDataSources(dataSourceTypeIds, refresh)
    .subscribe(datasourceResponse => {
      this.dataSourcesService.datasourcesTreeCache.enabledDataSourceTypeIds = datasourceResponse.enabledDataSourceTypeIds;
      this.dataSourceTypeIdsEnabled = datasourceResponse.enabledDataSourceTypeIds;
      this.RefreshUI();
      this.setTreeViewportHeight();
      this.angularZone.runOutsideAngular(() => {
        this.RefreshTree(datasourceResponse.nodes, scrollToPath);
      });
      
      if (dataSourceTypeIds.length > 0) {
        dataSourceTypeIds.forEach(id => {
          switch (id) {
            case DatasourceTypeId.System:
              this.systemSectionLoading = false;
              break;
            case DatasourceTypeId.Api:
              this.apiSectionLoading = false;
              break;
            case DatasourceTypeId.Custom:
              this.customSectionLoading = false;
              if (this.customDatasourceContext) {
                setTimeout(() => {
                  this.OpensAndHighlightDatasourcePathWithContext(this.customDatasourceContext, false);
                  this.customDatasourceContext = '';
                }, 500);
              }
              break;
            case DatasourceTypeId.Database:
              this.databaseSectionLoading = false;
              break;
            case DatasourceTypeId.InternetMessaging:
              this.internetMessagingSectionLoading = false;
              break;
            case DatasourceTypeId.LeapXL:
              this.leapXLSectionLoading = false;
              break;
            case DatasourceTypeId.Spreadsheet:
              this.spreadsheetsSectionLoading = false;
              this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetReloaded.emit(false);
              break;
            case DatasourceTypeId.UnifiedDatabase:
              this.unifiedDatabaseSectionLoading = false;
              break;
            case DatasourceTypeId.LocalDataStore:
              this.localDataStoreSectionLoading = false;
              break;
          }
        });
        
        setTimeout(() => {
          this.ExpandNodesAfterTreeCreated();
          this.ScrollToNode(`${ this.scrollToNode }`);
          this.scrollToNode = '';
        }, 600);
      }
    });
  }
  
  ScrollToNode(identifier: string) {
    if (identifier && identifier !== '') {
      const nodeElement = document.querySelector(identifier);
      if (nodeElement) {
        nodeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }
  
  GetOpenDataSourceTypeIdsSections(): number[] {
    const openDatasourcesIds = [];
    
    if (this.workareaService.apiSectionOpen) {
      openDatasourcesIds.push(DatasourceTypeId.Api);
    }
    if (this.workareaService.unifiedDatabaseSectionOpen) {
      openDatasourcesIds.push(DatasourceTypeId.UnifiedDatabase);
    }
    if (this.workareaService.spreadsheetsSectionOpen) {
      openDatasourcesIds.push(DatasourceTypeId.Spreadsheet);
    }
    if (this.workareaService.leapXLSectionOpen) {
      openDatasourcesIds.push(DatasourceTypeId.LeapXL);
    }
    if (this.workareaService.internetMessagingSectionOpen) {
      openDatasourcesIds.push(DatasourceTypeId.InternetMessaging);
    }
    if (this.workareaService.databaseSectionOpen) {
      openDatasourcesIds.push(DatasourceTypeId.Database);
    }
    if (this.workareaService.customSectionOpen) {
      openDatasourcesIds.push(DatasourceTypeId.Custom);
    }
    if (this.workareaService.systemSectionOpen) {
      openDatasourcesIds.push(DatasourceTypeId.System);
    }
    if (this.workareaService.localDataStoreSectionOpen) {
      openDatasourcesIds.push(DatasourceTypeId.LocalDataStore);
    }
    
    return openDatasourcesIds;
  }
  
  LocateDatasourceUses(node: any, event: MouseEvent) {
    this.draggableWindowService.OpenDraggableWindow(
      `${ node.name } Connections`,
      DraggableWindowType.DatasourceConnected,
      event,
      { node },
    );
  }
  
  SetApiFilteringLoading(e: any) {
    this.apiFilteringSearch = true;
  }
  
  TogglePinDatasource(node: any, event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    
    node.data.pinned = !node.data.pinned;
    
    if (this.pinnedDsNode && this.pinnedDsNode.id !== node.data.id) {
      this.pinnedDsNode.pinned = false;
      this.pinnedDsNode = null;
    }
    
    if (node.data.pinned) {
      this.pinnedDsNode = node.data;
    }
    
    this.pinnedDsId = node.data.pinned ? node.data.id : 0;
  }
  
  Fuzzysearch(needle: string, haystack: string) {
    const haystackLC = haystack.toLowerCase();
    const needleLC = needle.toLowerCase();
    
    const hlen = haystack.length;
    const nlen = needleLC.length;
    
    if (nlen > hlen) {
      return false;
    }
    if (nlen === hlen) {
      return needleLC === haystackLC;
    }
    outer: for (let i = 0, j = 0; i < nlen; i++) {
      const nch = needleLC.charCodeAt(i);
      
      while (j < hlen) {
        if (haystackLC.charCodeAt(j++) === nch) {
          continue outer;
        }
      }
      return false;
    }
    return true;
  }
  
  toggleSection(options: { dataSourceTypeId: DatasourceTypeId; open: boolean }) {
    const dataSourceIds = [
      DatasourceTypeId.Spreadsheet,
      DatasourceTypeId.Database,
      DatasourceTypeId.Api,
      DatasourceTypeId.System,
      DatasourceTypeId.LeapXL,
      DatasourceTypeId.UnifiedDatabase,
      DatasourceTypeId.Custom,
      DatasourceTypeId.InternetMessaging,
      DatasourceTypeId.LocalDataStore,
    ];
    
    if (this.workareaService.editorPreferences.oneDatasourceOpenAtTime) {
      dataSourceIds.forEach(datasource =>
        this.communicationService.Event.Editor.DataSource.ToggleSection.emit(
          { dataSourceTypeId: datasource, open: false }),
      );
    }
    this.communicationService.Event.Editor.DataSource.ToggleSection.emit(options);
    setTimeout(() => this.setTreeViewportHeight(), 20);
  }
  
  setTreeViewportHeight() {
    this.windowHeight = window.innerHeight;
    const elements = document.querySelectorAll('data-sources-panel tree-root');
    if (!elements) {
      return;
    }
    const headersHeight = Constants.SidePanelSectionHeaderHeight * 9;
    const separatorsHeight = Constants.SeparatorSectionHeaderHeight * 8;
    const toolbarHeight = this.workareaService.editorPreferences.compactToolBarPosition === 'top' ? Constants.ToolbarHeight : 0;
    elements.forEach(element => {
      this.renderer.setStyle(
        element.children[0],
        'max-height',
        `${ this.windowHeight - ((this.workareaService.apiSectionOpen ? 104 : 42) + headersHeight + separatorsHeight + toolbarHeight) }px`,
      );
    });
    this.RefreshUI();
  }
  
  OpenOneSectionAtTime() {
    if (!this.workareaService.editorPreferences.oneDatasourceOpenAtTime) {
      return;
    }
    
    let sectionOpen = false;
    
    if (this.workareaService.customSectionOpen) {
      sectionOpen = true;
    }
    if (this.workareaService.apiSectionOpen) {
      if (sectionOpen) {
        this.workareaService.apiSectionOpen = false;
        this.communicationService.Event.Editor.DataSource.ToggleSection.emit({
          dataSourceTypeId: DatasourceTypeId.Api,
          open: false,
        });
      } else {
        sectionOpen = true;
      }
    }
    if (this.workareaService.databaseSectionOpen) {
      if (sectionOpen) {
        this.communicationService.Event.Editor.DataSource.ToggleSection.emit({
          dataSourceTypeId: DatasourceTypeId.Database,
          open: false,
        });
      } else {
        sectionOpen = true;
      }
    }
    if (this.workareaService.systemSectionOpen) {
      if (sectionOpen) {
        this.communicationService.Event.Editor.DataSource.ToggleSection.emit({
          dataSourceTypeId: DatasourceTypeId.System,
          open: false,
        });
      } else {
        sectionOpen = true;
      }
    }
    if (this.workareaService.spreadsheetsSectionOpen) {
      if (sectionOpen) {
        this.communicationService.Event.Editor.DataSource.ToggleSection.emit({
          dataSourceTypeId: DatasourceTypeId.Spreadsheet,
          open: false,
        });
      } else {
        sectionOpen = true;
      }
    }
    if (this.workareaService.leapXLSectionOpen) {
      if (sectionOpen) {
        this.communicationService.Event.Editor.DataSource.ToggleSection.emit({
          dataSourceTypeId: DatasourceTypeId.LeapXL,
          open: false,
        });
      } else {
        sectionOpen = true;
      }
    }
    if (this.workareaService.localDataStoreSectionOpen) {
      if (sectionOpen) {
        this.communicationService.Event.Editor.DataSource.ToggleSection.emit({
          dataSourceTypeId: DatasourceTypeId.LocalDataStore,
          open: false,
        });
      } else {
        sectionOpen = true;
      }
    }
    if (this.workareaService.unifiedDatabaseSectionOpen) {
      if (sectionOpen) {
        this.communicationService.Event.Editor.DataSource.ToggleSection.emit({
          dataSourceTypeId: DatasourceTypeId.UnifiedDatabase,
          open: false,
        });
      } else {
        sectionOpen = true;
      }
    }
    if (this.workareaService.internetMessagingSectionOpen) {
      if (sectionOpen) {
        this.communicationService.Event.Editor.DataSource.ToggleSection.emit({
          dataSourceTypeId: DatasourceTypeId.InternetMessaging,
          open: false,
        });
      } else {
        sectionOpen = true;
      }
    }
    
    this.RefreshUI();
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowHeight = event.target.innerHeight;
    this.setTreeViewportHeight();
  }
  
  setLastUsedElements() {
    if (!this.workareaService.lastUsedElements.dataelement) {
      return;
    }
    
    for (const [key, value] of Object.entries(this.lastUsedDataSources)) {
      if (this.workareaService.lastUsedElements.dataelement[key]) {
        this.lastUsedDataSources[key] = this.workareaService.lastUsedElements.dataelement[key];
      }
    }
  }
  
  TreeChanged(treeModel: TreeModel) {
    const nodesEl = document.querySelectorAll('#api-tree-node span.name');
    this.selectedNodes = [];
    
    nodesEl.forEach(nodeEl => {
      const treeNode = nodeEl.closest('tree-node');
      (treeNode as HTMLElement).classList.add('.hide-node');
      const checkbox = treeNode.querySelector('tree-node-checkbox') as HTMLElement;
      if (checkbox) {
        (checkbox as HTMLElement).style.display = null;
      }
    });
    
    treeModel.doForAll((node: TreeNode) => {
      if (node.isSelected && node.isRoot) {
        this.selectedNodes.push(node);
        const nodeEl = Array.from(nodesEl)
        .find(htmlNode => htmlNode.classList.contains(`ds-${ node.data.id }`));
        const treeNode = nodeEl?.closest('tree-node');
        const checkbox = treeNode?.querySelector('tree-node-checkbox') as HTMLElement;
        if (checkbox) {
          (checkbox as HTMLElement).style.display = 'block';
        }
      }
    });
    
    if (this.autoGenerationService.autogenerationId.length === 0) {
      this.communicationService.Event.Editor.DataSource.$ApiNodesSelected.emit(this.selectedNodes);
    }
    this.GetRemoveLabel();
    this.RefreshUI();
  }
  
  OpenMenu($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    
    this.apiTreeMenuTrigger.openMenu();
    this.RefreshUI();
  }
  
  DeleteDataSource() {
    
    const apisInUseByApp = [];
    const apisInUseByOtherApps = [];
    
    this.selectedNodes.forEach(node => {
      if (node.data.dataSourceType === DatasourceType.Api && node.data.ownerId !== this.userId) {
        if (node.data.isBeingUsed) {
          apisInUseByApp.push(node);
        } else {
          apisInUseByOtherApps.push(node);
        }
      }
      
    });
    
    
    this.dataSourcesService.AppsUsingDataSource(apisInUseByOtherApps.map(node => node.data.dataSourceId))
    .subscribe((apps) => {
      
      
      if (apisInUseByApp.length > 0 || apps.length > 0) {
        
        let message = '';
        
        if (apisInUseByApp.length > 0) {
          message += 'The following APIs are being used by this Application, remove them before deleting: \n\n';
          
          apisInUseByApp.forEach(node => {
            message += `- ${ node.data.name }\n`;
          });
          
          message += '\n';
        }
        
        if (apps.length > 0) {
          message += 'The following APIs are being used by other Apps, do you want to delete anyways?\n\n';
          
          message += 'APIs:\n\n';
          
          apisInUseByOtherApps.forEach(node => {
            message += `${ node.data.name }\n`;
          });
          
          message += '\nBeing used on Apps:\n\n';
          
          apps.forEach(app => {
            message += `- ${ app }\n`;
          });
        }
        
        this.dialogService.OpenConfirmDialog({
          title: 'Datasource being used',
          message: message,
          confirmText: 'Cancel',
          cancelText: apps.length > 0 ? 'Delete anyways' : '',
        })
        .then((results) => {
          if (results) {
          } else {
            this.RemoveDatasources(apisInUseByOtherApps);
          }
        });
        
        
      } else {
        this.RemoveDatasources(apisInUseByOtherApps);
      }
    });
    
  }
  
  RemoveDatasources(nodes: TreeNode[]) {
    this.dataSourcesService.DeleteDataSource(nodes.map(node => node.data.dataSourceId))
    .subscribe(result => {
      if (result) {
        if (result.status === 409) {
          this.snackerService.ShowMessageOnBottom(result.error.title);
        } else {
          this.selectedNodes = [];
          this.snackerService.ShowMessageOnBottom('Data Source has been removed', 'do_not_disturb_on');
          this.communicationService.Event.Editor.DataSource.$ReloadDataSourcePanel.emit([]);
        }
      } else {
        this.snackerService.ShowMessageOnBottom('Data Source could not be deleted because it is being used.',
          'do_not_disturb_off');
      }
    });
  }
  
  GetRemoveLabel() {
    let apiTypeCount = 0;
    
    this.selectedNodes.forEach(node => {
      if (this.userId !== node.data.ownerId && node.data.dataSourceType === DatasourceType.Api) {
        apiTypeCount++;
      }
    });
    
    if (apiTypeCount === this.selectedNodes.length) {
      this.removeAPIsLabel = 'Disconnect';
    } else if (apiTypeCount === 0) {
      this.removeAPIsLabel = 'Delete';
    } else {
      this.removeAPIsLabel = 'Remove';
    }
  }
  
  OpenAutoGenerationApp() {
    
    const data = {
      nodes: this.selectedNodes,
      directive: null,
    };
    
    this.dialog.open(GenerationAppComponent, {
      height: '800px',
      width: '1200px',
      disableClose: true,
      panelClass: 'custom-dialog-container',
      data,
    });
    
    setTimeout(() => {
      this.communicationService.Event.Editor.DataSource.$ApiNodesSelected.emit(this.selectedNodes);
    }, 200);
  }
  
  OpenAutoGenerationWizard() {
    this.dialog.open(GenerationAppWizardComponent, {
      height: '650px',
      width: '700px',
      disableClose: false,
      panelClass: 'custom-dialog-container',
      data: this.selectedNodes,
    });
    
    setTimeout(() => {
      this.communicationService.Event.Editor.DataSource.$ApiNodesSelected.emit(this.selectedNodes);
    }, 200);
  }
  
  private CreateDatasourceTrees(dataSourceTypeIds = []) {
    this.RefreshUI();
    console.log('creating ds tree');
    this.usedDataSourcesIds = this.dataSourcesService.UpdateUsedDatasourcesForApp();
    this.RefreshUI();
    
    const openSectionsIds = dataSourceTypeIds.length > 0 ? dataSourceTypeIds : this.GetOpenDataSourceTypeIdsSections();
    this.GetDataSourcesSection(openSectionsIds);
  }
}
