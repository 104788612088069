import { uniqBy } from 'lodash';
import { AppInjector } from '../../app-injector';
import { BusService } from '../../core/molecular/services/bus.service';
import { ToolsService } from '../../core/services/tools.service';
import { EventTrack } from '../interfaces/event-track.interface';
import { ActionMolecule } from '../representative-molecule/interfaces/action-molecules';
import { Bus } from '../representative-molecule/interfaces/bus';
import { DataElement } from '../representative-molecule/interfaces/data-element';
import { LeapXLEvent } from '../representative-molecule/interfaces/leapxl-event';
import { Particle } from '../representative-molecule/interfaces/particle';

export class ParticleTrackFlow {
  private eventSource: LeapXLEvent = null;
  private busSource: Bus = null;
  private toolsService: ToolsService;
  private busService: BusService;
  private trackDown = false;
  private trackUp = false;
  private readonly particle: Particle = null;
  private trackingUp = false;
  private trackingDown = false;
  private limitParticlesToEventTrack = false;
  private followSourceBus = false;
  
  private upResults: TrackResult = {
    Bus: null,
    Particle: null,
    Particles: [],
    Buses: [],
    Events: [],
    DataSources: null,
    ParticlesCount: 0,
    BusesCount: 0,
    EventsCount: 0,
    DataSourcesCount: 0,
    Tracks: [],
    MaxDataRequests: 0,
    MaxProcessDataRequests: 0,
    ActionMolecules: [],
    ActionMoleculesCount: 0,
    LoopEvent: null,
    LoopRepresentativeMoleculeId: null,
  };
  
  private downResults: TrackResult = {
    Bus: null,
    Particle: null,
    Particles: [],
    Buses: [],
    Events: [],
    DataSources: null,
    ParticlesCount: 0,
    BusesCount: 0,
    EventsCount: 0,
    DataSourcesCount: 0,
    Tracks: [],
    MaxDataRequests: 0,
    MaxProcessDataRequests: 0,
    ActionMolecules: [],
    ActionMoleculesCount: 0,
    LoopEvent: null,
    LoopRepresentativeMoleculeId: null,
  };
  
  constructor(particle: Particle = null, busSource: Bus = null, followSourceBus = false) {
    this.InjectServices();
    this.particle = particle;
    this.followSourceBus = followSourceBus;
    
    if (busSource) {
      this.busSource = busSource.Clone();
    }
    
    if (this.particle && this.particle.IsEvent()) {
      this.SetEventSource(this.particle as LeapXLEvent);
    } else {
      busSource = busSource || this.busService.GetAllBuses().find(bus => bus.ContainsParticle(particle));
      this.busSource = busSource.Clone();
      this.SetEventSource(this.busSource.FirstParticle().IsEvent() ? this.busSource.FirstParticle() as LeapXLEvent : new LeapXLEvent());
    }
  }
  
  get TrackDownResult() {
    return this.downResults;
  }
  
  get TrackUpResult() {
    return this.upResults;
  }
  
  get EventSource() {
    return this.eventSource;
  }
  
  get TracksUp() {
    return this.upResults.Tracks;
  }
  
  get TracksDown() {
    return this.downResults.Tracks;
  }
  
  TrackDown() {
    console.log('track down');
    this.trackingDown = true;
    if (this.eventSource === null) {
      return [];
    }
    
    if (this.busSource && this.busSource.GetEvents().length === 1 && this.busSource.FirstParticle().IsEvent()) {
      return [];
    }
    
    this.AddTrackDown(this.TrackDownEventProcess(this.eventSource));
    this.Process(this.downResults);
    this.trackingDown = false;
    return this.TracksDown;
  }
  
  TrackUp(limitParticlesToEventTrack = false) {
    this.limitParticlesToEventTrack = limitParticlesToEventTrack;
    
    if (this.eventSource === null) {
      return [];
    }
    
    this.trackingUp = true;
    const buses = this.busService.GetAllBuses();
    this.busSource = this.busSource || buses.find((bus) => bus.ContainsParticle(this.eventSource)).Clone();
    this.upResults.Bus = this.busSource.Clone();
    const particleBuses = buses.filter((b) => b.ContainsParticle(this.eventSource));
    this.upResults.Buses.push(this.busSource);
    
    if (this.particle) {
      const particleIndex = this.busSource.Particles.findIndex(p => p.ParticleId === this.particle.ParticleId);
      this.busSource.Particles.length = particleIndex;
    }
    
    [this.busSource].forEach((pBus) => {
      if (pBus.FirstParticle() && pBus.FirstParticle().IsEvent()) {
        
        const section: EventTrack = {
          event: this.eventSource,
          eventId: this.eventSource.id,
          bus: pBus,
          repMolecule: this.busService.Get(this.busSource.RepresentativeMoleculeId.toString()),
          branches: [],
          level: 0,
          tracked: pBus.ContainsParticle(this.eventSource),
          weight: 0,
          particles: pBus.Particles.length,
        };
        
        const branch = this.TrackUpParticleProcess(pBus.FirstParticle() as LeapXLEvent, section);
        this.AddTrackUp(section);
      }
    });
    
    this.Process(this.upResults);
    this.trackingUp = false;
    
    return this.TracksUp;
  }
  
  private SetEventSource(event: LeapXLEvent) {
    this.eventSource = event;
  }
  
  private AddTrackDown(track: EventTrack) {
    this.downResults.Tracks.push(track);
    this.trackDown = true;
  }
  
  private AddTrackUp(track: EventTrack) {
    this.upResults.Tracks.push(track);
    this.trackUp = true;
  }
  
  private Process(result: TrackResult) {
    let loops = [];
    result.Particles = ((result.Buses.map((bus) => bus.Particles) as any).flat() as Particle[]);
    result.Events = (result.Buses.map((bus) => bus.GetEvents(false, this.trackingUp)) as any).flat();
    result.ActionMolecules = (result.Buses.map((bus) => bus.GetActionMolecules(this.trackingUp)) as any).flat();
    result.DataSources = ((result.Buses.map((bus) => bus.GetDataElements(this.trackingUp)) as any).flat() as DataElement[]);
    result.BusesCount = result.Buses.length;
    result.ParticlesCount = result.Particles.length;
    result.EventsCount = result.Events.length;
    result.ActionMoleculesCount = result.ActionMolecules.length;
    result.DataSourcesCount = result.DataSources.length;
    result.MaxDataRequests = (result.Buses.map((bus) => bus.GetActionMoleculeParticlesByInternalName('GetElementsDatasourceDataMolecule')) as any).flat().length;
    result.MaxProcessDataRequests = (result.Buses.map((bus) => bus.GetActionMoleculeParticlesByInternalName('AddToDatasourceMolecule')) as any).flat().length;
    result.Tracks.forEach(t => {
      loops.push(this.IsLoop(t));
    });
    loops = ((loops as any).flat() as {
      event: LeapXLEvent,
      repMoleculeId: number
    }[]);
    result.LoopEvent = loops.length > 0 ? loops[0].event : null;
    result.LoopRepresentativeMoleculeId = loops.length > 0 ? loops[0].repMoleculeId : null;
    result.Particle = this.particle;
  }
  
  private TrackDownEventProcess(event: LeapXLEvent, track: EventTrack = null, eventsProcessed = []) {
    let section: EventTrack = null;
    
    if (track) {
      section = {
        event: event,
        eventId: event.id,
        bus: null,
        repMolecule: null,
        branches: [],
        level: 0,
        weight: 0,
        particles: 0,
        tracked: false,
        loop: null,
      };
      
      track.branches.push(section);
    } else {
      track = {
        event: event,
        eventId: event.id,
        bus: null,
        repMolecule: null,
        branches: [],
        level: 0,
        weight: 0,
        particles: 0,
        tracked: false,
        loop: null,
      };
      
      section = track;
    }
    
    const buses = this.followSourceBus && event.id === this.eventSource.id && this.busSource ? [this.busSource] : this.busService.GetAllBusesInitiatedByEvent(event);
    const eventProcessed = { event: event.id, particles: 0 };
    eventsProcessed.push(eventProcessed);
    
    buses.forEach((bus) => {
      this.downResults.Buses.push(bus);
      const busEvents = bus.GetEvents(true);
      section.bus = bus;
      section.particles = section.particles + bus.Particles.length;
      section.repMolecule = this.busService.Get(bus.RepresentativeMoleculeId.toString());
      
      eventProcessed.particles = bus.Particles.length;
      busEvents.forEach((bEvent) => {
        if (!eventsProcessed.map((ep) => ep.event).includes(bEvent.id)) {
          this.TrackDownEventProcess(bEvent, section, eventsProcessed);
        } else {
          section.loop = bEvent;
        }
      });
    });
    
    return track;
  }
  
  private TrackUpParticleProcess(event: LeapXLEvent, track: EventTrack = null, eventsProcessed = [], level = 0) {
    
    if (eventsProcessed.includes(event.Identifier)) {
      return;
    }
    
    level++;
    
    eventsProcessed.push(event.Identifier);
    const buses = this.busService.GetAllBuses();
    const dispatchBuses = buses.filter((b) => b.DispatchEvent(event));
    const uniqueBuses = uniqBy(dispatchBuses, (bus) => {
      return bus.FirstParticle().ParticleId;
    });
    
    uniqueBuses.forEach((dispatchBus) => {
      let section: EventTrack = null;
      
      if (track) {
        section = {
          event: event,
          eventId: event.id,
          bus: null,
          repMolecule: null,
          branches: [],
          level: level,
          weight: 0,
          particles: 0,
          tracked: false,
        };
        
        track.branches.push(section);
      } else {
        section = {
          event: event,
          eventId: event.id,
          bus: null,
          repMolecule: null,
          branches: [],
          level: level,
          weight: 0,
          particles: 0,
          tracked: false,
        };
      }
      
      let eventBus = dispatchBus;
      
      // remove particles from bus not triggered by event
      if (this.limitParticlesToEventTrack) {
        eventBus = dispatchBus.Clone(true);
        eventBus.Particles.length = eventBus.Particles.findIndex(p => p.IsEvent() && (p as LeapXLEvent).IsSameSpecificEvent(event));
      }
      //
      
      this.upResults.Buses.push(eventBus);
      section.bus = dispatchBus;
      const repMolecule = this.busService.Get(dispatchBus.RepresentativeMoleculeId.toString());
      section.repMolecule = repMolecule;
      section.particles = dispatchBus.Particles.length;
      
      if (dispatchBus.FirstParticle().IsEvent()) {
        this.TrackUpParticleProcess(dispatchBus.FirstParticle() as LeapXLEvent, section, eventsProcessed, level);
      }
    });
    
    return track;
  }
  
  private IsLoop(track: EventTrack, loops: {
    event: LeapXLEvent,
    repMoleculeId: number
  }[] = []): {
    event: LeapXLEvent,
    repMoleculeId: number
  }[] {
    
    if (track.branches.length === 0) {
      if (track.loop) {
        loops.push({
          event: track.loop,
          repMoleculeId: track.repMolecule.Id,
        });
      }
      return loops;
    } else {
      track.branches.forEach(b => {
        this.IsLoop(b, loops);
      });
    }
    
    return loops;
  }
  
  private InjectServices() {
    const injector = AppInjector.getInjector();
    this.toolsService = injector.get(ToolsService);
    this.busService = injector.get(BusService);
  }
}

export interface TrackResult {
  Particle: Particle;
  Particles: Particle[];
  Buses: Bus[];
  Events: LeapXLEvent[];
  DataSources: DataElement[];
  ParticlesCount: number;
  BusesCount: number;
  EventsCount: number;
  DataSourcesCount: number;
  Tracks: EventTrack[];
  MaxDataRequests: number;
  MaxProcessDataRequests: number;
  ActionMolecules: ActionMolecule[];
  ActionMoleculesCount: number;
  LoopEvent: LeapXLEvent;
  LoopRepresentativeMoleculeId: number;
  Bus: Bus;
}
