import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { CommunicationService } from '../../shared/services/communication.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class ProfilerInterceptor implements HttpInterceptor {
  constructor(
    private communicationService: CommunicationService,
  ) {
  }
  
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const started = Date.now();
    let ok: string;
    
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) =>
          (ok = event instanceof HttpResponse ? 'succeeded' : ''),
        (error: HttpErrorResponse) => (ok = 'failed'),
      ),
      // Log when response observable either completes or errors
      finalize(() => {
        const elapsed = Date.now() - started;
        const msg = `${ req.method } "${ req.urlWithParams }" ${ ok } in ${ elapsed } ms.`;
        // console.log(msg);
      }),
    );
  }
}
