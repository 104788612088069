export enum RepresentativeMoleculeTypeIcons {
  WorkGroup = 'fullscreen',
  Textbox = 'input',
  Textarea = 'format_shapes',
  Iframe = 'preview',
  Label = 'label',
  Breadcrumb = 'circle_notifications',
  Table = 'view_comfy',
  Button = 'touch_app',
  Custom = 'settings_panorama',
  Badge = 'double_arrow',
  Icon = 'stars',
  Radio = 'radio_button_checked',
  Checkbox = 'check_box',
  Dropdown = 'view_list',
  Report = 'lab_profile',
  Datepicker = 'date_range',
  H1 = 'format_h1',
  H2 = 'format_h2',
  H3 = 'format_h3',
  H4 = 'format_h4',
  H5 = 'format_h5',
  Image = 'image',
  Chart = 'insert_chart_outlined',
  All = 'apps',
  QrCode = 'qr_code',
  Stepper = 'switch_left',
  Slider = 'tune',
  Progress = 'hourglass_empty',
}
