import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { DraggableWindowService } from '../../shared/services/draggable-window.service';
import { DocumentationService } from '../services/documentation.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-documentation',
  templateUrl: './text-component.component.html',
  styleUrls: ['./text-component.component.scss'],
})
export class TextComponent implements OnInit, OnDestroy {
  
  DraggableWindowType = 'TextComponent';
  @Input()
  title = '';
  @Input()
  content = '';
  
  constructor(
    private documentationService: DocumentationService,
    private draggableWindowService: DraggableWindowService,
    private workAreaService: WorkAreaService,
    private communicationService: CommunicationService,
    private clientStorageService: ClientStorageService,
  ) {
    const data = this.draggableWindowService.GetData();
    
    if (data) {
      this.title = data.title || '';
      this.content = (data.content || '').replaceAll('\n', '<br>');
    }
  }
  
  ngOnInit() {
  }
  
  ngOnDestroy(): void {
  }
}
