import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class TranslateMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(particleId: string, repMoleculeId: string, busProcessorKey: string, rule: any, logic: any, data: any) {
    try {
      data = data === undefined || data === null || data === {} ? '' : data;
      
      if (rule === null || rule.words.length === 0 || rule.translation.length === 0) {
        //console.warn('no rule values found');
        return;
      }
      
      if (rule.words.constructor !== Array) {
        rule.words = rule.words.split('|');
      }
      
      if (rule.translation.constructor !== Array) {
        rule.translation = rule.translation.split('|');
      }
      
      if (rule.words.length !== rule.translation.length) {
        //console.warn('rule or logic does not exist or not the same number of values');
        return;
      }
      let inputValue: string = this.busService.Get(repMoleculeId).GetValue;
      
      for (let i = 0; i < rule.words.length; i++) {
        inputValue = inputValue.replace(rule.words[i], rule.translation[i]);
      }
      const outputValue: string = inputValue;
      
      this.busService.Get(repMoleculeId).SetValue(outputValue);
      
      data.outputValue = outputValue;
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
