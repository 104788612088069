import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class SymbolOnlyMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    dataBus: any,
  ) {
    try {
      dataBus = dataBus || '';
      
      if (this.ExistsRepMoleculeAttached) {
        dataBus = this.RepMoleculeAttached.GetValue;
      }
      
      dataBus = this.DataActionModifierApply(dataBus, this.Modifier, repMoleculeId);
      
      
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    }
  }
  
  public AfterRemove(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  private Modifier(value: string, repMoleculeId: number): string {
    const type = this.toolsService.GetObjectType(value);
    if (type === 'string') {
      value = value.replace(/[A-Za-z0-9]+/g, '');
      const repMolecule = this.busService.Get(repMoleculeId.toString());
      repMolecule.Disallow = true;
      setTimeout(() => {
        repMolecule.Disallow = false;
      }, 300);
    }
    return value;
  }
}
