export enum MoleculesType {
  DataAction = 'DataAction',
  Behavior = 'Behavior',
  Configuration = 'Configuration',
  Representative = 'Representative',
  Cobble = 'Cobble',
  Cobblet = 'Cobblet',
  WorkGroup = 'WorkGroup',
  CompoundMolecule = 'Compounds',
  None = 'None',
}
