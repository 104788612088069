import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'inputs-dialog',
  templateUrl: './inputs-dialog.component.html',
  styleUrls: ['./inputs-dialog.component.scss'],
})
export class InputsDialogComponent implements OnInit {
  title = '';
  message = '';
  confirmText = '';
  cancelText = '';
  centerText = false;
  inputData: { inputKey: string, inputLabel: string, input: FormControl }[];
  
  constructor(
    public dialogRef: MatDialogRef<InputsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message: string;
      confirmText: string;
      cancelText: string;
      centerText?: boolean;
      inputData: {
        inputKey: string,
        inputLabel: string
      }[]
    },
  ) {
    this.title = data.title;
    this.message = data.message;
    this.confirmText = data.confirmText;
    this.cancelText = data.cancelText;
    this.centerText = data.centerText || false;
  }
  
  ngOnInit() {
    this.inputData = this.data.inputData.map(data => ({
      inputKey: data.inputKey,
      inputLabel: data.inputLabel,
      input: new FormControl('')
    }));
  }
  
  Confirm() {
    const inputsValues = {};
    this.data.inputData.forEach((data, index) => inputsValues[data.inputKey] = this.inputData[index].input.value);
    this.dialogRef.close(inputsValues);
  }
}
