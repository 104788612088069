export enum AutoGenSubject {
  Action = 'subject-action',
  RepMolecule = 'subject-repMolecule',
  Component = 'subject-component',
  Process = 'subject-process',
  View = 'subject-view',
  Form = 'subject-form',
  Store = 'subject-store',
  Actionable = 'subject-actionable',
}
