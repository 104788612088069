import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { fromEvent, Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { map, startWith } from 'rxjs/operators';
import { CommunicationService } from '../../../shared/services/communication.service';
import { WorkAreaService } from '../../workarea.service';

@Component({
  selector: 'app-add-particle-to-bus',
  templateUrl: './add-particle-to-bus.component.html',
  styleUrls: ['./add-particle-to-bus.component.scss'],
})
export class AddParticleToBusComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocomplete) autoComplete: MatAutocomplete;
  @Input() particles;
  @Input() closeSearches: Subject<any>;
  @Output() particleSelected = new EventEmitter();
  @Input() fixed = false;
  closeSearchesSub$: Subscription;
  clickEvent$: Subscription;
  windowResized$: Subscription;
  particleRemoved$: Subscription;
  onSearch = false;
  particleGroupOptions: Observable<any[]>;
  particleForm = this._formBuilder.group({
    particleGroup: '',
  });
  
  constructor(
    private _formBuilder: FormBuilder,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private communicationService: CommunicationService,
    public workareaService: WorkAreaService,
  ) {
  }
  
  _horizontalLayout = false;
  
  @Input()
  set horizontalLayout(value: boolean) {
    this._horizontalLayout = value;
    if (this._horizontalLayout) {
      this.setIndicatorHeight();
    } else {
      const element = this.elementRef.nativeElement.querySelector('.search-indicator');
      if (!element) {
        return;
      }
      this.renderer.removeStyle(element, 'height');
    }
  };
  
  ngOnInit() {
    this.closeSearchesSub$ = this.closeSearches?.subscribe(() => this.onSearch = false);
    this.windowResized$ = this.communicationService.Event.System.$WindowResized?.subscribe(() => {
      if (this._horizontalLayout) {
        this.setIndicatorHeight();
      }
    });
    
    this.particleRemoved$ = this.communicationService.Event.Editor.$ParticleRemoved.subscribe(() => {
      if (this._horizontalLayout) {
        this.setIndicatorHeight();
      }
    });
    
    this.particleGroupOptions = this.particleForm.get('particleGroup')!.valueChanges.pipe(
      startWith(''),
      map(value => this._filterGroup(value || '')),
    );
  }
  
  ngOnDestroy() {
    this.closeSearchesSub$?.unsubscribe();
    this.clickEvent$?.unsubscribe();
    this.windowResized$?.unsubscribe();
    this.particleRemoved$?.unsubscribe();
  }
  
  _filter = (opt: any[], value: string): string[] => {
    const filterValue = value.toLowerCase();
    return opt.filter(item => (item.name as any).replaceAll(' ', '').toLowerCase().includes((filterValue as any).replaceAll(' ', '')));
  };
  
  _filterGroup(value: string): any[] {
    if (value) {
      return this.particles
      .map(group => ({ type: group.type, particles: this._filter(group.particles, value) }))
      .filter(group => group.particles.length > 0);
    }
    
    return this.particles;
  }
  
  showInput() {
    this.closeSearches?.next();
    this.onSearch = true;
    setTimeout(() => this.searchInput?.nativeElement.focus(), 50);
  }
  
  hideInput() {
    this.particleForm.controls.particleGroup.reset();
    this.onSearch = false;
    if (this._horizontalLayout) {
      this.setIndicatorHeight();
    }
    this.clickEvent$?.unsubscribe();
  }
  
  emitParticleSelected(value: string) {
    this.particleSelected.emit(value);
    this.hideInput();
  }
  
  onBlur() {
    this.clickEvent$?.unsubscribe();
    if (!this.autoComplete || !this.autoComplete.isOpen) {
      return;
    }
    
    this.clickEvent$ = fromEvent(document, 'click').subscribe(() => this.hideInput());
  }
  
  setIndicatorHeight() {
    setTimeout(() => {
      const busHeight = document.querySelector('.particles-list')?.clientHeight;
      const offsetTop = this.elementRef.nativeElement.offsetTop;
      const element = this.elementRef.nativeElement.querySelector('.search-indicator');
      this.renderer.setStyle(element, 'height', `${ busHeight - offsetTop }px`);
      this.renderer.addClass(element, 'align-start');
    }, 100);
  }
}
