import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { Receptor } from '../../core/molecular/receptors.enum';
import { BusService } from '../../core/molecular/services/bus.service';
import { EventsService } from '../../core/molecular/services/events.service';
import { ApiDataTranslatorService } from '../../core/services/api-data-translator.service';
import { ToolsService } from '../../core/services/tools.service';
import { Constants } from '../constants';
import { LeapXLEventType } from '../enums/leapxl-event-type.enum';
import { RepresentativeMoleculesType } from '../enums/representative-molecules-types.enum';
import { ActionMoleculeProperties } from '../representative-molecule/interfaces/action-molecule-properties';
import { Bus } from '../representative-molecule/interfaces/bus';
import { DataElement } from '../representative-molecule/interfaces/data-element';
import { IRepresentativeMolecule } from '../representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../representative-molecule/services/cobble.service';
import { FactoryParticleService } from './factory-particle.service';

@Injectable({
  providedIn: 'root',
})
export class FactoryService {
  
  customEventIdsCreated = [];
  
  constructor(private toolsService: ToolsService, private factoryParticleService: FactoryParticleService, private cobbleService: CobbleService, private dataTranslatorService: ApiDataTranslatorService, private busService: BusService, private eventsService: EventsService) {
  }
  
  GetApiLiteralHeadingsContext(context: string): string {
    const splittedContext = context.split(Constants.ContextSeparator);
    const datasourceType = splittedContext.shift();
    const datasourceName = splittedContext.shift();
    const collection = splittedContext.shift();
    const dataElementName = splittedContext.join(Constants.ContextSeparator);
    
    return `Custom${ Constants.ContextSeparator }${ datasourceName } (Headings)${ Constants.ContextSeparator }${ collection } (Headings)${ Constants.ContextSeparator }${ dataElementName } (Heading)`;
  }
  
  SetRepresentativeMoleculeBusDefaults(repMolecules: any[], dependencyRepMolecule: any, contexts: string [] = [], actionMoleculeProperties: ActionMoleculeProperties[], isRepresentativeMolecule = true, dataElements: DataElement[] = [], parent: IRepresentativeMolecule = null, customEvent = null): Bus[] {
    
    let busesAffected: Bus[] = [];
    repMolecules = repMolecules.length > 0 ? repMolecules : [{ id: 0 }];
    
    repMolecules.forEach(repMolecule => {
      switch (contexts[0]) {
        case 'form_add':
          
          // region Getting submit molecule
          if (dependencyRepMolecule) {
          
          } else {
            if (parent) {
              
              const submitNames = [
                'submit',
                'add',
                'insert',
                'create',
              ];
              const wgRepMolecules = this.busService.ChildrenElements(parent.Id);
              
              const submitRepMolecule = wgRepMolecules.find(wrm => wrm.Type === RepresentativeMoleculesType.Button && submitNames.includes(wrm.Properties.textToDisplay.toLowerCase()) && !!wrm.GetActionMolecules().find(am => am.InternalMoleculeName === 'NotificationMolecule')) ||
                wgRepMolecules.find(wrm => wrm.Type === RepresentativeMoleculesType.Button && submitNames.includes(wrm.Properties.textToDisplay.toLowerCase())) ||
                wgRepMolecules.find(wrm => submitNames.includes(wrm.Properties.textToDisplay.toLowerCase()) && !!wrm.GetActionMolecules().find(am => am.InternalMoleculeName === 'NotificationMolecule')) ||
                wgRepMolecules.find(wrm => submitNames.includes(wrm.Properties.textToDisplay.toLowerCase()));
              
              dependencyRepMolecule = submitRepMolecule || { id: 0 };
            } else {
              dependencyRepMolecule = { id: 0 };
            }
          }
          // endregion
          
          const busToAdd = isRepresentativeMolecule ? (repMolecule as IRepresentativeMolecule).GenerateNewBus(false, null, Receptor.ValueOutput) : new Bus({
            id: this.toolsService.GenerateGuid(),
            Name: `Form Workgroup ${
              repMolecule.properties.find((p) => p.name === 'textToDisplay').value
            }`,
            Receptor: Receptor.ValueOutput,
            Particles: [],
          });
          
          const addDatasourceMolecule = actionMoleculeProperties.find(amp => amp.type === 'AddToDatasourceMolecule');
          const clearValueMolecule = actionMoleculeProperties.find(amp => amp.type === 'ClearValueMolecule');
          
          busToAdd.AddParticle(this.factoryParticleService.GenerateEvent(LeapXLEventType.Click, 'Molecule', dependencyRepMolecule.id || dependencyRepMolecule.Id));
          
          if (addDatasourceMolecule) {
            busToAdd.AddParticle(this.factoryParticleService.GenerateActionMolecule(addDatasourceMolecule, repMolecule.id || repMolecule.Id || 0, dataElements));
          }
          
          if (customEvent) {
            // custom event provided
          } else {
            const eventId = this.toolsService.GenerateIdFromContext(dataElements[0].Context);
            customEvent = this.factoryParticleService.GenerateCustomEvent(eventId);
          }
          
          busToAdd.AddParticle(customEvent);
          
          if (this.customEventIdsCreated.includes(customEvent.id)) {
            // custom event already saved
          } else {
            this.eventsService.SaveEvent([customEvent]).subscribe((result) => {
            });
            this.customEventIdsCreated.push(customEvent.id);
          }
          
          if (this.eventsService.CustomEvents.find(ce => ce.EventName === customEvent.EventName)) {
            // found
          } else {
            this.eventsService.CustomEvents.push(customEvent);
          }
          
          
          if (clearValueMolecule) {
            busToAdd.AddParticle(this.factoryParticleService.GenerateActionMolecule(clearValueMolecule, repMolecule.id || repMolecule.Id || 0));
          }
          
          busesAffected.push(busToAdd);
          break;
        
        case 'form_update':
          
          // region Getting update molecule
          if (dependencyRepMolecule) {
          
          } else {
            if (parent) {
              
              const submitNames = [
                'update',
                'edit',
              ];
              const wgRepMolecules = this.busService.ChildrenElements(parent.Id);
              
              const submitRepMolecule = wgRepMolecules.find(wrm => wrm.Type === RepresentativeMoleculesType.Button && submitNames.includes(wrm.Properties.textToDisplay.toLowerCase()) && !!wrm.GetActionMolecules().find(am => am.InternalMoleculeName === 'NotificationMolecule')) ||
                wgRepMolecules.find(wrm => wrm.Type === RepresentativeMoleculesType.Button && submitNames.includes(wrm.Properties.textToDisplay.toLowerCase())) ||
                wgRepMolecules.find(wrm => submitNames.includes(wrm.Properties.textToDisplay.toLowerCase()) && !!wrm.GetActionMolecules().find(am => am.InternalMoleculeName === 'NotificationMolecule')) ||
                wgRepMolecules.find(wrm => submitNames.includes(wrm.Properties.textToDisplay.toLowerCase()));
              
              dependencyRepMolecule = submitRepMolecule || { id: 0 };
            } else {
              dependencyRepMolecule = { id: 0 };
            }
          }
          // endregion
          
          const firstBus = isRepresentativeMolecule ? (repMolecule as IRepresentativeMolecule).GenerateNewBus(false, null, 'value-input') : new Bus({
            id: this.toolsService.GenerateGuid(),
            Name: `Form Workgroup ${
              repMolecule.properties.find(
                (p) => p.name === 'textToDisplay',
              ).value
            }`,
            Receptor: 'value-input',
            Particles: [],
          });
          
          const takeDataElementsMolecule = actionMoleculeProperties.find(amp => amp.type === 'FilterByDataElementReferenceMolecule');
          const updateMolecule = actionMoleculeProperties.find(amp => amp.type === 'UpdateDatasourceDataMolecule');
          
          firstBus.AddParticle(this.factoryParticleService.GenerateEvent(LeapXLEventType.AppBroadcast));
          if (takeDataElementsMolecule) {
            firstBus.AddParticle(this.factoryParticleService.GenerateActionMolecule(takeDataElementsMolecule, repMolecule.id || repMolecule.Id || 0, dataElements));
          }
          
          const secondBus = isRepresentativeMolecule ? (repMolecule as IRepresentativeMolecule).GenerateNewBus(false, null, Receptor.ValueOutput) : new Bus({
            id: this.toolsService.GenerateGuid(),
            Name: `Form Workgroup ${
              repMolecule.properties.find(
                (p) => p.name === 'textToDisplay',
              ).value
            } (2)`,
            Receptor: Receptor.ValueOutput,
            Particles: [],
          });
          
          secondBus.AddParticle(this.factoryParticleService.GenerateEvent(LeapXLEventType.Click, 'Molecule', dependencyRepMolecule.id || dependencyRepMolecule.Id));
          
          if (updateMolecule) {
            secondBus.AddParticle(this.factoryParticleService.GenerateActionMolecule(updateMolecule, repMolecule.id || repMolecule.Id || 0, dataElements));
          }
          
          // region adding new data elements to parent populating
          if (parent) {
            const populatingMolecules = [RepresentativeMoleculesType.Dropdown];
            
            const repMoleculePopulating = this.busService.ChildrenElements(parent.Id).find(repMol => populatingMolecules.includes(
              repMol.Type as RepresentativeMoleculesType,
            ) && !!repMol.GetBusByReceptorAndParticleEventType(Receptor.ValueOutput, LeapXLEventType.AppBroadcast));
            
            if (repMoleculePopulating) {
              const getDatasourceDataMolecule = repMoleculePopulating.GetBusByReceptorAndParticleEventType(Receptor.ValueOutput, LeapXLEventType.AppBroadcast).GetActionMoleculeParticle('GetElementsDatasourceDataMolecule');
              
              if (getDatasourceDataMolecule) {
                getDatasourceDataMolecule.AddDataElements(firstBus.GetDataElements());
                repMoleculePopulating.SaveProperty('buses', 'Data Element added').subscribe();
              }
            }
          }
          // endregion
          
          busesAffected = busesAffected.concat([firstBus, secondBus]);
          break;
      }
      
      // region Defaults by type
      if (isRepresentativeMolecule || (repMolecule.properties && repMolecule.properties.find(p => p.name === 'type'))) {
        const representativeMoleculeType = isRepresentativeMolecule ? (repMolecule as IRepresentativeMolecule).Type : repMolecule.properties.find(p => p.name === 'type').value;
        
        switch (representativeMoleculeType) {
          case RepresentativeMoleculesType.Dropdown:
            
            const getElementsMolecule = actionMoleculeProperties.find(amp => amp.type === 'GetElementsDatasourceDataMolecule');
            
            const busToAdd = isRepresentativeMolecule ? (repMolecule as IRepresentativeMolecule).GenerateNewBus(false, null, Receptor.OptionsListInput) : new Bus({
              id: this.toolsService.GenerateGuid(),
              Name: `Form Workgroup ${
                repMolecule.properties.find((p) => p.name === 'textToDisplay').value
              }`,
              Receptor: Receptor.OptionsListInput,
              Particles: [],
            });
            
            busToAdd.AddParticle(this.factoryParticleService.GenerateEvent(LeapXLEventType.Init, 'Molecule', repMolecule.id || repMolecule.Id || 0));
            busToAdd.AddParticle(this.factoryParticleService.GenerateActionMolecule(getElementsMolecule, repMolecule.id || repMolecule.Id || 0, dataElements));
            busesAffected.push(busToAdd);
            break;
          default:
            break;
        }
      }
      // endregion
      
    });
    
    return busesAffected;
  }
  
  CreateTranslationsForDataElements(dataElements: DataElement[], editorDbMode: boolean = false): Observable<any> {
    return from(new Promise(resolve => {
      
      const dataElementsTranslation = [];
      
      dataElements.forEach(dataElement => {
        dataElementsTranslation.push({
          dataSourceId: null,
          applicationId: this.cobbleService.Cobble.id,
          dataSourceType: dataElement.DataSourceType,
          specialMode: editorDbMode,
          context: dataElement.Context,
          reference: dataElement.Reference,
        });
      });
      
      this.dataTranslatorService.CreateTranslation(dataElementsTranslation).subscribe((translations) => {
        dataElements.forEach((de, index) => {
          const translation = translations.find(t => t.context.toLowerCase() === de.Context.toLowerCase());
          de.TranslationId = translation.id;
          de.DataSourceId = translation.dataSourceId;
        });
        
        return resolve(dataElements);
      });
    }));
  }
}
