import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ApiLogService } from '../core/services/api-log.service';
import { LocalStorageService } from '../core/services/local-storage.service';
import { ToolsService } from '../core/services/tools.service';
import { ConnectionStateService } from './services/connection-state.service';
import { SnackerService } from './services/snacker.service';

@Injectable()
export class GlobalHandlerError implements ErrorHandler {
  inDebounce = null;
  
  constructor(private injector: Injector, private snackerService: SnackerService) {
  }
  
  handleError(error: Error | HttpErrorResponse) {
    console.log(error);
    const connectionStateService = this.injector.get(ConnectionStateService);
    const logsService = this.injector.get(ApiLogService);
    const toolsService = this.injector.get(ToolsService);
    const localStorageService = this.injector.get(LocalStorageService);
    
    if (error instanceof HttpErrorResponse) {
      
      if (error && error.status) {
        
        switch (error.status) {
          case 409:
            this.snackerService.ShowMessageOnBottom('No permission to access', 'gpp_maybe');
            break;
        }
      }
      
    } else {
      if (!connectionStateService.IsOnline) {
        connectionStateService.ShowNoConnectionStatePopup();
        return;
      }
      
      if (localStorageService.IsDebug()) {
        console.error('error', error);
        connectionStateService.ShowErrorPopup();
      } else {
        
        this.Throttle(
          (func, delay, context) => {
            const info = toolsService.GetSystemInformation();
            
            logsService
            .SaveErrorLog(
              info,
              error.name,
              error.message,
              error.stack,
              error.toString(),
            )
            .subscribe();
          },
          2000,
          this,
          null,
        );
      }
    }
  }
  
  Throttle(func, delay, context, args) {
    clearTimeout(this.inDebounce);
    this.inDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }
}
