import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { WjGridSheetModule } from '@grapecity/wijmo.angular2.grid.sheet';
import { WjInputModule } from '@grapecity/wijmo.angular2.input';
import { DndModule } from 'ng2-dnd';
import { FileUploadModule } from 'ng2-file-upload';
import { MaterialModule } from '../shared/material/material.module';
import { SpreadsheetRibbonComponent } from './spreadsheet-ribbon/spreadsheet-ribbon.component';
import { SpreadsheetComponent } from './spreadsheet/spreadsheet.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    WjInputModule,
    WjGridSheetModule,
    DndModule,
    FileUploadModule,
  ],
  exports: [SpreadsheetRibbonComponent, SpreadsheetComponent],
  declarations: [SpreadsheetRibbonComponent, SpreadsheetComponent],
  providers: [],
})
export class SpreadsheetModule {
}
