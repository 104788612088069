import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { catchError, map } from 'rxjs/operators';
import { ApiDataService } from '../../core/services/api-data.service';
import { IDatasourceAudit } from '../../shared/interfaces/datasource-audit.interface';
import { IDatasourceInfo } from '../../shared/interfaces/datasource-info.interface';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';

@Injectable()
export class ApiDataQualityDataService extends ApiDataService {
  constructor(http: HttpClient, errorMessengerService: ErrorMessengerService) {
    super('datasources', http, errorMessengerService);
  }
  
  
  GetDataSourceInfo(contexts: string[]): Observable<IDatasourceInfo[]> {
    return this.http.post(this.apiEndpointUrl + '/Info/', { contexts }).pipe(
      map(response => <any>response),
      catchError(error =>
        this.errorMessengerService.HandleError(
          error,
          `Error getting Info for element ${ contexts.join() }.`,
          contexts,
        ),
      ),
    );
  }
  
  GetDataSourceAuditChanges(contexts: string[]): Observable<IDatasourceAudit[]> {
    return this.http.post(this.apiEndpointUrl + '/GetDataAuditChanges/', { contexts }).pipe(
      map(response => <any>response),
      catchError(error =>
        this.errorMessengerService.HandleError(
          error,
          `Error getting Info for element ${ contexts.join() }.`,
          contexts,
        ),
      ),
    );
  }
  
  GetDataSubmittedForRange(id: number) {
    return this.http.get(this.apiEndpointUrl + `/logData/${ id }`).pipe(
      map(response => <any>response),
      catchError(error =>
        this.errorMessengerService.HandleError(error, `Error getting data for element ${ id }.`, id),
      ),
    );
  }
  
  GetDataAuditForRange(data: {
    dataSourceId: string;
    collection: string;
    range: string;
  }) {
    return this.http
    .post(this.apiEndpointUrl + `/GetAuditLog`, {
      dataSourceId: data.dataSourceId,
      collection: data.collection,
      range: data.range,
    })
    .pipe(
      map(response => <any>response),
      catchError(error =>
        this.errorMessengerService.HandleError(
          error,
          `Error getting Data Audit for datasource: ${ data.dataSourceId }, sheet: ${ data.collection }, range: ${ data.range }.`,
          data,
        ),
      ),
    );
  }
}
