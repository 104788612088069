import { Component, OnInit } from '@angular/core';
import * as fileSaver from 'file-saver';
import { cloneDeep } from 'lodash-es';
import { ApiAuthService } from '../../core/services/api-auth.service';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { ToolsService } from '../../core/services/tools.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { IApiAuth } from '../models/IApiAuth.interface';

@Component({
  selector: 'app-api-register',
  templateUrl: './api-register.component.html',
  styleUrls: ['./api-register.component.scss'],
})
export class ApiRegisterComponent implements OnInit {
  
  registerApi = false;
  apiRegistered = false;
  apiRegistering = false;
  apiRegister = {
    id: 0,
    name: '',
    description: '',
    domain: '',
    apiKey: '',
    apiSecret: '',
    creationDate: '',
    username: '',
  };
  
  apisRegistered = [];
  
  
  constructor(
    private dialogService: GenericDialogService,
    private authService: ApiAuthService,
    private toolsService: ToolsService,
    private snackerService: SnackerService,
  ) {
  }
  
  ngOnInit() {
    this.RefreshAPIsRegisteredList();
  }
  
  DeleteAPI(api: IApiAuth) {
    this.dialogService.OpenConfirmDialog({
      title: 'Delete API Key',
      message: `Are you sure you want to remove this API key?, once removed this operation can't be undone and any API using this credentials is going to lose access.`,
      confirmText: 'Delete',
      cancelText: 'Cancel',
    }).then(result => {
      if (result) {
        this.authService.DeleteAPIAuthentication(api.id).subscribe(response => {
          this.apisRegistered = this.apisRegistered.filter(ar => ar.id !== api.id);
          this.snackerService.ShowMessageOnBottom('API credentials deleted successfully', 'check_circle', null, true);
        });
      } else {
      
      }
    });
  }
  
  GoToAPIsList() {
    this.registerApi = false;
    this.ResetAPIRegister();
  }
  
  ResetAPIRegister() {
    this.registerApi = false;
    this.apiRegistered = false;
    this.apiRegistering = false;
    this.apiRegister = {
      id: 0,
      name: '',
      description: '',
      domain: '',
      apiKey: '',
      apiSecret: '',
      creationDate: '',
      username: '',
    };
  }
  
  RegisterApi() {
    this.registerApi = true;
  }
  
  GenerateAPICredentials() {
    this.apiRegistering = true;
    
    this.authService.APIRegistration(this.apiRegister).subscribe(response => {
      this.apisRegistered.push(cloneDeep(response));
      this.apiRegister = response;
      this.apiRegistered = true;
      this.apiRegistering = false;
      
      this.snackerService.ShowMessageOnBottom('API credentials generated', 'vpn_key');
    });
  }
  
  RefreshAPIsRegisteredList() {
    this.authService.RegisteredAPIS().subscribe(apis => {
      this.apisRegistered = apis;
    });
  }
  
  Copy(text: string) {
    this.snackerService.ShowMessageOnBottom('Copied to clipboard!', 'content_copy', null, true);
    this.toolsService.CopyToClipboard(text);
  }
  
  DownloadAPICredentials() {
    
    const credentials = [
      'LeapXL API Registered Credentials File\n\n',
      this.apiRegister.name + ': ' + this.apiRegister.domain + '\n',
      'Registered By: ' + this.apiRegister.username + '\n',
      'Registered On: ' + this.apiRegister.creationDate + '\n\n',
      '===================================================================\n\n',
      'API Key:\n',
      `${ this.apiRegister.apiKey }\n\n`,
      'API Secret:\n',
      `${ this.apiRegister.apiSecret }\n\n`,
      '===================================================================\n\n',
      '**Store this file in a secure place, this file contains critical information and access credentials**',
    ];
    
    this.snackerService.ShowMessageOnBottom('Downloading file...', 'downloading', null, true);
    
    const credentialsBlob = new Blob(credentials, { type: 'text/plain;charset=utf-8' });
    fileSaver.saveAs(credentialsBlob, this.apiRegister.name.replace(/ /g, '_') + '_leapxl_auth.txt');
  }
  
}
