import { Component, Input, OnInit } from '@angular/core';
import { IRepresentativeMolecule } from '../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CommunicationService } from '../../shared/services/communication.service';

@Component({
  selector: 'app-override-style',
  template: `
		<div
			class="override-style-container"
			[class.margin-bottom]="marginBottom"
		>
			<p
				class="override-style-message"
				[class.margin-left]="marginLeft"
			>
				This setting is overwriting the selected style
			</p>
			<i
				class="material-icons-round"
				matTooltip="Revert"
				(click)="RemoveProperty()"
			>undo</i>
		</div>
  `,
  styles: [`
      .override-style-message {
          font-size: 12px;
          margin-bottom: 0;
          color: #EA5C2F;
          line-height: 24px;
      }

      .margin-left {
          margin-left: 35px;
      }

      .margin-bottom {
          margin-bottom: 1.25em;
      }

      .override-style-container {
          display: flex;
          column-gap: 5px;
      }

      i {
          cursor: pointer;
          color: #EA5C2F;
      }
  `],
})
export class OverrideStyleComponent implements OnInit {
  @Input() marginLeft: boolean = true;
  @Input() marginBottom: boolean = true;
  @Input() repMolecule: IRepresentativeMolecule = null;
  @Input() propertyName = '';
  
  constructor(private communicationService: CommunicationService) {
  }
  
  ngOnInit(): void {
  }
  
  RemoveProperty() {
    this.repMolecule.StyleMetadata.manualAdjustedProperties = this.repMolecule.StyleMetadata.manualAdjustedProperties.filter(style => style.property !== this.propertyName);
    this.repMolecule.SaveProperty('StyleMetadata', 'Revert manual adjusted value').subscribe();
    this.communicationService.Event.Editor.WorkArea.$PropertiesUpdated.emit();
    this.repMolecule.ApplyAssignedStyle(false);
  }
  
}
