import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable()
export class AppConfig {
  public static adalSettings: any;
  
  constructor(private http: HttpClient) {
  }
  
  load() {
    const adalFile = `${ environment.apiUrl + 'Accounts/ADALConfiguration' }`;
    console.log('adal url', adalFile);
    return new Promise<void>((resolve, reject) => {
      this.http.get(adalFile).toPromise().then((response: any) => {
        console.log(response);
        AppConfig.adalSettings = <any>response;
        resolve();
      }).catch((response: any) => {
        console.log(response);
        reject(`Could not load config file : ${ JSON.stringify(response) }`);
        // AppConfig.adalSettings = {
        //   'tenant': '8e9ab507-56f8-4bb2-9255-9da5e3669bec',
        //   'clientId': 'bb94e447-ee45-4086-8e0e-13cac5f0c007',
        //   'redirectUri': 'https://localhost:4200/login/external/aad',
        //   'popUp': false,
        //   'endpoints': {
        //     'api': 'bb94e447-ee45-4086-8e0e-13cac5f0c007'
        //   },
        //   'navigateToLoginRequestUrl': false,
        //   'cacheLocation': 'localStorage'
        // };
        // resolve();
      });
    });
  }
}

