export enum RepresentativeMoleculesType {
  WorkGroup = 'WorkGroup',
  Textbox = 'Textbox',
  Textarea = 'Textarea',
  Iframe = 'Iframe',
  Label = 'Label',
  Breadcrumb = 'Breadcrumb',
  Table = 'Table',
  Button = 'Button',
  Badge = 'Badge',
  Icon = 'Icon',
  Radio = 'Radio',
  Checkbox = 'Checkbox',
  Dropdown = 'Dropdown',
  Report = 'Report',
  Datepicker = 'Datepicker',
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  H5 = 'H5',
  Image = 'Image',
  Chart = 'Chart',
  All = 'All',
  QrCode = 'QrCode',
  Stepper = 'Stepper',
  Slider = 'Slider',
  Progress = 'Progress',
  Custom = 'Custom',
}
