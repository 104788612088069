import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiMoleculesService } from '../../core/services/api-molecules.service';
import { ToolsService } from '../../core/services/tools.service';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { SnackerService } from '../../shared/services/snacker.service';

@Component({
  selector: 'app-cobble-published-dialog',
  templateUrl: './cobble-published-dialog.component.html',
  styleUrls: ['./cobble-published-dialog.component.scss'],
})
export class CobblePublishedDialogComponent implements OnInit {
  url = '';
  qrCodeImage = '';
  appData = null;
  
  constructor(
    public cobbleService: CobbleService,
    private toolsService: ToolsService,
    private moleculesService: ApiMoleculesService,
    public dialogRef: MatDialogRef<CobblePublishedDialogComponent>,
    private snackerService: SnackerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }
  
  ngOnInit() {
    
    this.appData = this.data.cobble ? this.data.cobble : this.cobbleService.Cobble;
    
    this.url = `${ document.location.protocol }//${ document.location.hostname }${
      document.location.hostname.search('localhost') > -1 ? ':4200' : ''
    }/run/${ this.appData.companySlug || '' }/${ this.appData.slug }`;
    
    this.toolsService.GenerateLeapXLQRCode(this.url).subscribe(image => {
      this.qrCodeImage = image;
    });
  }
  
  RunPublishedApp() {
    window.open(
      `${ this.toolsService.GetDomain() }/run/${ this.appData.companySlug }/${ this.appData.slug }`,
      '_blank',
    );
  }
  
  RunCobble() {
    window.open(
      `/run/${
        this.data.cobble
          ? this.data.cobble.slug
          : this.cobbleService.Cobble.slug
      }`,
      '_blank',
    );
    this.Close();
  }
  
  CopyAppLink() {
    this.toolsService.CopyToClipboard(this.url);
    this.snackerService.ShowMessageOnBottom('App link copy to clipboard', 'content_copy');
  }
  
  Close() {
    this.dialogRef.close();
  }
  
  PublicToggle(e: any) {
    this.moleculesService.updateCobbleSharing({
      id: this.cobbleService.Cobble.id,
      isPublic: this.cobbleService.Cobble.isPublic,
    }).subscribe(result => {
    });
  }
}
