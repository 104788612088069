import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BuilderService } from '../../../../core/builder/builder.service';
import { ProcessorService } from '../../../../core/molecular/services/processor.service';
import { WorkAreaService } from '../../../../workarea/workarea.service';
import { RepresentativeMoleculesType } from '../../../enums/representative-molecules-types.enum';
import { DragService } from '../../services/drag.service';
import { BaseMoleculeComponent } from '../base-molecule/base-molecule.component';

@Component({
  selector: 'app-radio-molecule',
  templateUrl: './radio-molecule.component.html',
  styleUrls: ['./radio-molecule.component.scss'],
})
export class RadioMoleculeComponent extends BaseMoleculeComponent
  implements OnInit {
  MoleculeType = 'Representative';
  Type = RepresentativeMoleculesType.Radio;
  
  @ViewChild('radioWrapper', { static: true })
  radioWrapper: ElementRef;
  
  constructor(
    public builderService: BuilderService,
    public dragService: DragService,
    public workAreaService: WorkAreaService,
    public processorService: ProcessorService,
    bottomSheet: MatBottomSheet,
    public el: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
    super(bottomSheet, el, changeDetectorRef);
  }
  
  ngOnInit() {
    super.ngOnInit();
    this.context.Type = RepresentativeMoleculesType.Radio;
    this.RefreshGridsterConfiguration();
  }
  
  AttachEditorEventListeners() {
    const dragoverEventListener = this.renderer.listen(this.radioWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, true);
    });
    const dragleaveEventListener = this.renderer.listen(this.radioWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, false);
    });
    const dropEventListener = this.renderer.listen(this.radioWrapper.nativeElement, 'drop', (evt) => {
      this.DataDropped(evt);
    });
  }
  
  FireSelectedEvent(e: any) {
    this.FireRepresentativeMoleculeEvent('option-selected');
  }
}
