import { Component, OnInit } from '@angular/core';
import { ApiPropertiesService } from '../../core/services/api-properties.service';
import { CommunicationService } from '../../shared/services/communication.service';

@Component({
  selector: 'app-unsaved-data-apology',
  templateUrl: './unsaved-data-apology.component.html',
  styleUrls: ['./unsaved-data-apology.component.scss'],
})
export class UnsavedDataApologyComponent implements OnInit {
  unsavedChanges = [];
  elementsAffected = 0;
  totalChanges = 0;
  expanded = true;
  
  constructor(private propertiesService: ApiPropertiesService, private communicationService: CommunicationService) {
  }
  
  ngOnInit() {
    this.unsavedChanges = this.propertiesService.GetUnsavedProperties();
    this.elementsAffected = this.unsavedChanges.length;
    this.totalChanges = this.unsavedChanges.reduce(
      (a, b) => a + (b.properties.length || 0),
      0,
    );
  }
  
  TogglePanel() {
    this.expanded = !this.expanded;
  }
  
  ReloadApp() {
    this.communicationService.Event.Editor.$ReloadElementsOnApp.emit(
      this.unsavedChanges.map(uc => uc.elementId),
    );
  }
}
