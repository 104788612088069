import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class CopyMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    dataBus: any,
  ) {
    try {
      dataBus = dataBus || {};
      
      let inputValue: string;
      
      if (typeof dataBus === 'string') {
        inputValue = dataBus;
      }
      
      const outputValue: string = inputValue;
      
      if (typeof this.busService.Get(repMoleculeId) !== `undefined`) {
        this.busService.Get(repMoleculeId).SetValue(outputValue);
      }
      
      // console.log(`molecule working, copying ${inputValue}`);
      dataBus = outputValue;
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
