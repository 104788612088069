import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { clone } from 'lodash-es';
import { PropertyVersioningDto } from '../../shared/dtos/versioning-dto';
import { DatasourceType } from '../../shared/enums/datasource-type.enum';
import { LeapXLEventType } from '../../shared/enums/leapxl-event-type.enum';
import { MoleculesType } from '../../shared/enums/molecules-type.enum';
import { ParticleType } from '../../shared/enums/particle-type.enum';
import { RepresentativeMoleculesType } from '../../shared/enums/representative-molecules-types.enum';
import { ActionMolecule } from '../../shared/representative-molecule/interfaces/action-molecules';
import { DataElement } from '../../shared/representative-molecule/interfaces/data-element';
import { LeapXLEvent } from '../../shared/representative-molecule/interfaces/leapxl-event';
import { Particle } from '../../shared/representative-molecule/interfaces/particle';
import {
  RepresentativeMolecule,
} from '../../shared/representative-molecule/interfaces/representative-molecule';
import {
  IRepresentativeMolecule,
} from '../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { FactoryParticleService } from '../../shared/services/factory-particle.service';
import { FactoryService } from '../../shared/services/factory.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { SpreadsheetService } from '../../spreadsheet/spreadsheet.service';
import { ThematicService } from '../../workarea/services/thematic.service';
import { WorkAreaService } from '../../workarea/workarea.service';
import { Receptor } from '../molecular/receptors.enum';
import { BusService } from '../molecular/services/bus.service';
import { ProcessorService } from '../molecular/services/processor.service';
import { ApiDataTranslatorService } from '../services/api-data-translator.service';
import { ApiMoleculesService } from '../services/api-molecules.service';
import { ApiPropertiesService } from '../services/api-properties.service';
import { GenericDialogService } from '../services/generic-dialog.service';
import { MoleculeManagmentService } from '../services/molecule-managment.service';
import { TemplateService } from '../services/template.service';
import { ToolsService } from '../services/tools.service';

@Injectable()
export class BuilderService {
  RunningMode = false;
  fromCobblet = false;
  
  constructor(
    private busService: BusService,
    private moleculesService: ApiMoleculesService,
    private moleculeManagmentService: MoleculeManagmentService,
    private snackerService: SnackerService,
    public templateService: TemplateService,
    private workAreaService: WorkAreaService,
    private cobbleService: CobbleService,
    private toolsService: ToolsService,
    private propertiesService: ApiPropertiesService,
    private dataTranslatorService: ApiDataTranslatorService,
    private processorService: ProcessorService,
    private spreadsheetService: SpreadsheetService,
    private thematicService: ThematicService,
    private communicationService: CommunicationService,
    private factoryParticleService: FactoryParticleService,
    private genericDialogService: GenericDialogService,
    private factoryService: FactoryService,
  ) {
    const url = window.location.href;
    this.RunningMode = url.includes('/run/');
  }
  
  async GetMolecule(
    parent: IRepresentativeMolecule,
    moleculeToAddContext: any,
    position?: { x: number; y: number },
    properties: any[] = [],
    childrenProperties: any[] = [],
    subParentId = 0,
    includePlaceholder = false,
    fromTemplate = false,
    placeholders = [],
  ): Promise<any> {
    // console.log('moleculeToAddContext', moleculeToAddContext);
    return new Promise(async(resolve, reject) => {
      let isReplacement = false;
      properties = properties || [];
      this.fromCobblet = false;
      
      // if (moleculeToAddContext.type !== MoleculesType.Cobblet &&
      //   ((moleculeToAddContext.type !== RepresentativeMoleculesType.WorkGroup &&
      //     parent.Type === MoleculesType.Cobble) ||
      //     !this.AcceptMolecule(
      //       moleculeToAddContext.moleculeType,
      //       parent.MoleculesAllow
      //     ) || (moleculeToAddContext.type === RepresentativeMoleculesType.WorkGroup && parent.Type === RepresentativeMoleculesType.WorkGroup))
      // ) {
      if (false) {
        // console.log('molecule not allowed');
        this.snackerService.ShowMessageOnBottom('Element not allowed', 'block');
        return resolve(null);
      } else {
        if (moleculeToAddContext.moleculeType !== MoleculesType.Representative && moleculeToAddContext.moleculeType !== MoleculesType.Cobblet) {
          const newMolecule = await this.BuildMolecule(moleculeToAddContext, parent, position);
          this.snackerService.ShowMessageOnBottom('Molecule Added!', 'add_circle', null, true);
          return resolve(newMolecule);
        } else {
          if (parent && parent.ReplaceableByRepresentative && subParentId === 0) {
            isReplacement = true;
            
            // changing input bus for dropdowns
            if (moleculeToAddContext.type === RepresentativeMoleculesType.Dropdown) {
              const moleculeToReplace = this.busService.Get(parent.Id.toString());
              const initBus = moleculeToReplace.GetBusByEventType('init');
              if (initBus) {
                initBus.Receptor = Receptor.OptionsListInput;
              }
            }
            
            properties.push(
              {
                name: 'properties',
                value: parent.Properties,
                path: '',
              },
              {
                name: 'buses',
                value: parent.Buses,
                path: '',
              },
            );
            
            const parentId = parent.ParentId;
            this.moleculeManagmentService.RemoveRepresentativeMolecule(parent.Id);
            parent = this.busService.Get(parentId.toString());
          } else {
            if (position) {
              if (subParentId > 0) {
                properties.push({
                  name: 'subParentId',
                  value: +subParentId,
                  path: '',
                });
              }
              
              if (moleculeToAddContext.type === RepresentativeMoleculesType.WorkGroup) {
                const deviceConfiguration = this.cobbleService.Cobble.deviceConfigurations[this.cobbleService.Cobble.deviceType];
                
                properties.push(
                  {
                    name: 'view',
                    value: this.workAreaService.ActualView.id,
                    path: `properties`,
                  },
                  
                  {
                    name: 'minItemCols',
                    value: deviceConfiguration.minItemCols,
                    path: 'properties',
                  },
                  {
                    name: 'maxItemCols',
                    value: deviceConfiguration.maxItemCols,
                    path: 'properties',
                  },
                );
                
                if (this.cobbleService.Cobble.deviceType !== 'desktop') {
                  properties.push({
                    name: 'cols',
                    value: deviceConfiguration.minItemCols,
                    path: 'properties',
                  });
                }
              }
            }
          }
          
          if (!properties.find(p => p.name === 'view')) {
            properties.push({
              name: 'view',
              value: this.workAreaService.ActualView.id,
              path: `properties`,
            });
          }
          
          if (position && !isReplacement) {
            properties.push({
              name: 'x',
              value: position.x,
              path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
            });
            properties.push({
              name: 'y',
              value: position.y,
              path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
            });
          }
          
          console.log('moleculeToAddContext', moleculeToAddContext);
          console.log('properties', properties);
          this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
          this.moleculesService
          .GetMolecule(
            this.cobbleService.Cobble.id,
            parent === null ? this.cobbleService.Cobble.id : parent.Id,
            moleculeToAddContext.moleculeType === MoleculesType.Cobblet ? null : moleculeToAddContext.id,
            moleculeToAddContext.moleculeType === MoleculesType.Cobblet ? moleculeToAddContext.id : null,
            properties,
            // moleculeToAddContext.moleculeType === MoleculesType.Cobblet ? [] : properties,
            childrenProperties,
            fromTemplate,
            placeholders,
          )
          .subscribe(async(moleculeFromServer) => {
            this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
            
            console.log('molecule from server', moleculeFromServer);
            if (Array.isArray(moleculeFromServer.molecules)) {
              if (moleculeToAddContext.moleculeType === MoleculesType.Cobblet) {
                const repMolecule = await this.AddComponentToApp(moleculeFromServer, position, properties);
                return resolve(repMolecule);
              } else {
                moleculeFromServer.molecules.forEach(async(moleculefS) => {
                  if (moleculefS.type === MoleculesType.Cobblet) {
                    this.fromCobblet = true;
                  }
                  
                  const newMolecule = await this.BuildMolecule(moleculefS, parent,
                    isReplacement ? null : position);
                  
                  newMolecule.SetAppDefaultTheme();
                  
                  if (isReplacement) {
                    newMolecule.Properties = this.toolsService.ReplaceValueInObject(newMolecule.Properties,
                      parent.Id,
                      newMolecule.Id);
                    newMolecule.SaveProperty('properties', 'Updating Values')
                    .subscribe();
                  }
                  return resolve(
                    isReplacement ? this.busService.Get(newMolecule.ParentId.toString()) : newMolecule);
                });
              }
            } else {
              if (moleculeFromServer.molecules.parentId !== this.cobbleService.Cobble.id) {
                const newSizePositions = [];
                
                const mfsPosition = {
                  x: moleculeFromServer.molecules.properties.responsive[this.cobbleService.Cobble.deviceType].x,
                  y: moleculeFromServer.molecules.properties.responsive[this.cobbleService.Cobble.deviceType].y,
                  cols: moleculeFromServer.molecules.properties.responsive.desktop.cols,
                  rows: moleculeFromServer.molecules.properties.responsive.desktop.rows,
                };
                
                if (mfsPosition.x + mfsPosition.cols > parent.ResponsiveProperties().cols) {
                  moleculeFromServer.molecules.properties.responsive[this.cobbleService.Cobble.deviceType].cols = mfsPosition.cols =
                    parent.ResponsiveProperties().cols - mfsPosition.x;
                  
                  newSizePositions.push(
                    new PropertyVersioningDto({
                      elementId: moleculeFromServer.molecules.id,
                      property: 'cols',
                      value: moleculeFromServer.molecules.properties.responsive[this.cobbleService.Cobble.deviceType].cols,
                      path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                      change: 'Element Resized to fit',
                      name: moleculeFromServer.molecules.properties.name,
                    }),
                  );
                }
                
                if (mfsPosition.y + mfsPosition.rows > parent.ResponsiveProperties().rows) {
                  moleculeFromServer.molecules.properties.responsive[this.cobbleService.Cobble.deviceType].rows = mfsPosition.rows =
                    parent.ResponsiveProperties().rows - mfsPosition.y;
                  
                  newSizePositions.push(
                    new PropertyVersioningDto({
                      elementId: moleculeFromServer.molecules.id,
                      property: 'rows',
                      value: moleculeFromServer.molecules.properties.responsive[this.cobbleService.Cobble.deviceType].rows,
                      path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                      change: 'Element Resized to fit',
                      name: moleculeFromServer.molecules.properties.name,
                    }),
                  );
                }
                
                if (mfsPosition.x + mfsPosition.cols > parent.ResponsiveProperties().cols) {
                  moleculeFromServer.molecules.properties.responsive[this.cobbleService.Cobble.deviceType].x = mfsPosition.x =
                    parent.ResponsiveProperties().cols - mfsPosition.cols;
                  
                  moleculeFromServer.molecules.properties.responsive[this.cobbleService.Cobble.deviceType].x =
                    moleculeFromServer.molecules.properties.responsive[this.cobbleService.Cobble.deviceType].x < 0
                      ? 0
                      : moleculeFromServer.molecules.properties.responsive[this.cobbleService.Cobble.deviceType].x;
                  
                  newSizePositions.push(
                    new PropertyVersioningDto({
                      elementId: moleculeFromServer.molecules.id,
                      property: 'x',
                      value: moleculeFromServer.molecules.properties.responsive[this.cobbleService.Cobble.deviceType].x,
                      path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                      change: 'Element moved to fit',
                      name: moleculeFromServer.molecules.properties.name,
                    }),
                  );
                }
                
                if (mfsPosition.y + mfsPosition.rows > parent.ResponsiveProperties().rows) {
                  moleculeFromServer.molecules.properties.responsive[this.cobbleService.Cobble.deviceType].y = mfsPosition.y =
                    parent.ResponsiveProperties().rows - mfsPosition.rows;
                  
                  moleculeFromServer.molecules.properties.responsive[this.cobbleService.Cobble.deviceType].y =
                    moleculeFromServer.molecules.properties.responsive[this.cobbleService.Cobble.deviceType].y < 0
                      ? 0
                      : moleculeFromServer.molecules.properties.responsive[this.cobbleService.Cobble.deviceType].y;
                  
                  newSizePositions.push(
                    new PropertyVersioningDto({
                      elementId: moleculeFromServer.molecules.id,
                      property: 'y',
                      value: moleculeFromServer.molecules.properties.responsive[this.cobbleService.Cobble.deviceType].y,
                      path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                      change: 'Element moved to fit',
                      name: moleculeFromServer.molecules.properties.name,
                    }),
                  );
                }
                
                if (newSizePositions.length > 0) {
                  this.propertiesService.SaveProperties(newSizePositions)
                  .subscribe();
                }
              }
              
              const newMolecule = await this.BuildMolecule(moleculeFromServer.molecules, parent,
                isReplacement ? null : position);
              
              newMolecule.SetAppDefaultTheme();
              
              // disable pulse after creation
              // setTimeout(() => {
              //   if (newMolecule && newMolecule.Pulse) {
              //      newMolecule.Pulse();
              //   }
              // }, 140);
              return resolve(newMolecule);
            }
            // this.communicationService.Event.Editor.$MoleculePropertyChange.emit(
            //   [
            //     {
            //       moleculeId: +moleculeFromServer.parentId,
            //       change: new PropertyVersioningDto(),
            //       versionId:
            //       moleculeFromServer.parentChildrenPropertyVersionId,
            //       active: true
            //     }
            //   ]
            // );
          });
        }
      }
    });
  }
  
  AddComponentToApp(moleculeFromServer: any, position: any, properties: any[] = []): Promise<IRepresentativeMolecule> {
    
    return new Promise(resolve => {
      
      
      const propertiesToUpdate = [];
      
      let differenceX = 0;
      let differenceY = 0;
      
      if (position) {
        const wgs = moleculeFromServer.molecules.filter((m) => m.type === MoleculesType.WorkGroup);
        
        const yWG = wgs.reduce((prev, current) =>
          prev.properties.responsive[this.cobbleService.Cobble.deviceType].y < current.properties.responsive[this.cobbleService.Cobble.deviceType].y
            ? prev
            : current,
        );
        
        const xWg = wgs.reduce((prev, current) =>
          prev.properties.responsive[this.cobbleService.Cobble.deviceType].x < current.properties.responsive[this.cobbleService.Cobble.deviceType].x
            ? prev
            : current,
        );
        
        differenceX = position.x - xWg.properties.responsive[this.cobbleService.Cobble.deviceType].x;
        
        differenceY = position.y - yWG.properties.responsive[this.cobbleService.Cobble.deviceType].y;
      }
      
      let wgMolecule: IRepresentativeMolecule = null;
      
      moleculeFromServer.molecules.forEach(async(moleculefS, index) => {
        if (moleculefS.type === MoleculesType.WorkGroup) {
          if (position) {
            moleculefS.properties.responsive[this.cobbleService.Cobble.deviceType].x =
              moleculefS.properties.responsive[this.cobbleService.Cobble.deviceType].x + differenceX;
            
            moleculefS.properties.responsive[this.cobbleService.Cobble.deviceType].y =
              moleculefS.properties.responsive[this.cobbleService.Cobble.deviceType].y + differenceY;
            
            propertiesToUpdate.push(
              new PropertyVersioningDto({
                elementId: moleculefS.id.toString(),
                property: 'x',
                value: moleculefS.properties.responsive[this.cobbleService.Cobble.deviceType].x,
                path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                change: 'Updating position X',
                name: moleculefS.properties.name,
              }),
            );
            
            propertiesToUpdate.push(
              new PropertyVersioningDto({
                elementId: moleculefS.id.toString(),
                property: 'y',
                value: moleculefS.properties.responsive[this.cobbleService.Cobble.deviceType].y,
                path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                change: 'Updating position Y',
                name: moleculefS.properties.name,
              }),
            );
          }
          
          moleculefS.parentId = this.cobbleService.Cobble.id;
          
          propertiesToUpdate.push(
            new PropertyVersioningDto({
              elementId: moleculefS.id.toString(),
              property: 'parentId',
              value: this.cobbleService.Cobble.id,
              path: 'properties',
              change: 'Adding App',
              name: moleculefS.properties.name,
            }),
          );
        }
        
        if (moleculefS.type !== MoleculesType.Cobble) {
          if (!properties.find(p => p.name === 'view')) {
            moleculefS.properties.view = this.workAreaService.ActualView.id;
            propertiesToUpdate.push(
              new PropertyVersioningDto({
                elementId: moleculefS.id.toString(),
                property: 'view',
                value: this.workAreaService.ActualView.id,
                path: `properties`,
                change: 'Updating View',
                name: moleculefS.properties.name,
              }),
            );
          }
          
          
          const newMolecule = await this.BuildMolecule(moleculefS);
          newMolecule.SetAppDefaultTheme();
          
          if (newMolecule.Type === MoleculesType.WorkGroup) {
            wgMolecule = newMolecule;
          }
        }
        
        if (moleculeFromServer.molecules.length - 1 === index) {
          setTimeout(() => {
            this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(null);
            this.communicationService.Event.Editor.Views.$ViewsPanelChange.emit(1);
            this.communicationService.Event.Editor.Views.$RefreshViewsPanelUI.emit(true);
            
            this.propertiesService.SaveProperties(propertiesToUpdate)
            .subscribe();
            // this.cobbleService.SaveCobbleChildren();
          }, 500);
          
          return resolve(wgMolecule);
        }
      });
    });
  }
  
  Duplicate(repMolecule: IRepresentativeMolecule) {
    const repMolecules = this.workAreaService.elementsSelected.length > 1 ? this.workAreaService.elementsSelected : [repMolecule];
    
    if (this.workAreaService.elementsSelected.length > 1) {
      this.genericDialogService.OpenConfirmDialog({
        title: 'Duplicate Elements',
        message: `You are about to duplicate ${ this.workAreaService.elementsSelected.length } elements. Are you sure you want to duplicate all of them?`,
        confirmText: 'Duplicate',
        cancelText: 'Cancel',
      })
      .then((confirm) => {
        if (confirm) {
          this.workAreaService.elementsSelected.forEach((es) => {
            this.DuplicateProcess(es);
          });
        }
      });
    } else {
      this.DuplicateProcess(repMolecules[0]);
    }
  }
  
  DuplicateProcess(repMolecule: IRepresentativeMolecule, position: {
    x: number;
    y: number
  } = null, newParent: IRepresentativeMolecule = null) {
    console.log('duplicate');
    const moleculeTypeId = this.workAreaService.libraryMoleculeIds[`${ repMolecule.Type }-Representative`];
    const parent = newParent || this.busService.GetParent(repMolecule.Id.toString());
    let newYposition = 0;
    let newXposition = 0;
    
    if (position) {
      newXposition = position.x;
      newYposition = position.y;
    } else {
      newYposition = repMolecule.ResponsiveProperties().y + repMolecule.ResponsiveProperties().rows + 1;
      
      const sizePosition = {
        x: repMolecule.ResponsiveProperties().x,
        y: repMolecule.ResponsiveProperties().y + repMolecule.ResponsiveProperties().rows + 1,
        cols: repMolecule.ResponsiveProperties().cols,
        rows: repMolecule.ResponsiveProperties().rows,
      };
      
      newXposition = repMolecule.ResponsiveProperties().x;
      
      while (
        this.busService.GetSamePositionAndSizeRepresentativeMolecule(sizePosition, this.cobbleService.Cobble,
          this.workAreaService.ActualView.id)
          .length > 0
        ) {
        newYposition = newYposition + repMolecule.ResponsiveProperties().rows;
        sizePosition.y = newYposition;
      }
    }
    
    const clonedBuses = [];
    repMolecule.Buses.forEach((b) => clonedBuses.push(b.Clone()));
    
    const clonedProperties = cloneDeep(repMolecule.PropertiesBackup);
    clonedProperties.responsive[this.cobbleService.Cobble.deviceType].y = newYposition;
    clonedProperties.responsive[this.cobbleService.Cobble.deviceType].x = newXposition;
    
    const properties = [
      {
        name: 'subParentContext',
        value: repMolecule.SubParentContext,
        path: '',
      },
      {
        name: 'subParentId',
        value: repMolecule.SubParentId,
        path: '',
      },
      {
        name: 'styleMetadata',
        value: repMolecule.StyleMetadata,
        path: '',
      },
      {
        name: 'properties',
        value: clonedProperties,
        path: '',
      },
      {
        name: 'buses',
        value: clonedBuses,
        path: '',
      },
    ];
    
    this.GetMolecule(
      parent,
      {
        id: repMolecule.Type === RepresentativeMoleculesType.Custom ? repMolecule.TemplateId : moleculeTypeId,
        type: repMolecule.Type,
        moleculeType: repMolecule.MoleculeType,
      },
      null,
      properties,
      [],
      0,
      false,
      false,
    )
    .then((clonedMolecule: IRepresentativeMolecule) => {
      
      if (parent) {
        parent.Children.push({
          id: clonedMolecule.Id,
        });
        
        // parent.SaveProperty('children', 'Rep Molecule Added').subscribe();
      }
      
      if (clonedMolecule.ContainsParticles()) {
        clonedMolecule.Buses.forEach(
          bus => bus.MoveBusFromRepresentativeMolecule(repMolecule.Id, clonedMolecule.Id));
        this.toolsService.SetGuidToPropertyInObject(clonedMolecule.Buses, 'id');
        
        clonedMolecule.SaveProperty('buses', 'Cloning Particles')
        .subscribe();
      }
      
      if (repMolecule.Children.length > 0) {
        repMolecule.Children.forEach((c: { id: number }) => {
          const child = this.busService.Get(c.id.toString());
          this.DuplicateProcess(
            child,
            { x: child.ResponsiveProperties().x, y: child.ResponsiveProperties().y },
            clonedMolecule);
        });
        this.snackerService.ShowMessageOnBottom('Representative molecule duplicated', 'filter_2', null, true);
      } else {
        this.snackerService.ShowMessageOnBottom('Representative molecule duplicated', 'filter_2', null, true);
      }
      
      this.communicationService.Event.Editor.$RecreateEventsTable.emit();
      setTimeout(() => {
        clonedMolecule.FireDataSourceBus();
      }, 1000);
    });
  }
  
  public async BuildMolecule(
    moleculeContext: any,
    parent?: IRepresentativeMolecule,
    position?: any,
    fireChangeMoleculeEvent = true,
  ): Promise<IRepresentativeMolecule> {
    if (
      moleculeContext.moleculeType === MoleculesType.Behavior ||
      moleculeContext.moleculeType === MoleculesType.DataAction ||
      moleculeContext.moleculeType === MoleculesType.Configuration
    ) {
      this.factoryParticleService.SetupDefaultsForMolecule(moleculeContext, parent, null, false);
      return moleculeContext;
    } else if (moleculeContext.moleculeType === MoleculesType.Representative) {
      // console.log('build');
      const repMolecule = this.CreateAndAddRepresentativeMoleculeToBus(moleculeContext, 0,
        fireChangeMoleculeEvent);
      if (position && moleculeContext.type !== MoleculesType.Cobblet && this.fromCobblet) {
        repMolecule.ResponsiveProperties().x = position.x;
        repMolecule.ResponsiveProperties().y = position.y;
      }
      
      if (this.workAreaService.elementClicked && this.workAreaService.elementClicked.Id === repMolecule.Id) {
        this.workAreaService.elementClicked = repMolecule;
      }
      
      if (
        this.workAreaService.elementsSelected &&
        this.workAreaService.elementsSelected.length > 0 &&
        !!this.workAreaService.elementsSelected.find((es) => es.Id === repMolecule.Id)
      ) {
        this.workAreaService.elementsSelected = this.workAreaService.elementsSelected.filter(
          (es) => es.Id !== repMolecule.Id);
        this.workAreaService.elementsSelected.push(repMolecule);
      }
      
      if (
        this.workAreaService.primaryElementsSelected &&
        this.workAreaService.primaryElementsSelected.length > 0 &&
        !!this.workAreaService.primaryElementsSelected.find((es) => es.Id === repMolecule.Id)
      ) {
        this.workAreaService.primaryElementsSelected = this.workAreaService.primaryElementsSelected.filter(
          (es) => es.Id !== repMolecule.Id);
        this.workAreaService.primaryElementsSelected.push(repMolecule);
      }
      
      return repMolecule;
    } else if (moleculeContext.moleculeType === MoleculesType.CompoundMolecule) {
      this.moleculesService
      .GetMolecule(this.cobbleService.Cobble.id, parent.Id, moleculeContext.id, null, [], [], false)
      .subscribe(async(moleculeFromServer) => {
        this.AddCompoundToRepresentativeMolecule(moleculeFromServer.molecules, parent);
      });
    } else if (moleculeContext.moleculeType === MoleculesType.Configuration) {
    }
  }
  
  CreateAndAddRepresentativeMoleculeToBus(moleculeFromServer: any, moleculeId = 0, fireChangeMoleculeEvent = true): RepresentativeMolecule {
    // clone object
    let oldId = 0;
    if (moleculeId > 0) {
      oldId = clone(moleculeFromServer.id);
      this.toolsService.ReplaceValueInObject(moleculeFromServer, +oldId, moleculeId);
      this.toolsService.ReplaceValueInObject(moleculeFromServer, oldId.toString(), moleculeId);
    }
    const repMolecule = new RepresentativeMolecule(moleculeFromServer, this.RunningMode);
    this.busService.Add(repMolecule, fireChangeMoleculeEvent, oldId);
    return repMolecule;
  }
  
  AddCompoundToRepresentativeMolecule(
    compound: {
      name: string;
      type: string;
      eventType: string;
      icon: string;
      busReceptor: string;
      particles: any[];
    },
    repMolecule: IRepresentativeMolecule,
  ) {
    const defaultEvent = compound.eventType === '' ? repMolecule.DefaultEvent : compound.eventType;
    
    // always create new bus
    const targetBus = repMolecule.GenerateNewBus(true, `Process ${ repMolecule.Properties.name } Compound`,
      compound.busReceptor);
    
    // looking for existing bus
    // const targetBus = repMolecule.GetBusByReceptor(compound.busReceptor) ||
    // repMolecule.GenerateNewBus(true, `Process ${repMolecule.Properties.name} Compound`, compound.busReceptor);
    
    if (targetBus.FirstParticle()
    .IsEvent()) {
      targetBus.RemoveParticle(targetBus.FirstParticle().ParticleId);
      targetBus.AddParticle(
        this.factoryParticleService.GenerateEvent(defaultEvent, 'Molecule', repMolecule.Id));
    }
    
    if (repMolecule.GetBusByEventType(LeapXLEventType.AppBroadcast)) {
      repMolecule.RemoveBus(repMolecule.GetBusByEventType(LeapXLEventType.AppBroadcast).id);
    }
    
    compound.particles.forEach((p) => {
      const particle = this.factoryParticleService.GetParticle(p) as Particle;
      particle.AssingId();
      particle.AssingParticleId();
      
      switch (particle.ParticleType) {
        case ParticleType.Event:
          if ((particle as LeapXLEvent).EventSource === 'System') {
            (particle as LeapXLEvent).SourceId = this.cobbleService.Cobble.id;
          }
          break;
        case ParticleType.Molecule:
          (particle as ActionMolecule).MoleculeId = repMolecule.Id;
          (particle as ActionMolecule).DataElements.forEach((de) => {
            de.ApplicationId = this.cobbleService.Cobble.id;
            de.AssingParticleId();
            de.AssingId();
          });
          break;
        case ParticleType.DataElement:
          (particle as DataElement).ApplicationId = this.cobbleService.Cobble.id;
          break;
      }
      
      targetBus.AddParticle(particle);
    });
    
    switch (compound.type) {
      case 'CompoundPopulate':
        // region assign default dataelements
        const relatedMolecule = targetBus.GetActionMoleculeParticle('GetRelatedDataMolecule');
        const datasourceMolecule = targetBus.GetActionMoleculeParticle('GetElementsDatasourceDataMolecule');
        const updateDatsourceMolecule = targetBus.GetActionMoleculeParticle('UpdateDatasourceDataMolecule');
        
        if (updateDatsourceMolecule) {
          targetBus.RemoveParticle(updateDatsourceMolecule.ParticleId);
        }
        
        const sourceDataElementsBus = repMolecule.GetBusByReceptor(
          Receptor.ValueInput) || repMolecule.GetBusByReceptor(
          Receptor.OptionsListInput);
        if (sourceDataElementsBus) {
          const sourceDataElementsMolecule =
            sourceDataElementsBus.GetActionMoleculeParticle('GetElementsDatasourceDataMolecule') ||
            sourceDataElementsBus.GetActionMoleculeParticle('FilterByDataElementReferenceMolecule');
          
          if (sourceDataElementsMolecule && sourceDataElementsMolecule.DataElements.length > 0) {
            relatedMolecule.AddDataElement(new DataElement(sourceDataElementsMolecule.DataElements[0]));
          }
        }
        
        if (relatedMolecule && relatedMolecule.DataElements.length > 0) {
          const targetDataElements: DataElement[] = [];
          let viewRepMoleculeDataElements: DataElement[] = [];
          
          this.busService
          .GetViewRepresentativeMoleculesPopulating(this.cobbleService.Cobble,
            this.workAreaService.ActualView.id,
            [repMolecule])
          .forEach((pRepMolecule) => {
            viewRepMoleculeDataElements = viewRepMoleculeDataElements.concat(pRepMolecule.GetPopulatingBus()
            .GetDataElements());
          });
          
          viewRepMoleculeDataElements.forEach((repMolDE) => {
            if (repMolDE.GetSourceContext() === relatedMolecule.DataElements[0].GetSourceContext()) {
              if (!targetDataElements.find((d) => d.Context === repMolDE.Context)) {
                targetDataElements.push(repMolDE);
              }
            }
          });
          
          if (relatedMolecule.DataElements[0].DataSourceType === DatasourceType.Spreadsheet) {
            targetDataElements.forEach(
              (de) => (de.Context = this.toolsService.ReplaceContextReference(de.Context,
                this.toolsService.ExtractColFromContext(de.Context))),
            );
            this.factoryService.CreateTranslationsForDataElements(targetDataElements)
            .subscribe((dataElements: DataElement[]) => {
              datasourceMolecule.AddDataElements(dataElements);
              repMolecule.SaveProperty('buses', `${ compound.name } Compound added`)
              .subscribe();
            });
          } else {
            datasourceMolecule.AddDataElements(targetDataElements);
          }
        }
        // endregion
        break;
    }
    
    repMolecule.AddBus(targetBus);
    repMolecule.SaveProperty('buses', `${ compound.name } Compound added`)
    .subscribe();
  }
  
  private AcceptMolecule(type: string, moleculesAllowed: string[]) {
    return moleculesAllowed.includes(type);
  }
}
