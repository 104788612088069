import { Injectable } from '@angular/core';
import { Constants } from '../../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class SuperLocalService {
  settings = {
    dbPrefix: `${ Constants.LocalStoragePrefix }CACHE.DB`,
    dataFieldName: 'data',
    separator: '_',
    saveType: 'greedy',
  };
  
  constructor() {
  }
  
  Capable() {
    var a = `${ Constants.LocalStoragePrefix }ls`;
    try {
      return localStorage.setItem(a, a), localStorage.removeItem(a), !0;
    } catch (b) {
      return !1;
    }
  }
  
  Fetch(key): any {
    var c,
      b = {
        uID: {},
        created: {},
        modified: {},
        persistOnClear: false,
      };
    if ('newest' === key) {
      c = this.FindNewest();
    } else if ('oldest' === key) {
      c = this.FindOldest();
    } else if ('smallest' === key) {
      c = this.FindSmallest();
    } else if ('biggest' === key) {
      c = this.FindBiggest();
    } else {
      if (!isNaN(key)) {
        return !1;
      }
      c = key;
    }
    var d =
      this.settings.dbPrefix +
      this.settings.separator +
      c +
      this.settings.separator;
    
    return (
      (b.uID = c),
        (b[this.settings.dataFieldName] = JSON.parse(
          localStorage[d + this.settings.dataFieldName] || 'null',
        )),
        (b.created = localStorage[d + 'created']),
        (b.modified = localStorage[d + 'modified']),
        (b.persistOnClear = JSON.parse(
          localStorage[d + 'persistOnClear'] || 'true',
        )),
        b
    );
  }
  
  Remove(a) {
    var b =
      this.settings.dbPrefix +
      this.settings.separator +
      a +
      this.settings.separator;
    localStorage.removeItem(b + this.settings.dataFieldName),
      localStorage.removeItem(b + 'created'),
      localStorage.removeItem(b + 'modified');
    localStorage.removeItem(b + 'persistOnClear');
  }
  
  ClearAll(hardClear = false) {
    
    const keysToRemove = [];
    
    for (let i = 0; i < localStorage.length; i++) {
      const item = this.Fetch(localStorage.key(i));
      if (hardClear || !item.persistOnClear) {
        if ((item.uID as string).includes(this.settings.dbPrefix)) {
          keysToRemove.push(item.uID);
        }
      }
    }
    
    keysToRemove.forEach(key => {
      localStorage.removeItem(key);
    });
  }
  
  Save(uId: string, data: any, customTimeStamp = null, persistOnClear = false) {
    if (this.Capable() === !0) {
      let d;
      
      let e =
        this.settings.dbPrefix +
        this.settings.separator +
        uId +
        this.settings.separator;
      
      d =
        customTimeStamp === null
          ? Math.floor(new Date().getTime() / 1e3)
          : customTimeStamp;
      try {
        const dataProcessed = JSON.stringify(data);
        
        return (
          localStorage.setItem(e + this.settings.dataFieldName, dataProcessed),
            localStorage.setItem(e + 'modified', d),
            localStorage.setItem(
              e + 'persistOnClear',
              JSON.stringify(persistOnClear),
            ),
          localStorage[e + 'created'] === void 0 &&
          localStorage.setItem(e + 'created', d),
            !0
        );
      } catch (f) {
        console.log(f);
        this.Remove(uId);
        
        if (this.settings.saveType === 'greedy') {
          const oldest = this.FindOldest();
          if (oldest) {
            this.Remove(oldest);
            this.Save(uId, data, customTimeStamp, persistOnClear);
          } else {
            this.ClearAll(true);
            console.log('no space available');
          }
        }
      }
    }
  }
  
  public FindOldest() {
    for (
      var a = 0, b = '', c = Object.keys(localStorage), d = c.length, e = 0;
      d > e;
      e++
    ) {
      if (this.PrefixRegex().test(c[e]) && this.ModifiedRegex().test(c[e])) {
        var f = c[e].replace(this.settings.dbPrefix + this.settings.separator, '').replace(this.settings.separator + 'modified', ''),
          g =
            localStorage[
            this.settings.dbPrefix +
            this.settings.separator +
            f +
            this.settings.separator +
            'modified'
              ];
        (a > g || 0 === a) && ((b = f), (a = g));
      }
    }
    return b;
  }
  
  public RemoveOldest(): boolean {
    const oldestCachedData = this.FindOldest();
    
    if (oldestCachedData) {
      this.Remove(oldestCachedData);
      return true;
    } else {
      return false;
    }
  }
  
  public Keys(): string[] {
    const keys = [];
    
    Object.keys(localStorage).forEach(key => {
      if (key.includes(this.settings.dbPrefix) && key.includes(`_data`)) {
        keys.push(key.replace(`${ this.settings.dbPrefix }_`, '').replace(`_data`, ''));
      }
    });
    
    return keys;
  }
  
  private PrefixRegex() {
    return RegExp(this.settings.dbPrefix, 'g');
  }
  
  private CreatedRegex() {
    return RegExp('created', 'g');
  }
  
  private ModifiedRegex() {
    return RegExp('modified', 'g');
  }
  
  private DatafieldRegex() {
    return RegExp(this.settings.dataFieldName, 'g');
  }
  
  private FindNewest() {
    for (
      var a = 0, b = '', c = Object.keys(localStorage), d = c.length, e = 0;
      d > e;
      e++
    ) {
      if (this.PrefixRegex().test(c[e]) && this.ModifiedRegex().test(c[e])) {
        var f = c[e].replace(this.settings.dbPrefix + this.settings.separator, '').replace(this.settings.separator + 'modified', ''),
          g =
            localStorage[
            this.settings.dbPrefix +
            this.settings.separator +
            f +
            this.settings.separator +
            'modified'
              ];
        g > a && ((b = f), (a = g));
      }
    }
    return b;
  }
  
  private FindSmallest() {
    for (
      var a = 0, b = '', c = Object.keys(localStorage), d = c.length, e = 0;
      d > e;
      e++
    ) {
      if (this.PrefixRegex().test(c[e]) && this.DatafieldRegex().test(c[e])) {
        var f = c[e].replace(this.settings.dbPrefix + this.settings.separator, '').replace(this.settings.separator + 'modified', ''),
          g =
            localStorage[
            this.settings.dbPrefix +
            this.settings.separator +
            f +
            this.settings.separator +
            this.settings.dataFieldName
              ].length;
        (a > g || 0 === a) && ((b = f), (a = g));
      }
    }
    return b;
  }
  
  private FindBiggest() {
    for (
      var a = 0, b = '', c = Object.keys(localStorage), d = c.length, e = 0;
      d > e;
      e++
    ) {
      if (this.PrefixRegex().test(c[e]) && this.DatafieldRegex().test(c[e])) {
        var f = c[e].replace(this.settings.dbPrefix + this.settings.separator, '').replace(this.settings.separator + 'modified', ''),
          g =
            localStorage[
            this.settings.dbPrefix +
            this.settings.separator +
            f +
            this.settings.separator +
            this.settings.dataFieldName
              ].length;
        g > a && ((b = f), (a = g));
      }
    }
    return b;
  }
  
  private ListAllCreated() {
    for (
      var a = Object.keys(localStorage), b = a.length, c = {}, d = 0;
      b > d;
      d++
    ) {
      if (this.PrefixRegex().test(a[d]) && this.CreatedRegex().test(a[d])) {
        var e = a[d].replace(this.settings.dbPrefix + this.settings.separator, '').replace(this.settings.separator + 'created', '');
        c[e] =
          localStorage[
          this.settings.dbPrefix +
          this.settings.separator +
          e +
          this.settings.separator +
          'created'
            ];
      }
    }
    return c;
  }
  
  private ListAllModified() {
    for (
      var a = Object.keys(localStorage), b = a.length, c = {}, d = 0;
      b > d;
      d++
    ) {
      if (this.PrefixRegex().test(a[d]) && this.ModifiedRegex().test(a[d])) {
        var e = a[d].replace(this.settings.dbPrefix + this.settings.separator, '').replace(this.settings.separator + 'modified', '');
        c[e] =
          localStorage[
          this.settings.dbPrefix +
          this.settings.separator +
          e +
          this.settings.separator +
          'modified'
            ];
      }
    }
    return c;
  }
}
