import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';
import { Constants } from './../../../shared/constants';
import { OperationType } from './../../../shared/enums/operation-type.enum';

export class GetRelatedDataMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(particleId: string, repMoleculeId: string, busProcessorKey: string, rule: any, logic: any, data: any) {
    try {
      // console.log('sd');
      data = data === undefined || data === null || data === {} ? '' : data;
      
      const grouped = this.toolsService.CreateGroupedTranslationIdsContextsFromDataElements(this.DataElements);
      
      const busProcessor = this.busService.GetBusToRunFromProcessorChannel(busProcessorKey);
      this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id] = this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id] || {};
      this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id].offset = Constants.Defaults.Offset;
      this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id].relatedValueIndexes = grouped.translationIds[0];
      this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id].operationType = OperationType.Row;
      this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id].relatedValue = this.toolsService.ExtractValuesByType(data).string;
      
      // this.dataSourcesService.GetDataSourceData().subscribe((result) => {
      //   // console.log(`molecule working, getting related data`);
      //   this.busService.Get(repMoleculeId).SetValueMetaData(result.metaData);
      //   data = result.dataElements;
      //   this.Done(particleId, busProcessorKey, repMoleculeId, data);
      // });
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
