import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class FilterDatasourceDataByValueEqualsAndMolecule extends ActionMoleculeFunction {
  
  Negated = false;
  LogicOperation = 'AND';
  FilterOperation = 'equals';
  
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    data: any,
    dataElemetnsData: any,
  ) {
    try {
      // console.log('filter by value mol');
      
      if (this.ExistsRepMoleculeAttached) {
        data = this.RepMoleculeAttached.GetValue;
      }
      
      const filterValue =
        rule.valueFilter ||
        this.toolsService.ExtractValuesByType(data).string ||
        '';
      
      const filterData = {
        value: filterValue,
        indexes: this.DataElements.map(de => de.TranslationId) || [],
        filterOperation: this.FilterOperation,
        logicOperation: this.LogicOperation,
        negate: this.Negated,
      };
      
      console.log(filterData);
      
      const busProcessor = this.busService.GetBusToRunFromProcessorChannel(busProcessorKey);
      this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id] = this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id] || {};
      const dataSourceRequest = this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id];
      
      dataSourceRequest.dataFilters.push(filterData);
      
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
