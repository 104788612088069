import { Component, Input, OnInit } from '@angular/core';
import { BusService } from '../../core/molecular/services/bus.service';
import { ToolsService } from '../../core/services/tools.service';
import { RepresentativeMoleculesType } from '../../shared/enums/representative-molecules-types.enum';
import { EventTrack } from '../../shared/interfaces/event-track.interface';
import { LeapXLEvent } from '../../shared/representative-molecule/interfaces/leapxl-event';
import { IRepresentativeMolecule } from '../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { DraggableWindowService } from '../../shared/services/draggable-window.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-event-track',
  templateUrl: './event-track.component.html',
  styleUrls: ['./event-track.component.scss'],
})
export class EventTrackComponent implements OnInit {
  @Input() event: LeapXLEvent;
  tracks: EventTrack[] = [];
  treeOptions = null;
  treeNodes = [];
  
  constructor(
    private draggableWindowService: DraggableWindowService,
    private toolsService: ToolsService,
    private cobbleService: CobbleService,
    private workAreaService: WorkAreaService,
    private communicationService: CommunicationService,
    private busService: BusService,
  ) {
  }
  
  ngOnInit() {
    // this.event = this.draggableWindowService.GetData().event;
    this.tracks = this.draggableWindowService.GetData().tracks;
    console.log(this.tracks);
    
    this.treeOptions = {
      useVirtualScroll: false,
      nodeHeight: 22,
    };
    
    this.tracks.forEach((track) => {
      const nodes = this.GenerateTrack(track);
      this.treeNodes.push(nodes);
    });
  }
  
  GenerateTrack(root: any) {
    const node = this.CreateTreeNode(root);
    
    root.branches.forEach((p) => {
      node.children.push(this.GenerateTrack(p));
    });
    
    return node;
  }
  
  CreateTreeNode(track: EventTrack) {
    return {
      id: this.toolsService.GenerateGuid(),
      children: [],
      track,
    };
  }
  
  HighlightRepMolecule(repMolecule: IRepresentativeMolecule) {
    if (repMolecule.Type === RepresentativeMoleculesType.WorkGroup) {
      this.communicationService.Event.Editor.Views.$SwitchToRepresentativeMoleculeView.emit(repMolecule);
    } else {
      const parentRepMolecule = this.busService.Get(repMolecule.ParentId.toString());
      this.communicationService.Event.Editor.Views.$SwitchToRepresentativeMoleculeView.emit(parentRepMolecule);
      setTimeout(() => {
        repMolecule.ScrollIntoFocus();
      }, 200);
    }
    
    setTimeout(() => {
      this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(null);
      this.workAreaService.ShowElementFocusedMenu(repMolecule);
    }, 200);
  }
}
