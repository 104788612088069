import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BuilderService } from '../../../../core/builder/builder.service';
import { ProcessorService } from '../../../../core/molecular/services/processor.service';
import { WorkAreaService } from '../../../../workarea/workarea.service';
import { RepresentativeMoleculesType } from '../../../enums/representative-molecules-types.enum';
import { DragService } from '../../services/drag.service';
import { BaseMoleculeComponent } from '../base-molecule/base-molecule.component';

@Component({
  selector: 'app-button-molecule',
  templateUrl: './button-molecule.component.html',
  styleUrls: ['./button-molecule.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ButtonMoleculeComponent extends BaseMoleculeComponent
  implements OnInit {
  MoleculeType = 'Representative';
  Type = RepresentativeMoleculesType.Button;
  
  @ViewChild('buttonWrapper', { static: true })
  buttonWrapper: ElementRef;
  
  constructor(
    public builderService: BuilderService,
    public dragService: DragService,
    public workAreaService: WorkAreaService,
    public processorService: ProcessorService,
    bottomSheet: MatBottomSheet,
    public el: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
    super(bottomSheet, el, changeDetectorRef);
    
  }
  
  ngOnInit() {
    super.ngOnInit();
    if (!this.context.RunningMode) {
      this.DetachChangeDetection();
    }
    this.context.Type = RepresentativeMoleculesType.Button;
    this.RefreshGridsterConfiguration();
    this.Subscriptions = this.context.$UpdateTextValue.subscribe((update) => {
      // this.RefreshText();
    });
  }
  
  AttachEditorEventListeners() {
    const dragoverEventListener = this.renderer.listen(this.buttonWrapper.nativeElement, 'dragover',
      (evt) => {
        console.log('over 1');
        this.drag(evt, true);
      });
    const dragleaveEventListener = this.renderer.listen(this.buttonWrapper.nativeElement, 'dragleave',
      (evt) => {
        console.log('over 2');
        this.drag(evt, false);
      });
    const dropEventListener = this.renderer.listen(this.buttonWrapper.nativeElement, 'drop', (evt) => {
      console.log('dropped');
      this.DataDropped(evt);
    });
  }
  
  FireClickEvent(e: any) {
    this.FireRepresentativeMoleculeEvent('click', null, true);
  }
}
