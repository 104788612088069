import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DndModule } from 'ng2-dnd';
import { AuthGuard } from '../auth/auth-guard.service';
import { HierarchySharedComponent } from '../shared/components/hierarchy-shared/hierarchy-shared.component';
import { MaterialModule } from '../shared/material/material.module';
import { SharedModule } from '../shared/shared.module';
import { TopBarModule } from '../top-bar/top-bar.module';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    AdminRoutingModule,
    MatPasswordStrengthModule,
    ReactiveFormsModule,
    SharedModule,
    TopBarModule,
    DndModule,
  ],
  declarations: [AdminComponent],
  entryComponents: [HierarchySharedComponent],
  providers: [AuthGuard],
})
export class AdminModule {
}
