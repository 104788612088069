import { RepresentativeMoleculesType } from '../../../shared/enums/representative-molecules-types.enum';
import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class GetColumnValueForRowMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    dataBus: any,
  ) {
    try {
      dataBus = dataBus || '';
      
      let inputValue: string;
      
      if (rule && rule.column) {
        inputValue = rule.column;
      }
      
      if (typeof dataBus === 'string') {
        inputValue = dataBus;
      }
      
      const molecule = this.busService.Get(repMoleculeId);
      
      if (molecule) {
        const parent = this.busService.GetParent(repMoleculeId);
        if (parent.Type === RepresentativeMoleculesType.Table) {
          let col = 0;
          
          if (Number.isInteger(+rule.column)) {
            col = parseInt(rule.column, 10);
          } else {
            col = this.toolsService.ColumnNameToIndex(rule.column);
          }
          
          // molecule.DataSource.firstColIndex = molecule.DataSource.lastColIndex = col;
          // molecule.DataSource.range = `${this.toolsService.ColumnIndexToName(
          //   col
          // )}${
          //   molecule.DataSource.firstRowIndex
          // }:${this.toolsService.ColumnIndexToName(col)}${
          //   molecule.DataSource.firstRowIndex
          // }`;
          
          molecule.$UpdateValue.emit(true);
        }
      } else {
        this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
      }
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
