import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class CamelCaseMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    dataBus: any,
  ) {
    try {
      dataBus = dataBus || '';
      
      if (this.ExistsRepMoleculeAttached) {
        dataBus = this.RepMoleculeAttached.GetValue;
      }
      
      dataBus = this.DataActionModifierApply(dataBus, this.Modifier);
      
      
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  private Modifier(value: string): string {
    const type = this.toolsService.GetObjectType(value);
    if (type === 'string') {
      
      value = value.replace(/\s(.)/g, function($1) {
        return $1.toUpperCase();
      }).replace(/^(.)/, function($1) {
        return $1.toLowerCase();
      }).replace(/\s/g, function($1) {
        if (value.substr(value.length - 1) === ' ') {
          return ' ';
        } else {
          return '';
        }
      });
      
    }
    return value;
  }
}
