import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../components/custom-snackbar/custom-snackbar.component';
import { ConnectionStateService } from './connection-state.service';

@Injectable({
  providedIn: 'root',
})
export class SnackerService {
  
  backgroundTask: {
    id: string,
    icon: string,
    message: string,
  }[] = [];
  
  constructor(
    private snackbar: MatSnackBar,
    private connectionStateService: ConnectionStateService,
  ) {
  }
  
  
  ShowMessageOnTop(message: string, icon?: string, duration?: number, right = false, action?: {
    text: string;
    icon?: string;
    callback?: any
  }) {
    this.showMessage(message, icon, 'top', duration, right, action);
  }
  
  ShowMessageOnBottom(message: string, icon?: string, duration?: number, right = false, action?: {
    text: string;
    icon?: string;
    callback?: any
  }) {
    this.showMessage(message, icon, 'bottom', duration, right, action);
  }
  
  ToggleBackgroundTask(id: string, message = '', icon = 'information') {
    const taskExists = !!this.backgroundTask.find(t => t.id === id);
    
    if (taskExists) {
      this.backgroundTask = this.backgroundTask.filter(t => t.id !== id);
    } else if (message !== '') {
      this.backgroundTask.push({
        id,
        icon,
        message,
      });
    }
  }
  
  private showMessage(message: string, icon: string, position = 'bottom', duration?: number, rightPosition = false, action?: {
    text: string;
    icon?: string;
    callback?: any
  }, showDurationBar = true) {
    const wordsCount = message.split(' ').length;
    const calcDuration = duration ? duration : (wordsCount / 3) * 1000 + 1000;
    
    this.snackbar.openFromComponent(CustomSnackbarComponent, {
      duration: calcDuration,
      verticalPosition: position === 'bottom' ? 'bottom' : 'top',
      horizontalPosition: rightPosition ? 'right' : 'center',
      data: {
        showDurationBar: showDurationBar,
        duration: calcDuration,
        message,
        icon: icon && icon !== '' ? icon : 'info',
        action,
      },
    });
  }
}
