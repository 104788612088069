import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Permissions } from '../../admin/models/permissions.enum';
import { ApiFileService } from '../../core/services/api-file.service';
import { ApiMoleculesService } from '../../core/services/api-molecules.service';
import { ApiPropertiesService } from '../../core/services/api-properties.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { ToolsService } from '../../core/services/tools.service';
import { UserMenuService } from '../../core/services/user-menu.service';
import { PropertyVersioningDto } from '../../shared/dtos/versioning-dto';
import { MoleculesType } from '../../shared/enums/molecules-type.enum';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { DragService } from '../../shared/representative-molecule/services/drag.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { ISmartTemplateCategory } from '../interfaces/smart-template-category.interface';
import { ISmartTemplateSelection } from '../interfaces/smart-template-selection.interface';
import { ISmartTemplate } from '../interfaces/smart-template.interface';
import { ApiSmartTemplateService } from '../services/api-smart-template.service';
import { SmartTemplatesService } from '../services/smart-templates.service';
import { WorkAreaService } from '../workarea.service';
import { Constants } from './../../shared/constants';

@Component({
  selector: 'app-smart-template-wizard',
  templateUrl: './smart-template-wizard.component.html',
  styleUrls: ['./smart-template-wizard.component.scss'],
  animations: [
    trigger('zoomIn', [
      transition(':enter', [style({ transform: 'scale(0)', opacity: 0 }), animate('100ms', style({ transform: 'scale(1)', opacity: 1 }))]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('100ms', style({ transform: 'translateY(-5%)', opacity: 0 })),
      ]),
    ]),
    trigger('add', [
      transition(':enter', [style({ transform: 'translateY(10%)' }), animate('200ms', style({ transform: 'translateY(0)' }))]),
      // transition(':leave', [
      //   style({ transform: 'translateY(0)', opacity: 1 }),
      //   animate('100ms', style({ transform: 'translateY(-5%)', opacity: 0 }))
      // ])
    ]),
    trigger('logoOut', [
      transition(':leave', [style({ transform: 'scale(1)', opacity: 1 }), animate('200ms', style({ transform: 'scale(13)', opacity: 0 }))]),
    ]),
    trigger('flyInOut', [
      transition('void => fly', [
        animate(400, keyframes([style({ transform: 'translateY(15%)', opacity: 0 }), style({ transform: 'translateY(0)', opacity: 1 })])),
      ]),
    ]),
  ],
})
export class SmartTemplateWizardComponent implements OnInit {
  @ViewChild('editCategoryNameInput', { static: false })
  editCategoryNameInput: ElementRef;
  @ViewChild('editCategoryDescriptionInput', { static: false })
  editCategoryDescriptionInput: ElementRef;
  @ViewChild('editTemplateNameInput', { static: false })
  editTemplateNameInput: ElementRef;
  uploader: FileUploader = new FileUploader({});

  environment = Constants.Environment;
  templateCategories: ISmartTemplateCategory[] = [];
  categoriesTabOpen = true;
  selections: ISmartTemplateSelection[] = [];
  categorySelected: ISmartTemplateCategory = null;
  categoriesBreadcrumb: ISmartTemplateCategory[] = [];
  loadingTemplates = false;
  categoryTemplates: ISmartTemplate[] = [];
  showLocationMenu = false;
  locationMenuTop = 200;
  locationMenuLeft = 300;
  locationMenuTemplate: ISmartTemplate = null;
  myTemplatesEditPermission = false;
  globalEditPermission = false;
  editCategory: ISmartTemplateCategory = null;
  editTemplate: ISmartTemplate = null;
  editTemplateName = '';
  preventCategoryClick = false;
  timerCategoryClick = null;
  editCategoryName = '';
  editCategoryDescription = '';
  fileToUploadType = '';
  componentToSetPreviewImage = null;
  categoryDragged: ISmartTemplateCategory = null;
  templateDragged: ISmartTemplate = null;
  homeAllowDrop = false;
  objectDragged = '';
  noShowStartPage = false;
  myTemplatesCategories: ISmartTemplateCategory[] = [];
  myTemplatesCategoryID = 99999999;

  constructor(
    private toolsService: ToolsService,
    private propertiesService: ApiPropertiesService,
    private cobbleService: CobbleService,
    private moleculesService: ApiMoleculesService,
    public smartTemplatesService: SmartTemplatesService,
    private snackerService: SnackerService,
    private userMenuService: UserMenuService,
    private dragService: DragService,
    private imageCompress: NgxImageCompressService,
    private fileService: ApiFileService,
    private genericDialogService: GenericDialogService,
    private communicationService: CommunicationService,
    private apiSmartTemplateService: ApiSmartTemplateService,
    private clientStorageService: ClientStorageService,
    private workAreaService: WorkAreaService
  ) {}

  get TemplateCategories(): ISmartTemplateCategory[] {
    if (this.categorySelected) {
      return this.categorySelected.categories;
    } else {
      return this.templateCategories;
    }
  }

  SetTemplateCategories(categories: ISmartTemplateCategory[]) {
    if (this.categorySelected) {
      this.categorySelected.categories = categories;
    } else {
      this.templateCategories = categories;
    }
  }

  ngOnInit() {
    this.apiSmartTemplateService.GetCategoriesByUser(this.clientStorageService.getUserId()).subscribe(categories => {
      this.myTemplatesCategories = categories;
    });

    this.globalEditPermission = this.userMenuService.checkPermission(Permissions.SmartTemplatesGlobalEdit);
    this.myTemplatesEditPermission = this.userMenuService.checkPermission(Permissions.SmartTemplatesMyTemplatesEdit);
    this.apiSmartTemplateService.GetCategoriesByCompanyId(this.clientStorageService.getCompanyId(), 0, false).subscribe(categories => {
      this.templateCategories = categories.sort(this.toolsService.CompareValues('name')) || [];
    });

    this.noShowStartPage = this.clientStorageService.gethideSmartTemplateStartPage() === 'Y';
  }

  SelectCategory(category: ISmartTemplateCategory, fromBreadcrumb = false) {
    this.loadingTemplates = true;
    if (category.edit) {
      this.loadingTemplates = false;
      return;
    }

    this.timerCategoryClick = setTimeout(() => {
      if (!this.preventCategoryClick) {
        if (category.edit) {
          this.loadingTemplates = false;
          return;
        }

        if (category.id === this.myTemplatesCategoryID) {
          this.loadingTemplates = false;
        }

        if (category.categoryIds.length > 0) {
          if (category.id === this.myTemplatesCategoryID) {
          } else {
            this.apiSmartTemplateService.GetCategoriesForParent(category.id).subscribe(categories => {
              category.categories = categories.sort(this.toolsService.CompareValues('name'));
            });
          }
        }

        if (!fromBreadcrumb) {
          this.categoriesBreadcrumb.push(category);
        }
        this.categorySelected = category;

        this.GoToTemplateTab();
      } else {
        this.loadingTemplates = false;
      }
      this.preventCategoryClick = false;
    }, 200);
  }

  GoToTemplateTab() {
    this.categoryTemplates = [];
    this.categoriesTabOpen = false;

    if (this.categorySelected.id === this.myTemplatesCategoryID) {
      return;
    }

    this.loadingTemplates = true;

    console.log('breadcrumb', this.categoriesBreadcrumb);

    this.apiSmartTemplateService.GetTemplatesByCategoryId(this.categorySelected.id).subscribe(categoryTemplates => {
      categoryTemplates = categoryTemplates.sort(this.toolsService.CompareValues('name'));

      if (categoryTemplates.length === 0) {
        this.loadingTemplates = false;
      }

      categoryTemplates.forEach(t => {
        this.moleculesService.GetApp(t.componentId).subscribe((app: any) => {
          t.component = app;
          this.categoryTemplates.push(t);
          if (this.categoryTemplates.length === categoryTemplates.length) {
            this.loadingTemplates = false;
          }
        });
      });
    });
  }

  GoToCategoriesTab() {
    this.categorySelected = null;
    this.categoriesBreadcrumb = [];
    this.categoriesTabOpen = true;
  }

  NavigateToCategory(category: ISmartTemplateCategory = null, breadcrumbIndex = 0) {
    this.StopCategoryCreation();

    if (category && category.id === this.categorySelected.id) {
      return;
    }

    if (category) {
      this.categoriesBreadcrumb = this.categoriesBreadcrumb.splice(0, breadcrumbIndex + 1);
      this.categorySelected = category;
      this.SelectCategory(category, true);
    } else {
      this.apiSmartTemplateService.GetCategoriesByCompanyId(this.clientStorageService.getCompanyId(), 0, false).subscribe(categories => {
        this.templateCategories = categories.sort(this.toolsService.CompareValues('name')) || [];
      });
      this.GoToCategoriesTab();
    }
  }

  IsCategoryAdded(categoryId: number): boolean {
    return !!this.selections.find(s => s.category.id === categoryId);
  }

  IsCategorySelected(categoryId: number): boolean {
    return this.categorySelected && this.categorySelected.id === categoryId;
  }

  RemoveSelection(selectionId: string) {
    this.selections = this.selections.filter(s => s.id !== selectionId);
  }

  SelectTemplate(template: ISmartTemplate) {
    console.log(template);
    if (template.componentId === this.cobbleService.Cobble.id) {
      this.snackerService.ShowMessageOnBottom('Can not add this component, it is already opened in the editor', 'do_not_disturb_off', 3000);
      return;
    }

    if (template.component === null || (template.component && template.component.length === 1)) {
      this.snackerService.ShowMessageOnBottom('This template does not contain elements', 'empty_dashboard');
    }

    if (this.showLocationMenu || template.componentId === 0) {
      return;
    }

    this.apiSmartTemplateService.GetCategory(template.categoryId).subscribe(category => {
      const selection: ISmartTemplateSelection = {
        id: this.toolsService.GenerateGuid(),
        category: category,
        template: template,
      };

      this.selections.push(selection);
    });
  }

  GenerateApp() {
    this.workAreaService.HideElementFocusedMenu();
    this.communicationService.Event.Editor.WorkArea.$ShowLoadingOverlay.emit({
      display: true,
      showSpinner: true,
      iconAnimated: true,
      spinnerType: 'bar',
      message: 'Building Application...',
      icon: 'backup_table',
      iconColor: 'gray',
    });

    if (this.workAreaService.smartTemplateWindow) {
      this.workAreaService.smartTemplateWindow.Hide();
    }

    setTimeout(() => {
      this.smartTemplatesService.GenerateAppFromSmartTemplateSelection(this.selections);
    }, 1000);
  }

  EditTemplateName(template: ISmartTemplate) {
    if (!this.HasPermission()) {
      return;
    }

    template.edit = true;
    this.editTemplateName = template.name;
    this.editTemplate = template;
    this.FocusTemplateNameEditInput();
  }

  StopTemplateCreation(template: ISmartTemplate, event: MouseEvent) {
    template.edit = false;
    this.editTemplate.name = this.editTemplateName;
    this.editTemplateName = '';
  }

  SaveTemplate() {
    this.editTemplate.edit = false;
    this.editTemplateName = '';

    if (this.editTemplate.id > 0) {
      this.apiSmartTemplateService.UpdateTemplate(this.editTemplate).subscribe();
    } else {
      if (this.editTemplate.componentId > 0) {
        this.apiSmartTemplateService.CreateTemplate(this.editTemplate).subscribe(newTemplate => {
          const template = this.categoryTemplates.find(t => t.id === 0);
          template.id = newTemplate.id;
          this.categorySelected.templates.push(newTemplate.id);
          this.apiSmartTemplateService.UpdateCategory(this.categorySelected).subscribe(categoryUpdated => {});
        });
      }
    }
    console.log('save template');
  }

  DropComponent(event: any, template: ISmartTemplate) {
    if (!this.HasPermission()) {
      return;
    }

    console.log(event, this.dragService.dragData, template);

    if (this.dragService.dragData && this.dragService.dragData.moleculeType && this.dragService.dragData.moleculeType === MoleculesType.Cobblet) {
      if (template.id > 0 && template.componentId > 0) {
        this.genericDialogService.OpenConfirmDialog({
          title: 'Replace template component',
          message: `You are about to replace the component for this template. Are you sure you want to contine?`,
          confirmText: 'Replace',
          cancelText: 'Cancel',
        }).then(confirm => {
          if (confirm) {
            this.moleculesService.GetApp(this.dragService.dragData.id).subscribe((app: any) => {
              template.component = app;
              template.componentId = this.dragService.dragData.id;
              
              this.apiSmartTemplateService.UpdateTemplate(template).subscribe();
            });
            
            this.snackerService.ShowMessageOnBottom('Component replaced', 'swap_horizontal_circle');
          }
        });
      } else {
        this.moleculesService.GetApp(this.dragService.dragData.id).subscribe((app: any) => {
          template.component = app;
          template.componentId = this.dragService.dragData.id;

          if (template.name === '') {
            template.name = 'New Template';
            template.edit = true;
            this.editTemplate = template;
            this.FocusTemplateNameEditInput();
          } else {
            this.apiSmartTemplateService.CreateTemplate(template).subscribe(newTemplate => {
              template.id = newTemplate.id;
              this.categorySelected.templates.push(newTemplate.id);

              this.apiSmartTemplateService.UpdateCategory(this.categorySelected).subscribe();
            });

            this.snackerService.ShowMessageOnBottom('Template added to the library', 'library_add');
          }
        });
      }
    }
  }

  StopCategoryCreation(category: ISmartTemplateCategory = null, event: MouseEvent = null) {
    console.log('stop');

    if (event) {
      event.stopPropagation();
      event.preventDefault();

      const targetClassList = (event.target as any).classList;

      if (targetClassList.contains('category-name-edit-input') || targetClassList.contains('category-description-edit-input')) {
        return;
      }
    }

    if (category) {
      if (category.id > 0) {
        category.edit = false;
        this.editCategory = null;
        category.name = this.editCategoryName;
        category.description = this.editCategoryDescription;
      } else {
        this.SetTemplateCategories(this.TemplateCategories.filter(c => !c.edit));
        this.editCategory = null;
      }
    } else {
      this.editCategory = null;
    }

    this.editCategoryName = this.editCategoryDescription = '';
  }

  EditCategoryName(category: ISmartTemplateCategory) {
    if (!this.HasPermission()) {
      return;
    }

    clearTimeout(this.timerCategoryClick);
    this.preventCategoryClick = true;
    this.editCategoryName = category.name;
    this.editCategoryDescription = category.description;
    this.editCategory = category;
    category.edit = true;
    this.FocusNameEditInput();
  }

  EditCategoryDescription(category: ISmartTemplateCategory) {
    if (!this.HasPermission()) {
      return;
    }

    clearTimeout(this.timerCategoryClick);
    this.preventCategoryClick = true;
    this.editCategoryName = category.name;
    this.editCategoryDescription = category.description;
    this.editCategory = category;
    category.edit = true;
    this.FocusDescriptionEditInput();
  }

  FocusTemplateNameEditInput() {
    setTimeout(() => {
      if (this.editTemplateNameInput) {
        this.editTemplateNameInput.nativeElement.focus();
        this.editTemplateNameInput.nativeElement.select();
      }
    }, 100);
  }

  FocusNameEditInput() {
    setTimeout(() => {
      if (this.editCategoryNameInput) {
        this.editCategoryNameInput.nativeElement.focus();
        this.editCategoryNameInput.nativeElement.select();
      }
    }, 100);
  }

  FocusDescriptionEditInput() {
    setTimeout(() => {
      if (this.editCategoryDescriptionInput) {
        this.editCategoryDescriptionInput.nativeElement.focus();
        this.editCategoryDescriptionInput.nativeElement.select();
      }
    }, 100);
  }

  CreateTemplate(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (!!this.TemplateCategories.find(c => c.edit)) {
      this.FocusNameEditInput();
      return;
    }

    if (!!this.categoryTemplates.find(t => t.edit)) {
      this.FocusTemplateNameEditInput();
      return;
    }

    this.editTemplateName = 'New Template';
    this.editTemplate = {
      id: 0,
      name: 'New Template',
      edit: true,
      keepDataSource: false,
      categoryId: this.categorySelected.id,
      componentId: 0,
      component: null,
      location: 'random',
      enabled: true,
      companyId: null,
    };

    this.categoryTemplates.unshift(this.editTemplate);
    this.FocusTemplateNameEditInput();
  }

  CreateCategory(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (!!(this.TemplateCategories && this.TemplateCategories.find(c => c.edit))) {
      this.FocusNameEditInput();
      return;
    }

    this.editCategory = {
      id: 0,
      name: 'New Category',
      edit: true,
      description: 'Category description',
      location: 'random',
      categories: [],
      categoryIds: [],
      parentCategoryId: this.categorySelected && this.categorySelected.id !== this.myTemplatesCategoryID ? this.categorySelected.id : 0,
      templates: [],
      enabled: true,
      allowDrop: false,
      companyId: this.categorySelected && this.categorySelected.id === this.myTemplatesCategoryID ? null : this.clientStorageService.getCompanyId(),
    };

    this.TemplateCategories.unshift(this.editCategory);
    this.FocusNameEditInput();
  }

  SaveCategory() {
    if (this.editCategory.name === '') {
      this.FocusNameEditInput();
      return;
    }

    if (this.editCategory.description === '') {
      this.FocusDescriptionEditInput();
      return;
    }

    const category = this.TemplateCategories.find(c => c.edit);
    category.edit = false;
    this.editCategory = null;

    if (category.id === 0) {
      this.snackerService.ShowMessageOnBottom('Category Added', 'add_circle', null, true);

      this.apiSmartTemplateService.CreateCategory(category).subscribe(newCategory => {
        category.id = newCategory.id;

        if (this.categorySelected) {
          this.categorySelected.categoryIds.push(newCategory.id);

          if (this.categorySelected.id !== this.myTemplatesCategoryID) {
            this.apiSmartTemplateService.UpdateCategory(this.categorySelected).subscribe();
          }
        }
      });
      console.log('category created', category);
    } else {
      this.snackerService.ShowMessageOnBottom('Category Updated', 'update', null, true);
      this.apiSmartTemplateService.UpdateCategory(category).subscribe(newCategory => {});
    }
  }

  RemoveCategory(category: ISmartTemplateCategory, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (category.templates.length > 0 || category.categoryIds.length > 0) {
      this.genericDialogService.OpenConfirmDialog({
        title: 'Remove Category',
        message: `You are about to remove a category which contains ${category.templates.length} templates and ${category.categoryIds.length} sub-categories. Are you sure you want to remove it anyways?`,
        confirmText: 'Remove',
        cancelText: 'Cancel',
      }).then(confirm => {
        if (confirm) {
          this.SetTemplateCategories(this.TemplateCategories.filter(c => c.id !== category.id));
          console.log('category removed', category);
          this.snackerService.ShowMessageOnBottom('Category removed', 'do_not_disturb_on');
          category.parentCategoryId = null;
          category.enabled = false;
          this.apiSmartTemplateService.UpdateCategory(category).subscribe();
        }
      });
    } else {
      this.SetTemplateCategories(this.TemplateCategories.filter(c => c.id !== category.id));
      console.log('category removed', category);
      this.snackerService.ShowMessageOnBottom('Category removed', 'do_not_disturb_on');
      category.parentCategoryId = null;
      category.enabled = false;
      this.apiSmartTemplateService.UpdateCategory(category).subscribe();
    }
  }

  RemoveTemplate(template: ISmartTemplate, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.genericDialogService.OpenConfirmDialog({
      title: 'Remove Template',
      message: `You are about to remove this template. Are you sure you want to continue?`,
      confirmText: 'Remove',
      cancelText: 'Cancel',
    }).then(confirm => {
      if (confirm) {
        this.snackerService.ShowMessageOnBottom('Template removed', 'do_not_disturb_on');
        template.categoryId = 0;
        this.categoryTemplates = this.categoryTemplates.filter(t => t.id !== template.id);
        this.categorySelected.templates = this.categorySelected.templates.filter(t => t !== template.id);
        this.apiSmartTemplateService.UpdateTemplate(template).subscribe();
        this.apiSmartTemplateService.UpdateCategory(this.categorySelected).subscribe();
      }
    });

    console.log(template);
    return;
  }

  UploadPreviewImage(previewDevice: string, template: ISmartTemplate) {
    this.componentToSetPreviewImage = template.component;
    this.fileToUploadType = previewDevice;
    document.getElementById('imageFileSelector').click();
  }

  fileSelected(eventData: any[]) {
    console.log('file selected', eventData[0]);
    const file = eventData[0];

    if (file) {
    } else {
      return;
    }

    const filename = file.name;

    if (file) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageCompress.compressFile(event.target.result, -1, 80, 50).then(compressedImg => {
          this.toolsService.UrlToFile(file.size < 50000 ? event.target.result : compressedImg, filename, file.type).then(imageFile => {
            this.fileService.uploadFile([imageFile], 2, this.componentToSetPreviewImage[0].id).subscribe(result => {
              this.propertiesService
                .SaveProperty(
                  new PropertyVersioningDto({
                    elementId: this.componentToSetPreviewImage[0].id.toString(),
                    property: this.fileToUploadType,
                    value: result,
                    path: '',
                    change: `Preview Image`,
                    name: this.componentToSetPreviewImage[0].name,
                  })
                )
                .subscribe(r => {
                  this.componentToSetPreviewImage[0][this.fileToUploadType] = result;
                });
            });
          });
        });
      };
      reader.readAsDataURL(file);
    }
  }

  OpenCategoryMicrolearning(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  OpenTemplateMicrolearning(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  OpenSelectionMicrolearning(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  LocateComponent(location: string) {
    if (location === 'relative') {
      location = 'random';
    }

    this.locationMenuTemplate.location = location;

    if (this.locationMenuTemplate.componentId > 0) {
      this.apiSmartTemplateService.UpdateTemplate(this.locationMenuTemplate).subscribe();
    }
  }

  ShowLocationMenu(event: MouseEvent, template: ISmartTemplate) {
    if (!this.HasPermission()) {
      return;
    }

    console.log(event, template);
    const windowBounds = document.querySelector('.smart-template-container').getBoundingClientRect();

    this.locationMenuTemplate = template;
    this.locationMenuTop = event.clientY - windowBounds.top - 50;
    this.locationMenuLeft = event.clientX - windowBounds.left + 35;
    this.showLocationMenu = true;
    event.stopPropagation();
    event.preventDefault();
  }

  CategoryDrop(event: DragEvent, parentCategory: ISmartTemplateCategory) {
    this.homeAllowDrop = false;
    this.CategoryDragend();
    this.TemplateDragend();

    if (this.objectDragged === 'category') {
      if (this.categoryDragged && this.categoryDragged.id !== parentCategory.id) {
        this.MoveCategory(this.categoryDragged, parentCategory);
      }
    } else {
      this.MoveTemplate(this.templateDragged, parentCategory);
    }
  }

  MoveTemplate(template: ISmartTemplate, category: ISmartTemplateCategory) {
    template.categoryId = category.id;

    this.apiSmartTemplateService.UpdateTemplate(template).subscribe();
    this.categorySelected.templates = this.categorySelected.templates.filter(id => id !== template.id);
    category.templates.push(template.id);
    this.apiSmartTemplateService.UpdateCategory(this.categorySelected).subscribe(categoryUpdated => {
      this.apiSmartTemplateService.UpdateCategory(category).subscribe();
    });
    this.categoryTemplates = this.categoryTemplates.filter(t => t.id !== template.id);
  }

  MoveCategory(category: ISmartTemplateCategory, parentCategory: ISmartTemplateCategory = null) {
    if (this.categorySelected) {
      this.categorySelected.categoryIds = this.categorySelected.categoryIds.filter(id => id !== category.id);

      this.apiSmartTemplateService.UpdateCategory(this.categorySelected).subscribe();
    }

    this.SetTemplateCategories(this.TemplateCategories.filter(c => c.id !== category.id));

    if (parentCategory) {
      parentCategory.categoryIds.push(category.id);
      this.apiSmartTemplateService.UpdateCategory(parentCategory).subscribe();

      category.parentCategoryId = parentCategory.id;
    } else {
      category.parentCategoryId = null;
    }

    setTimeout(() => {
      this.apiSmartTemplateService.UpdateCategory(category).subscribe();
    }, 100);
    this.CategoryDragend();
  }

  CategoryDragend() {
    this.homeAllowDrop = false;
    this.categoriesBreadcrumb.forEach(c => {
      c.allowDrop = false;
    });

    this.TemplateCategories.forEach(c => {
      c.allowDrop = false;
    });
  }

  TemplateDragend() {
    this.categoriesBreadcrumb.forEach(c => {
      c.allowDrop = false;
    });

    this.TemplateCategories.forEach(c => {
      c.allowDrop = false;
    });
  }

  CategoryDragStart(event: DragEvent, category: ISmartTemplateCategory) {
    this.dragService.dragData = null;
    this.objectDragged = 'category';
    this.categoryDragged = category;
    this.homeAllowDrop = true;

    this.TemplateCategories.filter(c => c.id !== category.id).forEach(c => {
      c.allowDrop = true;
    });
    this.categoriesBreadcrumb
      .filter(c => c.id !== category.id && this.categorySelected && c.id !== this.categorySelected.id)
      .forEach(c => {
        c.allowDrop = true;
      });
  }

  TemplateDragStart(template: ISmartTemplate) {
    this.dragService.dragData = null;
    this.objectDragged = 'template';
    this.templateDragged = template;
    this.TemplateCategories.forEach(c => {
      c.allowDrop = true;
    });
    this.categoriesBreadcrumb
      .filter(c => this.categorySelected && c.id !== this.categorySelected.id)
      .forEach(c => {
        c.allowDrop = true;
      });
  }

  HomeCategoryDrop($event: DragEvent) {
    if (this.objectDragged === 'template') {
      return;
    }

    this.homeAllowDrop = false;
    this.MoveCategory(this.categoryDragged);
  }

  OpenMyTemplates() {
    const myTemplateCategory = {
      id: this.myTemplatesCategoryID,
      name: 'My Templates',
      edit: false,
      description: '',
      location: 'random',
      categories: this.myTemplatesCategories,
      categoryIds: this.myTemplatesCategories.map(tc => tc.id),
      parentCategoryId: 0,
      templates: [],
      enabled: true,
      allowDrop: false,
      companyId: null,
    };

    this.SelectCategory(myTemplateCategory);
  }

  ToggleKeepDatasource(event: any) {
    if (this.locationMenuTemplate.componentId > 0) {
      this.locationMenuTemplate.keepDataSource = event.checked;
      this.apiSmartTemplateService.UpdateTemplate(this.locationMenuTemplate).subscribe();
    }
  }

  IsMyTemplatesSection(): boolean {
    return (
      !!this.categoriesBreadcrumb.find(cb => cb.id === this.myTemplatesCategoryID) ||
      (this.categorySelected && this.categorySelected.id === this.myTemplatesCategoryID)
    );
  }

  ToggleStartPageVisibility(e: any) {
    const condition = e.checked ? 'Y' : 'N';
    // set flag on backend
    this.clientStorageService.setHideSmartTemplateStartPage(condition);
  }

  HasPermission(): boolean {
    return (this.IsMyTemplatesSection() && this.myTemplatesEditPermission) || (!this.IsMyTemplatesSection() && this.globalEditPermission);
  }
}
