import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Permissions } from 'app/admin/models/permissions.enum';
import { cloneDeep } from 'lodash-es';
import { Subscription } from 'rxjs';
import { BuilderService } from '../../core/builder/builder.service';
import { BusService } from '../../core/molecular/services/bus.service';
import { EventsService } from '../../core/molecular/services/events.service';
import { ApiPropertiesService } from '../../core/services/api-properties.service';
import { EditorStateService } from '../../core/services/editor-state.service';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { MoleculeManagmentService } from '../../core/services/molecule-managment.service';
import { ToolsService } from '../../core/services/tools.service';
import { UserMenuService } from '../../core/services/user-menu.service';
import { PropertyVersioningDto } from '../../shared/dtos/versioning-dto';
import { RepresentativeMoleculesType } from '../../shared/enums/representative-molecules-types.enum';
import {
  IRepresentativeMolecule,
} from '../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { ConnectionStateService } from '../../shared/services/connection-state.service';
import { DraggableWindowManagerService } from '../../shared/services/draggable-window-manager.service';
import { DraggableWindowService, DraggableWindowType } from '../../shared/services/draggable-window.service';
import { JsonTreeComponent } from '../json-tree/json-tree.component';
import { VersioningHistoryDialogService } from '../versioning-history/versioning-history-dialog.service';
import { WorkAreaService } from '../workarea.service';
import { ThematicService } from './../services/thematic.service';

@Component({
  selector: 'app-molecules-context-menu',
  templateUrl: './molecules-context-menu.component.html',
  styleUrls: ['./molecules-context-menu.component.scss'],
  animations: [
    trigger('enterUp', [
      transition(':enter', [
        style({ transform: 'translateX(-20%)', opacity: 0.7 }),
        animate('200ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('200ms', style({ transform: 'translateX(-25%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class MoleculesContextMenuComponent implements OnInit, OnDestroy {
  @ViewChild('renameInput', { static: false })
  renameInput: ElementRef;
  @Input()
  element: IRepresentativeMolecule;
  @Input()
  rename = false;
  @Input()
  public molecules = [];
  renaming = false;
  subscriptions: Subscription;
  Permissions = Permissions;
  isDebug = false;
  mouseOver = false;
  isSuperAdmin = false;
  historyVersionPermission = false;
  
  constructor(
    private draggableWindowService: DraggableWindowService,
    private draggableWindowManagerService: DraggableWindowManagerService,
    public workAreaService: WorkAreaService,
    private moleculeManagementService: MoleculeManagmentService,
    private versioningService: VersioningHistoryDialogService,
    private builderService: BuilderService,
    private busService: BusService,
    private toolsService: ToolsService,
    public cobbleService: CobbleService,
    private editorStateService: EditorStateService,
    private connectionStateService: ConnectionStateService,
    private genericDialogService: GenericDialogService,
    private communicationService: CommunicationService,
    private propertiesService: ApiPropertiesService,
    private eventsService: EventsService,
    public userMenuService: UserMenuService,
    public thematicService: ThematicService,
    private localStorageService: LocalStorageService,
  ) {
    this.isSuperAdmin = this.userMenuService.isAdminUser;
  }
  
  ngOnInit() {
    this.mouseOver = this.workAreaService.editorPreferences.fixedContextMenu;
    this.isDebug = this.localStorageService.IsDebug();
    
    // console.log('element', this.element);
    
    this.subscriptions = this.communicationService.Event.Editor.$CancelRepMoleculeRename.subscribe((data) => {
      console.log('cancel rename');
      this.CancelRename();
    });
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.$RenameRepMolecule.subscribe((data) => {
        console.log('rename');
        this.RenameRepMolecule();
      }),
    );
    
    if (this.rename) {
      this.RenameRepMolecule();
      this.workAreaService.renameElementFocusedMenu = false;
    }
    
    this.historyVersionPermission = this.userMenuService.checkPermission(Permissions.VersionHistory);
  }
  
  ShowPropertiesPanel(event: MouseEvent) {
    this.draggableWindowService.OpenDraggableWindow(
      `${ this.element.Type } - ${ this.element.Properties.name } Properties`,
      DraggableWindowType.RepresentativeProperties,
      event);
  }
  
  ShowMoleculesPanel(event: MouseEvent) {
    this.draggableWindowService.OpenDraggableWindow(
      `${ this.element.Properties.name } - ${ this.element.Type } Process Panel`,
      DraggableWindowType.ProcessPanel,
      event);
  }
  
  CreateCobblet(event: MouseEvent) {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    this.draggableWindowService.OpenDraggableWindow(
      'Create Component',
      DraggableWindowType.CreateComponent,
      event);
  }
  
  OpenVersioning(event: MouseEvent) {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    event.preventDefault();
    event.stopPropagation();
    this.toolsService.mousePosition = {
      x: event.clientX,
      y: event.clientY,
    };
    this.versioningService.openDialog(this.element.Id);
  }
  
  ToggleDragWG(event: MouseEvent) {
    this.element.EnableDragBetweenWG = !this.element.EnableDragBetweenWG;
  }
  
  RemoveMolecule(event: MouseEvent) {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    if (this.element.IsOnlyWorkgroupInWorkArea()) {
      return;
    }
    
    event.preventDefault();
    event.stopPropagation();
    this.toolsService.mousePosition = {
      x: event.clientX,
      y: event.clientY,
    };
    // console.log('delete');
    
    if (this.element.Type === RepresentativeMoleculesType.WorkGroup && this.busService.GetChildrenElementIds(
      this.element.Id).length > 2) {
      this.genericDialogService.OpenConfirmDialog({
        title: 'Delete Workgroup',
        message: `This workgroup contains ${
          this.busService.GetChildrenElementIds(this.element.Id).length
        } elements. Are you sure you want to delete ${ this.element.Properties.name }?`,
        confirmText: 'Delete',
        cancelText: 'Cancel',
      })
      .then((confirm) => {
        if (confirm) {
          this.moleculeManagementService.RemoveRepresentativeMolecule(this.element.Id);
          this.workAreaService.HideElementFocusedMenu();
        }
      });
    } else {
      if (this.workAreaService.elementsSelected.length > 1) {
        this.genericDialogService.OpenConfirmDialog({
          title: 'Delete Elements',
          message: `You are about to delete ${ this.workAreaService.elementsSelected.length } elements. Are you sure you want to delete all of them?`,
          confirmText: 'Delete',
          cancelText: 'Cancel',
        })
        .then((confirm) => {
          if (confirm) {
            this.workAreaService.elementsSelected.forEach((es) => {
              this.moleculeManagementService.RemoveRepresentativeMolecule(es.Id);
            });
            
            this.workAreaService.HideElementFocusedMenu();
          }
        });
      } else {
        this.moleculeManagementService.RemoveRepresentativeMolecule(this.element.Id);
        this.workAreaService.HideElementFocusedMenu();
      }
    }
  }
  
  Duplicate(event: MouseEvent) {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    event.preventDefault();
    event.stopPropagation();
    
    const repMolecules = this.workAreaService.elementsSelected.length > 1 ? this.workAreaService.elementsSelected : [this.element];
    
    if (this.workAreaService.elementsSelected.length > 1) {
      this.genericDialogService.OpenConfirmDialog({
        title: 'Duplicate Elements',
        message: `You are about to duplicate ${ this.workAreaService.elementsSelected.length } elements. Are you sure you want to duplicate all of them?`,
        confirmText: 'Duplicate',
        cancelText: 'Cancel',
      })
      .then((confirm) => {
        if (confirm) {
          this.workAreaService.elementsSelected.forEach((es) => {
            this.DuplicateProcess(es);
          });
        }
      });
    } else {
      this.DuplicateProcess(repMolecules[0]);
    }
  }
  
  DuplicateProcess(repMolecule: IRepresentativeMolecule) {
    const moleculeTypeId = this.workAreaService.libraryMoleculeIds[`${ repMolecule.Type }-Representative`];
    const parent = this.busService.GetParent(repMolecule.Id.toString());
    let newYposition = repMolecule.ResponsiveProperties().y + repMolecule.ResponsiveProperties().rows + 1;
    
    const sizePosition = {
      x: repMolecule.ResponsiveProperties().x,
      y: repMolecule.ResponsiveProperties().y + repMolecule.ResponsiveProperties().rows + 1,
      cols: repMolecule.ResponsiveProperties().cols,
      rows: repMolecule.ResponsiveProperties().rows,
    };
    
    while (
      this.busService.GetSamePositionAndSizeRepresentativeMolecule(sizePosition, this.cobbleService.Cobble,
        this.workAreaService.ActualView.id)
        .length > 0
      ) {
      newYposition = newYposition + repMolecule.ResponsiveProperties().rows;
      sizePosition.y = newYposition;
    }
    
    const clonedBuses = [];
    
    repMolecule.Buses.forEach((b) => clonedBuses.push(b.Clone()));
    
    const clonedProperties = cloneDeep(repMolecule.PropertiesBackup);
    clonedProperties.responsive[this.cobbleService.Cobble.deviceType].y = newYposition;
    
    const properties = [
      {
        name: 'styleMetadata',
        value: repMolecule.StyleMetadata,
        path: '',
      },
      {
        name: 'properties',
        value: clonedProperties,
        path: '',
      },
      {
        name: 'buses',
        value: clonedBuses,
        path: '',
      },
    ];
    
    this.builderService
    .GetMolecule(
      parent,
      {
        id: moleculeTypeId,
        type: repMolecule.Type,
        moleculeType: repMolecule.MoleculeType,
      },
      null,
      properties,
      [],
      0,
      false,
      false,
    )
    .then((clonedMolecule: IRepresentativeMolecule) => {
      if (clonedMolecule.ContainsEvents()) {
        this.toolsService.ReplaceValueInObject(clonedMolecule.Buses, +this.element.Id, +clonedMolecule.Id);
        this.toolsService.ReplaceValueInObject(clonedMolecule.Buses, this.element.Id.toString(),
          clonedMolecule.Id.toString());
        this.toolsService.SetGuidToPropertyInObject(clonedMolecule.Buses, 'id');
        
        clonedMolecule.SaveProperty('buses', 'Cloning Events')
        .subscribe();
      }
      
      if (clonedMolecule.StyleMetadata && clonedMolecule.StyleMetadata.styles && clonedMolecule.StyleMetadata.styles.length > 0) {
        clonedMolecule.StyleMetadata.styles.forEach((styleId) => {
          this.thematicService.AssignStyleToRepresentativeMolecule(styleId, clonedMolecule.Id);
        });
      }
      
      this.eventsService.CreateEventsTable();
    });
  }
  
  RenameRepMolecule() {
    this.workAreaService.elementFocusedNewName = this.element.Properties.name;
    this.workAreaService.renameElementFocusedMenu = true;
    this.communicationService.Event.System.App.$RefreshUI.emit();
    
    setTimeout(() => {
      if (this.renameInput) {
        this.renameInput.nativeElement.focus();
        this.renameInput.nativeElement.select();
      }
    }, 200);
  }
  
  SaveRepMoleculeName(evt: any) {
    if (this.workAreaService.elementFocusedNewName === '' || this.workAreaService.elementFocusedNewName === this.element.Properties.name) {
      this.CancelRename();
      return;
    }
    
    this.element.Properties.name = this.workAreaService.elementFocusedNewName;
    this.propertiesService
    .SaveProperty(
      new PropertyVersioningDto({
        elementId: this.element.Id.toString(),
        property: 'name',
        value: this.workAreaService.elementFocusedNewName,
        path: 'properties',
        change: `Representative element renamed`,
        name: this.element.Properties.name,
      }),
    )
    .subscribe((response) => {
      // this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(null);
      this.communicationService.Event.Editor.Views.$RefreshViewsPanel.emit();
      this.communicationService.Event.Editor.EventsTree.$RefreshEventsTree.emit(null);
    });
    this.CancelRename();
  }
  
  CancelRename() {
    this.workAreaService.elementFocusedNewName = '';
    this.workAreaService.renameElementFocusedMenu = false;
    this.workAreaService.repMoleculeJustCreated = false;
  }
  
  EventsFlow(event: MouseEvent) {
    this.communicationService.Event.Editor.$WorkAreaDetectionDisableFor.emit(300);
    event.preventDefault();
    event.stopPropagation();
    
    const eventTransactionWindow = document.getElementsByClassName('events-transaction-container');
    
    if (eventTransactionWindow.length > 0) {
      this.communicationService.Event.Editor.$ConstructEventsFlow.emit({
        repMoleculeId: this.element.Id.toString(),
        viewId: null,
        eventName: null,
      });
    } else {
      this.draggableWindowService.OpenDraggableWindow(
        'Process Design (Beta Test)',
        DraggableWindowType.EventsTransaction,
        event,
        {
          repMoleculeId: this.element.Id,
        });
    }
  }
  
  MoleculeProperties(event: MouseEvent) {
    // debug
    this.toolsService.DragWindowConfig = this.editorStateService.WindowSize('ProcessPanel');
    console.log(this.toolsService.DragWindowConfig);
    this.toolsService.DragWindowConfig.x =
      this.toolsService.DragWindowConfig.x || (window.innerWidth - event.clientX < 400 ? window.innerWidth - 480 : event.clientX - 40);
    this.toolsService.DragWindowConfig.y = this.toolsService.DragWindowConfig.y || 60;
    console.log(this.element);
    
    this.workAreaService.SelectRepresentativeMolecule(this.element);
    const dragWindow = this.draggableWindowService.GenerateWindow(JsonTreeComponent, {
      title: '[DEBUG] Properties',
      data: null,
    });
    this.workAreaService.draggableWindow.push(dragWindow);
    
    dragWindow.Show();
  }
  
  MouseOver(hover: boolean) {
    if (this.workAreaService.editorPreferences.fixedContextMenu) {
      this.mouseOver = true;
    } else {
      this.mouseOver = hover;
    }
  }
  
  ngOnDestroy(): void {
    this.CancelRename();
    this.subscriptions.unsubscribe();
    this.workAreaService.renameElementFocusedMenu = false;
  }
  
  
}
