import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as wjcNav from '@grapecity/wijmo.nav';
import { debounceTime } from 'rxjs/operators';
import { ApiHierarchyService } from '../../core/services/api-hierarchy.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { HierarchyLevel } from '../../shared/models/hierarchylevel.model';

@Component({
  selector: 'app-hierarchy-tree',
  templateUrl: './hierarchy.component.html',
})
export class HierarchyComponent implements OnInit, AfterViewInit {
  dataSources: HierarchyLevel[];
  leftAreaSize = 30;
  contentAreaSize = 70;
  levelToChange: HierarchyLevel;
  sublevelToAdd: HierarchyLevel;
  @ViewChild('dataSourcesTree', { static: true }) dataSourcesTree: wjcNav.TreeView;
  @ViewChild('mainForm', { static: true }) propForm: NgForm;
  previousValues: any;
  companyId: number;
  
  constructor(private hierarchyService: ApiHierarchyService,
              private clientStorageService: ClientStorageService,
  ) {
    this.levelToChange = new HierarchyLevel();
    this.sublevelToAdd = new HierarchyLevel();
    this.dataSources = [];
    this.companyId = this.clientStorageService.getCompanyId();
  }
  
  ngOnInit(): void {
    this.hierarchyService.treeChanged.subscribe(tree => {
      this.dataSources = [];
      this.dataSources.push(tree);
      if (!this.levelToChange.id) {
        this.levelToChange = this.dataSources[0] as HierarchyLevel;
      }
      this.dataSourcesTree.loadTree();
    });
  }
  
  showPanel(): void {
    this.levelToChange = this.dataSourcesTree.selectedItem as HierarchyLevel;
  }
  
  addSubLevel(): void {
    this.levelToChange.nodes.push(this.sublevelToAdd);
    this.sublevelToAdd = new HierarchyLevel();
    this.dataSourcesTree.loadTree();
    this.hierarchyService.saveElement(this.levelToChange).subscribe(res => {
      Object.assign(this.levelToChange, res);
      this.hierarchyService.treeChanged.next(this.dataSources[0]);
    });
  }
  
  ngAfterViewInit() {
    this.previousValues = Object.assign({}, this.propForm.value);
    const self = this;
    const form = this.propForm.form;
    
    setTimeout(function() {
      Object.keys(form.controls).forEach(key => {
        form
        .get(key)
        .valueChanges.pipe(debounceTime(500))
        .subscribe(value => {
          if (form.dirty) {
            form.markAsPristine();
            self.hierarchyService
            .saveElement(self.levelToChange)
            .subscribe(res => {
              self.hierarchyService.treeChanged.next(self.dataSources[0]);
            });
          }
        });
      });
    }, 10);
  }
}
