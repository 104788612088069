import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-share-cobble-confirmation',
  templateUrl: './share-cobble-confirmation.component.html',
  styleUrls: ['./share-cobble-confirmation.component.scss'],
})
export class ShareCobbleConfirmationComponent implements OnInit {
  
  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  
  ngOnInit() {
  }
  
}
