import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Permissions } from '../../admin/models/permissions.enum';
import { BusService } from '../../core/molecular/services/bus.service';
import { ApiMoleculesService } from '../../core/services/api-molecules.service';
import { ApiPropertiesService } from '../../core/services/api-properties.service';
import { TemplateService } from '../../core/services/template.service';
import { UserMenuService } from '../../core/services/user-menu.service';
import { MoleculesType } from '../../shared/enums/molecules-type.enum';
import { CommunicationService } from '../../shared/services/communication.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-create-cobblet',
  templateUrl: './create-cobblet.component.html',
  styleUrls: ['./create-cobblet.component.scss'],
})
export class CreateCobbletComponent implements OnInit {
  @ViewChild('input', { static: true })
  input: ElementRef;
  cobbletName = '';
  disableSaveButton = false;
  loading = false;
  componentDestination = [
    {
      name: 'My Components Only',
    },
    {
      name: 'Standard Components',
    },
  ];
  
  destination = 'My Components Only';
  adminExtensionPermission = false;
  
  constructor(
    private workAreaService: WorkAreaService,
    private busService: BusService,
    private moleculeService: ApiMoleculesService,
    private templateService: TemplateService,
    private communicationService: CommunicationService,
    private propertiesService: ApiPropertiesService,
    public userMenuService: UserMenuService,
    private snackerService: SnackerService,
  ) {
  }
  
  ngOnInit() {
    this.adminExtensionPermission = this.userMenuService.checkPermission(Permissions.AdminApplications);
    setTimeout(() => {
      this.input.nativeElement.focus();
      this.input.nativeElement.select();
    }, 200);
  }
  
  SaveCobblet() {
    
    if (this.loading) {
      return;
    }
    
    this.loading = true;
    this.disableSaveButton = true;
    
    const componentMolecules = this.ObtainSelectedMolecules();
    
    this.propertiesService.VerifyCobbleName(this.cobbletName).subscribe((availability: boolean) => {
      if (availability) {
        console.log(this.destination);
        this.moleculeService.CreateComponent(
          this.cobbletName,
          componentMolecules,
          this.destination !== null && this.destination === 'Standard Components',
        ).subscribe(response => {
          this.loading = true;
          this.snackerService.ShowMessageOnBottom(`${ this.cobbletName } added to your Components library.`, 'library_add', null, true);
          
          this.workAreaService.draggableWindow[
          this.workAreaService.draggableWindow.length - 1
            ].Hide();
          this.communicationService.Event.Editor.AppsTree.$RefreshAppsTree.emit(null);
        });
      } else {
        this.loading = false;
        this.disableSaveButton = false;
        this.input.nativeElement.focus();
        this.input.nativeElement.select();
        this.snackerService.ShowMessageOnBottom('There is already a Component with this name.', 'repeat_one_on');
      }
    });
  }
  
  ObtainSelectedMolecules(): any[] {
    
    const workgroupIds = {};
    console.log(this.workAreaService.elementsSelected);
    
    this.workAreaService.elementsSelected.filter(es => es.Type !== MoleculesType.WorkGroup).forEach(elementSelected => {
      const parent = this.busService.Get(elementSelected.ParentId.toString());
      
      if (workgroupIds[parent.Id]) {
        workgroupIds[parent.Id].childrenIds.push(elementSelected.Id);
      } else {
        workgroupIds[parent.Id] = {
          parentId: parent.Id,
          childrenIds: [elementSelected.Id],
        };
      }
    });
    
    this.workAreaService.elementsSelected.filter(es => es.Type === MoleculesType.WorkGroup).forEach(wg => {
      if (workgroupIds[wg.Id]) {
      } else {
        workgroupIds[wg.Id] = {
          parentId: wg.Id,
          childrenIds: [],
        };
      }
    });
    const componentMolecules = [];
    
    Object.keys(workgroupIds).forEach(key => {
      componentMolecules.push(workgroupIds[key]);
    });
    
    console.log('componentMolecules', componentMolecules);
    return componentMolecules;
  }
}
