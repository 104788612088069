import { Injectable, NgZone } from '@angular/core';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcGridSheet from '@grapecity/wijmo.grid.sheet';
import * as wjcXlsx from '@grapecity/wijmo.xlsx';
import * as fileSaver from 'file-saver';
import { Observable, Subject } from 'rxjs';
import { ApiDataSourcesService } from '../core/services/api-data-sources.service';
import { ApiPropertiesService } from '../core/services/api-properties.service';
import { ToolsService } from '../core/services/tools.service';
import { DatasourceTypeId } from '../shared/enums/datasource-type-id.enum';
import { DatasourceType } from '../shared/enums/datasource-type.enum';
import { SheetChangeValue } from '../shared/models/sheet-change';
import { DataElement } from '../shared/representative-molecule/interfaces/data-element';
import { CobbleService } from '../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../shared/services/communication.service';
import { ConnectionStateService } from '../shared/services/connection-state.service';
import { SnackerService } from '../shared/services/snacker.service';
import { WorkAreaService } from '../workarea/workarea.service';
import { Constants } from './../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class SpreadsheetService {
  
  SpreadSheetRangeSelected: DataSource;
  dataSourceOpened = null;
  dataSourceTypeOpened = '';
  dataSourceIdOpened = 0;
  dataSourceDataOpened = null;
  editorDbMode = false;
  editorDbModeVisibility = true;
  productionDatasourceOpen = false;
  draggedSpreadsheetData: DataSource = null;
  isDragging = false;
  flexSheet: wjcGridSheet.FlexSheet;
  wName: string;
  sName: string;
  openedSheets: number[] = [];
  readOnlyFile = false;
  spreadSheetLoaded = false;
  sheetHash = {};
  changesToSave: SheetChangeValue[] = [];
  SheetNames = [];
  apiDataElementsOpened = {};
  initializeFlexSheetRetry = 15;
  private selectionFormatState = new Subject<any>();
  private initLoadStateSource = new Subject<boolean>();
  initLoadState = this.initLoadStateSource.asObservable();
  private savingChangesState = new Subject<boolean>();
  savingChanges = this.savingChangesState.asObservable();
  spreadsheetData: { dataSourceId: number; sheetIndex: number; filename: string; blob: any; production: boolean };
  
  constructor(
    public dataSourcesService: ApiDataSourcesService,
    private workAreaService: WorkAreaService,
    private snackerService: SnackerService,
    private cobbleService: CobbleService,
    private propertiesService: ApiPropertiesService,
    private toolsService: ToolsService,
    private connectionStateService: ConnectionStateService,
    private communicationService: CommunicationService,
    private angularZone: NgZone,
  ) {
  }
  
  setSavingChanges(state: boolean) {
    this.savingChangesState.next(state);
  }
  
  SetSheetHash(index: number) {
    if (this.flexSheet && !this.sheetHash[index]) {
      const sheetData = this.GetCellsData(index);
      this.sheetHash[index] = {
        initHash: sheetData.hash,
      };
    }
  }
  
  GetCellsData(
    index: number,
  ): {
    hash: number;
    data: any[];
  } {
    const cells = this.flexSheet.sheets[index].grid.cells;
    const cellsJson = [];
    let hash = '';
    
    for (let r = 0; r < cells.rows.length; r++) {
      for (let c = 0; c < cells.columns.length; c++) {
        const data = cells.getCellData(r, c, false);
        
        if (data) {
          hash += data;
          const cell = {};
          cell['data'] = data;
          cell['dataType'] = this.toolsService.GetValueDataTypeForSpreadsheet(data);
          cell['row'] = r + 1;
          cell['col'] = c + 1;
          cell['colBinding'] = cells.columns[c].binding;
          cell['style'] = this.flexSheet.selectedSheet._styledCells[
          r * cells.columns.length + c + ''
            ]
            ? this.flexSheet.selectedSheet._styledCells[
            r * cells.columns.length + c + ''
              ]
            : null;
          
          // if (cell['dataType'] === 'formula') {
          //   console.log(cell);
          // }
          cellsJson.push(cell);
        } else {
          // cells.setCellData(r, c, "", false, false);
        }
      }
    }
    
    return {
      hash: this.toolsService.GenerateHashCode(hash),
      data: cellsJson,
    };
  }
  
  AddSheet(sheetName: string, index: number) {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    this.setSavingChanges(true);
    
    this.dataSourcesService.AddDataSourceSheet(sheetName, index).subscribe((result) => {
      this.setSavingChanges(false);
    });
  }
  
  RemoveSheet(sheetIndex: number) {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    this.setSavingChanges(true);
    this.dataSourcesService.RemoveDataSourceSheet(sheetIndex).subscribe((result) => {
      if (!result) {
        this.snackerService.ShowMessageOnBottom('The Sheet is being used and can not be deleted', 'block');
      }
      
      this.ReloadDataSourceOnSpreadSheetPanel();
      this.setSavingChanges(false);
    });
  }
  
  DownloadDataSourceFile(dataSourceId: number, production = this.productionDatasourceOpen) {
    const path = `${ Constants.Environment.apiUrl }DataSources/Download/${ dataSourceId }/published/${ production }`;
    this.dataSourcesService.GetDatasourceInfo(dataSourceId).subscribe(info => {
      
      // if (info.name) {
      //   this.snackBar.open('Downloading...', '', {
      //     duration: 3000
      //   });
      // }
      
      this.dataSourcesService.GetDatsourceFileForDownload(path).subscribe(file => {
        console.log(file);
        fileSaver.saveAs(file, info.name + `${ production ? ' - Published' : ' - Development' }`);
      });
    });
  }
  
  openExcelFile(file, sheetIndex: number = 0) {
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoaded.emit(false);
    this.communicationService.Event.System.App.$RefreshUI.emit();
    if (this.flexSheet) {
      this.initializeFlexSheetRetry = 15;
      this.spreadSheetLoaded = false;
      try {
        this.flexSheet.loadAsync(file, (wbook) => {
          //console.timeEnd('sheet');
          console.log('--- SPREADSHEET LOADED ---');
          // this.flexSheet.select(new CellRange(1, 1), false);
          
          this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoading.emit(false);
          this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoaded.emit(true);
          this.openedSheets = [];
          this.sheetHash = {};
          this.sName = this.flexSheet.selectedSheet.name.replace('&amp;', '&');
          this.dataSourcesService.openedDataSourceSheetIndex = this.flexSheet.selectedSheetIndex;
          this.openedSheets = [this.flexSheet.selectedSheetIndex];
          // console.log('sheet index', sheetIndex);
          
          this.flexSheet.columns.forEach(column => {
            column.multiLine = true;
          });
          
          this.flexSheet.selectedSheetIndex = sheetIndex;
          this.SetSheetHash(this.flexSheet.selectedSheetIndex);
          const rowQuantity = 100;
          const columnQuantity = 30;
          
          // setTimeout(() => {
          //   // this.flexSheet.sheets.forEach((sheet, index) => {
          //   //   if (index > 0) {
          //   //     for (
          //   //       let row = this.flexSheet.sheets[1].grid.rows.length;
          //   //       row < rowQuantity;
          //   //       row++
          //   //     ) {
          //   //       sheet.grid.rows.splice(row, 0, new wjcGrid.Row());
          //   //     }
          //   //
          //   //     for (
          //   //       let col = this.flexSheet.sheets[1].grid.columns.length;
          //   //       col < columnQuantity;
          //   //       col++
          //   //     ) {
          //   //       sheet.grid.columns.splice(col, 0, new wjcGrid.Column());
          //   //     }
          //   //   }
          //   // });
          //   this.flexSheet.refreshCells(true);
          // }, 6000);
        }, (error) => {
          console.log(error);
          
          if (this.initializeFlexSheetRetry > 0) {
            this.initializeFlexSheetRetry--;
            this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoading.emit(true);
            this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoaded.emit(false);
            this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
            this.communicationService.Event.System.App.$RefreshUI.emit();
            setTimeout(() => {
              this.openExcelFile(file, sheetIndex);
            }, 300);
          } else {
            this.initializeFlexSheetRetry = 15;
            this.snackerService.ShowMessageOnBottom('Error loading Spreadsheet', 'warning');
            this.communicationService.Event.Editor.DataSource.Spreadsheet.$CloseSpreadsheetPanel.emit(true);
          }
        });
      } catch (e) {
      }
    } else {
      if (this.initializeFlexSheetRetry > 0) {
        this.initializeFlexSheetRetry--;
        this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoading.emit(true);
        this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoaded.emit(false);
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
        this.communicationService.Event.System.App.$RefreshUI.emit();
        setTimeout(() => {
          this.openExcelFile(file, sheetIndex);
        }, 300);
      } else {
        this.initializeFlexSheetRetry = 15;
        this.communicationService.Event.Editor.DataSource.Spreadsheet.$CloseSpreadsheetPanel.emit(true);
        this.snackerService.ShowMessageOnBottom('Error opening Datasource', 'warning');
      }
    }
  }
  
  UpdateSheetNames() {
    this.SheetNames = [];
    this.flexSheet.sheets.forEach((sheet) => {
      this.SheetNames.push(sheet);
    });
  }
  
  SpreadSheetContainsUnsavedChanges(): boolean {
    if (this.dataSourceTypeOpened !== DatasourceType.Spreadsheet) {
      return false;
    }
    
    const cellsData = this.GetCellsData(this.flexSheet.selectedSheetIndex);
    if (cellsData && this.sheetHash[this.flexSheet.selectedSheetIndex]) {
      return (
        this.dataSourcesService.openedDataSourceId > 0 &&
        cellsData.hash !==
        this.sheetHash[this.flexSheet.selectedSheetIndex].initHash
      );
    } else {
      return false;
    }
  }
  
  ReloadProductionDataSourceOnSpreadSheetPanel() {
    if (this.dataSourcesService.openedDataSourceId > 0) {
      this.LoadDataSource(
        this.dataSourcesService.openedDataSourceId,
        this.wName,
        0,
        true,
      );
    }
  }
  
  ReloadDataSourceOnSpreadSheetPanel() {
    if (this.dataSourcesService.openedDataSourceId > 0) {
      this.LoadDataSource(
        this.dataSourcesService.openedDataSourceId,
        this.wName,
        0,
        false,
      );
    }
  }
  
  ObtainDatasourceInfo(datasourceId: number = this.dataSourceIdOpened) {
    if (this.dataSourcesService.datasourcesTreeCache) {
      const spreadsheets = this.dataSourcesService.datasourcesTreeCache.nodes.find(n => n.dataSourceType === DatasourceTypeId.Spreadsheet);
      
      if (spreadsheets) {
        return spreadsheets.nodes.find(s => s.dataSourceId === datasourceId);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  
  LoadDataSource(
    dataSourceId: number,
    filename: string,
    sheetIndex,
    production: boolean = false,
    blob = null,
    openSpreadsheetPanel: boolean = true
  ) {
    
    console.log(blob);
    
    if (dataSourceId === null) {
      return;
    }
    
    this.spreadsheetData = { dataSourceId, filename, sheetIndex, production, blob };
    
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoading.emit(true);
    this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoaded.emit(false);
    this.editorDbModeVisibility = true;
    this.editorDbMode = false;
    this.dataSourceTypeOpened = DatasourceType.Spreadsheet;
    
    if (openSpreadsheetPanel) {
      this.communicationService.Event.Editor.DataSource.$dataSourceLoaded.emit(true);
    }
    
    this.clearSheetChanges();
    
    if (blob) {
      // console.log('blob received', blob);
      
      this.loadDatasourceFile(
        filename,
        dataSourceId,
        sheetIndex,
        new File([blob], filename, {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );
    } else {
      if (dataSourceId) {
        this.productionDatasourceOpen = production;
        // console.log('loading ds');
        this.dataSourcesService.GetDatasourceFile(dataSourceId, production).subscribe((_blob) => {
          this.loadDatasourceFile(
            filename,
            dataSourceId,
            sheetIndex,
            new File([_blob], filename, {
              type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          );
        }, (error: any) => {
          this.snackerService.ShowMessageOnBottom('Error obtaining DataSource.', 'warning');
          this.communicationService.Event.Editor.DataSource.Spreadsheet.$CloseSpreadsheetPanel.emit(true);
        });
      }
    }
  }
  
  addNewChange(newChange: SheetChangeValue) {
    const changeExistIdx = this.changesToSave.findIndex((change) => {
      return (
        change.range === newChange.range &&
        change.sheetIndex === newChange.sheetIndex
      );
    });
    if (changeExistIdx > -1) {
      this.changesToSave[changeExistIdx].value = newChange.value;
    } else {
      this.changesToSave.push(newChange);
    }
  }
  
  clearSheetChanges() {
    this.changesToSave = [];
  }
  
  refreshOpenedSpreadsheet() {
    const id = this.dataSourcesService.openedDataSourceId;
    if (id === -1) {
      this.snackerService.ShowMessageOnBottom('Please, load a Data Source.');
      return;
    }
    this.dataSourcesService.getById(id).subscribe((result) => {
      const data = JSON.parse(result.data);
      this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoading.emit(true);
      this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoaded.emit(false);
      this.dataSourcesService.openedDataSourceId = id;
      this.wName = result.name;
      this.workAreaService.openExcelDataSource(-1, JSON.parse(result.data));
    });
  }
  
  loadExcelDataSourceAndOpenExcelFile(id: number) {
    if (id) {
      this.dataSourcesService.GetDatasourceFile(id).subscribe((res) => {
        this.dataSourcesService.openedDataSourceId = id;
        const file = new File([res], this.wName, {
          type: 'application/vnd.ms-excel',
        });
        this.openExcelFile(file);
      }, (error: any) => {
        
        this.snackerService.ShowMessageOnBottom('Error obtaining DataSource.', 'warning');
        
        
        this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoading.emit(false);
        this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoaded.emit(false);
      });
    } else {
      this.communicationService.Event.Editor.DataSource.Spreadsheet.$CloseSpreadsheetPanel.emit(true);
    }
  }
  
  updateOpenedSpreadsheet(datasourceData: any) {
    console.log('update spreadsheet');
    const selectedSheetIndex = this.flexSheet.selectedSheetIndex;
    
    datasourceData.collections.forEach((collection) => {
      let sheetIndex = null;
      
      this.flexSheet.sheets.forEach((sheet, index) => {
        if (sheet.name === collection.sheetName) {
          sheetIndex = index;
        }
      });
      
      if (sheetIndex >= 0) {
        this.flexSheet.selectedSheetIndex = sheetIndex;
        
        // delete
        if (datasourceData.action === 3) {
          if (datasourceData.operationType === 'col') {
            collection.indexes.forEach((columnIndex) => {
              this.flexSheet.deleteColumns(columnIndex - 1);
            });
          } else {
            collection.indexes.forEach((columnIndex) => {
              this.flexSheet.deleteRows(columnIndex - 1);
            });
          }
        } else {
          collection.dataCells.forEach((dt) => {
            // console.log('update value: ', dt.row - 1, dt.col - 1, dt.value);
            this.flexSheet.setCellData(dt.row - 1, dt.col - 1, dt.value, true);
            
            // add
            if (datasourceData.action === 1) {
              const style =
                datasourceData.operationType === 'col'
                  ? this.flexSheet.sheets[sheetIndex].getCellStyle(
                    dt.row - 1,
                    dt.col - 2,
                  )
                  : this.flexSheet.sheets[sheetIndex].getCellStyle(
                    dt.row - 2,
                    dt.col - 1,
                  );
              
              if (style) {
                this.flexSheet.applyCellsStyle(style, [
                  new wjcGrid.CellRange(
                    dt.row - 1,
                    dt.col - 1,
                    dt.row - 1,
                    dt.col - 1,
                  ),
                ]);
              }
            }
          });
        }
      }
    });
    
    this.flexSheet.selectedSheetIndex = selectedSheetIndex;
  }
  
  // private generateSheet(s, data?: SpreadsheetDataItem[]) {
  //   const sheet: wjcXlsx.WorkSheet = new wjcXlsx.WorkSheet();
  //   sheet.name = s.name;
  //   sheet.summaryBelow = s.summaryBelow;
  //   sheet.visible = s.visible;
  //   // add columns
  //   s.columns.forEach(c => {
  //     sheet.columns.push(c);
  //   });
  //   // add rows
  //   s.rows.forEach(r => {
  //     sheet.rows.push(r);
  //   });
  //   // add table names
  //   s.tableNames.forEach(t => {
  //     sheet.tableNames.push(t);
  //   });
  //   // insert data
  //   if (data) {
  //     const lastColIdx = this.getLastColumnIdx(sheet);
  //     if (!sheet.columns[lastColIdx]) {
  //       sheet.columns.push(_.cloneDeep(sheet.columns[lastColIdx - 1]));
  //     } else {
  //       sheet.columns.splice(
  //         lastColIdx,
  //         1,
  //         _.cloneDeep(sheet.columns[lastColIdx - 1])
  //       );
  //     }
  //     if (!sheet.rows[0].cells[lastColIdx]) {
  //       sheet.rows.forEach(row => {
  //         const cell = _.cloneDeep(row.cells[lastColIdx - 1]);
  //         cell.value = null;
  //         row.cells.push(cell);
  //       });
  //     }
  //     data.forEach(dataItem => {
  //       sheet.rows[dataItem.row].cells[lastColIdx].value = dataItem.value;
  //     });
  //   }
  //   return sheet;
  // }
  
  clearFlexSheet() {
    
    if (this.flexSheet) {
      this.flexSheet.clear();
      for (let index = 0; index < this.flexSheet.sheets.length; index++) {
        this.flexSheet.sheets.removeAt(index);
      }
      this.flexSheet.refresh();
    }
  }
  
  setState(state: any) {
    this.selectionFormatState.next(state);
  }
  
  getState(): Observable<any> {
    return this.selectionFormatState.asObservable();
  }
  
  // openSheet(bookData): Observable<boolean> {
  //   const wBook: wjcXlsx.Workbook = new wjcXlsx.Workbook();
  //   const bookdata = bookData.book;
  //   const sheetIdx = bookData.sheetIdx;
  //   let isLoaded = true;
  
  //   this.clearFlexSheet();
  
  //   wBook.activeWorksheet = bookdata.book.activeWorksheet;
  //   wBook.created = bookdata.book.created;
  //   wBook.lastModifiedBy = bookdata.book.lastModifiedBy;
  
  //   bookdata.book.styles.forEach(s => {
  //     wBook.styles.push(s);
  //   });
  //   bookdata.book.definedNames.forEach(dn => {
  //     wBook.definedNames.push(dn);
  //   });
  //   bookdata.book.tables.forEach(t => {
  //     wBook.tables.push(t);
  //   });
  //   bookdata.book.colorThemes.forEach(ct => {
  //     wBook.colorThemes.push(ct);
  //   });
  //   if (sheetIdx > -1) {
  //     wBook.sheets.push(this.generateSheet(bookdata.book.sheets[sheetIdx]));
  //   } else {
  //     bookdata.book.sheets.forEach(s => {
  //       wBook.sheets.push(this.generateSheet(s));
  //     });
  //   }
  //   this.flexSheet.loadAsync(wBook, wbook => {
  //     this.flexSheet.refresh();
  //     isLoaded = true;
  //   });
  //   return observableOf(isLoaded);
  // }
  
  resetLoadState() {
    this.initLoadStateSource.next(true);
  }
  
  DeleteDataSourceCollection(data: any[]) {
    data.forEach((ds) => {
      ds.cobbleId = this.cobbleService.Cobble.id;
    });
    this.dataSourcesService.deleteDataSourceCollection(data).subscribe();
  }
  
  private downloadFileInSeparateTab(filePath) {
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.setAttribute('style', 'display: none');
    link.setAttribute('target', '_blank');
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    
    link.click();
    window.URL.revokeObjectURL(filePath);
    link.remove();
  }
  
  private loadDatasourceFile(
    name: string,
    datasourceId: number,
    sheetIndex: number,
    file: any,
  ) {
    this.dataSourcesService.openedDataSourceId = datasourceId;
    this.dataSourceIdOpened = datasourceId;
    this.dataSourcesService.openedDataSourceSheetIndex = sheetIndex;
    this.wName = name;
    this.sName = name;
    this.openExcelFile(file, sheetIndex);
  }
  
  private getLastColumnIdx(sheet: wjcXlsx.WorkSheet) {
    const rows = sheet.rows;
    const cols = sheet.columns;
    let haveData = false;
    let idx = -1;
    for (let c = 0; c < cols.length; c++) {
      for (let r = 0; r < rows.length; r++) {
        if (!rows[r].cells[c]) {
          idx = c;
          return c;
        }
        if (rows[r].cells[c] && rows[r].cells[c].value) {
          haveData = true;
          break;
        }
      }
      if (!haveData) {
        idx = c;
        break;
      }
      haveData = false;
    }
    if (idx === -1) {
      idx = cols.length;
    }
    return idx;
  }
  
  private getSelColLayout(flex) {
    // const colLayout = JSON.parse(flex.columnLayout);
    // colLayout.columns.forEach(col => {
    //   if (flex.selection.containsColumn(flex.getColumn(col.name).index)) {
    //     col['visible'] = true;
    //   } else {
    //     col['visible'] = false;
    //   }
    // });
    // return colLayout;
    const colLayout = { columns: [] };
    
    for (let j = flex.selection.leftCol; j <= flex.selection.rightCol; j++) {
      const tempLayout = {};
      // get required col info
      tempLayout['width'] = flex.columns[j].width;
      tempLayout['isRequired'] = flex.columns[j].isRequired;
      tempLayout['binding'] = '' + j;
      tempLayout['header'] = String.fromCharCode(65 + j);
      let format;
      // format=flex.columns[j].format;
      for (let i = flex.selection.topRow; i <= flex.selection.bottomRow; i++) {
        const cellNum = flex.columns.length * i + j;
        if (
          flex.selectedSheet['_styledCells']['' + cellNum] &&
          flex.selectedSheet['_styledCells']['' + cellNum].format
        ) {
          format = flex.selectedSheet['_styledCells']['' + cellNum].format;
          break;
        }
      }
      
      tempLayout['format'] = format;
      colLayout.columns.push(tempLayout);
    }
    
    return colLayout;
  }
  
  private updateCollectionItems(
    collection: any,
    firstRowIdx: number,
    finalRowIdx: number,
    firstColIdx: number,
    finalColIdx: number,
  ) {
    for (let colIdx = firstColIdx + 1; colIdx <= finalColIdx; colIdx++) {
      const cell = collection.dataItems.find(
        (_cell) => _cell.row === firstRowIdx && _cell.col === colIdx,
      );
      
      if (cell) {
        cell.value = this.flexSheet.getCellValue(firstRowIdx, colIdx);
      } else {
        const newCell = {
          row: firstRowIdx,
          col: colIdx,
          value: this.flexSheet.getCellValue(firstRowIdx, colIdx),
        };
        
        collection.dataItems.push(newCell);
      }
    }
    
    for (let rowIdx = firstRowIdx; rowIdx <= finalRowIdx; rowIdx++) {
      for (let colIdx = firstColIdx; colIdx <= finalColIdx; colIdx++) {
        const cell = collection.dataItems.find(
          (_cell) => _cell.row === rowIdx && _cell.col === colIdx,
        );
        
        if (cell) {
          cell.value = this.flexSheet.getCellValue(rowIdx, colIdx);
        } else {
          const newCell = {
            row: rowIdx,
            col: colIdx,
            value: this.flexSheet.getCellValue(rowIdx, colIdx),
          };
          
          collection.dataItems.push(newCell);
        }
      }
    }
  }
}

export class DataSource {
  dataSourceType: string;
  dataSourceTypeName: string;
  dataSourceName: string;
  collection: string;
  range: string;
  dataSourceId: number;
  firstColIndex: number;
  firstRowIndex: number;
  lastRowIndex: number;
  lastColIndex: number;
  baseZero: boolean;
  isTemplate: boolean;
  cols: number;
  rows: number;
  dataItems: SpreadsheetDataItem[];
  context: string;
  dataElement: DataElement;
}

export class SpreadsheetDataItem {
  row: number;
  col: number;
  value: any;
}
