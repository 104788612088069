import { Pipe, PipeTransform } from '@angular/core';
import { RepresentativeMolecule } from '../../shared/representative-molecule/interfaces/representative-molecule';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { WorkAreaService } from '../workarea.service';

@Pipe({
  name: 'viewMoleculesVisiblePipe',
})
export class ViewMoleculesVisiblePipe implements PipeTransform {
  constructor(
    private workAreaService: WorkAreaService,
    cobbleService: CobbleService,
  ) {
  }
  
  transform(repMolecules: RepresentativeMolecule[]): RepresentativeMolecule[] {
    return repMolecules.filter(
      molecule =>
        this.workAreaService.actualEditorViews
        .map(v => v.id)
        .includes(molecule.Properties.view) ||
        molecule.Properties.dragEnabled === false,
    );
  }
}
