import { Constants } from '../../../shared/constants';
import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class NavigateViewMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    data: any,
    dataElementsData: any,
  ) {
    try {
      console.log(rule);
      let viewId = rule.view || this.toolsService.ExtractValuesByType(data).string || 0;
      
      if (dataElementsData) {
        viewId =
          this.toolsService.ExtractValuesByType(dataElementsData).string ||
          rule.view || Constants.Defaults.View;
      }
      
      const view = this.cobbleService.Cobble.properties.views.find(v => v.id === viewId);
      
      if (view) {
        history.pushState(null, null, window.location.href);
        this.communicationService.Event.Runtime.System.$ViewIdChanged.emit(viewId);
      }
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
