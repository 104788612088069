import { Constants } from '../../../shared/constants';
import { DatasourceType } from '../../../shared/enums/datasource-type.enum';
import {
  IRepresentativeMolecule,
} from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class AddToDatasourceMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(particleId: string, repMoleculeId: string, busProcessorKey: string, rule: any, logic: any, data?: any) {
    try {
      const dataItems = [];
      
      if (this.ExistsRepMoleculeAttached) {
        data = this.RepMoleculeAttached.GetValue;
      }
      
      console.log('add');
      data = data === undefined || data === null || data === {} ? '' : data;
      
      // const allDataElementsAreLocalStore = this.DataElements.length > 0 && !(!!this.DataElements.find(de => de.DataSourceType !== DatasourceType.Custom));
      
      switch (this.toolsService.GetObjectType(data)) {
        case 'object':
          // console.log('object');
          this.DataElements.forEach(de => {
            dataItems.push(this.CreateDataItem(de, repMoleculeId, data.value || data));
          });
          break;
        case 'array':
          // console.log('array');
          const repMolecule = this.busService.Get(repMoleculeId);
          const bus = repMolecule.GetBusByParticleId(particleId);
          
          if (this.DataElements.length === 1) {
            if (this.DataElements[0].DataSourceType === DatasourceType.LocalDataStore) {
              dataItems.push(this.CreateDataItem(this.DataElements[0], repMoleculeId, data));
            } else {
              dataItems.push(
                this.CreateDataItem(this.DataElements[0], repMoleculeId,
                  data.length === 1 ? data[0].value || '' : data.map(d => d.value)),
              );
            }
          }
          // manage arrays to be aligned and send to other data sources
          else if (this.DataElements.length > 1) {
            // region ORDERING
            const groupedData = this.toolsService.GroupBy(data, v => {
              const breakedContext = this.toolsService.BreakContext(v.context);
              
              if (breakedContext.dataSourceType === DatasourceType.Spreadsheet) {
                return breakedContext.col;
              } else {
                const spplitedContext = breakedContext.context.split(Constants.ContextSeparator);
                return spplitedContext[spplitedContext.length - 1];
              }
            });
            
            let orderedData = [];
            
            const takeDataElementMolecule = bus.GetActionMoleculeParticle(
              'FilterByDataElementReferenceMolecule');
            
            if (takeDataElementMolecule) {
              const dataElementsOrder = takeDataElementMolecule.DataElements;
              dataElementsOrder.forEach(deo => {
                const spplitedContext = deo.Context.split(Constants.ContextSeparator);
                const deoName = spplitedContext[spplitedContext.length - 1];
                orderedData.push(groupedData.get(deoName));
              });
            } else {
              orderedData = Array.from(groupedData)
              .map(d => d[1]);
            }
            
            // endregion
            
            console.log(orderedData);
            
            this.DataElements.forEach((de, index) => {
              const dataElements = orderedData.length === 1 ? orderedData[0] : orderedData[index];
              
              if (dataElements) {
                if (de.DataSourceType === DatasourceType.LocalDataStore) {
                  dataItems.push(this.CreateDataItem(de, repMoleculeId, dataElements));
                } else {
                  dataItems.push(
                    this.CreateDataItem(de, repMoleculeId,
                      dataElements.length === 1 ? dataElements[0].value || '' : dataElements.map(
                        d => d.value) || ''),
                  );
                }
              } else {
                dataItems.push(this.CreateDataItem(de, repMoleculeId, null));
              }
            });
          }
          break;
        case 'number':
        case 'string':
          // console.log('array');
          this.DataElements.forEach(de => {
            dataItems.push(this.CreateDataItem(de, repMoleculeId, data));
          });
          break;
        case 'filelist':
          this.DataElements.forEach(de => {
            dataItems.push(this.CreateDataItem(de, repMoleculeId, data));
          });
          
          break;
        default:
          break;
      }
      
      const subscription = this.communicationService.Event.Runtime.Data.$ModifyDataResult.subscribe(
        response => {
          // console.log('$ModifyDataResult');
          console.log('=event=');
          console.log(response);
          if (response.keys && response.keys.includes(repMoleculeId)) {
            subscription.unsubscribe();
            
            let responseDataElements = response.dataElements;
            
            //if add to local data store return same dataelements
            if (this.DataElements.length === 1 && this.DataElements[0].DataSourceType === DatasourceType.LocalDataStore) {
              responseDataElements = data;
            }
            
            this.Done(particleId, busProcessorKey, repMoleculeId, responseDataElements);
          }
        });
      console.log('add', this.EventKey);
      
      this.dataSourcesService.AddDataItemsToDataSource(dataItems, repMoleculeId,
        this.busService.Get(repMoleculeId), this.EventKey);
    } catch (error) {
      console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
