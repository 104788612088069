import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'breakCamelCase',
})
export class BreakCamelCasePipe implements PipeTransform {
  public transform(input: string): string {
    if (!input) {
      return '';
    } else {
      return input.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    }
  }
}
