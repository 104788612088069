import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiPropertiesService } from '../core/services/api-properties.service';
import { WorkAreaService } from './../workarea/workarea.service';

@Injectable()
export class UnsavedDataGuard implements CanActivate {
  constructor(
    private workareaService: WorkAreaService,
    private propertiesService: ApiPropertiesService,
  ) {
  }
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    // console.log('SavingInProcess', this.propertiesService.SavingInProcess());
    if (!this.propertiesService.SavingInProcess()) {
      return true;
    }
    
    if (window.confirm('Changes you made may not be saved.')) {
      this.propertiesService.CancelSaving();
      return true;
    } else {
      this.workareaService.ShowUnsavedDataWindow();
      return false;
    }
  }
}
