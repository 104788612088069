import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth-guard.service';
import { HierarchyComponent } from './hierarchy/hierarchy.component';
import { WelcomeComponent } from './welcome.component';

const welcomeRoutes: Routes = [
  {
    path: '',
    component: WelcomeComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'welcome/hierarchy', component: HierarchyComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(welcomeRoutes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})

export class WelcomeRoutingModule {
}
