/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ADALAuthTokenInterceptor } from './adal-token-interceptor';
import { AuthInterceptor } from './auth-interceptor';
import { BackgroundTaskInterceptor } from './background-task-interceptor';
import { ClearRequestBodyInterceptor } from './clear-request-body-interceptor';
import { HttpHttpsInterceptor } from './http-https-interceptor';
import { LeapxlMetadataInterceptor } from './leapxl-metadata-interceptor';
import { LoaderInterceptor } from './loader-interceptor';
import { ProfilerInterceptor } from './profiler-interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpHttpsInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ADALAuthTokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ProfilerInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ClearRequestBodyInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LeapxlMetadataInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: BackgroundTaskInterceptor, multi: true },
];
