import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';
import { ApiDataService } from './api-data.service';

@Injectable({
  providedIn: 'root',
})
export class ApiLeapxlImportService extends ApiDataService {
  
  constructor(
    http: HttpClient,
    errorMessengerService: ErrorMessengerService,
    protected cobbleService: CobbleService,
  ) {
    super('import', http, errorMessengerService);
  }
  
  ImportApplication(dataFile: any, encryptPassword: string, importPassword: string, name: string, extension: string, applicationName: string, dsChanges: { spreadSheetsData: any[], stylesData: any[] } = null) {
    
    const formData = this.ExportDataToFormData(dataFile, encryptPassword, importPassword, name, extension, applicationName, dsChanges);
    // console.log(formData);
    
    return this.http.post(
      this.apiEndpointUrl + '/Application/', formData
      ,
      this.AddBackgroundTask('Importing Application', 'publish', {
        reportProgress: true,
        observe: 'events',
      })
      ,
    ).pipe(
      map((events) => {
        return events;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error importing app`,
        );
        console.error('Error importing app', err);
        return of(err);
      }),
    );
  }
  
  ImportApplicationSpreadsheetData(dataFile: any, encryptPassword: string, importPassword: string, name: string, extension: string, applicationName: string) {
    
    const formData = this.ExportDataToFormData(dataFile, encryptPassword, importPassword, name, extension, applicationName);
    // console.log(formData);
    
    return this.http.post(
      this.apiEndpointUrl + '/ImportData/', formData
      ,
      {
        reportProgress: true,
        observe: 'events',
      },
    ).pipe(
      map((events) => {
        return events;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error importing app`,
        );
        console.error('Error importing app', err);
        return of(err);
      }),
    );
  }
  
  private ExportDataToFormData(dataFile: any, encryptPassword: string, importPassword: string, name: string, extension: string, applicationName: string, dsChanges: { spreadSheetsData: any[], stylesData: any[] } = null): FormData {
    const file: File = dataFile;
    const formData = new FormData();
    
    formData.append('applicationVersion', this.cobbleService.Cobble.version);
    formData.append('leapxlVersion', this.cobbleService.leapxlVersion);
    formData.append('url', this.cobbleService.systemInformation.referrer);
    formData.append('platform', this.cobbleService.systemInformation.browserPlatform);
    formData.append('browser', `${ this.cobbleService.systemInformation.browserEngine } ${ this.cobbleService.systemInformation.clientInformation.vendor }`);
    formData.append('encryptPassword', encryptPassword);
    formData.append('importPassword', importPassword);
    formData.append('name', name);
    formData.append('extension', extension);
    formData.append('newAppName', applicationName);
    formData.append('dataFile', file, file.name);
    
    if (dsChanges) {
      if (dsChanges.spreadSheetsData) {
        formData.append('spreadSheetsData', JSON.stringify(dsChanges.spreadSheetsData));
      }
      if (dsChanges.stylesData) {
        formData.append('stylesData', JSON.stringify(dsChanges.stylesData));
      }
    }
    
    return formData;
  }
  
}
