import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { TreeNode } from 'angular-tree-component';
import { fromEvent, Subscription } from 'rxjs';
import { ApiMoleculesService } from '../../core/services/api-molecules.service';
import { AutoGenerationService } from '../../core/services/autogeneration.service';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { AutoGenDEBehavior } from '../../shared/enums/autogen-DEBehavior.enum';
import { AutoGenModelOption } from '../../shared/enums/autogen-model-options.enum';
import { AutoGenModel } from '../../shared/enums/autogen-model.enum';
import { AutoGenSubject } from '../../shared/enums/autogen-subject.enum';
import { AutoGenType } from '../../shared/enums/autogen-type.enum';
import { Orientation } from '../../shared/enums/orientation.enum';
import { Placeholders } from '../../shared/enums/placeholders.enum';
import { RepresentativeMoleculesType } from '../../shared/enums/representative-molecules-types.enum';
import { DataElement } from '../../shared/representative-molecule/interfaces/data-element';
import { CommunicationService } from '../../shared/services/communication.service';
import { AutoGenAction } from '../interfaces/autogen-action.interface';
import { AutoGenUIOptions } from '../interfaces/autogen-ui-options.interface';

@Component({
  selector: 'generation-app-action',
  templateUrl: './generation-app-action.component.html',
  styleUrls: ['./generation-app-action.component.scss'],
})
export class GenerationAppActionComponent implements OnInit, OnDestroy {
  @Input() nodes: TreeNode[];
  @Input() order: number;
  @Input() processId: number;
  @Input() actionObj: any;
  @Input() isLastActionInProcess: boolean = false;
  @Output() action = new EventEmitter<AutoGenAction>();
  @Output() remove = new EventEmitter<number>();
  selectedNodes: TreeNode[] = [];
  typeSelected: AutoGenType;
  subjectSelected: AutoGenSubject;
  optionSelected: AutoGenDEBehavior | null = null;
  uiOptionModelSelected: AutoGenModelOption | null = null;
  uiOptionOrientationSelected: Orientation | null = Orientation.Vertical;
  uiOptionPadding = 7;
  uiOptionSeparation = 4;
  uiOptionAddons: {
    header: boolean
    autoStart: boolean
    hidden: boolean
    readOnly: boolean
  } = {
    header: true,
    autoStart: false,
    hidden: false,
    readOnly: false,
  };
  uiOptionColumns = 1;
  modelSelected: {
    modelType: AutoGenModel,
    name: string,
  };
  repMoleculeSelected: RepresentativeMoleculesType = null;
  dataElements: DataElement[] = [];
  id: number;
  itemData: {
    items: any[],
    itemType: any,
    itemLabels: string[],
  };
  defaultOptions: {
    type: AutoGenType,
    subject: AutoGenSubject,
    entity: RepresentativeMoleculesType,
    option: AutoGenDEBehavior
  }[] = [
    {
      type: AutoGenType.Create,
      subject: AutoGenSubject.Form,
      entity: null,
      option: AutoGenDEBehavior.RepMolSplit,
    },
    {
      type: AutoGenType.Populate,
      subject: AutoGenSubject.RepMolecule,
      entity: RepresentativeMoleculesType.Table,
      option: AutoGenDEBehavior.Join,
    },
    {
      type: AutoGenType.Create,
      subject: AutoGenSubject.RepMolecule,
      entity: RepresentativeMoleculesType.Table,
      option: AutoGenDEBehavior.Join,
    },
    {
      type: AutoGenType.Populate,
      subject: AutoGenSubject.Form,
      entity: null,
      option: AutoGenDEBehavior.RepMolSplit,
    },
  ];
  disabledSubjects: { type: AutoGenType, subjectsToDisable: AutoGenSubject[] }[] = [
    { type: AutoGenType.Create, subjectsToDisable: [AutoGenSubject.Action, AutoGenSubject.Actionable] },
    { type: AutoGenType.Add, subjectsToDisable: [AutoGenSubject.Action, AutoGenSubject.Actionable] },
    { type: AutoGenType.Store, subjectsToDisable: [AutoGenSubject.Action, AutoGenSubject.Actionable] },
    {
      type: AutoGenType.Populate,
      subjectsToDisable: [AutoGenSubject.Action, AutoGenSubject.Component, AutoGenSubject.Process,
        AutoGenSubject.View,
        AutoGenSubject.Store],
    },
    {
      type: AutoGenType.Show,
      subjectsToDisable: [AutoGenSubject.Action, AutoGenSubject.Component, AutoGenSubject.Process,
        AutoGenSubject.View,
        AutoGenSubject.Store],
    },
    {
      type: AutoGenType.Hide,
      subjectsToDisable: [AutoGenSubject.Action, AutoGenSubject.Component, AutoGenSubject.Process,
        AutoGenSubject.View,
        AutoGenSubject.Store],
    },
    {
      type: AutoGenType.Value,
      subjectsToDisable: [AutoGenSubject.Action, AutoGenSubject.Component, AutoGenSubject.Process,
        AutoGenSubject.View,
        AutoGenSubject.Store],
    },
    {
      type: AutoGenType.Navigate,
      subjectsToDisable: [AutoGenSubject.Action, AutoGenSubject.Component, AutoGenSubject.Process,
        AutoGenSubject.Store, AutoGenSubject.Actionable, AutoGenSubject.RepMolecule, AutoGenSubject.Form],
    },
  ];
  types: AutoGenType[] = Object.values(AutoGenType);
  subjects: AutoGenSubject[] = Object.values(AutoGenSubject);
  options: AutoGenDEBehavior[] = Object.values(AutoGenDEBehavior);
  uiOptionModels: AutoGenModelOption[] = Object.values(AutoGenModelOption);
  uiOptionOrientation: Orientation[] = Object.values(Orientation);
  models: AutoGenModel[] = Object.values(AutoGenModel);
  repMolecules: RepresentativeMoleculesType[] = Object.values(RepresentativeMoleculesType)
  .filter(molecule => {
    return ![RepresentativeMoleculesType.All, RepresentativeMoleculesType.Custom,
      RepresentativeMoleculesType.Report].includes(molecule);
  });
  contextMenuConfig = {
    x: 0,
    y: 0,
    show: false,
  };
  subscription: Subscription;
  subjectLabel: string;
  typeLabel: string;
  isUsingExistingSubject = false;
  isUsingExistingType = false;
  showRepMoleculesDropdown = false;
  visualSubjectId: number;
  showNodeHighlight: boolean = false;
  shouldTreeChangeEmit: boolean = true;
  highlightNodesOnImport: boolean = false;
  actionComponents = [];
  
  constructor(
    private dialogService: GenericDialogService,
    public autoGenerationService: AutoGenerationService,
    private communicationService: CommunicationService,
    private moleculesService: ApiMoleculesService,
  ) {
  }
  
  get autoGenType() {
    return AutoGenType;
  }
  
  ngOnInit(): void {
    console.log('action');
    this.subscription = fromEvent(document, 'click')
    .subscribe(event => {
      const target = (event.target as HTMLElement);
      if (this.contextMenuConfig.show && !target.classList.contains(
        'mat-checkbox-inner-container') && !target.classList.contains(
        'menu-context-option') && !target.classList.contains('mat-input-element')) {
        this.contextMenuConfig.show = false;
      }
    });
    
    this.subscription.add(
      this.communicationService.Event.Editor.DataSource.$RemoveAction.subscribe(processId => {
        if (processId !== this.processId) {
          return;
        }
        
        this.RemoveAction(true);
      }),
    );
    
    if (this.actionObj) {
      this.SetAction();
      return;
    }
    
    this.id = this.autoGenerationService.actionId;
    this.actionObj = {
      id: this.id,
      order: this.order,
      ui: {
        modelOptions: AutoGenModelOption.Standalone,
        orientation: Orientation.Vertical,
        padding: 4,
        separation: 2,
        columns: 0,
        addons: {
          hidden: false,
          autoStart: false,
          header: true,
        },
      },
      model: {
        id: null,
        type: null,
      },
      options: {
        dataElementsBehavior: null,
      },
      dataElements: [],
    };
    this.DisableAddAction(true);
  }
  
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
  
  SetAction() {
    console.log(this.nodes);
    const defaultUI: AutoGenUIOptions = {
      modelOptions: AutoGenModelOption.Standalone,
      orientation: Orientation.Vertical,
      padding: 4,
      separation: 2,
      columns: 0,
      addons: {
        hidden: false,
        autoStart: false,
        header: true,
        readOnly: false,
      },
    };
    
    this.actionObj.ui = Object.assign(defaultUI, this.actionObj.ui);
    
    this.id = this.actionObj.id;
    if (this.autoGenerationService.actionId < this.id) {
      this.autoGenerationService.actionId = this.id;
    }
    
    this.order = this.actionObj.order;
    this.typeSelected = this.actionObj.type.type;
    this.subjectSelected = this.actionObj.subject.type;
    this.optionSelected = this.actionObj.options?.dataElementsBehavior;
    
    this.uiOptionAddons = this.actionObj.ui?.addons;
    this.uiOptionModelSelected = this.actionObj.ui?.modelOptions;
    this.uiOptionOrientationSelected = this.actionObj.ui?.orientation;
    this.uiOptionPadding = this.actionObj.ui?.padding;
    this.uiOptionSeparation = this.actionObj.ui?.separation;
    this.uiOptionColumns = this.actionObj.ui?.columns;
    
    this.repMoleculeSelected = this.actionObj.subject.entity;
    this.modelSelected = {
      modelType: this.actionObj.model.type,
      name: this.autoGenerationService.components.find(
        component => component.id === this.actionObj.model.id)?.name,
    };
    this.visualSubjectId = this.getSubjectId();
    this.autoGenerationService.selectedSubjects.push(
      { subject: this.subjectSelected, entity: this.repMoleculeSelected, id: this.id });
    this.SaveInHistory();
    
    if (this.actionObj.type.id !== this.id) {
      const subjectData = this.GetSubjectData(
        { item: this.actionObj.type.id, itemType: this.actionObj.type.type });
      
      if (subjectData.entity) {
        this.typeLabel = `${ this.autoGenerationService.textTranslations[this.actionObj.type.type] } - ${ subjectData.entity }: ${ this.actionObj.type.id }`;
      } else {
        this.typeLabel = `${ this.autoGenerationService.textTranslations[this.actionObj.type.type] } - ${ this.autoGenerationService.textTranslations[subjectData.subject] }: ${ this.actionObj.type.id }`;
      }
      
      this.isUsingExistingType = true;
    } else {
      this.typeLabel = this.autoGenerationService.textTranslations[this.typeSelected];
    }
    
    if (this.actionObj.subject.id) {
      if (this.actionObj.subject.type === AutoGenSubject.RepMolecule) {
        this.subjectLabel = `${ this.autoGenerationService.selectedSubjects.find(
          s => s.id === this.actionObj.subject.id).entity } : ${ this.actionObj.subject.id }`;
      } else {
        this.subjectLabel = `${ this.autoGenerationService.textTranslations[this.subjectSelected] } : ${ this.actionObj.subject.id }`;
      }
      this.isUsingExistingSubject = true;
    } else {
      this.subjectLabel = this.autoGenerationService.textTranslations[this.subjectSelected];
    }
    
    this.highlightNodesOnImport = true;
    this.dataElements = this.actionObj.dataElements;
    
    if (this.dataElements.length > 0) {
      console.log(this.dataElements);
      this.dataElements.forEach((dataElement: DataElement) => {
        setTimeout(() => {
          this.communicationService.Event.Editor.DataSource.$SelectNode.emit(
            { dataElement: dataElement, id: this.id });
        }, 200);
      });
      this.shouldTreeChangeEmit = false;
    }
    
    this.SetShowRepMoleculeDropdown();
    this.SetDefaultOption();
    this.emitAction();
  }
  
  TreeChange(nodes: TreeNode[]) {
    this.selectedNodes = nodes;
    console.log('treechange', nodes);
    
    if (this.selectedNodes.length === 0) {
      this.shouldTreeChangeEmit = true;
    } else if (this.selectedNodes.length > 0 && this.actionObj.dataElements.length > 0 && this.highlightNodesOnImport) {
      this.showNodeHighlight = true;
    }
    this.dataElements = [];
    
    this.selectedNodes.forEach(node => {
      const de = this.autoGenerationService.GenerateDataElementFromDataSourceNode(
        node.data) as DataElement;
      
      if (!(this.dataElements.map(e => e.Context)
      .includes(de.Context))) {
        this.dataElements.push(de);
      }
      
    });
    
    // this.dataElements = (this.selectedNodes.map(
    //   node => this.autoGenerationService.GenerateDataElementsFromDataSourceNode(node.data.data)) as any).flat();
    
    this.actionObj.dataElements = this.dataElements;
    
    if (this.shouldTreeChangeEmit) {
      this.emitAction();
    }
  }
  
  TypeChange(type: AutoGenType) {
    this.typeSelected = type;
    if (this.SubjectShouldBeDisabled(this.subjectSelected) || this.typeSelected === AutoGenType.Use) {
      this.SubjectChange(null);
      this.DisableAddAction(true);
      this.communicationService.Event.Editor.DataSource.$DisableAddProcess.emit(true);
    }
    
    this.typeLabel = this.autoGenerationService.textTranslations[type];
    this.actionObj.type = {
      id: this.id,
      type: this.typeSelected,
    };
    
    if (this.actionObj.subject) {
      if (!this.isUsingExistingSubject) {
        this.actionObj.subject.id = null;
        this.visualSubjectId = this.getSubjectId();
      }
    }
    
    this.SetSubjectEntity();
    this.SetDefaultOption();
    this.SetShowRepMoleculeDropdown();
    this.isUsingExistingType = false;
    this.SaveInHistory();
    this.emitAction();
  }
  
  SubjectChange(subject: AutoGenSubject) {
    if (this.SubjectShouldBeDisabled(
      subject) || (this.typeSelected === AutoGenType.Use && !this.subjectSelected) || (subject === AutoGenSubject.Actionable)) {
      return;
    }
    
    this.subjectSelected = subject;
    this.subjectLabel = this.autoGenerationService.textTranslations[subject];
    this.visualSubjectId = this.getSubjectId();
    this.actionObj.subject = {
      id: null,
      type: this.subjectSelected,
      entity: null,
    };
    
    const subjectData = this.autoGenerationService.selectedSubjects.find(
      subjectData => subjectData.id === this.id);
    
    if (subjectData) {
      subjectData.subject = subject;
    } else {
      this.autoGenerationService.selectedSubjects.push({
        subject,
        entity: null,
        id: this.id,
      });
    }
    
    this.SetSubjectEntity();
    this.SetDefaultOption();
    this.SetShowRepMoleculeDropdown();
    this.SaveInHistory();
    this.emitAction();
    this.isUsingExistingSubject = false;
    this.contextMenuConfig.show = false;
  }
  
  RepMoleculeChange(molecule: RepresentativeMoleculesType) {
    this.repMoleculeSelected = molecule;
    this.actionObj.subject.entity = molecule;
    
    const selectedSubject = this.autoGenerationService.selectedSubjects.find(
      selectedSubject => selectedSubject.id === this.id);
    selectedSubject.entity = molecule;
    this.SetDefaultOption();
    this.emitAction();
  }
  
  OptionChange(option: string) {
    this.optionSelected = option as AutoGenDEBehavior;
    
    this.actionObj.options = {
      dataElementsBehavior: this.optionSelected,
    };
    this.emitAction();
  }
  
  ModelChange(model: AutoGenModel, id: string, name: string) {
    this.modelSelected = {
      modelType: model,
      name: name,
    };
    
    this.actionObj.model = {
      id: id,
      type: this.modelSelected.modelType,
    };
    this.emitAction();
  }
  
  UIOptionChange(option: string, property: 'uiOptionModel' | 'uiOptionOrientation' | 'uiOptionPadding' | 'uiOptionSeparation' | 'uiOptionColumns' | 'uiOptionAddons', item?: string) {
    
    this.actionObj.ui = this.actionObj.ui || {
      modelOptions: this.uiOptionModelSelected,
      orientation: this.uiOptionOrientationSelected,
      padding: this.uiOptionPadding,
      separation: this.uiOptionSeparation,
      columns: this.uiOptionColumns,
      addons: this.uiOptionAddons,
    };
    
    switch (property) {
      case 'uiOptionModel':
        this.uiOptionModelSelected = option as AutoGenModelOption;
        this.actionObj.ui.modelOptions = this.uiOptionModelSelected;
        break;
      case 'uiOptionOrientation':
        this.uiOptionOrientationSelected = option as Orientation;
        this.actionObj.ui.orientation = this.uiOptionOrientationSelected;
        break;
      case 'uiOptionColumns':
        this.uiOptionColumns = parseInt(option);
        this.actionObj.ui.columns = this.uiOptionColumns;
        break;
      case 'uiOptionPadding':
        this.uiOptionPadding = parseInt(option);
        this.actionObj.ui.padding = this.uiOptionPadding;
        break;
      case 'uiOptionSeparation':
        this.uiOptionSeparation = parseInt(option);
        this.actionObj.ui.separation = this.uiOptionSeparation;
        break;
      case 'uiOptionAddons':
        this.uiOptionAddons[item] = option !== null;
        this.actionObj.ui.addons = this.uiOptionAddons;
        break;
    }
    
    setTimeout(() => {
      this.emitAction();
    }, 20);
  }
  
  CheckboxChange(ref: MatCheckbox, item: string, itemType, name = null) {
    
    switch (itemType) {
      case 'option':
        this.OptionChange(ref.checked ? item : null);
        break;
      case 'uiOptionModel':
      case 'uiOptionOrientation':
        this.UIOptionChange(ref.checked ? item : null, itemType);
        break;
      case 'uiOptionAddons':
        this.UIOptionChange(ref.checked ? item : null, itemType, item);
        break;
      default:
        if (this.models.includes(itemType)) {
          if (ref.checked) {
            this.ModelChange(itemType, item, name);
            if (itemType === AutoGenModel.Component) {
              this.GetActionableComponentMolecules(+item);
            }
          } else {
            this.ModelChange(null, null, null);
            this.actionComponents = [];
            this.SaveInHistory();
          }
        }
        break;
    }
    
  }
  
  GetActionableComponentMolecules(id: number) {
    this.moleculesService.GetApp(id)
    .subscribe((component: any[]) => {
      const actionableMolecules = component.filter(
        c => c.placeholders && c.placeholders.length > 0 && (c.placeholders.includes(
          Placeholders.Actionable) && c.placeholders.includes(
          Placeholders.Maintain)));
      this.actionComponents = actionableMolecules;
      this.SaveInHistory();
    });
  }
  
  CheckboxChecked(itemData: any, item: string, index: number) {
    switch (itemData.itemType) {
      case 'option':
        return this.optionSelected === item;
      case 'uiOptionModel':
        return this.uiOptionModelSelected === item;
      case 'uiOptionAddons':
        return this.uiOptionAddons[item];
      case 'uiOptionOrientation':
        return this.uiOptionOrientationSelected === item;
      default:
        return this.modelSelected?.name === itemData.itemLabels[index];
    }
  }
  
  emitAction() {
    if ((this.typeSelected || this.typeLabel) && (this.subjectSelected || this.subjectLabel) /* && this.modelSelected */) {
      const repMoleculeIsEmpty = (this.typeSelected === AutoGenType.Create || this.typeSelected === AutoGenType.Populate || this.typeSelected === AutoGenType.Add) && this.subjectSelected === AutoGenSubject.RepMolecule && !this.repMoleculeSelected;
      //const optionIsEmpty = this.selectedNodes.length > 0 && !this.optionSelected;
      
      if (repMoleculeIsEmpty) {
        return;
      }
      
      this.action.emit(this.actionObj as AutoGenAction);
      this.DisableAddAction(false);
      this.communicationService.Event.Editor.DataSource.$DisableAddProcess.emit(false);
    }
  }
  
  SaveInHistory() {
    if (this.typeSelected && this.subjectSelected) {
      this.autoGenerationService.SaveSubjectInHistory(this.processId, this.id, this.subjectSelected,
        this.typeSelected,
        this.actionComponents.map(c => {
          return {
            id: c.id,
            actionId: this.id,
            processId: this.processId,
            name: c.properties.name,
            type: c.type,
            templateId: c.templateId,
          };
        }));
    }
  }
  
  getSubjectId() {
    if (this.autoGenerationService.typesCreateId.includes(this.typeSelected)) {
      return this.id;
    } else {
      return null;
    }
  }
  
  SetSubjectEntity() {
    if (!this.actionObj.subject) {
      return;
    }
    
    if (this.subjectSelected === AutoGenSubject.RepMolecule && (this.typeSelected === AutoGenType.Create || this.typeSelected === AutoGenType.Populate) && this.repMoleculeSelected) {
      this.actionObj.subject.entity = this.repMoleculeSelected;
    } else {
      
      if (this.repMoleculeSelected && this.subjectSelected === AutoGenSubject.RepMolecule && this.typeSelected === AutoGenType.Create || this.typeSelected === AutoGenType.Populate || this.typeSelected === AutoGenType.Add) {
        this.actionObj.subject.entity = this.repMoleculeSelected;
        return;
      }
      
      this.repMoleculeSelected = null;
      this.actionObj.subject.entity = null;
    }
  }
  
  ItemSelected(itemSelected: { item: any, itemType: string }) {
    console.log(this.autoGenerationService.selectedSubjects);
    if (this.subjects.includes(itemSelected.itemType as AutoGenSubject)) {
      this.isUsingExistingSubject = true;
      this.actionObj.subject = {
        id: itemSelected.item.id || itemSelected.item,
        type: itemSelected.itemType,
        entity: null,
      };
      
      if (itemSelected.itemType === AutoGenSubject.RepMolecule) {
        const subjectData = this.GetSubjectData(itemSelected);
        this.subjectLabel = `${ subjectData.entity } : ${ itemSelected.item }`;
        this.showRepMoleculesDropdown = false;
      } else {
        this.subjectLabel = `${ this.autoGenerationService.textTranslations[itemSelected.itemType] } : ${ itemSelected.itemType === AutoGenSubject.Actionable ? `P-${ itemSelected.item.processId } A-${ itemSelected.item.actionId } ${ itemSelected.item.name }` : itemSelected.item }`;
      }
    } else if (this.types.includes(itemSelected.itemType as AutoGenType)) {
      this.isUsingExistingType = true;
      
      // if actionable
      if (itemSelected.item.id) {
        this.actionObj.type = {
          id: itemSelected.item.id,
          type: itemSelected.itemType,
        };
        
        this.typeLabel = `${ this.autoGenerationService.textTranslations[itemSelected.itemType] } - Actionable: P-${ itemSelected.item.processId } A-${ itemSelected.item.actionId } ${ itemSelected.item.name }`;
      } else {
        const subjectData = this.GetSubjectData(itemSelected);
        this.actionObj.type = {
          id: itemSelected.item,
          type: itemSelected.itemType,
        };
        
        if (subjectData.entity) {
          this.typeLabel = `${ this.autoGenerationService.textTranslations[itemSelected.itemType] } - ${ subjectData.entity }: ${ itemSelected.item }`;
        } else {
          this.typeLabel = `${ this.autoGenerationService.textTranslations[itemSelected.itemType] } - ${ this.autoGenerationService.textTranslations[subjectData.subject] }: ${ itemSelected.item }`;
        }
      }
      
    } else if (itemSelected.itemType === 'option') {
      return;
    } else if (itemSelected.itemType === 'uiOptionHeader') {
      return;
    } else if (itemSelected.itemType === 'uiOptionModel') {
      return;
    } else if (itemSelected.itemType === 'uiOptionAddons') {
      return;
    } else if (itemSelected.itemType === 'uiOptionOrientation') {
      return;
    } else if (itemSelected.itemType === AutoGenModel.Component/* this.models.includes(itemSelected.itemType as AutoGenModel) */) {
      return;
    }
    
    this.contextMenuConfig.show = false;
    this.emitAction();
  }
  
  contextHandler(option = null) {
    const isCreateSubject = this.typeSelected === AutoGenType.Create && this.subjects.includes(option);
    
    if (isCreateSubject) {
      return;
    }
    
    if (this.subjects.includes(option)) {
      const items = option === AutoGenSubject.Action ? this.autoGenerationService.GetAllActions(
        this.processId,
        this.id) : (this.autoGenerationService.GetSubjectsByTypeFromHistory(this.processId, option,
        this.id) as any).flat();
      let itemLabels: string[];
      
      if (option === AutoGenSubject.RepMolecule) {
        itemLabels = this.GetRepresentativeMoleculesSubjects()
        .map(s => `${ s.entity } - ${ s.id }`);
      } else {
        itemLabels = (items as any)
        .map(
          item => `${ this.autoGenerationService.textTranslations[option] } - ${ item }`);
      }
      
      this.itemData = {
        items,
        itemType: option,
        itemLabels,
      };
    } else if (option === AutoGenType.Navigate) {
      const views = this.GetSubjectsByType(AutoGenSubject.View);
      
      this.itemData = {
        items: this.autoGenerationService.GetSubjectsByTypeFromHistory(this.processId, AutoGenSubject.View,
          this.id),
        itemType: option,
        itemLabels: views.map(
          view => `${ this.autoGenerationService.textTranslations[view.subject] } - ${ view.id }`),
      };
    } else if (this.autoGenerationService.typesUseId.includes(option)) {
      const items = (this.autoGenerationService.GetAllActions(this.processId, this.id, false) as any).flat();
      const subjectsNames = this.GetSubjectsNames();
      
      this.itemData = {
        items: items,
        itemType: option,
        itemLabels: subjectsNames,
      };
    } else if (option === 'option') {
      this.itemData = {
        items: this.options,
        itemType: option,
        itemLabels: this.options.map(item => `${ this.autoGenerationService.textTranslations[item] }`),
      };
    } else if (option === 'uiOptionModel') {
      this.itemData = {
        items: this.uiOptionModels,
        itemType: option,
        itemLabels: this.uiOptionModels.map(item => `${ this.autoGenerationService.textTranslations[item] }`),
      };
    } else if (option === 'uiOptionOrientation') {
      this.itemData = {
        items: this.uiOptionOrientation,
        itemType: option,
        itemLabels: this.uiOptionOrientation.map(
          item => `${ this.autoGenerationService.textTranslations[item] }`),
      };
    } else if (option === 'uiOptionHeader') {
      this.itemData = {
        items: ['Add'],
        itemType: option,
        itemLabels: ['Add'],
      };
    } else if (option === 'uiOptionPadding' || option === 'uiOptionSeparation' || option === 'uiOptionColumns') {
      this.itemData = {
        items: null,
        itemType: option,
        itemLabels: [],
      };
    } else if (option === AutoGenModel.RepMolecule) {
      
      console.warn(AutoGenModel.RepMolecule);
      
    } else if (option === AutoGenModel.Component) {
      this.itemData = {
        items: this.autoGenerationService.components.map(component => component.id),
        itemType: option,
        itemLabels: this.autoGenerationService.components.map(component => component.name),
      };
    } else if (option === 'uiOptionAddons') {
      const optionLabels = [
        'header',
        'autoStart',
        'hidden',
        'readOnly',
      ];
      
      this.itemData = {
        items: optionLabels,
        itemType: option,
        itemLabels: optionLabels.map(label => `${ this.autoGenerationService.textTranslations[label] }`),
      };
    }
    
    const isType = this.types.includes(option);
    const isSubject = this.subjects.includes(option);
    const isOption = option === 'option';
    const isUIOption = option.includes('uiOption');
    const isModel = option === AutoGenModel.Component; //this.models.includes(option);
    
    if (this.itemData?.items?.length === 0 ||
      (isType && !this.autoGenerationService.typesUseId.includes(option)) ||
      (!isType && !isSubject) && !isOption && !isUIOption && !isModel ||
      this.SubjectShouldBeDisabled(option)) {
      this.contextMenuConfig.show = false;
      return;
    }
    
    const triggerElement = document.getElementById(option);
    this.ToggleContextMenu(triggerElement);
  }
  
  ToggleContextMenu(referenceElement: HTMLElement) {
    const rect = referenceElement.getBoundingClientRect();
    this.contextMenuConfig.x = rect.left + rect.width + 10;
    this.contextMenuConfig.y = rect.top;
    this.contextMenuConfig.show = true;
  }
  
  GetSubjectsByType(subjectType: AutoGenSubject) {
    return this.autoGenerationService.selectedSubjects.filter(
      subjectData => subjectData.subject === subjectType);
  }
  
  GetSubjectsNames() {
    return this.autoGenerationService.selectedSubjects.map(
      s => s.entity ? `${ s.entity } - ${ s.id }` : `${ this.autoGenerationService.textTranslations[s.subject] } - ${ s.id }`);
  }
  
  GetInputValue(itemType: 'uiOptionPadding' | 'uiOptionSeparation' | 'uiOptionColumns') {
    switch (itemType) {
      case 'uiOptionColumns':
        return this.uiOptionColumns;
      case 'uiOptionPadding':
        return this.uiOptionPadding;
      case 'uiOptionSeparation':
        return this.uiOptionSeparation;
      default:
        break;
    }
  }
  
  GetRepresentativeMoleculesSubjects() {
    return this.autoGenerationService.selectedSubjects.filter(
      subjectData => subjectData.entity !== null && subjectData.id < this.id);
  }
  
  GetSubjectData(itemSelected: { item: any, itemType: string }) {
    return this.autoGenerationService.selectedSubjects.find(s => s.id === itemSelected.item);
  }
  
  SetDefaultOption() {
    if (this.optionSelected) {
      return;
    }
    
    this.defaultOptions.every(defaultOption => {
      if (this.typeSelected === defaultOption.type && this.subjectSelected === defaultOption.subject && this.repMoleculeSelected === defaultOption.entity) {
        this.optionSelected = defaultOption.option;
        return false;
      } else {
        this.optionSelected = null;
        return true;
      }
    });
  }
  
  SubjectShouldBeDisabled(subject: AutoGenSubject) {
    return this.disabledSubjects.some(option => {
      return this.typeSelected === option.type && option.subjectsToDisable.includes(subject);
    });
  }
  
  SetShowRepMoleculeDropdown(): void {
    const isTypeValid = this.typeSelected === AutoGenType.Create || this.typeSelected === AutoGenType.Populate || this.typeSelected === AutoGenType.Add || this.typeSelected === AutoGenType.Receive;
    const isSubjectValid = this.subjectSelected === AutoGenSubject.RepMolecule;
    
    if (isTypeValid && isSubjectValid) {
      this.showRepMoleculesDropdown = true;
      
      if (!this.repMoleculeSelected) {
        this.DisableAddAction(true);
      }
      
    } else {
      this.showRepMoleculesDropdown = false;
    }
  }
  
  DisableAddAction(state: boolean): void {
    this.communicationService.Event.Editor.DataSource.$DisableAddAction.emit(
      { disable: state, id: this.processId });
  }
  
  GetActionables(): any[] {
    return this.autoGenerationService.GetActionablesForAction(this.id, this.processId);
  }
  
  NodesOpenedChange(isOpen: boolean) {
    if (!isOpen && this.selectedNodes.length > 0) {
      this.showNodeHighlight = true;
    } else if (!isOpen && this.selectedNodes.length === 0) {
      this.showNodeHighlight = false;
    } else if (isOpen) {
      this.highlightNodesOnImport = false;
    }
    this.shouldTreeChangeEmit = true;
  }
  
  async RemoveAction(skipWarning = false) {
    if (!skipWarning) {
      const result = await this.dialogService.OpenConfirmDialog({
        title: 'Remove Action',
        message: 'Are you sure you want to remove this action? This action can not be undone',
        confirmText: 'Remove',
        cancelText: 'Cancel',
        maxWidth: '700px',
      });
      
      if (!result) {
        return;
      }
    }
    
    this.autoGenerationService.RemoveAction(this.processId, this.id, this.subjectSelected);
    this.remove.emit(this.id);
    // this.autoGenerationService.actionId--;
    this.DisableAddAction(false);
  }
}
