import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiAdminService } from '../../../core/services/api-admin.service';
import { ClientStorageService } from '../../../core/services/client-storage.service';
import { UserMenuService } from '../../../core/services/user-menu.service';
import { WorkAreaService } from '../../../workarea/workarea.service';
import { CobbleUser } from '../../models/cobble-user.model';
import { CompanyLicense } from '../../models/company-license.model';
import { CommunicationService } from '../../services/communication.service';

@Component({
  selector: 'app-companies-explorer',
  templateUrl: './companies-explorer.component.html',
  styleUrls: ['./companies-explorer.component.scss'],
})
export class CompaniesExplorerComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Output()
  companyChanged = new EventEmitter<CompanyLicense>();
  
  @Output()
  userChanged = new EventEmitter<number>();
  
  @ViewChild('companiesPicker', { static: false })
  companiesPicker: ElementRef;
  
  @ViewChild('usersPicker', { static: false })
  usersPicker: ElementRef;
  
  usersFiltered: CobbleUser[];
  users: CobbleUser[];
  selectedUser: CobbleUser;
  allUsers: CobbleUser;
  companyId: number;
  
  companiesFiltered: CompanyLicense[];
  companies: CompanyLicense[];
  selectedCompany: CompanyLicense;
  allCompany: CompanyLicense;
  
  removedCobbleSuscription: Subscription;
  createdCobbleSuscription: Subscription;
  changedCobbleSuscription: Subscription;
  
  constructor(
    private adminService: ApiAdminService,
    private clientStorageService: ClientStorageService,
    private menuService: UserMenuService,
    private workareaService: WorkAreaService,
    private communicationService: CommunicationService,
  ) {
  }
  
  get userIsAdmin() {
    return this.menuService.isAdminUser;
  }
  
  get userIsSuperUser() {
    return this.menuService.isSuperUser;
  }
  
  get atLeastOneCobble() {
    return (
      this.companies &&
      (this.companies.length > 1 || this.companies[0].other.hasCobbles)
    );
  }
  
  ngOnInit() {
    this.companyId = this.clientStorageService.getCurrentCompanyId();
    this.allCompany = new CompanyLicense();
    this.allCompany.id = 0;
    this.allCompany.name = 'All';
    
    this.allUsers = new CobbleUser();
    this.allUsers.id = '0';
    this.allUsers.name = 'All';
    this.allUsers.lastName = '';
    
    this.createdCobbleSuscription = this.workareaService.$cobbleCreated.subscribe(
      cobble => {
        this.workareaService.getCompanyCobbles(this.selectedCompany);
      },
    );
  }
  
  ngAfterViewInit(): void {
    if (this.menuService.isSuperUser) {
      this.adminService.getCompanies().subscribe(companies => {
        this.companies = companies;
        this.companiesFiltered = companies;
        if (this.companyId) {
          this.selectedCompany = this.companies.find(
            c => c.id === this.companyId,
          );
        } else {
          this.selectedCompany = this.allCompany;
        }
        
        if (this.companiesPicker) {
          this.companiesPicker.nativeElement.value = this.displayCompFn(
            this.selectedCompany,
          );
        }
        this.notifyCompanyChange(this.selectedCompany);
      });
      this.getUserForCompany(this.companyId);
    } else if (this.menuService.isAdminUser) {
      this.selectedCompany = new CompanyLicense();
      this.selectedCompany.id = this.companyId;
      this.getUserForCompany(this.companyId);
    }
  }
  
  companyPickChanged(
    company: CompanyLicense,
    companiesPicker: HTMLInputElement,
  ) {
    this.companyId = company.id;
    this.clientStorageService.setCurrentCompanyId(this.companyId);
    this.getUserForCompany(this.companyId);
    companiesPicker.blur();
    this.notifyCompanyChange(company);
  }
  
  filterCompanies(value: string) {
    const filter = value.toLowerCase();
    return this.companies.filter(comp =>
      comp.name.toLowerCase().includes(filter),
    );
  }
  
  resetCompanyFilter() {
    this.companiesFiltered = this.companies;
  }
  
  resetUsersFilter() {
    this.usersFiltered = this.filterUsers('');
  }
  
  getUserForCompany(companyId) {
    this.adminService.getUsersForCompany(companyId).subscribe(users => {
      this.users = users;
      this.usersFiltered = this.filterUsers('');
    });
  }
  
  displayCompFn(company: CompanyLicense) {
    return company ? company.name : undefined;
  }
  
  userPickChanged(user: CobbleUser, usersPicker: HTMLInputElement) {
    usersPicker.blur();
    this.userChanged.emit(+user.id);
  }
  
  filterUsers(value: string) {
    if (value) {
      const filter = value.toLowerCase();
      return this.users.filter(
        user =>
          user.name.toLowerCase().includes(filter) ||
          user.lastName.toLowerCase().includes(filter),
      );
    } else {
      return this.users;
    }
  }
  
  displayUsersFn(user: CobbleUser) {
    return user ? user.name + ' ' + user.lastName : undefined;
  }
  
  ngOnDestroy(): void {
    if (this.createdCobbleSuscription) {
      this.createdCobbleSuscription.unsubscribe();
    }
    if (this.removedCobbleSuscription) {
      this.removedCobbleSuscription.unsubscribe();
    }
    if (this.changedCobbleSuscription) {
      this.changedCobbleSuscription.unsubscribe();
    }
  }
  
  notifyCompanyChange(company: CompanyLicense) {
    this.companyChanged.emit(company);
    this.workareaService.getCompanyCobbles(company);
    this.userChanged.emit(+this.allUsers.id);
    if (this.usersPicker) {
      this.usersPicker.nativeElement.value = this.displayUsersFn(this.allUsers);
    }
  }
}
