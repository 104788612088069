import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DndModule } from 'ng2-dnd';
import { AddParticleToMoleculeComponent } from '../workarea/bus/add-particle-to-molecule/add-particle-to-molecule.component';
import { SharedModule } from './../shared/shared.module';
import { DynamicAutocompleteComponent } from './components/dynamic-autocomplete/dynamic-autocomplete.component';
import { DynamicButtonComponent } from './components/dynamic-button/dynamic-button.component';
import { DynamicCheckboxComponent } from './components/dynamic-checkbox/dynamic-checkbox.component';
import { DynamicDropdownComponent } from './components/dynamic-dropdown/dynamic-dropdown.component';
import { DynamicFormComponent } from './components/dynamic-form.component';
import { DynamicInputComponent } from './components/dynamic-input/dynamic-input.component';
import { DynamicLabelComponent } from './components/dynamic-label/dynamic-label.component';
import { DynamicRadioComponent } from './components/dynamic-radio/dynamic-radio.component';
import { DynamicSelectComponent } from './components/dynamic-select/dynamic-select.component';
import { DynamicFieldDirective } from './directives/dynamic-field.directive';
import { MoleculeSettingsComponent } from './molecule-settings/molecule-settings.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatRadioModule,
    MatProgressBarModule,
    MatIconModule,
    MatAutocompleteModule,
    DndModule,
    FormsModule,
    MatTableModule,
    MatSortModule,
    SharedModule,
  ],
  declarations: [
    DynamicFieldDirective,
    DynamicFormComponent,
    DynamicInputComponent,
    DynamicDropdownComponent,
    DynamicSelectComponent,
    DynamicButtonComponent,
    DynamicCheckboxComponent,
    DynamicRadioComponent,
    DynamicAutocompleteComponent,
    MoleculeSettingsComponent,
    DynamicLabelComponent,
    AddParticleToMoleculeComponent,
  ],
  exports: [DynamicFormComponent, MoleculeSettingsComponent],
  entryComponents: [
    DynamicInputComponent,
    DynamicDropdownComponent,
    DynamicSelectComponent,
    DynamicFormComponent,
    DynamicButtonComponent,
    DynamicCheckboxComponent,
    DynamicRadioComponent,
    DynamicLabelComponent,
    DynamicAutocompleteComponent,
  ],
  providers: [],
})
export class DynamicFormModule {
}
