import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class ReplaceWorkgroupMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(particleId: string, repMoleculeId: string, busProcessorKey: string, rule: any, logic: any, data: any) {
    try {
      data = data === undefined || data === null || data === {} ? '' : data;
      
      if (rule && rule.workgroupToReplaceId && rule.replaceWithId) {
        rule.workgroupToReplaceId.forEach((workgroupId, i) => {
          const workgroupToReplace = this.busService.Get(workgroupId);
          
          if (workgroupToReplace) {
            workgroupToReplace.Properties.show = false;
          }
        });
        
        rule.replaceWithId.forEach((workgroupId, i) => {
          const replaceW = this.busService.Get(workgroupId);
          
          if (replaceW) {
            replaceW.Properties.show = true;
          }
        });
        
        // console.log(`molecule working, replacing workgroup`);
      }
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
