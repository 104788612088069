import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiAdminService } from '../../core/services/api-admin.service';
import { ApiAuthService } from '../../core/services/api-auth.service';
import { ApiCompaniesService } from '../../core/services/api-companies.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { Company } from '../../shared/models/company';
import { CompanyLicense } from '../../shared/models/company-license.model';
import { User } from '../../shared/models/user';
import { HubConnectionService } from '../../shared/services/hub-connection.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { SystemService } from '../../shared/services/system.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  user: User;
  email: string;
  userName: string;
  password: string;
  firstName: string;
  lastName: string;
  phone: string;
  companies: Company[];
  company: Company;
  companyForLicense: CompanyLicense;
  formSubmitted: boolean;
  
  constructor(
    private adminService: ApiAdminService,
    private companiesService: ApiCompaniesService,
    private router: Router,
    private clientStorageService: ClientStorageService,
    private snackerService: SnackerService,
    private systemService: SystemService,
    private authService: ApiAuthService,
    private hubConnectionService: HubConnectionService,
  ) {
    this.clientStorageService.clearSession();
    this.companyForLicense = new CompanyLicense();
    this.companies = [];
    this.companies.push(
      new Company(
        1,
        'Unityware',
        '',
        '',
        '',
        '',
        '',
        'Brian Stack',
        '999-999-9999',
        '',
      ),
    );
    
    this.adminService.getBackendVersion().subscribe(result => {
      if (!result.newInstallation) {
        this.router.navigate(['/']);
      }
    });
  }
  
  ngOnInit() {
  }
  
  register() {
    if (!this.formSubmitted) {
      this.user = new User(
        '0',
        this.email,
        this.userName,
        this.password,
        '',
        this.firstName,
        '',
        this.firstName,
        this.lastName,
        this.phone,
        0,
      );
      this.companyForLicense.registerUser = this.user;
      this.formSubmitted = true;
      this.companiesService
      .register(this.companyForLicense)
      .subscribe(result => {
        if (result) {
          const token = result.jwt;
          const user = result.user;
          user.type = 'native';
          this.clientStorageService.setSessionData(token, user.email, user);
          this.systemService.SetSessionId(true);
          this.authService.userRole = user.roleId;
          // this.hubConnectionService.SendUserLogged(this.systemService.SessionId);
          
          // const user = result.user;
          // this.adminService.addHierarchyNodeToUser(user.id, 1).subscribe();
          this.snackerService.ShowMessageOnBottom(`${ this.user.email } registered!`, 'check_circle');
          this.router.navigate(['/initdb?download=false']);
        } else {
          // console.log('Error register');
        }
        this.formSubmitted = false;
      });
    }
  }
}
