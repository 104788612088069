import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommunicationService } from 'app/shared/services/communication.service';
import { throwError as observableThrowError } from 'rxjs';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { ToolsService } from '../../core/services/tools.service';
import { ActionableErrorComponent } from '../components/actionable-error/actionable-error.component';
import { ConnectionStateService } from './connection-state.service';
import { SnackerService } from './snacker.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessengerService {
  info: any;
  errorDialogOpen = false;
  dialogRef: MatDialogRef<any>;
  
  
  constructor(
    private toolsService: ToolsService,
    private connectionStateService: ConnectionStateService,
    private clientStorageService: ClientStorageService,
    private communicationService: CommunicationService,
    private snackerService: SnackerService,
    public dialog: MatDialog,
  ) {
  
  }
  
  ShowMessageOnTop(message: string, action: string) {
    this.snackerService.ShowMessageOnTop(message, action);
  }
  
  ShowMessageOnBottom(message: string, action: string) {
    this.snackerService.ShowMessageOnBottom(message, action);
  }
  
  showDialog(
    errorType: string,
    message: string,
    data: any,
    error: HttpErrorResponse,
  ) {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    if (this.errorDialogOpen) {
      const errorMessage = this.dialogRef.componentInstance.data.descriptions.find(
        d => d.errorType === errorType,
      );
      if (errorMessage) {
        errorMessage.messages.push(message);
      } else {
        this.dialogRef.componentInstance.data.descriptions.push({
          errorType: errorType,
          messages: [message],
        });
      }
      
      this.dialogRef.componentInstance.data.errors.push(error);
      this.dialogRef.componentInstance.data.requestsData.push(
        JSON.stringify(data),
      );
      
      // console.log('errors', this.dialogRef.componentInstance.data.errors);
    } else {
      this.errorDialogOpen = true;
      this.dialogRef = this.dialog.open(ActionableErrorComponent, {
        data: {
          descriptions: [
            {
              errorType: errorType,
              messages: [message],
            },
          ],
          errors: [error],
          requestsData: [JSON.stringify(this.toolsService.ClearLeapXLObjectFromCircularDependencies(data, true))],
        },
      });
      this.dialogRef.afterClosed().subscribe(result => {
        this.errorDialogOpen = false;
      });
    }
  }
  
  
  HandleError(error, requestMessage = '', data = {}) {
    console.log('===error received===', error, requestMessage);
    
    let errorThrow: any = '';
    let message = '';
    
    switch (error.status) {
      case 0:
        this.snackerService.ShowMessageOnBottom('Unable to load');
        break;
      case 401:
        if (this.clientStorageService.getUserId() > 0) {
          errorThrow = 'Unauthorize';
          this.snackerService.ShowMessageOnBottom('Unauthorized access. Please, log in again or ask for support.', 'do_not_disturb_on');
          this.communicationService.Event.System.Auth.$LogOutUser.emit(true);
        }
        break;
      
      case 0:
        message =
          'Looks like our servers are not responding or there is a problem with the internet connection.';
        this.showDialog(
          message,
          requestMessage,
          data,
          error,
        );
        
        errorThrow = error;
        break;
      
      case 400:
      case 404:
      case 409:
        if (this.clientStorageService.getUserId() > 0) {
          const defaultErrorMessage = 'Error processing the operation';
          
          try {
            const errorData = error.error || { title: defaultErrorMessage };
            this.ShowMessageOnBottom(
              errorData.title || JSON.parse(errorData).title || defaultErrorMessage,
              '',
            );
          } catch (e) {
            this.snackerService.ShowMessageOnBottom('No permission to access', 'gpp_maybe');
          }
          
          errorThrow = error;
        } else {
          this.snackerService.ShowMessageOnBottom('No permission to access', 'gpp_maybe');
        }
        break;
      
      case 403:
        message = 'Looks like you do not have permissions to these resources';
        this.snackerService.ShowMessageOnBottom(message, 'do_not_disturb_on');
        errorThrow = error;
        break;
      
      case 500:
        message =
          'Something bad happened, your operation could not be completed because there was a problem with the service.';
        this.showDialog(
          message,
          requestMessage,
          data,
          error,
        );
        
        errorThrow = error;
        break;
      
      case 501:
        message =
          'Looks like our servers are overloaded and are unable to respond.';
        this.showDialog(
          message,
          requestMessage,
          data,
          error,
        );
        
        errorThrow = error;
        break;
      
      case 503:
        message =
          'There is a problem with the service right now. Please try again later.';
        this.showDialog(
          message,
          requestMessage,
          data,
          error,
        );
        
        errorThrow = error;
        break;
      
      case 504:
      case 408:
        message =
          'Looks like the server is taking too long to respond, this can be caused by either poor connectivity or an error with our servers.';
        this.showDialog(
          message,
          requestMessage,
          data,
          error,
        );
        errorThrow = error;
        break;
      default:
        message = 'Something unexpected occurred on the server.';
        this.showDialog(
          message,
          requestMessage,
          data,
          error,
        );
        errorThrow = error;
        break;
    }
    
    return observableThrowError(new HttpErrorResponse(errorThrow));
  }
  
}
