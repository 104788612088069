import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Node } from '@swimlane/ngx-graph';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { BusService } from '../core/molecular/services/bus.service';
import { ApiLogService } from '../core/services/api-log.service';
import { LocalStorageService } from '../core/services/local-storage.service';
import { TemplateService } from '../core/services/template.service';
import { ToolsService } from '../core/services/tools.service';
import { Constants } from '../shared/constants';
import { PropertyVersioningDto } from '../shared/dtos/versioning-dto';
import { IRepresentativeMolecule } from '../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CommunicationService } from '../shared/services/communication.service';
import { DraggableWindowService } from '../shared/services/draggable-window.service';
import { HubConnectionService } from '../shared/services/hub-connection.service';
import { JsonTreeComponent } from '../workarea/json-tree/json-tree.component';
import { ProcessComponent } from '../workarea/process/process.component';
import { RepresentativePropertiesComponent } from '../workarea/representative-properties/representative-properties.component';
import { WorkAreaService } from '../workarea/workarea.service';
import { ApiPropertiesService } from './../core/services/api-properties.service';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss'],
  animations: [
    trigger('divState', [
      state('in', style({ backgroundColor: 'red', transform: 'translateX(0)' })),

      transition('void => *', [
        animate(
          1000,
          keyframes([
            style({ opacity: 0, transform: 'translateX(-100%)', offset: 0 }),
            style({ backgroundColor: '#bee0ff', opacity: 1, transform: 'translateX(15px)', offset: 0.3 }),
            style({ opacity: 1, transform: 'translateX(0)', offset: 1.0 }),
          ])
        ),
      ]),
      transition('* => void', [
        animate(
          300,
          keyframes([
            style({ opacity: 1, transform: 'translateX(0)', offset: 0 }),
            style({ opacity: 1, transform: 'translateX(-15px)', offset: 0.7 }),
            style({ opacity: 0, transform: 'translateX(100%)', offset: 1.0 }),
          ])
        ),
      ]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class MonitoringComponent implements OnInit, OnDestroy {
  @ViewChild('tabs', { static: false }) tabs;
  usersDisplayedColumns: string[] = ['online', 'id', 'name', 'username', 'platform', 'tabs', 'actions'];
  userLogsDisplayedColumns: string[] = ['timestamp', 'message'];
  @ViewChild('usersPaginator', { static: false }) usersPaginator: MatPaginator;
  @ViewChild('userDetailsLogsPaginator', { static: false }) userDetailsLogsPaginator: MatPaginator;
  usersDataSource = new MatTableDataSource<any>();
  userDetailsLogs = new MatTableDataSource<any>();
  usersConnected: UserConnection[] = [];
  subscriptions: Subscription = new Subscription();
  userDetails: UserConnection = null;
  userDetailsUpdate: any[] = [];
  tabSelected = 0;
  contextToLocate = '';
  moleculeToLocate = '';
  devEnvironment = 'http://localhost:4200/';
  pass = '';
  initializingDb = false;
  requestCount = 20;
  requestInterval = 100;
  overloadTestRequests = [];
  repMoleculeSelected: IRepresentativeMolecule = null;
  accessGranted = Constants.Environment.domain === this.devEnvironment;
  repMoleculesWithContextToLocate = [];
  isEditor = window.location.href.indexOf('/workarea/') > -1;
  isPreviewRunning = window.location.href.split('/')[3] === 'run' && window.location.href.split('/')[4] === 'preview';
  isPublishRunning = window.location.href.split('/')[3] === 'run' && window.location.href.split('/')[4] !== 'preview';
  isDashboard = window.location.href.indexOf('/dashboard') > -1;
  isAdmin = window.location.href.indexOf('/admin') > -1;
  CONSOLE_PASS = 'Cobbler123!';
  showSession = false;
  public editorOptions: JsonEditorOptions;
  inDebounce = null;

  updateProperty = {
    cobbleId: 0,
    paht: '',
    property: '',
    value: '',
    repMoleculeId: 0,
  };
  // region Tests
  overloadTests: {
    enable: boolean;
    name: string;
    url: string;
    requestCompleted: number;
    overloadTestRequests: any[];
    count: number;
    requestCount: number;
    requestInterval: number;
    failed: number;
    failedText: string;
    interval: any;
    startTime: any;
    method: string;
    elapsedTime: any;
    data: any;
  }[] = [
    {
      enable: true,
      name: 'Submit data',
      url: 'datasources/ProcessDataItems',
      requestCompleted: 0,
      overloadTestRequests: [],
      method: 'POST',
      failedText: 'POST',
      count: 0,
      requestCount: 50,
      requestInterval: 10,
      failed: 0,
      interval: null,
      startTime: null,
      elapsedTime: null,
      data: {
        dataItems: [
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::B',
            translationId: 3749,
            value: 'test ' + 1,
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - Issue name',
            oldValue: '',
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::C',
            translationId: 3750,
            value: 'test ' + 2,
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - LeapXL app name',
            oldValue: '',
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::D',
            translationId: 3751,
            value: 'test ' + 3,
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - What is happening?',
            oldValue: '',
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::E',
            translationId: 3753,
            value: 'test ' + 4,
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - How to reproduce',
            oldValue: '',
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::F',
            translationId: 3752,
            value: 'test ' + 5,
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - View',
            oldValue: '',
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::G',
            translationId: 3754,
            value: 'test ' + 6,
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - Workgroup',
            oldValue: '',
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::H',
            translationId: 3755,
            value: 'test ' + 7,
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - WorkArea Id',
            oldValue: '',
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::I',
            translationId: 3772,
            value: '',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - Navigation (in app)',
            oldValue: '',
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::P',
            translationId: 3764,
            value: 'ttt',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - O/S version',
            oldValue: '',
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::Q',
            translationId: 3760,
            value: '',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - Screen resolution',
            oldValue: '',
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::R',
            translationId: 3761,
            value: '',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - Browser zoom',
            oldValue: '',
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::S',
            translationId: 3762,
            value: '',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - LeapXL zoom',
            oldValue: '',
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::U',
            translationId: 3766,
            value: 'test ' + 8,
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - Browser version',
            oldValue: '',
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::AA',
            translationId: 3771,
            value: '',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - Subsystem',
            oldValue: '',
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::L',
            translationId: 3756,
            value: 'Sometimes ',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - Reproducible?',
            oldValue: null,
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::M',
            translationId: 3757,
            value: 'Performance',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - Classification',
            oldValue: null,
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::N',
            translationId: 3758,
            value: 'Critical',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - Severity',
            oldValue: null,
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::K',
            translationId: 3759,
            value: 'High',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - Priority',
            oldValue: null,
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::O',
            translationId: 3763,
            value: 'Windows',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - O/S',
            oldValue: null,
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::T',
            translationId: 3765,
            value: 'Chrome',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - Browser',
            oldValue: null,
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::V',
            translationId: 3767,
            value: '',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - issue in Edit',
            oldValue: null,
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::W',
            translationId: 3768,
            value: '',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - Preview',
            oldValue: null,
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::X',
            translationId: 3769,
            value: '',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - Published',
            oldValue: '',
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::Z',
            translationId: 3770,
            value: 'Cobbler leapXL',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - Product',
            oldValue: null,
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::AV',
            translationId: 3774,
            value: '/assets/images/no_image.png',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Image',
            oldValue: '',
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::AW',
            translationId: 3775,
            value: '/assets/images/no_image.png',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Image',
            oldValue: '',
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::AX',
            translationId: 3776,
            value: '/assets/images/no_image.png',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Image',
            oldValue: '',
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::AY',
            translationId: 3777,
            value: '/assets/images/no_image.png',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Image',
            oldValue: '',
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::AZ',
            translationId: 3773,
            value: '',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'PDF file',
            oldValue: '',
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::J',
            translationId: 3778,
            value: 'jhordy.rosario@leapxl.com',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - User name',
            oldValue: 'jhordy.rosario@leapxl.com',
          },
          {
            operationType: 'col',
            context: 'Spreadsheet::LeapXL Development Management (10)::Issue reports::Y',
            translationId: 3779,
            value: '2021-02-16 10:04:17',
            form: 'Issue reports Form WorkGroup',
            view: 'View 1',
            source: 'Textbox - Date',
            oldValue: '2021-02-16 10:04:17',
          },
        ],
        modificationType: 1,
        applicationId: 14409,
        applicationName: 'Product Management',
        applicationVersion: '282.0.282',
        leapxlVersion: 'B 3.4.0.1.1-20210215 - F 20197293.0.0.2',
        url: 'http://localhost:4200/run/leapxl/product-management',
        platform: 'Win32',
        browser: 'Gecko Google Inc.',
      },
    },
  ];
  // endregion

  // ==============================
  @Input() employees: Employee[] = [];

  // ==============================

  constructor(
    private communicationService: CommunicationService,
    private draggableWindowService: DraggableWindowService,
    private workAreaService: WorkAreaService,
    private toolsService: ToolsService,
    private router: Router,
    private hubConnectionService: HubConnectionService,
    private busService: BusService,
    private templateService: TemplateService,
    private localStorageService: LocalStorageService,
    private propertiesService: ApiPropertiesService,
    private logsService: ApiLogService
  ) {}

  ngOnInit() {
    if (this.accessGranted) {
      this.Init();
    }
  }

  public getStyles(node: Node): any {
    return {
      'background-color': node.data.backgroundColor,
    };
  }

  Init() {
    setTimeout(() => {
      this.tabs.realignInkBar();
    }, 100);
    this.usersDataSource.paginator = this.usersPaginator;

    this.subscriptions.add(
      this.communicationService.Event.System.Dev.$UserAppChange.subscribe(update => {
        console.log('=event=');
        if (this.userDetails && update.userId === this.userDetails.userId) {
          if (this.userDetailsUpdate.length === 15) {
            this.userDetailsUpdate.shift();
          }

          this.userDetailsUpdate.push(update);
        }
        this.UserUpdate(update.userId);
      })
    );

    this.subscriptions.add(
      this.communicationService.Event.System.Dev.$RepMoleculeSelected.subscribe(repMolecule => {
        console.log('=event=');
        this.DebugRepresentativeMolecule(repMolecule);
      })
    );

    this.subscriptions.add(
      this.communicationService.Event.System.Dev.$UserConnected.subscribe(data => {
        console.log('=event=');
        let userConnection = this.UserConnection(data.user.userId);

        if (userConnection) {
          userConnection.connectionsData.push(data);
        } else {
          userConnection = {
            userId: data.user.userId,
            connectionsData: [data],
          };
          this.usersConnected.push(userConnection);
        }

        this.usersDataSource = new MatTableDataSource<any>(this.usersConnected);
        this.usersDataSource.paginator = this.usersPaginator;
      })
    );

    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.sortObjectKeys = false;
    this.editorOptions.history = false;
    this.editorOptions.mode = 'view';
    this.editorOptions.navigationBar = false;
    this.editorOptions.statusBar = false;
    this.editorOptions.mainMenuBar = false;
    this.editorOptions.expandAll = false;
  }

  UserConnection(userId: number): UserConnection {
    return this.usersConnected.find(uc => uc.userId === userId);
  }

  UserUpdate(userId: number) {
    const statusIcon = document.querySelector('#status-icon-' + userId);
    const updateIcon = document.querySelector('#update-icon-' + userId);

    if (statusIcon) {
      statusIcon.classList.add('hide');
    }

    if (updateIcon) {
      updateIcon.classList.remove('hide');
    }

    setTimeout(() => {
      if (statusIcon) {
        statusIcon.classList.remove('hide');
      }

      if (updateIcon) {
        updateIcon.classList.add('hide');
      }
    }, 1000);
  }

  RequestUsersConnected() {
    this.usersConnected = [];
    this.userDetails = null;
    this.userDetailsUpdate = [];
    this.hubConnectionService.RequestUsersConnected();
  }

  DisplayUserInfo(userId: number) {
    this.userDetails = this.UserConnection(userId);
    this.GetUserSystemLogs(userId);
  }

  GetUserSystemLogs(userId: number) {
    this.logsService.GetUserSystemLogs(userId).subscribe(logs => {
      this.userDetailsLogs = new MatTableDataSource<any>(logs);
      setTimeout(() => {
        this.userDetailsLogs.paginator = this.userDetailsLogsPaginator;
      }, 100);
    });
  }

  LogOutUser(userId: number) {
    this.hubConnectionService.LogOutUser(userId);
  }

  LocateContextElements(context: string) {
    this.repMoleculesWithContextToLocate = [];

    if (this.contextToLocate !== '') {
      this.repMoleculesWithContextToLocate = this.busService.GetRepresentativeMoleculesWithContext(context);
    }
    // NavigateViewMolecule
    if (this.moleculeToLocate) {
      const t = this.busService.GetAllBuses().filter(bus => !!bus.GetActionMoleculeParticle(this.moleculeToLocate));

      t.forEach(bus => {
        const repMolecule = this.busService.Get(bus.RepresentativeMoleculeId.toString());
        if (repMolecule) {
          this.repMoleculesWithContextToLocate.push(repMolecule);
        }
      });
    }
  }

  TryAccess() {
    if (this.pass === this.CONSOLE_PASS) {
      this.accessGranted = true;
      setTimeout(() => {
        this.Init();
      }, 200);
    } else {
    }
  }

  InitializeDb() {
    this.initializingDb = true;
    this.templateService.UpdateTemplates().subscribe(result => {
      this.initializingDb = false;
      this.router.navigate(['/workarea']);
    });
  }

  DebugRepresentativeMolecule(repMolecule: IRepresentativeMolecule) {
    this.repMoleculeSelected = null;

    setTimeout(() => {
      this.repMoleculeSelected = repMolecule;
    }, 300);
  }

  RepMoleculeSelectedSettings() {
    this.workAreaService.SelectRepresentativeMolecule(this.repMoleculeSelected);
    const dragWindow = this.draggableWindowService.GenerateWindow(RepresentativePropertiesComponent, {
      title: '[DEBUG] Properties',
      data: null,
    });
    this.workAreaService.draggableWindow.push(dragWindow);

    dragWindow.Show();
  }

  RepMoleculeSelectedProcess() {
    this.workAreaService.SelectRepresentativeMolecule(this.repMoleculeSelected);
    const dragWindow = this.draggableWindowService.GenerateWindow(ProcessComponent, {
      title: '[DEBUG] Process',
      data: null,
    });
    this.workAreaService.draggableWindow.push(dragWindow);

    dragWindow.Show();
  }

  RepMoleculeSelectedProperties() {
    this.workAreaService.SelectRepresentativeMolecule(this.repMoleculeSelected);
    const dragWindow = this.draggableWindowService.GenerateWindow(JsonTreeComponent, {
      title: '[DEBUG] Properties',
      data: null,
    });
    this.workAreaService.draggableWindow.push(dragWindow);

    dragWindow.Show();
  }

  ShowData(data: any) {
    const dragWindow = this.draggableWindowService.GenerateWindow(JsonTreeComponent, {
      title: 'Data',
      data: null,
    });

    this.workAreaService.jsonTreeData = data;
    this.workAreaService.draggableWindow.push(dragWindow);

    dragWindow.Show();
  }

  toggleData(particle: any) {
    // console.log('toggle');
    particle.showData = !particle.showData;
  }

  toggleSession() {
    this.showSession = !this.showSession;
  }

  StartAllOverloadTests() {
    this.overloadTests.forEach(test => {
      this.StartOverloadTest(test);
    });
  }

  StartOverloadTest(test: {
    enable: boolean;
    name: string;
    url: string;
    requestCompleted: number;
    overloadTestRequests: any[];
    count: number;
    requestCount: number;
    requestInterval: number;
    interval: any;
    startTime: any;
    method: string;
    failedText: string;
    failed: number;
    elapsedTime: any;
    data: any;
  }) {
    const jwt = this.localStorageService.Get('jwt');

    if (test.enable) {
      test.startTime = Date.now();
      test.count = test.requestCount || 1;
      test.overloadTestRequests = [];
      test.requestCompleted = 0;
      test.failed = 0;
      test.failedText = '';

      test.interval = setInterval(() => {
        const xhr = new XMLHttpRequest();
        xhr.open(test.method, environment.apiUrl + test.url, true);
        xhr.setRequestHeader('Authorization', 'bearer ' + jwt);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = () => {
          if (xhr.status !== 200) {
            test.failed++;
            test.failedText = `${test.failed}/${test.requestCount}`;
          }

          test.requestCompleted++;
          if (test.requestCompleted === test.overloadTestRequests.length) {
            test.overloadTestRequests = [];
            test.elapsedTime = Date.now() - test.startTime;
          }
        };

        xhr.send(JSON.stringify(test.data));
        test.overloadTestRequests.push(xhr);

        test.count--;
        if (test.count === 0) {
          clearInterval(test.interval);
          test.interval = undefined;
        }
      }, test.requestInterval || 100);
    }

    // let count = this.requestCount;
    //
    // this.overloadTestRequests = [];
    // const url = environment.apiUrl + 'datasources/ProcessDataItems';
    // let requestCompleted = 0;
    //
    // let interval = setInterval
    // (
    //   () => {
    //     const xhr = new XMLHttpRequest();
    //     xhr.open('POST', url, true);
    //     xhr.setRequestHeader('Authorization', 'bearer ' + this.localStorageService.Get('jwt'));
    //     xhr.setRequestHeader('Content-Type', 'application/json');
    //     xhr.onload = () => {
    //       requestCompleted++;
    //       if (requestCompleted === this.overloadTestRequests.length) {
    //         this.overloadTestRequests = [];
    //       }
    //     };
    //
    //     const data = 4;
    //
    //     xhr.send(JSON.stringify(data));
    //     this.overloadTestRequests.push(xhr);
    //
    //     count--;
    //     if (count === 0) {
    //       clearInterval(interval);
    //       interval = undefined;
    //     }
    //   }, this.requestInterval || 100
    // );
  }

  AbortOverloadTest(test: any) {
    test.overloadTestRequests.forEach(r => {
      r.abort();
    });

    test.overloadTestRequests = [];
  }

  RefreshRepMoleculeUI() {
    this.communicationService.Event.Editor.$RefreshRepresentativeMoleculeUI.emit(this.repMoleculeSelected.Id);
  }

  Throttle(func, delay, context, args) {
    clearTimeout(this.inDebounce);
    this.inDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }

  RefreshAppUI() {}

  UpdateData() {
    this.repMoleculeSelected.$UpdateValue.emit();
  }

  RefreshParentRepMoleculeUI() {
    this.repMoleculeSelected.$RefreshParentUI.emit();
  }

  RefreshTableUI() {
    this.communicationService.Event.Editor.$RefreshRepresentativeTablesUI.emit(this.repMoleculeSelected.Id);
  }

  SelectRepMolecule(repMoleculeId: string) {
    this.repMoleculeSelected = this.busService.Get(repMoleculeId);
  }

  UpdateRepMolProperty() {
    this.propertiesService
      .SaveProperties([
        new PropertyVersioningDto({
          elementId: this.updateProperty.repMoleculeId.toString(),
          property: this.updateProperty.property,
          value: this.updateProperty.value,
          path: this.updateProperty.paht,
          change: 'Forge updated',
          name: '',
        }),
      ])
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

export interface UserConnection {
  userId: number;
  connectionsData: UserConnectionData[];
}

export interface UserConnectionData {
  user: any;
  system: any;
}

export class Employee {
  id: string;
  name: string;
  office: string;
  role: string;
  backgroundColor: string;
  upperManagerId?: string;
}
