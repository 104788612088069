import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from '../../../node_modules/rxjs';
import { ApiAuthService } from '../core/services/api-auth.service';
import { UserMenuService } from '../core/services/user-menu.service';
import { ErrorMessengerService } from '../shared/services/error-messenger.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: ApiAuthService,
    private router: Router,
    private errorMessengerService: ErrorMessengerService,
    private userMenuService: UserMenuService,
  ) {
  }
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log('other evaluating guard');
    
    if (this.authService.loggedIn()) {
      if (this.authService.EvaluateDefaultApp()) {
        // exists default app, redirect
      } else {
        if (this.userMenuService.canNavigateHere(route.data)) {
          return true;
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
    } else {
      this.errorMessengerService.ShowMessageOnBottom(
        'You must be logged in or registered on the system.',
        null,
      );
      this.router.navigate(['/']);
    }
  }
}
