import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { GenericDialogService } from '../../../core/services/generic-dialog.service';
import { TemplateService } from '../../../core/services/template.service';
import { CommunicationService } from '../../../shared/services/communication.service';
import { SnackerService } from '../../../shared/services/snacker.service';

@Component({
  selector: 'app-component-tree-node-menu',
  templateUrl: './component-tree-node-menu.component.html',
  styleUrls: ['./component-tree-node-menu.component.scss'],
})
export class ComponentTreeNodeMenuComponent implements OnInit {
  
  @ViewChild(MatMenuTrigger, { static: true })
  trigger: MatMenuTrigger;
  
  @Output() nodeRemoved: EventEmitter<any> = new EventEmitter<any>();
  
  @Input('componentNode')
  node: any;
  
  userPermissions = [];
  
  constructor(
    private communicationService: CommunicationService,
    private dialogService: GenericDialogService,
    private templateService: TemplateService,
    private snackerService: SnackerService,
  ) {
  }
  
  ngOnInit() {
  }
  
  public openContextMols($event: MouseEvent): void {
    this.trigger.openMenu();
    this.communicationService.Event.Editor.SidePanels.$KeepOpen.emit(1);
    $event.preventDefault();
    $event.stopPropagation();
  }
  
  public DeleteComponent() {
    this.dialogService.OpenConfirmDialog({
      title: 'Delete Component',
      message: `Are you sure you want to delete ${ this.node.name } component?`,
      confirmText: 'Delete',
      cancelText: 'Cancel',
    }).then(result => {
      if (result) {
        this.templateService.DeleteComponent(this.node.component.id).subscribe(response => {
          this.nodeRemoved.emit(this.node);
          this.snackerService.ShowMessageOnBottom('Component removed successfully', 'do_not_disturb_on', null, true);
        });
      }
    });
  }
}
