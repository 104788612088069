import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { faDatabase, faFileExcel, faServer } from '@fortawesome/free-solid-svg-icons';

import * as fileSaver from 'file-saver';
import { FileUploader } from 'ng2-file-upload';
import { debounceTime, finalize, map, switchMap, tap } from 'rxjs/operators';
import { IApiAuth } from '../admin/models/IApiAuth.interface';
import { ApiAdminService } from '../core/services/api-admin.service';
import { ApiDataSourcesService } from '../core/services/api-data-sources.service';
import { GenericDialogService } from '../core/services/generic-dialog.service';
import { ToolsService } from '../core/services/tools.service';
import { DatasourceType } from '../shared/enums/datasource-type.enum';
import { IDatasourceInfo } from '../shared/interfaces/datasource-info.interface';
import { SnackerService } from '../shared/services/snacker.service';
import { ApiDataQualityDataService } from '../workarea/data-quality/api-data-quality-data.service';
import { DocumentationService } from '../workarea/services/documentation.service';

declare const LeapXLCommunication;

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
  animations: [
    trigger('enabledStateChange', [
      state(
        'open',
        style({
          transform: 'translateX(350px)', opacity: 1,
        }),
      ),
      state(
        'close',
        style({
          transform: 'translateX(0px)', opacity: 1,
        }),
      ),
      transition('* => *', animate('500ms ease-out')),
    ]),
    trigger('search', [
      state(
        ':enter',
        style({
          transform: 'scaleX(0)', opacity: 1,
        }),
      ),
      state(
        ':leave',
        style({
          transform: 'scaleX(1)', opacity: 1,
        }),
      ),
      transition('* => *', animate('500ms ease-out')),
    ]),
    trigger('searchIcon', [
      transition('* => open', [
          animate(1000, keyframes([
            style({ transform: 'translateX(0px)', opacity: 0 }),
            style({ transform: 'translateX(350px)', opacity: 1 }),
          ])),
        ],
      ),
    ]),
    trigger('searchIcon', [
      transition('* => close', [
          animate(1000, keyframes([
            style({ transform: 'translateX(0px)', opacity: 1 }),
            // style({ transform: 'translateX(0px)', opacity: 0 })
          ])),
        ],
      ),
    ]),
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'scale(0)', opacity: 0 }),
        animate('300ms', style({ transform: 'scale(1)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('100ms', style({ transform: 'translateY(-5%)', opacity: 0 })),
      ]),
    ]),
    // trigger('search', [
    //   transition(':enter', [
    //     style({ transform: 'scaleX(0)' }),
    //     animate('2000ms', style({ transform: 'scaleX(1)' }))
    //   ]),
    //   transition(':leave', [
    //     style({ transform: 'scaleX(1)', opacity: 1 }),
    //     animate('1000ms', style({ transform: 'scaleX(0)', opacity: 0 }))
    //   ])
    // ]),
    // trigger('searchIcon', [
    //   transition(':enter', [
    //     style({ transform: 'scaleX(0)' }),
    //     animate('2000ms', style({ transform: 'scaleX(1)' }))
    //   ]),
    //   transition(':leave', [
    //     style({ transform: 'scaleX(1)', opacity: 1 }),
    //     animate('1000ms', style({ transform: 'scaleX(0)', opacity: 0 }))
    //   ])
    // ]),
    trigger('logoOut', [
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1 }),
        animate('200ms', style({ transform: 'scale(13)', opacity: 0 })),
      ]),
    ]),
    trigger('flyInOut', [
      transition('void => fly', [
        animate(
          400,
          keyframes([
            style({ transform: 'translateY(15%)', opacity: 0 }),
            style({ transform: 'translateY(0)', opacity: 1 }),
          ]),
        ),
      ]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class TestComponent implements OnInit {
  
  @ViewChild('searchInput', { static: false })
  newCustomNameValueInput: ElementRef;
  @ViewChild('moleculeFileSelector', { static: false })
  moleculeFileSelector: ElementRef;
  
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  userGuideSearchCtrl = new FormControl();
  userGuideFiltered: any;
  isHelpSearchLoading = false;
  searchOpen = false;
  searchIconAnimation = 'close';
  errorMsg: string;
  
  assignesList = [];
  classificationsList = [];
  languagesList = [];
  dialectsList = [];
  documentsList = [];
  assigneSelected = '';
  classificationSelected = '';
  languageSelected = '';
  dialectSelected = '';
  documentSelected = '';
  activeParent: boolean = false;
  uploader: FileUploader = new FileUploader({});
  js = '';
  DataSourceType = DatasourceType;
  spreadsheetIcon = faFileExcel;
  databaseIcon = faDatabase;
  unifiedDbIcon = faServer;
  datasourceInfo: IDatasourceInfo[] = [];
  
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Year';
  yAxisLabel: string = 'Normalized Population';
  timeline: boolean = true;
  multi: any[];
  view: any[] = [700, 300];
  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5'],
  };
  
  
  list = [
    { name: '1', subList: [{ name: '1.1' }, { name: '1.2' }, { name: '1.3' }] },
    { name: '2', subList: [{ name: '2.1' }, { name: '2.2' }, { name: '2.3' }] },
    { name: '3', subList: [{ name: '3.1' }, { name: '3.2' }, { name: '3.3' }] },
  ];
  dragOperation: boolean = false;
  containers: Array<Container> = [
    new Container(1, 'Container 1', [new Widget('1'), new Widget('2')]),
    new Container(2, 'Container 2', [new Widget('3'), new Widget('4')]),
    new Container(3, 'Container 3', [new Widget('5'), new Widget('6')]),
  ];
  widgets: Array<Widget> = [];
  registerApi = false;
  apiRegistered = false;
  apiRegistering = false;
  apiRegister = {
    id: 0,
    name: '',
    description: '',
    domain: '',
    secretKey: '',
    secretPassword: '',
    creationDate: '',
    username: '',
  };
  
  apisRegistered = [];
  
  progressType: 'progress' | 'spinner' = 'progress';
  value = 40;
  color = '#236544';
  backgroundColor = '#524898';
  diameter = 50;
  progressMode: 'determinate' | 'indeterminate' | 'buffer' | 'query' = 'indeterminate';
  strokeWidth = 5;
  
  constructor(protected http: HttpClient, private dsService: ApiDataSourcesService, private dqs: ApiDataQualityDataService,
              private documentationService: DocumentationService,
              private dialogService: GenericDialogService,
              private router: Router,
              private adminService: ApiAdminService,
              private toolsService: ToolsService,
              private snackerService: SnackerService,
              private _formBuilder: FormBuilder,
  ) {
    if (!toolsService.IsLocalEnvironment()) {
      this.router.navigate(['/login']);
    }
    Object.assign(this, { multi });
  }
  
  get progressBarMode() {
    return this.progressMode as ProgressBarMode;
  }
  
  get progressSpinnerMode() {
    return this.progressMode as ProgressSpinnerMode;
  }
  
  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required],
    });
    this.RefreshAPIsRegisteredList();
    
    // this.http.get(this.apiEndpointUrl + `/${id}`).subscribe(response => {
    //
    // });
    
    // this.FinicityTest();
    
    // this.getAuthorcontext('');
    
    const boxes = [
      ['.box-wrap.boxes.red', 252],
      ['.box-wrap.boxes.blue', 42],
      ['.box-wrap.boxes.green', 42],
    ];
    
    // for (const [sel, items] of boxes) {
    //   const container = document.querySelector(sel.toString());
    //
    //   for (let i = 0; i < items; i++) {
    //     container.appendChild(
    //       document.createElement('div')
    //     );
    //   }
    // }
    
    
    // const selection = Selection.create({
    //
    //   // Class for the selection-area
    //   class: 'selection',
    //
    //   // All elements in this container can be selected
    //   selectables: ['.box-wrap > div'],
    //
    //   // The container is also the boundary in this case
    //   boundaries: ['.box-wrap']
    // }).on('start', ({ inst, selected, oe }) => {
    //
    //   // Remove class if the user isn't pressing the control key or ⌘ key
    //   if (!oe.ctrlKey && !oe.metaKey) {
    //
    //     // Unselect all elements
    //     for (const el of selected) {
    //       el.classList.remove('selected');
    //       inst.removeFromSelection(el);
    //     }
    //
    //     // Clear previous selection
    //     inst.clearSelection();
    //   }
    //
    // }).on('move', ({ changed: { removed, added } }) => {
    //
    //   // Add a custom class to the elements that where selected.
    //   for (const el of added) {
    //     el.classList.add('selected');
    //   }
    //
    //   // Remove the class from elements that where removed
    //   // since the last selection
    //   for (const el of removed) {
    //     el.classList.remove('selected');
    //   }
    //
    // }).on('stop', ({ inst }) => {
    //   inst.keepSelection();
    // });
    
    
    // this.dqs.GetDataSourceInfo(['Spreadsheet.USDA Inventory4.Vehicle Inventory Log.B4']).subscribe(result => {
    //   // console.log(result);
    //   this.datasourceInfo = result;
    // });
    
    this.userGuideSearchCtrl.valueChanges.pipe(
      debounceTime(500),
      tap(() => {
        this.errorMsg = '';
        this.userGuideFiltered = [];
        this.isHelpSearchLoading = true;
      }),
      switchMap(value => {
          
          return this.documentationService.SearchDocumentationInfoByTags(value).pipe(finalize(() => {
              setTimeout(() => {
                this.isHelpSearchLoading = false;
              }, 700);
            }),
          );
        },
      ),
    ).subscribe(data => {
      this.userGuideFiltered = data;
    });
    
    // Set mat-progress colors
    this.toolsService.GenerateClassDynamically(
      '.mat-progress-bar-buffer',
      `background-color: ${ this.backgroundColor }`,
    );
    this.toolsService.GenerateClassDynamically(
      `${ this.progressType === 'progress' ? '.mat-progress-bar-fill::after' : '.mat-progress-spinner circle, .mat-spinner circle' }`,
      `${ this.progressType === 'progress' ? `background-color: ${ this.color }` : `stroke: ${ this.color }` }`,
    );
  }
  
  
  FinicityTest() {
    const finicityConnectUrl = 'https://connect2.finicity.com?customerId=1015204483&partnerId=2445583343075&signature=acf0c45a41ae2b9b31dd14d634b1cec8ed31d003c2caffae231c0d2ae6fdfe1a&timestamp=1605037255061&ttl=1605044455061';
    
    (window as any).finicityConnect.connectIFrame(finicityConnectUrl, {
      selector: '#connect-container',
      overlay: 'rgba(255,255,255, 0)',
      success: function(data) {
        console.log('Yay! We got data', data);
      },
      cancel: function() {
        console.log('The user cancelled the iframe');
      },
      error: function(err) {
        console.error('Some runtime error was generated during Finicity Connect', err);
      },
      loaded: function() {
        console.log('This gets called only once after the iframe has finished loading');
      },
      route: function(event) {
        console.log('This is called as the user progresses through Connect');
      },
    });
  }
  
  displayFn(data: any): string {
    // console.log(data);
    return '';
  }
  
  RunTest() {
    let time = 0;
    for (let index = 0; index < 5; index++) {
      setTimeout(() => {
        this.GetCobbleTest();
      }, 1000 * time);
      time += 5;
    }
  }
  
  GetCobbleTest() {
    for (let index = 0; index < 7; index++) {
      this.GetCobbleBySlug().subscribe();
    }
  }
  
  GetCobbleBySlug() {
    return this.http.get(
      // `http://localhost:54779/api/molecules/GetCobbleBySlug/product-management`
      `https://api4.cobbler.app/api/molecules/GetCobbleBySlug/product-management`,
    ).pipe(map((response) => <any>response));
  }
  
  onSorting(isParent: boolean) {
    // console.log(isParent);
    this.activeParent = isParent;
  }
  
  addTo($event: any) {
    if ($event) {
      this.widgets.push($event.dragData);
    }
  }
  
  dropdownSelection(dropdown: string) {
    // console.log('dropdown change', dropdown);
    switch (dropdown) {
      case 'assignee':
        this.classificationSelected = '';
        this.languageSelected = '';
        this.dialectSelected = '';
        this.documentSelected = '';
        setTimeout(() => {
          this.getAuthorcontext(dropdown);
        }, 200);
        break;
      
      case 'classification':
        this.languageSelected = '';
        this.dialectSelected = '';
        this.documentSelected = '';
        setTimeout(() => {
          this.getAuthorcontext(dropdown);
        }, 200);
        break;
      
      case 'language':
        this.dialectSelected = '';
        this.documentSelected = '';
        setTimeout(() => {
          this.getAuthorcontext(dropdown);
        }, 200);
        break;
      
      case 'dialect':
        this.documentSelected = '';
        setTimeout(() => {
          this.getAuthorcontext(dropdown);
        }, 200);
        break;
      
      case 'document':
        
        break;
    }
  }
  
  getAuthorcontext(dropdown: string) {
    // console.log(this.documentsList, this.assignesList, this.dialectsList, this.languagesList);
    this.dsService.GetAuthorContext(this.assigneSelected, this.classificationSelected, this.languageSelected, this.dialectSelected, this.documentSelected).subscribe(Response => {
      switch (dropdown) {
        case 'assignee':
          this.classificationsList = Response;
          break;
        
        case 'classification':
          this.languagesList = Response;
          break;
        
        case 'language':
          this.dialectsList = Response;
          break;
        
        case 'dialect':
          this.documentsList = Response;
          break;
        
        case '':
          this.assignesList = Response;
          break;
      }
    });
  }
  
  handleError(error: any, arg1: string, guid: string) {
  }
  
  ToggleSearch() {
    this.searchOpen = !this.searchOpen;
    this.searchIconAnimation = this.searchOpen ? 'open' : 'close';
    
    if (this.searchOpen) {
      setTimeout(() => {
        this.newCustomNameValueInput.nativeElement.focus();
        this.newCustomNameValueInput.nativeElement.select();
      }, 200);
    }
  }
  
  SearchIconAnimationCompleted() {
    
    return;
    const searchIcon = document.querySelector('.search-icon');
    
    if (searchIcon) {
      
      if (this.searchOpen) {
        searchIcon.setAttribute('style',
          'transform: translateX(350px)');
      } else {
        // searchIcon.setAttribute('style',
        //   'transform: translateX(0px)');
      }
    }
  }
  
  DeleteAPI(api: IApiAuth) {
    this.dialogService.OpenConfirmDialog({
      title: 'Delete API Key',
      message: `Are you sure you want to remove this API key?, once removed this operation can't be undone and any API using this credentials is going to lose access.`,
      confirmText: 'Delete',
      cancelText: 'Cancel',
    }).then(result => {
      if (result) {
        // this.adminService.DeleteAPIAuthentication(api.id).subscribe(response => {
        //   this.apisRegistered = this.apisRegistered.filter(ar => ar.id !== api.id);
        //   this.snackbar.open('API credentials deleted successfully', '', {
        //     duration: 2000
        //   });
        // });
      } else {
      
      }
    });
  }
  
  GoToAPIsList() {
    this.registerApi = false;
    this.ResetAPIRegister();
  }
  
  ResetAPIRegister() {
    this.registerApi = false;
    this.apiRegistered = false;
    this.apiRegistering = false;
    this.apiRegister = {
      id: 0,
      name: '',
      description: '',
      domain: '',
      secretKey: '',
      secretPassword: '',
      creationDate: '',
      username: '',
    };
  }
  
  RegisterApi() {
    this.registerApi = true;
  }
  
  GenerateAPICredentials() {
    this.apiRegistering = true;
    
    // this.adminService.APIRegistration(this.apiRegister).subscribe(response => {
    //   this.apisRegistered.push(_.cloneDeep(response));
    //   this.apiRegister = response;
    //   this.apiRegistered = true;
    //   this.apiRegistering = false;
    //
    //   this.snackbar.open('API credentials generated', '', {
    //     duration: 2500
    //   });
    // });
  }
  
  RefreshAPIsRegisteredList() {
    // this.adminService.RegisteredAPIS().subscribe(apis => {
    //   this.apisRegistered = apis;
    // });
  }
  
  Copy(text: string) {
    // this.snackbar.open('Copied to clipboard!', '', {
    //   duration: 1500,
    // });
    this.toolsService.CopyToClipboard(text);
  }
  
  SelectFile(e: any) {
    console.warn('opened');
    document.getElementById('moleculeFileSelector').click();
    // const fileInput = document.createElement('input');
    // fileInput.type = 'file';
    // fileInput.accept = 'image/*';
    //
    // fileInput.trigger('click');
    //
    // fileInput.on('change', function(ev) {
    //   console.log('nothing', ev.target.files[0]);
    // });
    
    this.moleculeFileSelector.nativeElement.value = null;
    
  }
  
  fileSelected(eventData: any) {
    console.warn(eventData.target.files);
  }
  
  DownloadAPICredentials() {
    
    const credentials = [
      'LeapXL API Registered Credentials File\n\n',
      this.apiRegister.name + ': ' + this.apiRegister.domain + '\n',
      'Registered By: ' + this.apiRegister.username + '\n',
      'Registered On: ' + this.apiRegister.creationDate + '\n\n',
      '===================================================================\n\n',
      'Secret Key:\n',
      `${ this.apiRegister.secretKey }\n\n`,
      'Secret Password:\n',
      `${ this.apiRegister.secretPassword }\n\n`,
      '===================================================================\n\n',
      '**Store this file in a secure place, this file contains critical information and access credentials**',
    ];
    
    // this.snackbar.open('Downloading file...', '', {
    //   duration: 1000,
    // });
    //
    const credentialsBlob = new Blob(credentials, { type: 'text/plain;charset=utf-8' });
    fileSaver.saveAs(credentialsBlob, this.apiRegister.name.replace(/ /g, '_') + '_leapxl_auth.txt');
  }
  
  //////// ace
  
  scrollTo() {
    const element = document.getElementsByClassName('slides')[0];
    const scrollAmount = 500; // replace with desired scroll position
    const duration = 500; // replace with desired duration in milliseconds
    var start = element.scrollTop,
      change = scrollAmount - start,
      currentTime = 0,
      increment = 20;
    
    let easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t + b;
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    };
    console.log(change);
    
    var animateScroll = () => {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollLeft = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }
  
}

class Container {
  constructor(
    public id: number,
    public name: string,
    public widgets: Array<Widget>,
  ) {
  }
}

class Widget {
  constructor(public name: string) {
  }
}

export const multi = [
  {
    'name': 'Germany',
    'series': [
      {
        'name': '1990',
        'value': 62000000,
      },
      {
        'name': '2010',
        'value': 73000000,
      },
      {
        'name': '2011',
        'value': 89400000,
      },
    ],
  },
  
  {
    'name': 'USA',
    'series': [
      {
        'name': '1990',
        'value': 250000000,
      },
      {
        'name': '2010',
        'value': 309000000,
      },
      {
        'name': '2011',
        'value': 311000000,
      },
    ],
  },
  
  {
    'name': 'France',
    'series': [
      {
        'name': '1990',
        'value': 58000000,
      },
      {
        'name': '2010',
        'value': 50000020,
      },
      {
        'name': '2011',
        'value': 58000000,
      },
    ],
  },
  {
    'name': 'UK',
    'series': [
      {
        'name': '1990',
        'value': 57000000,
      },
      {
        'name': '2010',
        'value': 62000000,
      },
    ],
  },
];
