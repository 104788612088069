import { Injectable } from '@angular/core';
import { EventsService } from '../../core/molecular/services/events.service';
import { ApiDataTranslatorService } from '../../core/services/api-data-translator.service';
import { TemplateService } from '../../core/services/template.service';
import { ToolsService } from '../../core/services/tools.service';
import { Constants } from '../constants';
import { DatasourceType } from '../enums/datasource-type.enum';
import { DragType } from '../enums/drag-type.enum';
import { LeapXLEventType } from '../enums/leapxl-event-type.enum';
import { ParticleType } from '../enums/particle-type.enum';
import { RepresentativeMoleculesType } from '../enums/representative-molecules-types.enum';
import { ActionMoleculeProperties } from '../representative-molecule/interfaces/action-molecule-properties';
import { ActionMolecule } from '../representative-molecule/interfaces/action-molecules';
import { DataElement } from '../representative-molecule/interfaces/data-element';
import { LeapXLEvent } from '../representative-molecule/interfaces/leapxl-event';
import { Particle } from '../representative-molecule/interfaces/particle';
import { IRepresentativeMolecule } from '../representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../representative-molecule/services/cobble.service';
import { CommunicationService } from './communication.service';

@Injectable({
  providedIn: 'root',
})
export class FactoryParticleService {
  
  private particleTypes = {
    Event: LeapXLEvent,
    DataElement: DataElement,
    Molecule: ActionMolecule,
  };
  
  constructor(
    private templateService: TemplateService,
    private dataTranslatorService: ApiDataTranslatorService,
    private cobbleService: CobbleService,
    private toolsService: ToolsService,
    private eventsService: EventsService,
    private communicationService: CommunicationService) {
  }
  
  CreateActionMoleculeParticle(actionMoleculeNameType: string, repMoleculeId: number): Promise<ActionMolecule> {
    
    return new Promise(resolve => {
      this.templateService.GetActionMoleculeProperties([actionMoleculeNameType]).subscribe((moleculeProperties) => {
        
        const actionMolecule = this.GenerateActionMolecule(moleculeProperties[0], repMoleculeId);
        
        return resolve(actionMolecule);
      });
    });
  }
  
  GenerateActionMolecule(properties: ActionMoleculeProperties, repMoleculeId: number = 0, dataElements: any[] = [], rule = null, logic = null): ActionMolecule {
    
    const actionMolecule = new ActionMolecule({
      particleId: this.toolsService.GenerateGuid(),
      id: this.toolsService.GenerateGuid(),
      molecule: properties.type,
      name: properties.name,
      moleculeType: properties.moleculeType,
      icon: properties.icon,
      rule: rule || {},
      logic: logic || {},
      moleculeId: repMoleculeId,
      dataElements: dataElements,
      touched: dataElements.length > 0 || (rule && Object.keys(rule).length > 0),
    });
    
    return actionMolecule;
  }
  
  CreateEventParticle(eventSource: string, sourceId: string, eventType: string): Promise<LeapXLEvent> {
    return new Promise(resolve => {
      
      const event = new LeapXLEvent({
        eventName: eventType,
        eventSource: eventSource,
        sourceId: sourceId,
        eventType: eventType,
        data: null,
        particleType: ParticleType.Event,
      });
      
      return resolve(event);
    });
  }
  
  CreateAndTranslateDataElements(dataElements: DataElement[], appId: number, dragType: string = '', editorDbMode = false): Promise<DataElement[]> {
    
    if (dataElements.length === 0) {
      return new Promise(resolve => {
        resolve([]);
      });
    }
    
    dataElements.forEach((de) => {
      de.AssingId();
    });
    
    const dataElementsTranslation = [];
    const firstDataElement = new DataElement(dataElements[0]);
    let secondDataElement = new DataElement();
    
    if (dragType === DragType.Spreadsheet) {
      // evaluate for range
      firstDataElement.Reference = firstDataElement.Reference.split(':')[0];
      if (firstDataElement.DataSourceType === DatasourceType.Spreadsheet) {
        firstDataElement.Context = `${ DatasourceType.Spreadsheet }${ Constants.ContextSeparator }${
          firstDataElement.DataSourceName
        }${ Constants.ContextSeparator }${ firstDataElement.Collection }${ Constants.ContextSeparator }${ firstDataElement.Reference }`;
      }
      
      dataElementsTranslation.push({
        dataSourceId: firstDataElement.DataSourceId,
        applicationId: firstDataElement.ApplicationId,
        dataSourceType: firstDataElement.DataSourceType,
        specialMode: editorDbMode,
        context: firstDataElement.Context,
        reference: firstDataElement.Reference,
      });
      
      secondDataElement = new DataElement(firstDataElement);
      
      if (
        dataElements[0].Reference.split(':')[1] &&
        firstDataElement.Reference !== dataElements[0].Reference.split(':')[1]
      ) {
        secondDataElement.AssingId();
        secondDataElement.AssingParticleId();
        secondDataElement.Reference = dataElements[0].Reference.split(':')[1];
        secondDataElement.Context = `${ DatasourceType.Spreadsheet }${ Constants.ContextSeparator }${
          secondDataElement.DataSourceName
        }${ Constants.ContextSeparator }${ secondDataElement.Collection }${ Constants.ContextSeparator }${ secondDataElement.Reference }`;
        
        dataElementsTranslation.push({
          dataSourceId: secondDataElement.DataSourceId,
          applicationId: secondDataElement.ApplicationId,
          dataSourceType: secondDataElement.DataSourceType,
          specialMode: editorDbMode,
          context: secondDataElement.Context,
          reference: secondDataElement.Reference,
        });
      }
      
    } else {
      dataElements.forEach((dataElement) => {
        dataElementsTranslation.push({
          dataSourceId: null,
          applicationId: appId,
          dataSourceType: dataElement.DataSourceType,
          specialMode: editorDbMode,
          context: dataElement.Context,
          reference: dataElement.Reference,
        });
      });
    }
    
    return new Promise(resolve => {
      this.dataTranslatorService.CreateTranslation(dataElementsTranslation).subscribe((translations) => {
        
        if (dataElements.length > 1) {
          dataElements.forEach((dataElement, index) => {
            const translation = translations.find(t => t.context.toLowerCase() === dataElement.Context.toLowerCase());
            
            dataElement.TranslationId = translation.id;
            dataElement.InternalName = translation.internalName;
            dataElement.Reference = translation.internalName;
          });
          
          return resolve(dataElements);
        } else {
          firstDataElement.TranslationId = translations[0].id;
          
          if (translations.length > 1) {
            secondDataElement.TranslationId = translations[1].id;
            firstDataElement.RangeParticleId = secondDataElement.ParticleId;
            secondDataElement.RangeParticleId = firstDataElement.ParticleId;
          }
          
          const dataElementsToReturn = [firstDataElement];
          
          if (secondDataElement.TranslationId > 0) {
            dataElementsToReturn.push(secondDataElement);
          }
          return resolve(dataElementsToReturn);
        }
      });
    });
  }
  
  GetParticle(particle: any = {}): any {
    const particleType = particle.particleType || particle.ParticleType || '';
    return this.particleTypes.hasOwnProperty(particleType) ?
      (new this.particleTypes[particleType](particle)) : new Particle(particle);
    
  }
  
  GenerateCustomEvent(name: string): LeapXLEvent {
    name = this.GenerateUniqueCustomEventName(name);
    const customEvent = this.GenerateEvent('Custom User', LeapXLEventType.Custom, name);
    customEvent.EventName = name;
    customEvent.SetIdentifier();
    this.eventsService.AddAndSaveCustomEvent(customEvent);
    return customEvent;
  }
  
  GenerateOrGetCustomEventFromDataElement(dataElement: DataElement, addIdentifier: string = null): LeapXLEvent {
    const customEventId = this.toolsService.GenerateIdFromContext(dataElement.Context) + (addIdentifier ? addIdentifier : '');
    const event = this.eventsService.CustomEvents.find(ce => ce.EventName === customEventId);
    
    if (event) {
      return event;
    } else {
      const customEvent = this.GenerateCustomEvent(customEventId);
      return customEvent;
    }
  }
  
  GenerateUniqueCustomEventName(name: string, id = 0) {
    const newName = id === 0 ? name + '' : name + '-' + id.toString();
    if (this.eventsService.CustomEvents.map(ce => ce.EventName.toLowerCase()).includes(newName.toLowerCase())) {
      return this.GenerateUniqueCustomEventName(name, id + 1);
    } else {
      return newName;
    }
  }
  
  GenerateEvent(eventType: string, eventSource?: string, sourceId?: any, data: any = null): LeapXLEvent {
    
    const event = new LeapXLEvent({
      eventName: eventType,
      eventSource: eventSource || 'Molecule',
      sourceId: sourceId || 0,
      eventType: eventType,
      data: data,
      particleType: ParticleType.Event,
      particleId: this.toolsService.GenerateGuid(),
      id: this.toolsService.GenerateGuid(),
    });
    
    switch (eventType) {
      
      case LeapXLEventType.AppInit:
      case LeapXLEventType.AppBroadcast:
        event.SourceId = this.cobbleService.Cobble.id;
        event.EventSource = 'System';
        break;
      case LeapXLEventType.ViewStart:
      case LeapXLEventType.ViewEnd:
      case LeapXLEventType.ViewChange:
        event.EventSource = 'View';
        break;
      case LeapXLEventType.Custom:
        event.EventSource = 'Custom';
        event.SourceId = this.toolsService.GenerateGuid();
        break;
      
      default:
        break;
    }
    
    return event;
  }
  
  SetupMoleculeDefaultsForDataElements(actionMolecule: ActionMolecule, repMolecule: IRepresentativeMolecule) {
    
    if ((actionMolecule.DataElements.length === 0)) {
      return;
    }
    
    this.templateService.GetActionMoleculeProperties([actionMolecule.InternalMoleculeName]).subscribe((properties) => {
      const moleculeProperties = properties[0];
      const moleculeDefaults =
        properties[0].defaults.find((d) =>
          d.elementType.includes(repMolecule.Type),
        ) ||
        properties[0].defaults.find((d) =>
          d.elementType.includes(RepresentativeMoleculesType.All),
        );
      
      if (moleculeDefaults.dataElements) {
        moleculeDefaults.dataElements.copyTo.forEach(copy => {
          const buses = repMolecule.GetBusByReceptorAndMoleculeNameType([copy.bus], copy.molecule);
          
          if (buses.length > 0) {
            const bus = buses[0];
            
            const actionMoleculeToCopy = bus.GetActionMoleculeParticle(copy.molecule);
            
            if (copy.append) {
              actionMoleculeToCopy.AddDataElements(actionMolecule.DataElements);
            } else {
              if (actionMoleculeToCopy.DataElements.length === 0) {
                actionMoleculeToCopy.AddDataElements(actionMolecule.DataElements);
              }
            }
            
          } else {
            if (copy.createIfNotExists) {
              // create bus and add molecule
            }
          }
          
        });
      }
    });
  }
  
  SetupDefaultsForMolecule(
    moleculeContext: any,
    parent?: IRepresentativeMolecule,
    moleculeValues?: any,
    droppedOnBus = false,
  ) {
    console.log('default setup');
    
    this.templateService.GetActionMoleculeProperties([moleculeContext.type]).subscribe((properties) => {
      const contextKey = 'default';
      
      const moleculeDefaults =
        properties[0].defaults.find((d) =>
          d.elementType.includes(parent.MoleculeSubType),
        ) ||
        properties[0].defaults.find((d) =>
          d.elementType.includes(RepresentativeMoleculesType.All),
        );
      const moleculeDefaultscontexts = moleculeDefaults.contexts;
      
      const moleculeDefaultContext = moleculeDefaultscontexts.find((c) =>
        c.keys.includes(contextKey),
      );
      
      let processBus = null;
      
      if (!droppedOnBus) {
        for (const busSetup of moleculeDefaultContext.setup.bus) {
          // console.log('bussetup', busSetup);
          // console.log('parent.Buses', parent.Buses);
          
          processBus = parent.Buses.find(
            (b) =>
              b.Receptor === busSetup.receptor &&
              b.HasParticles() &&
              b.FirstParticle().IsEvent() &&
              busSetup.event.includes((b.FirstParticle() as LeapXLEvent).EventType),
          );
          
          if (processBus) {
            // exists
          } else {
            processBus = parent.GenerateNewBus(false, null, busSetup.receptor);
            processBus.AddParticle(parent.GenerateEvent(busSetup.event));
          }
          
          let actionMolecule = processBus.GetActionMoleculeParticle(properties[0].type);
          
          if (actionMolecule) {
            // exists
          } else {
            actionMolecule = parent.GenerateActionMolecule(properties[0]);
            
            processBus.AddParticle(
              actionMolecule,
            );
          }
          
          if (moleculeValues) {
            actionMolecule.Rule = moleculeValues;
          }
          
          this.communicationService.Event.Editor.$ActionMoleculeAdded.emit({ actionMolecule, repMolecule: parent });
          this.communicationService.Event.Editor.$RepresentativeMoleculeDetection.emit({ repMoleculeId: +parent.Id, state: true });
          break;
        }
      }
      
      
      // molecule dependencies setup
      if (moleculeDefaults.dependencies && moleculeDefaults.dependencies.length > 0) {
        for (const dependency of moleculeDefaults.dependencies) {
          
          let bus = null;
          // region SETTING BUS
          if (dependency.bus === 'same') {
            bus = processBus;
          } else {
            bus = parent.Buses.find(
              (b) =>
                b.Receptor === dependency.bus,
            );
            
            if (bus) {
              // exists
            } else {
              bus = parent.GenerateNewBus(false, null, dependency.bus);
            }
          }
          // endregion
          
          dependency.molecules.forEach(molecule => {
            const moleculeCreated = this.CreateActionMoleculeParticle(molecule.name, parent.Id).then(actionMolecule => {
              
              if (molecule.sameValue) {
                actionMolecule.Rule = moleculeValues;
              } else if (molecule.value) {
                actionMolecule.Rule = molecule.value;
              }
              
              bus.AddParticle(actionMolecule);
            });
            
          });
          
        }
      }
      
      setTimeout(() => {
        parent.SaveProperty('buses', 'Molecule Added').subscribe();
      }, 500);
    });
  }
  
}
