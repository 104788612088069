import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EditorStateService } from '../../../core/services/editor-state.service';
import { DataAuditDto } from '../../../shared/dtos/data-audit-dto';
import { DraggableWindowService } from '../../../shared/services/draggable-window.service';
import { ApiDataQualityDataService } from '../api-data-quality-data.service';

@Component({
  selector: 'app-data-quality',
  templateUrl: './data-quality.component.html',
  styleUrls: ['./data-quality.component.scss'],
})
export class DataQualityComponent implements AfterViewInit, OnInit {
  displayedColumns = [
    'date',
    'cobbleName',
    'type',
    'origin',
    'collection',
    'user',
    'cell',
    'dataBefore',
    'dataAfter',
    'dataSubmitted',
    'accessPoint',
    'formula',
  ];
  
  dataSource: MatTableDataSource<DataAuditDto>;
  data: any;
  title: string;
  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  audit = true;
  
  constructor(
    public dialog: MatDialog,
    private dataQualityDataService: ApiDataQualityDataService,
    private draggableWindowService: DraggableWindowService,
    private editorStateService: EditorStateService,
    @Inject(MAT_DIALOG_DATA) public _data: any,
  ) {
    this.dataSource = new MatTableDataSource(_data.dataSource);
    this.data = _data;
  }
  
  showAudit(show: boolean) {
    this.audit = show;
  }
  
  auditOpened() {
    this.audit = true;
    
    this.dataQualityDataService
    .GetDataAuditForRange({
      dataSourceId: this.data.dataSourceId,
      collection: this.data.collection,
      range: this.data.dataRange,
    })
    .subscribe(audits => {
      this.dataSource = new MatTableDataSource(audits);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  
  showLogData(id: number, name: string) {
    this.dataQualityDataService
    .GetDataSubmittedForRange(id)
    .subscribe(dataItems => {
      const dialogRef = this.dialog.open(LogDataDialogComponent, {
        data: { name: name, dataItems: dataItems.data },
      });
    });
  }
  
  ngOnInit() {
  }
  
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
  // ShowDrillDown(data: any) {
  //   this.dialog.closeAll();
  //   this.draggableWindowService
  //     .GenerateWindow(DrillDownComponent, {
  //       data: {
  //         dataSourceId: data.dataSourceId,
  //         collection: data.collection,
  //         range: data.dataRange
  //       }
  //     })
  //     .Show();
  // }
}

@Component({
  selector: 'log-data-dialog',
  templateUrl: 'log-data-dialog.html',
})
export class LogDataDialogComponent implements OnInit {
  data: any;
  
  constructor(
    private dataQualityDataService: ApiDataQualityDataService,
    public dialogRef: MatDialogRef<LogDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
  ) {
    this.data = _data;
  }
  
  ngOnInit() {
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }
}
