import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { cloneDeep, uniqBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Permissions } from '../../admin/models/permissions.enum';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { EditorStateService } from '../../core/services/editor-state.service';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { TemplateService } from '../../core/services/template.service';
import { ToolsService } from '../../core/services/tools.service';
import { UserMenuService } from '../../core/services/user-menu.service';
import { RepresentativeMoleculeTypeIcons } from '../../shared/enums/representative-molecule-type-icons';
import { RepresentativeMoleculesType } from '../../shared/enums/representative-molecules-types.enum';
import { Theme } from '../../shared/interfaces/theme.interface';
import { Cobble } from '../../shared/representative-molecule/interfaces/cobble';
import { RepresentativeMolecule } from '../../shared/representative-molecule/interfaces/representative-molecule';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { DraggableWindowManagerService } from '../../shared/services/draggable-window-manager.service';
import { DraggableWindowService, DraggableWindowType } from '../../shared/services/draggable-window.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { ThematicService } from '../services/thematic.service';
import { WorkAreaService } from '../workarea.service';

interface MoleculeStyle {
  type: string;
  styles: any[];
  styleSelected: {
    asStyle: boolean;
    styleId: number;
    representativeMoleculeType: string;
    overrideThemeStyle: boolean;
  };
  styleInput: FormControl;
  styleOptions: Observable<any[]>;
}

@Component({
  selector: 'app-app-theme-configuration',
  templateUrl: './app-theme-configuration.component.html',
  styleUrls: ['./app-theme-configuration.component.scss'],
})
export class AppThemeConfigurationComponent implements OnInit {
  @Input() app: Cobble;
  @Input() showThemeSearch: boolean = false;
  @Input() maxHeight: string = '268px';
  DraggableWindowType = 'AppThemeConfigurationComponent';
  representativeMoleculeTypes = [];
  moleculeStyles: { [id: string]: MoleculeStyle } = {};
  stylesSelected = [];
  themeSelected: Theme;
  themesFieldLabel = 'Theme';
  appLockTheme = false;
  modifyAppThemePermission = false;
  modifyAppThemeStylesPermission = false;
  isOwner = false;
  themeCompletePermissions = false;
  representativeMoleculeTypeIcons = RepresentativeMoleculeTypeIcons;
  loadOnlyUsersTheme = true;
  newTheme = false;
  
  constructor(
    private thematicService: ThematicService,
    private templateService: TemplateService,
    private draggableWindowService: DraggableWindowService,
    private cobbleService: CobbleService,
    private editorStateService: EditorStateService,
    private workAreaService: WorkAreaService,
    private draggableWindowManagerService: DraggableWindowManagerService,
    private toolsService: ToolsService,
    private communicationService: CommunicationService,
    private userMenuService: UserMenuService,
    private clientStorageService: ClientStorageService,
    private snackerService: SnackerService,
    private dialogService: GenericDialogService,
  ) {
    this.isOwner = this.clientStorageService.getUserId() === this.cobbleService.Cobble.ownerId;
  }
  
  ngOnInit(): void {
    
    this.appLockTheme = this.app && this.app.lockAppDefaultTheme && this.app.appTheme?.id > 0;
    this.themeCompletePermissions = this.isOwner || this.userMenuService.checkPermission(Permissions.ModifyThemeOnDefaultThemedApps);
    this.modifyAppThemeStylesPermission = (this.userMenuService.checkPermission(Permissions.ModifyThemedAppStyle) && !this.appLockTheme) || this.themeCompletePermissions;
    this.modifyAppThemePermission = (this.userMenuService.checkPermission(Permissions.ModifyAppTheme) && !this.appLockTheme) || this.themeCompletePermissions;
    
    const notUsedRepMoleculeTypes = [RepresentativeMoleculesType.Report, RepresentativeMoleculesType.All];
    this.representativeMoleculeTypes = Object.keys(RepresentativeMoleculesType).filter((type: RepresentativeMoleculesType) => !notUsedRepMoleculeTypes.includes(type));
    
    if (this.app) {
      this.showThemeSearch = true;
    } else {
      this.loadOnlyUsersTheme = !this.themeCompletePermissions;
    }
    
    this.InitTheme(this.app?.appTheme);
    const data = this.draggableWindowService.GetData();
    
    if (data) {
      this.showThemeSearch = data.showThemeSearch || this.showThemeSearch;
      this.themesFieldLabel = data.themesFieldLabel || this.themesFieldLabel;
      this.maxHeight = data.maxHeight || this.maxHeight;
      
      if (data.theme) {
        this.InitTheme(data.theme);
      } else {
        this.newTheme = true;
      }
    }
  }
  
  InitTheme(theme?: Theme) {
    if (theme === undefined || theme === null || (Object.keys(theme).length === 0 && theme.constructor === Object)) {
      this.themeSelected = {
        id: 0,
        name: '',
        description: 'Theme Description',
        representativeMoleculeStyles: {},
      };
    } else {
      this.themeSelected = theme;
    }
    this.SetStylesByType();
  }
  
  SetStylesByType() {
    this.moleculeStyles = {};
    this.stylesSelected = [];
    
    this.representativeMoleculeTypes.forEach(repMol => {
      const uniqueLibrary = uniqBy(this.thematicService.StylesLibrary, (s) => {
        return s.styleId;
      });
      
      const styles = uniqueLibrary.filter(s => s.representativeMoleculeType === repMol).sort();
      
      const moleculeStyle: MoleculeStyle = {
        type: repMol,
        styles,
        styleSelected: {
          asStyle: false,
          styleId: null,
          overrideThemeStyle: false,
          representativeMoleculeType: repMol,
        },
        styleInput: new FormControl(''),
        styleOptions: null,
      };
      
      moleculeStyle.styleOptions = moleculeStyle.styleInput.valueChanges.pipe(
        startWith(''),
        map(value => this.filter(value || '', styles)),
      );
      
      if (styles.length === 0 || !this.modifyAppThemeStylesPermission) {
        moleculeStyle.styleInput.disable();
      }
      
      if (this.themeSelected && this.themeSelected.representativeMoleculeStyles) {
        Object.keys(this.themeSelected.representativeMoleculeStyles).forEach(type => {
          
          const style = this.themeSelected.representativeMoleculeStyles[type];
          
          if (style && type === repMol) {
            moleculeStyle.styleSelected.asStyle = style.asStyle;
            moleculeStyle.styleSelected.overrideThemeStyle = style.overrideThemeStyle;
          }
          
          if (style && type === repMol && style.styleId !== null) {
            const styleData = this.thematicService.GetStyle(this.themeSelected.representativeMoleculeStyles[type].styleId);
            
            if (styleData) {
              moleculeStyle.styleSelected.styleId = styleData.styleId;
              moleculeStyle.styleInput.setValue(styleData.name);
            }
            
            this.stylesSelected.push(type);
          }
        });
      }
      
      this.moleculeStyles[moleculeStyle.type] = moleculeStyle;
    });
  }
  
  filter(value: string, options: any[]): any[] {
    const filterValue = value.toLowerCase();
    return options.filter(theme => theme.name.toLowerCase().includes(filterValue));
  }
  
  StyleSelected(styleName: string, type: string) {
    const moleculeStyle = this.moleculeStyles[type];
    
    if (this.app) {
      console.warn(this.themeSelected);
      const oldStyle = this.themeSelected.representativeMoleculeStyles?.[type];
      
      if (oldStyle) {
        this.thematicService.UnAssignStyleFromRepresentativeMolecule(oldStyle.styleId, this.cobbleService.Cobble.id);
      }
    }
    
    if (styleName === 'none') {
      moleculeStyle.styleSelected.styleId = null;
      moleculeStyle.styleSelected.asStyle = false;
      moleculeStyle.styleInput.setValue('');
      this.stylesSelected = this.stylesSelected.filter(style => style !== type);
      delete this.themeSelected.representativeMoleculeStyles[type];
    } else {
      const style = moleculeStyle.styles.find(style => style.name === styleName);
      moleculeStyle.styleSelected.styleId = style.styleId;
      moleculeStyle.styleSelected.asStyle = true;
      this.stylesSelected.push(type);
      this.themeSelected.representativeMoleculeStyles[type] = {
        styleId: style.styleId,
        asStyle: true,
        representativeMoleculeType: type,
        overrideThemeStyle: (this.themeSelected.id && this.themeSelected.id > 0 ? true : false),
      };
      
      if (this.app) {
        this.thematicService.AssignStyleToRepresentativeMolecule(style.styleId, this.cobbleService.Cobble.id);
      }
    }
    
    if (this.app) {
      this.thematicService.SaveAppTheme(this.app);
    }
  }
  
  SetAsStyle(type: string, event: any) {
    this.moleculeStyles[type].styleSelected.asStyle = event.checked;
    this.themeSelected.representativeMoleculeStyles[type].asStyle = event.checked;
    
    if (this.app) {
      this.thematicService.SaveAppTheme(this.app);
    }
  }
  
  themeOptionSelected(theme?: Theme) {
    
    if (this.app) {
      
      if (this.app.appTheme?.id > 0) {
        this.thematicService.RemoveThemeFromApp(this.app);
      }
      
      if (theme) {
        this.thematicService.ApplyThemeToApp(theme, this.app).subscribe(() => {
          this.InitTheme(this.app.appTheme);
          this.workAreaService.SaveLastUsedElement('style', 'theme', theme.name);
        });
      } else {
        this.InitTheme(this.app.appTheme);
      }
    } else {
      this.InitTheme(theme);
    }
  }
  
  RemoveAll() {
    
    Object.values(this.moleculeStyles).forEach(moleculeStyle => {
      if (moleculeStyle.styleSelected.styleId !== null) {
        moleculeStyle.styleSelected.styleId = null;
        moleculeStyle.styleSelected.asStyle = false;
        moleculeStyle.styleInput.setValue('');
      }
    });
    
    this.stylesSelected = [];
    
    if (this.app) {
      this.thematicService.SaveAppTheme(this.app);
    }
  }
  
  PreviewRepresentativeMoleculeStyle(type: string, event: MouseEvent) {
    const moleculeStyle = this.moleculeStyles[type];
    const style = this.thematicService.GetStyle(moleculeStyle.styleSelected.styleId);
    
    if (style) {
      const repMoleculeTemplate = this.templateService.GetRepresentativeTemplateByType(style.representativeMoleculeType);
      const repMolecule = new RepresentativeMolecule(cloneDeep(repMoleculeTemplate));
      repMolecule.ApplyStyle([style]);
      
      this.draggableWindowService.OpenDraggableWindow(
        `Preview Style [${ style.representativeMoleculeType } - ${ style.section.toLocaleUpperCase() } - ${ style.name }]`,
        DraggableWindowType.PreviewRepresentativeMoleculeComponent,
        event,
        { repMolecule },
        true,
      );
    }
  }
  
  CreateUpdateTheme(name?: string, description?: string) {
    
    if (this.app) {
      const newTheme: Theme = {
        id: 0,
        name,
        description,
        representativeMoleculeStyles: {},
      };
      
      Object.values(this.moleculeStyles).forEach(moleculeStyle => {
        if (moleculeStyle.styleSelected.styleId && moleculeStyle.styleSelected.styleId !== null) {
          newTheme.representativeMoleculeStyles[moleculeStyle.type] = {
            representativeMoleculeType: moleculeStyle.type,
            styleId: moleculeStyle.styleSelected.styleId,
            overrideThemeStyle: false,
            asStyle: true,
          };
        }
      });
      
      this.thematicService.CreateTheme(newTheme).subscribe(() => {
        this.snackerService.ShowMessageOnBottom('New Theme Created Successfully', 'add_circle', null, true);
        this.communicationService.Event.Editor.$ReloadThemes.emit();
        this.communicationService.Event.Editor.$ReloadThemesPanel.emit();
      });
      
    } else {
      if (this.themeSelected.id > 0) {
        // update
        this.thematicService.UpdateTheme(this.themeSelected).subscribe(() => {
          this.communicationService.Event.Editor.$ReloadThemes.emit();
          this.snackerService.ShowMessageOnBottom('Theme Updated Successfully', 'check_circle', null, true);
          
        });
      } else {
        // create
        this.thematicService.CreateTheme(this.themeSelected).subscribe(() => {
          this.communicationService.Event.Editor.$ReloadThemes.emit();
          this.communicationService.Event.Editor.$ReloadThemesPanel.emit();
          this.snackerService.ShowMessageOnBottom('Theme Created Successfully', 'check_circle', null, true);
        });
      }
    }
  }
  
  async SaveAsNewTheme(): Promise<void> {
    const response = await this.dialogService.OpenFormDialog({
      title: 'Save theme as',
      confirmText: 'Save Theme',
      cancelText: 'Cancel',
      inputData: [
        {
          inputKey: 'inputName',
          inputLabel: 'Name',
        },
        {
          inputKey: 'inputDescription',
          inputLabel: 'Description',
        },
      ],
    });
    
    if (response) {
      this.CreateUpdateTheme(response.inputName, response.inputDescription);
    }
  }
}
