import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiAdminService } from '../../core/services/api-admin.service';
import { ApiAuthService } from '../../core/services/api-auth.service';
import { CacheService } from '../../core/services/cache.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { ToolsService } from '../../core/services/tools.service';
import { IframeComponent } from '../components/iframe/iframe.component';
import { Constants } from '../constants';
import { Browser } from '../enums/browser.enum';
import { IMenuEntry } from '../interfaces/menu-entry.interface';
import { DraggableWindowManagerService } from './draggable-window-manager.service';
import { DraggableWindowService } from './draggable-window.service';
import { HubConnectionService } from './hub-connection.service';
import { SnackerService } from './snacker.service';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  termsOfService = ``;
  public SessionId: string;
  UIStyle = 'modern-ui';
  protected apiUrl = Constants.Environment.apiUrl;
  
  constructor(
    private localStorageService: LocalStorageService,
    private apiAuthService: ApiAuthService,
    private cacheService: CacheService,
    private dialogService: GenericDialogService,
    private toolsService: ToolsService,
    private snackerService: SnackerService,
    private hubConnectionService: HubConnectionService,
    private draggableWindowService: DraggableWindowService,
    private draggableWindowManagerService: DraggableWindowManagerService,
    private clientStorageService: ClientStorageService,
    private adminService: ApiAdminService,
    private router: Router,
    private http: HttpClient,
  ) {
    this.adminService.GetSystemSetting('termsOfService').subscribe((response) => {
      this.termsOfService = response;
    });
  }
  
  get IsInternalDevelopment() {
    return Constants.Development.Internal.includes(this.clientStorageService.getUserName()) || this.toolsService.IsLocalhost;
  }
  
  get IsPublicDevelopment() {
    return Constants.Development.Public.includes(this.clientStorageService.getUserName()) || this.toolsService.IsLocalhost;
  }
  
  DisableMobileInputZoom() {
    const addMaximumScaleToMetaViewport = () => {
      const el = document.querySelector('meta[name=viewport]');
      
      if (el !== null) {
        let content = el.getAttribute('content');
        let re = /maximum\-scale=[0-9\.]+/g;
        
        if (re.test(content)) {
          content = content.replace(re, 'maximum-scale=1.0');
        } else {
          content = [content, 'maximum-scale=1.0'].join(', ');
        }
        
        el.setAttribute('content', content);
      }
    };
    
    const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;
    
    // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
    const checkIsIOS = () =>
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    
    if (checkIsIOS()) {
      disableIosTextFieldZoom();
    }
  }
  
  public RefreshUserInfo() {
    if (this.apiAuthService.loggedIn()) {
      this.apiAuthService.getUserById(+this.clientStorageService.getUserId()).subscribe((user) => {
        this.clientStorageService.refreshSessionData(user);
        
        if (user && !user.termsOfServiceAgreement) {
          this.dialogService.OpenConfirmDialog({
            title: 'Terms of Service',
            message: this.termsOfService,
            confirmText: 'Agreed',
            cancelText: 'I do not agree',
            maxWidth: '700px',
            height: '250px',
            fontSize: '12px',
          }).then((result) => {
            this.localStorageService.Set('agreement', JSON.stringify(result));
            this.apiAuthService.SetTermsOfServiceAgreement(result).subscribe();
            if (result) {
            } else {
              this.apiAuthService.logout();
            }
          });
        }
      });
    }
  }
  
  public SetSessionId(ignoreLocation = false) {
    if (!ignoreLocation && window.location.pathname.includes('/login')) {
      this.localStorageService.Remove(Constants.SessionId);
      this.SessionId = null;
    } else {
      let sessionId = this.localStorageService.Get(Constants.SessionId);
      console.log('sessionid', sessionId);
      if (sessionId && sessionId !== '') {
      } else {
        sessionId = this.toolsService.GenerateGuid();
        this.localStorageService.Set(Constants.SessionId, sessionId);
      }
      
      this.SessionId = sessionId;
    }
  }
  
  public GetUserPreferences(): {
    dashboardStyle: string;
    favApps: string;
    theme: string;
  } {
    // dashboard: card, compact
    // theme: light, dark, auto
    
    const preferences = this.localStorageService.Get('userPreferences') || JSON.stringify({ dashboardStyle: 'card', favApps: '[]', theme: 'light' });
    return JSON.parse(preferences);
  }
  
  public SaveUserPreference(setting: string, value: string) {
    const preferences = this.GetUserPreferences();
    preferences[setting] = value;
    
    this.localStorageService.Set('userPreferences', preferences);
  }
  
  public EvaluateSystemVersion(): Observable<any> {
    return from(
      new Promise((resolve) => {
        const systemInfo = this.GetSystemVersionInfo();
        
        this.GetPublishedSystemVersion().subscribe((versionData) => {
          if (systemInfo && versionData.frontendVersion === systemInfo.frontendVersion && versionData.backendVersion === systemInfo.backendVersion) {
            // no change
          } else {
            this.UpdateSystemVersionInfo(versionData);
            this.RunVersionActions(versionData);
          }
          return resolve(null);
        });
      }),
    );
  }
  
  public EvaluateSystemVersionAndSendUserAlive(sendUserAlive = true) {
    if (!Constants.Environment.production) {
      return;
    }
    this.EvaluateSystemVersion().subscribe(() => {
      if (sendUserAlive) {
        setTimeout(() => {
          this.UserAlive();
        }, 5000);
      }
    });
  }
  
  EvaluateBrowserSupport() {
    const acceptedBrowsers = [
      Browser.Chrome.trim(),
      Browser.EdgeChromium.trim(),
    ];
    const displayNotificationAreas = ['/dashboard', '/workarea'];
    const url = window.location.href;
    const browser = this.toolsService.GetBrowserInfo().browser;
    console.log(browser);
    // mobile
    if (this.toolsService.IsMobile) {
      if (displayNotificationAreas.some((area) => url.indexOf(area) > -1)) {
        this.DisplayBrowserNotSupportedNotification();
      }
    }
    // desktop
    else {
      if (!acceptedBrowsers.includes(browser)) {
        this.DisplayBrowserNotSupportedNotification();
      }
    }
  }
  
  DisplayBrowserNotSupportedNotification() {
    const browser = this.toolsService.GetBrowserInfo().browser;
    
    if (browser !== Browser.Chrome) {
      this.dialogService.OpenConfirmDialog({
        title: 'Chrome Browser Recommended',
        message: `Some functions may not work or may work improperly in your browser.\n
                  Chrome version 80 or newer.\n
                  It appears you are using ${ browser }.`,
        confirmText: 'Ok',
        cancelText: '',
      });
    }
  }
  
  public UserAlive() {
    if (this.apiAuthService.loggedIn()) {
      this.hubConnectionService.SendUserAlive(this.SessionId);
    }
  }
  
  OpenMenuEntryLeapXLApp(entry: IMenuEntry) {
    const menuEntry = this.clientStorageService.getMenuEntries().find((m) => m.applicationSlug === entry.applicationSlug);
    
    if (menuEntry) {
      const url = `${ document.location.protocol }//${ document.location.hostname }${ document.location.hostname.search('localhost') > -1 ? ':4200' : ''
      }/run/${ menuEntry.companySlug || '' }/${ menuEntry.applicationSlug }`;
      
      switch (menuEntry.openApplicationStage) {
        case 'Window':
          this.toolsService.DragWindowConfig = Constants.Defaults.DraggableWindowSizes.Microlearning;
          
          this.toolsService.DragWindowConfig = {
            changeLayout: false,
            icon: menuEntry.icon,
            x: menuEntry.positionX,
            y: menuEntry.positionY,
            width: menuEntry.width,
            height: menuEntry.height,
          };
          
          const draggableWindow = this.draggableWindowService.GenerateWindow(IframeComponent, {
            title: `Leap | ${ menuEntry.title }`,
            data: {
              url: url,
            },
          });
          
          this.draggableWindowManagerService.ShowWindowAndStore(draggableWindow);
          break;
        case 'Self':
        default:
          window.open(url, '_self');
          break;
        case 'Tab':
          const newWindow = window.open('', '_blank');
          (newWindow as any).location = url;
          break;
      }
    }
  }
  
  EvaluateNewInstallation() {
    this.adminService.getBackendVersion().subscribe(result => {
      if (result && result.newInstallation) {
        this.router.navigate(['/register']);
      }
    });
  }
  
  private GetSystemVersionInfo(): any {
    const systemVersionData = this.localStorageService.Get('system');
    console.log(systemVersionData);
    return JSON.parse(systemVersionData);
  }
  
  private UpdateSystemVersionInfo(systemInfo: any) {
    this.localStorageService.Set('system', systemInfo);
  }
  
  private GetPublishedSystemVersion(): Observable<any> {
    return this.http.get(this.apiUrl + `Admin/VersionData`).pipe(catchError((error) => this.handleError(error)));
  }
  
  private handleError(error, requestMessage = '', data = {}) {
    return observableThrowError(new HttpErrorResponse(error));
  }
  
  private RunVersionActions(versionInfo: any) {
    if (versionInfo.clearCache) {
      this.cacheService.Clear(true);
    }
    
    if (versionInfo.clearStorage) {
      localStorage.clear();
      this.UpdateSystemVersionInfo(versionInfo);
    }
    
    if (versionInfo.logOutUser) {
      setTimeout(() => {
        if (!window.location.href.includes('/run/')) {
          if (this.apiAuthService.loggedIn()) {
            this.apiAuthService.logout();
            this.snackerService.ShowMessageOnBottom('Leap has been updated, please log in again', 'browser_updated', 3500);
          }
        }
      }, 1000);
    }
  }
}
