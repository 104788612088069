import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DraggableService {
  
  droppableZones$: Subject<Array<String>> = new Subject<Array<String>>();
  
  constructor() {
  }
}
