import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';
import { RepresentativeMolecule } from './../../../shared/representative-molecule/interfaces/representative-molecule';

export class FilterElementsByMoleculeMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    data: any,
  ) {
    try {
      data = data || {};
      const elementsFilter: RepresentativeMolecule[] = [];
      
      if (data.elements && rule && rule.moleculeName) {
        const moleculeNames = rule.moleculeName.split(',');
        
        data.elements.forEach((element: RepresentativeMolecule) => {
          // element.Molecules.forEach((molecule) => {
          //   if (moleculeNames.includes(molecule.molecule)) {
          //     if (molecule.molecule === 'LoginUsernameMolecule') {
          //       data.username = element.Value;
          //     }
          //     if (molecule.molecule === 'LoginPasswordMolecule') {
          //       data.password = element.Value;
          //     }
          //     if (elementsFilter.findIndex((e) => e.Id === element.Id) === -1) {
          //       elementsFilter.push(element);
          //     }
          //   }
          // });
        });
        
        data.elements = elementsFilter;
      }
      // console.log(data);
      
      // console.log(`molecule working, filter elements`);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
