import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Snapshot } from '../../shared/interfaces/snapshot.interface';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';
import { ApiDataService } from './api-data.service';

@Injectable({
  providedIn: 'root',
})
export class ApiSnapshotsService extends ApiDataService {
  
  constructor(http: HttpClient, errorMessengerService: ErrorMessengerService, protected cobbleService: CobbleService) {
    super('Snapshots', http, errorMessengerService);
  }
  
  SaveSnapshot(versioned = false, forceSave = false, archived = false, description = ''): Observable<Snapshot> {
    return this.http.post(
      this.apiEndpointUrl + '/Save', {
        applicationId: this.cobbleService.Cobble.id,
        description,
        versioned,
        archived,
        forceSave,
      },
      this.AddBackgroundTask('Saving Snapshot', 'cloud_done'),
    ).pipe(
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error saving snapshot`,
        );
        console.error('Error saving snapshot', err);
        return of(err);
      }),
    );
  }
  
  CloneSnapshot(snapshotId: number, newName: string): Observable<Snapshot> {
    return this.http.post(
      this.apiEndpointUrl + '/Clone/' + snapshotId, {
        newName,
      },
      this.AddBackgroundTask('Cloning Snapshot', 'content_copy'),
    ).pipe(
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error cloning snapshot`,
        );
        console.error('Error cloning snapshot', err);
        return of(err);
      }),
    );
  }
  
  RestoreSnapshot(snapshot: Snapshot): Observable<any> {
    return this.http.post(
      this.apiEndpointUrl + '/Restore/' + snapshot.id, {},
      this.AddBackgroundTask('Restoring Snapshot', 'settings_backup_restore'),
    ).pipe(
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error restoring snapshot`,
        );
        console.error('Error restoring snapshot', err);
        return of(err);
      }),
    );
  }
  
  ToggleArchivedSnapshot(snapshotId: number, description = ''): Observable<Snapshot> {
    return this.http.put(
      this.apiEndpointUrl + `/ToggleArchived/${ snapshotId }`, {
        description,
      },
    ).pipe(
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error archiving snapshot`,
        );
        console.error('Error archiving snapshot', err);
        return of(err);
      }),
    );
  }
  
  GetSnapshotsForApplication(filter: {
    archived: boolean,
    snapshots: boolean,
    versioned: boolean
  } = {
    archived: true,
    snapshots: true,
    versioned: true,
  }, applicationId = this.cobbleService.Cobble.id): Observable<Snapshot[]> {
    return this.http.post(this.apiEndpointUrl + `/Application/${ applicationId }`, filter).pipe(
      map(response => <any>response),
      catchError(error => this.errorMessengerService.HandleError(error, `Error getting App snapshots.`)),
    );
  }
  
  GetArchivedSnapshots(): Observable<Snapshot[]> {
    return this.http.get(this.apiEndpointUrl + `/Archived`).pipe(
      map(response => <any>response),
      catchError(error => this.errorMessengerService.HandleError(error, `Error getting App snapshots.`)),
    );
  }
  
  DeleteSnapshot(snapshotId: number) {
    return this.http.delete(this.apiEndpointUrl + `/Remove/${ snapshotId }`).pipe(
      map(response => <any>response),
      catchError(error => this.errorMessengerService.HandleError(error, `Error deleting snapshots.`)),
    );
  }
  
  DownloadSnapshot(snapshotId: number, password: string) {
    return this.http.post(
      this.apiEndpointUrl + '/Download/' + snapshotId, {
        password,
      },
      {
        reportProgress: true,
        responseType: 'blob',
        observe: 'events',
      },
    ).pipe(
      map((events) => {
        return events;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error downloading snapshot`,
        );
        console.error('Error downloading snapshot', err);
        return of(err);
      }),
    );
  }
  
  UpdateSnapshot(snapshot: Snapshot): Observable<Snapshot> {
    return this.http.put(
      this.apiEndpointUrl + `/${ snapshot.id }`, {
        description: snapshot.description,
        archived: snapshot.archived,
        versioned: snapshot.versioned,
      },
    ).pipe(
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error saving snapshot`,
        );
        console.error('Error saving snapshot', err);
        return of(err);
      }),
    );
  }
  
}
