import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class ShowRepresentativeMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(particleId: string, repMoleculeId: string, busProcessorKey: string, rule: any, logic: any, data: any) {
    try {
      console.log(particleId);
      
      if (rule && rule.repMoleculeIds) {
        rule.repMoleculeIds.forEach((repMoleculeIdToShow) => {
          const repMolecule = this.busService.Get(repMoleculeIdToShow);
          if (repMolecule) {
            repMolecule.Properties.show = true;
            repMolecule.RefreshParent();
            if (repMolecule.Properties.alwaysRenderComponent) {
              this.toolsService.RedrawBrowser();
            }
          }
        });
        
        // console.log(`molecule working, showing element`);
      }
      
      if (rule && rule.showMe && !!rule.showMe === true) {
        const repMolecule = this.busService.Get(repMoleculeId);
        if (repMolecule) {
          repMolecule.Properties.show = true;
          repMolecule.RefreshParent();
          if (repMolecule.Properties.alwaysRenderComponent) {
            this.toolsService.RedrawBrowser();
          }
          setTimeout(() => {
            repMolecule.$UpdateValue.emit();
          }, 100);
        }
      }
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
