import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiAuthService } from '../../../core/services/api-auth.service';
import { SnackerService } from '../../services/snacker.service';

@Component({
  selector: 'tfa-dialog',
  templateUrl: './2fa-dialog.component.html',
  styleUrls: ['./2fa-dialog.component.scss'],
})
export class TFADialogComponent implements OnInit {
  @ViewChild('oneCodeInput', { static: false })
  oneCodeInput: any;
  @ViewChild('twoCodeInput', { static: false })
  twoCodeInput: any;
  @ViewChild('threeCodeInput', { static: false })
  threeCodeInput: any;
  @ViewChild('fourCodeInput', { static: false })
  fourCodeInput: any;
  @ViewChild('fiveCodeInput', { static: false })
  fiveCodeInput: any;
  @ViewChild('sixCodeInput', { static: false })
  sixCodeInput: any;
  
  tfaProcess = false;
  tfaType = 'email';
  loadingCode = false;
  tfaCode = '';
  tfaUser = null;
  
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: ApiAuthService,
    private snackerService: SnackerService,
  ) {
    this.tfaUser = data.user;
    this.tfaType = data.user.enabled2FATypes[0];
  }
  
  ngOnInit() {
  }
  
  closeDialog(result: any) {
    this.dialogRef.close(result);
  }
  
  EvaluateDeleteCodeInput(event: any) {
    const key = event.keyCode || event.charCode;
    
    if (key === 8) {
      if (event.target.value === '') {
        const input = event.target.classList[0];
        
        switch (input) {
          case 'two':
            this.FocusInputCode('one');
            break;
          case 'three':
            this.FocusInputCode('two');
            break;
          case 'four':
            this.FocusInputCode('three');
            break;
          case 'five':
            this.FocusInputCode('four');
            break;
          case 'six':
            this.FocusInputCode('five');
            break;
          default:
            this.GetCode();
            break;
        }
      }
    }
  }
  
  EvaluateCodeInput(event: any) {
    console.log(event);
    
    event.target.value = event.target.value.replace(/\D/g, '').charAt(0);
    
    const input = event.target.classList[0];
    
    if (event.target.value.length > 0) {
      switch (input) {
        case 'one':
          this.FocusInputCode('two');
          break;
        case 'two':
          this.FocusInputCode('three');
          break;
        case 'three':
          this.FocusInputCode('four');
          break;
        case 'four':
          this.FocusInputCode('five');
          break;
        case 'five':
          this.FocusInputCode('six');
          break;
        case 'six':
          this.LoadCode();
          break;
        default:
          break;
      }
    }
  }
  
  FocusInputCode(inputNumber: string) {
    switch (inputNumber) {
      case 'one':
        this.oneCodeInput.nativeElement.focus();
        this.oneCodeInput.nativeElement.select();
        break;
      case 'two':
        this.twoCodeInput.nativeElement.focus();
        this.twoCodeInput.nativeElement.select();
        break;
      case 'three':
        this.threeCodeInput.nativeElement.focus();
        this.threeCodeInput.nativeElement.select();
        break;
      case 'four':
        this.fourCodeInput.nativeElement.focus();
        this.fourCodeInput.nativeElement.select();
        break;
      case 'five':
        this.fiveCodeInput.nativeElement.focus();
        this.fiveCodeInput.nativeElement.select();
        break;
      case 'six':
        this.sixCodeInput.nativeElement.focus();
        this.sixCodeInput.nativeElement.select();
        break;
      
      default:
        break;
    }
    
    setTimeout(() => {
      this.GetCode();
    }, 100);
  }
  
  GetCode() {
    this.tfaCode =
      this.oneCodeInput.nativeElement.value +
      this.twoCodeInput.nativeElement.value +
      this.threeCodeInput.nativeElement.value +
      this.fourCodeInput.nativeElement.value +
      this.fiveCodeInput.nativeElement.value +
      this.sixCodeInput.nativeElement.value;
    
    console.log(this.tfaCode);
  }
  
  Clear2FACode() {
    this.oneCodeInput.nativeElement.value = '';
    this.twoCodeInput.nativeElement.value = '';
    this.threeCodeInput.nativeElement.value = '';
    this.fourCodeInput.nativeElement.value = '';
    this.fiveCodeInput.nativeElement.value = '';
    this.sixCodeInput.nativeElement.value = '';
    
    this.tfaCode = '';
    
    setTimeout(() => {
      this.FocusInputCode('one');
    }, 200);
  }
  
  ResendCode() {
    this.Clear2FACode();
    this.authService.Resend2FACode(this.tfaUser.id, this.tfaType).subscribe();
    this.snackerService.ShowMessageOnBottom('New 2FA Code sent', 'history', 3000);
  }
  
  LoadCode() {
    this.GetCode();
    this.loadingCode = true;
    this.authService.Validate2FACode(this.tfaUser.id, this.tfaType, this.tfaCode).subscribe((result: any) => {
      if (result) {
        this.closeDialog(true);
      } else {
        this.Clear2FACode();
        setTimeout(() => {
          this.loadingCode = false;
          setTimeout(() => {
            this.FocusInputCode('one');
          }, 200);
          // this.closeDialog(false);
        }, 2000);
        this.snackerService.ShowMessageOnBottom('Code expired or invalid', 'timer_off');
      }
    });
  }
}
