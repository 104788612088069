export enum Receptor {
  ValueInput = 'value-input',
  MinDateInput = 'min-date-input',
  MaxDateInput = 'max-date-input',
  StartDateInput = 'start-date-input',
  EndDateInput = 'end-date-input',
  BadgeValueInput = 'badge-value-input',
  BadgeValueOutput = 'badge-value-output',
  ValuePreviewInput = 'value-preview-input',
  TextInput = 'text-input',
  IconInput = 'icon-input',
  IconOutput = 'icon-output',
  HeaderInput = 'header-input',
  TooltipInput = 'tooltip-input',
  OptionsListInput = 'options-list-input',
  MinInput = 'min-input',
  MaxInput = 'max-input',
  MinOutput = 'min-output',
  MaxOutput = 'max-output',
  ValueOutput = 'value-output',
  None = 'none',
  PageValueOutput = 'page-value-output',
  MinDateOutput = 'min-date-output',
  MaxDateOutput = 'max-date-output',
  StartDateOutput = 'start-date-output',
  EndDateOutput = 'end-date-output',
  TextOutput = 'text-output',
  TextLoading = 'text-loading-input',
  SearchOutput = 'search-output',
  SearchInput = 'search-input',
  OptionsListOutput = 'options-list-output',
  SelectStepInput = 'select-step-input',
  SelectStepOutput = 'select-step-output',
}
