import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnsavedDataGuard } from '../guards/unsaved-data.guard';
import { ExternalLoginComponent } from './externalLogin/externalLogin.component';
import { ForgotPasswordComponent } from './forgotPassword/forgotPassword.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { SetPasswordComponent } from './setPassword/setPassword.component';

const authRoutes: Routes = [
  {
    path: 'login',
    canActivate: [UnsavedDataGuard],
    children: [
      { path: '', component: LoginComponent, pathMatch: 'full' },
      {
        path: 'sso/',
        component: LoginComponent,
      },
      {
        path: 'external/:type',
        component: ExternalLoginComponent,
      },
    ],
    data: {
      state: 'login',
      animationState: 'login',
    },
  },
  { path: 'register', component: RegisterComponent, data: { animationState: 'login' } },
  { path: 'setpassword', component: SetPasswordComponent, data: { animationState: 'login' } },
  { path: 'recoverpassword', component: ForgotPasswordComponent, data: { animationState: 'login' } },
];

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule],
  declarations: [],
})
export class AuthRoutingModule {
}
