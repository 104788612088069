import { Constants } from './app/shared/constants';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppInjector } from './app/app-injector';

if (Constants.Environment.production || !Constants.Environment.debug) {
  enableProdMode();
}

if (Constants.Environment.debug) {
  localStorage.setItem(`${ Constants.LocalStoragePrefix }debug`, JSON.stringify(true));
}

const debug = JSON.parse(localStorage.getItem(`${ Constants.LocalStoragePrefix }debug`));

// window.console.log = function () {};
if (!debug) {
  if (window) {
    window.console.log = function () { };
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((moduleRef) => {
    // if ('serviceWorker' in navigator && environment.production) {
    //   navigator.serviceWorker.register('/sw.js');
    // }
    AppInjector.setInjector(moduleRef.injector);
  })
  .catch((err) => console.log(err));
