import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { ApiDataSourcesService } from '../../core/services/api-data-sources.service';
import { ApiDataService } from '../../core/services/api-data.service';
import { ToolsService } from '../../core/services/tools.service';
import { CobbleService } from '../representative-molecule/services/cobble.service';
import { ErrorMessengerService } from './error-messenger.service';

@Injectable({
  providedIn: 'root',
})
export class ApiPublishedCobblesService extends ApiDataService {
  constructor(
    http: HttpClient,
    public dataSourcesService: ApiDataSourcesService,
    public errorMessengerService: ErrorMessengerService,
    protected cobbleService: CobbleService,
    private toolsService: ToolsService,
  ) {
    super('PublishedCobbles', http, errorMessengerService);
  }
  
  UnpublishCobble(cobbleId: number = 0) {
    return this.http
    .post(
      `${ this.apiEndpointUrl }/unpublish/${
        cobbleId === 0 ? this.cobbleService.Cobble.id : cobbleId
      }`,
      {},
    )
    .pipe(
      map(result => {
        if (cobbleId === 0) {
          this.cobbleService.Cobble.isRunnable = false;
        }
      }),
      catchError(error =>
        this.errorMessengerService.HandleError(
          error,
          `Error unpublishing App ${ cobbleId }.`,
          cobbleId,
        ),
      ),
    );
  }
  
  HasBeenPublished(guid: string) {
    return this.http.get(`${ this.apiEndpointUrl }/HasBeenPublished/${ guid }`).pipe(
      map(result => <any>result),
      catchError(error => this.errorMessengerService.HandleError(error)),
    );
  }
  
  HasBeenPublishedByCompanyAndAppSlug(companySlug: string, appSlug: string) {
    return this.http.get(`${ this.apiEndpointUrl }/HasBeenPublished/${ this.toolsService.SanitizeString(companySlug) }/${ this.toolsService.SanitizeString(appSlug) }`).pipe(
      map(result => <any>result),
      catchError(error =>
        this.errorMessengerService.HandleError(
          error,
          `Error getting publish state for App ${ appSlug }.`,
          appSlug,
        ),
      ),
    );
  }
  
  ChildrenByGuid(guid: string) {
    return this.http.get(`${ this.apiEndpointUrl }/Children/${ guid }`).pipe(
      map(result => <any>result),
      catchError(error => this.errorMessengerService.HandleError(error)),
    );
  }
  
  ChildrenByCompanyAndAppSlug(companySlug: string, appSlug: string) {
    return this.http.get(`${ this.apiEndpointUrl }/Children/${ this.toolsService.SanitizeString(companySlug) }/${ this.toolsService.SanitizeString(appSlug) }`).pipe(
      map(result => <any>result),
      catchError(error =>
        this.errorMessengerService.HandleError(error, `Error getting elements for App ${ appSlug }.`, appSlug),
      ),
    );
  }
  
  Children(parentId: number) {
    return this.http.get(`${ this.apiEndpointUrl }/Children/${ parentId }`).pipe(
      map(result => <any>result),
      catchError(error => this.errorMessengerService.HandleError(error)),
    );
  }
}
