import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { DndModule } from 'ng2-dnd';
import { CobbleDetailsComponent } from '../dashboard/cobble-details/cobble-details.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { TopBarComponent } from '../top-bar/top-bar.component';
import { TopBarModule } from '../top-bar/top-bar.module';
import { DataElementParticleComponent } from '../workarea/bus/data-element-particle/data-element-particle.component';
import { ActionableErrorComponent } from './components/actionable-error/actionable-error.component';
import { CompaniesExplorerComponent } from './components/companies-explorer/companies-explorer.component';
import { CustomSnackbarComponent } from './components/custom-snackbar/custom-snackbar.component';
import { IframeComponent } from './components/iframe/iframe.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ThemeSearchComponent } from './components/theme-search/theme-search.component';
import { DraggableEmitterDirective } from './directives/draggable-emitter.directive';
import { DroppableHighlighterDirective } from './directives/droppable-highlighter.directive';
import { ExistingEmailValidatorDirective } from './directives/existing-email-validator.directive';
import { MaterialModule } from './material/material.module';
import { DropdownComponent } from './components/dropdown/dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FontAwesomeModule,
    DndModule,
    TopBarModule,
    NgJsonEditorModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    // HierarchySharedComponent,
    DashboardComponent,
    DraggableEmitterDirective,
    DroppableHighlighterDirective,
    ActionableErrorComponent,
    ExistingEmailValidatorDirective,
    ProgressBarComponent,
    DataElementParticleComponent,
    CompaniesExplorerComponent,
    CobbleDetailsComponent,
    IframeComponent,
    CustomSnackbarComponent,
    ThemeSearchComponent,
    DropdownComponent,
  ],
  exports: [
    // HierarchySharedComponent,
    DashboardComponent,
    TopBarComponent,
    DraggableEmitterDirective,
    FontAwesomeModule,
    DroppableHighlighterDirective,
    ProgressBarComponent,
    DataElementParticleComponent,
    CompaniesExplorerComponent,
    NgJsonEditorModule,
    ExistingEmailValidatorDirective,
    TopBarModule,
    IframeComponent,
    ThemeSearchComponent,
    DropdownComponent,
  ],
  entryComponents: [ActionableErrorComponent, CobbleDetailsComponent],
})
export class SharedModule {
}
