import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { TreeNode } from 'angular-tree-component';
import { DatasourceSharedType } from 'app/shared/enums/datasource-shared-type.enum';
import { CommunicationService } from 'app/shared/services/communication.service';
import { FileUploader } from 'ng2-file-upload';
import { Permissions } from '../../admin/models/permissions.enum';
import { ApiDataSourcesService } from '../../core/services/api-data-sources.service';
import { CacheService } from '../../core/services/cache.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { EditorStateService } from '../../core/services/editor-state.service';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { ToolsService } from '../../core/services/tools.service';
import { UserMenuService } from '../../core/services/user-menu.service';
import { DatasourceTypeId } from '../../shared/enums/datasource-type-id.enum';
import { DatasourceType } from '../../shared/enums/datasource-type.enum';
import { UserRoleId } from '../../shared/models/user-role-id.enum';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { ConnectionStateService } from '../../shared/services/connection-state.service';
import { DraggableWindowService, DraggableWindowType } from '../../shared/services/draggable-window.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { SpreadsheetService } from '../../spreadsheet/spreadsheet.service';
import { DatasourceDialogComponent } from '../datasource-dialog/datasource-dialog.component';
import { RemoveDatasourceDialogComponent } from '../remove-datasource-dialog/remove-datasource-dialog.component';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-data-sources-panel-menu',
  templateUrl: './data-sources-panel-menu.component.html',
  styleUrls: ['./data-sources-panel-menu.component.scss'],
})
export class DataSourcesPanelMenuComponent implements OnInit, OnChanges {
  @ViewChild(MatMenuTrigger, { static: true })
  trigger: MatMenuTrigger;
  
  @Input('node')
  node: TreeNode;
  
  @Input('selectedNodes')
  selectedNodes: TreeNode[] = [];
  
  @Output()
  addCategory = new EventEmitter<TreeNode>();
  
  @Output()
  deleteCategory = new EventEmitter<TreeNode>();
  
  @Output()
  menuOpen = new EventEmitter<boolean>();
  
  @Output()
  deleteLiteral = new EventEmitter<TreeNode>();
  
  userId: number;
  userCompanyId: number;
  uploader: FileUploader = new FileUploader({});
  DataSourceType = DatasourceType;
  DatasourceSharedType = DatasourceSharedType;
  ableToDownload = false;
  ableToReplace = false;
  ableToReplacePublishedDs = false;
  removeSelectionLabel = 'Remove Selected';
  isNodeSelected = false;
  
  constructor(
    private workAreaService: WorkAreaService,
    private snackerService: SnackerService,
    private connectionStateService: ConnectionStateService,
    private draggableWindowService: DraggableWindowService,
    private dataSourceService: ApiDataSourcesService,
    private dialogService: GenericDialogService,
    private spreadsheetService: SpreadsheetService,
    private editorStateService: EditorStateService,
    private toolsService: ToolsService,
    public clientStorageService: ClientStorageService,
    private cobbleService: CobbleService,
    private communicationService: CommunicationService,
    private cacheService: CacheService,
    private userMenuService: UserMenuService,
  ) {
    this.userId = clientStorageService.getUserId();
    this.userCompanyId = clientStorageService.getCompanyId();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.GetRemoveLabel();
    this.isNodeSelected = !!this.selectedNodes.find(n => n.id === this.node.id);
  }
  
  ngOnInit() {
    // console.log(this.node);
    this.ableToReplace =
      this.node.data.dataSourceType === DatasourceType.Spreadsheet &&
      this.node.data.dataSourceId !== null &&
      (this.userId === this.node.data.ownerId ||
        this.clientStorageService.isRole(UserRoleId.SuperAdmin) ||
        this.userId === this.node.data.datasourceOwnerId ||
        this.node.data.sharedType === DatasourceSharedType.Global ||
        (this.node.data.sharedType === DatasourceSharedType.Company && this.node.data.companyId === this.userCompanyId));
    
    this.ableToDownload =
      this.node.data.dataSourceType === DatasourceType.Spreadsheet &&
      this.node.data.dataSourceId !== null &&
      (this.userId === this.node.data.ownerId ||
        this.clientStorageService.isRole(UserRoleId.SuperAdmin) ||
        this.node.data.sharedType === DatasourceSharedType.Global ||
        (this.node.data.sharedType === DatasourceSharedType.Company && this.node.data.companyId === this.userCompanyId));
    
    this.ableToReplacePublishedDs = this.userMenuService.checkPermission(Permissions.ReplacePublishedDatasource);
    
  }
  
  public openContextMols($event: MouseEvent): void {
    this.trigger.openMenu();
    // console.log('open side panel');
    
    this.menuOpen.emit(true);
    this.communicationService.Event.Editor.SidePanels.$KeepOpen.emit(1);
    $event.preventDefault();
    $event.stopPropagation();
    
    console.log(this.node.data);
  }
  
  RenameDataSource() {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    if (this.node.data.isDatasource) {
      // console.log(this.node);
      this.workAreaService.dataSourceRenameId = this.node.data.dataSourceId;
      
      this.draggableWindowService.OpenDraggableWindow(
        `Rename Data Source`,
        DraggableWindowType.RenameDatasource,
      );
    } else {
      this.workAreaService.sheetNodeToRename = this.node;
      this.draggableWindowService.OpenDraggableWindow(
        `Rename Sheet`,
        DraggableWindowType.RenameSheet,
      );
    }
  }
  
  DownloadDataSourceFile() {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    this.spreadsheetService.DownloadDataSourceFile(this.node.data.dataSourceId, true);
  }
  
  ReplaceDataSourceFile(published = false) {
    this.dialogService.openDialog(
      {
        type: DatasourceType.Spreadsheet,
        dataSource: this.node.data,
        data: {
          id: this.node.data.isDatasource ? this.node.data.dataSourceId : this.node.data.parentId,
          ownerId: this.clientStorageService.getUserId(),
          cobbleId: this.cobbleService.Cobble.id,
          approvedBy: this.clientStorageService.getUserFullName(),
          description: '',
          dateApproved: new Date(),
          returnFile: true,
          enabled: true,
          name: name,
          published,
          sheetName: '',
          sheetNameToReplace: this.node.data.isDataSource ? '' : this.node.data.name,
          delimiter: '',
          encoding: 'UTF-8',
          data: undefined,
          type: 'Spreadsheets',
          tables: [],
          dataFile: undefined,
        },
      },
      DatasourceDialogComponent,
    );
  }
  
  DeleteDataSource() {
    // console.log(this.node);
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    if (this.node.data.dataSourceId === null) {
      this.deleteCategory.emit(this.node);
      return;
    }
    
    if (this.node.data.dataSourceType === DatasourceType.Api && this.node.data.ownerId !== this.userId) {
      const appIsUsingIt = this.node.data.isBeingUsed;
      
      if (appIsUsingIt) {
        this.snackerService.ShowMessageOnBottom('This API cannot be disconnected, the application is using it', 'warning', 4000);
      } else {
        this.RemoveDatasource(true);
      }
      
      return;
    }
    
    this.dataSourceService.AppsUsingDataSource([this.node.data.dataSourceId]).subscribe((apps) => {
      if (apps.length > 0) {
        this.dialogService.OpenConfirmDialog({
          title: 'Datasource being used',
          message: `This datasource shouldn't be removed because it's being used in the following Apps: \n
          ${ apps.join('\n') }`,
          confirmText: 'Cancel',
          cancelText: 'Delete anyways',
        }).then((results) => {
          if (results) {
          } else {
            this.RemoveDatasource();
          }
        });
      } else {
        this.RemoveDatasource();
      }
    });
  }
  
  RefreshApiMetadata() {
    const nodes = this.node.treeModel.expandedNodes;
    this.cacheService.Remove(this.node.data.path, true);
    this.dataSourceService.RefreshMetadata(this.node.data.dataSourceId).subscribe((response) => {
      this.communicationService.Event.Editor.DataSource.$RefreshSectionDataSourcePanel.emit({
        datasourceTypeId: DatasourceTypeId.Api,
        nodeIdsToExpand: nodes.map((n) => n.id),
        focusNodeDatasourceId: this.node.data.dataSourceId,
      });
    });
  }
  
  RemoveDatasource(avoidWarning = false) {
    
    if (avoidWarning) {
      this.ProcessDeleteDataSource();
      return;
    }
    
    this.dialogService.openDialog({ dataSourceName: this.node.data.name }, RemoveDatasourceDialogComponent).then((result) => {
      if (result) {
        if (this.node.data.dataSourceType === DatasourceType.Custom) {
          this.dataSourceService.DeleteCustomValue(this.node.data.name).subscribe((data) => {
            this.communicationService.Event.Editor.DataSource.$ReloadDataSourcePanel.emit();
            this.snackerService.ShowMessageOnBottom('Literal Removed', 'do_not_disturb_on');
          });
        } else if (this.node.data.dataSourceType === DatasourceType.Spreadsheet && this.node.data.level > 1 && this.node.data.isLeaf) {
          this.dataSourceService.RemoveDataSourceSheet(this.node.data.index - 1, this.node.data.dataSourceId).subscribe((removeSheetResult) => {
            // error deleting
            if (removeSheetResult) {
              this.snackerService.ShowMessageOnBottom('Sheet could not be deleted', 'do_not_disturb_off');
              
            } else {
              this.snackerService.ShowMessageOnBottom('Sheet has been deleted.', 'do_not_disturb_on');
              
              this.communicationService.Event.Editor.DataSource.$ReloadDataSourcePanel.emit([]);
            }
          });
        } else {
          this.ProcessDeleteDataSource();
        }
      }
    });
  }
  
  ProcessDeleteDataSource() {
    this.dataSourceService.DeleteDataSource([this.node.data.dataSourceId]).subscribe((_result) => {
      if (_result) {
        if (_result.status === 409) {
          this.snackerService.ShowMessageOnBottom(_result.error.title);
        } else {
          this.snackerService.ShowMessageOnBottom('Data Source has been ' + (this.userId !== this.node.data.ownerId && this.node.data.dataSourceType === DatasourceType.Api ? 'disconnected' : 'deleted'), 'do_not_disturb_on');
          this.communicationService.Event.Editor.DataSource.$ReloadDataSourcePanel.emit([]);
        }
      } else {
        this.snackerService.ShowMessageOnBottom('Data Source could not be deleted because it is being used.', 'do_not_disturb_off');
      }
    });
  }
  
  ToggleFullAudit() {
    this.node.data.fullAudit = !this.node.data.fullAudit;
    this.dataSourceService.ToggleFullAuditForSpreadsheet(this.node.data.dataSourceId, this.node.data.fullAudit).subscribe((result) => {
    });
  }
  
  AddCategory(event: MouseEvent) {
    // console.log('add category click');
    event.stopPropagation();
    event.preventDefault();
    this.addCategory.emit(this.node);
  }
  
  GetRemoveLabel() {
    let apiTypeCount = 0;
    
    this.selectedNodes.forEach(node => {
      if (this.userId !== node.data.ownerId && node.data.dataSourceType === DatasourceType.Api) {
        apiTypeCount++;
      }
    });
    
    if (apiTypeCount === this.selectedNodes.length) {
      this.removeSelectionLabel = 'Disconnect';
    } else if (apiTypeCount === 0) {
      this.removeSelectionLabel = 'Delete';
    } else {
      this.removeSelectionLabel = 'Remove';
    }
  }
  
  RemoveSelectedDataSources() {
    
    const apisInUseByApp = [];
    const apisInUseByOtherApps = [];
    
    this.selectedNodes.forEach(node => {
      if (node.data.dataSourceType === DatasourceType.Api && node.data.ownerId !== this.userId) {
        if (node.data.isBeingUsed) {
          apisInUseByApp.push(node);
        } else {
          apisInUseByOtherApps.push(node);
        }
      }
      
    });
    
    
    this.dataSourceService.AppsUsingDataSource(apisInUseByOtherApps.map(node => node.data.dataSourceId)).subscribe((apps) => {
      
      
      if (apisInUseByApp.length > 0 || apps.length > 0) {
        
        let message = '';
        
        if (apisInUseByApp.length > 0) {
          message += 'The following APIs are being used by this Application, remove them before deleting: \n\n';
          
          apisInUseByApp.forEach(node => {
            message += `- ${ node.data.name }\n`;
          });
          
          message += '\n';
        }
        
        if (apps.length > 0) {
          message += 'The following APIs are being used by other Apps, do you want to delete anyways?\n\n';
          
          message += 'APIs:\n\n';
          
          apisInUseByOtherApps.forEach(node => {
            message += `${ node.data.name }\n`;
          });
          
          message += '\nBeing used on Apps:\n\n';
          
          apps.forEach(app => {
            message += `- ${ app }\n`;
          });
        }
        
        this.dialogService.OpenConfirmDialog({
          title: 'Datasource being used',
          message: message,
          confirmText: 'Cancel',
          cancelText: apps.length > 0 ? 'Delete anyways' : '',
        }).then((results) => {
          if (results) {
          } else {
            this.RemoveDatasources(apisInUseByOtherApps);
          }
        });
        
        
      } else {
        this.RemoveDatasources(apisInUseByOtherApps);
      }
    });
    
  }
  
  RemoveDatasources(nodes: TreeNode[]) {
    this.dataSourceService.DeleteDataSource(nodes.map(node => node.data.dataSourceId))
    .subscribe(result => {
      if (result) {
        if (result.status === 409) {
          this.snackerService.ShowMessageOnBottom(result.error.title);
        } else {
          this.selectedNodes = [];
          this.snackerService.ShowMessageOnBottom('Data Source has been removed', 'do_not_disturb_on');
          this.communicationService.Event.Editor.DataSource.$ReloadDataSourcePanel.emit([]);
        }
      } else {
        this.snackerService.ShowMessageOnBottom('Data Source could not be deleted because it is being used.', 'do_not_disturb_off');
      }
    });
  }
}
