import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Bus } from 'app/shared/representative-molecule/interfaces/bus';
import { cloneDeep, debounce } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PropertyVersioningDto } from '../../shared/dtos/versioning-dto';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { BusService } from '../molecular/services/bus.service';
import { ApiDataService } from './api-data.service';
import { HTTPStatusService } from './httpStatus.service';
import { ToolsService } from './tools.service';

@Injectable({
  providedIn: 'root',
})
export class ApiPropertiesService extends ApiDataService {
  inDebounce = null;
  criticAlert = false;
  backupUnsavedProperties = [];
  timeFrequencyCheck = 150;
  timeBusyIndicator = 120;
  timeCriticBusyIndicator = 2000;
  timeCriticAlert = 5000;
  propertiesSaveQueue: any = {};
  propertiesSaveInterval = null;
  cachedPropertiesToSave: PropertyVersioningDto[] = [];
  saveInDebounce = null;
  private savingDelay = SavingDelay.Low;
  private pauseSaving = false;
  
  SavePropertiesCached = debounce((() => {
    // run action
    console.log('========run2========', this.cachedPropertiesToSave);
    if (this.pauseSaving) return;
    
    this.SaveCachedProperties();
  }), this.savingDelay);
  
  constructor(
    http: HttpClient,
    public errorMessengerService: ErrorMessengerService,
    private busService: BusService,
    protected cobbleService: CobbleService,
    private httpStatusService: HTTPStatusService,
    private toolsService: ToolsService,
    private communicationService: CommunicationService,
    private snackerService: SnackerService,
    private angularZone: NgZone,
  ) {
    super('properties', http, errorMessengerService);
  }
  
  SaveProperty(versioning: PropertyVersioningDto): Observable<any> {
    
    this.angularZone.runOutsideAngular(() => {
      this.cachedPropertiesToSave.push(versioning);
      console.log('save property');
      this.SavePropertiesCached();
    });
    
    // this.cachedPropertiesToSave.push(versioning);
    // console.log('save property');
    // this.SavePropertiesCached();
    // this.angularZone.run(() => {
    //   this.SaveThrottle(
    //     (func, delay, context) => {
    //       console.log('========run1========');
    //
    //       // this.SavePropertiesCached();
    //       // this.SaveProperties(this.cachedPropertiesToSave).subscribe(result => {
    //       //   this.cachedPropertiesToSave = [];
    //       // });
    //     },
    //     10,
    //     this,
    //     null
    //   );
    // });
    return of(null);
    
    // versioning.cobbleId = this.cobbleService.Cobble.id;
    // if (this.RunningMode) {
    //   return;
    // }
    // this.httpStatusService.PropertySaving = true;
    //
    // // console.log('versioning property...', versioning);
    // versioning.id = this.toolsService.GenerateGuid();
    // this.angularZone.run(() => {
    //   this.addElementPropertiesQueue(versioning);
    // });
    //
    // return this.http.post(this.apiEndpointUrl + '/Save', versioning).pipe(
    //   map((response) => {
    //
    //     const repMolecule = this.busService.Get(
    //       versioning.elementId
    //     );
    //
    //     if (repMolecule) {
    //       repMolecule.Versioning = (response as any).versioning;
    //
    //       this.angularZone.run(() => {
    //         this.communicationService.Event.Editor.$MoleculePropertyChange.emit([
    //           {
    //             moleculeId: +versioning.elementId,
    //             change: versioning,
    //             versionId: (response as any).versionId,
    //             active: true
    //           }
    //         ]);
    //       });
    //
    //       this.angularZone.run(() => {
    //         setTimeout(() => {
    //           this.removePropertieFromQueue(
    //             (response as any).moleculeId,
    //             (response as any).savedPropertyId
    //           );
    //         }, 1000);
    //       });
    //
    //       setTimeout(() => {
    //         this.angularZone.run(() => {
    //           this.httpStatusService.PropertySaving = false;
    //         });
    //       }, 800);
    //     }
    //
    //   }),
    //   catchError((error) => {
    //
    //       this.angularZone.run(() => {
    //         this.removePropertieFromQueue(
    //           versioning.elementId,
    //           versioning.id
    //         );
    //       });
    //
    //       setTimeout(() => {
    //         this.angularZone.run(() => {
    //           this.httpStatusService.PropertySaving = false;
    //         });
    //       }, 800);
    //
    //       return this.errorMessengerService.HandleError(
    //         error,
    //         `Error saving element property ${versioning.property}: ${versioning.value}, ${versioning.elementId}.`,
    //         versioning
    //       );
    //     }
    //   )
    // );
  }
  
  SetSavingDelay(delay: SavingDelay) {
    this.savingDelay = delay;
  }
  
  PauseSaving() {
    this.pauseSaving = true;
  }
  
  ResumeSaving() {
    this.pauseSaving = false;
    this.SaveCachedProperties();
  }
  
  SaveProperties(versioning: PropertyVersioningDto[]): Observable<any> {
    versioning.forEach(v => this.SaveProperty(v)
    .subscribe());
    return of({});
  }
  
  UpdateBackupPropertiesForRepMolecules(properties: PropertyVersioningDto[]) {
    properties.forEach(property => {
      if (property.path.includes('properties')) {
        const repMolecule = this.busService.Get(property.elementId);
        if (repMolecule) {
          repMolecule.UpdateBackupProperties(property.path.split('.'), property.property, property.value);
        }
      }
    });
  }
  
  SavePropertiesPromise(versioning: PropertyVersioningDto[]): Promise<any> {
    return new Promise(resolve => {
      this.SaveProperties(versioning)
      .subscribe(result => {
      });
      return resolve(null);
    });
  }
  
  getVersionByElementId(id: number) {
    return this.http.get(this.apiEndpointUrl + `/VersionHistory/${ id }`)
    .pipe(
      catchError((error) =>
        this.errorMessengerService.HandleError(
          error,
          `Error getting version history for element ${ id }.`,
          id,
        ),
      ),
    );
  }
  
  VerifyCobbleName(name: string, appId = null) {
    return this.http.get(this.apiEndpointUrl + `/VerifyName/${ name }${ appId === null ? '' : '/' + appId }`)
    .pipe(
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error verifyng App name availability.`, name),
      ),
    );
  }
  
  SaveOwnProperty(moleculeIndex: string, propertyName: string, change: string, path = '') {
    
    if (this.RunningMode) {
      return;
    }
    this.httpStatusService.PropertySaving = true;
    const molecule = this.busService.Get(moleculeIndex);
    const value = molecule[propertyName.charAt(0)
    .toUpperCase() + propertyName.slice(1)];
    // console.time('clone');
    const rawValue = this.GetRawProperty(propertyName, value);
    // console.timeEnd('clone');
    
    return this.SaveProperty(
      new PropertyVersioningDto({
        elementId: moleculeIndex,
        property: propertyName,
        value: rawValue,
        path,
        change: change,
        name: molecule.Properties.name,
      }),
    );
  }
  
  GetRawProperty(property: string, value: any): any {
    
    let rawObject = null;
    
    switch (property) {
      case 'buses':
        const buses = value as Bus[];
        const rawBuses = [];
        buses.forEach(b => rawBuses.push(b.GetRawObject()));
        rawObject = rawBuses;
        break;
      
      default:
        rawObject = cloneDeep(value);
        break;
    }
    
    return this.toolsService.ClearLeapXLObjectFromCircularDependencies(rawObject);
  }
  
  RemoveVersionedProperties(versionIds: number[]) {
    this.httpStatusService.PropertySaving = true;
    
    return this.http.post(this.apiEndpointUrl + `/RemoveVersions/`, versionIds)
    .pipe(
      map((response) => {
        setTimeout(() => {
          this.httpStatusService.PropertySaving = false;
        }, 800);
      }),
      catchError((error) =>
        this.errorMessengerService.HandleError(
          error,
          `Error getting property previous value, versions: ${ versionIds.join() }.`,
          versionIds,
        ),
      ),
    );
  }
  
  updateProperties(resource) {
    if (this.RunningMode) {
      return;
    }
    this.httpStatusService.PropertySaving = true;
    
    // console.log('updating properties...', resource);
    
    return this.http.put(this.apiEndpointUrl + '/UpdateEnabledVersions', resource)
    .pipe(
      map((response) => {
        setTimeout(() => {
          this.httpStatusService.PropertySaving = false;
        }, 800);
      }),
      catchError((error) => this.errorMessengerService.HandleError(error)),
    );
  }
  
  GetUnsavedProperties(): {
    elementId: string;
    name: string;
    type: string;
    properties: PropertyVersioningDto[];
  }[] {
    const propertiesToBeSaved = [];
    console.log(this.propertiesSaveQueue);
    Object.entries(this.propertiesSaveQueue)
    .forEach(
      ([elementId, properties], index) => {
        propertiesToBeSaved.push({
          elementId: elementId,
          type: this.busService.Get(elementId).Type,
          name: this.busService.Get(elementId).Properties.name,
          properties: properties,
        });
      },
    );
    
    if (propertiesToBeSaved.length > 0) {
      this.backupUnsavedProperties = Object.assign([], propertiesToBeSaved);
    }
    
    return this.backupUnsavedProperties;
  }
  
  CancelSaving() {
    clearInterval(this.propertiesSaveInterval);
    this.propertiesSaveInterval = null;
    this.propertiesSaveQueue = {};
    this.httpStatusService.PropertySaving = false;
    this.criticAlert = false;
  }
  
  SavingInProcess(): boolean {
    const saving =
      Object.entries(this.propertiesSaveQueue).length > 0 ||
      this.backupUnsavedProperties.length > 0;
    // console.log('savingInProcess', saving);
    return saving;
  }
  
  Throttle(func, delay, context, args) {
    clearTimeout(this.inDebounce);
    this.inDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }
  
  SaveThrottle(func, delay, context, args) {
    console.log('throttle');
    clearTimeout(this.saveInDebounce);
    this.saveInDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }
  
  private SaveCachedProperties() {
    const deduplicatedProperties = this.RemoveDuplicateProperties(this.cachedPropertiesToSave);
    this.cachedPropertiesToSave = [];
    this.SaveMultiple(deduplicatedProperties)
    .subscribe(result => {
    });
  }
  
  private RemoveDuplicateProperties(properties: PropertyVersioningDto[]): PropertyVersioningDto[] {
    const filtered: PropertyVersioningDto[] = [];
    
    this.cachedPropertiesToSave.forEach(property => {
      const index = filtered.findIndex(fp =>
        fp.property === property.property &&
        fp.elementId === property.elementId &&
        fp.name === property.name &&
        fp.path === property.path);
      
      if (index >= 0) {
        filtered[index] = property;
      } else {
        filtered.push(property);
      }
    });
    
    return filtered;
  }
  
  private SaveMultiple(versioning: PropertyVersioningDto[]) {
    if (this.RunningMode) {
      return;
    }
    
    if (versioning.length === 0) {
      return of();
    }
    
    // versioning = versioning.filter(v => v.elementId !== '0');
    this.httpStatusService.PropertySaving = true;
    this.angularZone.runOutsideAngular(() => {
      this.UpdateBackupPropertiesForRepMolecules(versioning);
    });
    
    versioning.forEach((v) => {
      v.cobbleId = this.cobbleService.Cobble.id;
      this.angularZone.run(() => {
        this.addElementPropertiesQueue(v);
      });
    });
    
    // console.log('versioning properties...', versioning);
    console.time('clone1');
    let clonedProperties = this.toolsService.CloneObjectPrimitive(versioning);
    // console.log('clonedProperties properties...', clonedProperties);
    // let clonedProperties = this.toolsService.CloneObject(versioning);
    console.timeEnd('clone1');
    return this.http.post(this.apiEndpointUrl + '/SaveGroup', {
      cobbleId: this.cobbleService.Cobble.id,
      properties: clonedProperties,
      // properties: cloneDeep(versioning)
    })
    .pipe(
      map((response: any) => {
        const versions = [];
        try {
          if (Array.isArray(response)) {
            
            response.forEach((version) => {
              const repMolecule = this.busService.Get(version.moleculeId);
              if (repMolecule) {
                repMolecule.Versioning =
                  version.versioning;
                // version.versionIds.forEach((versionId) => {
                //   versions.push({
                //     moleculeId: version.moleculeId,
                //     versionId: versionId,
                //     active: true,
                //   });
                // });
              }
              
              version.savedPropertiesIds.forEach((spId) => {
                this.angularZone.run(() => {
                  this.removePropertieFromQueue(version.moleculeId, spId);
                });
              });
            });
          } else {
            this.busService.Get(response.moleculeId).Versioning =
              response.versioning;
            
            versions.push({
              moleculeId: response.moleculeId,
              versionId: response.versionId,
              active: true,
            });
          }
          
          this.angularZone.run(() => {
            this.communicationService.Event.Editor.$MoleculePropertyChange.emit(versions);
          });
        } catch (error) {
          console.log('error', error);
          
          this.CancelSaving();
          this.snackerService.ShowMessageOnBottom('Error saving properties', 'warning');
          
        }
        
        setTimeout(() => {
          this.angularZone.run(() => {
            this.httpStatusService.PropertySaving = false;
          });
        }, 800);
      }),
      catchError((error) => {
          this.CancelSaving();
          this.snackerService.ShowMessageOnBottom('Error saving properties', 'warning');
          
          return this.errorMessengerService.HandleError(
            error,
            `Error saving multiple properties for elements ${ versioning.map((v) => v.elementId)
            .join() }.`,
            null,
          );
        },
      ),
    );
  }
  
  private getElementPropertiesQueue(
    moleculeId: string,
  ): PropertyVersioningDto[] {
    const elementProperties = this.propertiesSaveQueue[moleculeId];
    return elementProperties;
  }
  
  private addElementPropertiesQueue(property: PropertyVersioningDto): void {
    this.runTimerTracker();
    this.addToTimer(property);
    const htmlElement = document.getElementById(
      `gridsterItem-${ property.elementId }`,
    );
    // hurt performance, need to refactor
    console.log('element property added to queue');
    
    const repMolecule = this.busService.Get(property.elementId);
    if (repMolecule) {
      setTimeout(() => {
        if (
          htmlElement &&
          repMolecule &&
          repMolecule.BackendProcess > 0
        ) {
          htmlElement.classList.add('border-busy-indicator');
          htmlElement.classList.add('fade-loop');
        }
      }, this.timeBusyIndicator);
      
      // console.log('property', property);
      // console.log('rep moelcule', repMolecule);
      
      repMolecule.BackendProcess++;
      const elementProperties = this.getElementPropertiesQueue(
        property.elementId,
      );
      if (elementProperties) {
        elementProperties.push(property);
      } else {
        this.propertiesSaveQueue[property.elementId] = [property];
      }
      
      // console.log(Object.keys(this.propertiesSaveQueue).length);
    }
  }
  
  private removePropertieFromQueue(elementId: string, propertyId: string) {
    const elementProperties = this.getElementPropertiesQueue(elementId);
    
    const htmlElement = document.getElementById(`gridsterItem-${ elementId }`);
    if (elementProperties) {
      this.propertiesSaveQueue[elementId] = elementProperties.filter(
        (ap) => ap.id !== propertyId,
      );
    }
    
    const repMolecule = this.busService.Get(elementId);
    
    if (repMolecule) {
      repMolecule.BackendProcess--;
      if (repMolecule.BackendProcess <= 0) {
        if (htmlElement) {
          htmlElement.classList.remove('critic-border-busy-indicator');
          htmlElement.classList.remove('border-busy-indicator');
          htmlElement.classList.remove('fade-loop');
        }
        delete this.propertiesSaveQueue[elementId];
        
        if (htmlElement && repMolecule.CriticBackendProcess) {
          htmlElement.classList.add('green-animation-pulse');
          setTimeout(() => {
            htmlElement.classList.remove('green-animation-pulse');
          }, 1500);
        }
        repMolecule.CriticBackendProcess = false;
      }
      
      this.communicationService.Event.Editor.Saving.$UnsavedRemainingProperties.emit(
        Object.keys(this.propertiesSaveQueue).length);
    } else {
      this.propertiesSaveQueue = {};
      this.communicationService.Event.Editor.Saving.$UnsavedRemainingProperties.emit(
        Object.keys(this.propertiesSaveQueue).length);
    }
    
    
    // console.log('removed: ', Object.keys(this.propertiesSaveQueue).length);
  }
  
  private addToTimer(property: PropertyVersioningDto) {
    performance.mark(`start ${ property.elementId }`);
  }
  
  private runTimerTracker() {
    if (this.propertiesSaveInterval === null) {
      this.propertiesSaveInterval = setInterval(() => {
        if (Object.entries(this.propertiesSaveQueue).length === 0) {
          clearInterval(this.propertiesSaveInterval);
          this.propertiesSaveInterval = null;
          console.log('data saved');
          setTimeout(() => {
            this.communicationService.Event.Editor.Saving.$AllDataSaved.emit(true);
          }, 200);
          this.criticAlert = false;
        } else {
          Object.entries(this.propertiesSaveQueue)
          .forEach(
            ([elementId, properties], index) => {
              performance.mark(`end ${ elementId }`);
              performance.measure(
                elementId,
                `start ${ elementId }`,
                `end ${ elementId }`,
              );
              
              const elementElapsedTime = performance.getEntriesByName(
                elementId,
              )[performance.getEntriesByName(elementId).length - 1].duration;
              
              if (
                this.busService.Get(elementId) &&
                elementElapsedTime > this.timeCriticBusyIndicator &&
                !this.busService.Get(elementId).CriticBackendProcess
              ) {
                const htmlElement = document.getElementById(
                  `gridsterItem-${ elementId }`,
                );
                
                if (htmlElement) {
                  htmlElement.classList.add('critic-border-busy-indicator');
                }
                
                this.busService.Get(elementId).CriticBackendProcess = true;
              }
              
              if (
                elementElapsedTime > this.timeCriticAlert &&
                !this.criticAlert
              ) {
                console.log('critic alert');
                this.criticAlert = true;
                this.communicationService.Event.Editor.Saving.$BackendNotSavingState.emit(
                  Object.keys(this.propertiesSaveQueue).length);
              }
            },
          );
        }
      }, this.timeFrequencyCheck);
    }
  }
}

export enum SavingDelay {
  Low = 10,
  Medium = 3000,
  High = 5000
}
