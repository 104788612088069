import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as LZString from '../../../assets/lz-string.js';
import { Constants } from '../../shared/constants';
import { SuperLocalService } from './superLocal.service';
import { ToolsService } from './tools.service';

@Injectable()
export class LocalStorageService {
  Debug = null;
  encryptEnabled = false;
  memoryLocalStorage = {};
  
  constructor(private superLocalService: SuperLocalService, private toolsService: ToolsService, private router: Router) {
    // navigator.storage.estimate().then(function(estimate) {
    //   console.log('Total Storage Available: ' + estimate.quota);
    //   console.log('Storage Used: ' + estimate.usage);
    // });
    //
    // console.log(this.GetLocalStorageSpaceUsed());
  }
  
  public Set(name: string, value: any, highPriority = true) {
    const customName = `${ Constants.LocalStoragePrefix }${ name }`;
    
    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }
    
    // compress/encrypt
    value = this.CompressString(value);
    //
    
    try {
      this.memoryLocalStorage[customName] = value;
      localStorage.setItem(customName, value);
    } catch (e) {
      console.log('==no space==');
      if (highPriority) {
        if (this.SL_RemoveOldestData()) {
          this.memoryLocalStorage[customName] = value;
          this.Set(customName, value, highPriority);
        } else {
          this.SL_ClearAll();
          
          try {
            this.memoryLocalStorage[customName] = value;
            localStorage.setItem(customName, value);
          } catch (e) {
            console.log('==no space 2==');
            
            if (this.DataFits(value)) {
              this.memoryLocalStorage[customName] = value;
              localStorage.setItem(customName, value);
            } else {
              console.log('no local storage space available');
            }
          }
        }
      }
    }
  }
  
  public Get(name: string, ignoreCache = false): any {
    // console.warn('Reading LocalStorage', name);
    const customName = `${ Constants.LocalStoragePrefix }${ name }`;
    let item = null;
    const memoryItem = this.memoryLocalStorage[customName];
    
    if (memoryItem && !ignoreCache) {
      item = memoryItem;
    } else {
      item = localStorage.getItem(customName);
      this.memoryLocalStorage[customName] = item;
    }
    
    // if data is saved already encrypted and encrypt is disabled, decrypt data
    if (!this.encryptEnabled && item && (item as string).startsWith('EQ')) {
      try {
        const clearItem = LZString.decompressFromBase64(item);
        item = clearItem;
      } catch (error) {
        console.log(error);
      }
    }
    //
    
    if (item) {
      let data = null;
      
      try {
        data = JSON.parse(this.DecompressString(item));
      } catch (e) {
        return item;
      }
      return data;
    } else {
      return item;
    }
  }
  
  public Remove(name: string) {
    const customName = `${ Constants.LocalStoragePrefix }${ name }`;
    delete this.memoryLocalStorage[customName];
    localStorage.removeItem(customName);
  }
  
  public IsDebug(): boolean {
    if (this.Debug === null) {
      this.Debug = !!JSON.parse(localStorage.getItem(`${ Constants.LocalStoragePrefix }debug`));
      
      return this.Debug;
    } else {
      return this.Debug;
    }
  }
  
  public SL_Fetch(key: string) {
    return this.superLocalService.Fetch(key);
  }
  
  public SL_Save(uId: string, data: any, customTimeStamp = null, persistOnClear = false) {
    this.superLocalService.Save(uId, data, customTimeStamp, persistOnClear);
  }
  
  public SL_ClearAll(hardClear = false) {
    this.memoryLocalStorage = {};
    this.superLocalService.ClearAll(hardClear);
  }
  
  public SL_RemoveOldestData(): boolean {
    this.memoryLocalStorage = {};
    return this.superLocalService.RemoveOldest();
  }
  
  public GetDataSize(data: string): number {
    if (data) {
      return (data.length * 2) / 1024 / 1024;
    } else {
      return 0;
    }
  }
  
  public SL_Keys() {
    return this.superLocalService.Keys();
  }
  
  public GetLocalStorageSpaceUsed() {
    let total = 0;
    for (let x in localStorage) {
      // Value is multiplied by 2 due to data being stored in `utf-16` format, which requires twice the space.
      const amount = (localStorage[x].length * 2) / 1024 / 1024;
      if (!isNaN(amount) && localStorage.hasOwnProperty(x)) {
        total += amount;
      }
    }
    return total;
  }
  
  public GetLocalStorageSpaceAvailable(): number {
    return 10 - this.GetLocalStorageSpaceUsed();
  }
  
  public DataFits(value: string): boolean {
    return this.GetDataSize(value) < this.GetLocalStorageSpaceAvailable();
  }
  
  DecompressString(compressedString: string) {
    return this.encryptEnabled ? LZString.decompressFromBase64(compressedString) : compressedString;
  }
  
  private CompressString(string) {
    return this.encryptEnabled ? LZString.compressToBase64(JSON.stringify(string)) : JSON.stringify(string);
  }
}
