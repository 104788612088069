import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import saveAs from 'file-saver';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';
import { CobbleService } from './../../shared/representative-molecule/services/cobble.service';
import { ApiDataService } from './api-data.service';
import { CacheService } from './cache.service';
import { ClientStorageService } from './client-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ApiFileService extends ApiDataService {
  mimetypes = [];
  public fileSelected$ = new Subject();
  filesSelected = [];
  dialogOpen = false;
  fileServiceRunning = false;
  
  constructor(
    http: HttpClient,
    errorMessengerService: ErrorMessengerService,
    private clientStorage: ClientStorageService,
    protected cobbleService: CobbleService,
    private cacheService: CacheService,
  ) {
    super('files', http, errorMessengerService);
  }
  
  OpenFileDialog() {
    this.dialogOpen = true;
    document.getElementById('moleculeFileSelector').click();
  }
  
  evaluateFileDialogValue(eventData: any) {
    if (!this.dialogOpen) {
      const dialog = document.getElementById('moleculeFileSelector');
      if (dialog) {
        setTimeout(() => {
          // console.log(this.filesSelected);
          
          this.fileSelected$.next(this.filesSelected);
          // this.filesSelected = [];
        }, 300);
      } else {
        //console.warn('dialog file does not exists');
      }
    }
  }
  
  // Editor = 1,
  // Application = 2
  uploadFile(eventData: any[], uploadType = 2, appId = null) {
    // console.log(eventData);
    
    if (eventData.length === 0) {
      return;
    }
    
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'bearer ' + this.clientStorage.getToken());
    const options = {
      headers: headers,
      responseType: 'text',
    };
    const file = this.fileToFormData(eventData, uploadType, appId);
    
    return this.upload(file, options);
  }
  
  upload(file: any, options: any) {
    return this.http.post(this.apiEndpointUrl, file, options).pipe(
      map((response) => {
        this.filesSelected = [];
        return response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(err, 'Error uploading file');
        //console.error('Error uploading Data Source', err);
        return of(err);
      }),
    );
  }
  
  getFileInformation(guid: string): Observable<{
    cobbleId: number;
    contentType: string;
    dataFile: string;
    guid: string;
    name: string;
    type: string;
  }> {
    if (typeof guid === 'undefined') {
      return of(null);
    }
    
    if (this.cacheService.IsCached(guid.trim())) {
      return this.cacheService.GetAsync(guid.trim());
    } else {
      const request = this.http.get(this.apiEndpointUrl + `/${ guid.trim() }`).pipe(
        tap((result) => {
          this.cacheService.Store(guid.trim(), result, true, true);
          return result;
        }),
      );
      return request as any;
    }
  }
  
  downloadFile_deprecated(guid: any, zip = false) {
    if (guid.indexOf('http') > -1 || guid.indexOf('https') > -1) {
      const newWindow = window.open('', '_blank');
      newWindow.location = guid;
    } else {
      this.getFileInformation(guid).subscribe(
        (imgData) => {
          let headers = new HttpHeaders();
          headers = headers.append('Authorization', 'bearer ' + this.clientStorage.getToken());
          
          this.http
          .get(this.apiEndpointUrl + `/download/${ guid.trim() }`, {
            responseType: 'arraybuffer',
          })
          .pipe(map((response) => <any>response))
          .subscribe((result) => {
            // console.log(result);
            
            this._downLoadFile(result, imgData);
          });
        },
        (error) => {
        },
      );
    }
  }
  
  downloadFiles(guids: string[], zip = false, zipName = 'files') {
    
    if (guids && guids.length > 0) {
    
    } else {
      return;
    }
    
    guids = guids.filter((guid) => guid && guid !== '');
    if ((guids.length === 1 && guids[0].indexOf('http') > -1) || guids[0].indexOf('https') > -1) {
      const newWindow = window.open('', '_blank');
      newWindow.location = guids[0] as any;
    } else {
      if (zip) {
        this.http
        .post(
          this.apiEndpointUrl + `/download`,
          {
            zip,
            fileGuids: guids,
          },
          {
            responseType: 'arraybuffer',
          },
        )
        .pipe(map((response) => <any>response))
        .subscribe((result) => {
          this._downLoadFile(result, {
            contentType: 'application/zip',
            name: zipName,
          });
        }, (error) => {
          console.log(error);
        });
      } else {
        guids.forEach((guid) => {
          this.getFileInformation(guid).subscribe(
            (imgData) => {
              this.http
              .post(
                this.apiEndpointUrl + `/download`,
                {
                  zip: false,
                  fileGuids: [guid.trim()],
                },
                {
                  responseType: 'arraybuffer',
                },
              )
              .pipe(map((response) => <any>response))
              .subscribe((result) => {
                this._downLoadFile(result, imgData);
              }, (error) => {
                console.log(error);
              });
            },
            (error) => {
            },
          );
        });
      }
    }
  }
  
  _downLoadFile(data: any, file: any) {
    const blob = new Blob([data], { type: file.contentType });
    const url = window.URL.createObjectURL(blob);
    saveAs(blob, file.name);
    // window.open(url);
  }
  
  // _downloadFile(data: Response) {
  //   const blob = new Blob([data], { type: 'text/csv' });
  //   const url = window.URL.createObjectURL(blob);
  //   window.open(url);
  // }
  
  // private _downloadFile(filePath) {
  
  //   const link = document.createElement('a');
  
  //   document.body.appendChild(link);
  //   link.setAttribute('style', 'display: none');
  //   link.setAttribute('target', '_blank');
  //   link.href = filePath;
  //   link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
  //   // console.log(link.download);
  
  //   link.click();
  //   window.URL.revokeObjectURL(filePath);
  //   link.remove();
  // }
  
  private fileToFormData(eventData: any[], uploadType = 2, appId = null): FormData {
    const file: File = eventData[0];
    const formData = new FormData();
    
    formData.append('userId', String(this.clientStorage.getUserId()));
    formData.append('name', file.name);
    formData.append('type', file.type);
    formData.append('dataFile', file, file.name);
    formData.append('cobbleId', String(appId ? appId : this.cobbleService.Cobble.id));
    formData.append('uploadType', String(uploadType));
    
    return formData;
  }
}
