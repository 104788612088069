import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class NavigateMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(particleId: string, repMoleculeId: string, busProcessorKey: string, rule: any, logic: any, data: any) {
    try {
      data = data === undefined || data === null || data === {} ? '' : data;
      const element = this.busService.Get(repMoleculeId);
      
      this.busService.Channel.forEach((el) => {
        // if (el.value.context.type === 'WorkGroup') {
        //   el.value.context.show =
        //     el.value.context.id.toString() === rule.workgroup.toString();
        // }
      });
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
