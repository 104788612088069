import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class CenterAlignMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    data: any,
  ) {
    try {
      const molecule = this.busService.Get(repMoleculeId);
      
      molecule.Properties.align = 'center';
      this.communicationService.Event.Editor.$RepresentativeMoleculeDetection.emit({ repMoleculeId: +repMoleculeId, state: true });
      
      // console.log(`molecule working, center alignment`);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
    repMolecule.Properties.align = 'center';
  }
  
  public AfterRemove(repMolecule: IRepresentativeMolecule, data?: any) {
    repMolecule.Properties.align = 'left';
  }
}
