import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../../running-area/services/loader.service';
import { HTTPStatusService } from '../services/httpStatus.service';
import { ToolsService } from '../services/tools.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  requestsCount = 0;
  
  constructor(private httpStatusService: HTTPStatusService, private loaderService: LoaderService, private toolsService: ToolsService) {
  }
  
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.IsUrlAllowed(req.url)) {
      if (this.toolsService.RunningMode && this.loaderService) {
        this.loaderService.isLoading.next(true);
      }
      
      this.httpStatusService.setHttpStatus(true);
      this.requestsCount++;
    }
    return next.handle(req).pipe(
      finalize(() => {
        // loaderService.hide();
        
        if (this.IsUrlAllowed(req.url)) {
          this.requestsCount--;
          
          if (this.requestsCount <= 0) {
            this.httpStatusService.setHttpStatus(false);
            if (this.toolsService.RunningMode && this.loaderService) {
              this.loaderService.isLoading.next(false);
            }
          }
        }
      }),
    );
  }
  
  
  private IsUrlAllowed(url: string) {
    const urlToAvoidLoader = ['/alive'];
    return !(!!urlToAvoidLoader.some(urlToAvoid => url.includes(urlToAvoid)));
  }
}
