import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';
import { Constants } from './../../../shared/constants';

export class LiteralMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    dataBus: any,
    dataElementsData: any,
  ) {
    try {
      const literalValue = dataElementsData ? this.toolsService.ExtractValuesByType(dataElementsData).string || Constants.Defaults.DataItemValue : rule.value || Constants.Defaults.DataItemValue;
      dataBus = literalValue;
      // console.log(`molecule working, input=> ${dataBus} output => ${dataBus}`);
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    } catch (error) {
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    }
  }
  
  public AfterRemove(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
