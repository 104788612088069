import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import 'brace';
import 'brace/ext/language_tools';
import 'brace/mode/css';
import 'brace/mode/html';
import 'brace/mode/javascript';
import 'brace/mode/json';
import 'brace/theme/ambiance';
import 'brace/theme/dreamweaver';
import 'brace/theme/iplastic';
import 'brace/theme/tomorrow_night_bright';
import { AceComponent, AceConfigInterface, AceDirective } from 'ngx-ace-wrapper';
import { of, Subject } from 'rxjs';
import { debounceTime, delay, flatMap, map } from 'rxjs/operators';
import { Permissions } from '../admin/models/permissions.enum';
import { ApiPropertiesService } from '../core/services/api-properties.service';
import { EditorStateService } from '../core/services/editor-state.service';
import { GenericDialogService } from '../core/services/generic-dialog.service';
import { TemplateService } from '../core/services/template.service';
import { ToolsService } from '../core/services/tools.service';
import { UserMenuService } from '../core/services/user-menu.service';
import { Constants } from '../shared/constants';
import { PropertyVersioningDto } from '../shared/dtos/versioning-dto';
import { RepresentativeMolecule } from '../shared/representative-molecule/interfaces/representative-molecule';
import { IRepresentativeMolecule } from '../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CommunicationService } from '../shared/services/communication.service';
import { DraggableWindowService, DraggableWindowType } from '../shared/services/draggable-window.service';
import { SnackerService } from '../shared/services/snacker.service';
import { customTemplate } from '../shared/templates/custom';
import { SelectIconDialogComponent } from '../workarea/select-icon-dialog/select-icon-dialog.component';
import { WorkAreaService } from '../workarea/workarea.service';

@Component({
  selector: 'app-web-editor',
  templateUrl: './web-editor.component.html',
  styleUrls: ['./web-editor.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ transform: 'scale(0)', opacity: 0 }), animate('150ms', style({ transform: 'scale(1)', opacity: 1 }))]),
    ]),
    trigger('expand', [
      transition(':enter', [style({ height: '0px' }), animate('150ms', style({ height: '50px' }))]),
      transition(':leave', [style({ height: '50px' }), animate('200ms', style({ height: '0px' }))]),
    ]),
  ],
})
export class WebEditorComponent implements OnInit {
  
  
  @ViewChild('templateNameInput', { static: false })
  templateNameInput: ElementRef;
  
  public htmlEditorConfig: AceConfigInterface = {
    enableLiveAutocompletion: true,
    enableBasicAutocompletion: true,
    mode: 'html',
    theme: 'ambiance',
    readOnly: false,
  };
  
  public cssEditorConfig: AceConfigInterface = {
    enableLiveAutocompletion: true,
    enableBasicAutocompletion: true,
    mode: 'css',
    theme: 'ambiance',
    readOnly: false,
  };
  
  invalidCssImports = false;
  
  displayIframe = true;
  
  public jsEditorConfig: AceConfigInterface = {
    enableLiveAutocompletion: true,
    enableBasicAutocompletion: [{
      getCompletions: (editor, session, pos, prefix, callback) => {
        // Implement your custom logic to fetch completions
        console.log(prefix);
        var completions = [
          { value: 'Leap', score: 100, meta: 'Leap System Events' },
        ];
        
        callback(null, completions);
      },
    }],
    mode: 'javascript',
    theme: 'ambiance',
    readOnly: false,
  };
  
  @ViewChild(AceComponent, { static: false }) componentRef?: AceComponent;
  @ViewChild(AceDirective, { static: false }) directiveRef?: AceDirective;
  
  renderIframe = '';
  
  public externalDependencyChange = new Subject<any>();
  editorOptions = {
    viewStyle: 'tabbed',
    showEvents: true,
    showReceptors: true,
    tabSelected: 0,
    autosave: true,
  };
  changesToSave = false;
  showOptions = false;
  
  public editorChanges = new Subject<any>();
  initializing = true;
  repMolecule: IRepresentativeMolecule;
  css = '';
  js = '';
  html = '';
  
  newTemplate = false;
  editTemplate = null;
  editTemplateId = null;
  enableDependencies = false;
  public jsCodeChange = new Subject<any>();
  invalidJsCode = false;
  showDocumentation = false;
  eventsDoc = [];
  receptorsDoc = [];
  
  constructor(private draggableWindowService: DraggableWindowService,
              private propertiesService: ApiPropertiesService,
              private snackerService: SnackerService,
              private userMenuService: UserMenuService,
              private templateService: TemplateService,
              private toolsService: ToolsService,
              private editorStateService: EditorStateService,
              private dialogService: GenericDialogService,
              private workareaService: WorkAreaService,
              private communicationService: CommunicationService,
              private renderer: Renderer2) {
    this.enableDependencies = this.userMenuService.checkPermission(Permissions.EnableCustomControlDependencies);
    
    const data = this.draggableWindowService.GetData();
    if (data && data.repMolecule) {
      this.repMolecule = data.repMolecule;
    } else if (data.templateId) {
      this.editTemplateId = data.templateId;
      this.editTemplate = data.editTemplate;
      this.editorOptions.autosave = false;
      this.repMolecule = new RepresentativeMolecule(this.editTemplate);
    } else {
      this.newTemplate = true;
      this.editorOptions.autosave = false;
      const template = this.toolsService.DeepCloneObject(customTemplate[0]);
      this.repMolecule = new RepresentativeMolecule(template);
      this.repMolecule.Properties.name = '';
      setTimeout(() => {
        this.templateNameInput.nativeElement.focus();
        this.templateNameInput.nativeElement.select();
      }, 200);
    }
    
  }
  
  ngOnInit(): void {
    this.editorChanges
    .pipe(
      map((event) => event),
      debounceTime(350),
      flatMap((search) => of(search).pipe(delay(50))),
    )
    .subscribe((value) => {
      this.UpdateChanges();
    });
    
    this.externalDependencyChange
    .pipe(
      debounceTime(150),
      flatMap((search) => of(search).pipe(delay(50))),
    )
    .subscribe((value) => {
      this.ValidateExternalDependencies();
      this.UpdateChanges();
    });
    
    this.jsCodeChange
    .pipe(
      debounceTime(1000),
      flatMap((search) => of(search).pipe(delay(50))),
    )
    .subscribe((value) => {
      this.ValidateJsCode();
    });
    
    this.communicationService.Event.System.$WindowResized.subscribe(({ height }) => {
      const element = document.querySelector('.documentation-container');
      if (!element) return;
      
      this.renderer.setStyle(element, 'height', `${ height - 70 }px`);
    });
    
    this.ValidateCssImports();
    this.ValidateExternalDependencies();
    this.RenderIframe();
    
    if (this.repMolecule.Properties.customControl.events) {
    } else {
      this.repMolecule.Properties.customControl['events'] = [];
    }
    
    if (this.repMolecule.Properties.customControl.receptors) {
    } else {
      this.repMolecule.Properties.customControl['receptors'] = [];
    }
    
    if (this.repMolecule.Properties.customControl.options.documentation) {
    } else {
      this.repMolecule.Properties.customControl.options.documentation = {
        info: '',
        events: [],
        receptors: [],
      };
    }
    
    if (this.repMolecule.Properties.customControl.options.documentation.events) {
    
    } else {
      this.repMolecule.Properties.customControl.options.documentation.events = [];
    }
    
    if (this.repMolecule.Properties.customControl.options.documentation.receptors) {
    
    } else {
      this.repMolecule.Properties.customControl.options.documentation.receptors = [];
    }
    
    
    this.OrderEventReceptorDoc('events');
    this.OrderEventReceptorDoc('receptors');
  }
  
  UpdateChanges() {
    console.log('update');
    if (!this.initializing) {
      this.changesToSave = true;
      this.RenderIframe();
      if (this.editorOptions.autosave && !this.newTemplate && this.editTemplateId === null) {
        this.SaveChanges();
      }
    } else {
      this.initializing = false;
    }
  }
  
  RenderIframe() {
    this.initializing = true;
    try {
      const jsReplacements = {
        'LeapXL.TriggerEvent': 'console.log',
        'LeapXL.RegisterControlReceptor': 'console.log',
      };
      this.html = this.repMolecule.Properties.customControl.html;
      this.js = this.repMolecule.Properties.customControl.js;
      this.css = this.repMolecule.Properties.customControl.css;
      
      let clearHtml = this.html;
      clearHtml = (clearHtml as any).replaceAll('<script>', '');
      clearHtml = (clearHtml as any).replaceAll('</script> ', '');
      clearHtml = (clearHtml as any).replaceAll('<html>', '');
      clearHtml = (clearHtml as any).replaceAll('</html>', '');
      clearHtml = (clearHtml as any).replaceAll('<!DOCTYPE html>', '');
      
      let externalJs = '';
      let externalCss = '';
      
      if (this.repMolecule.Properties.customControl.options) {
        if (this.repMolecule.Properties.customControl.options.externalJs) {
          this.repMolecule.Properties.customControl.options.externalJs.forEach(extJs => {
            if (extJs && extJs.url !== '' && extJs.valid) {
              const jsTag = `<script ${ extJs.module ? 'type="module"' : '' } src="${ extJs.url }" type="application/javascript"></script>`;
              externalJs += jsTag;
            }
          });
        }
        
        if (this.repMolecule.Properties.customControl.options.externalCss) {
          this.repMolecule.Properties.customControl.options.externalCss.forEach(extCss => {
            if (extCss && extCss.url !== '' && extCss.valid) {
              const cssTag = `<link href="${ extCss.url }" rel="stylesheet">`;
              externalCss += cssTag;
            }
          });
        }
      }
      
      Object.keys(jsReplacements).forEach(key => {
        this.js = this.toolsService.ReplaceInText(this.js, key, jsReplacements[key]);
      });
      
      if (this.js.includes('InitCustomControl')) {
        this.js = this.js + `\n\n InitCustomControl();\n`;
      }
      
      const iframeBody =
        '<html>' +
        '<head>' +
        externalCss +
        '<style>' +
        this.css +
        '</style>' +
        '</head>' +
        '<body style="margin : 10px;" >' +
        '<div style="display: flex; justify-content: center;">' + clearHtml + '</div>' +
        externalJs +
        `${ this.repMolecule.Properties.customControl.options.jsModuleType ? '<script type="module" >' : '<script>' }` +
        'window["LeapXL"] = {' +
        '"RegisterControlReceptor": function(_,__,___){' +
        '}' +
        '}; ' +
        '        function runEditorJS() {' +
        '          \'use script\';' +
        '          try { console.log("editor js running"); ' +
        this.js +
        '          } catch(e) {' +
        '            console.error(e);' +
        '          }' +
        '        }' +
        'runEditorJS()' +
        '</script>' +
        '</body>' +
        '</html>';
      
      
      this.displayIframe = false;
      setTimeout(() => {
        if (this.editorOptions.autosave && !this.newTemplate && this.editTemplateId === null) {
          this.repMolecule.$UpdateValue.emit();
        }
        this.renderIframe = iframeBody;
        this.displayIframe = true;
        setTimeout(() => {
          this.initializing = false;
        }, 300);
      }, 300);
    } catch (e) {
      console.error(e);
      this.initializing = false;
    }
  }
  
  ValidateJsCode() {
    this.toolsService.MinifyMangleJsCode(this.repMolecule.Properties.customControl.js, false, true).then(result => {
      this.invalidJsCode = result.error;
    });
  }
  
  ValidateCssImports() {
    if (this.repMolecule.Properties.customControl.css !== '') {
      this.invalidCssImports = this.repMolecule.Properties.customControl.css.indexOf('@import') > -1;
    }
  }
  
  ValidateExternalDependencies() {
    if (this.repMolecule.Properties.customControl.options) {
      if (this.repMolecule.Properties.customControl.options.externalCss) {
        this.repMolecule.Properties.customControl.options.externalCss.forEach(externalCss => {
          externalCss.valid = this.toolsService.IsUrl(externalCss.url);
        });
      }
      
      if (this.repMolecule.Properties.customControl.options.externalJs) {
        this.repMolecule.Properties.customControl.options.externalJs.forEach(externalJs => {
          externalJs.valid = this.toolsService.IsUrl(externalJs.url);
        });
      }
    }
  }
  
  SaveChanges() {
    
    if (this.editTemplateId !== null) {
      return;
    }
    
    this.repMolecule.Properties.customControl.modified = true;
    
    this.ValidateExternalDependencies();
    this.ValidateCssImports();
    
    const propertiesToSave = [
      new PropertyVersioningDto({
        elementId: this.repMolecule.Id.toString(),
        property: 'customControl',
        value: this.repMolecule.Properties.customControl,
        path: 'properties',
        change: `Edit custom control`,
        name: this.repMolecule.Properties.name,
      }),
    ];
    
    this.changesToSave = false;
    this.propertiesService
    .SaveProperties(propertiesToSave)
    .subscribe((result) => {
    
    });
  }
  
  ManualSaveChanges() {
    this.SaveChanges();
    this.repMolecule.$UpdateValue.emit();
  }
  
  AddCustomEvent(eventName: string) {
    
    if (eventName === '') {
      return;
    }
    
    if (this.repMolecule.Properties.customControl.events.map(ce => ce.name).includes(eventName)) {
      this.snackerService.ShowMessageOnBottom('An event with this name already exists', 'repeat_one_on');
      return;
    }
    
    console.log(eventName);
    this.repMolecule.Properties.customControl.events.push({ name: eventName });
    this.communicationService.Event.Editor.EventsTree.$RefreshEventsTree.emit();
    
    if (this.editTemplateId !== null || this.newTemplate) {
      return;
    }
    
    this.repMolecule.SavePropertyFromVersioning(new PropertyVersioningDto({
      elementId: this.repMolecule.Id.toString(),
      property: 'events',
      value: this.repMolecule.Properties.customControl.events,
      change: 'Custom events modified',
      version: 2.0,
      path: 'properties.customControl',
      name: this.repMolecule.Properties.name,
    })).subscribe();
    this.snackerService.ShowMessageOnBottom('Custom event added', 'add_circle');
    this.OrderEventReceptorDoc('events');
  }
  
  AddCustomReceptor(receptor: string, receptorType: any) {
    const receptorName = `${ (receptor as any).replaceAll(' ', '-') }-${ receptorType }`.toLowerCase();
    
    if (receptorName === '') {
      return;
    }
    
    if (this.repMolecule.Properties.customControl.receptors.map(cr => cr.name).includes(receptorName)) {
      this.snackerService.ShowMessageOnBottom('A Receptor with this name already exists', 'repeat_one_on');
      return;
    }
    
    this.repMolecule.Properties.customControl.receptors.push({ name: receptorName });
    
    if (this.editTemplateId !== null || this.newTemplate) {
      return;
    }
    
    this.repMolecule.SavePropertyFromVersioning(new PropertyVersioningDto({
      elementId: this.repMolecule.Id.toString(),
      property: 'receptors',
      value: this.repMolecule.Properties.customControl.receptors,
      change: 'Custom receptors modified',
      version: 2.0,
      path: 'properties.customControl',
      name: this.repMolecule.Properties.name,
    })).subscribe();
    this.snackerService.ShowMessageOnBottom('Custom receptor added', 'add_circle');
    this.OrderEventReceptorDoc('receptors');
  }
  
  dragStartEventHandler(event: DragEvent, leapEvent: any) {
    if (this.editorOptions.viewStyle === 'tabbed' && this.editorOptions.tabSelected !== 2) {
      return;
    }
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
    event.dataTransfer.setData('text/plain', `LeapXL.TriggerEvent("${ leapEvent.name }", "[DATA]");`);
  }
  
  dragStartReceptorHandler(event: DragEvent, receptor: any) {
    if (this.editorOptions.viewStyle === 'tabbed' && this.editorOptions.tabSelected !== 2) {
      return;
    }
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
    
    event.dataTransfer.setData('text/plain', `LeapXL.RegisterControlReceptor("${ receptor.name }", (${ receptor.name.includes('input') ? 'data' : '' }) => {${ receptor.name.includes('output') ? 'return "";' : '' }});`);
  }
  
  SaveEditorOptions() {
  
  }
  
  ViewStyleChanged() {
    this.initializing = true;
  }
  
  ToggleOptionsPanel() {
    this.showOptions = !this.showOptions;
  }
  
  ToggleShowDocumentation() {
    if (!this.repMolecule.Properties.customControl.options.documentation) {
      this.repMolecule.Properties.customControl.options.documentation = {
        info: '',
        events: [],
        receptors: [],
      };
    }
    
    this.showDocumentation = !this.showDocumentation;
  }
  
  SwitchTab(tab: number) {
    this.initializing = true;
    this.editorOptions.tabSelected = tab;
  }
  
  SaveEditTemplate() {
    this.CreateTemplate(this.editTemplate);
  }
  
  CreateTemplate(template: any = null) {
    this.ValidateExternalDependencies();
    this.ValidateCssImports();
    
    template = template || this.toolsService.DeepCloneObject(customTemplate[0]);
    template.properties = this.repMolecule.Properties;
    template.name = this.repMolecule.Properties.name;
    template.icon = this.repMolecule.Icon;
    
    this.templateService.CreateCustomControlTemplate(template, this.editTemplateId).subscribe(response => {
      this.workareaService.draggableWindow[
      this.workareaService.draggableWindow.length - 1
        ].Hide();
      this.workareaService.draggableWindow.pop();
      this.snackerService.ShowMessageOnBottom(this.newTemplate ? `${ template.name } added to your Custom Controls.` : 'Custom Control Saved', 'library_add');
      this.communicationService.Event.Editor.Library.$ReloadLibrary.emit(true);
      console.log(response);
    });
  }
  
  OpenIconSelection() {
    this.dialogService.openDialog(null, SelectIconDialogComponent).then(iconSelected => {
      if (iconSelected !== this.repMolecule.Icon) {
        this.repMolecule.Icon = iconSelected;
      }
    });
  }
  
  RemoveEntry(array: any[], indexToRemove: number) {
    
    if (array[indexToRemove].url === '') {
      array.splice(indexToRemove, 1);
    } else {
      array[indexToRemove].url = '';
    }
    
    this.UpdateChanges();
  }
  
  RemoveEventReceptor(array: any[], indexToRemove: number, isReceptor = false) {
    
    this.dialogService.OpenConfirmDialog({
      title: `Remove ${ isReceptor ? 'receptor' : 'event' }`,
      message: `Are you sure you want to remove this ${ isReceptor ? 'receptor' : 'event' }?`,
      confirmText: 'Remove',
      cancelText: 'Cancel',
    }).then(result => {
      
      if (result) {
        array.splice(indexToRemove, 1);
        this.UpdateChanges();
        
        this.snackerService.ShowMessageOnBottom(`Custom ${ isReceptor ? 'receptor' : 'event' } removed`, 'error');
      }
      this.OrderEventReceptorDoc(isReceptor ? 'receptors' : 'events');
    });
    
  }
  
  ShareCustomControl() {
    this.toolsService.DragWindowConfig = this.editorStateService.WindowSize('HierarchyShared');
    
    this.draggableWindowService.OpenDraggableWindow(
      `Share`,
      DraggableWindowType.HierarchyShared,
      null,
      {
        data: {
          entityIdToShare: this.editTemplateId,
          entityNameToShare: this.repMolecule.Properties.name,
          type: 'customControl',
        },
      },
    );
  }
  
  CustomControlHelp() {
    this.toolsService.DragWindowConfig = Constants.Defaults.DraggableWindowSizes.Microlearning;
    
    this.workareaService.userGuideId = 'CustomControlsEditor';
    this.workareaService.userGuideType = 'help';
    
    if (this.workareaService.userGuideWindow) {
      this.communicationService.Event.Documentation.$Get.emit();
    } else {
      this.draggableWindowService.OpenDraggableWindow(
        `Custom Controls Editor Help`,
        DraggableWindowType.Microlearning,
        null,
        {
          id: this.workareaService.userGuideId,
          type: this.workareaService.userGuideType,
        },
      );
    }
    
    return;
    // disable custom documentation component
    this.toolsService.DragWindowConfig = this.editorStateService.WindowSize('CustomControlHelp');
    
    this.draggableWindowService.OpenDraggableWindow(
      'Custom Control Help',
      DraggableWindowType.CustomControlHelp,
      null,
      {
        data: {},
      },
    );
  }
  
  DocInfo(event, eventName, type: 'events' | 'receptors') {
    const eventInfo = this.repMolecule.Properties.customControl.options.documentation[type].find(({ name }) => name === eventName);
    
    if (eventInfo) {
      eventInfo.info = event.target.value;
    } else {
      this.repMolecule.Properties.customControl.options.documentation[type].push({
        name: eventName,
        info: event.target.value,
      });
    }
    
    this.UpdateChanges();
  }
  
  GetDocInfo(eventName, type: 'events' | 'receptors') {
    const event = this.repMolecule.Properties.customControl.options.documentation[type].find(({ name }) => name === eventName);
    return event ? event.info : '';
  }
  
  OrderEventReceptorDoc(type: 'events' | 'receptors') {
    type === 'events' ? this.eventsDoc = [] : this.receptorsDoc = [];
    
    this.repMolecule.Properties.customControl[type].forEach((eventReceptor, index) => {
      type === 'events' ? this.eventsDoc.push({ name: eventReceptor.name, info: '' }) : this.receptorsDoc.push({ name: eventReceptor, info: '' });
      
      const eventReceptorDoc = this.repMolecule.Properties.customControl.options.documentation[type].find(eventReceptorDoc => eventReceptorDoc.name === eventReceptor.name);
      
      if (eventReceptorDoc) {
        type === 'events' ? this.eventsDoc[index].info = eventReceptorDoc.info : this.receptorsDoc[index].info = eventReceptorDoc.info;
      }
    });
  }
  
  keyPressAlphaNumeric(event) {
    
    var inp = String.fromCharCode(event.keyCode);
    
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  
  public onEditorBlur(event: any): void {
    console.log('Editor blur:', event);
  }
  
  public onEditorFocus(event: any): void {
    console.log('Editor focus:', event);
  }
  
  public onValueChange(value: string): void {
    // console.log('Value change:', value);
  }
  
  public onContentChange(event: any): void {
    console.log('Content change:', event);
  }
  
  public onSelectionChange(event: any): void {
    console.log('Selection change:', event);
  }
}
