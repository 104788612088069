import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { TreeNode } from 'angular-tree-component';
import { Subscription } from 'rxjs';
import { AutoGenerationService } from '../../core/services/autogeneration.service';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { GenerationAppActionComponent } from '../generation-app-action/generation-app-action.component';
import { AutoGenAction } from '../interfaces/autogen-action.interface';
import { AutoGenProcess } from '../interfaces/autogen-process.interface';

@Component({
  selector: 'generation-app-process',
  templateUrl: './generation-app-process.component.html',
  styleUrls: ['./generation-app-process.component.scss'],
})
export class GenerationAppProcessComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  @Input() id: number;
  @Input() processImport: AutoGenProcess;
  @Output() process = new EventEmitter<AutoGenProcess>();
  @Output() remove = new EventEmitter<number>();
  componentsRef: ComponentRef<GenerationAppActionComponent>[] = [];
  processObj: any = {};
  nodes: TreeNode[] = [];
  order = 0;
  disableAddAction = true;
  subscription: Subscription;
  
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private dialogService: GenericDialogService,
    public autoGenerationService: AutoGenerationService,
    private communicationService: CommunicationService,
  ) {
  }
  
  ngOnInit(): void {
    if (!this.processImport) {
      this.processObj.id = this.id;
    } else {
      this.processObj.id = this.processImport.id;
    }
    
    this.subscription = this.communicationService.Event.Editor.DataSource.$DisableAddAction.subscribe(
      disableAddAction => {
        if (this.processObj.id === disableAddAction.id) {
          this.disableAddAction = disableAddAction.disable;
        }
      });
    
    this.processObj.actions = [];
  }
  
  ngAfterViewInit(): void {
    if (this.processImport?.actions.length > 0) {
      this.CreateActions();
    } else {
      this.processImport = {
        id: 0,
        actions: [],
      };
      this.CreateAction();
    }
  }
  
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
  
  CreateAction(action: AutoGenAction = null) {
    this.communicationService.Event.Editor.DataSource.$DisableAddProcess.emit(true);
    if (!action) {
      this.autoGenerationService.actionId++;
    }
    this.order++;
    const factory = this.componentFactoryResolver.resolveComponentFactory(GenerationAppActionComponent);
    const component = this.container.createComponent(factory);
    
    this.componentsRef.forEach(component => component.instance.isLastActionInProcess = false);
    
    this.componentsRef.push(component);
    component.instance.nodes = this.nodes;
    component.instance.order = this.order;
    component.instance.processId = this.processObj.id;
    component.instance.isLastActionInProcess = true;
    component.instance.action.subscribe(action => {
      const existingActionIndex = this.processImport.actions.findIndex(a => a.id === action.id);
      
      if (existingActionIndex !== -1) {
        this.processImport.actions[existingActionIndex] = action;
      } else {
        this.processImport.actions.push(action);
      }
      
      this.processObj.actions = this.processImport.actions;
      this.process.emit(this.processObj as AutoGenProcess);
    });
    
    component.instance.remove.subscribe(id => {
      this.order--;
      component.destroy();
      this.componentsRef = this.componentsRef.filter(c => c.instance.id !== id);
      
      if (this.componentsRef.length > 0) {
        this.componentsRef[this.componentsRef.length - 1].instance.isLastActionInProcess = true;
      }
      
      this.processObj.actions = this.processObj.actions.filter(a => a.id !== id);
      this.process.emit(this.processObj as AutoGenProcess);
      
      this.communicationService.Event.Editor.DataSource.$DisableAddProcess.emit(false);
      
      if (this.componentsRef.length === 0) {
        this.remove.emit(this.processObj.id);
      }
    });
    
    if (action) {
      component.instance.actionObj = action;
    }
  }
  
  CreateActions() {
    this.order = 0;
    this.componentsRef.forEach(component => component.destroy());
    this.processImport.actions.forEach(action => this.CreateAction(action));
  }
  
  async RemoveProcess() {
    const result = await this.dialogService.OpenConfirmDialog({
      title: 'Remove Process',
      message: 'Are you sure you want to remove this process? This action can not be undone',
      confirmText: 'Remove',
      cancelText: 'Cancel',
      maxWidth: '700px',
    });
    
    if (!result) {
      return;
    }
    
    this.communicationService.Event.Editor.DataSource.$RemoveAction.emit(this.id);
  }
}
