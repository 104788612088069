import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DynamicFormComponent } from '../dynamic-form/components/dynamic-form.component';
import { FieldConfig } from '../dynamic-form/models/field-config.model';

@Component({
  selector: 'app-molecule-parameters-dialog',
  templateUrl: './molecule-parameters-dialog.component.html',
  styleUrls: ['./molecule-parameters-dialog.component.css'],
})
export class MoleculeParametersDialogComponent implements AfterViewInit {
  @ViewChild(DynamicFormComponent, { static: true }) form: DynamicFormComponent;
  
  config: FieldConfig[];
  
  constructor(
    public dialogRef: MatDialogRef<MoleculeParametersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
  ) {
    this.config = data.parameters || [];
    if (data.events !== null) {
      this.config.push(data.events);
    }
    this.config.push({
      label: 'Accept',
      name: 'submit',
      type: 'button',
      busId: '',
      particleId: '',
      disabled: true,
      isMoleculeSetting: false,
    });
  }
  
  ngAfterViewInit() {
    const previousValid = this.form.valid;
    this.form.changes.subscribe(() => {
      this.form.setDisabled('submit', !this.form.valid);
    });
  }
  
  submit(value: { [name: string]: any }) {
    this.dialogRef.close({ valid: this.form.valid, data: value });
  }
}
