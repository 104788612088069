import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { uniqBy } from 'lodash-es';
import { FileUploader } from 'ng2-file-upload';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, flatMap, map, startWith } from 'rxjs/operators';
import { ApiApplicationSettingsService } from '../../core/services/api-application-settings.service';
import { ApiFileService } from '../../core/services/api-file.service';
import { ApiMoleculesService } from '../../core/services/api-molecules.service';
import { ApiPropertiesService } from '../../core/services/api-properties.service';
import { EditorStateService } from '../../core/services/editor-state.service';
import { ToolsService } from '../../core/services/tools.service';
import { PropertyVersioningDto } from '../../shared/dtos/versioning-dto';
import { ApplicationSettings } from '../../shared/interfaces/application-settings.interface';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { ApiPublishedCobblesService } from '../../shared/services/api-published-cobbles.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { DraggableWindowManagerService } from '../../shared/services/draggable-window-manager.service';
import { DraggableWindowService, DraggableWindowType } from '../../shared/services/draggable-window.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { WorkAreaService } from '../workarea.service';
import { BusService } from './../../core/molecular/services/bus.service';
import { ApiAuthService } from './../../core/services/api-auth.service';
import { ApiDataSourcesService } from './../../core/services/api-data-sources.service';
import { ApiHierarchyService } from './../../core/services/api-hierarchy.service';
import { ApiMessagesService } from './../../core/services/api-messages.service';
import { ClientStorageService } from './../../core/services/client-storage.service';
import { UserMenuService } from './../../core/services/user-menu.service';
import { Constants } from './../../shared/constants';

@Component({
  selector: 'app-cobble-properties',
  templateUrl: './cobble-properties.component.html',
  styleUrls: ['./cobble-properties.component.scss'],
})
export class CobblePropertiesComponent implements OnInit, OnDestroy {
  @ViewChild('propertiesForm', { static: true })
  propertiesForm: NgForm;
  
  @ViewChild('emailShareInput', { static: false })
  emailShareInput: any;
  
  @ViewChild('shareNodeInput', { static: false })
  shareNodeInput: any;
  DraggableWindowType = 'AppProperties';
  uploader: FileUploader = new FileUploader({});
  productionVersion: any;
  productionVersions: any[] = [];
  nodesShared: any[] = [];
  public subscriptions: Subscription;
  public appName = new Subject<any>();
  public appDescription = new Subject<any>();
  name = '';
  embedView = 0;
  fileToUploadType = '';
  environment = Constants.Environment;
  url = '';
  qrCodeImage = '';
  
  allowToDelete = false;
  ownerData = null;
  treeControl = new NestedTreeControl<any>((node) => node.children);
  dataSourceTree = new MatTreeNestedDataSource<any>();
  shareNodes = [];
  myControl = new FormControl('');
  filteredNodes: Observable<any[]>;
  public responsiveBlockMessage = new Subject<any>();
  snapshotAutoSave: boolean;
  snapshotSaveFrequency: number;
  snapshotClearDays: number;
  applicationSettings: ApplicationSettings = null;
  isOwner = false;
  screenshotError = false;
  
  constructor(
    public cobbleService: CobbleService,
    private toolsService: ToolsService,
    private propertiesService: ApiPropertiesService,
    private fileService: ApiFileService,
    private imageCompress: NgxImageCompressService,
    private workAreaService: WorkAreaService,
    private publishedCobbleService: ApiPublishedCobblesService,
    private snackerService: SnackerService,
    private editorStateService: EditorStateService,
    private moleculesService: ApiMoleculesService,
    private communicationService: CommunicationService,
    private draggableWindowService: DraggableWindowService,
    private dragableWindowManagerService: DraggableWindowManagerService,
    private userMenuService: UserMenuService,
    private clientStorageService: ClientStorageService,
    private apiDataSourcesService: ApiDataSourcesService,
    private apiAuthService: ApiAuthService,
    private messagesService: ApiMessagesService,
    private busService: BusService,
    private hierarchyService: ApiHierarchyService,
    private applicationSettingsService: ApiApplicationSettingsService,
  ) {
    this.isOwner = this.clientStorageService.getUserId() === this.cobbleService.Cobble.ownerId;
    this.allowToDelete = this.isOwner || this.userMenuService.isAdminUser;
    this.responsiveBlockMessage.pipe(debounceTime(350))
    .subscribe((data) => {
      const message = data.event.target.value;
      const messageProperty = data.name;
      
      this.propertiesService
      .SaveProperty(
        new PropertyVersioningDto({
          elementId: this.cobbleService.Cobble.id.toString(),
          property: messageProperty,
          value: message,
          path: 'properties',
          change: `Block message changed`,
          name: this.cobbleService.Cobble.properties.name,
        }),
      )
      .subscribe();
    });
  }
  
  hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;
  
  ngOnInit() {
    this.name = this.cobbleService.Cobble.properties.name;
    
    this.subscriptions = this.communicationService.Event.Editor.AppsTree.$AppShared.subscribe(() => {
      console.log('=event=');
      this.GetAppSharedNodes();
    });
    
    this.subscriptions.add(this.communicationService.Event.Editor.$ErrorCaptureCanvas.subscribe(() => {
      console.log('=event=');
      this.screenshotError = true;
    }));
    
    this.appName
    .pipe(
      map((event) => event.target.value),
      debounceTime(900),
      flatMap((search) => of(search)
      .pipe(delay(20))),
    )
    .subscribe((newName) => {
      if (newName === this.cobbleService.Cobble.properties.name) {
        return;
      }
      
      this.propertiesService.VerifyCobbleName(newName, this.cobbleService.Cobble.id)
      .subscribe((availability: boolean) => {
        if (availability) {
          this.cobbleService.Cobble.properties.name = newName;
          this.name = newName;
          this.propertiesService
          .SaveProperty(
            new PropertyVersioningDto({
              elementId: this.cobbleService.Cobble.id.toString(),
              property: 'name',
              value: newName,
              path: 'properties',
              change: `Name changed`,
              name: this.cobbleService.Cobble.properties.name,
            }),
          )
          .subscribe();
        } else {
          this.snackerService.ShowMessageOnBottom('An App already exists with this name!', 'repeat_one_on');
          this.name = this.cobbleService.Cobble.properties.name;
        }
        
        this.communicationService.Event.System.App.$AppEdited.emit(true);
      });
    });
    
    this.appDescription.pipe(
      debounceTime(600),
    )
    .subscribe(() => this.DescriptionChanged());
    
    setTimeout(() => {
      
      if (this.propertiesForm) {
        Object.keys(this.propertiesForm.controls)
        .forEach((key) => {
          this.propertiesForm.form
          .get(key)
          .valueChanges
          .pipe(debounceTime(300))
          .subscribe((value) => {
            if (this.propertiesForm.dirty) {
              this.propertiesForm.form.markAsPristine();
              this.communicationService.Event.System.App.$AppEdited.emit(true);
              this.propertiesService
              .SaveProperty(
                new PropertyVersioningDto({
                  elementId: this.cobbleService.Cobble.id.toString(),
                  property: key,
                  value: value,
                  path: 'properties',
                  change: `${ key } changed`,
                  name: this.cobbleService.Cobble.properties.name,
                }),
              )
              .subscribe();
            }
          });
        });
      }
      
    }, 500);
    
    this.moleculesService.getPublishedCobbles(this.cobbleService.Cobble.id)
    .subscribe((published) => {
      if (published) {
        this.productionVersions = published.reverse();
        this.productionVersion = published.find((p) => p.enable);
      }
    });
    
    this.GetAppSharedNodes();
    
    this.url = `${ document.location.protocol }//${ document.location.hostname }${
      document.location.hostname.search('localhost') > -1 ? ':4200' : ''
    }/run/${ this.cobbleService.Cobble.companySlug || '' }/${ this.cobbleService.Cobble.slug }`;
    
    this.toolsService.GenerateLeapXLQRCode(this.url)
    .subscribe((image) => {
      this.qrCodeImage = image;
    });
    
    this.apiAuthService.getUserById(this.cobbleService.Cobble.ownerId)
    .subscribe((user) => {
      this.ownerData = user;
    });
    
    this.applicationSettingsService.ApplicationSettings(this.cobbleService.Cobble.id)
    .subscribe(settings => {
      this.applicationSettings = settings;
      this.snapshotClearDays = this.applicationSettings.daysBeforeClearingSnapshots;
      this.snapshotAutoSave = this.applicationSettings.automaticSnapshots;
      this.snapshotSaveFrequency = this.applicationSettings.snapshotsFrequency;
    });
    
    this.GetDatasourcesUsed();
    this.GetCompanyNodes();
  }
  
  DescriptionChanged() {
    this.propertiesService.SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: 'description',
        value: this.cobbleService.Cobble.properties.description,
        path: 'properties',
        change: `Description updated`,
        name: this.cobbleService.Cobble.properties.name,
      }),
    )
    .subscribe();
  }
  
  ShareAppWithNode(nodeName: string) {
    if (nodeName !== '') {
      const node = this.shareNodes.find((node) => node.nodeName === nodeName);
      
      if (node) {
        this.ShareApp(this.cobbleService.Cobble.id, node.nodeId, node.nodeType, node.nodeName);
      }
    }
  }
  
  ExportApp(event: MouseEvent) {
    this.draggableWindowService.OpenDraggableWindow(
      'Export Leap Application (Beta Test)',
      DraggableWindowType.LeapxlExport,
      event,
      {
        export: {
          type: 'app',
          name: this.cobbleService.Cobble.properties.name,
          id: this.cobbleService.Cobble.id,
          isApplication: this.cobbleService.Cobble.isApplication,
        },
      },
    );
  }
  
  GetCompanyNodes() {
    this.shareNodes = [];
    this.hierarchyService.getShareTree(0)
    .subscribe((node) => {
      this.AddNodeToShareNodes(node);
      this.filteredNodes = this.myControl.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value || '')),
      );
    });
  }
  
  ShareAppLink() {
    const address = this.emailShareInput.nativeElement.value;
    if (!this.toolsService.EmailValid(address)) {
      this.snackerService.ShowMessageOnBottom(`Please provide a valid email address`);
      return;
    }
    
    const emailTemplate = `<div>
    <style type="text/css">
        <!--
        .rps_f211 html {
            background-color: #E1E1E1;
            margin: 0;
            padding: 0
        }

        .rps_f211>div,
        .rps_f211 #x_bodyTable,
        .rps_f211 #x_bodyCell,
        .rps_f211 #x_bodyCell {
            height: 100% !important;
            margin: 0;
            padding: 0;
            width: 100% !important;
            font-family: Helvetica, Arial, "Lucida Grande", sans-serif
        }

        .rps_f211 table {
            border-collapse: collapse
        }

        .rps_f211 table[id=bodyTable] {
            width: 100% !important;
            margin: auto;
            max-width: 600px !important;
            color: #7A7A7A;
            font-weight: normal
        }

        .rps_f211 img,
        .rps_f211 a img {
            border: 0;
            outline: none;
            text-decoration: none;
            height: auto;
            line-height: 100%
        }

        .rps_f211 a {
            text-decoration: none !important;
            border-bottom: 1px solid
        }

        .rps_f211 h1,
        .rps_f211 h2,
        .rps_f211 h3,
        .rps_f211 h4,
        .rps_f211 h5,
        .rps_f211 h6 {
            color: #5F5F5F;
            font-weight: normal;
            font-family: Helvetica;
            font-size: 20px;
            line-height: 125%;
            text-align: Left;
            letter-spacing: normal;
            margin-top: 0;
            margin-right: 0;
            margin-bottom: 10px;
            margin-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0
        }

        .rps_f211 .x_ReadMsgBody {
            width: 100%
        }

        .rps_f211 .x_ExternalClass {
            width: 100%
        }

        .rps_f211 .x_ExternalClass,
        .rps_f211 .x_ExternalClass p,
        .rps_f211 .x_ExternalClass span,
        .rps_f211 .x_ExternalClass font,
        .rps_f211 .x_ExternalClass td,
        .rps_f211 .x_ExternalClass div {
            line-height: 100%
        }

        .rps_f211 table,
        .rps_f211 td {}

        .rps_f211 #x_outlook a {
            padding: 0
        }

        .rps_f211 img {
            display: block;
            outline: none;
            text-decoration: none
        }

        .rps_f211>div,
        .rps_f211 table,
        .rps_f211 td,
        .rps_f211 p,
        .rps_f211 a,
        .rps_f211 li,
        .rps_f211 blockquote {
            font-weight: normal !important
        }

        .rps_f211 .x_ExternalClass td[class="ecxflexibleContainerBox"] h3 {
            padding-top: 10px !important
        }

        .rps_f211 h1 {
            display: block;
            font-size: 26px;
            font-style: normal;
            font-weight: normal;
            line-height: 100%
        }

        .rps_f211 h2 {
            display: block;
            font-size: 20px;
            font-style: normal;
            font-weight: normal;
            line-height: 120%
        }

        .rps_f211 h3 {
            display: block;
            font-size: 17px;
            font-style: normal;
            font-weight: normal;
            line-height: 110%
        }

        .rps_f211 h4 {
            display: block;
            font-size: 18px;
            font-style: italic;
            font-weight: normal;
            line-height: 100%
        }

        .rps_f211 .x_flexibleImage {
            height: auto
        }

        .rps_f211 .x_linkRemoveBorder {
            border-bottom: 0 !important
        }

        .rps_f211 table[class=flexibleContainerCellDivider] {
            padding-bottom: 0 !important;
            padding-top: 0 !important
        }

        .rps_f211>div,
        .rps_f211 #x_bodyTable {
            background-color: #E1E1E1
        }

        .rps_f211 #x_emailHeader {
            background-color: #E1E1E1
        }

        .rps_f211 #x_emailBody {
            background-color: #FFFFFF
        }

        .rps_f211 #x_emailFooter {
            background-color: #E1E1E1
        }

        .rps_f211 .x_nestedContainer {
            background-color: #F8F8F8;
            border: 1px solid #CCCCCC
        }

        .rps_f211 .x_emailButton {
            background-color: #205478;
            border-collapse: separate
        }

        .rps_f211 .x_buttonContent {
            color: #FFFFFF;
            font-family: Helvetica;
            font-size: 18px;
            font-weight: bold;
            line-height: 100%;
            padding: 15px;
            text-align: center
        }

        .rps_f211 .x_buttonContent a {
            color: #FFFFFF;
            display: block;
            text-decoration: none !important;
            border: 0 !important
        }

        .rps_f211 .x_emailCalendar {
            background-color: #FFFFFF;
            border: 1px solid #CCCCCC
        }

        .rps_f211 .x_emailCalendarMonth {
            background-color: #205478;
            color: #FFFFFF;
            font-family: Helvetica, Arial, sans-serif;
            font-size: 16px;
            font-weight: bold;
            padding-top: 10px;
            padding-bottom: 10px;
            text-align: center
        }

        .rps_f211 .x_emailCalendarDay {
            color: #205478;
            font-family: Helvetica, Arial, sans-serif;
            font-size: 60px;
            font-weight: bold;
            line-height: 100%;
            padding-top: 20px;
            padding-bottom: 20px;
            text-align: center
        }

        .rps_f211 .x_imageContentText {
            margin-top: 10px;
            line-height: 0
        }

        .rps_f211 .x_imageContentText a {
            line-height: 0
        }

        .rps_f211 #x_invisibleIntroduction {
            display: none !important
        }

        .rps_f211 span[class=ios-color-hack] a {
            color: #275100 !important;
            text-decoration: none !important
        }

        .rps_f211 span[class=ios-color-hack2] a {
            color: #205478 !important;
            text-decoration: none !important
        }

        .rps_f211 span[class=ios-color-hack3] a {
            color: #8B8B8B !important;
            text-decoration: none !important
        }

        @media only screen and (max-width: 480px) {
            .rps_f211>div {
                width: 100% !important;
                min-width: 100% !important
            }

            .rps_f211 table[id="emailHeader"],
            .rps_f211 table[id="emailBody"],
            .rps_f211 table[id="emailFooter"],
            .rps_f211 table[class="flexibleContainer"],
            .rps_f211 td[class="flexibleContainerCell"] {
                width: 100% !important
            }

            .rps_f211 td[class="flexibleContainerBox"],
            .rps_f211 td[class="flexibleContainerBox"] table {
                display: block;
                width: 100%;
                text-align: left
            }

            .rps_f211 td[class="imageContent"] img {
                height: auto !important;
                width: 100% !important;
                max-width: 100% !important
            }

            .rps_f211 img[class="flexibleImage"] {
                height: auto !important;
                width: 100% !important;
                max-width: 100% !important
            }

            .rps_f211 img[class="flexibleImageSmall"] {
                height: auto !important;
                width: auto !important
            }

            .rps_f211 table[class="flexibleContainerBoxNext"] {
                padding-top: 10px !important
            }

            .rps_f211 table[class="emailButton"] {
                width: 100% !important
            }

            .rps_f211 td[class="buttonContent"] {
                padding: 0 !important
            }

            .rps_f211 td[class="buttonContent"] a {
                padding: 15px !important
            }

        }

        @media only screen and (-webkit-device-pixel-ratio:.75) {}

        @media only screen and (-webkit-device-pixel-ratio:1) {}

        @media only screen and (-webkit-device-pixel-ratio:1.5) {}

        @media only screen and (min-device-width: 320px) and (max-device-width:568px) {}
        -->
    </style>
    <div class="rps_f211">
        <div leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" style="background-color:#E1E1E1">
            <center style="background-color:#E1E1E1">
                <table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="x_bodyTable"
                    style="table-layout:fixed; max-width:100%!important; width:100%!important; min-width:100%!important">
                    <tbody>
                        <tr>
                            <td align="center" valign="top" id="x_bodyCell">
                                <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="600"
                                    id="x_emailBody" style="margin-top:70px">
                                    <tbody>
                                        <tr>
                                            <td align="center" valign="top">
                                                <table border="0" cellpadding="0" cellspacing="0" width="100%"
                                                    bgcolor="white" style="color:#FFFFFF">
                                                    <tbody>
                                                        <tr>
                                                            <td align="center" valign="top">
                                                                <table border="0" cellpadding="0" cellspacing="0"
                                                                    width="600" class="x_flexibleContainer">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td align="center" valign="top" width="600"
                                                                                class="x_flexibleContainerCell">
                                                                                <table border="0" cellpadding="30"
                                                                                    cellspacing="0" width="100%">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td align="left"
                                                                                                valign="top"
                                                                                                class="x_textContent">
                                                                                                <img data-imagetype="External"
                                                                                                    src="https://app.leapxl.com/assets/leap_logo/leap_logo_black.png"
                                                                                                    alt="LeapXL"
                                                                                                    width="180"
                                                                                                    height="66"
                                                                                                    style="width:180px; height:66px; float:left; display:block">
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="center" valign="top">
                                                <table border="0" cellpadding="0" cellspacing="0" width="100%"
                                                    bgcolor="#FFFFFF" style="height:100%">
                                                    <tbody>
                                                        <tr>
                                                            <td align="center" valign="top">
                                                                <table border="0" cellpadding="0" cellspacing="0"
                                                                    width="600" class="x_flexibleContainer">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td align="center" valign="top" width="600"
                                                                                class="x_flexibleContainerCell">
                                                                                <table border="0" cellpadding="30"
                                                                                    cellspacing="0" width="100%">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td align="center"
                                                                                                valign="top"
                                                                                                style="padding-top:0">
                                                                                                <table border="0"
                                                                                                    cellpadding="0"
                                                                                                    cellspacing="0"
                                                                                                    width="100%">
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td valign="top"
                                                                                                                class="x_textContent">
                                                                                                                <div
                                                                                                                    style="text-align: center; font-family: Helvetica, Arial, sans-serif, serif, EmojiFont; font-size: 17px; margin-bottom: 0px; color: rgb(95, 95, 95); line-height: 135%;">
                                                                                                                    <h1
                                                                                                                        style="text-align:center; font-size:30px; font-weight:normal">
                                                                                                                        App Link Shared
                                                                                                                    </h1>
                                                                                                                    <p>
                                                                                                                        This App has been shared with you by ${ this.clientStorageService.getUserFullName() } (${ this.clientStorageService.getUserName() }), click in the link below to run the application.
                                                                                                                    </p>
                                                                                                                    <br
                                                                                                                        aria-hidden="true"><a
                                                                                                                        href="${ this.url }"
                                                                                                                        target="_blank"
                                                                                                                        rel="noopener noreferrer"
                                                                                                                        data-auth="NotApplicable"
                                                                                                                        style="font-size:20px; padding:8px; width:170px; margin-bottom:10px; border-bottom:none"
                                                                                                                        data-linkindex="0"><u>Open ${
      this.cobbleService.Cobble
        .properties.name
    }</u>
                                                                                                                    </a>
                                                                                                                    <p>Thanks!
                                                                                                                        Enjoy
                                                                                                                        your
                                                                                                                        leaping!
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="600"
                                    id="x_emailFooter">
                                    <tbody>
                                        <tr>
                                            <td align="center" valign="top">
                                                <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                                    <tbody>
                                                        <tr>
                                                            <td align="center" valign="top">
                                                                <table border="0" cellpadding="0" cellspacing="0"
                                                                    width="600" class="x_flexibleContainer">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td align="center" valign="top" width="600"
                                                                                class="x_flexibleContainerCell">
                                                                                <table border="0" cellpadding="30"
                                                                                    cellspacing="0" width="100%">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td valign="top"
                                                                                                bgcolor="#E1E1E1">
                                                                                                <div
                                                                                                    style="font-family: Helvetica, Arial, sans-serif, serif, EmojiFont; font-size: 13px; color: rgb(130, 130, 130); text-align: center; line-height: 120%;">
                                                                                                    <div>Copyright ©
                                                                                                        2023 - LeapXL
                                                                                                        All&nbsp;rights&nbsp;reserved.
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </center>
        </div>
    </div>
</div>`;
    
    this.messagesService.SendHTMLEmail(address, `${ this.cobbleService.Cobble.properties.name } Link Shared`,
      emailTemplate)
    .subscribe((response) => {
      this.snackerService.ShowMessageOnBottom(`App link shared via email!`, 'send');
    });
  }
  
  AddNodeToShareNodes(mainNode: any, parentNode = null) {
    console.log('properties');
    this.shareNodes.push({
      nodeId: mainNode.other.id,
      nodeName: mainNode.name,
      nodeCompany: parentNode ? parentNode.name : '',
      nodeType: mainNode.other.type,
    });
    
    mainNode.list.forEach((node) => {
      this.AddNodeToShareNodes(node, mainNode);
    });
  }
  
  ShareApp(appId: number, nodeId: number, nodeType: string, nodeName: string) {
    this.moleculesService
    .saveCobbletShare([
      {
        objectId: appId,
        id: nodeId,
        added: true,
        type: nodeType,
      },
    ])
    .subscribe((res) => {
      this.communicationService.Event.Editor.AppsTree.$AppShared.emit(true);
      this.snackerService.ShowMessageOnBottom(
        `App ${ this.cobbleService.Cobble.properties.name } shared to ${ nodeName }!`, 'ios_share');
    });
  }
  
  GetDatasourcesUsed() {
    const usedDataSources = uniqBy(this.busService.GetDataSourcesForApp(), (e) => {
      return e.translationId;
    });
    
    const groupedUsedDatasoruces = this.toolsService.GroupBy(usedDataSources, (d) => d.dataSourceName);
    const tree = [];
    
    groupedUsedDatasoruces.forEach((group, key) => {
      const parent = {
        name: `${ group.length > 0 ? group[0].dataSourceType + ' > ' : '' }${ key }`,
        children: [],
      };
      
      group.forEach((g) => {
        parent.children.push({
          name: g.context,
          children: [],
        });
      });
      
      tree.push(parent);
    });
    
    this.dataSourceTree.data = tree.sort(this.toolsService.CompareValues('name'));
  }
  
  GetAppSharedNodes() {
    this.nodesShared = [];
    this.moleculesService.GetCobbleSharedNodes(this.cobbleService.Cobble.id)
    .subscribe((nodesShared) => {
      this.nodesShared = nodesShared;
    });
  }
  
  VersionToggle(vesion: any) {
  }
  
  MainViewChanged() {
    this.propertiesService
    .SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: 'mainView',
        value: this.cobbleService.Cobble.properties.mainView,
        path: 'properties',
        change: `Main View changed`,
        name: this.cobbleService.Cobble.properties.name,
      }),
    )
    .subscribe();
  }
  
  LoaderStyleChanged() {
    this.propertiesService
    .SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: 'loader',
        value: this.cobbleService.Cobble.loader,
        path: '',
        change: `Loader Style changed`,
        name: this.cobbleService.Cobble.properties.name,
      }),
    )
    .subscribe();
  }
  
  unshare(sharedTo: any) {
    this.moleculesService
    .saveCobbletShare([{
      objectId: this.cobbleService.Cobble.id,
      id: sharedTo.other.id,
      added: false,
      type: sharedTo.other.type,
    }])
    .subscribe((res) => {
      this.cobbleService.Cobble.nodesShared = res;
      this.GetAppSharedNodes();
    });
  }
  
  UpdateSizeProperties(property, value) {
    this.propertiesService
    .SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: property,
        value: value.checked,
        path: `deviceConfigurations.${ this.cobbleService.Cobble.deviceType }`,
        change: `Lock App size`,
        name: property,
      }),
    )
    .subscribe((result) => {
      this.communicationService.Event.System.App.$AppChanged.emit(true);
    });
  }
  
  DeletionLock(value) {
    this.moleculesService.LockDeletion(this.cobbleService.Cobble.id, value.checked)
    .subscribe();
  }
  
  ResetFirstTimeAccess(environment: string) {
    const data = {
      applicationId: this.cobbleService.Cobble.id,
      preview: false,
      published: false,
    };
    
    data[environment] = true;
    
    this.moleculesService.ResetFirstTimeAccess(data)
    .subscribe();
  }
  
  AutoLogOffChange() {
    this.propertiesService
    .SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: 'autoLogOff',
        value: this.cobbleService.Cobble.properties['autoLogOff'],
        path: 'properties',
        change: `Auto log off toggle`,
        name: this.cobbleService.Cobble.properties.name,
      }),
    )
    .subscribe((result) => {
    });
  }
  
  ToggleAsTemplate() {
    this.propertiesService
    .SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: 'isComponentTemplate',
        value: this.cobbleService.Cobble.isComponentTemplate,
        path: '',
        change: `Toggle template`,
        name: this.cobbleService.Cobble.properties.name,
      }),
    )
    .subscribe((result) => {
    });
  }
  
  
  DarkModeAllowChange() {
    this.propertiesService
    .SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: 'allowDarkMode',
        value: this.cobbleService.Cobble.properties['allowDarkMode'],
        path: 'properties',
        change: `Dark mode toggle`,
        name: this.cobbleService.Cobble.properties.name,
      }),
    )
    .subscribe((result) => {
    });
  }
  
  CacheToggled() {
    this.propertiesService
    .SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: 'enableCache',
        value: this.cobbleService.Cobble.properties['enableCache'],
        path: 'properties',
        change: `Cache toggle`,
        name: this.cobbleService.Cobble.properties.name,
      }),
    )
    .subscribe((result) => {
    });
  }
  
  AnimationsChange() {
    this.propertiesService
    .SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: 'disableAnimations',
        value: this.cobbleService.Cobble.properties['disableAnimations'],
        path: 'properties',
        change: `Disable animations`,
        name: this.cobbleService.Cobble.properties.name,
      }),
    )
    .subscribe((result) => {
    });
  }
  
  PrivateChange() {
    this.propertiesService
    .SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: 'privateApp',
        value: this.cobbleService.Cobble.properties['privateApp'],
        path: 'properties',
        change: `Toggle app private status`,
        name: this.cobbleService.Cobble.properties.name,
      }),
    )
    .subscribe();
  }
  
  UpdatePropertyChange(property: string) {
    this.propertiesService
    .SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: property,
        value: this.cobbleService.Cobble.properties[property],
        path: 'properties',
        change: `Toggle ${ property }`,
        name: this.cobbleService.Cobble.properties.name,
      }),
    )
    .subscribe();
  }
  
  AutoLogOffTimeoutChange() {
    this.propertiesService
    .SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: 'autoLogOffTimeoutMinutes',
        value: this.cobbleService.Cobble.properties['autoLogOffTimeoutMinutes'],
        path: 'properties',
        change: `Auto log off timeout change`,
        name: this.cobbleService.Cobble.properties.name,
      }),
    )
    .subscribe((result) => {
    });
  }
  
  BackgroundChange(property: string) {
    this.propertiesService
    .SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: property,
        value: this.cobbleService.Cobble.properties[property],
        path: 'properties',
        change: `Background type changed`,
        name: this.cobbleService.Cobble.properties.name,
      }),
    )
    .subscribe((result) => {
      this.communicationService.Event.System.App.$AppChanged.emit(true);
    });
  }
  
  BackgroundTypeChange(event: any) {
    this.propertiesService
    .SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: 'backgroundTypeImage',
        value: this.cobbleService.Cobble.properties.backgroundTypeImage,
        path: 'properties',
        change: `Background type changed`,
        name: this.cobbleService.Cobble.properties.name,
      }),
    )
    .subscribe((result) => {
      this.communicationService.Event.System.App.$AppChanged.emit(true);
    });
  }
  
  CopyEmbedCode() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.GetEmbedCode();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  
  GetEmbedCode(): string {
    const embed = `<iframe style="width: 100%;height: 100%" src="${ this.url }">
    <p>App not visible, your browser does not support iframes.</p>
  </iframe>`;
    
    return embed;
  }
  
  UnpublishCobble() {
    this.publishedCobbleService.UnpublishCobble()
    .subscribe((result) => {
      this.communicationService.Event.System.App.$AppEdited.emit(true);
    });
  }
  
  PublicToggle(e: any) {
    this.moleculesService
    .updateCobbleSharing({
      id: this.cobbleService.Cobble.id,
      isPublic: this.cobbleService.Cobble.isPublic,
    })
    .subscribe((result) => {
      this.communicationService.Event.System.App.$AppEdited.emit(true);
    });
  }
  
  selectFile(type: string) {
    this.fileToUploadType = type;
    document.getElementById('imageFileSelector')
    .click();
  }
  
  fileSelected(eventData: any[]) {
    const file = eventData[0];
    const filename = file.name;
    let ratioIcon = 80;
    const maxIconDimension = 200;
    
    if (file) {
      switch (this.fileToUploadType) {
        case 'icon':
          this.toolsService.GetHeightAndWidthFromImageFile(file)
          .then((dimensions) => {
            console.log(dimensions);
            ratioIcon = (maxIconDimension / dimensions.max) * 100;
            
            const reader = new FileReader();
            reader.onload = (event: any) => {
              this.imageCompress.compressFile(event.target.result, -1, ratioIcon, 50)
              .then((compressedImg) => {
                this.cobbleService.Cobble[this.fileToUploadType] = compressedImg;
                this.propertiesService
                .SaveProperty(
                  new PropertyVersioningDto({
                    elementId: this.cobbleService.Cobble.id.toString(),
                    property: this.fileToUploadType,
                    value: compressedImg,
                    path: '',
                    change: `App Icon`,
                    name: this.cobbleService.Cobble.properties.name,
                  }),
                )
                .subscribe((r) => {
                  this.communicationService.Event.System.App.$AppChanged.emit(false);
                });
              });
            };
            reader.readAsDataURL(file);
          });
          break;
        
        case 'loaderImage':
          const lodaerReader = new FileReader();
          lodaerReader.onload = (event: any) => {
            this.toolsService.UrlToFile(event.target.result, filename, file.type)
            .then((imageFile) => {
              this.fileService.uploadFile([imageFile])
              .subscribe((result) => {
                this.cobbleService.Cobble.loaderImage = result;
                this.propertiesService
                .SaveProperty(
                  new PropertyVersioningDto({
                    elementId: this.cobbleService.Cobble.id.toString(),
                    property: 'loaderImage',
                    value: result,
                    path: '',
                    change: `Loader image`,
                    name: this.cobbleService.Cobble.properties.name,
                  }),
                )
                .subscribe((r) => {
                  this.communicationService.Event.System.App.$AppChanged.emit(false);
                });
              });
            });
          };
          lodaerReader.readAsDataURL(file);
          break;
        
        default:
          const reader = new FileReader();
          reader.onload = (event: any) => {
            this.imageCompress.compressFile(event.target.result, -1, ratioIcon, 50)
            .then((compressedImg) => {
              this.toolsService.UrlToFile(file.size < 50000 ? event.target.result : compressedImg, filename,
                file.type)
              .then((imageFile) => {
                this.fileService.uploadFile([imageFile])
                .subscribe((result) => {
                  switch (this.fileToUploadType) {
                    case 'imageUrl':
                      this.cobbleService.Cobble.properties.imageUrl = result;
                      this.propertiesService
                      .SaveProperty(
                        new PropertyVersioningDto({
                          elementId: this.cobbleService.Cobble.id.toString(),
                          property: 'imageUrl',
                          value: result,
                          path: 'properties',
                          change: `Background Image`,
                          name: this.cobbleService.Cobble.properties.name,
                        }),
                      )
                      .subscribe((r) => {
                        this.communicationService.Event.System.App.$AppChanged.emit(false);
                      });
                      break;
                    
                    case 'previewDesktopUrl':
                      this.propertiesService
                      .SaveProperty(
                        new PropertyVersioningDto({
                          elementId: this.cobbleService.Cobble.id.toString(),
                          property: 'previewDesktopUrl',
                          value: result,
                          path: '',
                          change: `Preview Desktop Image`,
                          name: this.cobbleService.Cobble.properties.name,
                        }),
                      )
                      .subscribe((r) => {
                        this.cobbleService.Cobble.previewDesktopUrl = result;
                        this.communicationService.Event.System.App.$AppEdited.emit();
                      });
                      break;
                    
                    case 'previewMobileUrl':
                      this.propertiesService
                      .SaveProperty(
                        new PropertyVersioningDto({
                          elementId: this.cobbleService.Cobble.id.toString(),
                          property: 'previewMobileUrl',
                          value: result,
                          path: '',
                          change: `Preview Mobile Image`,
                          name: this.cobbleService.Cobble.properties.name,
                        }),
                      )
                      .subscribe((r) => {
                        this.cobbleService.Cobble.previewMobileUrl = result;
                        this.communicationService.Event.System.App.$AppEdited.emit();
                      });
                      break;
                  }
                });
              });
            });
          };
          reader.readAsDataURL(file);
          break;
      }
    }
  }
  
  CopyQRcode(qrCodeStringImage: string) {
    this.toolsService.CopyImageToClipboard(qrCodeStringImage);
  }
  
  AutoCapturePreview() {
    this.communicationService.Event.Editor.$CaptureCanvas.emit();
  }
  
  GetViewName(viewId: number) {
    const view = this.cobbleService.Cobble.properties.views.find((v) => v.id === viewId);
    
    if (view) {
      return view.name;
    } else {
      return '<View Removed>';
    }
  }
  
  BlockResponsiveUse(device: string, event: any) {
    this.cobbleService.Cobble.properties[device] = event.checked;
    this.propertiesService
    .SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: device,
        value: event.checked,
        path: 'properties',
        change: `Block responsive use`,
        name: this.cobbleService.Cobble.properties.name,
      }),
    )
    .subscribe((r) => {
    });
  }
  
  SaveSnapshotConfiguration() {
    
    const minimumSaveFrequency = 20;
    const minimumClearDays = 10;
    
    if (this.snapshotSaveFrequency < minimumSaveFrequency) {
      this.snapshotSaveFrequency = minimumSaveFrequency;
      this.snackerService.ShowMessageOnBottom(`Minimum Save Frequency Is ${ minimumSaveFrequency }`);
    }
    
    if (this.snapshotClearDays < minimumClearDays) {
      this.snapshotClearDays = minimumClearDays;
      this.snackerService.ShowMessageOnBottom(`Minimum Clear Frequency Is ${ minimumClearDays }`);
    }
    
    this.applicationSettings.daysBeforeClearingSnapshots = this.snapshotClearDays;
    this.applicationSettings.snapshotsFrequency = this.snapshotSaveFrequency;
    this.applicationSettings.automaticSnapshots = this.snapshotAutoSave;
    this.SaveApplicationSettigns();
  }
  
  SaveApplicationSettigns() {
    this.applicationSettingsService.UpdateApplicationSettings(this.cobbleService.Cobble.id,
      this.applicationSettings)
    .subscribe(result => {
      this.snackerService.ShowMessageOnBottom('Settings saved', 'check_circle');
    });
  }
  
  LockDefaultThemeChange() {
    this.propertiesService
    .SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: 'lockAppDefaultTheme',
        value: this.cobbleService.Cobble.lockAppDefaultTheme,
        path: '',
        change: `Lock Theme toggle`,
        name: this.cobbleService.Cobble.properties.name,
      }),
    )
    .subscribe((result) => {
    });
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    
    return this.shareNodes.filter((node) => node.nodeName.toLowerCase()
    .includes(filterValue));
  }
}
