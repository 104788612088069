import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommunicationService } from 'app/shared/services/communication.service';
import { TemplateService } from '../../core/services/template.service';
import { ActionMolecule } from '../../shared/representative-molecule/interfaces/action-molecules';
import { Bus } from '../../shared/representative-molecule/interfaces/bus';
import { LeapXLEvent } from '../../shared/representative-molecule/interfaces/leapxl-event';
import { DraggableWindowService } from '../../shared/services/draggable-window.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-create-compound',
  templateUrl: './create-compound.component.html',
  styleUrls: ['./create-compound.component.scss'],
})
export class CreateCompoundComponent implements OnInit {
  @ViewChild('input', { static: true })
  input: ElementRef;
  compoundName = '';
  bus: Bus = null;
  
  constructor(
    private workAreaService: WorkAreaService,
    private templateService: TemplateService,
    private draggableWindowService: DraggableWindowService,
    private communicationService: CommunicationService,
    private snackerService: SnackerService,
  ) {
    this.bus = this.draggableWindowService.GetData().bus;
  }
  
  ngOnInit() {
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 200);
  }
  
  SaveCompound() {
    const id = this.workAreaService.draggableWindow[this.workAreaService.draggableWindow.length - 1].Id
    this.workAreaService.draggableWindow[this.workAreaService.draggableWindow.length - 1].Hide();
    this.workAreaService.draggableWindow = this.workAreaService.draggableWindow.filter(dw => dw.Id !== id);
    
    this.CreateCompoundMolecule();
  }
  
  private CreateCompoundMolecule() {
    
    const repMolecule = this.workAreaService.primaryElementsSelected[0];
    const acceptedEvents = [
      'System',
      'View',
      'Custom',
    ];
    
    if (!repMolecule || !this.bus) {
      return;
    }
    
    const clonedBus = this.bus.CloneAndAssignIds();
    
    let compoundMolecule: {
      name: string;
      type: string;
      icon: string;
      eventType: string,
      busReceptor: string;
      particles: any[];
    };
    
    const clonedParticles = [];
    
    clonedBus.Particles.forEach(p => {
      if (!p.IsEvent() || (p.IsEvent() && acceptedEvents.includes((p as LeapXLEvent).EventSource))) {
        const clonedParticle = p.CloneAndAssignIds();
        
        clonedParticle.AssingParticleId();
        clonedParticle.AssingId();
        if (clonedParticle.IsActionMolecule()) {
          (clonedParticle as ActionMolecule).MoleculeId = 0;
        }
        clonedParticles.push(clonedParticle.GetRawObject());
      }
    });
    
    compoundMolecule = {
      name: this.compoundName,
      type: `Compound${ this.compoundName }`.replace(' ', ''),
      icon: 'bubble_chart',
      eventType: '',
      busReceptor: clonedBus.Receptor,
      particles: clonedParticles,
    };
    
    this.templateService.CreateCompound([
      {
        name: 'busReceptor',
        value: compoundMolecule.busReceptor,
      },
      {
        name: 'particles',
        value: compoundMolecule.particles,
      },
      {
        name: 'moleculeType',
        value: 'Compounds',
      },
      {
        name: 'type',
        value: compoundMolecule.type,
      },
      {
        name: 'eventType',
        value: compoundMolecule.eventType,
      },
      {
        name: 'name',
        value: compoundMolecule.name,
      },
      {
        name: 'icon',
        value: compoundMolecule.icon,
      },
    ]).subscribe((response) => {
      this.communicationService.Event.Editor.Library.$ReloadLibrary.emit(true);
      this.snackerService.ShowMessageOnBottom('Compound added to My Library', 'add_circle', null, true);
    });
  }
}
