import { HttpClient } from '@angular/common/http';
import { Directive, EventEmitter, OnDestroy } from '@angular/core';
import { cloneDeep, uniqBy } from 'lodash-es';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { NgxCaptureService } from 'ngx-capture';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Subscription } from 'rxjs';
import { AppInjector } from '../../../app-injector';
import { RuntimeService } from '../../../running-area/services/runtime.service';
import { TFADialogService } from '../../../shared/components/2fa-dialog/2fa-dialog.service';
import { Constants } from '../../../shared/constants';
import { DatasourceType } from '../../../shared/enums/datasource-type.enum';
import { LeapProcessMetadata } from '../../../shared/interfaces/leap-process-metadata.interface';
import { ActionMolecule } from '../../../shared/representative-molecule/interfaces/action-molecules';
import { DataElement } from '../../../shared/representative-molecule/interfaces/data-element';
import { LeapXLEvent } from '../../../shared/representative-molecule/interfaces/leapxl-event';
import {
  IRepresentativeMolecule,
} from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../../../shared/representative-molecule/services/cobble.service';
import { BrowserNotificationsService } from '../../../shared/services/browser-notifications.service';
import { CommunicationService } from '../../../shared/services/communication.service';
import { ErrorMessengerService } from '../../../shared/services/error-messenger.service';
import { SnackerService } from '../../../shared/services/snacker.service';
import { SpreadsheetService } from '../../../spreadsheet/spreadsheet.service';
import { WorkAreaService } from '../../../workarea/workarea.service';
import { ApiAuthService } from '../../services/api-auth.service';
import { ApiDataSourcesService } from '../../services/api-data-sources.service';
import { ApiDataTranslatorService } from '../../services/api-data-translator.service';
import { ApiFileService } from '../../services/api-file.service';
import { ApiMoleculesService } from '../../services/api-molecules.service';
import { CacheService } from '../../services/cache.service';
import { ClientStorageService } from '../../services/client-storage.service';
import { DataManagementService } from '../../services/data-management.service';
import { DevToolsService } from '../../services/dev-tools.service';
import { GenericDialogService } from '../../services/generic-dialog.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { TemplateService } from '../../services/template.service';
import { ToolsService } from '../../services/tools.service';
import { Receptor } from '../receptors.enum';
import { BusService } from '../services/bus.service';

@Directive()
export abstract class ActionMoleculeFunction extends DataManagementService implements OnDestroy {
  DataElements: DataElement[] = [];
  OriginalDataElements: DataElement[] = [];
  RepMoleculeIds: number[] = [];
  EventKey = '';
  MoleculeProcessDone: any;
  actionMolcule: ActionMolecule;
  Subscriptions: Subscription;
  dataElementsRequested = false;
  repMoleculeDataRequested = false;
  public $Data = new EventEmitter<any>();
  RunIndependent = false;
  
  // region  services ===========================
  busService: BusService;
  spreadSheetService: SpreadsheetService;
  dataSourcesService: ApiDataSourcesService;
  clientStorageService: ClientStorageService;
  authService: ApiAuthService;
  adalService: MsAdalAngular6Service;
  toolsService: ToolsService;
  captureService: NgxCaptureService;
  runtimeService: RuntimeService;
  cobbleService: CobbleService;
  snackerService: SnackerService;
  fileService: ApiFileService;
  moleculesService: ApiMoleculesService;
  templateService: TemplateService;
  dataTranslatorService: ApiDataTranslatorService;
  communicationService: CommunicationService;
  browserNotificationsService: BrowserNotificationsService;
  localStorageService: LocalStorageService;
  workAreaService: WorkAreaService;
  devToolsService: DevToolsService;
  imageCompress: NgxImageCompressService;
  tfaDialogService: TFADialogService;
  
  private newDataElementsContextAssoications = {};
  
  // endregion ====================================
  constructor(
    actionMolecule: ActionMolecule,
    http: HttpClient,
    errorMessengerService: ErrorMessengerService,
    communicationService: CommunicationService,
    cacheService: CacheService,
    toolsService: ToolsService,
    snackerService: SnackerService,
    protected dialogService: GenericDialogService,
    runtimeService: RuntimeService,
    cobbleService: CobbleService,
  ) {
    super(
      http,
      errorMessengerService,
      communicationService,
      cacheService,
      toolsService,
      snackerService,
      dialogService,
      runtimeService,
      cobbleService,
    );
    this.actionMolcule = actionMolecule;
  }
  
  get ExistsRepMoleculeAttached() {
    return this.RepMoleculeIds.length > 0;
  }
  
  get RepMoleculeAttachedId(): string {
    return this.ExistsRepMoleculeAttached ? this.RepMoleculeIds[0].toString() : null;
  }
  
  get RepMoleculeAttached(): IRepresentativeMolecule {
    return this.ExistsRepMoleculeAttached ? this.busService.Get(this.RepMoleculeAttachedId) : null;
  }
  
  public Process(
    runIndependent: boolean,
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule?: any,
    logic?: any,
    dataBus?: any,
  ) {
    this.InjectServices();
    this.RunIndependent = runIndependent;
    console.log(this.EventKey, this.actionMolcule.DisplayName);
    try {
      const repMolecule = this.busService.Get(repMoleculeId);
      repMolecule.HighlightRepMoleculeDebug();
      
      this.templateService.GetActionMoleculeProperties([this.actionMolcule.InternalMoleculeName])
      .subscribe((properties) => {
        if (!this.toolsService.RunningMode && !properties[0].allowRunOnEditor) {
          this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
          return;
        }
        
        setTimeout(
          () => {
            this.Subscriptions = this.$Data.subscribe((data) => {
              this.Subscriptions.unsubscribe();
              this.MoleculeProcess(
                particleId,
                repMoleculeId,
                busProcessorKey,
                rule,
                logic,
                dataBus,
                this.dataElementsRequested || this.repMoleculeDataRequested ? data : null,
              );
            });
            
            if (this.RepMoleculeIds.length > 0) {
              const repMoleculeIdAttached = this.RepMoleculeIds[0];
              const repMoleculeAttached = this.busService.Get(repMoleculeIdAttached.toString());
              
              if (repMoleculeAttached) {
                this.repMoleculeDataRequested = true;
                const dataValue = repMoleculeAttached.GetValue;
                this.$Data.emit(
                  Array.isArray(dataValue)
                    ? dataValue
                    : [
                      {
                        col: 0,
                        row: 0,
                        value: dataValue,
                        formattedValue: dataValue,
                        context: '',
                      },
                    ],
                );
              } else {
                this.$Data.emit(dataBus);
              }
            } else {
              if (
                (this.DataElements.length > 0 && !properties[0].bypassGetDataElements) ||
                properties[0].type === 'GetElementsDatasourceDataMolecule'
              ) {
                if (this.DataElements.length === 0) {
                  const particlesToRun = this.busService.GetBusToRunFromProcessorChannel(busProcessorKey);
                  const eventsListeningForData = particlesToRun.bus.Particles.filter((p) => p.IsEvent());
                  const mode = 'view'; // 'app'
                  const navigatingViewMolecule = particlesToRun.bus.GetActionMoleculeParticle(
                    'NavigateViewMolecule');
                  const navigatingViewId =
                    navigatingViewMolecule && navigatingViewMolecule.Rule && navigatingViewMolecule.Rule.view ? navigatingViewMolecule.Rule.view : 0;
                  
                  let dataElements = [];
                  eventsListeningForData.forEach((event) => {
                    const des =
                      mode === 'view' && navigatingViewId > 0
                        ? this.busService.GetDataElementsByBusEventForView(this.cobbleService.Cobble,
                          event as LeapXLEvent, navigatingViewId)
                        : this.busService.GetDataElementsByBusEventForApp(event as LeapXLEvent);
                    dataElements = dataElements.concat(uniqBy(des, 'Context'));
                  });
                  
                  this.DataElements = dataElements;
                }
                
                if (this.DataElements.length === 0) {
                  this.$Data.emit(dataBus);
                } else {
                  this.GetDataElementsData(repMoleculeId, busProcessorKey);
                }
              } else {
                this.$Data.emit(dataBus);
              }
            }
          },
          this.DataElements.length === 0
            ? 0
            : properties[0].delayGetDataElements &&
            !(this.DataElements.length === 1 && this.DataElements[0].DataSourceType === DatasourceType.LocalDataStore)
              ? 200
              : 40,
        );
      });
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    }
  }
  
  public SuspendProcessor(event: string, busProcessorKey: string, elementId: any, data: any) {
    const dataToSuspend = {
      cobbleId: this.cobbleService.Cobble.id,
      date: new Date().getTime(),
      info: {
        key: busProcessorKey,
        molecule: this.actionMolcule.InternalMoleculeName,
        event: event,
        elementId: elementId,
        data: data,
        endProccess: false,
        particleId: this.actionMolcule.ParticleId,
      },
      channel: this.busService.ProcessorChannel,
    };
    
    this.localStorageService.Set(
      'processor_molecules_suspended',
      JSON.stringify(this.toolsService.ClearLeapXLObjectFromCircularDependencies(dataToSuspend)),
    );
    this.localStorageService.Set('processor_suspended', JSON.stringify(true));
    this.communicationService.Event.Runtime.MolecularEngine.$ProcessorSuspended.emit(dataToSuspend);
  }
  
  GetDataElementsData(repMoleculeId: string, busProcessorKey: string) {
    const repMolecule = this.busService.Get(repMoleculeId);
    
    if (repMolecule) {
      const busProcessor = this.busService.GetBusToRunFromProcessorChannel(busProcessorKey);
      this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id] = this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id] || {};
      if (busProcessor && busProcessor.bus) {
        // region set ignore first data row
        switch (busProcessor.bus.Receptor) {
          case Receptor.ValuePreviewInput:
          case Receptor.ValueInput:
          case Receptor.BadgeValueInput:
          case Receptor.OptionsListInput:
            this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id].ignoredIndex =
              repMolecule.Properties.ignoreValueDataIndex || Constants.Defaults.IgnoreValueDataIndex;
            break;
          default:
            this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id].ignoredIndex = Constants.Defaults.IgnoreValueDataIndex;
            break;
        }
        // endregion
        
        // region special conditions
        switch (busProcessor.bus.Receptor) {
          // avoid filters for headers
          case Receptor.HeaderInput:
            this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id].searchFilter = '';
            break;
          default:
            this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id].searchFilter =
              repMolecule.SearchFilter || Constants.Defaults.SearchFilter;
            break;
        }
        // endregion
      }
      
      const grouped = this.toolsService.CreateGroupedTranslationIdsContextsFromDataElements(
        this.DataElements);
      this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id].pageNumber = repMolecule.PageNumber || Constants.Defaults.InitPageNumber;
      
      this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id].translationIds = grouped.translationIds;
      this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id].contexts = grouped.contexts;
      this.dataSourcesService.dataSourceDataRequest[busProcessor.bus.id].offset = Constants.Defaults.Offset;
      
      this.dataSourcesService.GetDataSourceData(busProcessor.bus.id, null, repMolecule)
      .subscribe((dataResult) => {
        this.dataElementsRequested = true;
        if (busProcessor.bus.Receptor === Receptor.HeaderInput ||
          busProcessor.bus.Receptor === Receptor.None) {
          // avoid set metada for tables header input
        } else {
          repMolecule.SetValueMetaData(dataResult.metaData, dataResult.dataKey);
        }
        this.$Data.emit(dataResult.dataElements);
      });
      
    } else {
      this.$Data.emit([]);
    }
  }
  
  public abstract AfterRemove(repMolecule: IRepresentativeMolecule, data?: any);
  
  public abstract AfterAdded(repMolecule: IRepresentativeMolecule, data?: any);
  
  ngOnDestroy(): void {
    if (this.Subscriptions) {
      this.Subscriptions.unsubscribe();
    }
  }
  
  protected abstract MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule?: any,
    logic?: any,
    dataBus?: any,
    dataElementsData?: any,
  ): void;
  
  protected Done(particleId: string, busProcessorKey: string, repMoleculeId: any, data: any, endProccess = false) {
    const completedProcess: LeapProcessMetadata = {
      init: false,
      runParticleIndependent: false,
      firstParticle: null,
      particle: null,
      busId: this.actionMolcule.TriggeredByBusId,
      key: busProcessorKey,
      particleId: particleId,
      event: '',
      elementId: repMoleculeId,
      data: data,
      endProccess: endProccess,
      eventKey: this.EventKey,
    };
    
    console.log('done');
    if (this.RunIndependent) {
      // running outside of leapxl app
      this.communicationService.Event.Runtime.MolecularEngine.$ConnectorParticleProcessCompleted.emit(
        completedProcess);
    } else {
      this.MoleculeWorkDone(completedProcess);
    }
    
    return completedProcess;
  }
  
  protected InjectServices() {
    const injector = AppInjector.getInjector();
    this.busService = injector.get(BusService);
    this.spreadSheetService = injector.get(SpreadsheetService);
    this.dataSourcesService = injector.get(ApiDataSourcesService);
    this.toolsService = injector.get(ToolsService);
    this.runtimeService = injector.get(RuntimeService);
    this.fileService = injector.get(ApiFileService);
    this.authService = injector.get(ApiAuthService);
    this.snackerService = injector.get(SnackerService);
    this.clientStorageService = injector.get(ClientStorageService);
    this.adalService = injector.get(MsAdalAngular6Service);
    this.cobbleService = injector.get(CobbleService);
    this.dataTranslatorService = injector.get(ApiDataTranslatorService);
    this.moleculesService = injector.get(ApiMoleculesService);
    this.templateService = injector.get(TemplateService);
    this.communicationService = injector.get(CommunicationService);
    this.browserNotificationsService = injector.get(BrowserNotificationsService);
    this.workAreaService = injector.get(WorkAreaService);
    this.localStorageService = injector.get(LocalStorageService);
    this.captureService = injector.get(NgxCaptureService);
    this.imageCompress = injector.get(NgxImageCompressService);
    this.tfaDialogService = injector.get(TFADialogService);
    this.devToolsService = injector.get(DevToolsService);
  }
  
  protected DataActionModifierApply(data: any, modifier: any, args: any = null) {
    data = data === undefined || data === null || data === {} ? '' : data;
    
    try {
      if (data !== null && data !== undefined && data !== {} && data !== []) {
        const dataType = this.toolsService.GetObjectType(data);
        
        switch (dataType) {
          case 'number':
            data = modifier.call(this, data, args);
            break;
          case 'string':
            data = modifier.call(this, data, args);
            break;
          case 'object':
            data.value = modifier.call(this, data.value || Constants.Defaults.DataItemValue, args);
            data.formattedValue = data.value;
            break;
          case 'array':
            data.forEach((dataItem) => {
              dataItem.value = modifier.call(this, dataItem.value || Constants.Defaults.DataItemValue, args);
              dataItem.formattedValue = dataItem.value;
            });
            break;
        }
      }
      
      return data;
    } catch (e) {
      console.error(e);
      return data;
    }
  }
  
  protected CreateDataItem(dataElement: DataElement, repMoleculeId: string, data: any, newContexts: DataElement[] = []) {
    // console.log('createdataitem');
    
    if (newContexts.length > 0) {
      const newContext = this.newDataElementsContextAssoications[dataElement.Context];
      
      if (newContext) {
        dataElement.Context = newContext;
      } else {
        this.newDataElementsContextAssoications[dataElement.Context] = newContexts[0].Context;
        dataElement.Context = newContexts[0].Context;
      }
    }
    
    const repMolecule = this.busService.Get(repMoleculeId);
    const parentRepMolecule = this.busService.Get(repMolecule.ParentId.toString());
    
    console.log('dataitem');
    
    let viewName = 'View';
    
    try {
      viewName = this.cobbleService.Cobble.properties.views.find(
        (view) => view.id === this.runtimeService.ActualViewId).name;
    } catch (e) {
      viewName = 'View Id: ' + this.runtimeService.ActualViewId;
    }
    
    const dataItem = {
      operationType: repMolecule.Properties.orientation || Constants.Defaults.OrientationOperation,
      context: dataElement.Context,
      translationId: dataElement.TranslationId,
      value: [],
      form: parentRepMolecule ? parentRepMolecule.Properties.name : '',
      view: viewName,
      source: repMolecule.Properties.name,
      oldValue: Array.isArray(repMolecule.OldValue) ? repMolecule.OldValue.map((ov) => ov.value)
      .toString() : (repMolecule.OldValue || '').toString(),
      dataType: 'string',
    };
    
    const dataType = this.toolsService.GetObjectType(data);
    
    switch (dataType) {
      case 'string':
      case 'number':
        dataItem.value = [data];
        break;
      case 'array':
        dataItem.value = data;
        break;
      case 'object':
        dataItem.value = [(data.value ? data.value : '') || Constants.Defaults.DataItemValue];
        break;
      case 'filelist':
        dataItem.value = data;
        break;
      default:
        dataItem.value = [''];
        break;
    }
    
    dataItem.dataType = this.toolsService.GetValueDataTypeForSpreadsheet(dataItem.value, dataType);
    
    return cloneDeep(dataItem);
  }
  
  private MoleculeWorkDone(completedProcess: LeapProcessMetadata) {
    
    const actionMolecule = new ActionMolecule(this.actionMolcule);
    if (!this.toolsService.IsEditor() && this.devToolsService.HasRuntimeBreakpointOrStepOverForBus(
      completedProcess.particleId, this.actionMolcule.TriggeredByBusId, actionMolecule.MoleculeId.toString(),
      'end')) {
      const metadata = Object.assign(completedProcess);
      metadata.particle = actionMolecule;
      this.devToolsService.PauseProcess(metadata);
    } else {
      
      if (this.devToolsService.BusHasRuntimeBreakpoint(actionMolecule.TriggeredByBusId)) {
        this.devToolsService.AddHistory(actionMolecule.GlobalIdentifier(), completedProcess.data);
      }
      
      this.MoleculeProcessDone.emit(completedProcess);
    }
    
    if (this.cobbleService.Cobble.onEmulator) {
      this.runtimeService.EmulatorParticleProcessed({
        userId: this.clientStorageService.getUserId(),
        appId: this.cobbleService.Cobble.id,
        completedProcess,
      });
    }
  }
}
