import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiAuthService } from '../../core/services/api-auth.service';
import { CookieService } from '../../core/services/cookie-service';

@Component({
  selector: 'app-forgotPassword',
  templateUrl: './forgotPassword.component.html',
  styleUrls: ['./forgotPassword.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  attemptsLimit = 3;
  timeToWait = 1;
  email: string;
  emailSent = false;
  disableSend = false;
  
  constructor(private cookieService: CookieService, private authService: ApiAuthService, private router: Router) {
  }
  
  ngOnInit() {
  }
  
  sendEmail() {
    this.disableSend = true;
    if (this.canSendEmail()) {
      this.authService.recoverPassword(this.email).subscribe(result => {
        this.emailSent = true;
        this.increaseAttempt();
      });
    }
  }
  
  attempts() {
    if (this.cookieService.Check('recoverEmailAttempts')) {
      return +this.cookieService.Get('recoverEmailAttempts');
    } else {
      const date = new Date();
      date.setTime(date.getTime() + 60 * 1000 * 10);
      this.cookieService.Set('recoverEmailAttempts', '0', date);
      return 0;
    }
  }
  
  increaseAttempt() {
    const a = this.attempts() + 1;
    if (a === this.attemptsLimit) {
      const date = new Date();
      date.setTime(date.getTime() + 60 * 1000 * this.timeToWait);
      this.cookieService.Set('recoverEmailAttempts', a.toString(), date);
    } else {
      this.cookieService.Set('recoverEmailAttempts', a.toString());
    }
  }
  
  canSendEmail() {
    const attempts = this.attempts();
    if (attempts) {
      if (attempts >= this.attemptsLimit) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  
  goToLogin() {
    this.router.navigate(['/login']);
  }
}
