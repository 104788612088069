import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BusService } from '../../core/molecular/services/bus.service';
import { ApiAuthService } from '../../core/services/api-auth.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { SnackerService } from '../../shared/services/snacker.service';

@Component({
  selector: 'app-setPassword',
  templateUrl: './setPassword.component.html',
  styleUrls: ['./setPassword.component.scss'],
})
export class SetPasswordComponent implements OnInit {
  @ViewChild('passwordWithValidation', { static: false })
  password: any;
  @ViewChild('confirmPassword', { static: false })
  confirmPass: any;
  strength: number;
  passwordMatch: boolean;
  valid: boolean;
  token: string;
  reset: boolean;
  username: string;
  redirect: boolean;
  
  constructor(
    private route: ActivatedRoute,
    private authService: ApiAuthService,
    private router: Router,
    private clientStorageService: ClientStorageService,
    private busService: BusService,
    private snackerService: SnackerService,
  ) {
  }
  
  ngOnInit() {
    this.reset = false;
    this.redirect = false;
    this.strength = 0;
    this.passwordMatch = false;
    this.valid = false;
    
    this.route.queryParams.subscribe((params) => {
      console.log(params);
      
      this.token = params.token || params['amp;token'];
      this.reset = params.r === `${ this.token.charAt(5) }${ this.token.charAt(10) }${ this.token.charAt(15) }${ this.token.charAt(20) }`;
      
      if (!this.reset) {
        if (params.r !== `${ this.token.charAt(6) }${ this.token.charAt(9) }${ this.token.charAt(12) }${ this.token.charAt(15) }`) {
          this.router.navigate(['recoverpassword']);
        }
      }
      
      this.authService.getUserByToken(this.token).subscribe((result) => {
        if (result) {
          this.username = result.name;
        } else {
          this.redirect = true;
          setTimeout(() => {
            this.router.navigate(['recoverpassword']);
          }, 4000);
        }
      });
    });
  }
  
  onStrengthChanged(e: any) {
    this.strength = e;
  }
  
  onkeyupPassword() {
    this.validPasswords();
  }
  
  onCopy() {
    return false;
  }
  
  validPasswords() {
    if (this.confirmPass) {
      this.passwordMatch = this.password.nativeElement.value === this.confirmPass.nativeElement.value;
      // console.log(this.passwordMatch);
      
      this.valid = this.passwordMatch && this.strength === 100;
    } else {
      this.valid = false;
    }
  }
  
  onConfirmPasswordChange() {
    this.validPasswords();
  }
  
  submit() {
    this.authService.setUserPassword(this.password.nativeElement.value, this.token).subscribe((result) => {
      const response = <any>result;
      const user = response.user;
      const token = response.jwt;
      if (user) {
        if (this.reset) {
          this.snackerService.ShowMessageOnBottom(`Password reset successfully!`, 'check_circle');
          this.router.navigate(['login']);
        } else {
          this.snackerService.ShowMessageOnBottom(`Password set successfully!`, 'check_circle');
          
          this.clientStorageService.setSessionData(token, user.email, user);
          this.busService.Clear();
          this.clientStorageService.set2faLogged();
          this.authService.userRole = user.roleId;
          if (user.roleId === 1 || user.roleId === 2) {
            this.router.navigate(['/workarea']);
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
      } else {
        this.redirect = true;
        setTimeout(() => {
          this.router.navigate(['recoverpassword']);
        }, 4000);
      }
    });
  }
}
