export class GenericTreeItem {
  name: string;
  email: string;
  title: string;
  list: GenericTreeItem[];
  // isRoot: boolean;
  other: any;
  
  get canBeSubleveled() {
    return !this.other.hasUsers && this.isCompanyNode;
  }
  
  get isCompanyNode() {
    return this.other.type === 'companyNode';
  }
}
