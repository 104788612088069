import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { IActionMapping, KEYS, TreeComponent } from 'angular-tree-component';
import { ITreeNode } from 'angular-tree-component/dist/defs/api';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, flatMap, map } from 'rxjs/operators';
import { BusService } from '../../core/molecular/services/bus.service';
import { ApiMoleculesService } from '../../core/services/api-molecules.service';
import { ApiPropertiesService } from '../../core/services/api-properties.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { EditorStateService } from '../../core/services/editor-state.service';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { MoleculeManagmentService } from '../../core/services/molecule-managment.service';
import { ToolsService } from '../../core/services/tools.service';
import { Constants } from '../../shared/constants';
import { PropertyVersioningDto } from '../../shared/dtos/versioning-dto';
import { DragType } from '../../shared/enums/drag-type.enum';
import { Placeholders } from '../../shared/enums/placeholders.enum';
import { View } from '../../shared/interfaces/view';
import { UserRoleId } from '../../shared/models/user-role-id.enum';
import {
  IRepresentativeMolecule,
} from '../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { DragService } from '../../shared/representative-molecule/services/drag.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { ConnectionStateService } from '../../shared/services/connection-state.service';
import { DraggableWindowManagerService } from '../../shared/services/draggable-window-manager.service';
import { DraggableWindowService, DraggableWindowType } from '../../shared/services/draggable-window.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-views-panel',
  templateUrl: './views-panel.component.html',
  styleUrls: ['./views-panel.component.scss'],
})
export class ViewsPanelComponent implements OnInit, OnDestroy {
  @ViewChild('editViewNameInput', { static: false })
  editViewNameInput: ElementRef;
  @ViewChild('newViewNameInput', { static: false })
  newViewNameInput: ElementRef;
  @ViewChild('ViewsTree', { static: false })
  treeComponent: TreeComponent;
  public viewsSearch = new Subject<any>();
  subscription: Subscription;
  editingNode = null;
  timerViewClick = null;
  delayViewClick = 150;
  preventViewClick = false;
  hiddenRepMolecules: IRepresentativeMolecule[] = [];
  newView: View = null;
  treeExpanded = false;
  viewNameEdit = '';
  inDebounce = null;
  filterValue = '';
  viewSectionOpen = true;
  actionMapping: IActionMapping = {
    mouse: {
      click: (tree, node, $event) => {
        this.RefreshUI();
        // console.log('click', $event);
        // does not trigger click on icon actions
        if ($event.target.classList.contains('view-action-icon')) {
          return;
        }
        
        if (node.data.isView) {
          if (!node.data.editMode && (this.workAreaService.ActualView === undefined || node.data.id !== this.workAreaService.ActualView.id)) {
            this.timerViewClick = setTimeout(() => {
              if (!this.preventViewClick) {
                this.communicationService.Event.Editor.Views.$VisibilityChange.emit(node.data);
                this.RefreshUI();
              }
              this.preventViewClick = false;
            }, this.delayViewClick);
          }
          return;
        }
        
        const molecule = this.busService.Get(node.data.id);
        if (molecule.ParentId === this.cobbleService.Cobble.id) {
          this.SwitchToRepresentativeMoleculeView(molecule);
          this.workAreaService.ShowElementFocusedMenu(molecule);
          this.RefreshUI();
        } else {
          if (molecule.EditorVisible) {
            const parentRepMolecule = this.busService.Get(molecule.ParentId.toString());
            
            if (this.workAreaService.ActualView.id === parentRepMolecule.Properties.view) {
              molecule.ScrollIntoFocus();
            } else {
              this.workAreaService.focusRepresentativeMolecule = molecule;
              this.SwitchToRepresentativeMoleculeView(parentRepMolecule);
            }
            this.RefreshUI();
          } else {
            this.snackerService.ShowMessageOnBottom('Molecule hidden', 'visibility_off');
          }
        }
        this.RefreshUI();
      },
      dblClick: (tree, node, $event) => {
        if (node.data.isView) {
          this.RefreshUI();
          clearTimeout(this.timerViewClick);
          this.preventViewClick = true;
          this.viewNameEdit = node.data.name;
          node.data.editMode = true;
          this.editingNode = node.data;
          this.RefreshUI();
          setTimeout(() => {
            this.RefreshUI();
            this.editViewNameInput.nativeElement.focus();
            this.editViewNameInput.nativeElement.select();
            this.RefreshUI();
          }, 100);
        }
      },
      dragStart: (tree, node, $event) => {
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
        // console.log('node', node);
        this.dragService.dragginFromViewsPanel = true;
        
        if (node.data.isView) {
          this.dragService.dragData = {
            dragType: DragType.View,
            data: node.data,
          };
        } else {
          this.dragService.dragginRepMolecule = true;
          this.dragService.dragData = {
            dragType: DragType.RepresentativeMolecule,
            data: node.data,
          };
        }
      },
      dragEnd: (tree, node, $event) => {
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
        this.dragService.dragginFromViewsPanel = false;
        this.dragService.dragginRepMolecule = false;
      },
      mouseOver: (tree, node, $event) => {
        console.log('mouse over');
        
        if (node && !node.data.isView) {
          const highlightClass = 'highlight-rep-molecule';
          const el = document.querySelector(`#gridsterItem-${ node.data.id }`);
          if (el) {
            el.classList.add(highlightClass);
          }
        }
      },
      mouseOut: (tree, node, $event) => {
        const highlightClass = 'highlight-rep-molecule';
        document.querySelectorAll(`.${ highlightClass }`)
        .forEach(el => {
          el.classList.remove(highlightClass);
        });
      },
    },
    keys: {
      [KEYS.RIGHT]: null,
      [KEYS.LEFT]: null,
      [KEYS.DOWN]: null,
      [KEYS.UP]: null,
      [KEYS.SPACE]: null,
      [KEYS.ENTER]: null,
    },
  };
  searchLoading = false;
  options: any;
  busServiceElements = 0;
  nodes: TreeNode[] = [];
  placeholderNodes: TreeNode[] = [];
  isDebug = false;
  isSuperAdmin = false;
  actualEditorViewsIds = [];
  
  constructor(
    private busService: BusService,
    public workAreaService: WorkAreaService,
    public cobbleService: CobbleService,
    private snackerService: SnackerService,
    private toolsService: ToolsService,
    private dragService: DragService,
    private draggableWindowService: DraggableWindowService,
    private draggableWindowManagerService: DraggableWindowManagerService,
    private propertiesService: ApiPropertiesService,
    private connectionStateService: ConnectionStateService,
    private moleculeManagementService: MoleculeManagmentService,
    private moleculesService: ApiMoleculesService,
    private editorStateService: EditorStateService,
    private changeDetectorRef: ChangeDetectorRef,
    private communicationService: CommunicationService,
    private angularZone: NgZone,
    private localStorageService: LocalStorageService,
    private clientStorageService: ClientStorageService,
    private renderer: Renderer2,
  ) {
    this.isSuperAdmin = this.clientStorageService.isRole(UserRoleId.SuperAdmin);
    this.isDebug = this.localStorageService.IsDebug();
    
    changeDetectorRef.detach();
    this.options = {
      actionMapping: this.actionMapping,
      useVirtualScroll: false,
      nodeHeight: 22,
      allowDrag: node => node.data.draggable,
      allowDrop: (nodeDragged, { parent, index }) => !nodeDragged.data.isView && parent.data.isView,
    };
    
    this.viewsSearch
    .pipe(
      map(event => event.target.value),
      debounceTime(350),
      flatMap(search => of(search)
      .pipe(delay(50))),
    )
    .subscribe(value => {
      this.SearchTree(value);
    });
    
    this.GetEditorViewIds();
  }
  
  GetEditorViewIds() {
    this.actualEditorViewsIds =
      this.workAreaService.actualEditorViews.length > 0 ? this.workAreaService.actualEditorViews.filter(
        v => v)
      .map(v => v.id) : [];
  }
  
  SearchTree(value: string) {
    this.searchLoading = true;
    this.RefreshUI();
    this.filterValue = value;
    setTimeout(() => {
      const searchValue = value.toLowerCase();
      
      if (searchValue === '') {
        this.treeComponent.treeModel.doForAll(node => {
          node.show();
          node.children.forEach(c => c.show());
        });
        this.treeComponent.treeModel.collapseAll();
      } else {
        const foundResults: ITreeNode[] = [];
        this.treeComponent.treeModel.filterNodes((node: ITreeNode) => {
          const nodeName = node.data.name.toLowerCase();
          const nodeFound = nodeName.includes(searchValue);
          if (nodeFound && node.hasChildren) {
            console.log(node.children);
            for (const child of node.children) {
              if (child.hasChildren) {
                foundResults.push(child);
              }
            }
            foundResults.push(node);
          }
          return nodeFound;
        });
        
        if (foundResults.length > 0) {
          foundResults.forEach(item => {
            item.children.forEach(child => {
              child.show();
              // child.ensureVisible();
            });
          });
        } else {
          this.treeComponent.treeModel.collapseAll();
        }
      }
      // this.treeComponent.treeModel.filterNodes(value, false);
      setTimeout(() => {
        this.searchLoading = false;
        this.RefreshUI();
      }, 1000);
    }, 100);
  }
  
  UpdateHiddenRepMolecules() {
    this.hiddenRepMolecules.forEach(molecule => {
      const node = this.treeComponent.treeModel.getNodeById(molecule.Id);
      if (node) {
        node.data.visible = molecule.EditorVisible;
      }
    });
    
    this.hiddenRepMolecules = this.busService.GetEditorHiddenRepresentativeMolecules();
    
    this.hiddenRepMolecules.forEach(molecule => {
      const node = this.treeComponent.treeModel.getNodeById(molecule.Id);
      if (node) {
        node.data.visible = molecule.EditorVisible;
      }
    });
    this.editorStateService.SaveHiddenRepresentativeMolecules();
    this.RefreshUI();
    this.setSectionHeight();
  }
  
  DisplayAllHiddenRepMolecules() {
    this.busService.GetEditorHiddenRepresentativeMolecules()
    .forEach(m => m.ToggleVisibilityFromEditorLayer(true));
    this.setSectionHeight();
  }
  
  ngOnInit() {
    this.subscription = this.communicationService.Event.System.Update.$ChangesOnMolecules
    .pipe(debounceTime(250))
    .subscribe((molecule: IRepresentativeMolecule) => {
      console.log('=event=');
      console.log('$ChangesOnMolecules');
      this.refreshViewElements();
      this.RefreshUI();
    });
    
    this.subscription.add(
      this.communicationService.Event.Editor.Views.$RefreshViewsPanelUI.pipe(debounceTime(20))
      .subscribe(condition => {
        console.log('=event=');
        console.log('refresh views panel ui');
        this.RemoveViewsHighlight();
        this.HighlightView();
        this.workAreaService.elementsSelected.forEach(es => es.HighlightViewsPath());
        this.RefreshUI();
        this.setSectionHeight();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.Views.$RefreshViewsPanel.subscribe(condition => {
        console.log('=event=');
        console.log('refresh views panel');
        this.refreshViewElements();
        this.RefreshUI();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.Views.$SwapRepMoleculeView.subscribe(data => {
        console.log('=event=');
        this.onMoveNode(null, data.repMoleculeId, data.viewId);
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.Views.$SwitchToRepresentativeMoleculeView.subscribe(
        repMolecule => {
          console.log('=event='); // console.log('refresh views panel');
          this.SwitchToRepresentativeMoleculeView(repMolecule);
        }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.Views.$Highlight.pipe(debounceTime(0))
      .subscribe((path: number[]) => {
        console.log('=event=');
        this.angularZone.runOutsideAngular(() => {
          this.HighlightPath(path);
        });
        this.RefreshUI();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$RepresentativeMoleculeVisibleChange.pipe(debounceTime(200))
      .subscribe(({ repMoleculeId, status }) => {
        console.log('=event=');
        console.log('update');
        this.UpdateHiddenRepMolecules();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.Views.$RefreshUI.subscribe(() => {
        console.log('=event=');
        this.RefreshUI();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.Views.$Collapse.subscribe(condition => {
        console.log('=event=');
        this.CollapseAll();
        this.RefreshUI();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.Preferences.$PreferenceChange.subscribe(preference => {
        console.log('=event=');
        if (preference === 'compactToolBarPosition') {
          this.setSectionHeight();
        }
      }),
    );
    
    this.refreshViewElements();
    this.RefreshUI();
  }
  
  RefreshUI() {
    this.angularZone.run(() => {
      this.changeDetectorRef.detectChanges();
      // setTimeout(() => {
      //   this.changeDetectorRef.detectChanges();
      // }, 200);
    });
  }
  
  RefreshUIDelayed() {
    this.angularZone.run(() => {
      this.changeDetectorRef.detectChanges();
      setTimeout(() => {
        this.changeDetectorRef.detectChanges();
      }, 100);
    });
  }
  
  SwitchToRepresentativeMoleculeView(repMolecule: IRepresentativeMolecule) {
    console.log('SwitchToRepresentativeMoleculeView');
    this.RefreshUI();
    
    if (this.workAreaService.ActualView.id !== repMolecule.Properties.view) {
      this.workAreaService.SwitchToView(repMolecule.Properties.view);
    }
  }
  
  HighlightView() {
    this.RefreshUI();
    
    if (this.workAreaService.ActualView) {
      const el = document.querySelector(`.view-path-${ this.workAreaService.ActualView.id }`);
      if (el) {
        el.classList.add('selected-element-node');
      }
      this.RefreshUI();
    }
  }
  
  HighlightPath(path: number[]) {
    this.RefreshUI();
    
    if (path && path.length > 0) {
      // remove cobble parent
      path.shift();
      
      if (path && path.length > 0) {
        const firstChild = this.busService.Get(path[0].toString());
        
        if (firstChild && this.treeComponent) {
          const view = firstChild.Properties.view;
          
          this.treeComponent.treeModel.roots.forEach(rootNode => {
            if (rootNode.data.id === view) {
              rootNode.expand();
              this.RefreshUI();
            } else {
              // rootNode.collapse();
              this.RefreshUI();
            }
          });
          
          let node: ITreeNode = this.treeComponent.treeModel.getNodeBy(n => n.data.id === view);
          
          let el = document.querySelector(`.view-path-${ view }`);
          if (el) {
            el.classList.add('selected-element-node');
          }
          this.RefreshUI();
          
          if (node) {
            path.forEach(p => {
              node.children.forEach(treeNode => {
                if (treeNode.data.id === p) {
                  treeNode.expand();
                } else {
                  // treeNode.collapse();
                }
                this.RefreshUI();
              });
              
              node = this.treeComponent.treeModel.getNodeBy(n => n.data.id === p);
              
              if (node) {
                el = document.querySelector(`.view-path-${ p }`);
                if (el) {
                  el.classList.add('selected-element-node');
                  el.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
              }
              this.RefreshUI();
            });
          }
        }
      }
    }
  }
  
  RemoveViewsHighlight(path?: number[]) {
    this.RefreshUI();
    // console.log('remove events highlight');
    let highlitedRepMols = [];
    
    if (path) {
      path.forEach(p => {
        const identifier = `.view-path-${ p }`;
        const el = document.querySelector(identifier);
        if (el) {
          highlitedRepMols.push(el);
        }
      });
    } else {
      highlitedRepMols = document.querySelectorAll(`#viewsTree .selected-element-node`) as unknown as any[];
    }
    
    highlitedRepMols.forEach(hl => hl.classList.remove('selected-element-node'));
    this.RefreshUI();
  }
  
  OrderViews() {
    this.cobbleService.Cobble.properties.views.sort(this.toolsService.CompareValues('name'));
  }
  
  refreshViewElements() {
    console.log('refreshViewElements');
    this.RefreshUI();
    this.nodes = [];
    this.placeholderNodes = [];
    
    this.OrderViews();
    
    for (let viewNumber = 0; viewNumber < this.cobbleService.Cobble.properties.views.length; viewNumber++) {
      const view = {
        id: this.cobbleService.Cobble.properties.views[viewNumber].id,
        name: `${ this.cobbleService.Cobble.properties.views[viewNumber].name }`,
        children: [],
        icon: 'layers',
        isView: true,
        value: null,
        isPlaceholder: false,
        locked: this.cobbleService.Cobble.properties.views[viewNumber].locked,
        draggable: true,
        representativeType: 'View',
        visible: true,
        editMode: false,
        squish: this.cobbleService.Cobble.properties.views[viewNumber].squish,
      };
      
      this.busService.DirectChildrenElements(this.cobbleService.Cobble.id)
      .forEach(molecule => {
        if (molecule.Properties.view === this.cobbleService.Cobble.properties.views[viewNumber].id) {
          const wgView = {
            id: molecule.Id,
            name: `${ molecule.Properties.name }`,
            isView: false,
            value: null,
            isPlaceholder: false,
            representativeType: molecule.Type,
            visible: molecule.EditorVisible,
            icon: molecule.Icon,
            children: [],
            draggable: true,
            editMode: false,
          };
          
          this.busService.GetChildrenElementIds(molecule.Id)
          .forEach(wgChildId => {
            const wgChild = this.busService.Get(wgChildId.id);
            const childView = {
              id: wgChild.Id,
              name: `${ wgChild.Properties.name }`,
              children: [],
              isView: false,
              value: null,
              isPlaceholder: false,
              representativeType: wgChild.Type,
              visible: wgChild.EditorVisible,
              icon: wgChild.Icon,
              draggable: true,
              editMode: false,
            };
            
            this.busService.GetChildrenElementIds(wgChildId.id)
            .forEach(children => {
              const molChild = this.busService.Get(children.id);
              const child = {
                id: molChild.Id,
                name: `${ molChild.Properties.name }`,
                children: [],
                isView: false,
                value: null,
                isPlaceholder: false,
                representativeType: molChild.Type,
                visible: molChild.EditorVisible,
                icon: molChild.Icon,
                draggable: true,
                editMode: false,
              };
              childView.children.push(child);
            });
            
            wgView.children.push(childView);
          });
          
          view.children.push(wgView);
        }
      });
      this.nodes.push(view);
    }
    
    const placeholders = [
      {
        id: 99999,
        name: 'Placeholder - Navigation',
        value: Placeholders.Navigation,
      },
      {
        id: 999991,
        name: 'Placeholder - Title',
        value: Placeholders.Title,
      },
      {
        id: 999992,
        name: 'Placeholder - Separator',
        value: Placeholders.Separator,
      },
      {
        id: 999993,
        name: 'Placeholder - Heading',
        value: Placeholders.Heading,
      },
      {
        id: 999994,
        name: 'Placeholder - Maintain',
        value: Placeholders.Maintain,
      },
      {
        id: 999995,
        name: 'Placeholder - Actionable',
        value: Placeholders.Actionable,
      },
    
    ];
    
    placeholders.forEach(p => {
      this.placeholderNodes.push({
        id: p.id,
        locked: false,
        name: p.name,
        isView: true,
        isPlaceholder: true,
        icon: 'grid_guides',
        value: p.value,
        draggable: true,
        children: [],
        editMode: false,
        squish: false,
      });
    });
    
    this.busServiceElements = Object.keys(this.busService.Channel).length - 1;
    this.workAreaService.elementsSelected.forEach(es => es.HighlightViewsPath());
    this.RefreshUI();
    this.RefreshUIDelayed();
    this.SelectViewRemaining();
    this.GetEditorViewIds();
    setTimeout(() => this.setSectionHeight(), 800);
  }
  
  SaveViewAsComponent(node: any) {
    const name = `[View] - ${ node.data.name }`;
    
    this.propertiesService.VerifyCobbleName(name)
    .subscribe((availability: boolean) => {
      if (availability) {
        this.moleculesService
        .CreateComponent(
          name,
          node.data.children.map(c => {
            return {
              childrenIds: [],
              parentId: c.id,
            };
          }),
          false,
        )
        .subscribe(response2 => {
          this.snackerService.ShowMessageOnBottom(`${ name } added to your Components library.`, 'add_circle',
            null,
            true);
          this.communicationService.Event.Editor.AppsTree.$RefreshAppsTree.emit(null);
        });
      } else {
        this.snackerService.ShowMessageOnBottom('There is already a View Component with this name.',
          'repeat_one_on');
      }
    });
  }
  
  ToggleViewSquish(e: MouseEvent, node: any) {
    this.RefreshUI();
    const view = this.cobbleService.Cobble.properties.views.find(v => v.id === node.data.id);
    // console.log('view', view);
    
    view.squish = !view.squish;
    this.propertiesService
    .SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: 'views',
        value: this.cobbleService.Cobble.properties.views,
        path: 'properties',
        cobbleId: this.cobbleService.Cobble.id.toString(),
        name: view.name,
      }),
    )
    .subscribe();
    this.refreshViewElements();
    this.RefreshUI();
  }
  
  AddView(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.RefreshUI();
    setTimeout(() => {
      const viewId = Math.max(...this.cobbleService.Cobble.properties.views.map(v => v.id)) + 1;
      this.newView = {
        id: viewId,
        name: `View ${ viewId }`,
        locked: false,
        squish: true,
      };
      
      this.RefreshUI();
      setTimeout(() => {
        this.newViewNameInput.nativeElement.focus();
        this.newViewNameInput.nativeElement.select();
        this.RefreshUI();
      }, 100);
    }, 50);
  }
  
  SaveView() {
    this.RefreshUI();
    this.cobbleService.Cobble.properties.views.push(this.newView);
    this.snackerService.ShowMessageOnBottom(`View ${ this.newView.name } added`, 'add_circle', null, true);
    
    this.propertiesService
    .SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: 'views',
        value: this.cobbleService.Cobble.properties.views,
        path: 'properties',
        change: 'View Added',
        name: 'View',
      }),
    )
    .subscribe();
    
    this.StopViewCreation();
    this.refreshViewElements();
    this.RefreshUI();
    this.GetEditorViewIds();
  }
  
  StopViewCreation() {
    // console.log('stop view creation');
    this.newView = null;
    this.RefreshUI();
    this.GetEditorViewIds();
  }
  
  ToggleViewVisibility(e: MouseEvent, node: TreeNode) {
    e.stopPropagation();
    e.preventDefault();
    this.communicationService.Event.Editor.Views.$VisibilityChange.emit(node);
  }
  
  onMoveNode(event, repMolId = null, viewId = null) {
    const repMoleculeId = event ? event.node.id : repMolId;
    const newViewId = event ? event.to.parent.id : viewId;
    const newView = this.cobbleService.Cobble.properties.views.find(v => v.id === newViewId);
    
    this.RefreshUI();
    // console.log('Moved', event.node, 'to', event.to.parent);
    this.busService.Get(repMoleculeId)
    .UpdateProperty('view', newViewId, null, this.busService);
    this.busService.Get(repMoleculeId)
    .UpdateProperty('dragEnabled', !newView.locked, null, this.busService);
    this.busService.Get(repMoleculeId)
    .UpdateProperty('resizeEnabled', !newView.locked, null, this.busService);
    
    const changes = [
      new PropertyVersioningDto({
        elementId: repMoleculeId,
        property: 'view',
        value: newViewId,
        path: 'properties',
        change: 'View Change',
        name: 'View ' + newViewId,
      }),
      new PropertyVersioningDto({
        elementId: repMoleculeId,
        property: 'dragEnabled',
        value: !newView.locked,
        path: 'properties',
        change: 'Drag enabled',
        name: '',
      }),
      new PropertyVersioningDto({
        elementId: repMoleculeId,
        property: 'resizeEnabled',
        value: !newView.locked,
        path: 'properties',
        change: 'Resize enabled',
        name: '',
      }),
    ];
    
    const movedElementChildren = this.busService.ChildrenElements(repMoleculeId);
    movedElementChildren.forEach(c => {
      c.Properties.view = newViewId;
      changes.push(
        new PropertyVersioningDto({
          elementId: c.Id.toString(),
          property: 'view',
          value: newViewId,
          path: 'properties',
          change: 'View Change',
          name: 'View ' + newViewId,
        }),
      );
    });
    
    this.propertiesService.SaveProperties(changes)
    .subscribe();
    this.RefreshUI();
    this.GetEditorViewIds();
  }
  
  RemoveView(node: any) {
    this.RefreshUI();
    if (this.cobbleService.Cobble.properties.views.length === 1) {
      this.snackerService.ShowMessageOnBottom('Last view can\'t be removed', 'do_not_disturb_off');
      
      return;
    }
    
    if (node.hasChildren) {
      this.snackerService.ShowMessageOnBottom(
        `Can\'t remove view "${ node.data.name }" because it has elements`,
        'do_not_disturb_off');
    } else {
      this.cobbleService.Cobble.properties.views = this.cobbleService.Cobble.properties.views.filter(
        view => view.id !== node.data.id);
      if (this.workAreaService.actualEditorViews.map(v => v.id)
      .includes(node.data.id)) {
        this.workAreaService.actualEditorViews = this.workAreaService.actualEditorViews.filter(
          view => view.id !== node.data.id);
      }
      
      this.refreshViewElements();
      this.RefreshUI();
      this.propertiesService
      .SaveProperty(
        new PropertyVersioningDto({
          elementId: this.cobbleService.Cobble.id.toString(),
          property: 'views',
          value: this.cobbleService.Cobble.properties.views,
          path: 'properties',
          change: 'View Added',
          name: 'View',
        }),
      )
      .subscribe();
      
      this.SelectViewRemaining();
    }
    this.GetEditorViewIds();
  }
  
  SelectViewRemaining() {
    console.log('SelectViewRemaining');
    if (this.cobbleService.Cobble.properties.views.length === 1) {
      const remainingView = this.cobbleService.Cobble.properties.views[0];
      this.workAreaService.SwitchToView(remainingView.id);
    }
  }
  
  ShowMoleculeProperties(event: any, node: any) {
    this.RefreshUI();
    console.log(node.data);
    
    if (node.data.isView) {
      this.workAreaService.viewFocused = node.data.id;
    } else {
      this.workAreaService.SelectRepresentativeMolecule(this.busService.Get(node.data.id), false);
    }
    
    this.draggableWindowService.OpenDraggableWindow(
      `${ node.data.name } Properties`,
      DraggableWindowType.RepresentativeProperties,
      event,
    );
  }
  
  ToggleRepMoleculeFromLayer(event: any, node: any) {
    this.RefreshUI();
    node.data.visible = !node.data.visible;
    if (node.data.isView) {
    } else {
      this.busService.Get(node.data.id)
      .ToggleVisibilityFromEditorLayer(node.data.visible);
    }
    this.RefreshUI();
    
    event.preventDefault();
    event.stopPropagation();
  }
  
  RemoveMolecule(event: any, node: any) {
    this.RefreshUI();
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    const repMolecule = this.busService.Get(node.data.id);
    
    if (repMolecule.IsOnlyWorkgroupInWorkArea() && !this.cobbleService.Cobble.isApplication) {
      return;
    }
    
    event.preventDefault();
    event.stopPropagation();
    
    this.moleculeManagementService.RemoveRepresentativeMolecule(node.data.id);
    this.workAreaService.HideElementFocusedMenu();
    this.RefreshUI();
    this.GetEditorViewIds();
  }
  
  LockView(node: any) {
    this.RefreshUI();
    const lockedView = this.cobbleService.Cobble.properties.views.find(v => v.locked);
    if (lockedView) {
      this.busService
      .DirectChildrenElements(this.cobbleService.Cobble.id)
      .filter(c => c.Properties.view === lockedView.id)
      .forEach((wg: IRepresentativeMolecule) => {
        wg.Properties.dragEnabled = true;
        wg.Properties.resizeEnabled = true;
        this.propertiesService
        .SaveProperty(
          new PropertyVersioningDto({
            elementId: wg.Id.toString(),
            property: 'dragEnabled',
            value: wg.Properties.dragEnabled,
            path: 'properties',
            change: 'Element Unlocked',
            name: wg.Properties.name,
          }),
        )
        .subscribe();
        this.propertiesService
        .SaveProperty(
          new PropertyVersioningDto({
            elementId: wg.Id.toString(),
            property: 'resizeEnabled',
            value: wg.Properties.resizeEnabled,
            path: 'properties',
            change: 'Element Unlocked',
            name: wg.Properties.name,
          }),
        )
        .subscribe();
        // this.propertiesService.SaveProperties([
        //   new PropertyVersioningDto({
        //     elementId: wg.Id.toString(),
        //     property: 'dragEnabled',
        //     value: wg.Properties.dragEnabled,
        //     path: 'properties',
        //     change: 'Element Unlocked',
        //     name: wg.Properties.name
        //   }),
        //   new PropertyVersioningDto({
        //     elementId: wg.Id.toString(),
        //     property: 'resizeEnabled',
        //     value: wg.Properties.resizeEnabled,
        //     path: 'properties',
        //     change: 'Element Unlocked',
        //     name: wg.Properties.name
        //   })
        // ]).subscribe();
      });
      lockedView.locked = false;
      node.data.locked = lockedView.locked;
    }
    this.RefreshUI();
    
    if (!lockedView || lockedView.id !== node.data.id) {
      const view = this.cobbleService.Cobble.properties.views.find(v => v.id === node.data.id);
      view.locked = !view.locked;
      node.data.locked = view.locked;
      
      this.busService
      .DirectChildrenElements(this.cobbleService.Cobble.id)
      .filter(c => c.Properties.view === view.id)
      .forEach((wg: IRepresentativeMolecule) => {
        wg.Properties.dragEnabled = !view.locked;
        wg.Properties.resizeEnabled = !view.locked;
        this.propertiesService
        .SaveProperty(
          new PropertyVersioningDto({
            elementId: wg.Id.toString(),
            property: 'dragEnabled',
            value: wg.Properties.dragEnabled,
            path: 'properties',
            change: 'Element Locked',
            name: wg.Properties.name,
          }),
        )
        .subscribe();
        this.propertiesService
        .SaveProperty(
          new PropertyVersioningDto({
            elementId: wg.Id.toString(),
            property: 'resizeEnabled',
            value: wg.Properties.resizeEnabled,
            path: 'properties',
            change: 'Element Locked',
            name: wg.Properties.name,
          }),
        )
        .subscribe();
        
        // this.propertiesService.SaveProperties([
        //   new PropertyVersioningDto({
        //     elementId: wg.Id.toString(),
        //     property: 'dragEnabled',
        //     value: wg.Properties.dragEnabled,
        //     path: 'properties',
        //     change: 'Element Locked',
        //     name: wg.Properties.name
        //   }),
        //   new PropertyVersioningDto({
        //     elementId: wg.Id.toString(),
        //     property: 'resizeEnabled',
        //     value: wg.Properties.resizeEnabled,
        //     path: 'properties',
        //     change: 'Element Locked',
        //     name: wg.Properties.name
        //   })
        // ]).subscribe();
      });
    }
    
    this.RefreshUI();
    this.communicationService.Event.System.App.$AppChanged.emit(true);
    this.refreshViewElements();
    this.propertiesService
    .SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: 'views',
        value: this.cobbleService.Cobble.properties.views,
        path: 'properties',
        change: 'View Added',
        name: 'View',
      }),
    )
    .subscribe();
  }
  
  onClickedOutsideEdit(e: Event) {
    this.RefreshUI();
    this.CancelEditingViewName();
    this.RefreshUI();
  }
  
  CancelEditingViewName() {
    this.RefreshUI();
    this.viewNameEdit = '';
    if (this.editingNode) {
      this.editingNode.editMode = false;
      this.editingNode = null;
    }
    this.RefreshUI();
  }
  
  SaveViewName() {
    this.RefreshUI();
    const existingNameView = this.cobbleService.Cobble.properties.views.find(
      v => v.name === this.viewNameEdit);
    
    if (existingNameView) {
      this.snackerService.ShowMessageOnBottom('There exists a View with the same name', 'repeat_one_on');
    } else {
      this.editingNode.name = this.viewNameEdit;
      this.editingNode.editMode = false;
      
      const viewEdit = this.cobbleService.Cobble.properties.views.find(v => v.id === this.editingNode.id);
      
      viewEdit.name = this.viewNameEdit;
      
      this.editingNode = null;
      this.viewNameEdit = '';
      this.refreshViewElements();
      
      this.propertiesService
      .SaveProperty(
        new PropertyVersioningDto({
          elementId: this.cobbleService.Cobble.id.toString(),
          property: 'views',
          value: this.cobbleService.Cobble.properties.views,
          path: 'properties',
          cobbleId: this.cobbleService.Cobble.id.toString(),
          name: viewEdit.name,
        }),
      )
      .subscribe();
    }
    this.RefreshUI();
  }
  
  CollapseAll(event?: MouseEvent) {
    event?.preventDefault();
    event?.stopPropagation();
    this.RefreshUI();
    if (this.treeComponent) {
      // console.log('collapsing views');
      this.treeComponent.treeModel.collapseAll();
      this.RefreshUI();
    }
  }
  
  onEvent(e: any) {
    this.RefreshUI();
    this.treeExpanded = e.isExpanded;
    this.RefreshUI();
  }
  
  Throttle(func, delay, context, args) {
    clearTimeout(this.inDebounce);
    this.inDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  
  toggleViewSection() {
    this.viewSectionOpen = !this.viewSectionOpen;
    this.RefreshUI();
  }
  
  setSectionHeight() {
    const windowHeight = window.innerHeight;
    const element = document.querySelector('#views .angular-tree-component');
    if (!element) {
      return;
    }
    const headersHeight = Constants.SidePanelSectionHeaderHeight;
    const separatorsHeight = Constants.SeparatorSectionHeaderHeight;
    const toolbarHeight = this.workAreaService.editorPreferences.compactToolBarPosition === 'top' ? Constants.ToolbarHeight : 0;
    const hiddenRepMoleculesMessageHeight = 18;
    this.renderer.setStyle(
      element,
      'max-height',
      `${
        windowHeight -
        (98 + headersHeight + separatorsHeight + toolbarHeight + (this.hiddenRepMolecules.length > 0 ? hiddenRepMoleculesMessageHeight : 0))
      }px`,
    );
    this.RefreshUI();
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setSectionHeight();
  }
}

export interface TreeNode {
  id: number;
  locked: boolean;
  name: string;
  isView: boolean;
  value: string;
  isPlaceholder: boolean;
  icon: string;
  draggable: boolean;
  children: TreeNode[];
  editMode: boolean;
  squish: boolean;
}
