import { Pipe, PipeTransform } from '@angular/core';
import IMask from 'imask';
import { ToolsService } from '../../core/services/tools.service';

@Pipe({
  name: 'leapMask',
})
export class MaskPipe implements PipeTransform {
  constructor(private toolService: ToolsService) {
  }
  
  transform(value: string, mask: string): string {
    console.log('maskpipe');
    
    if (value && value.length > 0 && mask && mask.length > 0) {
      if (this.IsCurrency(mask) || this.IsAmount(mask)) {
        let currency = '';
        
        if (this.toolService.IsNumeric(value)) {
          const amount = Number(value.replace(/[$,]/g, ''));
          const cloneMask = (mask as any).replaceAll(' ', '');
          const decimal = cloneMask.split('.')[1];
          const decimals = value.split('.')[1];
          const integer = value.split('.')[0];
          
          let formattedAmount = this.MaskAmount(integer);
          
          if (+decimal > 0 && decimals && decimals.length > 0) {
            formattedAmount += '.';
            
            for (let index = 0; index < +decimal; index++) {
              if (decimals[index]) {
                formattedAmount += decimals[index];
              }
            }
          }
          
          if (this.IsCurrency(mask)) {
            currency = '$';
            
            if (value.includes('-')) {
              currency = `-${ currency }`;
              formattedAmount = formattedAmount.replace('-', '');
            }
          }
          
          currency += formattedAmount;
        } else {
          currency += value;
        }
        
        return currency;
      } else {
        var masker = IMask.createMask({
          mask: mask,
        });
        
        return masker.resolve(value);
      }
    } else {
      return value;
    }
  }
  
  IsCurrency(mask: string) {
    return mask.charAt(0) === '$';
  }
  
  IsAmount(mask: string) {
    return mask.charAt(0) === '#';
  }
  
  MaskAmount(amount: string) {
    var mask = IMask.createMask({
      mask: Number,
      thousandsSeparator: ',',
      signed: true,
    });
    
    return mask.resolve(amount);
  }
}
