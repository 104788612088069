import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AwesomeQR } from 'awesome-qr';
import copyImg from 'copy-image-clipboard';
import * as DeviceDetector from 'device-detector-js';
import * as Entities from 'entities';
import * as htmlToImage from 'html-to-image';
import { cloneDeep, groupBy, isEqual, uniq } from 'lodash-es';
import { from, Observable } from 'rxjs';
import * as terser from 'terser';
import * as XLSX from 'xlsx';
import * as LZString from '../../../assets/lz-string.js';
import * as textFit from '../../../assets/textFit.min';
import { Constants } from '../../shared/constants';
import { Browser } from '../../shared/enums/browser.enum';
import { DatasourceTypeId } from '../../shared/enums/datasource-type-id.enum';
import { DatasourceType } from '../../shared/enums/datasource-type.enum';
import { ParsedCSS } from '../../shared/interfaces/parsed-css.interface';
import { DataElement } from '../../shared/representative-molecule/interfaces/data-element';

declare const GradientParser: any;
declare const cssjs: any;

@Injectable()
export class ToolsService {
  public mousePosition: {
    x: number;
    y: number;
  };
  public DragWindowConfig: {
    changeLayout: boolean;
    icon?: string;
    x: number;
    y: number;
    width?: number;
    height?: number;
    autoHeight?: boolean;
    center?: boolean;
    resizable?: boolean;
    microlearning?: {
      type: string;
      id: string;
      title: string;
    };
    shortcut?: string;
  };
  public RunningMode: boolean;
  responsiveWidth = 1000;
  inDebounce = null;
  currentTheme = 'light';
  circularDependencies = [
    'injector',
    'toolsService',
    'busService',
    'cobbleService',
    'devToolsService',
    'workAreaService',
    'particlesFactoryService',
    'particleFactoryService',
    'factoryParticleService',
    'communicationService',
    'propertiesService',
    'clientStorageService',
    'messagesService',
    'spreadSheetService',
    'dataSourcesService',
    'fileService',
    'authService',
    'snackBarService',
    'snackerService',
    'clientStorageService',
    'adalService',
    'dataTranslatorService',
    'moleculesService',
    'templateService',
    'moleculeMangementService',
    'bottomSheet',
    'draggableWindowService',
    'connectionStateService',
    'dataQualityDialogService',
    'bottomSheetService',
    'processorService',
    'dragService',
    'builderService',
    'snackBar',
    'snackerService',
    'molecularEngineConnectorService',
    '$Resized',
    '$UpdateTextValue',
    '$UpdateValue',
    'editorStateService',
    '_',
    'subscriptions',
    'localStorageService',
    'superLocalService',
    'thematicService',
    'apiThematicService',
    'angularZone',
    'runtimeService',
    'xhrFactory',
    'datasourcesService',
  ];
  Environments = {
    Local: 'localhost',
    Dev: 'dev-cluster.leapxl.com',
    Test: 'test-cluster.leapxl.com',
    Prod: 'app.leapxl.com',
  };
  
  constructor(private route: ActivatedRoute) {
    this.RunningMode = false;
  }
  
  get IsLocalhost() {
    return document.location.href.includes('localhost');
  }
  
  get IsMobile() {
    const widthMobile = window.innerWidth <= this.responsiveWidth;
    
    let check = false;
    (function(a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4),
        )
      ) {
        check = true;
      }
    })(navigator.userAgent || navigator.vendor || (window as any).opera);
    return check || widthMobile;
  }
  
  GetDevice() {
    const deviceDetector = new DeviceDetector();
    const device = deviceDetector.parse(window.navigator.userAgent);
    return device;
  }
  
  EvaluateDateByRegex(value: string): boolean {
    // mm/dd/yyyy or mm-dd-yyyy or mm.dd.yyyy
    const dateRegValidator1 = new RegExp(
      [
        '^(?:(?:(?:0?[13578]|1[02])(\\/|-|\\.)31)',
        '\\1|(?:(?:0?[1,3-9]|1[0-2])(\\/|-|\\.)(?:29|30)',
        '\\2))(?:(?:1[6-9]|[2-9]\\d)?d{2})$|^(?:0?2(\\/|-|\\.)',
        '29\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|',
        '[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))',
        '$|^(?:(?:0?[1-9])|(?:1[0-2]))(\\/|-|\\.)',
        '(?:0?[1-9]|1\\d|2[0-8])\\4',
        '(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$',
      ].join(''),
      'g',
    );
    // M/d/y hh:mm:ss
    const dateRegValidator2 = new RegExp(
      [
        '^(?=\\d)(?:(?:(?:(?:(?:0?[13578]|1[02])(\\/|-|\\.)31)\\1|(?:(?:0?[1,3-9]|1[0-2])(\\/|-|\\.)(?:29|30)\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})|(?:0?2(\\/|-|\\.)29\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))|(?:(?:0?[1-9])|(?:1[0-2]))(\\/|-|\\.)(?:0?[1-9]|1\\d|2[0-8])\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2}))($|\\ (?=\\d)))?(((0?[1-9]|1[012])(:[0-5]\\d){0,2}(\\ [AP]M))|([01]\\d|2[0-3])(:[0-5]\\d){1,2})?$',
      ].join(''),
      'g',
    );
    // dd MMM yyyy
    const dateRegValidator3 = new RegExp(
      [
        '^((31(?!\\ (Feb(ruary)?|Apr(il)?|June?|(Sep(?=\\b|t)t?|Nov)(ember)?)))|((30|29)(?!\\ Feb(ruary)?))|(29(?=\\ Feb(ruary)?\\ (((1[6-9]|[2-9]\\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)))))|(0?[1-9])|1\\d|2[0-8])\\ (Jan(uary)?|Feb(ruary)?|Ma(r(ch)?|y)|Apr(il)?|Ju((ly?)|(ne?))|Aug(ust)?|Oct(ober)?|(Sep(?=\\b|t)t?|Nov|Dec)(ember)?)\\ ((1[6-9]|[2-9]\\d)\\d{2})$',
      ].join(''),
      'g',
    );
    // dd/mm/yyyy hh:MM:ss  hh:MM:ss AM/PM 12 hour format
    const dateRegValidator4 = new RegExp(
      [
        '^(?=\\d)(?:(?:31(?!.(?:0?[2469]|11))|(?:30|29)(?!.0?2)|29(?=.0?2.(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(?:\x20|$))|(?:2[0-8]|1d|0?[1-9]))([-./])(?:1[012]|0?[1-9])\\1(?:1[6-9]|[2-9]\\d)?\\d\\d(?:(?=\x20\\d)\x20|$))?(((0?[1-9]|1[012])(:[0-5]\\d){0,2}(\x20[AP]M))|([01]\\d|2[0-3])(:[0-5]\\d){1,2})?$',
      ].join(''),
      'g',
    );
    // yyyy/mm/dd hh:MM:ss
    const dateRegValidator5 = new RegExp(
      [
        '^(?=\\d)(?:(?!(?:1582(?:\\.|-|\\/)10(?:\\.|-|\\/)(?:0?[5-9]|1[0-4]))|(?:1752(?:\\.|-|\\/)0?9(?:\\.|-|\\/)(?:0?[3-9]|1[0-3])))(?=(?:(?!000[04]|(?:(?:1[^0-6]|[2468][^048]|[3579][^26])00))(?:(?:\\d\\d)(?:[02468][048]|[13579][26]))\\D0?2\\D29)|(?:\\d{4}\\D(?!(?:0?[2469]|11)\\D31)(?!0?2(?:\\.|-|\\/)(?:29|30))))(\\d{4})([-\\/.])(0?\\d|1[012])\\2((?!00)[012]?\\d|3[01])(?:$|(?=\x20\\d)\x20))?((?:(?:0?[1-9]|1[012])(?::[0-5]\\d){0,2}(?:\x20[aApP][mM]))|(?:[01]\\d|2[0-3])(?::[0-5]\\d){1,2})?$',
      ].join(''),
      'g',
    );
    // d/m/y h:m:s
    const dateRegValidator6 = new RegExp(
      [
        '^((((31\\/(0?[13578]|1[02]))|((29|30)\\/(0?[1,3-9]|1[0-2])))\\/(1[6-9]|[2-9]\\d)?\\d{2})|(29\\/0?2\\/(((1[6-9]|[2-9]\\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))|(0?[1-9]|1\\d|2[0-8])\\/((0?[1-9])|(1[0-2]))\\/((1[6-9]|[2-9]\\d)?\\d{2})) (20|21|22|23|[0-1]?\\d):[0-5]?\\d:[0-5]?\\d$',
      ].join(''),
      'g',
    );
    // Sql date format 2099-12-31T23:59:59
    const dateRegValidator7 = new RegExp(
      ['20\\d{2}(-|\\/)((0[1-9])|(1[0-2]))(-|\\/)((0[1-9])|([1-2][0-9])|(3[0-1]))(T|\\s)(([0-1][0-9])|(2[0-3])):([0-5][0-9]):([0-5][0-9])'].join(
        ''),
      'g',
    );
    // yyyy-mm-dd hh:MM
    const dateRegValidator8 = new RegExp(
      ['^20\\d{2}-(0[1-9]|1[0-2])-[0-3]\\d\\s([0-1][0-9]|2[0-3]):[0-5]\\d$'].join(''), 'g');
    // 24 hour time, or a 12 hour time with AM or PM specified
    const dateRegValidator9 = new RegExp(
      [
        '^((([0]?[1-9]|1[0-2])(:|\\.)[0-5][0-9]((:|\\.)[0-5][0-9])?( )?(AM|am|aM|Am|PM|pm|pM|Pm))|(([0]?[0-9]|1[0-9]|2[0-3])(:|\\.)[0-5][0-9]((:|\\.)[0-5][0-9])?))$',
      ].join(''),
      'g',
    );
    // DD-MON-YYYY
    const dateRegValidator10 = new RegExp(
      [
        '^((31(?! (FEB|APR|JUN|SEP|NOV)))|((30|29)(?! FEB))|(29(?= FEB (((1[6-9]|[2-9]\\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)))))|(0?[1-9])|1\\d|2[0-8])-(JAN|FEB|MAR|MAY|APR|JUL|JUN|AUG|OCT|SEP|NOV|DEC)-((1[6-9]|[2-9]\\d)\\d{2})$',
      ].join(''),
      'g',
    );
    // DD-mon-YYYY
    const dateRegValidator11 = new RegExp(
      [
        '^((31(?! (feb|apr|jun|sep|nov)))|((30|29)(?! feb))|(29(?= feb (((1[6-9]|[2-9]\\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)))))|(0?[1-9])|1\\d|2[0-8])-(jan|feb|mar|may|apr|jul|jun|aug|oct|sep|nov|dec)-((1[6-9]|[2-9]\\d)\\d{2})$',
      ].join(''),
      'g',
    );
    // RFC 2822 Date Regex
    const dateRegValidator12 = new RegExp(
      [
        '^(?:(Sun|Mon|Tue|Wed|Thu|Fri|Sat),\\s+)?(0[1-9]|[1-2]?[0-9]|3[01])\\s+(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\\s+(19[0-9]{2}|[2-9][0-9]{3})\\s+(2[0-3]|[0-1][0-9]):([0-5][0-9])(?::(60|[0-5][0-9]))?\\s+([-\\+][0-9]{2}[0-5][0-9]|(?:UT|GMT|(?:E|C|M|P)(?:ST|DT)|[A-IK-Z]))(\\s+|\\(([^\\(\\)]+|\\\\\\(|\\\\\\))*\\))*$',
      ].join(''),
      'g',
    );
    //  MMM dd, yyyy
    const dateRegValidator13 = new RegExp(
      [
        '^(?:(((Jan(uary)?|Ma(r(ch)?|y)|Jul(y)?|Aug(ust)?|Oct(ober)?|Dec(ember)?)\\ 31)|((Jan(uary)?|Ma(r(ch)?|y)|Apr(il)?|Ju((ly?)|(ne?))|Aug(ust)?|Oct(ober)?|(Sept|Nov|Dec)(ember)?)\\ (0?[1-9]|([12]\\d)|30))|(Feb(ruary)?\\ (0?[1-9]|1\\d|2[0-8]|(29(?=,\\ ((1[6-9]|[2-9]\\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)))))))\\,\\ ((1[6-9]|[2-9]\\d)\\d{2}))',
      ].join(''),
      'g',
    );
    //  mm/dd/yyyy hh:mm:ss am/pm
    const dateRegValidator14 = new RegExp(
      [
        '^(((((0[13578])|([13578])|(1[02]))[\\-\\/\\s]?((0[1-9])|([1-9])|([1-2][0-9])|(3[01])))|((([469])|(11))[\\-\\/\\s]?((0[1-9])|([1-9])|([1-2][0-9])|(30)))|((02|2)[\\-\\/\\s]?((0[1-9])|([1-9])|([1-2][0-9]))))[\\-\\/\\s]?\\d{4})(\\s(((0[1-9])|([1-9])|(1[0-2]))\\:([0-5][0-9])((\\s)|(\\:([0-5][0-9])\\s))([AM|PM|am|pm]{2,2})))?$',
      ].join(''),
      'g',
    );
    //  DD MONTH YY
    const dateRegValidator15 = new RegExp(
      [
        '(0[1-9]|[12][0-9]|3[01])\\s(J(anuary|uly)|Ma(rch|y)|August|(Octo|Decem)ber)\\s[1-9][0-9]{3}| (0[1-9]|[12][0-9]|30)\\s(April|June|(Sept|Nov)ember)\\s[1-9][0-9]{3}| (0[1-9]|1[0-9]|2[0-8])\\sFebruary\\s[1-9][0-9]{3}| 29\\sFebruary\\s((0[48]|[2468][048]|[13579][26])00|[0-9]{2}(0[48]|[2468][048]|[13579][26]))',
      ].join(''),
      'g',
    );
    //   yyyy-MM-dd HH:mm:ss or, yyyy-MM-dd HH:mm or, yyyy-MM-dd , "T" and "Z" are optionals
    const dateRegValidator16 = new RegExp(
      ['^\\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2]\\d|3[0-1])((T|\\s)([0-1]\\d|2[0-3]):[0-5]\\d(:[0-5]\\dZ?)?)?$'].join(
        ''),
      'g',
    );
    
    const result1 = value.match(dateRegValidator1);
    const result2 = value.match(dateRegValidator2);
    const result3 = value.match(dateRegValidator3);
    const result4 = value.match(dateRegValidator4);
    const result5 = value.match(dateRegValidator5);
    const result6 = value.match(dateRegValidator6);
    const result7 = value.match(dateRegValidator7);
    const result8 = value.match(dateRegValidator8);
    const result9 = value.match(dateRegValidator9);
    const result10 = value.match(dateRegValidator10);
    const result11 = value.match(dateRegValidator11);
    const result12 = value.match(dateRegValidator12);
    const result13 = value.match(dateRegValidator13);
    const result14 = value.match(dateRegValidator14);
    const result15 = value.match(dateRegValidator15);
    const result16 = value.match(dateRegValidator16);
    
    return (
      (result1 ? result1.length > 0 : false) ||
      (result2 ? result2.length > 0 : false) ||
      (result3 ? result3.length > 0 : false) ||
      (result4 ? result4.length > 0 : false) ||
      (result5 ? result5.length > 0 : false) ||
      (result6 ? result6.length > 0 : false) ||
      (result7 ? result7.length > 0 : false) ||
      (result8 ? result8.length > 0 : false) ||
      (result9 ? result9.length > 0 : false) ||
      (result10 ? result10.length > 0 : false) ||
      (result12 ? result12.length > 0 : false) ||
      (result13 ? result13.length > 0 : false) ||
      (result14 ? result14.length > 0 : false) ||
      (result15 ? result15.length > 0 : false) ||
      (result16 ? result16.length > 0 : false) ||
      (result11 ? result11.length > 0 : false)
    );
  }
  
  EvaluateDateByJS(value: string): boolean {
    const acceptedDateWords = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
      'AM',
      'PM',
      'am',
      'pm',
      '//',
      '/',
      '--',
      '-',
      ':',
      'GMT+',
      'GMT-',
      '::',
      '--T::.Z',
      '',
    ];
    
    const words = value.match(/("[^"]+"|[^"\s]+)/g) || [];
    // console.log('words', words);
    
    let validDateString = true;
    let validCount = 0;
    
    words.forEach(word => {
      const clearWord = word.replace(/[0-9]/g, '')
      .replace(/[,]/g, '');
      // console.log(clearWord);
      
      if (acceptedDateWords.includes(clearWord)) {
        validCount++;
      } else {
        if (words.length >= 5 && validCount <= 5) {
        } else {
          validDateString = false;
        }
      }
    });
    
    if (!validDateString) {
      // console.log('Not Valid String: ' + value);
      return false;
    }
    
    if ((new Date(value) as any) !== 'Invalid Date' && !isNaN(new Date(value) as any)) {
      if (new Date(value).toISOString()) {
        // console.log('Valid date: ' + value);
        return true;
      } else {
        // console.log('Invalid date: ' + value);
        return false;
      }
    } else {
      // console.log('Invalid date: ' + value);
      return false;
    }
  }
  
  EvaluateStringToDate(value: string) {
    const valid = this.EvaluateDateByRegex(value) || this.EvaluateDateByJS(value);
    // console.log('Date: ' + value, 'Regex: ', this.EvaluateDateByRegex(value), 'JS: ', this.EvaluateDateByJS(value));
    return valid;
  }
  
  GetObjectType(obj): string {
    return (
      Object.prototype.toString
      .call(obj)
      .replace(/^\[object (.+)\]$/, '$1')
      .toLowerCase() || ''
    );
  }
  
  CloneObjectPrimitive(object: any) {
    const objectType = this.GetObjectType(object);
    if (objectType === 'array') {
      const arrayObjectCopy = [];
      object.forEach(item => {
        arrayObjectCopy.push(this.CloneObjectPrimitive(item));
      });
      return arrayObjectCopy;
    } else if (objectType === 'object') {
      const copyObject = {};
      Object.keys(object)
      .forEach(key => {
        const value = object[key];
        const valueType = this.GetObjectType(value);
        
        switch (valueType) {
          case 'number':
            copyObject[key] = value;
            break;
          case 'string':
            copyObject[key] = value.toString();
            break;
          case 'boolean':
            copyObject[key] = value;
            break;
          case 'array':
            const copyArray = [];
            value.forEach(item => {
              copyArray.push(this.CloneObjectPrimitive(item));
            });
            copyObject[key] = copyArray;
            break;
          case 'object':
            if (!(this.circularDependencies.includes(key) || key.includes('$'))) {
              copyObject[key] = this.CloneObjectPrimitive(value);
            }
            break;
        }
      });
      
      return copyObject;
    } else {
      return object;
    }
  }
  
  GenerateLeapXLQRCode(text: string, size = 150): Observable<any> {
    return from(
      new Promise(async(resolve, reject) => {
        const componentOptions = {
          data: {
            scale: 0.8,
            protectors: false,
          },
          timing: {
            scale: 0,
            protectors: false,
          },
          alignment: {
            scale: 0,
            protectors: false,
          },
          cornerAlignment: {
            scale: 0,
            protectors: false,
          },
        };
        
        new AwesomeQR({
          text: text,
          size: size,
          margin: 4,
          // correctLevel?: number;
          // maskPattern?: number;
          // version?: number;
          components: componentOptions,
          colorDark: 'black',
          // colorLight: 'blue',
          autoColor: false,
          // backgroundImage?: string | Buffer;
          // backgroundDimming?: string;
          // gifBackground?: ArrayBuffer;
          // whiteMargin?: boolean;
          logoImage: 'assets/leap_logo/leap_logo_black_small.png',
          // logoScale: 0.25,
          logoMargin: 1,
          logoCornerRadius: 0,
          // dotScale?: number; // DEPRECATED!!
        })
        .draw()
        .then(dataURL => {
          return resolve(dataURL as string);
        });
      }),
    );
  }
  
  GetValueDataTypeForSpreadsheet(data: any, dataType = 'string'): string {
    const value = Array.isArray(data) ? data[0] : data;
    
    if (dataType === 'array') {
    } else if (value === '') {
      dataType = 'string';
    } else if (dataType === 'filelist') {
      dataType = 'file';
    } else if (!isNaN(value as any)) {
      dataType = 'number';
    } else if (value.charAt(0) === '=') {
      dataType = 'formula';
    } else if (this.EvaluateStringToDate(value)) {
      dataType = 'date';
    }
    
    return dataType;
  }
  
  HtmlToImg(selector: string, attachTo: string) {
    return new Promise((resolve, reject) => {
      if (selector && selector !== '') {
        htmlToImage.toCanvas(document.querySelector(selector) as any)
        .then(function(dataUrl) {
          const elementToAttach = attachTo && attachTo !== '' ? document.querySelector(
            attachTo) : document.body;
          const img = new Image();
          // img.src = dataUrl;
          elementToAttach.appendChild(dataUrl);
          return resolve(img);
        });
      } else {
        return resolve(null);
      }
    });
  }
  
  Capitalize(str, lower = false) {
    return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
  }
  
  HtmlToImgB64(selector: string) {
    return new Promise((resolve, reject) => {
      if (selector && selector !== '') {
        htmlToImage.toBlob(document.querySelector(selector) as any)
        .then(dataUrl => {
          this.BlobTob64(dataUrl)
          .subscribe(imgB64 => {
            return resolve(imgB64);
          });
        });
      } else {
        return resolve(null);
      }
    });
  }
  
  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  
  BlobTob64(blob: any): Observable<any> {
    return from(
      new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function() {
          const base64data = reader.result;
          return resolve(base64data);
        };
      }),
    );
  }
  
  Throttle(func, delay, context, args) {
    clearTimeout(this.inDebounce);
    this.inDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }
  
  TextTrim(text: string, length = 9999999, characterAppend = ''): string {
    if (text.length > length) {
      const trimmedText = text.substring(0, length);
      return `${ trimmedText }${ characterAppend }`;
    } else {
      return text;
    }
  }
  
  SanitizeString(str) {
    str = str.replace(/[^a-z0-9áéíóúñü \.,_-]/gim, '');
    return str.trim();
  }
  
  GenerateIdFromContext(context: string, addIdentifier = false): string {
    return context.split(Constants.ContextSeparator)[1].replace(/ /g,
      '') + (addIdentifier ? '-' + this.GenerateGuid() : '');
  }
  
  SetTheme(theme = 'light') {
    this.currentTheme = theme;
    if (theme === 'auto') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.SetTheme('dark');
      }
    } else {
      document.querySelector('html')
      .setAttribute('data-theme', this.SanitizeString(theme));
    }
  }
  
  RemoveDigits(text: string): string {
    return text.replace(/[0-9]/g, '');
  }
  
  TimeDifference(date1: any, date2: any) {
    let difference = date1 - date2;
    
    const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    difference -= daysDifference * 1000 * 60 * 60 * 24;
    
    const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60;
    
    const minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60;
    
    const secondsDifference = Math.floor(difference / 1000);
    
    return {
      daysDifference,
      hoursDifference,
      minutesDifference,
      secondsDifference,
    };
  }
  
  DateDifference(date1, date2, interval) {
    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;
    date1 = new Date(date1);
    date2 = new Date(date2);
    const timediff = date2 - date1;
    if (isNaN(timediff)) {
      return NaN;
    }
    switch (interval) {
      case 'years':
        return date2.getFullYear() - date1.getFullYear();
      case 'months':
        return date2.getFullYear() * 12 + date2.getMonth() - (date1.getFullYear() * 12 + date1.getMonth());
      case 'weeks':
        return Math.floor(timediff / week);
      case 'days':
        return Math.floor(timediff / day);
      case 'hours':
        return Math.floor(timediff / hour);
      case 'minutes':
        return Math.floor(timediff / minute);
      case 'seconds':
        return Math.floor(timediff / second);
      default:
        return undefined;
    }
  }
  
  StringToTitleCase(string: string): string {
    return string
    .replace(
      /([^[\p{L}\d]+|(?<=[\p{Ll}\d])(?=\p{Lu})|(?<=\p{Lu})(?=\p{Lu}[\p{Ll}\d])|(?<=[\p{L}\d])(?=\p{Lu}[\p{Ll}\d]))/gu,
      ' ')
    .toLowerCase()
    .replace(/\b\w/g, s => s.toUpperCase());
  }
  
  ConvertDataElementsToRows(data: any) {
    let rowSeparatedData = data;
    
    if (Array.isArray(data)) {
      if (Array.isArray(data[0])) {
      } else {
        rowSeparatedData = Array.from(this.GroupBy(data, v => v.row))
        .map(d => d[1]);
      }
    }
    
    return rowSeparatedData;
  }
  
  ExtractColFromContext(context: string): string {
    const spplitedContext = context.split(Constants.ContextSeparator);
    const dataSourceType = spplitedContext.shift();
    const reference = spplitedContext.pop();
    
    switch (dataSourceType) {
      case DatasourceType.Spreadsheet:
        const spplitedReference = reference.split(':');
        return this.RemoveDigits(spplitedReference[0]);
        break;
      default:
        return reference;
        break;
    }
  }
  
  ExtractRowFromContext(context: string): number {
    const spplitedContext = context.split(Constants.ContextSeparator);
    const dataSourceType = spplitedContext.shift();
    const reference = spplitedContext.pop();
    
    switch (dataSourceType) {
      case DatasourceType.Spreadsheet:
        const spplitedReference = reference.split(':');
        const row = spplitedReference[0].replace(/\D/g, '');
        
        if (row.length > 0) {
          return +row;
        } else {
          return null;
        }
        break;
      default:
        return null;
        break;
    }
  }
  
  GetBrowserInfo(): {
    browser: string;
    version: string;
  } {
    return {
      browser: this.GetBrowser(),
      version: this.GetBrowserVersion(),
    };
  }
  
  GetBrowser(): string {
    const agent = window.navigator.userAgent.toLowerCase();
    
    switch (true) {
      case agent.indexOf('edge') > -1:
        return Browser.EdgeHtml;
      case agent.indexOf('edg') > -1:
        return Browser.EdgeChromium;
      case agent.indexOf('opr') > -1 && !!(window as any).opr:
        return Browser.Opera;
      case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
        return Browser.Chrome;
      case agent.indexOf('trident') > -1:
        return Browser.InternetExplorer;
      case agent.indexOf('firefox') > -1:
        return Browser.Firefox;
      case agent.indexOf('safari') > -1:
        return Browser.Safari;
      default:
        return Browser.Unknown;
    }
  }
  
  GetRandomColorHEX() {
    return '#' + (Math.random()
    .toString(16) + '000000').substring(2, 8);
  }
  
  GetRandomColorFromString(input: string) {
    for (var i = 0, hash = 0; i < input.length; hash = input.charCodeAt(i++) + ((hash << 5) - hash)) ;
    const color = Math.floor(Math.abs(((Math.sin(hash) * 10000) % 1) * 16777216))
    .toString(16);
    return '#' + Array(6 - color.length + 1)
    .join('0') + color;
    
    let stringUniqueHash = [...input].reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    return `hsl(${ stringUniqueHash % 360 }, 95%, 35%)`;
  }
  
  SortBy(array: any[], property: string, order: 'desc' | 'asc' = 'desc') {
    if (array && array.length > 0) {
      return array.sort(
        (a, b) => ((order === 'desc' ? a[property] > b[property] : a[property] < b[property]) ? 1 : -1));
    } else {
      return [];
    }
  }
  
  /**
   * Takes in two strings and returns how similiar they are via percentage.
   * Uses Dice's Coefficient.
   * @param {String} stringOne First string to be compared.
   * @param {String} stringTwo Second string to be compared.
   * @param {String} [trimByString] By default the two strings get all whitespace.
   * removed via a replacement call. This argument allows for custom string trimming
   * inside the funciton with regex or strings.
   * @param {Boolean} [caseSensitive] By default the comparison is case sensitive.
   * This argument can turn it off.
   * @returns {Integer} Percentage of how similiar the strings are.
   */
  StringSimilarity(stringOne, stringTwo, options = { trimByString: /\s/g, caseSensitive: false }) {
    const config = Object.assign({ trimByString: /\s/g, caseSensitive: false }, options);
    
    stringOne = stringOne.replace(config.trimByString, '');
    stringTwo = stringTwo.replace(config.trimByString, '');
    
    if (!config.caseSensitive) {
      stringOne = stringOne.toLowerCase();
      stringTwo = stringTwo.toLowerCase();
    }
    
    if (!stringOne.length && !stringTwo.length) return 1;
    if (!stringOne.length || !stringTwo.length) return 0;
    if (stringOne === stringTwo) return 1;
    if (stringOne.length === 1 && stringTwo.length === 1) return 0;
    if (stringOne.length < 2 || stringTwo.length < 2) return 0;
    
    const firstBigrams = new Map();
    for (let i = 0; i < stringOne.length - 1; i++) {
      const bigram = stringOne.substring(i, i + 2);
      const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1;
      
      firstBigrams.set(bigram, count);
    }
    
    let intersectionSize = 0;
    for (let i = 0; i < stringTwo.length - 1; i++) {
      const bigram = stringTwo.substring(i, i + 2);
      const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0;
      
      if (count > 0) {
        firstBigrams.set(bigram, count - 1);
        intersectionSize++;
      }
    }
    
    return (2.0 * intersectionSize) / (stringOne.length + stringTwo.length - 2);
  }
  
  GetClosestSimilarity(values: string[], compare: string): string {
    
    const mostSimilar = {
      similarityIndex: 0,
      value: compare,
    };
    
    values.forEach(v => {
      
      const index = this.StringSimilarity(v, compare);
      if (index > 0.6) {
        
        if (index >= mostSimilar.similarityIndex) {
          mostSimilar.similarityIndex = index;
          mostSimilar.value = v;
        }
        
      }
      
    });
    
    return mostSimilar.value;
  }
  
  GetBrowserVersion() {
    const userAgent = navigator.userAgent;
    let tem;
    
    let matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    
    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      
      return 'IE ' + (tem[1] || '');
    }
    
    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      
      if (tem != null) {
        return tem.slice(1)
        .join(' ')
        .replace('OPR', 'Opera');
      }
    }
    
    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
    
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) {
      matchTest.splice(1, 1, tem[1]);
    }
    
    return matchTest.join(' ');
  }
  
  GetSystemInformation() {
    const info = {
      origin: window.origin,
      clientInformation: window.clientInformation,
      timeOpened: new Date(),
      timezone: new Date().getTimezoneOffset() / 60,
      pageon: window.location.pathname,
      referrer: document.URL,
      previousSites: history.length,
      browserName: navigator.appName,
      browserEngine: navigator.product,
      browserVersion1a: navigator.appVersion,
      browserVersion1b: navigator.userAgent,
      browserLanguage: navigator.language,
      browserOnline: navigator.onLine,
      browserPlatform: navigator.platform,
      javaEnabled: navigator.javaEnabled(),
      dataCookiesEnabled: navigator.cookieEnabled,
      dataCookies1: document.cookie,
      dataCookies2: decodeURIComponent(document.cookie.split(';')
      .toString()),
      dataStorage: {
        rememberMe: this.DecompressString(
          localStorage.getItem(`${ Constants.LocalStoragePrefix }rememberMe`)),
        userLastName: this.DecompressString(
          localStorage.getItem(`${ Constants.LocalStoragePrefix }userLastName`)),
        currentCompanyId: this.DecompressString(
          localStorage.getItem(`${ Constants.LocalStoragePrefix }currentCompanyId`)),
        userRole: this.DecompressString(localStorage.getItem(`${ Constants.LocalStoragePrefix }userRole`)),
        userFullName: this.DecompressString(
          localStorage.getItem(`${ Constants.LocalStoragePrefix }userFullName`)),
        debug: this.DecompressString(localStorage.getItem(`${ Constants.LocalStoragePrefix }debug`)),
        userName: this.DecompressString(localStorage.getItem(`${ Constants.LocalStoragePrefix }userName`)),
        userType: this.DecompressString(localStorage.getItem(`${ Constants.LocalStoragePrefix }userType`)),
        jwt: this.DecompressString(localStorage.getItem(`${ Constants.LocalStoragePrefix }jwt`)),
        userId: this.DecompressString(localStorage.getItem(`${ Constants.LocalStoragePrefix }userId`)),
        userPermissions: this.DecompressString(
          localStorage.getItem(`${ Constants.LocalStoragePrefix }userPermissions`)),
        companyId: this.DecompressString(localStorage.getItem(`${ Constants.LocalStoragePrefix }companyId`)),
      },
      sizeScreenW: screen.width,
      sizeScreenH: screen.height,
      sizeInW: window.innerWidth,
      sizeInH: window.innerHeight,
      sizeAvailW: screen.availWidth,
      sizeAvailH: screen.availHeight,
      scrColorDepth: screen.colorDepth,
      scrPixelDepth: screen.pixelDepth,
    };
    
    return info;
  }
  
  ReplaceContextReference(context: string, newReference): string {
    const spplitedContext = context.split(Constants.ContextSeparator);
    spplitedContext.pop();
    return `${ spplitedContext.join(
      Constants.ContextSeparator) }${ Constants.ContextSeparator }${ newReference }`;
  }
  
  FormatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  
  ClearText(text: string): string {
    if (!text) {
      return '';
    } else {
      const clearText = text.toString()
      .replace(/\b\w/g, first => first.toLocaleUpperCase());
      return clearText.replace(/[_]/g, ' ')
      .replace(/[.]/g, ' - ');
    }
  }
  
  UrlToFile(url, filename, mimeType) {
    return fetch(url)
    .then(function(res) {
      return res.arrayBuffer();
    })
    .then(function(buf) {
      return new File([buf], filename, { type: mimeType });
    });
  }
  
  GenerateHashCode(string: string): number {
    let hash = 0,
      i,
      chr;
    if (string.length === 0) {
      return hash;
    }
    for (i = 0; i < string.length; i++) {
      chr = string.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }
  
  GroupBy(list, keyGetter) {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  
  GetDataSourceTypeFromId(dataSourceTypeId: number) {
    switch (dataSourceTypeId) {
      case DatasourceTypeId.Api:
        return DatasourceType.Api;
      case DatasourceTypeId.Custom:
        return DatasourceType.Custom;
      case DatasourceTypeId.Database:
        return DatasourceType.Database;
      case DatasourceTypeId.LeapXL:
        return DatasourceType.LeapXL;
      case DatasourceTypeId.Spreadsheet:
        return DatasourceType.Spreadsheet;
      case DatasourceTypeId.System:
        return DatasourceType.System;
      case DatasourceTypeId.UnifiedDatabase:
        return DatasourceType.UnifiedDatabase;
      case DatasourceTypeId.InternetMessaging:
        return DatasourceType.InternetMessaging;
      case DatasourceTypeId.LocalDataStore:
        return DatasourceType.LocalDataStore;
      default:
        return '';
    }
  }
  
  GetDataSourceIcon(dataSourceType: DatasourceType) {
    switch (dataSourceType) {
      case DatasourceType.Api:
        return 'api';
      case DatasourceType.Custom:
        return 'text_format';
      case DatasourceType.Database:
        return 'database';
      case DatasourceType.LeapXL:
        return 'home';
      case DatasourceType.Spreadsheet:
        return 'heap_snapshot_large';
      case DatasourceType.System:
        return 'desktop_windows';
      case DatasourceType.UnifiedDatabase:
        return 'database';
      case DatasourceType.InternetMessaging:
        return 'email';
      case DatasourceType.LocalDataStore:
        return 'folder';
      default:
        return 'database';
    }
  }
  
  GetDataSourceIdFromType(dataSourceType: string): number {
    switch (dataSourceType) {
      case DatasourceType.Api:
        return DatasourceTypeId.Api;
      
      case DatasourceType.Custom:
        return DatasourceTypeId.Custom;
      
      case DatasourceType.Database:
        return DatasourceTypeId.Database;
      
      case DatasourceType.LeapXL:
        return DatasourceTypeId.LeapXL;
      
      case DatasourceType.Spreadsheet:
        return DatasourceTypeId.Spreadsheet;
      
      case DatasourceType.System:
        return DatasourceTypeId.System;
      
      case DatasourceType.UnifiedDatabase:
        return DatasourceTypeId.UnifiedDatabase;
      
      case DatasourceType.InternetMessaging:
        return DatasourceTypeId.InternetMessaging;
      
      case DatasourceType.LocalDataStore:
        return DatasourceTypeId.LocalDataStore;
      default:
        return 0;
    }
  }
  
  CreateGroupedTranslationIdsContextsFromDataElements(dataElements: DataElement[]): {
    translationIds: number[][];
    contexts: string[][];
  } {
    const grouped = {
      translationIds: [],
      contexts: [],
    };
    
    const rangedParticles = [];
    
    dataElements.forEach(de => {
      if (de.RangeParticleId === '' || de.RangeParticleId === null || de.RangeParticleId === undefined) {
        grouped.translationIds.push([de.TranslationId]);
        grouped.contexts.push([de.Context]);
        rangedParticles.push(de.ParticleId);
      } else if (!rangedParticles.includes(de.RangeParticleId)) {
        rangedParticles.push(de.ParticleId);
        const rangeTranslations = [];
        const rangeContexts = [];
        rangeTranslations.push(de.TranslationId);
        rangeContexts.push(de.Context);
        const secondParticle = dataElements.find(sde => sde.ParticleId === de.RangeParticleId);
        
        if (secondParticle) {
          rangeTranslations.push(secondParticle.TranslationId);
          rangeContexts.push(secondParticle.Context);
          rangedParticles.push(secondParticle.ParticleId);
        }
        grouped.translationIds.push(rangeTranslations);
        grouped.contexts.push(rangeContexts);
      }
    });
    
    return grouped;
  }
  
  SeparateRangeReference(range: string): {
    firstColumn: string;
    firstRow: number;
    lastColumn: string;
    lastRow: number;
  } {
    const separatedReference = {
      firstColumn: '',
      firstRow: 0,
      lastColumn: '',
      lastRow: 0,
    };
    
    const splittedRange = range.split(':');
    const firstReference = splittedRange[0];
    const lastReference = splittedRange[1] || firstReference;
    
    separatedReference.firstColumn = firstReference.replace(/\d+/g, '');
    separatedReference.lastColumn = lastReference.replace(/\d+/g, '');
    separatedReference.firstRow = +firstReference.replace(/\D/g, '');
    separatedReference.lastRow = +lastReference.replace(/\D/g, '');
    return separatedReference;
  }
  
  ExtractValuesByType(data): {
    string: string;
    number: number;
    array: any[];
    file: any;
    object: any;
  } {
    const valueByType = {
      string: '',
      number: 0,
      array: [],
      file: {},
      object: {},
    };
    
    switch (this.GetObjectType(data)) {
      case 'string':
      case 'number':
        valueByType['string'] = data.toString();
        valueByType['number'] = this.IsNumeric(valueByType['string']) ? +valueByType['string'] : 0;
        valueByType['array'] = [
          {
            row: 0,
            col: 0,
            context: '',
            value: data,
            formattedValue: data,
          },
        ];
        break;
      case 'object':
        valueByType['array'] = [data];
        valueByType['object'] = data;
        valueByType['string'] = data.value ? data.value.toString() : data.toString() || Constants.Defaults.DataItemValue;
        valueByType['number'] = this.IsNumeric(valueByType['string']) ? +valueByType['string'] : 0;
        break;
      case 'filelist':
        valueByType['file'] = data[0];
        valueByType['object'] = data[0];
        valueByType['string'] = data[0].name;
        break;
      case 'array':
        valueByType['array'] = data;
        const firstElemenData = data[0];
        switch (this.GetObjectType(firstElemenData)) {
          case 'string':
          case 'number':
            valueByType['string'] = firstElemenData.toString();
            valueByType['number'] = this.IsNumeric(valueByType['string']) ? +valueByType['string'] : 0;
            break;
          case 'object':
            valueByType['object'] = firstElemenData;
            
            const objectValue = firstElemenData.value || firstElemenData.formattedValue || null;
            switch (this.GetObjectType(objectValue)) {
              case 'string':
              case 'number':
                valueByType['string'] = objectValue.toString();
                valueByType['number'] = this.IsNumeric(valueByType['string']) ? +valueByType['string'] : 0;
                break;
              case 'object':
                valueByType['object'] = objectValue;
                break;
              case 'filelist':
                valueByType['file'] = objectValue[0];
                valueByType['object'] = objectValue[0];
                valueByType['string'] = objectValue[0].name;
                break;
              default:
                break;
            }
            break;
          case 'filelist':
            valueByType['file'] = firstElemenData[0];
            valueByType['object'] = firstElemenData[0];
            valueByType['string'] = firstElemenData[0].name;
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    
    return valueByType;
  }
  
  GetSpreadsheetRangeOrientation(context: string, context2: string) {
    const firstDE = this.BreakContext(context);
    const secondDE = this.BreakContext(context2);
    
    if (firstDE.row === secondDE.row) {
      return 'col';
    }
    
    if (firstDE.col === secondDE.col) {
      return 'row';
    }
    
    const rows = Math.abs(firstDE.row - secondDE.row);
    const cols = Math.abs(this.ColumnNameToIndex(firstDE.col) - this.ColumnNameToIndex(secondDE.col));
    
    if (rows > cols) {
      return 'row';
    } else {
      return 'col';
    }
  }
  
  DatasourceDataByRows(data: any): any[] {
    const g = groupBy(data, 'row');
    return Object.values(g) || [];
  }
  
  PaginateData(data: any, pageSize: number, pageNumber = 1): any {
    const paginatedData = {
      data,
      totalItems: 0,
      pageNumber: +pageNumber,
      pageSize: +pageSize,
      pageCount: 0,
    };
    
    if (data && this.GetObjectType(data) === 'array') {
      const dataRows = this.DatasourceDataByRows(data);
      paginatedData.totalItems = dataRows.length;
      paginatedData.pageCount = paginatedData.totalItems / pageSize;
      
      paginatedData.data = this.Paginate(dataRows, pageSize, pageNumber)
      .flat();
    } else {
      return paginatedData;
    }
    
    return paginatedData;
  }
  
  Paginate(array, pageSize, pageNumber) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    console.log(array);
    
    if (this.GetObjectType(array) === 'array') {
      return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }
  }
  
  BreakContext(context: string): {
    context: string;
    dataSourceType: string;
    dataSourceName: string;
    collection: string;
    reference: string;
    internalName: string;
    col: string;
    row: number;
  } {
    
    const splittedContext = context.split(Constants.ContextSeparator);
    const dataSourceType = splittedContext[0];
    
    switch (dataSourceType) {
      case DatasourceType.Spreadsheet:
        const reference = splittedContext[splittedContext.length - 1];
        if (splittedContext.length === 4) {
          splittedContext.pop();
        }
        return {
          dataSourceType: dataSourceType,
          dataSourceName: splittedContext[1],
          collection: splittedContext[2],
          context: splittedContext.join(Constants.ContextSeparator),
          row: reference.match(/(\d+)/) ? +reference.match(/(\d+)/)[0] : 0,
          col: reference.replace(/[0-9]/g, ''),
          reference: `${ reference.match(/(\d+)/) ? +reference.match(/(\d+)/)[0] : 0 }:${ reference.replace(
            /[0-9]/g, '') }`,
          internalName: `${ reference.match(/(\d+)/) ? +reference.match(
            /(\d+)/)[0] : 0 }:${ reference.replace(/[0-9]/g, '') }`,
        };
      
      default:
        return {
          dataSourceType: dataSourceType,
          dataSourceName: splittedContext[1],
          collection: splittedContext[2],
          context: splittedContext.join(Constants.ContextSeparator),
          reference: splittedContext[splittedContext.length - 1],
          internalName: splittedContext[splittedContext.length - 1],
          row: 0,
          col: '',
        };
    }
  }
  
  public ColumnIndexToName(index: number) {
    let ret = '';
    let a = 1;
    let b = 26;
    
    for (ret = '', a = 1, b = 26; (index -= a) >= 0; a = b, b *= 26) {
      const y = (index % b) / a;
      const x = parseInt(y.toString(), 10) + 65;
      ret = String.fromCharCode(x) + ret;
    }
    return ret;
  }
  
  public ColumnNameToIndex(value: string) {
    const base = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let i = 0;
    let j = 0;
    let result = 0;
    
    for (i = 0, j = value.length - 1; i < value.length; i += 1, j -= 1) {
      result += Math.pow(base.length, j) * (base.indexOf(value[i]) + 1);
    }
    
    return result;
  }
  
  public GenerateGuid() {
    const s = [];
    const hexDigits = '0123456789abcdef';
    for (let i = 0; i < 10; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4';
    // tslint:disable-next-line:no-bitwise
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = '';
    return s.join('');
  }
  
  public GetBrowserHeight() {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.documentElement.clientHeight,
    );
  }
  
  public GetBrowserWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth,
    );
  }
  
  public GetDomain() {
    const url = window.location.href;
    const arr = url.split('/');
    return arr[0] + '//' + arr[2];
  }
  
  public RefreshPage() {
    location.reload();
  }
  
  public HtmlToElements(html) {
    const parser = new DOMParser(),
      content = 'text/html',
      DOM = parser.parseFromString(html, content);
    
    // return element
    return DOM.body.childNodes[0];
  }
  
  IsPreview() {
    return document.location.pathname.indexOf('/run/preview/') > -1;
  }
  
  GetTranslateXY(element) {
    const style = window.getComputedStyle(element);
    const matrix = new DOMMatrixReadOnly(style.transform);
    return {
      translateX: matrix.m41,
      translateY: matrix.m42,
    };
  }
  
  IsUrl(url: string) {
    let inputElement = document.createElement('input');
    inputElement.type = 'url';
    inputElement.value = url;
    
    if (!inputElement.checkValidity()) {
      return false;
    } else {
      return true;
    }
  }
  
  IsEditor() {
    const isEditor = document.location.pathname.indexOf('/workarea/') > -1;
    return isEditor;
  }
  
  MoveArrayElement(array, from, to, on = 1) {
    return array.splice(to > array.length - 1 ? array.length - 1 : to, 0, ...array.splice(from, on)), array;
  }
  
  IsDevEnvironment() {
    return document.location.hostname === this.Environments.Dev;
  }
  
  IsTestEnvironment() {
    return document.location.hostname === this.Environments.Test;
  }
  
  IsProdEnvironment() {
    return document.location.hostname === this.Environments.Prod;
  }
  
  IsLocalEnvironment() {
    return document.location.hostname === this.Environments.Local;
  }
  
  public GenerateClassDynamically(name: string, rules: any, styleSheetTitle = '') {
    // console.log(name, rules);
    
    try {
      const style = document.createElement('style') as any;
      style.type = 'text/css';
      document.getElementsByTagName('head')[0].appendChild(style);
      
      if (styleSheetTitle && styleSheetTitle !== '') {
        const styleSheets = [].slice.call(document.styleSheets);
        const customStyleSheet = styleSheets.find(ss => ss.title === styleSheetTitle);
        
        if (name === '@keyframes') {
          style.sheet.insertRule(rules, 0);
        } else {
          if (rules) {
            customStyleSheet.addRule(name, rules.replaceAll('\t', ' '));
          }
        }
      } else {
        if (!(style.sheet || {}).insertRule) {
          (style.styleSheet || style.sheet).addRule(name, rules.replaceAll('\t', ' '));
        } else {
          style.sheet.insertRule(name + '{' + rules.replaceAll('\t', ' ') + '}', 0);
        }
      }
    } catch (e) {
      console.warn('Error adding dynamic class', e);
    }
  }
  
  ParseCSS(cssText: string): ParsedCSS[] {
    let parser = new cssjs();
    let parsed = parser.parseCSS(cssText);
    return parsed;
  }
  
  BuildParsedCss(parsedCss: ParsedCSS[]): string {
    let css = '';
    
    parsedCss.forEach(pCss => {
      let section = '';
      
      if (pCss.type) {
        section += `${ pCss.styles }\n\n`;
      } else {
        section += `${ pCss.selector } {\n`;
        pCss.rules.forEach(rule => {
          section += `\t${ rule.directive }: ${ rule.value };\n`;
        });
        
        section += `}\n\n`;
      }
      
      css += section;
    });
    
    return css;
  }
  
  public TriggerEvent(type, el = document) {
    // modern browsers, IE9+
    const e = document.createEvent('HTMLEvents');
    e.initEvent(type, true, true);
    el.dispatchEvent(e);
  }
  
  public RedrawBrowser() {
    console.log('redraw browser');
    
    this.TriggerEvent('resize');
  }
  
  GenerateHttpHeaders(headers: { name: string; value: string }[]) {
    const newHeaders = new HttpHeaders();
    
    headers.forEach(header => {
      newHeaders.append(header.name, header.value);
    });
    
    return newHeaders;
  }
  
  shortString(stringToTrim: string, length: number, postString: string) {
    if (stringToTrim && stringToTrim.length > length) {
      return stringToTrim.substr(0, length - 3) + postString;
    } else {
      return stringToTrim;
    }
  }
  
  TextFit() {
    return;
    const textElements = document.getElementsByClassName('textfit');
    for (let i = 0; i < textElements.length; i++) {
      const element = textElements[i] as any;
      textFit(element, { maxFontSize: +element.dataset.textfit });
    }
  }
  
  CompressString(string: string) {
    return LZString.compressToBase64(JSON.stringify(string));
  }
  
  DecompressString(compressedString: string) {
    return LZString.decompressFromBase64(compressedString);
  }
  
  async CopyImageToClipboard(base64Image: string) {
    copyImg(base64Image)
    .then(value => {
      console.log(value);
    });
  }
  
  CopyToClipboard(text: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  
  ClearLeapXLObjectFromCircularDependencies(obj: any, clone = false) {
    let cleanObject = obj;
    try {
      cleanObject = this.RemovePropertiesFromObject(obj, this.circularDependencies, clone);
      return cleanObject;
    } catch (e) {
      console.log(e);
      return cleanObject;
    }
  }
  
  DeepCloneObject(object: any) {
    return cloneDeep(object);
  }
  
  RemovePropertiesFromObject(obj: any, propertiesToRemove: string[], clone = false) {
    // obj = clone ? _.cloneDeep(_.omit(obj, propertiesToRemove)) : obj;
    
    if (obj) {
      Object.keys(obj)
      .forEach(key => {
        const lowerCaseProperties = propertiesToRemove.map(p => p.toLowerCase());
        if (obj[key] && lowerCaseProperties.includes(key.toLowerCase())) {
          delete obj[key];
        } else if (obj[key] && typeof obj[key] === 'object') {
          this.RemovePropertiesFromObject(obj[key], propertiesToRemove);
        }
      });
    }
    return obj;
  }
  
  ReplaceValueInObject(obj: any, oldValue: any, newValue: any) {
    if (obj) {
      Object.keys(obj)
      .forEach(key => {
        if (!this.circularDependencies.includes(key)) {
          if (isEqual(obj[key], oldValue)) {
            obj[key] = newValue;
          } else if (obj[key] && typeof obj[key] === 'object') {
            this.ReplaceValueInObject(obj[key], oldValue, newValue);
          }
        }
      });
    }
    return obj;
  }
  
  SetPropertyValueInObject(obj: any, property: any, value: any) {
    if (obj) {
      Object.keys(obj)
      .forEach(key => {
        if (!this.circularDependencies.includes(key)) {
          if (key === property) {
            obj[key] = value;
          } else if (obj[key] && typeof obj[key] === 'object') {
            this.ReplaceValueInObject(obj[key], property, value);
          }
        }
      });
    }
    return obj;
  }
  
  SetGuidToPropertyInObject(obj: any, property: any) {
    if (obj) {
      Object.keys(obj)
      .forEach(key => {
        if (!this.circularDependencies.includes(key)) {
          if (key === property) {
            obj[key] = this.GenerateGuid();
          } else if (obj[key] && typeof obj[key] === 'object') {
            this.SetGuidToPropertyInObject(obj[key], property);
          }
        }
      });
    }
    return obj;
  }
  
  ExportArrayToExcel(data: any[], name: string = 'ExportResult') {
    const sheetName = `${ name } - 1`;
    const fileName = name;
    
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${ fileName }.xlsx`);
  }
  
  ExportTableToExcel(tableId: string, name?: string) {
    const timeSpan = new Date().toISOString();
    const prefix = name || 'ExportResult';
    let fileName = `${ prefix }-${ timeSpan }`;
    fileName = fileName.substring(0, 25);
    const targetTableElm = document.getElementById(tableId);
    const wb = XLSX.utils.table_to_book(targetTableElm, <XLSX.Table2SheetOpts>{ sheet: prefix });
    XLSX.writeFile(wb, `${ fileName }.xlsx`);
  }
  
  IsGUID(guid: string) {
    const guidRegexPattern = new RegExp(
      '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');
    return guidRegexPattern.test(guid);
  }
  
  ExportToTextFile(filename: string, data: any, format = 'json') {
    data = JSON.stringify(data);
    filename = filename + '.' + format;
    
    const blob = new Blob([data], { type: 'text/plain' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  
  StringIsDate(date: string) {
    return !isNaN(Date.parse(date));
  }
  
  ExportToCsv(filename, rows) {
    const processRow = function(row) {
      let finalVal = '';
      for (let j = 0; j < row.length; j++) {
        let innerValue = row[j] === null ? '' : row[j].toString();
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString();
        }
        
        let result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) {
          result = '"' + result + '"';
        }
        
        if (isNaN(result)) {
          result = `"${ result }"`;
        }
        
        if (j > 0) {
          finalVal += ',';
        }
        finalVal += result;
      }
      return finalVal + '\n';
    };
    
    let csvFile = '';
    for (let i = 0; i < rows.length; i++) {
      console.log(rows[i]);
      console.log(processRow(rows[i]));
      csvFile += processRow(rows[i]);
    }
    
    const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  
  CheckDuplicateInObject(propertyName, inputArray) {
    let seenDuplicate = false;
    const testObject = {};
    const duplicated = [];
    
    inputArray.map(function(item) {
      const itemPropertyName = item[propertyName];
      if (itemPropertyName in testObject) {
        testObject[itemPropertyName].duplicate = true;
        item.duplicate = true;
        seenDuplicate = true;
        duplicated.push(itemPropertyName);
      } else {
        testObject[itemPropertyName] = item;
        delete item.duplicate;
      }
    });
    
    return duplicated.join(' ,');
  }
  
  ScrollToElement(htmlElementSelector: string, htmlScrollableParentContainerSelector: string) {
    const elHtml = document.querySelector(htmlElementSelector);
    const containerEl = document.querySelector(htmlScrollableParentContainerSelector);
    const elBoundings = elHtml.getBoundingClientRect();
    const top = elBoundings.y - window.innerHeight / 2;
    const left = elBoundings.x - window.innerWidth / 2;
    return this.ScrollToPosition(containerEl, top, left);
  }
  
  ScrollToPosition(parentContainer, topElement: number, leftElement: number) {
    topElement = Math.round(topElement);
    leftElement = Math.round(leftElement);
    
    if (parentContainer.scrollTop === topElement && parentContainer.scrollLeft === leftElement) {
      return;
    }
    
    let resolveFn;
    let scrollListener;
    let timeoutId;
    let cancel = true;
    
    const promise = new Promise(resolve => {
      resolveFn = resolve;
    });
    
    const finished = () => {
      parentContainer.removeEventListener('scroll', scrollListener);
      resolveFn();
    };
    
    scrollListener = () => {
      clearTimeout(timeoutId);
      cancel = false;
      
      // scroll is finished when either the position has been reached, or 100ms have elapsed since the last scroll event
      if (parentContainer.scrollTop === topElement && parentContainer.scrollLeft === leftElement) {
        finished();
      } else {
        timeoutId = setTimeout(finished, 50);
      }
    };
    
    parentContainer.addEventListener('scroll', scrollListener);
    
    setTimeout(() => {
      if (cancel) {
        finished();
      }
    }, 100);
    
    parentContainer.scrollBy({
      top: topElement,
      left: leftElement,
      behavior: 'smooth',
    });
    
    return promise;
  }
  
  GetFormattedDate(date: Date) {
    const year = date.getFullYear();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    return `${ month }/${ day }/${ year }`;
  }
  
  JSDateToExcelDate(inDate: Date) {
    const calc1 = inDate.getTimezoneOffset() * 60 * 1000;
    const calc2 = inDate.getTime() - calc1;
    const returnDateTime = 25569.0 + calc2 / (1000 * 60 * 60 * 24);
    return returnDateTime.toString()
    .substr(0, 20);
  }
  
  ExcelDateToJSDate(date) {
    return new Date(Math.round((date - 25569) * 86400 * 1000));
  }
  
  ReplaceInText(text: string, toReplace: string, replaceWith: string): string {
    return (text as any).replaceAll(toReplace, replaceWith);
  }
  
  IsFloat(value: any) {
    value = value.toString();
    return !/[^0-9.]/i.test(value);
  }
  
  IsNumeric(value: string): boolean {
    if (typeof value !== 'number' && typeof value !== 'string') return false;
    return value && (!isNaN(Number(value.replace(/[$,]/g, ''))));
  }
  
  GetArrayMin(array: any[], property: string) {
    return (
      (array.length &&
        array.reduce(function(prev, curr) {
          return prev[property] < curr[property] ? prev : curr;
        })) ||
      null
    );
  }
  
  GetQueryParams(param = null) {
    if (param) {
      return this.route.snapshot.queryParamMap.get(param);
    } else {
      return this.route.snapshot.queryParams;
    }
  }
  
  StringDifference(stringA: string, stringB: string) {
    return stringA.split(stringB)
    .join('');
  }
  
  CompareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }
      
      const varA = typeof a[key] === 'string' ? a[key].toUpperCase()
      .trim() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase()
      .trim() : b[key];
      
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }
  
  GeneratedPageURL = ({ html, css, js }) => {
    const getBlobURL = (code, type) => {
      const blob = new Blob([code], { type });
      return URL.createObjectURL(blob);
    };
    
    const cssURL = getBlobURL(css, 'text/css');
    const jsURL = getBlobURL(js, 'text/javascript');
    
    const source = `
    <html>
      <head>
        ${ css && `<link rel="stylesheet" type="text/css" href="${ cssURL }" />` }
        ${ js && `<script src="${ jsURL }"></script>` }
      </head>
      <body>
        ${ html || '' }
      </body>
    </html>
  `;
    
    return getBlobURL(source, 'text/html');
  };
  
  CloneObject(item: any) {
    if (!item) {
      return item;
    } // null, undefined values check
    
    const types = [Number, String, Boolean];
    let result;
    
    // normalizing primitives if someone did new String('aaa'), or new Number('444');
    types.forEach(function(type) {
      if (item instanceof type) {
        result = type(item);
      }
    });
    
    if (typeof result === 'undefined') {
      if (Object.prototype.toString.call(item) === '[object Array]') {
        result = [];
        item.forEach((child, index, array) => {
          result[index] = this.CloneObject(child);
        });
      } else if (typeof item === 'object') {
        // testing that this is DOM
        if (item.nodeType && typeof item.cloneNode === 'function') {
          result = item.cloneNode(true);
        } else if (!item.prototype) {
          // check that this is a literal
          if (item instanceof Date) {
            result = new Date(item);
          } else {
            // it is an object literal
            result = {};
            for (var i in item) {
              result[i] = this.CloneObject(item[i]);
            }
          }
        } else {
          // depending what you would like here,
          // just keep the reference, or create new object
          if (false && item.constructor) {
            // would not advice to do that, reason? Read below
            result = new item.constructor();
          } else {
            result = item;
          }
        }
      } else {
        result = item;
      }
    }
    
    return result;
  }
  
  StringifyObject(obj: any, string: string = ''): string {
    // obj = clone ? _.cloneDeep(_.omit(obj, propertiesToRemove)) : obj;
    
    if (obj) {
      Object.keys(obj)
      .forEach(key => {
        if (obj[key] && typeof obj[key] === 'object') {
          string += this.StringifyObject(obj[key], string);
        } else {
          string += key + (obj[key] ? obj[key].toString() : '');
        }
      });
    }
    return string;
  }
  
  NavigateObjectByPath(path: string[], object: any) {
    if (object) {
      let lookupObject = object;
      path.forEach(p => (lookupObject = lookupObject[p] || lookupObject));
      return lookupObject;
    } else {
      return object;
    }
  }
  
  EmailValid(emailAddress: string) {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailAddress.match(validRegex);
  }
  
  ExistsInObject(object: any, property: string, value = null) {
    return JSON.stringify(object)
    .includes(value ? `${ property }: ${ value.toString() }` : `${ property }:`);
  }
  
  ReadJSONFileToData<T>(files: FileList, acceptedFileTypes: string[] = []): Observable<T> {
    return from(
      new Promise((resolve, reject) => {
        if (files.length > 0) {
          const dataFile = files[0];
          
          if (acceptedFileTypes.length > 0) {
            const fileType = this.GetFileTypeFromFileName(dataFile.name);
            if (!acceptedFileTypes.includes(fileType)) {
              resolve(null);
            }
          }
          
          const reader = new FileReader();
          
          reader.onload = (event: any) => {
            let data = JSON.parse(event.target.result);
            resolve(data);
          };
          
          reader.readAsText(dataFile);
        }
      }),
    ) as Observable<T>;
  }
  
  GetFileTypeFromFileName(fileName: string) {
    return fileName.split('.')[fileName.split('.').length - 1];
  }
  
  ToggleMaterialOverlayBackdrop(condition = null) {
    const overlay = document.querySelector('.cdk-overlay-container');
    
    if (overlay) {
      if (condition === null) {
        overlay.classList.toggle('overlay-backdrop-hidden');
      } else {
        if (condition === true) {
          overlay.classList.remove('overlay-backdrop-hidden');
        } else {
          overlay.classList.add('overlay-backdrop-hidden');
        }
      }
    }
  }
  
  GetHeightAndWidthFromImageFile(imageFile: any): Promise<any> {
    return new Promise(resolve => {
      const fileAsDataURL = window.URL.createObjectURL(imageFile);
      const img = new Image();
      img.onload = () => {
        return resolve({
          height: img.height,
          width: img.width,
          max: img.height > img.width ? img.height : img.width,
        });
      };
      img.src = fileAsDataURL;
    });
  }
  
  ParseGradient(gradient: string) {
    let parse;
    
    const direction = gradient.slice(gradient.indexOf('(') + 1, gradient.indexOf(','));
    const angle = Number(direction);
    
    if (isNaN(angle)) {
      parse = GradientParser.parse(gradient);
    } else {
      let modifiedGradient;
      switch (angle) {
        case 0:
          modifiedGradient = gradient.replace(direction, 'to top');
          break;
        case 90:
          modifiedGradient = gradient.replace(direction, 'to right');
          break;
        case 180:
          modifiedGradient = gradient.replace(direction, 'to bottom');
          break;
        case 270:
          modifiedGradient = gradient.replace(direction, 'to left');
          break;
      }
      parse = GradientParser.parse(modifiedGradient);
    }
    
    return parse;
  }
  
  ReplaceHexWithRGBA(text: string): string {
    const hexRegex = /#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})\b/g;
    
    const replaced = text.replace(hexRegex, match => {
      let color = match.substring(1); // Remove the '#' symbol
      
      // Expand shorthand color format (e.g., #FFF to #FFFFFF)
      if (color.length === 3) {
        color = color.replace(/./g, char => char + char);
      }
      
      // Convert hexadecimal to decimal values
      const red = parseInt(color.substring(0, 2), 16);
      const green = parseInt(color.substring(2, 4), 16);
      const blue = parseInt(color.substring(4, 6), 16);
      
      // Return the RGBA color format
      return `rgba(${ red }, ${ green }, ${ blue }, 1)`;
    });
    
    return replaced;
  }
  
  Unique(array: any[]) {
    return uniq(array);
  }
  
  HashCode(obj: any): number {
    obj = obj || {};
    obj = this.StringifyObject(obj);
    
    let hash = 0;
    for (let i = 0; i < obj.length; i++) {
      const character = obj.charCodeAt(i);
      hash = (hash << 5) - hash + character;
      hash = hash & hash; // Convert to 32bit integer
    }
    return Math.abs(hash);
  }
  
  EncodeBase64(value: string) {
    return btoa(value);
  }
  
  DecodeBase64(value: string) {
    return atob(value);
  }
  
  EncodeUTF8(value: string) {
    var hex, i;
    
    var result = '';
    for (i = 0; i < value.length; i++) {
      hex = value.charCodeAt(i)
      .toString(16);
      result += ('\\x' + hex).slice(-4);
    }
    
    return result;
  }
  
  DecodeUTF8(value: string) {
    const parsedValue = value.replace('\\x', '');
    let str = '';
    for (let i = 0; i < parsedValue.length; i += 2) {
      str += String.fromCharCode(parseInt(parsedValue.substr(i, 2), 16));
    }
    return str;
  }
  
  EncodeUTF16(value: string) {
    let hex = '';
    for (let i = 0; i < value.length; i++) {
      hex += `\\u{${ value.charCodeAt(i)
      .toString(16) }}`;
    }
    return hex;
  }
  
  DecodeUTF16(value: string) {
    const parsedValue = value.replace(/\\u{|}/g, '');
    let str = '';
    for (let i = 0; i < parsedValue.length; i += 2) {
      str += String.fromCharCode(parseInt(parsedValue.substr(i, 2), 16));
    }
    return str;
  }
  
  EncodeHEX(value: string) {
    var hex, i;
    
    var result = '';
    for (i = 0; i < value.length; i++) {
      hex = value.charCodeAt(i)
      .toString(16);
      result += ('000' + hex).slice(-4);
    }
    
    return result;
  }
  
  DecodeHEX(value: string) {
    var j;
    var hexes = value.match(/.{1,4}/g) || [];
    var back = '';
    for (j = 0; j < hexes.length; j++) {
      back += String.fromCharCode(parseInt(hexes[j], 16));
    }
    
    return back;
  }
  
  EncodeASCII(value: string) {
    let ascii = '';
    
    for (let i = 0; i < value.length; i++) {
      const char = value[i];
      ascii += `${ char.charCodeAt(0) } `;
    }
    
    return ascii;
  }
  
  DecodeASCII(value: string) {
    const asciiArray = value.split(' ');
    
    let string = '';
    
    asciiArray.forEach(code => {
      string += String.fromCharCode(+code);
    });
    
    return string;
  }
  
  EncodeHTML(value: string) {
    return Entities.encodeHTML(value);
  }
  
  DecodeHTML(value: string) {
    return Entities.decodeHTML(value);
  }
  
  EncodeXML(value: string) {
    return Entities.encodeXML(value);
  }
  
  DecodeXML(value: string) {
    return Entities.decodeXML(value);
  }
  
  EncodeURI(value: string) {
    return encodeURIComponent(value);
  }
  
  DecodeURI(value: string) {
    return decodeURIComponent(value);
  }
  
  FormatShortcut(shortcuts: string[]): string[] {
    const sequence: string[] = shortcuts as Array<string>;
    for (let i = 0; i < sequence.length; i++) {
      sequence[i] = this.SymbolizeKey(sequence[i]);
    }
    return sequence;
  }
  
  SymbolizeKey(combo: string): string {
    const map: any = {
      command: '\u2318', // ⌘
      shift: '\u21E7', // ⇧
      left: '\u2190', // ←
      right: '\u2192', // →
      up: '\u2191', // ↑
      down: '\u2193', // ↓
      // tslint:disable-next-line:object-literal-key-quotes
      return: '\u23CE', // ⏎
      backspace: '\u232B', // ⌫
    };
    const comboSplit: string[] = combo.split('+');
    
    for (let i = 0; i < comboSplit.length; i++) {
      // try to resolve command / ctrl based on OS:
      if (comboSplit[i] === 'mod' || comboSplit[i] === 'meta') {
        if (window.navigator && window.navigator.platform.indexOf('Mac') >= 0) {
          comboSplit[i] = 'command';
        } else {
          comboSplit[i] = 'ctrl';
        }
      }
      
      comboSplit[i] = map[comboSplit[i]] || comboSplit[i];
    }
    
    return comboSplit.join(' + ');
  }
  
  ApplyOpacityToColor(color: string, opacity: number) {
    const i = Math.round(opacity * 100) / 100;
    const alpha = Math.round(i * 255);
    const opacityHex = (alpha + 0x10000).toString(16)
    .substr(-2);
    
    return `${ color }${ opacityHex }`;
  }
  
  MinifyMangleJsCode(
    code: string,
    mangle = false,
    compress = false,
    mangleIdentifer: string = '',
    mangleReserved: string[] = [],
    debug = false,
  ): Promise<{
    error: boolean;
    result: string;
    replacements: any;
  }> {
    return new Promise((resolve, reject) => {
      try {
        const nameCache = {
          vars: {
            props: {},
          },
        };
        // Minify the input code using Terser with mangling enabled
        const minifiedCode = terser.minify(code, {
          compress: compress,
          nameCache: nameCache,
          format: {
            beautify: !compress,
          },
          mangle: mangle
            ? {
              toplevel: true, // Mangle top-level names// Do not mangle object properties
              keep_fnames: false, // Keep function names
              reserved: mangleReserved, // Exclude reserved names from mangling
              keep_classnames: false, // Keep class names
              safari10: true, // Compatibility with Safari 10
              nth_identifier: {
                get: n => {
                  return `${ mangleIdentifer.length > 0 ? mangleIdentifer + '_' : '' }` + n;
                },
              },
            }
            : false,
        });
        
        minifiedCode.then(
          results => {
            let code = results.code;
            let replacements = {};
            
            if (mangle) {
              const debugReplacements = [];
              
              Object.keys(nameCache.vars.props)
              .forEach(key => {
                replacements[key.slice(1)] = nameCache.vars.props[key];
                debugReplacements.push(`// ${ key }: ${ nameCache.vars.props[key] }`);
              });
              
              if (debug) {
                code = '\n' + debugReplacements.join('\n') + '\n\n' + code;
              }
            }
            
            return resolve({
              error: false,
              result: code,
              replacements: replacements,
            });
          },
          error => {
            return resolve({
              error: true,
              result: error,
              replacements: {},
            });
          },
        );
      } catch (error) {
        return resolve({
          error: true,
          result: 'Error mangling code',
          replacements: {},
        });
      }
    });
  }
  
  SubscriptionTest() {
    return from(
      new Promise((resolve, reject) => {
        return resolve('===SUBSCRIPTION_TEST===');
        // setInterval(() => {
        //   console.log('===SUBSCRIPTION_TEST===');
        // }, 300);
      }),
    );
  }
  
  AddPathValueToObject(path: string, value: string, object = {}, separator = '.') {
    if (path.includes(separator)) {
      const splittedPath = path.split(separator);
      const actualPath = splittedPath.shift();
      let nextObject = null;
      
      if (object[actualPath]) {
        nextObject = object[actualPath];
      } else {
        nextObject = {};
        object[actualPath] = nextObject;
      }
      
      this.AddPathValueToObject(splittedPath.join(separator), value, nextObject);
      return object;
    } else {
      object[path] = value;
      return object;
    }
  }
}
