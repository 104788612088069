import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HttpTransportType, HubConnection, LogLevel } from '@microsoft/signalr';
import { debounce, uniqBy } from 'lodash-es';
import { Subscription } from 'rxjs';
import { EventsService } from '../../core/molecular/services/events.service';
import { ApiAuthService } from '../../core/services/api-auth.service';
import { ApiDataSourcesService } from '../../core/services/api-data-sources.service';
import { CacheService } from '../../core/services/cache.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { TemplateService } from '../../core/services/template.service';
import { ToolsService } from '../../core/services/tools.service';
import { RuntimeService } from '../../running-area/services/runtime.service';
import { SpreadsheetService } from '../../spreadsheet/spreadsheet.service';
import { WorkAreaService } from '../../workarea/workarea.service';
import { HubConnectionDto } from '../dtos/hub-connection-dto';
import { LeapXLEventType } from '../enums/leapxl-event-type.enum';
import { CobbleService } from '../representative-molecule/services/cobble.service';
import { Constants } from './../constants';
import { CommunicationService } from './communication.service';
import { ConnectionStateService } from './connection-state.service';
import { FactoryParticleService } from './factory-particle.service';

@Injectable({
  providedIn: 'root',
})
export class HubConnectionService implements OnDestroy, OnInit {
  public async: any;
  message = '';
  inDebounce = null;
  subscriptions: Subscription;
  public connectionIsEstablished = false;
  isDebug = false;
  userId = null;
  debouncePropertiesUpdated = [];
  debouncePropertyUpdateFunction = null;
  private hubConnection: HubConnection;
  private apiUrl = Constants.Environment.apiUrl + 'messages/';
  
  constructor(
    private spreadSheestService: SpreadsheetService,
    private cobbleService: CobbleService,
    private datasourceService: ApiDataSourcesService,
    private runtimeService: RuntimeService,
    private clientStorageService: ClientStorageService,
    protected http: HttpClient,
    private connectionStateService: ConnectionStateService,
    private cacheService: CacheService,
    private communicationService: CommunicationService,
    private toolsService: ToolsService,
    private workAreaService: WorkAreaService,
    private templateService: TemplateService,
    private authService: ApiAuthService,
    private eventsService: EventsService,
    private localStorageService: LocalStorageService,
    private dialogService: GenericDialogService,
    private factoryParticleService: FactoryParticleService,
  ) {
    // delay to avoid congestion on the server when the page loads
    setTimeout(() => {
      this.initHubConnection();
    }, 2000);
    this.subscriptions = this.communicationService.Event.System.Connection.$StateChange.subscribe(online => {
      console.log('=event='); // console.log('$StateChange');
      if (online) {
        this.startConnectionChecker();
      }
    });
    
    this.isDebug = this.localStorageService.IsDebug();
    
    this.debouncePropertyUpdateFunction = debounce(() => {
      if (this.debouncePropertiesUpdated.length > 0) {
        console.log('changes on queue====');
        const unique = uniqBy(this.debouncePropertiesUpdated, p => {
          return p.data.moleculeId;
        });
        
        this.communicationService.Event.Editor.$AppRefreshPreview.emit(unique);
        this.communicationService.Event.Editor.$AppUpdated.emit(unique);
        this.debouncePropertiesUpdated = [];
      }
    }, 800);
  }
  
  ngOnInit(): void {
  }
  
  initHubConnection() {
    // console.log('init');
    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection();
  }
  
  public CobbleClosed() {
    this.SendMessage(
      {
        cobbleId: this.cobbleService.Cobble.id,
      },
      'cobbleClosed',
    ).subscribe();
  }
  
  public AppOpened() {
    this.SendMessage(
      {
        cobbleId: this.cobbleService.Cobble.id,
      },
      'cobbleOpened',
    ).subscribe();
  }
  
  Throttle(func, delay, context, args) {
    clearTimeout(this.inDebounce);
    this.inDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }
  
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
  
  RequestUsersConnected() {
    this.SendMessage({ requester: this.clientStorageService.getUserId() }, 'reportConnectedUsers').subscribe();
  }
  
  LogOutUser(userId: number) {
    this.SendMessage({ userId }, 'logOutUser').subscribe();
  }
  
  SendUserAlive(sessionId: string) {
    if (sessionId && sessionId !== '') {
      const device = this.toolsService.GetDevice();
      this.SendMessage(
        {
          userId: this.clientStorageService.getUserId(),
          sessionId,
          url: window.location.href,
          device: device.os.name,
          brand: device.device.brand,
          browser: device.client.name,
        },
        'UserAlive',
      ).subscribe();
    }
  }
  
  SendUserLogged(sessionId: string) {
    if (sessionId && sessionId !== '') {
      const device = this.toolsService.GetDevice();
      this.SendMessage(
        {
          userId: this.clientStorageService.getUserId(),
          sessionId,
          url: window.location.href,
          device: device.os.name,
          brand: device.device.brand,
          browser: device.client.name,
        },
        'UserLogged',
      ).subscribe();
    }
  }
  
  SendChatMessage(message: any) {
    this.SendMessage(
      {
        message,
      },
      'ChatMessageReceived',
    ).subscribe();
  }
  
  SendChatMessageRead(message: any) {
    this.SendMessage(message, 'ChatMessageRead').subscribe();
  }
  
  SendChatMessageTyping(message: any) {
    this.SendMessage(message, 'ChatMessageTyping').subscribe();
  }
  
  SendTestSignalR(data = null, type = 'Test') {
    this.SendMessage(data, type).subscribe();
  }
  
  UserLoggedElsewhere(message = 'This user has logged in a different device.') {
    this.authService.logout();
    this.dialogService.OpenConfirmDialog({
      title: 'User logged',
      message: message,
      confirmText: 'Ok',
      cancelText: '',
    });
  }
  
  private startConnectionChecker() {
    setTimeout(() => {
      this.connectionStateService.VerifyConnectionWithServer().subscribe(
        result => {
          // console.log('connection');
          this.startConnection();
        },
        error => {
          // console.log('no connection');
          this.startConnectionChecker();
          this.connectionIsEstablished = false;
          this.connectionStateService.connectionWithServerEstablished = false;
        },
      );
    }, 3500);
  }
  
  private createConnection() {
    this.hubConnection = null;
    this.hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(Constants.Environment.hubConnectionUrl, {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
    })
    .configureLogging(LogLevel.Debug)
    .build();
    this.hubConnection.serverTimeoutInMilliseconds = 120000;
  }
  
  private startConnection(): void {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    if (!this.connectionIsEstablished) {
      this.hubConnection
      .start()
      .then(() => {
        this.connectionIsEstablished = true;
        this.connectionStateService.connectionWithServerEstablished = true;
        const actualDate = new Date();
        console.warn(
          `-------Connection successfully established with LeapXL server through SignalR ${ actualDate.getHours() }:${ actualDate.getMinutes() }:${ actualDate.getSeconds() }-------`,
        );
        this.Throttle(
          (func, delay, context) => {
            this.communicationService.Event.System.Connection.$ConnectionRecovered.emit(true);
          },
          400,
          this,
          null,
        );
      })
      .catch(err => {
        // console.log('Error while establishing connection, retrying...');
        this.startConnectionChecker();
      });
      
      this.hubConnection.onclose(() => {
        // console.log('signalr disconected');
        this.connectionIsEstablished = false;
        this.connectionStateService.connectionWithServerEstablished = false;
        this.Throttle(
          (func, delay, context) => {
            this.startConnectionChecker();
            
            setTimeout(
              () => {
                if (!this.connectionIsEstablished) {
                  this.communicationService.Event.System.Connection.$ConnectionLost.emit(true);
                }
              },
              this.isDebug ? 0 : 10000,
            );
          },
          400,
          this,
          null,
        );
      });
    }
  }
  
  private registerOnServerEvents(): void {
    this.hubConnection.on('Send', (data: HubConnectionDto) => {
      if (this.isDebug) {
        console.warn('Realtime connection Data received:', data);
      }
      this.communicationService.Event.System.HubConnection.$Update.emit(data);
      this.eventHandler(data);
    });
  }
  
  private eventHandler(message: HubConnectionDto) {
    // run all the time
    switch (message.type) {
      case 'TreeModification':
        setTimeout(() => {
          this.communicationService.Event.Editor.DataSource.$ReloadAPIDataSource.emit(true);
        }, 1000);
        break;
    }
    
    // only process if runtimeId valid for app
    if (message.appRuntimeId && message.appRuntimeId !== '' && this.runtimeService.AppRuntimeId() !== message.appRuntimeId && message.type !== 'dataSourceCRUD') {
      return;
    }
    
    switch (message.type) {
      case 'InterimMessage':
        if (message.data && message.data.appRuntimeId && message.data.appRuntimeId === this.runtimeService.AppRuntimeId()) {
          const interimVolunteerEvent = this.factoryParticleService.GenerateEvent(
            LeapXLEventType.InterimVolunteer,
            'System',
            this.cobbleService.Cobble.id,
            message.data.dataElements,
          );
          this.communicationService.Event.Runtime.MolecularEngine.$LeapXLEvent.emit(interimVolunteerEvent);
        }
        break;
      case 'InboundExternalDataSource':
        const inboundExternalDataSourceEvent = this.factoryParticleService.GenerateEvent(
          LeapXLEventType.InboundExternalDatasource,
          'System',
          this.cobbleService.Cobble.id,
          message.data.dataElements,
        );
        this.communicationService.Event.Runtime.MolecularEngine.$LeapXLEvent.emit(inboundExternalDataSourceEvent);
        break;
      
      case 'dataSourceCRUD':
        // runtime
        if (this.cobbleService.Cobble.running) {
          // preview
          if (this.cobbleService.Cobble.preview || this.cobbleService.Cobble.onEmulator) {
            if (!message.productionDataSource) {
              this.communicationService.Event.Runtime.System.$DataSourceCRUDUpdate.emit(message.data);
              this.cacheService.Clear();
            }
          }
          // production
          else {
            if (message.productionDataSource) {
              this.communicationService.Event.Runtime.System.$DataSourceCRUDUpdate.emit(message.data);
              this.cacheService.Clear();
            }
          }
          
          message.data.forEach(datasource => {
            const datasourceChangeEvent = this.factoryParticleService.GenerateEvent(
              LeapXLEventType.DatasourceChange,
              'System',
              this.cobbleService.Cobble.id,
              datasource.collections.map(c => c.dataCells).flat(),
            );
            this.communicationService.Event.Runtime.MolecularEngine.$LeapXLEvent.emit(datasourceChangeEvent);
          });
        }
        // editor
        else {
          if (message.userId !== this.clientStorageService.getUserId()) {
            message.data.forEach(datasource => {
              if (this.spreadSheestService.dataSourcesService.openedDataSourceId === datasource.dataSourceId) {
                this.spreadSheestService.updateOpenedSpreadsheet(datasource);
              }
            });
          }
        }
        
        break;
      
      // not being used actually
      // case 'dataSourceUpdated':
      //   if (
      //     this.spreadSheestService.dataSourcesService.openedDataSourceId ===
      //     message.data.dataSourceId
      //   ) {
      //     this.spreadSheestService.loadExcelDataSourceAndOpenExcelFile(
      //       message.data.dataSourceId
      //     );
      //   }
      //   break;
      
      case 'PropertiesUpdated':
      case 'PropertyUpdated':
      case 'moleculeUpdated':
        // for monitoring tool
        this.communicationService.Event.System.Dev.$UserAppChange.emit(message);
        //
        
        // region omit update under this conditions
        if (
          this.cobbleService.Cobble === null ||
          (this.cobbleService.Cobble.running && !this.cobbleService.Cobble.preview) ||
          (!this.cobbleService.Cobble.running && message.userId === this.clientStorageService.getUserId()) ||
          !this.workAreaService.editorPreferences.collaboratorsUpdates
        ) {
          return;
        }
        
        if (message.type === 'PropertiesUpdated') {
          if (this.cobbleService.Cobble.id !== message.data[0].cobbleId) {
            return;
          }
        } else {
          if (this.cobbleService.Cobble.id !== message.data.cobbleId) {
            return;
          }
        }
        // endregion
        
        message.initials = message.user
        .split(' ')
        .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
        .join('');
        
        if (message.type === 'PropertiesUpdated') {
          message.data.forEach(d => {
            const messageCopy = new HubConnectionDto(message);
            messageCopy.data = d;
            this.debouncePropertiesUpdated.push(messageCopy);
          });
        } else {
          this.debouncePropertiesUpdated.push(message);
        }
        
        this.debouncePropertyUpdateFunction();
        break;
      
      case 'cobbleOpened':
        if (message.userId === this.clientStorageService.getUserId()) {
          return;
        }
        
        message.initials = message.user
        .split(' ')
        .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
        .join('');
        this.communicationService.Event.Editor.$AppOpened.emit(message);
        break;
      
      case 'cobbleClosed':
        if (message.userId === this.clientStorageService.getUserId()) {
          return;
        }
        message.initials = message.user
        .split(' ')
        .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
        .join('');
        this.communicationService.Event.Editor.$AppClosed.emit(message);
        break;
      
      case 'reportConnectedUsers':
        if (this.authService.loggedIn()) {
          this.SendMessage(
            {
              user: this.clientStorageService.getUserInfo(),
              system: this.toolsService.GetSystemInformation(),
            },
            'userConnectedResponse',
          ).subscribe();
        }
        break;
      
      case 'userConnectedResponse':
        this.communicationService.Event.System.Dev.$UserConnected.emit(message.data);
        break;
      
      case 'logOutUser':
        if (message.data.userId === this.clientStorageService.getUserId()) {
          this.authService.logout();
        }
        break;
      
      case 'ChatMessageReceived':
        if (
          message.data.message.chatMessageHistory.sender.id !== this.clientStorageService.getUserId() &&
          message.data.message.receptorId === this.clientStorageService.getUserId()
        ) {
          this.communicationService.Event.System.Chat.$ReceiveMessage.emit(message.data);
        }
        break;
      
      case 'ChatMessageRead':
        if (message.data.senderId !== this.clientStorageService.getUserId() && message.data.receptorId === this.clientStorageService.getUserId()) {
          this.communicationService.Event.System.Chat.$MessagesRead.emit(message.data);
        }
        break;
      
      case 'ChatMessageTyping':
        if (message.data.senderId !== this.clientStorageService.getUserId() && message.data.receptorId === this.clientStorageService.getUserId()) {
          this.communicationService.Event.System.Chat.$MessagesTyping.emit(message.data);
        }
        break;
      
      case 'LeapXLCommunicationsService':
        if (this.cobbleService.Cobble.running) {
          this.eventsService.HandleLeapXLCommunicationsEvents(message.data);
        }
        break;
      
      case 'LibraryUpdated':
        if (!this.toolsService.RunningMode) {
          this.templateService.Libraries = [];
          this.communicationService.Event.Editor.Library.$ReloadLibrary.emit();
        }
        break;
      
      case 'PublishedApp':
        this.communicationService.Event.Runtime.System.$ApplicationPublished.emit(message.data);
        break;
      
      case 'UserLogged':
      case 'UserAlive':
        // console.log(message.userId, this.clientStorageService.getUserId());
        // console.log(this.localStorageService.Get(Constants.SessionId), message.data.sessionId);
        if (
          this.authService.loggedIn() &&
          message.data.userId === this.clientStorageService.getUserId() &&
          message.data.sessionId !== '' &&
          this.localStorageService.Get(Constants.SessionId) !== message.data.sessionId
        ) {
          const userLoggedOnLeapApp = (message.data.url as string).includes('/run/');
          let deviceInfo = '';
          
          if (!this.cobbleService.Cobble.running && !userLoggedOnLeapApp) {
            if (
              message.data.device &&
              message.data.device !== '' &&
              message.data.brand &&
              message.data.brand !== '' &&
              message.data.browser &&
              message.data.browser !== ''
            ) {
              deviceInfo = ` (${ message.data.brand } ${ message.data.device } - ${ message.data.browser })`;
            }
            
            this.UserLoggedElsewhere(`${ message.user } has logged in a different device${ deviceInfo }, this session has been closed. [${ message.data.sessionId }, ${ message.data.hostUrl }], [${ this.localStorageService.Get(Constants.SessionId) }, ${ window.location.href }], ${ message.type }`);
          }
        }
        break;
      
      case 'SnapshotRestoreInProcess':
        if (message.data.applicationId === this.cobbleService.Cobble.id) {
          this.communicationService.Event.Editor.WorkArea.$SnapshotRestoreStart.emit(message.data.applicationId);
        }
        break;
      
      case 'SnapshotRestoreCompleted':
        if (message.data.applicationId === this.cobbleService.Cobble.id) {
          this.communicationService.Event.Editor.WorkArea.$SnapshotRestoreStop.emit(message.data.applicationId);
        }
        break;
      default:
        break;
    }
  }
  
  private SendMessage(data: any, type: string) {
    return this.http.post(this.apiUrl + 'send', {
      data: data,
      type: type,
      url: '',
      icon: '',
      description: '',
    });
  }
}
