import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TreeModel, TreeNode } from 'angular-tree-component';
import { debounce } from 'lodash';
import { Subscription } from 'rxjs';
import { DataElement } from '../../shared/representative-molecule/interfaces/data-element';
import { CommunicationService } from '../../shared/services/communication.service';

@Component({
  selector: 'app-select-nodes',
  templateUrl: './select-nodes.component.html',
  styleUrls: ['./select-nodes.component.scss'],
})
export class SelectNodesComponent implements OnInit, OnDestroy {
  @Input() id: number;
  
  @Input() defaultSelectionNodeNames: string[] = [];
  @Input() subDefaultSelectionNodeNames: string[] = [];
  
  @Input() defaultToAvoidSelectionNodeNames: string[] = [];
  @Input() subDefaultToAvoidSelectionNodeNames: string[] = [];
  
  @Output() treeChange = new EventEmitter();
  selectedNodes: TreeNode[] = [];
  tree: TreeModel;
  options = {
    scrollContainer: <HTMLElement>document.body,
    useCheckbox: true,
  };
  subscription: Subscription;
  nodesToRemove: number[] = [];
  
  emitSelectedNodes = debounce((() => {
    console.log(this.selectedNodes);
    this.treeChange.emit(this.selectedNodes);
  }), 200);
  
  constructor(
    private communicationService: CommunicationService,
  ) {
  }
  
  _nodes = [];
  
  @Input()
  set nodes(value) {
    console.log(value);
    if (value && value.length > 0) {
      if (value[0].data) {
        this._nodes = [...Object.assign([], value.map(v => v.data))];
      } else {
        delete value[0].id;
        this._nodes = [...Object.assign([], value[0].data)];
      }
      
    }
    
  }
  
  ngOnInit(): void {
    this.subscription = this.communicationService.Event.Editor.DataSource.$ApiNodesSelected
    .subscribe(nodes => {
      console.log('nodes selected====', nodes);
      this._nodes = nodes.map(n => n.data);
    });
    
    this.subscription.add(
      this.communicationService.Event.Editor.DataSource.$SelectNode.subscribe(({ dataElement, id }) => {
        if (this.id !== id) {
          return;
        }
        this.SelectNode(dataElement);
      }),
    );
    
  }
  
  InitializeTree(treeModel: TreeModel) {
    this.tree = treeModel;
    this.OpenFirstNode(this.tree);
  }
  
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
  
  TreeChanged(treeModel: TreeModel) {
    this.selectedNodes = [];
    
    treeModel.doForAll((node: TreeNode) => {
      if (node.isSelected && node.isAllSelected) {
        if (node.data.children.length === 0) {
          if (!this.selectedNodes.includes(node)) {
            this.selectedNodes.push(node);
          }
        }
      }
    });
    
    // treeModel.doForAll((node: TreeNode) => {
    //   if (node.isSelected) {
    //     node.data.data.children = [];
    //     this.selectedNodes.push(node);
    //     this.selectedNodes.forEach(selectedNode => this.GetParentNode(selectedNode));
    //
    //     this.nodesToRemove.forEach(nodeToRemove => {
    //       this.selectedNodes = this.selectedNodes.filter(node => node.id !== nodeToRemove);
    //     });
    //     this.nodesToRemove = [];
    //   }
    // });
    this.emitSelectedNodes();
  }
  
  SelectNode(dataElement: DataElement) {
    this.tree.expandedNodeIds = {};
    this.tree.doForAll((node: TreeNode) => {
      
      if (dataElement.Context.includes(node.data.path)) {
        
        if (node.data.name === dataElement.Reference) {
          node.setIsSelected(true);
        }
        
        node.setIsExpanded(true);
        node.expand();
        this.tree.expandedNodeIds[node.id] = true;
      }
      
    });
    
    setTimeout(() => {
      this.tree.update();
    });
  }
  
  GetParentNode(node: TreeNode) {
    for (const key in node) {
      if (key === 'parent') {
        if (this.selectedNodes.includes(node[key])) {
          this.nodesToRemove.push(node[key].id);
        }
        this.GetParentNode(node[key]);
      }
    }
  }
  
  OpenFirstNode(treeModel: TreeModel) {
    setTimeout(() => {
      if (this._nodes?.length > 1) return;
      treeModel.getFirstRoot()
      ?.expand();
      
    }, 200);
    
    if (this.defaultSelectionNodeNames && this.defaultSelectionNodeNames.length > 0) {
      
      setTimeout(() => {
        const root = this.tree.getVisibleRoots()
        .find(n => !!this.defaultSelectionNodeNames.find(d => n.data.name.toLowerCase()
        .includes(d)));
        
        if (!root) return;
        
        root.expand();
        
        const dataNode = this.subDefaultSelectionNodeNames.length > 0 ? root.children.find(
            c => this.subDefaultSelectionNodeNames.includes(c.data.name.toLowerCase())) :
          root.children.find(
            c => !this.subDefaultToAvoidSelectionNodeNames.includes(c.data.name.toLowerCase()));
        
        if (!dataNode) return;
        dataNode.expandAll();
        dataNode.setIsSelected(true);
        
      }, 100);
    } else if (this.defaultToAvoidSelectionNodeNames && this.defaultToAvoidSelectionNodeNames.length > 0) {
      
      setTimeout(() => {
        const root = this.tree.getVisibleRoots()
        .find(n => !this.defaultToAvoidSelectionNodeNames.find(d => n.data.name.toLowerCase()
        .includes(d)));
        
        if (!root) return;
        
        root.expand();
        
        const dataNode = root.children.find(
          c => this.subDefaultSelectionNodeNames.includes(c.data.name.toLowerCase()));
        
        if (!dataNode) return;
        dataNode.expandAll();
        dataNode.setIsSelected(true);
        
      }, 100);
      
    }
    
  }
}
