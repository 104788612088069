import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiPropertiesService } from '../../core/services/api-properties.service';
import { PropertyVersioningDto } from '../../shared/dtos/versioning-dto';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-views-properties',
  templateUrl: './views-properties.component.html',
  styleUrls: ['./views-properties.component.scss'],
})
export class ViewsPropertiesComponent implements OnInit {
  @ViewChild('input', { static: true })
  input: ElementRef;
  viewName: string;
  
  constructor(
    private workareaService: WorkAreaService,
    private cobbleService: CobbleService,
    private propertiesService: ApiPropertiesService,
    private communicationService: CommunicationService,
  ) {
  }
  
  ngOnInit() {
    this.viewName = this.cobbleService.Cobble.properties.views.find(v => v.id === this.workareaService.viewFocused).name;
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 200);
  }
  
  SaveView() {
    this.cobbleService.Cobble.properties.views.find(v => v.id === this.workareaService.viewFocused).name = this.viewName;
    
    this.propertiesService
    .SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: 'views',
        value: this.cobbleService.Cobble.properties.views,
        path: 'properties',
        cobbleId: this.cobbleService.Cobble.id.toString(),
        name: this.viewName,
      }),
    )
    .subscribe();
    
    this.communicationService.Event.Editor.Views.$ViewsPanelChange.emit(this.workareaService.viewFocused);
    
    this.workareaService.draggableWindow[this.workareaService.draggableWindow.length - 1].Hide();
  }
}
