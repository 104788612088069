import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class GetWorkgroupElementsMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(particleId: string, repMoleculeId: string, busProcessorKey: string, rule: any, logic: any, data: any) {
    try {
      data = data === undefined || data === null || data === {} ? '' : data;
      
      const molecule = this.busService.Get(repMoleculeId);
      
      const workgroup = data.workgroupSelectedId ? this.busService.Get(data.workgroupSelectedId) : this.busService.Get(molecule.ParentId.toString());
      
      const elements = this.busService.DirectChildrenElements(workgroup.Id);
      // console.log(`molecule working, getting elements`);
      
      data.elements = elements;
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
