import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { WjGridModule } from '@grapecity/wijmo.angular2.grid';
import { WjGridSheetModule } from '@grapecity/wijmo.angular2.grid.sheet';
import { WjInputModule } from '@grapecity/wijmo.angular2.input';
import { DndModule } from 'ng2-dnd';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from '../shared/material/material.module';
import { SharedModule } from './../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    MaterialModule,
    DndModule,
    FormsModule,
    WjGridSheetModule,
    WjInputModule,
    WjGridModule,
    NgxPaginationModule,
    SharedModule,
  ],
  exports: [AppRoutingModule],
  declarations: [],
  providers: [],
})
export class CoreModule {
}
