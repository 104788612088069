import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import IMask from 'imask';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BuilderService } from '../../../../core/builder/builder.service';
import { ProcessorService } from '../../../../core/molecular/services/processor.service';
import { WorkAreaService } from '../../../../workarea/workarea.service';
import { RepresentativeMoleculesType } from '../../../enums/representative-molecules-types.enum';
import { DragService } from '../../services/drag.service';
import { BaseMoleculeComponent } from '../base-molecule/base-molecule.component';

@Component({
  selector: 'app-textbox-molecule',
  templateUrl: './textbox-molecule.component.html',
  styleUrls: ['./textbox-molecule.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TextboxMoleculeComponent extends BaseMoleculeComponent implements OnInit, OnDestroy {
  @ViewChild('textboxWrapper', { static: true })
  textboxWrapper: ElementRef;
  
  MoleculeType = 'Representative';
  Type = RepresentativeMoleculesType.Textbox;
  
  mask = null;
  inputEl = null;
  inputValue = '';
  debounceTime = 250; // milliseconds
  public inputChange = new Subject<any>();
  
  constructor(
    public builderService: BuilderService,
    public dragService: DragService,
    public workAreaService: WorkAreaService,
    public processorService: ProcessorService,
    bottomSheet: MatBottomSheet,
    public el: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
    super(bottomSheet, el, changeDetectorRef);
  }
  
  get TextboxValue() {
    return this.inputValue;
  }
  
  set TextboxValue(data: string) {
    setTimeout(() => {
      this.inputValue = data;
      this.context.Value = this.IsMasked() ? this.mask.unmaskedValue : data;
      this.inputChange.next();
    }, 15);
  }
  
  ngOnInit() {
    super.ngOnInit();
    if (!this.context.RunningMode) {
      this.DetachChangeDetection();
    }
    
    this.context.Type = RepresentativeMoleculesType.Textbox;
    this.RefreshGridsterConfiguration();
    const keyupDebounce = this.context.Debounce.events.find(e => e.name === 'keyup');
    this.inputChange
    .pipe(
      debounceTime(keyupDebounce ? keyupDebounce.time : this.debounceTime),
      // flatMap((search) => of(search).pipe(delay(50))),
    )
    .subscribe(() => {
      this.FireKeyEvent(null);
    });
    
    this.inputEl = document.querySelector(`#gridsterItem-${ this.context.Id } input`) as any;
    
    if (this.HasMasking()) {
      this.mask = IMask(this.inputEl, this.GetMaskOptions());
    }
    
    this.UpdateData();
  }
  
  AttachEditorEventListeners() {
    const dragoverEventListener = this.renderer.listen(this.textboxWrapper.nativeElement, 'dragover',
      (evt) => {
        this.drag(evt, true);
      });
    const dragleaveEventListener = this.renderer.listen(this.textboxWrapper.nativeElement, 'dragover',
      (evt) => {
        this.drag(evt, false);
      });
    const dropEventListener = this.renderer.listen(this.textboxWrapper.nativeElement, 'drop', (evt) => {
      this.DataDropped(evt);
    });
  }
  
  AttachRunningEventListeners() {
    const clickEventListener = this.renderer.listen(this.textboxWrapper.nativeElement, 'click', (evt) => {
      this.FireClickEvent(evt);
    });
  }
  
  FireClickEvent(e: any) {
    this.FireRepresentativeMoleculeEvent('click', null, true);
  }
  
  FireKeyEvent(e: any) {
    this.FireRepresentativeMoleculeEvent('keyup', this.context.Value, true);
    this.FireRepresentativeMoleculeEvent('keyup-masked',
      this.IsMasked() ? IMask.createMask(this.GetMaskOptions())
      .resolve(this.context.Value) : this.context.Value, true);
  }
  
  FirePasteEvent(e: any) {
    setTimeout(() => {
      this.FireRepresentativeMoleculeEvent('keyup', this.context.Value, true);
    }, 25);
  }
  
  IsMasked(): boolean {
    return this.HasMasking() && this.mask;
  }
  
  UpdateData(): void {
    if (this.HasMasking() && this.context.Value) {
      var mask = IMask.createMask(this.GetMaskOptions());
      this.inputValue = mask.resolve(this.context.Value);
    } else {
      this.inputValue = this.context.Value;
    }
  }
}
