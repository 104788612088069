import { Component, OnInit, ViewChild } from '@angular/core';
import * as wjcNav from '@grapecity/wijmo.nav';
import { ApiAdminService } from '../../core/services/api-admin.service';
import { ApiAuthService } from '../../core/services/api-auth.service';
import { ApiHierarchyService } from '../../core/services/api-hierarchy.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { CobbleUser } from '../../shared/models/cobble-user.model';
import { HierarchyLevel } from '../../shared/models/hierarchylevel.model';
import { LicenseRole } from '../../shared/models/licenserole.model';
import { SnackerService } from '../../shared/services/snacker.service';
import { WelcomeService } from '../welcome.service';

@Component({
  selector: 'app-hierarchy-users',
  templateUrl: './hierarchy-users.component.html',
})
export class HierarchyUsersComponent implements OnInit {
  dataSources: HierarchyLevel[];
  leftAreaSize = 30;
  contentAreaSize = 70;
  users: CobbleUser[];
  user: CobbleUser;
  licenseRoles: LicenseRole[];
  companyId: number;

  @ViewChild('dataSourcesTree', { static: true })
  dataSourcesTree: wjcNav.TreeView;

  constructor(
    private hierarchyService: ApiHierarchyService,
    private authService: ApiAuthService,
    private welcomeService: WelcomeService,
    private adminService: ApiAdminService,
    private snackerService: SnackerService,
    private clientStorageService: ClientStorageService
  ) {
    this.companyId = this.clientStorageService.getCompanyId();
    this.user = new CobbleUser();
    this.user.companyId = this.companyId;
    this.dataSources = [];
    this.licenseRoles = [];
    this.users = [];
  }

  ngOnInit() {
    this.hierarchyService.treeChanged.subscribe(tree => {
      this.dataSources = [];
      this.dataSources.push(tree);
      this.dataSourcesTree.loadTree();
    });
    this.getLicenseRoles();
    this.adminService.getUsersForCompany(+this.companyId).subscribe(users => {
      this.hierarchyService.setUsersList(users);
    });
    this.hierarchyService.users$.subscribe(users => {
      this.users = users;
    });
  }

  addToUser() {
    if (this.user.id) {
      const selectedItem = this.dataSourcesTree.selectedItem as HierarchyLevel;
      if (selectedItem.canBeAttached) {
        const exists = selectedItem.users.indexOf(this.user.id);
        if (exists === -1) {
          if (!this.welcomeService.getDoneStepAccess()) {
            this.welcomeService.setDoneStepAccess(true);
          }
          this.user.companyNodes.push(selectedItem);
          this.hierarchyService.setUsersList(this.users);
          selectedItem.users.push(this.user.id);
          this.hierarchyService.saveElement(selectedItem).subscribe(() => {
            this.snackerService.ShowMessageOnBottom('User added to ' + selectedItem.name, 'group_add', null, true);
          });
        } else {
          this.snackerService.ShowMessageOnBottom('The User is already attached to ' + selectedItem.name);
        }
      }
    }
  }

  register() {
    this.authService.registerCobbleUser(this.user).subscribe(result => {
      if (result) {
        this.adminService.getRolesAvailables(this.companyId).subscribe(roles => {
          this.licenseRoles = roles;
          this.user.id = result;
          this.users.push(this.user);
          this.hierarchyService.setUsersList(this.users);
          this.snackerService.ShowMessageOnBottom(`${this.user.email} registered!`, 'check_circle', null, true);
        });
      }
    });
  }

  registerNew() {
    this.user = new CobbleUser();
    this.user.companyId = this.companyId;
  }

  getLicenseRoles(): void {
    this.adminService.getRolesAvailables(this.companyId).subscribe(roles => {
      this.licenseRoles = roles;
    });
  }
}
