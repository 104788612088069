import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, flatMap, map } from 'rxjs/operators';
import { ApiCompaniesService } from '../core/services/api-companies.service';
import { ApiMoleculesService } from '../core/services/api-molecules.service';
import { ClientStorageService } from '../core/services/client-storage.service';
import { EditorStateService } from '../core/services/editor-state.service';
import { LocalStorageService } from '../core/services/local-storage.service';
import { ToolsService } from '../core/services/tools.service';
import { UserMenuService } from '../core/services/user-menu.service';
import { CobbleService } from '../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../shared/services/communication.service';
import { ConnectionStateService } from '../shared/services/connection-state.service';
import { DraggableWindowService, DraggableWindowType } from '../shared/services/draggable-window.service';
import { SnackerService } from '../shared/services/snacker.service';
import { SystemService } from '../shared/services/system.service';
import { WorkAreaService } from '../workarea/workarea.service';

@Component({
  selector: 'cbb-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [style({ opacity: '0' }), animate('.7s ease-out', style({ opacity: '1' }))]),
      transition(':leave', [style({ opacity: '1' }), animate('.2s ease-out', style({ opacity: '0' }))]),
    ]),
  ],
})
export class DashboardComponent implements OnInit, OnDestroy {
  cacheMyCobbles: any[] = [];
  cacheCompaniesCobbles: any[] = [];
  cacheSharedCobbles: any[] = [];
  sharedCobblesLoading = false;
  userobblesLoading = false;
  companiesCobblesLoading = false;
  filterValue = '';
  
  userCompany = 'My Apps';
  myCobbles: any[] = [];
  myFavApps: any[] = [];
  companiesCobbles: any[] = [];
  sharedCobbles: any[] = [];
  userRoleId: number;
  
  public cobbleSearch = new Subject<any>();
  isRouteWorkarea = false;
  dashboardStyle = 'compact';
  showCompanyApps = false;
  isDebug = false;
  subscriptions: Subscription;
  
  constructor(
    private route: ActivatedRoute,
    public clientStorageService: ClientStorageService,
    private titleService: Title,
    public menuService: UserMenuService,
    private companiesService: ApiCompaniesService,
    private moleculesService: ApiMoleculesService,
    private cobbleService: CobbleService,
    private ref: ChangeDetectorRef,
    private connectionStateService: ConnectionStateService,
    public toolsService: ToolsService,
    private draggableWindowService: DraggableWindowService,
    private communicationService: CommunicationService,
    private workAreaService: WorkAreaService,
    private systemService: SystemService,
    private editorStateService: EditorStateService,
    private snackerService: SnackerService,
    private localStorageService: LocalStorageService,
  ) {
    workAreaService.editorPreferences.compactToolBarPosition = 'top';
    this.isDebug = this.localStorageService.IsDebug();
    const userPreferences = systemService.GetUserPreferences();
    
    
    this.dashboardStyle = userPreferences.dashboardStyle;
    this.userCompany = clientStorageService.getCompanyName();
    this.cobbleService.ClearCobbleSession();
    
    this.updateCobbles();
    
    this.cobbleSearch
    .pipe(
      map((event) => event.target.value),
      debounceTime(150),
      flatMap((search) => of(search).pipe(delay(50))),
    )
    .subscribe((value) => {
      this.filterValue = value;
      this.filterCobbles(value);
    });
    
    this.UpdateFavoriteApps();
    
  }
  
  UpdateFavoriteApps() {
    const userPreferences = this.systemService.GetUserPreferences();
    const favApps = userPreferences.favApps ? JSON.parse(userPreferences.favApps) : [];
    const favoriteAppIds = favApps.map(a => a.id);
    
    if (favoriteAppIds.length > 0) {
      this.moleculesService.GetApplicationsBasicInfo(favoriteAppIds).subscribe(appsInfo => {
        const updatedFavApps = [];
        
        appsInfo.forEach(appInfo => {
          const app = favApps.find(a => a.id === appInfo.id);
          app.name = appInfo.name;
          app.published = appInfo.hasBeenPublished;
          app.isRunnable = appInfo.isRunnable;
          updatedFavApps.push(app);
        });
        this.myFavApps = updatedFavApps;
      });
    }
  }
  
  ngOnInit() {
    this.subscriptions = this.communicationService.Event.System.Update.$UpdateDashboardApps.subscribe((newAppAdded) => {
      console.log('=event=');
      // console.log('$UpdateDashboardApps');
      this.updateCobbles(newAppAdded);
      if (this.showCompanyApps) {
        this.LoadCompaniesApps();
      }
      
      this.UpdateFavoriteApps();
    });
    
    this.subscriptions.add(
      this.communicationService.Event.System.App.$RemoveAppFromFavorites.subscribe(cobble => {
        this.RemoveFromFavorites(cobble);
      }),
    );
    
    this.route.url.subscribe((result) => {
      result.length > 0 ? (this.isRouteWorkarea = false) : (this.isRouteWorkarea = true);
    });
    this.route.data.subscribe((routeData) => {
      this.titleService.setTitle(routeData.title);
    });
    this.userRoleId = this.clientStorageService.getUserRoleId();
    this.workAreaService.HideDraggableWindows();
    if (this.workAreaService.smartTemplateWindow) {
      this.workAreaService.smartTemplateWindow.Hide();
    }
  }
  
  updateCobbles(newAppAdded = false) {
    if (this.myCobbles.length === 0) {
      this.userobblesLoading = true;
      this.sharedCobblesLoading = true;
    }
    
    this.moleculesService.GetUserApplications().subscribe((cobbles: any[]) => {
      this.userobblesLoading = false;
      
      if (newAppAdded && this.myCobbles.length > 0) {
        const apps = cobbles.filter((c) => c.isApplication);
        const appIds = this.cacheMyCobbles.map(mc => mc.id);
        const newAppIds = apps.filter(c => !appIds.includes(c.id)).map(na => na.id);
        cobbles.forEach(app => {
          if (newAppIds.includes(app.id)) {
            app.added = true;
          }
        });
      }
      
      this.myCobbles = cobbles.filter((c) => c.isApplication);
      this.cacheMyCobbles = cobbles.filter((c) => c.isApplication);
      
      this.myFavApps.forEach((app) => {
        const ogApp = this.cacheMyCobbles.find((mc) => mc.id === app.id);
        if (ogApp) {
          app.name = ogApp.name;
        }
      });
      this.ref.markForCheck();
      this.moleculesService.GetSharedApplications().subscribe((sharedApps: any[]) => {
        this.sharedCobblesLoading = false;
        this.sharedCobbles = [];
        this.cacheSharedCobbles = [];
        this.sharedCobbles = sharedApps;
        
        this.cacheSharedCobbles = sharedApps;
        
        this.myFavApps.forEach((app) => {
          const ogApp = this.cacheSharedCobbles.find((mc) => mc.id === app.id);
          if (ogApp) {
            app.name = ogApp.name;
          }
        });
        
        this.ref.markForCheck();
      });
      
      if (newAppAdded) {
        setTimeout(() => {
          const newAppElement = document.querySelector(`.pulse`);
          if (newAppElement) {
            newAppElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }, 200);
      }
    });
  }
  
  filterCobbles(name: string) {
    if (name === '') {
      this.myCobbles = this.cacheMyCobbles;
      this.sharedCobbles = this.cacheSharedCobbles;
      this.companiesCobbles = this.cacheCompaniesCobbles;
    } else {
      name = name.toLowerCase().replace(' ', '');
      this.myCobbles = this.cacheMyCobbles.filter((c) => c.name.toLowerCase().replace(' ', '').search(name) > -1);
      // console.log(this.cacheSharedCobbles);
      
      this.sharedCobbles = this.cacheSharedCobbles;
      
      const filterCobbleCompanies = [];
      this.cacheCompaniesCobbles.forEach((company) => {
        const filteredCobbles = company.cobbles.filter((c) => c.name.toLowerCase().replace(' ', '').search(name) > -1);
        if (filteredCobbles.length > 0) {
          filterCobbleCompanies.push({
            companyName: company.companyName,
            cobbles: filteredCobbles,
          });
        }
      });
      
      this.companiesCobbles = filterCobbleCompanies;
      
      const filterCobbleShared = [];
      
      this.sharedCobbles.forEach((user) => {
        const filteredCobbles = user.cobbles.filter((c) => c.name.toLowerCase().replace(' ', '').search(name) > -1);
        if (filteredCobbles.length > 0) {
          filterCobbleShared.push({
            userName: user.userName,
            cobbles: filteredCobbles,
          });
        }
      });
      
      this.sharedCobbles = filterCobbleShared;
    }
    this.ref.markForCheck();
  }
  
  public TrackById(index, item) {
    if (!item) {
      return null;
    }
    return item.Id;
  }
  
  public TrackByCompanyId(index, item) {
    if (!item) {
      return null;
    }
    return item.companyId;
  }
  
  public TrackByUserId(index, item) {
    if (!item) {
      return null;
    }
    return item.userId;
  }
  
  ShowCobbleDetailsPanel(name: string, id: number, event: MouseEvent) {
    const dragWindow = this.draggableWindowService.OpenUniqueDraggableWindow(
      `${ name } Details`,
      DraggableWindowType.AppDetails,
      this.workAreaService.appDetailsWindow,
      event,
      { id: id },
    );
    
    this.workAreaService.appDetailsWindow = dragWindow;
  }
  
  FilterByApplication(cobbles): any[] {
    return cobbles.filter((c) => c.isApplication);
  }
  
  ChangeDashboardStyle(dashboardStyle: string) {
    this.dashboardStyle = dashboardStyle;
    this.systemService.SaveUserPreference('dashboardStyle', dashboardStyle);
  }
  
  LoadCompaniesApps() {
    this.showCompanyApps = true;
    this.companiesCobblesLoading = true;
    
    this.companiesService.GetCobblesByCompanies().subscribe((companies: any[]) => {
      this.companiesCobblesLoading = false;
      this.companiesCobbles = [];
      this.cacheCompaniesCobbles = [];
      this.companiesCobbles = companies;
      this.cacheCompaniesCobbles = companies;
      this.ref.markForCheck();
      
      if (this.filterValue !== '') {
        this.filterCobbles(this.filterValue);
      }
    });
  }
  
  AddToFavorites(app: any) {
    if (this.myFavApps.length >= 10) {
      this.snackerService.ShowMessageOnBottom('10 favorite Apps limit');
      return;
    }
    
    if (!this.myFavApps.includes(app)) {
      this.myFavApps.push(app);
      this.systemService.SaveUserPreference('favApps', JSON.stringify(this.myFavApps));
    } else {
      this.snackerService.ShowMessageOnBottom('App already added');
    }
  }
  
  RunPublishedApp(app: any) {
    window.open(`${ this.toolsService.GetDomain() }/run/${ app.companySlug }/${ app.slug }`, '_blank');
  }
  
  RunPreviewApp(app: any) {
    window.open(
      `${ this.toolsService.GetDomain() }/run/preview/${ app.companySlug }/${ app.slug }/${ this.toolsService.currentTheme }`,
      '_blank',
      'noopener',
    );
  }
  
  RemoveFromFavorites(app: any) {
    this.myFavApps = this.myFavApps.filter((a) => a.id !== app.id);
    this.systemService.SaveUserPreference('favApps', JSON.stringify(this.myFavApps));
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
