import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExternalLoginComponent } from './auth/externalLogin/externalLogin.component';
import { ForgotPasswordComponent } from './auth/forgotPassword/forgotPassword.component';
import { HomeComponent } from './auth/home/home.component';
import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { RegisterComponent } from './auth/register/register.component';
import { SetPasswordComponent } from './auth/setPassword/setPassword.component';
import { CommitCreatorComponent } from './commit-creator/commit-creator.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DebugToggleComponent } from './debug-toggle/debug-toggle.component';
import { AuthGuard } from './guards/auth.guard';
import { UnsavedDataGuard } from './guards/unsaved-data.guard';
import { InitDbComponent } from './init-db/init-db.component';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { TestComponent } from './test/test.component';

const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: { animationState: 'home' },
    canActivate: [UnsavedDataGuard],
    pathMatch: 'full',
  },
  // { path: 'login', component: LoginComponent, data: { state: 'login',
  //     animationState: 'login' } },
  {
    path: 'login',
    canActivate: [UnsavedDataGuard],
    children: [
      { path: '', component: LoginComponent, pathMatch: 'full' },
      {
        path: 'sso',
        component: LoginComponent,
      },
      {
        path: 'external/:type',
        component: ExternalLoginComponent,
      },
    ],
    data: {
      state: 'login',
      animationState: 'login',
    },
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: {
      state: 'logout',
      animationState: 'logout',
    },
  },
  {
    path: 'dev',
    component: MonitoringComponent,
    data: {
      state: 'dev',
      animationState: 'dev',
    },
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      state: 'register',
      animationState: 'register',
    },
  },
  { path: 'initdb', component: InitDbComponent, data: { state: 'initdb' } },
  { path: 'cc', component: CommitCreatorComponent, data: { state: 'cc', animationState: 'cc' } },
  { path: 'debug', component: DebugToggleComponent, data: { state: 'debug' } },
  { path: 'test', component: TestComponent, data: { state: 'test' } },
  {
    path: 'dashboard',
    canActivate: [AuthGuard, UnsavedDataGuard],
    component: DashboardComponent,
    data: {
      title: 'Leap | Dashboard',
      invokeSuscriptions: true,
      state: 'dashboard',
      animationState: 'dashboard',
    },
  },
  {
    path: 'workarea',
    canActivate: [AuthGuard, UnsavedDataGuard],
    loadChildren: () => import('./workarea/workarea.module').then((m) => m.WorkareaModule),
    data: { animationState: 'workarea' },
  },
  // {
  //   path: 'welcome',
  //   loadChildren: './welcome/welcome.module#WelcomeModule'
  // },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard, UnsavedDataGuard],
    data: { animationState: 'admin' },
  },
  {
    path: 'run',
    loadChildren: () => import('./running-area/running-area.module').then((m) => m.RunningAreaModule),
    data: { animationState: 'run' },
  },
  // { path: 'login', loadChildren: './auth/auth.module#AuthModule',
  //   data: { animationState: 'login' }
  //   },
  { path: 'setpassword', component: SetPasswordComponent, data: { animationState: 'login' } },
  { path: 'recoverpassword', component: ForgotPasswordComponent, data: { animationState: 'login' } },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { enableTracing: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
