import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';
import { Constants } from './../../../shared/constants';

export class OSNotificationMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    data: any,
    dataElementsData: any,
  ) {
    try {
      let message = (rule && rule.message && rule.message !== '') ? rule.message : this.toolsService.ExtractValuesByType(data).string || Constants.Defaults.DataItemValue;
      message = dataElementsData ? this.toolsService.ExtractValuesByType(dataElementsData).string : message;
      
      if (message !== '') {
        
        this.browserNotificationsService.generateNotification([
            {
              alertContent: message,
            },
          ],
          this.cobbleService.Cobble.properties.name);
        
        // console.log(`molecule working, notification`);
      }
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
