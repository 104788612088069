import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class ParseMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    dataBus: any,
    dataElementsData: any,
  ) {
    try {
      dataBus = dataBus || '';
      
      console.log(dataElementsData);
      const parser = rule.parser || '';
      const colBased = rule.col || false;
      
      if (this.ExistsRepMoleculeAttached) {
        dataBus = this.DataActionModifierApply(dataBus, this.Modifier, { parser, colBased });
      } else if (dataElementsData) {
        
        dataElementsData.forEach(de => {
          dataBus = this.DataActionModifierApply(dataBus, this.Modifier, { parser, colBased });
        });
      } else {
        dataBus = this.DataActionModifierApply(dataBus, this.Modifier, { parser, colBased });
      }
      
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  private Modifier(value: any, data: { parser: any, colBased: boolean }): string {
    const joiner = '##!LEAP_JOINER!##';
    const type = this.toolsService.GetObjectType(value);
    if (type === 'string') {
      
      const parsers = data.parser.split('|');
      let parseValue = value;
      
      parsers.forEach(parser => {
        switch (parser) {
          case '\\n':
            parser = /\r?\n/;
            break;
        }
        
        parseValue = (parseValue as any).split(parser);
        parseValue = parseValue.join(joiner);
      });
      
      
      value = parseValue.split(joiner).map((v, index) => {
          return {
            col: data.colBased === true ? index : 0,
            row: data.colBased === true ? 0 : index,
            value: v,
            formattedValue: v,
            context: '',
          };
        },
      );
    }
    return value;
  }
}
