import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';
import { Constants } from './../../../shared/constants';

export class MaxLengthMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    dataBus: any,
    dataElementsData: any,
  ) {
    try {
      
      let length = rule.length || Constants.Defaults.MaxStringLength;
      
      if (dataElementsData) {
        length = this.toolsService.ExtractValuesByType(dataElementsData).number || length;
      }
      
      dataBus = this.DataActionModifierApply(dataBus, this.Modifier, { length, repMoleculeId });
      
      
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    }
  }
  
  public AfterRemove(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  private Modifier(value: string, data: { length: number, repMoleculeId: number }): string {
    const type = this.toolsService.GetObjectType(value);
    if (type === 'string') {
      if (data.length < value.length) {
        const molecule = this.busService.Get(data.repMoleculeId.toString());
        molecule.Disallow = true;
        setTimeout(() => {
          molecule.Disallow = false;
        }, 300);
        
        value = value.substring(
          0,
          value.length - (value.length - data.length),
        );
      }
    }
    return value;
  }
}
