import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToolsService } from '../../../core/services/tools.service';
import { Constants } from '../../../shared/constants';
import { IDatasourceAudit } from '../../../shared/interfaces/datasource-audit.interface';
import { DraggableWindowService } from '../../../shared/services/draggable-window.service';
import { ApiDataQualityDataService } from '../api-data-quality-data.service';

@Component({
  selector: 'app-data-audit',
  templateUrl: './data-audit.component.html',
  styleUrls: ['./data-audit.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DataAuditComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  Constants = Constants;
  contexts = [];
  dataAudits: IDatasourceAudit[] = [];
  tableData: MatTableDataSource<IDatasourceAudit>;
  expandedauditLog: IDatasourceAudit | null;
  loading = false;
  columns = [
    'date',
    'who',
    'origin',
    'change',
    'from',
    'app',
    'appVersion',
    'view',
    'form',
    'element',
    'dataElement',
    'value',
    'previewsValue',
    'newFormula',
    'previousFormula',
    'leapxlVersion',
    'accessPoint',
    'url',
    'browser',
    'platform',
  ];
  maxHeight = `${ window.innerHeight - 400 }px`;
  
  constructor(private dataQualityService: ApiDataQualityDataService,
              private draggableWindowService: DraggableWindowService,
              private toolsService: ToolsService) {
    this.contexts = this.draggableWindowService.GetData().contexts;
  }
  
  ngOnInit() {
    this.loading = true;
    this.dataQualityService.GetDataSourceAuditChanges(this.contexts).subscribe(result => {
      this.loading = false;
      this.dataAudits = result;
      this.tableData = new MatTableDataSource(this.dataAudits);
      
      setTimeout(() => {
        this.tableData.paginator = this.paginator;
        this.tableData.sort = this.sort;
      }, 200);
    });
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableData.filter = filterValue.trim().toLowerCase();
    
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  
  DownloadAudit() {
    const spplitedContext = this.contexts[0].split(Constants.ContextSeparator);
    const filename = `${ spplitedContext[spplitedContext.length - 1] } - Audit`;
    
    const dataToExport = this.dataAudits.map(da => ({
      date: da.date,
      who: da.dataAuditLog.user,
      origin: da.dataAuditLog.origin,
      change: da.dataAuditLog.modificationType,
      from: da.dataAuditLog.entryType,
      application: da.dataAuditLog.applicationName,
      applicationVersion: da.dataAuditLog.applicationVersion,
      screen: da.view,
      form: da.form,
      element: da.source,
      dataElement: da.context.split(Constants.ContextSeparator)[da.context.split(Constants.ContextSeparator).length - 1],
      newValue: da.formattedValue,
      oldValue: da.previousValue,
      newFormula: da.formula,
      previousFormula: da.formula,
      accessPoint: da.dataAuditLog.accessPoint,
      leapxlVersion: da.dataAuditLog.leapXLVersion,
      url: da.dataAuditLog.url,
      browser: da.dataAuditLog.browser,
      platform: da.dataAuditLog.platform,
    }));
    
    this.toolsService.ExportArrayToExcel(dataToExport, filename);
  }
  
}
