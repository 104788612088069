export const badgeTemplate = [
  {
    icon: 'circle_notifications',
    moleculeType: 'Representative',
    name: 'Badge',
    type: 'Badge',
    moleculesAllow: ['Representative', 'DataAction', 'Behavior', 'Configuration', 'Compounds'],
    dataSource: {},
    styleMetadata: {
      styles: [],
      specificStyles: {
        dimension: null,
        font: null,
        hover: null,
        frame: null,
        appearance: null,
      },
      manualAdjustedProperties: [],
    },
    creationFromTemplate: false,
    receptors: ['value-input', 'value-output', 'icon-input', 'icon-output', 'tooltip-input', 'none'],
    events: ['click', 'init', 'hover'],
    defaultEvent: 'click',
    editableProperties: ['textToDisplay', 'cols', 'rows', 'x', 'y', 'hover', 'tabindex', 'font', 'opacity',
      'shadow', 'background', 'borders', 'backgroundType'],
    properties: {
      align: 'left', horizontalPadding: 0,
      verticalPadding: 0,
      wrapText: false,
      allowManualEntry: false,
      alwaysRenderComponent: false,
      location: null,
      hover: {
        hoverBackground: 'white',
        hoverBackgroundOpacity: 1,
        hoverBorderRadius: 5,
        hoverBorderStyle: 'none',
        hoverBorderColor: 'transparent',
        hoverBorderWidth: 1,
        hoverFontColor: 'black',
      },
      bordersValues: {
        borderRadius: 500,
        borderWidth: 4,
        borderStyle: 'solid',
        borderColor: 'white',
      },
      options: [
        {
          value: 'value 1',
          text: 'Text 1',
        },
      ],
      show: true,
      opacity: 1,
      orientation: 'col',
      enable: true,
      altText: '',
      badge: {
        badgeEnable: false,
        badgeBackgroundColor: '#f22525',
        badgeFontFamily: 'Source Sans Pro',
        badgeFontColor: 'white',
        badgeStyle: 'circle',
        badgePosition: 'right',
        badgeAnimate: 'pulse-badge',
        badgeIcon: true,
      },
      dateRange: false,
      search: false,
      multiSelect: false,
      autoDataRefresh: false,
      ignoreValueDataIndex: false,
      textDecoration: 'none',
      allowEmptyValues: false,
      viewNames: [],
      
      responsive: {
        desktop: {
          customGuidelines: {
            x: [],
            y: [],
          },
          id: 0,
          cols: 27,
          rows: 12,
          colsQty: 0,
          rowsQty: 0,
          layer: 1,
          centerPositioning: false,
          x: 0,
          y: 0,
          font: {
            fontSize: 18,
            fontColor: 'white',
            fontFamily: 'Source Sans Pro',
            fontStyle: 'inherit',
            fontWeight: 'bold',
          },
          tableOptions: {
            collapse: true,
            bodyRoundCorners: false,
            search: true,
            header: true,
            verticallyCenter: true,
            rowSeparator: false,
            columnSeparator: false,
            rowPadding: true,
            tableOrder: [],
            tableWidth: {},
          },
        },
        smartphone: {
          customGuidelines: {
            x: [],
            y: [],
          },
          id: null,
          cols: null,
          rows: null,
          colsQty: null,
          rowsQty: null,
          layer: null,
          centerPositioning: false,
          x: null,
          y: null,
          font: {
            fontSize: null,
            fontColor: null,
            fontFamily: null,
            fontStyle: null,
            fontWeight: null,
          },
          tableOptions: {
            search: true,
            header: true,
            verticallyCenter: null,
            rowSeparator: null,
            columnSeparator: null,
            rowPadding: null,
            tableOrder: [],
            tableWidth: {},
          },
        },
      },
      
      icon: {
        iconType: 'check_circle',
        iconColor: 'white',
        iconSize: 45,
      },
      name: 'Badge',
      legend: true,
      loadingText: '',
      background: {
        backgroundColor: '#65b95f',
        backgroundTypeImage: false,
        backgroundImageUrl: '',
        backgroundOpacity: 1,
      },
      shadowValues: {
        hShadow: 1,
        vShadow: 1,
        shadowColor: '#b5b5b5',
        shadowBlur: 8,
      },
      preferences: {},
      view: 1,
      chartLibrary: 'ngxCharts',
      chartType: 'line',
      tabindex: 0,
      textToDisplay: 'Badge',
      tooltip: '',
      tableOrder: [],
      placeholder: 'Badge',
      minItemCols: 2,
      maxItemCols: 200,
    },
    molecules: [],
    placeholders: [],
    buses: [],
    debounce: {
      events: [],
    },
    minValue: null,
    maxValue: null,
    isSelected: false,
    enable: true,
    value: null,
    children: [],
    replaceableByRepresentative: true,
    loading: false,
    autoGenerationInfo: [],
    moleculeSubType: 'Badge',
    optionsMenu: {
      configuration: true,
      molecules: true,
      compound: true,
      cobblet: true,
      remove: true,
      versioning: true,
      dataSource: true,
      duplicate: true,
    },
  },
];
