import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FileUploadModule } from 'ng2-file-upload';
import { MaterialModule } from '../shared/material/material.module';
import { BreakCamelCasePipe } from '../workarea/pipes/break-camel-case.pipe';
import { InitialsPipe } from './../workarea/pipes/initials.pipe';
import { TopBarComponent } from './top-bar.component';
import { TopBarService } from './top-bar.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
  ],
  declarations: [TopBarComponent, InitialsPipe,
    BreakCamelCasePipe],
  exports: [TopBarComponent],
  entryComponents: [],
  providers: [TopBarService],
})
export class TopBarModule {
}
