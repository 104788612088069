import { Constants } from '../../../shared/constants';
import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class RemoveMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    dataBus: any,
    dataElementsData: any,
  ) {
    try {
      let toRemove = rule.toremove || Constants.Defaults.DataItemValue;
      
      if (dataElementsData) {
        dataElementsData.forEach(de => {
          toRemove = this.toolsService.ExtractValuesByType(de).string || Constants.Defaults.DataItemValue;
          dataBus = this.DataActionModifierApply(dataBus, this.Modifier, toRemove);
        });
      } else {
        dataBus = this.DataActionModifierApply(dataBus, this.Modifier, toRemove);
      }
      
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    }
  }
  
  public AfterRemove(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  private Modifier(value: string, toRemove: string): string {
    const type = this.toolsService.GetObjectType(value);
    if (type === 'string') {
      // const removeRegExp = new RegExp(toRemove, 'g');
      // value = value.replace(removeRegExp, '');
      value = (value as any).replaceAll(toRemove, '');
    }
    return value;
  }
}
