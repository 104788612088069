import { Injectable } from '@angular/core';
import { BottomSheetOption } from '../interfaces/bottom-sheet-option.interface';

@Injectable({
  providedIn: 'root',
})

export class BottomSheetService {
  Options: BottomSheetOption[] = [];
  
  constructor() {
  }
}


