import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WjNavModule } from '@grapecity/wijmo.angular2.nav';
import { AngularSplitModule } from 'angular-split';
import { AuthGuard } from '../auth/auth-guard.service';
import { ApiHierarchyService } from '../core/services/api-hierarchy.service';
import { ApiLicenseService } from '../core/services/api-license.service';
import { MaterialModule } from '../shared/material/material.module';
import { TopBarModule } from '../top-bar/top-bar.module';
import { HierarchyUsersComponent } from './hierarchy-users/hierarchy-users.component';
import { HierarchyComponent } from './hierarchy/hierarchy.component';
import { WelcomeRoutingModule } from './welcome-routing.module';
import { WelcomeComponent } from './welcome.component';
import { WelcomeService } from './welcome.service';

@NgModule({
  declarations: [WelcomeComponent, HierarchyComponent, HierarchyUsersComponent],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    WelcomeRoutingModule,
    WjNavModule,
    AngularSplitModule,
    ReactiveFormsModule,
    FormsModule,
    TopBarModule,
  ],
  providers: [AuthGuard, ApiHierarchyService, ApiLicenseService, WelcomeService],
})
export class WelcomeModule {
}
