import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as wjcGridSheet from '@grapecity/wijmo.grid.sheet';
import { FileUploader } from 'ng2-file-upload';
import { ApiDataSourcesService } from '../../core/services/api-data-sources.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { ToolsService } from '../../core/services/tools.service';
import { DatasourceSharedType } from '../../shared/enums/datasource-shared-type.enum';
import { DatasourceType } from '../../shared/enums/datasource-type.enum';
import { DatasourceUploadResponse } from '../../shared/interfaces/datasource-upload-response';
import { CommunicationService } from '../../shared/services/communication.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { DataSourceUploadFile } from '../../spreadsheet/models/data-source';
import { SpreadsheetService } from '../../spreadsheet/spreadsheet.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-datasource-dialog',
  templateUrl: './datasource-dialog.component.html',
  styleUrls: ['./datasource-dialog.component.scss'],
})
export class DatasourceDialogComponent implements OnInit {
  @ViewChild('btnUploader', { static: true }) btnUploader;
  datasourceForm: FormGroup;
  @ViewChild('flexSheet', { static: true })
  flexSheet: wjcGridSheet.FlexSheet;
  filesToUpload: FileList;
  uploader: FileUploader = new FileUploader({});
  dataSource: DataSourceUploadFile;
  dataSourceToReplace: any;
  approvedByFocused: Boolean = false;
  dateApprovedFocused: Boolean = false;
  isFileCSV = false;
  loadingSheets = false;
  csvDelimiter = '';
  csvDelimiterError = false;
  csvEncoding = 'UTF-8';
  uploadedFileResponse: any;
  uploading = false;
  replace = false;
  sheetNames = [];
  sheetNameSelected = '';
  openFile = false;
  fileTypes = '';
  title = 'Upload Existing Workbook';
  sharedtypes = [];
  
  constructor(
    private dataSourcesService: ApiDataSourcesService,
    public dialogRef: MatDialogRef<DatasourceDialogComponent>,
    private snackerService: SnackerService,
    private clientStorageService: ClientStorageService,
    private workareaService: WorkAreaService,
    private spreadSheetService: SpreadsheetService,
    private toolsService: ToolsService,
    private communicationService: CommunicationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    
    Object.keys(DatasourceSharedType).filter(key => {
      if (!isNaN(Number(DatasourceSharedType[key]))) {
        this.sharedtypes.push({
          id: DatasourceSharedType[key],
          name: key,
        });
      }
    });
    
    console.log(data);
    this.dataSourceToReplace = data;
    
    this.openFile = false;
    const tables: { index: number; name: string }[] = [];
    const newDate = new Date();
    
    this.fileTypes = '.csv,.xls,.xlsx';
    
    switch (data.type) {
      case DatasourceType.Spreadsheet:
        this.fileTypes = '.csv,.xls,.xlsx';
        break;
      
      case DatasourceType.Api:
        this.fileTypes = '.json,.xml';
        this.title = 'Upload API file';
        break;
      
      default:
        this.fileTypes = '.csv,.xls,.xlsx';
        break;
    }
    
    if (this.dataSourceToReplace.dataSource) {
      this.title = 'Upload Existing Workbook [Replace]';
      this.replace = true;
      this.dataSource = data.data;
    } else {
      this.replace = false;
      this.dataSource = {
        id: 0,
        ownerId: this.clientStorageService.getUserId(),
        cobbleId: 0,
        approvedBy: this.clientStorageService.getUserFullName(),
        description: '',
        sheetName: '',
        sheetNameToReplace: '',
        dateApproved: newDate,
        returnFile: false,
        enabled: true,
        name: '',
        delimiter: '',
        encoding: 'UTF-8',
        published: false,
        data: undefined,
        type: DatasourceType.Spreadsheet,
        extension: '',
        tables: tables,
        fullAudit: true,
        isTemplate: false,
        dataFile: undefined,
        sharedType: DatasourceSharedType.Personal,
      };
    }
    
    if (this.replace) {
      this.dataSource.fullAudit = false;
      this.dataSource.isTemplate = this.dataSourceToReplace.dataSource.isTemplate;
      this.dataSource.sharedType = this.dataSourceToReplace.dataSource.sharedType;
      this.dataSource.fullAudit = this.dataSourceToReplace.dataSource.fullAudit;
    } else {
      setTimeout(() => {
        this.dataSource.fullAudit = false;
        this.dataSource.fullAudit = true;
      }, 1000);
      
      this.dataSource.sharedType = DatasourceSharedType.Company;
    }
  }
  
  removeDefaultApprovedBy() {
    if (!this.approvedByFocused) {
      this.dataSource.approvedBy = '';
      this.approvedByFocused = true;
    }
  }
  
  removeDefaultDateApproved() {
    if (!this.dataSource.dateApproved) {
      this.dataSource.approvedBy = null;
      this.dateApprovedFocused = true;
    }
  }
  
  FullAudittoggle(event: any) {
    this.dataSource.fullAudit = event;
  }
  
  queueFile(eventData: FileList) {
    const splittedName = eventData[0].name.split('.');
    
    this.csvDelimiterError = false;
    this.filesToUpload = eventData;
    this.dataSource.extension = splittedName.pop();
    this.dataSource.name = splittedName.join('.');
    this.dataSource.type =
      this.dataSource.extension === 'json' ||
      this.dataSource.extension === 'xml'
        ? DatasourceType.Api
        : DatasourceType.Spreadsheet;
    this.dataSource.dataFile = eventData[0];
    // console.log(eventData[0]);
    
    switch (this.dataSource.type) {
      case DatasourceType.Api:
        
        break;
      
      case DatasourceType.Spreadsheet:
        if (this.dataSource.extension === 'csv') {
          this.isFileCSV = true;
        } else {
          this.isFileCSV = false;
          if (this.replace && !this.dataSourceToReplace.dataSource.isDatasource) {
            this.SelectSheetToReplace(eventData[0]);
          } else {
            this.uploadFile();
          }
        }
        break;
    }
  }
  
  SelectSheetToReplace(file: any) {
    this.loadingSheets = true;
    let reader;
    if (file) {
      reader = new FileReader();
      reader.onload = (e) => {
        this.flexSheet.loadAsync(reader.result, (wbook) => {
          this.flexSheet.sheets.forEach((sheet) => {
            this.sheetNames.push(sheet.name);
          });
          
          if (this.sheetNames.includes(this.dataSource.sheetNameToReplace)) {
            this.sheetNameSelected = this.dataSource.sheetNameToReplace;
          }
          
          this.loadingSheets = false;
        });
      };
      reader.readAsDataURL(file);
    }
  }
  
  uploadReplacementSheetFile() {
    this.dataSource.sheetName = this.sheetNameSelected;
    this.uploadFile();
  }
  
  uploadFile() {
    this.uploading = true;
    if (this.isFileCSV) {
    } else {
      this.dialogRef.close();
    }
    
    this.communicationService.Event.Editor.SidePanels.$Open.emit(1);
    this.communicationService.Event.Editor.DataSource.$dataSourceUploadState.emit(true);
    this.dataSource.returnFile = this.openFile;
    this.dataSource.delimiter = this.csvDelimiter;
    this.dataSource.encoding = this.csvEncoding;
    
    if (this.replace) {
      if (this.dataSourceToReplace.dataSource.isDatasource) {
        this.dataSourcesService.ReplaceDataSource(this.dataSource).subscribe((result) => {
          this.proccessUploadedFile(result);
        });
      } else {
        this.dataSourcesService.ReplaceDataSourceSheet(this.dataSource).subscribe((result) => {
          this.proccessUploadedFile(result);
        });
      }
    } else {
      this.dataSourcesService.uploadDataSource(this.dataSource).subscribe((result) => {
        this.proccessUploadedFile(result);
      });
    }
  }
  
  proccessUploadedFile(result: DatasourceUploadResponse) {
    this.uploading = false;
    this.uploadedFileResponse = result;
    
    switch (result.type) {
      case DatasourceType.Spreadsheet:
        if (result.delimiterError) {
          this.csvDelimiterError = true;
          this.communicationService.Event.Editor.DataSource.$dataSourceUploadState.emit(false);
        } else {
          this.dialogRef.close();
          this.csvDelimiterError = false;
          this.loadUploadedFile(result);
        }
        break;
      case DatasourceType.Api:
        // todo: add the node to spreadsheet tree
        this.communicationService.Event.Editor.DataSource.$ReloadDataSourcePanel.emit([]);
        break;
      default:
        break;
    }
  }
  
  loadUploadedFile(result: any) {
    if (result.dataFile !== '') {
      // console.log('loading uploaded file');
      
      this.spreadSheetService.LoadDataSource(
        result.id,
        result.name,
        0,
        false,
        this.toolsService.b64toBlob(result.dataFile, 'application/vnd.ms-excel'),
      );
    }
    
    this.dataSourcesService.isLoading = false;
    if (result) {
      this.communicationService.Event.Editor.DataSource.$ReloadDataSourcePanel.emit([]);
      // console.log('Upload Finished', result);
      this.snackerService.ShowMessageOnBottom(`File ${ this.dataSource.name } was uploaded!`, 'upload_file', null, true);
    } else {
      // console.log('Upload Failed');
    }
  }
  
  acceptCSVFile() {
    this.dialogRef.close();
    this.loadUploadedFile(this.uploadedFileResponse);
  }
  
  reuploadFile() {
    this.dataSource.id = this.uploadedFileResponse.id;
    this.dataSource.delimiter = this.csvDelimiter;
    this.dataSourcesService.ReplaceDataSource(this.dataSource).subscribe((result) => {
      this.uploading = false;
      this.uploadedFileResponse = result;
      
      if (result.delimiterError) {
        this.csvDelimiterError = true;
        this.communicationService.Event.Editor.DataSource.$dataSourceUploadState.emit(false);
      } else {
        this.dialogRef.close();
        this.csvDelimiterError = false;
        this.loadUploadedFile(result);
      }
    });
  }
  
  ngOnInit() {
    this.datasourceForm = new FormGroup({
      approvedBy: new FormControl('', Validators.required),
      dateApproved: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      fullAudit: new FormControl(true),
      isTemplate: new FormControl(false),
    });
  }
}
