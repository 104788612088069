import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { AutoGenType } from '../../../shared/enums/autogen-type.enum';
import { RepresentativeMoleculesType } from '../../../shared/enums/representative-molecules-types.enum';
import { Bus } from '../../../shared/representative-molecule/interfaces/bus';
import { Cobble } from '../../../shared/representative-molecule/interfaces/cobble';
import { DataElement } from '../../../shared/representative-molecule/interfaces/data-element';
import { LeapXLEvent } from '../../../shared/representative-molecule/interfaces/leapxl-event';
import {
  IRepresentativeMolecule,
} from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CommunicationService } from '../../../shared/services/communication.service';
import { SnackerService } from '../../../shared/services/snacker.service';

@Injectable({ providedIn: 'root' })
export class BusService {
  public RunningMode = false;
  inDebounce = null;
  private cachedRawApp: any[] = [];
  private channel: any = {};
  private parentChildren: any = {};
  private processorChannel: [
    {
      key: string;
      value: {
        bus: Bus;
        data: any;
      };
    }
  ];
  
  constructor(private snackerService: SnackerService, private communicationService: CommunicationService) {
    this.processorChannel = [null];
    this.processorChannel.pop();
  }
  
  get Channel() {
    return this.channel;
  }
  
  get ParentChildren() {
    return this.parentChildren;
  }
  
  get ProcessorChannel() {
    return this.processorChannel;
  }
  
  get Count() {
    return Object.keys(this.channel).length;
  }
  
  Get(moleculeId: string): IRepresentativeMolecule {
    return this.channel[moleculeId];
  }
  
  GetMultiple(moleculeIds: string[]): IRepresentativeMolecule[] {
    const repMolecules = [];
    moleculeIds.forEach((id) => {
      const repMolecule = this.channel[id];
      if (repMolecule) {
        repMolecules.push(repMolecule);
      }
    });
    
    return repMolecules;
  }
  
  GetParent(moleculeId: string): IRepresentativeMolecule {
    const molecule = this.Get(moleculeId);
    
    if (molecule) {
      const parent = this.Get(molecule.ParentId.toString());
      
      if (parent) {
        return parent;
      } else {
      }
    } else {
      // console.log('element does not exist');
      return null;
    }
  }
  
  GetSiblings(repMoleculeId: string): IRepresentativeMolecule[] {
    const parent = this.GetParent(repMoleculeId);
    
    if (!parent) {
      return [];
    }
    
    return this.DirectChildrenElements(parent.Id)
    .filter((child) => child.Id !== +repMoleculeId);
  }
  
  Add(context: IRepresentativeMolecule, fireChangeMoleculeEvent = true, originalClonedRepMoleculeId: number = 0) {
    const exists = this.Get(context.Id.toString());
    if (exists) {
    
    } else {
      this.channel[context.Id] = context;
      
      const parent = this.parentChildren[context.ParentId];
      if (parent) {
        if (!parent.includes(context.Id)) {
          parent.push(context.Id);
        }
      } else {
        this.parentChildren[context.ParentId] = [context.Id];
      }
      
      // child remove disable temporarily
      const registeredChildren = this.parentChildren[context.Id];
      
      if (registeredChildren && registeredChildren.length > 0 && registeredChildren.length !== context.Children.length) {
        this.parentChildren[context.Id] = context.Children.map(c => c.id);
      }
      
      if (fireChangeMoleculeEvent) {
        this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(context);
        this.communicationService.Event.System.App.$RefreshUI.emit(true);
      }
      
      // assigning children to dinamically created rep molecules
      if (originalClonedRepMoleculeId > 0) {
        const hasChildren = this.DirectChildrenElements(originalClonedRepMoleculeId);
        
        if (hasChildren.length > 0) {
          this.parentChildren[context.Id] = this.parentChildren[originalClonedRepMoleculeId];
        }
      }
    }
  }
  
  GetDinamicallyGeneratedChildren(parentId: number) {
    const minId = 11111111;
    return this.GetChannelArray()
    .filter((repMol) => repMol.ParentId === parentId && repMol.Id > minId);
  }
  
  Clear() {
    this.channel = {};
    this.parentChildren = {};
    this.processorChannel = [null];
    this.processorChannel.pop();
  }
  
  RemoveParent(moleculeId: string) {
    const molecule = this.Get(moleculeId.toString());
    
    if (!molecule || !molecule.ParentId) return;
    
    const parent = this.Get(molecule.ParentId.toString());
    if (!parent) return;
    
    parent.Children = parent.Children.filter(c => c.id !== +moleculeId);
    
    this.parentChildren[molecule.ParentId] = this.parentChildren[molecule.ParentId].filter(
      (id) => id !== +moleculeId);
    molecule.ParentId = null;
  }
  
  AddParent(moleculeId: string, parentId: string) {
    const molecule = this.Get(moleculeId);
    
    if (molecule) {
      const parent = this.Get(parentId);
      
      molecule.ParentId = +parentId;
      
      const parentChildren = this.parentChildren[parentId];
      
      if (parentChildren) {
        parentChildren.push(moleculeId);
      } else {
        this.parentChildren[parentId] = [moleculeId];
      }
      
      parent.AddChildren([+moleculeId]);
    }
  }
  
  Remove(moleculeId: string) {
    const molecule = this.Get(moleculeId.toString());
    
    if (molecule) {
      molecule.Children.forEach((child) => {
        this.Remove(child.id);
      });
      
      if (this.parentChildren[molecule.ParentId]) {
        this.parentChildren[molecule.ParentId] = this.parentChildren[molecule.ParentId].filter(
          (id) => id !== +moleculeId);
      }
      delete this.channel[moleculeId];
      
      if (molecule.ParentId) {
        const parent = this.Get(molecule.ParentId.toString());
        this.parentChildren[molecule.ParentId] = this.parentChildren[molecule.ParentId].filter(
          (id) => id !== +moleculeId);
        this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(parent);
      }
      
      this.communicationService.Event.System.App.$RefreshUI.emit(true);
    }
  }
  
  SwapParent(repMoleculeId: number, oldParentId: number, newParentId: number) {
    const oldParent = this.Get(oldParentId.toString());
    const newParent = this.Get(newParentId.toString());
    const repMolecule = this.Get(repMoleculeId.toString());
    repMolecule.ParentId = newParentId;
    
    this.parentChildren[oldParentId] = this.parentChildren[oldParentId].filter((id) => id !== +repMoleculeId);
    
    const parentChildren = this.parentChildren[newParentId];
    if (parentChildren) {
      parentChildren.push(repMoleculeId);
    } else {
      this.parentChildren[newParentId] = [repMoleculeId];
    }
    
    oldParent.Children = oldParent.Children.filter((c) => c.id !== repMoleculeId);
    newParent.Children.push({ id: repMoleculeId });
  }
  
  SetMoleculesChannel(channel: any) {
    this.processorChannel = channel;
  }
  
  public Exists(moleculeId: number) {
    return !!this.channel[moleculeId];
  }
  
  AddBusToRunToProcessorChannel(key: string, bus: Bus) {
    this.processorChannel.push({
      key: key,
      value: {
        bus: bus.Clone(true),
        data: null,
      },
    });
    // console.log(`Adding particles to proccess: ${key}`, this.processorChannel);
  }
  
  GetBusToRunFromProcessorChannel(key): {
    bus: Bus;
    data: any;
  } {
    const bus = this.ProcessorChannel.find((m) => m.key === key);
    return bus?.value || null;
  }
  
  RemoveBusFromProcessorChannel(key: string) {
    const busIndex = this.ProcessorChannel.findIndex((m) => m.key === key);
    this.ProcessorChannel.splice(busIndex, 1);
  }
  
  public DirectChildrenElements(moleculeId: number): IRepresentativeMolecule[] {
    const childrenIds = this.parentChildren[moleculeId];
    const children = [];
    
    if (childrenIds) {
      childrenIds.forEach((id) => {
        const repMolecule = this.Get(id);
        if (repMolecule) {
          children.push(repMolecule);
        }
      });
    }
    
    return children;
  }
  
  public ChildrenElements(moleculeId: number): IRepresentativeMolecule[] {
    const directChildren = this.DirectChildrenElements(moleculeId);
    
    let children = [];
    
    directChildren.forEach((dc) => {
      children = children.concat(this.DirectChildrenElements(dc.Id));
    });
    return directChildren.concat(children);
  }
  
  GetAllChildrenIds(moleculeId: number): number[] {
    let childrenToDisable = [moleculeId];
    
    this.GetChildrenElementIds(moleculeId)
    .map((c) => c.id)
    .forEach((childrenId) => {
      childrenToDisable = childrenToDisable.concat(this.GetAllChildrenIds(childrenId));
    });
    
    return childrenToDisable;
  }
  
  public GetChildrenElementIds(moleculeId: number): any[] {
    const childrenIds = this.parentChildren[moleculeId.toString()];
    const children = [];
    if (childrenIds !== undefined) {
      childrenIds.forEach((id) => {
        children.push({ id: id });
      });
    }
    return children;
  }
  
  public IsMyChild(parentId: number, childId: number): boolean {
    if (this.parentChildren[parentId] === undefined) {
      return false;
    } else {
      const childrenIds = this.parentChildren[parentId];
      return childrenIds.includes(+childId);
    }
  }
  
  GetViewRepresentativeMolecules(viewId: number) {
    return this.GetChannelArray()
    .filter(repMol => repMol.Properties.view === viewId);
  }
  
  public GetViewMolecules(cobble: Cobble, viewId: number): IRepresentativeMolecule[] {
    let molecules = [];
    this.GetViewWorkgroupsMolecules(cobble, viewId)
    .forEach((wg) => {
      molecules.push(wg);
      molecules = molecules.concat(this.DirectChildrenElements(wg.Id));
    });
    
    return molecules;
  }
  
  public GetVisibleViewMolecules(cobble: Cobble, viewId: number): IRepresentativeMolecule[] {
    let count = 0;
    let molecules = [];
    const wgMolecules = this.DirectChildrenElements(cobble.id)
    .filter(
      (m) => m.Properties.view === viewId && m.Type === RepresentativeMoleculesType.WorkGroup,
    );
    
    wgMolecules.forEach((wg) => {
      count++;
      molecules.push(wg);
      this.DirectChildrenElements(wg.Id)
      .forEach((i) => {
        count++;
      });
      
      if (wg.Properties.show) {
        molecules = molecules.concat(this.DirectChildrenElements(wg.Id));
      }
    });
    
    return molecules.filter((m) => m.Properties.show);
  }
  
  public GetInitializedViewMolecules(cobble: Cobble, viewId: number): IRepresentativeMolecule[] {
    let molecules = [];
    const wgMolecules = this.DirectChildrenElements(cobble.id)
    .filter(
      (m) => m.Properties.view === viewId && m.Type === RepresentativeMoleculesType.WorkGroup,
    );
    
    wgMolecules.forEach((wg) => {
      molecules.push(wg);
      if (wg.Initialized) {
        molecules = molecules.concat(this.DirectChildrenElements(wg.Id)
        .filter((child) => child.Initialized));
      }
    });
    
    return molecules.filter((m) => m.Initialized);
  }
  
  public GetViewRepresentativeMoleculesPopulating(
    cobble: Cobble,
    viewId: number,
    skipRepMolecules: IRepresentativeMolecule[] = [],
  ): IRepresentativeMolecule[] {
    const populatingRepMolecules = [];
    const viewRepMolecules = this.GetViewMolecules(cobble, viewId)
    .filter((vrm) => !skipRepMolecules.map((s) => s.Id)
    .includes(vrm.Id));
    
    viewRepMolecules.forEach((viewRepMolecule) => {
      if (viewRepMolecule.GetPopulatingBus()) {
        populatingRepMolecules.push(viewRepMolecule);
      }
    });
    
    return populatingRepMolecules;
  }
  
  public GetViewWorkgroupsMolecules(cobble: Cobble, viewId: number): IRepresentativeMolecule[] {
    const lockedView = cobble.properties.views.find((v) => v.locked);
    
    return this.DirectChildrenElements(cobble.id)
    .filter(
      (m) => (m.Properties.view === viewId || (lockedView && m.Properties.view === lockedView.id)) && m.Type === RepresentativeMoleculesType.WorkGroup,
    );
  }
  
  public GetViewWorkgroupsAndStepperMolecules(cobble: Cobble, viewId: number, includeLockedView = true): IRepresentativeMolecule[] {
    const lockedView = includeLockedView ? cobble.properties.views.find((v) => v.locked) : null;
    
    return this.DirectChildrenElements(cobble.id)
    .filter(
      (m) =>
        (m.Properties.view === viewId || (lockedView && m.Properties.view === lockedView.id)) &&
        (m.Type === RepresentativeMoleculesType.WorkGroup || m.Type === RepresentativeMoleculesType.Stepper),
    );
  }
  
  public GetWorkgroupsMolecules(cobble: Cobble): IRepresentativeMolecule[] {
    return this.DirectChildrenElements(cobble.id);
  }
  
  public GetSpecificViewWorkgroupsMolecules(cobble: Cobble, viewId: number): IRepresentativeMolecule[] {
    const lockedView = cobble.properties.views.find((v) => v.locked);
    
    return this.DirectChildrenElements(cobble.id)
    .filter((m) => m.Properties.view === viewId && m.Type === RepresentativeMoleculesType.WorkGroup);
  }
  
  public GetRepresentativeMoleculesWithDatSourcesConnected(): IRepresentativeMolecule[] {
    return this.GetChannelArray()
    .filter((repMolecule) => repMolecule.DataSourceConnected);
  }
  
  public GetWorkareaViewMolecules(cobble: Cobble, actualEditorViews: any[]): IRepresentativeMolecule[] {
    const lockedView = cobble.properties.views.find((v) => v.locked);
    
    return this.ChildrenElements(cobble.id)
    .filter(
      (m) => actualEditorViews.map((v) => v.id)
      .includes(m.Properties.view) || (lockedView && m.Properties.view === lockedView.id),
    );
  }
  
  public GetRunningViewWorkgroupsMolecules(cobble: Cobble, viewId: number): IRepresentativeMolecule[] {
    return this.GetViewWorkgroupsMolecules(cobble, viewId)
    .filter((m) => m.Properties.show);
  }
  
  public GetRepresentativeMoleculeByAutoGenTranslationId(translationId: number, autogenerationId: string = null, triggerType?: AutoGenType): IRepresentativeMolecule {
    return this.GetChannelArray()
    .find(repMol => repMol.IsAutoGenTranslationId(translationId, autogenerationId, triggerType));
  }
  
  public GetRepresentativeMoleculesByAutoGenTranslationId(translationId: number, autogenerationId: string = null, triggerType?: AutoGenType): IRepresentativeMolecule[] {
    return this.GetChannelArray()
    .filter(repMol => repMol.IsAutoGenTranslationId(translationId, autogenerationId, triggerType));
  }
  
  public GetRunningViewWorkgroupsAndStepperMolecules(cobble: Cobble, viewId: number): IRepresentativeMolecule[] {
    return this.GetViewWorkgroupsAndStepperMolecules(cobble, viewId)
    .filter((m) => m.Properties.show && !m.ResponsiveProperties().centerPositioning);
  }
  
  GetChannelArray(): IRepresentativeMolecule[] {
    const molecules = Object.values(this.channel) as IRepresentativeMolecule[];
    return molecules;
  }
  
  public GetDataSourcesForApp(): {
    dataSourceId: number;
    dataSourceName: string;
    translationId: number;
    dataSourceType: string;
    context: string;
  }[] {
    const dataSources = [];
    
    this.GetChannelArray()
    .forEach((repMolecule) => {
      repMolecule.GetDataElements()
      .forEach((de) => {
        const usedDs = {
          dataSourceId: de.DataSourceId,
          dataSourceName: de.DataSourceName,
          translationId: de.TranslationId,
          dataSourceType: de.DataSourceType,
          context: de.Context,
        };
        
        if (!dataSources.includes(usedDs)) {
          dataSources.push(usedDs);
        }
      });
    });
    
    // console.log('ds used', dataSources);
    return dataSources;
  }
  
  public GetDataSourcesByBusEventForApp(event: LeapXLEvent): {
    dataSourceId: number;
    dataSourceName: string;
    translationId: number;
    dataSourceType: string;
    context: string;
  }[] {
    const dataSources = [];
    
    this.GetChannelArray()
    .forEach((repMolecule) => {
      repMolecule.GetDataElementsByBusEvent(event)
      .forEach((de) => {
        const usedDs = {
          dataSourceId: de.DataSourceId,
          dataSourceName: de.DataSourceName,
          translationId: de.TranslationId,
          dataSourceType: de.DataSourceType,
          context: de.Context,
        };
        
        if (!dataSources.includes(usedDs)) {
          dataSources.push(usedDs);
        }
      });
    });
    
    // // console.log('ds used', dataSources);
    return dataSources;
  }
  
  public GetDataElementsByBusEventForApp(event: LeapXLEvent): DataElement[] {
    const dataelements = [];
    
    this.GetChannelArray()
    .forEach((repMolecule) => {
      repMolecule.GetDataElementsByBusEvent(event)
      .forEach((de) => {
        if (!dataelements.includes(de)) {
          dataelements.push(de);
        }
      });
    });
    
    // // console.log('ds used', dataSources);
    return dataelements;
  }
  
  public GetDataElementsByBusEventForView(cobble: Cobble, event: LeapXLEvent, viewId: number): DataElement[] {
    const dataelements = [];
    
    this.GetViewMolecules(cobble, viewId)
    .forEach((repMolecule) => {
      repMolecule.GetDataElementsByBusEvent(event)
      .forEach((de) => {
        if (!dataelements.includes(de)) {
          dataelements.push(de);
        }
      });
    });
    
    // // console.log('ds used', dataSources);
    return dataelements;
  }
  
  public GetRepresentativeMoleculesFiredBySpecificEvent(cobble: Cobble, eventName: string, sourceId: any, viewId: number): IRepresentativeMolecule[] {
    const repMolecules = [];
    
    this.GetViewMolecules(cobble, viewId)
    .forEach((repMolecule) => {
      if (repMolecule.GetBusInitiatedBySpecificEvent(eventName, sourceId)) {
        repMolecules.push(repMolecule);
      }
    });
    
    return repMolecules;
  }
  
  public GetRepresentativeMoleculesFiredByEvent(cobble: Cobble, eventName: string, viewId: number): IRepresentativeMolecule[] {
    const repMolecules = [];
    
    this.GetViewMolecules(cobble, viewId)
    .forEach((repMolecule) => {
      if (repMolecule.GetBusInitiatedByEventName(eventName)) {
        repMolecules.push(repMolecule);
      }
    });
    
    return repMolecules;
  }
  
  PulseRepresentativeMoleculesWithContext(context: string): void {
    this.GetRepresentativeMoleculesWithContext(context)
    .forEach((repMol) => repMol.Pulse(2000));
  }
  
  GetRepresentativeMoleculesWithContext(context: string): IRepresentativeMolecule[] {
    const repMoleculesConnected = this.GetRepresentativeMoleculesWithDatSourcesConnected();
    return repMoleculesConnected.filter((repmol) => repmol.ContainsDataElement(context));
  }
  
  GetRepresentativeMoleculesWithEvent(eventName: string): IRepresentativeMolecule[] {
    return this.GetChannelArray()
    .filter((repMolecule) => repMolecule.GetBusesByEventName(eventName).length > 0);
  }
  
  GetSamePositionAndSizeRepresentativeMolecule(
    size: { cols: number; rows: number; x: number; y: number },
    cobble: Cobble,
    viewId: number,
  ): IRepresentativeMolecule[] {
    return this.GetViewMolecules(cobble, viewId)
    .filter(
      (repMolecule) =>
        repMolecule.ResponsiveProperties().x === size.x &&
        repMolecule.ResponsiveProperties().y === size.y &&
        repMolecule.ResponsiveProperties().cols === size.cols &&
        repMolecule.ResponsiveProperties().rows === size.rows,
    );
  }
  
  SaveRawApp(rawApp: any[]) {
    this.cachedRawApp = rawApp;
  }
  
  GetRepresentativeMoleculeFromRawApp(repMoleculeId: number): any {
    const rawRepMolecule = cloneDeep(this.cachedRawApp.find((rawRepMol) => rawRepMol.id === repMoleculeId));
    return rawRepMolecule || {};
  }
  
  GetEditorVisibleRepresentativeMolecules(): IRepresentativeMolecule[] {
    return this.GetChannelArray()
    .filter((m) => m.EditorVisible);
  }
  
  GetEditorHiddenRepresentativeMolecules(): IRepresentativeMolecule[] {
    return this.GetChannelArray()
    .filter((m) => !m.EditorVisible);
  }
  
  GetAllBuses() {
    const repMoleculesArray = this.GetChannelArray();
    return (repMoleculesArray.map((repMolecule) => repMolecule.Buses) as any).flat() as Bus[];
  }
  
  GetAllBusesInitiatedByEvent(event: LeapXLEvent) {
    const allBuses = this.GetAllBuses();
    return allBuses.filter((bus) => bus.InitiatedByEvent(event));
  }
  
  Throttle(func, delay, context, args) {
    clearTimeout(this.inDebounce);
    this.inDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }
  
  RepMoleculesWithPlaceholder(placeholder: string): IRepresentativeMolecule[] {
    return this.GetChannelArray()
    .filter(repMolecule => repMolecule.Placeholders.includes(placeholder));
  }
}
