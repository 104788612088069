import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { DraggableWindowService } from '../../shared/services/draggable-window.service';
import { DocumentationService } from '../services/documentation.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss'],
})
export class DocumentationComponent implements OnInit, OnDestroy {
  
  DraggableWindowType = 'Microlearning';
  @Input()
  noShowNext = false;
  
  iframeInput = null;
  Subscriptions = new Subscription();
  
  constructor(
    private documentationService: DocumentationService,
    private draggableWindowService: DraggableWindowService,
    private workAreaService: WorkAreaService,
    private communicationService: CommunicationService,
    private clientStorageService: ClientStorageService) {
    const data = this.draggableWindowService.GetData();
    const elementId = data.id || '';
    const elementType = data.type || '';
    this.noShowNext = data.noShowNext || false;
    
    this.GetDocumentationUrl(elementType, elementId);
    // this.iframeUrl = `https://wiki.js.org/`;
  }
  
  ngOnInit() {
    this.Subscriptions.add(this.communicationService.Event.Documentation.$Get.subscribe(data => {
      this.iframeInput = null;
      this.GetDocumentationUrl(this.workAreaService.userGuideType, this.workAreaService.userGuideId);
    }));
  }
  
  GetDocumentationUrl(type: string, id: string) {
    this.documentationService.GetDocumentationInfo(id, type).subscribe(info => {
      if (info) {
        if (info.guideWebPage) {
          this.iframeInput = info.guideWebPage;
        } else {
          this.iframeInput = `${ info.guideAddress }/${ info.guidePath }/${ info.guideHtmlPageName }.html`;
        }
      }
    });
  }
  
  ToggleStartPageVisibility(e: any) {
    const condition = e.checked ? 'Y' : 'N';
    this.documentationService.ToggleStartPageVisibility(condition).subscribe(response => {
    });
    this.clientStorageService.setDocumentationStartPage(condition);
  }
  
  ngOnDestroy(): void {
    this.workAreaService.userGuideWindow = null;
    this.Subscriptions.unsubscribe();
    // console.log('destroyed');
  }
}
