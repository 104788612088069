import { Constants } from '../../../shared/constants';
import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class LinkToTabMolecule extends ActionMoleculeFunction {
  private newWindow: any;
  
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    dataBus: any,
    dataElementsData: any,
  ) {
    try {
      
      console.log('link to tab');
      
      let url: string;
      
      if (dataElementsData) {
        url = this.toolsService.ExtractValuesByType(dataElementsData).string || Constants.Defaults.DataItemValue;
      } else {
        if (rule && rule.url) {
          url = rule.url;
        } else {
          if (typeof dataBus === 'string') {
            url = dataBus;
          } else {
            url = this.toolsService.ExtractValuesByType(dataBus).string;
          }
        }
      }
      
      if (url !== '') {
        this.newWindow = window.open('', '_blank');
        url =
          url.indexOf('http://') === -1 &&
          url.indexOf('https://') === -1
            ? `https://${ document.location.host }` + '/run' + (url.startsWith('/') ? url : '/' + url)
            : url;
        
        this.newWindow.location = url;
        
      }
      
      this.Done(particleId, busProcessorKey, repMoleculeId, url);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
