import { Injectable, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { Constants } from '../../shared/constants';
import { DatasourceTypeId } from '../../shared/enums/datasource-type-id.enum';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { IEditorState } from '../../workarea/interfaces/editor-state.interface';
import { TreeNode } from '../../workarea/views-panel/views-panel.component';
import { WorkAreaService } from '../../workarea/workarea.service';
import { BusService } from '../molecular/services/bus.service';
import { LocalStorageService } from './local-storage.service';
import { ToolsService } from './tools.service';

@Injectable({
  providedIn: 'root',
})
export class EditorStateService implements OnInit {
  subscription: Subscription;
  lastStateLoaded = null;
  private windowSizes = {};
  private KEY = 'editorState';
  
  constructor(
    private workAreaService: WorkAreaService,
    private busService: BusService,
    private communicationService: CommunicationService,
    private toolsService: ToolsService,
    private cobbleService: CobbleService,
    private localStorageService: LocalStorageService,
  ) {
    if (toolsService.IsEditor()) {
      this.subscription = this.communicationService.Event.Editor.$DraggableWindowChange.subscribe(change => {
        console.log('=event=');
        this.SaveDraggableWindowSize(change.window, change.size);
      });
      
      this.subscription.add(
        this.communicationService.Event.Editor.$SaveEditorState.subscribe(data => {
          console.log('=event=');
          this.SaveEditorState();
        }),
      );
    }
  }
  
  ngOnInit(): void {
  }
  
  SaveEditorState(state: IEditorState = null) {
    if (this.cobbleService.Cobble.id <= 0) {
      return;
    }
    
    this.RemoveState(this.GetAppId());
    
    state = state || {
      appId: this.GetAppId(),
      view: this.GetViewSelected(),
      leftAreaSize: this.GetLeftPanelState(),
      rightAreaSize: this.GetRightPanelState(),
      leftPanelIndexTabSelected: this.GetLeftPanelIndexTabSelected(),
      rightPanelIndexTabSelected: this.GetRightPanelIndexTabSelected(),
      representativeMoleculesSelected: this.GetRepresentativeMoleculesSelected(),
      systemSectionOpen: this.GetSystemSectionState(),
      apiSectionOpen: this.GetApiSectionState(),
      leapXLSectionOpen: this.GetLeapXlSectionState(),
      customSectionOpen: this.GetCustomSectionState(),
      hideUnusedDataSources: this.GetHiddenUnusedDataSourcesState(),
      hideNotOwnedDataSources: this.GetHiddenNotOwnedDataSourcesState(),
      spreadsheetsSectionOpen: this.GetSpreadsheetsSectionState(),
      databaseSectionOpen: this.GetDatabaseSectionState(),
      unifiedDatabaseSectionOpen: this.GetUnifiedDbSectionState(),
      internetMessagingSectionOpen: this.GetInternetMessagingSectionState(),
      layoutsPanelOpen: this.GetLayoutsPanelState(),
      windowSizes: this.windowSizes,
      mostUsedMolecules: this.GetMostUsedMolecules(),
      eventsPanelSectionsState: this.GetEventsPanelSectionsState(),
      representativeMoleculesHidden: this.GetHiddenRepresentativeMolecules().map(m => m.Id),
      busState: this.GetBusStates(),
      editorPreferences: this.GetEditorPreferences(),
    };
    
    const states = this.GetStates();
    states.push(state);
    
    this.localStorageService.Remove(this.KEY);
    this.localStorageService.Set(this.KEY, JSON.stringify(states));
    // console.log('editor state saved');
  }
  
  RemoveState(appId: number) {
    let states = this.GetStates();
    if (states) {
      states = states.filter(s => s.appId !== appId);
      this.localStorageService.Set(this.KEY, JSON.stringify(states));
    }
  }
  
  GetEditorState(): IEditorState {
    const appId = this.cobbleService.Cobble.id;
    const states = this.GetStates();
    if (states) {
      const state = states.find(s => s.appId === appId);
      this.lastStateLoaded = state;
      return state;
    }
  }
  
  RestoreEditorState() {
    const state = this.GetEditorState();
    
    console.log(state);
    if (state) {
      // if (this.workAreaService.ActualView.id !== state.view.id) {
      //   this.communicationService.Event.Editor.Views.$VisibilityChange.emit(state.view);
      // }
      
      if (state.leftAreaSize > 0) {
        this.communicationService.Event.Editor.SidePanels.$Open.emit(1);
      } else {
        this.communicationService.Event.Editor.SidePanels.$Close.emit(1);
      }
      
      if (state.rightAreaSize > 0) {
        this.communicationService.Event.Editor.SidePanels.$Open.emit(2);
      } else {
        this.communicationService.Event.Editor.SidePanels.$Close.emit(2);
      }
      
      this.workAreaService.leapXLSectionOpen = state.leapXLSectionOpen;
      this.workAreaService.hideUnusedDataSources = state.hideUnusedDataSources;
      this.workAreaService.hideNotOwnedDataSources = state.hideNotOwnedDataSources;
      
      this.workAreaService.systemSectionOpen = state.systemSectionOpen;
      this.workAreaService.internetMessagingSectionOpen = state.internetMessagingSectionOpen;
      this.workAreaService.apiSectionOpen = state.apiSectionOpen;
      this.workAreaService.spreadsheetsSectionOpen = state.spreadsheetsSectionOpen;
      this.workAreaService.customSectionOpen = state.customSectionOpen;
      this.workAreaService.databaseSectionOpen = state.databaseSectionOpen;
      
      this.workAreaService.unifiedDatabaseSectionOpen = state.unifiedDatabaseSectionOpen;
      this.windowSizes = state.windowSizes || {};
      this.workAreaService.editorPreferences.layoutsPanel = state.layoutsPanelOpen;
      this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanelUI.emit(true);
      this.workAreaService.busState = state.busState || {};
      this.workAreaService.mostUsedMolecules = state.mostUsedMolecules || [];
      this.workAreaService.eventsPanelSectionsState = state.eventsPanelSectionsState || {};
      
      this.workAreaService.leftPanelIndexTab = state.leftPanelIndexTabSelected;
      this.workAreaService.rightPanelIndexTab = state.rightPanelIndexTabSelected;
      
      this.HideRepMolNoVisible(state);
      
      if (state.editorPreferences) {
        Object.keys(state.editorPreferences).forEach(key => {
          this.workAreaService.editorPreferences[key] = state.editorPreferences[key];
          this.communicationService.Event.Editor.Preferences.$PreferenceChange.emit(key);
        });
      }
      
      this.communicationService.Event.Editor.DataSource.$OpenOneSectionAtTime.emit();
      this.communicationService.Event.Editor.WorkArea.$EditorStateLoaded.emit();
      setTimeout(() => {
        // if (state.representativeMoleculesSelected && state.representativeMoleculesSelected.length > 0) {
        //   state.representativeMoleculesSelected.forEach(repMolId => {
        //     const repMol = this.busService.Get(repMolId.toString());
        //     if (repMol) {
        //       this.workAreaService.SelectRepresentativeMolecule(repMol, true);
        //       this.workAreaService.ShowElementFocusedMenu(repMol);
        //     }
        //   });
        // }
      }, 1000);
    } else {
      this.communicationService.Event.Editor.DataSource.ToggleSection.emit({ dataSourceTypeId: DatasourceTypeId.Spreadsheet, open: true });
      this.workAreaService.editorPreferences.layoutsPanel = true;
    }
  }
  
  WindowSize(window: string) {
    return this.GetDraggableWindowSizes()[window];
  }
  
  SaveDraggableWindowSize(window: string, size: any) {
    this.windowSizes[window] = size;
  }
  
  SaveHiddenRepresentativeMolecules() {
    this.SaveConfig(
      'representativeMoleculesHidden',
      this.busService.GetEditorHiddenRepresentativeMolecules().map(m => m.Id),
    );
  }
  
  SaveBusState() {
    this.SaveConfig('busState', this.GetBusStates());
  }
  
  SaveConfig(configKey: string, configData: any) {
    console.log(configData);
    const editorState = this.GetEditorState();
    console.log('editor');
    
    if (editorState) {
      editorState[configKey] = configData;
      this.SaveEditorState(editorState);
    } else {
      this.SaveEditorState();
    }
  }
  
  HideRepMolNoVisible(state = this.GetEditorState()) {
    if (state && state.representativeMoleculesHidden && state.representativeMoleculesHidden.length > 0) {
      state.representativeMoleculesHidden.forEach(id => {
        const repMolecule = this.busService.Get(id);
        if (repMolecule) {
          repMolecule.ToggleVisibilityFromEditorLayer(false);
        }
      });
    }
  }
  
  private GetDraggableWindowSizes() {
    const windowSizes = Constants.Defaults.DraggableWindowSizes;
    
    Object.keys(this.windowSizes).forEach(window => {
      // windowSizes[window] = this.windowSizes[window];
      
      if (windowSizes[window]) {
        Object.keys(windowSizes[window]).forEach(key => {
          windowSizes[window][key] = this.windowSizes[window][key] || windowSizes[window][key];
        });
      }
    });
    
    return windowSizes;
  }
  
  private GetStates(): IEditorState[] {
    return JSON.parse(this.localStorageService.Get('editorState') || '[]') as IEditorState[];
  }
  
  private GetAppId(): number {
    return this.cobbleService.Cobble.id;
  }
  
  private GetViewSelected(): TreeNode {
    const actualView = this.workAreaService.ActualView as any;
    
    return {
      draggable: actualView.draggable,
      editMode: actualView.editMode,
      icon: actualView.icon,
      id: actualView.id,
      isView: actualView.isView,
      locked: actualView.locked,
      name: actualView.name,
      representativeType: actualView.representativeType,
      squish: actualView.squish,
    } as any;
  }
  
  private GetLeftPanelIndexTabSelected(): number {
    return this.workAreaService.leftPanelIndexTabSelected;
  }
  
  private GetRightPanelIndexTabSelected(): number {
    return this.workAreaService.rightPanelIndexTabSelected;
  }
  
  private GetLeftPanelState(): number {
    return this.workAreaService.leftAreaSize;
  }
  
  private GetRightPanelState(): number {
    return this.workAreaService.rightAreaSize;
  }
  
  private GetRepresentativeMoleculesSelected(): number[] {
    return this.workAreaService.elementsSelected.map(es => es.Id);
  }
  
  private GetLeapXlSectionState(): boolean {
    return this.workAreaService.leapXLSectionOpen;
  }
  
  private GetSystemSectionState(): boolean {
    return this.workAreaService.systemSectionOpen;
  }
  
  private GetInternetMessagingSectionState(): boolean {
    return this.workAreaService.internetMessagingSectionOpen;
  }
  
  private GetApiSectionState(): boolean {
    return this.workAreaService.apiSectionOpen;
  }
  
  private GetSpreadsheetsSectionState(): boolean {
    return this.workAreaService.spreadsheetsSectionOpen;
  }
  
  private GetDatabaseSectionState(): boolean {
    return this.workAreaService.databaseSectionOpen;
  }
  
  private GetEditorPreferences(): any {
    return this.workAreaService.editorPreferences;
  }
  
  private GetUnifiedDbSectionState(): boolean {
    return this.workAreaService.unifiedDatabaseSectionOpen;
  }
  
  private GetCustomSectionState(): boolean {
    return this.workAreaService.customSectionOpen;
  }
  
  private GetHiddenUnusedDataSourcesState(): boolean {
    return this.workAreaService.hideUnusedDataSources;
  }
  
  private GetMostUsedMolecules(): any[] {
    return this.workAreaService.mostUsedMolecules;
  }
  
  private GetHiddenNotOwnedDataSourcesState(): boolean {
    return this.workAreaService.hideNotOwnedDataSources;
  }
  
  private GetLayoutsPanelState() {
    return this.workAreaService.editorPreferences.layoutsPanel;
  }
  
  private GetEventsPanelSectionsState() {
    return this.workAreaService.eventsPanelSectionsState;
  }
  
  private GetHiddenRepresentativeMolecules() {
    return this.busService.GetEditorHiddenRepresentativeMolecules();
  }
  
  private GetBusStates() {
    return this.workAreaService.busState;
  }
}
