import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class SkipAllOnMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    data: any,
    dataElemetnsData: any,
  ) {
    try {
      rule.valueFilter = rule.valueFilter || '';
      const dataValue = data ? this.toolsService.ExtractValuesByType(data).string : '';
      
      console.log('skip');
      const filterValue =
        rule.valueFilter || '';
      
      let match = false;
      
      // molecule compares the value on the bus with its value
      // datasource data has priority over literal value
      if (dataElemetnsData && dataElemetnsData.length > 0) {
        dataElemetnsData.forEach(de => {
          if (this.toolsService.ExtractValuesByType(de).string === dataValue) {
            match = true;
          }
        });
      } else {
        if (dataValue === filterValue) {
          match = true;
        }
      }
      
      if (
        match
      ) {
        const moleculesChannel = this.busService.GetBusToRunFromProcessorChannel(
          busProcessorKey,
        );
        if (moleculesChannel) {
          moleculesChannel.bus.Particles.length = 1;
        }
        this.Done(particleId, busProcessorKey, repMoleculeId, data);
      } else {
        this.Done(particleId, busProcessorKey, repMoleculeId, data);
      }
      
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
