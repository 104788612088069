import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { catchError, map } from 'rxjs/operators';
import { ApiDataService } from '../../core/services/api-data.service';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';
import { ISmartTemplateCategory } from '../interfaces/smart-template-category.interface';
import { ISmartTemplate } from '../interfaces/smart-template.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiSmartTemplateService extends ApiDataService {
  
  constructor(http: HttpClient,
              errorMessengerService: ErrorMessengerService) {
    super('SmartTemplates', http, errorMessengerService);
  }
  
  CreateTemplate(template: ISmartTemplate): Observable<ISmartTemplate> {
    return this.http.post(this.apiEndpointUrl, template).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error creating template`, template),
      ),
    );
  }
  
  CreateCategory(category: ISmartTemplateCategory): Observable<ISmartTemplateCategory> {
    return this.http.post(this.apiEndpointUrl + '/Category', category).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error creating category`, category),
      ),
    );
  }
  
  GetCategoriesByCompanyId(companyId?: number, excludeUserId?: number, isPublic = false): Observable<ISmartTemplateCategory[]> {
    return this.http.get(this.apiEndpointUrl + '/Categories/ByCompany/' + companyId + '/ExcludeUserId/' + excludeUserId + '/Public/' + isPublic).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error getting categories by company`, companyId),
      ),
    );
  }
  
  GetCategory(categoryId: number): Observable<ISmartTemplateCategory> {
    return this.http.get(this.apiEndpointUrl + '/Category/' + categoryId).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error getting category`, categoryId),
      ),
    );
  }
  
  GetTemplatesByCategoryId(categoryId: number): Observable<ISmartTemplate[]> {
    return this.http.get(this.apiEndpointUrl + '/ByCategory/' + categoryId).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error getting templates for category`, categoryId),
      ),
    );
  }
  
  GetCategoriesForParent(parentCategoryId: number): Observable<ISmartTemplateCategory[]> {
    return this.http.get(this.apiEndpointUrl + '/Categories/ByParent/' + (parentCategoryId === 0 ? '' : parentCategoryId)).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error getting categories`, parentCategoryId),
      ),
    );
  }
  
  GetCategoriesByUser(userId: number): Observable<ISmartTemplateCategory[]> {
    return this.http.get(this.apiEndpointUrl + '/Categories/ByUser/' + userId).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error getting categories for user`, userId),
      ),
    );
  }
  
  UpdateTemplate(template: ISmartTemplate): Observable<ISmartTemplate> {
    return this.http.put(this.apiEndpointUrl, template).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error updating template`, template),
      ),
    );
  }
  
  UpdateCategory(category: ISmartTemplateCategory): Observable<ISmartTemplateCategory> {
    return this.http.put(this.apiEndpointUrl + '/Category', category).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error updating category`, category),
      ),
    );
  }
}
