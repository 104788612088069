export enum AutoGenType {
  Create = 'type-create',
  Add = 'type-add',
  Populate = 'type-populate',
  Submit = 'type-submit',
  Receive = 'type-receive',
  Store = 'type-store',
  Use = 'type-use',
  Navigate = 'type-navigate',
  Show = 'type-show',
  Hide = 'type-hide',
  Value = 'type-value',
}
