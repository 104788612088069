import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss'],
})
export class CustomSnackbarComponent {
  
  icon = null;
  message = '';
  action = null;
  showDurationBar = false;
  class = '';
  duration = 0;
  remainingDuration = 0;
  percent = 100;
  
  successIcons = [
    'person_add',
    'check_circle',
    'add_circle',
    'cloud_done',
    'library_add',
    'wifi',
    'save',
  ];
  warningIcons = [
    'wifi_off',
    'warning',
    'vpn_key_alert',
    'block',
    'laps',
  ];
  errorIcons = [
    'do_not_disturb_on',
    'do_not_disturb_off',
    'front_hand',
    'unpublished',
    'error',
  ];
  
  constructor(public snackBarRef: MatSnackBarRef<CustomSnackbarComponent>,
              @Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.icon = data.icon;
    this.message = data.message;
    this.duration = data.duration;
    this.remainingDuration = data.duration;
    this.action = data.action;
    
    if (this.successIcons.includes(this.icon)) {
      this.class = 'success';
    } else if (this.warningIcons.includes(this.icon)) {
      this.class = 'warning';
    } else if (this.errorIcons.includes(this.icon)) {
      this.class = 'error';
    }
    
    if (this.action) {
      this.showDurationBar = data.showDurationBar;
      this.UpdateRemainingTime();
    }
  }
  
  Action() {
    this.action.callback();
    this.snackBarRef.dismiss();
  }
  
  UpdateRemainingTime() {
    let durationInterval = setInterval(() => {
      this.remainingDuration = this.remainingDuration - 100;
      const percent = (this.remainingDuration * 100) / this.duration;
      this.percent = percent;
      
      if (this.remainingDuration <= 0) {
        clearInterval(durationInterval);
        durationInterval = null;
      }
    }, 100);
  }
}
