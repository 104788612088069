import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cobble-stages',
  templateUrl: './cobble-stages.component.html',
  styleUrls: ['./cobble-stages.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [style({ opacity: 0 }), animate(500)]),
      transition('* => void', [animate(200, style({ opacity: 0 }))]),
    ]),
  ],
})
export class CobbleStagesComponent implements OnInit {
  @Input() stages: any[];
  
  constructor() {
    this.stages = [];
  }
  
  ngOnInit() {
  }
  
  addStage(input: HTMLInputElement) {
    this.stages.splice(this.stages.length, 0, { name: input.value });
    input.value = '';
  }
  
  removeStage(item) {
    const index = this.stages.indexOf(item);
    this.stages.splice(index, 1);
  }
}
