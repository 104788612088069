import { Pipe, PipeTransform } from '@angular/core';
import { RepresentativeMolecule } from './../../shared/representative-molecule/interfaces/representative-molecule';

@Pipe({
  name: 'filterBySubparent',
})
export class FilterBySubparentPipe implements PipeTransform {
  transform(children: RepresentativeMolecule[], subparentId: number): any {
    // console.log(children, subparentId);
    
    return children.filter(c => c.SubParentId === subparentId);
  }
}
