import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { ApiAuthService } from '../../core/services/api-auth.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { ToolsService } from '../../core/services/tools.service';
import { SnackerService } from '../../shared/services/snacker.service';

@Component({
  selector: 'app-externalLogin',
  templateUrl: './externalLogin.component.html',
  styleUrls: ['./externalLogin.component.scss'],
})
export class ExternalLoginComponent implements OnInit {
  constructor(
    private adalSvc: MsAdalAngular6Service,
    private authService: ApiAuthService,
    private localStorageService: LocalStorageService,
    private clientStorageService: ClientStorageService,
    private router: Router,
    private snackerService: SnackerService,
    private toolsService: ToolsService,
    private dialogService: GenericDialogService,
  ) {
    console.log('external aad');
    if (!(self === top)) {
      self.close();
      return;
    }
    
    if (this.adalSvc.isAuthenticated) {
      setTimeout(() => {
        // console.log('azure user', this.adalSvc.userInfo);
        this.authService.externalLogin(this.adalSvc.accessToken).subscribe(
          (respons) => {
            const response = <any>respons;
            if (response.successful) {
              this.clientStorageService.set2faLogged();
              this.authService.userRole = response.user.roleId;
              if (this.localStorageService.Get('aad_redirect_uri')) {
                const redirectUri = this.localStorageService.Get('aad_redirect_uri');
                this.localStorageService.Remove('aad_redirect_uri');
                window.location.href = redirectUri;
              } else {
                (window.location as any) = `${ this.toolsService.GetDomain() }/dashboard`;
              }
            } else {
              this.dialogService.OpenConfirmDialog({
                title: 'Sign In Error',
                message: response.message,
                confirmText: 'Ok',
                cancelText: '',
              }).then((result) => this.router.navigate(['/']));
            }
          },
          (error) => {
            this.snackerService.ShowMessageOnBottom('Error login in with Azure Active Directory', 'error', 4000);
            this.router.navigate(['/login']);
          },
        );
      }, 10);
    } else {
      this.snackerService.ShowMessageOnBottom('Error login in with Azure Active Directory', 'error', 4000);
      this.router.navigate(['/login']);
    }
  }
  
  ngOnInit() {
  }
}
