import { HttpEventType } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import * as fileSaver from 'file-saver';
import { Subscription } from 'rxjs/internal/Subscription';
import { Permissions } from '../../admin/models/permissions.enum';
import { ApiSnapshotsService } from '../../core/services/api-snapshots.service';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { UserMenuService } from '../../core/services/user-menu.service';
import { LeapXLFileFormat } from '../../shared/enums/leapxl-file-format.enum';
import { Snapshot } from '../../shared/interfaces/snapshot.interface';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { DraggableWindowService } from '../../shared/services/draggable-window.service';
import { SnackerService } from '../../shared/services/snacker.service';

@Component({
  selector: 'app-snapshots',
  templateUrl: './snapshots.component.html',
  styleUrls: ['./snapshots.component.scss'],
})
export class SnapshotsComponent implements OnInit, OnDestroy {
  snapshots: Snapshot[] = [];
  archivedApps = false;
  managePermission = false;
  removeArchivedPermission = false;
  downloadSnapshot = false;
  snapshotToDownload: Snapshot = null;
  preparingDownload = false;
  downloading = false;
  downloadingProgress = 0;
  downloadPassword = '';
  preparingClone = false;
  snapshotToClone: Snapshot = null;
  cloneNewName = '';
  preparingArchive = false;
  snapshotToArchive: Snapshot = null;
  archiveDescription = '';
  restoreRunning = false;
  cloneRunning = false;
  subscriptions = new Subscription();
  
  constructor(
    public cobbleService: CobbleService,
    private dialogService: GenericDialogService,
    private userMenuService: UserMenuService,
    private snackerService: SnackerService,
    private communicationService: CommunicationService,
    private snapshotService: ApiSnapshotsService,
    private draggableWindowService: DraggableWindowService,
  ) {
    const data = this.draggableWindowService.GetData();
    this.archivedApps = data.archivedApps;
  }
  
  ngOnInit(): void {
    this.LoadSnapshots();
    this.removeArchivedPermission = this.userMenuService.checkPermission(Permissions.RemoveArchivedSnapshot);
    this.subscriptions.add(
      this.communicationService.Event.Editor.WorkArea.$SnapshotSaved.subscribe(() => {
        console.log('=event=');
        this.LoadSnapshots();
      }),
    );
  }
  
  LoadSnapshots() {
    if (this.archivedApps) {
      this.snapshotService.GetArchivedSnapshots().subscribe(snapshots => {
        this.snapshots = snapshots.reverse();
      });
    } else {
      this.snapshotService.GetSnapshotsForApplication().subscribe(snapshots => {
        this.snapshots = snapshots.reverse();
      });
    }
  }
  
  ArchiveSnapshot(snapshot: Snapshot) {
    this.snapshotToArchive = snapshot;
    this.archiveDescription = '';
    this.preparingArchive = true;
  }
  
  StartArchive() {
    this.snapshotService.ToggleArchivedSnapshot(this.snapshotToArchive.id, this.archiveDescription).subscribe(result => {
      this.snackerService.ShowMessageOnBottom('Snapshot archived', 'backup', null, true);
      this.StopArchive();
      this.LoadSnapshots();
    });
  }
  
  RemoveSnapshot(snapshot: Snapshot) {
    this.dialogService
    .OpenConfirmDialog({
      title: 'Delete Snapshot',
      message: `Are you sure you want to remove this Snapshot?, once removed this operation can't be undone.`,
      confirmText: 'Delete',
      cancelText: 'Cancel',
    })
    .then(result => {
      if (result) {
        this.snapshotService.DeleteSnapshot(snapshot.id).subscribe(reposnse => {
          this.snackerService.ShowMessageOnBottom('Snapshot removed', 'cloud_off', null, true);
          this.LoadSnapshots();
        });
      } else {
      }
    });
  }
  
  RestoreSnapshot(snapshot: Snapshot) {
    this.restoreRunning = true;
    this.snapshotService.RestoreSnapshot(snapshot).subscribe(result => {
      this.restoreRunning = false;
      setTimeout(() => {
        this.communicationService.Event.Editor.DataSource.$ReloadDataSourcePanel.emit();
      }, 500);
    });
  }
  
  CloneSnapshot(snapshot: Snapshot) {
    this.preparingClone = true;
    this.snapshotToClone = snapshot;
  }
  
  StartClone() {
    this.cloneRunning = true;
    setTimeout(() => {
      this.StopClone();
    }, 200);
    
    this.communicationService.Event.Editor.WorkArea.$ShowLoadingOverlay.emit({
      display: true,
      showSpinner: true,
      iconAnimated: true,
      spinnerType: 'bar',
      message: `Cloning Application...`,
      icon: 'content_copy',
      iconColor: 'mediumseagreen',
    });
    this.snapshotService.CloneSnapshot(this.snapshotToClone.id, this.cloneNewName).subscribe(response => {
      this.communicationService.Event.Editor.WorkArea.$HideLoadingOverlay.emit();
      this.snackerService.ShowMessageOnBottom(`Application cloned successfully`, 'content_copy');
      this.communicationService.Event.System.Update.$UpdateDashboardApps.emit(true);
      this.cloneRunning = false;
    });
  }
  
  StopClone() {
    this.preparingClone = false;
    this.snapshotToClone = null;
    this.cloneNewName = '';
  }
  
  StopArchive() {
    this.snapshotToArchive = null;
    this.archiveDescription = '';
    this.preparingArchive = false;
  }
  
  DownloadSnapshot(snapshot: Snapshot) {
    this.downloadSnapshot = true;
    this.snapshotToDownload = snapshot;
  }
  
  ToggleDescription(snapshot: Snapshot) {
    snapshot.displayDescription = !snapshot.displayDescription;
  }
  
  Download() {
    this.preparingDownload = true;
    this.snapshotService.DownloadSnapshot(this.snapshotToDownload.id, this.downloadPassword).subscribe(
      response => {
        switch (response.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            this.preparingDownload = false;
            this.downloading = true;
            this.downloadingProgress = 0;
            break;
          case HttpEventType.DownloadProgress:
            const percent = Math.round(100 * (response.loaded / response.total));
            this.downloadingProgress = percent;
            break;
          case HttpEventType.Response:
            setTimeout(() => {
              this.downloading = false;
            }, 1500);
            fileSaver.saveAs(
              response.body,
              `${ this.archivedApps ? this.snapshotToDownload.applicationName : this.cobbleService.Cobble.properties.name } Snapshot-${ this.snapshotToDownload.sequence }-${ this.snapshotToDownload.version }` + `.${ LeapXLFileFormat.Application }`,
            );
            this.snackerService.ShowMessageOnBottom('Snapshot download completed!', 'download', 3000, true);
            
            setTimeout(() => {
              this.StopDownload();
            }, 1500);
            break;
        }
      },
      error => {
        this.snackerService.ShowMessageOnBottom('Error downloading snapshot', 'file_download_off');
      },
      () => {
      },
    );
  }
  
  StopDownload() {
    this.downloadSnapshot = false;
    this.downloadingProgress = 0;
    this.downloadPassword = '';
    this.snapshotToDownload = null;
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
