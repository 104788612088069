import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class CustomIconsService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
  }
  
  init() {
    this.matIconRegistry.addSvgIcon(
      'hierarchy',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/hierarchy.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'logout',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/logout.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'cobbler_logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/cobbler_logo.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'asterisk',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/asterisk.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'email_white',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/email_white.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'lock-lock',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/lock-lock.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'logo_white',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/logo_white.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'show-eye',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/show-eye.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'hide-eye',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/hide-eye.svg',
      ),
    );
  }
}
