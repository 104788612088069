export enum ChartType {
  AreaStacked = 'lineStackedAreaChart',
  Area = 'lineAreaChart',
  AreaNormalized = 'lineNormalizedAreaChart',
  AreaLine = 'lineChart',
  BarNormalizedHorizontal = 'normalizedHorizontalBarChart',
  BarStackedVertical = 'stackedVerticalBarChart',
  BarVertical = 'verticalBarChart',
  BarGroupedHorizontal = 'groupedHorizontalBarChart',
  BarNormalizedVertical = 'normalizedVerticalBarChart',
  BarStackedHorizontal = 'stackedHorizontalBarChart',
  BarGroupedVertical = 'groupedVerticalBarChart',
  BarHorizontal = 'horizontalBarChart',
  HeatMap = 'heatMapChart',
  PieGrid = 'pieGridChart',
  PieAdvanced = 'advancedPieChart',
  Pie = 'pieChart',
  Gauge = 'gaugeChart',
  PolarRadar = 'polarRadarChart',
  LinearGauge = 'linearGaugeChart',
  NumberCard = 'numberCardChart',
  TreeMap = 'treeMapChart',
  Funnel = 'funnelChart',
  SparkLine = 'sparkLineChart',
  LineReferences = 'lineReferencesChart',
  Combo = 'comboChart',
}
