import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';
import { MoleculeRequest } from '../../workarea/interfaces/molecule-request';
import { ApiDataService } from './api-data.service';
import { ClientStorageService } from './client-storage.service';

@Injectable()
export class ApiMoleculesService extends ApiDataService {
  
  private pauseDelete = false;
  private deleteCache = [];
  
  constructor(
    http: HttpClient,
    errorMessengerService: ErrorMessengerService,
    private clientStorageService: ClientStorageService,
    private communicationService: CommunicationService,
    protected cobbleService: CobbleService,
  ) {
    super('molecules', http, errorMessengerService);
  }
  
  PauseDelete() {
    this.pauseDelete = true;
  }
  
  ResumeDelete() {
    this.pauseDelete = false;
    const moleculesToDelete = [...new Set(this.deleteCache)];
    this.deleteCache = [];
    this.DeleteRepresentativeMolecule(moleculesToDelete)
    .subscribe();
  }
  
  CreateCobble(properties: any[]) {
    return this.http
    .post(this.apiEndpointUrl, {
      cobbleId: null,
      parentId: null,
      moleculeTemplateId: null,
      properties: properties,
    })
    .pipe(
      map((result) => <any>result),
      catchError((error) => this.errorMessengerService.HandleError(error, `Error creating App.`, properties)),
    );
  }
  
  GetApplicationsData(appIds: number[]) {
    return this.http.post(this.apiEndpointUrl + `/ApplicationsData`, appIds)
    .pipe(
      map((response) => <any>response),
      catchError(
        (error) => this.errorMessengerService.HandleError(error, `Error obtainig apps data.`, appIds)),
    );
  }
  
  GetApplicationsBasicInfo(appIds: number[]) {
    return this.http.post(this.apiEndpointUrl + `/ApplicationsBasicInfo`, appIds)
    .pipe(
      map((response) => <any>response),
      catchError(
        (error) => this.errorMessengerService.HandleError(error, `Error obtainig apps info.`, appIds)),
    );
  }
  
  CreateComponent(name: string, molecules: any, isStandardComponent = false) {
    return this.http
    .post(this.apiEndpointUrl + `/AddComponent`, {
      name,
      molecules,
      isStandardComponent,
    })
    .pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error creating component ${ name }.`, {
          name,
          molecules,
        }),
      ),
    );
  }
  
  SetParent(repMoleculeId: number, parentRepMoleculeId: number) {
    return this.http.post(
      `${ this.apiEndpointUrl }/parentId/${ parentRepMoleculeId }/moleculeId/${ repMoleculeId }`,
      {})
    .pipe(
      map((result) => <any>result),
      catchError((error) => this.errorMessengerService.HandleError(error, `Error assigning parent`)),
    );
  }
  
  GetMolecule(
    cobbleId: number,
    parentId: number,
    moleculeTemplateId: number,
    moleculeId: null,
    properties: {
      name: string;
      value: any;
      path: string;
    }[],
    childrenProperties: {
      name: string;
      value: any;
      path: string;
    }[],
    creationFromTemplate = false,
    placeholders = [],
  ) {
    return this.http
    .post(this.apiEndpointUrl, {
      cobbleId: cobbleId,
      parentId: parentId,
      moleculeId,
      moleculeTemplateId: moleculeTemplateId,
      childrenProperties,
      properties: properties,
      creationFromTemplate,
      placeholders,
    })
    .pipe(
      map((result) => {
        setTimeout(() => {
          this.communicationService.Event.Editor.$MoleculeAdded.emit(0);
        }, 1000);
        return result as any;
      }),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error adding element to App ${ cobbleId }.`, {
          cobbleId: cobbleId,
          parentId: parentId,
          moleculeTemplateId: moleculeTemplateId,
          properties: properties,
        }),
      ),
    );
  }
  
  GetFormMolecules(molecules: MoleculeRequest[]) {
    // console.log(molecules);
    return this.http.post(this.apiEndpointUrl + '/WithChildren', molecules,
      this.AddBackgroundTask('Generating form', 'post_add'))
    .pipe(
      map((result) => {
        setTimeout(() => {
          this.communicationService.Event.Editor.$MoleculeAdded.emit(0);
        }, 1000);
        return result as any;
      }),
      catchError(
        (error) => this.errorMessengerService.HandleError(error, `Error getting elements.`, molecules)),
    );
  }
  
  AutogenerateMolecules(molecules: MoleculeRequest[]) {
    console.log(molecules);
    return this.http.post(this.apiEndpointUrl + '/WithChildren', molecules,
      this.AddBackgroundTask('Generating Application', 'sound_sampler'))
    .pipe(
      map((result) => {
        setTimeout(() => {
          this.communicationService.Event.Editor.$MoleculeAdded.emit(0);
        }, 1000);
        return result as any;
      }),
      catchError(
        (error) => this.errorMessengerService.HandleError(error, `Error getting elements.`, molecules)),
    );
  }
  
  GetMolecules(molecules: MoleculeRequest[]) {
    // console.log(molecules);
    return this.http.post(this.apiEndpointUrl + '/WithChildren', molecules)
    .pipe(
      map((result) => {
        setTimeout(() => {
          this.communicationService.Event.Editor.$MoleculeAdded.emit(0);
        }, 1000);
        return result as any;
      }),
      catchError(
        (error) => this.errorMessengerService.HandleError(error, `Error getting elements.`, molecules)),
    );
  }
  
  GetChildren(parentId: number) {
    return this.http.get(`${ this.apiEndpointUrl }/GetChildren/${ parentId }`)
    .pipe(
      map((result) => <any>result),
      catchError((error) => this.errorMessengerService.HandleError(error, 'Error getting')),
    );
  }
  
  ChangeCobbleStage(stageId: number, cobbleId = 0, replaceDataSource = false, styles = []): Observable<any> {
    if (this.RunningMode) {
      return;
    }
    // console.log('updating App stage...', stageId);
    
    return this.http
    .post(this.apiEndpointUrl + '/ChangeCobbleStage', {
      cobbleId: cobbleId === 0 ? this.cobbleService.Cobble.id : cobbleId,
      stageId: stageId,
      replaceDataSource,
      styles,
    }, this.AddBackgroundTask('Publishing App', 'rocket_launch'))
    .pipe(
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error publishign App ${ cobbleId }.`, {
          cobbleId: cobbleId === 0 ? this.cobbleService.Cobble.id : cobbleId,
          stageId: stageId,
        }),
      ),
    );
  }
  
  GetCobbleStages(cobbleId: number): Observable<any> {
    if (this.RunningMode) {
      return;
    }
    return this.http
    .get(this.apiEndpointUrl + '/CobbleStages/' + cobbleId)
    .pipe(
      catchError(
        (error) => this.errorMessengerService.HandleError(error, `Error getting App ${ cobbleId } Stages.`)));
  }
  
  GetCobbleSharedNodes(cobbleId: number): Observable<any> {
    if (this.RunningMode) {
      return;
    }
    return this.http
    .get(this.apiEndpointUrl + '/SharedNodes/' + cobbleId)
    .pipe(catchError(
      (error) => this.errorMessengerService.HandleError(error,
        `Error getting Shared nodes for App ${ cobbleId }.`,
        cobbleId)));
  }
  
  saveCobbletShare(resource: any[]) {
    return this.http.post(this.apiEndpointUrl + `/sharecobble/`, resource)
    .pipe(
      map((response) => <any>response),
      catchError(
        (error) => this.errorMessengerService.HandleError(error, `Error sharing element.`, resource)),
    );
  }
  
  updateCobbleSharing(resource) {
    return this.http
    .put(this.apiEndpointUrl + '/setcobbleispublicstate', resource)
    .pipe(catchError(
      (error) => this.errorMessengerService.HandleError(error, `Error setting App public state.`, resource)));
  }
  
  addCompoundMolecule(resource) {
    return this.http
    .post(this.apiEndpointUrl + `/addcompoundmolecule/`, resource)
    .pipe(catchError((error) => this.errorMessengerService.HandleError(error)));
  }
  
  saveSectionLibraryOrder(resource) {
    return this.http
    .post(this.apiEndpointUrl + `/ReorderSectionObjects/`, resource)
    .pipe(
      catchError((error) => this.errorMessengerService.HandleError(error`Error reordering library section`)));
  }
  
  ResetFirstTimeAccess(data: any) {
    return this.http
    .post(this.apiEndpointUrl + `/ResetFirstAccess/`, data)
    .pipe(catchError((error) => this.errorMessengerService.HandleError(error`Error resetting`)));
  }
  
  getPublishedCobbles(cobbleId: number): Observable<any[]> {
    return this.http.get(this.apiEndpointUrl + `/publishedcobbles/${ cobbleId }`)
    .pipe(
      map((response) => <any[]>response),
      catchError(
        (error) => this.errorMessengerService.HandleError(error, `Error getting Published Apps.`, cobbleId)),
    );
  }
  
  DeleteRepresentativeMolecule(molecules: number[], isApplication = false) {
    
    if (this.pauseDelete) {
      this.deleteCache = [...this.deleteCache, ...molecules];
      return of({});
    }
    
    return this.http.put(this.apiEndpointUrl + '/delete', molecules,
      isApplication ? this.AddBackgroundTask('Deleting Application', 'delete_forever') : {})
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError(
        (error) => this.errorMessengerService.HandleError(error,
          `Error deleting elements: ${ molecules.join() }.`,
          molecules)),
    );
  }
  
  CloneCobble(cobbleId: number, newAppName: string, spreadSheetsData = []) {
    return this.http.post(this.apiEndpointUrl + '/CloneCobble',
      { cobbleId: cobbleId, spreadSheetsData, newAppName },
      this.AddBackgroundTask('Cloning Application', 'file_copy'))
    .pipe(
      map((response) => <any>response),
      catchError(
        (error) => this.errorMessengerService.HandleError(error, `Error clonning App ${ cobbleId }.`,
          cobbleId)),
    );
  }
  
  GetRepMolecule(id: number) {
    return this.http.get(this.apiEndpointUrl + `/${ id }`)
    .pipe(
      map((response) => <any>response),
      catchError(
        (error) => this.errorMessengerService.HandleError(error, `Error getting element: ${ id }.`, id)),
    );
  }
  
  GetAppSpreadsheetDataInfo(appId: number) {
    return this.http.get(this.apiEndpointUrl + `/SpreadsheetsData/${ appId }`)
    .pipe(
      map((response) => <any>response),
      catchError((error) => this.errorMessengerService.HandleError(error,
        `Error getting spreadsheets info for app: ${ appId }.`)),
    );
  }
  
  Get(ids: number[]) {
    return this.http.post(this.apiEndpointUrl + '/Get', ids)
    .pipe(
      map((response) => <any>response),
      catchError(
        (error) => this.errorMessengerService.HandleError(error, `Error getting elements: ${ ids.join() }.`,
          ids)),
    );
  }
  
  GetParentWithChildren(moleculesIds: string[]) {
    return this.http.post(this.apiEndpointUrl + `/ParentsWithChildren`, moleculesIds)
    .pipe(
      map((response) => <any>response),
      catchError(
        (error) => this.errorMessengerService.HandleError(error, `Error getting elements`, moleculesIds)),
    );
  }
  
  GetViewRepresentativeMolecules(viewId: number): Observable<any[]> {
    return this.http.get(
      `${ this.apiEndpointUrl }/View/${ viewId }/applicationId/${ this.cobbleService.Cobble.id }`)
    .pipe(
      map((response) => <any>response),
      catchError(
        (error) => this.errorMessengerService.HandleError(error, `Error getting view elements`, viewId)),
    );
  }
  
  LockDeletion(appId: number, enable: boolean) {
    return this.http.put(this.apiEndpointUrl + `/LockDeletion/${ appId }/${ enable }`, {})
    .pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(
          error,
          `Error getting App ${ appId } information.`,
          null,
        ),
      ),
    );
  }
  
  CobbleData(cobbleId: number) {
    return this.http.get(this.apiEndpointUrl + `/CobbleData/${ cobbleId }`)
    .pipe(
      map((response) => <any>response),
      catchError(
        (error) => this.errorMessengerService.HandleError(error,
          `Error getting App ${ cobbleId } information.`,
          cobbleId)),
    );
  }
  
  GetAppPromise(id: number): Promise<any[]> {
    return new Promise((resolve) => {
      this.GetApp(id)
      .subscribe(molecules => resolve(molecules));
    });
  }
  
  GetApp(id: number): Observable<any[]> {
    return this.http.get(this.apiEndpointUrl + `/Application/${ id }`)
    .pipe(
      map((response) => <any>response),
      catchError(
        (error) => this.errorMessengerService.HandleError(error, `Error getting App ${ id }.`, id)),
    );
  }
  
  GetUserApplications() {
    return this.http.get(this.apiEndpointUrl + `/UserApplications/${ this.clientStorageService.getUserId() }`)
    .pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(
          error,
          `Error getting Apps for user ${ this.clientStorageService.getUserId() }.`,
          this.clientStorageService.getUserId(),
        ),
      ),
    );
  }
  
  GetUserComponents(): Observable<{
    id: number,
    name: string,
    owner: string,
    isComponentTemplate: boolean,
    guid: string,
    description: string,
    icon: string,
    previewDesktopUrl: string,
    previewMobileUrl: string
  }[]> {
    return this.http.get(this.apiEndpointUrl + `/UserComponents/${ this.clientStorageService.getUserId() }`)
    .pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(
          error,
          `Error getting components for user ${ this.clientStorageService.getUserId() }.`,
          this.clientStorageService.getUserId(),
        ),
      ),
    );
  }
  
  GetStandardApplications() {
    return this.http.get(this.apiEndpointUrl + `/StandardApplications`)
    .pipe(
      map((response) => <any>response),
      catchError((error) => this.errorMessengerService.HandleError(error, `Error getting standard Apps.`,
        this.clientStorageService.getUserId())),
    );
  }
  
  GetSharedApplications() {
    return this.http.get(
      this.apiEndpointUrl + `/SharedApplications/${ this.clientStorageService.getUserId() }`)
    .pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(
          error,
          `Error getting shared Apps for user ${ this.clientStorageService.getUserId() }.`,
          this.clientStorageService.getUserId(),
        ),
      ),
    );
  }
  
  createCobblet(cobblet: any) {
    return this.insert(cobblet);
  }
  
  getCompoundMoleculeCompatibility(id: number) {
    return this.http
    .get(this.apiEndpointUrl + `/CompoundMoleculeIncompatibility/` + id)
    .pipe(catchError((error) => this.errorMessengerService.HandleError(error)));
  }
  
  getElementCompoundMoleculeCompatibility(elementType = '') {
    return this.http
    .get(this.apiEndpointUrl + `/ElementTypeCompatibility/` + elementType)
    .pipe(catchError((error) => this.errorMessengerService.HandleError(error)));
  }
}
