import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BuilderService } from '../../../../core/builder/builder.service';
import { ProcessorService } from '../../../../core/molecular/services/processor.service';
import { WorkAreaService } from '../../../../workarea/workarea.service';
import { RepresentativeMoleculesType } from '../../../enums/representative-molecules-types.enum';
import { DragService } from '../../services/drag.service';
import { BaseMoleculeComponent } from '../base-molecule/base-molecule.component';

@Component({
  selector: 'app-iframe-molecule',
  templateUrl: './iframe-molecule.component.html',
  styleUrls: ['./iframe-molecule.component.scss'],
})
export class IframeMoleculeComponent extends BaseMoleculeComponent
  implements OnInit, OnDestroy {
  MoleculeType = 'Representative';
  Type = RepresentativeMoleculesType.Iframe;
  
  @ViewChild('iframeWrapper', { static: true })
  iframeWrapper: ElementRef;
  
  constructor(
    public builderService: BuilderService,
    public dragService: DragService,
    public workAreaService: WorkAreaService,
    public processorService: ProcessorService,
    bottomSheet: MatBottomSheet,
    public el: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
    super(bottomSheet, el, changeDetectorRef);
  }
  
  ngOnInit() {
    super.ngOnInit();
    this.context.Type = RepresentativeMoleculesType.Iframe;
    this.RefreshGridsterConfiguration();
    this.UpdateData();
  }
  
  AttachEditorEventListeners() {
    const dragoverEventListener = this.renderer.listen(this.iframeWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, true);
    });
    const dragleaveEventListener = this.renderer.listen(this.iframeWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, false);
    });
    const dropEventListener = this.renderer.listen(this.iframeWrapper.nativeElement, 'drop', (evt) => {
      this.DataDropped(evt);
    });
  }
  
  FireClickEvent(e: any) {
    this.FireRepresentativeMoleculeEvent('click');
  }
  
  UpdateData() {
    this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(this.context.ParentId);
    setTimeout(() => {
      if (this.context.Properties.source && this.context.Properties.source !== '' && (this.context.Value === null || this.context.Value === '')) {
        this.context.Value = this.context.Properties.source;
      }
      this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(this.context.ParentId);
      console.log('iframe test');
    }, 250);
  }
  
  ngOnDestroy() {
  }
}
