import { Constants } from '../../../shared/constants';
import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class DecodeMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    data?: any,
    dataElementsData?: any,
  ) {
    try {
      // IF MOLECULE IS ATTACHED
      if (this.ExistsRepMoleculeAttached) {
        data = this.RepMoleculeAttached.GetValue;
      }
      
      // IF DATA ELEMENTS ARE ATTACHED
      if (dataElementsData) {
        data = this.toolsService.ExtractValuesByType(dataElementsData).string || Constants.Defaults.DataItemValue;
      } else {
        data = data || '';
      }
      
      const decodeType = rule && rule.decodeType ? rule.decodeType : '';
      
      data = this.DataActionModifierApply(data, this.Modifier, { decodeType });
      
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    } catch (error) {
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  private Modifier(value: string, rule: { decodeType: string }): string {
    switch (rule.decodeType) {
      case 'b64':
        return this._toolService.DecodeBase64(value);
      case 'uri':
        return this._toolService.DecodeURI(value);
      case 'utf8':
        return this._toolService.DecodeUTF8(value);
      case 'utf16':
        return this._toolService.DecodeUTF16(value);
      case 'hex':
        return this._toolService.DecodeHEX(value);
      case 'ascii':
        return this._toolService.DecodeASCII(value);
      case 'html':
        return this._toolService.DecodeHTML(value);
      case 'xml':
        return this._toolService.DecodeXML(value);
      default:
        return value;
    }
  }
}
