import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSelect } from '@angular/material/select';
import { colorSets } from '@swimlane/ngx-charts';
import { cloneDeep, debounce } from 'lodash-es';
import { FileUploader } from 'ng2-file-upload';
import { NgxImageCompressService } from 'ngx-image-compress';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, flatMap, map, pairwise, startWith } from 'rxjs/operators';
import { ApiFileService } from '../../core/services/api-file.service';
import { EditorStateService } from '../../core/services/editor-state.service';
import { TemplateService } from '../../core/services/template.service';
import { ToolsService } from '../../core/services/tools.service';
import { Constants } from '../../shared/constants';
import { ChartType } from '../../shared/enums/chart-type.enum';
import { RepresentativeMoleculesType } from '../../shared/enums/representative-molecules-types.enum';
import { IRepresentativeMoleculeStyleData } from '../../shared/interfaces/rep-mol-style.interface';
import { RepresentativeMolecule } from '../../shared/representative-molecule/interfaces/representative-molecule';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { DraggableWindowManagerService } from '../../shared/services/draggable-window-manager.service';
import { DraggableWindowService, DraggableWindowType } from '../../shared/services/draggable-window.service';
import { ThematicService } from '../services/thematic.service';
import { WorkAreaService } from '../workarea.service';

declare const Grapick: any;

@Component({
  selector: 'app-style-properties',
  templateUrl: './style-properties.component.html',
  styleUrls: ['./style-properties.component.scss'],
})
export class StylePropertiesComponent implements OnInit, OnDestroy {
  @ViewChild('propertiesForm', { static: false })
  propertiesForm: NgForm;
  
  @ViewChild('headerLeftTextAlignment', { static: false })
  headerLeftTextAlignment: MatCheckbox;
  
  @ViewChild('headerCenterTextAlignment', { static: false })
  headerCenterTextAlignment: MatCheckbox;
  
  @ViewChild('headerRightTextAlignment', { static: false })
  headerRightTextAlignment: MatCheckbox;
  
  @ViewChild('gradientTypeSelector', { static: false })
  gradientTypeSelector: MatSelect;
  
  @ViewChild('gradientDirectionSelector', { static: false })
  gradientDirectionSelector: MatSelect;
  @ViewChild('grapick') grapick: ElementRef<HTMLDivElement>;
  uploader: FileUploader = new FileUploader({});
  style: IRepresentativeMoleculeStyleData = null;
  deviceType = 'desktop';
  disabled = false;
  editableProperties = [];
  Constants = Constants;
  RepresentativeMoleculesType = RepresentativeMoleculesType;
  ChartType = ChartType;
  ngxRepMoleculeChartType = '';
  ChartColorSets = colorSets;
  ignoreChanges = false;
  RefreshStyle = debounce(() => {
    if (this.style) {
      this.thematicService.SaveStyle(this.style);
      this.communicationService.Event.Editor.WorkArea.$RefreshStyles.emit(this.style.styleId);
    }
  }, 200);
  newStyle = false;
  representativeMoleculesType = [];
  sections = ['all', 'font', 'appearance', 'hover', 'frame'].sort();
  subscriptions = new Subscription();
  backgroundColorStyle = 'solid';
  gp = null;
  
  
  constructor(
    private cobbleService: CobbleService,
    private templateService: TemplateService,
    private draggableWindowService: DraggableWindowService,
    private communicationService: CommunicationService,
    private imageCompress: NgxImageCompressService,
    private fileService: ApiFileService,
    private thematicService: ThematicService,
    private editorStateService: EditorStateService,
    private workAreaService: WorkAreaService,
    private draggableWindowManagerService: DraggableWindowManagerService,
    private toolsService: ToolsService,
    private renderer: Renderer2
  ) {
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  
  ngOnInit(): void {
    this.Init();
  }
  
  Init() {
    this.subscriptions.unsubscribe();
    this.subscriptions = this.communicationService.Event.Editor.WorkArea.$RefreshPropertiesStyles.subscribe(() => {
      console.log('=event=');
      this.ignoreChanges = true;
      this.Init();
    });
    const data = this.draggableWindowService.GetData();
    if (data && data.newStyle) {
      this.newStyle = data.newStyle;
      this.representativeMoleculesType = Object.values(RepresentativeMoleculesType);
      
      this.style = {
        representativeMoleculeType: RepresentativeMoleculesType.Label,
        properties: cloneDeep(this.templateService.GetRepresentativeTemplateByType(RepresentativeMoleculesType.Label).properties),
        section: 'all',
        styleId: null,
        name: `New ${ RepresentativeMoleculesType.Label } Style`,
      };
    } else if (data && data.style) {
      this.style = data.style;
      this.backgroundColorStyle = this.style.properties.background.backgroundColor.includes('gradient') ? 'gradient' : 'solid';
      
        setTimeout(() => {
          if (this.editableProperties.includes('backgroundType')) {
            this.InitGradientPicker();
          }
        }, 500);
    }
    this.deviceType = this.cobbleService.Cobble.deviceType;
    
    this.editableProperties = this.templateService.GetEditablePropertiesForRepresentativeMoleculeType(this.style.representativeMoleculeType);
    if (this.newStyle) {
      this.style.properties = cloneDeep(this.templateService.GetRepresentativeTemplateByType(this.style.representativeMoleculeType).properties);
    } else if (this.style) {
      setTimeout(() => {
        Object.keys(this.propertiesForm.controls).forEach(key => {
          this.subscriptions.add(
            this.propertiesForm.form
            .get(key)
            .valueChanges.pipe(debounceTime(200), startWith(this.propertiesForm.controls[key].value), pairwise())
            .subscribe(([prev, next]: [any, any]) => {
              console.log('change: ', key, next);
              if (!this.ignoreChanges) {
                this.RefreshStyle();
              }
            }),
          );
        });
      }, 500);
    }
    
    if (this.style.representativeMoleculeType === RepresentativeMoleculesType.Chart && this.style.properties.chartLibrary === 'ngxCharts') {
      this.SetNgxChartType();
    }
    
    setTimeout(() => {
      this.ignoreChanges = false;
    }, 300);
  }
  
  ResetClicked(input) {
    input.clicked = false;
  }
  
  Deselect(input) {
    input.selectionStart = input.selectionEnd;
  }
  
  Select(event: MouseEvent) {
    const input = event.target as any;
    if (input.clicked) {
    } else {
      input.select();
      input.clicked = true;
    }
  }
  
  SetNgxChartType() {
    this.ngxRepMoleculeChartType = this.style.properties.responsive[this.deviceType].chartOptions.ngxChartType;
  }
  
  HeaderTextAlignmentChanged(alignment: string, e: any) {
    switch (alignment) {
      case 'left':
        this.headerRightTextAlignment.checked = false;
        this.headerCenterTextAlignment.checked = false;
        break;
      case 'center':
        this.headerLeftTextAlignment.checked = false;
        this.headerRightTextAlignment.checked = false;
        break;
      case 'right':
        this.headerLeftTextAlignment.checked = false;
        this.headerCenterTextAlignment.checked = false;
        break;
    }
  }
  
  SelectIcon(icon: string) {
    this.style.properties.icon.iconType = icon;
  }
  
  SetProperty(property: string, value: any, path: string = 'properties') {
    const object = this.toolsService.NavigateObjectByPath(path.split('.'), this.style.properties);
    if (object) {
      object[property] = value;
      this.RefreshStyle();
    }
  }
  
  selectFile() {
    document.getElementById('imageFileSelector').click();
  }
  
  fileSelected(eventData: any[]) {
    // console.log('file selected', eventData[0]);
    const file = eventData[0];
    const filename = file.name;
    const noCompressFileTypes = ['image/gif'];
    
    if (file) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageCompress.compressFile(event.target.result, -1, 60, 50).then(compressedImg => {
          this.toolsService
          .UrlToFile(file.size < 50000 || noCompressFileTypes.includes(file.type) ? event.target.result : compressedImg, filename, file.type)
          .then(imageFile => {
            this.fileService.uploadFile([imageFile], 1).subscribe(result => {
              if (this.style.representativeMoleculeType === RepresentativeMoleculesType.Image) {
                this.style.properties.source = result;
              } else {
                this.style.properties.background.backgroundImageUrl = result;
              }
            });
          });
        });
      };
      reader.readAsDataURL(file);
    }
  }
  
  SelectChart(chartType: string) {
    this.style.properties.chartType = chartType;
  }
  
  SaveStyle() {
    if (!this.newStyle) {
      return;
    }
    this.style.category = 'User';
    this.thematicService.SaveStyle(this.style);
    this.workAreaService.stylePropertiesWindow.Hide();
    this.workAreaService.stylePropertiesWindow = null;
    console.log(this.workAreaService.stylePropertiesWindow);
  }
  
  PreviewRepresentativeMoleculeStyle(style: IRepresentativeMoleculeStyleData, event: MouseEvent) {
    const repMoleculeTemplate = this.templateService.GetRepresentativeTemplateByType(style.representativeMoleculeType);
    const repMolecule = new RepresentativeMolecule(cloneDeep(repMoleculeTemplate));
    repMolecule.ApplyStyle([style]);
    
    this.draggableWindowService.OpenDraggableWindow(
      `Preview Style [${ style.representativeMoleculeType } - ${ style.section.toLocaleUpperCase() } - ${ style.name }]`,
      DraggableWindowType.PreviewRepresentativeMoleculeComponent,
      event,
      { repMolecule },
      true,
    );
  }
  
  ChangeGradientType(type: string) {
    this.gp.setType(type);
  }
  
  ChangeGradientDirection(direction: string) {
    this.gp.setDirection(direction);
  }
  
  ChangeBackgroundStyle() {
    if (this.backgroundColorStyle === 'gradient') {
      setTimeout(() => {
        this.InitGradientPicker();
      }, 500);
    } else {
      this.SetProperty('backgroundColor', 'white');
    }
  }
  
  SaveGradientBackground() {
    let gradientValue = this.gp.getSafeValue();
    gradientValue = this.toolsService.ReplaceHexWithRGBA(this.gp.getValue());
    if (gradientValue === '') {
      return;
    }
    this.SetProperty('backgroundColor', gradientValue === '' ? this.gp.getValue() : gradientValue, 'background');
  }
  
  InitGradientPicker() {
    if (this.backgroundColorStyle === 'gradient') {
      const guid = this.toolsService.GenerateGuid();
      this.renderer.setAttribute(this.grapick.nativeElement, 'id', `a${ guid }`);
      
      this.gp = new Grapick({ el: `#a${ guid }` });
      
      if (this.style.properties.background.backgroundColor.includes('gradient')) {
        const gradientParsed = this.toolsService.ParseGradient(this.style.properties.background.backgroundColor);
        
        if (gradientParsed[0]) {
          switch (gradientParsed[0].type) {
            case 'linear-gradient':
              this.gp.setType('linear');
              this.gradientTypeSelector.value = 'linear';
              break;
            case 'radial-gradient':
              this.gp.setType('radial');
              this.gradientTypeSelector.value = 'radial';
              break;
            
            default:
              this.gp.setType('linear');
              this.gradientTypeSelector.value = 'linear';
              break;
          }
          
          if (gradientParsed[0].orientation) {
            this.gradientDirectionSelector.value = gradientParsed[0].orientation.value;
            this.gp.setDirection(gradientParsed[0].orientation.value);
          } else {
            this.gradientDirectionSelector.value = 'top';
            this.gp.setDirection('top');
          }
          
          gradientParsed[0].colorStops.forEach(stop => {
            this.gp.addHandler(+stop.length.value, `rgb(${ stop.value[0] }, ${ stop.value[1] }, ${ stop.value[2] })`);
          });
        }
        
        // Handlers are color stops
        // gp.addHandler(50, this.elementFocused.Properties.background.backgroundColor);
      } else {
        this.gradientTypeSelector.value = 'linear';
        this.gradientDirectionSelector.value = 'top';
        this.gp.addHandler(50, this.style.properties.background.backgroundColor);
      }
      // Do stuff on change of the gradient
      this.gp.on('change', complete => {
        this.SaveGradientBackground();
      });
    }
  }
}
