import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { DraggableService } from '../../core/services/draggable.service';

@Directive({
  selector: '[appDraggableEmitter]',
})
export class DraggableEmitterDirective {
  public elementRef: ElementRef;
  @Input() dropZones: any[];
  
  constructor(elementRef: ElementRef,
              private draggableService: DraggableService) {
    this.elementRef = elementRef;
  }
  
  @HostListener('dragstart') onDragStart(ev) {
    this.draggableService.droppableZones$.next(this.dropZones);
  }
  
  @HostListener('dragend') onDragEnd() {
    this.draggableService.droppableZones$.next(null);
  }
}
