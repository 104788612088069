import { AfterViewInit, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FieldConfig } from '../../models/field-config.model';

@Component({
  selector: 'app-dynamic-autocomplete',
  template: `
		<div class="panel-viewer-body">
			<div [formGroup]="group">
				<mat-form-field>
					<input
						#input
						[attr.name]="config.name + config.label"
						(input)="inputValidator($event)"
						(focus)="focus()"
						(click)="focus()"
						(mouseover)="focus()"
						type="text"
						[matTooltipPosition]="config.tooltipPosition"
						#tooltip="matTooltip"
						[matTooltip]="config.tooltip"
						matTooltipClass="custom-element-tooltip"
						[placeholder]="config.placeholder"
						aria-label="Number"
						matInput
						[formControlName]="config.name"
						[matAutocomplete]="auto"
						[(ngModel)]="config.value"
					/>
					<mat-autocomplete
						autoActiveFirstOption
						#auto="matAutocomplete"
					>
						<mat-option
							*ngFor="let option of filteredOptions | async"
							[value]="option"
						>
							{{ option }}
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
			</div>
		</div>
  `,
  styleUrls: ['./dynamic-autocomplete.component.scss'],
})
export class DynamicAutocompleteComponent implements OnInit, AfterViewInit {
  @ViewChild('tooltip', { static: true }) tooltip: MatTooltip;
  @ViewChild('input', { static: true }) input: any;
  filteredOptions: Observable<string[]>;
  options: string[];
  config: FieldConfig;
  group: FormGroup;
  
  constructor(private renderer: Renderer2) {
  }
  
  ngOnInit() {
    this.options = [];
    this.config.options.forEach(option => {
      this.options.push(option.value);
    });
    
    const formControl = this.group.controls[this.config.name];
    this.filteredOptions = formControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
  }
  
  ngAfterViewInit(): void {
    if (this.input) {
      setTimeout(() => {
        this.input.nativeElement.focus();
      }, 700);
    }
  }
  
  public inputValidator(event: any) {
    const reg = new RegExp(this.config.mask, 'gm');
    
    if (!reg.test(event.target.value)) {
      this.input.nativeElement.value = event.target.value.replace(reg, '');
      this.group.get(this.config.name).setValue(this.input.nativeElement.value);
    }
  }
  
  focus() {
    this.tooltip.show();
  }
  
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    
    return this.options.filter(
      option => option.toLowerCase().indexOf(filterValue) === 0,
    );
  }
}
