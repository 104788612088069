import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WindowStoreService {
  
  private domain = 'LeapXL';
  private window = window as any;
  
  constructor() {
    if (this.window[this.domain]) {
      // exists
    } else {
      this.window[this.domain] = {};
    }
  }
  
  Store(name: string, value: any) {
    this.window[this.domain][name] = value;
  }
  
  Read(name: string) {
    return this.window[this.domain][name];
  }
}
