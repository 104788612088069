import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiDataSourcesService } from '../../core/services/api-data-sources.service';
import { ToolsService } from '../../core/services/tools.service';
import { DatasourceSharedType } from '../../shared/enums/datasource-shared-type.enum';
import { CommunicationService } from '../../shared/services/communication.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { SpreadsheetService } from '../../spreadsheet/spreadsheet.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-new-datasource-dialog',
  templateUrl: './new-datasource-dialog.component.html',
  styleUrls: ['./new-datasource-dialog.component.scss'],
})
export class NewDatasourceDialogComponent implements OnInit {
  datasourceForm: FormGroup;
  workBookName = '';
  sharedTypeSelected = 1;
  sharedtypes = [];
  
  constructor(
    public dialogRef: MatDialogRef<NewDatasourceDialogComponent>,
    private spreadsheetService: SpreadsheetService,
    private workAreaService: WorkAreaService,
    private dataSourceService: ApiDataSourcesService,
    private communicationService: CommunicationService,
    private toolsService: ToolsService,
    private snackerService: SnackerService,
  ) {
    Object.keys(DatasourceSharedType).filter(key => {
      if (!isNaN(Number(DatasourceSharedType[key]))) {
        this.sharedtypes.push({
          id: DatasourceSharedType[key],
          name: key,
        });
      }
    });
  }
  
  ngOnInit() {
    this.datasourceForm = new FormGroup({
      workBookNameInput: new FormControl('', Validators.required),
    });
  }
  
  CreateWorkBook() {
    this.dialogRef.close();
    
    this.dataSourceService.isLoading = true;
    setTimeout(() => {
      this.dataSourceService.CreateDataSource(this.workBookName, this.sharedTypeSelected).subscribe(result => {
        if (result.dataFile !== '') {
          // console.log('loading uploaded file');
          
          this.spreadsheetService.LoadDataSource(
            result.id,
            result.name,
            0,
            false,
            this.toolsService.b64toBlob(result.dataFile, 'application/vnd.ms-excel'),
          );
        }
        
        this.dataSourceService.isLoading = false;
        if (result) {
          this.communicationService.Event.Editor.DataSource.$ReloadDataSourcePanel.emit([]);
          // console.log('Upload Finished', result);
          this.snackerService.ShowMessageOnBottom(`File ${ this.workBookName } was created!`, 'note_add', null, true);
        } else {
          // console.log('Upload Failed');
        }
      });
    }, 200);
  }
}
