import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfig } from '../../models/field-config.model';

@Component({
  selector: 'app-dynamic-radio',
  template: `
    <div class="panel-viewer-body">
      <div [formGroup]="group">
        <mat-radio-group
          [formControlName]="config.name"
          [(ngModel)]="config.value"
        >
          <mat-radio-button
            *ngFor="let option of config.options"
            [value]="option"
          >
            {{ option }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  `,
  styleUrls: ['./dynamic-radio.component.css'],
})
export class DynamicRadioComponent {
  config: FieldConfig;
  group: FormGroup;

  constructor() {
  }
  
  ngOnInit() {
  }
}
