import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { uniqBy } from 'lodash-es';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { ApiAdminService } from '../../core/services/api-admin.service';
import { ApiAuthService } from '../../core/services/api-auth.service';
import { ApiCompaniesService } from '../../core/services/api-companies.service';
import { ApiMoleculesService } from '../../core/services/api-molecules.service';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { ToolsService } from '../../core/services/tools.service';
import { ConnectionStateService } from '../../shared/services/connection-state.service';
import { HubConnectionService } from '../../shared/services/hub-connection.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { SystemService } from '../../shared/services/system.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [trigger('scale', [
    
    transition(':enter', [style({ transform: 'scale(0.7)', opacity: 0 }),
      animate('270ms', style({ transform: 'scale(1)', opacity: 1 }))]),
    
    transition(':leave', [
      style({ transform: 'scale(1)', opacity: 1 }),
      animate('190ms', style({ transform: 'scale(0.5)', opacity: 0 })),
    
    ]),
  ]),
  ],
})
export class LoginComponent {
  @ViewChild('oneCodeInput', { static: false })
  oneCodeInput: any;
  @ViewChild('twoCodeInput', { static: false })
  twoCodeInput: any;
  @ViewChild('threeCodeInput', { static: false })
  threeCodeInput: any;
  @ViewChild('fourCodeInput', { static: false })
  fourCodeInput: any;
  @ViewChild('fiveCodeInput', { static: false })
  fiveCodeInput: any;
  @ViewChild('sixCodeInput', { static: false })
  sixCodeInput: any;
  
  userName: string;
  password: string;
  loading = false;
  authTypes = [];
  rememberMe = false;
  loginInitiated = false;
  bothTypesExists = false;
  authSettings = null;
  termsAgreed = false;
  tfaProcess = false;
  tfaType = 'email';
  loadingCode = false;
  tfaCode = '';
  tfaUser = null;
  
  constructor(
    private authService: ApiAuthService,
    private router: Router,
    private snackerService: SnackerService,
    private titleService: Title,
    private connectionStateService: ConnectionStateService,
    private localStorageService: LocalStorageService,
    private adalSvc: MsAdalAngular6Service,
    private adminService: ApiAdminService,
    private toolsService: ToolsService,
    private hubConnectionService: HubConnectionService,
    private companiesService: ApiCompaniesService,
    private moleculesService: ApiMoleculesService,
    private systemService: SystemService,
    private dialogService: GenericDialogService,
    private route: ActivatedRoute,
  ) {
    this.systemService.EvaluateNewInstallation();
    this.titleService.setTitle('LeapXL');
    
    if (this.authService.loggedIn()) {
      this.router.navigate(['/dashboard']);
    }
    
    // console.log(this.toolsService.DecompressString('EQTmQ==='));
    
    // if (this.adalSvc.isAuthenticated) {
    //   this.router.navigate(['/dashboard']);
    // }
    // else {
    //   this.authService.LogOutUser();
    // }
    
    this.adminService.AuthenticationSettings()
    .subscribe(response => {
      this.authSettings = response;
      
      if (this.authSettings.separateLogin) {
        if (this.router.url.includes('sso')) {
          this.authTypes = response.types.filter(t => t.type !== 'Native')
          .sort(this.toolsService.CompareValues('priority'));
          
          if (this.authTypes.length === 0) {
            this.router.navigate(['/login']);
          }
        } else {
          this.authTypes = response.types.filter(r => r.type === 'Native');
        }
      } else {
        if (this.router.url.includes('sso')) {
          this.router.navigate(['/login']);
        }
        this.authTypes = response.types.sort(this.toolsService.CompareValues('priority'));
        this.bothTypesExists = this.authTypes.length > 1 && !!this.authTypes.find(t => t.type === 'Native');
      }
    });
    
    console.log(this.localStorageService.Get('rememberMe'));
    const rememberMe = JSON.parse(this.localStorageService.Get('rememberMe'));
    try {
      const agreement = JSON.parse(this.localStorageService.Get('agreement'));
      if (agreement) {
        this.termsAgreed = true;
      }
    } catch (err) {
      this.termsAgreed = false;
    }
    
    if (rememberMe) {
      this.rememberMe = true;
      this.userName = rememberMe.username;
      this.password = rememberMe.password;
    }
  }
  
  login() {
    if (!this.termsAgreed) {
      return;
    }
    
    this.loginInitiated = false;
    this.loading = true;
    setTimeout(() => {
      if (!this.loginInitiated) {
        this.loading = false;
      }
    }, 2000);
    
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    this.authService.login(this.userName, this.password)
    .subscribe((result: any) => {
      result.enabled2FATypes = result.enabled2FATypes || [];
      
      if (result && result.enabled2FATypes.length === 0) {
        this.SetLoggedUser(result);
      } else if (result && result.enabled2FATypes.length > 0) {
        this.Init2FALogin(result);
      }
    });
  }
  
  Init2FALogin(user: any) {
    this.tfaProcess = true;
    this.tfaUser = user;
    this.tfaType = user.enabled2FATypes[0];
  }
  
  SetLoggedUser(result) {
    this.adminService.GetAntiforgery();
    this.authService.SetTermsOfServiceAgreement(this.termsAgreed)
    .subscribe();
    this.loginInitiated = true;
    this.loading = true;
    // console.log('login', result);
    
    const user = <any>result;
    const noEditCreateAppsPermissionRoleIds = 4;
    if (user) {
      this.systemService.SetSessionId(true);
      if (this.rememberMe) {
        this.localStorageService.Set(
          'rememberMe',
          JSON.stringify({
            username: this.userName,
            password: this.password,
          }),
        );
      } else {
        this.localStorageService.Remove('rememberMe');
      }
      
      this.localStorageService.Remove('aad_redirect_uri');
      this.authService.userRole = user.roleId;
      
      let userApps = [];
      let requestResponse = 0;
      
      // if (user.roleId >= noEditCreateAppsPermissionRoleIds) {
      //
      //   this.companiesService.GetCobblesByCompanies().subscribe((companies: any[]) => {
      //     requestResponse += 1;
      //
      //     companies.forEach(c => {
      //       userApps = userApps.concat(c.cobbles);
      //     });
      //
      //     if (requestResponse === 3) {
      //       this.EvaluateAppsForNotPermissionUser(userApps);
      //     }
      //
      //   });
      //
      //   this.moleculesService.GetUserCobbles().subscribe((cobbles: any[]) => {
      //     requestResponse += 1;
      //
      //     userApps = userApps.concat(cobbles);
      //
      //     if (requestResponse === 3) {
      //       this.EvaluateAppsForNotPermissionUser(userApps);
      //     }
      //   });
      //
      //   this.moleculesService.GetSharedCobbles().subscribe((departments: any[]) => {
      //     requestResponse += 1;
      //
      //     departments.forEach(d => {
      //       userApps = userApps.concat(d.cobbles);
      //     });
      //
      //     if (requestResponse === 3) {
      //       this.EvaluateAppsForNotPermissionUser(userApps);
      //     }
      //   });
      //
      // }
      // else {
      // }
      
      // this.hubConnectionService.SendUserLogged(this.systemService.SessionId);
      if (this.authService.EvaluateDefaultApp()) {
        // redirect to app
      } else {
        this.router.navigate(['/dashboard']);
      }
    } else {
      this.loading = false;
      this.loginInitiated = false;
      // console.log('Error login in');
      this.snackerService.ShowMessageOnBottom('Error login in', 'vpn_key_alert');
    }
  }
  
  EvaluateAppsForNotPermissionUser(userApps: any[]) {
    console.log(userApps);
    userApps = uniqBy(userApps, 'id');
    console.log(userApps);
    
    if (userApps.length === 1) {
      this.RunApp(userApps[0]);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }
  
  RunApp(app: any) {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    window.location.href = `${ document.location.protocol }//${ document.location.hostname }${
      document.location.hostname.search('localhost') > -1 ? ':4200' : ''
    }/run/${ app.companySlug }/${ app.slug }`;
  }
  
  AADLogin(e: any) {
    if (!this.termsAgreed) {
      return;
    }
    
    e.preventDefault();
    
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    this.adalSvc.login();
  }
  
  setADUserCredentials() {
    this.adalSvc.acquireToken('https://graph.microsoft.com')
    .subscribe((t: string) => {
      // console.log(this.adalSvc.userInfo);
    });
  }
  
  SetAgreement(agree: boolean) {
    this.termsAgreed = agree;
    this.localStorageService.Set('agreement', JSON.stringify(this.termsAgreed));
  }
  
  ReadAgreements() {
    this.dialogService.OpenConfirmDialog({
      title: 'Terms of Service',
      message: this.systemService.termsOfService,
      confirmText: 'Agreed',
      cancelText: 'I do not agree',
      maxWidth: '700px',
      height: '250px',
      fontSize: '12px',
    })
    .then(result => this.SetAgreement(result));
  }
  
  EvaluateDeleteCodeInput(event: any) {
    const key = event.keyCode || event.charCode;
    
    if (key === 8) {
      if (event.target.value === '') {
        const input = event.target.classList[0];
        
        switch (input) {
          case 'two':
            this.FocusInputCode('one');
            break;
          case 'three':
            this.FocusInputCode('two');
            break;
          case 'four':
            this.FocusInputCode('three');
            break;
          case 'five':
            this.FocusInputCode('four');
            break;
          case 'six':
            this.FocusInputCode('five');
            break;
          default:
            this.GetCode();
            break;
        }
      }
    }
  }
  
  EvaluateCodeInput(event: any) {
    console.log(event);
    
    event.target.value = event.target.value.replace(/\D/g, '')
    .charAt(0);
    
    const input = event.target.classList[0];
    
    if (event.target.value.length > 0) {
      switch (input) {
        case 'one':
          this.FocusInputCode('two');
          break;
        case 'two':
          this.FocusInputCode('three');
          break;
        case 'three':
          this.FocusInputCode('four');
          break;
        case 'four':
          this.FocusInputCode('five');
          break;
        case 'five':
          this.FocusInputCode('six');
          break;
        case 'six':
          this.LoadCode();
          break;
        default:
          break;
      }
    }
  }
  
  FocusInputCode(inputNumber: string) {
    switch (inputNumber) {
      case 'one':
        this.oneCodeInput.nativeElement.focus();
        this.oneCodeInput.nativeElement.select();
        break;
      case 'two':
        this.twoCodeInput.nativeElement.focus();
        this.twoCodeInput.nativeElement.select();
        break;
      case 'three':
        this.threeCodeInput.nativeElement.focus();
        this.threeCodeInput.nativeElement.select();
        break;
      case 'four':
        this.fourCodeInput.nativeElement.focus();
        this.fourCodeInput.nativeElement.select();
        break;
      case 'five':
        this.fiveCodeInput.nativeElement.focus();
        this.fiveCodeInput.nativeElement.select();
        break;
      case 'six':
        this.sixCodeInput.nativeElement.focus();
        this.sixCodeInput.nativeElement.select();
        break;
      
      default:
        break;
    }
    
    setTimeout(() => {
      this.GetCode();
    }, 100);
  }
  
  GetCode() {
    this.tfaCode =
      this.oneCodeInput.nativeElement.value +
      this.twoCodeInput.nativeElement.value +
      this.threeCodeInput.nativeElement.value +
      this.fourCodeInput.nativeElement.value +
      this.fiveCodeInput.nativeElement.value +
      this.sixCodeInput.nativeElement.value;
    
    console.log(this.tfaCode);
  }
  
  Clear2FACode() {
    this.oneCodeInput.nativeElement.value = '';
    this.twoCodeInput.nativeElement.value = '';
    this.threeCodeInput.nativeElement.value = '';
    this.fourCodeInput.nativeElement.value = '';
    this.fiveCodeInput.nativeElement.value = '';
    this.sixCodeInput.nativeElement.value = '';
    
    this.tfaCode = '';
    
    setTimeout(() => {
      this.FocusInputCode('one');
    }, 200);
  }
  
  ResendCode() {
    this.Clear2FACode();
    this.authService.Resend2FACode(this.tfaUser.id, this.tfaType)
    .subscribe();
    this.snackerService.ShowMessageOnBottom('New 2FA Code sent', 'pin');
  }
  
  LoadCode() {
    this.GetCode();
    this.loadingCode = true;
    this.authService.Validate2FACode(this.tfaUser.id, this.tfaType, this.tfaCode)
    .subscribe((result: any) => {
      if (result) {
        this.SetLoggedUser(result);
      } else {
        setTimeout(() => {
          this.Clear2FACode();
          this.loadingCode = false;
        }, 200);
        this.snackerService.ShowMessageOnBottom('Code expired or invalid', 'timer_off');
      }
    });
  }
}
