import { Component, OnInit } from '@angular/core';
import { DevToolsService } from '../../core/services/dev-tools.service';
import { EditorStateService } from '../../core/services/editor-state.service';
import { ToolsService } from '../../core/services/tools.service';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { DraggableWindowManagerService } from '../../shared/services/draggable-window-manager.service';
import { DraggableWindowService } from '../../shared/services/draggable-window.service';
import { DocumentationComponent } from '../documentation/documentation.component';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-toolbar-panel',
  templateUrl: './toolbar-panel.component.html',
  styleUrls: ['./toolbar-panel.component.scss'],
})
export class ToolbarPanelComponent implements OnInit {
  constructor(public workAreaService: WorkAreaService,
              public cobbleService: CobbleService,
              private communicationService: CommunicationService,
              private toolsService: ToolsService,
              private editorStateService: EditorStateService,
              private devToolsService: DevToolsService,
              private draggableWindowService: DraggableWindowService,
              private dragableWindowManagerService: DraggableWindowManagerService) {
  }
  
  ngOnInit() {
  }
  
  WorkareaDetection(enable: boolean) {
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(enable);
  }
  
  HideLayoutsPanel() {
    this.workAreaService.editorPreferences.toolbarPanel = false;
    this.communicationService.Event.Editor.Preferences.$PreferenceChange.emit('toolbarPanel');
  }
  
  ChangeEditorPreferences(preference: string) {
    console.log(preference);
    if (preference === 'compactToolBarPosition' || preference === 'compactToolBar') {
      setTimeout(() => {
        this.communicationService.Event.Editor.WorkArea.$ChangeTopBarPosition.emit(this.workAreaService.editorPreferences.compactToolBarPosition === 'top' ? 'left' : 'top');
      }, 200);
    }
    
    if (preference === 'busDiagnostic') {
      console.log(this.workAreaService.editorPreferences.busDiagnostic);
      this.devToolsService.Enabled = this.workAreaService.editorPreferences.busDiagnostic;
    }
    
    setTimeout(() => {
      this.communicationService.Event.Editor.Preferences.$PreferenceChange.emit(preference);
      this.communicationService.Event.Editor.$SaveEditorState.emit();
    }, 500);
  }
  
  OpenToolbarPanelHelp() {
    this.toolsService.DragWindowConfig = this.editorStateService.WindowSize('LibraryMoleculeDocumentation');
    
    this.workAreaService.userGuideId = 'Options_panel_reference_doc';
    this.workAreaService.userGuideType = 'Guide';
    
    if (this.workAreaService.userGuideWindow) {
      this.communicationService.Event.Documentation.$Get.emit();
    } else {
      this.workAreaService.userGuideWindow = this.draggableWindowService.GenerateWindow(DocumentationComponent, {
        title: `Options Microlearning`,
        data: {
          id: this.workAreaService.userGuideId,
          type: this.workAreaService.userGuideType,
        },
      });
      
      this.dragableWindowManagerService.ShowWindowAndStore(this.workAreaService.userGuideWindow);
    }
  }
}
