import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DevToolsService } from '../../../core/services/dev-tools.service';
import { ToolsService } from '../../../core/services/tools.service';
import { CommunicationService } from '../../../shared/services/communication.service';

@Component({
  selector: 'app-dev-tools-toolbar',
  templateUrl: './dev-tools-toolbar.component.html',
  styleUrls: ['./dev-tools-toolbar.component.scss'],
})
export class DevToolsToolbarComponent implements OnInit, OnDestroy {
  
  subscriptions = new Subscription();
  data = null;
  
  constructor(
    public devToolsService: DevToolsService,
    private toolsService: ToolsService,
    private communicationService: CommunicationService,
  ) {
    this.subscriptions.add(this.communicationService.Event.System.DevTools.$ProcessPaused.subscribe(() => this.ProcessData()));
  }
  
  ngOnInit(): void {
    this.ProcessData();
  }
  
  
  ngOnDestroy(): void {
    console.log('stop debugger');
    this.devToolsService.StopDebugger();
    this.subscriptions.unsubscribe();
  }
  
  ProcessData() {
    const processMetadata = this.devToolsService.GetPausedProcessMetadata();
    
    if (processMetadata) {
      const processData = processMetadata.data;
      this.data = processData;
    } else {
      this.data = '';
    }
  }
}
