import { Constants } from '../../../shared/constants';
import { DatasourceType } from '../../../shared/enums/datasource-type.enum';
import { RepresentativeMoleculesType } from '../../../shared/enums/representative-molecules-types.enum';
import { DataElement } from '../../../shared/representative-molecule/interfaces/data-element';
import {
  IRepresentativeMolecule,
} from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class FilterByDataElementReferenceMolecule extends ActionMoleculeFunction {
  repMolecule: IRepresentativeMolecule;
  
  public MoleculeProcess(particleId: string, repMoleculeId: string, busProcessorKey: string, rule: any, logic: any, data?: any) {
    try {
      
      console.log('filter');
      
      const isAppRunning = this.cobbleService.Cobble.running;
      
      data = data === undefined || data === null || data === {} ? '' : data;
      
      if (this.ExistsRepMoleculeAttached) {
        data = this.RepMoleculeAttached.GetValue;
      }
      
      if (this.DataElements.length > 0) {
        let dataElementsFiltered = [];
        let dataElementsMC = [];
        let dataElementsMS = [];
        
        this.repMolecule = this.busService.Get(repMoleculeId);
        
        this.dataTranslatorService.TranslateMultiple(this.DataElements.map((de) => de.TranslationId))
        .subscribe((translations) => {
          let acceptedContext: {
            context: string;
            dataSourceType: string;
            col: string;
            row: number;
          }[] = [];
          
          //if de.translationid === 0
          if (translations.length === 0 && this.DataElements.length > 0) {
            this.DataElements.forEach(de => {
              translations.push({
                id: 0,
                internalName: de.InternalName,
                context: de.Context,
                dataSourceType: de.DataSourceType,
              });
            });
          }
          
          translations.forEach((translation) => {
            acceptedContext.push(this.toolsService.BreakContext(translation.context));
          });
          
          acceptedContext = acceptedContext.concat(this.AcceptedContextForRangedDataElements());
          
          switch (this.toolsService.GetObjectType(data)) {
            case 'object':
              if (data.hasOwnProperty('context')) {
                const dataBrokeContext = this.toolsService.BreakContext(data.context);
                const matchContext = acceptedContext.filter((ac) => ac.context === dataBrokeContext.context);
                
                if (matchContext.length > 0) {
                  matchContext.forEach((mc) => {
                    const matchCol = this.MatchColumn(mc, dataBrokeContext);
                    const matchSpecific = this.MatchSpecific(mc, dataBrokeContext);
                    
                    if (matchCol || matchSpecific) {
                      const moleculesToUpdateContext = this.repMolecule.GetMolecules(
                        'UpdateDatasourceDataMolecule');
                      const moleculesToDeleteContext = this.repMolecule.GetMolecules(
                        'DeleteDatasourceDataMolecule');
                      const joinMolecules = moleculesToUpdateContext.concat(moleculesToDeleteContext);
                      
                      joinMolecules.forEach((m) => {
                        if (m.DataElements.length === 0) {
                          const fakeDataElementToPopulate = new DataElement({
                            context: data.context,
                            translationId: null,
                          });
                          
                          if (isAppRunning) {
                            fakeDataElementToPopulate.TranslationId = null;
                          }
                          
                          if (m.OriginalDataElements.length === 0) {
                            m.OriginalDataElements = m.CloneDataElements();
                          }
                          
                          m.DataElements = [fakeDataElementToPopulate];
                        } else {
                          m.DataElements.forEach((de) => {
                            if (this.toolsService.BreakContext(
                              de.Context).dataSourceType !== DatasourceType.LocalDataStore) {
                              // do not replace context for delete
                              de.Context = m.InternalMoleculeName === 'DeleteDatasourceDataMolecule' ? de.Context : data.context;
                              if (isAppRunning) {
                                de.TranslationId = null;
                              }
                            }
                          });
                        }
                      });
                      if (matchCol) {
                        dataElementsMC.push(data);
                      }
                      
                      if (matchSpecific) {
                        dataElementsMS.push(data);
                      }
                    }
                  });
                }
              }
              break;
            case 'array':
              data.forEach((dataElement) => {
                const arrayElementType = this.toolsService.GetObjectType(dataElement);
                
                // console.log('arrayElementType', arrayElementType, dataElement);
                
                if (arrayElementType === 'object' && dataElement.hasOwnProperty('context')) {
                  const dataBrokeContext = this.toolsService.BreakContext(dataElement.context);
                  
                  switch (dataBrokeContext.dataSourceType) {
                    case DatasourceType.Spreadsheet:
                      const matchContext = acceptedContext.filter(
                        (ac) => ac.context === dataBrokeContext.context);
                      
                      if (matchContext.length > 0) {
                        matchContext.forEach((mc) => {
                          const matchCol = this.MatchColumn(mc, dataBrokeContext);
                          const matchSpecific = this.MatchSpecific(mc, dataBrokeContext);
                          
                          if (matchCol || matchSpecific) {
                            const moleculesToUpdateContext = this.repMolecule.GetMolecules(
                              'UpdateDatasourceDataMolecule');
                            const moleculesToDeleteContext = this.repMolecule.GetMolecules(
                              'DeleteDatasourceDataMolecule');
                            const joinMolecules = moleculesToUpdateContext.concat(moleculesToDeleteContext);
                            
                            // If update or delete molecules does not contain data element, tries to take the ones populating
                            joinMolecules.forEach((m) => {
                              
                              if (m.OriginalDataElements.length === 0) {
                                m.OriginalDataElements = m.CloneDataElements();
                              }
                              
                              if (m.DataElements.length === 0) {
                                const fakeDataElementToPopulate = new DataElement({
                                  context: dataElement.context,
                                  translationId: null,
                                });
                                if (isAppRunning) {
                                  fakeDataElementToPopulate.TranslationId = null;
                                }
                                
                                m.DataElements = [fakeDataElementToPopulate];
                              } else {
                                m.DataElements.forEach((de) => {
                                  if (this.toolsService.BreakContext(
                                    de.Context).dataSourceType !== DatasourceType.LocalDataStore) {
                                    // do not replace context for delete
                                    de.Context = m.InternalMoleculeName === 'DeleteDatasourceDataMolecule' ? de.Context : dataElement.context;
                                    if (isAppRunning) {
                                      de.TranslationId = null;
                                    }
                                  }
                                });
                              }
                            });
                            
                            if (matchCol) {
                              dataElementsMC.push(dataElement);
                            }
                            
                            if (matchSpecific) {
                              dataElementsMS.push(dataElement);
                            }
                          }
                        });
                      }
                      break;
                    
                    default:
                      const contexts = acceptedContext.filter(
                        (ac) => ac.context === dataBrokeContext.context && ac.dataSourceType === dataBrokeContext.dataSourceType,
                      );
                      
                      if (contexts.length > 0) {
                        if (this.repMolecule) {
                          const moleculesToUpdateContext = this.repMolecule.GetMolecules(
                            'UpdateDatasourceDataMolecule');
                          const moleculesToDeleteContext = this.repMolecule.GetMolecules(
                            'DeleteDatasourceDataMolecule');
                          const joinMolecules = moleculesToUpdateContext.concat(moleculesToDeleteContext);
                          
                          // If update or delete molecules does not contain data element, tries to take the ones populating
                          joinMolecules.forEach((m) => {
                            // update reference only if bus receptor is set as input
                            if (this.repMolecule.GetBusByParticleId(m.ParticleId)
                            .Receptor
                            .includes('input')) {
                              
                              if (m.OriginalDataElements.length === 0) {
                                m.OriginalDataElements = m.CloneDataElements();
                              }
                              
                              if (m.DataElements.length === 0) {
                                const fakeDataElementToPopulate = new DataElement({
                                  context: dataElement.context,
                                  translationId: null,
                                });
                                if (isAppRunning) {
                                  fakeDataElementToPopulate.TranslationId = null;
                                }
                                
                                m.DataElements = [fakeDataElementToPopulate];
                              } else {
                                m.DataElements.forEach((de) => {
                                  if (this.toolsService.BreakContext(
                                    de.Context).dataSourceType !== DatasourceType.LocalDataStore) {
                                    // do not replace context for delete
                                    de.Context = m.InternalMoleculeName === 'DeleteDatasourceDataMolecule' ? de.Context : dataElement.context;
                                    if (isAppRunning) {
                                      de.TranslationId = null;
                                    }
                                  }
                                });
                              }
                            }
                          });
                        }
                        
                        const positionIndex = this.DataElements.findIndex(
                          (de) => de.Context === dataElement.context);
                        if (positionIndex > -1) {
                          dataElement.orderCol = positionIndex + 1;
                          dataElement.id = this.toolsService.GenerateGuid();
                        }
                        dataElementsFiltered.push(dataElement);
                      }
                      break;
                  }
                }
              });
              
              if (dataElementsMS.length > 0) {
                dataElementsMS.forEach((dataElement) => {
                  dataElementsFiltered.push(dataElement);
                });
              } else {
                dataElementsMC.forEach((dataElement) => {
                  dataElementsFiltered.push(dataElement);
                });
              }
              
              break;
            default:
              dataElementsFiltered = data;
              break;
          }
          
          data = dataElementsFiltered;
          
          if (this.repMolecule.Type === RepresentativeMoleculesType.Dropdown) {
            data = (this.GroupDataElementsByContext(this.DataElements.map(de => de.Context),
              dataElementsFiltered) as any).flat();
          }
          this.Done(particleId, busProcessorKey, repMoleculeId, data);
        });
      } else {
        this.Done(particleId, busProcessorKey, repMoleculeId, data);
      }
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  private AcceptedContextForRangedDataElements(): any[] {
    if (
      this.DataElements.length === 2 &&
      this.DataElements[0].RangeParticleId === this.DataElements[1].ParticleId &&
      this.DataElements[1].RangeParticleId === this.DataElements[0].ParticleId
    ) {
      const partialContext = this.DataElements[0].Context.split(Constants.ContextSeparator);
      partialContext.shift();
      partialContext.pop();
      
      const startRangeContext = this.toolsService.BreakContext(this.DataElements[0].Context);
      const endRangeContext = this.toolsService.BreakContext(this.DataElements[1].Context);
      
      const acceptedContext = [];
      
      for (
        let i = this.toolsService.ColumnNameToIndex(startRangeContext.col) + 1;
        i < this.toolsService.ColumnNameToIndex(endRangeContext.col);
        i++
      ) {
        acceptedContext.push({
          context: partialContext.join(Constants.ContextSeparator),
          dataSourceType: this.DataElements[0].DataSourceType,
          col: this.toolsService.ColumnIndexToName(i),
          row: startRangeContext.row,
        });
      }
      
      return acceptedContext;
    } else {
      return [];
    }
  }
  
  private MatchColumn(brokenContext1: any, brokenContext2: any) {
    return brokenContext1[this.repMolecule.Properties.orientation || 'col'] === brokenContext2[this.repMolecule.Properties.orientation || 'col'];
  }
  
  private MatchSpecific(brokenContext1: any, brokenContext2: any) {
    if (this.MatchColumn(brokenContext1, brokenContext2)) {
      return brokenContext1.row === brokenContext2.row;
    } else {
      return false;
    }
  }
  
  private GroupDataElementsByContext(contexts: string[], dataElements: any[]): DataElement[][] {
    const groups = [];
    
    if (dataElements && dataElements.length > 0) {
      contexts.forEach(context => {
        groups.push(dataElements.filter(de => new DataElement().FromContext(de.context)
        .BelongsToContext(context)));
      });
    } else {
      console.log(dataElements);
      console.warn('dataElements is empty');
    }
    
    return groups;
  }
}
