import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TreeNode } from 'angular-tree-component';
import { BusService } from '../../core/molecular/services/bus.service';
import { ApiDataSourcesService } from '../../core/services/api-data-sources.service';
import { ApiMoleculesService } from '../../core/services/api-molecules.service';
import { AutoGenerationService } from '../../core/services/autogeneration.service';
import { ToolsService } from '../../core/services/tools.service';
import { Constants } from '../../shared/constants';
import { AutoGenDEBehavior } from '../../shared/enums/autogen-DEBehavior.enum';
import { AutoGenModelOption } from '../../shared/enums/autogen-model-options.enum';
import { AutoGenModel } from '../../shared/enums/autogen-model.enum';
import { AutoGenSubject } from '../../shared/enums/autogen-subject.enum';
import { AutoGenType } from '../../shared/enums/autogen-type.enum';
import { Orientation } from '../../shared/enums/orientation.enum';
import { Placeholders } from '../../shared/enums/placeholders.enum';
import { RepresentativeMoleculesType } from '../../shared/enums/representative-molecules-types.enum';
import { DataElement } from '../../shared/representative-molecule/interfaces/data-element';
import { CommunicationService } from '../../shared/services/communication.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { GenerationAppComponent } from '../generation-app/generation-app.component';
import { AutoGenAction } from '../interfaces/autogen-action.interface';
import { AutoGenDirective } from '../interfaces/autogen-directive.interface';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-generation-app-wizard',
  templateUrl: './generation-app-wizard.component.html',
  styleUrls: ['./generation-app-wizard.component.scss'],
})
export class GenerationAppWizardComponent implements OnInit {
  
  environment = Constants.Environment;
  nodes: TreeNode[] = [];
  components: {
    id: number,
    name: string,
    owner: string,
    guid: string,
    description: string,
    icon: string,
    previewDesktopUrl: string,
    isComponentTemplate: boolean,
    previewMobileUrl: string
  }[] = [];
  autoGenerationTypeSelected = null;
  autoGenerationStepIndex = null;
  scrollPosition = 0;
  generatingApp = false;
  navigationComponent = null;
  addNavigation = false;
  navigationExists = false;
  
  //region options
  dashboard = [
    {
      id: 'form-de',
      name: 'Select Data Elements to create the Form',
      required: true,
      type: 'de-selection',
      value: null,
    },
    {
      id: 'form-component',
      name: 'Select Component for the Form',
      type: 'component-selection',
      required: false,
      value: null,
    },
    {
      id: 'chart-1-de',
      name: 'Select Data Elements to populate the 1st Chart',
      required: true,
      type: 'de-selection',
      value: null,
    },
    {
      id: 'chart-1-component',
      name: 'Select Component for the 1st Chart',
      required: false,
      type: 'component-selection',
      value: null,
    },
    {
      id: 'chart-2-de',
      name: 'Select Data Elements to populate the 2nd Chart',
      required: true,
      type: 'de-selection',
      value: null,
    },
    {
      id: 'chart-2-component',
      name: 'Select Component for the 2nd Chart',
      required: false,
      type: 'component-selection',
      value: null,
    },
    {
      id: 'chart-3-de',
      name: 'Select Data Elements to populate the 3rd Chart',
      required: true,
      type: 'de-selection',
      value: null,
    },
    {
      id: 'chart-3-component',
      name: 'Select Component for the 3rd Chart',
      required: false,
      type: 'component-selection',
      value: null,
    },
    {
      id: 'chart-4-de',
      name: 'Select Data Elements to populate the 4th Chart',
      required: true,
      type: 'de-selection',
      value: null,
    },
    {
      id: 'chart-4-component',
      name: 'Select Component for the 4th Chart',
      required: false,
      type: 'component-selection',
      value: null,
    },
  ];
  
  dashboardDirective: AutoGenDirective =
    {
      id: 1,
      separateView: true,
      processes: [
        {
          id: 1,
          actions: [
            {
              order: 1,
              id: 1,
              type: { id: 1, type: AutoGenType.Create },
              subject: {
                id: null,
                type: AutoGenSubject.Form,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.RepMolSplit,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 8,
                separation: 1,
                columns: 0,
                addons: {
                  hidden: true,
                  autoStart: true,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
          ],
        },
        {
          id: 2,
          actions: [
            {
              order: 1,
              id: 2,
              type: { id: 1, type: AutoGenType.Receive },
              subject: {
                id: null,
                type: AutoGenSubject.RepMolecule,
                entity: RepresentativeMoleculesType.Chart,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.Join,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 8,
                separation: 1,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
          ],
        },
        {
          id: 3,
          actions: [
            {
              order: 1,
              id: 3,
              type: { id: 1, type: AutoGenType.Receive },
              subject: {
                id: null,
                type: AutoGenSubject.RepMolecule,
                entity: RepresentativeMoleculesType.Chart,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.Join,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 8,
                separation: 1,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
          ],
        },
        {
          id: 4,
          actions: [
            {
              order: 1,
              id: 4,
              type: { id: 1, type: AutoGenType.Receive },
              subject: {
                id: null,
                type: AutoGenSubject.RepMolecule,
                entity: RepresentativeMoleculesType.Chart,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.Join,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 8,
                separation: 1,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
          ],
        },
        {
          id: 5,
          actions: [
            {
              order: 1,
              id: 5,
              type: { id: 1, type: AutoGenType.Receive },
              subject: {
                id: null,
                type: AutoGenSubject.RepMolecule,
                entity: RepresentativeMoleculesType.Chart,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.Join,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 8,
                separation: 1,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
          ],
        },
      ],
      navigation: {
        enabled: false,
        componentId: null,
      },
    };
  
  formToTable = [
    {
      id: 'form-de',
      name: 'Select Data Elements to create the Form',
      required: true,
      type: 'de-selection',
      value: null,
    },
    {
      id: 'form-component',
      name: 'Select Component for the Form',
      type: 'component-selection',
      required: false,
      value: null,
    },
    {
      id: 'table-de',
      name: 'Select Data Elements to populate the Table',
      required: true,
      type: 'de-selection',
      value: null,
    },
    {
      id: 'table-component',
      name: 'Select Component for the Table',
      required: false,
      type: 'component-selection',
      value: null,
    },
  ];
  
  formToTableDirective: AutoGenDirective =
    {
      id: 1,
      separateView: true,
      processes: [
        {
          id: 1,
          actions: [
            {
              order: 1,
              id: 1,
              type: { id: 1, type: AutoGenType.Create },
              subject: {
                id: null,
                type: AutoGenSubject.Form,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.RepMolSplit,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 8,
                separation: 1,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
            {
              order: 2,
              id: 2,
              type: { id: 2, type: AutoGenType.Populate },
              subject: {
                id: null,
                type: AutoGenSubject.RepMolecule,
                entity: RepresentativeMoleculesType.Table,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.Join,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 4,
                separation: 4,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
          ],
        },
      ],
      navigation: {
        enabled: false,
        componentId: null,
      },
    };
  
  formToChart = [
    {
      id: 'form-de',
      name: 'Select Data Elements to create the Form',
      required: true,
      type: 'de-selection',
      value: null,
    },
    {
      id: 'form-component',
      name: 'Select Component for the Form',
      type: 'component-selection',
      required: false,
      value: null,
    },
    {
      id: 'chart-de',
      name: 'Select Data Elements to populate the Chart',
      required: true,
      type: 'de-selection',
      value: null,
    },
    {
      id: 'chart-component',
      name: 'Select Component for the Chart',
      required: false,
      type: 'component-selection',
      value: null,
    },
  ];
  
  formToChartDirective: AutoGenDirective =
    {
      id: 1,
      separateView: true,
      processes: [
        {
          id: 1,
          actions: [
            {
              order: 1,
              id: 1,
              type: { id: 1, type: AutoGenType.Create },
              subject: {
                id: null,
                type: AutoGenSubject.Form,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.RepMolSplit,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 8,
                separation: 1,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
            {
              order: 2,
              id: 2,
              type: { id: 2, type: AutoGenType.Populate },
              subject: {
                id: null,
                type: AutoGenSubject.RepMolecule,
                entity: RepresentativeMoleculesType.Chart,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.Join,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 4,
                separation: 4,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
          ],
        },
      ],
      navigation: {
        enabled: false,
        componentId: null,
      },
    };
  
  formToForm = [
    {
      id: 'form-de',
      name: 'Select Data Elements to create the 1st Form',
      required: true,
      type: 'de-selection',
      value: null,
    },
    {
      id: 'form-component',
      name: 'Select Component for the 1st Form',
      type: 'component-selection',
      required: false,
      value: null,
    },
    {
      id: 'form-de-2',
      name: 'Select Data Elements to populate the 2nd Form',
      required: true,
      type: 'de-selection',
      value: null,
    },
    {
      id: 'form-2-component',
      name: 'Select Component for the 2nd Form',
      required: false,
      type: 'component-selection',
      value: null,
    },
  ];
  
  formToFormDirective: AutoGenDirective =
    {
      id: 1,
      separateView: true,
      processes: [
        {
          id: 1,
          actions: [
            {
              order: 1,
              id: 1,
              type: { id: 1, type: AutoGenType.Create },
              subject: {
                id: null,
                type: AutoGenSubject.Form,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.RepMolSplit,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 8,
                separation: 1,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
            {
              order: 2,
              id: 2,
              type: { id: 2, type: AutoGenType.Populate },
              subject: {
                id: null,
                type: AutoGenSubject.Form,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.RepMolSplit,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 4,
                separation: 4,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
          ],
        },
      ],
      navigation: {
        enabled: false,
        componentId: null,
      },
    };
  
  form = [
    {
      id: 'form-de',
      name: 'Select Data Elements to create the Form',
      type: 'de-selection',
      required: true,
      value: null,
    },
    {
      id: 'form-component',
      name: 'Select Component for the Form',
      type: 'component-selection',
      required: false,
      value: null,
    },
  ];
  
  formDirective: AutoGenDirective =
    {
      id: 1,
      separateView: false,
      processes: [
        {
          id: 1,
          actions: [
            {
              order: 1,
              id: 1,
              type: { id: 1, type: AutoGenType.Create },
              subject: {
                id: null,
                type: AutoGenSubject.Form,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.RepMolSplit,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 8,
                separation: 1,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
          ],
        },
      ],
      navigation: {
        enabled: false,
        componentId: null,
      },
    };
  
  updateForm = [
    {
      id: 'form-de',
      name: 'Select Data Elements to populate the Form',
      type: 'de-selection',
      required: true,
      value: null,
    },
    {
      id: 'form-de-2',
      name: 'Select Data Elements to update',
      type: 'de-selection',
      required: true,
      value: null,
    },
    {
      id: 'form-component',
      name: 'Select Component for the Update Form',
      type: 'component-selection',
      required: false,
      value: null,
    },
  ];
  
  updateFormDirective: AutoGenDirective =
    {
      id: 1,
      separateView: false,
      processes: [
        {
          id: 1,
          actions: [
            {
              order: 1,
              id: 1,
              type: { id: 2, type: AutoGenType.Populate },
              subject: {
                id: null,
                type: AutoGenSubject.Form,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.RepMolSplit,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 8,
                separation: 1,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
            {
              order: 2,
              id: 2,
              type: { id: 1, type: AutoGenType.Submit },
              subject: {
                id: 1,
                type: AutoGenSubject.Form,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.RepMolSplit,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 8,
                separation: 1,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
          ],
        },
      ],
      navigation: {
        enabled: false,
        componentId: null,
      },
    };
  
  crud = [
    {
      id: 'form-de',
      name: 'Select Data Elements to obtain Table Data',
      type: 'de-selection',
      required: true,
      dependsOn: '',
      value: null,
    },
    {
      id: 'table-de',
      name: 'Select Data Elements to populate Table',
      type: 'de-selection',
      dependsOn: '',
      required: true,
      value: null,
    },
    {
      id: 'table-component',
      name: 'Select Component for the Table',
      type: 'component-selection',
      dependsOn: '',
      required: false,
      value: null,
    },
    {
      id: 'update-form-de',
      name: 'Select Data Elements to populate Update Form',
      type: 'de-selection',
      dependsOn: '',
      required: true,
      value: null,
    },
    {
      id: 'update-form-de-2',
      name: 'Select Data Elements to Update',
      type: 'de-selection',
      dependsOn: '',
      required: true,
      value: null,
    },
    {
      id: 'form-component',
      name: 'Select Component for the Update Form',
      type: 'component-selection',
      dependsOn: '',
      required: false,
      value: null,
    },
    {
      id: 'submit-form',
      name: 'Select Data Elements to Add Form',
      type: 'de-selection',
      dependsOn: '',
      required: true,
      value: null,
    },
    {
      id: 'form-component',
      name: 'Select Component for the Add Form',
      type: 'component-selection',
      dependsOn: '',
      required: false,
      value: null,
    },
  ];
  
  crud2 = [
    {
      id: 'query-form-de',
      name: 'Select Data Elements to obtain Table Data',
      type: 'de-selection',
      required: true,
      value: null,
      defaults: ['query', 'inquiry'],
      subDefaults: ['data'],
      dependsOn: '',
      defaultsToAvoid: [],
    },
    {
      id: 'create-form-de',
      name: 'Select Data Elements to Create Data',
      type: 'de-selection',
      required: false,
      value: null,
      defaults: ['create', 'add'],
      subDefaults: ['data'],
      dependsOn: '',
      defaultsToAvoid: [],
    },
    {
      id: 'create-form-component',
      name: 'Select Component for the Create Form',
      type: 'component-selection',
      required: false,
      dependsOn: 'create-form-de',
      value: null,
      autoSelectComponent: true,
    },
    {
      id: 'update-form-de',
      name: 'Select Data Elements Update Data',
      type: 'de-selection',
      required: false,
      value: null,
      defaults: ['update', 'edit', 'modify'],
      subDefaults: ['data'],
      dependsOn: '',
      defaultsToAvoid: [],
    },
    {
      id: 'update-form-component',
      name: 'Select Component for the Update Form',
      type: 'component-selection',
      required: false,
      value: null,
      autoSelectComponent: true,
      dependsOn: 'update-form-de',
    },
    {
      id: 'delete-form-de',
      name: 'Select Data Elements to Delete Data',
      type: 'de-selection',
      required: false,
      value: null,
      defaults: ['delete', 'remove'],
      subDefaults: ['data'],
      dependsOn: '',
      defaultsToAvoid: [],
    },
    {
      id: 'delete-form-component',
      name: 'Select Component for the Delete Form',
      type: 'component-selection',
      required: false,
      value: null,
      dependsOn: 'delete-form-de',
      autoSelectComponent: true,
    },
    {
      id: 'view-form-component',
      name: 'Select Component for View Form',
      type: 'component-selection',
      required: false,
      value: null,
      dependsOn: '',
      autoSelectComponent: true,
    },
    {
      id: 'table-de',
      name: 'Select Data Elements to populate Table',
      type: 'de-selection',
      required: true,
      value: null,
      autoSelectComponent: true,
      defaults: ['query', 'inquiry'],
      defaultsToAvoid: [],
      subDefaults: [],
      dependsOn: '',
      subDefaultsToAvoid: ['database report', '(extra)', 'failed', 'data', '(response)'],
    },
    {
      id: 'table-component',
      name: 'Select Component for the Table',
      type: 'component-selection',
      dependsOn: '',
      required: true,
      value: null,
    },
  ];
  
  crudDirective: AutoGenDirective =
    {
      id: 1,
      separateView: true,
      processes: [
        {
          id: 1,
          actions: [
            {
              order: 1,
              id: 1,
              type: { id: 1, type: AutoGenType.Create },
              subject: {
                id: null,
                type: AutoGenSubject.Form,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.RepMolSplit,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 4,
                separation: 2,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
            {
              order: 2,
              id: 2,
              type: { id: 2, type: AutoGenType.Populate },
              subject: {
                id: null,
                type: AutoGenSubject.RepMolecule,
                entity: RepresentativeMoleculesType.Table,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.Join,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 4,
                separation: 2,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
            {
              order: 3,
              id: 3,
              type: { id: 3, type: AutoGenType.Create },
              subject: {
                id: null,
                type: AutoGenSubject.View,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.RepMolSplit,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 4,
                separation: 2,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
          ],
        },
        {
          id: 2,
          actions: [
            {
              order: 1,
              id: 4,
              type: { id: 2, type: AutoGenType.Use },
              subject: {
                id: 2,
                type: AutoGenSubject.RepMolecule,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.RepMolSplit,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 4,
                separation: 2,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
            {
              order: 2,
              id: 5,
              type: { id: 5, type: AutoGenType.Populate },
              subject: {
                id: null,
                type: AutoGenSubject.Form,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.RepMolSplit,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 4,
                separation: 2,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
            {
              order: 3,
              id: 6,
              type: { id: 6, type: AutoGenType.Create },
              subject: {
                id: null,
                type: AutoGenSubject.View,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.RepMolSplit,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 4,
                separation: 2,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
            {
              order: 4,
              id: 15,
              type: { id: 6, type: AutoGenType.Add },
              subject: {
                id: null,
                type: AutoGenSubject.RepMolecule,
                entity: RepresentativeMoleculesType.Button,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.Join,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 4,
                separation: 2,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: false, readOnly: false,
                },
              },
              dataElements: [],
            },
            {
              order: 5,
              id: 16,
              type: { id: 5, type: AutoGenType.Submit },
              subject: {
                id: 5,
                type: AutoGenSubject.Form,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.Join,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 4,
                separation: 2,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: false, readOnly: false,
                },
              },
              dataElements: [],
            },
          ],
        },
        {
          id: 3,
          actions: [
            {
              order: 1,
              id: 7,
              type: { id: 7, type: AutoGenType.Create },
              subject: {
                id: 2,
                type: AutoGenSubject.Form,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.RepMolSplit,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 4,
                separation: 2,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
            {
              order: 2,
              id: 8,
              type: { id: 8, type: AutoGenType.Create },
              subject: {
                id: null,
                type: AutoGenSubject.View,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.RepMolSplit,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 4,
                separation: 2,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
          ],
        },
        {
          id: 4,
          actions: [
            {
              order: 1,
              id: 9,
              type: { id: 9, type: AutoGenType.Use },
              subject: {
                id: 2,
                type: AutoGenSubject.RepMolecule,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.RepMolSplit,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 4,
                separation: 2,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
            {
              order: 2,
              id: 10,
              type: { id: 6, type: AutoGenType.Navigate },
              subject: {
                id: 6,
                type: AutoGenSubject.View,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.RepMolSplit,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 4,
                separation: 2,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
          ],
        },
        {
          id: 5,
          actions: [
            {
              order: 1,
              id: 11,
              type: { id: 11, type: AutoGenType.Use },
              subject: {
                id: 5,
                type: AutoGenSubject.Form,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.RepMolSplit,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 4,
                separation: 2,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
            {
              order: 2,
              id: 12,
              type: { id: 3, type: AutoGenType.Navigate },
              subject: {
                id: 3,
                type: AutoGenSubject.View,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.RepMolSplit,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 4,
                separation: 2,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
          ],
        },
        {
          id: 6,
          actions: [
            {
              order: 1,
              id: 13,
              type: { id: 13, type: AutoGenType.Use },
              subject: {
                id: 7,
                type: AutoGenSubject.Form,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.RepMolSplit,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 4,
                separation: 2,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
            {
              order: 2,
              id: 14,
              type: { id: 3, type: AutoGenType.Navigate },
              subject: {
                id: 3,
                type: AutoGenSubject.View,
                entity: null,
              },
              options: {
                dataElementsBehavior: AutoGenDEBehavior.RepMolSplit,
              },
              model: {
                type: null,
                id: null,
              },
              ui: {
                modelOptions: AutoGenModelOption.Includes,
                orientation: Orientation.Vertical,
                padding: 4,
                separation: 2,
                columns: 0,
                addons: {
                  hidden: false,
                  autoStart: false,
                  header: true, readOnly: false,
                },
              },
              dataElements: [],
            },
          ],
        },
      ],
      navigation: {
        enabled: true,
        componentId: null,
      },
    };
  
  screenCRUD: AutoGenDirective = {
    id: 0,
    separateView: true,
    processes: [
      {
        id: 1,
        actions: [{
          id: 1,
          order: 1,
          type: { id: 1, type: AutoGenType.Create },
          subject: { id: null, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: true, autoStart: true, header: false, readOnly: false },
          },
          dataElements: [],
        }],
      },
      {
        id: 2,
        actions: [{
          id: 2,
          order: 1,
          type: { id: 2, type: AutoGenType.Create },
          subject: { id: null, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Includes,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }],
      },
      {
        id: 3,
        actions: [{
          id: 3,
          order: 1,
          type: { id: 3, type: AutoGenType.Create },
          subject: { id: null, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Includes,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }],
      },
      {
        id: 4,
        actions: [{
          id: 4,
          order: 1,
          type: { id: 4, type: AutoGenType.Create },
          subject: { id: null, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Includes,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }],
      },
      {
        id: 5,
        actions: [{
          id: 5,
          order: 1,
          type: { id: 5, type: AutoGenType.Create },
          subject: { id: null, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Includes,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: true },
          },
          dataElements: [],
        }],
      },
      {
        id: 6,
        actions: [{
          id: 6,
          order: 1,
          type: { id: 1, type: AutoGenType.Receive },
          subject: { id: null, type: AutoGenSubject.RepMolecule, entity: 'Table' },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Includes,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }],
      },
      {
        id: 7,
        actions: [{
          id: 7,
          order: 1,
          type: { id: null, type: AutoGenType.Use },
          subject: { id: null, type: AutoGenSubject.Actionable, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }, {
          id: 8,
          order: 2,
          type: { id: 4, type: AutoGenType.Show },
          subject: { id: 4, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }],
      },
      {
        id: 8,
        actions: [{
          id: 9,
          order: 1,
          type: { id: null, type: AutoGenType.Use },
          subject: { id: null, type: AutoGenSubject.Actionable, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }, {
          id: 10,
          order: 2,
          type: { id: 2, type: AutoGenType.Show },
          subject: { id: 2, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }],
      },
      {
        id: 9,
        actions: [{
          id: 11,
          order: 1,
          type: { id: null, type: AutoGenType.Use },
          subject: { id: null, type: AutoGenSubject.Actionable, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }, {
          id: 12,
          order: 2,
          type: { id: 4, type: AutoGenType.Value },
          subject: { id: 4, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }],
      },
      {
        id: 10,
        actions: [{
          id: 13,
          order: 1,
          type: { id: null, type: AutoGenType.Use },
          subject: { id: null, type: AutoGenSubject.Actionable, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }, {
          id: 14,
          order: 2,
          type: { id: 3, type: AutoGenType.Show },
          subject: { id: 3, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }],
      },
      {
        id: 11,
        actions: [{
          id: 15,
          order: 1,
          type: { id: null, type: AutoGenType.Use },
          subject: { id: null, type: AutoGenSubject.Actionable, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }, {
          id: 16,
          order: 2,
          type: { id: 3, type: AutoGenType.Value },
          subject: { id: 3, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }],
      },
      {
        id: 12,
        actions: [{
          id: 17,
          order: 1,
          type: { id: null, type: AutoGenType.Use },
          subject: { id: null, type: AutoGenSubject.Actionable, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }, {
          id: 18,
          order: 2,
          type: { id: 5, type: AutoGenType.Show },
          subject: { id: 5, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }],
      },
      {
        id: 13,
        actions: [{
          id: 19,
          order: 1,
          type: { id: null, type: AutoGenType.Use },
          subject: { id: null, type: AutoGenSubject.Actionable, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }, {
          id: 20,
          order: 2,
          type: { id: 5, type: AutoGenType.Value },
          subject: { id: 5, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }],
      },
      {
        id: 14,
        actions: [{
          id: 21,
          order: 1,
          type: { id: 2, type: AutoGenType.Use },
          subject: { id: 2, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }, {
          id: 22,
          order: 2,
          type: { id: 2, type: AutoGenType.Hide },
          subject: { id: 2, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }],
      },
      {
        id: 15,
        actions: [{
          id: 23,
          order: 1,
          type: { id: 3, type: AutoGenType.Use },
          subject: { id: 3, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }, {
          id: 24,
          order: 2,
          type: { id: 3, type: AutoGenType.Hide },
          subject: { id: 3, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }],
      },
      {
        id: 16,
        actions: [{
          id: 25,
          order: 1,
          type: { id: 4, type: AutoGenType.Use },
          subject: { id: 4, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }, {
          id: 26,
          order: 2,
          type: { id: 4, type: AutoGenType.Hide },
          subject: { id: 4, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }],
      },
      {
        id: 17,
        actions: [{
          id: 27,
          order: 1,
          type: { id: 2, type: AutoGenType.Receive },
          subject: { id: 1, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }, {
          id: 28,
          order: 2,
          type: { id: 1, type: AutoGenType.Use },
          subject: { id: 1, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }],
      },
      {
        id: 18,
        actions: [{
          id: 29,
          order: 1,
          type: { id: 3, type: AutoGenType.Receive },
          subject: { id: 1, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }, {
          id: 30,
          order: 2,
          type: { id: 1, type: AutoGenType.Use },
          subject: { id: 1, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }],
      },
      {
        id: 19,
        actions: [{
          id: 31,
          order: 1,
          type: { id: 4, type: AutoGenType.Receive },
          subject: { id: 1, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }, {
          id: 32,
          order: 2,
          type: { id: 1, type: AutoGenType.Use },
          subject: { id: 1, type: AutoGenSubject.Form, entity: null },
          options: { dataElementsBehavior: null },
          model: { id: null, type: null },
          ui: {
            modelOptions: AutoGenModelOption.Standalone,
            orientation: Orientation.Vertical,
            padding: 4,
            separation: 2,
            columns: 0,
            addons: { hidden: false, autoStart: false, header: true, readOnly: false },
          },
          dataElements: [],
        }],
      },
    ],
    navigation: { enabled: false, componentId: null },
  };
  
  autoGenerationTypes = [
    {
      
      type: 'form',
      name: 'Form',
      icons: ['export_notes'],
      steps: this.form,
    },
    {
      type: 'updateForm',
      name: 'Update Form',
      icons: ['edit_note'],
      steps: this.updateForm,
    },
    {
      type: 'formToChart',
      name: 'Form to Chart',
      icons: ['insert_chart'],
      steps: this.formToChart,
    },
    {
      type: 'formToForm',
      name: 'Form To Form',
      icons: ['other_admission', 'other_admission'],
      steps: this.formToForm,
    },
    {
      type: 'formToTable',
      name: 'Form To Table',
      icons: ['article', 'table'],
      steps: this.formToTable,
    },
    {
      type: 'crud2',
      name: 'C R U D',
      icons: ['table', 'export_notes', 'add_notes'],
      steps: this.crud2,
    },
    // {
    //   type: 'crud',
    //   name: 'C R U D',
    //   icons: ['table', 'export_notes', 'add_notes'],
    //   steps: this.crud,
    // },
    {
      type: 'dashboard',
      name: 'Dashboard',
      icons: ['team_dashboard'],
      steps: this.dashboard,
    },
  ];
  
  //endregion
  constructor(public autoGenerationService: AutoGenerationService,
              private apiMoleculesService: ApiMoleculesService,
              private toolsService: ToolsService,
              private dataSourcesService: ApiDataSourcesService,
              private snackerService: SnackerService,
              private dialog: MatDialog,
              private busService: BusService,
              private workAreaService: WorkAreaService,
              private communicationService: CommunicationService,
              private dialogRef: MatDialogRef<GenerationAppWizardComponent>,
              @Inject(MAT_DIALOG_DATA) public data: TreeNode[]) {
    this.workAreaService.apiSectionOpen = true;
    this.workAreaService.leftPanelIndexTab = 0;
    this.communicationService.Event.Editor.SidePanels.$Open.emit(1);
    if (data && data.length > 0) {
      this.nodes = data;
    } else {
      this.nodes = this.dataSourcesService.apiTreeNodes;
    }
  }
  
  ngOnInit(): void {
    this.apiMoleculesService.GetUserComponents()
    .subscribe(components => {
      this.components = components.sort(this.toolsService.CompareValues('name'));
    });
    this.navigationExists = this.NavigationPlaceholderRepMolecules().length > 0;
  }
  
  InitSlides() {
    this.autoGenerationTypeSelected = null;
    this.autoGenerationStepIndex = null;
    this.scrollPosition = 0;
  }
  
  TreeChange(nodes: TreeNode[], step: any) {
    const dataElements = (nodes.map(
      node => this.autoGenerationService.GenerateDataElementsFromDataSourceNode(
        node.data)) as any).flat();
    
    if (dataElements.length > 0) {
      step.value = dataElements;
    } else {
      step.value = null;
    }
  }
  
  SkipDefault() {
    const stepsToSkip = this.autoGenerationTypeSelected.steps.findIndex(s => s.value === null);
    this.MoveNext(stepsToSkip - this.autoGenerationStepIndex);
  }
  
  MoveBack(steps = 1) {
    if (this.autoGenerationStepIndex === null) {
      return;
    }
    
    if (this.autoGenerationStepIndex === 0) {
      this.autoGenerationStepIndex = null;
    } else {
      this.autoGenerationStepIndex--;
    }
    
    this.ScrollToLeft(steps);
  }
  
  MoveNext(steps = 1) {
    
    if (steps <= 0) {
      return;
    }
    
    if (this.autoGenerationTypeSelected) {
      
      if (this.autoGenerationStepIndex >= this.autoGenerationTypeSelected.steps.length + 1) {
        return;
      }
      
      const nextStep = this.autoGenerationTypeSelected.steps[this.autoGenerationStepIndex + 1];
      if (nextStep && nextStep.dependsOn && nextStep.dependsOn.length > 0) {
        const step = this.autoGenerationTypeSelected.steps.find(
          s => s.id === nextStep.dependsOn);
        if (!step.value) {
          steps++;
        }
      }
      
      if (this.autoGenerationStepIndex === null) {
        this.autoGenerationStepIndex = 0;
      } else {
        this.autoGenerationStepIndex += steps;
      }
      
      this.ScrollToRight(steps);
    }
  }
  
  ScrollToRight(steps = 1) {
    this.Scroll(500 * steps);
  }
  
  ScrollToLeft(steps = 1) {
    this.Scroll(-500 * steps);
  }
  
  Scroll(scrollAmount = 500) {
    const element = document.getElementsByClassName('slides')[0];
    const duration = 500; // replace with desired duration in milliseconds
    var start = this.scrollPosition,
      change = scrollAmount,
      currentTime = 0,
      increment = 20;
    
    let easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t + b;
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    };
    
    var animateScroll = () => {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollLeft = val;
      this.scrollPosition = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }
  
  SelectType(type: any) {
    
    if (this.nodes.length === 0) {
      this.snackerService.ShowMessageOnBottom('Open Api Datasource panel to continue', 'block');
      return;
    }
    
    this.autoGenerationTypeSelected = type;
    
    this.MoveNext();
  }
  
  SelectionContainsView() {
    
    let directive: AutoGenDirective = null;
    
    switch (this.autoGenerationTypeSelected.type) {
      case 'formToTable':
        directive = this.formToTableDirective;
        break;
      case 'formToChart':
        directive = this.formToChartDirective;
        break;
      case 'formToForm':
        directive = this.formToFormDirective;
        break;
      case 'form':
        directive = this.formDirective;
        break;
      case 'updateForm':
        directive = this.updateFormDirective;
        break;
      case 'crud':
        directive = this.crudDirective;
        break;
      case 'crud2':
        directive = this.screenCRUD;
        break;
    }
    
    return !!directive.processes.find(p => !!p.actions.find(a => a.subject.type === AutoGenSubject.View));
  }
  
  SaveUsedComponents() {
    const components = this.autoGenerationTypeSelected.steps.filter(
      s => s.type === 'component-selection' && s.value)
    .map(s => s.value.id);
    
    this.autoGenerationService.frequentUsedComponents =
      [...new Set([...this.autoGenerationService.frequentUsedComponents,
        ...components])];
  }
  
  async GenerateApp() {
    this.SaveUsedComponents();
    this.generatingApp = true;
    
    const directive = await this.ConvertSelectionToDirective();
    this.autoGenerationService.Process(directive)
    .then(resul => {
      this.generatingApp = false;
      console.log('completed');
      this.dialogRef.close();
    });
  }
  
  GetStepDataElements(stepIndex: number, selection = this.autoGenerationTypeSelected): DataElement[] {
    return selection.steps[stepIndex].value ?? [];
  }
  
  GetStepComponent(stepIndex: number, selection = this.autoGenerationTypeSelected): any {
    return selection.steps[stepIndex].value;
  }
  
  AssignComponentToAction(action: AutoGenAction, component: { id: number }) {
    if (component) {
      action.model.type = AutoGenModel.Component;
      action.model.id = component.id;
    }
  }
  
  async ConvertSelectionToDirective(): Promise<AutoGenDirective> {
    
    let directive: AutoGenDirective = null;
    
    if (this.autoGenerationTypeSelected) {
      
      switch (this.autoGenerationTypeSelected.type) {
        case 'dashboard':
          const formDashboardDataElements = this.GetStepDataElements(0);
          const formDashboardComponent = this.GetStepComponent(1);
          
          const chart1DataElements = this.GetStepDataElements(2);
          const chart1Component = this.GetStepComponent(3);
          
          const chart2DataElements = this.GetStepDataElements(4);
          const chart2Component = this.GetStepComponent(5);
          
          const chart3DataElements = this.GetStepDataElements(6);
          const chart3Component = this.GetStepComponent(7);
          
          const chart4DataElements = this.GetStepDataElements(8);
          const chart4Component = this.GetStepComponent(9);
          
          const formDashboardAction = this.dashboardDirective.processes[0].actions[0];
          const chart1Action = this.dashboardDirective.processes[1].actions[0];
          const chart2Action = this.dashboardDirective.processes[2].actions[0];
          const chart3Action = this.dashboardDirective.processes[3].actions[0];
          const chart4Action = this.dashboardDirective.processes[4].actions[0];
          
          formDashboardAction.dataElements = formDashboardDataElements;
          chart1Action.dataElements = chart1DataElements;
          chart2Action.dataElements = chart2DataElements;
          chart3Action.dataElements = chart3DataElements;
          chart4Action.dataElements = chart4DataElements;
          
          this.AssignComponentToAction(formDashboardAction, formDashboardComponent);
          this.AssignComponentToAction(chart1Action, chart1Component);
          this.AssignComponentToAction(chart2Action, chart2Component);
          this.AssignComponentToAction(chart3Action, chart3Component);
          this.AssignComponentToAction(chart4Action, chart4Component);
          
          directive = this.dashboardDirective;
          break;
        case 'formToTable':
          const formDataElements = this.GetStepDataElements(0);
          const formComponent = this.GetStepComponent(1);
          const tableDataElements = this.GetStepDataElements(2);
          const tableComponent = this.GetStepComponent(3);
          
          const formAction = this.formToTableDirective.processes[0].actions[0];
          const tableAction = this.formToTableDirective.processes[0].actions[1];
          
          formAction.dataElements = formDataElements;
          tableAction.dataElements = tableDataElements;
          
          this.AssignComponentToAction(formAction, formComponent);
          this.AssignComponentToAction(tableAction, tableComponent);
          
          directive = this.formToTableDirective;
          break;
        case 'formToChart':
          const formChartDataElements = this.GetStepDataElements(0);
          const formChartComponent = this.GetStepComponent(1);
          const ChartDataElements = this.GetStepDataElements(2);
          const ChartComponent = this.GetStepComponent(3);
          
          const formChartAction = this.formToChartDirective.processes[0].actions[0];
          const ChartAction = this.formToChartDirective.processes[0].actions[1];
          
          formChartAction.dataElements = formChartDataElements;
          ChartAction.dataElements = ChartDataElements;
          
          this.AssignComponentToAction(formChartAction, formChartComponent);
          this.AssignComponentToAction(ChartAction, ChartComponent);
          
          directive = this.formToChartDirective;
          break;
        case 'formToForm':
          
          const form1DataElements = this.GetStepDataElements(0);
          const form1Component = this.GetStepComponent(1);
          const form2DataElements = this.GetStepDataElements(2);
          const form2Component = this.GetStepComponent(3);
          
          const form1Action = this.formToFormDirective.processes[0].actions[0];
          const form2Action = this.formToFormDirective.processes[0].actions[1];
          
          form1Action.dataElements = form1DataElements;
          form2Action.dataElements = form2DataElements;
          
          this.AssignComponentToAction(form1Action, form1Component);
          this.AssignComponentToAction(form2Action, form2Component);
          
          directive = this.formToFormDirective;
          break;
        case 'form':
          
          const form11DataElements = this.GetStepDataElements(0);
          const form11Component = this.GetStepComponent(1);
          const form11Action = this.formDirective.processes[0].actions[0];
          form11Action.dataElements = form11DataElements;
          
          this.AssignComponentToAction(form11Action, form11Component);
          
          directive = this.formDirective;
          break;
        case 'updateForm':
          
          const updateFormDataElements = this.GetStepDataElements(0);
          const updateFormSubmitDataElements = this.GetStepDataElements(1);
          const updateFormComponent = this.GetStepComponent(2);
          
          const populateAction = this.updateFormDirective.processes[0].actions[0];
          const submitAction = this.updateFormDirective.processes[0].actions[1];
          
          populateAction.dataElements = updateFormDataElements;
          submitAction.dataElements = updateFormSubmitDataElements;
          
          this.AssignComponentToAction(populateAction, updateFormComponent);
          
          directive = this.updateFormDirective;
          break;
        case 'crud':
          
          const getTableDataDataElements = this.GetStepDataElements(0);
          const populateTableDataElements = this.GetStepDataElements(1);
          const crudTableComponent = this.GetStepComponent(2);
          const populateUpdateFormDataElements = this.GetStepDataElements(3);
          const submitUpdateFormDataElements = this.GetStepDataElements(4);
          const crudUpdateFormComponent = this.GetStepComponent(5);
          const addFormDataElements = this.GetStepDataElements(6);
          const addFormComponent = this.GetStepComponent(7);
          
          const getTableDataAction = this.crudDirective.processes[0].actions[0];
          const populateTableAction = this.crudDirective.processes[0].actions[1];
          
          const populateFormAction = this.crudDirective.processes[1].actions[1];
          const populateSubmitFormAction = this.crudDirective.processes[1].actions[4];
          
          const addFormAction = this.crudDirective.processes[2].actions[0];
          
          getTableDataAction.dataElements = getTableDataDataElements;
          populateTableAction.dataElements = populateTableDataElements;
          populateFormAction.dataElements = populateUpdateFormDataElements;
          addFormAction.dataElements = addFormDataElements;
          populateSubmitFormAction.dataElements = submitUpdateFormDataElements;
          
          this.AssignComponentToAction(populateTableAction, crudTableComponent);
          this.AssignComponentToAction(populateFormAction, crudUpdateFormComponent);
          this.AssignComponentToAction(addFormAction, addFormComponent);
          
          directive = this.crudDirective;
          break;
        case 'crud2':
          
          const crud2QueryDataDataElements = this.GetStepDataElements(0);
          
          const crud2CreateDataElements = this.GetStepDataElements(1); //
          
          const crud2CreateFormComponent = this.GetStepComponent(2);
          
          const crud2UpdateFormDataElements = this.GetStepDataElements(3); //
          
          const crud2UpdateFormComponent = this.GetStepComponent(4);
          
          const crud2DeleteFormDataElements = this.GetStepDataElements(5); //
          
          const crud2DeleteFormComponent = this.GetStepComponent(6);
          const crud2TableDataElements = this.GetStepDataElements(8);
          const crud2ViewFormComponent = this.GetStepComponent(7);
          const crud2tableFormComponent = this.GetStepComponent(9);
          
          const crud2QueryDataAction = this.screenCRUD.processes[0].actions[0];
          
          const crud2CreateDataAction = this.screenCRUD.processes[1].actions[0];
          const crud2UpdateDataAction = this.screenCRUD.processes[2].actions[0];
          const crud2DeleteDataAction = this.screenCRUD.processes[3].actions[0];
          
          const crud2ViewDataAction = this.screenCRUD.processes[4].actions[0];
          const crud2TableDataAction = this.screenCRUD.processes[5].actions[0];
          const crud2SendDeleteDataAction = this.screenCRUD.processes[8].actions[1];
          const crud2SendUpdateDataAction = this.screenCRUD.processes[10].actions[1];
          const crud2SendViewDataAction = this.screenCRUD.processes[12].actions[1];
          
          crud2CreateDataAction.dataElements = crud2CreateDataElements;
          crud2UpdateDataAction.dataElements = crud2UpdateFormDataElements;
          crud2DeleteDataAction.dataElements = crud2DeleteFormDataElements;
          
          crud2QueryDataAction.dataElements = crud2QueryDataDataElements;
          crud2ViewDataAction.dataElements = crud2TableDataElements;
          crud2TableDataAction.dataElements = crud2TableDataElements;
          crud2SendDeleteDataAction.dataElements = crud2TableDataElements;
          crud2SendUpdateDataAction.dataElements = crud2TableDataElements;
          crud2SendViewDataAction.dataElements = crud2TableDataElements;
          
          const component = await this.apiMoleculesService.GetAppPromise(crud2tableFormComponent.id);
          
          if (component && component.length > 0) {
            const addMolecule = component.find(m => m.properties.name.toLowerCase()
            .includes('add'));
            const viewMolecule = component.find(m => m.properties.name.toLowerCase()
            .includes('view'));
            const deleteMolecule = component.find(m => m.properties.name.toLowerCase()
            .includes('delete'));
            const updateMolecule = component.find(m => m.properties.name.toLowerCase()
            .includes('edit'));
            
            if (addMolecule) {
              const addAction = this.screenCRUD.processes[7].actions[0];
              addAction.type.id = addMolecule.id;
              addAction.subject.id = addMolecule.id;
            }
            
            if (deleteMolecule) {
              const deleteAction1 = this.screenCRUD.processes[6].actions[0];
              const deleteAction2 = this.screenCRUD.processes[8].actions[0];
              deleteAction1.type.id = deleteMolecule.id;
              deleteAction1.subject.id = deleteMolecule.id;
              
              deleteAction2.type.id = deleteMolecule.id;
              deleteAction2.subject.id = deleteMolecule.id;
            }
            
            if (viewMolecule) {
              const viewAction1 = this.screenCRUD.processes[11].actions[0];
              const viewAction2 = this.screenCRUD.processes[12].actions[0];
              viewAction1.type.id = viewMolecule.id;
              viewAction1.subject.id = viewMolecule.id;
              
              viewAction2.type.id = viewMolecule.id;
              viewAction2.subject.id = viewMolecule.id;
            }
            
            if (updateMolecule) {
              const updateAction1 = this.screenCRUD.processes[9].actions[0];
              const updateAction2 = this.screenCRUD.processes[10].actions[0];
              updateAction1.type.id = updateMolecule.id;
              updateAction1.subject.id = updateMolecule.id;
              
              updateAction2.type.id = updateMolecule.id;
              updateAction2.subject.id = updateMolecule.id;
            }
            
          }
          
          this.AssignComponentToAction(crud2CreateDataAction, crud2CreateFormComponent);
          this.AssignComponentToAction(crud2UpdateDataAction, crud2UpdateFormComponent);
          this.AssignComponentToAction(crud2DeleteDataAction, crud2DeleteFormComponent);
          this.AssignComponentToAction(crud2ViewDataAction, crud2ViewFormComponent);
          this.AssignComponentToAction(crud2TableDataAction, crud2tableFormComponent);
          
          directive = this.screenCRUD;
          
          if (crud2CreateDataElements.length === 0) { // 2
            directive.processes.splice(directive.processes.findIndex(p => p.id === 2), 1);
            directive.processes.splice(directive.processes.findIndex(p => p.id === 8), 1);
            directive.processes.splice(directive.processes.findIndex(p => p.id === 14), 1);
          }
          
          if (crud2UpdateFormDataElements.length === 0) { // 3
            directive.processes.splice(directive.processes.findIndex(p => p.id === 3), 1);
            directive.processes.splice(directive.processes.findIndex(p => p.id === 10), 1); // 7
            directive.processes.splice(directive.processes.findIndex(p => p.id === 11), 1);
            directive.processes.splice(directive.processes.findIndex(p => p.id === 15), 1);
            
          }
          
          if (crud2DeleteFormDataElements.length === 0) { // 4
            directive.processes.splice(directive.processes.findIndex(p => p.id === 4), 1);
            directive.processes.splice(directive.processes.findIndex(p => p.id === 9), 1);
            directive.processes.splice(directive.processes.findIndex(p => p.id === 16), 1);
          }
          
          break;
      }
      
    }
    
    if (this.addNavigation) {
      directive.navigation.enabled = this.addNavigation;
      if (this.navigationComponent) {
        directive.navigation.componentId = this.navigationComponent.id;
      }
    }
    
    return directive;
  }
  
  SelectComponent(step: any, component: any) {
    
    
    if (step.value && step.value.name === component.name) {
      step.value = null;
    } else {
      step.value = component;
      this.MoveNext();
    }
    
    let key = null;
    
    if (step.id.includes('form')) {
      key = 'form';
    } else if (step.id.includes('table')) {
      key = 'table';
    } else if (step.id.includes('chart')) {
      key = 'chart';
    }
    
    if (!key) return;
    
    const unsetSteps = this.autoGenerationTypeSelected.steps.filter(
      s => s.autoSelectComponent && s.type === step.type && s.value === null && s.id.includes(key));
    
    unsetSteps.forEach(us => {
      us.value = component;
    });
    
  }
  
  CustomizeDirective() {
    this.dialogRef.close();
    this.OpenAutoGenerationApp();
  }
  
  async OpenAutoGenerationApp() {
    
    const directive = await this.ConvertSelectionToDirective();
    
    const data = {
      nodes: this.nodes,
      directive: directive,
    };
    
    this.dialog.open(GenerationAppComponent, {
      height: '800px',
      width: '1200px',
      disableClose: true,
      panelClass: 'custom-dialog-container',
      data,
    });
    
    // setTimeout(() => {
    //   this.communicationService.Event.Editor.DataSource.$ApiNodesSelected.emit(this.selectedNodes);
    // }, 200);
  }
  
  SelectComponentForNavigation(component: any) {
    this.navigationComponent = component;
    this.MoveNext();
  }
  
  ToggleNavigation(event: any) {
    this.addNavigation = event.checked;
    
    if (!this.addNavigation) {
      this.navigationComponent = null;
    }
  }
  
  NavigationPlaceholderRepMolecules() {
    return this.busService.RepMoleculesWithPlaceholder(Placeholders.Navigation);
  }
}
