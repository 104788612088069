import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TreeNode } from 'angular-tree-component';
import { ApiDataSourcesService } from '../../../core/services/api-data-sources.service';
import { EditorStateService } from '../../../core/services/editor-state.service';
import { ToolsService } from '../../../core/services/tools.service';
import { DataElementDirection } from '../../../shared/enums/data-element-direction.enum';
import { CommunicationService } from '../../../shared/services/communication.service';
import { DraggableWindowManagerService } from '../../../shared/services/draggable-window-manager.service';
import { DraggableWindowService } from '../../../shared/services/draggable-window.service';
import { DocumentationComponent } from '../../documentation/documentation.component';
import { WorkAreaService } from '../../workarea.service';

@Component({
  selector: 'app-datasource-tree-name',
  templateUrl: './datasource-tree-name.component.html',
  styleUrls: ['./datasource-tree-name.component.scss'],
})
export class DatasourceTreeNameComponent implements OnInit {
  
  @Input('node') node: TreeNode;
  @Input() lastUsedDataSource = '';
  @Output() setCategoryRename = new EventEmitter<TreeNode>();
  
  DataElementDirection = DataElementDirection;
  classIdentifier = '';
  
  constructor(
    private toolsService: ToolsService,
    private editorStateService: EditorStateService,
    private workAreaService: WorkAreaService,
    private communicationService: CommunicationService,
    private draggableWindowService: DraggableWindowService,
    private dragableWindowManagerService: DraggableWindowManagerService,
    public dataSourcesService: ApiDataSourcesService,
  ) {
  }
  
  ngOnInit() {
    this.classIdentifier = `ds-${ this.node.data.dataSourceId } ds-${ this.node.data.name.replace(/[#(){}. ]/g, '') }`;
  }
  
  SetCategoryRename(node: any) {
    // console.log(node);
    if (node.data.dataSourceId === null) {
      this.setCategoryRename.emit(node);
    }
  }
  
  OpenApiDocumentation(data: any) {
    console.log(data);
    
    this.toolsService.DragWindowConfig = {
      changeLayout: false,
      x: 500,
      y: 50,
      width: 800,
      height: 600,
    };
    
    this.workAreaService.userGuideId = data.path;
    this.workAreaService.userGuideType = 'Author';
    
    if (this.workAreaService.userGuideWindow) {
      this.communicationService.Event.Documentation.$Get.emit();
    } else {
      this.toolsService.DragWindowConfig = this.editorStateService.WindowSize('Microlearning');
      
      this.workAreaService.userGuideWindow = this.draggableWindowService.GenerateWindow(DocumentationComponent, {
        title: `Microlearning`,
        data: {
          id: data.path,
          type: 'Author',
        },
      });
      
      this.dragableWindowManagerService.ShowWindowAndStore(this.workAreaService.userGuideWindow);
    }
  }
}
