import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injector, ViewContainerRef } from '@angular/core';
import { DraggableWindowComponent } from './components/draggable-window/draggable-window.component';

export class DraggableWindow {
  public component: ComponentRef<any>;
  public Id: string;
  public draggableWindowComponentRef: ComponentRef<DraggableWindowComponent>;
  public type = '';
  private componentPortal: ComponentPortal<any>;
  private componentPortalHost: DomPortalHost;
  
  constructor(
    id: string,
    container: ViewContainerRef,
    componentFactoryResolver: ComponentFactoryResolver,
    appRef: ApplicationRef,
    injector: Injector,
    componenentType: any,
    title: string,
    data = {},
    type = null,
  ) {
    const factory = componentFactoryResolver.resolveComponentFactory(
      DraggableWindowComponent,
    );
    this.draggableWindowComponentRef = container.createComponent(factory);
    this.draggableWindowComponentRef.instance.ref = this.draggableWindowComponentRef;
    this.draggableWindowComponentRef.instance.title = this.TextTrim(title, 70, '...');
    this.draggableWindowComponentRef.instance.componentId = id;
    this.draggableWindowComponentRef.instance.data = data;
    const draggableWindowElement = this.draggableWindowComponentRef.location.nativeElement.children[0];
    
    this.componentPortalHost = new DomPortalHost(
      draggableWindowElement,
      componentFactoryResolver,
      appRef,
      injector,
    );
    
    this.Id = id;
    this.componentPortal = new ComponentPortal(componenentType);
    this.type = type;
  }
  
  Show(): ComponentRef<any> {
    if (this.componentPortalHost.hasAttached()) {
      this.componentPortalHost.detach();
    }
    this.component = this.componentPortalHost.attach(this.componentPortal).instance;
    setTimeout(() => {
      this.draggableWindowComponentRef.instance.AddToOpenedWindows();
      this.draggableWindowComponentRef.instance.Show();
    }, 50);
    
    return this.component;
  }
  
  Hide() {
    this.componentPortalHost.detach();
    this.draggableWindowComponentRef.destroy();
  }
  
  FocusWindow() {
    console.log(this.draggableWindowComponentRef);
    this.draggableWindowComponentRef.instance.EvaluateWindowBounds();
    this.componentPortalHost?.outletElement?.classList.add('focus-window');
  }
  
  TextTrim(text: string, length = 9999999, characterAppend = ''): string {
    if (text.length > length) {
      const trimmedText = text.substring(0, length);
      return `${ trimmedText }${ characterAppend }`;
    } else {
      return text;
    }
  }
}
