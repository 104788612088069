import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as fileSaver from 'file-saver';
import { ApiLeapxlExportService } from '../../core/services/api-leapxl-export.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { LeapXLFileFormat } from '../../shared/enums/leapxl-file-format.enum';
import { CommunicationService } from '../../shared/services/communication.service';
import { DraggableWindowService } from '../../shared/services/draggable-window.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-leapxl-export',
  templateUrl: './leapxl-export.component.html',
  styleUrls: ['./leapxl-export.component.scss'],
})
export class LeapxlExportComponent implements OnInit {
  
  title = '';
  importPassword = '';
  encryptPassword = '';
  encrypt = true;
  exportType = '';
  exportName = '';
  exportId = 0;
  readOnly = false;
  preparingExport = false;
  downloadingProgress = 0;
  downloading = false;
  isApplication = false;
  userPermissions = [];
  
  constructor(
    private draggableWindowService: DraggableWindowService,
    private leapxlExportService: ApiLeapxlExportService,
    private communicationService: CommunicationService,
    private snackerService: SnackerService,
    private clientStorageService: ClientStorageService,
    private workAreaService: WorkAreaService) {
    const exportData = this.draggableWindowService.GetData().export || {};
    this.exportType = exportData.type || 'app';
    this.exportName = exportData.name || '';
    this.exportId = exportData.id || 0;
    this.isApplication = exportData.isApplication;
    this.userPermissions = clientStorageService.getPermissions();
    
    switch (this.exportType) {
      case 'app':
        this.title = `Export ${ this.exportName } Application`;
        break;
    }
  }
  
  ngOnInit() {
  }
  
  Export() {
    this.preparingExport = true;
    this.leapxlExportService.ExportApplication(this.readOnly, this.encrypt, this.encryptPassword, this.importPassword, this.exportId).subscribe(response => {
      switch (response.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          this.preparingExport = false;
          this.downloading = true;
          this.downloadingProgress = 0;
          break;
        case HttpEventType.DownloadProgress:
          const percent = Math.round(100 * (response.loaded / response.total));
          this.downloadingProgress = percent;
          break;
        case HttpEventType.Response:
          setTimeout(() => {
            this.downloading = false;
          }, 1500);
          fileSaver.saveAs(response.body, this.exportName + `.${ this.isApplication ? LeapXLFileFormat.Application : LeapXLFileFormat.Component }`);
          this.snackerService.ShowMessageOnBottom('App exported successfully!', 'download', 3000, true);
          setTimeout(() => {
            this.communicationService.Event.Editor.WorkArea.$CloseAllDraggableWindows.emit();
          }, 150);
          break;
      }
    }, error => {
      this.snackerService.ShowMessageOnBottom('Error exporting Application', 'file_download_off');
    }, () => {
    });
  }
}
