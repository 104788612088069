import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiDataQualityDataService } from './api-data-quality-data.service';

@Injectable()
export class DataQualityDialogService {
  constructor(
    public dialog: MatDialog,
    private dataQualityDataService: ApiDataQualityDataService,
  ) {
  }
  
  // openDialog(
  //   dataSourceId: number,
  //   name: string,
  //   collection: string,
  //   range: string
  // ): BehaviorSubject<any> {
  //   this.dataQualityDataService
  //     .in(dataSourceId)
  //     .subscribe(response => {
  //       response.name = name;
  //       response.collection = collection;
  //       response.dataRange = range;
  //       response.dataSourceId = dataSourceId;
  //       const dialogRef = this.dialog.open(DataQualityComponent, {
  //         data: response
  //       });
  //
  //       dialogRef.afterClosed().subscribe(result => {
  //         return result;
  //       });
  //     });
  //   return null;
  // }
}
