import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfig } from '../../models/field-config.model';

@Component({
  selector: 'app-dynamic-button',
  template: `
  <div class="panel-viewer-body">
  <div
      class="dynamic-field form-button"
      [formGroup]="group">
      <button md-dialog-close [disabled]="config.disabled" mat-raised-button>{{ config.label }}</button>
    </div>
  </div>


  `,
  styleUrls: ['./dynamic-button.component.css'],
})
export class DynamicButtonComponent implements OnInit {
  config: FieldConfig;
  group: FormGroup;

  constructor() {
  }
  
  ngOnInit() {
  }
}
