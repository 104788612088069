import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, map } from 'rxjs/operators';
import { CobbleUser } from '../../shared/models/cobble-user.model';
import { LicenseRole } from '../../shared/models/licenserole.model';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';

import { ApiDataService } from './api-data.service';

@Injectable()
export class ApiLicenseService extends ApiDataService {
  constructor(http: HttpClient, errorMessengerService: ErrorMessengerService,
  ) {
    super('license', http, errorMessengerService);
  }
  
  // getRolesAvailables(companyId: number): Observable<LicenseRole[]> {
  //   return this.http.get(this.apiUrl + `/rolesAvailables`).pipe(
  //     map(response => this.parseRoleArray(<any>response)),
  //     catchError(error => this.errorMessengerService.HandleError(error))
  //   );
  // }
  // getUsersForLicense(): Observable<CobbleUser[]> {
  //   return this.http.get(this.apiUrl + `/listUsersAdded`).pipe(
  //     map(response => this.parseCobbleUsers(<any>response)),
  //     catchError(error => this.errorMessengerService.HandleError(error))
  //   );
  // }
  // removeCobbleUser(user: CobbleUser): any {
  //   return (
  //     this.http
  //       .delete(`${this.apiUrl}/removeuser/${user.id}`)
  //       .pipe(
  //         catchError(error => this.errorMessengerService.HandleError(error))
  //       )
  //   );
  // }
  createLicensedUser(licensedUser: any) {
    return this.http
    .post(`${ this.apiEndpointUrl }/create`, licensedUser)
    .pipe(
      map(response => <any>response),
      catchError(error => this.errorMessengerService.HandleError(error)),
    );
  }
  
  private parseRoleArray(licenses: any[]): LicenseRole[] {
    const newLicenses = [];
    
    licenses.forEach(element => {
      newLicenses.push(this.parseRoleObject(element));
    });
    
    return newLicenses;
  }
  
  private parseRoleObject(license: any) {
    const newLicense: LicenseRole = new LicenseRole();
    Object.assign(newLicense, license);
    newLicense.list = [];
    
    license.list.forEach(element => {
      newLicense.list.push(element);
    });
    
    return newLicense;
  }
  
  private parseCobbleUsers(users: any[]): CobbleUser[] {
    const newUsers = [];
    
    users.forEach(element => {
      newUsers.push(this.parseUserCobbletObject(element));
    });
    
    return newUsers;
  }
  
  private parseUserCobbletObject(user: any) {
    const newUser: CobbleUser = new CobbleUser();
    Object.assign(newUser, user);
    return newUser;
  }
}
