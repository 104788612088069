export const h4Template = [
  {
    icon: 'format_h4',
    moleculeType: 'Representative',
    name: 'Heading 4',
    type: 'H4',
    textToDisplay: 'Heading 4',
    moleculesAllow: ['Representative', 'Compounds', 'Configuration', 'DataAction', 'Behavior'],
    dataSource: {},
    styleMetadata: {
      styles: [],
      specificStyles: {
        dimension: null,
        font: null,
        hover: null,
        frame: null,
        appearance: null,
      },
      manualAdjustedProperties: [],
    },
    receptors: ['value-input', 'value-output', 'tooltip-input', 'badge-value-input', 'badge-value-output',
      'none'],
    buses: [],
    debounce: {
      events: [],
    },
    minValue: null,
    maxValue: null,
    events: ['init', 'hover'],
    defaultEvent: 'init',
    creationFromTemplate: false,
    editableProperties: [
      'mask',
      'textToDisplay',
      'badge',
      'cols',
      'rows',
      'x',
      'y',
      'tabindex',
      'font',
      'hover',
      'borders',
      'opacity',
      'shadow',
      'background',
      'backgroundType',
    ],
    properties: {
      align: 'left', horizontalPadding: 0,
      verticalPadding: 0,
      progressType: 'progress',
      progressColor: '#EA5C2F',
      progressBackgroundColor: '#E5957C',
      progressDiameter: 50,
      progressMode: 'indeterminate',
      progressStrokeWidth: 5,
      wrapText: false,
      allowManualEntry: false,
      alwaysRenderComponent: false,
      sliderVertical: false,
      sliderStep: 1,
      sliderThumbLabel: false,
      sliderTicks: false,
      sliderColor: '#EA5C2F',
      location: null,
      viewNames: [],
      allowEmptyValues: false,
      hover: {
        hoverBackground: 'white',
        hoverBackgroundOpacity: 1,
        hoverBorderRadius: 0,
        hoverFontColor: null,
        
        hoverBorderStyle: 'none',
        hoverBorderColor: 'transparent',
        hoverBorderWidth: 0,
        hoverTextDecoration: null,
        hoverPointer: null,
      },
      options: [
        {
          value: 'value 1',
          text: 'Text 1',
        },
      ],
      show: true,
      opacity: 1,
      orientation: 'col',
      enable: true,
      altText: '',
      dateRange: false,
      search: false,
      multiSelect: false,
      autoDataRefresh: false,
      ignoreValueDataIndex: false,
      textDecoration: 'none',
      cols: 10,
      rows: 4,
      name: 'Heading 4',
      legend: true,
      loadingText: '',
      maskType: 'none',
      mask: '',
      badge: {
        badgeEnable: true,
        badgeBackgroundColor: '#f22525',
        badgeFontFamily: 'Source Sans Pro',
        badgeFontColor: 'white',
        badgeStyle: 'circle',
        badgePosition: 'right',
      },
      background: {
        backgroundColor: 'transparent',
        backgroundTypeImage: false,
        backgroundImageUrl: '',
        backgroundOpacity: 1,
      },
      bordersValues: {
        borderWidth: 1,
        borderStyle: 'none',
        borderRadius: 0,
        borderColor: '#212529',
      },
      icon: {
        iconType: 'stars',
        iconColor: 'black',
        iconSize: 45,
      },
      shadowValues: {
        hShadow: 0,
        vShadow: 0,
        shadowColor: '#212529',
        shadowBlur: 0,
      },
      preferences: {},
      view: 1,
      chartLibrary: 'ngxCharts',
      chartType: 'line',
      tabindex: 0,
      textToDisplay: 'Heading 4',
      tooltip: '',
      placeholder: 'Heading 4',
      minItemCols: 1,
      tableOrder: [],
      
      maxItemCols: 200,
      responsive: {
        desktop: {
          customGuidelines: {
            x: [],
            y: [],
          },
          id: 0,
          cols: 38,
          rows: 12,
          colsQty: 0,
          rowsQty: 0,
          layer: 1,
          centerPositioning: false,
          x: 0,
          y: 0,
          font: {
            fontSize: 16,
            fontColor: '#000000',
            fontFamily: 'inherit',
            fontStyle: 'inherit',
            fontWeight: 'normal',
          },
          tableOptions: {
            collapse: true,
            bodyRoundCorners: false,
            search: true,
            header: true,
            verticallyCenter: true,
            rowSeparator: false,
            columnSeparator: false,
            rowPadding: true,
            tableOrder: [],
            tableWidth: {},
          },
        },
        smartphone: {
          customGuidelines: {
            x: [],
            y: [],
          },
          id: null,
          cols: null,
          rows: null,
          colsQty: null,
          rowsQty: null,
          layer: null,
          centerPositioning: false,
          x: null,
          y: null,
          font: {
            fontSize: null,
            fontColor: null,
            fontFamily: null,
            fontStyle: null,
            fontWeight: null,
          },
          tableOptions: {
            search: true,
            header: true,
            verticallyCenter: null,
            rowSeparator: null,
            columnSeparator: null,
            rowPadding: null,
            tableOrder: [],
            tableWidth: {},
          },
        },
      },
    },
    molecules: [],
    placeholders: [],
    isSelected: false,
    enable: true,
    value: null,
    children: [],
    replaceableByRepresentative: true,
    loading: false,
    autoGenerationInfo: [],
    moleculeSubType: 'H4',
    optionsMenu: {
      configuration: true,
      molecules: true,
      compound: false,
      cobblet: true,
      remove: true,
      versioning: true,
      dataSource: true,
      duplicate: true,
    },
  },
];
