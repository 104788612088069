import { Injectable } from '@angular/core';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { Constants } from '../../shared/constants';
import { CookieService } from './cookie-service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ClientStorageService {
  constructor(private localStorageService: LocalStorageService, private adalSvc: MsAdalAngular6Service, private cookieService: CookieService) {
  }
  
  decode(token: string) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  }
  
  /**
   * Returns the JWT toke for the logged user.
   */
  getToken() {
    return this.localStorageService.Get('jwt');
  }
  
  /**
   * Returns if token is valid.
   */
  isTokenValid(): boolean {
    if (this.getUserType() === 'external') {
      console.log('logout by adal');
      return this.adalSvc.isAuthenticated;
    } else {
      
      return true;
      const token = this.getToken();
      try {
        if (token) {
          const date = new Date(0);
          const decoded = this.decode(token);
          date.setUTCSeconds(decoded.exp);
          
          const expired = date.valueOf() > new Date().valueOf();
          
          if (!expired) {
            console.log('logout token expired');
          }
          
          return expired;
        } else {
          console.log('no token');
          return false;
        }
      } catch (err) {
        console.log('token no valid error');
        return false;
      }
    }
  }
  
  /**
   * Returns the companyName for the logged user.
   */
  getCompanyName(): string {
    return this.localStorageService.Get('companyName');
  }
  
  /**
   * Returns the department for the logged user.
   */
  getDepartment(): string {
    return this.localStorageService.Get('department');
  }
  
  /**
   * Returns the companyId for the logged user.
   */
  getCompanyId(): number {
    return +this.localStorageService.Get('companyId');
  }
  
  /**
   * Returns the companyId for the logged user.
   */
  getCurrentCompanyId(): number {
    return +this.localStorageService.Get('currentCompanyId');
  }
  
  /**
   * Returns the userId for the logged user.
   */
  getUserId(): number {
    return +this.localStorageService.Get('userId');
  }
  
  /**
   * Returns the userRole for the logged user.
   */
  isRole(roleId: number): boolean {
    return this.getUserRoleId() === roleId;
  }
  
  /**
   * Returns the userRole for the logged user.
   */
  getUserRole(): string {
    return this.localStorageService.Get('userRole');
  }
  
  /**
   * Returns the userRoleId for the logged user.
   */
  getUserRoleId(): number {
    return +this.localStorageService.Get('userRoleId');
  }
  
  /**
   * Returns the type for the logged user.
   */
  getUserType(): string {
    return this.localStorageService.Get('userType');
  }
  
  /**
   * Returns the 2fa state.
   */
  get2faState(): string {
    return this.localStorageService.Get('2faLogged');
  }
  
  /**
   * Returns the userName for the logged user.
   */
  getUserName() {
    return this.localStorageService.Get('userName');
  }
  
  /**
   * Returns the default app for the logged user.
   */
  getDefaultApp() {
    return this.localStorageService.Get('defaultApp');
  }
  
  /**
   * Returns the startpage for the logged user.
   */
  getGuideStartPage() {
    return this.localStorageService.Get('guideStartPage');
  }
  
  /**
   * Returns the startpage for the logged user.
   */
  getInitialAssistPage() {
    return this.localStorageService.Get('initialAssistPage');
  }
  
  gethideSmartTemplateStartPage() {
    return this.localStorageService.Get('hideSmartTemplateStartPage');
  }
  
  getHideRearrangeDatasource() {
    return this.localStorageService.Get('hideRearrangeDatasource');
  }
  
  /**
   * Returns the companySlug for the logged user.
   */
  getCompanySlug() {
    return this.localStorageService.Get('companySlug');
  }
  
  /**
   * Returns the representation of the full name for the logged user.
   */
  getUserFullName() {
    return this.localStorageService.Get('userFullName');
  }
  
  /**
   * Returns the company default theme.
   */
  getCompanyDefaultThemeId() {
    return this.localStorageService.Get('companyDefaultThemeId');
  }
  
  /**
   * Returns the company lock default theme.
   */
  getLockAppsToDefaultTheme() {
    return this.localStorageService.Get('lockAppsToDefaultTheme');
  }
  
  /**
   * Returns the permissions array.
   */
  getPermissions() {
    return this.localStorageService.Get('userPermissions') || [];
  }
  
  /**
   * Returns the menu entries array.
   */
  getMenuEntries() {
    const menuEntries = JSON.parse(this.localStorageService.Get('menuEntries'));
    console.log(menuEntries);
    return menuEntries || [];
  }
  
  /**
   * datasource provisioning.
   */
  getDataSourceProvisioning() {
    console.log(this.localStorageService.Get('isSelfProvisioning'));
    let value = this.localStorageService.Get('isSelfProvisioning');
    
    try {
      value = JSON.parse(value);
    } catch (e) {
      value = false;
    }
    
    return value || false;
  }
  
  getUserInfo() {
    return {
      token: this.getToken(),
      companyId: this.getCompanyId(),
      currentCompanyId: this.getCurrentCompanyId(),
      companySlug: this.getCompanySlug(),
      userId: this.getUserId(),
      userRole: this.getUserRoleId(),
      userName: this.getUserName(),
      userFullName: this.getUserFullName(),
      permissions: this.getPermissions(),
      menuEntries: this.getMenuEntries(),
    };
  }
  
  refreshSessionData(user: any) {
    this.localStorageService.Set('userName', user.email);
    this.localStorageService.Set('companyName', user.companyName);
    this.localStorageService.Set('department', user.department);
    this.localStorageService.Set('companyId', user.companyId);
    this.localStorageService.Set('companySlug', user.companySlug);
    this.localStorageService.Set('currentCompanyId', user.companyId);
    this.localStorageService.Set('userLastName', user.lastName);
    this.localStorageService.Set('userId', user.id);
    this.localStorageService.Set('userFullName', user.fullName);
    this.localStorageService.Set('userRoleId', user.roleId);
    this.localStorageService.Set('lockAppsToDefaultTheme', user.lockAppsToDefaultTheme);
    this.localStorageService.Set('userRole', user.role);
    this.localStorageService.Set('companyDefaultThemeId', user.companyDefaultThemeId);
    this.localStorageService.Set('guideStartPage', user.guideHideStartPage);
    this.localStorageService.Set('hideSmartTemplateStartPage', user.hideSmartTemplateStartPage);
    this.localStorageService.Set('userPermissions', JSON.stringify(user.permissions));
    this.localStorageService.Set('menuEntries', JSON.stringify(user.menuEntries));
    this.localStorageService.Set('isSelfProvisioning', user.isSelfProvisioning);
    
    if (user.defaultApp && user.defaultApp !== '' && user.defaultApp.length > 3 && user.defaultApp.indexOf('/') > -1) {
      this.localStorageService.Set('defaultApp', user.defaultApp);
    }
  }
  
  getAntiforgeryToken() {
    const tokenName = 'XSRF-TOKEN';
    return this.cookieService.Get(tokenName) || this.localStorageService.Get('XSRF-TOKEN') || '';
  }
  
  setAntiforgeryToken(token: string) {
    this.localStorageService.Set('XSRF-TOKEN', token);
  }
  
  /**
   * Sets the session login info after succesful login.
   * @param token the JWT token
   * @param userName the logged username
   * @param user the object returned by the API
   */
  setSessionData(token: any, userName: string, user: any) {
    this.clearSession();
    this.localStorageService.Set('jwt', token);
    this.localStorageService.Set('userName', userName);
    this.localStorageService.Set('userType', user.type);
    this.localStorageService.Set('companyName', user.companyName);
    this.localStorageService.Set('department', user.department);
    this.localStorageService.Set('companyId', user.companyId);
    this.localStorageService.Set('companySlug', user.companySlug);
    this.localStorageService.Set('lockAppsToDefaultTheme', user.lockAppsToDefaultTheme);
    this.localStorageService.Set('companyDefaultThemeId', user.companyDefaultThemeId);
    this.localStorageService.Set('currentCompanyId', user.companyId);
    this.localStorageService.Set('userLastName', user.lastName);
    this.localStorageService.Set('userId', user.id);
    this.localStorageService.Set('userFullName', user.fullName);
    this.localStorageService.Set('userRoleId', user.roleId);
    this.localStorageService.Set('userRole', user.role);
    this.localStorageService.Set('guideStartPage', user.guideHideStartPage);
    this.localStorageService.Set('hideSmartTemplateStartPage', user.hideSmartTemplateStartPage);
    this.localStorageService.Set('userPermissions', JSON.stringify(user.permissions));
    this.localStorageService.Set('menuEntries', JSON.stringify(user.menuEntries));
    this.localStorageService.Set('isSelfProvisioning', user.isSelfProvisioning);
    
    if (user.defaultApp && user.defaultApp !== '' && user.defaultApp.length > 3 && user.defaultApp.indexOf('/') > -1) {
      this.localStorageService.Set('defaultApp', user.defaultApp);
    }
  }
  
  /**
   * Set the company id currently selected.
   */
  setCurrentCompanyId(companyId: any) {
    this.localStorageService.Set('currentCompanyId', companyId);
  }
  
  setDocumentationStartPage(condition: string) {
    this.localStorageService.Set('guideStartPage', condition);
  }
  
  setInitialAssistPage(condition: string) {
    this.localStorageService.Set('initialAssistPage', condition);
  }
  
  setHideSmartTemplateStartPage(condition: string) {
    this.localStorageService.Set('hideSmartTemplateStartPage', condition);
  }
  
  setHideRearrangeDatasource(condition: string) {
    this.localStorageService.Set('hideRearrangeDatasource', condition);
  }
  
  set2faLogged() {
    this.localStorageService.Set('2faLogged', true);
  }
  
  clearSession() {
    // console.log('clearing session');
    
    this.localStorageService.Remove('jwt');
    this.localStorageService.Remove('userName');
    this.localStorageService.Remove('userType');
    this.localStorageService.Remove('companyId');
    this.localStorageService.Remove('companyName');
    this.localStorageService.Remove('department');
    this.localStorageService.Remove('companySlug');
    this.localStorageService.Remove('currentCompanyId');
    this.localStorageService.Remove('userLastName');
    this.localStorageService.Remove('userId');
    this.localStorageService.Remove('defaultApp');
    this.localStorageService.Remove('userFullName');
    this.localStorageService.Remove(Constants.SessionId);
    this.localStorageService.Remove('userRoleId');
    this.localStorageService.Remove('userRole');
    this.localStorageService.Remove('userPermissions');
    this.localStorageService.Remove('GuideStartPage');
    this.localStorageService.Remove('hideSmartTemplateStartPage');
    this.localStorageService.Remove('isSelfProvisioning');
    this.localStorageService.Remove('2faLogged');
  }
}
