import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSidenav } from '@angular/material/sidenav';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { version } from '../../environments/version';
import { ApiAdminService } from '../core/services/api-admin.service';
import { ApiAuthService } from '../core/services/api-auth.service';
import { ApiCompaniesService } from '../core/services/api-companies.service';
import { ApiMoleculesService } from '../core/services/api-molecules.service';
import { CacheService } from '../core/services/cache.service';
import { ClientStorageService } from '../core/services/client-storage.service';
import { CustomIconsService } from '../core/services/custom-icons.service';
import { ToolsService } from '../core/services/tools.service';
import { UserMenuService } from '../core/services/user-menu.service';
import { CobbleUser } from '../shared/models/cobble-user.model';
import { CompanyLicense } from '../shared/models/company-license.model';
import { LicenseRole } from '../shared/models/licenserole.model';
import { UserRole } from '../shared/models/user-role.enum';
import { SnackerService } from '../shared/services/snacker.service';
import { Permissions } from './models/permissions.enum';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild('sidenav', { static: false })
  sidenav: MatSidenav;
  @ViewChild('sidenavPasswordChange', { static: true })
  passwordChangeSidenav: MatSidenav;
  
  @ViewChild('defaultAppCheckbox', { static: false })
  defaultAppCheckbox: MatCheckbox;
  user = new CobbleUser();
  companyId: number;
  roleId: number;
  licenseRoles: LicenseRole[] = [];
  availableRoles: LicenseRole[] = [];
  roles: LicenseRole[] = [];
  users: CobbleUser[] = [];
  usersDs: MatTableDataSource<CobbleUser>;
  companyForLicense: CompanyLicense;
  companiesFiltered: CompanyLicense[];
  userColumnsToDisplay = ['name', 'phone', 'email', 'roleId', 'assignedTo', 'menu'];
  dropZones: String[] = ['dzUserCobbles'];
  backendVersion: string;
  frontendVersion: string;
  @ViewChild('passwordWithValidation', { static: false })
  password: any;
  @ViewChild('confirmPassword', { static: false })
  confirmPass: any;
  strength: number;
  passwordMatch: boolean;
  valid: boolean;
  currentPassword = '';
  currentUser: any = {};
  currentCompany: number;
  selectedCompany: CompanyLicense = null;
  companies: any[];
  showCreateCompany: boolean;
  canCreateUser: boolean;
  showSetLicense: boolean;
  usersSubscription$: Subscription;
  companyApps: any[] = [];
  userDefaultApp = null;
  
  constructor(
    private customIconsService: CustomIconsService,
    private adminService: ApiAdminService,
    private authService: ApiAuthService,
    private snackerService: SnackerService,
    public clientStorage: ClientStorageService,
    private route: ActivatedRoute,
    public userMenu: UserMenuService,
    private titleService: Title,
    public dialog: MatDialog,
    private cacheService: CacheService,
    private moleculesService: ApiMoleculesService,
    private toolsService: ToolsService,
    private companiesService: ApiCompaniesService,
  ) {
    this.companyId = this.clientStorage.getCompanyId();
    this.currentCompany = this.companyId;
    this.roleId = this.clientStorage.getUserRoleId();
    this.customIconsService.init();
    this.frontendVersion = `${ version.build }-${ version.date }`;
    adminService.getBackendVersion().subscribe(result => {
      this.backendVersion = result.version;
    });
    companiesService.GetDefaultAppsByCompany(this.clientStorage.getCompanyId()).subscribe(apps => {
      this.companyApps = apps.filter(app => app.isRunnable).sort(this.toolsService.CompareValues('name'));
    });
    if (this.isSuperUser) {
      this.companyForLicense = new CompanyLicense();
      this.selectedCompany = new CompanyLicense();
      this.getAllRoles();
      this.adminService.getCompanies().subscribe(companies => {
        this.companies = companies;
        this.companiesFiltered = companies;
        this.selectedCompany = this.companies.find(c => c.id === this.companyId);
      });
    }
  }
  
  get isSuperUser() {
    return this.userMenu.checkPermission(Permissions.SpecialPermissions);
  }
  
  get canCreateCompany() {
    return this.isSuperUser && this.showCreateCompany;
  }
  
  get canSetCompanyLicense() {
    return this.isSuperUser && this.showSetLicense;
  }
  
  get isCompanyDiferentFromSuperUser() {
    return this.companyId !== this.currentCompany;
  }
  
  ngOnInit() {
    this.strength = 0;
    this.passwordMatch = false;
    this.valid = false;
    
    this.authService.getUserById(+this.clientStorage.getUserId()).subscribe(user => {
      this.currentUser = user;
    });
    
    this.getLicenseUsers();
    this.getCompanyLicenseInfo();
    
    this.route.data.subscribe(routeData => {
      this.titleService.setTitle(routeData.title);
    });
  }
  
  onDrop(e: any, user: any) {
    const nodeLevel = e.dragData;
    // console.log('user', user);
    
    this.adminService.addHierarchyNodeToUser(user.id, nodeLevel.other.id).subscribe(response => {
      // console.log('addHierarchyNodeToUser reponse', response);
      this.refreshUsers();
    });
  }
  
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.usersDs.filter = filterValue;
  }
  
  getRole(roleId: number) {
    const role = this.licenseRoles.find(r => r.id === roleId);
    
    if (role) {
      return role.name;
    }
    return '';
  }
  
  getLicenseRoles(): void {
    this.adminService.getRolesAvailables(this.currentCompany).subscribe(roles => {
      this.licenseRoles = roles.licenseRoles;
      this.availableRoles = roles.availableRoles;
      // console.log('Licensed Roles', roles);
    });
  }
  
  getAllRoles(): void {
    this.adminService.getRolesAvailables(0).subscribe(roles => {
      this.roles = roles.availableRoles;
      // console.log('All Roles', roles);
    });
  }
  
  getLicenseUsers() {
    this.usersSubscription$ = this.adminService.users$.subscribe(users => {
      // console.log('Users', users);
      
      this.usersDs = new MatTableDataSource<CobbleUser>(users);
      this.usersDs.paginator = this.paginator;
    });
  }
  
  closeNewUser(userForm: NgForm) {
    this.sidenav.close();
    this.showCreateCompany = false;
    this.canCreateUser = false;
    this.showSetLicense = false;
    userForm.resetForm();
  }
  
  addUser(userForm: NgForm) {
    // If is creating company
    if (this.canCreateCompany) {
      const newUser: any = Object.assign({}, this.user);
      const newCompany: any = Object.assign({}, this.companyForLicense);
      newCompany.user = newUser;
      newCompany.roles = [];
      
      for (const role of this.companyForLicense.roles) {
        if (role.availableQty > 0) {
          newCompany.roles.push({
            id: role.id,
            availableQty: role.availableQty,
          });
        }
      }
      
      const rolesSelected = this.companyForLicense.roles.filter(role => role.availableQty > 0);
      this.companyForLicense.roles = rolesSelected;
      this.adminService.createLicensedUser(newCompany).subscribe(company => {
        if (company) {
          this.refreshCompanies();
          this.closeNewUser(userForm);
          this.snackerService.ShowMessageOnBottom('Company and User Added!', 'person_add', null, true);
        } else {
          this.snackerService.ShowMessageOnBottom('The Company and user could no be created! Please ask for support.', 'warning');
        }
      });
    } else if (this.canSetCompanyLicense) {
      // If is updating license
      this.updateLicense(userForm);
    } else {
      // If is creating an user
      
      if (this.defaultAppCheckbox.checked && this.userDefaultApp) {
        this.user.defaultApp = `${ this.userDefaultApp.companySlug }/${ this.userDefaultApp.slug }`;
      }
      
      this.user.companyId = this.currentCompany;
      this.authService.registerCobbleUser(this.user).subscribe(result => {
        if (result) {
          // console.log('user', this.user);
          const newUser: CobbleUser = Object.assign({}, this.user);
          newUser.id = result;
          this.users = this.adminService.getUsersList();
          this.users.push(newUser);
          this.adminService.setUsersList(this.users);
          this.getCompanyLicenseInfo();
          this.closeNewUser(userForm);
          this.snackerService.ShowMessageOnBottom('User Added!', 'person_add', null, true);
        }
      });
    }
  }
  
  updateLicense(userForm: NgForm) {
    const license: any = {};
    license.companyId = this.companyForLicense.id;
    license.roles = [];
    for (const role of this.companyForLicense.roles) {
      if (role.availableQty > -1) {
        license.roles.push({ id: role.id, availableQty: role.availableQty });
      }
    }
    this.adminService.updateLicense(license).subscribe(result => {
      if (result) {
        this.refreshCompanies();
        this.closeNewUser(userForm);
        this.snackerService.ShowMessageOnBottom(`License for ${ this.companyForLicense.name } updated!`);
        this.getCompanyLicenseInfo();
      } else {
        this.snackerService.ShowMessageOnBottom(`License for ${ this.companyForLicense.name } could not be updated`);
      }
    });
  }
  
  removeUser(user: CobbleUser) {
    this.adminService.removeCobbleUser(user).subscribe(result => {
      if (result) {
        this.snackerService.ShowMessageOnBottom(`User ${ user.email } removed!`, 'do_not_disturb_on');
        this.getCompanyLicenseInfo();
      } else {
        this.snackerService.ShowMessageOnBottom(`Could not remove User ${ user.email }`, 'do_not_disturb_off');
      }
    });
  }
  
  getCompanyLicenseInfo() {
    this.getLicenseRoles();
    this.refreshUsers();
  }
  
  closePasswordChangeSidenav() {
    this.passwordChangeSidenav.close();
  }
  
  onStrengthChanged(e: any) {
    this.strength = e;
  }
  
  onkeyupPassword() {
    this.validPasswords();
  }
  
  onCopy() {
    return false;
  }
  
  validPasswords() {
    if (this.confirmPass && this.confirmPass) {
      this.passwordMatch = this.password.nativeElement.value === this.confirmPass.nativeElement.value;
      
      this.valid = this.passwordMatch && this.strength === 100;
    } else {
      this.valid = false;
    }
  }
  
  onConfirmPasswordChange() {
    this.validPasswords();
  }
  
  changePassword(form: NgForm) {
    this.authService.changePassword(this.currentPassword, this.password.nativeElement.value).subscribe(result => {
      if (result) {
        this.currentPassword = '';
        this.password.nativeElement.value = '';
        this.confirmPass.nativeElement.value = '';
        
        this.snackerService.ShowMessageOnBottom('Password changed successfully!', 'password');
        this.closePasswordChangeSidenav();
      } else {
        this.snackerService.ShowMessageOnBottom('Error changing the password', 'error');
      }
    });
  }
  
  userIsAdmin(role: LicenseRole) {
    return role.name === UserRole.Admin.toString();
  }
  
  getUserForCompany(companyId) {
    this.adminService.getUsersForCompany(companyId).subscribe(users => {
      this.adminService.setUsersList(users);
    });
  }
  
  showUserSideNav(showCreateCompany: boolean = false, showCreateUser: boolean = true, showSetLicense = false) {
    this.sidenav.open();
    this.showCreateCompany = showCreateCompany;
    this.canCreateUser = showCreateUser;
    this.showSetLicense = showCreateCompany || showSetLicense;
  }
  
  createNewUser() {
    this.companyForLicense = Object.assign({}, this.selectedCompany);
    this.showUserSideNav();
  }
  
  createNewLicense() {
    this.companyForLicense = new CompanyLicense();
    const formattedRoles = [];
    for (const role of this.roles) {
      const newRole = Object.assign({}, role);
      this.setDefaultRoleQty(newRole);
      formattedRoles.push(newRole);
    }
    this.companyForLicense.roles = formattedRoles;
    this.showUserSideNav(true);
  }
  
  editLicense() {
    this.companyForLicense = Object.assign({}, this.selectedCompany);
    const formattedRoles = [];
    for (const role of this.roles) {
      const newRole = Object.assign({}, role);
      const currentRole = this.licenseRoles.find(r => r.id === newRole.id);
      if (currentRole) {
        newRole.availableQty = currentRole.other.qtyTotal;
        newRole.degradableLimit = currentRole.other.qtyTotal - currentRole.other.availableQty;
      } else {
        this.setDefaultRoleQty(newRole);
      }
      formattedRoles.push(newRole);
    }
    this.companyForLicense.roles = formattedRoles;
    this.showUserSideNav(false, false, true);
  }
  
  companyPickChanged(companyId: number, companiesPicker: HTMLInputElement) {
    this.userDefaultApp = null;
    this.currentCompany = companyId;
    this.getUserForCompany(companyId);
    this.getLicenseRoles();
    companiesPicker.blur();
    this.companiesService.GetDefaultAppsByCompany(companyId).subscribe(apps => {
      this.companyApps = apps.filter(app => app.isRunnable).sort(this.toolsService.CompareValues('name'));
    });
  }
  
  filterCompanies(value: string) {
    const filter = value.toLowerCase();
    return this.companies.filter(comp => comp.name.toLowerCase().includes(filter));
  }
  
  displayCompFn(company: CompanyLicense) {
    return company ? company.name : undefined;
  }
  
  ClearLocalCache() {
    this.cacheService.Clear(true);
    this.snackerService.ShowMessageOnBottom('Local cache cleared', 'delete_sweep', null, true);
  }
  
  ngOnDestroy() {
    this.usersSubscription$.unsubscribe();
  }
  
  SetDefaultApp(event: any) {
    console.log(event);
  }
  
  private showUserNodes(user: CobbleUser) {
    if (user.companyNodes) {
      return user.companyNodes.length <= 0 ? false : true;
    }
    return false;
  }
  
  private refreshUsers() {
    this.adminService.getUsersForCompany(this.currentCompany).subscribe(users => {
      this.adminService.setUsersList(users);
      // console.log('Users after refresh', users);
    });
  }
  
  private refreshCompanies() {
    this.adminService.getCompanies().subscribe(companies => {
      this.companies = companies;
      this.companiesFiltered = companies;
    });
  }
  
  private setDefaultRoleQty(newRole: LicenseRole) {
    if (this.userIsAdmin(newRole)) {
      newRole.availableQty = 1;
      newRole.degradableLimit = 1;
    } else {
      newRole.degradableLimit = 0;
    }
  }
}
