import { animate, group, query, stagger, style, transition, trigger } from '@angular/animations';

export const routeTransitionAnimations = trigger('triggerName', [
  transition('admin => *', [
    group([
      query(
        ':enter .router-transition',
        stagger(100, [
          style({ transform: 'translateX(-100px)', opacity: 0 }),
          animate(
            '0.4s ease',
            style({ transform: 'translateX(0px)', opacity: 1 }),
          ),
        ]),
        { optional: true },
      ),
      query(
        ':leave .router-transition',
        stagger(100, [
          style({ transform: 'translateX(0px)', opacity: 0.5 }),
          animate(
            '0.4s ease',
            style({ transform: 'translateX(100px)', opacity: 0 }),
          ),
        ]),
        { optional: true },
      ),
    ]),
  ]),
  
  transition('workarea => *', [
    group([
      query(
        ':enter .router-transition',
        stagger(100, [
          style({ transform: 'translateX(100px)', opacity: 0 }),
          animate(
            '0.4s ease',
            style({ transform: 'translateX(0px)', opacity: 1 }),
          ),
        ]),
        { optional: true },
      ),
      query(
        ':leave .router-transition',
        stagger(100, [
          style({ transform: 'translateX(0px)', opacity: 0.5 }),
          animate(
            '0.4s ease',
            style({ transform: 'translateX(-100px)', opacity: 0 }),
          ),
        ]),
        { optional: true },
      ),
    ]),
  ]),
  
  transition('dashboard <=> app', [
    group([
      query(
        ':enter .router-transition',
        stagger(100, [
          style({ transform: 'translateY(100px)', opacity: 1, 'z-index': -1 }),
          animate(
            '0.4s ease',
            style({ transform: 'translateY(0px)', opacity: 1 }),
          ),
        ]),
        { optional: true },
      ),
      query(
        ':leave .router-transition',
        stagger(100, [
          style({ transform: 'translateY(0px)', opacity: 0.5 }),
          animate(
            '0.4s ease',
            style({ transform: 'translateY(-100px)', opacity: 0 }),
          ),
        ]),
        { optional: true },
      ),
    ]),
  ]),
  
  transition('* => app', [
    group([
      query(
        ':enter .router-transition',
        stagger(100, [
          style({ transform: 'translateY(100px)', opacity: 0 }),
          animate(
            '0.4s ease',
            style({ transform: 'translateY(0px)', opacity: 1 }),
          ),
        ]),
        { optional: true },
      ),
    ]),
  ]),
  
  transition('* => dashboard', [
    group([
      query(
        ':enter .router-transition',
        stagger(100, [
          style({ transform: 'translateY(100px)', opacity: 0 }),
          animate(
            '0.4s ease',
            style({ transform: 'translateY(0px)', opacity: 1 }),
          ),
        ]),
        { optional: true },
      ),
    ]),
  ]),
  
  transition('dashboard => *', [
    group([
      query(
        ':enter .router-transition',
        stagger(100, [
          style({ transform: 'translateX(-100px)', opacity: 1 }),
          animate(
            '0.4s ease',
            style({ transform: 'translateX(0px)', opacity: 1 }),
          ),
        ]),
        { optional: true },
      ),
      query(
        ':leave .router-transition',
        stagger(100, [
          style({ transform: 'translateX(0px)', opacity: 0.5 }),
          animate(
            '0.4s ease',
            style({ transform: 'translateX(100px)', opacity: 0 }),
          ),
        ]),
        { optional: true },
      ),
    ]),
  ]),
  
  transition('* => workarea', [
    group([
      query(
        ':enter .router-transition',
        stagger(100, [
          style({ transform: 'translateY(100px)', opacity: 0 }),
          animate(
            '0.4s ease',
            style({ transform: 'translateY(0px)', opacity: 1 }),
          ),
        ]),
        { optional: true },
      ),
    ]),
  ]),
]);
