import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { RepresentativeMoleculesType } from '../../shared/enums/representative-molecules-types.enum';
import { RepresentativeMolecule } from '../../shared/representative-molecule/interfaces/representative-molecule';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { BusService } from '../molecular/services/bus.service';
import { ApiDataService } from './api-data.service';
import { ApiMoleculesService } from './api-molecules.service';
import { HTTPStatusService } from './httpStatus.service';

@Injectable({
  providedIn: 'root',
})
export class ApiVersioningService extends ApiDataService {
  
  constructor(
    http: HttpClient,
    public errorMessengerService: ErrorMessengerService,
    private busService: BusService,
    protected cobbleService: CobbleService,
    private httpStatusService: HTTPStatusService,
    private moleculesService: ApiMoleculesService,
    private communicationService: CommunicationService,
    private snackerService: SnackerService,
  ) {
    super('properties', http, errorMessengerService);
    
  }
  
  
  ActivateChange(version) {
    if (this.RunningMode) {
      return;
    }
    this.httpStatusService.PropertySaving = true;
    
    // console.log('activating change...', version);
    
    return this.http.put(this.apiEndpointUrl + `/EnableVersion`, version).pipe(
      map((response) => {
        this.ApplyChange(response);
        setTimeout(() => {
          this.httpStatusService.PropertySaving = false;
        }, 800);
      }),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error getting value for property.`, version),
      ),
    );
  }
  
  DeactivateChange(version) {
    if (this.RunningMode) {
      return;
    }
    this.httpStatusService.PropertySaving = true;
    
    // console.log('reverting change...', version);
    
    return this.http.put(this.apiEndpointUrl + `/DisableVersion`, version).pipe(
      map((response) => {
        // console.log('response', response);
        
        if (this.busService.Exists((response as any).elementId)) {
          this.ApplyChange(response);
        }
        setTimeout(() => {
          this.httpStatusService.PropertySaving = false;
        }, 800);
      }),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error disabling change.`, version),
      ),
    );
  }
  
  UndoChange(version) {
    if (this.RunningMode) {
      return;
    }
    this.httpStatusService.PropertySaving = true;
    
    // console.log('reverting change...', version);
    
    return this.http.put(this.apiEndpointUrl + `/UndoVersion`, version).pipe(
      map((response) => {
        // console.log('response', response);
        
        if (this.busService.Exists((response as any).elementId)) {
          this.ApplyChange(response);
          this.snackerService.ShowMessageOnBottom(`Change ${ (response as any).property } reverted`);
        }
        setTimeout(() => {
          this.httpStatusService.PropertySaving = false;
        }, 800);
      }),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error reverting change`, version),
      ),
    );
  }
  
  ApplyChange(response: any) {
    const result = response as any;
    // console.log('apply change', result);
    
    if (result.elementId === this.cobbleService.Cobble.id) {
    } else {
      this.busService.Get(result.elementId).UpdateProperty(null, null, result, this.busService);
    }
    
    if (result.property === 'children') {
      // console.log('remove children', result.value);
      
      const elementsToRemove = [];
      const elementsToGet = [];
      
      this.busService.GetChildrenElementIds(result.elementId).forEach((childId) => {
        if (!result.value.map((r) => r.id).includes(childId.id)) {
          elementsToRemove.push(childId.id);
        }
      });
      
      result.value.map((r) => r.id).forEach((childId) => {
        if (
          !this.busService.GetChildrenElementIds(result.elementId).map((c) => c.id).includes(childId)
        ) {
          elementsToGet.push(childId);
        }
      });
      
      elementsToRemove.forEach((id) => {
        this.busService.Remove(id);
      });
      
      // console.log('elements to get', elementsToGet);
      
      if (elementsToGet.length > 0) {
        this.moleculesService.Get(elementsToGet).subscribe((elements: any[]) => {
          elements.forEach((element) => {
            const repMolecule = new RepresentativeMolecule(element);
            repMolecule.RunningMode = this.RunningMode;
            this.busService.Add(repMolecule);
          });
        });
      }
    }
    
    const molecule = this.busService.Get(result.elementId);
    if (molecule.Type === RepresentativeMoleculesType.WorkGroup) {
      if (response.property === 'cols') {
        setTimeout(() => {
          molecule.GridsterConfig.minCols = molecule.GridsterConfig.maxCols = molecule.GridsterConfig.maxItemCols =
            response.value;
          this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(result.elementId);
        }, 500);
      }
      if (response.property === 'rows') {
        setTimeout(() => {
          molecule.GridsterConfig.minRows = molecule.GridsterConfig.maxRows =
            response.value;
          this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(result.elementId);
        }, 500);
      }
      
      this.communicationService.Event.System.App.$AppChanged.emit(true);
    }
  }
  
}
