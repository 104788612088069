import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { BusService } from '../../core/molecular/services/bus.service';
import { ApiPropertiesService } from '../../core/services/api-properties.service';
import { HistoryListComponent } from './history-list/history-list.component';

@Injectable()
export class VersioningHistoryDialogService {
  constructor(
    public dialog: MatDialog,
    private busService: BusService,
    private propertiesService: ApiPropertiesService,
  ) {
  }
  
  openDialog(
    elementId: number,
    cobble = { exists: false, name: '' },
  ): BehaviorSubject<any> {
    this.propertiesService
    .getVersionByElementId(elementId)
    .subscribe(history => {
      const title = cobble.exists
        ? cobble.name
        : this.busService.Get(elementId.toString()).Properties.name;
      // console.log(history);
      
      const dataSource = history;
      const dialogRef = this.dialog.open(HistoryListComponent, {
        data: {
          dataSource: dataSource,
          title: title,
        },
        // position: {
        //   top: '50px'
        // }
      });
      
      dialogRef.afterClosed().subscribe(result => {
        return result;
      });
    });
    return null;
  }
}
