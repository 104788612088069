import { EventEmitter, Injectable } from '@angular/core';
import { SheetSelection } from '../../spreadsheet/models/sheet-selection';
import { DataSource } from '../../spreadsheet/spreadsheet.service';
import { BottomSheetOption } from '../../workarea/interfaces/bottom-sheet-option.interface';
import { TreeNode } from '../../workarea/views-panel/views-panel.component';
import { HubConnectionDto } from '../dtos/hub-connection-dto';
import { PropertyVersioningDto } from '../dtos/versioning-dto';
import { DatasourceDataElement } from '../interfaces/datasource-data-element';
import { LeapProcessMetadata } from '../interfaces/leap-process-metadata.interface';
import { Theme } from '../interfaces/theme.interface';
import { ActionMolecule } from '../representative-molecule/interfaces/action-molecules';
import { Bus } from '../representative-molecule/interfaces/bus';
import { Cobble } from '../representative-molecule/interfaces/cobble';
import { DataElement } from '../representative-molecule/interfaces/data-element';
import { LeapXLEvent } from '../representative-molecule/interfaces/leapxl-event';
import { RepresentativeMolecule } from '../representative-molecule/interfaces/representative-molecule';
import { IRepresentativeMolecule } from '../representative-molecule/interfaces/representative-molecule.interface';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  
  Event = {
    Runtime: {
      MolecularEngine: {
        $ProcessorStartRunning: new EventEmitter<{
          event: LeapXLEvent,
          busKey: string,
          bus: Bus,
          repMolecule: IRepresentativeMolecule
        }>(),
        $ParticleProcessCompleted: new EventEmitter<{
          key: string,
          particleId: string,
          event: string,
          elementId: any,
          data: any,
          endProccess: boolean,
        }>(),
        $StopProcessor: new EventEmitter<{
          particlesChannel: { bus: Bus; data: any },
          repMolecule: IRepresentativeMolecule,
          key: string,
          data: any
        }>(),
        $ConnectorParticleProcessCompleted: new EventEmitter<{
          key: string,
          particleId: string,
          event: string,
          elementId: any,
          data: any,
          endProccess: boolean,
        }>(),
        $LeapXLEvent: new EventEmitter<LeapXLEvent>(),
        $ProcessorSuspended: new EventEmitter<{
          cobbleId: number;
          date: number;
          info: {
            key: string;
            molecule: string;
            event: any;
            elementId: string;
            data: any;
            endProccess: boolean;
          },
          channel: any;
        }>(),
      },
      Emulator: {
        $ProcessorStartRunning: new EventEmitter<{
          userId: number,
          appId: number,
          processData: {
            event: LeapXLEvent,
            busKey: string,
            bus: Bus,
            repMolecule: IRepresentativeMolecule
          }
        }>(),
        $ParticleProcessCompleted: new EventEmitter<{
          userId: number,
          appId: number,
          processData: {
            key: string,
            particleId: string,
            event: string,
            elementId: any,
            data: any,
            endProccess: boolean,
          }
        }>(),
        $StopProcessor: new EventEmitter<{
          userId: number,
          appId: number,
          processData: {
            particlesChannel: { bus: Bus; data: any },
            repMolecule: IRepresentativeMolecule,
            key: string,
            data: any
          }
        }>(),
        $RunningProcessorStartRunning: new EventEmitter<{
          userId: number,
          appId: number,
          processData: {
            event: LeapXLEvent,
            busKey: string,
            bus: Bus,
            repMolecule: IRepresentativeMolecule
          }
        }>(),
        $RunningParticleProcessCompleted: new EventEmitter<{
          userId: number,
          appId: number,
          processData: {
            key: string,
            particleId: string,
            event: string,
            elementId: any,
            data: any,
            endProccess: boolean,
          }
        }>(),
        $RunningStopProcessor: new EventEmitter<{
          userId: number,
          appId: number,
          processData: {
            particlesChannel: { bus: Bus; data: any },
            repMolecule: IRepresentativeMolecule,
            key: string,
            data: any
          }
        }>(),
      },
      System: {
        $AppInit: new EventEmitter<LeapXLEvent>(),
        $AppReceived: new EventEmitter<any>(),
        $RemoveOldChildren: new EventEmitter<number>(),
        $ShowLoadingOverlay: new EventEmitter<{
          display: boolean,
          iconAnimated: boolean,
          showSpinner: boolean,
          spinnerType: 'bar' | 'spin',
          message: string,
          icon: string,
          iconColor: string
        }>(),
        $HideLoadingOverlay: new EventEmitter<null>(),
        $SelectStepperStep: new EventEmitter<{ repMoleculeId: number, stepIndex: number }>(),
        $TableSearch: new EventEmitter<{ repMoleculeId: number, value: string }>(),
        $ApplicationPublished: new EventEmitter<{
          isPublic: boolean,
          published: boolean,
          version: string,
          applicationId: number
        }>(),
        $ViewChange: new EventEmitter<LeapXLEvent>(),
        $ViewIdChanged: new EventEmitter<number>(),
        $ViewStart: new EventEmitter<any>(),
        $ViewEnd: new EventEmitter<any>(),
        $BrowserBackButtonPrevented: new EventEmitter<any>(),
        $DataSourceCRUDUpdate: new EventEmitter<{
          dataSourceId: number,
          action: number,
          operationType: string
          contexts: string[]
        }[]>(),
        $OpenContextMenu: new EventEmitter<{ event: MouseEvent, data: { contexts: string[]; } }>(),
      },
      Data: {
        $ModifyDataResult: new EventEmitter<{ keys: string[], dataElements: DatasourceDataElement[] }>(),
        $SMTPMessageSent: new EventEmitter<{
          message: string,
          subject: string,
          to: string,
          cc: string,
          bcc: string,
          transport: string
        }>(),
      },
    },
    Editor: {
      SidePanels: {
        $KeepOpen: new EventEmitter<number>(),
        $Open: new EventEmitter<number>(),
        $Close: new EventEmitter<number>(),
      },
      EventsTree: {
        $RefreshCustomEvents: new EventEmitter<null>(),
        $RefreshEventsTree: new EventEmitter<boolean>(),
        $OpenEventsSection: new EventEmitter<string>(),
        $Highlight: new EventEmitter<number[]>(),
        $RemoveHighlight: new EventEmitter<number[]>(),
        $Collapse: new EventEmitter<boolean>(),
      },
      AppsTree: {
        $RefreshAppsTree: new EventEmitter<boolean>(),
        $RefreshUI: new EventEmitter<boolean>(),
        $AppShared: new EventEmitter<boolean>(),
      },
      Views: {
        $ViewsPanelChange: new EventEmitter<number>(),
        $Highlight: new EventEmitter<number[]>(),
        $SwitchView: new EventEmitter<number>(),
        $RefreshViewsPanelUI: new EventEmitter<boolean>(),
        $RefreshViewsPanel: new EventEmitter<boolean>(),
        $RefreshUI: new EventEmitter(),
        $VisibilityChange: new EventEmitter<TreeNode>(),
        $Collapse: new EventEmitter<boolean>(),
        $SwapRepMoleculeView: new EventEmitter<{ repMoleculeId: number, viewId: number }>(),
        $SwitchToRepresentativeMoleculeView: new EventEmitter<IRepresentativeMolecule>(),
      },
      Library: {
        $ReloadLibrary: new EventEmitter<boolean>(),
      },
      Preferences: {
        $PreferenceChange: new EventEmitter<string>(),
      },
      Saving: {
        $UnsavedRemainingProperties: new EventEmitter<number>(),
        $BackendNotSavingState: new EventEmitter<number>(),
        $AllDataSaved: new EventEmitter<boolean>(),
      },
      DataSource: {
        ToggleSection: new EventEmitter<{ dataSourceTypeId: number; open: boolean; }>(),
        Spreadsheet: {
          $SpreadsheetRangeSelected: new EventEmitter<DataSource>(),
          $SpreadsheetLoading: new EventEmitter<boolean>(),
          $SpreadsheetLoaded: new EventEmitter<boolean>(),
          $SpreadsheetReloaded: new EventEmitter<boolean>(),
          $SpreadsheetSaved: new EventEmitter<boolean>(),
          $CloseSpreadsheetPanel: new EventEmitter<boolean>(),
          $SpreadsheetModeSwitched: new EventEmitter<boolean>(),
          $SpreadsheetVersionSwitched: new EventEmitter<boolean>(),
          $SpreadsheetSelection: new EventEmitter<SheetSelection>(),
          $SelectRangeOnSpreadsheet: new EventEmitter<{
            firstRow: number;
            firstCol: number;
            lastRow: number;
            lastCol: number;
            dataSourceId: number;
            dataSourceName: string;
            collection: string;
            show: boolean;
          }>(),
        },
        $dataSourceUploaded: new EventEmitter<any>(),
        $dataSourceLoaded: new EventEmitter<boolean>(),
        $dataSourceUploadState: new EventEmitter<boolean>(),
        $RemoveDataSourceHighlight: new EventEmitter<string>(),
        $RefreshDataSourcePanelUI: new EventEmitter<boolean>(),
        $RefreshDataSourcePanel: new EventEmitter<boolean>(),
        $RefreshSectionDataSourcePanel: new EventEmitter<{
          datasourceTypeId: number,
          nodeIdsToExpand?: number[],
          focusNodeDatasourceId?: number
        }>(),
        $ReloadDataSourcePanel: new EventEmitter<number[]>(),
        $ReloadAPIDataSource: new EventEmitter<boolean>(),
        $DataSourceVisibilityChanged: new EventEmitter<boolean>(),
        $OpenOneSectionAtTime: new EventEmitter<boolean>(),
        $UpdateDownloableDataSources: new EventEmitter<boolean>(),
        $ApiNodesSelected: new EventEmitter<any[]>(),
        $DisableAddAction: new EventEmitter<{ disable: boolean, id: number | null }>(),
        $DisableAddProcess: new EventEmitter<boolean>(),
        $RemoveAction: new EventEmitter<number>(),
        $SelectNode: new EventEmitter<{ dataElement: DataElement, id: number }>(),
        $Highlight: new EventEmitter<{
          context: string;
          dataSourceId: number;
          dataSourceName: string;
          spreadsheetReferences: {
            firstRow: number;
            firstCol: number;
            lastRow: number;
            lastCol: number;
            collection: string;
            show: boolean;
          }
        }>(),
        $HighlightOnSpreadsheetPanel: new EventEmitter<{
          context: string;
          dataSourceId: number;
          dataSourceName: string;
          spreadsheetReferences: {
            firstRow: number;
            firstCol: number;
            lastRow: number;
            lastCol: number;
            collection: string;
            dataSourceId: number;
            dataSourceName: string;
            show: boolean;
          }
        }>(),
      },
      WorkArea: {
        $ShowLoadingOverlay: new EventEmitter<{
          display: boolean,
          iconAnimated: boolean,
          showSpinner: boolean,
          spinnerType: 'bar' | 'spin',
          message: string,
          icon: string,
          iconColor: string
        }>(),
        $AddRepMolecule: new EventEmitter<{
          parent: IRepresentativeMolecule,
          moleculeTemplate: any,
          event: any,
          position: { x: number, y: number, cols: number, rows: number },
          properties: any[]
        }>(),
        $HideLoadingOverlay: new EventEmitter<null>(),
        $DraggableWindowClosed: new EventEmitter<string>(),
        $CloseAllDraggableWindows: new EventEmitter<string>(),
        $RefreshStyles: new EventEmitter<number>(),
        $RefreshStylesLibrary: new EventEmitter<null>(),
        $MoleculeUsed: new EventEmitter<any>(),
        $ChangeTopBarPosition: new EventEmitter<string>(),
        $EditorStateLoaded: new EventEmitter<null>(),
        $ScrollIntoMolecule: new EventEmitter<null>(),
        $ShowFocusedMenu: new EventEmitter<IRepresentativeMolecule>(),
        $Zoom: new EventEmitter<{ level: number, xOrigin: number, yOrigin: number, setLevel?: boolean }>(),
        $ResetZoom: new EventEmitter<{ xOrigin: number, yOrigin: number }>(),
        $ResetPan: new EventEmitter<{ xOrigin: number, yOrigin: number }>(),
        $ResetPanZoom: new EventEmitter<{ xOrigin: number, yOrigin: number }>(),
        $TogglePanZoom: new EventEmitter<boolean>(),
        $RefreshPropertiesStyles: new EventEmitter<null>(),
        $PropertiesUpdated: new EventEmitter<null>(),
        $SnapshotSaved: new EventEmitter<number>(),
        $SnapshotRestoreStart: new EventEmitter<number>(),
        $SnapshotRestoreStop: new EventEmitter<number>(),
      },
      $DragParticleStartFromSidePanelStart: new EventEmitter<number>(),
      $ReloadThemes: new EventEmitter<null>(),
      $ReloadThemesPanel: new EventEmitter<null>(),
      $DragParticleStartFromSidePanelStop: new EventEmitter<number>(),
      $RecreateEventsTable: new EventEmitter<null>(),
      $DeselectRepresentativeMolecule: new EventEmitter<number>(),
      $ActionMoleculeAdded: new EventEmitter<{
        actionMolecule: ActionMolecule,
        repMolecule: IRepresentativeMolecule,
        data?: any
      }>(),
      $ConstructEventsFlow: new EventEmitter<{ viewId: any, repMoleculeId: any, eventName: any }>(),
      $BottomSheetOptionSelected: new EventEmitter<BottomSheetOption>(),
      $DropOnRepresentativeMolecule: new EventEmitter<{ event: any, repMoleculeId: number }>(),
      $RepresentativeMoleculeVisibleChange: new EventEmitter<{ repMoleculeId: number, status: boolean }>(),
      $OpenSmartTemplateWizard: new EventEmitter<boolean>(),
      $OpenToolbarPanel: new EventEmitter<boolean>(),
      $OpenAppProperties: new EventEmitter<boolean>(),
      $OpenNavigationViewMenu: new EventEmitter<boolean>(),
      $SelectAllRepresentativeMolecules: new EventEmitter<boolean>(),
      $OpenPropertiesPanel: new EventEmitter<boolean>(),
      $OpenProcessPanel: new EventEmitter<boolean>(),
      $OpenMicrolearningForRepSelection: new EventEmitter<boolean>(),
      $OpenMicrolearning: new EventEmitter<boolean>(),
      $CopyRepresentativeMolecule: new EventEmitter<boolean>(),
      $CutRepresentativeMolecule: new EventEmitter<boolean>(),
      $PasteRepresentativeMolecule: new EventEmitter<boolean>(),
      $DuplicateRepresentativeMolecule: new EventEmitter<boolean>(),
      $CreateComponent: new EventEmitter<boolean>(),
      $LockUnlockRepresentativeMolecule: new EventEmitter<boolean>(),
      $HideRepresentativeMolecule: new EventEmitter<boolean>(),
      $DeleteRepresentativeMolecule: new EventEmitter<boolean>(),
      $OpenRepresentativeMoleculeHistoryChanges: new EventEmitter<boolean>(),
      $ToggleLockViewVisibility: new EventEmitter<boolean>(),
      $DeselectAllRepresentativeMolecule: new EventEmitter<boolean>(),
      $OpenSharePanel: new EventEmitter<boolean>(),
      $OpenSnapshotsWindow: new EventEmitter<boolean>(),
      $OpenPreferencesPanel: new EventEmitter<boolean>(),
      $MoleculeRemoved: new EventEmitter<number>(),
      $MoleculeAdded: new EventEmitter<number>(),
      $AppOpened: new EventEmitter<HubConnectionDto>(),
      $AppClosed: new EventEmitter<HubConnectionDto>(),
      $AppUpdated: new EventEmitter<HubConnectionDto[]>(),
      $AppRefreshPreview: new EventEmitter<HubConnectionDto[]>(),
      $ClearSession: new EventEmitter<null>(),
      $WorkAreaDetection: new EventEmitter<boolean>(),
      $WorkAreaDetectionDisableFor: new EventEmitter<number>(),
      $RepresentativeMoleculeDetection: new EventEmitter<{ repMoleculeId: number, state: boolean }>(),
      $RefreshRepresentativeMoleculeUI: new EventEmitter<number>(),
      $RefreshRepresentativeTablesUI: new EventEmitter<number>(),
      $SearchRepresentativeTables: new EventEmitter<{ repMoleculeId: string, value: string }>(),
      $SelectedElementsChange: new EventEmitter<IRepresentativeMolecule[]>(),
      $DataElementsChange: new EventEmitter<boolean>(),
      $WorkareaClick: new EventEmitter<boolean>(),
      $RecreateProcessBuses: new EventEmitter<boolean>(),
      $CancelRepMoleculeRename: new EventEmitter<boolean>(),
      $RenameRepMolecule: new EventEmitter<boolean>(),
      $RefreshRepresentativePropertiesPanel: new EventEmitter<boolean>(),
      $InitRepresentativePropertiesPanel: new EventEmitter<boolean>(),
      $ChangesRepresentativePropertiesPanel: new EventEmitter<boolean>(),
      $RepMoleculeSelected: new EventEmitter<IRepresentativeMolecule>(),
      $ParticlesOrderParentEnabled: new EventEmitter<boolean>(),
      $ParticleRemoved: new EventEmitter<boolean>(),
      $EvaluateDestructiveDataMolecules: new EventEmitter<boolean>(),
      $ReloadElementsOnApp: new EventEmitter<string[]>(),
      $EditorDragging: new EventEmitter<boolean>(),
      $SaveEditorState: new EventEmitter<null>(),
      $RefreshCustomGuidelines: new EventEmitter<number>(),
      $DraggableWindowChange: new EventEmitter<{ window: string, size: any }>(),
      $TableMoleculesReorder: new EventEmitter<boolean>(),
      $ReloadApp: new EventEmitter<boolean>(),
      $EvaluateEditorToolBar: new EventEmitter<null>(),
      $CaptureCanvas: new EventEmitter<boolean>(),
      $RetryCaptureCanvas: new EventEmitter<boolean>(),
      $ErrorCaptureCanvas: new EventEmitter<boolean>(),
      $SetAppTheme: new EventEmitter<{ theme: Theme, app?: Cobble }>(),
      $LastUsedElement: new EventEmitter<any>(),
      $ToggleBusMolecule: new EventEmitter<{ isMoleculeClosed: boolean, particleId: string }>(),
      $MoleculePropertyChange: new EventEmitter<{
        moleculeId: number,
        change: PropertyVersioningDto,
        versionId: number,
        active: boolean,
      }[]>(),
    },
    System: {
      $WindowResized: new EventEmitter<{ height: number, width: number, id: string }>(),
      $ProcessImportAppFile: new EventEmitter<{ spreadSheetsData: any[], stylesData: any[] }>(),
      $ProcessImportAppFileCancel: new EventEmitter<any>(),
      DevTools: {
        $ResumeBreakpointMolecule: new EventEmitter<any>(),
        $ResumeBreakpointEvent: new EventEmitter<any>(),
        $ResumeBreakpointInitEvent: new EventEmitter<any>(),
        $ProcessPaused: new EventEmitter<LeapProcessMetadata>(),
        $OpenDevContextMenu: new EventEmitter<{ repMolecule: IRepresentativeMolecule, event: MouseEvent }>(),
        $UpdateBus: new EventEmitter<{
          repMoleculeId: string,
          busId: string
        }>(),
        $DisplayHistoryData: new EventEmitter<any>(),
      },
      Dev: {
        $UserConnected: new EventEmitter<any>(),
        $UserAppChange: new EventEmitter<any>(),
        $RepMoleculeSelected: new EventEmitter<IRepresentativeMolecule>(),
      },
      Connection: {
        $StateChange: new EventEmitter<boolean>(),
        $ConnectionLost: new EventEmitter<boolean>(),
        $ConnectionRecovered: new EventEmitter<boolean>(),
      },
      KeyPress: {
        $EnterKey: new EventEmitter<boolean>(),
        $ESCKey: new EventEmitter<boolean>(),
        $UPKey: new EventEmitter<boolean>(),
        $DOWNKey: new EventEmitter<boolean>(),
        $LEFTKey: new EventEmitter<boolean>(),
        $RIGHTKey: new EventEmitter<boolean>(),
      },
      Update: {
        $ChangesOnMolecules: new EventEmitter<IRepresentativeMolecule>(),
        $RefreshWorkgroups: new EventEmitter<number>(),
        $ActivateWorkgroupDetection: new EventEmitter<number>(),
        $DeactivateWorkgroupDetection: new EventEmitter<number>(),
        $ChangesOnCharts: new EventEmitter<RepresentativeMolecule>(),
        $UpdateDashboardApps: new EventEmitter<boolean>(),
      },
      App: {
        $AppChanged: new EventEmitter<boolean>(),
        $RefreshUI: new EventEmitter<boolean>(),
        $UpdateAppColsSize: new EventEmitter<number>(),
        $AppLoaded: new EventEmitter<boolean>(),
        $AppUnloaded: new EventEmitter<boolean>(),
        $AppEdited: new EventEmitter<boolean>(),
        $ReloadApp: new EventEmitter<string>(),
        $AppResponsiveAdjusting: new EventEmitter<boolean>(),
        $RemoveAppFromFavorites: new EventEmitter<any>(),
      },
      Auth: {
        $AADUserLogged: new EventEmitter<boolean>(),
        $LogOutUser: new EventEmitter<boolean>(),
        $UserLoggedIn: new EventEmitter<null>(),
      },
      Chat: {
        $ReceiveMessage: new EventEmitter<any>(),
        $MessagesRead: new EventEmitter<boolean>(),
        $MessagesTyping: new EventEmitter<boolean>(),
      },
      HubConnection: {
        $Update: new EventEmitter<HubConnectionDto>(),
      },
    },
    Documentation: {
      $Get: new EventEmitter<null>(),
      $OpenWindow: new EventEmitter<{
        type: string,
        id: string,
        title: string
      }>(),
    },
    Testing: {
      $Test: new EventEmitter<any>(),
    },
  };
  
  constructor() {
  }
}

