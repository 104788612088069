export class CobbleUser {
  companyNodes: any[];
  
  id: string;
  email: string;
  userName: string;
  password: string;
  firstName: string;
  middleName: string;
  name: string;
  lastName: string;
  phone: string;
  defaultApp: string;
  companyId: number;
  roleId: number;
  isOwnerOfCobbles: boolean;
  
  constructor() {
    // this.companyNodes = [];
  }
}
