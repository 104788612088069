import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class UploadFileMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    dataBus: any,
  ) {
    try {
      if (dataBus.length > 0) {
        const file = dataBus[0];
        const filename = file.name;
        if (file && file.type.includes('image') && file.size > 2000000) {
          const reader = new FileReader();
          reader.onload = (event: any) => {
            this.imageCompress.compressFile(event.target.result, -1, 50, 50).then(compressedImg => {
              this.toolsService.UrlToFile(
                compressedImg,
                filename,
                file.type,
              ).then(imageFile => {
                this.fileService.uploadFile([imageFile], 2).subscribe((result) => {
                  dataBus = result;
                  this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
                }, (error) => {
                  this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
                });
              });
            });
          };
          reader.readAsDataURL(file);
        } else {
          this.fileService.uploadFile(dataBus, 2).subscribe((result) => {
            dataBus = result;
            this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
          }, (error) => {
            this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
          });
        }
      } else {
        this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
      }
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
