import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { faDatabase, faFileExcel, faServer } from '@fortawesome/free-solid-svg-icons';
import { Receptor } from '../../../core/molecular/receptors.enum';
import { BusService } from '../../../core/molecular/services/bus.service';
import { ApiDataSourcesService } from '../../../core/services/api-data-sources.service';
import { ApiDataTranslatorService } from '../../../core/services/api-data-translator.service';
import { ApiPropertiesService } from '../../../core/services/api-properties.service';
import { ToolsService } from '../../../core/services/tools.service';
import { Constants } from '../../../shared/constants';
import { DatasourceType } from '../../../shared/enums/datasource-type.enum';
import { DragType } from '../../../shared/enums/drag-type.enum';
import { RepresentativeMoleculesType } from '../../../shared/enums/representative-molecules-types.enum';
import { ActionMolecule } from '../../../shared/representative-molecule/interfaces/action-molecules';
import { Bus } from '../../../shared/representative-molecule/interfaces/bus';
import { DataElement } from '../../../shared/representative-molecule/interfaces/data-element';
import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../../../shared/representative-molecule/services/cobble.service';
import { DragService } from '../../../shared/representative-molecule/services/drag.service';
import { CommunicationService } from '../../../shared/services/communication.service';
import { SnackerService } from '../../../shared/services/snacker.service';
import { SpreadsheetService } from '../../../spreadsheet/spreadsheet.service';
import { WorkAreaService } from '../../workarea.service';

@Component({
  selector: 'app-data-element-particle',
  templateUrl: './data-element-particle.component.html',
  styleUrls: ['./data-element-particle.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DataElementParticleComponent implements OnInit {
  @Input() dataElement: DataElement;
  @Input() dataElementValue: DataElement;
  @Input() actionMoleculeParticle: ActionMolecule;
  @Input() bus: Bus;
  @Input() repMolecule: IRepresentativeMolecule;
  @Input() position: number;
  spreadsheetIcon = faFileExcel;
  databaseIcon = faDatabase;
  unifiedDbIcon = faServer;
  DataSourceType = DatasourceType;
  context = '';
  internalName = '';
  hovering = false;
  loadingTranslation = false;
  dataDefinition = false;
  dataDefinitionName = '';
  datasourceWarning = false;
  
  constructor(
    private dataTranslatorService: ApiDataTranslatorService,
    private propertiesService: ApiPropertiesService,
    private snackerService: SnackerService,
    public dragService: DragService,
    private spreadsheetService: SpreadsheetService,
    private datasourceService: ApiDataSourcesService,
    public toolsService: ToolsService,
    private workAreaService: WorkAreaService,
    private cobbleService: CobbleService,
    private busService: BusService,
    private communicationService: CommunicationService,
  ) {
  }
  
  ngOnInit() {
    // console.log('tid', this.dataElement.TranslationId);
    
    if (this.toolsService.RunningMode && this.dataElementValue) {
      this.context = this.dataElementValue.Context;
      this.dataElement = this.dataElementValue;
      return;
    }
    
    this.loadingTranslation = true;
    this.dataTranslatorService
    .Translate(this.dataElement.TranslationId)
    .subscribe((translations) => {
      this.loadingTranslation = false;
      if (translations.length > 0) {
        this.context = translations[0].context;
        this.internalName = translations[0].internalName;
      } else {
        this.context = this.dataElement.Context;
        this.internalName = this.dataElement.InternalName;
      }
    });
    
    const moleculeTypes =
      this.cobbleService.Cobble.moleculeDataAssociationDefinitions.map(
        (d) => d.molecule,
      );
    
    console.log('evaluation');
    // exist definitions for molecule
    if (moleculeTypes.includes(this.repMolecule.Type)) {
      // is value input bus
      if (this.bus.Receptor === Receptor.ValueInput) {
        // is get data source or take data element
        // allowed molecules order for data
        const orderMolecules = [
          'GetElementsDatasourceDataMolecule',
          'FilterByDataElementReferenceMolecule',
        ];
        if (
          orderMolecules.includes(
            this.actionMoleculeParticle.InternalMoleculeName,
          )
        ) {
          // is last molecule on bus
          if (
            this.bus.Particles.findIndex(
              (p) => p.id === this.actionMoleculeParticle.id,
            ) ===
            this.bus.Particles.length - 1
          ) {
            // if data elements is first position
            
            const definitions =
              this.cobbleService.Cobble.moleculeDataAssociationDefinitions.filter(
                (d) => d.molecule === this.repMolecule.Type,
              );
            const definition = definitions.find(
              (d) => d.dataIndex === this.position,
            );
            if (definition) {
              this.dataDefinition = true;
              this.dataDefinitionName = definition.title;
            }
          }
        }
      }
    }
    
    if (
      this.actionMoleculeParticle.InternalMoleculeName ===
      'AddToDatasourceMolecule'
    ) {
      if (this.dataElement.DataSourceType === DatasourceType.Api) {
        if (!this.dataElement.Context.toLowerCase().includes('::data::')) {
          this.datasourceWarning = true;
        }
      }
    }
  }
  
  RemoveDataElement() {
    this.repMolecule.MarkAsTouched();
    if (this.actionMoleculeParticle) {
      this.actionMoleculeParticle.RemoveDataElement(this.dataElement);
    } else {
      this.bus.RemoveParticle(this.dataElement.ParticleId);
      if (this.bus.Particles.length === 0) {
        this.repMolecule.RemoveBus(this.bus.id);
      }
    }
    
    this.communicationService.Event.Editor.$WorkAreaDetectionDisableFor.emit(
      200,
    );
    this.repMolecule.SaveProperty('buses', 'Data element removed').subscribe();
    
    this.communicationService.Event.Editor.$DataElementsChange.emit(true);
    // this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanel.emit();
    // setTimeout(() => {
    //   this.repMolecule.HighlightDatasourcesPath();
    //   this.repMolecule.HighlightEventsPath();
    //   this.repMolecule.HighlightViewsPath();
    //   this.repMolecule.FireDataSourceBus();
    // }, 300);
    
    this.repMolecule.RefreshDatasourceConnected();
    this.repMolecule.RefreshDataSourceRelationships();
    if (this.repMolecule.Type === RepresentativeMoleculesType.Table) {
      this.repMolecule.$UpdateValue.emit();
    }
    
    this.snackerService.ShowMessageOnBottom('Data element removed', 'do_not_disturb_on', null, true);
  }
  
  DropData() {
    // console.log('======dropped on data eleemtn');
    
    this.hovering = false;
    this.repMolecule =
      this.repMolecule ||
      this.busService.Get(this.actionMoleculeParticle.MoleculeId.toString());
    this.bus =
      this.bus ||
      this.repMolecule.GetBusByParticleId(
        this.actionMoleculeParticle.ParticleId,
      );
    
    if (this.dragService.dragData && this.actionMoleculeParticle) {
      switch (this.dragService.dragData.dragType) {
        case DragType.DataElement:
          this.dragService.AssignDataElementToMolecule(
            this.bus.id,
            this.repMolecule.Id || this.actionMoleculeParticle.MoleculeId,
            this.actionMoleculeParticle.ParticleId,
            this.dragService.dragData.data,
            this.position,
            true,
          );
          this.DisplayReplaceDataSourceMessage();
          break;
        
        case DragType.Spreadsheet:
          // console.log(this.spreadsheetService.dataSourceIdOpened);
          this.dragService.ConvertAndAssignDataSourceToMolecule(
            this.bus.id,
            this.repMolecule.Id || this.actionMoleculeParticle.MoleculeId,
            this.actionMoleculeParticle.ParticleId,
            this.spreadsheetService.SpreadSheetRangeSelected,
            this.position,
            true,
            this.dragService.dragData.fromTree,
          );
          this.DisplayReplaceDataSourceMessage();
          break;
      }
      this.dragService.StopDragging();
    }
    
    if (
      this.dragService.dragData &&
      this.dragService.dragData.dragType === DatasourceType.Spreadsheet &&
      !this.actionMoleculeParticle
    ) {
      if (this.actionMoleculeParticle) {
        this.dragService.ConvertAndAssignDataSourceToMolecule(
          this.bus.id,
          this.actionMoleculeParticle.MoleculeId,
          this.actionMoleculeParticle.ParticleId,
          this.spreadsheetService.SpreadSheetRangeSelected,
          this.position,
          false,
          this.dragService.dragData.fromTree,
        );
        this.DisplayReplaceDataSourceMessage();
      } else {
        this.dataElement.AssingId();
        this.dataElement.AssingParticleId();
        this.dataElement.Reference =
          this.spreadsheetService.SpreadSheetRangeSelected.range;
        this.dataElement.ApplicationId = this.cobbleService.Cobble.id;
        this.dataElement.DataSourceId =
          this.spreadsheetService.SpreadSheetRangeSelected.dataSourceId;
        this.dataElement.DataSourceType = this.spreadsheetService
          .SpreadSheetRangeSelected.dataSourceType as DatasourceType;
        this.dataElement.DataSourceName =
          this.spreadsheetService.SpreadSheetRangeSelected.dataSourceName;
        this.dataElement.Collection =
          this.spreadsheetService.SpreadSheetRangeSelected.collection;
        
        this.dataTranslatorService
        .CreateTranslation([
          {
            dataSourceId: this.dataElement.DataSourceId,
            applicationId: this.dataElement.ApplicationId,
            dataSourceType: this.dataElement.DataSourceType,
            specialMode: this.spreadsheetService.editorDbMode,
            context: `${ this.dataElement.DataSourceType }${ Constants.ContextSeparator }${ this.dataElement.DataSourceName }${ Constants.ContextSeparator }${ this.dataElement.Collection }${ Constants.ContextSeparator }${ this.dataElement.Reference }`,
            reference: this.dataElement.Reference,
          },
        ])
        .subscribe((translations) => {
          this.dataElement.TranslationId = translations[0].id;
          this.context = translations[0].context;
          this.internalName = translations[0].internalName;
          this.dataElement.DataSourceId = translations[0].dataSourceId;
          
          setTimeout(() => {
            this.repMolecule.RefreshDatasourceConnected();
            this.repMolecule
            .SaveProperty('buses', 'Added Data element')
            .subscribe();
            this.DisplayReplaceDataSourceMessage();
          }, 100);
        });
      }
      this.dragService.dragData.dragType = '';
    }
    
    return;
  }
  
  Highligth() {
    this.dataElement.HighlightDatasourcePathOnEditor();
  }
  
  DisplayReplaceDataSourceMessage() {
    this.snackerService.ShowMessageOnBottom('Datasource Replaced', 'swap_horizontal_circle', null, true);
  }
  
  ShowDataSource() {
    const timeout = this.workAreaService.leftPanelIndexTab === 0 ? 0 : 400;
    this.workAreaService.leftPanelIndexTab = 0;
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    
    setTimeout(() => {
      this.dataElement.HighlightDatasourcePathOnEditor();
    }, timeout);
    this.dataElement.HighlightDatasourceOnSpreadhseet();
  }
}
