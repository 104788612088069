import { Injectable } from '@angular/core';
import Cookies from 'js-cookie';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  
  constructor() {
  }
  
  
  Set(key: string, value: string, date?: any) {
    
    if (date) {
      Cookies.set(key, value, { expires: date, secure: true });
    } else {
      Cookies.set(key, value, { secure: true });
    }
  }
  
  Get(key: string) {
    return Cookies.get(key);
  }
  
  Check(key: string) {
    return !this.Get(key) === undefined;
  }
  
  Remove(key: string) {
    Cookies.remove(key);
  }
}
