import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { fromEvent, Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { BusService } from '../../../core/molecular/services/bus.service';
import { ApiDataSourcesService } from '../../../core/services/api-data-sources.service';
import { ToolsService } from '../../../core/services/tools.service';
import { DragType } from '../../../shared/enums/drag-type.enum';
import { DataSourceSuggestion } from '../../../shared/interfaces/datasource-suggestion.interface';
import { ActionMolecule } from '../../../shared/representative-molecule/interfaces/action-molecules';
import { DataElement } from '../../../shared/representative-molecule/interfaces/data-element';
import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CommunicationService } from '../../../shared/services/communication.service';
import { WorkAreaService } from '../../workarea.service';

@Component({
  selector: 'app-add-particle-to-molecule',
  templateUrl: './add-particle-to-molecule.component.html',
  styleUrls: ['./add-particle-to-molecule.component.scss'],
})
export class AddParticleToMoleculeComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocomplete) autoComplete: MatAutocomplete;
  @Input() particle: ActionMolecule;
  @Output() particleSelected = new EventEmitter();
  repMolecules: IRepresentativeMolecule[] = [];
  dataElementsData: DataSourceSuggestion = { dataElements: [], message: { DEFAULT: '', ALTERNATE: '' } };
  clickEvent$: Subscription;
  subscriptions$: Subscription;
  onSearch = false;
  groupOptions: Observable<any[]>;
  entities: {
    name: string;
    icon: string;
    value: DataElement | IRepresentativeMolecule;
  }[] = [];
  particleForm = this._formBuilder.group({
    particleGroup: '',
  });

  groups = [
    {
      type: 'Data Sources Available',
      groupMessage: '',
      entities: [],
    },
    {
      type: 'Representative Elements',
      groupMessage: 'Current View',
      entities: [],
    },
  ];
  loadingData = false;
  private postMolecules = ['AddToDatasourceMolecule', 'UpdateDatasourceDataMolecule', 'DeleteDatasourceDataMolecule'];
  private filterMolecules = ['FilterByDataElementReferenceMolecule'];

  constructor(
    private _formBuilder: FormBuilder,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private communicationService: CommunicationService,
    private dataSourceService: ApiDataSourcesService,
    private toolsService: ToolsService,
    private workAreaService: WorkAreaService,
    private busService: BusService
  ) {}

  _horizontalLayout = false;

  @Input()
  set horizontalLayout(value: boolean) {
    this._horizontalLayout = value;
    if (this._horizontalLayout) {
      this.setIndicatorHeight();
    } else {
      const element = this.elementRef.nativeElement.querySelector('.search-indicator');
      if (!element) {
        return;
      }
      this.renderer.removeStyle(element, 'height');
    }
  }

  ngOnInit() {
    this.subscriptions$ = this.communicationService.Event.System.$WindowResized?.subscribe(() => {
      console.log('=event=');
      if (this._horizontalLayout) {
        this.setIndicatorHeight();
      }
    });

    this.subscriptions$.add(
      this.communicationService.Event.Editor.$ParticleRemoved.subscribe(() => {
        console.log('=event=');
        if (this._horizontalLayout) {
          this.setIndicatorHeight();
        }
      })
    );

    this.repMolecules = this.busService.GetViewRepresentativeMolecules(this.workAreaService.ActualView.id);
    // remove app from array
    // this.repMolecules.shift();
  }

  ClearGroups() {
    this.groups = [
      {
        type: 'Data Sources Available',
        groupMessage: '',
        entities: [],
      },
      {
        type: 'Representative Elements',
        groupMessage: `Current View - ${this.workAreaService.ActualView.name}`,
        entities: [],
      },
    ];
  }

  CreateEntities() {
    this.ClearGroups();

    this.dataElementsData.dataElements.forEach(de => {
      this.groups[0].entities.push({
        name: de.GetName(),
        icon: this.toolsService.GetDataSourceIcon(de.DataSourceType),
        value: { type: DragType.DataElement, data: de },
        repMoleculeIcon: de.SourceTrackRepMoleculeIcon,
        repMoleculeName: de.SourceTrackRepMoleculeName,
        eventIcon: 'flash_on',
        eventName: de.SourceTrackEventName,
      });
    });

    this.groups[0].type = `${this.groups[0].entities.length === 0 ? 'No' : ''} Available Data Sources`;
    this.groups[0].groupMessage = this.dataElementsData.message.DEFAULT;

    if ([...this.postMolecules, ...this.filterMolecules].includes(this.particle.InternalMoleculeName)) {
      this.groups.pop();
    } else {
      this.repMolecules.forEach(repMol => {
        this.groups[1].entities.push({
          name: repMol.Properties.name,
          icon: repMol.Icon,
          value: {
            type: DragType.RepresentativeMolecule,
            data: repMol,
          },
        });
      });
    }

    this.groupOptions = this.particleForm.get('particleGroup')!.valueChanges.pipe(
      debounceTime(200),
      startWith(''),
      map(value => this._filterGroup(value || ''))
    );

    setTimeout(() => {
      this.loadingData = false;
      setTimeout(() => this.searchInput?.nativeElement.focus(), 100);
    }, 200);
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
    this.clickEvent$?.unsubscribe();
  }

  _filter = (opt: any[], value: any): string[] => {
    if (!(typeof value === 'string' || value instanceof String)) {
      return [];
    }
    const filterValue = value.toLowerCase();
    return opt.filter(item =>
      (item.name as any)
        .replaceAll(' ', '')
        .toLowerCase()
        .includes((filterValue as any).replaceAll(' ', ''))
    );
  };

  _filterGroup(value: string): any[] {
    if (value) {
      return this.groups
        .map(group => ({ type: group.type, entities: this._filter(group.entities, value) }))
        .filter(group => group.entities.length > 0);
    }

    return this.groups;
  }

  showInput() {
    this.onSearch = true;
    this.loadingData = true;
    this.ObtainDataSourceSuggestions();
  }

  ObtainDataSourceSuggestions() {
    this.dataElementsData.dataElements = [];
    this.dataSourceService.ObtainAcceptedDataSourceForParticle(this.particle).subscribe(dataElements => {
      this.dataElementsData = dataElements;
      this.CreateEntities();
    });
  }

  hideInput() {
    this.particleForm.controls.particleGroup.reset();
    this.onSearch = false;
    if (this._horizontalLayout) {
      this.setIndicatorHeight();
    }
    this.clickEvent$?.unsubscribe();
  }

  emitParticleSelected(value: any) {
    this.particleSelected.emit(value);
    this.hideInput();
  }

  onBlur() {
    this.clickEvent$?.unsubscribe();

    this.subscriptions$.add(
      this.groupOptions.subscribe(options => {
        if ((!this.autoComplete || !this.autoComplete.isOpen) && options.length > 0) {
          return;
        }
      })
    );

    this.clickEvent$ = fromEvent(document, 'click').subscribe(() => this.hideInput());
  }

  setIndicatorHeight() {
    setTimeout(() => {
      const busHeight = document.querySelector('.particles-list')?.clientHeight;
      const offsetTop = this.elementRef.nativeElement.offsetTop;
      const element = this.elementRef.nativeElement.querySelector('.search-indicator');
      this.renderer.setStyle(element, 'height', `${busHeight - offsetTop}px`);
      this.renderer.addClass(element, 'align-start');
    }, 100);
  }
}
