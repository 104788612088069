import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Permissions } from '../../admin/models/permissions.enum';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { ApiAdminService } from './api-admin.service';
import { ApiAuthService } from './api-auth.service';
import { ClientStorageService } from './client-storage.service';
import { EditorStateService } from './editor-state.service';
import { GenericDialogService } from './generic-dialog.service';
import { LocalStorageService } from './local-storage.service';
import { UserMenuService } from './user-menu.service';

@Injectable({
  providedIn: 'root',
})
export class BackgroundRecurrentTasksService implements OnDestroy {
  CHECK_INTERVAL = 3000;
  STORE_KEY = 'lastAction';
  ALIVE_KEY = 'lastAlive';
  renewingADALToken = false;
  private autoLogoffEnabled = true;
  private autoLogoffTimeout = 15 * 60; // seconds
  private aliveTimeout = 30 * 60; // seconds
  private editorStateTimeout = 1 * 60; // seconds
  private interval = null;
  private subscriptions = new Subscription();
  
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private ngZone: NgZone,
    private authService: ApiAuthService,
    private dialogService: GenericDialogService,
    private userMenuService: UserMenuService,
    private editorStateService: EditorStateService,
    private cobbleService: CobbleService,
    private clientStorageService: ClientStorageService,
    private communicationService: CommunicationService,
    private adminService: ApiAdminService,
  ) {
    this.subscriptions.add(
      this.communicationService.Event.System.Auth.$UserLoggedIn.subscribe(() => {
        console.log('=event=');
        this.EvaluateAutoLogOffPermission();
      }),
    );
    
    this.localStorageService.Remove(this.STORE_KEY);
    
    setTimeout(() => {
      this.Check();
      this.InitListener();
      this.InitInterval();
      this.EvaluateAutoLogOffPermission();
    }, 5000);
    
    this.lastAlive = 0;
  }
  
  protected get lastAction() {
    return parseInt(this.localStorageService.Get(this.STORE_KEY, true));
  }
  
  protected set lastAction(value) {
    this.localStorageService.Set(this.STORE_KEY, value);
  }
  
  protected get lastAlive() {
    // tslint:disable-next-line:radix
    return parseInt(this.localStorageService.Get(this.ALIVE_KEY) || 0);
  }
  
  protected set lastAlive(value) {
    this.localStorageService.Set(this.ALIVE_KEY, value);
  }
  
  EvaluateAutoLogOffPermission() {
    if (this.userMenuService.checkPermission(Permissions.AutoLogOffDisabled)) {
      this.DisableAutoLogOff();
    } else {
      this.EnableAutoLogOff();
    }
  }
  
  DisableAutoLogOff() {
    this.autoLogoffEnabled = false;
  }
  
  EnableAutoLogOff() {
    this.autoLogoffEnabled = true;
  }
  
  ResetAliveCounter() {
    this.lastAlive = Date.now();
  }
  
  SetAutoLogOffTimeout(minutes = 5) {
    this.autoLogoffTimeout = minutes * 60;
    this.InitInterval();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  
  private InitListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('mousedown', () => this.Reset());
    });
  }
  
  private InitInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    
    this.ngZone.runOutsideAngular(() => {
      this.interval = setInterval(() => {
        this.Check();
      }, this.CHECK_INTERVAL);
    });
    
    // region EDITOR_STATE
    // save editor settings every 10 seconds
    setInterval(() => {
      this.ProcessEditorStateSave();
    }, 10000);
    // endregion
  }
  
  private Reset() {
    this.lastAction = Date.now();
  }
  
  private Check() {
    // region USER_INACTIVE
    this.ProcessAutoLogOff();
    // endregion
    
    // region KEEP_ALIVE
    this.ProcessKeepAlive();
    // endregion
    
    // region SESSION_CLOSED
    this.ProcessSessionClosed();
    // endregion
  }
  
  private ProcessEditorStateSave() {
    if (!this.authService.loggedIn() || !(this.cobbleService.Cobble.id > 0 && !this.cobbleService.Cobble.running)) {
      return;
    }
    
    // const now = Date.now();
    // const timeleft = (this.lastAction === 0 ? Date.now() : this.lastAction) + this.editorStateTimeout * 1000;
    // const diff = now - timeleft;
    // const isTimeout = diff < 0;
    
    if (true) {
      this.ngZone.run(() => {
        try {
          this.editorStateService.SaveEditorState();
        } catch (error) {
          console.error(error);
        }
      });
    }
  }
  
  private ProcessSessionClosed() {
    if (!this.authService.loggedIn()) {
      return;
    }
    
    let adalTokenExpired = false;
    const loginType = this.clientStorageService.getUserType();
    
    if (loginType === 'external' && this.authService.IsADALTokenExpired()) {
      adalTokenExpired = true;
    }
    
    if (adalTokenExpired && !this.renewingADALToken) {
      this.renewingADALToken = true;
      this.authService.RenewADALToken();
      setTimeout(() => {
        this.renewingADALToken = false;
      }, 2000);
    } else {
      if (this.authService.getUserId() > 0) {
      } else {
        const omitRoutes = ['/login', '/run', '/register', '/test', '/setpassword', '/cc', '/recoverpassword'];
        
        if (
          this.router.url.includes(omitRoutes[0]) ||
          this.router.url.includes(omitRoutes[1]) ||
          this.router.url.includes(omitRoutes[2]) ||
          this.router.url.includes(omitRoutes[3]) ||
          this.router.url.includes(omitRoutes[4]) ||
          this.router.url.includes(omitRoutes[5]) ||
          this.router.url.includes(omitRoutes[6])
        ) {
        } else {
          this.authService.logout();
          this.dialogService.OpenConfirmDialog({
            title: 'Session closed',
            message: adalTokenExpired ? 'Your AAD session has expired, please log in again' : `Your session has been closed.`,
            confirmText: 'Ok',
            cancelText: '',
          });
        }
      }
    }
  }
  
  private ProcessAutoLogOff() {
    if (!this.autoLogoffEnabled || !this.authService.loggedIn() || this.userMenuService.checkPermission(Permissions.AutoLogOffDisabled)) {
      return;
    }
    
    const now = Date.now();
    
    const timeleft = this.lastAction + this.autoLogoffTimeout * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    
    if (isTimeout) {
      this.ngZone.run(() => {
        this.DisableAutoLogOff();
        let remainingTime = 30;
        let countdownInterval = setInterval(() => {
          remainingTime = remainingTime - 1;
          
          if (remainingTime <= 0) {
            this.authService.logout();
            this.dialogService.closeDialog();
            this.dialogService.OpenConfirmDialog({
              title: 'Session closed',
              message: `Your session has been closed due to inactivity, please log in again to use the system.`,
              confirmText: 'Ok',
              cancelText: '',
            });
            
            clearInterval(countdownInterval);
            countdownInterval = null;
          }
        }, 1000);
        
        this.dialogService
        .OpenConfirmDialog({
          title: 'User Inactive',
          message: `You have been inactive for ${
            this.autoLogoffTimeout / 60
          } minute(s). Session will be closed in ${ remainingTime } seconds.\n\n Press 'Stay log in' to continue using the system.`,
          confirmText: 'Stay log in',
          cancelText: '',
        })
        .then(result => {
          if (result) {
            clearInterval(countdownInterval);
            countdownInterval = null;
            this.EnableAutoLogOff();
          }
        });
      });
    }
  }
  
  private ProcessKeepAlive() {
    if (!this.authService.loggedIn()) {
      return;
    }
    
    const now = Date.now();
    
    const timeleft = this.lastAlive + this.aliveTimeout * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    
    if (isTimeout) {
      this.ngZone.run(() => {
        try {
          this.adminService.KeepAlive().subscribe(response => {
            this.ResetAliveCounter();
          });
        } catch (error) {
          console.error(error);
        }
      });
    }
  }
}
