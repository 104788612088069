import { flattenDeep } from 'lodash-es';
import { AppInjector } from '../../../app-injector';
import { BusService } from '../../../core/molecular/services/bus.service';
import { DevToolsService } from '../../../core/services/dev-tools.service';
import { ToolsService } from '../../../core/services/tools.service';
import { WorkAreaService } from '../../../workarea/workarea.service';
import { CommunicationService } from '../../services/communication.service';
import { DraggableWindowService, DraggableWindowType } from '../../services/draggable-window.service';
import { FactoryParticleService } from '../../services/factory-particle.service';
import { SnackerService } from '../../services/snacker.service';
import { CobbleService } from '../services/cobble.service';
import { ActionMolecule } from './action-molecules';
import { DataElement } from './data-element';
import { LeapXLEvent } from './leapxl-event';
import { Particle } from './particle';
import { IRepresentativeMolecule } from './representative-molecule.interface';

export class Bus {
  public id: string;
  public Name: string;
  public Receptor: string;
  public ReceptorData: string;
  public LinkBusId?: string;
  public ClonedFromId?: string;
  public Particles: Particle[] = [];
  public Open: boolean;
  public SearchActive: boolean;
  public RepresentativeMoleculeId: number;
  public Enabled = true;
  public Dragging = false;
  public Note: string;
  public OpenNote = false;
  
  private snackerService: SnackerService;
  private toolsService: ToolsService;
  private busService: BusService;
  private cobbleService: CobbleService;
  private draggableWindowService: DraggableWindowService;
  private workAreaService: WorkAreaService;
  private communicationService: CommunicationService;
  private particlesFactoryService: FactoryParticleService;
  private devToolsService: DevToolsService;
  
  constructor(bus: any = {}, repMoleculeId = null) {
    this.Open = true;
    this.injectServices();
    this.AssingId(bus.id || bus.Id);
    this.Name = bus.name || bus.Name || '';
    this.Receptor = bus.receptor || bus.Receptor || '';
    this.LinkBusId = bus.linkBusId || bus.LinkBusId || '';
    this.Note = bus.note || bus.Note || '';
    this.OpenNote = bus.openNote || bus.OpenNote || false;
    this.ClonedFromId = bus.clonedFromId || bus.ClonedFromId;
    this.RepresentativeMoleculeId = repMoleculeId || bus.representativeMoleculeId || bus.RepresentativeMoleculeId;
    
    const particles: any[] = bus.Particles || bus.particles || [];
    this.Particles = particles.map((p) => this.particlesFactoryService.GetParticle(p));
    
    if (bus.enabled === false || bus.Enabled === false) {
      this.Enabled = false;
    }
  }
  
  public Enable() {
    this.Enabled = true;
  }
  
  public Disable() {
    this.Enabled = true;
  }
  
  public AssingId(id: string = null) {
    const newId = id || this.toolsService.GenerateGuid();
    if (this.id && this.id !== '') {
      this.devToolsService.AddBusTranslation(this.id, newId);
    }
    this.id = newId;
  }
  
  public ClearParticles() {
    this.Particles = [];
  }
  
  public GetEventParticles(): LeapXLEvent[] {
    return this.Particles.filter((p) => p.IsEvent()) as LeapXLEvent[];
  }
  
  public InitiatedByEvent(event: LeapXLEvent): boolean {
    return this.FirstParticle() && this.FirstParticle()
    .IsEvent() && this.FirstParticle()
    .IsSameSpecificEvent(event);
  }
  
  public RemoveParticle(particleId: string) {
    this.Particles = this.Particles.filter((p) => p.ParticleId !== particleId);
  }
  
  public IsReceptor(receptor: string) {
    return this.Receptor.includes(receptor);
  }
  
  public ReceptorIsOutput() {
    return this.IsReceptor('output');
  }
  
  public ReceptorIsInput() {
    return this.IsReceptor('input');
  }
  
  public SetParticlesPriority() {
    this.Particles.forEach((particle, index) => {
      particle.SetPriority(index + 1);
    });
  }
  
  public GetActionMoleculeParticlesByInternalName(internalName: string): ActionMolecule[] {
    return (
      (this.Particles.filter(
        (p) => p.IsActionMolecule() && (p as ActionMolecule).InternalMoleculeName === internalName) as ActionMolecule[]) ||
      null
    );
  }
  
  public GetActionMoleculeParticlesByContext(context: string): ActionMolecule[] {
    return (
      (this.Particles.filter(
        (p) => p.IsActionMolecule() && (p as ActionMolecule).ContainsContext(context)) as ActionMolecule[]) ||
      null
    );
  }
  
  public GetActionMoleculeParticle(moleculeNameType: string): ActionMolecule {
    return (
      (this.Particles.find(
        (p) => p.IsActionMolecule() && (p as ActionMolecule).InternalMoleculeName === moleculeNameType) as ActionMolecule) || null
    );
  }
  
  public GetActionMoleculeByParticleId(particleId: string): ActionMolecule {
    return (this.Particles.find(
      (p) => p.IsActionMolecule() && (p as ActionMolecule).ParticleId === particleId) as ActionMolecule) || null;
  }
  
  public GetActionMoleculesParticlesByInternalNames(internalNames: string[]): ActionMolecule[] {
    return (internalNames as any).flatMap(name => this.GetActionMoleculeParticlesByInternalName(name));
  }
  
  public ReplaceActionMolecule(newActionMolecule: ActionMolecule, particleIdToReplace: string) {
    const particleIndex = this.Particles.findIndex((p) => p.ParticleId === particleIdToReplace);
    this.Particles[particleIndex] = newActionMolecule;
  }
  
  DuplicateParticle(particleId: string) {
    const particle = this.GetParticle(particleId);
    const duplicateParticle = particle.CloneAndAssignIds();
    this.AddParticle(duplicateParticle);
  }
  
  public FirstParticle(): Particle {
    return this.Particles[0] || null;
  }
  
  public GetEvents(ignoreInitiator = false, reverse = false): LeapXLEvent[] {
    const events = [];
    const particles = this.Particles.filter((p) => p.IsEvent());
    
    particles.forEach((p) => {
      events.push(p as LeapXLEvent);
    });
    
    if (ignoreInitiator) {
      events.shift();
    }
    
    return reverse ? events.reverse() : events;
  }
  
  public GetParticle(particleId: string): Particle {
    return this.Particles.find((p) => p.ParticleId === particleId) || null;
  }
  
  public AddParticles(particles: Particle[]) {
    particles.forEach((p) => {
      this.AddParticle(p);
    });
  }
  
  public AddParticleIfNotAdded(particle: Particle, positionIndex = this.Particles.length, repMoleculeId: string = null) {
    if (!this.ParticleExists(particle)) {
      this.AddParticle(particle, positionIndex, repMoleculeId);
    }
  }
  
  public GetDataElementsWithSimilarName(name: string): DataElement[] {
    let similarDataElements = [];
    
    this.GetDataElements()
    .forEach(de => {
      const similarity = this.toolsService.StringSimilarity(de.InternalName, name);
      if (similarity > 0.6) {
        similarDataElements.push({ de, similarity });
      }
    });
    
    return this.toolsService.SortBy(similarDataElements, 'similarity')
    .map(s => s.de);
  }
  
  public ParticleExists(particle: Particle): boolean {
    return !!this.Particles.find(p => p.IsSameParticle(particle));
  }
  
  public AddParticle(particle: Particle, positionIndex = this.Particles.length, repMoleculeId: string = null): Particle[] {
    this.injectServices();
    particle.Touched = true;
    
    if (positionIndex === this.Particles.length) {
      this.Particles.push(particle);
    } else {
      this.Particles.splice(positionIndex, 0, particle);
    }
    
    if (this.Particles.length > 1 && particle.IsEvent()) {
      let traces: {
        bus: Bus;
        particle: Particle;
        repMolecule: IRepresentativeMolecule;
      }[][] = [];
      // console.log('bus service', this.busService);
      // console.log('cobble service', this.cobbleService);
      const repMolecule = repMoleculeId
        ? this.busService.Get(repMoleculeId)
        : this.busService.GetViewMolecules(this.cobbleService.Cobble,
          (this.workAreaService.ActualView ? this.workAreaService.ActualView.id : this.cobbleService.Cobble.properties.views[0].id))
        .find((m) => m.ContainsBus(this.id));
      
      if (repMolecule) {
        this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(repMolecule.Id);
      }
      
      const initTrace = repMolecule
        ? [
          {
            particle: particle,
            bus: this,
            repMolecule,
          },
        ]
        : [];
      
      ////////
      // const traceResult = this.CreateEventTrace(particle as LeapXLEvent, this.busService.ChildrenElements(this.cobbleService.Cobble.id), initTrace, traces);
      //
      // traces.push(traceResult);
      // const loops = traces.filter(t => t.filter(p => p.repMolecule.Id === repMolecule.Id).length > 1);
      
      traces = traces.filter((t) => t.length > 1);
      if (traces.length > 0) {
        this.RemoveParticle(particle.ParticleId);
        
        this.draggableWindowService.OpenDraggableWindow(
          `Loops Detected`,
          DraggableWindowType.Loop,
        );
        this.snackerService.ShowMessageOnBottom('Loop detected, prevent Event from being added', 'laps',
          6000);
      }
    }
    return this.Particles;
  }
  
  public CreateEventTrace(
    eventTracking: LeapXLEvent,
    repMolecules: IRepresentativeMolecule[],
    trace: {
      repMolecule: IRepresentativeMolecule;
      bus: Bus;
      particle: any;
    }[],
    traces: any[],
  ): any[] {
    const listeningForMe = [];
    
    if (repMolecules.length === 0) {
      return trace;
    }
    
    repMolecules.forEach((rm) => {
      rm.Buses.forEach((b) => {
        if (
          b.HasParticles() &&
          b.FirstParticle()
          .IsEvent() &&
          Number((b.FirstParticle() as LeapXLEvent).SourceId) === Number(eventTracking.SourceId) &&
          (b.FirstParticle() as LeapXLEvent).EventType === eventTracking.EventType
        ) {
          const clonedBus = b.Clone();
          clonedBus.Particles.shift();
          
          listeningForMe.push({
            repMolecule: rm,
            bus: clonedBus,
          });
        }
      });
    });
    
    if (listeningForMe.length > 0) {
      listeningForMe.forEach((lfm, lfmIndex) => {
        // console.log('lfm #', lfmIndex);
        if (lfmIndex === 25) {
          // console.log('here');
        }
        
        lfm.bus.Particles.forEach((p, index) => {
          // console.log('particle #', index);
          
          if (p.IsEvent()) {
            if (!flattenDeep(trace.map((t) => t.repMolecule)
            .map((rm) => rm.Id))
            .includes(lfm.repMolecule.Id)) {
              const traceResult = this.CreateEventTrace(
                p,
                repMolecules.filter((rm) => rm.Id !== lfm.repMolecule.Id),
                trace.concat([
                  {
                    repMolecule: lfm.repMolecule,
                    bus: lfm.bus,
                    particle: p,
                  },
                ]),
                traces,
              );
              // console.log('trace result', traceResult);
              
              traces.push(traceResult);
            } else {
              if (trace.length <= 1 || (trace.length > 1 && trace[trace.length - 1].repMolecule.Id !== lfm.repMolecule.Id)) {
                trace.push({
                  repMolecule: lfm.repMolecule,
                  bus: lfm.bus,
                  particle: p,
                });
              }
              return trace;
            }
          }
        });
        repMolecules = repMolecules.filter((rm) => rm.Id !== lfm.repMolecule.Id);
      });
    }
    
    return trace;
  }
  
  public Clone(keepOriginalReference = false) {
    const newBus = new Bus(this);
    
    if (!keepOriginalReference) {
      newBus.ClonedFromId = this.id;
    }
    return newBus;
  }
  
  public CloneAndAssignIds() {
    const clonedBus = new Bus(this);
    clonedBus.AssingId();
    return clonedBus;
  }
  
  public CompleteClone() {
    const newBus = this.CloneAndAssignIds();
    newBus.Name = `${ newBus.Name } - 2`;
    
    newBus.Particles = [];
    this.Particles.forEach((particle) => {
      const newParticle = particle.CloneAndAssignIds();
      newBus.Particles.push(newParticle);
    });
    
    return newBus;
  }
  
  public HasParticles(): boolean {
    return this.Particles.length > 0;
  }
  
  GetDataElements(reverse = false): DataElement[] {
    const dataElements = [];
    
    this.Particles.forEach((particle) => {
      if (particle.IsDataElement()) {
        dataElements.push(particle);
      } else if (particle.IsActionMolecule() && (particle as ActionMolecule).DataElements.length > 0) {
        (particle as ActionMolecule).DataElements.forEach((de) => {
          dataElements.push(de);
        });
      }
    });
    
    return reverse ? dataElements.reverse() : dataElements;
  }
  
  GetLastParticle(): Particle {
    return this.Particles[this.Particles.length - 1];
  }
  
  GetLastParticleDataElements(): DataElement[] {
    let dataElements = [];
    
    const dataElementParticles = this.Particles.filter(
      p => p.IsActionMolecule() && (p as ActionMolecule).DataElements.length > 0);
    
    if (dataElementParticles.length > 0) {
      const dataElementParticle = dataElementParticles[dataElementParticles.length - 1];
      dataElements = (dataElementParticle as ActionMolecule).DataElements;
      
      if ((dataElementParticle as ActionMolecule).DataElements.length === 2 && (dataElementParticle as ActionMolecule).DataElements[0].RangeParticleId !== '') {
        // range
        dataElements = new DataElement().FromRange((dataElementParticle as ActionMolecule).DataElements[0],
          (dataElementParticle as ActionMolecule).DataElements[1]);
      }
    }
    
    return dataElements;
  }
  
  GetDataElementsByBusEvent(event: LeapXLEvent): DataElement[] {
    const dataElements = [];
    if (this.FirstParticle() && this.FirstParticle()
    .IsSameEventParticle(event as Particle)) {
      this.Particles.forEach((particle) => {
        if (particle.IsDataElement()) {
          dataElements.push(particle);
        } else if (particle.IsActionMolecule() && (particle as ActionMolecule).DataElements.length > 0) {
          (particle as ActionMolecule).DataElements.forEach((de) => {
            dataElements.push(de);
          });
        }
      });
    }
    return dataElements;
  }
  
  GetActionMolecules(reverse = false): ActionMolecule[] {
    const actionMolecules = this.Particles.filter((p) => p.IsActionMolecule()) as ActionMolecule[];
    return reverse ? actionMolecules.reverse() : actionMolecules;
  }
  
  GetActionMoleculesByInternalNameAndContext(internalNames: string[], context: string): ActionMolecule[] {
    const actionMolecules = this.GetActionMoleculesParticlesByInternalNames(internalNames);
    const contextActionMolecules = actionMolecules.filter(am => am.ContainsContext(context));
    return contextActionMolecules;
  }
  
  ContainsParticle(particle: Particle) {
    return !!this.Particles.find((p) => p.ParticleId === particle.ParticleId);
  }
  
  DispatchEvent(event: LeapXLEvent) {
    return !!this.Particles.find(
      (p) => p.IsEvent() && p.IsSameSpecificEvent(
        event) && !(this.FirstParticle() as LeapXLEvent).IsSameSpecificEvent(event),
    );
  }
  
  ContainsSpecificContext(context: string): boolean {
    return !!this.Particles.find(
      (p) =>
        (p.IsDataElement() && (p as DataElement).HaveSpecificContext(context)) ||
        (p.IsActionMolecule() && (p as ActionMolecule).ContainsContext(context)),
    );
  }
  
  IncludeContext(context: string): boolean {
    return !!this.Particles.find(
      (p) =>
        (p.IsDataElement() && (p as DataElement).IncludesContext(
          context)) || (p.IsActionMolecule() && (p as ActionMolecule).IncludesContext(context)),
    );
  }
  
  SetParticlesTriggeredBy(busId = this.id) {
    this.Particles.forEach((p) => p.SetTriggerBy(busId));
  }
  
  MoveBusFromRepresentativeMolecule(oldRepMoleculeId: number, newRepMoleculeId: number) {
    this.toolsService.ReplaceValueInObject(this, +oldRepMoleculeId, +newRepMoleculeId);
    this.toolsService.ReplaceValueInObject(this, oldRepMoleculeId.toString(), newRepMoleculeId.toString());
  }
  
  PreviousParticle(particleId: string) {
    const particleIndex = this.Particles.findIndex(p => p.ParticleId === particleId);
    
    if (particleIndex > -1) {
      const previousParticle = this.Particles[particleIndex - 1];
      return previousParticle ? previousParticle : null;
    } else {
      return null;
    }
  }
  
  GetLastActionMolecule() {
    const molecules = this.GetActionMolecules();
    return molecules[molecules.length - 1];
  }
  
  GetRawObject(): any {
    const object = {
      id: this.id,
      Name: this.Name,
      Enabled: this.Enabled,
      Receptor: this.Receptor,
      Note: this.Note,
      RepresentativeMoleculeId: this.RepresentativeMoleculeId,
      Particles: [],
    };
    
    if (this.HasParticles()) {
      this.Particles.forEach((p) => object.Particles.push(p.GetRawObject()));
    }
    
    return object;
  }
  
  private injectServices() {
    const injector = AppInjector.getInjector();
    this.snackerService = injector.get(SnackerService);
    this.toolsService = injector.get(ToolsService);
    this.particlesFactoryService = injector.get(FactoryParticleService);
    this.snackerService = injector.get(SnackerService);
    this.busService = injector.get(BusService);
    this.cobbleService = injector.get(CobbleService);
    this.draggableWindowService = injector.get(DraggableWindowService);
    this.workAreaService = injector.get(WorkAreaService);
    this.communicationService = injector.get(CommunicationService);
    this.devToolsService = injector.get(DevToolsService);
  }
}
