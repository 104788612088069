import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  title = '';
  message = '';
  confirmText = '';
  cancelText = '';
  optionalText = '';
  centerText = false;
  maxWidth = 'auto';
  height = 'auto';
  fontSize = 'auto';
  
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message: string;
      confirmText: string;
      cancelText: string;
      optionalText: string;
      maxWidth: string;
      height: string;
      fontSize: string;
      centerText?: boolean;
    },
  ) {
    console.log('dialog');
    
    this.title = data.title;
    this.message = data.message;
    this.confirmText = data.confirmText;
    this.optionalText = data.optionalText ?? '';
    this.cancelText = data.cancelText;
    this.centerText = data.centerText || false;
    this.maxWidth = data.maxWidth || 'auto';
    this.height = data.height || 'auto';
    this.fontSize = data.fontSize || 'auto';
  }
  
  ngOnInit() {
  }
}
