export class PropertyVersioningDto {
  id: string;
  elementId: string;
  moleculeId: string;
  property: string;
  value: any;
  path: string;
  version: number;
  versionId: number;
  change: string;
  cobbleId: number;
  name: string;
  username: string;
  date: string;
  active: boolean;
  enabled: boolean;
  versions: [{ propertiePath: number }];
  
  public constructor(fields?: {
    id?: string;
    elementId?: string;
    moleculeId?: string;
    property?: string;
    value?: any;
    path?: string;
    version?: number;
    change?: string;
    name: string;
    versionId?: number;
    cobbleId?: string;
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
    this.id = this.id || Math.floor(Math.random() * Math.floor(Math.random() * Date.now())).toString();
  }
}
