import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { DatasourceType } from '../../shared/enums/datasource-type.enum';
import { DatasourceDataElement } from '../../shared/interfaces/datasource-data-element';
import { WorkAreaService } from '../../workarea/workarea.service';
import { LocalStorageService } from './local-storage.service';
import { ToolsService } from './tools.service';

@Injectable({
  providedIn: 'root',
})
export class CacheService implements OnDestroy {
  cachedFiles = {};
  cachedLocalStoreData = {};
  dataSet = {};
  
  private subscriptions: Subscription;
  
  constructor(
    private toolsService: ToolsService,
    private localStorageService: LocalStorageService,
    private workAreaService: WorkAreaService,
  ) {
    // this.subscriptions = this.hubconnectionService.$DataSourceCRUDUpdate.subscribe(
    //   update => {
    //     this.clear();
    //   }
    // );
  }
  
  IsCached(key: string): boolean {
    if (!this.workAreaService.cacheEnabled) {
      return false;
    }
    
    if (this.cachedFiles[key] || this.cachedLocalStoreData[key] || this.localStorageService.SL_Fetch(key).created) {
      return true;
    } else {
      // console.log('not cached');
      return false;
    }
  }
  
  StoreDataSetValues(dataElements: DatasourceDataElement[]) {
    dataElements.forEach(de => {
      this.dataSet[`${ de.context }-${ de.col }-${ de.row }`] = de.value;
    });
  }
  
  GetAsync(key: string): Observable<any> {
    // console.log('getting from cache', key);
    const cacheData = this.Get(key);
    let data = cacheData;
    
    if (key.includes(DatasourceType.LocalDataStore)) {
      data = this.toolsService.DeepCloneObject(data);
    }
    return of(data);
  }
  
  Get(key: string): any {
    return this.cachedLocalStoreData[key] || this.cachedFiles[key] || this.localStorageService.SL_Fetch(key).data;
  }
  
  Remove(key: string, isPersisting = true) {
    delete this.cachedLocalStoreData[key];
    delete this.cachedFiles[key];
    this.localStorageService.Remove(key);
  }
  
  Store(key: string, data: any, isPersisting = false, compress = false) {
    console.log('storing in cache', key, data);
    
    if (key.includes(`${ DatasourceType.LocalDataStore }-`)) {
      this.cachedLocalStoreData[key] = data;
    } else {
      if (isPersisting) {
        this.localStorageService.SL_Save(key, data, null, compress);
      } else {
        this.cachedFiles[key] = data;
      }
    }
  }
  
  Clear(hardClear = false) {
    this.cachedFiles = {};
    this.localStorageService.SL_ClearAll(hardClear);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
