import { Component, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventEmitter } from 'events';
import { FieldConfig } from '../../models/field-config.model';

@Component({
  selector: 'app-dynamic-input',
  template: `
		<div class="panel-viewer-body">
			<div
				class="dynamic-field form-input"
				[formGroup]="group"
			>
				<mat-form-field>
					<input
						[disabled]="config.disabled"
						*ngIf="!config.isMultiple"
						matInput
						type="text"
						[placeholder]="config.placeholder"
						[formControlName]="config.name"
					>
					<input
						[disabled]="config.disabled"
						*ngIf="config.isMultiple"
						matTooltip="Multiple values separated with |"
						matInput
						type="text"
						[placeholder]="config.placeholder"
						[formControlName]="config.name"
					>
				</mat-form-field>
			</div>
		</div>
  
  
  `,
  styleUrls: ['./dynamic-input.component.css'],
})
export class DynamicInputComponent implements OnInit {
  config: FieldConfig;
  group: FormGroup;
  @Output() public inputKeyup = new EventEmitter();
  
  constructor() {
  }
  
  ngOnInit() {
  }
}
