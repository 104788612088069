import * as wjcCore from '@grapecity/wijmo';
import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class PrintMolecule extends ActionMoleculeFunction {
  printDoc = null;
  textareaWrappers = [];
  
  public MoleculeProcess(particleId: string, repMoleculeId: string, busProcessorKey: string, rule: any, logic: any, data: any) {
    try {
      data = data === undefined || data === null || data === {} ? '' : data;
      
      if (this.ExistsRepMoleculeAttached) {
        data = this.RepMoleculeAttached.GetValue;
      }
      
      this.printDoc = new wjcCore.PrintDocument({
        title: 'Leap Print',
        copyCss: true,
      });
      
      this.printDoc.addCSS('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Round');
      if (rule && rule.workgroupIds && rule.workgroupIds.length > 0) {
        this.communicationService.Event.Runtime.System.$ShowLoadingOverlay.emit({
          display: true,
          showSpinner: true,
          iconAnimated: true,
          spinnerType: 'bar',
          message: 'Preparing to print...',
          icon: 'print',
          iconColor: '#EA5C2F',
        });
        
        const wgIds = [];
        for (const key in rule.workgroupIds) {
          if (rule.workgroupIds.hasOwnProperty(key)) {
            const id = rule.workgroupIds[key];
            wgIds.push(id);
          }
        }
        
        this.ProcessWorkgroupImg(wgIds, {
          particleId,
          busProcessorKey,
          repMoleculeId,
          data,
        });
      } else {
        this.printDoc.append(document.getElementsByClassName('work-area-wrapper')[0]);
        this.printDoc.print();
        this.Done(particleId, busProcessorKey, repMoleculeId, data);
      }
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  ProcessWorkgroupImg(wgIds: string[], extra: any) {
    if (wgIds.length === 0) {
      this.printDoc.print();
      this.communicationService.Event.Runtime.System.$HideLoadingOverlay.emit();
      this.Done(extra.particleId, extra.busProcessorKey, extra.repMoleculeId, extra.data);
      setTimeout(() => {
        this.CompleteTextareaPrint();
      }, 500);
      return;
    } else {
      const wgId = wgIds.shift();
      
      this.PrepareTextareasPrint(wgId);
      
      try {
        this.captureService.getImage(document.getElementById(`gridsterItem-${ wgId }`), true).subscribe((b64Img) => {
          const br = document.createElement('br');
          const img = document.createElement('img');
          img.src = b64Img;
          this.printDoc.append(img);
          this.printDoc.append(br);
          this.printDoc.append(br);
          
          this.ProcessWorkgroupImg(wgIds, extra);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }
  
  PrepareTextareasPrint(wgId: string) {
    const wgHTMLElement = document.querySelector(`#gridsterItem-${ wgId }`) as any;
    const wgTextareaWrappers = document.querySelectorAll(`#gridsterItem-${ wgId } .textarea-wrapper`);
    
    wgTextareaWrappers.forEach((taw) => {
      this.textareaWrappers.push(taw);
      
      const w = taw as any;
      const textarea = w.children[0];
      
      const width = textarea.offsetWidth;
      const height = textarea.offsetHeight;
      textarea.setAttribute('hidden', true);
      const textValue = textarea.value.replace(/\n/gi, '<br>');
      
      const div = document.createElement('div');
      // div.style.cssText = textarea.style.cssText;
      // div.style.borderBottom = textarea.style.borderBottom;
      // div.style.borderBottomColor = textarea.style.borderBottomColor;
      // div.style.borderBottomLeftRadius = textarea.style.borderBottomLeftRadius;
      // div.style.borderBottomRightRadius = textarea.style.borderBottomRightRadius;
      // div.style.borderBottomStyle = textarea.style.borderBottomStyle;
      // div.style.borderBottomWidth = textarea.style.borderBottomWidth;
      // div.style.borderCollapse = textarea.style.borderCollapse;
      // div.style.borderColor = textarea.style.borderColor;
      // div.style.borderLeft = textarea.style.borderLeft;
      // div.style.borderLeftColor = textarea.style.borderLeftColor;
      // div.style.borderLeftStyle = textarea.style.borderLeftStyle;
      // div.style.borderLeftWidth = textarea.style.borderLeftWidth;
      // div.style.borderRadius = textarea.style.borderRadius;
      // div.style.borderRight = textarea.style.borderRight;
      // div.style.borderRightColor = textarea.style.borderRightColor;
      // div.style.borderRightStyle = textarea.style.borderRightStyle;
      // div.style.borderRightWidth = textarea.style.borderRightWidth;
      // div.style.borderSpacing = textarea.style.borderSpacing;
      // div.style.borderStyle = textarea.style.borderStyle;
      // div.style.borderTop = textarea.style.borderTop;
      // div.style.borderTopColor = textarea.style.borderTopColor;
      // div.style.borderTopLeftRadius = textarea.style.borderTopLeftRadius;
      // div.style.borderTopRightRadius = textarea.style.borderTopRightRadius;
      // div.style.borderTopStyle = textarea.style.borderTopStyle;
      // div.style.borderTopWidth = textarea.style.borderTopWidth;
      // div.style.borderWidth = textarea.style.borderWidth;
      
      div.style.borderWidth = '1px';
      div.style.borderColor = 'lightgray';
      div.style.borderStyle = 'solid';
      div.style.borderRadius = '5px';
      div.style.padding = '5px';
      
      div.id = `textarea-placeholder-${ textarea.id }`;
      div.style.width = `${ width }px`;
      div.style.position = `absolute`;
      div.innerHTML = textValue;
      
      w.appendChild(div);
      
      const divWidth = div.offsetWidth;
      const divHeight = div.offsetHeight;
      
      const heightDiff = divHeight - height;
      console.log(width, height, divWidth, divHeight, heightDiff);
      
      if (heightDiff > 0) {
        wgHTMLElement.style.height = wgHTMLElement.offsetHeight + heightDiff + 'px';
      }
    });
  }
  
  CompleteTextareaPrint() {
    this.textareaWrappers.forEach((taw) => {
      const w = taw as any;
      const textarea = w.children[0];
      console.log('remove', textarea);
      textarea.removeAttribute('hidden');
      console.log(textarea);
      console.log(textarea.id);
      document.querySelector(`#textarea-placeholder-${ textarea.id }`).remove();
    });
    
    this.textareaWrappers = [];
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
