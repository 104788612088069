import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Constants } from '../../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class ApiMessagesService {
  apiUrl = Constants.Environment.apiUrl;
  
  constructor(public http: HttpClient) {
    this.apiUrl += 'Messages';
  }
  
  SendHTMLEmail(to: string, subject: string, html: string) {
    return this.http
    .post(this.apiUrl + `/SendEmail`, {
      to,
      subject: subject,
      htmlContent: html,
    })
    .pipe(map(response => <any>response));
  }
  
  SendITEmail(subject: string, text: string) {
    return this.http
    .post(this.apiUrl + `/SendEmail`, {
      subject: subject,
      htmlContent: text,
    })
    .pipe(map(response => <any>response));
  }
}
