export class Company {
  constructor(
    public id: number,
    public companyName: string,
    public address1: string,
    public address2: string,
    public city: string,
    public country: string,
    public phone: string,
    public contactName: string,
    public contactPhone: string,
    public contactEmail: string,
  ) {
  }
}
