import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { version } from '../../../environments/version';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';
import { ApiDataService } from './api-data.service';

@Injectable({
  providedIn: 'root',
})
export class ApiLogService extends ApiDataService {
  constructor(http: HttpClient, errorMessengerService: ErrorMessengerService) {
    super('logs', http, errorMessengerService);
  }
  
  SaveErrorLog(
    data: any,
    name: string,
    message: string,
    stack: string,
    errorMessage: string,
  ) {
    return this.http
    .post(`${ this.apiEndpointUrl }/SaveExternalLog`, {
      version: `${ version.build } ${ version.date }`,
      name: name,
      message: message,
      stack: stack,
      error: errorMessage,
      data: data,
    })
    .pipe(
      map(response => <any>response),
      catchError(error => this.errorMessengerService.HandleError(error, 'Error saving log')),
    );
  }
  
  GetUserSystemLogs(
    userId: number,
  ) {
    return this.http
    .get(`${ this.apiEndpointUrl }/userId/${ userId }`)
    .pipe(
      map(response => <any>response),
      catchError(error => this.errorMessengerService.HandleError(error, 'Error getting user logs')),
    );
  }
}
