import { Injectable } from '@angular/core';
import { Permissions } from '../../admin/models/permissions.enum';
import { IMenuEntry } from '../../shared/interfaces/menu-entry.interface';
import { ClientStorageService } from './client-storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserMenuService {
  
  constructor(private clientStorage: ClientStorageService) {
  }
  
  get canAccessEditor() {
    return this.checkPermission(Permissions.CobbleDevelopment);
  }
  
  get canAccessAdministration() {
    return this.checkPermission(Permissions.Administration);
  }
  
  get canDownloadSpreadsheet() {
    return this.checkPermission(Permissions.DownloadSpreadSheet);
  }
  
  get isSuperUser() {
    return this.checkPermission(Permissions.SpecialPermissions);
  }
  
  get isAdminUser() {
    return this.canAccessAdministration;
  }
  
  get canShareCobbles() {
    return this.checkPermission(Permissions.ShareCobbles);
  }
  
  /**
   * This user can filter foreign companies or users from his own company.
   */
  get canFilterUsers() {
    return this.isSuperUser || this.isAdminUser;
  }
  
  get canUseSmartTemplates() {
    return this.checkPermission(Permissions.SmartTemplatesUse);
  }
  
  checkPermission(permission: string) {
    return this.clientStorage.getPermissions().indexOf(permission) !== -1;
  }
  
  getMenuEntriesForArea(area: string): IMenuEntry[] {
    // return [{
    //   companySlug: 'cobblertech',
    //   applicationSlug: 'luis-test-app',
    //   roleName: 'SuperAdmin',
    //   menuArea: 'TopBarUserMenu',
    //   title: 'Test',
    //   icon: 'home',
    //   openApplicationStage: 'Window',
    //   positionx: 100,
    //   positiony: 100,
    //   height: 400,
    //   width: 400
    // }];
    return this.clientStorage.getMenuEntries().filter((me: IMenuEntry) => me.menuArea === area) || [];
  }
  
  isSameCompany(companyId: number) {
    return companyId === this.clientStorage.getCompanyId();
  }
  
  canNavigateHere(routeData: any) {
    if (routeData && routeData.permissions) {
      const permissions = routeData.permissions as any[];
      if (permissions.length > 0) {
        return this.clientStorage.getPermissions().includes(permissions[0]);
      }
    }
    return true;
  }
}
