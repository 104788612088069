import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ToolsService } from '../../../core/services/tools.service';
import { RuntimeService } from '../../../running-area/services/runtime.service';
import { DraggableWindowService } from '../../services/draggable-window.service';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss'],
})
export class IframeComponent implements OnInit {
  
  @Input()
  iframeComponentId: string = null;
  @Input()
  input: string = null;
  @Input()
  scrollbars: boolean = true;
  iframeUrl: SafeResourceUrl;
  srcDoc = null;
  iframeId = '';
  
  constructor(
    public sanitizer: DomSanitizer,
    private toolsService: ToolsService,
    private draggableWindowService: DraggableWindowService,
    private runtimeService: RuntimeService) {
  }
  
  ngOnInit() {
    const data = this.draggableWindowService.GetData();
    const dataUrl = data.url || '';
    this.iframeId = this.iframeComponentId || this.toolsService.GenerateGuid();
    console.log(dataUrl);
    
    if (this.input || dataUrl) {
      const source = this.input || dataUrl;
      if (source.indexOf('http') === 0) {
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          `${ source }`,
        );
      } else if ((source.indexOf('<!DOCTYPE html>') > -1) || (source.indexOf('<html>') > -1) || (source.indexOf('<HTML>') > -1)) {
        this.srcDoc = this.sanitizer.bypassSecurityTrustHtml(source);
      } else {
        this.srcDoc = this.sanitizer.bypassSecurityTrustHtml(source);
      }
    }
    
    setTimeout(() => {
      const runtimeInformation = this.runtimeService.GetRuntimeInformation();
      runtimeInformation.repMoleculeSource = this.iframeComponentId;
      const htmlIframeElement = (document.getElementById(this.iframeId) as any);
      if (htmlIframeElement) {
        htmlIframeElement.contentWindow.postMessage({
          type: 'LeapXLCommunicationsSharedAppInfo',
          appData: runtimeInformation,
        }, '*');
      }
    }, 1200);
  }
  
}
