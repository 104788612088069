import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { TFADialogComponent } from './2fa-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class TFADialogService {
  constructor(public dialog: MatDialog) {
  }
  
  openDialog(loginResponse: any): Observable<any> {
    const dialogRef = this.dialog.open(TFADialogComponent, { data: loginResponse });
    return dialogRef.afterClosed();
  }
}
