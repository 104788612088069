import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class PreserveMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(particleId: string, repMoleculeId: string, busProcessorKey: string, rule: any, logic: any, data: any) {
    try {
      data = data === undefined || data === null || data === {} ? '' : data;
      
      // for (const source of sourceIndex) {
      //   elementContext = busService.Get(source).context;
      // }
      
      // if (elementContext.preservedValue === null) {
      //   elementContext.preservedValue = elementContext.value;
      // }
      
      // for (const source of sourceIndex) {
      //   busService.Get(source).context.usePreserved = false;
      // }
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any) {
    // elementContext.value =
    //   elementContext.preservedValue || elementContext.value;
    // elementContext.preservedValue = null;
    // elementContext.usePreserved = false;
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
