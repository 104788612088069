import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ClientStorageService } from '../services/client-storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private clientStorage: ClientStorageService,
  ) {
  }
  
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.clientStorage.getToken();
    const xsrfToken = this.clientStorage.getAntiforgeryToken();
    const authReq = req.clone({
      withCredentials: true,
      headers: req.headers.set('Authorization', 'bearer ' + authToken).set('X-XSRF-TOKEN', xsrfToken),
    });
    // send cloned request with header to the next handler.
    
    return next.handle(authReq).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            // this.router.navigate(['/login']);
            // this.authService.logout();
          }
        }
        return throwError(err);
      }),
    );
  }
}
