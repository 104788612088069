import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BusService } from '../../core/molecular/services/bus.service';
import { EventTrack } from '../../shared/interfaces/event-track.interface';
import { ParticleTrackFlow } from '../../shared/models/particle-track-flow.model';
import { LeapXLEvent } from '../../shared/representative-molecule/interfaces/leapxl-event';
import { IRepresentativeMolecule } from '../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { WorkAreaService } from '../workarea.service';

interface LoopTrack {
  eventLoop: LeapXLEvent;
  repMoleculeLoop: IRepresentativeMolecule;
  loopTracks: {
    event: LeapXLEvent;
    repMolecule: IRepresentativeMolecule;
  }[]
}

@Component({
  selector: 'app-bus-loop',
  templateUrl: './bus-loop.component.html',
  styleUrls: ['./bus-loop.component.scss'],
})
export class BusLoopComponent implements OnInit {
  track: ParticleTrackFlow;
  loopTracksData: LoopTrack = {
    eventLoop: null,
    repMoleculeLoop: null,
    loopTracks: [],
  };
  possiblesLoopTracksData: LoopTrack[] = [];
  
  constructor(
    private angularZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private snackerService: SnackerService,
    private cobbleService: CobbleService,
    private communicationService: CommunicationService,
    private workAreaService: WorkAreaService,
    private busService: BusService,
  ) {
  }
  
  @ViewChild('elementsContainer') set element(elementsContainer: ElementRef<HTMLDivElement>) {
    setTimeout(() => this.PositionElements(elementsContainer), 50);
  };
  
  ngOnInit() {
    this.track = this.workAreaService.loopTrack;
    const loopTracks = this.GetTracksLoop(this.track.TrackDownResult.Tracks);
    this.possiblesLoopTracksData = this.GetEventsLoops(loopTracks);
    this.loopTracksData = this.possiblesLoopTracksData[0];
  }
  
  GetTracksLoop(tracks: EventTrack[], level = 0, previousLoop = null): EventTrack[] {
    const result: EventTrack[] = [];
    
    tracks.forEach((track) => {
      if (previousLoop !== null && track.loop !== previousLoop) {
        return;
      }
      
      const trackBranches = track.branches || [];
      const filteredBranches = this.GetTracksLoop(trackBranches, level + 1, track.loop);
      
      if (filteredBranches.length === 0 && track.loop === null) {
        return;
      }
      
      result.push({
        ...track,
        branches: filteredBranches,
      });
    });
    
    return result;
  }
  
  GetEventsLoops(tracks: EventTrack[]): LoopTrack[] {
    const result: LoopTrack[] = [];
    
    const ExtractEventsLoops = (track: EventTrack, path: { event: LeapXLEvent, repMolecule: IRepresentativeMolecule }[]) => {
      const repMolecule = this.busService.Get(track.bus.RepresentativeMoleculeId.toString());
      
      const newPath: LoopTrack = {
        eventLoop: null,
        repMoleculeLoop: null,
        loopTracks: [
          ...path,
          {
            event: track.event,
            repMolecule,
          }
        ]
      };
      
      if (track.loop) {
        const eventLoop = track.loop;
        const repMoleculeLoop = eventLoop.EventSource === 'Molecule' ? this.busService.Get(eventLoop.SourceId) : track.repMolecule;
        newPath.eventLoop = eventLoop;
        newPath.repMoleculeLoop = repMoleculeLoop;
      }
      
      if (track.branches.length === 0) {
        result.push(newPath);
      } else {
        track.branches.forEach( track => ExtractEventsLoops(track, newPath.loopTracks));
      }
    }
    
    ExtractEventsLoops(tracks[0], []);
    return result;
  }
  
  PositionElements(elementsContainer: ElementRef<HTMLDivElement>) {
    const elements = elementsContainer.nativeElement.children;
    let angle = 360 - 90;
    const dangle = 360 / elements.length;
    
    for (let i = 0; i < elements.length; ++i) {
      let element = elements[i];
      const height = element.getBoundingClientRect().height;
      const width = element.getBoundingClientRect().width;
      const isEvent = element.classList.contains('element');
      
      angle += dangle;
      
      if (isEvent) {
        this.renderer.setStyle(element, 'transform', `rotate(${ angle }deg) translate(${ 300 / 2 }px) rotate(-${ angle }deg)`);
        this.renderer.setStyle(element, 'top', `${ (300 / 2) - (height / 2) }px`);
        this.renderer.setStyle(element, 'left', `${ (300 / 2) - (width / 2) }px`);
      } else {
        this.renderer.setStyle(element, 'transform', `rotate(${ angle }deg) translate(${ 295 / 2 }px) scale(1.5)`);
        this.renderer.setStyle(element, 'top', `${ (295 / 2) - (height / 2) }px`);
        this.renderer.setStyle(element, 'left', `${ (295 / 2) - (width / 2) }px`);
      }
    }
  }
  
  HighlightRepMolecule(molecule: IRepresentativeMolecule) {
    if (molecule.ParentId === this.cobbleService.Cobble.id) {
      this.SwitchToRepresentativeMoleculeView(molecule);
      this.workAreaService.ShowElementFocusedMenu(molecule);
      this.RefreshUI();
    } else {
      if (molecule.EditorVisible) {
        const parentRepMolecule = this.busService.Get(molecule.ParentId.toString());
        
        if (this.workAreaService.ActualView.id === parentRepMolecule.Properties.view) {
          molecule.ScrollIntoFocus();
        } else {
          this.workAreaService.focusRepresentativeMolecule = molecule;
          this.SwitchToRepresentativeMoleculeView(parentRepMolecule);
        }
        this.RefreshUI();
      } else {
        this.snackerService.ShowMessageOnBottom('Molecule hidden', 'visibility_off');
      }
    }
    this.RefreshUI();
  }
  
  SwitchToRepresentativeMoleculeView(repMolecule: IRepresentativeMolecule) {
    this.RefreshUI();
    if (this.workAreaService.ActualView.id !== repMolecule.Properties.view) {
      this.SwitchToView(repMolecule.Properties.view);
    }
  }
  
  SwitchToView(viewId: number) {
    this.workAreaService.actualEditorViews = [this.cobbleService.Cobble.properties.views.find((v) => v.id === viewId)];
    this.communicationService.Event.Editor.$ReloadApp.emit(true);
    this.RefreshUI();
  }
  
  RefreshUI() {
    this.angularZone.run(() => this.changeDetectorRef.detectChanges());
  }
  
  HighlightEvent(loopEvent: { event: LeapXLEvent, repMolecule: IRepresentativeMolecule }) {
    if (loopEvent.event.EventSource.toLowerCase() !== 'custom') {
      this.HighlightRepMolecule(loopEvent.repMolecule);
      return;
    }
    
    this.communicationService.Event.Editor.EventsTree.$OpenEventsSection.emit('custom');
    const timeout = this.workAreaService.leftPanelIndexTab === 1 ? 0 : 400;
    
    this.workAreaService.leftPanelIndexTab = 1;
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    
    setTimeout(() => {
      const highlitedEvents = document.querySelectorAll(`.event.selected-element-node`) as unknown as any[];
      highlitedEvents.forEach((hl) => hl.classList.remove('selected-element-node'));
      
      let identifier = '';
      
      if (loopEvent.event.EventSource === 'Custom') {
        identifier = 'event-custom-' + loopEvent.event.Identifier;
      } else {
        identifier = 'event-' + loopEvent.repMolecule.Id + '-' + loopEvent.event.EventName;
      }
      
      const treeEventNodeElement = document.querySelector(`.${ identifier }`);
      
      if (treeEventNodeElement) {
        treeEventNodeElement.classList.add('selected-element-node');
        treeEventNodeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        if (loopEvent.event.EventSource === 'Custom') {
          this.snackerService.ShowMessageOnBottom('Custom event not found', 'warning');
        }
      }
    }, timeout);
  }
  
  HeaderHover(elementRef: HTMLDivElement, show: boolean) {
    if (show) {
      this.renderer.addClass(elementRef, 'element-shadow');
    } else {
      this.renderer.removeClass(elementRef, 'element-shadow');
    }
  }
}
