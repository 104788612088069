import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Permissions } from 'app/admin/models/permissions.enum';
import { UserMenuService } from '../../../core/services/user-menu.service';
import { CommunicationService } from '../../../shared/services/communication.service';

@Component({
  selector: 'app-leapxl-import-spreadsheet-data',
  templateUrl: './leapxl-import-spreadsheet-data.component.html',
  styleUrls: ['./leapxl-import-spreadsheet-data.component.scss'],
})
export class LeapxlImportSpreadsheetDataComponent implements OnInit {
  
  dsData = [];
  stylesData = [];
  
  Permissions = Permissions;
  existingDs = [];
  nonExistingDs = [];
  isApplication = false;
  
  constructor(private communicationService: CommunicationService,
              public userMenuService: UserMenuService,
              public dialogRef: MatDialogRef<LeapxlImportSpreadsheetDataComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    
    this.dsData = data.ds.spreadSheetsData;
    this.stylesData = data.ds.stylesData;
    this.isApplication = data.isApplication;
    this.dsData.forEach(ds => {
      ds.newName = ds.name;
      ds.useNewInstead = !ds.exist;
      ds.replaceData = false;
      ds.takeOwnership = false;
      ds.useExisting = true;
    });
    this.existingDs = this.dsData.filter(d => d.exist);
    this.nonExistingDs = this.dsData.filter(d => !d.exist);
  }
  
  ngOnInit() {
  }
  
  ContinueImport() {
    this.communicationService.Event.System.$ProcessImportAppFile.emit({ spreadSheetsData: this.dsData, stylesData: this.stylesData });
  }
  
  ReplaceSpreadsheet(ds, event) {
    ds.replaceData = event.checked;
    
    if (event.checked) {
      ds.useNewInstead = false;
      ds.useExisting = false;
    } else {
      ds.useExisting = true;
    }
  }
  
  UseExisting(ds, event) {
    ds.useExisting = event.checked;
    ds.replaceData = false;
    ds.useNewInstead = false;
    
    if (event.checked) {
      ds.useNewInstead = !event.checked;
    }
  }
  
  Cancel() {
    this.communicationService.Event.System.$ProcessImportAppFileCancel.emit(null);
  }
  
  UseSpreadsheet(ds, event) {
    ds.useNewInstead = event.checked;
    
    if (event.checked) {
      ds.useExisting = false;
      ds.replaceData = false;
    } else {
      ds.useExisting = true;
    }
  }
  
  FocusInput(event: any) {
    event.target.focus();
    event.target.select();
  }
}
