import { Component, OnInit, } from '@angular/core';

@Component({
  selector: 'app-custom-control-help',
  templateUrl: './custom-control-help.component.html',
  styleUrls: ['./custom-control-help.component.scss']
})
export class CustomControlHelpComponent implements OnInit {
  
  constructor(
    ) {}
  
  ngOnInit(): void {
  }
}
