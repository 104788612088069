import { Injectable, NgZone } from '@angular/core';
import { Receptor } from 'app/core/molecular/receptors.enum';
import { BuilderService } from '../../../core/builder/builder.service';
import { BusService } from '../../../core/molecular/services/bus.service';
import { ApiDataTranslatorService } from '../../../core/services/api-data-translator.service';
import { ApiMoleculesService } from '../../../core/services/api-molecules.service';
import { ApiPropertiesService } from '../../../core/services/api-properties.service';
import { DataManagementService } from '../../../core/services/data-management.service';
import { GenericDialogService } from '../../../core/services/generic-dialog.service';
import { ToolsService } from '../../../core/services/tools.service';
import { DataSource, SpreadsheetService } from '../../../spreadsheet/spreadsheet.service';
import { WorkAreaService } from '../../../workarea/workarea.service';
import { Constants } from '../../constants';
import { PropertyVersioningDto } from '../../dtos/versioning-dto';
import { DatasourceType } from '../../enums/datasource-type.enum';
import { RepresentativeMoleculesType } from '../../enums/representative-molecules-types.enum';
import { CommunicationService } from '../../services/communication.service';
import { FactoryParticleService } from '../../services/factory-particle.service';
import { FactoryService } from '../../services/factory.service';
import { SnackerService } from '../../services/snacker.service';
import { ActionMolecule } from '../interfaces/action-molecules';
import { DataElement } from '../interfaces/data-element';
import { IRepresentativeMolecule } from './../interfaces/representative-molecule.interface';
import { CobbleService } from './cobble.service';

@Injectable({
  providedIn: 'root',
})
export class DragService {
  dragData: any;
  dragginFromLibrary = false;
  dragginDataElementParticle = false;
  dragginStyle = false;
  dragginRepMolecule = false;
  dragginFromViewsPanel = false;
  dragginParticle = false;
  dragginBus = false;
  dragText = '';
  dragBetweenWG: {
    processing: boolean;
    previewX: number;
    previewY: number;
    showPreview: boolean;
    sourceWgId: number;
    repMoleculeIdDragged: number;
    repMoleculeIdsDragged: number[];
    targetWgId: number;
    dragging: boolean;
  } = {
    previewX: 0,
    previewY: 0,
    showPreview: false,
    sourceWgId: null,
    repMoleculeIdDragged: null,
    repMoleculeIdsDragged: [],
    targetWgId: null,
    dragging: false,
    processing: false,
  };
  
  constructor(
    private busService: BusService,
    private propertiesService: ApiPropertiesService,
    private dataTranslatorService: ApiDataTranslatorService,
    private dataManagementService: DataManagementService,
    private cobbleService: CobbleService,
    private toolsService: ToolsService,
    private communicationService: CommunicationService,
    private spreadsheetService: SpreadsheetService,
    private snackerService: SnackerService,
    private factoryService: FactoryService,
    private factoryParticleService: FactoryParticleService,
    private builderService: BuilderService,
    private dialogService: GenericDialogService,
    private workAreaService: WorkAreaService,
    private moleculesService: ApiMoleculesService,
    private ngZone: NgZone,
  ) {
  }
  
  private _dragginEvent = false;
  
  get dragginEvent(): boolean {
    return this._dragginEvent;
  }
  
  set dragginEvent(value: boolean) {
    this.ClearDragging();
    
    if (value) {
      this.communicationService.Event.Editor.$DragParticleStartFromSidePanelStart.emit();
    } else {
      this.communicationService.Event.Editor.$DragParticleStartFromSidePanelStop.emit();
    }
    this._dragginEvent = value;
  }
  
  private _dragginMolecule = false;
  
  get dragginMolecule(): boolean {
    return this._dragginMolecule;
  }
  
  set dragginMolecule(value: boolean) {
    this.ClearDragging();
    
    if (value) {
      this.communicationService.Event.Editor.$DragParticleStartFromSidePanelStart.emit();
    } else {
      this.communicationService.Event.Editor.$DragParticleStartFromSidePanelStop.emit();
    }
    this._dragginMolecule = value;
  }
  
  private _dragginDataElement = false;
  
  get dragginDataElement(): boolean {
    return this._dragginDataElement;
  }
  
  set dragginDataElement(value: boolean) {
    this.ClearDragging();
    
    if (value) {
      this.communicationService.Event.Editor.$DragParticleStartFromSidePanelStart.emit();
    } else {
      this.communicationService.Event.Editor.$DragParticleStartFromSidePanelStop.emit();
    }
    this._dragginDataElement = value;
  }
  
  get DragIcon() {
    if (this._dragginDataElement) {
      return 'dns';
    } else if (this._dragginEvent) {
      return 'flash_on';
    } else {
      return 'calendar_view_day';
    }
  }
  
  AssignRepMoleculeToActionMolecule(busId: string, repMoleculeId: number, particleId: string, data: any) {
    console.log(data);
    const repMolecule = this.busService.Get(repMoleculeId.toString());
    const bus = repMolecule.GetBus(busId);
    const moleculeParticle = bus.GetParticle(particleId) as ActionMolecule;
    moleculeParticle.AddRepMoleculeId(data.id);
  }
  
  ConvertAndAssignDataSourceToMolecule(
    busId: string,
    repMoleculeId: number,
    particleId: string,
    dataSource: DataSource,
    positionIndex = 0,
    replaceDataSoruce = false,
    fromTree = false,
  ) {
    // console.log('data element', dataSource);
    let fromHeading = false;
    const dbModeBehavior = this.spreadsheetService.editorDbMode || (dataSource.lastRowIndex === 0 && dataSource.firstRowIndex === 0);
    const repMolecule = this.busService.Get(repMoleculeId.toString());
    repMolecule.MarkAsTouched();
    const bus = repMolecule.GetBus(busId);
    const moleculeParticle = bus.GetParticle(particleId) as ActionMolecule;
    const dropOnGetDataMolecule = moleculeParticle.InternalMoleculeName === 'GetElementsDatasourceDataMolecule';
    const isDownloadMolecule = moleculeParticle.InternalMoleculeName === 'DownloadFileMolecule';
    
    if (bus) {
      const dataElementsTranslation = [];
      const dataElements = [];
      const firstDataElement = new DataElement(dataSource);
      const secondDataElement = new DataElement(firstDataElement);
      
      if (isDownloadMolecule && fromTree) {
        let dataElement = null;
        
        // workbook
        if (dataSource.dataItems.length > 0) {
          dataElement = {
            dataSourceId: dataSource.dataSourceId,
            applicationId: this.cobbleService.Cobble.id,
            dataSourceType: dataSource.dataSourceType,
            specialMode: dbModeBehavior,
            context: `${ dataSource.dataSourceType }${ Constants.ContextSeparator }${ dataSource.dataSourceName }${ Constants.ContextSeparator }${ dataSource.collection }`,
            reference: '',
          };
        }
        // spreadsheet
        else {
          dataElement = {
            dataSourceId: dataSource.dataSourceId,
            applicationId: this.cobbleService.Cobble.id,
            dataSourceType: dataSource.dataSourceType,
            specialMode: dbModeBehavior,
            context: `${ dataSource.dataSourceType }${ Constants.ContextSeparator }${ dataSource.dataSourceName }`,
            reference: '',
          };
        }
        firstDataElement.Context = dataElement.context;
        dataElements.push(firstDataElement);
        dataElementsTranslation.push(dataElement);
      } else {
        if (dbModeBehavior) {
          const firstRowIndex = Math.min.apply(
            Math,
            dataSource.dataItems.map((o) => {
              return o.row;
            }),
          );
          const row = dataSource.dataItems.filter((a) => a.row === firstRowIndex);
          row.reverse();
          
          row.forEach((di) => {
            const dataElement = new DataElement(dataSource);
            dataElement.ApplicationId = this.cobbleService.Cobble.id;
            dataElement.Reference = this.toolsService.ColumnIndexToName(di.col + 1);
            dataElement.Context = `${ dataSource.dataSourceType }${ Constants.ContextSeparator }${ dataSource.dataSourceName }${ Constants.ContextSeparator }${ dataSource.collection }${ Constants.ContextSeparator }${ dataElement.Reference }`;
            dataElements.push(dataElement);
            
            dataElementsTranslation.push({
              dataSourceId: dataElement.DataSourceId,
              applicationId: dataElement.ApplicationId,
              dataSourceType: dataElement.DataSourceType,
              specialMode: dbModeBehavior,
              context: dataElement.Context,
              reference: dataElement.Reference,
            });
          });
        } else {
          const firstRangeDataElement = moleculeParticle.DataElements.find((de) => de.RangeParticleId !== '');
          
          if (firstRangeDataElement) {
            const secondRangeDataElement = moleculeParticle.DataElements.find(
              (de) => de.ParticleId === firstRangeDataElement.RangeParticleId);
            
            const dataSourceSeparatedRange = this.toolsService.SeparateRangeReference(dataSource.range);
            const secondRangeDataElementSeparated = this.toolsService.SeparateRangeReference(
              secondRangeDataElement.Reference);
            const firstRangeDataElementSeparated = this.toolsService.SeparateRangeReference(
              firstRangeDataElement.Reference);
            
            dataSource.range = `${ dataSourceSeparatedRange.firstColumn }${ firstRangeDataElementSeparated.firstRow }:${ dataSourceSeparatedRange.lastColumn }${ secondRangeDataElementSeparated.lastRow }`;
            this.snackerService.ShowMessageOnBottom(`Selection adjusted to range ${ dataSource.range }`,
              'low_priority', 3500);
          }
          
          firstDataElement.id = this.toolsService.GenerateGuid();
          firstDataElement.ParticleId = this.toolsService.GenerateGuid();
          firstDataElement.Reference = dataSource.range.split(':')[0];
          firstDataElement.Context = `${ firstDataElement.DataSourceType }${ Constants.ContextSeparator }${ firstDataElement.DataSourceName }${ Constants.ContextSeparator }${ firstDataElement.Collection }${ Constants.ContextSeparator }${ firstDataElement.Reference }`;
          firstDataElement.ApplicationId = this.cobbleService.Cobble.id;
          
          dataElementsTranslation.push({
            dataSourceId: firstDataElement.DataSourceId,
            applicationId: this.cobbleService.Cobble.id,
            dataSourceType: firstDataElement.DataSourceType,
            specialMode: dbModeBehavior,
            context: firstDataElement.Context,
            reference: firstDataElement.Reference,
          });
          
          if (dataSource.range.split(':').length > 1 && dataSource.range.split(
            ':')[0] !== dataSource.range.split(':')[1]) {
            secondDataElement.id = this.toolsService.GenerateGuid();
            secondDataElement.ParticleId = this.toolsService.GenerateGuid();
            secondDataElement.Reference = dataSource.range.split(':')[1];
            secondDataElement.Context = `${ secondDataElement.DataSourceType }${ Constants.ContextSeparator }${ secondDataElement.DataSourceName }${ Constants.ContextSeparator }${ secondDataElement.Collection }${ Constants.ContextSeparator }${ secondDataElement.Reference }`;
            secondDataElement.ApplicationId = this.cobbleService.Cobble.id;
            
            dataElementsTranslation.push({
              dataSourceId: secondDataElement.DataSourceId,
              applicationId: secondDataElement.ApplicationId,
              dataSourceType: secondDataElement.DataSourceType,
              specialMode: dbModeBehavior,
              context: secondDataElement.Context,
              reference: secondDataElement.Reference,
            });
          } else {
            // drag from spradsheet heading
            fromHeading = true;
          }
        }
      }
      
      if (dataElementsTranslation.length === 0) {
        if (dataSource.isTemplate) {
        } else {
          return;
        }
        
        const dataElement = new DataElement(dataSource);
        dataElement.Context = `${ dataSource.dataSourceTypeName }${ Constants.ContextSeparator }${ dataSource.dataSourceName }`;
        dataElements.push(dataElement);
        
        dataElementsTranslation.push({
          dataSourceId: dataElement.DataSourceId,
          applicationId: this.cobbleService.Cobble.id,
          dataSourceType: dataElement.DataSourceType,
          specialMode: dbModeBehavior,
          context: `${ dataSource.dataSourceTypeName }${ Constants.ContextSeparator }${ dataSource.dataSourceName }`,
          reference: dataSource.dataSourceName,
        });
      }
      
      if (dataElementsTranslation.length > 0) {
        this.dataTranslatorService.CreateTranslation(dataElementsTranslation)
        .subscribe((translations) => {
          setTimeout(() => {
            if (dbModeBehavior) {
              dataElements.reverse();
              dataElements.forEach((da) => {
                da.TranslationId = translations.find(
                  (t) => t.context.toLowerCase() === da.Context.toLowerCase()).id;
              });
              
              moleculeParticle.AddDataElements(dataElements, positionIndex, replaceDataSoruce);
              this.factoryParticleService.SetupMoleculeDefaultsForDataElements(moleculeParticle, repMolecule);
              
              moleculeParticle.Touched = true;
              
              if (bus.Receptor === Receptor.ValueInput && dropOnGetDataMolecule) {
                const headerBus =
                  repMolecule.GetBusByReceptorAndMoleculeNameType([Receptor.HeaderInput],
                    'GetElementsDatasourceDataMolecule') ||
                  repMolecule.GetBusByReceptorAndMoleculeNameType([Receptor.HeaderInput],
                    'FilterByDataElementReferenceMolecule');
                
                if (headerBus && headerBus.length > 0) {
                  
                  const actionMolecule =
                    headerBus[0].GetActionMoleculeParticle('GetElementsDatasourceDataMolecule') ||
                    headerBus[0].GetActionMoleculeParticle('FilterByDataElementReferenceMolecule');
                  if (actionMolecule) {
                    if (headerBus[0].LinkBusId) {
                      if (headerBus[0].LinkBusId === bus.id) {
                        actionMolecule.AddDataElements(dataElements, positionIndex, replaceDataSoruce);
                        actionMolecule.Touched = true;
                      }
                    } else {
                      actionMolecule.AddDataElements(dataElements, positionIndex, replaceDataSoruce);
                      actionMolecule.Touched = true;
                      repMolecule.MarkAsTouched();
                    }
                  }
                  
                  // avoid popup, auto add headings
                  
                  // this.dialogService.OpenConfirmDialog({
                  //   title: 'Update Headings',
                  //   message: `Do you want to update the Heading Data Sources?`,
                  //   confirmText: 'Yes',
                  //   cancelText: 'No',
                  // }).then(result => {
                  //   console.log(result);
                  //   if (result) {
                  //     const actionMolecule =
                  //       headerBus[0].GetActionMoleculeParticle('GetElementsDatasourceDataMolecule') ||
                  //       headerBus[0].GetActionMoleculeParticle('FilterByDataElementReferenceMolecule');
                  //     if (actionMolecule) {
                  //       if (headerBus[0].LinkBusId) {
                  //         if (headerBus[0].LinkBusId === bus.id) {
                  //           actionMolecule.AddDataElements(dataElements, positionIndex, replaceDataSoruce);
                  //           actionMolecule.Touched = true;
                  //         }
                  //       } else {
                  //         actionMolecule.AddDataElements(dataElements, positionIndex, replaceDataSoruce);
                  //         actionMolecule.Touched = true;
                  //         repMolecule.MarkAsTouched();
                  //       }
                  //     }
                  //     this.communicationService.Event.Editor.$DataElementsChange.emit(true);
                  //     repMolecule.RefreshDatasourceConnected();
                  //     repMolecule.RefreshDataSourceRelationships();
                  //     repMolecule.SaveProperty('buses', 'Added Data element').subscribe();
                  //   } else {
                  //   }
                  // });
                }
              }
            } else {
              firstDataElement.TranslationId = translations.find(
                (t) => t.context.toLowerCase() === firstDataElement.Context.toLowerCase()).id;
              
              const dataElementsToAdd = [firstDataElement];
              
              if (fromHeading && bus.Receptor !== Receptor.HeaderInput && dropOnGetDataMolecule) {
                const headerBus = repMolecule.GetBusByReceptor(Receptor.HeaderInput);
                if (headerBus) {
                  const headerActionMolecule =
                    headerBus.GetActionMoleculeParticle('GetElementsDatasourceDataMolecule') ||
                    headerBus.GetActionMoleculeParticle('FilterByDataElementReferenceMolecule');
                  
                  if (headerBus.LinkBusId) {
                    if (headerBus.LinkBusId === bus.id) {
                      if (headerActionMolecule) {
                        headerActionMolecule.AddDataElement(firstDataElement, positionIndex,
                          replaceDataSoruce);
                      }
                    }
                  } else {
                    if (headerActionMolecule) {
                      headerActionMolecule.AddDataElement(firstDataElement, positionIndex, replaceDataSoruce);
                    }
                  }
                }
              }
              
              if (translations.length > 1) {
                secondDataElement.TranslationId = translations.find(
                  (t) => t.context.toLowerCase() === secondDataElement.Context.toLowerCase()).id;
                firstDataElement.RangeParticleId = secondDataElement.ParticleId;
                secondDataElement.RangeParticleId = firstDataElement.ParticleId;
                dataElementsToAdd.push(secondDataElement);
              }
              
              moleculeParticle.AddDataElements(dataElementsToAdd, positionIndex, replaceDataSoruce);
              this.factoryParticleService.SetupMoleculeDefaultsForDataElements(moleculeParticle, repMolecule);
              
              moleculeParticle.Touched = true;
            }
            repMolecule.MarkAsTouched();
            
            this.communicationService.Event.Editor.$DataElementsChange.emit(true);
            this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(repMolecule.Id);
            
            repMolecule.RefreshDatasourceConnected();
            repMolecule.RefreshDataSourceRelationships();
            this.snackerService.ShowMessageOnBottom('DataSource Added', 'add_circle', null, true);
            repMolecule.SaveProperty('buses', 'Added Data element')
            .subscribe();
          }, 100);
        });
      }
    }
  }
  
  AssignDataElementToMolecule(
    busId: string,
    repMoleculeId: number,
    particleId: string,
    dataElements: DataElement[],
    positionIndex = 0,
    replaceDataSoruce = false,
    refreshDSTree = true,
  ) {
    
    return new Promise((resolve, reject) => {
      const apiHeaderDataElements: DataElement[] = [];
      const repMolecule = this.busService.Get(repMoleculeId.toString());
      repMolecule.MarkAsTouched();
      const bus = repMolecule.GetBus(busId);
      const headerBus = repMolecule.GetBusByReceptorAndMoleculeNameType([Receptor.HeaderInput],
        'GetElementsDatasourceDataMolecule')[0];
      let headerActionMolecule = null;
      
      if (headerBus && bus.ReceptorIsInput() && bus.id !== headerBus.id && dataElements[0].DataSourceType !== DatasourceType.LocalDataStore) {
        if (headerBus.LinkBusId) {
          if (headerBus.LinkBusId === bus.id) {
            headerActionMolecule = headerBus.GetActionMoleculeParticle('GetElementsDatasourceDataMolecule');
          }
        } else {
          headerActionMolecule = headerBus.GetActionMoleculeParticle('GetElementsDatasourceDataMolecule');
        }
      }
      
      const dataElementsTranslation = [];
      
      dataElements.forEach((de) => {
        dataElementsTranslation.push({
          dataSourceId: de.DataSourceId,
          applicationId: this.cobbleService.Cobble.id,
          dataSourceType: de.DataSourceType,
          specialMode: this.spreadsheetService.editorDbMode,
          context: de.Context,
          reference: de.Reference,
        });
      });
      
      if (headerActionMolecule) {
        dataElements.forEach((de) => {
          if (!this.dataManagementService.clientDataElements.includes(de.Context)) {
            const headerContext = this.factoryService.GetApiLiteralHeadingsContext(de.Context);
            const headerDataElement = new DataElement(de);
            headerDataElement.AssingId();
            headerDataElement.AssingParticleId();
            headerDataElement.Context = headerContext;
            headerDataElement.DataSourceType = DatasourceType.Custom;
            headerDataElement.DataSourceName = DatasourceType.Custom;
            headerDataElement.Reference = headerContext.split(Constants.ContextSeparator)[1];
            apiHeaderDataElements.push(headerDataElement);
            
            dataElementsTranslation.push({
              dataSourceId: null,
              applicationId: this.cobbleService.Cobble.id,
              dataSourceType: DatasourceType.Custom,
              specialMode: this.spreadsheetService.editorDbMode,
              context: headerDataElement.Context,
              reference: headerDataElement.Reference,
            });
          }
        });
      }
      
      this.dataTranslatorService.CreateTranslation(dataElementsTranslation)
      .subscribe((translations) => {
        setTimeout(() => {
          const moleculeParticle = bus.GetParticle(particleId) as ActionMolecule;
          moleculeParticle.Touched = true;
          
          dataElements.forEach((de, index) => {
            const translation = translations.find(
              (t) => t.context.toLowerCase() === de.Context.toLowerCase());
            
            if (translation) {
              de.TranslationId = translation.id;
              moleculeParticle.AddDataElement(de, positionIndex, replaceDataSoruce);
            }
          });
          
          this.factoryParticleService.SetupMoleculeDefaultsForDataElements(moleculeParticle, repMolecule);
          
          if (bus.Receptor !== Receptor.HeaderInput && headerActionMolecule) {
            
            apiHeaderDataElements.forEach((hde) => {
              const headerTranslation = translations.find(
                (t) => t.context.toLowerCase() === hde.Context.toLowerCase());
              
              if (headerTranslation) {
                hde.DataSourceId = headerTranslation.dataSourceId || Constants.Defaults.DataSourceId;
                hde.TranslationId = headerTranslation.id || Constants.Defaults.TranslationId;
                hde.InternalName = headerTranslation.internalName || Constants.Defaults.InternalName;
              }
            });
            
            headerActionMolecule.AddDataElements(apiHeaderDataElements, positionIndex, replaceDataSoruce);
          }
          
          this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(repMolecule.Id);
          this.communicationService.Event.Editor.$DataElementsChange.emit(refreshDSTree);
          repMolecule.RefreshDatasourceConnected();
          // disable datasource highlight after adding ds
          // repMolecule.RefreshDataSourceRelationships();
          this.propertiesService.SaveOwnProperty(repMoleculeId.toString(), 'buses', 'Added Data element')
          .subscribe();
          resolve(null);
        }, 100);
      });
      
    });
    
    
  }
  
  StartDrag() {
    this.communicationService.Event.Editor.$EditorDragging.emit(true);
  }
  
  StopDragging() {
    // console.log('stop dragging');
    this.communicationService.Event.Editor.$EditorDragging.emit(false);
    this.dragginFromViewsPanel = false;
    this.dragginFromLibrary = false;
    this.dragText = '';
    this.ClearDragging();
  }
  
  ClearDragging() {
    this._dragginDataElement = false;
    this.dragginStyle = false;
    this._dragginEvent = false;
    this.dragginBus = false;
    this._dragginMolecule = false;
    this.dragginParticle = false;
  }
  
  ProcessRepMoleculeDragBetweenWG(event: MouseEvent = null, position: { x: number; y: number } = null) {
    console.log('process rep mol move', this.dragBetweenWG);
    if (
      this.dragBetweenWG.repMoleculeIdDragged &&
      this.dragBetweenWG.sourceWgId &&
      this.dragBetweenWG.targetWgId !== this.dragBetweenWG.sourceWgId &&
      this.dragBetweenWG.repMoleculeIdDragged !== this.dragBetweenWG.targetWgId &&
      this.dragBetweenWG.repMoleculeIdDragged !== this.dragBetweenWG.sourceWgId
    ) {
      this.dragBetweenWG.processing = true;
      console.log('>>>>>> swap wg');
      const oldParent = this.busService.Get(this.dragBetweenWG.sourceWgId.toString());
      const newParent = this.busService.Get(this.dragBetweenWG.targetWgId.toString());
      const repMolecule = this.busService.Get(this.dragBetweenWG.repMoleculeIdDragged.toString());
      let canPosition = false;
      
      const col = position ? position.x : Math.floor(event.offsetX / 4);
      const row = position ? position.y : Math.floor(event.offsetY / 4);
      
      if (repMolecule.Type === RepresentativeMoleculesType.WorkGroup || repMolecule.Type === RepresentativeMoleculesType.Stepper) {
        canPosition = true;
      } else {
        canPosition = this.workAreaService.CanPositionElement(
          this.dragBetweenWG.repMoleculeIdDragged,
          {
            rows: repMolecule.ResponsiveProperties().rows,
            cols: repMolecule.ResponsiveProperties().cols,
            x: col,
            y: row,
          },
          newParent.Id,
        );
      }
      
      if (canPosition) {
        repMolecule.EnableDragBetweenWG = !(
          repMolecule.Type === RepresentativeMoleculesType.WorkGroup || repMolecule.Type === RepresentativeMoleculesType.Stepper
        );
        this.SwapRepMolecule(repMolecule, oldParent, newParent, col, row);
      } else {
        this.ResetPosition(repMolecule);
        this.snackerService.ShowMessageOnBottom(
          `${ repMolecule.Properties.name } does not fit in this space, please drop somewhere else`,
          'pip_exit', 4000);
        setTimeout(() => {
          this.ClearDragBetweenWG();
        }, 500);
      }
    } else {
      this.ClearDragBetweenWG();
    }
  }
  
  SwapRepMolecule(
    repMolecule: IRepresentativeMolecule,
    oldParent: IRepresentativeMolecule,
    newParent: IRepresentativeMolecule,
    x: number,
    y: number,
  ) {
    const gridsterHtml = document.querySelector(`#gridsterItem-${ this.dragBetweenWG.repMoleculeIdDragged }`);
    if (gridsterHtml) {
      gridsterHtml.remove();
    }
    this.busService.SwapParent(repMolecule.Id, oldParent.Id, newParent.Id);
    
    setTimeout(() => {
      repMolecule.Properties.responsive[this.cobbleService.Cobble.deviceType].y = y;
      repMolecule.Properties.responsive[this.cobbleService.Cobble.deviceType].x = x;
      this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(oldParent);
      
      if (repMolecule.ParentId === this.cobbleService.Cobble.id) {
        this.communicationService.Event.Editor.$ReloadApp.emit();
      } else {
        setTimeout(() => {
          this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(newParent);
        }, 300);
      }
      
      this.communicationService.Event.System.App.$RefreshUI.emit(true);
      setTimeout(() => {
        this.workAreaService.HideElementFocusedMenu();
      }, 20);
      
      this.ngZone.runOutsideAngular(() => {
        repMolecule
        .SavePropertyFromVersioning(
          new PropertyVersioningDto({
            elementId: repMolecule.Id.toString(),
            property: 'x',
            value: x,
            change: 'Reposition X',
            path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
            name: `${ repMolecule.Properties.name }`,
          }),
        )
        .subscribe();
        
        repMolecule
        .SavePropertyFromVersioning(
          new PropertyVersioningDto({
            elementId: repMolecule.Id.toString(),
            property: 'y',
            value: y,
            change: 'Reposition Y',
            path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
            name: `${ repMolecule.Properties.name }`,
          }),
        )
        .subscribe();
        
        repMolecule
        .SavePropertyFromVersioning(
          new PropertyVersioningDto({
            elementId: repMolecule.Id.toString(),
            property: 'parentId',
            value: +newParent.Id,
            change: 'Workgroup swap',
            path: ``,
            name: `${ repMolecule.Properties.name }`,
          }),
        )
        .subscribe();
        
        this.moleculesService.SetParent(repMolecule.Id, +newParent.Id)
        .subscribe();
        
        // oldParent
        // .SavePropertyFromVersioning(
        //   new PropertyVersioningDto({
        //     elementId: oldParent.Id.toString(),
        //     property: 'children',
        //     value: oldParent.Children,
        //     change: `${ repMolecule.Type } removed`,
        //     path: ``,
        //     name: `${ oldParent.Properties.name }`,
        //   }),
        // )
        // .subscribe();
        
        if (repMolecule.Type === RepresentativeMoleculesType.WorkGroup || repMolecule.Type === RepresentativeMoleculesType.Stepper) {
          this.cobbleService.SaveCobbleChildren();
        } else {
          // newParent
          // .SavePropertyFromVersioning(
          //   new PropertyVersioningDto({
          //     elementId: newParent.Id.toString(),
          //     property: 'children',
          //     value: newParent.Children,
          //     change: `${ repMolecule.Type } added`,
          //     path: ``,
          //     name: `${ newParent.Properties.name }`,
          //   }),
          // )
          // .subscribe();
        }
      });
      
      this.ClearDragBetweenWG();
    }, 50);
  }
  
  ClearDragBetweenWG() {
    this.dragBetweenWG = {
      previewX: 0,
      previewY: 0,
      showPreview: false,
      processing: false,
      sourceWgId: null,
      repMoleculeIdDragged: null,
      repMoleculeIdsDragged: [],
      targetWgId: null,
      dragging: false,
    };
  }
  
  CancelDrag() {
    this.workAreaService.elementsSelected.forEach(es => {
      this.ResetPosition(es);
    });
    this.ClearDragging();
    this.ClearDragBetweenWG();
  }
  
  ResetPosition(representativeMolecule: IRepresentativeMolecule) {
    const startPosition = this.workAreaService.dragStartPositions[representativeMolecule.Id];
    
    if (startPosition) {
      representativeMolecule.ResponsiveProperties().x = startPosition.x;
      representativeMolecule.ResponsiveProperties().y = startPosition.y;
      representativeMolecule.RefreshParent();
    }
  }
}
