import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class SubmittableMolecule extends ActionMoleculeFunction {
  moleculeName = 'SubmittableMolecule';
  
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    data: any,
  ) {
    try {
    } catch (error) {
      // console.log(error);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
    // window.services.savingService
    //   .updateProperties([
    //     {
    //       elementId: elementContext.id,
    //       property: this.Path,
    //       value: true,
    //       path: '',
    //     },
    //   ])
    //   .subscribe();
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
