import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { interval, Observable } from 'rxjs';
import { map, startWith, takeWhile } from 'rxjs/operators';
import { GenericDialogService } from '../core/services/generic-dialog.service';
import { TemplateService } from '../core/services/template.service';
import { ToolsService } from '../core/services/tools.service';
import { SnackerService } from '../shared/services/snacker.service';

@Component({
  selector: 'commit-creator',
  templateUrl: './commit-creator.component.html',
  styleUrls: ['./commit-creator.component.scss'],
})
export class CommitCreatorComponent implements OnInit {
  @ViewChild('inputTitle', { static: false })
  inputTitle: ElementRef;
  
  @ViewChild('inputType', { static: false })
  inputTypeSelector: ElementRef;
  
  @ViewChild('inputScope', { static: false })
  inputScopeSelector: ElementRef;
  
  @ViewChild('inputDesc', { static: false })
  inputDescSelector: ElementRef;
  
  @ViewChild('inputBA', { static: false })
  inputBASelector: ElementRef;
  
  @ViewChild('inputVersion', { static: false })
  inputVersionSelector: ElementRef;
  
  @ViewChild('inputUser', { static: false })
  inputUserSelector: ElementRef;
  
  @ViewChild(MatAutocompleteTrigger)
  autocompleteTrigger: MatAutocompleteTrigger;
  
  typeControl = new FormControl('');
  filteredTypeOptions: Observable<string[]>;
  
  userControl = new FormControl('');
  filteredUserOptions: Observable<string[]>;
  
  scopeControl = new FormControl('');
  filteredScopeOptions: Observable<string[]>;
  
  displayTypes = ['Bug Fix', 'Feature', 'Style', 'Refactor', 'Performance', 'Testing', 'Documentation', 'Compilation', 'Configuration'];
  gitmojis = [':bug:', ':sparkles:', ':art:', ':recycle:', ':zap:', ':white_check_mark:', ':memo:', ':construction_worker:', ':package:'];
  types = ['fix', 'feat', 'style', 'refactor', 'perf', 'test', 'docs', 'build', 'chore'];
  scopes = ['molecular engine', 'database', 'ui', 'editor', 'runtime', 'datasource'];
  displayUsers = ['Jhordy Rosario', 'Luis Ventura', 'Leuris Ventura'];
  users = ['Jhordy Rosario jhordy.rosario@leapxl.com', 'Luis Ventura luis.ventura@leapxl.com', 'Leuris Ventura leuris@leapxl.com'];
  documentation = `
Commit Template:

=======================================
<type>[gitmoji] [optional scope]: <description>

[optional body]

[optional footer(s)]


Refs: [BA#<item id>]
version: <system version>
user: <user email>
=====================================


- title:
brief description of overall change


- types:
fix: A bug fix
feat: A new feature
style: A code that is related to styling
refactor: A code that neither fix bug nor adds a feature. (eg: You can use this when there is semantic
perf: A code that improves performance

test: Adding new test or making changes to existing test
docs: Documentation related changes, changes like renaming a variable/ function name)
build: Build related changes (eg: npm related/ adding external dependencies)
chore: A code change that external user won't see (eg: change to .gitignore file or .prettierrc file)


- scope:
Scope must be noun and it represents the section of the section of the codebase


- description:
use imperative, present tense (eg: use "add" instead of "added" or "adds")
don't use dot(.) at end
don't capitalize first letter

- BREAKING CHANGE:
A commit that has a footer BREAKING CHANGE:, or appends a ! after the type/scope, introduces a breaking API change (correlating with MAJOR in Semantic Versioning)


- board item:
Board Azure work item ID


- version:
Leap platform version

  `;
  type = '';
  scope = '';
  gitmoji = '';
  title = '';
  description = '';
  breakingChangeDescription = '';
  boardItems = '';
  version = '';
  breakingChange = false;
  user = '';
  commit = '';
  incomplete = true;
  updated = false;
  versionSuggestion = 'Insert updated platform version';
  
  constructor(
    private router: Router,
    private templateService: TemplateService,
    private toolsService: ToolsService,
    private snackerService: SnackerService,
    private genericDialogService: GenericDialogService,
  ) {
  }
  
  ngOnInit() {
    this.filteredTypeOptions = this.typeControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._Typesfilter(value || '')),
    );
    
    this.filteredUserOptions = this.userControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._usersFilter(value || '')),
    );
    
    this.filteredScopeOptions = this.scopeControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._scopesFilter(value || '')),
    );
    
    this.toolsService.SetTheme('light');
    setTimeout(() => {
      this.FocusInput(this.inputTitle);
    }, 200);
  }
  
  BreakingChangeToggle(event) {
  }
  
  GenerateCommit() {
    const commit = `
    ${ this.breakingChange ? '!' : '' }${ this.types[this.displayTypes.findIndex((t) => t === this.type)] }[${
      this.gitmojis[this.displayTypes.findIndex((t) => t === this.type)]
    }] (${ this.scope }): ${ this.title + `` }
    ${
      this.description !== ''
        ? `
    ` +
        this.description
        .split('\n')
        .map((line) => {
          return '- ' + line;
        })
        .join('\n    ') +
        `
    `
        : ''
    }${
      this.breakingChange
        ? `
    ` +
        'BREAKING CHANGE: ' +
        this.breakingChangeDescription +
        `
          `
        : ''
    }
    ${ this.boardItems !== '' ? 'Refs: ' + this.FormatBoardItems() : '' }
    Version: ${ this.version + '-' + new Date().toJSON().slice(0, 10).replace(/-/g, '') }
    User: ${ this.users[this.displayUsers.findIndex((t) => t === this.user)] }
    `;
    
    this.GenerateVersionSuggestion();
    this.commit = commit;
    this.updated = true;
    this.AutoCopyMessage();
    this.incomplete = this.title === '' || this.type === '' || this.scope === '' || this.user === '' || this.version === '';
  }
  
  FormatBoardItems() {
    let formattedBA = '';
    const splittedBA = (this.boardItems as any).replaceAll(' ', '').split(',');
    
    splittedBA.forEach((id) => {
      formattedBA += `${ formattedBA === '' ? '' : ', ' }BA#${ id }`;
    });
    
    return formattedBA;
  }
  
  CopyMessage() {
    this.toolsService.CopyToClipboard(this.commit);
    this.updated = false;
  }
  
  OpenDocumentation() {
    this.genericDialogService.OpenConfirmDialog({
      title: 'Create Commit Tool',
      message: this.documentation,
      confirmText: 'Ok',
      cancelText: '',
    });
  }
  
  AutoCopyMessage() {
    if (!this.updated) {
      return;
    }
    
    interval(1000)
    .pipe(takeWhile(() => this.updated && !this.incomplete))
    .subscribe(() => {
      console.log('copy');
      // this.CopyMessage();
    });
  }
  
  GenerateVersionSuggestion() {
    let newSuggestion = this.versionSuggestion;
    
    const type = this.types[this.displayTypes.findIndex((t) => t === this.type)];
    switch (type) {
      case 'fix':
        newSuggestion = 'Fixes must increment third numeric value';
        break;
      case 'feat':
        newSuggestion = 'New Features must increment second numeric value';
        break;
      case 'style':
        newSuggestion = 'Style changes must increment third numeric value';
        break;
      case 'refactor':
        newSuggestion = 'Refactor must increment third numeric value';
        break;
      case 'perf':
        newSuggestion = 'Performance changes must increment second numeric value';
        break;
      case 'test':
        newSuggestion = 'Testing must increment third numeric value';
        break;
      case 'docs':
        newSuggestion = 'Documentation changes must increment third numeric value';
        break;
      case 'build':
        newSuggestion = 'Compilation changes must increment third numeric value';
        break;
      case 'chore':
        newSuggestion = 'Configuration changes must increment third numeric value';
        break;
      
      default:
        break;
    }
    
    this.versionSuggestion = newSuggestion;
  }
  
  FocusInput(input: any, delay = false) {
    setTimeout(
      () => {
        input.nativeElement.focus();
        input.nativeElement.select();
      },
      delay ? 100 : 0,
    );
  }
  
  private _Typesfilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    
    return this.displayTypes.filter((option) => option.toLowerCase().includes(filterValue));
  }
  
  private _usersFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    
    return this.displayUsers.filter((option) => option.toLowerCase().includes(filterValue));
  }
  
  private _scopesFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    
    return this.scopes.filter((option) => option.toLowerCase().includes(filterValue));
  }
}
