export const tableTemplate = [
  {
    icon: 'view_comfy',
    moleculeType: 'Representative',
    name: 'Table',
    type: 'Table',
    moleculesAllow: ['Representative', 'DataAction', 'Behavior', 'Configuration', 'Compounds'],
    buses: [],
    debounce: {
      events: [],
    },
    minValue: null,
    maxValue: null,
    dataSource: {},
    styleMetadata: {
      styles: [],
      specificStyles: {
        dimension: null,
        font: null,
        hover: null,
        frame: null,
        appearance: null,
      },
      manualAdjustedProperties: [],
    },
    events: ['init', 'search', 'page-changed', 'row-selected', 'reload'],
    defaultEvent: 'init',
    creationFromTemplate: false,
    receptors: [
      'value-input',
      'value-output',
      'page-value-output',
      'tooltip-input',
      'search-output',
      'search-input',
      'header-input',
      'text-loading-input',
      'badge-value-input',
      'badge-value-output',
      'none',
    ],
    advanced: true,
    editableProperties: ['cols', 'rows', 'x', 'y', 'opacity', 'borders', 'shadow', 'hover', 'background',
      'badge', 'backgroundType'],
    properties: {
      align: 'left', horizontalPadding: 0,
      verticalPadding: 0,
      progressType: 'progress',
      progressColor: '#EA5C2F',
      progressBackgroundColor: '#E5957C',
      progressDiameter: 50,
      progressMode: 'indeterminate',
      progressStrokeWidth: 5,
      wrapText: false,
      allowManualEntry: false,
      alwaysRenderComponent: false,
      sliderVertical: false,
      sliderStep: 1,
      sliderThumbLabel: false,
      sliderTicks: false,
      sliderColor: '#EA5C2F',
      viewNames: [],
      location: null,
      allowEmptyValues: false,
      hover: {
        hoverBackground: 'white',
        hoverBackgroundOpacity: 1,
        hoverBorderRadius: 2,
        hoverBorderStyle: 'solid',
        hoverBorderColor: '#d1d1d1',
        hoverBorderWidth: 1,
        hoverTextDecoration: null,
        hoverPointer: null,
      },
      options: [
        {
          value: 'value 1',
          text: 'Text 1',
        },
      ],
      show: true,
      opacity: 1,
      orientation: 'col',
      enable: true,
      altText: '',
      dateRange: false,
      search: false,
      multiSelect: false,
      autoDataRefresh: true,
      ignoreValueDataIndex: false,
      textDecoration: 'none',
      badge: {
        badgeEnable: true,
        badgeBackgroundColor: '#f22525',
        badgeFontFamily: 'Source Sans Pro',
        badgeFontColor: 'white',
        badgeStyle: 'circle',
        badgePosition: 'right',
      },
      maskType: 'none',
      mask: '',
      cols: 14,
      rows: 10,
      name: 'Table',
      legend: true,
      loadingText: '',
      background: {
        backgroundColor: '#ffffff',
        backgroundTypeImage: false,
        backgroundImageUrl: '',
        backgroundOpacity: 1,
      },
      bordersValues: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 2,
        borderColor: '#d1d1d1',
      },
      icon: {
        iconType: 'stars',
        iconColor: 'black',
        iconSize: 45,
      },
      shadowValues: {
        hShadow: 0,
        vShadow: 0,
        shadowColor: '#212529',
        shadowBlur: 0,
      },
      preferences: {},
      view: 1,
      chartLibrary: 'ngxCharts',
      filter: true,
      chartType: 'line',
      tabindex: 0,
      textToDisplay: 'Enter Text',
      tooltip: '',
      placeholder: 'Enter Text',
      minItemCols: 5,
      maxItemCols: 2000,
      responsive: {
        desktop: {
          customGuidelines: {
            x: [],
            y: [],
          },
          itemsPerPage: 50,
          id: 0,
          cols: 50,
          rows: 40,
          colsQty: 0,
          rowsQty: 0,
          layer: 1,
          centerPositioning: false,
          x: 0,
          y: 0,
          font: {
            fontSize: 16,
            fontColor: '#000000',
            fontFamily: 'inherit',
            fontStyle: 'inherit',
            fontWeight: 'normal',
          },
          header: {
            headerTextAlignment: 'left',
            headerHeight: 40,
            headerFontSize: 15,
            headerFontColor: '#9ca3b3',
            headerFontFamily: 'Source Sans Pro',
            headerFontStyle: 'normal',
            headerBackgroundColor: '#f7f9fc',
            stickyHeaders: true,
          },
          body: {
            bodyCenterText: false,
            bodyFontFamily: 'MS Sans Serif',
            bodyFontStyle: 'normal',
            bodyFontSize: 14,
            bodyHeight: 42,
            bodyFontColor: '#383838',
            bodyBackgroundColor: 'white',
            isBodyStriped: false,
            firstBodyStripedColor: '#ffffff',
            secondBodyStripedColor: '#f5f5f5',
            bodyHighlightRowOnHover: true,
            stickyFooters: true,
          },
          tableOptions: {
            collapse: true,
            bodyRoundCorners: false,
            search: false,
            header: true,
            verticallyCenter: true,
            rowSeparator: true,
            columnSeparator: false,
            rowPadding: true,
            tableOrder: [],
            tableWidth: {},
            centerPagination: false,
            itemsCount: false,
            selectRow: false,
            paginationBackgroundColor: 'white',
          },
        },
        smartphone: {
          customGuidelines: {
            x: [],
            y: [],
          },
          itemsPerPage: null,
          id: null,
          cols: null,
          rows: null,
          colsQty: null,
          rowsQty: null,
          layer: null,
          centerPositioning: false,
          x: null,
          y: null,
          font: {
            fontSize: null,
            fontColor: null,
            fontFamily: null,
            fontStyle: null,
            fontWeight: null,
          },
          header: {
            headerTextAlignment: null,
            headerHeight: null,
            headerFontSize: null,
            headerFontColor: null,
            headerFontFamily: null,
            headerFontStyle: null,
            headerBackgroundColor: null,
            stickyHeaders: null,
          },
          body: {
            bodyCenterText: null,
            bodyFontFamily: null,
            bodyFontStyle: null,
            bodyFontSize: null,
            bodyHeight: null,
            bodyFontColor: null,
            bodyBackgroundColor: null,
            isBodyStriped: null,
            firstBodyStripedColor: null,
            secondBodyStripedColor: null,
            bodyHighlightRowOnHover: null,
            stickyFooters: null,
          },
          tableOptions: {
            search: false,
            header: true,
            verticallyCenter: null,
            centerPagination: null,
            rowSeparator: null,
            columnSeparator: null,
            rowPadding: null,
            tableOrder: [],
            tableWidth: {},
          },
        },
      },
    },
    molecules: [],
    placeholders: [],
    isSelected: false,
    enable: true,
    value: null,
    children: [],
    replaceableByRepresentative: false,
    loading: false,
    autoGenerationInfo: [],
    moleculeSubType: 'Table',
    optionsMenu: {
      configuration: true,
      molecules: true,
      compound: false,
      cobblet: true,
      remove: true,
      versioning: true,
      dataSource: true,
      duplicate: true,
    },
  },
];
