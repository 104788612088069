export enum DatasourceTypeId {
  Spreadsheet = 1,
  Database,
  Api,
  System,
  LeapXL,
  UnifiedDatabase,
  Custom,
  InternetMessaging,
  LocalDataStore
}
