import { Component, OnInit } from '@angular/core';
import { faDatabase, faFileExcel, faServer } from '@fortawesome/free-solid-svg-icons';
import { Constants } from '../../../shared/constants';
import { DatasourceType } from '../../../shared/enums/datasource-type.enum';
import { IDatasourceInfo } from '../../../shared/interfaces/datasource-info.interface';
import { DraggableWindowService } from '../../../shared/services/draggable-window.service';
import { ApiDataQualityDataService } from '../api-data-quality-data.service';

@Component({
  selector: 'app-data-provenance',
  templateUrl: './data-provenance.component.html',
  styleUrls: ['./data-provenance.component.scss'],
})
export class DataProvenanceComponent implements OnInit {
  
  contexts = [];
  DataSourceType = DatasourceType;
  spreadsheetIcon = faFileExcel;
  databaseIcon = faDatabase;
  unifiedDbIcon = faServer;
  datasourceInfo: IDatasourceInfo[] = [];
  path = [];
  
  constructor(private dataQualityService: ApiDataQualityDataService, private draggableWindowService: DraggableWindowService) {
    this.contexts = this.draggableWindowService.GetData().contexts;
  }
  
  ngOnInit() {
    this.dataQualityService.GetDataSourceInfo(this.contexts).subscribe(result => {
      
      result.forEach((dsInfo, index) => {
        dsInfo.queryPath = [];
        
        if (dsInfo.queryString) {
          dsInfo.queryString = dsInfo.queryString
          .replace('aiauthor_assignee=', '')
          .replace('&aiauthor_classification=', '.')
          .replace('&aiauthor_classification=', '.')
          .replace('&aiauthor_language=', '.')
          .replace('&aiauthor_dialect=', '.')
          .replace('&aiauthor_document=', '.');
          dsInfo.queryPath = dsInfo.queryString.split('.').filter(p => p !== '');
        }
        
        console.log(dsInfo);
        dsInfo.source = this.contexts[index].split(Constants.ContextSeparator);
        this.path = dsInfo.source;
        this.path.pop();
        this.datasourceInfo.push(dsInfo);
      });
    });
  }
  
}
