import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { colorSets, escapeLabel, formatLabel } from '@swimlane/ngx-charts';
import { range } from 'd3-array';
import * as D3Funnel from 'd3-funnel';
import { scaleLinear, scaleOrdinal, scaleQuantile } from 'd3-scale';
import * as shape from 'd3-shape';
import { cloneDeep } from 'lodash-es';
import { BuilderService } from '../../../../core/builder/builder.service';
import { ProcessorService } from '../../../../core/molecular/services/processor.service';
import { ApiDataSourcesService } from '../../../../core/services/api-data-sources.service';
import { multi } from '../../../../test/test.component';
import { WorkAreaService } from '../../../../workarea/workarea.service';
import { ChartType } from '../../../enums/chart-type.enum';
import { RepresentativeMoleculesType } from '../../../enums/representative-molecules-types.enum';
import { DragService } from '../../services/drag.service';
import { BaseMoleculeComponent } from '../base-molecule/base-molecule.component';

@Component({
  selector: 'app-chart-molecule',
  templateUrl: './chart-molecule.component.html',
  styleUrls: ['./chart-molecule.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ChartMoleculeComponent extends BaseMoleculeComponent implements OnInit, OnDestroy {
  MoleculeType = 'Representative';
  Type = RepresentativeMoleculesType.Chart;
  chartDataReady = false;
  noChartImgPath = '/assets/images/chart-placeholder.png';

  // region LEGACY CHARTS
  multiSetCharts = ['line', 'radar', 'doughnut', 'bar'];
  singleSetCharts = ['pie', 'polarArea'];
  datasets: any[] = [];
  updatingChart = false;
  labels: any[] = [];
  options: any = {};
  plugins: any[] = [];
  setRanges = [];
  data: {
    selection: {
      name: string;
      rows: number;
      cols: number;
      firstRowIdx: number;
      firstColIdx: number;
      lastRowIdx: number;
      lastColIdx: number;
      dataItems: {
        row: number;
        col: number;
        value: any;
        context: string;
        formattedValue: any;
      }[];
    };
  } = null;
  auditContexts = [];
  datatest = [];
  // endregion

  // region NGX CHART SETTINGS
  // =========================================

  lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b', '#7aa3e5', '#a8385d', '#00bfa5'],
  };

  comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b'],
  };

  showRightYAxisLabel = true;
  yAxisLabelRight = '';

  refLinesChart = [];
  funnelChart = null;
  funnelData = [];
  funnelOptions: any = {};
  type = '';
  ChartType = ChartType;
  ngxMultiDataSets = [];
  ngxSingleDataSets = [];
  single: any[] = [
    {
      name: 'Germany',
      value: 40632,
      extra: {
        code: 'de',
      },
    },
    {
      name: 'Holanda',
      value: 5684,
      extra: {
        code: 'de',
      },
    },
    {
      name: 'Italy',
      value: 13084,
      extra: {
        code: 'de',
      },
    },
  ];

  view: any[];
  width = 700;
  height = 300;

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  legendTitle = 'Legend';
  legendPosition = 'right';
  showXAxisLabel = true;
  tooltipDisabled = false;
  showText = true;
  xAxisLabel = '';
  showYAxisLabel = true;
  yAxisLabel = '';
  showGridLines = true;
  barPadding = 8;
  groupPadding = 16;
  roundDomains = false;
  showSeriesOnHover = true;
  roundEdges = true;
  animations = true;
  xScaleMin: any;
  xScaleMax: any;
  yScaleMin: number;
  yScaleMax: number;
  showDataLabel = false;
  noBarWhenZero = true;
  trimXAxisTicks = true;
  trimYAxisTicks = true;
  rotateXAxisTicks = true;
  maxXAxisTickLength = 16;
  maxYAxisTickLength = 16;
  pinched: boolean;
  inverted: boolean;
  dynamic: boolean;
  labelColor = '#fff';
  labelSize = '16px';
  funnelBottomWidth = 0.33333;
  funnelPinchLevels = 1;
  curveHeight = 20;

  curves = {
    Basis: shape.curveBasis,
    'Basis Closed': shape.curveBasisClosed,
    Bundle: shape.curveBundle.beta(1),
    Cardinal: shape.curveCardinal,
    'Cardinal Closed': shape.curveCardinalClosed,
    'Catmull Rom': shape.curveCatmullRom,
    'Catmull Rom Closed': shape.curveCatmullRomClosed,
    Linear: shape.curveLinear,
    'Linear Closed': shape.curveLinearClosed,
    'Monotone X': shape.curveMonotoneX,
    'Monotone Y': shape.curveMonotoneY,
    Natural: shape.curveNatural,
    Step: shape.curveStep,
    'Step After': shape.curveStepAfter,
    'Step Before': shape.curveStepBefore,
    default: shape.curveLinear,
  };

  sparklineCurve = this.curves['Monotone Y'];
  referenceCurve = this.curves['Catmull Rom'];

  // line interpolation
  curveType = 'Natural';
  curve: any = this.curves[this.curveType];
  closedCurve: any = this.curves[this.curveType];
  colorSets: any = colorSets;
  colorScheme: any;
  schemeType = 'ordinal';
  selectedColorScheme: string;
  rangeFillOpacity = 0.15;

  // Override colors for certain values
  customColors: any[] = [];

  // pie
  explodeSlices = false;
  doughnut = false;
  arcWidth = 0.25;

  // line, area
  autoScale = true;
  timeline = false;

  // margin
  margin = false;
  marginTop = 40;
  marginRight = 40;
  marginBottom = 40;
  marginLeft = 40;

  // gauge
  gaugeMin = 0;
  gaugeMax = 100;
  gaugeLargeSegments = 10;
  gaugeSmallSegments = 5;
  gaugeTextValue = '';
  gaugeUnits = 'alerts';
  gaugeAngleSpan = 240;
  gaugeStartAngle = -120;
  gaugeShowAxis = true;
  gaugePreviousValue = 70;

  // linear gauge value
  gaugeValue = 50;

  // heatmap
  heatmapMin = 0;
  heatmapMax = 50000;
  innerPadding = '10%';

  // =========================================
  // endregion

  constructor(
    public builderService: BuilderService,
    public dragService: DragService,
    public workAreaService: WorkAreaService,
    public processorService: ProcessorService,
    private dataSourceService: ApiDataSourcesService,
    private ref: ChangeDetectorRef,
    bottomSheet: MatBottomSheet,
    public el: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(bottomSheet, el, changeDetectorRef);
  }

  generateColorScheme(scheme, type, domain) {
    if (typeof scheme === 'string') {
      scheme = colorSets.find(cs => {
        return cs.name === scheme;
      });
    }
    let colorScale;
    if (type === 'quantile') {
      colorScale = scaleQuantile().range(scheme.domain).domain(domain);
    } else if (type === 'ordinal') {
      colorScale = scaleOrdinal().range(scheme.domain).domain(domain);
    } else if (type === 'linear') {
      // linear schemes must have at least 2 colors
      const colorDomain = [...scheme.domain];
      if (colorDomain.length === 1) {
        colorDomain.push(colorDomain[0]);
      }

      const points = range(0, 1, 1.0 / colorDomain.length);
      colorScale = scaleLinear().domain(points).range(colorDomain);
    }

    return colorScale;
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.context.RunningMode) {
      this.DetachChangeDetection();
    }
    // console.log('init chart');
    this.context.Type = RepresentativeMoleculesType.Chart;
    this.RefreshGridsterConfiguration();

    this.UpdateData();

    this.Subscriptions = this.communicationService.Event.System.Update.$ChangesOnCharts.subscribe(molecule => {
      // console.log('$ChangesOnCharts');
      // console.log('==========changes on charts event =========');

      console.log('=event=');
      this.UpdateData();
    });
  }

  public RepMoleculeResized() {
    if (this.type === ChartType.Funnel && this.funnelChart) {
      this.RefreshFunnelChart();
    }

    // console.log('çhart resize');

    const size = this.GetChartElementSize();

    const offset = this.context.SubParentId && this.context.SubParentId > 0 ? 30 : 0;

    if (size) {
      // console.log(size);
      this.view = [size.width + offset, size.height + offset];
      this.ref.markForCheck();
      this.communicationService.Event.System.App.$RefreshUI.emit();
    }
  }

  UpdateData() {
    if (this.context.ResponsiveProperties().chartOptions) {
      // property exists
    } else {
      return;
    }

    this.type = this.context.ResponsiveProperties().chartOptions.ngxChartType;
    // this.type = ChartType.BarVertical;
    // this.ngxDataSets = this.single;
    // this.chartDataReady = true;
    // return;

    if (this.updatingChart || this.context.Value === null || (Array.isArray(this.context.Value) && this.context.Value.length === 0)) {
      // no data
      return;
    }

    let chartData = cloneDeep(Array.isArray(this.context.Value) ? this.context.Value : []);

    console.log(this.datasets);

    if (chartData.length === 0) {
      return false;
    }

    // region change data orientation for non legacy
    if (this.context.Properties.chartLibrary === 'ngxCharts' && this.context.ResponsiveProperties().chartOptions.switchRowCols) {
      chartData = this.TransposeData(chartData);
    }
    // endregion

    // remove empty values from data
    chartData = chartData.filter(c => c.value !== '');
    //

    this.updatingChart = true;
    this.datasets = [];
    this.labels = [];
    this.chartDataReady = false;

    const lastRow = Math.max.apply(
      Math,
      chartData.map(o => {
        return o.row;
      })
    );
    const lastCol = Math.max.apply(
      Math,
      chartData.map(function (o) {
        return o.col;
      })
    );
    const firstRowIndex = Math.min.apply(
      Math,
      chartData.map(o => {
        return o.row;
      })
    );
    const firstColIndex = Math.min.apply(
      Math,
      chartData.map(function (o) {
        return o.col;
      })
    );

    console.log('chartData', chartData);

    this.data = {
      selection: {
        name: '',
        rows: lastRow - firstRowIndex + 1,
        cols: lastCol - firstColIndex + 1,
        firstRowIdx: firstRowIndex,
        firstColIdx: firstColIndex,
        lastRowIdx: lastRow,
        lastColIdx: lastCol,
        dataItems: chartData,
      },
    };

    this.resfresChart();
    this.updatingChart = false;
  }

  SetNgxProperties() {
    if (!this.context) {
      return;
    }

    this.type = this.context.ResponsiveProperties().chartOptions.ngxChartType;
    this.width = this.context.ResponsiveProperties().chartOptions.width;
    this.height = this.context.ResponsiveProperties().chartOptions.height;
    this.closedCurve = this.curves[this.context.ResponsiveProperties().chartOptions.closedCurveType];
    this.curve = this.curves[this.context.ResponsiveProperties().chartOptions.curveType];
    this.schemeType = this.context.ResponsiveProperties().chartOptions.schemeType;
    this.colorScheme = this.context.ResponsiveProperties().chartOptions.colorScheme;
    this.showXAxis = this.context.ResponsiveProperties().chartOptions.showXAxis;
    this.showYAxis = this.context.ResponsiveProperties().chartOptions.showYAxis;
    this.gradient = this.context.ResponsiveProperties().chartOptions.gradient;
    this.showLegend = this.context.ResponsiveProperties().chartOptions.showLegend;
    this.legendTitle = this.context.ResponsiveProperties().chartOptions.legendTitle;
    this.legendPosition = this.context.ResponsiveProperties().chartOptions.legendPosition;
    this.showXAxisLabel = this.context.ResponsiveProperties().chartOptions.showXAxisLabel;
    this.showText = this.context.ResponsiveProperties().chartOptions.showText;
    this.xAxisLabel = this.context.ResponsiveProperties().chartOptions.xAxisLabel;
    this.showYAxisLabel = this.context.ResponsiveProperties().chartOptions.showYAxisLabel;
    this.yAxisLabel = this.context.ResponsiveProperties().chartOptions.yAxisLabel;
    this.showGridLines = this.context.ResponsiveProperties().chartOptions.showGridLines;
    this.barPadding = this.context.ResponsiveProperties().chartOptions.barPadding;
    this.groupPadding = this.context.ResponsiveProperties().chartOptions.groupPadding;
    this.roundDomains = this.context.ResponsiveProperties().chartOptions.roundDomains;
    this.roundEdges = this.context.ResponsiveProperties().chartOptions.roundEdges;
    this.animations = this.context.ResponsiveProperties().chartOptions.animations;
    this.xScaleMin = this.context.ResponsiveProperties().chartOptions.xScaleMin;
    this.xScaleMax = this.context.ResponsiveProperties().chartOptions.xScaleMax;
    this.yScaleMin = this.context.ResponsiveProperties().chartOptions.yScaleMin;
    this.yScaleMax = this.context.ResponsiveProperties().chartOptions.yScaleMax;
    this.showDataLabel = this.context.ResponsiveProperties().chartOptions.showDataLabel;
    this.trimXAxisTicks = this.context.ResponsiveProperties().chartOptions.trimXAxisTicks;
    this.trimYAxisTicks = this.context.ResponsiveProperties().chartOptions.trimYAxisTicks;
    this.rotateXAxisTicks = this.context.ResponsiveProperties().chartOptions.rotateXAxisTicks;
    this.maxXAxisTickLength = this.context.ResponsiveProperties().chartOptions.maxXAxisTickLength;
    this.maxYAxisTickLength = this.context.ResponsiveProperties().chartOptions.maxYAxisTickLength;
    this.explodeSlices = this.context.ResponsiveProperties().chartOptions.explodeSlices;
    this.doughnut = this.context.ResponsiveProperties().chartOptions.doughnut;
    this.margin = this.context.ResponsiveProperties().chartOptions.margin;
    this.marginTop = this.context.ResponsiveProperties().chartOptions.marginTop;
    this.marginRight = this.context.ResponsiveProperties().chartOptions.marginRight;
    this.marginBottom = this.context.ResponsiveProperties().chartOptions.marginBottom;
    this.marginLeft = this.context.ResponsiveProperties().chartOptions.marginLeft;
    this.arcWidth = this.context.ResponsiveProperties().chartOptions.arcWidth;
    this.autoScale = this.context.ResponsiveProperties().chartOptions.autoScale;
    this.gaugeMin = this.context.ResponsiveProperties().chartOptions.gaugeMin;
    this.gaugeMax = this.context.ResponsiveProperties().chartOptions.gaugeMax;
    this.gaugeLargeSegments = this.context.ResponsiveProperties().chartOptions.gaugeLargeSegments;
    this.gaugeSmallSegments = this.context.ResponsiveProperties().chartOptions.gaugeSmallSegments;
    this.gaugeTextValue = this.context.ResponsiveProperties().chartOptions.gaugeTextValue;
    this.gaugeUnits = this.context.ResponsiveProperties().chartOptions.gaugeUnits;
    this.gaugeAngleSpan = this.context.ResponsiveProperties().chartOptions.gaugeAngleSpan;
    this.gaugeStartAngle = this.context.ResponsiveProperties().chartOptions.gaugeStartAngle;
    this.gaugeShowAxis = this.context.ResponsiveProperties().chartOptions.gaugeShowAxis;
    this.gaugePreviousValue = this.context.ResponsiveProperties().chartOptions.gaugePreviousValue;
    this.gaugeValue = this.context.ResponsiveProperties().chartOptions.linearGaugeValue;
    this.innerPadding = this.context.ResponsiveProperties().chartOptions.innerPadding.toString() + 'px';
    this.rangeFillOpacity = this.context.ResponsiveProperties().chartOptions.rangeFillOpacity;
    this.showSeriesOnHover = this.context.ResponsiveProperties().chartOptions.showSeriesOnHover;
    this.noBarWhenZero = this.context.ResponsiveProperties().chartOptions.noBarWhenZero;
    this.tooltipDisabled = this.context.ResponsiveProperties().chartOptions.tooltipDisabled || !this.toolsService.RunningMode;
    this.pinched = this.context.ResponsiveProperties().chartOptions.pinched;
    this.inverted = this.context.ResponsiveProperties().chartOptions.inverted;
    this.dynamic = this.context.ResponsiveProperties().chartOptions.dynamic;
    this.funnelBottomWidth = this.context.ResponsiveProperties().chartOptions.funnelBottomWidth;
    this.funnelPinchLevels = this.context.ResponsiveProperties().chartOptions.funnelPinchLevels;
    this.labelSize = this.context.ResponsiveProperties().chartOptions.labelSize + 'px';
    this.labelColor = this.context.ResponsiveProperties().chartOptions.labelColor;
    this.curveHeight = this.context.ResponsiveProperties().chartOptions.curveHeight;
  }

  resfresChart() {
    if (this.context.Properties.chartLibrary === 'ng2Charts') {
      this.options = {
        responsive: true,
        scales: this.context.Properties.chartScales ? { xAxes: [{}], yAxes: [{}] } : {},
        showLines: this.context.Properties.chartShowLines,
        spanGaps: false,
        animation: {
          animateScale: true,
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem: any, data) => {
              // console.log(tooltipItem, data, this.context.Properties.chartType);

              let label = data.datasets[tooltipItem.datasetIndex].label || '';
              this.auditContexts = data.datasets[tooltipItem.datasetIndex].contexts;

              if (this.singleSetCharts.includes(this.context.Properties.chartType) || this.context.Properties.chartType === 'doughnut') {
                label = `${data.labels[tooltipItem.index] ? data.labels[tooltipItem.index] : `Series ${tooltipItem.index}`} : ${
                  data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                }`;
                // console.log('label', label);
              } else {
                if (label) {
                  label += ': ';
                }
                label += Math.round(tooltipItem.yLabel * 100) / 100;
              }

              return label;
            },
          },
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              const label = ctx.chart.data.labels[ctx.dataIndex];
              return label;
            },
          },
        },
      };

      setTimeout(() => {
        switch (this.context.Properties.chartType) {
          case 'line':
          case 'bubble':
          case 'scatter':
          case 'radar':
          case 'bar':
          case 'doughnut':
            this.createMultiDatasetData();
            break;

          case 'pie':
          case 'polarArea':
            this.createSingleDatasetData();
            break;

          default:
            break;
        }

        this.ref.markForCheck();
      }, 200);
    } else {
      this.SetNgxProperties();

      const dataPrepared = this.PrepareData();
      const ngxMultiDataSets = this.CreateNgxMultiDataSets(dataPrepared);

      const chartOptions = ChartOptions.find(co => co.name === this.type);

      if (chartOptions) {
        switch (chartOptions.dataSet) {
          case 'single':
            this.ngxMultiDataSets = this.CreateNgxSingleDataSetsFormMultiDataSets(ngxMultiDataSets, true);
            break;

          case 'multi':
            this.ngxMultiDataSets = ngxMultiDataSets;
            break;

          default:
            this.ngxMultiDataSets = [];
            break;
        }
      }

      // console.log('datasets', this.ngxMultiDataSets);

      switch (this.type) {
        case ChartType.Combo:
          this.ngxSingleDataSets = this.CreateNgxTransposeSingleDataSetsFormMultiDataSets(ngxMultiDataSets);
          this.RepMoleculeResized();
          this.chartDataReady = true;
          this.ref.markForCheck();
          this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(this.context.ParentId);
          break;

        case ChartType.Funnel:
          this.CreateFunnelChart(this.ngxMultiDataSets);
          break;

        default:
          this.RepMoleculeResized();
          this.chartDataReady = true;
          this.ref.markForCheck();
          this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(this.context.ParentId);
          break;
      }
    }
  }

  RefreshFunnelChart() {
    if (this.funnelChart) {
      this.funnelChart.destroy();

      const size = this.GetChartElementSize();
      this.funnelOptions.chart.width = size.width - 20;
      this.funnelOptions.chart.height = size.height - 20;

      this.funnelChart = new D3Funnel('#funnel-' + this.context.Id);
      if (this.funnelChart) {
        this.funnelChart.draw(this.funnelData, this.funnelOptions);
      }
    }
  }

  CreateFunnelChart(singleDataSet: any[]) {
    this.chartDataReady = true;
    this.ref.markForCheck();
    if (this.funnelChart) {
      this.funnelChart.destroy();
    }

    this.funnelData = [];

    singleDataSet.forEach(d => {
      this.funnelData.push({
        label: d.name,
        value: d.value,
        extra: {
          contexts: d.extra.contexts,
        },
      });
    });

    const size = this.GetChartElementSize();

    if (size) {
      this.funnelOptions = {
        chart: {
          width: size.width - 20,
          height: size.height - 20,
          bottomPinch: this.pinched ? this.funnelPinchLevels : 0,
          bottomWidth: this.funnelBottomWidth,
          inverted: this.inverted,
          animate: this.animations && this.toolsService.RunningMode ? 100 : 0,
          curve: {
            enabled: this.roundEdges,
            height: this.curveHeight,
          },
        },
        block: {
          dynamicHeight: this.dynamic,
          minHeight: 15,
          highlight: this.showSeriesOnHover,
          barOverlay: this.schemeType === 'linear',
          fill: {
            scale: this.generateColorScheme(this.colorScheme, 'ordinal', [0, this.funnelData.length]),
            type: this.gradient ? 'gradient' : 'solid',
          },
        },
        label: {
          enabled: this.showDataLabel,
          fill: this.labelColor,
          fontSize: this.labelSize,
        },
        tooltip: {
          enabled: !this.tooltipDisabled,
        },
        events: {
          click: {
            block(d) {
              // alert(d.label.raw);
            },
          },
        },
      };
      this.ref.markForCheck();

      setTimeout(() => {
        this.funnelChart = new D3Funnel('#funnel-' + this.context.Id);
        this.funnelChart.draw(this.funnelData, this.funnelOptions);
      }, 50);
    }
  }

  chartHovered(e) {
    // console.log(e);
  }

  GetChartElementSize(): {
    width: number;
    height: number;
  } {
    const chartElement = document.getElementById('gridsterItem-' + this.context.Id);

    if (chartElement) {
      const view = [
        +chartElement.style.width.replace('px', ''),
        +chartElement.style.height.replace('px', '') -
          (this.context.ResponsiveProperties().chartOptions.showLegend && this.context.ResponsiveProperties().chartOptions.legendPosition === 'below'
            ? 100
            : 0),
      ];

      return {
        width: view[0],
        height: view[1],
      };
    } else {
      return null;
    }
  }

  OnContextMenu(event: MouseEvent) {
    const contexts = this.context.Value.map(c => c.context);

    // console.log(this.auditContexts);
    // console.log(contexts);
    this.context.OnContextMenu(event, {
      contexts: this.auditContexts,
      completeContexts: contexts,
    });
    return false;
  }

  chartClicked(e) {
    if (!this.cobbleService.Cobble.running || e.active.length === 0) {
      return;
    }

    const chart = e.active.length > 0 ? e.active[0]._chart : e.chart;
    // console.log('chart', e, chart);

    const firstPoint = chart.getElementAtEvent(e.event)[0];
    if (firstPoint) {
      let range = '';

      if (this.singleSetCharts.includes(this.context.Properties.chartType)) {
        range = this.setRanges[firstPoint._index];
      } else {
        range = this.setRanges[firstPoint._datasetIndex][firstPoint._index];
      }
    }
  }

  Throttle(func, delay, context, args) {
    clearTimeout(this.inDebounce);
    this.inDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }

  select(data) {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  activate(data) {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));

    if (data.value.extra && data.value.extra.contexts && Array.isArray(data.value.extra.contexts) && data.value.extra.contexts.length > 0) {
      this.auditContexts = data.value.extra.contexts.map(c => (Array.isArray(c) ? c[0] : c));
    }
  }

  deactivate(data) {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  onLegendLabelClick(entry) {
    // console.log('Legend clicked', entry);
  }

  setColorScheme(name) {
    this.selectedColorScheme = name;
    this.colorScheme = this.colorSets.find(s => s.name === name);
  }

  pieTooltipText({ data }) {
    const label = formatLabel(data.name);
    const val = formatLabel(data.value);

    return `
      <span class="tooltip-label">${escapeLabel(label)}</span>
      <span class="tooltip-val">${val}</span>
    `;
  }

  dblclick(event) {
    // console.log('Doube click', event);
  }

  gdpLabelFormatting(c) {
    return `${escapeLabel(c.label)}<br/><small class="number-card-label">GDP Per Capita</small>`;
  }

  currencyFormatting(value: number) {
    return `${Math.round(value).toLocaleString()}`;
  }

  valueFormatting(value: number): string {
    return `${Math.round(value).toLocaleString()}`;
  }

  labelFormatting(c) {
    return `${c.label} Population`;
  }

  IsFirstColHeaders(data: any = this.data, isFirstRowHeaders = false): boolean {
    let isFirstColHeaders = false;

    for (let row = this.data.selection.firstRowIdx + 1; row < this.data.selection.firstRowIdx + this.data.selection.rows; row++) {
      const colValue = this.data.selection.dataItems.find(di => di.row === row && di.col === this.data.selection.firstColIdx);

      if (colValue && colValue.value && !this.toolsService.IsNumeric(colValue.formattedValue)) {
        isFirstColHeaders = true;
        break;
      }
    }

    if (isFirstRowHeaders && isFirstColHeaders) {
      isFirstColHeaders = false;
    }

    return isFirstColHeaders;
  }

  IsFirstRowHeaders(data: any = this.data, isFirstColHeaders = false): boolean {
    let isFirstRowHeaders = false;

    for (let col = this.data.selection.firstColIdx + 1; col < this.data.selection.firstColIdx + this.data.selection.cols; col++) {
      const colValue = this.data.selection.dataItems.find(di => di.col === col && di.row === this.data.selection.firstRowIdx);

      if (colValue && colValue.value && !this.toolsService.IsNumeric(colValue.formattedValue)) {
        isFirstRowHeaders = true;
        break;
      }
    }

    if (isFirstRowHeaders && isFirstColHeaders) {
      isFirstRowHeaders = false;
    }

    return isFirstRowHeaders;
  }

  AddSeriesColumnToData(data: {
    selection: {
      name: string;
      rows: number;
      cols: number;
      firstRowIdx: number;
      firstColIdx: number;
      lastRowIdx: number;
      lastColIdx: number;
      dataItems: {
        row: number;
        col: number;
        value: any;
        context: string;
        formattedValue: any;
      }[];
    };
  }) {
    data.selection.lastColIdx = data.selection.lastColIdx + 1;
    data.selection.cols = data.selection.cols + 1;
    data.selection.dataItems.forEach(di => (di.col = di.col + 1));

    let seriesNumber = 1;

    for (let row = this.data.selection.firstRowIdx; row < this.data.selection.firstRowIdx + this.data.selection.rows; row++) {
      data.selection.dataItems.push({
        row: row,
        col: data.selection.firstColIdx,
        value: `Series ${seriesNumber}`,
        context: '',
        formattedValue: `Series ${seriesNumber}`,
      });

      seriesNumber++;
    }

    return data;
  }

  AddSeriesRowToData(data: {
    selection: {
      name: string;
      rows: number;
      cols: number;
      firstRowIdx: number;
      firstColIdx: number;
      lastRowIdx: number;
      lastColIdx: number;
      dataItems: {
        row: number;
        col: number;
        value: any;
        context: string;
        formattedValue: any;
      }[];
    };
  }) {
    data.selection.lastRowIdx = data.selection.lastRowIdx + 1;
    data.selection.rows = data.selection.rows + 1;
    data.selection.dataItems.forEach(di => (di.row = di.row + 1));

    let seriesNumber = 1;
    for (let col = this.data.selection.firstColIdx; col < this.data.selection.firstColIdx + this.data.selection.cols; col++) {
      data.selection.dataItems.push({
        row: data.selection.firstRowIdx,
        col: col,
        value: `Series ${seriesNumber}`,
        context: '',
        formattedValue: `Series ${seriesNumber}`,
      });

      seriesNumber++;
    }

    return data;
  }

  CreateNgxMultiDataSets(data = this.data): any[] {
    const multiDataSets = [];

    for (let col = this.data.selection.firstColIdx + 1; col < this.data.selection.firstColIdx + this.data.selection.cols; col++) {
      const headingCell = this.data.selection.dataItems.find(di => di.row === this.data.selection.firstRowIdx && di.col === col);

      if (headingCell) {
        const multiDataSet = {
          name: headingCell.formattedValue,
          series: [],
        };

        for (let row = this.data.selection.firstRowIdx + 1; row < this.data.selection.firstRowIdx + this.data.selection.rows; row++) {
          const cellValue = this.GetCellFromDataItems(row, col, this.data.selection.dataItems);
          // console.log('cellValue', cellValue);
          //
          // console.log('dataitems', this.data.selection.dataItems);
          // console.log('row', row);
          // console.log('col', this.data.selection.firstColIdx);
          const cell = this.data.selection.dataItems.find(di => di.row === row && di.col === this.data.selection.firstColIdx);

          if (cell) {
            multiDataSet.series.push({
              name: cell.formattedValue,
              value: +cellValue.value,
              extra: {
                contexts: [cellValue.context],
              },
            });
          }
        }

        multiDataSets.push(multiDataSet);
      }
    }

    this.CreateMultiDataSetsRefLines(multiDataSets);

    return multiDataSets;
  }

  CreateMultiDataSetsRefLines(
    multiDataSets: {
      name: string;
      series: any[];
    }[]
  ) {
    let min = 0;
    let max = 0;
    let avg = 0;

    const avgs = [];

    multiDataSets.forEach(md => {
      const seriesMin = Math.min(...md.series.map(s => s.value));
      const seriesMax = Math.max(...md.series.map(s => s.value));

      min = seriesMin < min ? seriesMin : min;
      max = seriesMax > max ? seriesMax : max;
      avgs.push(md.series.map(s => s.value).reduce((a, x) => a + x, 0) / md.series.map(s => s.value).length);
    });
    avg = avgs.reduce((a, x) => a + x, 0) / avgs.length;

    this.refLinesChart.push({ value: max, name: 'Maximum' }, { value: avg, name: 'Average' }, { value: min, name: 'Minimum' });
  }

  GetCellFromDataItems(
    row: number,
    col: number,
    dataItems: {
      row: number;
      col: number;
      value: any;
      formattedValue: any;
      context: string;
    }[]
  ) {
    let cellValue = this.data.selection.dataItems.find(di => di.row === row && di.col === col);

    cellValue = cellValue || {
      row: row,
      col: col,
      value: 0,
      formattedValue: 0,
      context: '',
    };

    cellValue.value = cellValue.value || 0;

    if (typeof cellValue.value === 'string') {
      if (this.toolsService.IsNumeric(cellValue.value)) {
        cellValue.value = parseFloat(cellValue.value);
      } else {
        cellValue.value = 0;
      }
    }

    return cellValue;
  }

  CreateNgxSingleDataSetsFormMultiDataSets(
    multiDataSets: {
      name: string;
      series: {
        name: string;
        value: number;
        extra: any;
      }[];
    }[],
    aggregate = false
  ) {
    const singleDataSet = [];

    multiDataSets.forEach(md => {
      if (aggregate) {
        let value = 0;
        const contexts = md.series.map(s => s.extra.contexts);
        md.series.forEach(s => {
          // console.log('value', value);
          // console.log('+value', +s.value);
          value += +s.value;
        });

        singleDataSet.push({
          name: md.name,
          value: +value,
          extra: {
            contexts: contexts,
          },
        });
      } else {
        singleDataSet.push({
          name: md.name,
          value: +md.series[0].value,
          extra: {
            contexts: [md.series[0].extra.contexts],
          },
        });
      }
    });

    return singleDataSet;
  }

  CreateNgxTransposeSingleDataSetsFormMultiDataSets(
    multiDataSets: {
      name: string;
      series: {
        name: string;
        value: number;
        extra: any;
      }[];
    }[],
    aggregate = false
  ) {
    const singleDataSet = [];

    multiDataSets.forEach(md => {
      md.series.forEach(s => {
        const dataSet = singleDataSet.find(sd => sd.name === s.name);

        if (dataSet) {
          if (aggregate) {
            dataSet.value = dataSet.value + +s.value;
          }
        } else {
          singleDataSet.push({
            name: s.name,
            value: +s.value,
            extra: {
              contexts: [s.extra.contexts],
            },
          });
        }
      });
    });

    // console.log('s', singleDataSet);

    return singleDataSet;
  }

  TransposeData(dataCells: any[]) {
    dataCells.forEach(di => ([di.col, di.row] = [di.row, di.col]));
    // [data.selection.cols, data.selection.rows] = [data.selection.rows, data.selection.cols];
    // [data.selection.firstColIdx, data.selection.firstRowIdx] = [data.selection.firstRowIdx, data.selection.firstColIdx];
    // [data.selection.lastColIdx, data.selection.lastRowIdx] = [data.selection.lastRowIdx, data.selection.lastColIdx];
    return dataCells;
  }

  yLeftAxisScale(min, max) {
    return { min: `${min}`, max: `${max}` };
  }

  yRightAxisScale(min, max) {
    // console.log(min, max);
    return { min: `${min}`, max: `${max}` };
  }

  yLeftTickFormat(data) {
    return `${data.toLocaleString()}`;
  }

  /*
   **
   Combo Chart
   **
   [yLeftAxisScaleFactor]="yLeftAxisScale" and [yRightAxisScaleFactor]="yRightAxisScale"
   exposes the left and right min and max axis values for custom scaling, it is probably best to
   scale one axis in relation to the other axis but for flexibility to scale either the left or
   right axis bowth were exposed.
   **
   */

  yRightTickFormat(data) {
    // return `${data}%`;
    return `${data}`;
  }

  private createMultiDatasetData() {
    // console.log('multi dataset data', this.data);
    // console.log('chartOrientation', this.context.Properties.chartOrientation);

    try {
      let isFirstColHeaders = false;
      let isFirstRowHeaders = false;

      for (let row = this.data.selection.firstRowIdx; row < this.data.selection.firstRowIdx + this.data.selection.rows; row++) {
        const colValue = this.data.selection.dataItems.find(di => di.row === row && di.col === this.data.selection.firstColIdx);

        if (colValue && colValue.value && !this.toolsService.IsNumeric(colValue.formattedValue)) {
          isFirstColHeaders = true;
        }
      }

      for (let col = this.data.selection.firstColIdx; col < this.data.selection.firstColIdx + this.data.selection.cols; col++) {
        const colValue = this.data.selection.dataItems.find(di => di.col === col && di.row === this.data.selection.firstRowIdx);

        if (colValue && colValue.value && !this.toolsService.IsNumeric(colValue.formattedValue)) {
          isFirstRowHeaders = true;
        }
      }

      // console.log('isFirstColHeaders', isFirstColHeaders);
      // console.log('isFirstRowHeaders', isFirstRowHeaders);

      if (this.context.Properties.chartOrientation === 'top') {
        const dataSet: {
          label: string;
          data: number[];
          contexts: string[];
        }[] = [];

        for (let col = this.data.selection.firstRowIdx; col <= this.data.selection.lastColIdx; col++) {
          // console.log('===COL===', col);

          if ((isFirstColHeaders && col > this.data.selection.firstColIdx) || !isFirstColHeaders) {
            // console.log('analizing col');

            if (isFirstRowHeaders && !isFirstColHeaders) {
              this.labels.push(`Series ${col - 1}`);
            }
            const newSet = {
              label: `Series ${col - this.data.selection.firstColIdx - 1}`,
              data: [],
              contexts: [],
            };

            const ranges = [];

            for (let row = this.data.selection.firstRowIdx; row < this.data.selection.firstRowIdx + this.data.selection.rows; row++) {
              let rowValue = this.data.selection.dataItems.find(di => di.row === row && di.col === col);

              // console.log('row value', rowValue);

              rowValue = rowValue
                ? rowValue
                : {
                    row: row,
                    col: col,
                    value: 0,
                    context: '',
                    formattedValue: '',
                  };

              if (isFirstRowHeaders && row === this.data.selection.firstRowIdx) {
                newSet.label = rowValue.formattedValue;
              } else {
                if (col === this.data.selection.firstColIdx && isNaN(rowValue.value)) {
                  // console.log('add label 1', rowValue.formattedValue);
                  this.labels.push(rowValue.formattedValue);
                } else {
                  ranges.push(`${this.toolsService.ColumnIndexToName(col)}${row}:${this.toolsService.ColumnIndexToName(col)}${row}`);
                  newSet.data.push(parseFloat(rowValue.value));
                  newSet.contexts.push(rowValue.context);
                }
              }
            }
            if (newSet.data.length > 0) {
              // console.log('ranges', ranges);
              // console.log('newSet', newSet);

              this.setRanges.push(ranges);
              dataSet.push(newSet);
            }
          } else {
            // console.log('rows', this.data.selection.rows);

            for (let row = this.data.selection.firstRowIdx; row < this.data.selection.firstRowIdx + this.data.selection.rows; row++) {
              // console.log('row header', row);

              let rowValue = this.data.selection.dataItems.find(di => di.row === row && di.col === col);

              rowValue = rowValue
                ? rowValue
                : {
                    row: row,
                    col: col,
                    value: 0,
                    context: '',
                    formattedValue: `${col}`,
                  };

              if (isFirstRowHeaders && row === this.data.selection.firstRowIdx) {
              } else {
                // console.log('add label 2', rowValue.formattedValue);

                this.labels.push(rowValue.formattedValue);
              }
              // console.log('row value header', rowValue);
            }
          }
        }

        this.datasets = dataSet;
        this.context.Properties.chartLegend = this.context.Properties.chartLegend;
        // ? isFirstColHeaders
        // : false;

        if (Array.isArray(this.datasets) && this.datasets.length > 0) {
          this.chartDataReady = true;
          this.ref.markForCheck();
        }
        // console.log('top');
      } else {
        const dataSet: {
          label: string;
          data: number[];
          contexts: string[];
        }[] = [];

        for (let row = this.data.selection.firstRowIdx; row <= this.data.selection.lastRowIdx; row++) {
          // console.log('===ROW===', row);

          if ((isFirstRowHeaders && row > this.data.selection.firstRowIdx) || !isFirstRowHeaders) {
            // console.log('analizing row');

            if (isFirstColHeaders && !isFirstRowHeaders) {
              this.labels.push(`Series ${row - 1}`);
            }
            const newSet = {
              label: `Series ${row - this.data.selection.firstRowIdx - 1}`,
              data: [],
              contexts: [],
            };

            const ranges = [];

            for (let col = this.data.selection.firstColIdx; col < this.data.selection.firstColIdx + this.data.selection.cols; col++) {
              let colValue = this.data.selection.dataItems.find(di => di.row === row && di.col === col);

              // console.log('col value', colValue);

              colValue = colValue
                ? colValue
                : {
                    row: row,
                    col: col,
                    value: 0,
                    context: '',
                    formattedValue: '',
                  };

              if (isFirstColHeaders && col === this.data.selection.firstColIdx) {
                newSet.label = colValue.formattedValue;
              } else {
                if (row === this.data.selection.firstRowIdx && isNaN(colValue.value)) {
                  // console.log('add label 1', colValue.formattedValue);
                  this.labels.push(colValue.formattedValue);
                } else {
                  ranges.push(`${this.toolsService.ColumnIndexToName(col)}${row}:${this.toolsService.ColumnIndexToName(col)}${row}`);
                  this.datatest.push({
                    value: parseFloat(colValue.value),
                    name: 'name' + col + row,
                  });
                  newSet.data.push(parseFloat(colValue.value));
                  newSet.contexts.push(colValue.context);
                }
              }
            }
            if (newSet.data.length > 0) {
              // console.log('ranges', ranges);
              // console.log('newSet', newSet);

              this.setRanges.push(ranges);
              dataSet.push(newSet);
            }
          } else {
            // console.log('cols', this.data.selection.cols);

            for (let col = this.data.selection.firstColIdx; col < this.data.selection.firstColIdx + this.data.selection.cols; col++) {
              // console.log('col header', col);

              let colValue = this.data.selection.dataItems.find(di => di.row === row && di.col === col);

              colValue = colValue
                ? colValue
                : {
                    row: row,
                    col: col,
                    context: '',
                    value: 0,
                    formattedValue: `${col}`,
                  };

              if (isFirstColHeaders && col === this.data.selection.firstColIdx) {
              } else {
                // console.log('add label 2', colValue.formattedValue);

                this.labels.push(colValue.formattedValue);
              }
              // console.log('col value header', colValue);
            }
          }
        }

        this.datasets = dataSet;
        // this.context.Properties.chartLegend = this.context.Properties.chartLegend;

        if (Array.isArray(this.datasets) && this.datasets.length > 0) {
          this.chartDataReady = true;
          this.ref.markForCheck();
        }
      }

      // console.log('datasets', this.datasets);
      // console.log('adtatest', this.datatest);
      // console.log('labels', this.labels);
    } catch (error) {
      // console.log('error creating chart', error);
      this.ref.markForCheck();
    }
  }

  private createSingleDatasetData() {
    // console.log('single dataset data');
    // console.log('orientation', this.context.Properties.chartOrientation);

    let isFirstColHeaders = false;
    let isFirstRowHeaders = false;

    for (let row = this.data.selection.firstRowIdx; row < this.data.selection.firstRowIdx + this.data.selection.rows; row++) {
      const colValue = this.data.selection.dataItems.find(di => di.row === row && di.col === this.data.selection.firstColIdx);

      if (colValue && isNaN(+colValue.value)) {
        isFirstColHeaders = true;
      }
    }

    for (let col = this.data.selection.firstColIdx; col < this.data.selection.firstColIdx + this.data.selection.cols; col++) {
      const rowValue = this.data.selection.dataItems.find(di => di.col === col && di.row === this.data.selection.firstRowIdx);

      if (rowValue && isNaN(+rowValue.value)) {
        isFirstRowHeaders = true;
      }
    }

    // console.log('isFirstColHeaders', isFirstColHeaders);
    // console.log('isFirstRowHeaders', isFirstRowHeaders);

    const newSet = [];
    const a = [];

    if (this.context.Properties.chartOrientation === 'top') {
      for (let col = this.data.selection.firstColIdx; col < this.data.selection.firstColIdx + this.data.selection.cols; col++) {
        for (let row = this.data.selection.firstRowIdx; row < this.data.selection.firstRowIdx + this.data.selection.rows; row++) {
          const rowValue = this.data.selection.dataItems.find(di => di.col === col && di.row === row).value;

          if (row > this.data.selection.firstRowIdx) {
            newSet[col] = newSet[col] + (typeof rowValue === 'string' ? 0 : rowValue);
          } else {
            newSet[col] = 0;
            this.labels.push(this.data.selection.dataItems.find(di => di.col === col && di.row === row).formattedValue);
          }
        }
      }

      this.datasets = newSet;

      this.chartDataReady = true;
      this.ref.markForCheck();
    } else {
      for (let row = this.data.selection.firstRowIdx; row < this.data.selection.firstRowIdx + this.data.selection.rows; row++) {
        // console.log('====ROW====', row);
        if ((isFirstRowHeaders && row > this.data.selection.firstRowIdx) || !isFirstRowHeaders) {
          for (let col = this.data.selection.firstColIdx; col < this.data.selection.firstColIdx + this.data.selection.cols; col++) {
            const rowData = this.data.selection.dataItems.find(di => di.row === row && di.col === col);

            if (rowData) {
              // console.log('rowData', rowData);

              const rowValue = rowData.value;

              if (col === this.data.selection.firstColIdx) {
                if (isFirstColHeaders) {
                  this.labels.push(this.data.selection.dataItems.find(di => di.row === row && di.col === col).formattedValue);
                } else {
                  if (!isFirstRowHeaders) {
                    this.labels.push(`Series ${row}`);
                  }
                }
              }

              if (!isFirstColHeaders || (isFirstColHeaders && col > this.data.selection.firstColIdx)) {
                if (isFirstRowHeaders) {
                  newSet.push(+rowValue);
                } else {
                  const previousValue =
                    newSet[row - this.data.selection.firstRowIdx - (isFirstRowHeaders ? 1 : 0)] === undefined
                      ? 0
                      : newSet[row - this.data.selection.firstRowIdx - (isFirstRowHeaders ? 1 : 0)];

                  const valueToAdd = previousValue + (Number.isInteger(+rowValue) ? +rowValue : 0);
                  // console.log('value to add', valueToAdd);

                  newSet[row - this.data.selection.firstRowIdx - (isFirstRowHeaders ? 1 : 0)] = valueToAdd;
                }
              }
            }
          }

          this.setRanges.push(
            `${this.toolsService.ColumnIndexToName(this.data.selection.firstColIdx)}${row}:${this.toolsService.ColumnIndexToName(
              this.data.selection.lastColIdx
            )}${row}`
          );
        } else {
          for (let col = this.data.selection.firstColIdx; col < this.data.selection.firstColIdx + this.data.selection.cols; col++) {
            const rowData = this.data.selection.dataItems.find(di => di.row === row && di.col === col);
            const rowValue = rowData.value;
            this.labels.push(rowValue);
          }
        }
      }
    }

    this.datasets = newSet;
    // console.log('datasets', this.datasets);
    // console.log('labels', this.labels);

    this.chartDataReady = true;
    this.ref.markForCheck();
  }

  private PrepareData(data = this.data) {
    const isFirstRowHeaders = this.IsFirstRowHeaders(data);
    if (!isFirstRowHeaders) {
      this.AddSeriesRowToData(data);
    }

    const isFirstColHeaders = this.IsFirstColHeaders(data);
    if (!isFirstColHeaders) {
      this.AddSeriesColumnToData(data);
    }

    // console.log('isFirstColHeaders', isFirstColHeaders);
    // console.log('isFirstRowHeaders', isFirstRowHeaders);

    return data;
  }

  /*
   **
   End of Combo Chart
   **
   */
}

export const ChartOptions = [
  {
    name: ChartType.AreaStacked,
    dataSet: 'multi',
  },
  {
    name: ChartType.Area,
    dataSet: 'multi',
  },
  {
    name: ChartType.AreaNormalized,
    dataSet: 'multi',
  },
  {
    name: ChartType.AreaLine,
    dataSet: 'multi',
  },
  {
    name: ChartType.BarNormalizedHorizontal,
    dataSet: 'multi',
  },
  {
    name: ChartType.BarStackedVertical,
    dataSet: 'multi',
  },
  {
    name: ChartType.BarGroupedHorizontal,
    dataSet: 'multi',
  },
  {
    name: ChartType.BarNormalizedVertical,
    dataSet: 'multi',
  },
  {
    name: ChartType.BarStackedHorizontal,
    dataSet: 'multi',
  },
  {
    name: ChartType.BarGroupedVertical,
    dataSet: 'multi',
  },
  {
    name: ChartType.BarHorizontal,
    dataSet: 'single',
  },
  {
    name: ChartType.BarVertical,
    dataSet: 'single',
  },
  {
    name: ChartType.HeatMap,
    dataSet: 'multi',
  },
  {
    name: ChartType.PieGrid,
    dataSet: 'single',
  },
  {
    name: ChartType.PieAdvanced,
    dataSet: 'single',
  },
  {
    name: ChartType.Pie,
    dataSet: 'single',
  },
  {
    name: ChartType.Gauge,
    dataSet: 'single',
  },
  {
    name: ChartType.PolarRadar,
    dataSet: 'multi',
  },
  {
    name: ChartType.LinearGauge,
    dataSet: '',
  },
  {
    name: ChartType.NumberCard,
    dataSet: 'single',
  },
  {
    name: ChartType.TreeMap,
    dataSet: 'single',
  },
  {
    name: ChartType.Funnel,
    dataSet: 'single',
  },
  {
    name: ChartType.SparkLine,
    dataSet: 'multi',
  },
  {
    name: ChartType.LineReferences,
    dataSet: 'multi',
  },
  {
    name: ChartType.Combo,
    dataSet: 'multi',
  },
];
