import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppInjector } from '../../../app-injector';
import { ApiMessagesService } from '../../../core/services/api-messages.service';
import { SnackerService } from '../../services/snacker.service';
import { ClientStorageService } from './../../../core/services/client-storage.service';
import { ToolsService } from './../../../core/services/tools.service';

export class ErrorInfo {
  public description: string;
  public data: any;
  public exceptionData: any;
}

@Component({
  selector: 'app-actionable-error',
  templateUrl: './actionable-error.component.html',
  styleUrls: ['./actionable-error.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'scale(0)', opacity: 0 }),
        animate('500ms', style({ transform: 'scale(1)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class ActionableErrorComponent implements OnInit, AfterViewInit {
  toolsService: ToolsService;
  messagesService: ApiMessagesService;
  public clientStorageService: ClientStorageService;
  userId = 0;
  userFullName = '';
  private snackerService: SnackerService;
  
  constructor(
    public dialogRef: MatDialogRef<ActionableErrorComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      descriptions: {
        errorType: string;
        messages: string[];
      }[];
      requestsData: any[];
      errors: any[];
    },
  ) {
    console.log(data);
  }
  
  get Location() {
    return window.location.href;
  }
  
  get Timestamp() {
    return new Date().toLocaleString();
  }
  
  ngAfterViewInit(): void {
    const injector = AppInjector.getInjector();
    
    if (injector) {
      this.clientStorageService = injector.get(ClientStorageService);
      this.userFullName = this.clientStorageService.getUserFullName();
      this.userId = this.clientStorageService.getUserId();
    }
    
  }
  
  ngOnInit() {
  
  }
  
  Close() {
    this.dialogRef.close();
  }
  
  CopyErrorData() {
    const injector = AppInjector.getInjector();
    this.snackerService = injector.get(SnackerService);
    this.toolsService = injector.get(ToolsService);
    
    this.toolsService.CopyToClipboard(JSON.stringify(this.data));
    this.snackerService.ShowMessageOnBottom('Error data copy to clipboard', 'content_copy', null, true);
  }
  
  SendErrorReport() {
    
    const injector = AppInjector.getInjector();
    this.snackerService = injector.get(SnackerService);
    this.messagesService = injector.get(ApiMessagesService);
    
    const message = JSON.stringify(this.data);
    const subject = `Leap Error Report [User ${ this.clientStorageService.getUserId() } - ${
      this.Timestamp
    } - ${ this.Location }]`;
    this.messagesService.SendITEmail(subject, message).subscribe();
    this.snackerService.ShowMessageOnBottom('Error report sent', 'send', null, true);
  }
  
  public RefreshPage() {
    location.reload();
  }
}
