import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clearText',
})
export class ClearTextPipe implements PipeTransform {
  public transform(input: string): string {
    if (!input) {
      return '';
    } else {
      const clearText = input.replace(/\b\w/g, (first) =>
        first.toLocaleUpperCase(),
      );
      return clearText.replace(/[_]/g, ' ').replace(/[.]/g, ' - ');
    }
  }
}
