import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BuilderService } from '../../../../core/builder/builder.service';
import { ProcessorService } from '../../../../core/molecular/services/processor.service';
import { WorkAreaService } from '../../../../workarea/workarea.service';
import { LeapXLEventType } from '../../../enums/leapxl-event-type.enum';
import { RepresentativeMoleculesType } from '../../../enums/representative-molecules-types.enum';
import { DragService } from '../../services/drag.service';
import { BaseMoleculeComponent } from '../base-molecule/base-molecule.component';

@Component({
  selector: 'app-slider-molecule',
  templateUrl: './slider-molecule.component.html',
  styleUrls: ['./slider-molecule.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SliderMoleculeComponent extends BaseMoleculeComponent implements OnInit, AfterViewInit, OnDestroy {
  MoleculeType = 'Representative';
  Type = RepresentativeMoleculesType.Slider;
  
  @ViewChild('sliderWrapper', { static: true })
  sliderWrapper: ElementRef;
  min: number;
  max: number;
  
  constructor(
    public builderService: BuilderService,
    public dragService: DragService,
    public workAreaService: WorkAreaService,
    public processorService: ProcessorService,
    bottomSheet: MatBottomSheet,
    public el: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
    super(bottomSheet, el, changeDetectorRef);
  }
  
  ngOnInit() {
    super.ngOnInit();
    if (!this.context.RunningMode) {
      this.DetachChangeDetection();
    }
    this.context.Type = RepresentativeMoleculesType.Slider;
    this.RefreshGridsterConfiguration();
    this.setColors();
    if (!this.context.Properties.minValue) {
      this.context.Properties.minValue = 0;
    }
    if (!this.context.Properties.maxValue) {
      this.context.Properties.maxValue = 10;
    }
    this.min = this.context.Properties.minValue;
    this.max = this.context.Properties.maxValue;
    if (!this.cobbleService.Cobble.running) {
      this.context.Value = (this.max + this.min) / 2;
    }
  }
  
  ngAfterViewInit() {
  }
  
  UpdateData() {
    this.setColors();
    this.min = this.context.Properties.minValue;
    this.max = this.context.Properties.maxValue;
  }
  
  setColors() {
    const { sliderColor } = this.context.Properties;
    this.toolsService.GenerateClassDynamically(
      `#gridsterItem-${ this.context.Id } .mat-accent .mat-slider-thumb, #gridsterItem-${ this.context.Id } .mat-accent .mat-slider-thumb-label, #gridsterItem-${ this.context.Id } .mat-accent .mat-slider-track-fill`,
      `background-color: ${ sliderColor }`,
    );
  }
  
  AttachEditorEventListeners() {
    this.renderer.listen(this.sliderWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, true);
    });
    this.renderer.listen(this.sliderWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, false);
    });
    this.renderer.listen(this.sliderWrapper.nativeElement, 'drop', (evt) => {
      this.DataDropped(evt);
    });
  }
  
  FireClickEvent(e: any) {
    this.FireRepresentativeMoleculeEvent(LeapXLEventType.Click);
  }
  
  FireValueChange(e: any) {
    this.FireRepresentativeMoleculeEvent(LeapXLEventType.ValueChange);
  }
}
