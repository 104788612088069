import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  IRepresentativeMolecule,
} from 'app/shared/representative-molecule/interfaces/representative-molecule.interface';
import { difference } from 'lodash-es';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, flatMap, map, startWith } from 'rxjs/operators';
import { BuilderService } from '../../core/builder/builder.service';
import { BusService } from '../../core/molecular/services/bus.service';
import { EventsService } from '../../core/molecular/services/events.service';
import { ProcessorService } from '../../core/molecular/services/processor.service';
import { ApiPropertiesService } from '../../core/services/api-properties.service';
import { DevToolsService } from '../../core/services/dev-tools.service';
import { EditorStateService } from '../../core/services/editor-state.service';
import { TemplateService } from '../../core/services/template.service';
import { ToolsService } from '../../core/services/tools.service';
import { DragType } from '../../shared/enums/drag-type.enum';
import { MoleculesType } from '../../shared/enums/molecules-type.enum';
import { ParticleTrackFlow } from '../../shared/models/particle-track-flow.model';
import { ActionMolecule } from '../../shared/representative-molecule/interfaces/action-molecules';
import { Bus } from '../../shared/representative-molecule/interfaces/bus';
import { DataElement } from '../../shared/representative-molecule/interfaces/data-element';
import { LeapXLEvent } from '../../shared/representative-molecule/interfaces/leapxl-event';
import { Particle } from '../../shared/representative-molecule/interfaces/particle';
import {
  RepresentativeMolecule,
} from '../../shared/representative-molecule/interfaces/representative-molecule';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { DragService } from '../../shared/representative-molecule/services/drag.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { DraggableWindowService, DraggableWindowType } from '../../shared/services/draggable-window.service';
import { FactoryParticleService } from '../../shared/services/factory-particle.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { SpreadsheetService } from '../../spreadsheet/spreadsheet.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-bus',
  templateUrl: './bus.component.html',
  styleUrls: ['./bus.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ transform: 'scale(0)', opacity: 0 }),
        animate('150ms', style({ transform: 'scale(1)', opacity: 1 }))]),
    ]),
    trigger('expand', [
      transition(':enter', [style({ height: '0px' }), animate('150ms', style({ height: '50px' }))]),
      transition(':leave', [style({ height: '50px' }), animate('200ms', style({ height: '0px' }))]),
    ]),
  ],
})
export class BusComponent implements OnInit, OnDestroy {
  @Input() bus: Bus;
  @Input() repMolecule: IRepresentativeMolecule;
  @Input() isDetached = false;
  @Output() dragHover = new EventEmitter<boolean>();
  @ViewChild(MatMenuTrigger, { static: false })
  contextMenu: MatMenuTrigger;
  hovering = false;
  activeParent = false;
  subscriptions: Subscription = new Subscription();
  inDebounce = null;
  closeSearches$: Subject<any> = new Subject<any>();
  hover = false;
  dragTimer = null;
  public noteSave = new Subject<any>();
  autoRefreshTypeEvent: { type: string, sourceId: string | null } = null;
  
  particleForm = this._formBuilder.group({
    particleGroup: '',
  });
  particles = [
    {
      type: 'Molecule Events',
      particles: [],
    },
    {
      type: 'Custom Events',
      particles: [],
    },
    {
      type: 'Data Action',
      particles: [],
    },
    {
      type: 'Behavior',
      particles: [],
    },
  ];
  particleGroupOptions: Observable<any[]>;
  onSearch = false;
  scrollValue: number;
  
  constructor(
    private propertiesService: ApiPropertiesService,
    private cobbleService: CobbleService,
    public dragService: DragService,
    private builderService: BuilderService,
    private spreadsheetService: SpreadsheetService,
    private draggableWindowService: DraggableWindowService,
    private busService: BusService,
    public toolsService: ToolsService,
    private eventsService: EventsService,
    public workAreaService: WorkAreaService,
    private processorService: ProcessorService,
    private communicationService: CommunicationService,
    private snackerService: SnackerService,
    private _formBuilder: FormBuilder,
    private templateService: TemplateService,
    private particleFactoryService: FactoryParticleService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private editorStateService: EditorStateService,
    public devToolsService: DevToolsService,
  ) {
    if (this.bus && this.repMolecule) {
    } else {
      const data = this.draggableWindowService.GetData();
      console.log(data);
      this.bus = data.bus;
      this.repMolecule = busService.Get(data.repMoleculeId);
    }
    
    this.noteSave
    .pipe(
      map(event => event.target.value),
      debounceTime(300),
      flatMap(search => of(search)
      .pipe(delay(50))),
    )
    .subscribe(value => {
      this.repMolecule.SaveProperty('buses', 'Note edited')
      .subscribe();
    });
  }
  
  ngOnInit() {
    if (!this.toolsService.RunningMode) {
      if (this.repMolecule) {
      } else {
        this.repMolecule = this.workAreaService.elementsSelected[0];
      }
      
      if (!this.repMolecule.Touched) {
        this.repMolecule.MarkAsTouched();
      }
      
      this.subscriptions.add(
        this.communicationService.Event.Editor.$ParticlesOrderParentEnabled.subscribe(enabled => {
          this.activeParent = enabled;
        }),
      );
      
      this.particleGroupOptions = this.particleForm.get('particleGroup')!.valueChanges.pipe(
        startWith(''),
        map(value => this._filterGroup(value || '')),
      );
      
      this.particles[0].particles = this.repMolecule.Events.map(e => {
        return { value: e, name: e, type: 'event', icon: 'flash_on' };
      });
      
      this.particles[1].particles = this.eventsService.CustomEvents.map(e => {
        return { value: e.EventName, name: e.EventName, type: 'customEvent', icon: 'flash_on' };
      });
      
      this.particles[2].particles = this.templateService.Libraries[0].sections
      .find(s => s.name === 'Data Action')
      .templates
      .map(t => {
        return { value: t.type, name: t.name, type: 'molecule', icon: t.icon, molecule: t };
      });
      
      this.particles[3].particles = this.templateService.Libraries[0].sections
      .find(s => s.name === 'Behavior')
      .templates
      .map(t => {
        return { value: t.type, name: t.name, type: 'molecule', icon: t.icon, molecule: t };
      });
    } else {
      this.subscriptions.add(
        this.communicationService.Event.System.DevTools.$ProcessPaused.subscribe(processMetadata => {
          console.log('=event=');
          this.repMolecule = null;
          this.bus = null;
          
          this.repMolecule = this.busService.Get(processMetadata.elementId.toString());
          this.bus = this.repMolecule.GetBus(this.devToolsService.GetBusTranslation(processMetadata.busId));
        }),
      );
      
      this.subscriptions.add(
        this.communicationService.Event.System.DevTools.$UpdateBus.subscribe(data => {
          console.log('=event=');
          this.repMolecule = null;
          this.bus = null;
          
          this.repMolecule = this.busService.Get(data.repMoleculeId.toString());
          this.bus = this.repMolecule.GetBus(data.busId);
        }),
      );
    }
    
    this.autoRefreshTypeEvent = this.repMolecule.AutoRefreshEvent();
  }
  
  _filter = (opt: any[], value: string): string[] => {
    const filterValue = value.toLowerCase();
    return opt.filter(item =>
      (item.name as any)
      .replaceAll(' ', '')
      .toLowerCase()
      .includes((filterValue as any).replaceAll(' ', '')),
    );
  };
  
  _filterGroup(value: string): any[] {
    if (value) {
      return this.particles
      .map(group => ({ type: group.type, particles: this._filter(group.particles, value) }))
      .filter(group => group.particles.length > 0);
    }
    
    return this.particles;
  }
  
  DragOver(e: any) {
    e.preventDefault();
    this.hovering = true;
  }
  
  DropData(positionIndex = 0) {
    this.repMolecule.MarkAsTouched();
    const processPanel = document.querySelector('.process-panel');
    this.scrollValue = this.workAreaService.windowHorizontalLayout ? processPanel.scrollLeft : processPanel.scrollTop;
    console.log('DropData');
    positionIndex = positionIndex + 1;
    this.hovering = false;
    // console.log('drag data', this.dragService.dragData);
    // console.log('molecule', this.repMolecule);
    // console.log('bus', this.bus);
    const repMoleculesToApply = this.workAreaService.elementsSelected.length > 1 ? this.workAreaService.elementsSelected : [this.repMolecule];
    
    if (this.dragService.dragData) {
      if (this.dragService.dragData.dragType !== DragType.Spreadsheet && this.dragService.dragData.dragType !== DragType.Molecule) {
        this.workAreaService.SaveLastUsedElement(
          this.dragService.dragData.dragType,
          this.dragService.dragData.data.EventSource,
          this.dragService.dragData.data.EventSource === 'Molecule'
            ? this.dragService.dragData.data.Identifier
            : this.dragService.dragData.data.EventName,
        );
      }
      
      repMoleculesToApply.forEach(repMolecule => {
        const moleculeBus = repMolecule.GetBus(this.bus.id) || repMolecule.GetBusEquals(this.bus);
        
        switch (this.dragService.dragData.dragType) {
          case DragType.Spreadsheet:
            const dataElement = new DataElement(this.spreadsheetService.SpreadSheetRangeSelected);
            dataElement.ParticleId = this.toolsService.GenerateGuid();
            dataElement.Reference = this.spreadsheetService.SpreadSheetRangeSelected.range;
            dataElement.ApplicationId = this.cobbleService.Cobble.id;
            dataElement.ParticleId = this.toolsService.GenerateGuid();
            dataElement.Touched = true;
            this.repMolecule.MarkAsTouched();
            // console.log(dataElement);
            
            this.particleFactoryService
            .CreateAndTranslateDataElements([dataElement], this.cobbleService.Cobble.id, DragType.Spreadsheet,
              this.spreadsheetService.editorDbMode)
            .then(dataElementsCreated => {
              dataElementsCreated.forEach(de => {
                moleculeBus.AddParticle(de, positionIndex);
              });
              
              this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanel.emit();
              
              setTimeout(() => {
                repMolecule.HighlightDatasourcesPath();
                repMolecule.HighlightEventsPath();
                repMolecule.HighlightViewsPath();
                repMolecule.RefreshDatasourceConnected();
                repMolecule.FireDataSourceBus();
              }, 300);
              repMolecule.SaveProperty('buses', 'Molecule Added')
              .subscribe();
            });
            
            break;
          case DragType.DataElement:
            const dataElements = this.dragService.dragData.data;
            dataElements.forEach(de => {
              de.ApplicationId = this.cobbleService.Cobble.id;
              de.Touched = true;
            });
            this.repMolecule.MarkAsTouched();
            
            this.particleFactoryService
            .CreateAndTranslateDataElements(dataElements, this.cobbleService.Cobble.id, DragType.DataElement,
              this.spreadsheetService.editorDbMode)
            .then(dataElementsCreated => {
              dataElementsCreated.forEach(de => {
                moleculeBus.AddParticle(de, positionIndex);
              });
              
              this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanel.emit();
              
              setTimeout(() => {
                repMolecule.HighlightDatasourcesPath();
                repMolecule.HighlightEventsPath();
                repMolecule.HighlightViewsPath();
                repMolecule.RefreshDatasourceConnected();
                repMolecule.FireDataSourceBus();
              }, 300);
              repMolecule.SaveProperty('buses', 'Molecule Added')
              .subscribe();
            });
            
            break;
          case DragType.Event:
            const newEvent = new LeapXLEvent(this.dragService.dragData.data);
            this.AddEventToRepMolecule(repMolecule, newEvent, positionIndex);
            break;
          case DragType.Molecule:
            switch (this.dragService.dragData.moleculeType) {
              case MoleculesType.CompoundMolecule:
                this.builderService.GetMolecule(repMolecule, this.dragService.dragData, null, [], [], 0,
                  false, false);
                break;
              default:
                this.AddMoleculeToRepresentativeMolecule(repMolecule, this.dragService.dragData,
                  positionIndex);
                break;
            }
            break;
          default:
            break;
        }
      });
      this.dragService.dragData.dragType = '';
      setTimeout(() => {
        this.communicationService.Event.Editor.$RecreateProcessBuses.emit();
        
        if (processPanel && this.scrollValue) {
          setTimeout(() => {
            this.workAreaService.windowHorizontalLayout
              ? (processPanel.scrollLeft += this.scrollValue)
              : (processPanel.scrollTop += this.scrollValue);
            this.scrollValue = 0;
          }, 200);
        }
      }, 100);
    }
  }
  
  AddEventToRepMolecule(repMolecule: IRepresentativeMolecule, event: LeapXLEvent, positionIndex = 0) {
    const bus = repMolecule.GetBus(this.bus.id) || repMolecule.GetBusEquals(this.bus);
    
    bus.AddParticle(event, positionIndex, repMolecule.Id.toString());
    
    if (this.IsEventLoop(event)) {
      this.DisplayLoopMessage(event, positionIndex);
      this.DisplayLoopWindow(event);
      bus.RemoveParticle(event.ParticleId);
    } else {
      repMolecule.SaveProperty('buses', 'Molecule Added')
      .subscribe();
    }
  }
  
  AddMoleculeToRepresentativeMolecule(repMolecule: IRepresentativeMolecule, moleculeTemplate: any, positionIndex = 0) {
    const bus = repMolecule.GetBus(this.bus.id) || repMolecule.GetBusEquals(this.bus);
    
    this.communicationService.Event.Editor.WorkArea.$MoleculeUsed.emit(moleculeTemplate);
    this.particleFactoryService.CreateActionMoleculeParticle(moleculeTemplate.type, repMolecule.Id)
    .then(actionMolecule => {
      // console.log('adding molecule to bus', actionMolecule);
      bus.AddParticle(actionMolecule, positionIndex);
      
      this.particleFactoryService.SetupDefaultsForMolecule(
        {
          type: actionMolecule.InternalMoleculeName,
        },
        repMolecule,
        null,
        true,
      );
      
      this.communicationService.Event.Editor.$ActionMoleculeAdded.emit({ actionMolecule, repMolecule });
      this.communicationService.Event.Editor.$RepresentativeMoleculeDetection.emit(
        { repMoleculeId: +repMolecule.Id, state: true });
      // repMolecule.SaveProperty('buses', 'Molecule Added').subscribe();
    });
  }
  
  ParticleDropPlaceholder(show: boolean, particleIdElement?: string) {
    if (show) {
      const element = document.getElementById(
        particleIdElement ? 'drop-placeholder-particle-' + particleIdElement : 'drop-placeholder-particle-fixed',
      );
      
      if (element) {
        element.classList.add('show-particle-drop-placeholder');
      }
    } else {
      const element = document.getElementById(
        particleIdElement ? 'drop-placeholder-particle-' + particleIdElement : 'drop-placeholder-particle-fixed',
      );
      
      setTimeout(() => {
        if (element) {
          element.classList.remove('show-particle-drop-placeholder');
        }
      }, 50);
    }
  }
  
  ReorderMolecules(particle: Particle) {
    console.log('reorder', particle);
    if (particle) {
      particle.Touched = true;
      this.repMolecule.MarkAsTouched();
    }
    this.repMolecule.SaveProperty('buses', 'Molecules Reordered')
    .subscribe();
  }
  
  onSortingParent(isParent: boolean) {
    this.communicationService.Event.Editor.$ParticlesOrderParentEnabled.emit(isParent);
  }
  
  onContextMenu(event: MouseEvent, particle: Particle, bus: Bus) {
    // console.log(event);
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    this.contextMenu.closeMenu();
    
    event.stopPropagation();
    event.preventDefault();
    
    const rect = (event.target as any).getBoundingClientRect();
    // console.log('rect', rect);
    
    this.workAreaService.contextMenuPosition.x = event.clientX - rect.left + 20 + 'px';
    this.workAreaService.contextMenuPosition.y = event.screenY - rect.top + 'px';
    this.workAreaService.contextMenuPosition.event = event;
    
    this.contextMenu.menuData = { particle, bus };
    this.contextMenu.openMenu();
    
    return false;
  }
  
  FlashRepresentativeMolecules(particle: Particle) {
    this.workAreaService.elementsSelectedShadowParticleAssociation[particle.ParticleId].forEach(
      (bp: {
        particleId: string;
        busId: string;
        repMolecule: RepresentativeMolecule;
        particles: Particle[]
      }) => {
        bp.repMolecule.Pulse();
        bp.repMolecule.PreviewParticleValue = {
          ParticleName: particle.GetName(),
          ParticleValues: particle.GetDisplayValues(),
        };
      },
    );
    
    this.communicationService.Event.System.Update.$RefreshWorkgroups.emit();
  }
  
  HidePreviewMoleculesValues() {
    this.workAreaService.elementsSelected.forEach(es => {
      es.PreviewParticleValue = null;
    });
    this.communicationService.Event.System.Update.$RefreshWorkgroups.emit();
  }
  
  ApplyParticleToAllSelected(particle: Particle, bus: Bus) {
    const particlesAssociation = this.workAreaService.elementsSelectedShadowParticleAssociation[particle.ParticleId] as {
      particleId: string;
      busId: string;
      repMolecule: RepresentativeMolecule;
      particles: Particle[];
    }[];
    const particleToApply = particlesAssociation[0].particles[0];
    const busToApply = particlesAssociation[0].repMolecule.GetBus(particlesAssociation[0].busId);
    const repMoleculeIdsWithoutParticle = difference(
      this.workAreaService.elementsSelected.map(es => es.Id),
      particlesAssociation.map(pa => pa.repMolecule)
      .map(rm => rm.Id),
    );
    
    repMoleculeIdsWithoutParticle.forEach(repMoleculeId => {
      const paExists = particlesAssociation.find(pa => pa.repMolecule.Id === repMoleculeId);
      let mainBus: Bus = null;
      const repMolecule = this.busService.Get(repMoleculeId.toString());
      
      if (paExists) {
        mainBus = paExists.repMolecule.GetBus(paExists.busId);
      } else {
        const sameBus = repMolecule.GetBusEquals(bus);
        
        mainBus = sameBus ? sameBus : busToApply.Clone();
        if (!sameBus) {
          mainBus.AssingId();
          mainBus.ClearParticles();
          
          if (!particleToApply.IsSameParticle(busToApply.FirstParticle())) {
            const firstParticle = busToApply.FirstParticle()
            .CloneAndAssignIds();
            firstParticle.AssingParticleId();
            firstParticle.AssingId();
            mainBus.AddParticle(firstParticle);
          }
          
          repMolecule.AddBus(mainBus);
        }
      }
      
      const newParticle = particleToApply.CloneAndAssignIds();
      
      if (newParticle.IsActionMolecule()) {
        (newParticle as ActionMolecule).MoleculeId = repMoleculeId;
      }
      
      if (newParticle.IsEvent() && mainBus.HasParticles() && mainBus.Particles.filter(
        p => p.IsSameParticle(newParticle)).length > 0) {
      } else {
        mainBus.AddParticle(newParticle);
      }
      
      repMolecule.SaveProperty('buses', `${ newParticle.ParticleType } Added`)
      .subscribe();
    });
    
    setTimeout(() => {
      this.communicationService.Event.Editor.$RecreateProcessBuses.emit(true);
    }, 100);
  }
  
  RemoveParticleFromAllElements(particle: Particle, bus: Bus) {
    this.workAreaService.RemoveParticleFromAllElements(particle.ParticleId, bus.id);
  }
  
  ParticleMouseUp() {
    this.repMolecule.SaveProperty('buses', 'Molecules Reordered')
    .subscribe();
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
  }
  
  ParticleMouseDown() {
    // console.log('down');
    this.dragService.dragginParticle = true;
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
  }
  
  ParticleSelected(value: any, positionIndex?: number) {
    console.log(value);
    
    if (this.workAreaService.elementsSelected.length > 1) {
      const repMoleculesToApply = this.workAreaService.elementsSelected.length > 1 ? this.workAreaService.elementsSelected : [this.repMolecule];
      
      repMoleculesToApply.forEach(repMolecule => {
        if (this.particles[0].particles.find(p => p.value === value)) {
          // event
          const event = this.repMolecule.GenerateEvent(value);
          this.AddEventToRepMolecule(repMolecule, event, positionIndex);
        } else if (this.particles[1].particles.find(p => p.value === value)) {
          // custom event
          
          const customEvent = this.eventsService.CustomEvents.find(event => event.EventName === value);
          const newEvent = new LeapXLEvent({
            particleId: this.toolsService.GenerateGuid(),
            id: this.toolsService.GenerateGuid(),
            sourceId: customEvent.SourceId,
            eventType: customEvent.EventType,
            eventSource: customEvent.EventSource,
            eventName: customEvent.EventName,
          });
          this.AddEventToRepMolecule(repMolecule, newEvent, positionIndex);
        } else if (this.particles[2].particles.concat(this.particles[3].particles)
        .find(p => p.value === value)) {
          // molecule
          const moleculeTemplate = this.particles[2].particles.concat(this.particles[3].particles)
          .find(p => p.value === value).molecule;
          this.AddMoleculeToRepresentativeMolecule(repMolecule, moleculeTemplate, positionIndex);
        }
      });
      
      setTimeout(() => {
        this.communicationService.Event.Editor.$RecreateProcessBuses.emit();
      }, 100);
    } else {
      let particle = null;
      this.repMolecule.MarkAsTouched();
      
      if (this.particles[0].particles.find(p => p.value === value)) {
        // event
        particle = this.repMolecule.GenerateEvent(value);
        
        this.bus.AddParticle(particle, positionIndex);
        
        if (this.IsEventLoop(particle)) {
          this.DisplayLoopMessage(particle, positionIndex);
          this.DisplayLoopWindow(particle);
          this.bus.RemoveParticle(particle.ParticleId);
          return;
        }
        this.workAreaService.SaveLastUsedElement(particle.ParticleType, particle.EventSource,
          particle.Identifier);
      } else if (this.particles[1].particles.find(p => p.value === value)) {
        // custom event
        
        const customEvent = this.eventsService.CustomEvents.find(event => event.EventName === value);
        particle = new LeapXLEvent({
          particleId: this.toolsService.GenerateGuid(),
          id: this.toolsService.GenerateGuid(),
          sourceId: customEvent.SourceId,
          eventType: customEvent.EventType,
          eventSource: customEvent.EventSource,
          eventName: customEvent.EventName,
        });
        
        this.bus.AddParticle(particle, positionIndex);
        if (this.IsEventLoop(particle)) {
          this.DisplayLoopMessage(particle, positionIndex);
          this.DisplayLoopWindow(particle);
          this.bus.RemoveParticle(particle.ParticleId);
          return;
        }
        this.workAreaService.SaveLastUsedElement(particle.ParticleType, particle.EventSource,
          particle.EventName);
      } else if (this.particles[2].particles.concat(this.particles[3].particles)
      .find(p => p.value === value)) {
        // molecule
        
        this.communicationService.Event.Editor.WorkArea.$MoleculeUsed.emit(
          this.particles[2].particles.concat(this.particles[3].particles)
          .find(p => p.value === value).molecule,
        );
        this.particleFactoryService.CreateActionMoleculeParticle(value, this.repMolecule.Id)
        .then(actionMolecule => {
          particle = actionMolecule;
          this.bus.AddParticle(particle, positionIndex);
          this.repMolecule.SaveProperty('buses', 'Event added')
          .subscribe();
        });
      }
      
      if (particle) {
        this.repMolecule.SaveProperty('buses', 'Event added')
        .subscribe();
      }
    }
    
    this.particleForm.controls.particleGroup.reset();
    this.onSearch = false;
  }
  
  DisplayLoopMessage(particle: LeapXLEvent, positionIndex: number) {
    this.snackerService.ShowMessageOnBottom(`Event add prevented to avoid loop`, 'cycle', 6000, false, {
      text: 'Add Anyways',
      icon: 'add_circle',
      callback: () => {
        this.bus.AddParticle(particle, positionIndex, this.repMolecule.Id.toString());
        this.busService.Get(particle.SourceId)
        .SaveProperty('buses', 'Molecule Added')
        .subscribe();
        this.snackerService.ShowMessageOnBottom('Event added', 'add_circle');
      },
    });
  }
  
  IsEventLoop(particle: LeapXLEvent) {
    const trackFlow = new ParticleTrackFlow(particle, this.bus);
    trackFlow.TrackDown();
    return !!trackFlow.TrackDownResult.LoopEvent;
  }
  
  DisplayLoopWindow(particle: LeapXLEvent) {
    const trackFlow = new ParticleTrackFlow(particle, this.bus);
    trackFlow.TrackDown();
    this.workAreaService.loopTrack = trackFlow;
    
    this.draggableWindowService.OpenDraggableWindow(
      'Loops Detected',
      DraggableWindowType.Loop,
      null,
      trackFlow.TrackDownResult,
    );
  }
  
  ngOnDestroy(): void {
    console.log('destroy');
    this.subscriptions.unsubscribe();
    this.devToolsService.ContinuePausedProcess();
  }
  
  Throttle(func, delay, context, args) {
    clearTimeout(this.inDebounce);
    this.inDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }
  
  dragOver() {
    if (
      this.dragService.dragginEvent ||
      this.dragService.dragginMolecule ||
      this.dragService.dragginDataElement ||
      this.dragService.dragginRepMolecule
    ) {
      this.hover = true;
      this.dragHover.emit(true);
      
      if (this.workAreaService.windowHorizontalLayout) {
        const elements = this.elementRef.nativeElement.querySelectorAll('.expanded-drop-placeholder');
        const element: any = elements[elements.length - 1];
        if (element) {
          const busHeight = this.elementRef.nativeElement.querySelector('.particles-list').clientHeight;
          const elementOffsetTop = element.offsetTop;
          this.renderer.setStyle(element, 'height', `${ busHeight - elementOffsetTop - 8 }px`);
          this.renderer.setStyle(element, 'font-size', 'unset');
          this.renderer.addClass(element.querySelector('.drop-data'), 'align-center');
        }
      }
      
      if (this.dragTimer) {
        clearTimeout(this.dragTimer);
        this.dragTimer = null;
      }
      
      this.dragTimer = setTimeout(() => {
        this.hover = false;
        this.dragHover.emit(false);
        this.dragTimer = null;
      }, 100);
    }
  }
  
  click(event: MouseEvent) {
    console.log(event);
  }
}
