import { Injectable, OnInit } from '@angular/core';
import { BuilderService } from '../../core/builder/builder.service';
import { BusService } from '../../core/molecular/services/bus.service';
import { EventsService } from '../../core/molecular/services/events.service';
import { ApiMoleculesService } from '../../core/services/api-molecules.service';
import { ApiPropertiesService } from '../../core/services/api-properties.service';
import { ToolsService } from '../../core/services/tools.service';
import { PropertyVersioningDto } from '../../shared/dtos/versioning-dto';
import { MoleculesType } from '../../shared/enums/molecules-type.enum';
import { RepresentativeMoleculesType } from '../../shared/enums/representative-molecules-types.enum';
import { RepresentativeMolecule } from '../../shared/representative-molecule/interfaces/representative-molecule';
import { IRepresentativeMolecule } from '../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { ISmartTemplateSelection } from '../interfaces/smart-template-selection.interface';
import { ISmartTemplate } from '../interfaces/smart-template.interface';
import { WorkAreaService } from '../workarea.service';

@Injectable({
  providedIn: 'root',
})
export class SmartTemplatesService implements OnInit {
  
  SmartTemplateBuilding = false;
  
  Location = {
    NorthWest: 'nw',
    North: 'n',
    West: 'w',
    NorthEast: 'ne',
    Random: 'random',
    East: 'e',
    SouthWest: 'sw',
    SouthEast: 'se',
    South: 's',
    View: 'view',
    Original: 'original',
  };
  
  LocationsTaken = {
    NorthWest: false,
    North: false,
    West: false,
    NorthEast: false,
    Random: false,
    East: false,
    SouthWest: false,
    SouthEast: false,
    South: false,
    Original: false,
  };
  locationConfigs: ISmartTemplateSelection[] = [];
  viewLocationConfigs: ISmartTemplateSelection[] = [];
  lastRandomPosition = null;
  footerYPosition = null;
  viewsCreated = [];
  creationPermission = false;
  autoOpen = false;
  lastRepMoleculeNavigationId = 0;
  
  navigationViewId: number = null;
  templatesAdded: ISmartTemplate[] = [];
  isolatedTemplates: ISmartTemplate[] = [];
  viewTemplates: ISmartTemplate[] = [];
  navigationTemplate: ISmartTemplate = null;
  isolatedElementsViewId = null;
  firstViewTemplateViewId = null;
  navigationRepMoleculeParent: IRepresentativeMolecule = null;
  navigationViewSpecific = true;
  
  constructor(private builderService: BuilderService,
              private cobbleService: CobbleService,
              private moleculesService: ApiMoleculesService,
              private busService: BusService,
              private workAreaService: WorkAreaService,
              private propertiesService: ApiPropertiesService,
              private communicationService: CommunicationService,
              private eventsService: EventsService,
              private toolsService: ToolsService) {
  }
  
  ngOnInit(): void {
  }
  
  GenerateAppFromSmartTemplateSelection(templateConfig: ISmartTemplateSelection[]) {
    this.SmartTemplateBuilding = true;
    console.log('template config', templateConfig);
    this.templatesAdded = templateConfig.map(tc => tc.template);
    this.isolatedTemplates = templateConfig.map(tc => tc.template).filter(t => t.location !== 'view');
    this.viewTemplates = templateConfig.map(tc => tc.template).filter(t => t.location === 'view');
    this.navigationTemplate = templateConfig.map(tc => tc.template).find(t => !!t.component.find(c => c.buses && !!c.buses.find(b => b.Particles.find(p => p.InternalMoleculeName === 'NavigateViewMolecule'))));
    
    Object.keys(this.Location).forEach((key, index) => {
      const location = this.Location[key];
      const locationConfig = templateConfig.filter(tc => tc.template.location === location);
      
      if (locationConfig) {
        if (location === this.Location.View) {
          this.viewLocationConfigs = locationConfig;
        } else {
          this.locationConfigs = this.locationConfigs.concat(locationConfig);
        }
      }
      
    });
    
    this.ProcessComponent();
  }
  
  GenerateView(viewName = '', ignoreView = false): {
    id: number,
    name: string,
    locked: boolean,
    squish: boolean
  } {
    const viewId =
      Math.max(...this.cobbleService.Cobble.properties.views.map((v) => v.id)) +
      1;
    const newView = {
      id: viewId,
      name: viewName === '' ? `View ${ viewId }` : viewName,
      locked: false,
      squish: true,
    };
    
    if (!ignoreView) {
      this.viewsCreated.push(newView);
    }
    this.cobbleService.Cobble.properties.views.unshift(newView);
    return newView;
  }
  
  ProcessComponent() {
    let lc: ISmartTemplateSelection = null;
    
    if (this.locationConfigs.length > 0) {
      lc = this.locationConfigs.shift();
    } else if (this.viewLocationConfigs.length > 0) {
      lc = this.viewLocationConfigs.shift();
      if (this.busService.GetViewWorkgroupsMolecules(this.cobbleService.Cobble, this.workAreaService.ActualView.id).length === 0) {
        // drop on actual view
      } else {
        const view = this.GenerateView(lc.template.name);
        
        if (this.firstViewTemplateViewId) {
        
        } else {
          this.firstViewTemplateViewId = view.id;
        }
        
        this.communicationService.Event.Editor.Views.$SwitchView.emit(view.id);
      }
    }
    
    if (lc) {
      
      // set lock for navigation view
      if (this.navigationTemplate && lc.template.id === this.navigationTemplate.id) {
        
        if (this.isolatedElementsViewId && this.isolatedElementsViewId === this.workAreaService.ActualView.id) {
          const view = this.GenerateView(lc.template.name, true);
          this.communicationService.Event.Editor.Views.$SwitchView.emit(view.id);
        }
        
        if (this.viewTemplates.length > 1) {
          const actualView = this.cobbleService.Cobble.properties.views.find(v => v.id === this.workAreaService.ActualView.id);
          actualView.locked = true;
        }
      }
      
      // isolated elements
      if (this.navigationTemplate && this.isolatedTemplates.map(t => t.id).includes(lc.template.id) && lc.template.id !== this.navigationTemplate.id) {
        if (this.isolatedElementsViewId) {
          if (this.isolatedElementsViewId !== this.workAreaService.ActualView.id) {
            this.communicationService.Event.Editor.Views.$SwitchView.emit(this.isolatedElementsViewId);
          }
        } else {
          
          // create isolated view
          if (this.navigationTemplate && this.viewTemplates.length > 0) {
            const isolatedView = this.GenerateView(lc.template.name);
            this.isolatedElementsViewId = isolatedView.id;
            this.communicationService.Event.Editor.Views.$SwitchView.emit(isolatedView.id);
          }
        }
        
        if (this.navigationViewId && this.workAreaService.ActualView.id === this.navigationViewId) {
          this.navigationViewSpecific = false;
        }
      }
      
      lc.template.component[0].type = MoleculesType.Cobblet;
      lc.template.component[0].moleculeType = MoleculesType.Cobblet;
      this.CalcPositionAndAddComponent(lc.template.location, lc.template.component, lc.template);
    } else {
      this.SetNavigation();
    }
  }
  
  CalcPositionAndAddComponent(location, app: any[], template: ISmartTemplate) {
    if (app.length > 1) {
      this.builderService.GetMolecule(
        new RepresentativeMolecule(this.cobbleService.Cobble),
        app[0],
        this.ObtainPositionForLocation(location, app),
        [],
        [{
          name: 'templateKeepDatasources',
          value: template.keepDataSource === true,
          path: '',
        }],
        0,
        false,
        true,
      ).then((newElement: IRepresentativeMolecule) => {
        this.eventsService.CreateEventsTable();
        setTimeout(() => {
          this.SmartTemplateComponentProcessed();
        }, 300);
      });
    } else {
      this.SmartTemplateComponentProcessed();
    }
  }
  
  SmartTemplateComponentProcessed() {
    
    if (this.locationConfigs.length === 0 && this.viewLocationConfigs.length === 0) {
      this.SetNavigation();
      this.communicationService.Event.Editor.EventsTree.$RefreshCustomEvents.emit();
      console.log('smart template completed');
    } else {
      this.ProcessComponent();
    }
  }
  
  ComparePosition(repMolecule1: IRepresentativeMolecule, repMolecule2: IRepresentativeMolecule) {
    if ((repMolecule1.ResponsiveProperties().x + repMolecule1.ResponsiveProperties().y) < (repMolecule2.ResponsiveProperties().x + repMolecule2.ResponsiveProperties().y)) {
      return -1;
    }
    if ((repMolecule1.ResponsiveProperties().x + repMolecule1.ResponsiveProperties().y) >= (repMolecule2.ResponsiveProperties().x + repMolecule2.ResponsiveProperties().y)) {
      return 1;
    }
    // a must be equal to b
    return 0;
  }
  
  SetNavigation() {
    this.viewsCreated.unshift(this.cobbleService.Cobble.properties.views.reduce((prev, current) => (prev.id < current.id ? prev : current)));
    
    if (this.viewsCreated.length === 0) {
      this.ClearSmartTemplateCreation();
    } else {
      let viewParentMoleculeGrowth = 'y';
      const propertiesToSave = [];
      let navigationRepMoleculeParent: IRepresentativeMolecule = null;
      let navigateMolecules = this.busService.GetChannelArray().filter(repMolecule => repMolecule.CreationFromTemplate && repMolecule.GetMolecules('NavigateViewMolecule').length > 0).reverse();
      
      // region identify navigation placeholder
      const getNavigationPlaceholderMolecule = navigateMolecules.find(m => !!m.GetMolecules('NavigateViewMolecule').find(am => am.Rule));
      
      if (getNavigationPlaceholderMolecule) {
        getNavigationPlaceholderMolecule.GetMolecules('NavigateViewMolecule')[0].Rule = {};
        getNavigationPlaceholderMolecule.GetMolecules('NavigateViewMolecule')[0].Touched = false;
        navigateMolecules = navigateMolecules.filter(m => m.ParentId === getNavigationPlaceholderMolecule.ParentId);
      }
      // endregion
      
      console.log('getNavigationPlaceholderMolecule', getNavigationPlaceholderMolecule);
      if (navigateMolecules.length > 0) {
        const parents = [];
        
        // region order navigation molecules
        const orderedNavigateMolecules = navigateMolecules.sort((repMolecule1, repMolecule2) => {
            if ((repMolecule1.ResponsiveProperties().x + repMolecule1.ResponsiveProperties().y) < (repMolecule2.ResponsiveProperties().x + repMolecule2.ResponsiveProperties().y)) {
              return -1;
            }
            if ((repMolecule1.ResponsiveProperties().x + repMolecule1.ResponsiveProperties().y) > (repMolecule2.ResponsiveProperties().x + repMolecule2.ResponsiveProperties().y)) {
              return 1;
            }
            return 0;
          },
        );
        // endregion
        
        // region get parent wg
        orderedNavigateMolecules.forEach(repMolecule => {
          if (!parents.includes(repMolecule.ParentId)) {
            parents.push(repMolecule.ParentId);
          }
        });
        
        if (parents.length > 0) {
          navigationRepMoleculeParent = this.busService.Get(parents[0]);
          viewParentMoleculeGrowth = navigationRepMoleculeParent.ResponsiveProperties().cols > navigationRepMoleculeParent.ResponsiveProperties().rows ? 'x' : 'y';
        }
        // endregion
        
        const viewsToBeGenerated = [];
        this.navigationViewId = navigationRepMoleculeParent.Properties.view;
        
        
        this.viewsCreated.forEach(view => {
          
          const viewContainsElements = this.busService.GetViewMolecules(this.cobbleService.Cobble, view.id).length > 0;
          
          if (viewContainsElements && !(this.navigationViewId && view.id === this.navigationViewId && this.navigationViewSpecific)) {
            const repMolecule = orderedNavigateMolecules.shift();
            
            // if repmolecule exists add view
            if (repMolecule) {
              const viewMolecule = repMolecule.GetMolecules('NavigateViewMolecule').find(m => !m.Touched);
              
              if (viewMolecule) {
                this.lastRepMoleculeNavigationId = repMolecule.Id;
                
                repMolecule.Properties.textToDisplay = view.name;
                viewMolecule.Rule = {
                  view: view.id,
                };
                viewMolecule.Touched = true;
                
                propertiesToSave.push(
                  new PropertyVersioningDto({
                    elementId: repMolecule.Id.toString(),
                    property: 'textToDisplay',
                    value: view.name,
                    path: 'properties',
                    change: 'Text to display',
                    name: repMolecule.Properties.name,
                  }),
                );
                propertiesToSave.push(
                  new PropertyVersioningDto({
                    elementId: repMolecule.Id.toString(),
                    property: 'name',
                    value: view.name + ` ${ repMolecule.Type }`,
                    path: 'properties',
                    change: 'Element name',
                    name: repMolecule.Properties.name,
                  }),
                );
                propertiesToSave.push(
                  new PropertyVersioningDto({
                    elementId: repMolecule.Id.toString(),
                    property: 'buses',
                    value: repMolecule.Buses,
                    path: '',
                    change: 'Set smart navigation',
                    name: repMolecule.Properties.name,
                  }),
                );
              }
            }
            // create new navigation rep molecule and set navigation
            else {
              viewsToBeGenerated.push(view);
            }
          }
        });
        
        this.navigationRepMoleculeParent = navigationRepMoleculeParent;
        this.GenerateRepresentativeNavigationMolecules(viewsToBeGenerated.reverse(), navigationRepMoleculeParent, viewParentMoleculeGrowth, navigateMolecules[0]);
        this.propertiesService.SaveProperties(propertiesToSave).subscribe();
      } else {
        this.ClearSmartTemplateCreation();
      }
    }
  }
  
  GenerateRepresentativeNavigationMolecules(viewsToBeGenerated: any[], navigationRepMoleculeParent: IRepresentativeMolecule, viewParentMoleculeGrowth: string, sourceNavigationRepMolecule: IRepresentativeMolecule, externalProcess = false, startPositioningOnSourceNavigateMolecule = true, propertiesToSave = []) {
    console.log('GenerateRepresentativeNavigationMolecules');
    return new Promise(resolve => {
      if (viewsToBeGenerated.length === 0) {
        this.propertiesService.SaveProperties(propertiesToSave).subscribe();
        
        if (!externalProcess) {
          this.ClearSmartTemplateCreation();
          this.communicationService.Event.Editor.Views.$SwitchView.emit(this.cobbleService.Cobble.properties.views.reduce((prev, current) => (prev.id < current.id ? prev : current)).id);
        } else {
          this.Clear();
        }
        
        return resolve(null);
      } else {
        propertiesToSave = [];
      }
      const view = viewsToBeGenerated.pop();
      
      if (this.lastRepMoleculeNavigationId === 0) {
        if (sourceNavigationRepMolecule) {
          this.lastRepMoleculeNavigationId = sourceNavigationRepMolecule.Id;
        } else {
          this.propertiesService.SaveProperties(propertiesToSave).subscribe();
          if (!externalProcess) {
            this.ClearSmartTemplateCreation();
            this.communicationService.Event.Editor.Views.$SwitchView.emit(this.cobbleService.Cobble.properties.views.reduce((prev, current) => (prev.id < current.id ? prev : current)).id);
          } else {
            this.Clear();
          }
          return resolve(null);
        }
      }
      
      if (this.lastRepMoleculeNavigationId > 0 && navigationRepMoleculeParent) {
        const lastRepMoleculeNavigation = this.busService.Get(this.lastRepMoleculeNavigationId.toString());
        
        const newRepMolPositionDesktop = {
          x: viewParentMoleculeGrowth === 'x' ? lastRepMoleculeNavigation.Properties.responsive.desktop.x + lastRepMoleculeNavigation.Properties.responsive.desktop.cols + 2 : lastRepMoleculeNavigation.Properties.responsive.desktop.x,
          y: viewParentMoleculeGrowth === 'x' ? lastRepMoleculeNavigation.Properties.responsive.desktop.y : lastRepMoleculeNavigation.Properties.responsive.desktop.y + lastRepMoleculeNavigation.Properties.responsive.desktop.rows + 2,
        };
        
        const newRepMolPositionMobile = {
          x: viewParentMoleculeGrowth === 'x' ? lastRepMoleculeNavigation.Properties.responsive.smartphone.x + lastRepMoleculeNavigation.Properties.responsive.smartphone.cols + 2 : lastRepMoleculeNavigation.Properties.responsive.smartphone.x,
          y: viewParentMoleculeGrowth === 'x' ? lastRepMoleculeNavigation.Properties.responsive.smartphone.y : lastRepMoleculeNavigation.Properties.responsive.smartphone.y + lastRepMoleculeNavigation.Properties.responsive.smartphone.rows + 2,
        };
        
        if (viewParentMoleculeGrowth === 'x') {
          // desktop
          if (lastRepMoleculeNavigation.Properties.responsive.desktop.x + lastRepMoleculeNavigation.Properties.responsive.desktop.cols + 4 + lastRepMoleculeNavigation.Properties.responsive.desktop.cols >
            navigationRepMoleculeParent.Properties.responsive.desktop.cols) {
            
            navigationRepMoleculeParent.Properties.responsive.desktop.cols = navigationRepMoleculeParent.Properties.responsive.desktop.cols + 4 + lastRepMoleculeNavigation.Properties.responsive.desktop.cols;
            navigationRepMoleculeParent.Properties.responsive.desktop.colsQty = navigationRepMoleculeParent.Properties.responsive.desktop.cols;
            
            propertiesToSave.push(
              new PropertyVersioningDto({
                elementId: navigationRepMoleculeParent.Id.toString(),
                property: 'cols',
                value: navigationRepMoleculeParent.Properties.responsive.desktop.cols,
                path: `properties.responsive.desktop`,
                change: 'Expand right',
                name: navigationRepMoleculeParent.Properties.name,
              }),
            );
            propertiesToSave.push(
              new PropertyVersioningDto({
                elementId: navigationRepMoleculeParent.Id.toString(),
                property: 'colsQty',
                value: navigationRepMoleculeParent.Properties.responsive.desktop.cols,
                path: `properties.responsive.desktop`,
                change: 'WG Width',
                name: navigationRepMoleculeParent.Properties.name,
              }),
            );
            this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(navigationRepMoleculeParent);
          }
          
          // mobile
          if (lastRepMoleculeNavigation.Properties.responsive.smartphone.x + lastRepMoleculeNavigation.Properties.responsive.smartphone.cols + 4 + lastRepMoleculeNavigation.Properties.responsive.smartphone.cols >
            navigationRepMoleculeParent.Properties.responsive.smartphone.cols) {
            
            navigationRepMoleculeParent.Properties.responsive.smartphone.cols = navigationRepMoleculeParent.Properties.responsive.smartphone.cols + 4 + lastRepMoleculeNavigation.Properties.responsive.smartphone.cols;
            navigationRepMoleculeParent.Properties.responsive.smartphone.colsQty = navigationRepMoleculeParent.Properties.responsive.smartphone.cols;
            
            propertiesToSave.push(
              new PropertyVersioningDto({
                elementId: navigationRepMoleculeParent.Id.toString(),
                property: 'cols',
                value: navigationRepMoleculeParent.Properties.responsive.smartphone.cols,
                path: `properties.responsive.smartphone`,
                change: 'Expand right',
                name: navigationRepMoleculeParent.Properties.name,
              }),
            );
            propertiesToSave.push(
              new PropertyVersioningDto({
                elementId: navigationRepMoleculeParent.Id.toString(),
                property: 'colsQty',
                value: navigationRepMoleculeParent.Properties.responsive.smartphone.cols,
                path: `properties.responsive.smartphone`,
                change: 'WG Width',
                name: navigationRepMoleculeParent.Properties.name,
              }),
            );
            this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(navigationRepMoleculeParent);
          }
          
        } else {
          
          // desktop
          if (lastRepMoleculeNavigation.Properties.responsive.desktop.y + lastRepMoleculeNavigation.Properties.responsive.desktop.rows + 4 + lastRepMoleculeNavigation.Properties.responsive.desktop.rows >
            navigationRepMoleculeParent.Properties.responsive.desktop.rows) {
            navigationRepMoleculeParent.Properties.responsive.desktop.rows = navigationRepMoleculeParent.Properties.responsive.desktop.rows + 8 + lastRepMoleculeNavigation.Properties.responsive.desktop.rows;
            navigationRepMoleculeParent.Properties.responsive.desktop.rowsQty = navigationRepMoleculeParent.Properties.responsive.desktop.rows;
            propertiesToSave.push(
              new PropertyVersioningDto({
                elementId: navigationRepMoleculeParent.Id.toString(),
                property: 'rows',
                value: navigationRepMoleculeParent.Properties.responsive.desktop.rows,
                path: `properties.responsive.desktop`,
                change: 'Expand bottom',
                name: navigationRepMoleculeParent.Properties.name,
              }),
            );
            
            propertiesToSave.push(
              new PropertyVersioningDto({
                elementId: navigationRepMoleculeParent.Id.toString(),
                property: 'rowsQty',
                value: navigationRepMoleculeParent.Properties.responsive.desktop.rows,
                path: `properties.responsive.desktop`,
                change: 'WG Height',
                name: navigationRepMoleculeParent.Properties.name,
              }),
            );
            this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(navigationRepMoleculeParent);
          }
          
          // mobile
          if (lastRepMoleculeNavigation.Properties.responsive.smartphone.y + lastRepMoleculeNavigation.Properties.responsive.smartphone.rows + 4 + lastRepMoleculeNavigation.Properties.responsive.smartphone.rows >
            navigationRepMoleculeParent.Properties.responsive.smartphone.rows) {
            navigationRepMoleculeParent.Properties.responsive.smartphone.rows = navigationRepMoleculeParent.Properties.responsive.smartphone.rows + 8 + lastRepMoleculeNavigation.Properties.responsive.smartphone.rows;
            navigationRepMoleculeParent.Properties.responsive.smartphone.rowsQty = navigationRepMoleculeParent.Properties.responsive.smartphone.rows;
            propertiesToSave.push(
              new PropertyVersioningDto({
                elementId: navigationRepMoleculeParent.Id.toString(),
                property: 'rows',
                value: navigationRepMoleculeParent.Properties.responsive.smartphone.rows,
                path: `properties.responsive.smartphone`,
                change: 'Expand bottom',
                name: navigationRepMoleculeParent.Properties.name,
              }),
            );
            
            propertiesToSave.push(
              new PropertyVersioningDto({
                elementId: navigationRepMoleculeParent.Id.toString(),
                property: 'rowsQty',
                value: navigationRepMoleculeParent.Properties.responsive.smartphone.rows,
                path: `properties.responsive.smartphone`,
                change: 'WG Height',
                name: navigationRepMoleculeParent.Properties.name,
              }),
            );
            this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(navigationRepMoleculeParent);
          }
        }
        
        this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(navigationRepMoleculeParent.Id);
        
        // region duplicate rep molecule
        
        const moleculeTypeId = this.workAreaService.libraryMoleculeIds[
          `${ lastRepMoleculeNavigation.Type }-Representative`
          ];
        const clonedBuses = [];
        
        lastRepMoleculeNavigation.Buses.forEach(b => clonedBuses.push(b.Clone()));
        
        if (externalProcess && startPositioningOnSourceNavigateMolecule && lastRepMoleculeNavigation.Id === sourceNavigationRepMolecule.Id) {
          newRepMolPositionDesktop.x = lastRepMoleculeNavigation.ResponsiveProperties().x;
          newRepMolPositionDesktop.y = lastRepMoleculeNavigation.ResponsiveProperties().y;
        }
        
        const properties = [
          {
            name: 'properties',
            value: lastRepMoleculeNavigation.Properties,
            path: '',
          },
          {
            name: 'styleMetadata',
            value: lastRepMoleculeNavigation.StyleMetadata,
            path: '',
          },
          {
            name: 'textToDisplay',
            value: view.name,
            path: 'properties',
          },
          {
            name: 'buses',
            value: clonedBuses,
            path: '',
          },
          {
            name: 'x',
            value: newRepMolPositionDesktop.x,
            path: `properties.responsive.desktop`,
          },
          {
            name: 'y',
            value: newRepMolPositionDesktop.y,
            path: `properties.responsive.desktop`,
          },
          {
            name: 'x',
            value: newRepMolPositionMobile.x,
            path: `properties.responsive.smartphone`,
          },
          {
            name: 'y',
            value: newRepMolPositionMobile.y,
            path: `properties.responsive.smartphone`,
          },
        ];
        
        this.builderService.GetMolecule(
          navigationRepMoleculeParent,
          {
            id: moleculeTypeId,
            type: lastRepMoleculeNavigation.Type,
            moleculeType: lastRepMoleculeNavigation.MoleculeType,
          },
          null,
          properties,
          [],
          0,
          false,
          true,
        ).then(async(clonedMolecule: IRepresentativeMolecule) => {
          
          this.lastRepMoleculeNavigationId = clonedMolecule.Id;
          
          if (clonedMolecule.ContainsEvents()) {
            this.toolsService.ReplaceValueInObject(clonedMolecule.Buses, +lastRepMoleculeNavigation.Id, +clonedMolecule.Id);
            this.toolsService.ReplaceValueInObject(clonedMolecule.Buses, lastRepMoleculeNavigation.Id.toString(), clonedMolecule.Id.toString());
            this.toolsService.SetGuidToPropertyInObject(clonedMolecule.Buses, 'id');
          }
          
          const clonedViewMolecule = clonedMolecule.GetMolecules('NavigateViewMolecule')[0];
          clonedViewMolecule.Rule = {
            view: view.id,
          };
          clonedViewMolecule.Touched = true;
          clonedMolecule.SaveProperty('buses', 'Cloning Events').subscribe();
          const finish = await this.GenerateRepresentativeNavigationMolecules(viewsToBeGenerated, navigationRepMoleculeParent, viewParentMoleculeGrowth, sourceNavigationRepMolecule, externalProcess, startPositioningOnSourceNavigateMolecule, propertiesToSave);
          return resolve(null);
        });
        
        // endregion
      }
    });
  }
  
  SetMainView() {
    this.cobbleService.Cobble.properties.mainView = this.firstViewTemplateViewId || this.isolatedElementsViewId || this.cobbleService.Cobble.properties.views[0].id;
    this.propertiesService.SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: 'mainView',
        value: this.cobbleService.Cobble.properties.mainView,
        path: 'properties',
        change: 'Start View set',
        name: 'View',
      }),
    ).subscribe();
  }
  
  RemoveEmptyViews() {
    const appViews = this.cobbleService.Cobble.properties.views;
    
    appViews.forEach(view => {
      const viewContainsElements = this.busService.GetSpecificViewWorkgroupsMolecules(this.cobbleService.Cobble, view.id);
      
      if (viewContainsElements.length === 0) {
        this.cobbleService.Cobble.properties.views = this.cobbleService.Cobble.properties.views.filter(v => v.id !== view.id);
      }
    });
    
  }
  
  ClearSmartTemplateCreation(error = false) {
    this.RemoveEmptyViews();
    this.SetMainView();
    this.SaveAppViews();
    
    if (error) {
      this.communicationService.Event.Editor.WorkArea.$ShowLoadingOverlay.emit({
        display: true,
        showSpinner: false,
        spinnerType: 'bar',
        iconAnimated: true,
        message: 'Error generating template, please try again',
        icon: 'cancel',
        iconColor: 'indianred',
      });
    } else {
      this.communicationService.Event.Editor.WorkArea.$ShowLoadingOverlay.emit({
        display: true,
        showSpinner: false,
        spinnerType: 'bar',
        iconAnimated: true,
        message: 'Application generated successfully!',
        icon: 'check_circle_outline',
        iconColor: 'seagreen',
      });
    }
    
    this.Clear();
  }
  
  Clear() {
    setTimeout(() => {
      this.communicationService.Event.Editor.WorkArea.$HideLoadingOverlay.emit();
      
      if (this.navigationRepMoleculeParent) {
        this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(this.navigationRepMoleculeParent);
        this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(this.navigationRepMoleculeParent.Id);
        this.navigationRepMoleculeParent.Children.forEach(child => {
          this.communicationService.Event.Editor.$RefreshRepresentativeMoleculeUI.emit(child.id);
        });
        this.navigationRepMoleculeParent = null;
        
      }
      this.communicationService.Event.System.App.$RefreshUI.emit(true);
    }, 1500);
    
    setTimeout(() => {
      
      this.locationConfigs = [];
      this.lastRandomPosition = null;
      this.footerYPosition = null;
      this.SmartTemplateBuilding = false;
      this.viewLocationConfigs = [];
      this.viewsCreated = [];
      
      this.navigationViewId = null;
      this.templatesAdded = [];
      this.isolatedTemplates = [];
      this.viewTemplates = [];
      this.navigationTemplate = null;
      this.isolatedElementsViewId = null;
      this.navigationViewSpecific = true;
      this.firstViewTemplateViewId = null;
    }, 300);
  }
  
  EvaluatePosition(viewWg: IRepresentativeMolecule, repMolecule: any, xPosition: number, yPosition: number) {
    
    const xFinal =
      viewWg.ResponsiveProperties().x +
      viewWg.ResponsiveProperties().cols;
    const xStart =
      viewWg.ResponsiveProperties().x;
    
    const yFinal =
      viewWg.ResponsiveProperties().y +
      viewWg.ResponsiveProperties().rows;
    const yStart =
      viewWg.ResponsiveProperties().y;
    
    
    // console.log('=====================================================');
    // console.log('condition 1', yFinal >=
    //                            yPosition &&
    //                            yFinal <=
    //                            yPosition +
    //                            repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].rows);
    // console.log('condition 2', yStart >=
    //                            yPosition &&
    //                            yStart <=
    //                            yPosition +
    //                            repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].rows);
    // console.log('condition 3', yStart <=
    //                            yPosition &&
    //                            yFinal >=
    //                            yPosition +
    //                            repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].rows);
    // console.log('condition 4', yStart >=
    //                            yPosition &&
    //                            yFinal <=
    //                            yPosition +
    //                            repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].rows);
    // console.log('=====================================================');
    
    const xResult = !(xFinal >=
        xPosition &&
        xFinal <=
        xPosition +
        repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].cols) &&
      !(xStart >=
        xPosition &&
        xStart <=
        xPosition +
        repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].cols) &&
      !(xStart <=
        xPosition &&
        xFinal >=
        xPosition +
        repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].cols) &&
      !(xStart >=
        xPosition &&
        xFinal <=
        xPosition +
        repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].cols);
    
    const yResult = !(yFinal >=
        yPosition &&
        yFinal <=
        yPosition +
        repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].rows) &&
      !(yStart >=
        yPosition &&
        yStart <=
        yPosition +
        repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].rows) &&
      !(yStart <=
        yPosition &&
        yFinal >=
        yPosition +
        repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].rows) &&
      !(yStart >=
        yPosition &&
        yFinal <=
        yPosition +
        repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].rows);
    
    const result = (!(yFinal >=
          repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].y &&
          yFinal <=
          repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].y +
          repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].rows) &&
        !(yStart >=
          repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].y &&
          yStart <=
          repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].y +
          repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].rows) &&
        !(yStart <=
          repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].y &&
          yFinal >=
          repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].y +
          repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].rows) &&
        !(yStart >=
          repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].y &&
          yFinal <=
          repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].y +
          repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].rows)) &&
      
      (xFinal >=
        repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].x &&
        xFinal <
        repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].x +
        repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].cols);
    
    // console.log('x result', xResult);
    // console.log('y result', yResult);
    
    return xResult && yResult;
  }
  
  ObtainPositionForLocation(location: string, app: any = null): { x: number, y: number } {
    
    let repMolecule = null;
    const viewWgs = this.busService.GetViewWorkgroupsMolecules(this.cobbleService.Cobble, this.workAreaService.ActualView.id);
    
    if (app) {
      repMolecule = app.find(m => m.type === RepresentativeMoleculesType.WorkGroup);
    }
    
    // const yMinWG = viewWgs.length > 0 ? viewWgs.reduce((prev, current) => (prev.Properties.responsive[
    //                                                                          this.cobbleService.Cobble.deviceType
    //                                                                          ].y < current.Properties.responsive[
    //                                                                          this.cobbleService.Cobble.deviceType
    //                                                                          ].y) ? prev : current) : null;
    
    const xMinWg = viewWgs.length > 0 ? viewWgs.reduce((prev, current) => (prev.Properties.responsive[
      this.cobbleService.Cobble.deviceType
      ].x < current.Properties.responsive[
      this.cobbleService.Cobble.deviceType
      ].x) ? prev : current) : null;
    const yMaxWG = viewWgs.length > 0 ? viewWgs.reduce((prev, current) => (prev.Properties.responsive[
      this.cobbleService.Cobble.deviceType
      ].y + prev.Properties.responsive[
      this.cobbleService.Cobble.deviceType
      ].rows > current.Properties.responsive[
      this.cobbleService.Cobble.deviceType
      ].y + current.Properties.responsive[
      this.cobbleService.Cobble.deviceType
      ].rows) ? prev : current) : null;
    
    const xMaxWg = viewWgs.length > 0 ? viewWgs.reduce((prev, current) => (prev.Properties.responsive[
      this.cobbleService.Cobble.deviceType
      ].x + prev.Properties.responsive[
      this.cobbleService.Cobble.deviceType
      ].cols > current.Properties.responsive[
      this.cobbleService.Cobble.deviceType
      ].x + current.Properties.responsive[
      this.cobbleService.Cobble.deviceType
      ].cols) ? prev : current) : null;
    
    switch (location) {
      
      case this.Location.NorthWest:
        this.LocationsTaken.NorthWest = true;
        
        return {
          x: 0,
          y: 0,
        };
      
      case this.Location.North:
        this.LocationsTaken.North = true;
        
        return {
          x: xMinWg ? xMinWg.Properties.responsive[this.cobbleService.Cobble.deviceType].x + xMinWg.Properties.responsive[this.cobbleService.Cobble.deviceType].cols + 1 : 0,
          y: 0,
        };
      
      case this.Location.West:
        this.LocationsTaken.West = true;
        
        const leftWg = viewWgs.length > 0 ? viewWgs.reduce((prev, current) => (prev.Properties.responsive[
          this.cobbleService.Cobble.deviceType
          ].x < current.Properties.responsive[
          this.cobbleService.Cobble.deviceType
          ].x) ? prev : current) : null;
        
        
        this.lastRandomPosition = {
          x: repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].x + repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].cols,
          y: leftWg ? leftWg.Properties.responsive[this.cobbleService.Cobble.deviceType].y + leftWg.Properties.responsive[this.cobbleService.Cobble.deviceType].rows + 1 : 0,
          repMolecule,
        };
        
        return {
          x: 0,
          y: this.lastRandomPosition.y,
        };
      
      case this.Location.Random:
        const limitXRandom = 315;
        this.LocationsTaken.Random = true;
        let xPosition = 0;
        
        if (this.lastRandomPosition) {
          this.lastRandomPosition.x = this.lastRandomPosition.x + 1;
          
          if ((this.lastRandomPosition.x + repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].x + repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].cols) > limitXRandom) {
            xPosition = 0;
            this.lastRandomPosition.y = this.lastRandomPosition.y + this.lastRandomPosition.repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].rows + 1;
          } else {
            xPosition = this.lastRandomPosition.x + 1;
          }
          
        } else {
          const leftWg = viewWgs.length > 0 ? viewWgs.reduce((prev, current) => (prev.Properties.responsive[
            this.cobbleService.Cobble.deviceType
            ].x < current.Properties.responsive[
            this.cobbleService.Cobble.deviceType
            ].x) ? prev : current) : null;
          
          console.log('left wg', leftWg);
          
          xPosition = 0;
          this.lastRandomPosition = {
            x: 0,
            y: leftWg ? leftWg.Properties.responsive[this.cobbleService.Cobble.deviceType].y + leftWg.Properties.responsive[this.cobbleService.Cobble.deviceType].rows + 1 : 0,
            repMolecule,
          };
        }
        this.lastRandomPosition.repMolecule = repMolecule;
        this.lastRandomPosition.x = xPosition + repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].x + repMolecule.properties.responsive[this.cobbleService.Cobble.deviceType].cols;
        
        
        return {
          x: xPosition,
          y: this.lastRandomPosition.y,
        };
      
      // case this.Location.Random:
      //   this.LocationsTaken.Random = true;
      //
      //   let x = 0;
      //   let y = 0;
      //
      //   const xMax = xMaxWg.Properties.responsive[this.cobbleService.Cobble.deviceType].x + xMaxWg.Properties.responsive[this.cobbleService.Cobble.deviceType].cols + 30;
      //   const yMax = yMaxWG.Properties.responsive[this.cobbleService.Cobble.deviceType].y + yMaxWG.Properties.responsive[this.cobbleService.Cobble.deviceType].rows + 30;
      //
      //   let validX = xMax;
      //   let validY = yMax;
      //
      //   if (repMolecule) {
      //
      //     for (let yz = yMax; yz > 0; yz--) {
      //       for (let xz = xMax; xz > 0; xz--) {
      //
      //         let result = false;
      //         viewWgs.forEach(viewWG => {
      //
      //           if (this.EvaluatePosition(viewWG, repMolecule, xz, yz)) {
      //             result = true;
      //           }
      //
      //
      //         });
      //
      //         if (result) {
      //           validX = xz;
      //           validY = yz;
      //         }
      //         // console.log(result, xz);
      //       }
      //     }
      //
      //     console.log('valid x', validX);
      //     console.log('valid y', validY);
      //   }
      //
      //
      //   return {
      //     x,
      //     y
      //   };
      //
      //   if (this.lastRandomPosition) {
      //     const randomWG = viewWgs.find(wg => wg.Properties.responsive[this.cobbleService.Cobble.deviceType].x === this.lastRandomPosition.x &&
      //                                         wg.Properties.responsive[this.cobbleService.Cobble.deviceType].y === this.lastRandomPosition.y);
      //
      //     x = randomWG.Properties.responsive[
      //           this.cobbleService.Cobble.deviceType
      //           ].x + randomWG.Properties.responsive[
      //           this.cobbleService.Cobble.deviceType
      //           ].cols + 1;
      //
      //     y = this.lastRandomPosition.y;
      //   }
      //   else {
      //     const topWgs = viewWgs.filter(wg => wg.Properties.responsive[this.cobbleService.Cobble.deviceType].y === 0);
      //     const leftWgs = viewWgs.filter(wg => wg.Properties.responsive[this.cobbleService.Cobble.deviceType].x === 0);
      //
      //
      //     if (topWgs.length > 0) {
      //       const maxYWg = topWgs.reduce((prev, current) => (prev.Properties.responsive[
      //                                                          this.cobbleService.Cobble.deviceType
      //                                                          ].y + prev.Properties.responsive[
      //                                                          this.cobbleService.Cobble.deviceType
      //                                                          ].rows > current.Properties.responsive[
      //                                                          this.cobbleService.Cobble.deviceType
      //                                                          ].y + current.Properties.responsive[
      //                                                          this.cobbleService.Cobble.deviceType
      //                                                          ].rows) ? prev : current);
      //
      //       y = maxYWg.Properties.responsive[this.cobbleService.Cobble.deviceType].y + maxYWg.Properties.responsive[this.cobbleService.Cobble.deviceType].rows + 1;
      //     }
      //     else {
      //       y = 0;
      //     }
      //
      //     if (leftWgs.length > 0) {
      //
      //       const sameyWGS = leftWgs.filter(wg => wg.ResponsiveProperties().y + wg.ResponsiveProperties().rows >= y);
      //
      //       const wgs = sameyWGS.length > 0 ? sameyWGS : leftWgs;
      //
      //       const maxXWg = wgs.reduce((prev, current) => (prev.Properties.responsive[
      //                                                       this.cobbleService.Cobble.deviceType
      //                                                       ].x + prev.Properties.responsive[
      //                                                       this.cobbleService.Cobble.deviceType
      //                                                       ].cols > current.Properties.responsive[
      //                                                       this.cobbleService.Cobble.deviceType
      //                                                       ].x + current.Properties.responsive[
      //                                                       this.cobbleService.Cobble.deviceType
      //                                                       ].cols) ? prev : current);
      //
      //       x = maxXWg.Properties.responsive[this.cobbleService.Cobble.deviceType].x + maxXWg.Properties.responsive[this.cobbleService.Cobble.deviceType].cols + 1;
      //     }
      //     else {
      //       x = 0;
      //     }
      //
      //   }
      //
      //   this.lastRandomPosition = {
      //     x,
      //     y
      //   };
      //
      //   return this.lastRandomPosition;
      
      case this.Location.NorthEast:
        this.LocationsTaken.NorthEast = true;
        
        return {
          x: xMaxWg ? xMaxWg.Properties.responsive[this.cobbleService.Cobble.deviceType].x + xMaxWg.Properties.responsive[this.cobbleService.Cobble.deviceType].cols + 1 : 0,
          y: 0,
        };
      
      case this.Location.East:
        this.LocationsTaken.East = true;
        return {
          x: xMaxWg ? xMaxWg.Properties.responsive[this.cobbleService.Cobble.deviceType].x + xMaxWg.Properties.responsive[this.cobbleService.Cobble.deviceType].cols + 1 : 0,
          y: 0,
        };
      
      case this.Location.SouthWest:
        this.LocationsTaken.East = true;
        
        this.footerYPosition = yMaxWG ? yMaxWG.Properties.responsive[this.cobbleService.Cobble.deviceType].y + yMaxWG.Properties.responsive[this.cobbleService.Cobble.deviceType].rows + 1 : 0;
        
        return {
          x: 0,
          y: this.footerYPosition,
        };
      
      case this.Location.South:
      case this.Location.SouthEast:
        this.LocationsTaken.South = true;
        
        if (this.footerYPosition) {
          
          const footerWgs = viewWgs.filter(wg => wg.Properties.responsive[this.cobbleService.Cobble.deviceType].y === this.footerYPosition);
          
          const maxFooterWg = footerWgs.reduce((prev, current) => (prev.Properties.responsive[
            this.cobbleService.Cobble.deviceType
            ].x + prev.Properties.responsive[
            this.cobbleService.Cobble.deviceType
            ].cols > current.Properties.responsive[
            this.cobbleService.Cobble.deviceType
            ].x + current.Properties.responsive[
            this.cobbleService.Cobble.deviceType
            ].cols) ? prev : current);
          
          
          return {
            x: maxFooterWg.Properties.responsive[this.cobbleService.Cobble.deviceType].x + maxFooterWg.Properties.responsive[this.cobbleService.Cobble.deviceType].cols + 1,
            y: this.footerYPosition,
          };
          
        } else {
          this.footerYPosition = yMaxWG ? yMaxWG.Properties.responsive[this.cobbleService.Cobble.deviceType].y + yMaxWG.Properties.responsive[this.cobbleService.Cobble.deviceType].rows + 1 : 0;
          return {
            x: 0,
            y: yMaxWG ? yMaxWG.Properties.responsive[this.cobbleService.Cobble.deviceType].y + yMaxWG.Properties.responsive[this.cobbleService.Cobble.deviceType].rows + 1 : 0,
          };
        }
      
      case this.Location.View:
        
        const wgs = app.filter(m => m.type === RepresentativeMoleculesType.WorkGroup);
        
        const yWG = wgs.reduce((prev, current) => (prev.properties.responsive[
          this.cobbleService.Cobble.deviceType
          ].y < current.properties.responsive[
          this.cobbleService.Cobble.deviceType
          ].y) ? prev : current);
        
        const xWg = wgs.reduce((prev, current) => (prev.properties.responsive[
          this.cobbleService.Cobble.deviceType
          ].x < current.properties.responsive[
          this.cobbleService.Cobble.deviceType
          ].x) ? prev : current);
        
        return {
          x: xWg.properties.responsive[
            this.cobbleService.Cobble.deviceType
            ].x,
          y: yWG.properties.responsive[
            this.cobbleService.Cobble.deviceType
            ].y,
        };
      
      case this.Location.Original:
        return null;
    }
  }
  
  SaveAppViews() {
    this.propertiesService.SaveProperty(
      new PropertyVersioningDto({
        elementId: this.cobbleService.Cobble.id.toString(),
        property: 'views',
        value: this.cobbleService.Cobble.properties.views,
        path: 'properties',
        change: 'View Added',
        name: 'View',
      }),
    ).subscribe();
  }
}
