import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfig } from '../../models/field-config.model';

@Component({
  selector: 'app-dynamic-dropdown',
  template: `
		<div class="panel-viewer-body">
			<div
				class="dynamic-field form-input"
				[formGroup]="group"
			>
				<mat-form-field appearance="fill">
					<mat-label>{{ config.placeholder }}</mat-label>
					<mat-select
						[(value)]="config.value"
						(selectionChange)="OptionSelected($event)"
					>
						<mat-option
							*ngFor="let option of config.options"
							[value]="option.value"
						>{{ option.name }}</mat-option>
					</mat-select>
				</mat-form-field>

				<!-- <mat-form-field>
        <input *ngIf="!config.isMultiple" matInput type="text" [placeholder]="config.placeholder" [formControlName]="config.name">
        <input *ngIf="config.isMultiple" matTooltip="Multiple values separated with |" matInput type="text" [placeholder]="config.placeholder" [formControlName]="config.name">
      </mat-form-field> -->
			</div>
		</div>
  `,
  styleUrls: ['./dynamic-dropdown.component.css'],
})
export class DynamicDropdownComponent implements OnInit {
  config: FieldConfig;
  group: FormGroup;
  
  constructor() {
  }
  
  ngOnInit() {
  }
  
  OptionSelected(event: any) {
    const optionSelected = event.value;
    this.Save(optionSelected);
  }
  
  Save(value: boolean) {
    this.group.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    const control = this.group.controls[this.config.name];
    this.group.markAsDirty();
    control.setValue(value);
  }
}
