import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { Component, NgZone, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import * as wjcCore from '@grapecity/wijmo';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { wijmoLicenseKey } from '../license/wijmo.licenseKey';
import { routerTransition } from './animations/router.animations';
import { ApiAdminService } from './core/services/api-admin.service';
import { ApiAuthService } from './core/services/api-auth.service';
import { ApiFileService } from './core/services/api-file.service';
import { BackgroundRecurrentTasksService } from './core/services/background-recurrent-tasks.service';
import { ChatService } from './core/services/chat.service';
import { ClientStorageService } from './core/services/client-storage.service';
import { DevToolsService } from './core/services/dev-tools.service';
import { LocalStorageService } from './core/services/local-storage.service';
import { ToolsService } from './core/services/tools.service';
import { routeTransitionAnimations } from './route-transition-animations';
import { RuntimeService } from './running-area/services/runtime.service';
import { Constants } from './shared/constants';
import { CobbleService } from './shared/representative-molecule/services/cobble.service';
import { DragService } from './shared/representative-molecule/services/drag.service';
import { BrowserNotificationsService } from './shared/services/browser-notifications.service';
import { CommunicationService } from './shared/services/communication.service';
import { ConnectionStateService } from './shared/services/connection-state.service';
import { CrossOriginIframeService } from './shared/services/cross-origin-iframe.service';
import { DraggableWindowService } from './shared/services/draggable-window.service';
import { HubConnectionService } from './shared/services/hub-connection.service';
import { LeapXLShortcutsService } from './shared/services/leapxl-shortcuts.service';
import { SnackerService } from './shared/services/snacker.service';
import { SystemService } from './shared/services/system.service';
import { WorkAreaService } from './workarea/workarea.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    routerTransition,
    trigger('noConnectionAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(-13%)', opacity: 0 }),
        animate('350ms', style({ transform: 'translateY(0', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('350ms', style({ transform: 'translateY(-13%)', opacity: 0 })),
      ]),
    ]),
    trigger('fadeAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('150ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('150ms', style({ opacity: 0 }))]),
    ]),
    trigger('zoomOut', [
      transition(':leave', [style({ transform: 'scale(1)', opacity: 1 }), animate('100ms ease', style({ transform: 'scale(0.5)', opacity: 0 }))]),
    ]),
    trigger('bounce', [
      transition(':enter', [
        animate(
          '0.35s',
          keyframes([
            style({ transform: 'scale(1,1) translateY(0)' }),
            style({ transform: 'scale(1.2, 0.9) translateY(0)' }),
            style({ transform: 'scale(0.9, 1.1) translateY(-25px) translateX(25px)' }),
            style({ transform: 'scale(1.05, 0.95) translateY(0) translateX(0px)' }),
            style({ transform: 'scale(1,1) translateY(-7px)' }),
            style({ transform: 'scale(1,1) translateY(0)' }),
          ]),
        ),
      ]),
      transition(':leave', [
        style({ transform: 'scale(1) translateX(0px)', opacity: 1 }),
        animate('150ms ease', style({ transform: 'scale(0.4) translateX(-150px)', opacity: 0.25 })),
      ]),
    ]),
    routeTransitionAnimations,
  ],
})
export class AppComponent implements OnInit {
  @ViewChild('windowContainer', { read: ViewContainerRef, static: true })
  windowContainer: ViewContainerRef;
  showPwaPrompt = true;
  askedOnce = false;
  deferredPrompt = null;
  debug = false;
  
  constructor(
    private draggableWindowService: DraggableWindowService,
    private communicationService: CommunicationService,
    private snackerService: SnackerService,
    private clientStorageService: ClientStorageService,
    private fileService: ApiFileService,
    private serviceWorkerUpdate: SwUpdate,
    public connectionStateService: ConnectionStateService,
    private browserNotificationsService: BrowserNotificationsService,
    private adalSvc: MsAdalAngular6Service,
    private authService: ApiAuthService,
    private toolsService: ToolsService,
    private localStorageService: LocalStorageService,
    private crossOriginIframeService: CrossOriginIframeService,
    public shortcutsService: LeapXLShortcutsService,
    private runtimeService: RuntimeService,
    public cobbleService: CobbleService,
    public workAreaService: WorkAreaService,
    private hubConnectionService: HubConnectionService,
    private systemService: SystemService,
    private zone: NgZone,
    public chatService: ChatService,
    private readonly router: Router,
    private backgroundRecurrentTasksService: BackgroundRecurrentTasksService,
    private adminService: ApiAdminService,
    private devToolsService: DevToolsService,
    public dragService: DragService,
  ) {
    this.browserNotificationsService.requestPermission();
    this.CheckExternalLoginStatus();
    this.debug = this.localStorageService.IsDebug();
    
    if (this.systemService.UIStyle === 'modern-ui') {
      this.workAreaService.editorPreferences.fixedContextMenu = true;
      this.workAreaService.editorPreferences.guidelines = false;
      this.workAreaService.editorPreferences.groupDrag = true;
      this.workAreaService.editorPreferences.zoom = false;
      this.workAreaService.editorPreferences.gridline = false;
      this.workAreaService.editorPreferences.displayElementsBounds = true;
      this.workAreaService.editorPreferences.customGuideline = false;
      this.workAreaService.editorPreferences.searchAddParticlesOnBus = true;
      this.workAreaService.editorPreferences.mostUsedSection = true;
      this.workAreaService.editorPreferences.dragRepMoleculesPreview = true;
      this.workAreaService.editorPreferences.pinDS = true;
      this.workAreaService.editorPreferences.expandedContextMenu = true;
      this.workAreaService.editorPreferences.compactToolBar = true;
      this.workAreaService.editorPreferences.compactToolBarPosition = 'top';
      this.workAreaService.editorPreferences.busDiagnostic = false;
      
      // devtools
      if (this.toolsService.IsLocalEnvironment() || this.toolsService.IsDevEnvironment() || this.debug) {
        this.workAreaService.editorPreferences.busDiagnostic = true;
        this.devToolsService.Enabled = true;
      }
    }
    
    document.body.classList.add(this.systemService.UIStyle);
    this.EvaluateTopBar();
    
    // clear cache is system is not updated
    // if (!this.IsFrontEndUpdatedOnClient()) {
    //   localStorage.clear();
    //   cookieService.set(
    //     'version',
    //     environment.version,
    //     new Date(
    //       Date.now() +
    //         1000 /*sec*/ * 60 /*min*/ * 60 /*hour*/ * 24 /*day*/ * 9999
    //     ),
    //     '/'
    //   );
    // }
    
    this.adminService.GetAntiforgery();
    // app running
    if (window.location.href.indexOf('/run/') > -1) {
    } else {
      this.authService.EvaluateDefaultApp();
      this.systemService.EvaluateSystemVersionAndSendUserAlive(false);
    }
    
    console.log('evaluation');
    this.systemService.SetSessionId();
    this.systemService.EvaluateBrowserSupport();
    this.systemService.RefreshUserInfo();
    this.systemService.DisableMobileInputZoom();
    const userPreference = this.systemService.GetUserPreferences();
    
    if (userPreference.theme) {
      switch (userPreference.theme) {
        case 'auto':
          if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            this.toolsService.SetTheme('dark');
          }
          break;
        case 'light':
          this.toolsService.SetTheme('light');
          break;
        case 'dark':
          this.toolsService.SetTheme('dark');
          break;
      }
    } else {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.toolsService.SetTheme('dark');
      }
    }
    
    window.matchMedia('(prefers-color-scheme: light)').addEventListener('change', (event) => {
      if (event.matches) {
        this.toolsService.SetTheme('light');
        console.log('light mode');
      } else {
        this.toolsService.SetTheme('dark');
        console.log('dark mode');
      }
    });
    
    if (navigator.serviceWorker) {
      // navigator.serviceWorker.ready.then(function(swRegistration) {
      //   return swRegistration.sync.register('syncData');
      // });
      
      if (this.serviceWorkerUpdate.isEnabled) {
        // interval(1000 * 60 * 60 * 12).subscribe(() => {
        //   this.serviceWorkerUpdate.checkForUpdate();
        // });
        
        this.serviceWorkerUpdate.available.subscribe((e) => {
          this.serviceWorkerUpdate.activateUpdate().then(() => {
            document.location.reload();
          });
        });
        
        this.serviceWorkerUpdate.activated.subscribe((e) => {
          // console.log('sw old version', e.previous);
          // console.log('sw new version', e.current);
        });
      }
    }
    
    window.addEventListener('message', (event) => {
      this.crossOriginIframeService.MessageReceived(event);
    });
    
    window.addEventListener('online', () => {
      this.communicationService.Event.System.Connection.$StateChange.emit(navigator.onLine);
      this.browserNotificationsService.generateNotification([
        {
          alertContent: 'Application Online',
        },
      ]);
      this.connectionStateService.ShowOnlinePopup();
    });
    window.addEventListener('offline', () => {
      this.communicationService.Event.System.Connection.$StateChange.emit(navigator.onLine);
      this.browserNotificationsService.generateNotification([
        {
          alertContent: 'Application Offline',
        },
      ]);
      this.connectionStateService.ShowOfflinePopup();
    });
    
    router.events.subscribe((uri) => {
      zone.run(() => {
        this.draggableWindowService.SetContainer(this.windowContainer);
      });
    });
    
    this.communicationService.Event.Editor.WorkArea.$EditorStateLoaded.subscribe(load => {
      console.log('=event=');
      this.EvaluateTopBar();
    });
    
    const devtools = function() {
    };
    devtools.toString = function() {
      if ((window as any).devToolsOnce) {
        localStorage.setItem(`${ Constants.LocalStoragePrefix }devtools`, JSON.stringify(true));
        const devtoolsEvent = new Event('devtools');
        window.dispatchEvent(devtoolsEvent);
        // console.log('dispatching devtools event');
      } else {
        (window as any).devToolsOnce = true;
      }
    };
    
    // DO NOT COMMENT THIS LINE
    console.log('devtools', devtools);
  }
  
  get nativeWindow(): any {
    return window;
  }
  
  EvaluateTopBar() {
    if (this.workAreaService.editorPreferences.compactToolBar) {
      document.body.classList.add('compact');
      
      let topbarPositionClass = '';
      switch (this.workAreaService.editorPreferences.compactToolBarPosition) {
        case 'top':
          topbarPositionClass = 'top-toolbar';
          break;
        case 'right':
          topbarPositionClass = 'right-side-toolbar';
          break;
        case 'left':
          topbarPositionClass = 'left-side-toolbar';
          break;
        
        default:
          break;
      }
      
      document.body.classList.remove('top-toolbar');
      document.body.classList.add(topbarPositionClass);
    } else {
      document.body.classList.add('top-toolbar');
    }
  }
  
  IsFrontEndUpdatedOnClient() {
    // if (this.cookieService.check('version')) {
    //   return this.cookieService.get('version') === version.build;
    // } else {
    //   return false;
    // }
  }
  
  CheckExternalLoginStatus() {
    if (this.clientStorageService.getUserType() === 'external') {
      if (
        (this.adalSvc && this.adalSvc.userInfo === null) ||
        (this.adalSvc && this.adalSvc.userInfo.profile.exp < Math.round(new Date().getTime() / 1000))
      ) {
        this.snackerService.ShowMessageOnBottom('Your Azure session has expired, please log in again', 'timer', 5000);
        this.authService.logout();
      }
    }
  }
  
  ngOnInit() {
    if (Constants.Environment.production) {
      this.disableRightClick();
    }
    
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      // e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      
      // console.log('beforeinstallprompt!');
      // if askedOnce is true, no need to ask again.
      this.showPwaPrompt = !this.askedOnce;
    });
    
    const loaderNav = document.querySelector('#loaderNav');
    setTimeout(() => {
      document.body.classList.remove('loaderBgWhite');
      document.body.classList.remove('loaderBgBlack');
    }, 100);
    
    if (loaderNav) {
      loaderNav.remove();
    }
    
    const loader = document.querySelector('#initLoader');
    if (loader) {
      loader.remove();
    }
    
    wjcCore.setLicenseKey(wijmoLicenseKey.find((key) => key.indexOf(document.location.hostname) > -1) || wijmoLicenseKey[0]);
    
    this.nativeWindow.services = {
      fileService: this.fileService,
    };
  }
  
  acceptPwaPrompt() {
    this.showPwaPrompt = false;
    this.askedOnce = true;
    this.deferredPrompt.prompt(); // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        // console.log('User accepted the A2HS prompt');
      } else {
        // console.log('User dismissed the A2HS prompt');
      }
      
      this.deferredPrompt = null;
    });
  }
  
  public getState(outlet) {
    return outlet.activatedRouteData.state;
  }
  
  prepareRoute(outlet: RouterOutlet) {
    if (outlet.activatedRouteData['animationState'] === 'workarea') {
      const path = document.location.pathname.split('/');
      
      if (path.length === 3 && +path[2] > 0) {
        return 'app';
      } else {
        return 'workarea';
      }
    } else {
      return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animationState'];
    }
  }
  
  private disableRightClick() {
    document.addEventListener(
      'contextmenu',
      (e) => {
        e.preventDefault();
      },
      false,
    );
    document.addEventListener(
      'keydown',
      (e) => {
        // console.log('keydown');
        // document.onkeydown = function(e) {
        // "I" key
        if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
          disabledEvent(e);
        }
        // "J" key
        if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
          disabledEvent(e);
        }
        // "S" key + macOS
        if (e.keyCode === 83 && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
          disabledEvent(e);
        }
        // "U" key
        if (e.ctrlKey && e.keyCode === 85) {
          disabledEvent(e);
        }
        // "F12" key
        if (e.keyCode === 123) {
          disabledEvent(e);
        }
      },
      false,
    );
    
    function disabledEvent(e) {
      if (e.stopPropagation) {
        e.stopPropagation();
      } else if (window.event) {
        window.event.cancelBubble = true;
      }
      e.preventDefault();
      return false;
    }
  }
}
