import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, flatMap, map } from 'rxjs/operators';
import { Permissions } from '../admin/models/permissions.enum';
import { BuilderService } from '../core/builder/builder.service';
import { BusService } from '../core/molecular/services/bus.service';
import { ApiDataSourcesService } from '../core/services/api-data-sources.service';
import { ApiPropertiesService } from '../core/services/api-properties.service';
import { ClientStorageService } from '../core/services/client-storage.service';
import { EditorStateService } from '../core/services/editor-state.service';
import { GenericDialogService } from '../core/services/generic-dialog.service';
import { LocalStorageService } from '../core/services/local-storage.service';
import { TemplateService } from '../core/services/template.service';
import { ToolsService } from '../core/services/tools.service';
import { UserMenuService } from '../core/services/user-menu.service';
import { Constants } from '../shared/constants';
import { DragType } from '../shared/enums/drag-type.enum';
import { MoleculesType } from '../shared/enums/molecules-type.enum';
import { RepresentativeMoleculesType } from '../shared/enums/representative-molecules-types.enum';
import { Library } from '../shared/interfaces/library';
import { CobbleService } from '../shared/representative-molecule/services/cobble.service';
import { DragService } from '../shared/representative-molecule/services/drag.service';
import { BrowserNotificationsService } from '../shared/services/browser-notifications.service';
import { CommunicationService } from '../shared/services/communication.service';
import { ConnectionStateService } from '../shared/services/connection-state.service';
import { DraggableWindowManagerService } from '../shared/services/draggable-window-manager.service';
import { DraggableWindowService, DraggableWindowType } from '../shared/services/draggable-window.service';
import { LeapXLShortcutsService } from '../shared/services/leapxl-shortcuts.service';
import { SnackerService } from '../shared/services/snacker.service';
import { SystemService } from '../shared/services/system.service';
import { SpreadsheetService } from '../spreadsheet/spreadsheet.service';
import { DocumentationComponent } from './documentation/documentation.component';
import { VersioningHistoryDialogService } from './versioning-history/versioning-history-dialog.service';
import { WorkAreaService } from './workarea.service';

@Component({
  selector: 'app-workarea',
  templateUrl: './workarea.component.html',
  styleUrls: ['./workarea.component.scss'],
  animations: [
    trigger('fadeIn', [transition(':enter', [style({ opacity: '0.3' }), animate('.7s ease-out', style({ opacity: '1' }))])]),
    trigger('sidepanels', [
      transition(':enter', [style({ opacity: '0' }), animate('0.4s ease-out', style({ opacity: '1' }))]),
      transition(':leave', [style({ opacity: '1' }), animate('0.4s ease-out', style({ opacity: '0' }))]),
    ]),
    trigger('add', [transition(':enter', [style({ transform: 'translateY(10%)' }), animate('20000ms', style({ transform: 'translateY(0)' }))])]),
  ],
})
export class WorkareaComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
  @ViewChild('leftMatTabGroup', { static: true })
  leftMatTabGroup: MatTabGroup;
  @ViewChild('rightMatTabGroup', { static: false })
  rightMatTabGroup: MatTabGroup;
  @ViewChild('fileUploader', { static: true })
  fileUploader;
  uploader: FileUploader = new FileUploader({});
  libraryExpanded = true;
  myLibraryExpanded = true;
  sidePanelSize = 14;
  selectedRightTabsIndex = 0;
  userName: string;
  
  userId = 0;
  libraryFiltered: Library;
  myLibraryFiltered: Library;
  library: Library;
  debug = false;
  myLibrary: Library;
  subscription: Subscription;
  filteringSearch = false;
  public librarySearch = new Subject<any>();
  public myLibrarySearch = new Subject<any>();
  libraryFilterValue = '';
  libraryStepOpen = 0;
  mostUsedSection = {
    templates: [],
  };
  representativeMoleculeDragImg = {};
  showMostUsedSection = true;
  customControlEnabled = false;
  modifyCustomControl = false;
  modifyImportedCustomControlTemplate = false;
  createCustomControl = false;
  
  constructor(
    public busService: BusService,
    public workAreaService: WorkAreaService,
    private historyService: VersioningHistoryDialogService,
    private router: Router,
    private route: ActivatedRoute,
    private toolsService: ToolsService,
    private genericDialogService: GenericDialogService,
    private clientStorageService: ClientStorageService,
    private titleService: Title,
    private snackerService: SnackerService,
    private builderService: BuilderService,
    private menuService: UserMenuService,
    public dragService: DragService,
    public cobbleService: CobbleService,
    public dataSourcesService: ApiDataSourcesService,
    private templateService: TemplateService,
    private connectionStateService: ConnectionStateService,
    private spreadsheetService: SpreadsheetService,
    private dragableWindowManagerService: DraggableWindowManagerService,
    private draggableWindowService: DraggableWindowService,
    private changeDetectorRef: ChangeDetectorRef,
    private editorStateService: EditorStateService,
    private propertiesService: ApiPropertiesService,
    private browserNotificationsService: BrowserNotificationsService,
    private communicationService: CommunicationService,
    private localStorageService: LocalStorageService,
    public systemService: SystemService,
    private shortcutsService: LeapXLShortcutsService,
    private userMenuService: UserMenuService,
    private angularZone: NgZone,
  ) {
    this.userName = this.clientStorageService.getUserName();
    this.userId = this.clientStorageService.getUserId();
    this.builderService.RunningMode = false;
    this.toolsService.RunningMode = false;
    this.busService.RunningMode = false;
    this.debug = this.localStorageService.IsDebug();
    this.customControlEnabled = this.userMenuService.checkPermission(Permissions.UseCustomControl);
    this.createCustomControl = this.userMenuService.checkPermission(Permissions.CreateCustomControl);
    this.modifyCustomControl = this.userMenuService.checkPermission(Permissions.ModifyCustomControlTemplate);
    this.modifyImportedCustomControlTemplate = this.userMenuService.checkPermission(Permissions.EditCustomControlTemplateFromImportedApp);
    this.communicationService.Event.Editor.WorkArea.$CloseAllDraggableWindows.emit();
  }
  
  @HostListener('mousemove', ['$event'])
  onMouseMove(e: MouseEvent) {
    this.dragService.dragBetweenWG.previewX = e.clientX + 5;
    this.dragService.dragBetweenWG.previewY = e.clientY + 5;
  }
  
  ngOnInit() {
    this.SetRepMoleculePlaceholderImg();
    
    console.log('workarea');
    this.communicationService.Event.Editor.SidePanels.$KeepOpen.subscribe(panelId => {
      console.log('=event=');
      if (panelId === 1) {
        this.workAreaService.userOpenCobblePanel = true;
      } else {
        this.workAreaService.userOpenLibraryPanel = true;
      }
    });
    
    this.subscription = this.communicationService.Event.Editor.SidePanels.$Open.subscribe(panelId => {
      console.log('=event=');
      if (panelId === 1) {
        this.workAreaService.userOpenCobblePanel = false;
        this.ToggleLeftPanel(true);
      } else {
        this.workAreaService.userOpenLibraryPanel = false;
        this.ToggleRightPanel(true);
      }
    });
    
    this.subscription.add(
      this.communicationService.Event.Editor.$WorkAreaDetection.subscribe(enable => {
        console.log('=event=');
        // console.warn('WORKAREA DETECTION CHANGE', enable);
        
        if (enable) {
          this.changeDetectorRef.reattach();
        } else {
          this.changeDetectorRef.detach();
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$WorkAreaDetectionDisableFor.subscribe(duration => {
        console.log('=event=');
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
        
        setTimeout(() => {
          this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
        }, duration || 100);
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.Library.$ReloadLibrary.subscribe(result => {
        console.log('=event=');
        this.loadLibrary(true);
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.WorkArea.$EditorStateLoaded.subscribe(result => {
        console.log('=event=');
        this.GetMostUsedMolecules();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.WorkArea.$MoleculeUsed.subscribe(data => {
        console.log('=event=');
        console.log('molecule used');
        
        const moleculeData = this.workAreaService.mostUsedMolecules.find(m => m.type === data.type);
        
        if (moleculeData) {
          moleculeData.count++;
        } else {
          this.workAreaService.mostUsedMolecules.push({
            molecule: data,
            type: data.type,
            count: 1,
          });
        }
        
        this.GetMostUsedMolecules();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.SidePanels.$Close.subscribe(panelId => {
        console.log('=event='); // console.log('close side panel');
        if (panelId === 1) {
          this.workAreaService.userOpenCobblePanel = true;
          this.ToggleLeftPanel(false);
        } else {
          this.workAreaService.userOpenLibraryPanel = true;
          this.ToggleRightPanel(false);
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.System.Connection.$ConnectionLost.subscribe(condition => {
        console.log('=event=');
        if (this.propertiesService.SavingInProcess()) {
          this.workAreaService.ShowUnsavedDataWindow();
          this.workAreaService.SetUnsavedElementsIndicators();
        }
        
        setTimeout(() => {
          this.propertiesService.CancelSaving();
          this.workAreaService.HideNotification();
          this.workAreaService.workareaOverlayActive = true;
          
          // REMOVED TEMPORARILY
          if (this.debug) {
            this.snackerService.ShowMessageOnBottom('Connection lost with the server', 'wifi_off');
            this.browserNotificationsService.generateNotification([
              {
                alertContent: 'Connection lost with the server',
              },
            ]);
          }
        }, 1000);
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.System.Connection.$ConnectionRecovered.subscribe(condition => {
        console.log('=event=');
        this.workAreaService.HideNotification();
        this.workAreaService.HideUnsavedDataWindow();
        
        if (this.propertiesService.backupUnsavedProperties.length > 0) {
          this.workAreaService.ShowUnsavedApologyWindow();
          
          // REMOVED TEMPORARILY
          if (this.debug) {
            this.browserNotificationsService.generateNotification([
              {
                alertContent: 'Connection regain with the server',
              },
            ]);
            this.snackerService.ShowMessageOnBottom('Connection regain', 'wifi');
          }
        } else {
          this.workAreaService.UnblockEditor();
        }
      }),
    );
    
    this.cobbleService.ClearCobbleSession();
    this.workAreaService.actualEditorViews = [];
    
    this.angularZone.runOutsideAngular(() => {
      this.loadLibrary();
    });
    
    this.workAreaService.topBarTitle = 'Welcome to LeapXL!';
    this.route.data.subscribe(routeData => {
      this.titleService.setTitle(routeData.title);
    });
    
    // if (this.menuService.canFilterUsers) {
    //   const company = new CompanyLicense();
    //   company.id = this.clientStorageService.getCurrentCompanyId();
    //   this.workAreaService.getCompanyCobbles(company);
    // }
    
    this.librarySearch
    .pipe(
      map(event => event.target.value),
      debounceTime(150),
      flatMap(search => of(search).pipe(delay(50))),
    )
    .subscribe(value => {
      this.filteringSearch = true;
      this.libraryFilterValue = value;
      this.filterLibrary(value, true);
      
      // this.angularZone.runOutsideAngular(() => {
      //   setTimeout(() => {
      //     if (value && value !== '') {
      //       this.ShowAdvancedMolecules();
      //     } else {
      //       this.HideAdvancedMolecules();
      //     }
      //   }, 150);
      // });
    });
    
    this.myLibrarySearch
    .pipe(
      map(event => event.target.value),
      debounceTime(150),
      flatMap(search => of(search).pipe(delay(50))),
    )
    .subscribe(value => {
      this.filteringSearch = true;
      this.libraryFilterValue = value;
      this.filterLibrary(value);
    });
  }
  
  ngAfterViewInit() {
    this.workAreaService.fileUploader = this.fileUploader;
  }
  
  GetMostUsedMolecules() {
    if (this.workAreaService.editorPreferences.mostUsedSection) {
      const molecules = this.workAreaService.mostUsedMolecules.sort(this.toolsService.CompareValues('count', 'desc')).map(m => m.molecule);
      this.mostUsedSection.templates = molecules.slice(0, 6);
    }
  }
  
  loadLibrary(force = false) {
    console.log('load library');
    
    if (!(this.templateService.Libraries.length > 0) || force) {
      this.templateService.getLibrary().subscribe((libraries: Library[]) => {
        this.BuildLibrary(libraries);
      });
    } else {
      this.BuildLibrary(this.templateService.Libraries);
    }
  }
  
  BuildLibrary(libraries: Library[]) {
    this.library = libraries[0];
    this.libraryFiltered = libraries[0];
    libraries[1].sections[1].templates.sort(this.toolsService.CompareValues('name'));
    this.myLibrary = libraries[1];
    this.myLibraryFiltered = libraries[1];
    
    libraries.forEach(library => {
      library.sections.forEach(section => {
        section.templates.forEach(molecule => {
          this.workAreaService.libraryMoleculeIds[`${ molecule.type }-${ molecule.moleculeType }`] = molecule.id;
        });
      });
    });
  }
  
  uploadExcelDataSource(files) {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    if (files.length > 0) {
      this.workAreaService.loadExcelFileIntoPanel(files[0]);
    }
  }
  
  selectedRightTabsIndexChange(index: number) {
    this.WorkareaDetection(false);
    this.selectedRightTabsIndex = index;
    this.workAreaService.rightPanelIndexTabSelected = index;
    
    setTimeout(() => {
      this.WorkareaDetection(true);
      
      setTimeout(() => {
        switch (index) {
          // views tab
          case 2:
            this.communicationService.Event.Editor.Views.$RefreshViewsPanelUI.emit();
            break;
        }
      }, 50);
    }, 100);
  }
  
  selectedLeftTabsIndexChange(index: number) {
    this.WorkareaDetection(false);
    this.workAreaService.leftPanelIndexTabSelected = index;
    this.workAreaService.leftPanelIndexTab = index;
    setTimeout(() => {
      this.WorkareaDetection(true);
      
      setTimeout(() => {
        switch (index) {
          // datasource tab
          case 0:
            this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanel.emit();
            break;
          // events tab
          case 1:
            this.communicationService.Event.Editor.EventsTree.$RefreshEventsTree.emit();
            break;
          // apps tab
          case 2:
            this.communicationService.Event.Editor.AppsTree.$RefreshUI.emit();
            break;
        }
      }, 50);
    }, 100);
  }
  
  SplitGutterDragEnd(e: { gutterNum: number; sizes: Array<number> }) {
    const refreshLeftPanel = this.workAreaService.leftAreaSize === 0;
    
    this.workAreaService.sheetPanelDragged();
    this.workAreaService.leftAreaSize = e.sizes[0];
    this.workAreaService.contentAreaSize = e.sizes[1];
    this.workAreaService.rightAreaSize = e.sizes[2];
    
    if (e.gutterNum === 1 && e.sizes[0] > 0) {
      // left panel open
      if (refreshLeftPanel) {
        setTimeout(() => {
          this.communicationService.Event.Editor.DataSource.$ReloadDataSourcePanel.emit();
          this.communicationService.Event.Editor.AppsTree.$RefreshAppsTree.emit();
        }, 10);
      }
    } else if (e.gutterNum === 2 && e.sizes[2] > 0) {
      // right panel open
    }
  }
  
  splitGutterClick(e: { gutterNum: number; sizes: Array<number> }) {
    if (e.gutterNum === 1) {
      this.workAreaService.sheetPanelDragged();
      if (this.workAreaService.leftAreaSize > 0) {
        this.ToggleLeftPanel(false);
      } else if (this.workAreaService.rightAreaSize > this.sidePanelSize) {
        this.workAreaService.rightAreaSize -= this.sidePanelSize;
        this.workAreaService.leftAreaSize = this.sidePanelSize;
        this.communicationService.Event.Editor.AppsTree.$RefreshAppsTree.emit();
      } else {
        this.workAreaService.userOpenCobblePanel = true;
        this.ToggleLeftPanel(true);
      }
    } else if (e.gutterNum === 2) {
      this.workAreaService.sheetPanelDragged();
      if (this.workAreaService.rightAreaSize > 0) {
        this.ToggleRightPanel(false);
      } else if (this.workAreaService.contentAreaSize > this.sidePanelSize) {
        this.ToggleRightPanel(true);
        this.workAreaService.userOpenLibraryPanel = true;
      } else {
        this.workAreaService.leftAreaSize = this.sidePanelSize;
        this.workAreaService.contentAreaSize = 74;
        this.workAreaService.rightAreaSize = this.sidePanelSize;
        this.communicationService.Event.Editor.AppsTree.$RefreshAppsTree.emit();
      }
    }
  }
  
  ToggleRightPanel(open: boolean) {
    if (open) {
      if (this.workAreaService.rightAreaSize > 0) {
        return;
      }
      
      this.workAreaService.contentAreaSize -= this.sidePanelSize;
      this.workAreaService.rightAreaSize = this.sidePanelSize;
    } else {
      if (this.workAreaService.rightAreaSize === 0) {
        return;
      }
      
      this.workAreaService.contentAreaSize += this.workAreaService.rightAreaSize;
      this.workAreaService.rightAreaSize = 0;
    }
  }
  
  ToggleLeftPanel(open: boolean) {
    if (open) {
      if (this.workAreaService.leftAreaSize > 0) {
        return;
      }
      
      this.workAreaService.contentAreaSize -= this.sidePanelSize;
      this.workAreaService.leftAreaSize = this.sidePanelSize;
      setTimeout(() => {
        this.communicationService.Event.Editor.DataSource.$ReloadDataSourcePanel.emit();
        this.communicationService.Event.Editor.AppsTree.$RefreshAppsTree.emit();
      }, 50);
    } else {
      if (this.workAreaService.leftAreaSize === 0) {
        return;
      }
      
      this.workAreaService.contentAreaSize += this.workAreaService.leftAreaSize;
      this.workAreaService.leftAreaSize = 0;
    }
  }
  
  splitDragEnd(e: { gutterNum: number; sizes: Array<number> }) {
    this.workAreaService.sheetPanelDragged();
  }
  
  WorkareaDetection(enable: boolean) {
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(enable);
  }
  
  DragEndHandler(event: MouseEvent) {
    (event.target as any).style.opacity = 1;
    this.dragService.StopDragging();
    this.communicationService.Event.Editor.$DragParticleStartFromSidePanelStop.emit();
    setTimeout(() => {
      this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    }, 300);
  }
  
  SetRepMoleculePlaceholderImg() {
    Object.values(RepresentativeMoleculesType).forEach(type => {
      const url = `../../assets/images/placeholders/${ type }.png`;
      const imgElement = document.createElement('img');
      imgElement.src = url;
      this.representativeMoleculeDragImg[type] = imgElement;
    });
  }
  
  dragStartHandler(event: DragEvent, element: any) {
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
    // console.log('drag start', event);
    this.communicationService.Event.Editor.WorkArea.$ResetZoom.emit({ xOrigin: 200, yOrigin: 200 });
    this.dragService.StartDrag();
    (event.target as any).style.opacity = 0.3;
    
    switch (element.moleculeType) {
      case MoleculesType.Representative:
        if (this.workAreaService.editorPreferences.dragRepMoleculesPreview && this.representativeMoleculeDragImg[element.type]) {
          event.dataTransfer.setDragImage(this.representativeMoleculeDragImg[element.type], 0, 0);
        }
        
        break;
      case MoleculesType.Behavior:
      case MoleculesType.DataAction:
      case MoleculesType.CompoundMolecule:
        this.dragService.dragText = `Attach ${ element.name } molecule`;
        this.dragService.dragginMolecule = true;
        break;
    }
    
    this.dragService.dragginFromLibrary = true;
    element.dragType = DragType.Molecule;
    this.dragService.dragData = element;
    
    // switch (element.moleculeType) {
    //   case MoleculesType.Representative:
    //     event.dataTransfer.setDragImage(this.representaticeDragImg, -10, 0);
    //     break;
    //   case MoleculesType.Behavior:
    //     event.dataTransfer.setDragImage(this.behaviorDragImg, -10, 0);
    //     break;
    //   case MoleculesType.DataAction:
    //     event.dataTransfer.setDragImage(this.dataActionDragImg, -10, 0);
    //     break;
    //   default:
    //     event.dataTransfer.setDragImage(this.representaticeDragImg, -10, 0);
    //     break;
    // }
  }
  
  SetFilteringLoading(e: any) {
    this.filteringSearch = true;
  }
  
  filterLibrary(name: string, filterMyLibrary = false) {
    this.filteringSearch = true;
    if (name === '') {
      this.libraryFiltered = this.library;
      this.myLibraryFiltered = this.myLibrary;
      this.filteringSearch = false;
      this.showMostUsedSection = true;
    } else {
      this.showMostUsedSection = false;
      name = name.toLowerCase().replace(' ', '');
      this.libraryFiltered = null;
      
      const newLibrary = {
        name: this.library.name,
        sections: [],
      };
      
      this.library.sections.forEach(section => {
        const newSection = {
          color: section.color,
          id: section.id,
          name: section.name,
          templates: section.templates.filter(t => t.name.toLowerCase().replace(' ', '').search(name) > -1),
        };
        
        if (newSection.templates.length === 0) {
          return;
        }
        
        newLibrary.sections.push(newSection);
      });
      
      this.libraryFiltered = newLibrary;
      
      if (filterMyLibrary) {
        const newMyLibrary = {
          name: this.myLibrary.name,
          sections: [],
        };
        
        this.myLibrary.sections.forEach(section => {
          const newSection = {
            color: section.color,
            id: section.id,
            name: section.name,
            templates: section.templates.filter(t => t.name.toLowerCase().replace(' ', '').search(name) > -1),
          };
          
          newMyLibrary.sections.push(newSection);
        });
        
        this.myLibraryFiltered = newMyLibrary;
        console.log(this.myLibraryFiltered);
      }
      
      this.filteringSearch = false;
    }
  }
  
  ToggleAdvancedMolecules(e: any, library) {
    document.querySelector(`.${ library }-library`).classList.toggle('show-advanced-molecules');
  }
  
  HideAdvancedMolecules() {
    document.querySelector(`.Representative-library`).classList.remove('show-advanced-molecules');
    document.querySelector(`.DataAction-library`).classList.remove('show-advanced-molecules');
    document.querySelector(`.Behavior-library`).classList.remove('show-advanced-molecules');
    document.querySelector(`.Compounds-library`).classList.remove('show-advanced-molecules');
  }
  
  ShowAdvancedMolecules() {
    document.querySelector(`.Representative-library`).classList.add('show-advanced-molecules');
    document.querySelector(`.DataAction-library`).classList.add('show-advanced-molecules');
    document.querySelector(`.Behavior-library`).classList.add('show-advanced-molecules');
    document.querySelector(`.Compounds-library`).classList.add('show-advanced-molecules');
  }
  
  NotificationClick() {
    this.workAreaService.ShowUnsavedDataWindow();
  }
  
  public TrackByName(index, item) {
    if (!item) {
      return null;
    }
    return item.name;
  }
  
  ToggleDebug() {
    const debugMode = this.localStorageService.IsDebug();
    if (debugMode) {
      localStorage.removeItem(`${ Constants.LocalStoragePrefix }debug`);
    } else {
      localStorage.setItem(`${ Constants.LocalStoragePrefix }debug`, JSON.stringify(true));
    }
    
    window.location.reload();
  }
  
  EditCustomControl(molecule: any) {
    console.log(molecule);
    this.templateService.GetTemplate(molecule.id).subscribe(template => {
      this.draggableWindowService.OpenDraggableWindow('Edit Custom Control', DraggableWindowType.CustomControlEditor, null, {
        repMolecule: null,
        editTemplate: template,
        templateId: molecule.id,
      });
    });
  }
  
  DeleteCustomControl(molecule: any) {
    console.log(molecule);
    const templateId = molecule.id;
    this.genericDialogService.OpenConfirmDialog({
      title: 'Delete Custom Control',
      message: `Are you sure you want to delete ${ molecule.name }?, this operation can't be undone.`,
      confirmText: 'Cancel',
      cancelText: 'Delete',
    }).then((result) => {
        if (result) {
        } else {
          this.templateService.DeleteTemplate(templateId).subscribe(result => {
            this.communicationService.Event.Editor.Library.$ReloadLibrary.emit(true);
            this.snackerService.ShowMessageOnBottom('Custom Control removed from library', 'unpublished');
          });
        }
      },
    );
  }
  
  OpenMoleculeDocumentation(molecule: any) {
    // console.log(molecule);
    
    this.toolsService.DragWindowConfig = this.editorStateService.WindowSize('LibraryMoleculeDocumentation');
    
    this.workAreaService.userGuideId = molecule.type;
    this.workAreaService.userGuideType = 'Molecule';
    
    if (this.workAreaService.userGuideWindow) {
      this.communicationService.Event.Documentation.$Get.emit();
    } else {
      this.workAreaService.userGuideWindow = this.draggableWindowService.GenerateWindow(DocumentationComponent, {
        title: `Microlearning`,
        data: {
          id: molecule.type,
          type: 'Molecule',
        },
      });
      
      this.dragableWindowManagerService.ShowWindowAndStore(this.workAreaService.userGuideWindow);
    }
  }
  
  OpenProcessFlowForEmulator() {
    this.draggableWindowService.OpenDraggableWindow(
      'Actual Process Flow (Beta Test)',
      DraggableWindowType.EventsTransaction,
      null,
      {
        processDesign: false,
      },
      false,
      {
        microlearning: {
          title: `Events Process Flow Microlearning`,
          id: 'Actual_Events_Flow_GUI',
          type: 'Guide',
        },
      },
    );
  }
  
  FocusWindow(event: MouseEvent) {
    const htmlWindow = (event.target as any).closest('.floating-window');
    const draggableWindows = document.querySelectorAll('.floating-window');
    draggableWindows.forEach(dw => dw.classList.remove('focus-window'));
    
    if (htmlWindow) {
      htmlWindow.classList.add('focus-window');
    }
  }
  
  ngAfterViewChecked() {
    // // console.log('Change detection triggered!');
  }
  
  MouseEnterTab() {
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
  }
  
  async RemoveCompound(molecule: any) {
    const confirm = await this.genericDialogService.OpenConfirmDialog({
      title: 'Remove Compound',
      message: `Are you sure you want to remove "${ molecule.name }"?`,
      confirmText: 'Delete',
      cancelText: 'Cancel',
    });
    
    if (confirm) {
      this.templateService.DeleteTemplate(molecule.id).subscribe(result => {
        this.snackerService.ShowMessageOnBottom('Compound removed successfully', 'do_not_disturb_on', null, true);
        this.communicationService.Event.Editor.Library.$ReloadLibrary.emit(true);
      });
    }
  }
  
  CreateCustomControl(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    
    this.draggableWindowService.OpenDraggableWindow('Create Custom Control', DraggableWindowType.CustomControlEditor, null, {
      repMolecule: null,
    });
  }
  
  OpenCustomControlDocumentation(molecule: any) {
    console.log(molecule);
    
    const documentation = molecule.documentation;
    
    if (documentation) {
      
      let content = '';
      
      content += `${ documentation.info === '' ? 'No info added yet.' : documentation.info }\n\n`;
      
      if (documentation.events && documentation.events.length > 0) {
        
        content += 'Events:\n\n';
        
        documentation.events.forEach(event => {
          content += `${ event.name }: ${ event.info }\n`;
        });
        
        content += '\n';
      }
      
      if (documentation.receptors && documentation.receptors.length > 0) {
        
        content += 'Receptors:\n\n';
        
        documentation.receptors.forEach(receptor => {
          content += `${ receptor.name }: ${ receptor.info }\n`;
        });
        
        content += '\n';
      }
      
      console.log(content);
      
      this.draggableWindowService.OpenDraggableWindow(`${ molecule.name } Microlearning`, DraggableWindowType.TextComponent, null, {
        title: molecule.name,
        content: content,
      });
    }
  }
  
  OpenCustomControlMicrolearning(event: any) {
    event.preventDefault();
    event.stopPropagation();
    
    this.toolsService.DragWindowConfig = Constants.Defaults.DraggableWindowSizes.Microlearning;
    
    this.workAreaService.userGuideId = 'CustomControls';
    this.workAreaService.userGuideType = 'help';
    
    if (this.workAreaService.userGuideWindow) {
      this.communicationService.Event.Documentation.$Get.emit();
    } else {
      this.draggableWindowService.OpenDraggableWindow(
        `Custom Controls Microlearning`,
        DraggableWindowType.Microlearning,
        null,
        {
          id: this.workAreaService.userGuideId,
          type: this.workAreaService.userGuideType,
        },
      );
    }
  }
  
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
