import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiAdminService } from '../../core/services/api-admin.service';
import { ApiAuthService } from '../../core/services/api-auth.service';
import { ToolsService } from '../../core/services/tools.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  
  constructor(
    private adminService: ApiAdminService,
    private toolsService: ToolsService,
    private authService: ApiAuthService,
    private router: Router,
  ) {
    
    if (this.authService.loggedIn()) {
      this.router.navigate(['/dashboard']);
    } else {
      this.adminService.AuthenticationSettings().subscribe(response => {
        if (response.separateLogin && response.types.length > 0) {
          if (this.toolsService.GetArrayMin(response.types, 'priority').type === 'Native') {
            this.router.navigate(['/login']);
          } else {
            this.router.navigate(['/login/sso']);
          }
        } else {
          this.router.navigate(['/login']);
        }
      });
    }
  }
  
  ngOnInit() {
  }
  
}
