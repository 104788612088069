import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GridsterModule } from '@leapxl/gridster';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { IMaskModule } from 'angular-imask';
import { DynamicModule } from 'ng-dynamic-component';
import { ChartsModule } from 'ng2-charts';
import { DndModule } from 'ng2-dnd';
import { IConfig } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { MoleculesContextMenuComponent } from '../../workarea/molecules-context-menu/molecules-context-menu.component';
import { MaskPipe } from '../../workarea/pipes/mask.pipe';
import { SafeStylePipe } from '../../workarea/pipes/safe-style.pipe';
import { TableRowEmptyValueGeneratorPipe } from '../../workarea/pipes/table-row-empty-value-generator.pipe';
import { SharedModule } from '../shared.module';
import { FilterBySubparentPipe } from './../../workarea/pipes/filter-by-subparent.pipe';
import { MaterialModule } from './../material/material.module';
import { BadgeMoleculeComponent } from './badge-molecule/badge-molecule.component';
import { BreadcrumbMoleculeComponent } from './molecules/breadcrumb-molecule/breadcrumb-molecule.component';
import { ButtonMoleculeComponent } from './molecules/button-molecule/button-molecule.component';
import { ChartMoleculeComponent } from './molecules/chart-molecule/chart-molecule.component';
import { NgxChartsComboSeriesVerticalComponent } from './molecules/chart-molecule/ngx-charts-combo/ngx-charts-combo-series-vertical/ngx-charts-combo-series-vertical.component';
import { NgxChartsComboComponent } from './molecules/chart-molecule/ngx-charts-combo/ngx-charts-combo.component';
import { NgxChartsSparklineComponent } from './molecules/chart-molecule/ngx-charts-sparkline/ngx-charts-sparkline.component';
import { CheckboxMoleculeComponent } from './molecules/checkbox-molecule/checkbox-molecule.component';
import { CustomMoleculeComponent } from './molecules/custom-molecule/custom-molecule.component';
import { DatepickerMoleculeComponent } from './molecules/datepicker-molecule/datepicker-molecule.component';
import { DropdownMoleculeComponent } from './molecules/dropdown-molecule/dropdown-molecule.component';
import { H1MoleculeComponent } from './molecules/h1-molecule/h1-molecule.component';
import { H2MoleculeComponent } from './molecules/h2-molecule/h2-molecule.component';
import { H3MoleculeComponent } from './molecules/h3-molecule/h3-molecule.component';
import { H4MoleculeComponent } from './molecules/h4-molecule/h4-molecule.component';
import { H5MoleculeComponent } from './molecules/h5-molecule/h5-molecule.component';
import { IconMoleculeComponent } from './molecules/icon-molecule/icon-molecule.component';
import { IframeMoleculeComponent } from './molecules/iframe-molecule/iframe-molecule.component';
import { ImageMoleculeComponent } from './molecules/image-molecule/image-molecule.component';
import { LabelMoleculeComponent } from './molecules/label-molecule/label-molecule.component';
import { ProgressMoleculeComponent } from './molecules/progress-molecule/progress-molecule.component';
import { QrCodeMoleculeComponent } from './molecules/qrcode-molecule/qrcode-molecule.component';
import { RadioMoleculeComponent } from './molecules/radio-molecule/radio-molecule.component';
import { SliderMoleculeComponent } from './molecules/slider-molecule/slider-molecule.component';
import { StepperMoleculeComponent } from './molecules/stepper-molecule/stepper-molecule.component';
import { TableMoleculeComponent } from './molecules/table-molecule/table-molecule.component';
import { TextareaMoleculeComponent } from './molecules/textarea-molecule/textarea-molecule.component';
import { TextboxMoleculeComponent } from './molecules/textbox-molecule/textbox-molecule.component';
import { WorkgroupMoleculeComponent } from './molecules/workgroup-molecule/workgroup-molecule.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
  specialCharacters: ['-', '/', '(', ')', '.', ':', ' ', '+', ',', '@', '[', ']', '"', '\'', '$', '%'],
  thousandSeparator: ',',
  leadZeroDateTime: true,
};

@NgModule({
  imports: [
    CommonModule,
    DndModule,
    MaterialModule,
    FormsModule,
    NgxPaginationModule,
    GridsterModule,
    NgxChartsModule,
    ChartsModule,
    DynamicModule,
    // NgxMaskModule.forRoot(maskConfig),
    SharedModule,
    IMaskModule,
  ],
  
  declarations: [
    SafeStylePipe,
    NgxChartsSparklineComponent,
    MaskPipe,
    NgxChartsComboComponent,
    ButtonMoleculeComponent,
    BadgeMoleculeComponent,
    BreadcrumbMoleculeComponent,
    IconMoleculeComponent,
    WorkgroupMoleculeComponent,
    TextboxMoleculeComponent,
    TextareaMoleculeComponent,
    LabelMoleculeComponent,
    H1MoleculeComponent,
    H2MoleculeComponent,
    H3MoleculeComponent,
    H4MoleculeComponent,
    DropdownMoleculeComponent,
    IframeMoleculeComponent,
    H5MoleculeComponent,
    DatepickerMoleculeComponent,
    MoleculesContextMenuComponent,
    ChartMoleculeComponent,
    ImageMoleculeComponent,
    CheckboxMoleculeComponent,
    TableMoleculeComponent,
    RadioMoleculeComponent,
    StepperMoleculeComponent,
    TableRowEmptyValueGeneratorPipe,
    FilterBySubparentPipe,
    NgxChartsComboComponent,
    NgxChartsComboSeriesVerticalComponent,
    QrCodeMoleculeComponent,
    ProgressMoleculeComponent,
    SliderMoleculeComponent,
    CustomMoleculeComponent,
  ],
  entryComponents: [
    ButtonMoleculeComponent,
    BadgeMoleculeComponent,
    BreadcrumbMoleculeComponent,
    IconMoleculeComponent,
    WorkgroupMoleculeComponent,
    TextboxMoleculeComponent,
    CustomMoleculeComponent,
    TextareaMoleculeComponent,
    LabelMoleculeComponent,
    DatepickerMoleculeComponent,
    DropdownMoleculeComponent,
    StepperMoleculeComponent,
    H1MoleculeComponent,
    H2MoleculeComponent,
    H3MoleculeComponent,
    H4MoleculeComponent,
    H5MoleculeComponent,
    ChartMoleculeComponent,
    ImageMoleculeComponent,
    IframeMoleculeComponent,
    CheckboxMoleculeComponent,
    TableMoleculeComponent,
    RadioMoleculeComponent,
    QrCodeMoleculeComponent,
    ProgressMoleculeComponent,
    SliderMoleculeComponent,
  ],
  exports: [MoleculesContextMenuComponent],
})
export class RepresentativeMoleculeModule {
}
