import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CommunicationService } from '../../../shared/services/communication.service';
import { BottomSheetOption } from '../../interfaces/bottom-sheet-option.interface';
import { BottomSheetService } from '../bottom-sheet.service';

@Component({
  selector: 'app-bottom-sheet-options',
  templateUrl: './bottom-sheet-options.component.html',
  styleUrls: ['./bottom-sheet-options.component.scss'],
})
export class BottomSheetOptionsComponent implements OnInit {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetOptionsComponent>,
    public bottomSheetService: BottomSheetService,
    private communicationService: CommunicationService,
  ) {
  }
  
  ngOnInit() {
  }
  
  OptionSelected(event: MouseEvent, option: BottomSheetOption): void {
    this.communicationService.Event.Editor.$BottomSheetOptionSelected.emit(option);
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
}
