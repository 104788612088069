import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiPropertiesService } from '../../core/services/api-properties.service';

@Component({
  selector: 'app-unsaved-data',
  templateUrl: './unsaved-data.component.html',
  styleUrls: ['./unsaved-data.component.scss'],
})
export class UnsavedDataComponent implements OnInit, OnDestroy {
  unsavedChanges = [];
  elementsAffected = 0;
  totalChanges = 0;
  
  constructor(private propertiesService: ApiPropertiesService) {
  }
  
  ngOnInit() {
    const unsavedChanges = this.propertiesService.GetUnsavedProperties();
    console.log('this.unsavedChanges', unsavedChanges);
    
    unsavedChanges.forEach(uc => {
      uc.properties.forEach(property => {
        if (Array.isArray(property.value)) {
          property.value = `[Internal ${ property.change } value for ${ property.name }]`;
        }
      });
    });
    
    this.unsavedChanges = unsavedChanges;
    
    this.elementsAffected = this.unsavedChanges.length;
    this.totalChanges = this.unsavedChanges.reduce((a, b) => a + (b.properties.length || 0), 0);
  }
  
  ngOnDestroy(): void {
  }
}
