import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProcessorService } from '../../../core/molecular/services/processor.service';
import { DevToolsService } from '../../../core/services/dev-tools.service';
import { ToolsService } from '../../../core/services/tools.service';
import { DatasourceType } from '../../../shared/enums/datasource-type.enum';
import { ActionMolecule } from '../../../shared/representative-molecule/interfaces/action-molecules';
import { RepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule';
import { CommunicationService } from '../../../shared/services/communication.service';
import { WorkAreaService } from '../../../workarea/workarea.service';
import { FieldConfig } from '../../models/field-config.model';

@Component({
  selector: 'app-dynamic-label',
  template: `
	  <div class="panel-viewer-title">
		  <div class="panel-viewer-title-spacer">
			  <i
				  *ngIf="workAreaService.elementsSelected.length < 2 && devToolsService.Enabled"
				  (click)="devToolsService.ToggleParticleBreakpoint(this.particle.GlobalIdentifier(), this.config.busId, 'end')"
				  matTooltip="Breakpoint"
				  class="material-icons-round molecule-icon breakpoint"
				  [ngClass]="{'breakpoint-set': this.devToolsService.HasBreakpointForBus(this.particle.GlobalIdentifier(), this.config.busId)}"
			  >stop_circle</i>
			  <i class="material-icons-round molecule-icon">{{ config.icon }}</i>
			  <b>{{ config.label }}</b> <i
			  *ngIf="requestServer"
			  matTooltip="Requests data from the server"
			  style="color: blue;"
			  class="material-icons-round molecule-icon"
		  >import_export</i>
			  <ng-container *ngIf="!toolsService.RunningMode">
				  <i
					  *ngIf="config.isMoleculeSetting"
					  (click)="RemoveMolecule()"
					  class="material-icons-round remove-molecule action-icons"
					  matTooltip="Remove Molecule"
				  >close</i
				  >
				  <i
					  *ngIf="config.isMoleculeSetting"
					  (click)="DuplicateMolecule()"
					  class="material-icons-round remove-molecule action-icons duplicate-icon"
					  matTooltip="Duplicate Molecule"
				  >filter_2</i
				  >
				  <i
					  *ngIf="config.isMoleculeSetting"
					  (click)="ToggleMolecule(config.particleId)"
					  [matTooltip]="isMoleculeClosed ? 'Open Molecule' : 'Close Molecule'"
					  class="material-icons-round remove-molecule action-icons"
				  >
					  {{ isMoleculeClosed ? 'unfold_more' : 'unfold_less' }}
				  </i>
			  </ng-container>
		  </div>
	  </div>
  `,
  styleUrls: ['./dynamic-label.component.scss'],
})
export class DynamicLabelComponent implements OnInit {
  config: FieldConfig;
  group: FormGroup;
  particle: ActionMolecule;
  requestServer = false;
  isMoleculeClosed = false;
  
  constructor(public workAreaService: WorkAreaService,
              private processorService: ProcessorService,
              private communicationService: CommunicationService,
              public devToolsService: DevToolsService,
              public toolsService: ToolsService,
              public workareaService: WorkAreaService,
  ) {
  }
  
  DuplicateMolecule() {
    const repMolecule = (this.config.value.element as RepresentativeMolecule);
    const particle = repMolecule.GetParticle(this.config.particleId);
    if (particle && particle.IsActionMolecule()) {
      repMolecule.DuplicateParticle(particle.ParticleId);
      
    }
  }
  
  RemoveMolecule() {
    // console.log('removing label');
    this.devToolsService.RemoveParticleBreakpoint(this.particle.GlobalIdentifier(), this.config.busId, 'end');
    
    if (this.workAreaService.elementsSelected.length > 1) {
      this.workAreaService.RemoveParticleFromAllElements(this.config.particleId, this.config.busId);
    } else {
      let containsDataElements = false;
      const repMolecule = (this.config.value.element as RepresentativeMolecule);
      repMolecule.MarkAsTouched();
      const particle = repMolecule.GetParticle(this.config.particleId);
      if (particle && particle.IsActionMolecule()) {
        const actionMolecule = (particle as ActionMolecule);
        containsDataElements = actionMolecule.DataElements.length > 0;
        this.communicationService.Event.Editor.$ActionMoleculeAdded.emit({ actionMolecule, repMolecule });
        this.communicationService.Event.Editor.$RepresentativeMoleculeDetection.emit({ repMoleculeId: +repMolecule.Id, state: true });
      }
      repMolecule.GetBus(this.config.busId).RemoveParticle(this.config.particleId);
      repMolecule.RefreshDatasourceConnected();
      if (containsDataElements) {
        this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanel.emit(true);
        repMolecule.FireDataSourceBus();
      }
      repMolecule.SaveProperty('buses', 'Molecule Removed').subscribe();
    }
    
    this.communicationService.Event.Editor.$ParticleRemoved.emit(true);
  }
  
  ToggleMolecule(particleId: string) {
    this.isMoleculeClosed = !this.isMoleculeClosed;
    this.workAreaService.moleculeState[particleId] = this.isMoleculeClosed;
    this.communicationService.Event.Editor.$ToggleBusMolecule.emit({ isMoleculeClosed: this.isMoleculeClosed, particleId });
  }
  
  ngOnInit() {
    console.log(this.config.value);
    const repMolecule = (this.config.value.element as RepresentativeMolecule);
    this.particle = repMolecule.GetActionMoleculeParticleByParticleId(this.config.particleId);
    this.requestServer = !this.config.value.properties.bypassGetDataElements && this.particle && this.particle.DataElements.filter(de => de.DataSourceType !== DatasourceType.LocalDataStore && de.DataSourceType !== DatasourceType.Custom).length > 0;
    this.isMoleculeClosed = this.workareaService.moleculeState?.[this.particle.ParticleId] ?? false;
  }
  
}
