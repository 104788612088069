import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SnackerService } from '../../shared/services/snacker.service';
import { ToolsService } from '../services/tools.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class BackgroundTaskInterceptor implements HttpInterceptor {
  
  constructor(private toolsService: ToolsService, private snackerService: SnackerService) {
  }
  
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const taskMessage = req.headers.get('LeapXL-BackgroundTaskMessage');
    const taskIcon = req.headers.get('LeapXL-BackgroundTaskIcon');
    const taskId = req.headers.get('LeapXL-RequestId');
    
    if (taskMessage && taskMessage !== '') {
      this.snackerService.ToggleBackgroundTask(taskId, taskMessage, taskIcon);
    }
    
    return next.handle(req).pipe(
      finalize(() => {
        // loaderService.hide();
        this.snackerService.ToggleBackgroundTask(taskId);
      }),
    );
  }
  
}
