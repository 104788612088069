import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ConfigurationLoaderService } from './configuration-loader.service';


export function loadConfiguration(configService: ConfigurationLoaderService) {
  return () => configService.loadConfiguration();
}

@NgModule({
  imports: [HttpClientModule],
  declarations: [],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfiguration,
      deps: [ConfigurationLoaderService],
      multi: true
    }
  ]
})
export class ConfigurationModule {}
