import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { DevToolsService } from '../../../core/services/dev-tools.service';
import { ToolsService } from '../../../core/services/tools.service';
import { Constants } from '../../../shared/constants';
import { DataElement } from '../../../shared/representative-molecule/interfaces/data-element';
import { CommunicationService } from '../../../shared/services/communication.service';
import { DraggableWindowService } from '../../../shared/services/draggable-window.service';

@Component({
  selector: 'app-dev-tools-data',
  templateUrl: './dev-tools-data.component.html',
  styleUrls: ['./dev-tools-data.component.scss'],
})
export class DevToolsDataComponent implements OnInit, OnDestroy {
  @Input() data;
  dataSet = [];
  subscriptions: Subscription;
  isDetached: boolean = false;
  
  constructor(
    private toolsService: ToolsService,
    private devToolsService: DevToolsService,
    private draggableWindowService: DraggableWindowService,
    private renderer: Renderer2,
    private communicationService: CommunicationService,
    private elRef:ElementRef
  ) {
    if (this.data) {
    } else {
      const data = this.draggableWindowService.GetData();
      this.data = data.data;
      this.isDetached = data.isDetached ?? false;
    }
    this.DisplayData();
  }
  
  ngOnInit(): void {
    this.SetDevToolsDataHeight();
    
    this.subscriptions = this.communicationService.Event.System.$WindowResized?.subscribe(({ height, width, id }) => {
      const window = document.querySelector(`#${ id }`);
      
      const elements = window.querySelectorAll('.data-container');
      
      elements.forEach(element => {
        const elementDevToolsType = element.getAttribute('dev-tools-type');
        if (elementDevToolsType === 'detached') {
          this.renderer.setStyle(element, 'max-height', `${ height - 40 }px`);
        } else {
          this.renderer.setStyle(element, 'max-height', `${ height - 90 }px`);
        }
      });
    });
  }
  
  DisplayData() {
    this.dataSet = [];
    
    let processData = null;
    
    if (this.data) {
      processData = this.data;
    } else {
      const processMetadata = this.devToolsService.GetPausedProcessMetadata();
      
      if (processMetadata) {
        processData = processMetadata.data;
      } else {
        processData = '';
        console.warn('no data to display');
      }
    }
    
    const dataByType = this.toolsService.ExtractValuesByType(processData);
    
    if (dataByType.array.length > 0) {
      
      dataByType.array.forEach(de => {
        this.dataSet.push({
          value: de.formattedValue,
          dataElement: new DataElement(de),
        });
      });
      
    } else if (Object.keys(dataByType.object).length > 0) {
      
      this.dataSet.push({
        value: dataByType.object.value,
        dataElement: new DataElement(dataByType.object),
      });
      
    } else if (dataByType.string != '') {
      this.dataSet.push({
        value: dataByType.string,
        dataElement: new DataElement(),
      });
    }
    
    this.SetDevToolsDataHeight();
  }
  
  SetDevToolsDataHeight() {
    const draggableWindowContainer = document.querySelector('.draggable-window-container');
    
    const height = draggableWindowContainer.clientHeight;
    
    const elements = this.SetDevToolsType();
    
    if (this.isDetached) {
      elements.forEach(element => {
        this.renderer.setStyle(element, 'max-height', `${ Constants.Defaults.DraggableWindowSizes.DevToolsBreakpointData.height - 40 }px`);
      });
    } else {
      elements.forEach(element => {
        this.renderer.setStyle(element, 'max-height', `${ (height ? height : Constants.Defaults.DraggableWindowSizes.DevTools.height) - 90 }px`);
      });
    }
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  
  SetDevToolsType() {
    const container = this.elRef.nativeElement.querySelector('.data-container');
    
    if (container) {
      let elements: NodeList;
      
      if (this.isDetached) {
        this.renderer.setAttribute(container, 'dev-tools-type', 'detached');
        elements = document.querySelectorAll('div[dev-tools-type="detached"]');
      } else {
        this.renderer.setAttribute(container, 'dev-tools-type', 'attached');
        elements = document.querySelectorAll('div[dev-tools-type="attached"]');
      }
      
      return elements;
    } else {
      return [];
    }
  }
}
