import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AnimationsService } from '../../../core/services/animations.service';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { ToolsService } from '../../../core/services/tools.service';
import { WorkAreaService } from '../../../workarea/workarea.service';
import { Bus } from '../../representative-molecule/interfaces/bus';
import { CommunicationService } from '../../services/communication.service';
import { DraggableWindowManagerService } from '../../services/draggable-window-manager.service';

@Component({
  selector: 'draggable-window',
  templateUrl: './draggable-window.component.html',
  styleUrls: ['./draggable-window.component.scss'],
  animations: [
    trigger('enterUp', [
      transition(':enter', [
        style({ transform: 'translateY(10%)', opacity: 0 }),
        animate('400ms', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('100ms', style({ transform: 'translateY(-5%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class DraggableWindowComponent implements OnInit, OnDestroy {
  inBounds = true;
  bounds = null;
  outOfBounds = {
    top: false,
    bottom: false,
    right: false,
    left: false,
  };
  isResizeable = true;
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true,
  };
  resizeHanders = 'se';
  windowHeight = `${ window.innerHeight - 120 }px`;
  windowWidth = 'min-content';
  maxWidth = `${ window.innerWidth - 200 }px`;
  data: any;
  componentId: string;
  public id: string;
  public show = false;
  title: string;
  ref: any;
  position = {
    x: 400,
    y: 42,
  };
  initWidth = 300;
  initHeight = 'auto';
  footer: false;
  actualSize = {
    x: null,
    y: null,
    width: null,
    height: null,
  };
  microlearning: {
    type: string;
    id: string;
    title: string;
  } = null;
  changeLayout = false;
  icon = '';
  shortcut = '';
  receptorBus: Bus;
  debug = false;
  subscriptions = new Subscription();
  
  constructor(
    private toolsService: ToolsService,
    private animationService: AnimationsService,
    public dragableManager: DraggableWindowManagerService,
    private communicationService: CommunicationService,
    private workareaService: WorkAreaService,
    private localStorageService: LocalStorageService,
  ) {
    this.debug = this.localStorageService.IsDebug();
    const windowConfig = this.toolsService.DragWindowConfig || null;
    windowConfig.x = windowConfig.x || this.position.x;
    windowConfig.y = windowConfig.y || this.position.y;
    windowConfig.width = windowConfig.width || this.initWidth;
    this.changeLayout = windowConfig && windowConfig.changeLayout;
    this.icon = windowConfig.icon || '';
    this.isResizeable = windowConfig.resizable === false ? false : true;
    this.shortcut = windowConfig.shortcut || '';
    
    if (windowConfig.center) {
      windowConfig.y = 100;
      windowConfig.x = (window.innerWidth / 2) - (windowConfig.width / 2);
    }
    
    this.microlearning = windowConfig.microlearning || null;
    
    this.id = 'dw-' + this.toolsService.GenerateGuid();
    
    this.bounds = document.querySelector('#grid-main-container') || document.querySelector('.work-area-content');
    console.log('position', windowConfig);
    if (windowConfig) {
      this.position = windowConfig;
      this.initWidth = this.toolsService.DragWindowConfig.width || this.initWidth;
      
      // console.log(this.initHeight);
      if (this.toolsService.DragWindowConfig.height) {
        this.initHeight = this.toolsService.DragWindowConfig.height + 'px' || this.initHeight;
        this.actualSize.height = this.toolsService.DragWindowConfig.height || this.initHeight;
      } else {
        if (this.toolsService.DragWindowConfig.autoHeight) {
        } else {
          setTimeout(() => {
            const dragWindowHtmlElement = document.querySelector(`#${ this.id }`);
            
            if (dragWindowHtmlElement) {
              const size = dragWindowHtmlElement.getBoundingClientRect();
              
              this.initHeight = size.height + 'px';
              this.actualSize.height = size.height;
            }
          }, 2000);
        }
      }
    } else if (this.toolsService.mousePosition) {
      this.position = {
        x:
          this.toolsService.mousePosition.x + 100 < 0
            ? 60
            : this.toolsService.mousePosition.x + 100 + 400 > window.innerWidth
              ? window.innerWidth - 400
              : this.toolsService.mousePosition.x + 100,
        y: this.toolsService.mousePosition.y - 100 < 0 ? 60 : this.toolsService.mousePosition.y - 100,
      };
    }
    
    this.position.x = this.position.x > window.innerWidth - 400 ? 400 : this.position.x;
    this.position.y = this.position.y < 0 ? 100 : this.position.y;
    this.actualSize.x = this.position.x;
    this.actualSize.y = this.position.y;
    this.actualSize.width = this.initWidth;
  }
  
  ngOnInit(): void {
    const draggableWindows = document.querySelectorAll('.floating-window');
    draggableWindows.forEach((dw) => dw.classList.remove('focus-window'));
    
    console.log(this.data);
  }
  
  checkEdge(event) {
    this.edge = event;
  }
  
  Show() {
    this.dragableManager.show(this.ref);
  }
  
  FocusWindow(event: MouseEvent) {
    const htmlWindow = (event.target as any).closest('.floating-window');
    const draggableWindows = document.querySelectorAll('.floating-window');
    draggableWindows.forEach((dw) => dw.classList.remove('focus-window'));
    
    if (htmlWindow) {
      htmlWindow.classList.add('focus-window');
    }
  }
  
  AddToOpenedWindows(): boolean {
    if (this.dragableManager.openedWindows.includes(`${ this.title }`)) {
      return false;
    } else {
      this.dragableManager.openedWindows.push(`${ this.title }`);
      return true;
    }
  }
  
  EvaluateWindowBounds() {
    
    const element = document.getElementById(this.id);
    
    if (element) {
      const coordenates = this.toolsService.GetTranslateXY(element);
      let xOutOfBounds = false;
      
      if (coordenates.translateX + this.actualSize.width > window.innerWidth) {
        xOutOfBounds = true;
        this.position.x = window.innerWidth - (this.actualSize.width + 100);
        this.actualSize.x = this.position.x;
        this.position = { ...this.position };
      }
      
      if (coordenates.translateY + this.actualSize.height > window.innerHeight) {
        this.position.y = 100;
        this.position.x = xOutOfBounds ? this.position.x : coordenates.translateX;
        this.actualSize.y = this.position.y;
        this.position = { ...this.position };
      }
    }
  }
  
  appear() {
    this.show = true;
    // console.log('id', this.id);
    // this.animationService.PulseHTMLElement(this.id);
  }
  
  SetPosition(position: { x: number; y: number }) {
    this.position = position;
    document.getElementById(this.id).style.transform = `translate(${ position.x }px, ${ position.y < 20 ? 55 : position.y }px)`;
  }
  
  WorkareaDetection(enable: boolean) {
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(enable);
  }
  
  closeWindow() {
    const component = this.dragableManager.WindowsComponents[this.componentId];
    if (component && component.ngOnDestroy) {
      component.ngOnDestroy();
    }
    this.animationService.FadeHTMLElement(this.id, () => {
      this.dragableManager.hide(this.ref);
    });
    this.communicationService.Event.Editor.WorkArea.$DraggableWindowClosed.emit(this.componentId);
  }
  
  ChangeLayout() {
    this.workareaService.windowHorizontalLayout = !this.workareaService.windowHorizontalLayout;
  }
  
  DragEnd(event: any) {
    console.log('drag end', event.computedStyleMap().get('transform')[0], this.position);
    this.actualSize.x = event.computedStyleMap().get('transform')[0].x.value;
    this.actualSize.y = event.computedStyleMap().get('transform')[0].y.value - 50;
    this.WorkareaDetection(true);
    this.DraggableWindowChange();
  }
  
  ResizeEnd(event: any) {
    console.log('resize end', event);
    this.communicationService.Event.System.$WindowResized.emit({ height: event.size.height, width: event.size.width, id: this.id });
    this.WorkareaDetection(true);
    this.actualSize.height = event.size.height;
    this.actualSize.width = event.size.width;
    
    this.EvaluateBounds();
    this.DraggableWindowChange();
  }
  
  DraggableWindowChange() {
    if (this.dragableManager.WindowsComponents[this.componentId]) {
      if (this.dragableManager.WindowsComponents[this.componentId].DraggableWindowSizeChanged) {
        this.dragableManager.WindowsComponents[this.componentId].DraggableWindowSizeChanged(this.actualSize);
      }
      
      this.communicationService.Event.Editor.$DraggableWindowChange.emit({
        window: this.dragableManager.WindowsComponents[this.componentId].DraggableWindowType,
        size: this.actualSize,
      });
    }
  }
  
  OpenMicrolearning() {
    this.communicationService.Event.Documentation.$OpenWindow.emit(this.microlearning);
  }
  
  EvaluateBounds() {
    let positionChanged = false;
    
    const windowHtml = document.querySelector(`#${ this.id }`);
    const windowStyle = window.getComputedStyle(windowHtml);
    const matrixStyle = new WebKitCSSMatrix(windowStyle.transform);
    
    const htmlSize = {
      x: matrixStyle.m41,
      y: matrixStyle.m42,
      width: +windowStyle.width.replace('px', ''),
      height: +windowStyle.height.replace('px', ''),
    };
    
    if (this.actualSize.x <= 20 || htmlSize.x <= 20) {
      this.actualSize.x = 50;
      positionChanged = true;
    }
    
    if (this.actualSize.y <= 50 || htmlSize.y <= 50) {
      this.actualSize.y = 10;
      positionChanged = true;
    }
    
    if (positionChanged) {
      this.actualSize.y = this.actualSize.y + 50;
      this.SetPosition(this.actualSize);
    }
  }
  
  ngOnDestroy(): void {
    this.dragableManager.openedWindows = this.dragableManager.openedWindows.filter((e) => e !== `${ this.title }`);
    this.subscriptions.unsubscribe();
  }
}
