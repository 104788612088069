import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Constants } from '../../shared/constants';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class HttpHttpsInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    console.log('ssl interceptors');
    let request: HttpRequest<any>;
    request = req.clone({
      url: Constants.Environment.https
        ? req.url.replace('http:', 'https:')
        : req.url.replace('https:', 'http:'),
    });
    return next.handle(request);
  }
}
