import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiHierarchyService } from '../core/services/api-hierarchy.service';
import { ClientStorageService } from '../core/services/client-storage.service';
import { HierarchyLevel } from '../shared/models/hierarchylevel.model';

@Component({
  selector: 'welcome-licensed',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  userName: string;
  companyId: number;
  treeGotNodes = false;
  usersAttachedToHierarchy = false;
  cobblerIsReady = false;
  
  constructor(
    private clientStorageService: ClientStorageService,
    private hierarchyService: ApiHierarchyService,
    private router: Router,
  ) {
    this.userName = this.clientStorageService.getUserName();
    this.companyId = this.clientStorageService.getCompanyId();
  }
  
  ngOnInit(): void {
    this.hierarchyService.getTree(this.companyId).subscribe(tree => {
      this.hierarchyService.treeChanged.next(tree);
    });
    this.hierarchyService.treeChanged.subscribe(
      (hierarchyLevel: HierarchyLevel) => {
        this.treeGotNodes = hierarchyLevel ? true : false;
      },
    );
    this.hierarchyService.users$.subscribe(users => {
      this.usersAttachedToHierarchy = this.hierarchyService.areUsersAttachedToHierarchy();
      this.cobblerIsReady = Boolean(
        Number(sessionStorage.getItem('cobblerIsReady')),
      );
      if (!this.cobblerIsReady && !this.usersAttachedToHierarchy) {
        return;
      }
      if (this.cobblerIsReady) {
        if (!this.usersAttachedToHierarchy) {
          this.cobblerIsReady = false;
          sessionStorage.setItem('cobblerIsReady', '0');
          this.hierarchyService.changeCompanyState(this.companyId);
        }
      } else {
        if (this.usersAttachedToHierarchy) {
          this.cobblerIsReady = true;
          sessionStorage.setItem('cobblerIsReady', '1');
          this.hierarchyService.changeCompanyState(this.companyId).subscribe();
        }
      }
    });
  }
  
  //   finish(): void {
  //     this.hierarchyService.finish(this.companyId).subscribe();
  //   }
  goToCobbles(): void {
    this.router.navigate(['/workarea']);
  }
}
