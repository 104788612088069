export class User {
  constructor(
    public id: string,
    public email: string,
    public userName: string,
    public password: string,
    public photoUrl: string,
    public firstName: string,
    public middleName: string,
    public name: string,
    public lastName: string,
    public phone: string,
    public companyId: number,
  ) {
  }
}
