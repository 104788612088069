import { Injectable, OnDestroy } from '@angular/core';
import { cloneDeep, merge } from 'lodash-es';
import { from, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApiPropertiesService } from '../../core/services/api-properties.service';
import { ToolsService } from '../../core/services/tools.service';
import { PropertyVersioningDto } from '../../shared/dtos/versioning-dto';
import { LeapXLFileFormat } from '../../shared/enums/leapxl-file-format.enum';
import { IRepresentativeMoleculeStyleData } from '../../shared/interfaces/rep-mol-style.interface';
import { Theme } from '../../shared/interfaces/theme.interface';
import { Cobble } from '../../shared/representative-molecule/interfaces/cobble';
import { IRepresentativeMolecule } from '../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { WorkAreaService } from '../workarea.service';
import { ApiThematicService } from './api-thematic.service';

@Injectable({
  providedIn: 'root',
})
export class ThematicService implements OnDestroy {
  
  // region Library
  StylesLibrary: IRepresentativeMoleculeStyleData[] = [];
  // endregion
  styleClipboard: IRepresentativeMoleculeStyleData = null;
  SectionProperties = {
    dimension: ['cols', 'rows', 'colsQty', 'rowsQty'],
    font: [
      'font',
      'textDecoration',
    ],
    appearance: [
      'opacity',
      'shadowValues',
    ],
    hover: ['hover'],
    frame: ['background', 'bordersValues'],
  };
  
  ApplyingStyle = false;
  subscription = new Subscription();
  // region IGNORE
  private ignoredProperties = [
    'location',
    'options',
    'show',
    'altText',
    'tabindex',
    'dateRange',
    'id',
    'layer',
    'x',
    'y',
    'name',
    'view',
    'textToDisplay',
    'tooltip',
    'placeholder',
    'minItemCols',
    'maxItemCols',
  ];
  
  // endregion
  private allProperties = [
    'align',
    'allowEmptyValues',
    'chartLibrary',
    'chartType',
    'enable',
    'icon',
    'ignoreValueDataIndex',
    'legend',
    'loadingText',
    'multiSelect',
    'tableOptions',
    'orientation',
    'preferences',
    'search',
    'tableOrder',
    'wrapText',
  ];
  
  constructor(
    private toolsService: ToolsService,
    private workAreaService: WorkAreaService,
    private apiThematicService: ApiThematicService,
    private cobbleService: CobbleService,
    private communicationService: CommunicationService,
    private propertiesService: ApiPropertiesService,
    private snackerService: SnackerService,
  ) {
    this.subscription.add(this.communicationService.Event.Editor.WorkArea.$RefreshStylesLibrary.pipe(debounceTime(400)).subscribe(e => {
      
      console.log('=event=');
      this.GetLibrary();
    }));
    
    this.subscription.add(
      this.communicationService.Event.Editor.$SetAppTheme.subscribe(data => {
        console.log('=event=');
        this.ApplyThemeToApp(data.theme, data.app).subscribe(() => {
          this.snackerService.ShowMessageOnBottom(`Theme "${ data.theme.name }" Applied`, 'add_circle', null, true);
        });
      }),
    );
  }
  
  get UserLibrary() {
    return this.StylesLibrary.filter(s => s.category === 'User').sort(this.toolsService.CompareValues('name'));
  }
  
  get CompanyLibrary() {
    return this.StylesLibrary.filter(s => s.category === 'CompanyShared').sort(this.toolsService.CompareValues('name'));
  }
  
  get ApplicationLibrary() {
    return this.StylesLibrary.filter(s => s.category === 'Application').sort(this.toolsService.CompareValues('name'));
  }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  
  ShareStyleWithCompany(styleId: number, shared: boolean) {
    this.apiThematicService.ShareStyle(styleId, shared).subscribe();
  }
  
  GetLibrary() {
    this.StylesLibrary = [];
    this.apiThematicService.GetAllStyles().subscribe(stylesResponse => {
      this.StylesLibrary = stylesResponse;
    });
  }
  
  GetStyle(styleId: number): IRepresentativeMoleculeStyleData {
    return this.StylesLibrary.find(s => s.styleId === styleId);
  }
  
  AssignStyleToRepresentativeMolecule(styleId: number, repMoleculeId: number) {
    this.apiThematicService.AssignStyleToRepresentativeMolecule(styleId, repMoleculeId, this.cobbleService.Cobble.id).subscribe();
  }
  
  AssignStyleToRepresentativeMolecules(assign: { styleId: number, repMoleculeId: number }[]) {
    
    const data = assign.map(a => {
      return {
        styleId: a.styleId,
        moleculeId: a.repMoleculeId,
        applicationId: this.cobbleService.Cobble.id,
      };
    });
    
    this.apiThematicService.AssignStyleToRepresentativeMolecules(data).subscribe();
  }
  
  UnAssignThemeFromApp(themeId: number, appId: number) {
    this.apiThematicService.UnAssignThemeFromApp(themeId, appId).subscribe();
  }
  
  AssignThemeToApp(themeId: number, appId: number) {
    this.apiThematicService.AssignThemeToApp(themeId, appId).subscribe();
  }
  
  UnAssignStyleFromRepresentativeMolecule(styleId: number, repMoleculeId: number) {
    this.apiThematicService.UnAssignStyleFromRepresentativeMolecule(styleId, repMoleculeId).subscribe();
  }
  
  ObtainRepresentativeMoleculeStyle(repMolecule: IRepresentativeMolecule, section = 'all', offsetRepresentativeMolecule: IRepresentativeMolecule = null): IRepresentativeMoleculeStyleData {
    let properties = cloneDeep(repMolecule.Properties);
    properties = this.ObtainSectionStyleFromProperties(properties, section, offsetRepresentativeMolecule ? cloneDeep(offsetRepresentativeMolecule.Properties) : null);
    return {
      representativeMoleculeType: repMolecule.Type,
      properties: properties,
      section,
      styleId: null,
      name: repMolecule.Properties.name + ` - ${ section.toLocaleUpperCase() } Style`,
    };
  }
  
  ObtainSectionStyleFromProperties(properties: any, section = 'all', offsetProperties: any = null) {
    // calc offset position
    if (offsetProperties) {
      this.ObtainOffset(properties, offsetProperties);
    }
    
    this.RemovePropertiesFromObject(this.ignoredProperties, properties);
    
    if (section === 'all') {
    
    } else if (section === 'basic') {
      const sectionProperties = this.SectionProperties['dimension'] as string[];
      this.RemovePropertiesFromObject(sectionProperties, properties);
    } else {
      for (const sectionProperty in this.SectionProperties) {
        if (sectionProperty !== section) {
          // remove unwanted sections
          const sectionProperties = this.SectionProperties[sectionProperty] as string[];
          this.RemovePropertiesFromObject(sectionProperties, properties);
        }
      }
      
      this.RemovePropertiesFromObject(this.allProperties, properties);
    }
    return properties;
  }
  
  ObtainOffset(originalProperties: any, offsetProperties: any) {
    if (originalProperties.responsive) {
      const deviceTypes = ['desktop', 'smartphone'];
      
      deviceTypes.forEach(deviceType => {
        const originalResponsiveProperties = originalProperties.responsive[deviceType];
        const offsetResponsiveProperties = offsetProperties.responsive[deviceType];
        
        if (originalResponsiveProperties && offsetResponsiveProperties) {
          const xOffset = (offsetResponsiveProperties.x - (originalResponsiveProperties.x));
          const yOffset = (offsetResponsiveProperties.y - (originalResponsiveProperties.y));
          originalResponsiveProperties.xOffset = xOffset;
          originalResponsiveProperties.yOffset = yOffset;
        }
      });
    }
  }
  
  PasteStyle(repMolecule: IRepresentativeMolecule) {
    const backupStyles = cloneDeep(repMolecule.StyleMetadata);
    repMolecule.ResetStyles();
    const style = this.styleClipboard;
    if (style) {
      this.SetStyle(style, repMolecule);
      repMolecule.BackupProperties();
      backupStyles.manualAdjustedProperties = [];
      repMolecule.StyleMetadata = backupStyles;
      repMolecule.SaveProperty('styleMetadata', 'Clear style').subscribe();
      repMolecule.ApplyAssignedStyle();
    }
  }
  
  VerifyStyleUses(styleId: number) {
    return this.apiThematicService.VerifyStyleUses(styleId);
  }
  
  VerifyThemeUses(themeId: number) {
    return this.apiThematicService.VerifyThemeUses(themeId);
  }
  
  
  DeleteStyle(styleId: number) {
    this.StylesLibrary = this.StylesLibrary.filter(s => s.styleId !== styleId);
    this.apiThematicService.DeleteStyle(styleId).subscribe();
  }
  
  SaveStyle(styleData: IRepresentativeMoleculeStyleData) {
    this.apiThematicService.SaveStyle(styleData).subscribe(createdStyle => {
      const styleExists = this.StylesLibrary.find(s => s.styleId === createdStyle.styleId);
      if (styleExists) {
        styleExists.properties = createdStyle.properties;
        styleExists.name = createdStyle.name;
      } else {
        this.StylesLibrary.push(createdStyle);
      }
      
      const appStyleExists = this.cobbleService.Cobble.styles.find(s => s.styleId === createdStyle.styleId);
      if (appStyleExists) {
        appStyleExists.properties = createdStyle.properties;
        appStyleExists.name = createdStyle.name;
      } else {
        this.cobbleService.Cobble.styles.push(createdStyle);
      }
    });
  }
  
  GetStylesLibrary(): IRepresentativeMoleculeStyleData[] {
    return this.StylesLibrary;
  }
  
  GetStylesById(styleIds: number[]) {
    return this.StylesLibrary.filter(s => styleIds.includes(s.styleId));
  }
  
  FetchStyle(styleId: number) {
    return this.apiThematicService.GetStyle(styleId);
  }
  
  GetStylesByIdFromApp(styleIds: number[]) {
    let libraryStyles = this.toolsService.Unique(this.GetStylesById(styleIds));
    
    if (libraryStyles && libraryStyles.length === 0) {
      libraryStyles = this.cobbleService.Cobble.styles && this.cobbleService.Cobble.styles.length > 0 ? this.toolsService.Unique(this.cobbleService.Cobble.styles) :
        this.toolsService.Unique(this.GetStylesById(styleIds));
    }
    
    const stylesToReturn = [];
    styleIds.forEach(styleId => {
      const styleExists = libraryStyles.find(s => s.styleId === styleId);
      
      if (styleExists) {
        stylesToReturn.push(styleExists);
      }
    });
    return stylesToReturn;
  }
  
  SetStyle(styleData: IRepresentativeMoleculeStyleData, repMolecule: IRepresentativeMolecule) {
    this.ApplyStyle(styleData, repMolecule);
    repMolecule.SaveProperty('properties', `${ styleData.section } style set`).subscribe();
  }
  
  ApplyStyle(styleData: IRepresentativeMoleculeStyleData, repMolecule: IRepresentativeMolecule, specificSection = 'all', sectionsToAvoid: string[] = [], ignoreCalcProperties = false, considerManualAdjustedProperties = false) {
    console.log('applying style');
    this.ApplyingStyle = true;
    const repMoleculeProperties = repMolecule.Properties;
    let styleProperties = cloneDeep(styleData.properties);
    
    if (sectionsToAvoid.length > 0) {
      sectionsToAvoid.forEach(section => {
        this.RemovePropertiesFromObject(this.SectionProperties[section], styleProperties);
      });
    }
    
    if (specificSection !== 'all') {
      styleProperties = this.ObtainSectionStyleFromProperties(styleProperties, specificSection);
    }
    
    if (considerManualAdjustedProperties && repMolecule.StyleMetadata.manualAdjustedProperties) {
      this.RemovePropertiesFromObject(repMolecule.StyleMetadata.manualAdjustedProperties.map(p => p.property), styleProperties);
    }
    
    
    merge(repMoleculeProperties, styleProperties);
    
    // limit size for rep molecules on tables
    if (repMolecule.SubParentId > 0) {
      const maxChildrenCols = 15;
      const maxChildrenRows = 15;
      
      repMolecule.ResponsiveProperties().cols = repMolecule.ResponsiveProperties().x + repMolecule.ResponsiveProperties().cols > maxChildrenCols ?
        maxChildrenCols - repMolecule.ResponsiveProperties().x : repMolecule.ResponsiveProperties().cols;
      
      repMolecule.ResponsiveProperties().rows = repMolecule.ResponsiveProperties().y + repMolecule.ResponsiveProperties().rows > maxChildrenRows ?
        maxChildrenRows - repMolecule.ResponsiveProperties().y : repMolecule.ResponsiveProperties().rows;
    }
    
    
    // disabling offset temporarily
    if (false && !ignoreCalcProperties &&
      styleData.properties.responsive[this.cobbleService.Cobble.deviceType].xOffset !== null &&
      styleData.properties.responsive[this.cobbleService.Cobble.deviceType].xOffset !== undefined &&
      styleData.properties.responsive[this.cobbleService.Cobble.deviceType].yOffset !== null &&
      styleData.properties.responsive[this.cobbleService.Cobble.deviceType].yOffset !== undefined
    ) {
      this.ApplyOffset(repMolecule, this.workAreaService.elementsSelected);
    }
    
    repMolecule.RefreshUI();
    setTimeout(() => {
      this.ApplyingStyle = false;
      console.log('style applied');
    }, 500);
  }
  
  ApplyOffset(repMolecule: IRepresentativeMolecule, selectedElements: IRepresentativeMolecule[]) {
    if (!isNaN(repMolecule.ResponsiveProperties().xOffset) &&
      !isNaN(repMolecule.ResponsiveProperties().yOffset) &&
      this.workAreaService.elementsSelected.length > 1 &&
      this.workAreaService.ElementsSelectedContainsId(repMolecule.Id)) {
      
      const position = selectedElements.findIndex(se => se.Id === repMolecule.Id);
      if (this.workAreaService.firstElementSelected.Id !== repMolecule.Id && position > 0) {
        
        const newX = selectedElements[position - 1].ResponsiveProperties().x + repMolecule.ResponsiveProperties().xOffset;
        const newY = selectedElements[position - 1].ResponsiveProperties().y + repMolecule.ResponsiveProperties().yOffset;
        
        if (newX !== repMolecule.ResponsiveProperties().x) {
          repMolecule.ResponsiveProperties().x = newX;
          repMolecule.SavePropertyFromVersioning(new PropertyVersioningDto({
            elementId: repMolecule.Id.toString(),
            property: 'x',
            value: repMolecule.ResponsiveProperties().x,
            path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
            change: 'Edit',
            name: 'Position X',
          })).subscribe();
        }
        
        if (newY !== repMolecule.ResponsiveProperties().y) {
          repMolecule.ResponsiveProperties().y = newY;
          repMolecule.SavePropertyFromVersioning(new PropertyVersioningDto({
            elementId: repMolecule.Id.toString(),
            property: 'y',
            value: repMolecule.ResponsiveProperties().y,
            path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
            change: 'Edit',
            name: 'Position Y',
          })).subscribe();
        }
        
        repMolecule.RefreshParent();
      }
    }
  }
  
  CopyStyle(repMolecule: IRepresentativeMolecule, section = 'all', offsetRepresentativeMolecule: IRepresentativeMolecule = null): IRepresentativeMoleculeStyleData {
    const style = repMolecule.GetStyle(section, offsetRepresentativeMolecule);
    this.styleClipboard = style;
    return style;
  }
  
  GetStylePromised(styleId: number): Promise<IRepresentativeMoleculeStyleData> {
    return this.apiThematicService.GetStylePromised(styleId);
  }
  
  ExportStyle(repMolecule: IRepresentativeMolecule, section = 'all') {
    const style = repMolecule.GetStyle(section);
    this.toolsService.ExportToTextFile(`${ repMolecule.Properties.name }-${ section.toUpperCase() }-style`, style, LeapXLFileFormat.Style);
  }
  
  UnAssignThemeStylesToApp(app: Cobble) {
    Object.values(app.appTheme.representativeMoleculeStyles).forEach(style => {
      this.UnAssignStyleFromRepresentativeMolecule(style.styleId, app.id);
    });
  }
  
  AssignThemeStylesToApp(app: Cobble) {
    Object.values(app.appTheme.representativeMoleculeStyles).forEach(style => {
      this.AssignStyleToRepresentativeMolecule(style.styleId, app.id);
    });
  }
  
  ApplyThemeToApp(theme: Theme, app: Cobble = this.cobbleService.Cobble): Observable<any> {
    return from(new Promise((resolve, reject) => {
      
      app.appTheme = {
        id: 0,
        name: '',
        description: '',
        representativeMoleculeStyles: {},
      };
      this.apiThematicService.GetTheme(theme.id).subscribe(theme => {
        app.appTheme = theme;
        
        Object.values(app.appTheme.representativeMoleculeStyles).forEach(repMoleculeStyle => {
          repMoleculeStyle.asStyle = true;
        });
        this.AssignThemeToApp(theme.id, app.id);
        // this.AssignThemeStylesToApp(app);
        this.SaveAppTheme(app);
        resolve(theme);
      });
    }));
  }
  
  RemoveThemeFromApp(app: Cobble) {
    this.UnAssignThemeFromApp(app.appTheme.id, app.id);
    app.appTheme = {
      id: 0,
      name: '',
      description: '',
      representativeMoleculeStyles: {},
    };
    
    this.SaveAppTheme(app);
  }
  
  SaveAppTheme(app: Cobble) {
    this.propertiesService.SaveProperty(new PropertyVersioningDto({
      elementId: app.id.toString(),
      property: 'appTheme',
      value: app.appTheme,
      path: '',
      change: 'Theme changed',
      name: app.properties.name,
    })).subscribe();
  }
  
  CreateTheme(theme: Theme) {
    return this.apiThematicService.CreateTheme(theme);
  }
  
  UpdateTheme(theme: Theme) {
    return this.apiThematicService.UpdateTheme(theme);
  }
  
  DeleteTheme(themeId: number) {
    this.apiThematicService.DeleteTheme(themeId).subscribe();
  }
  
  GetTheme(themeId: number) {
    return this.apiThematicService.GetTheme(themeId);
  }
  
  private RemovePropertiesFromObject(propertiesToRemove: string[], object: any) {
    for (const property in object) {
      if (propertiesToRemove.includes(property)) {
        delete object[property];
        
        if (property === 'cols') {
          delete object['colsQty'];
        }
        if (property === 'rows') {
          delete object['rowsQty'];
        }
      } else {
        if (this.toolsService.GetObjectType(object[property]) === 'object') {
          this.RemovePropertiesFromObject(propertiesToRemove, object[property]);
        }
      }
    }
  }
}
