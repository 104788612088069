import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class LogUserAADMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(particleId: string, repMoleculeId: string, busProcessorKey: string, rule: any, logic: any, data: any) {
    try {
      data = data === undefined || data === null || data === {} ? '' : data;
      
      // console.log('aad login');
      if (this.adalService.isAuthenticated) {
        // console.log('user logged');
        this.communicationService.Event.System.Auth.$AADUserLogged.emit(true);
        this.Done(particleId, busProcessorKey, repMoleculeId, data);
      } else {
        this.localStorageService.Set('aad_redirect_uri', window.location.href);
        this.SuspendProcessor(particleId, busProcessorKey, repMoleculeId, data);
        this.adalService.login();
        
        // is iframe
        if (!(self === top)) {
          this.authService.EvaluateAADLogin();
        }
      }
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
