import { animate, style, transition, trigger } from '@angular/animations';
import { NestedTreeControl } from '@angular/cdk/tree';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, NgZone, OnDestroy, OnInit, Renderer2, ViewChild, ViewRef } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { IActionMapping, TreeComponent, TreeModel, TreeNode } from 'angular-tree-component';
import { of as observableOf, Subject, Subscription } from 'rxjs';
import { Permissions } from '../../admin/models/permissions.enum';
import { ApiMoleculesService } from '../../core/services/api-molecules.service';
import { ApiPropertiesService } from '../../core/services/api-properties.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { EditorStateService } from '../../core/services/editor-state.service';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { TemplateService } from '../../core/services/template.service';
import { ToolsService } from '../../core/services/tools.service';
import { UserMenuService } from '../../core/services/user-menu.service';
import { PropertyVersioningDto } from '../../shared/dtos/versioning-dto';
import { DragType } from '../../shared/enums/drag-type.enum';
import { MoleculesType } from '../../shared/enums/molecules-type.enum';
import { CobbleNode } from '../../shared/models/cobble-node';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { DragService } from '../../shared/representative-molecule/services/drag.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { ConnectionStateService } from '../../shared/services/connection-state.service';
import { DraggableWindowManagerService } from '../../shared/services/draggable-window-manager.service';
import { DraggableWindowService, DraggableWindowType } from '../../shared/services/draggable-window.service';
import { HubConnectionService } from '../../shared/services/hub-connection.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { WorkAreaService } from '../workarea.service';
import { Constants } from './../../shared/constants';
import { ShareCobbleConfirmationComponent } from './share-cobble-confirmation/share-cobble-confirmation.component';

@Component({
  selector: 'app-cobbles-tree',
  templateUrl: './cobbles-tree.component.html',
  styleUrls: ['./cobbles-tree.component.scss'],
  animations: [
    trigger('add', [
      transition(':enter', [style({ transform: 'translateY(10%)' }), animate('200ms', style({ transform: 'translateY(0)' }))]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('100ms', style({ transform: 'translateY(-5%)', opacity: 0 })),
      ]),
    ]),
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CobblesTreeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('editNodeNameInput', { static: false })
  editNodeNameInput: ElementRef;
  @ViewChild('cobblesTree', { static: false }) cobblesTree: TreeComponent;
  nodes: any[] = [];
  cacheMyCobbles: any[] = [];
  cacheSharedCobbles: any[] = [];
  myCobbles: any[] = [];
  sharedCobbles: any[] = [];
  editingNode = null;
  viewNodeNameEdit = '';
  renamingNode = false;
  preventNodeClick = false;
  timerNodeClick = null;
  public cobbleSearch = new Subject<any>();
  filterValue = '';
  adminApplicationPermission = false;
  previewPosition = {
    x: 0,
    y: 0,
  };
  previewUrl = '';
  showPreview = false;
  actionMapping: IActionMapping = {
    mouse: {
      click: (tree, node, $event) => {
        this.HidePreview();
        console.log(tree, node, $event);
        this.RefreshUI();
        node.expand();
        this.RefreshUI();
      },
      dragStart: (tree, node, $event) => {
        this.HidePreview();
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
        this.SetDragStartForComponent($event, node.data.cobble);
        this.RefreshUI();
      },
      dragEnd: (tree, node, $event) => {
        this.communicationService.Event.Editor.$WorkAreaDetectionDisableFor.emit(200);
        this.SetDragEndForComponent();
        this.RefreshUI();
      },
      drop: (tree: TreeModel, node: TreeNode, $event: any, { from, to }) => {
        if (!node.data.cobble || this.cobbleService.Cobble.id !== node.data.cobble.id) {
          return;
        }
        
        this.communicationService.Event.Editor.$SetAppTheme.emit({ theme: this.dragService.dragData.theme });
      },
      dblClick: (tree, node, $event) => {
        if (!node.data.isApplication && node.data.standard && !this.userMenuService.checkPermission(Permissions.AdminApplications)) {
          return;
        }
        
        if (node.data.isComponent || node.data.isCobble) {
          clearTimeout(this.timerNodeClick);
          this.preventNodeClick = true;
          this.RefreshUI();
          
          if (node.data.isCobble && node.data.shared) {
            // no edit
          } else {
            this.viewNodeNameEdit = node.data.name;
            node.data.editMode = true;
            this.editingNode = node.data;
            this.RefreshUI();
            setTimeout(() => {
              this.RefreshUI();
              this.editNodeNameInput.nativeElement.focus();
              this.editNodeNameInput.nativeElement.select();
              this.RefreshUI();
            }, 100);
          }
        }
      },
    },
  };
  loading = false;
  appsPanelSectionsState = {
    apps: true,
    extensions: false,
    companies: false,
  };
  activeCobbleId: number;
  userFiltered: number;
  cobbleLoadingId: number;
  cobbletNodes: CobbleNode[] = [];
  companiesNodes: CobbleNode[] = [];
  treeControlCompanies: NestedTreeControl<CobbleNode>;
  dataSourceCobbles: MatTreeNestedDataSource<CobbleNode>;
  dataSourceSharedCobbles: MatTreeNestedDataSource<CobbleNode>;
  dataSourceCompaniesCobbles: MatTreeNestedDataSource<CobbleNode>;
  loadingCobble = false;
  loadingCobbleId = 0;
  dropZones: String[] = ['dzUserCobbles'];
  activeNode: CobbleNode;
  subscription: Subscription;
  appsLoadedFirstTime = false;
  appsTreeOpen = true;
  componentTreeOpen = false;
  standardAppsTreeOpen = false;
  standardComponentsTreeOpen = false;
  mySharedCobblesNode = null;
  standardApps = [];
  treeOptions = {
    actionMapping: this.actionMapping,
    allowDrag: node => node.data.allowDrag,
    allowDrop: (element, { parent, index }) => {
      if (element) {
        if (
          !element.data.isApplication &&
          !element.data.standard &&
          parent.data.root &&
          parent.data.id === 'standard_components' &&
          this.userMenuService.checkPermission(Permissions.AdminApplications)
        ) {
          return true;
        }
        
        if (
          element.data.isApplication &&
          !element.data.standard &&
          parent.data.root &&
          parent.data.id === 'standard_apps' &&
          this.userMenuService.checkPermission(Permissions.AdminApplications)
        ) {
          return true;
        }
        
        return false;
      } else {
        return true;
      }
    },
    getChildren: (node: TreeNode) => {
      return new Promise((resolve, reject) => {
        switch (node.data.id) {
          case 'apps_shared_with_me':
            this.moleculesService.GetSharedApplications().subscribe((sharedCobbles: any[]) => {
              this.sharedCobbles = sharedCobbles;
              this.cacheSharedCobbles = sharedCobbles;
              
              const sharedApps = [];
              this.sharedCobbles.forEach(user => {
                // console.log(user);
                
                user.cobbles.sort(this.toolsService.CompareValues('name')).forEach(cobble => {
                  sharedApps.push({
                    id: cobble.id,
                    icon: cobble.icon,
                    name: `${ user.userName }: ${ cobble.name }`,
                    isCobble: true,
                    editingNode: false,
                    previewDesktopUrl: cobble.previewDesktopUrl,
                    previewMobileUrl: cobble.previewMobileUrl,
                    isApplication: true,
                    standard: false,
                    allowDrag: false,
                    isComponent: false,
                    root: false,
                    cobble: cobble,
                    shared: true,
                  });
                });
              });
              setTimeout(() => this.setSectionHeight(), 1000);
              return resolve(sharedApps);
            });
            break;
          case 'standard_components':
            const standardComponents = [];
            
            if (this.standardApps.length === 0) {
              this.moleculesService.GetStandardApplications().subscribe((apps: any[]) => {
                this.standardApps = apps;
                
                this.standardApps
                .filter(c => !c.isApplication && c.isStandardComponent)
                .sort(this.toolsService.CompareValues('name'))
                .forEach(c => {
                  c.moleculeType = MoleculesType.Cobblet;
                  standardComponents.push({
                    id: c.id,
                    name: c.name,
                    icon: c.icon,
                    previewDesktopUrl: c.previewDesktopUrl,
                    previewMobileUrl: c.previewMobileUrl,
                    isCobble: true,
                    isApplication: false,
                    standard: true,
                    editingNode: false,
                    root: false,
                    allowDrag: true,
                    cobble: c,
                    shared: false,
                  });
                });
                setTimeout(() => this.setSectionHeight(), 1000);
                return resolve(standardComponents);
              });
            } else {
              this.standardApps
              .filter(c => !c.isApplication && c.isStandardComponent)
              .sort(this.toolsService.CompareValues('name'))
              .forEach(c => {
                c.moleculeType = MoleculesType.Cobblet;
                standardComponents.push({
                  id: c.id,
                  name: c.name,
                  icon: c.icon,
                  previewDesktopUrl: c.previewDesktopUrl,
                  previewMobileUrl: c.previewMobileUrl,
                  isCobble: true,
                  isApplication: false,
                  standard: true,
                  editingNode: false,
                  root: false,
                  allowDrag: true,
                  cobble: c,
                  shared: false,
                });
              });
              setTimeout(() => this.setSectionHeight(), 1000);
              return resolve(standardComponents);
              return;
            }
            
            break;
          case 'standard_apps':
            const standardApps = [];
            if (this.standardApps.length === 0) {
              this.moleculesService.GetStandardApplications().subscribe((apps: any[]) => {
                this.standardApps = apps;
                
                this.standardApps
                .filter(c => c.isApplication && c.isStandardApplication)
                .sort(this.toolsService.CompareValues('name'))
                .forEach(c => {
                  c.moleculeType = MoleculesType.Cobblet;
                  standardApps.push({
                    id: c.id,
                    name: c.name,
                    icon: c.icon,
                    previewDesktopUrl: c.previewDesktopUrl,
                    previewMobileUrl: c.previewMobileUrl,
                    isCobble: true,
                    isApplication: true,
                    standard: true,
                    editingNode: false,
                    root: false,
                    allowDrag: true,
                    cobble: c,
                    shared: false,
                  });
                });
                setTimeout(() => this.setSectionHeight(), 1000);
                return resolve(standardApps);
              });
            } else {
              this.standardApps
              .filter(c => c.isApplication && c.isStandardApplication)
              .sort(this.toolsService.CompareValues('name'))
              .forEach(c => {
                c.moleculeType = MoleculesType.Cobblet;
                standardApps.push({
                  id: c.id,
                  name: c.name,
                  icon: c.icon,
                  previewDesktopUrl: c.previewDesktopUrl,
                  previewMobileUrl: c.previewMobileUrl,
                  isCobble: true,
                  isApplication: true,
                  standard: true,
                  editingNode: false,
                  root: false,
                  allowDrag: true,
                  cobble: c,
                  shared: false,
                });
              });
              setTimeout(() => this.setSectionHeight(), 1000);
              return resolve(standardApps);
            }
            break;
          default:
            return resolve([]);
        }
      });
    },
    animateExpand: false,
    animateSpeed: 20,
    animateAcceleration: 1.2,
  };
  userPermissions = [];
  userRoleId = 0;
  windowHeight = 0;
  
  constructor(
    private menuService: UserMenuService,
    private templateService: TemplateService,
    private workAreaService: WorkAreaService,
    private router: Router,
    private snackerService: SnackerService,
    private dialogService: GenericDialogService,
    private moleculesService: ApiMoleculesService,
    public cobbleService: CobbleService,
    private connectionStateService: ConnectionStateService,
    private hubConnectionService: HubConnectionService,
    private draggableWindowService: DraggableWindowService,
    public clientStorageService: ClientStorageService,
    private communicationService: CommunicationService,
    private changeDetectorRef: ChangeDetectorRef,
    private propertiesService: ApiPropertiesService,
    private userMenuService: UserMenuService,
    private dragService: DragService,
    private toolsService: ToolsService,
    private editorStateService: EditorStateService,
    private dragableWindowManagerService: DraggableWindowManagerService,
    private angularZone: NgZone,
    private renderer: Renderer2,
  ) {
    // changeDetectorRef.detach();
    
    this.userPermissions = clientStorageService.getPermissions();
    this.userRoleId = clientStorageService.getUserRoleId();
    
    this.adminApplicationPermission = userMenuService.checkPermission(Permissions.AdminApplications);
    this.subscription = this.communicationService.Event.System.App.$AppLoaded.subscribe(condition => {
      console.log('=event='); // console.log('$AppLoaded');
      this.loadingCobbleId = 0;
      this.loadingCobble = false;
    });
    
    this.subscription.add(
      this.workAreaService.$cobbleCreated.subscribe(newCobble => {
        console.log('=event='); // console.log('$cobbleCreated');
        setTimeout(() => {
          this.createCobblesTree();
        }, 200);
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.System.App.$AppEdited.subscribe(cobble => {
        console.log('=event='); // console.log('$AppEdited');
        setTimeout(() => {
          this.createCobblesTree();
        }, 600);
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.System.App.$AppChanged.subscribe(() => {
        console.log('=event=');
        setTimeout(() => {
          this.createCobblesTree();
        }, 200);
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.AppsTree.$RefreshAppsTree.subscribe(cobble => {
        console.log('=event='); // console.log('$AppEdited');
        this.createCobblesTree();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.AppsTree.$RefreshUI.subscribe(cobble => {
        console.log('=event='); // console.log('$RefreshUI');
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.System.App.$AppLoaded.subscribe(cobble => {
        console.log('=event='); // console.log('$AppLoaded');
        setTimeout(() => {
          if (!this.appsLoadedFirstTime) {
            this.appsLoadedFirstTime = true;
            this.createCobblesTree();
          }
        }, 2000);
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.Preferences.$PreferenceChange.subscribe(preference => {
        console.log('=event=');
        if (preference === 'compactToolBarPosition') {
          this.setSectionHeight();
        }
      }),
    );
    
    this.dataSourceCobbles = new MatTreeNestedDataSource<CobbleNode>();
    this.dataSourceSharedCobbles = new MatTreeNestedDataSource();
    // if has admin privileges
    if (this.menuService.canFilterUsers) {
      this.dataSourceCompaniesCobbles = new MatTreeNestedDataSource();
      this.treeControlCompanies = new NestedTreeControl<CobbleNode>(this._getCompanyChildren);
      
      this.subscription.add(
        this.workAreaService.companiesCobbles.subscribe(companiesCobbles => {
          if (companiesCobbles && companiesCobbles.length) {
            let cobbles: CobbleNode[];
            if (this.menuService.isSuperUser) {
              cobbles = companiesCobbles;
            } else {
              cobbles = companiesCobbles[0].list;
            }
            
            this.companiesNodes = cobbles;
            this.dataSourceCompaniesCobbles.data = cobbles;
            // this.treeControlCompanies.expand(cobbles[0]);
            this.expandToActiveCobble(this.treeControlCompanies, cobbles[0]);
            
            if (cobbles[0].id === 0 && cobbles[0].list && cobbles[0].list.length === 1) {
              // this.treeControlCompanies.expand(cobbles[0].list[0]);
            }
          } else {
            this.companiesNodes = [];
            this.dataSourceCompaniesCobbles.data = [];
          }
          setTimeout(() => this.setSectionHeight(), 100);
        }),
      );
    }
  }
  
  @Input() set isTabSelected(value) {
    if (value) {
      this.setSectionHeight();
    }
  }
  
  get userIsAdmin() {
    return this.menuService.isAdminUser;
  }
  
  get userIsSuperUser() {
    return this.menuService.isSuperUser;
  }
  
  get userHasAdminPrivileges() {
    return this.userIsAdmin || this.userIsSuperUser;
  }
  
  RefreshUI() {
    if (this.changeDetectorRef && !(this.changeDetectorRef as ViewRef).destroyed) {
      this.changeDetectorRef.detectChanges();
    }
  }
  
  ngOnInit() {
    this.windowHeight = window.innerHeight;
  }
  
  ngAfterViewInit() {
    this.setSectionHeight();
  }
  
  OnDrop(e: any, node: any) {
    console.log(e, node);
    if (node.cobble.isShareable || !node.cobble.isApplication) {
      const nodeLevel = e.dragData;
      
      if (nodeLevel.other.companyId === node.cobble.companyId) {
        this.shareCobble(nodeLevel, node);
      } else {
        this.dialogService
        .openDialog(
          {
            cobbleName: node.cobble.name,
            oneWayDiferent: this.menuService.isSameCompany(node.cobble.companyId),
          },
          ShareCobbleConfirmationComponent,
        )
        .then(result => {
          if (result) {
            this.shareCobble(nodeLevel, node);
          }
        });
      }
    } else {
      this.snackerService.ShowMessageOnBottom('This App is not available to be shared. Make sure it is published.', 'rocket');
    }
  }
  
  // TO REMOVE
  
  onDropCompany(e: any, cobbleNode: CobbleNode) {
    if (cobbleNode.other.isShareable) {
      const nodeLevel = e.dragData;
      
      if (nodeLevel.other.companyId === cobbleNode.other.companyId) {
        this.shareCobble(nodeLevel, cobbleNode);
      } else {
        this.dialogService
        .openDialog(
          {
            cobbleName: cobbleNode.name,
            oneWayDiferent: this.menuService.isSameCompany(cobbleNode.other.companyId),
          },
          ShareCobbleConfirmationComponent,
        )
        .then(result => {
          if (result) {
            this.shareCobble(nodeLevel, cobbleNode);
          }
        });
      }
    } else {
      this.snackerService.ShowMessageOnBottom('This App is not available to be shared. Make sure it is published.', 'rocket');
    }
  }
  
  OpenCobble(e: any, node: any) {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    console.log(node);
    
    if (!node.isApplication && node.standard && !this.userMenuService.checkPermission(Permissions.AdminApplications)) {
      return;
    }
    
    this.timerNodeClick = setTimeout(() => {
      if (!this.preventNodeClick) {
        if (node.isCobble) {
          this.hubConnectionService.CobbleClosed();
          if (node.cobble.id === this.cobbleService.Cobble.id) {
            this.draggableWindowService.OpenDraggableWindow(
              'App Properties',
              DraggableWindowType.AppProperties,
              e,
            );
          } else {
            this.loadingCobble = true;
            this.loadingCobbleId = node.cobble.id;
            this.router.navigate(['/workarea/' + node.cobble.id]);
          }
        } else if (node.isComponent) {
        }
        this.RefreshUI();
      }
      this.preventNodeClick = false;
    }, 200);
  }
  
  openCobble(node) {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    if (node.id && node.other.isCobble && node.id !== this.activeCobbleId) {
      this.workAreaService.openingProgress = 0;
      this.router.navigate(['/workarea/' + node.id]);
      this.workAreaService.loadingCobble.next(node.id);
      this.activeNode = node;
    } else if (node.id) {
    }
  }
  
  runCobble(node) {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    if (node && node.other && node.other.slug) {
      // console.log(node);
      const path = `${ document.location.hostname.search('localhost') > -1 ? ':4200' : '' }/run/${ node.other.slug }`;
      window.open(path, '_blank');
    }
  }
  
  onNodeOver(node: CobbleNode, enter: boolean) {
    if (node.other) {
      node.other.showRemove = node.other && node.other.isCobble && enter && node.other.isRemovable;
    }
  }
  
  userChanged(userId: number) {
    this.userFiltered = userId;
  }
  
  nodeIsLoading(node: CobbleNode) {
    return this.cobbleLoadingId && this.cobbleLoadingId === node.id;
  }
  
  hideWhenFilteringUser(node: CobbleNode) {
    return node.other.itemType !== 'user' || node.id === (this.userFiltered || node.id);
  }
  
  _getCompanyChildren = (node: CobbleNode) => {
    let cobbleNodes: CobbleNode[] = [];
    if (node.list && node.list.length) {
      cobbleNodes = node.list;
    } else if (this.nodeIsUser(node)) {
      cobbleNodes.push(this.getEmptyCobbleNode());
    }
    return observableOf(cobbleNodes);
  };
  
  hasCompanyChild = (_: number, _nodeData: CobbleNode) => {
    let hasChild: boolean;
    if (_nodeData.list && _nodeData.list.length) {
      hasChild = _nodeData.list ? _nodeData.list.length > 0 : false;
    } else if (this.nodeIsUser(_nodeData)) {
      hasChild = true;
    }
    return hasChild;
  };
  
  ngOnDestroy() {
    this.unsuscribe();
  }
  
  CreateNewApp(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate(['/workarea']);
  }
  
  ImportLeapXLFile(event: MouseEvent) {
    this.draggableWindowService.OpenDraggableWindow(
      'Import LeapXL File (Beta Test)',
      DraggableWindowType.LeapxlImport,
      event,
    );
  }
  
  SetDragStartForComponent(event: MouseEvent, element: any) {
    console.log(element);
    element.type = MoleculesType.Cobblet;
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
    // console.log('drag start', event);
    this.dragService.StartDrag();
    
    switch (element.moleculeType) {
      case MoleculesType.Behavior:
      case MoleculesType.DataAction:
      case MoleculesType.CompoundMolecule:
        this.dragService.dragText = `Attach ${ element.name } molecule`;
        this.dragService.dragginMolecule = true;
        break;
    }
    
    this.dragService.dragginFromLibrary = true;
    element.dragType = DragType.Molecule;
    this.dragService.dragData = element;
  }
  
  SetDragEndForComponent() {
    this.dragService.StopDragging();
    setTimeout(() => {
      this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    }, 300);
  }
  
  onClickedOutsideEdit(e: Event) {
    this.RefreshUI();
    this.CancelEditingNodeName();
    this.RefreshUI();
  }
  
  RefreshUIDelayed() {
    this.angularZone.run(() => {
      this.changeDetectorRef.detectChanges();
      setTimeout(() => {
        this.changeDetectorRef.detectChanges();
      }, 200);
    });
  }
  
  CancelEditingNodeName() {
    this.RefreshUI();
    this.viewNodeNameEdit = '';
    if (this.editingNode) {
      this.editingNode.editMode = false;
      this.editingNode = null;
    }
    this.RefreshUI();
  }
  
  SaveNodeName() {
    this.RefreshUI();
    
    if ((this.editingNode.isComponent && this.viewNodeNameEdit === this.editingNode.component.name) || this.viewNodeNameEdit === '') {
      this.CancelEditingNodeName();
      return;
    }
    
    console.log(this.editingNode);
    if (this.editingNode.isCobble) {
      this.propertiesService.VerifyCobbleName(this.viewNodeNameEdit, this.editingNode.cobble.id).subscribe((availability: boolean) => {
        if (availability) {
          if (this.cobbleService.Cobble.id === this.editingNode.cobble.id) {
            this.cobbleService.Cobble.properties.name = this.viewNodeNameEdit;
            this.communicationService.Event.System.App.$AppEdited.emit(true);
          }
          this.propertiesService
          .SaveProperty(
            new PropertyVersioningDto({
              elementId: this.editingNode.cobble.id.toString(),
              property: 'name',
              value: this.viewNodeNameEdit,
              path: 'properties',
              change: `Name changed`,
              name: this.viewNodeNameEdit,
            }),
          )
          .subscribe();
          
          this.editingNode.cobble.name = this.viewNodeNameEdit;
          this.editingNode.editMode = false;
          this.editingNode = null;
        } else {
          this.snackerService.ShowMessageOnBottom('An App already exists with this name!', 'repeat_one_on');
        }
      });
    }
    this.RefreshUI();
  }
  
  RemoveNodeFromTree(node) {
    const componentsNode = this.cobblesTree.treeModel.nodes.find(n => n.id === 'components');
    componentsNode.children = componentsNode.children.filter(c => c.id !== node.id);
    this.cobblesTree.treeModel.update();
  }
  
  ToggleAppsTreeNode(event) {
    switch (event.node.data.id) {
      case 'my_apps':
        this.appsTreeOpen = event.isExpanded;
        break;
      case 'components':
        this.componentTreeOpen = event.isExpanded;
        break;
      case 'standard_comopnents':
        this.standardComponentsTreeOpen = event.isExpanded;
        break;
      case 'standard_apps':
        this.standardAppsTreeOpen = event.isExpanded;
        break;
    }
    setTimeout(() => this.setSectionHeight(), 100);
  }
  
  ShowPreview(event: MouseEvent, node: any) {
    if (node.isCobble && !node.root) {
      const previewUrl = this.cobbleService.Cobble.deviceType === 'desktop' ? node.previewDesktopUrl : node.previewMobileUrl;
      this.previewPosition = {
        x: event.x + 40,
        y: event.y - 180,
      };
      this.previewUrl = Constants.Environment.apiUrl + 'files/view/?filename=' + previewUrl;
      this.showPreview = !(previewUrl === '');
    }
  }
  
  HidePreview() {
    this.showPreview = false;
    this.previewUrl = '';
  }
  
  NodeMoved(event: any) {
    const movedNode = event.node;
    movedNode.cobble.isStandardApplication = true;
    movedNode.isStandardApplication = true;
    
    const standardAppsNode = this.cobblesTree.treeModel.getNodeById('standard_apps');
    console.log('node moved', event, standardAppsNode, this.standardApps);
    
    this.standardApps.push(movedNode);
    // standardAppsNode
    if (event.to.parent.id === 'standard_apps' && event.node.isApplication && !event.node.standard) {
      this.propertiesService
      .SaveProperty(
        new PropertyVersioningDto({
          elementId: event.node.id.toString(),
          property: 'isStandardApplication',
          value: true,
          path: '',
          change: `Share Application`,
          name: event.node.name,
        }),
      )
      .subscribe();
    }
    
    if (event.to.parent.id === 'standard_components' && !event.node.isApplication && !event.node.standard) {
      this.propertiesService
      .SaveProperty(
        new PropertyVersioningDto({
          elementId: event.node.id.toString(),
          property: 'isStandardComponent',
          value: true,
          path: '',
          change: `Share Component`,
          name: event.node.name,
        }),
      )
      .subscribe();
    }
    
    this.RefreshUI();
    
    setTimeout(() => {
      this.createCobblesTree();
      this.RefreshUI();
    }, 100);
  }
  
  toggleSection(section: string, event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    
    for (let [key, value] of Object.entries(this.appsPanelSectionsState)) {
      if (key !== section) {
        this.appsPanelSectionsState[key] = false;
      }
    }
    
    this.appsPanelSectionsState[section] = !this.appsPanelSectionsState[section];
    this.setSectionHeight();
  }
  
  setSectionHeight() {
    this.windowHeight = window.innerHeight;
    let element: Element;
    let section: string;
    
    for (let [key, value] of Object.entries(this.appsPanelSectionsState)) {
      if (value) {
        section = key;
      }
    }
    
    const headersHeight = Constants.SidePanelSectionHeaderHeight * 3;
    const separatorsHeight = Constants.SeparatorSectionHeaderHeight * 2;
    const toolbarHeight = this.workAreaService.editorPreferences.compactToolBarPosition === 'top' ? Constants.ToolbarHeight : 0;
    
    switch (section) {
      case 'apps':
        element = document.querySelector('#cobblesTreeContainer tree-root');
        
        if (element) {
          const treeViewport = element.children[0];
          this.renderer.setStyle(treeViewport, 'max-height', `${ this.windowHeight - (104 + headersHeight + separatorsHeight + toolbarHeight) }px`);
        }
        break;
      case 'extensions':
        break;
      case 'companies':
        element = document.querySelector('.mat-tree');
        
        if (element) {
          this.renderer.setStyle(element, 'max-height', `${ this.windowHeight - (142 + headersHeight + separatorsHeight + toolbarHeight) }px`);
        }
        break;
    }
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowHeight = event.target.innerHeight;
    this.setSectionHeight();
  }
  
  searchApp(value: string) {
    this.toggleSection('apps');
    this.cobblesTree.treeModel.filterNodes(value);
  }
  
  private createCobblesTree() {
    console.log('create tree');
    this.moleculesService.GetUserApplications().subscribe((cobbles: any[]) => {
      this.myCobbles = [];
      this.cacheMyCobbles = [];
      this.RefreshUI();
      this.myCobbles = cobbles;
      this.cacheMyCobbles = cobbles;
      
      this.sharedCobbles = [];
      this.cacheSharedCobbles = [];
      
      this.loading = true;
      this.nodes = [];
      
      console.log(this.appsTreeOpen);
      const myCobblesNode = {
        id: 'my_apps',
        isCobble: false,
        name: 'My Apps',
        isExpanded: this.appsTreeOpen,
        editingNode: false,
        allowDrag: false,
        root: true,
        standard: false,
        isApplication: true,
        isComponent: false,
        children: [],
      };
      
      this.myCobbles
      .filter(c => c.isApplication)
      .sort(this.toolsService.CompareValues('name'))
      .forEach(cobble => {
        myCobblesNode.children.push({
          id: cobble.id,
          icon: cobble.icon,
          name: cobble.name,
          isCobble: true,
          previewDesktopUrl: cobble.previewDesktopUrl,
          previewMobileUrl: cobble.previewMobileUrl,
          isApplication: true,
          standard: false,
          isComponent: false,
          root: false,
          allowDrag: this.userMenuService.checkPermission(Permissions.AdminApplications),
          editingNode: false,
          cobble: cobble,
          shared: false,
        });
      });
      
      const componentsNode = {
        id: 'components',
        isCobble: false,
        isComponent: false,
        isApplication: false,
        editingNode: false,
        allowDrag: false,
        root: true,
        standard: false,
        name: 'Components',
        isExpanded: this.componentTreeOpen,
        children: [],
      };
      
      this.myCobbles
      .filter(c => !c.isApplication && c.ownerId === this.clientStorageService.getUserId())
      .sort(this.toolsService.CompareValues('name'))
      .forEach(c => {
        c.moleculeType = MoleculesType.Cobblet;
        componentsNode.children.push({
          id: c.id,
          name: c.name,
          icon: c.icon,
          previewDesktopUrl: c.previewDesktopUrl,
          previewMobileUrl: c.previewMobileUrl,
          isCobble: true,
          isApplication: false,
          standard: false,
          editingNode: false,
          root: false,
          allowDrag: true,
          cobble: c,
          shared: false,
        });
      });
      
      this.mySharedCobblesNode = {
        id: 'apps_shared_with_me',
        isCobble: false,
        name: 'Apps Shared With Me',
        isExpanded: false,
        editingNode: false,
        standard: false,
        root: true,
        allowDrag: false,
        isApplication: false,
        isComponent: false,
        hasChildren: true,
      };
      
      const staandardAppsNode = {
        id: 'standard_apps',
        isCobble: false,
        name: 'Standard Apps',
        isApplication: false,
        isExpanded: this.standardAppsTreeOpen,
        editingNode: false,
        root: true,
        isComponent: false,
        standard: false,
        allowDrag: false,
        hasChildren: true,
      };
      
      if (this.standardApps.length > 0) {
        const standardApps = [];
        this.standardApps
        .filter(c => c.isApplication && c.isStandardApplication)
        .sort(this.toolsService.CompareValues('name'))
        .forEach(c => {
          c.moleculeType = MoleculesType.Cobblet;
          standardApps.push({
            id: c.id,
            name: c.name,
            icon: c.icon,
            previewDesktopUrl: c.previewDesktopUrl,
            previewMobileUrl: c.previewMobileUrl,
            isCobble: true,
            isApplication: true,
            standard: true,
            editingNode: false,
            root: false,
            allowDrag: true,
            cobble: c,
            shared: false,
          });
        });
        
        staandardAppsNode['children'] = standardApps;
      }
      
      const staandardComponentsNode = {
        id: 'standard_components',
        isCobble: false,
        name: 'Standard Components',
        isExpanded: this.standardComponentsTreeOpen,
        isApplication: false,
        editingNode: false,
        allowDrag: false,
        isComponent: false,
        root: true,
        standard: false,
        hasChildren: true,
      };
      
      this.nodes.push(myCobblesNode);
      this.nodes.push(staandardAppsNode);
      this.nodes.push(componentsNode);
      this.nodes.push(staandardComponentsNode);
      this.nodes.push(this.mySharedCobblesNode);
      console.log(this.nodes);
      this.loading = false;
      
      this.cobblesTree.treeModel.update();
      this.RefreshUI();
      if (this.appsTreeOpen) {
        setTimeout(() => {
          if (this.cobblesTree && (this.cobblesTree.treeModel as TreeModel).roots[0]) {
            (this.cobblesTree.treeModel as TreeModel).roots[0].expandAll();
          }
        }, 200);
      }
    });
    
    this.changeDetectorRef.markForCheck();
    if (this.changeDetectorRef && !(this.changeDetectorRef as ViewRef).destroyed) {
      this.changeDetectorRef.detectChanges();
    }
    this.setSectionHeight();
  }
  
  private refreshMyCobbles() {
    this.dataSourceCobbles.data = null;
    this.dataSourceCobbles.data = this.cobbletNodes;
  }
  
  private nodeIsUser(node: CobbleNode) {
    return node.other.itemType === 'user';
  }
  
  private shareCobble(nodeLevel: any, node: any) {
    this.moleculesService
    .saveCobbletShare([{
      objectId: node.id,
      id: nodeLevel.other.id,
      added: true,
      type: nodeLevel.other.type,
    }])
    .subscribe(res => {
      this.communicationService.Event.Editor.AppsTree.$AppShared.emit(true);
      this.snackerService.ShowMessageOnBottom(`App ${ node.name } shared to ${ nodeLevel.name }!`, 'ios_share');
    });
  }
  
  private getEmptyCobbleNode() {
    return { id: 0, name: '-- No Apps --', other: { noCobbles: true } };
  }
  
  private expandToActiveCobble(treeControl: NestedTreeControl<CobbleNode>, node: CobbleNode) {
    if (node.other && node.other.isCobble) {
      return node.id === this.activeCobbleId;
    } else {
      for (const child of node.list) {
        const contains = this.expandToActiveCobble(treeControl, child);
        if (contains) {
          treeControl.expand(child);
          return contains;
        }
      }
      return false;
    }
  }
  
  private NodeRemoved(node: any) {
    if (node.standard) {
      const parentNode = this.cobblesTree.treeModel.getNodeById(node.isApplication ? 'standard_apps' : 'standard_components');
      parentNode.data.children = parentNode.data.children.filter(c => c.id !== node.id);
      
      if (node.isApplication) {
        this.standardApps = this.standardApps.filter(a => a.id !== node.id);
      }
      
      this.cobblesTree.treeModel.update();
      console.log(parentNode);
    }
  }
  
  private unsuscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
