import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BuilderService } from '../../../../core/builder/builder.service';
import { ProcessorService } from '../../../../core/molecular/services/processor.service';
import { ApiFileService } from '../../../../core/services/api-file.service';
import { WorkAreaService } from '../../../../workarea/workarea.service';
import { Constants } from '../../../constants';
import { RepresentativeMoleculesType } from '../../../enums/representative-molecules-types.enum';
import { DragService } from '../../services/drag.service';
import { BaseMoleculeComponent } from '../base-molecule/base-molecule.component';

@Component({
  selector: 'app-image-molecule',
  templateUrl: './image-molecule.component.html',
  styleUrls: ['./image-molecule.component.scss'],
})
export class ImageMoleculeComponent extends BaseMoleculeComponent implements OnInit, OnDestroy {
  MoleculeType = 'Representative';
  Type = RepresentativeMoleculesType.Image;
  noImagePath = '/assets/images/no_image.png';
  ImgSource = this.noImagePath;
  @ViewChild('imageWrapper', { static: true })
  imageWrapper: ElementRef;
  
  constructor(
    public builderService: BuilderService,
    public dragService: DragService,
    public workAreaService: WorkAreaService,
    public processorService: ProcessorService,
    private fileService: ApiFileService,
    bottomSheet: MatBottomSheet,
    public el: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
    super(bottomSheet, el, changeDetectorRef);
  }
  
  ngOnInit() {
    console.log('image');
    super.ngOnInit();
    this.context.Type = RepresentativeMoleculesType.Image;
    this.RefreshGridsterConfiguration();
    this.UpdateData();
  }
  
  AttachEditorEventListeners() {
    const dragoverEventListener = this.renderer.listen(this.imageWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, true);
    });
    const dragleaveEventListener = this.renderer.listen(this.imageWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, false);
    });
    const dropEventListener = this.renderer.listen(this.imageWrapper.nativeElement, 'drop', (evt) => {
      this.DataDropped(evt);
    });
  }
  
  UpdateData() {
    console.log('update image');
    if (this.context.Value && this.context.Value !== '') {
      this.SetImageFromDataElement();
    } else if (this.context.Properties.source && this.context.Properties.source !== '') {
      this.SetImageFromSource();
    } else {
      this.ImgSource = this.noImagePath;
    }
  }
  
  SetImageFromDataElement() {
    if (this.context.Value.indexOf('http') > -1 || this.context.Value.indexOf('https') > -1) {
      this.ImgSource = this.context.Value;
      this.StopLoading();
    } else {
      // console.log(this.context.Value);
      this.context.Loading = true;
      try {
        if (this.context.Value.indexOf('assets/images/no_image.png') > -1) {
          this.ImgSource = this.context.Value;
          this.StopLoading();
        } else {
          let size = `&width=&height=`;
          const container = document.getElementById(`gridsterItem-${ this.context.Id }`);
          
          if (this.context.Properties.originalQuality || !this.context.RunningMode) {
          } else {
            if (container) {
              this.context.RuntimeData = {
                containerSize: {
                  width: container.offsetWidth,
                  height: container.offsetHeight,
                },
              };
              size = `&width=${ container.offsetWidth }&height=${ container.offsetHeight }`;
            } else {
              if (this.context.RuntimeData.containerSize) {
                size = `&width=${ this.context.RuntimeData.containerSize.width }&height=${ this.context.RuntimeData.containerSize.height }`;
              } else {
                size = `&width=&height=`;
              }
            }
          }
          
          this.ImgSource = `${ Constants.Environment.apiUrl }files/view/?fileName=${ this.context.Value }${ size }`;
          this.StopLoading();
          return;
          
          this.fileService.getFileInformation(this.context.Value).subscribe(
            (imgData) => {
              // console.log('imgdata', imgData);
              if (imgData) {
                if (imgData.contentType === 'application/pdf') {
                  this.ImgSource = '/assets/images/pdf.png';
                } else {
                  this.ImgSource = `data:${ imgData.type };base64,${ imgData.dataFile }`;
                }
              } else {
                this.ImgSource = this.noImagePath;
              }
              this.StopLoading();
            },
            (error) => {
              this.ImgSource = this.noImagePath;
              this.StopLoading();
            },
          );
        }
      } catch (error) {
        this.ImgSource = this.noImagePath;
        this.StopLoading();
      }
    }
  }
  
  SetImageFromSource() {
    if (this.context.Properties.source.indexOf('http') > -1 || this.context.Properties.source.indexOf('https') > -1) {
      this.ImgSource = this.context.Properties.source;
    } else {
      this.context.Loading = true;
      if (this.context.Properties.source !== this.noImagePath) {
        try {
          let size = `&width=&height=`;
          const container = document.getElementById(`gridsterItem-${ this.context.Id }`);
          if (this.context.Properties.originalQuality || !this.context.RunningMode) {
          } else {
            if (container) {
              this.context.RuntimeData = {
                containerSize: {
                  width: container.offsetWidth,
                  height: container.offsetHeight,
                },
              };
              size = `&width=${ container.offsetWidth }&height=${ container.offsetHeight }`;
            } else {
              if (this.context.RuntimeData.containerSize) {
                size = `&width=${ this.context.RuntimeData.containerSize.width }&height=${ this.context.RuntimeData.containerSize.height }`;
              } else {
                size = `&width=&height=`;
              }
            }
          }
          
          this.ImgSource = `${ Constants.Environment.apiUrl }files/view/?fileName=${ this.context.Properties.source }${ size }`;
          this.StopLoading();
          // this.fileService.getFileInformation(this.context.Properties.source).subscribe((result) => {
          //     if (result) {
          //       if (result.contentType.indexOf('image') > -1) {
          //         this.ImgSource = `data:${result.type};base64,${result.dataFile}`;
          //       } else {
          //         this.ImgSource = '/assets/images/pdf.png';
          //       }
          //       this.StopLoading();
          //     } else {
          //       this.ImgSource = this.noImagePath;
          //     }
          //     this.StopLoading();
          //   },
          //   (error) => {
          //     this.ImgSource = this.noImagePath;
          //     this.StopLoading();
          //   });
        } catch (error) {
          this.ImgSource = this.noImagePath;
          this.StopLoading();
        }
      } else {
        if (this.context.Value === '') {
          this.ImgSource = this.noImagePath;
        }
        this.StopLoading();
      }
    }
  }
  
  StopLoading() {
    this.context.Loading = false;
    this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(this.context.ParentId);
  }
  
  FireClickEvent(e: any) {
    this.FireRepresentativeMoleculeEvent('click');
  }
}
