import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToolsService } from '../services/tools.service';

@Injectable()
export class ClearRequestBodyInterceptor implements HttpInterceptor {
  constructor(
    private toolsService: ToolsService,
  ) {
  }
  
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const clearBodyRequest = req.clone({
      body: this.toolsService.ClearLeapXLObjectFromCircularDependencies(req.body),
    });
    return next.handle(clearBodyRequest);
  }
}
