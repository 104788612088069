import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { AnimationsService } from '../../core/services/animations.service';
import { DraggableService } from '../../core/services/draggable.service';

@Directive({
  selector: '[appDroppableHighlighter]',
})
export class DroppableHighlighterDirective implements OnInit, OnDestroy {
  public elementRef: ElementRef;
  @Input() dropZones: any[];
  @Input() applyHighlight: boolean;
  @Input() published: boolean;
  private suscriptionDroppableZones: Subscription;
  
  constructor(
    elementRef: ElementRef,
    private draggableService: DraggableService,
    private renderer: Renderer2,
    private animationService: AnimationsService,
  ) {
    this.elementRef = elementRef;
  }
  
  ngOnInit(): void {
    this.suscriptionDroppableZones = this.draggableService.droppableZones$.subscribe(
      dropzones => {
        let filteredDropZones = [];
        if (dropzones) {
          filteredDropZones = this.dropZones.filter(
            dropZone => dropzones.indexOf(dropZone) !== -1,
          );
        }
        
        const toggleClass = this.published ? 'highlight-drop' : 'highlight-drop-no';
        if (filteredDropZones.length > 0 && this.applyHighlight) {
          this.renderer.addClass(this.elementRef.nativeElement, toggleClass);
        } else {
          this.renderer.removeClass(this.elementRef.nativeElement, toggleClass);
        }
        
        this.animationService.PulseElementByRef(this.elementRef.nativeElement);
      },
    );
  }
  
  ngOnDestroy(): void {
    if (this.suscriptionDroppableZones) {
      this.suscriptionDroppableZones.unsubscribe();
    }
  }
}
