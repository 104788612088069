import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IConfiguration, IEnvironment } from './configuration.interface';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationLoaderService {
  private readonly CONFIGURATION_URL = './assets/config/configuration.json';
  private _configuration: IConfiguration;

  constructor(private _http: HttpClient) {}

  public loadConfiguration() {
    return this._http
      .get(this.CONFIGURATION_URL)
      .toPromise()
      .then((configuration: IConfiguration) => {
        console.log('configuration loader');
        this._configuration = configuration;
        return configuration;
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  get Environment(): IEnvironment {
    const domain = window.location.hostname;
    let environment: IEnvironment;

    switch (domain) {
      case 'dev.leapxl.com':
        environment = this._configuration.dev;
        break;
      case 'test.leapxl.com':
        environment = this._configuration.test;
        break;
      case 'app.leapxl.com':
        environment = this._configuration.prod;
        break;
      case 'localhost':
        environment = this._configuration.local;
        break;
      default:
        environment = this._configuration.default;
        break;
    }

    return environment;
  }
}
