import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Constants } from '../../shared/constants';
import { DatasourceType } from '../../shared/enums/datasource-type.enum';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';
import { ApiDataService } from './api-data.service';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class ApiDataTranslatorService extends ApiDataService {
  constructor(http: HttpClient, errorMessengerService: ErrorMessengerService,
              private cacheService: CacheService, protected cobbleService: CobbleService) {
    super('translations', http, errorMessengerService);
  }
  
  Translate(
    id: number,
  ): Observable<{
    id: number;
    dataSourceType: string;
    internalName: string;
    context: string;
  }[]> {
    return this.TranslateMultiple([id]);
  }
  
  TranslateMultiple(
    ids: number[],
  ): Observable<{
    id: number;
    dataSourceType: string;
    internalName: string;
    context: string;
  }[]> {
    
    const localTranslation = this.cobbleService.Cobble.translations.filter(t => ids.includes(t.id));
    
    // if app does not contain translation
    if (localTranslation.length === 0 || (localTranslation.length !== ids.length)) {
      const key = `${ ids.join('-') }-translations`;
      
      if (this.cacheService.IsCached(key)) {
        return this.cacheService.GetAsync(key);
      } else {
        return this.http.post(this.apiEndpointUrl + '/get', ids).pipe(
          tap((result: any) => {
            this.cacheService.Store(key, result, true);
            return result;
          }),
          map((response) => {
            return response;
          }),
          catchError((err) => {
            this.errorMessengerService.HandleError(err, 'Error getting data reference translation');
            // console.error('Error getting reference translation', err);
            return of(err);
          }),
        );
      }
    } else {
      return of(localTranslation);
    }
  }
  
  DeleteTranslation(translationId: number): Observable<any> {
    return this.DeleteTranslations([translationId]);
  }
  
  DeleteTranslations(
    translationIds: number[],
  ): Observable<any> {
    return this.http.post(this.apiEndpointUrl + '/delete', []).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(err, 'Error removing translation');
        // console.error('Error getting reference translation', err);
        return of(err);
      }),
    );
  }
  
  CreateTranslation(
    translations: {
      dataSourceId: number;
      applicationId: number;
      dataSourceType: string;
      specialMode: boolean;
      context: string;
      reference: string;
    }[],
  ): Observable<{
    id: number;
    dataSourceType: string;
    dataSourceId: number;
    internalName: string;
    context: string;
  }[]> {
    // console.log(translations);
    translations.forEach((t) => {
      if (t.specialMode && t.context.split(Constants.ContextSeparator)[0] === DatasourceType.Spreadsheet) {
        const splittedContext = t.context.split(Constants.ContextSeparator);
        
        if (splittedContext.length > 3) {
          splittedContext[splittedContext.length - 1] = splittedContext[splittedContext.length - 1].replace(/\d+/g, '');
        }
        
        const splittedReference = t.reference.split(':');
        splittedReference[splittedReference.length - 1] = splittedReference[splittedReference.length - 1].replace(/\d+/g, '');
        
        t.context = splittedContext.join(Constants.ContextSeparator);
        t.reference = splittedReference.join(Constants.ContextSeparator);
      }
    });
    
    translations.forEach(t => this.ChangeTranslationNames(t));
    
    const cacheKey = 'translationCreation-' + translations.map(t => t.context).join('-') + `${ this.cobbleService.Cobble.id }`;
    
    if (this.cacheService.IsCached(cacheKey)) {
      // if (false) {
      return this.cacheService.GetAsync(cacheKey);
    } else {
      return this.http.post(this.apiEndpointUrl, translations).pipe(
        tap((result: any) => {
          this.cacheService.Store(cacheKey, result, true);
          return result;
        }),
        map((response) => {
          return response;
        }),
        catchError((err) => {
          this.errorMessengerService.HandleError(err, 'Error getting data reference translation');
          // console.error('Error getting reference translation', err);
          return of(err);
        }),
      );
    }
  }
  
  ChangeTranslationNames(translation: any) {
    const swaps = Constants.ContextInternalNameDefaults;
    const context = translation.context || translation.Context;
    
    if (swaps[context]) {
      translation.reference = swaps[context];
      translation.internalName = swaps[context];
      translation.InternalName = swaps[context];
    }
  }
}
