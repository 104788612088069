export const compoundTemplates = [
  // compound save datasource
  // {
  //   name: 'Save',
  //   icon: 'bubble_chart',
  //   moleculeType: 'Compounds',
  //   type: 'CompoundSaveDatasource',
  //   molecules: {
  //     ids: 5,
  //     elementId: '#ELEMENT_ID#',
  //     molecules: [
  //       {
  //         id: '#ID1#',
  //         molecule: 'GetWorkgroupElementsMolecule',
  //         name: 'GetWorkgroupElementsMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       },
  //       {
  //         id: '#ID2#',
  //         molecule: 'GetElementsDatasourceDataMolecule',
  //         name: 'GetElementsDatasourceDataMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       },
  //       {
  //         id: '#ID3#',
  //         molecule: 'SaveDatasourceDataMolecule',
  //         name: 'SaveDatasourceDataMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       },
  //       {
  //         id: '#ID4#',
  //         molecule: 'ClearValueMolecule',
  //         name: 'ClearValueMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       }
  //     ]
  //   },
  //   moleculesList: [
  //     'GetWorkgroupElementsMolecule',
  //     'GetElementsDatasourceDataMolecule',
  //     'SaveDatasourceDataMolecule',
  //     'ClearValueMolecule'
  //   ]
  // },
  // login
  // {
  //   name: 'Login',
  //   icon: 'bubble_chart',
  //   moleculeType: 'Compounds',
  //   type: 'CompoundLogin',
  //   molecules: {
  //     ids: 4,
  //     elementId: '#ELEMENT_ID#',
  //     molecules: [
  //       {
  //         id: '#ID1#',
  //         molecule: 'GetWorkgroupElementsMolecule',
  //         name: 'GetWorkgroupElementsMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       },
  //       {
  //         id: '#ID2#',
  //         molecule: 'FilterElementsByMoleculeMolecule',
  //         name: 'FilterElementsByMoleculeMolecule',
  //         rule: {
  //           moleculeName: 'LoginUsernameMolecule,LoginPasswordMolecule'
  //         },
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       },
  //       {
  //         id: '#ID3#',
  //         molecule: 'LogUserMolecule',
  //         name: 'LogUserMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       }
  //     ]
  //   },
  //   moleculesList: [
  //     'GetWorkgroupElementsMolecule',
  //     'FilterElementsByMoleculeMolecule',
  //     'LogUserMolecule'
  //   ]
  // },
  // compound populate form
  // {
  //   name: 'Populate Form',
  //   icon: 'bubble_chart',
  //   moleculeType: 'Compounds',
  //   type: 'CompoundPopulateForm',
  //   molecules: {
  //     ids: 5,
  //     elementId: '#ELEMENT_ID#',
  //     molecules: [
  //       {
  //         id: '#ID1#',
  //         molecule: 'SelectWorkgroupMolecule',
  //         name: 'SelectWorkgroupMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       },
  //       {
  //         id: '#ID2#',
  //         molecule: 'GetWorkgroupElementsMolecule',
  //         name: 'GetWorkgroupElementsMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       },
  //       {
  //         id: '#ID3#',
  //         molecule: 'GetRelatedDataMolecule',
  //         name: 'GetRelatedDataMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       },
  //       {
  //         id: '#ID4#',
  //         molecule: 'SetElementsValueMolecule',
  //         name: 'SetElementsValueMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       }
  //     ]
  //   },
  //   moleculesList: [
  //     'SelectWorkgroupMolecule',
  //     'GetWorkgroupElementsMolecule',
  //     'GetRelatedDataMolecule',
  //     'SetElementsValueMolecule'
  //   ]
  // },
  // compound show/hide
  // {
  //   name: 'Show/Hide',
  //   icon: 'bubble_chart',
  //   moleculeType: 'Compounds',
  //   type: 'CompoundShowHide',
  //   molecules: {
  //     ids: 3,
  //     elementId: '#ELEMENT_ID#',
  //     molecules: [
  //       {
  //         id: '#ID1#',
  //         molecule: 'ShowRepresentativeMolecule',
  //         name: 'Show elements',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       },
  //       {
  //         id: '#ID2#',
  //         molecule: 'HideRepresentativeMolecule',
  //         name: 'Hide elements',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       }
  //     ]
  //   },
  //   moleculesList: ['ShowRepresentativeMolecule', 'HideRepresentativeMolecule']
  // },
  // compound update and save file
  {
    name: 'Add File',
    icon: 'bubble_chart',
    moleculeType: 'Compounds',
    type: 'CompoundAddFile',
    eventType: 'click',
    busReceptor: 'value-input',
    particles: [
      {
        DataElements: [],
        DisplayName: 'Select File',
        Icon: 'attach_file',
        InternalMoleculeName: 'SelectFileMolecule',
        Logic: {},
        Rule: {},
        MoleculeId: 0,
        ParticleType: 'Molecule',
        id: '',
      },
      {
        DataElements: [],
        DisplayName: 'Upload File',
        Icon: 'cloud_upload',
        InternalMoleculeName: 'UploadFileMolecule',
        Logic: {},
        Rule: {},
        MoleculeId: 0,
        ParticleType: 'Molecule',
        id: '',
      },
    ],
  },
  {
    name: 'Populate',
    icon: 'bubble_chart',
    moleculeType: 'Compounds',
    type: 'CompoundPopulate',
    eventType: '',
    busReceptor: 'value-output',
    particles: [
      {
        DataElements: [],
        DisplayName: 'Get Related Data',
        Icon: 'pan_tool',
        InternalMoleculeName: 'GetRelatedDataMolecule',
        Logic: {},
        Rule: {},
        MoleculeId: 0,
        ParticleType: 'Molecule',
        id: '',
      },
      {
        DataElements: [],
        DisplayName: 'Get Datasource Data',
        Icon: 'pan_tool',
        InternalMoleculeName: 'GetElementsDatasourceDataMolecule',
        Logic: {},
        Rule: {},
        MoleculeId: 0,
        ParticleType: 'Molecule',
        id: '',
      },
      {
        Data: null,
        EventName: 'App Broadcast',
        EventSource: 'System',
        EventType: 'App Broadcast',
        SourceId: 0,
        ParticleType: 'Event',
        id: '',
      },
    ],
  },
  // compound update datasource
  // {
  //   name: 'Update',
  //   icon: 'bubble_chart',
  //   moleculeType: 'Compounds',
  //   type: 'CompoundUpdateDatasource',
  //   molecules: {
  //     ids: 5,
  //     elementId: '#ELEMENT_ID#',
  //     molecules: [
  //       {
  //         id: '#ID1#',
  //         molecule: 'GetWorkgroupElementsMolecule',
  //         name: 'GetWorkgroupElementsMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       },
  //       {
  //         id: '#ID2#',
  //         molecule: 'GetElementsDatasourceDataMolecule',
  //         name: 'GetElementsDatasourceDataMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       },
  //       {
  //         id: '#ID3#',
  //         molecule: 'UpdateDatasourceDataMolecule',
  //         name: 'UpdateDatasourceDataMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       },
  //       {
  //         id: '#ID4#',
  //         molecule: 'ClearValueMolecule',
  //         name: 'ClearValueMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       }
  //     ]
  //   },
  //   moleculesList: [
  //     'GetWorkgroupElementsMolecule',
  //     'GetElementsDatasourceDataMolecule',
  //     'UpdateDatasourceDataMolecule',
  //     'ClearValueMolecule'
  //   ]
  // },
  // compound delete datasource
  // {
  //   name: 'Delete',
  //   icon: 'bubble_chart',
  //   moleculeType: 'Compounds',
  //   type: 'CompoundDeleteDatasource',
  //   molecules: {
  //     ids: 5,
  //     elementId: '#ELEMENT_ID#',
  //     molecules: [
  //       {
  //         id: '#ID1#',
  //         molecule: 'GetWorkgroupElementsMolecule',
  //         name: 'GetWorkgroupElementsMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       },
  //       {
  //         id: '#ID2#',
  //         molecule: 'GetElementsDatasourceDataMolecule',
  //         name: 'GetElementsDatasourceDataMolecule',
  //         rule: {},
  //         logic: {},
  //         index: {
  //           sourceIndex: ['#ELEMENT_ID#'],
  //           targetIndex: ['#ELEMENT_ID#']
  //         }
  //       },
  //       {
  //         id: '#ID3#',
  //         molecule: 'DeleteDatasourceDataMolecule',
  //         name: 'DeleteDatasourceDataMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       },
  //       {
  //         id: '#ID4#',
  //         molecule: 'ClearValueMolecule',
  //         name: 'ClearValueMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       }
  //     ]
  //   },
  //   moleculesList: [
  //     'GetWorkgroupElementsMolecule',
  //     'GetElementsDatasourceDataMolecule',
  //     'DeleteDatasourceDataMolecule',
  //     'ClearValueMolecule'
  //   ]
  // },
  // compound populate molecule
  // {
  //   name: 'Populate',
  //   icon: 'bubble_chart',
  //   moleculeType: 'Compounds',
  //   type: 'CompoundPopulate',
  //   molecules: {
  //     ids: 4,
  //     elementId: '#ELEMENT_ID#',
  //     molecules: [
  //       {
  //         id: '#ID1#',
  //         molecule: 'GetWorkgroupElementsMolecule',
  //         name: 'GetWorkgroupElementsMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       },
  //       {
  //         id: '#ID2#',
  //         molecule: 'GetRelatedDataMolecule',
  //         name: 'GetRelatedDataMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       },
  //       {
  //         id: '#ID3#',
  //         molecule: 'SetElementsValueMolecule',
  //         name: 'SetElementsValueMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       }
  //     ]
  //   },
  //   moleculesList: [
  //     'GetWorkgroupElementsMolecule',
  //     'GetRelatedDataMolecule',
  //     'SetElementsValueMolecule'
  //   ]
  // },
  // filter and update molecule
  // {
  //   name: 'Filter & Update',
  //   icon: 'bubble_chart',
  //   moleculeType: 'Compounds',
  //   type: 'CompoundFilterUpdateValue',
  //   molecules: {
  //     ids: 5,
  //     elementId: '#ELEMENT_ID#',
  //     molecules: [
  //       {
  //         id: '#ID1#',
  //         molecule: 'GetRelatedDataMolecule',
  //         name: 'GetRelatedDataMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       },
  //       {
  //         id: '#ID2#',
  //         molecule: 'GetValueFromRangeDataMolecule',
  //         name: 'GetValueFromRangeDataMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       },
  //       {
  //         id: '#ID3#',
  //         molecule: 'UpdateDatasourceDataMolecule',
  //         name: 'UpdateDatasourceDataMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       },
  //       {
  //         id: '#ID4#',
  //         molecule: 'SubmittableMolecule',
  //         name: 'SubmittableMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       }
  //     ]
  //   },
  //   moleculesList: [
  //     'GetRelatedDataMolecule',
  //     'GetValueFromRangeDataMolecule',
  //     'UpdateDatasourceDataMolecule',
  //     'SubmittableMolecule'
  //   ]
  // },
  // filter range data molecule
  // {
  //   name: 'Filter Data',
  //   icon: 'bubble_chart',
  //   moleculeType: 'Compounds',
  //   type: 'CompoundFilterDataValue',
  //   molecules: {
  //     ids: 4,
  //     elementId: '#ELEMENT_ID#',
  //     molecules: [
  //       {
  //         id: '#ID1#',
  //         molecule: 'GetRelatedDataMolecule',
  //         name: 'GetRelatedDataMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       },
  //       {
  //         id: '#ID2#',
  //         molecule: 'GetValueFromRangeDataMolecule',
  //         name: 'GetValueFromRangeDataMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       },
  //       {
  //         id: '#ID3#',
  //         molecule: 'FilterRangeDataMolecule',
  //         name: 'FilterRangeDataMolecule',
  //         rule: {},
  //         logic: {},
  //         moleculeId: '#ELEMENT_ID#'
  //       }
  //     ]
  //   },
  //   moleculesList: [
  //     'GetRelatedDataMolecule',
  //     'GetValueFromRangeDataMolecule',
  //     'FilterRangeDataMolecule'
  //   ]
  // }
];
