import { NestedTreeControl } from '@angular/cdk/tree';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { uniqBy } from 'lodash-es';
import {
  BehaviorSubject,
  forkJoin,
  from,
  Observable,
  of,
  Subscription,
  throwError as observableThrowError,
} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { RuntimeService } from '../../running-area/services/runtime.service';
import { Constants } from '../../shared/constants';
import { DatasourceTypeId } from '../../shared/enums/datasource-type-id.enum';
import { DatasourceType } from '../../shared/enums/datasource-type.enum';
import { LeapXLEventType } from '../../shared/enums/leapxl-event-type.enum';
import { DatasourceDataResponse } from '../../shared/interfaces/datasource-data-response';
import { IDatasourceInfo } from '../../shared/interfaces/datasource-info.interface';
import { DataSourceSuggestion } from '../../shared/interfaces/datasource-suggestion.interface';
import { DataSourceTreeNodeElement } from '../../shared/interfaces/datasource-tree-node';
import { DatasourceUploadResponse } from '../../shared/interfaces/datasource-upload-response';
import { ParticleTrackFlow } from '../../shared/models/particle-track-flow.model';
import { ActionMolecule } from '../../shared/representative-molecule/interfaces/action-molecules';
import { Bus } from '../../shared/representative-molecule/interfaces/bus';
import { DataElement } from '../../shared/representative-molecule/interfaces/data-element';
import { LeapXLEvent } from '../../shared/representative-molecule/interfaces/leapxl-event';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';
import { FactoryParticleService } from '../../shared/services/factory-particle.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { DataSourceUploadFile } from '../../spreadsheet/models/data-source';
import { Receptor } from '../molecular/receptors.enum';
import { BusService } from '../molecular/services/bus.service';
import { ApiDataTranslatorService } from './api-data-translator.service';
import { CacheService } from './cache.service';
import { ClientStorageService } from './client-storage.service';
import { DataManagementService } from './data-management.service';
import { GenericDialogService } from './generic-dialog.service';
import { ToolsService } from './tools.service';

@Injectable({
  providedIn: 'root',
})
export class ApiDataSourcesService extends DataManagementService {
  openedDataSourceId = 0;
  openedDataSourceSheetIndex = 0;
  dataSourcesTree: NestedTreeControl<DataSourceObject>;
  isLoading = false;
  usedDataSourceTranslations = [];
  datasourcesTreeCache: any = {
    nodes: [],
    enabledDataSourceTypeIds: [],
  };
  AppDatasourcesUsed = [];
  inDebounce = null;
  subscription = new Subscription();
  dataSourceSuggestionMessages = {
    NOT_AVAILABLE: {
      DEFAULT: 'No DataSources Available',
      ALTERNATE: 'No DataSources Available',
    },
    NOT_ALLOW: {
      DEFAULT: 'No DataSources Allowed',
      ALTERNATE: 'No DataSources Allowed',
    },
    AVAILABLE_FOR_ROW: {
      DEFAULT: 'DataSources Available For Row',
      ALTERNATE: 'No DataSources Available For Row',
    },
    NOT_AVAILABLE_FROM_ROW: {
      DEFAULT: 'No DataSources Available From Rows',
      ALTERNATE: 'DataSources Available From Rows',
    },
    AVAILABLE_FROM_ELEMENT: {
      DEFAULT: 'DataSources Available From Element',
      ALTERNATE: 'DataSources Not Available For Element',
    },
    AVAILABLE_FROM_TABLE_ROW: {
      DEFAULT: 'DataSources Available From Table Row',
      ALTERNATE: 'DataSources Not Available For Element',
    },
    ELEMENT_NOT_DATASOURCE_AVAILABLE: {
      DEFAULT: 'Element Does Not Have DataSources Available',
      ALTERNATE: 'DataSources Available For Element',
    },
    LIMITED_BY_TAKE: {
      DEFAULT: 'DataSources Limited By Previous Take DataElement',
      ALTERNATE: 'DataSource Not In Bus',
    },
    LIMITED_BY_API: {
      DEFAULT: 'Datasource Limited By Previous API Request',
      ALTERNATE: 'DataSource Not Available For API',
    },
    LIMITED_BY_BUS: {
      DEFAULT: 'Datasource Limited By Previous Data On The Bus',
      ALTERNATE: 'DataSource Not Available In Bus',
    },
    SPREADSHEET_AVAILABLE: {
      DEFAULT: 'Spreadsheet DataSources Available',
      ALTERNATE: 'Spreadsheet DataSources Not Available',
    },
    API_NO_RESPONSE: {
      DEFAULT: 'No API DataSource Response Available Yet',
      ALTERNATE: 'DataSource Response Not Available',
    },
    API_NO_DATASOURCE: {
      DEFAULT: 'No API DataSources Available',
      ALTERNATE: 'DataSources For API NotAvailable',
    },
    API_NOT_EXIST: {
      DEFAULT: 'API Does Not Exists',
      ALTERNATE: 'API Does Not Exists',
    },
    AVAILABLE_FOR_BUS: {
      DEFAULT: 'DataSources Available From Bus',
      ALTERNATE: 'DataSource Not Available In Bus',
    },
    STORED_AVAILABLE: {
      DEFAULT: 'DataSources Stored Available',
      ALTERNATE: 'DataSources Not Stored',
    },
    STORED_POSSIBLE: {
      DEFAULT: 'Possible DataSources Stored',
      ALTERNATE: 'DataSource Not Stored',
    },
    NO_STORED: {
      DEFAULT: 'No DataSources Stored',
      ALTERNATE: 'DataSource Stored',
    },
    NOT_AVAILABLE_MESSAGING: {
      DEFAULT: 'No DataSources Available For Messaging',
      ALTERNATE: 'DataSources Available For Messaging',
    },
    NOT_AVAILABLE_FOR_BUS: {
      DEFAULT: 'No DataSources On The Bus available',
      ALTERNATE: 'DataSource No Available On Bus',
    },
    AVAILABLE_FOR_API: {
      DEFAULT: 'DataSource Available For API Request',
      ALTERNATE: 'DataSource Not Available',
    },
    RESERVED_RESPONSE: {
      DEFAULT: 'DataSource Reserved For Data Response',
      ALTERNATE: 'Reserved For Data Submission',
    },
    RESERVED_SUBMISSION: {
      DEFAULT: 'DataSource Reserved For Data Submission',
      ALTERNATE: 'Reserved For Data Response',
    },
  };
  apiTreeNodes: any[] = [];
  
  private dataSourcesListSource = new BehaviorSubject<DataSourceObject[]>(null);
  private postMolecules = [
    'AddToDatasourceMolecule',
    'UpdateDatasourceDataMolecule',
    'DeleteDatasourceDataMolecule',
  ];
  private dataProviderMolecules = [
    'AddToDatasourceMolecule',
    'UpdateDatasourceDataMolecule',
    'DeleteDatasourceDataMolecule',
    'FilterByDataElementReferenceMolecule',
    'GetElementsDatasourceDataMolecule',
  ];
  private noModifyDataMolecules = [
    'DatasourcePaginateDataMolecule',
  ];
  private noDataManipulationMolecules = [
    'GetRowDataElementsMolecule',
    'GetCellDataElementMolecule',
  ];
  
  constructor(
    http: HttpClient,
    errorMessengerService: ErrorMessengerService,
    private clientStorage: ClientStorageService,
    private busService: BusService,
    private communicationService: CommunicationService,
    private cacheService: CacheService,
    private toolService: ToolsService,
    private snackerService: SnackerService,
    protected cobbleService: CobbleService,
    protected dialogService: GenericDialogService,
    protected runtimeService: RuntimeService,
    private translatorService: ApiDataTranslatorService,
    private factoryService: FactoryParticleService,
  ) {
    super(http, errorMessengerService, communicationService, cacheService, toolService, snackerService,
      dialogService, runtimeService, cobbleService);
    
    this.subscription.add(this.communicationService.Event.Editor.$ClearSession.subscribe(e => {
      this.AppDatasourcesUsed = [];
      this.usedDataSourceTranslations = [];
      this.openedDataSourceId = 0;
      this.openedDataSourceSheetIndex = 0;
    }));
  }
  
  GetDatasourceInfo(datasourceId: number): Observable<IDatasourceInfo> {
    return this.http.get(this.apiEndpointUrl + '/Info/' + datasourceId)
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error getting datasource Info ${ datasourceId }`,
        );
        return of(err);
      }),
    );
  }
  
  AppsUsingDataSource(datasourceIds: number[]): Observable<[]> {
    return this.http.post(this.apiEndpointUrl + '/Applications/', datasourceIds)
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error getting apps Info ${ datasourceIds }`,
        );
        return of(err);
      }),
    );
  }
  
  GetDatsourceFileForDownload(path: string): Observable<any> {
    return this.http.get(path, { responseType: 'blob' })
    .pipe(
      catchError((error) => {
          const reader = new FileReader();
          
          reader.onload = e => {
            error.error = JSON.parse(JSON.stringify(reader.result as any));
            this.errorMessengerService.HandleError(error, `Error getting Datasources.`);
          };
          
          reader.readAsText(error.error);
          return observableThrowError(new HttpErrorResponse(null));
        },
      ),
    );
  }
  
  GetContextReferences(contexts: string[], offset = 2): Observable<{
    row: number,
    col: number,
    value: string,
    formattedValue: string,
    context: string,
    formula: string,
    comment: string,
    affectedBy: any[],
    affectingTo: any[]
  }[]> {
    return this.http.post(this.apiEndpointUrl + '/GetContextReferences', {
      contexts,
      offset,
    })
    .pipe(
      map((response) => <{
        row: number,
        col: number,
        value: string,
        formattedValue: string,
        context: string,
        formula: string,
        comment: string,
        affectedBy: any[],
        affectingTo: any[]
      }[]>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error getting context references.`),
      ),
    );
  }
  
  GetDatasourceDataSetByKey(dataKey: string): Observable<DatasourceDataResponse> {
    const parametersKey = this.toolService.GenerateGuid();
    this.dataSourceDataRequest = this.dataSourceDataRequest || {};
    this.dataSourceDataRequest[parametersKey] = this.dataSourceDataRequest[parametersKey] || {};
    this.dataSourceDataRequest[parametersKey].pageNumber = 1;
    this.dataSourceDataRequest[parametersKey].pageSize = 999999999;
    this.dataSourceDataRequest[parametersKey].searchFilter = null;
    
    return this.GetDataSourceData(parametersKey, dataKey);
  }
  
  PaginateDatasourceData(dataKey: string, pageNumber: number, pageSize, searchFilter?: string): Observable<DatasourceDataResponse> {
    
    const parametersKey = this.toolService.GenerateGuid();
    this.dataSourceDataRequest = this.dataSourceDataRequest || {};
    this.dataSourceDataRequest[parametersKey] = this.dataSourceDataRequest[parametersKey] || {};
    this.dataSourceDataRequest[parametersKey].pageNumber = pageNumber;
    this.dataSourceDataRequest[parametersKey].pageSize = pageSize;
    this.dataSourceDataRequest[parametersKey].searchFilter = searchFilter;
    
    return this.GetDataSourceData(parametersKey, dataKey);
  }
  
  GetDataSources(dataSourceTypeIds: number[] = [], refresh = false): Observable<{
    nodes: DataSourceTreeNodeElement[],
    enabledDataSourceTypeIds: number[]
  }> {
    return this.http.post(this.apiEndpointUrl + '/Tree', {
      translationIds: this.usedDataSourceTranslations,
      applicationId: this.cobbleService.Cobble.id,
      dataSourceTypeIds: dataSourceTypeIds,
      refresh,
    })
    .pipe(
      map((response) => <{
        nodes: DataSourceTreeNodeElement[],
        enabledDataSourceTypeIds: number[]
      }>response),
      tap((result: {
        nodes: DataSourceTreeNodeElement[],
        enabledDataSourceTypeIds: number[]
      }) => {
        result.nodes.forEach((node, index) => {
          const cacheNodeIndex = this.datasourcesTreeCache.nodes.findIndex(
            n => n.dataSourceType === node.dataSourceType);
          
          if (cacheNodeIndex > -1) {
            this.datasourcesTreeCache.nodes.splice(cacheNodeIndex, 1);
          }
          this.datasourcesTreeCache.nodes.push(node);
          
        });
        return result;
      }),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error getting Datasources.`),
      ),
    );
  }
  
  GetPath(context: string): Observable<string[]> {
    const path = this.cacheService.Get(context);
    // if (this.cacheService.IsCached(context)) {
    if (path && Array.isArray(path) && path.length > 0) {
      return this.cacheService.GetAsync(context);
    } else {
      return this.http.post(this.apiEndpointUrl + '/GetPath', { context })
      .pipe(
        tap((result: DatasourceDataResponse) => {
          this.cacheService.Store(context, result, true);
          return result;
        }),
        map((response) => {
          return <any>response;
        }),
        catchError((err) => {
          this.errorMessengerService.HandleError(
            err,
            `Error getting context path ${ context }`,
          );
          return of(err);
        }),
      );
    }
  }
  
  UpdateUsedDatasourcesForApp(): { dataSourceId: number, context: string }[] {
    const oldTranslations = this.usedDataSourceTranslations;
    const usedDataSources = this.busService.GetDataSourcesForApp();
    const usedDataSourcesIds = usedDataSources.map((d) => {
      return {
        dataSourceId: d.dataSourceId || null,
        context: d.context,
        translationId: d.translationId,
      };
    });
    
    const uniq = uniqBy(usedDataSources.filter(ud => ud.dataSourceType === DatasourceType.Spreadsheet),
      function(e) {
        return e.dataSourceId;
      });
    
    this.UpdateDownloadbleDataSources(
      uniq,
    );
    this.usedDataSourceTranslations = uniqBy(usedDataSources, (e) => {
      return e.translationId;
    })
    .map(ud => ud.translationId);
    
    const removedTranslations = oldTranslations.filter(tId => !this.usedDataSourceTranslations.includes(tId));
    // if (removedTranslations.length > 0) {
    //   this.translatorService.DeleteTranslations(removedTranslations).subscribe();
    // }
    
    return usedDataSourcesIds;
  }
  
  UpdateDownloadbleDataSources(
    datasourcesUsed: any[],
  ) {
    // console.log(datasourcesUsed);
    this.AppDatasourcesUsed = datasourcesUsed;
  }
  
  setDataSources(dataSources) {
    this.dataSourcesListSource.next(dataSources);
  }
  
  DownloadSpreadsheetFile(context: string) {
    console.log('download spreadsheet');
    return this.http.post(this.apiEndpointUrl + '/download', {
      context,
      published: this.cobbleService.Cobble.running && !this.cobbleService.Cobble.preview,
    }, { responseType: 'blob' })
    .pipe(
      map((response) => {
        console.log(response);
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error downloading ${ context }`,
        );
        return of(err);
      }),
    );
  }
  
  CreateDataSource(name: string, sharedType: number) {
    return this.http.post(this.apiEndpointUrl + '/workbook',
      this.AddAuditInfoToRequest({ name: name, sharedType }))
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error creating workbook ${ name }`,
        );
        return of(err);
      }),
    );
  }
  
  GetCustomValues() {
    return this.http.get(this.apiEndpointUrl + '/Custom')
    .pipe(
      map((response) => {
        return (response = <any>response);
      }),
    );
  }
  
  CreateLocalDataStore(name: string) {
    return this.http.post(this.apiEndpointUrl + '/LocalDataStore',
      { name: name, applicationId: this.cobbleService.Cobble.id })
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error creating local store ${ name }`,
        );
        return of(err);
      }),
    );
  }
  
  CreateCustomValue(name: string, value: string) {
    return this.http.post(this.apiEndpointUrl + '/Custom',
      { name: name, value: value, applicationId: this.cobbleService.Cobble.id })
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error creating literal ${ name }`,
        );
        return of(err);
      }),
    );
  }
  
  UpdateCustomValue(name: string, value: string) {
    return this.http.post(this.apiEndpointUrl + '/Custom', { name: name, value: value })
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error updating literal ${ name }`,
        );
        return of(err);
      }),
    );
  }
  
  UpdateLocalDataStore(name: string) {
    return this.http.post(this.apiEndpointUrl + '/LocalDataStore',
      { name: name, applicationId: this.cobbleService.Cobble.id })
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error updating literal ${ name }`,
        );
        return of(err);
      }),
    );
  }
  
  DeleteCustomValue(name: string) {
    return this.http.delete(this.apiEndpointUrl + '/Custom/' + name)
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error deleting literal ${ name }`,
        );
        return of(err);
      }),
    );
  }
  
  SaveSheetChanges(data: any[]) {
    // console.log('Saving changes');
    
    data.forEach(sheet => {
      this.AddAuditInfoToRequest(sheet);
    });
    
    return this.http.put(
      this.apiEndpointUrl + '/Collections/' + this.openedDataSourceId,
      data,
    )
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error saving changes ${ data }`,
        );
        return of(err);
      }),
    );
  }
  
  AddDataSourceSheet(sheetName: string, index: number) {
    // console.log('creating sheet');
    
    return this.http.put(this.apiEndpointUrl + '/AddSheet/' + this.openedDataSourceId,
      this.AddAuditInfoToRequest({
        index: index + 1,
        name: sheetName,
      }))
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error adding sheet ${ sheetName }`,
        );
        return of(err);
      }),
    );
  }
  
  ReorderDataSourceSheet(sheets: string[]) {
    // console.log('order sheet');
    return this.http.put(
      this.apiEndpointUrl + '/ReorderSheets/' + this.openedDataSourceId,
      sheets,
    )
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error ordering sheet ${ sheets }`,
        );
        return of(err);
      }),
    );
  }
  
  RenameSheet(dataSourceId: number, sheetIndex: number, name: string) {
    // console.log('change name');
    return this.http.put(this.apiEndpointUrl + '/RenameSheet/' + dataSourceId, this.AddAuditInfoToRequest({
      index: sheetIndex,
      name: name,
    }))
    .pipe(
      map((response) => {
        this.cacheService.Clear(true);
        setTimeout(() => {
          this.communicationService.Event.Editor.$RecreateProcessBuses.emit();
        }, 300);
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error renaming sheet ${ sheetIndex }`,
        );
        return of(err);
      }),
    );
  }
  
  RenameDataSource(dataSourceId: number, name: string) {
    // console.log('change datasource name');
    return this.http.put(this.apiEndpointUrl + '/Rename/' + dataSourceId, this.AddAuditInfoToRequest({
      name: name,
    }))
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error renaming datasource ${ dataSourceId } ${ name }`,
        );
        return of(err);
      }),
    );
  }
  
  RefreshMetadata(dataSourceId: number) {
    return this.http.put(this.apiEndpointUrl + '/RefreshMetadata/' + dataSourceId, {},
      this.AddBackgroundTask('Refreshing metadata', 'rotate_left'))
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error refreshing metadata for ${ dataSourceId }`,
        );
        return of(err);
      }),
    );
  }
  
  DeleteDataSource(dataSourceIds: number[]) {
    // console.log('delete datasource');
    return this.http.post(this.apiEndpointUrl + '/delete', dataSourceIds)
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error deleting datasource ${ dataSourceIds }`,
        );
        return of(err);
      }),
    );
  }
  
  RemoveDataSourceSheet(sheetIndex: number, dataSourceId: number = null) {
    // console.log('removing sheet');
    return this.http.put(
      `${ this.apiEndpointUrl }/RemoveSheet/${ dataSourceId || this.openedDataSourceId }/${ sheetIndex + 1 }`,
      this.AddAuditInfoToRequest(),
    )
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error removing sheet ${ sheetIndex }`,
        );
        return of(err);
      }),
    );
  }
  
  RemoveVirtualNode(nodeId: number): Observable<{
    node: DataSourceTreeNodeElement,
    parentId: number
  }> {
    // @ts-ignore
    return this.http.post(this.apiEndpointUrl + '/DeleteVirtualNode', {
      nodeId,
      applicationId: this.cobbleService.Cobble.id,
    })
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error removing category`,
        );
        return of(err);
      }),
    );
  }
  
  RenameNode(nodeId: number, name: string): Observable<{
    node: DataSourceTreeNodeElement,
    parentId: number
  }> {
    // @ts-ignore
    return this.http.put(this.apiEndpointUrl + '/RenameVirtualNode', {
      nodeId,
      name,
      translationIds: this.usedDataSourceTranslations,
    })
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error renaming category`,
        );
        return of(err);
      }),
    );
  }
  
  AddVirtualNodeToDatasource(
    datasourceType: string,
    parentId: number,
    nodeId: number,
    name: string,
    dataSourceId: number,
    index = 1,
  ): Observable<{
    node: DataSourceTreeNodeElement,
    parentId: number
  }> {
    // console.log('adding node...');
    // @ts-ignore
    return this.http.post(this.apiEndpointUrl + '/AddVirtualNode', {
      datasourceType,
      parentId,
      nodeId,
      name,
      index,
      description: '',
      dataSourceId,
      translationIds: this.usedDataSourceTranslations,
      applicationId: this.cobbleService.Cobble.id,
    })
    .pipe(
      map((response) => {
        this.cacheService.Clear(true);
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error adding category`,
        );
        return of(err);
      }),
    );
  }
  
  AssingNodeToNode(
    parentId: number,
    nodeId: number,
    dataSourceId: number,
  ): Observable<{ node: DataSourceTreeNodeElement, parentId: number }> {
    // console.log('assigning node...');
    // @ts-ignore
    return this.http.post(this.apiEndpointUrl + '/SaveDataSourceVirtualNode', {
      parentId,
      nodeId,
      dataSourceId,
      applicationId: this.cobbleService.Cobble.id,
      translationIds: this.usedDataSourceTranslations,
    })
    .pipe(
      map((response) => {
        this.cacheService.Clear(true);
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error assigning category`,
        );
        return of(err);
      }),
    );
  }
  
  GetAuthorContext(
    assignee: string,
    classification: string,
    language: string,
    dialect: string,
    document: string,
  ): Observable<string[]> {
    
    // @ts-ignore
    return this.http.post(this.apiEndpointUrl + '/Author/GetContext', {
      assignee,
      classification,
      language,
      dialect,
      document,
    })
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error getting api context ${ assignee } ${ classification } ${ language } ${ dialect } ${ document }`,
        );
        return of(err);
      }),
    );
  }
  
  GetUnificationContext(
    system: string,
    application: string,
    table: string,
  ): Observable<string[]> {
    
    // @ts-ignore
    return this.http.post(this.apiEndpointUrl + '/Unification/GetContext', {
      system,
      application,
      table,
    })
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error gettign db context`,
        );
        return of([]);
      }),
    );
  }
  
  SetAuthorApi(
    assignee: string,
    classification: string,
    language: string,
    dialect: string,
    document: string,
  ): Observable<any> {
    
    // @ts-ignore
    return this.http.post(this.apiEndpointUrl + '/Author/SetApi', {
      assignee,
      classification,
      language,
      dialect,
      document,
      applicationId: this.cobbleService.Cobble.id,
    })
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error adding api ${ assignee } ${ classification } ${ language } ${ dialect } ${ document }`,
        );
        return of(err);
      }),
    );
  }
  
  SetUnificationDbCollection(
    system: string,
    application: string,
    table: string,
  ): Observable<string[]> {
    
    // @ts-ignore
    return this.http.post(this.apiEndpointUrl + '/Unification/SetDatabaseTable', {
      system,
      application,
      table,
      applicationId: this.cobbleService.Cobble.id,
    })
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error adding unified db ${ system }`,
        );
        return of(err);
      }),
    );
  }
  
  SendSMTPMessageSendSMTPMessage(
    message: string,
    subject: string,
    to: string,
    cc: string,
    bcc: string,
    transport: 'EMAIL' | 'SMS',
  ): Observable<string[]> {
    
    // @ts-ignore
    return this.http.post(this.apiEndpointUrl + '/Author/SendSmtpMessage', {
      message,
      subject,
      to,
      cc,
      bcc,
      transport,
    })
    .pipe(
      map((response) => {
        this.communicationService.Event.Runtime.Data.$SMTPMessageSent.emit({
          message,
          subject,
          to,
          cc,
          bcc,
          transport,
        });
        return <any>response;
      }),
    );
  }
  
  deleteDataSourceCollection(data: any) {
    // console.log('Updating collection...');
    return this.http.put(this.apiEndpointUrl + '/deleteData', data)
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error deleting data`,
        );
        return of(err);
      }),
    );
  }
  
  ToggleFullAuditForSpreadsheet(dataSourceId: number, fullAudit: boolean) {
    // console.log('Updating collection...');
    return this.http.put(this.apiEndpointUrl + '/ToggleFullAudit', {
      dataSourceId,
      fullAudit,
    })
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error toggle data`,
        );
        return of(err);
      }),
    );
  }
  
  uploadDataSource(
    datasource: DataSourceUploadFile,
    replace = false,
  ): Observable<DatasourceUploadResponse> {
    
    return this.http.post(
      this.apiEndpointUrl + (replace ? '/Replace' : ''),
      this.AddAuditInfoToRequest(this.datasourceToFormData(datasource)),
      this.AddBackgroundTask(replace ? 'Replacing Datasource' : 'Uploading Datasource',
        replace ? 'published_with_changes' : 'upload_file'),
    )
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error ${ replace ? 'replacing' : 'uploading' } datasource.`,
        );
        // console.error('Error uploading Data Source', err);
        return of(err);
      }),
    );
  }
  
  ReplaceDataSource(datasource: DataSourceUploadFile) {
    return this.uploadDataSource(datasource, true);
  }
  
  ReplaceDataSourceSheet(datasource: DataSourceUploadFile) {
    return this.replaceDataSourceSheet(datasource);
  }
  
  GetDatasourceFile(datasourceId: number, production = false) {
    return this.http.get(`${ this.apiEndpointUrl }/Open/${ datasourceId }/published/${ production }`, {
      responseType: 'blob',
    });
  }
  
  GetDataSourceNodeFromTree(context: string, nodes: DataSourceTreeNodeElement[] = this.datasourcesTreeCache.nodes): DataSourceTreeNodeElement {
    let contexts = context.split(Constants.ContextSeparator);
    let nodeName = contexts.shift();
    
    let node = nodes.find(n => n.name.toLowerCase() === nodeName.toLowerCase());
    
    if (node) {
      if (contexts.length > 0) {
        return this.GetDataSourceNodeFromTree(contexts.join(Constants.ContextSeparator), node.nodes);
      } else {
        return node;
      }
    } else {
      // datasource name includes '.'
      nodeName = `${ nodeName }${ Constants.ContextSeparator }${ contexts.join(Constants.ContextSeparator) }`;
      contexts = [];
      
      node = nodes.find(n => n.name.toLowerCase() === nodeName.toLowerCase());
      if (contexts.length > 0) {
        return this.GetDataSourceNodeFromTree(contexts.join(Constants.ContextSeparator), node.nodes);
      } else {
        return node;
      }
    }
  }
  
  ObtainAcceptedDataSourceForParticle(particle: ActionMolecule, asTake = false): Observable<DataSourceSuggestion> {
    // if asTake is true particle is treated as a FilterByDataElementReferenceMolecule
    const switchCondition = asTake ? 'FilterByDataElementReferenceMolecule' : particle.InternalMoleculeName;
    
    // todo: implement spreadsheets
    return (from(new Promise((resolve, reject) => {
      
      if (this.noDataManipulationMolecules.includes(particle.InternalMoleculeName)) {
        resolve({
          dataElements: [],
          message: this.dataSourceSuggestionMessages.NOT_ALLOW,
        });
      }
      
      const particleTrack = new ParticleTrackFlow(particle);
      particleTrack.TrackUp(true);
      
      const sourceEvent = particleTrack.TrackUpResult.Events.length > 0 ? particleTrack.TrackUpResult.Events[particleTrack.TrackUpResult.Events.length - 1] : null;
      let sourceEventName = this.GetSourceEventName(sourceEvent);
      
      switch (switchCondition) {
        case 'FilterByDataElementReferenceMolecule':
          this.ObtainDataSourceForFilterMolecules(particleTrack, sourceEventName)
          .subscribe(de => resolve(de));
          break;
        case 'AddToDatasourceMolecule':
        case 'UpdateToDatasourceMolecule':
        case 'DeleteToDatasourceMolecule':
          this.ObtainDataSourceForPostMolecules(sourceEventName)
          .subscribe(de => resolve(de));
          break;
        default:
          this.ObtainDataSourceForDefaultMolecules(sourceEventName)
          .subscribe(de => resolve(de));
          break;
      }
    })) as Observable<DataSourceSuggestion>);
  }
  
  LoadDataSourceNodes(dataSourceTypes: string[]): Observable<any> {
    return from(new Promise((resolve, reject) => {
      const nodes = this.datasourcesTreeCache.nodes.filter(
        (ds) => dataSourceTypes.map(type => this.toolService.GetDataSourceIdFromType(type))
        .includes(ds.dataSourceType),
      );
      if (nodes.length === dataSourceTypes.length) {
        return resolve(nodes);
      } else {
        this.GetDataSources(dataSourceTypes.map(type => this.toolService.GetDataSourceIdFromType(type)))
        .subscribe(result => {
          return resolve(result.nodes);
        });
      }
    }));
  }
  
  ObtainDataElementsForContext(context: string): DataElement[] {
    const node = this.GetDataSourceNodeFromTree(context);
    
    if (node) {
      const contextRoot = context.includes(Constants.ContextSeparator) ? context.slice(0,
        context.lastIndexOf(Constants.ContextSeparator)) : context;
      const nodeContexts = this.CreateContextsForNode(node, contextRoot) || [];
      
      return nodeContexts.map(nodeContext => {
        return new DataElement().FromContext(nodeContext.context, nodeContext.dataSourceId);
      });
    } else {
      return [];
    }
  }
  
  CreateContextsForNode(node: DataSourceTreeNodeElement, contextRoot: string, context: string = ''): any | string[] {
    context += `${ node.name }${ node.nodes.length > 0 ? Constants.ContextSeparator : '' }`;
    const extraNodes = [];
    
    // add api roots
    if (contextRoot === DatasourceType.Api && context === `${ DatasourceType.Api }${ Constants.ContextSeparator }`) {
      node.nodes.forEach(n => extraNodes.push({
        context: `${ context }${ n.name }`,
        dataSourceId: n.dataSourceId,
      }));
    }
    
    if (node.nodes.length > 0) {
      return extraNodes.concat(
        (node.nodes as any).flatMap(n => this.CreateContextsForNode(n, contextRoot, context)));
    } else {
      
      if (node.name === contextRoot && node.dataSourceType !== DatasourceTypeId.Api) {
        return [];
      } else {
        const nodeContext = {
          dataSourceId: node.dataSourceId,
          context: contextRoot.includes(
            Constants.ContextSeparator) ? `${ contextRoot }${ Constants.ContextSeparator }${ context }` : context,
        };
        return nodeContext;
      }
    }
  }
  
  GetSourceEventName(event: LeapXLEvent) {
    let sourceEventName = '';
    
    if (event) {
      if (event.EventSource === 'Molecule') {
        const repMoleculeSourceEvent = this.busService.Get(event.SourceId);
        sourceEventName = `${ repMoleculeSourceEvent.Properties.name } - ${ event.EventName }`;
      } else {
        sourceEventName = event.EventName;
      }
    }
    
    return sourceEventName;
  }
  
  FilterDataElementsByDomain(dataElements: DataElement[], domain: string) {
    return dataElements.filter(de => de.Domain.toLowerCase()
    .trim() === domain.toLowerCase()
    .trim());
  }
  
  FilterDataElementsByContext(dataElements: DataElement[], context: string) {
    return dataElements.filter(de => de.Context.toLowerCase()
    .trim() === context.toLowerCase()
    .trim());
  }
  
  private ObtainDataSourceForFilterMolecules(particleTrack: ParticleTrackFlow, eventName: string): Observable<DataSourceSuggestion> {
    let message: {
      DEFAULT: string,
      ALTERNATE: string,
    } = null;
    
    let dataElements: DataElement[] = [];
    const filterDataSourceTypeNodes = [
      DatasourceType.System,
      DatasourceType.LeapXL,
      DatasourceType.Api,
      DatasourceType.Database,
      DatasourceType.UnifiedDatabase,
      DatasourceType.Custom,
      DatasourceType.LocalDataStore,
    ];
    
    console.log(particleTrack);
    
    return (from(new Promise((resolve, reject) => {
      
      // region AVOID TAKE SUGGESTIONS
      const previousMolecule = particleTrack.TrackUpResult.ActionMolecules[0];
      
      if (previousMolecule && this.dataProviderMolecules.includes(
        previousMolecule.InternalMoleculeName) && previousMolecule.DataElements.length === 0) {
        // No DataSources Available
        message = this.dataSourceSuggestionMessages.NOT_AVAILABLE;
        return resolve({ dataElements: [], message });
      }
      
      if (!(previousMolecule && [...this.dataProviderMolecules, ...this.noModifyDataMolecules].includes(
        previousMolecule.InternalMoleculeName))) {
        // DataSources Available for Row
        message = this.dataSourceSuggestionMessages.AVAILABLE_FOR_ROW;
        if ((previousMolecule && previousMolecule.IsActionMoleculeType('GetRowDataElementsMolecule'))) {
          const parentMolecule = this.busService.GetParent(previousMolecule.MoleculeId.toString());
          const parentBus = parentMolecule.Buses[0];
          
          if (parentBus) {
            this.ObtainDataSourceForBusOutput(null, parentBus)
            .subscribe(de => {
              
              if (de.dataElements.length === 0) {
                message = this.dataSourceSuggestionMessages.NOT_AVAILABLE_FROM_ROW;
                
              }
              
              return resolve({ dataElements: de.dataElements, message });
            });
          } else {
            // No DataSources Available from rows
            message = this.dataSourceSuggestionMessages.NOT_AVAILABLE_FROM_ROW;
            return resolve({ dataElements: [], message });
          }
        }
        // DataSources available from element
        else if (particleTrack.TrackUpResult.Bus.ReceptorIsOutput()) {
          message = this.dataSourceSuggestionMessages.AVAILABLE_FROM_ELEMENT;
          this.ObtainDataSourceForBusOutput(particleTrack)
          .subscribe(de => {
            return resolve({ dataElements: de.dataElements, message });
          });
        }
        // Datasources from selected row events
        else if ((previousMolecule === undefined || [...this.dataProviderMolecules].includes(
          previousMolecule.InternalMoleculeName)) && particleTrack.TrackUpResult.Events.length > 0 && particleTrack.TrackUpResult.Events[particleTrack.TrackUpResult.Events.length - 1].EventType === LeapXLEventType.RowSelected) {
          
          message = this.dataSourceSuggestionMessages.AVAILABLE_FROM_TABLE_ROW;
          const rowSelectedEvent = particleTrack.TrackUpResult.Events[particleTrack.TrackUpResult.Events.length - 1];
          //
          
          const rowSelectedRepMolecule = this.busService.Get(rowSelectedEvent.SourceId);
          const repMoleculeBus = rowSelectedRepMolecule.Buses[0];
          
          if (repMoleculeBus) {
            this.ObtainDataSourceForBusOutput(null, repMoleculeBus)
            .subscribe(de => {
              if (de.dataElements.length === 0) {
                message = this.dataSourceSuggestionMessages.NOT_AVAILABLE_FROM_ROW;
              }
              return resolve({ dataElements: de.dataElements, message });
            });
          } else {
            // No DataSources Available from rows
            message = this.dataSourceSuggestionMessages.NOT_AVAILABLE_FROM_ROW;
            return resolve({ dataElements: [], message });
          }
          //
        } else {
          // Element does not have DataSources available
          message = this.dataSourceSuggestionMessages.ELEMENT_NOT_DATASOURCE_AVAILABLE;
          return resolve({ dataElements: [], message });
        }
      } else if (previousMolecule.IsActionMoleculeType('FilterByDataElementReferenceMolecule')) {
        // DataSources limited by previous Take DataElement
        message = this.dataSourceSuggestionMessages.LIMITED_BY_TAKE;
        return resolve({ dataElements: previousMolecule.DataElements, message });
      }
      // endregion
      else {
        this.LoadDataSourceNodes(filterDataSourceTypeNodes)
        .subscribe(nodes => {
          
          filterDataSourceTypeNodes.forEach(dsType => {
            const typeDataElements = this.ObtainDataElementsForContext(dsType);
            
            if (dsType === DatasourceType.Api) {
              // Datasource limited by previous API request
              message = this.dataSourceSuggestionMessages.LIMITED_BY_API;
              dataElements.push(...typeDataElements.filter(de => de.Collection !== 'data'));
            } else {
              // Datasource limited by previous data on the Bus
              message = this.dataSourceSuggestionMessages.LIMITED_BY_BUS;
              dataElements.push(...typeDataElements);
            }
          });
          
          const priorityDataSource = particleTrack.TrackUpResult.DataSources[0];
          
          if (priorityDataSource) {
            const priorityMolecule = particleTrack.TrackUpResult.ActionMolecules.find(
              m => m.ContainsDataElementParticle(priorityDataSource.ParticleId));
            const priorityBus = particleTrack.TrackUpResult.Buses.find(
              bus => bus.ContainsParticle(priorityMolecule));
            const repMolecule = this.busService.Get(priorityBus.RepresentativeMoleculeId.toString());
            
            switch (priorityDataSource.DataSourceType) {
              case DatasourceType.Spreadsheet:
                // Spreadsheet DataSources Available
                message = this.dataSourceSuggestionMessages.SPREADSHEET_AVAILABLE;
                dataElements = priorityMolecule.DataElements;
                return resolve({ dataElements, message });
                break;
              case DatasourceType.Api:
              case DatasourceType.Database:
              case DatasourceType.UnifiedDatabase:
                if (priorityDataSource.Collection === 'data' ||
                  priorityDataSource.Collection === undefined ||
                  priorityDataSource.Collection === null
                ) {
                  
                  // DataSources available for previous API request
                  message = this.dataSourceSuggestionMessages.AVAILABLE_FOR_API;
                  message.DEFAULT = `DataSources available for ${ priorityDataSource.GetDataSourceName() } API Request`;
                  dataElements = this.FilterDataElementsByDomain(dataElements, priorityDataSource.Domain);
                  
                  if (dataElements.length === 0 || dataElements.filter(
                    de => de.Collection && de.Collection !== '(Extra)').length === 0) {
                    const node = this.GetDataSourceNodeFromTree(priorityDataSource.Domain);
                    
                    if (node) {
                      if (node.nodes.length === 2 || node.nodes.length === 1) {
                        message = this.dataSourceSuggestionMessages.API_NO_RESPONSE;
                      } else if (node.nodes.length === 0) {
                        //broken API
                        message = this.dataSourceSuggestionMessages.API_NO_DATASOURCE;
                      }
                    } else {
                      message = this.dataSourceSuggestionMessages.API_NOT_EXIST;
                    }
                  }
                  
                } else {
                  // DataSources available for bus data
                  message = this.dataSourceSuggestionMessages.AVAILABLE_FOR_BUS;
                  dataElements = this.FilterDataElementsByContext(dataElements, priorityDataSource.Context);
                }
                
                return resolve({
                  dataElements: this.AssignDataElementsSources(dataElements, eventName, repMolecule.Id,
                    repMolecule.Properties.name, repMolecule.Icon),
                  message,
                });
                break;
              case DatasourceType.LocalDataStore:
                dataElements = [];
                const trackBuses = particleTrack.TrackUpResult.Buses;
                const postMolecules = this.GetPostMoleculesForContext(trackBuses, priorityDataSource.Context);
                
                if (postMolecules.length > 0) {
                  this.GetDataElementsForParticleAsTake(
                    postMolecules,
                    eventName,
                    repMolecule.Id,
                    repMolecule.Properties.name,
                    repMolecule.Icon,
                  )
                  .subscribe(dataElements => {
                    // Possible DataSources stored
                    message = dataElements.length > 0 ? this.dataSourceSuggestionMessages.STORED_AVAILABLE : this.dataSourceSuggestionMessages.NO_STORED;
                    return resolve({ dataElements, message });
                  });
                } else {
                  
                  const buses = this.busService.GetAllBuses();
                  
                  const postMolecules = this.GetPostMoleculesForContext(buses, priorityDataSource.Context);
                  
                  if (postMolecules.length > 0) {
                    this.GetDataElementsForParticleAsTake(
                      postMolecules,
                      eventName,
                      repMolecule.Id,
                      repMolecule.Properties.name,
                      repMolecule.Icon,
                    )
                    .subscribe(dataElements => {
                      // Possible DataSources stored
                      message = this.dataSourceSuggestionMessages.STORED_POSSIBLE;
                      return resolve({ dataElements, message });
                    });
                  } else {
                    // No DataSources stored
                    message = this.dataSourceSuggestionMessages.NO_STORED;
                    return resolve({ dataElements: [], message });
                  }
                }
                break;
              case DatasourceType.InternetMessaging:
                message = this.dataSourceSuggestionMessages.NOT_AVAILABLE_MESSAGING;
                dataElements = [];
                return resolve({
                  dataElements,
                  message,
                });
                break;
              default:
                message = this.dataSourceSuggestionMessages.AVAILABLE_FOR_BUS;
                dataElements = priorityMolecule.DataElements;
                return resolve({
                  dataElements: this.AssignDataElementsSources(dataElements, eventName, repMolecule.Id,
                    repMolecule.Properties.name, repMolecule.Icon),
                  message,
                });
                break;
            }
          } else {
            // No DataSources on the Bus available
            message = this.dataSourceSuggestionMessages.NOT_AVAILABLE_FOR_BUS;
            dataElements = [];
            return resolve({ dataElements, message });
          }
        });
      }
    })) as Observable<DataSourceSuggestion>);
  }
  
  private ObtainDataSourceForPostMolecules(eventName: string) {
    let message = this.dataSourceSuggestionMessages.RESERVED_SUBMISSION;
    
    const postDataSourceTypeNodes = [
      DatasourceType.InternetMessaging,
      DatasourceType.Api,
      DatasourceType.Database,
      DatasourceType.UnifiedDatabase,
      DatasourceType.LocalDataStore];
    const dataElements = [];
    
    // DataSource reserved for data submission
    return (from(new Promise((resolve, reject) => {
      this.LoadDataSourceNodes(postDataSourceTypeNodes)
      .subscribe(nodes => {
        postDataSourceTypeNodes.forEach(dsType => {
          const typeDataElements = this.ObtainDataElementsForContext(dsType);
          if (dsType === DatasourceType.Api) {
            dataElements.push(...typeDataElements.filter(de => de.Collection === 'data' || de.IsRoot));
          } else {
            dataElements.push(...typeDataElements);
          }
        });
        
        dataElements.forEach(de => {
          de.SourceTrackEventName = eventName;
        });
        return resolve({ dataElements, message });
      });
    })) as Observable<DataSourceSuggestion>);
  }
  
  private ObtainDataSourceForDefaultMolecules(eventName: string) {
    let message = this.dataSourceSuggestionMessages.RESERVED_RESPONSE;
    
    const dataElements = [];
    const defaultDataSourceTypeNodes = [
      DatasourceType.LeapXL,
      DatasourceType.System,
      DatasourceType.Custom,
      DatasourceType.LocalDataStore];
    
    // DataSource reserved for data response
    return (from(new Promise((resolve, reject) => {
      this.LoadDataSourceNodes(defaultDataSourceTypeNodes)
      .subscribe(nodes => {
        defaultDataSourceTypeNodes.forEach(dsType => {
          dataElements.push(...this.ObtainDataElementsForContext(dsType));
        });
        
        dataElements.forEach(de => {
          de.SourceTrackEventName = eventName;
        });
        return resolve({ dataElements, message });
      });
    })) as Observable<DataSourceSuggestion>);
  }
  
  private GetDataElementsForParticleAsTake(molecules: ActionMolecule[], sourceEventName: string, repMoleculeId: number, repMoleculeName: string, repMoleculeIcon: string) {
    return (from(new Promise((resolve, reject) => {
      let dataElements = [];
      forkJoin(molecules.map(m => this.ObtainAcceptedDataSourceForParticle(m, true)))
      .subscribe(des => {
        
        des.forEach(d => dataElements.push(...(d.dataElements)));
        
        return resolve(
          this.AssignDataElementsSources(dataElements, sourceEventName, repMoleculeId, repMoleculeName,
            repMoleculeIcon));
      });
    })) as Observable<DataElement[]>);
  }
  
  private AssignDataElementsSources(dataElements: DataElement[], sourceEventName: string = '', repMoleculeId: number = 0, repMoleculeName: string = '', repMoleculeIcon: string = '') {
    dataElements.forEach(de => {
      de.SourceTrackEventName = sourceEventName;
      de.SourceTrackRepMoleculeId = repMoleculeId;
      de.SourceTrackRepMoleculeName = repMoleculeName;
      de.SourceTrackRepMoleculeIcon = repMoleculeIcon;
    });
    
    return dataElements;
  }
  
  private GetPostMoleculesForContext(buses: Bus[], context: string): ActionMolecule[] {
    const postMolecules: ActionMolecule[] = [];
    const dataStoreBuses = buses.filter(b => b.ContainsSpecificContext(context));
    
    dataStoreBuses.forEach(bus => {
      const addUpdateDeleteMolecules = bus.GetActionMoleculesByInternalNameAndContext(this.postMolecules,
        context);
      
      if (addUpdateDeleteMolecules.length > 0) {
        postMolecules.push(addUpdateDeleteMolecules.reduce(
          (previous, current) => previous.Priority > current.Priority ? previous : current));
      }
    });
    
    return postMolecules;
  }
  
  private ObtainDataSourceForBusOutput(particleTrack: ParticleTrackFlow = null, bus: Bus = null) {
    let message: {
      DEFAULT: string,
      ALTERNATE: string,
    } = null;
    return (from(new Promise((resolve, reject) => {
      
      if (particleTrack || bus) {
        const busSource = bus || particleTrack.TrackUpResult.Bus;
        const repMolecule = this.busService.Get(busSource.RepresentativeMoleculeId.toString());
        const inputBuses = repMolecule.GetBusesByReceptor(Receptor.ValueInput);
        const dataElements = [];
        let trackCompleted = 0;
        
        if (inputBuses.length > 0) {
          inputBuses.forEach(bus => {
            const lastMolecule = bus.GetLastActionMolecule();
            
            if (lastMolecule && this.dataProviderMolecules.includes(lastMolecule.InternalMoleculeName)) {
              this.factoryService.CreateActionMoleculeParticle('FilterByDataElementReferenceMolecule',
                repMolecule.Id)
              .then(molecule => {
                bus.Particles.push(molecule);
                this.ObtainAcceptedDataSourceForParticle(molecule)
                .subscribe(de => {
                  bus.RemoveParticle(molecule.ParticleId);
                  dataElements.push(...de.dataElements);
                  message = de.message;
                  trackCompleted++;
                  
                  if (trackCompleted === inputBuses.length) {
                    return resolve({ dataElements, message });
                  }
                });
              });
            } else {
              return resolve({ dataElements: [], message });
            }
          });
        } else {
          return resolve({ dataElements: [], message });
        }
      } else {
        return resolve({ dataElements: [], message });
      }
      
    })) as Observable<DataSourceSuggestion>);
  }
  
  private shortDataSourceName(ds: DataSourceObject) {
    ds.shortName = this.toolService.shortString(ds.name, 25, '...');
    if (ds.list && ds.list.length) {
      ds.list.forEach((childDS) => {
        this.shortDataSourceName(childDS);
      });
    }
  }
  
  private replaceDataSourceSheet(datasource: DataSourceUploadFile) {
    let headers = new HttpHeaders();
    headers = headers.append(
      'Authorization',
      'bearer ' + this.clientStorage.getToken(),
    );
    const options = {
      headers: headers,
    };
    
    return this.http.post(
      this.apiEndpointUrl + '/ReplaceSheet',
      this.AddAuditInfoToRequest(this.datasourceToFormData(datasource)),
      options,
    )
    .pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error replacing sheet for datasource ${ datasource.id }`,
        );
        // console.error('Error uploading Data Source', err);
        return of(err);
      }),
    );
  }
  
  private datasourceToFormData(datasource: DataSourceUploadFile): FormData {
    const file: File = datasource.dataFile;
    const formData = new FormData();
    const pipe: DatePipe = new DatePipe('en-US');
    
    formData.append('approvedBy', datasource.approvedBy);
    formData.append(
      'dateApproved',
      pipe.transform(datasource.dateApproved, 'short'),
    );
    formData.append('ownerId', String(datasource.ownerId));
    formData.append('fullAudit', String(datasource.fullAudit));
    formData.append('isTemplate', String(datasource.isTemplate));
    formData.append('published', String(datasource.published));
    formData.append('name', datasource.name);
    formData.append('delimiter', datasource.delimiter);
    formData.append('encoding', datasource.encoding);
    formData.append('dataFile', file, file.name);
    formData.append('id', String(datasource.id));
    formData.append('description', datasource.description);
    formData.append('sheetNameToReplace', datasource.sheetNameToReplace);
    formData.append('sheetName', datasource.sheetName);
    formData.append('enabled', String(datasource.enabled));
    formData.append('cobbleId', String(datasource.cobbleId));
    formData.append('type', datasource.type);
    formData.append('extension', datasource.extension);
    formData.append('tables', JSON.stringify(datasource.tables));
    formData.append('returnFile', JSON.stringify(datasource.returnFile));
    formData.append('sharedType', datasource.sharedType.toString());
    formData.append('applicationId', this.cobbleService.Cobble.id.toString());
    
    return formData;
  }
}

export class DataSourceObject {
  constructor(
    public id: number,
    public other: number,
    public name: string,
    public path: string,
    public data: any,
    public list?: any,
    public shortName?: string,
    public isLoading?: boolean,
    public recentlyUploaded?: boolean,
  ) {
  }
}
