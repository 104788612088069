import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { NgxImageCompressService } from 'ngx-image-compress';
import { of, Subject } from 'rxjs';
import { debounceTime, delay, flatMap, map } from 'rxjs/operators';
import { Permissions } from '../../admin/models/permissions.enum';
import { ApiMoleculesService } from '../../core/services/api-molecules.service';
import { ApiPropertiesService } from '../../core/services/api-properties.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { ToolsService } from '../../core/services/tools.service';
import { UserMenuService } from '../../core/services/user-menu.service';
import { Theme } from '../../shared/interfaces/theme.interface';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { ConnectionStateService } from '../../shared/services/connection-state.service';
import { DraggableWindowManagerService } from '../../shared/services/draggable-window-manager.service';
import { DraggableWindowService, DraggableWindowType } from '../../shared/services/draggable-window.service';
import { ApiThematicService } from '../services/api-thematic.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-new-cobble',
  templateUrl: './new-cobble.component.html',
  styleUrls: ['./new-cobble.component.scss'],
  animations: [
    trigger('fadeIn', [transition(':enter', [style({ opacity: '0' }), animate('.5s ease-out', style({ opacity: '1' }))])]),
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(-20%)', opacity: 0 }),
        animate('500ms', style({ transform: 'translateY(0', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('500ms', style({ transform: 'translateY(-20%)', opacity: 0 })),
      ]),
    ]),
    trigger('enterAnimationLabel', [
      transition(':enter', [
        style({ transform: 'translateY(-20%)', opacity: 0 }),
        animate('100ms', style({ transform: 'translateY(0', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('100ms', style({ transform: 'translateY(-20%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class NewCobbleComponent implements OnInit, AfterViewInit {
  @ViewChild('appNameInput', { static: false })
  appNameInput: ElementRef;
  public nameTyped = new Subject<any>();
  cobbleName: string;
  cobbleNameAvailable = true;
  uploader: FileUploader = new FileUploader({});
  cobbleDescription: string;
  stages: string[];
  userPermissions = [];
  obtainingNameAvailability = false;
  creatingApp = false;
  appIcon = null;
  themeSelected?: Theme;
  showThemeSelector = true;
  modifyThemeOnDefaultThemedApps = false;
  lockCompanyDefaultApp = false;
  
  constructor(
    private router: Router,
    private workAreaService: WorkAreaService,
    private titleService: Title,
    private cobbleService: CobbleService,
    private connectionStateService: ConnectionStateService,
    private propertiesService: ApiPropertiesService,
    private imageCompress: NgxImageCompressService,
    private moleculesService: ApiMoleculesService,
    private communicationService: CommunicationService,
    private toolsService: ToolsService,
    private draggableWindowService: DraggableWindowService,
    private dragableWindowManagerService: DraggableWindowManagerService,
    private clientStorageService: ClientStorageService,
    private apiThematic: ApiThematicService,
    private userMenuService: UserMenuService,
  ) {
    this.stages = [];
    this.userPermissions = clientStorageService.getPermissions();
    this.modifyThemeOnDefaultThemedApps = this.userMenuService.checkPermission(Permissions.ModifyThemeOnDefaultThemedApps);
    
  }
  
  ngOnInit() {
    this.lockCompanyDefaultApp = this.clientStorageService.getLockAppsToDefaultTheme().toString() === 'true';
    if (this.clientStorageService.getCompanyDefaultThemeId() > 0) {
      this.showThemeSelector = false;
      this.apiThematic.GetTheme(this.clientStorageService.getCompanyDefaultThemeId()).subscribe(theme => {
        this.themeSelected = theme;
        this.showThemeSelector = true;
      });
    }
    
    this.communicationService.Event.Editor.AppsTree.$RefreshAppsTree.emit();
    this.workAreaService.topBarTitle = 'Create New App';
    this.workAreaService.activeCobble.next(0);
    this.titleService.setTitle('Leap | New App');
    
    this.communicationService.Event.Editor.SidePanels.$Close.emit(2);
    this.nameTyped
    .pipe(
      map(event => {
        event.target.value = event.target.value.replace(/[^A-Za-z0-9 ]*/g, '');
        if (event.target.value === '') {
          this.cobbleNameAvailable = true;
        }
        return event.target.value;
      }),
      debounceTime(300),
      flatMap(search => {
        return of(search).pipe(delay(50));
      }),
    )
    .subscribe(value => {
      if (value !== '') {
        this.cobbleNameAvailable = true;
        this.obtainingNameAvailability = true;
        this.propertiesService.VerifyCobbleName(value).subscribe((availability: boolean) => {
          this.obtainingNameAvailability = false;
          this.cobbleNameAvailable = availability;
        });
      }
    });
    
    this.workAreaService.leftPanelIndexTab = 2;
    this.workAreaService.HideDraggableWindows();
    setTimeout(() => {
      this.appNameInput.nativeElement.focus();
      this.appNameInput.nativeElement.select();
    }, 400);
  }
  
  ngAfterViewInit(): void {
  }
  
  themeOptionSelected(theme?: Theme) {
    this.themeSelected = theme;
  }
  
  CreateNewCobble() {
    if (this.creatingApp) {
      return false;
    }
    
    this.creatingApp = true;
    this.cobbleService.ClearCobbleSession();
    
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    const appProperties: any = [
      {
        name: 'name',
        value: this.cobbleName,
        path: 'properties',
      },
      {
        name: 'description',
        value: this.cobbleDescription,
        path: 'properties',
      },
      {
        name: 'newDataSubmitMechanism',
        value: true,
        path: 'properties',
      },
      {
        name: 'views',
        value: [
          {
            id: 1,
            name: 'View 1',
            locked: false,
          },
        ],
        path: 'properties',
      },
    ];
    
    if (this.themeSelected) {
      Object.keys(this.themeSelected.representativeMoleculeStyles).forEach(key => {
        const moleculeTheme = this.themeSelected.representativeMoleculeStyles[key];
        moleculeTheme.asStyle = true;
      });
      
      appProperties.push({
        name: 'appTheme',
        value: this.themeSelected,
        path: '',
      });
    }
    
    if (this.appIcon) {
      appProperties.push({
        name: 'icon',
        value: this.appIcon,
        path: '',
      });
    }
    
    this.moleculesService.CreateCobble(appProperties).subscribe(result => {
      this.workAreaService.$cobbleCreated.next(result.molecules);
      this.router.navigate(['/workarea/' + result.molecules.id]);
    });
  }
  
  cancel() {
    this.router.navigate(['/workarea']);
  }
  
  submitForm(invalid) {
    // console.log('submit');
    if (this.creatingApp || this.obtainingNameAvailability) {
      return false;
    }
    if (!invalid && this.cobbleNameAvailable) {
      this.CreateNewCobble();
    }
  }
  
  ImportLeapXLFile(event: MouseEvent) {
    this.draggableWindowService.OpenDraggableWindow(
      'Import Leap File (Beta Test)',
      DraggableWindowType.LeapxlImport,
      event,
      { event: event },
    );
  }
  
  SelectIconFile(event: any) {
    document.getElementById('AppimageFileSelector').click();
  }
  
  fileSelected(eventData: any[]) {
    const file = eventData[0];
    const filename = file.name;
    let ratioIcon = 80;
    const maxIconDimension = 200;
    
    if (file) {
      this.toolsService.GetHeightAndWidthFromImageFile(file).then(dimensions => {
        ratioIcon = (maxIconDimension / dimensions.max) * 100;
        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.imageCompress.compressFile(event.target.result, -1, ratioIcon, 50).then(compressedImg => {
            this.appIcon = compressedImg;
          });
        };
        reader.readAsDataURL(file);
      });
    }
  }
}
