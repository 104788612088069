import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { CdkStep, STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper';
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { CompactType, DisplayGrid, GridsterConfig, GridsterItem, GridsterItemComponentInterface, GridType } from '@leapxl/gridster';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { BuilderService } from '../../../../core/builder/builder.service';
import { Receptor } from '../../../../core/molecular/receptors.enum';
import { BusService } from '../../../../core/molecular/services/bus.service';
import { ProcessorService } from '../../../../core/molecular/services/processor.service';
import { ApiFileService } from '../../../../core/services/api-file.service';
import { ApiPropertiesService } from '../../../../core/services/api-properties.service';
import { ToolsService } from '../../../../core/services/tools.service';
import { WorkAreaService } from '../../../../workarea/workarea.service';
import { PropertyVersioningDto } from '../../../dtos/versioning-dto';
import { LeapXLEventType } from '../../../enums/leapxl-event-type.enum';
import { RepresentativeMoleculesType } from '../../../enums/representative-molecules-types.enum';
import { CommunicationService } from '../../../services/communication.service';
import { DraggableWindowService, DraggableWindowType } from '../../../services/draggable-window.service';
import { BadgeMoleculeComponent } from '../../badge-molecule/badge-molecule.component';
import { IRepresentativeMolecule } from '../../interfaces/representative-molecule.interface';
import { DragService } from '../../services/drag.service';
import { BaseMoleculeComponent } from '../base-molecule/base-molecule.component';
import { BreadcrumbMoleculeComponent } from '../breadcrumb-molecule/breadcrumb-molecule.component';
import { ButtonMoleculeComponent } from '../button-molecule/button-molecule.component';
import { ChartMoleculeComponent } from '../chart-molecule/chart-molecule.component';
import { CheckboxMoleculeComponent } from '../checkbox-molecule/checkbox-molecule.component';
import { DatepickerMoleculeComponent } from '../datepicker-molecule/datepicker-molecule.component';
import { DropdownMoleculeComponent } from '../dropdown-molecule/dropdown-molecule.component';
import { H1MoleculeComponent } from '../h1-molecule/h1-molecule.component';
import { H2MoleculeComponent } from '../h2-molecule/h2-molecule.component';
import { H3MoleculeComponent } from '../h3-molecule/h3-molecule.component';
import { H4MoleculeComponent } from '../h4-molecule/h4-molecule.component';
import { H5MoleculeComponent } from '../h5-molecule/h5-molecule.component';
import { IconMoleculeComponent } from '../icon-molecule/icon-molecule.component';
import { IframeMoleculeComponent } from '../iframe-molecule/iframe-molecule.component';
import { ImageMoleculeComponent } from '../image-molecule/image-molecule.component';
import { LabelMoleculeComponent } from '../label-molecule/label-molecule.component';
import { QrCodeMoleculeComponent } from '../qrcode-molecule/qrcode-molecule.component';
import { RadioMoleculeComponent } from '../radio-molecule/radio-molecule.component';
import { TableMoleculeComponent } from '../table-molecule/table-molecule.component';
import { TextareaMoleculeComponent } from '../textarea-molecule/textarea-molecule.component';
import { TextboxMoleculeComponent } from '../textbox-molecule/textbox-molecule.component';
import { WorkgroupMoleculeComponent } from '../workgroup-molecule/workgroup-molecule.component';

@Component({
  selector: 'app-stepper-molecule',
  templateUrl: './stepper-molecule.component.html',
  styleUrls: ['./stepper-molecule.component.scss'],
  animations: [
    trigger('flyInOut', [
      transition('void => fly', [
        animate(300, keyframes([style({ transform: 'translateY(50%)', opacity: 0 }), style({ transform: 'translateY(0)', opacity: 1 })])),
      ]),
    ]),
    trigger('flyInOut', [transition('void => fade', [animate(3000, keyframes([style({ opacity: 0 }), style({ opacity: 1 })]))])]),
    trigger('fade', [transition('void => *', [style({ opacity: 0 }), animate(500)]), transition('* => void', [animate(200, style({ opacity: 0 }))])]),
    trigger('fadeIn', [transition(':enter', [style({ opacity: '0' }), animate('.7s ease-out', style({ opacity: '1' }))])]),
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class StepperMoleculeComponent extends BaseMoleculeComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('stepper', { static: false })
  stepper: MatStepper;
  
  children: IRepresentativeMolecule[] = [];
  headChildren: IRepresentativeMolecule[] = [];
  headChildrenGenerated: IRepresentativeMolecule[] = [];
  subscription: Subscription;
  elementsPushed = [];
  environment = environment;
  dragStart: any = {};
  sizeStart: any = {};
  animation = '';
  ImgSource = '';
  debug = false;
  orderedData = [];
  headChildrenIds = [];
  stepsChildrenIds = [];
  stepsChildrenGenerated = [];
  lastStepSelected: CdkStep = null;
  lastStepSelectedIndex = 0;
  stepperHeadingGridsterConfig: GridsterConfig;
  DESELECT_INDEX = 9999999;
  avoidEventFiring = false;
  
  // region GRIDSTER CONFIG
  runningGridsterConfig = {
    collision: false,
    gridType: GridType.Fit,
    compactType: CompactType.None,
    margin: 0,
    outerMargin: true,
    outerMarginTop: null,
    outerMarginRight: null,
    outerMarginBottom: null,
    outerMarginLeft: null,
    useTransformPositioning: true,
    mobileBreakpoint: 0,
    minCols: this.context ? this.context.ResponsiveProperties().colsQty : 1000,
    maxCols: this.context ? this.context.ResponsiveProperties().colsQty : 1000,
    minRows: this.context ? this.context.ResponsiveProperties().rowsQty : 1000,
    maxRows: this.context ? this.context.ResponsiveProperties().rowsQty : 1000,
    maxItemCols: this.context ? this.context.ResponsiveProperties().colsQty : 1000,
    maxItemRows: this.context ? this.context.ResponsiveProperties().rowsQty : 1000,
    minItemRows: 1,
    maxItemArea: 200000,
    minItemArea: 1,
    defaultItemCols: 3,
    defaultItemRows: 3,
    fixedColWidth: 20,
    fixedRowHeight: 20,
    keepFixedHeightInMobile: false,
    keepFixedWidthInMobile: false,
    scrollSensitivity: 0,
    scrollSpeed: 0,
    enableEmptyCellClick: false,
    enableEmptyCellContextMenu: false,
    enableEmptyCellDrop: false,
    enableEmptyCellDrag: false,
    emptyCellDragMaxCols: 50,
    emptyCellDragMaxRows: 50,
    ignoreMarginInRow: false,
    draggable: {
      enabled: false,
    },
    resizable: {
      enabled: false,
    },
    swap: false,
    pushItems: false,
    disablePushOnDrag: false,
    disablePushOnResize: false,
    pushDirections: {
      north: false,
      east: false,
      south: false,
      west: false,
    },
    pushResizeItems: false,
    displayGrid: DisplayGrid.Always,
    disableWindowResize: false,
    disableWarnings: false,
    scrollToNewItems: false,
  };
  editorGridsterConfig = {
    dragStartCallback: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
      this.workAreaService.mobileEmulatorSize = 'normal';
      this.workAreaService.RunMobileTest(false);
      this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
    },
    collision: false,
    gridType: GridType.Fit,
    compactType: CompactType.None,
    margin: 0,
    outerMargin: true,
    outerMarginTop: null,
    outerMarginRight: null,
    outerMarginBottom: null,
    outerMarginLeft: null,
    useTransformPositioning: true,
    mobileBreakpoint: 0,
    minCols: this.context ? this.context.ResponsiveProperties().colsQty : 1000,
    maxCols: this.context ? this.context.ResponsiveProperties().colsQty : 1000,
    minRows: this.context ? this.context.ResponsiveProperties().rowsQty : 1000,
    maxRows: this.context ? this.context.ResponsiveProperties().rowsQty : 1000,
    maxItemCols: this.context ? this.context.ResponsiveProperties().colsQty : 1000,
    maxItemRows: this.context ? this.context.ResponsiveProperties().rowsQty : 1000,
    minItemCols: 1,
    minItemRows: 1,
    maxItemArea: 200000,
    minItemArea: 1,
    defaultItemCols: 3,
    defaultItemRows: 3,
    fixedColWidth: 20,
    fixedRowHeight: 20,
    keepFixedHeightInMobile: false,
    keepFixedWidthInMobile: false,
    scrollSensitivity: 0,
    scrollSpeed: 0,
    enableEmptyCellClick: true,
    enableEmptyCellContextMenu: false,
    enableEmptyCellDrop: true,
    itemChangeCallback: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
      // console.log('item changed', item, itemComponent);
      if (item.change === 'dragStart') {
        // this.workAreaService.HideElementFocusedMenu();
        this.workAreaService.elementClicked = this.busService.Get(item.id);
      }
      if (item.change === 'push') {
        if (this.elementsPushed.findIndex(e => e.id === item.id) === -1) {
          this.elementsPushed.push(item);
        }
        this.Throttle(
          (func, delay, context) => {
            const properties = [];
            
            this.elementsPushed.forEach(element => {
              properties.push(
                new PropertyVersioningDto({
                  elementId: element.id,
                  property: 'x',
                  value: element.x,
                  path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                  change: `Position Changed`,
                  name: 'Position - X',
                }),
                new PropertyVersioningDto({
                  elementId: element.id,
                  property: 'y',
                  value: element.y,
                  path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                  change: `Position Changed`,
                  name: 'Position - Y',
                }),
              );
            });
            
            this.angularZone.runOutsideAngular(() => {
              this.propertiesService.SaveProperties(properties).subscribe();
            });
            this.elementsPushed = [];
          },
          1000,
          this,
          item,
        );
      }
    },
    emptyCellClickCallback: (event: MouseEvent, item: GridsterItem) => {
      this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
      this.workAreaService.mobileEmulatorSize = 'normal';
      this.workAreaService.RunMobileTest(false);
      this.workAreaService.EndSettingTabOrder();
      
      // this.workAreaService.draggableWindow.forEach((dw) => {
      //   dw.Hide();
      // });
      // this.workAreaService.draggableWindow = [];
      // this.workAreaService.ShowElementFocusedMenu(this.context, event);
      // this.workAreaService.elementClicked = this.busService.Get(this.context.Id.toString());
      // console.log('empty cell callback');
    },
    emptyCellDropCallback: (event: any, position: GridsterItem) => {
      console.log('drop on stepper');
      this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
      // console.log('position', position);
      
      if (this.workAreaService.actualEditorViews.map(v => v.id).includes(this.context.Properties.view)) {
        this.OnDrop(this.dragService.dragData, event, position);
      } else {
        this.snackerService.ShowMessageOnBottom(
          `Change to [${ this.cobbleService.Cobble.properties.views.find(v => v.id === this.context.Properties.view).name }] View to drop elements`,
          'layers',
        );
      }
    },
    enableEmptyCellDrag: true,
    emptyCellDragMaxCols: 50,
    emptyCellDragMaxRows: 50,
    ignoreMarginInRow: false,
    draggable: {
      enabled: true,
      stop: (element: any, itemComponent: any, event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
        const molecule = this.busService.Get(element.id);
        
        setTimeout(() => {
          if (
            molecule.ParentId === this.context.Id &&
            (molecule.ResponsiveProperties().x !== this.dragStart[element.id].x || molecule.ResponsiveProperties().y !== this.dragStart[element.id].y)
          ) {
            this.angularZone.runOutsideAngular(() => {
              this.propertiesService
              .SaveProperties([
                new PropertyVersioningDto({
                  elementId: element.id,
                  property: 'x',
                  value: element.x,
                  path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                  change: `Position Changed`,
                  name: 'Position - X',
                }),
                new PropertyVersioningDto({
                  elementId: element.id,
                  property: 'y',
                  value: element.y,
                  path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                  change: `Position Changed`,
                  name: 'Position - Y',
                }),
              ])
              .subscribe();
              
              const position = {
                x: molecule.ResponsiveProperties().x,
                y: molecule.ResponsiveProperties().y,
                cols: molecule.ResponsiveProperties().cols,
                rows: molecule.ResponsiveProperties().rows,
              };
              
              this.workAreaService.elementsSelectedOriginalPosition[molecule.Id] = position;
            });
            // this.workAreaService.HideElementFocusedMenu();
            this.workAreaService.showElementFocusedMenu = false;
          } else {
            this.ClickOnElement(molecule, event);
          }
        }, 50);
        
        this.communicationService.Event.Editor.$RefreshRepresentativePropertiesPanel.emit(true);
      },
      start: (element: any, itemComponent: any, event: MouseEvent) => {
        console.log('init drag', event);
        this.workAreaService.elementClicked = this.busService.Get(element.id);
        this.dragService.dragBetweenWG.dragging = this.workAreaService.elementClicked.EnableDragBetweenWG;
        if (!(event.ctrlKey || event.metaKey)) {
          this.workAreaService.HideElementFocusedMenu();
          this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
        }
        this.dragService.dragBetweenWG.repMoleculeIdDragged = this.workAreaService.elementClicked.Id;
        this.dragService.dragBetweenWG.sourceWgId = this.workAreaService.elementClicked.ParentId;
        this.dragStart[element.id] = {
          x: element.x,
          y: element.y,
        };
        setTimeout(() => {
          this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
        }, 30);
      },
    },
    resizable: {
      enabled: true,
      stop: (element: any, itemComponent: any, event: MouseEvent) => {
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
        console.log('element resized', element);
        const molecule = this.busService.Get(element.id);
        if (molecule.Type === RepresentativeMoleculesType.Table) {
          this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(molecule);
        }
        
        if (molecule.Type === RepresentativeMoleculesType.WorkGroup || molecule.Type === RepresentativeMoleculesType.Stepper) {
          setTimeout(() => {
            this.workAreaService.ShowElementFocusedMenu(molecule);
            
            if (
              molecule.ResponsiveProperties().cols !== this.sizeStart[element.id].cols ||
              molecule.ResponsiveProperties().rows !== this.sizeStart[element.id].rows
            ) {
              const propertiesToSave: PropertyVersioningDto[] = [];
              
              // address columns
              if (molecule.ResponsiveProperties().cols !== this.sizeStart[element.id].cols) {
                let newWgCols =
                  molecule.ResponsiveProperties().cols > this.sizeStart[element.id].cols
                    ? molecule.GridsterConfig.minCols + (molecule.ResponsiveProperties().cols - this.sizeStart[element.id].cols)
                    : molecule.GridsterConfig.minCols - (this.sizeStart[element.id].cols - molecule.ResponsiveProperties().cols);
                
                // console.log('newWgCols', newWgCols);
                // console.log('element cols', element.cols);
                newWgCols = element.cols;
                
                if (!this.workAreaService.editorPreferences.resizeAll) {
                  const elementsToSave = [];
                  const colsContracted = this.sizeStart[element.id].cols - newWgCols;
                  // console.log('cols contracted', colsContracted);
                  
                  if (colsContracted > 0) {
                    const startElementsOnEdge = [];
                    for (let i = 1; i <= colsContracted; i++) {
                      const elementsResized = this.busService
                      .DirectChildrenElements(element.id)
                      .filter(e => e.ResponsiveProperties().x + e.ResponsiveProperties().cols > newWgCols)
                      .sort((a, b) =>
                        a.ResponsiveProperties().x + a.ResponsiveProperties().cols > b.ResponsiveProperties().x + b.ResponsiveProperties().cols
                          ? -1
                          : 1,
                      );
                      
                      // console.log('elements affected', elementsResized);
                      
                      const startElements = [];
                      elementsResized.forEach(e => {
                        const elementsToRight = [];
                        
                        elementsResized.forEach(eCompare => {
                          const yFinal = eCompare.ResponsiveProperties().y + eCompare.ResponsiveProperties().rows;
                          const yStart = eCompare.ResponsiveProperties().y;
                          
                          if (
                            eCompare.ResponsiveProperties().x + eCompare.ResponsiveProperties().cols >
                            e.ResponsiveProperties().x + e.ResponsiveProperties().cols &&
                            ((yFinal >= e.ResponsiveProperties().y && yFinal <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
                              (yStart >= e.ResponsiveProperties().y && yStart <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
                              (yStart <= e.ResponsiveProperties().y && yFinal >= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
                              (yStart >= e.ResponsiveProperties().y && yFinal <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows))
                          ) {
                            elementsToRight.push(eCompare);
                          }
                        });
                        
                        if (elementsToRight.length === 0) {
                          startElements.push(e);
                        }
                      });
                      
                      // console.log('start elements', startElements);
                      
                      startElements.forEach(startElement => {
                        startElementsOnEdge.push(startElement);
                        let elementsChained = [
                          ...new Set([startElement].concat(this.workAreaService.GetChainedElementsWest(startElement, 1))),
                        ] as IRepresentativeMolecule[];
                        
                        // console.log('elements on chain', elementsChained);
                        
                        if (elementsChained.filter(e => e.ResponsiveProperties().x < 1).length > 0) {
                          // console.log('shrink chain');
                          
                          elementsChained = elementsChained.sort((a, b) => (a.ResponsiveProperties().x > b.ResponsiveProperties().x ? 1 : -1));
                          
                          elementsChained.forEach(elementToShrink => {
                            // console.log('=====================================================');
                            
                            const elementToShrinkPositioning = elementToShrink.ResponsiveProperties();
                            
                            const colsDifference =
                              elementToShrinkPositioning.cols -
                              (elementToShrinkPositioning.cols * (this.sizeStart[element.id].cols - i)) / (this.sizeStart[element.id].cols - i + 1);
                            
                            const leftElements = this.GetLeftElements(elementToShrink).sort((a, b) =>
                              a.ResponsiveProperties().x + a.ResponsiveProperties().cols > b.ResponsiveProperties().x + b.ResponsiveProperties().cols
                                ? 1
                                : -1,
                            );
                            
                            const isAnyOfChainedElementsForElementOnEdge =
                              this.workAreaService.GetChainedElementsWest(elementToShrink, 2).filter(e => e.ResponsiveProperties().x < 1).length > 0;
                            
                            if (isAnyOfChainedElementsForElementOnEdge || elementToShrinkPositioning.x <= 1) {
                              // =========setting cols for shrinked elments
                              
                              // console.log('new el cols', elementToShrinkPositioning.x + elementToShrinkPositioning.cols - colsDifference, this.sizeStart.cols - i);
                              
                              elementToShrinkPositioning.cols = Math.floor(elementToShrinkPositioning.cols - colsDifference);
                              // elementToShrinkPositioning.cols =
                              //   Math.ceil(
                              //     elementToShrinkPositioning.x +
                              //       elementToShrinkPositioning.cols -
                              //       colsDifference
                              //   ) >
                              //   this.sizeStart.cols - i
                              //     ? this.sizeStart.cols -
                              //       i -
                              //       elementToShrinkPositioning.x +
                              //       1
                              //     : Math.floor(
                              //         elementToShrinkPositioning.cols -
                              //           colsDifference
                              //       );
                              // ==========================================
                              
                              if (leftElements.length > 0) {
                                const overlapingme =
                                  leftElements[leftElements.length - 1].ResponsiveProperties().x +
                                  leftElements[leftElements.length - 1].ResponsiveProperties().cols >
                                  elementToShrinkPositioning.x + 1;
                                if (overlapingme) {
                                  const newX =
                                    (elementToShrinkPositioning.x * (this.sizeStart[element.id].cols - i)) /
                                    (this.sizeStart[element.id].cols - i + 1);
                                  // console.log('diff', newX);
                                  elementToShrinkPositioning.x = Math.round(newX);
                                } else {
                                  elementToShrinkPositioning.x = Math.floor(
                                    leftElements[leftElements.length - 1].ResponsiveProperties().x +
                                    leftElements[leftElements.length - 1].ResponsiveProperties().cols,
                                  );
                                }
                              } else {
                                elementToShrinkPositioning.x = 0;
                              }
                              
                              // if (startElement.Id === elementToShrink.Id) {
                              //   elementToShrinkPositioning.cols =
                              //     newWgCols - elementToShrinkPositioning.x;
                              // }
                            } else {
                              elementToShrinkPositioning.x = elementToShrinkPositioning.x - 1;
                            }
                            elementToShrinkPositioning.x = elementToShrinkPositioning.x < 0 ? 0 : elementToShrinkPositioning.x;
                            
                            if (elementsToSave.filter(ets => ets.Id === elementToShrink.Id).length === 0) {
                              elementsToSave.push(elementToShrink);
                            }
                          });
                        } else {
                          elementsChained.forEach(e => {
                            if (elementsToSave.filter(ets => ets.Id === e.Id).length === 0) {
                              elementsToSave.push(e);
                            }
                            
                            e.ResponsiveProperties().x = e.ResponsiveProperties().x - 1;
                          });
                          
                          // console.log('push chain');
                        }
                      });
                    }
                    
                    startElementsOnEdge.forEach((see: IRepresentativeMolecule) => {
                      if (see.ResponsiveProperties().x + see.ResponsiveProperties().cols + 1 >= newWgCols) {
                        see.ResponsiveProperties().cols = newWgCols - see.ResponsiveProperties().x;
                      }
                    });
                    
                    elementsToSave.forEach((e: IRepresentativeMolecule) => {
                      propertiesToSave.push(
                        new PropertyVersioningDto({
                          elementId: e.Id.toString(),
                          property: 'cols',
                          value: e.ResponsiveProperties().cols,
                          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                          change: `Size Changed`,
                          name: 'Width',
                        }),
                      );
                      propertiesToSave.push(
                        new PropertyVersioningDto({
                          elementId: e.Id.toString(),
                          property: 'x',
                          value: e.ResponsiveProperties().x,
                          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                          change: `Position Changed`,
                          name: 'Position - X',
                        }),
                      );
                    });
                  }
                } else {
                  this.ShrinkElementsHorizontally(this.busService.DirectChildrenElements(element.id), molecule, element.cols);
                }
                
                if (molecule.ResponsiveProperties().x !== this.sizeStart[element.id].x) {
                  propertiesToSave.push(
                    new PropertyVersioningDto({
                      elementId: element.id,
                      property: 'x',
                      value: molecule.ResponsiveProperties().x,
                      path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                      change: `Size Changed`,
                      name: 'Position - X',
                    }),
                  );
                }
                
                molecule.ResponsiveProperties().colsQty = newWgCols;
                molecule.GridsterConfig.minCols = molecule.GridsterConfig.maxCols = newWgCols;
                molecule.GridsterConfig.maxItemCols = molecule.GridsterConfig.maxCols = newWgCols;
                
                propertiesToSave.push(
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'cols',
                    value: newWgCols,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Size Changed`,
                    name: 'Width',
                  }),
                );
                
                propertiesToSave.push(
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'colsQty',
                    value: molecule.ResponsiveProperties().colsQty,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Size Changed`,
                    name: 'WG Width',
                  }),
                );
              }
              
              // address rows
              if (molecule.ResponsiveProperties().rows !== this.sizeStart[element.id].rows) {
                let newWgRows =
                  molecule.ResponsiveProperties().rows > this.sizeStart[element.id].rows
                    ? molecule.GridsterConfig.minRows + (molecule.ResponsiveProperties().rows - this.sizeStart[element.id].rows)
                    : molecule.GridsterConfig.minRows - (this.sizeStart[element.id].rows - molecule.ResponsiveProperties().rows);
                
                newWgRows = element.rows;
                
                if (!this.workAreaService.editorPreferences.resizeAll) {
                  const elementsToSave = [];
                  const rowsContracted = this.sizeStart[element.id].rows - newWgRows;
                  // console.log('cols contracted', rowsContracted);
                  
                  if (rowsContracted > 0) {
                    for (let i = 1; i <= rowsContracted; i++) {
                      const elementsResized = this.busService
                      .DirectChildrenElements(element.id)
                      .filter(e => e.ResponsiveProperties().y + e.ResponsiveProperties().rows > newWgRows)
                      .sort((a, b) =>
                        a.ResponsiveProperties().y + a.ResponsiveProperties().rows > b.ResponsiveProperties().y + b.ResponsiveProperties().rows
                          ? -1
                          : 1,
                      );
                      
                      // console.log('elements affected', elementsResized);
                      
                      const startElements = [];
                      elementsResized.forEach(e => {
                        const elementsToRight = [];
                        
                        elementsResized.forEach(eCompare => {
                          const xFinal = eCompare.ResponsiveProperties().x + eCompare.ResponsiveProperties().cols;
                          const xStart = eCompare.ResponsiveProperties().x;
                          
                          if (
                            eCompare.ResponsiveProperties().y + eCompare.ResponsiveProperties().rows >
                            e.ResponsiveProperties().y + e.ResponsiveProperties().rows &&
                            ((xFinal >= e.ResponsiveProperties().x && xFinal <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
                              (xStart >= e.ResponsiveProperties().x && xStart <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
                              (xStart <= e.ResponsiveProperties().x && xFinal >= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
                              (xStart >= e.ResponsiveProperties().x && xFinal <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols))
                          ) {
                            elementsToRight.push(eCompare);
                          }
                        });
                        
                        if (elementsToRight.length === 0) {
                          startElements.push(e);
                        }
                      });
                      
                      // console.log('start elements', startElements);
                      
                      startElements.forEach(startElement => {
                        let elementsChained = [
                          ...new Set([startElement].concat(this.workAreaService.GetChainedElementsNorth(startElement, 1))),
                        ] as IRepresentativeMolecule[];
                        
                        // console.log('elements on chain', elementsChained);
                        
                        if (elementsChained.filter(e => e.ResponsiveProperties().y < 1).length > 0) {
                          // console.log('shrink chain');
                          
                          elementsChained = elementsChained.sort((a, b) => (a.ResponsiveProperties().y > b.ResponsiveProperties().y ? 1 : -1));
                          
                          elementsChained.forEach(elementToShrink => {
                            // console.log('=====================================================');
                            
                            const elementToShrinkPositioning = elementToShrink.ResponsiveProperties();
                            
                            const rowsDifference =
                              elementToShrinkPositioning.rows -
                              (elementToShrinkPositioning.rows * (this.sizeStart[element.id].rows - i)) / (this.sizeStart[element.id].rows - i + 1);
                            
                            const northElements = this.GetNorthElements(elementToShrink).sort((a, b) =>
                              a.ResponsiveProperties().y + a.ResponsiveProperties().rows > b.ResponsiveProperties().y + b.ResponsiveProperties().rows
                                ? 1
                                : -1,
                            );
                            
                            const isAnyOfChainedElementsForElementOnEdge =
                              this.workAreaService.GetChainedElementsNorth(elementToShrink, 2).filter(e => e.ResponsiveProperties().y < 1).length > 0;
                            
                            if (isAnyOfChainedElementsForElementOnEdge || elementToShrinkPositioning.y <= 1) {
                              // =========setting rows for shrinked elments
                              elementToShrinkPositioning.rows = Math.floor(elementToShrinkPositioning.rows - rowsDifference);
                              // ========================================== here
                              
                              if (northElements.length > 0) {
                                const overlapingme =
                                  northElements[northElements.length - 1].ResponsiveProperties().y +
                                  northElements[northElements.length - 1].ResponsiveProperties().rows >
                                  elementToShrinkPositioning.y + 1;
                                if (overlapingme) {
                                  const newY =
                                    (elementToShrinkPositioning.y * (this.sizeStart[element.id].rows - i)) /
                                    (this.sizeStart[element.id].rows - i + 1);
                                  // console.log('diff', newY);
                                  elementToShrinkPositioning.y = Math.round(newY);
                                } else {
                                  elementToShrinkPositioning.y = Math.floor(
                                    northElements[northElements.length - 1].ResponsiveProperties().y +
                                    northElements[northElements.length - 1].ResponsiveProperties().rows,
                                  );
                                }
                              } else {
                                elementToShrinkPositioning.y = 0;
                              }
                            } else {
                              elementToShrinkPositioning.y = elementToShrinkPositioning.y - 1;
                            }
                            elementToShrinkPositioning.y = elementToShrinkPositioning.y < 0 ? 0 : elementToShrinkPositioning.y;
                            
                            if (elementsToSave.filter(ets => ets.Id === elementToShrink.Id).length === 0) {
                              elementsToSave.push(elementToShrink);
                            }
                          });
                        } else {
                          elementsChained.forEach(e => {
                            if (elementsToSave.filter(ets => ets.Id === e.Id).length === 0) {
                              elementsToSave.push(e);
                            }
                            
                            e.ResponsiveProperties().y = e.ResponsiveProperties().y - 1;
                          });
                          
                          // console.log('push chain');
                        }
                      });
                    }
                    
                    elementsToSave.forEach((e: IRepresentativeMolecule) => {
                      propertiesToSave.push(
                        new PropertyVersioningDto({
                          elementId: e.Id.toString(),
                          property: 'rows',
                          value: e.ResponsiveProperties().rows,
                          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                          change: `Size Changed`,
                          name: 'Height',
                        }),
                      );
                      propertiesToSave.push(
                        new PropertyVersioningDto({
                          elementId: e.Id.toString(),
                          property: 'y',
                          value: e.ResponsiveProperties().y,
                          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                          change: `Position Changed`,
                          name: 'Position - Y',
                        }),
                      );
                    });
                  }
                } else {
                  this.ShrinkElementsVertically(this.busService.DirectChildrenElements(element.id), molecule, element.rows);
                }
                
                if (molecule.ResponsiveProperties().y !== this.sizeStart[element.id].y) {
                  propertiesToSave.push(
                    new PropertyVersioningDto({
                      elementId: element.id,
                      property: 'y',
                      value: molecule.ResponsiveProperties().y,
                      path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                      change: `Size Changed`,
                      name: 'Position - Y',
                    }),
                  );
                }
                
                molecule.ResponsiveProperties().rowsQty = newWgRows;
                molecule.GridsterConfig.minRows = newWgRows;
                molecule.GridsterConfig.maxItemRows = molecule.GridsterConfig.maxRows = newWgRows;
                
                propertiesToSave.push(
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'rows',
                    value: newWgRows,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Size Changed`,
                    name: 'Height',
                  }),
                );
                
                propertiesToSave.push(
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'rowsQty',
                    value: molecule.ResponsiveProperties().rowsQty,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Size Changed`,
                    name: 'WG Height',
                  }),
                );
              }
              
              molecule.GridsterConfig.api.optionsChanged();
              
              this.propertiesService.SaveProperties(propertiesToSave).subscribe();
              
              this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(molecule.Id);
            }
          }, 50);
        }
        
        setTimeout(() => {
          const propertiesToSave = [];
          let sizeChanged = true;
          let positionChanged = true;
          if (
            molecule.ResponsiveProperties().cols !== this.sizeStart[element.id].cols ||
            molecule.ResponsiveProperties().rows !== this.sizeStart[element.id].rows
          ) {
            // console.log(this.sizeStart);
            
            let newCols = element.cols;
            let newRows = element.rows;
            
            if (!this.context.RepresentativeMoleculeFitOnX(molecule.ResponsiveProperties().x, molecule.ResponsiveProperties().cols)) {
              newCols = this.context.ResponsiveProperties().cols - molecule.ResponsiveProperties().x;
              molecule.ResponsiveProperties().cols = element.cols = newCols;
            }
            
            if (!this.context.RepresentativeMoleculeFitOnY(molecule.ResponsiveProperties().y, molecule.ResponsiveProperties().rows)) {
              newRows = this.context.ResponsiveProperties().rows - molecule.ResponsiveProperties().y;
              molecule.ResponsiveProperties().rows = element.rows = newRows;
            }
            
            if (molecule.GridsterConfig) {
              molecule.GridsterConfig.api.optionsChanged();
            }
            this.context.GridsterConfig.api.optionsChanged();
            molecule.$Resized.emit();
            propertiesToSave.push(
              new PropertyVersioningDto({
                elementId: element.id,
                property: 'cols',
                value: newCols,
                path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                change: `Size Changed`,
                name: 'Size - Cols',
              }),
              new PropertyVersioningDto({
                elementId: element.id,
                property: 'rows',
                value: newRows,
                path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                change: `Size Changed`,
                name: 'Size - Rows',
              }),
            );
          } else {
            sizeChanged = false;
          }
          
          if (
            molecule.ResponsiveProperties().x !== this.sizeStart[element.id].x ||
            molecule.ResponsiveProperties().y !== this.sizeStart[element.id].y
          ) {
            if (molecule.ResponsiveProperties().x !== this.sizeStart[element.id].x) {
              propertiesToSave.push(
                new PropertyVersioningDto({
                  elementId: element.id,
                  property: 'x',
                  value: molecule.ResponsiveProperties().x,
                  path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                  change: `Size Changed`,
                  name: 'Position - X',
                }),
              );
            }
            
            if (molecule.ResponsiveProperties().y !== this.sizeStart[element.id].y) {
              propertiesToSave.push(
                new PropertyVersioningDto({
                  elementId: element.id,
                  property: 'y',
                  value: molecule.ResponsiveProperties().y,
                  path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                  change: `Size Changed`,
                  name: 'Position - Y',
                }),
              );
            }
          } else {
            positionChanged = false;
          }
          
          if (!positionChanged && !sizeChanged) {
            this.ClickOnElement(molecule, event);
          }
          
          if (propertiesToSave.length > 0) {
            this.angularZone.runOutsideAngular(() => {
              this.propertiesService.SaveProperties(propertiesToSave).subscribe();
              const positionxy = {
                x: molecule.ResponsiveProperties().x,
                y: molecule.ResponsiveProperties().y,
                cols: molecule.ResponsiveProperties().cols,
                rows: molecule.ResponsiveProperties().rows,
              };
              
              this.workAreaService.elementsSelectedOriginalPosition[molecule.Id] = positionxy;
            });
          }
        }, 50);
        
        this.communicationService.Event.Editor.$RefreshRepresentativePropertiesPanel.emit(true);
      },
      start: (element: any) => {
        // console.log('init drag', element);
        this.workAreaService.elementClicked = this.busService.Get(element.id);
        this.sizeStart[element.id] = {
          cols: element.cols,
          rows: element.rows,
          x: element.x,
          y: element.y,
        };
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
      },
    },
    itemInitCallback: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
      // setTimeout(() => {
      //   this.UpdateChildContextMenuPosition(true);
      // }, 100);
    },
    swap: false,
    pushItems: this.workAreaService.editorPreferences.push,
    disablePushOnDrag: false,
    disablePushOnResize: false,
    pushDirections: { north: true, east: true, south: true, west: true },
    pushResizeItems: false,
    displayGrid: DisplayGrid.Always,
    disableWindowResize: false,
    disableWarnings: false,
    scrollToNewItems: false,
  };
  // endregion
  
  public moleculeComponents = {
    Button: ButtonMoleculeComponent,
    Badge: BadgeMoleculeComponent,
    Breadcrumb: BreadcrumbMoleculeComponent,
    WorkGroup: WorkgroupMoleculeComponent,
    Textbox: TextboxMoleculeComponent,
    Textarea: TextareaMoleculeComponent,
    Label: LabelMoleculeComponent,
    Datepicker: DatepickerMoleculeComponent,
    Dropdown: DropdownMoleculeComponent,
    H1: H1MoleculeComponent,
    H2: H2MoleculeComponent,
    H3: H3MoleculeComponent,
    H4: H4MoleculeComponent,
    H5: H5MoleculeComponent,
    Chart: ChartMoleculeComponent,
    Image: ImageMoleculeComponent,
    Iframe: IframeMoleculeComponent,
    Checkbox: CheckboxMoleculeComponent,
    Table: TableMoleculeComponent,
    Icon: IconMoleculeComponent,
    Radio: RadioMoleculeComponent,
    QrCode: QrCodeMoleculeComponent,
    Stepper: StepperMoleculeComponent,
  };
  
  constructor(
    public busService: BusService,
    public builderService: BuilderService,
    public dragService: DragService,
    public workAreaService: WorkAreaService,
    public processorService: ProcessorService,
    private ref: ChangeDetectorRef,
    bottomSheet: MatBottomSheet,
    public propertiesService: ApiPropertiesService,
    public toolsService: ToolsService,
    public draggableWindowService: DraggableWindowService,
    private fileService: ApiFileService,
    public el: ElementRef<HTMLElement>,
    public communicationService: CommunicationService,
    changeDetectorRef: ChangeDetectorRef,
    private angularZone: NgZone,
    private _formBuilder: FormBuilder,
  ) {
    super(bottomSheet, el, changeDetectorRef);
    
    // this.disableHover = true;
    this.debug = this.localStorageService.IsDebug();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }
  
  ngOnInit() {
    super.ngOnInit();
    
    if (!this.context.RunningMode) {
      this.DetachChangeDetection();
    }
    
    this.subscription = this.communicationService.Event.System.Update.$RefreshWorkgroups.subscribe(wgId => {
      console.log('=event=');
      if (wgId) {
        if (wgId === this.context.Id) {
          this.UpdateWGBackground();
          this.busService.ChildrenElements(wgId).forEach(c => {
            if (c) {
              c.$Resized.emit();
            }
          });
          this.ref.markForCheck();
          this.ref.detectChanges();
        } else if (this.busService.IsMyChild(this.context.Id, wgId)) {
          // console.log('update wg');
          this.ref.markForCheck();
        }
        this.SetVerticalSize();
      } else {
        this.busService.ChildrenElements(this.context.Id).forEach(c => {
          c.$Resized.emit();
        });
        
        this.UpdateWGBackground();
        this.ref.markForCheck();
      }
      setTimeout(() => {
        if (this.context.GridsterConfig.api) {
          this.context.GridsterConfig.api.optionsChanged();
        }
      }, 700);
    });
    
    this.subscription.add(
      this.communicationService.Event.Runtime.System.$SelectStepperStep.subscribe(data => {
        console.log('=event=');
        if (data.repMoleculeId === this.context.Id && data.stepIndex >= 0) {
          this.stepper.selectedIndex = data.stepIndex;
          
          if (data.stepIndex === this.DESELECT_INDEX) {
            this.context.LastSelected = null;
          }
        }
      }),
    );
    
    if (!this.cobbleService.Cobble.running || this.cobbleService.Cobble.preview) {
      this.subscription.add(
        this.communicationService.Event.System.Update.$ChangesOnMolecules.subscribe((molecule: IRepresentativeMolecule) => {
          // console.log('$ChangesOnMolecules');
          this.ref.markForCheck();
          const lockedView = this.cobbleService.Cobble.properties.views.find(v => v.locked);
          
          if (
            !this.workAreaService.actualEditorViews.map(v => v.id).includes(this.context.Properties.view) &&
            !(lockedView && lockedView.id === this.context.Properties.view)
          ) {
            // console.log('molecules change vent return');
            // console.log('return', molecule);
            return;
          }
          
          if (molecule) {
            if (molecule.Id === this.context.Id || this.busService.IsMyChild(this.context.Id, molecule.Id)) {
              // console.log('molecules change event', molecule);
              this.Throttle(
                (func, delay, context) => {
                  this.refreshChildren();
                  this.RefreshStepperUI();
                },
                50,
                this,
                null,
              );
            }
          } else {
            // console.log(2);
            
            this.Throttle(
              (func, delay, context) => {
                this.refreshChildren();
                setTimeout(() => {
                  if (this.context.GridsterConfig.api) {
                    this.context.GridsterConfig.api.optionsChanged();
                  }
                  this.communicationService.Event.System.App.$RefreshUI.emit(true);
                  this.ref.markForCheck();
                }, 300);
              },
              200,
              this,
              null,
            );
          }
        }),
      );
      
      this.subscription.add(
        this.communicationService.Event.System.Update.$ActivateWorkgroupDetection.subscribe(wgId => {
          console.log('=event=');
          if (wgId) {
            if (wgId === this.context.Id || this.busService.IsMyChild(this.context.Id, wgId)) {
              // console.log('attaching detection');
              
              this.ref.reattach();
              this.ref.detectChanges();
            }
          }
        }),
      );
      
      this.subscription.add(
        this.communicationService.Event.System.Update.$DeactivateWorkgroupDetection.subscribe(wgId => {
          console.log('=event='); // console.log('dettaching wg');
          setTimeout(() => {
            this.ref.detach();
          }, 300);
        }),
      );
    } else {
    }
    
    this.UpdateWGBackground();
    
    // region GRIDSTER OPTIONS
    this.stepperHeadingGridsterConfig = this.cobbleService.Cobble.running
      ? {
        collision: false,
        gridType: GridType.Fit,
        compactType: CompactType.None,
        margin: 0,
        outerMargin: true,
        outerMarginTop: null,
        outerMarginRight: null,
        outerMarginBottom: null,
        outerMarginLeft: null,
        useTransformPositioning: true,
        mobileBreakpoint: 0,
        minCols: 15,
        maxCols: 15,
        minRows: 15,
        maxRows: 15,
        maxItemCols: 15,
        minItemCols: 1,
        maxItemRows: 500,
        minItemRows: 1,
        maxItemArea: 100000,
        minItemArea: 1,
        defaultItemCols: 3,
        defaultItemRows: 3,
        fixedColWidth: 20,
        fixedRowHeight: 20,
        keepFixedHeightInMobile: false,
        keepFixedWidthInMobile: false,
        scrollSensitivity: 0,
        scrollSpeed: 0,
        enableEmptyCellClick: false,
        enableEmptyCellContextMenu: false,
        enableEmptyCellDrop: false,
        enableEmptyCellDrag: false,
        emptyCellDragMaxCols: 50,
        emptyCellDragMaxRows: 50,
        ignoreMarginInRow: false,
        draggable: {
          enabled: false,
        },
        resizable: {
          enabled: false,
        },
        swap: false,
        pushItems: true,
        disablePushOnDrag: false,
        disablePushOnResize: false,
        pushDirections: {
          north: true,
          east: true,
          south: true,
          west: true,
        },
        pushResizeItems: false,
        displayGrid: DisplayGrid.Always,
        disableWindowResize: false,
        disableWarnings: false,
        scrollToNewItems: false,
      }
      : {
        gridType: GridType.Fit,
        compactType: CompactType.None,
        margin: 0,
        outerMargin: true,
        outerMarginTop: null,
        outerMarginRight: null,
        outerMarginBottom: null,
        outerMarginLeft: null,
        useTransformPositioning: true,
        mobileBreakpoint: 0,
        minCols: 15,
        maxCols: 15,
        minRows: 15,
        maxRows: 15,
        maxItemCols: 15,
        minItemCols: 1,
        maxItemRows: 500,
        minItemRows: 1,
        maxItemArea: 100000,
        minItemArea: 1,
        defaultItemCols: 3,
        defaultItemRows: 3,
        fixedColWidth: 20,
        fixedRowHeight: 20,
        keepFixedHeightInMobile: false,
        keepFixedWidthInMobile: false,
        scrollSensitivity: 0,
        scrollSpeed: 0,
        enableEmptyCellClick: true,
        enableEmptyCellContextMenu: false,
        enableEmptyCellDrop: true,
        emptyCellDropCallback: (event: any, position: GridsterItem) => {
          position.x = 0;
          position.y = 0;
          console.log('event.srcElement ', (event.srcElement as any).parentElement.dataset);
          const subparentId = (event.srcElement as any).parentElement.dataset.subparentId;
          const subparentContext = (event.srcElement as any).parentElement.dataset.subparentContext;
          // console.log('subparentid', subparentId);
          
          this.templateService.GetActionMoleculeProperties(['GetCellDataElementMolecule']).subscribe(moleculeProperties => {
            const buses = [
              {
                id: this.toolsService.GenerateGuid(),
                Name: `Head Stepper Data`,
                Receptor: Receptor.ValueOutput,
                Particles: [
                  this.particlesFactoryService.GenerateEvent(LeapXLEventType.Click),
                  this.particlesFactoryService.GenerateActionMolecule(moleculeProperties[0]),
                ],
              },
            ];
            
            this.OnDrop(
              this.dragService.dragData,
              event,
              position,
              [
                {
                  name: 'location',
                  value: 'heading',
                  path: `properties`,
                },
                {
                  name: 'buses',
                  value: buses,
                  path: ``,
                },
                {
                  name: 'rows',
                  value: 14,
                  path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                },
                {
                  name: 'cols',
                  value: 14,
                  path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                },
                {
                  name: 'subParentContext',
                  value: subparentContext,
                  path: ``,
                },
              ],
              subparentId,
            );
          });
        },
        enableEmptyCellDrag: true,
        emptyCellDragMaxCols: 50,
        emptyCellDragMaxRows: 50,
        ignoreMarginInRow: false,
        draggable: {
          enabled: !this.cobbleService.Cobble.running,
          stop: (element: any) => {
            // console.log(element);
            
            const molecule = this.busService.Get(element.id);
            // console.log(molecule);
            
            setTimeout(() => {
              if (molecule.ResponsiveProperties().x !== this.dragStart.x || molecule.ResponsiveProperties().y !== this.dragStart.y) {
                this.propertiesService
                .SaveProperties([
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'x',
                    value: element.x,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Position Changed`,
                    name: 'Position - X',
                  }),
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'y',
                    value: element.y,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Position Changed`,
                    name: 'Position - Y',
                  }),
                ])
                .subscribe();
              }
            }, 300);
          },
          start: (element: any) => {
            // console.log('init drag');
            this.dragStart = {
              x: element.x,
              y: element.y,
            };
          },
        },
        resizable: {
          enabled: !this.cobbleService.Cobble.running,
          stop: (element: any) => {
            const molecule = this.busService.Get(element.id);
            
            setTimeout(() => {
              if (molecule.ResponsiveProperties().cols !== this.sizeStart.cols || molecule.ResponsiveProperties().rows !== this.sizeStart.rows) {
                if (this.busService.Get(element.id).GridsterConfig) {
                  this.busService.Get(element.id).GridsterConfig.api.optionsChanged();
                }
                
                this.propertiesService
                .SaveProperties([
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'cols',
                    value: element.cols,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Size Changed`,
                    name: 'Size - Cols',
                  }),
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'rows',
                    value: element.rows,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Size Changed`,
                    name: 'Size - Rows',
                  }),
                ])
                .subscribe();
              }
            }, 200);
          },
          start: (element: any) => {
            // console.log('init drag');
            this.sizeStart = {
              cols: element.cols,
              rows: element.rows,
            };
          },
        },
        swap: false,
        pushItems: this.workAreaService.editorPreferences.push,
        disablePushOnDrag: false,
        disablePushOnResize: false,
        pushDirections: { north: true, east: true, south: true, west: true },
        pushResizeItems: false,
        displayGrid: DisplayGrid.None,
        disableWindowResize: false,
        disableWarnings: false,
        scrollToNewItems: false,
      };
    // endregion
    
    const gridsterConfig = this.cobbleService.Cobble.running ? this.runningGridsterConfig : this.editorGridsterConfig;
    
    // initializing with wg values
    gridsterConfig.minCols = this.context.ResponsiveProperties().colsQty;
    gridsterConfig.maxCols = this.context.ResponsiveProperties().colsQty;
    gridsterConfig.minRows = this.context.ResponsiveProperties().rowsQty;
    gridsterConfig.maxRows = this.context.ResponsiveProperties().rowsQty;
    gridsterConfig.maxItemCols = this.context.ResponsiveProperties().colsQty;
    gridsterConfig.maxItemRows = this.context.ResponsiveProperties().rowsQty;
    //
    
    this.context.GridsterConfig = gridsterConfig;
    
    console.log('init stepper');
    
    this.showAltText = this.context.Properties.showAltText;
    setTimeout(() => {
      this.refreshChildren();
      this.SetVerticalSize();
      this.FireInitEvents();
    }, 50);
    
    if (
      (this.orderedData === null || this.orderedData === undefined || this.orderedData.length === 0) &&
      this.context.Value &&
      this.context.Value.length > 0
    ) {
      if (this.context.ProcessedValue) {
        this.orderedData = this.context.ProcessedValue;
        
        if (this.context.LastSelected) {
          this.avoidEventFiring = true;
          setTimeout(() => {
            this.stepper.selectedIndex = this.context.LastSelected;
          }, 100);
        } else {
          setTimeout(() => {
            this.stepper.selectedIndex = this.DESELECT_INDEX;
            if (this.context.Properties.selectFirstOption) {
              this.stepper.selectedIndex = 0;
              this.context.LastSelected = 0;
            }
          }, 100);
        }
      } else {
        // update data if stepper was hidden and received data, otherwise it wont show anything
        this.UpdateData();
      }
      
      console.log('orderedData', this.orderedData);
      this.ref.markForCheck();
    }
  }
  
  RepMoleculeResized() {
    console.log('resized');
    this.SetVerticalSize();
    if (!this.context.RunningMode) {
      this.toolsService.RedrawBrowser();
    }
  }
  
  SetVerticalSize() {
    let panelHeight = this.context.ResponsiveProperties().rows * 4 - 160;
    
    // adjust step height dynamically on running app
    if (this.context.RunningMode && this.context.Properties.stepper.stepperOrientation === 'vertical') {
      const children = this.GetStepperChildren();
      let maxY = 0;
      
      children.forEach(child => {
        maxY =
          maxY > child.ResponsiveProperties().y + child.ResponsiveProperties().rows
            ? maxY
            : child.ResponsiveProperties().y + child.ResponsiveProperties().rows;
      });
      
      panelHeight = maxY * 4;
      
      setTimeout(() => {
        this.context.GridsterConfig.maxRows = maxY;
        this.context.GridsterConfig.maxItemRows = maxY;
        this.context.GridsterConfig.minItemRows = maxY;
        this.context.GridsterConfig.minRows = maxY;
        
        if (this.context.GridsterConfig.api) {
          this.context.GridsterConfig.api.optionsChanged();
        }
      }, 50);
    }
    
    if (this.context.RunningMode) {
      this.toolsService.GenerateClassDynamically(
        `#gridsterItem-${ this.context.Id } .mat-vertical-content-container [aria-expanded="true"]`,
        `height: ${ panelHeight }px !important`,
      );
    } else {
      this.toolsService.GenerateClassDynamically(
        `.develop #gridsterItem-${ this.context.Id } .mat-vertical-content-container`,
        `height: ${ panelHeight }px !important`,
      );
    }
  }
  
  UpdateWGBackground() {
    this.ImgSource = 'url(' + this.context.Properties.background.backgroundImageUrl + ')';
    
    // console.log(this.ImgSource);
    
    if (this.context.Properties.background.backgroundTypeImage) {
      if (
        this.context.Properties.background.backgroundImageUrl.indexOf('http') > -1 ||
        this.context.Properties.background.backgroundImageUrl.indexOf('https') > -1
      ) {
        this.ImgSource = 'url(' + this.context.Properties.background.backgroundImageUrl + ')';
        this.ref.markForCheck();
      } else {
        try {
          this.fileService.getFileInformation(this.context.Properties.background.backgroundImageUrl).subscribe(
            result => {
              if (result && result.contentType.indexOf('image') > -1) {
                this.ImgSource = 'url(' + 'data:image/png;base64,' + result.dataFile + ')';
              }
              this.ref.markForCheck();
            },
            error => {
            },
          );
        } catch (error) {
          this.ImgSource = '/assets/images/no_image.png';
        }
      }
    }
  }
  
  // region ELEMENT REPOSITION
  GetLeftElements(e: IRepresentativeMolecule) {
    const elementsFound = [];
    this.busService.DirectChildrenElements(e.ParentId).forEach(eCompare => {
      const yFinal = eCompare.ResponsiveProperties().y + eCompare.ResponsiveProperties().rows;
      const yStart = eCompare.ResponsiveProperties().y;
      
      if (
        ((yFinal >= e.ResponsiveProperties().y && yFinal <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
          (yStart >= e.ResponsiveProperties().y && yStart <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
          (yStart <= e.ResponsiveProperties().y && yFinal >= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
          (yStart >= e.ResponsiveProperties().y && yFinal <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows)) &&
        eCompare.ResponsiveProperties().x + eCompare.ResponsiveProperties().cols <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols &&
        eCompare.Id !== e.Id
      ) {
        // console.log('Elements found', eCompare);
        elementsFound.push(eCompare);
      }
    });
    
    return elementsFound;
  }
  
  GetNorthElements(e: IRepresentativeMolecule) {
    const elementsFound = [];
    this.busService.DirectChildrenElements(e.ParentId).forEach(eCompare => {
      const xFinal = eCompare.ResponsiveProperties().x + eCompare.ResponsiveProperties().cols;
      const xStart = eCompare.ResponsiveProperties().x;
      
      if (
        ((xFinal >= e.ResponsiveProperties().x && xFinal <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
          (xStart >= e.ResponsiveProperties().x && xStart <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
          (xStart <= e.ResponsiveProperties().x && xFinal >= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
          (xStart >= e.ResponsiveProperties().x && xFinal <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols)) &&
        eCompare.ResponsiveProperties().y + eCompare.ResponsiveProperties().rows <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows &&
        eCompare.Id !== e.Id
      ) {
        // console.log('Elements found', eCompare);
        elementsFound.push(eCompare);
      }
    });
    
    return elementsFound;
  }
  
  ShrinkElementsHorizontally(elements: IRepresentativeMolecule[], parent: IRepresentativeMolecule, parentCols: number) {
    const propertiesToSave = [];
    
    elements.forEach(child => {
      let gridRatio = 1;
      
      if (this.sizeStart[parent.Id].cols !== parent.ResponsiveProperties().colsQty) {
        // console.log('wg cols b', this.sizeStart.cols);
        // console.log('wg cols qty b', parent.ResponsiveProperties().colsQty);
        
        gridRatio = this.sizeStart[parent.Id].cols / parent.ResponsiveProperties().colsQty;
      }
      
      // console.log('grid ratio', gridRatio);
      
      let ratioColsChange = ((child.ResponsiveProperties().cols * parentCols) / this.sizeStart[parent.Id].cols) * gridRatio;
      let ratioXChange = ((child.ResponsiveProperties().x * parentCols) / this.sizeStart[parent.Id].cols) * gridRatio;
      // console.log('ratio cols change', ratioColsChange);
      // console.log('ratio x change', ratioXChange);
      
      if (Math.round(ratioColsChange) + Math.round(ratioXChange) > parentCols) {
        ratioColsChange = Math.floor(ratioColsChange);
        ratioXChange = Math.floor(ratioXChange);
      } else {
        ratioColsChange = Math.round(ratioColsChange);
        ratioXChange = Math.round(ratioXChange);
      }
      
      ratioColsChange = ratioColsChange < 1 ? 1 : ratioColsChange;
      ratioXChange = ratioXChange < 0 ? 0 : ratioXChange;
      
      child.ResponsiveProperties().cols = ratioColsChange;
      child.ResponsiveProperties().x = ratioXChange;
      
      propertiesToSave.push(
        new PropertyVersioningDto({
          elementId: child.Id.toString(),
          property: 'x',
          value: child.ResponsiveProperties().x,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: `Position Changed`,
          name: 'Position - X',
        }),
      );
      propertiesToSave.push(
        new PropertyVersioningDto({
          elementId: child.Id.toString(),
          property: 'cols',
          value: child.ResponsiveProperties().cols,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: `Size Changed`,
          name: 'Width',
        }),
      );
    });
    
    this.propertiesService.SaveProperties(propertiesToSave).subscribe();
  }
  
  ShrinkElementsVertically(elements: IRepresentativeMolecule[], parent: IRepresentativeMolecule, parentRows: number) {
    const propertiesToSave = [];
    
    elements.forEach(child => {
      let gridRatio = 1;
      
      if (this.sizeStart[parent.Id].rows !== parent.ResponsiveProperties().rowsQty) {
        // console.log('wg cols b', this.sizeStart.rows);
        // console.log('wg cols qty b', parent.ResponsiveProperties().rowsQty);
        
        gridRatio = this.sizeStart[parent.Id].rows / parent.ResponsiveProperties().rowsQty;
      }
      
      // console.log('grid ratio', gridRatio);
      
      let ratioRowsChange = ((child.ResponsiveProperties().rows * parentRows) / this.sizeStart[parent.Id].rows) * gridRatio;
      let ratioYChange = ((child.ResponsiveProperties().y * parentRows) / this.sizeStart[parent.Id].rows) * gridRatio;
      
      if (Math.round(ratioRowsChange) + Math.round(ratioYChange) > parentRows) {
        ratioRowsChange = Math.floor(ratioRowsChange);
        ratioYChange = Math.floor(ratioYChange);
      } else {
        ratioRowsChange = Math.round(ratioRowsChange);
        ratioYChange = Math.round(ratioYChange);
      }
      
      ratioRowsChange = ratioRowsChange < 1 ? 1 : ratioRowsChange;
      ratioYChange = ratioYChange < 0 ? 0 : ratioYChange;
      
      child.ResponsiveProperties().rows = ratioRowsChange;
      child.ResponsiveProperties().y = ratioYChange;
      
      propertiesToSave.push(
        new PropertyVersioningDto({
          elementId: child.Id.toString(),
          property: 'y',
          value: child.ResponsiveProperties().y,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: `Position Changed`,
          name: 'Position - Y',
        }),
      );
      propertiesToSave.push(
        new PropertyVersioningDto({
          elementId: child.Id.toString(),
          property: 'rows',
          value: child.ResponsiveProperties().rows,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: `Size Changed`,
          name: 'Height',
        }),
      );
    });
    
    this.propertiesService.SaveProperties(propertiesToSave).subscribe();
  }
  
  // endregion
  
  public UpdateWGContextMenuPosition(moleculeId: number, init = false) {
    setTimeout(
      () => {
        const molecule = this.busService.Get(moleculeId.toString());
        const menuColsSize = 8.5;
        if (molecule.GridsterItem) {
          const leftRegex = /(\d+)px,\s/;
          const left = +leftRegex.exec(molecule.GridsterItem.style.transform)[1];
          const topRegex = /,\s(\d+)px,\s/g;
          const top = +topRegex.exec(molecule.GridsterItem.style.transform)[1];
          
          const k = this.context.ResponsiveProperties().colsQty / this.context.ResponsiveProperties().cols;
          
          const spaceAvailable = (this.context.ResponsiveProperties().colsQty - molecule.ResponsiveProperties().x) / k;
          
          const isMenuSpaceAvailable = spaceAvailable >= menuColsSize;
          
          molecule.ContextMenu.style.transform =
            molecule.ResponsiveProperties().y === 0 || molecule.ResponsiveProperties().y === 1
              ? molecule.GridsterItem.style.transform.replace(`, ${ top }px,`, `, ${ top + 15 }px,`)
              : molecule.GridsterItem.style.transform;
          
          molecule.ContextMenu.style.transform = isMenuSpaceAvailable
            ? molecule.ContextMenu.style.transform
            : molecule.ContextMenu.style.transform.replace(`(${ left }px,`, `(${ left - (menuColsSize - spaceAvailable) * 30 }px,`);
        }
      },
      init ? 300 : 0,
    );
  }
  
  public TrackById(index, item) {
    if (!item) {
      return null;
    }
    return item.Id;
  }
  
  workgroupDrag(event: any, condition: boolean) {
    this.busService.DirectChildrenElements(this.context.Id).forEach(child => {
      child.DragOver = false;
    });
  }
  
  ShowChangesLost() {
    this.workAreaService.ShowUnsavedDataWindow();
  }
  
  ClickOnElement(element: IRepresentativeMolecule, e: MouseEvent) {
    // console.log('click on element');
    e.preventDefault();
    e.stopPropagation();
    if (!this.cobbleService.Cobble.running) {
      if (this.workAreaService.tabManagement.settingTab) {
        this.workAreaService.SetTabOrder(element);
      } else {
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
        this.workAreaService.ShowElementFocusedMenu(element, e);
      }
    }
  }
  
  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  
  RepresentativeElementClick(event: MouseEvent, repMolecule: IRepresentativeMolecule) {
    this.communicationService.Event.System.Dev.$RepMoleculeSelected.emit(repMolecule);
  }
  
  DataDroppedOnChildren($event: any, repMolecule: IRepresentativeMolecule) {
    console.log('drop on placeholder', repMolecule);
    this.communicationService.Event.Editor.$DropOnRepresentativeMolecule.emit({ event: $event, repMoleculeId: repMolecule.Id });
  }
  
  OpenProcessPanelForRepresentativeMolecule(repMolecule, event) {
    if (this.cobbleService.Cobble.running) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
    
    this.draggableWindowService.OpenDraggableWindow(
      `Process Panel `,
      DraggableWindowType.ProcessPanel,
      event,
    );
  }
  
  ClearDropZone(repMolecule: IRepresentativeMolecule, event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    repMolecule.CreationFromTemplate = false;
    repMolecule.SaveProperty('creationFromTemplate', 'Dropzone removed').subscribe();
    setTimeout(() => {
      this.workAreaService.HideElementFocusedMenu();
      setTimeout(() => {
        this.workAreaService.HideElementFocusedMenu();
      }, 50);
    }, 10);
  }
  
  MouseRelease(event: MouseEvent): void {
    if (this.context.RunningMode) {
      return;
    }
    console.log('wg receive');
    
    setTimeout(() => {
      let wgFit = null;
      
      if (this.dragService.dragBetweenWG.repMoleculeIdDragged === this.context.Id) {
        const viewWgs = this.busService.GetViewWorkgroupsAndStepperMolecules(this.cobbleService.Cobble, this.workAreaService.ActualView.id);
        
        const wgsFit = viewWgs.filter(
          w =>
            w.Id !== this.context.Id &&
            w.ParentId === this.cobbleService.Cobble.id &&
            w.ResponsiveProperties().x <= this.context.ResponsiveProperties().x &&
            w.ResponsiveProperties().y <= this.context.ResponsiveProperties().y &&
            w.ResponsiveProperties().x + w.ResponsiveProperties().cols >=
            this.context.ResponsiveProperties().x + this.context.ResponsiveProperties().cols &&
            w.ResponsiveProperties().y + w.ResponsiveProperties().rows >=
            this.context.ResponsiveProperties().y + this.context.ResponsiveProperties().rows,
        );
        
        if (wgsFit) {
          let smallestAreaWg: IRepresentativeMolecule = null;
          
          wgsFit.forEach(w => {
            if (smallestAreaWg) {
              if (
                smallestAreaWg.ResponsiveProperties().cols * smallestAreaWg.ResponsiveProperties().rows >
                w.ResponsiveProperties().cols * w.ResponsiveProperties().rows
              ) {
                smallestAreaWg = w;
              }
            } else {
              smallestAreaWg = w;
            }
          });
          
          wgFit = smallestAreaWg;
        }
      }
      
      if (this.dragService.dragBetweenWG.dragging) {
        if (wgFit && this.context.ParentId !== this.cobbleService.Cobble.id) {
          return;
        }
        
        this.dragService.dragBetweenWG.processing = true;
        this.dragService.dragBetweenWG.targetWgId = wgFit ? wgFit.Id : this.context.Id;
        this.dragService.ProcessRepMoleculeDragBetweenWG(event, wgFit ? { x: 1, y: 1 } : null);
      }
    }, 100);
    
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
  }
  
  MouseEnter(event: MouseEvent): void {
    if (this.dragService.dragBetweenWG.dragging && this.dragService.dragBetweenWG.sourceWgId === this.context.Id) {
      this.dragService.dragBetweenWG.showPreview = false;
    }
  }
  
  MouseLeave(event: MouseEvent): void {
    console.log('leave');
    
    if (this.dragService.dragBetweenWG.dragging) {
      this.dragService.dragBetweenWG.showPreview = true;
    }
  }
  
  StepClicked(mouseEvent: PointerEvent, step: MatStep): void {
    console.log(mouseEvent, step);
  }
  
  StepChanged(event: StepperSelectionEvent) {
    console.log('step changed', event.selectedIndex);
    
    if (event.selectedIndex === this.DESELECT_INDEX) {
      this.avoidEventFiring = false;
      return;
    }
    
    if (this.avoidEventFiring) {
      this.avoidEventFiring = false;
      return;
    }
    
    this.toolsService.RedrawBrowser();
    this.lastStepSelected = event.previouslySelectedStep;
    this.context.LastSelected = event.selectedIndex;
    
    if (this.context.RunningMode && this.context.Properties.stepper.stepperOrientation === 'horizontal') {
      document.querySelector(`#gridsterItem-${ this.context.Id } .mat-horizontal-stepper-header-container`).scrollLeft +=
        event.previouslySelectedIndex > event.selectedIndex ? -165 : 165;
    } else {
      document.querySelector(`#gridsterItem-${ this.context.Id } mat-stepper`).scrollTop +=
        event.previouslySelectedIndex > event.selectedIndex ? -100 : 100;
    }
    
    this.lastStepSelectedIndex = event.selectedIndex;
    setTimeout(() => {
      this.RefreshStepperUI();
      this.toolsService.RedrawBrowser();
      this.FireRepresentativeMoleculeEvent(LeapXLEventType.StepSelected, this.orderedData[event.selectedIndex], true);
    }, 200);
  }
  
  RefreshData() {
    this.context.LastSelected = 0;
    console.log(this.context.Id, this.context.Value);
    // console.log(this.rows, this.cols);
    
    const rowSeparatedData = Array.from(this.toolsService.GroupBy(this.context.Value, v => v.row)).map(d => d[1]);
    // console.log('rowSeparatedData', rowSeparatedData);
    // console.log('last col', this.colsCount.lastCol);
    // console.log('min col', this.colsCount.firstCol);
    
    this.orderedData = [];
    rowSeparatedData.forEach((row: any[]) => {
      if (row[0].orderCol) {
        row.sort((a, b) => 0 - (a.orderCol > b.orderCol ? -1 : 1));
      }
    });
    
    this.orderedData = rowSeparatedData;
    console.log('orderedData', this.orderedData, this.DataAssociationDefinitions);
    this.context.ProcessedValue = this.orderedData;
    
    if (this.stepper && this.orderedData.length < this.stepper.selectedIndex + 1) {
      this.stepper.selectedIndex = !this.context.Properties.selectFirstOption ? this.DESELECT_INDEX : 0;
    }
    
    if (this.context.RunningMode) {
      this.CreateRepresentativeMoleculesForSteps();
    }
    this.FireRepresentativeMoleculeEvent(LeapXLEventType.DataReceived, this.orderedData, false);
  }
  
  UpdateData() {
    if (this.context.RunningMode) {
      this.RefreshData();
    }
  }
  
  CreateRepresentativeMoleculesForSteps() {
    if (this.orderedData.length > 0) {
      this.orderedData.forEach((data, index) => {
        this.stepsChildrenGenerated[index] = this.GetChildren(index);
        if (this.headChildren.length > 0) {
          this.headChildrenGenerated[index] = this.GetHeadChildren(index);
        }
      });
      
      this.eventsService.RemoveFromListening(this.children.map(c => c.Id));
      this.RefreshStepperUI();
      console.log('stepsChildren', this.stepsChildrenGenerated);
      
      setTimeout(() => {
        if (this.context.Properties.selectFirstOption) {
          if (this.lastStepSelected === null) {
            this.FireRepresentativeMoleculeEvent(LeapXLEventType.StepSelected, this.orderedData[this.stepper && this.orderedData.length >= this.stepper.selectedIndex + 1 ? this.stepper.selectedIndex : 0], true);
          }
        } else {
          this.stepper.selectedIndex = this.DESELECT_INDEX;
        }
      }, 300);
      // delay to wait for any data to complete
    }
  }
  
  RefreshStepperUI() {
    if (this.context.GridsterConfig.api) {
      this.context.GridsterConfig.api.optionsChanged();
      this.context.GridsterConfig.api.resize();
    }
    
    if (this.ref && !(this.ref as ViewRef).destroyed) {
      this.ref.markForCheck();
      this.ref.detectChanges();
    }
    this.communicationService.Event.System.App.$RefreshUI.emit(true);
    
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    this.RefreshUI();
  }
  
  GetChildren(index: number): IRepresentativeMolecule[] {
    const renderedChildrenIds = this.stepsChildrenIds[index] || [];
    if (renderedChildrenIds.length > 0) {
      return this.busService.GetMultiple(renderedChildrenIds);
    }
    
    const minId = 11111111;
    const childrenIds = [];
    const repMolecules = [];
    
    this.children.forEach(c => {
      if (c.Id < minId) {
        const newId = Math.floor(Math.random() * (999999999 - minId)) + minId;
        childrenIds.push(newId);
        
        const newRepMolecule = this.builderService.CreateAndAddRepresentativeMoleculeToBus(
          this.busService.GetRepresentativeMoleculeFromRawApp(c.Id),
          newId,
          false,
        );
        
        newRepMolecule.OriginalRepMoleculeId = c.Id;
        newRepMolecule.SubParentId = index;
        newRepMolecule.RowDataElements = this.orderedData[index];
        repMolecules.push(newRepMolecule);
      } else {
        if (c.SubParentId === index) {
          repMolecules.push(c);
        }
      }
    });
    this.stepsChildrenIds[index] = childrenIds;
    
    // todo: evaluate events table creation for children
    this.eventsService.AddToEventsTable(childrenIds);
    console.log('new child events', this.eventsService.eventsTable);
    
    return repMolecules;
  }
  
  GetHeadChildren(index: number): IRepresentativeMolecule {
    const renderedChildrenIds = this.headChildrenIds[index] || [];
    if (renderedChildrenIds.length > 0) {
      return this.busService.GetMultiple(renderedChildrenIds)[0];
    }
    
    const minId = 11111111;
    const childrenIds = [];
    const repMolecules = [];
    
    this.headChildren.forEach(c => {
      const newId = Math.floor(Math.random() * (999999999 - minId)) + minId;
      childrenIds.push(newId);
      
      const newRepMolecule = this.builderService.CreateAndAddRepresentativeMoleculeToBus(
        this.busService.GetRepresentativeMoleculeFromRawApp(c.Id),
        newId,
        false,
      );
      
      newRepMolecule.OriginalRepMoleculeId = c.Id;
      newRepMolecule.SubParentId = index;
      newRepMolecule.RowDataElements = this.orderedData[index];
      newRepMolecule.CellDataElement = this.orderedData[index][this.DataAssociationDefinitions.label];
      repMolecules.push(newRepMolecule);
    });
    this.headChildrenIds[index] = childrenIds;
    
    // todo: evaluate events table creation for children
    this.eventsService.AddToEventsTable(childrenIds);
    console.log('new child events', this.eventsService.eventsTable);
    
    return repMolecules[0];
  }
  
  GetStepperChildren() {
    const minId = 11111111;
    return this.busService.DirectChildrenElements(this.context.Id).filter(c => c.Id < minId && c.Properties.location === null);
  }
  
  GetStepperHeadChildren() {
    const minId = 11111111;
    return this.busService.DirectChildrenElements(this.context.Id).filter(c => c.Id < minId && c.Properties.location === 'heading');
  }
  
  private ChildrenUpdate() {
    console.log(this.orderedData);
    
    if (this.cobbleService.Cobble.running) {
      this.children = this.GetStepperChildren();
      this.headChildren = this.GetStepperHeadChildren();
      
      this.CreateRepresentativeMoleculesForSteps();
      this.communicationService.Event.System.Update.$ChangesOnCharts.emit(null);
      
      setTimeout(() => {
        if (this.lastStepSelected === null && this.headChildren.length > 0) {
          console.log('aqui');
          
          this.FireRepresentativeMoleculeEvent(LeapXLEventType.StepSelected, this.orderedData[0], true);
        }
      }, 300);
      // delay to wait for any data to complete
    } else {
      setTimeout(() => {
        this.children = this.GetStepperChildren();
        this.headChildren = this.GetStepperHeadChildren();
        this.communicationService.Event.System.Update.$ChangesOnCharts.emit();
        
        this.RefreshStepperUI();
      }, 0);
      // setTimeout(() => {
      //   // this.ActivateMagnetElements();
      // }, 2000);
    }
  }
  
  private InitChildren() {
    this.ChildrenUpdate();
    
    setTimeout(() => {
      this.context.GridsterConfig.draggable.enabled = this.editorGridsterConfig.draggable.enabled;
      this.context.GridsterConfig.draggable.stop = this.editorGridsterConfig.draggable.stop;
      this.context.GridsterConfig.draggable.start = this.editorGridsterConfig.draggable.start;
      this.context.GridsterConfig.resizable = this.editorGridsterConfig.resizable;
      this.context.GridsterConfig.dragStartCallback = this.editorGridsterConfig.dragStartCallback;
      this.context.GridsterConfig.enableEmptyCellClick = this.editorGridsterConfig.enableEmptyCellClick;
      this.context.GridsterConfig.enableEmptyCellDrop = this.editorGridsterConfig.enableEmptyCellDrop;
      this.context.GridsterConfig.enableEmptyCellDrag = this.editorGridsterConfig.enableEmptyCellDrag;
      this.context.GridsterConfig.pushItems = this.editorGridsterConfig.pushItems;
      this.context.GridsterConfig.pushDirections = this.editorGridsterConfig.pushDirections;
      this.context.GridsterConfig.itemChangeCallback = this.editorGridsterConfig.itemChangeCallback;
      this.context.GridsterConfig.emptyCellClickCallback = this.editorGridsterConfig.emptyCellClickCallback;
      this.context.GridsterConfig.emptyCellDropCallback = this.editorGridsterConfig.emptyCellDropCallback;
      this.context.GridsterConfig.itemInitCallback = this.editorGridsterConfig.itemInitCallback;
      
      setTimeout(() => {
        this.context.GridsterConfig.api.optionsChanged();
      }, 300);
    }, 2000);
  }
  
  private refreshChildren() {
    console.log('refresh wg children', this.children);
    
    // this.context.GridsterConfig.draggable.enabled = false;
    // this.context.GridsterConfig.resizable.enabled = false;
    this.ChildrenUpdate();
    
    // if (!this.cobbleService.Cobble.running && this.context.GridsterConfig && this.context.GridsterConfig.api) {
    //   setTimeout(() => {
    //     this.context.GridsterConfig.draggable.enabled = true;
    //     this.context.GridsterConfig.resizable.enabled = true;
    //   }, 1000);
    // }
  }
}
