import * as fileSaver from 'file-saver';
import { Constants } from '../../../shared/constants';
import { DatasourceType } from '../../../shared/enums/datasource-type.enum';
import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class DownloadFileMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    dataBus: any,
    dataElementsData: any,
  ) {
    try {
      const title = rule && rule.filename && rule.filename !== '' ? rule.filename : null;
      const zip = rule && rule.zip && !!rule.zip === true;
      
      if (this.DataElements.length > 0) {
        console.log(dataElementsData);
        
        if (this.DataElements.length > 1) {
          if (dataElementsData && dataElementsData.length > 0) {
            this.ProcessAndExport(dataElementsData, title, repMoleculeId, zip);
          }
          
          this.DataElements.forEach((dataElement) => {
            if (dataElement.DataSourceType === DatasourceType.Spreadsheet) {
              const spplitedContext = dataElement.Context.split(Constants.ContextSeparator);
              
              if (spplitedContext.length === 3 || spplitedContext.length === 2) {
                this.dataSourcesService.DownloadSpreadsheetFile(dataElement.Context).subscribe((blob) => {
                  console.log(blob);
                  fileSaver.saveAs(blob, title && title !== '' ? title : (dataElement.Context as any).replaceAll(Constants.ContextSeparator, '-'));
                });
                console.log('obtain and download spreadsheet from backend');
              }
            }
          });
        } else {
          const dataElement = this.DataElements[0];
          
          if (dataElement.DataSourceType === DatasourceType.Spreadsheet) {
            const spplitedContext = dataElement.Context.split(Constants.ContextSeparator);
            
            if (spplitedContext.length === 3 || spplitedContext.length === 2) {
              this.dataSourcesService.DownloadSpreadsheetFile(dataElement.Context).subscribe((blob) => {
                console.log(blob);
                if (blob.error) {
                  
                  if (blob.status === 0) {
                    this.snackerService.ShowMessageOnBottom('Error downloading file', 'file_download_off');
                  }
                  
                } else {
                  fileSaver.saveAs(blob, title && title !== '' ? title : (dataElement.Context as any).replaceAll(Constants.ContextSeparator, '-'));
                }
              });
              console.log('obtain and download spreadsheet from backend');
            } else {
              this.ProcessAndExport(dataElementsData, title, repMoleculeId, zip);
            }
          } else {
            this.ProcessAndExport(dataElementsData, title, repMoleculeId, zip);
          }
        }
      } else {
        if (this.ExistsRepMoleculeAttached) {
          dataBus = this.RepMoleculeAttached.GetValue;
        }
        
        const parsedData = this.toolsService.ExtractValuesByType(dataBus);
        this.ProcessAndExport(
          parsedData.array.length > 1
            ? parsedData.array
            : [
              {
                row: 1,
                value: parsedData.string,
              },
            ],
          title,
          repMoleculeId,
          zip,
        );
      }
      
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    } catch (error) {
      console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  private ProcessAndExport(parsedData: any[], title: string, repMoleculeId: string, zip = false) {
    if (parsedData.length === 1) {
      const guid = this.toolsService.ExtractValuesByType(parsedData).string;
      if (this.toolsService.IsGUID(guid)) {
        this.fileService.getFileInformation(guid).subscribe(
          (fileData) => {
            if (fileData.guid === guid) {
              this.fileService.downloadFiles([guid], zip);
            }
            // do no exist
            else {
              this.toolsService.ExportToCsv(`${ this.busService.Get(repMoleculeId).Properties.name } Data`, [[guid]]);
            }
          },
          (error) => {
            this.toolsService.ExportToCsv(`${ this.busService.Get(repMoleculeId).Properties.name } Data`, [[guid]]);
          },
        );
      } else {
        this.toolsService.ExportToCsv(`${ this.busService.Get(repMoleculeId).Properties.name } Data`, [[guid]]);
      }
    } else {
      let areAllFiles = true;
      const guidFiles = [];
      
      const rowSeparatedData = Array.from(this.toolsService.GroupBy(parsedData, (v) => v.row)).map((d) => d[1]);
      
      const dataToExport = [];
      
      rowSeparatedData.forEach((row) => {
        const rowToExport = row.map((r) => r.value);
        dataToExport.push(rowToExport);
        if (!!rowToExport.find((value) => !this.toolsService.IsGUID(value))) {
          areAllFiles = false;
        }
      });
      
      if (title) {
      } else if (zip) {
        title = `${ this.cobbleService.Cobble.properties.name }-${ new Date().toJSON().slice(0, 10) }`;
      } else {
        try {
          title = parsedData[0].context
            ? `${ parsedData[0].context.split(Constants.ContextSeparator)[0] }-${ parsedData[0].context.split(Constants.ContextSeparator)[1] }`
            : `${ this.busService.Get(repMoleculeId).Properties.name } Data`;
        } catch (err) {
          title = 'Data';
        }
      }
      
      if (areAllFiles) {
        this.fileService.downloadFiles((dataToExport as any).flat(), zip, title);
      } else {
        this.toolsService.ExportToCsv(title, dataToExport);
      }
    }
  }
}
