export const checkboxTemplate = [
  {
    icon: 'check_box',
    moleculeType: 'Representative',
    name: 'Checkbox',
    type: 'Checkbox',
    moleculesAllow: ['DataAction', 'Behavior', 'Configuration', 'Compounds'],
    buses: [],
    debounce: {
      events: [],
    },
    minValue: null,
    maxValue: null,
    dataSource: {},
    styleMetadata: {
      styles: [],
      specificStyles: {
        dimension: null,
        font: null,
        hover: null,
        frame: null,
        appearance: null,
      },
      manualAdjustedProperties: [],
    },
    advanced: false,
    creationFromTemplate: false,
    editableProperties: ['textToDisplay', 'cols', 'rows', 'x', 'y', 'font', 'hover', 'opacity', 'shadow',
      'background', 'backgroundType'],
    receptors: [
      'value-input',
      'value-output',
      'tooltip-input',
      'options-list-input',
      'options-list-output',
      'badge-value-input',
      'badge-value-output',
      'text-input',
      'text-output',
      'none',
    ],
    events: ['init', 'state-change', 'state-checked', 'state-unchecked', 'hover'],
    defaultEvent: 'state-change',
    properties: {
      align: 'left', horizontalPadding: 0,
      verticalPadding: 0,
      progressType: 'progress',
      progressColor: '#EA5C2F',
      progressBackgroundColor: '#E5957C',
      progressDiameter: 50,
      progressMode: 'indeterminate',
      progressStrokeWidth: 5,
      wrapText: false,
      allowManualEntry: false,
      alwaysRenderComponent: false,
      sliderVertical: false,
      sliderStep: 1,
      sliderThumbLabel: false,
      sliderTicks: false,
      sliderColor: '#EA5C2F',
      location: null,
      allowEmptyValues: false,
      hover: {
        hoverBackground: 'white',
        hoverBackgroundOpacity: 1,
        hoverBorderRadius: 0,
        hoverBorderStyle: 'none',
        hoverBorderColor: 'transparent',
        hoverBorderWidth: 0,
        hoverTextDecoration: null,
        hoverPointer: null,
      },
      options: [
        {
          value: 'value 1',
          text: 'Text 1',
        },
      ],
      show: true,
      opacity: 1,
      orientation: 'col',
      enable: true,
      dateRange: false,
      search: false,
      multiSelect: false,
      autoDataRefresh: false,
      altText: '',
      ignoreValueDataIndex: false,
      textDecoration: 'none',
      badge: {
        badgeEnable: true,
        badgeBackgroundColor: '#f22525',
        badgeFontFamily: 'Source Sans Pro',
        badgeFontColor: 'white',
        badgeStyle: 'circle',
        badgePosition: 'right',
      },
      name: 'Checkbox',
      labelPosition: 'after',
      legend: true,
      loadingText: '',
      maskType: 'none',
      mask: '',
      background: {
        backgroundColor: 'transparent',
        backgroundTypeImage: false,
        backgroundImageUrl: '',
        backgroundOpacity: 1,
      },
      icon: {
        iconType: 'stars',
        iconColor: 'black',
        iconSize: 45,
      },
      bordersValues: {
        borderWidth: 1,
        borderStyle: 'none',
        borderColor: '#212529',
      },
      shadowValues: {
        hShadow: 0,
        vShadow: 0,
        shadowColor: '#212529',
        shadowBlur: 0,
      },
      preferences: {},
      view: 1,
      chartLibrary: 'ngxCharts',
      chartType: 'line',
      tabindex: 0,
      textToDisplay: 'Checkbox',
      tooltip: '',
      placeholder: 'Checkbox',
      minItemCols: 2,
      maxItemCols: 20,
      responsive: {
        desktop: {
          customGuidelines: {
            x: [],
            y: [],
          },
          id: 0,
          cols: 38,
          rows: 12,
          colsQty: 0,
          rowsQty: 0,
          layer: 1,
          centerPositioning: false,
          x: 0,
          y: 0,
          font: {
            fontSize: 16,
            fontColor: '#000000',
            fontFamily: 'inherit',
            fontStyle: 'inherit',
            fontWeight: 'normal',
          },
          tableOptions: {
            collapse: true,
            bodyRoundCorners: false,
            search: true,
            header: true,
            verticallyCenter: true,
            rowSeparator: false,
            columnSeparator: false,
            rowPadding: true,
            tableOrder: [],
            tableWidth: {},
          },
        },
        smartphone: {
          smartphone: {
            customGuidelines: {
              x: [],
              y: [],
            },
            id: null,
            cols: null,
            rows: null,
            colsQty: null,
            rowsQty: null,
            layer: null,
            centerPositioning: false,
            x: null,
            y: null,
            font: {
              fontSize: null,
              fontColor: null,
              fontFamily: null,
              fontStyle: null,
              fontWeight: null,
            },
            tableOptions: {
              search: true,
              header: true,
              verticallyCenter: null,
              rowSeparator: null,
              columnSeparator: null,
              rowPadding: null,
              tableOrder: [],
              tableWidth: {},
            },
          },
        },
      },
      molecules: [],
      placeholders: [],
      isSelected: false,
      value: null,
      children: [],
      replaceableByRepresentative: true,
      loading: false,
      moleculeSubType: 'Checkbox',
      optionsMenu: {
        configuration: true,
        molecules: true,
        compound: false,
        cobblet: true,
        remove: true,
        versioning: true,
        dataSource: true,
        duplicate: true,
      },
    },
  },
];
