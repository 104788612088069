export class HierarchyNode {
  id?: number;
  name: string;
  description: string;
  companyId: number;
  parentCompanyNodeId: number;
  nodes?: any[];
  users?: any[];
  
  constructor() {
  }
}
