import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { AngularSplitModule } from 'angular-split';
import { TreeModule } from 'angular-tree-component';
import { AngularDraggableModule } from 'angular2-draggable';
import { HotkeyModule } from 'angular2-hotkeys';
import { MsAdalAngular6Module } from 'microsoft-adal-angular6';
import { ClickOutsideModule } from 'ng-click-outside';
import { DndModule } from 'ng2-dnd';
import { FileUploadModule } from 'ng2-file-upload';
import { ACE_CONFIG, AceConfigInterface, AceModule } from 'ngx-ace-wrapper';
import { NgxCaptureModule, NgxCaptureService } from 'ngx-capture';
import { NgxImageCompressService } from 'ngx-image-compress';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { NgxPaginationModule } from 'ngx-pagination';
import 'script-loader!jszip/dist/jszip.min.js';
import { ConfigurationModule } from '../assets/config/configuration.module';
import { environment } from '../environments/local/environment';
import { AdminModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { AuthModule } from './auth/auth.module';
import { CommitCreatorComponent } from './commit-creator/commit-creator.component';
import { BuilderService } from './core/builder/builder.service';
import { CoreModule } from './core/core.module';
import { httpInterceptorProviders } from './core/http-interceptors';
import { BusService } from './core/molecular/services/bus.service';
import { AnimationsService } from './core/services/animations.service';
import { ApiAdminService } from './core/services/api-admin.service';
import { ApiHierarchyService } from './core/services/api-hierarchy.service';
import { ApiLicenseService } from './core/services/api-license.service';
import { ApiMoleculesService } from './core/services/api-molecules.service';
import { CustomIconsService } from './core/services/custom-icons.service';
import { LocalStorageService } from './core/services/local-storage.service';
import { MoleculeManagmentService } from './core/services/molecule-managment.service';
import { ToolsService } from './core/services/tools.service';
import { DebugToggleComponent } from './debug-toggle/debug-toggle.component';
import { DynamicFormModule } from './dynamic-form/dynamic-form.module';
import { MoleculeSettingsComponent } from './dynamic-form/molecule-settings/molecule-settings.component';
import { EventsTransactionComponent } from './events-transaction/events-transaction.component';
import { AuthGuard } from './guards/auth.guard';
import { UnsavedDataGuard } from './guards/unsaved-data.guard';
import { InitDbComponent } from './init-db/init-db.component';
import { MoleculeParametersDialogComponent } from './molecule-parameters-dialog/molecule-parameters-dialog.component';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { RequestsLogComponent } from './requests-log/requests-log.component';
import { RuntimeService } from './running-area/services/runtime.service';
import { TFADialogComponent } from './shared/components/2fa-dialog/2fa-dialog.component';
import { TFADialogService } from './shared/components/2fa-dialog/2fa-dialog.service';
import {
  BackgroundTaskSnackbarComponent,
} from './shared/components/background-task-snackbar/background-task-snackbar.component';
import { ChatComponent } from './shared/components/chat/chat.component';
import { DialogComponent } from './shared/components/dialog/dialog.component';
import { DialogService } from './shared/components/dialog/dialog.service';
import { DraggableWindowComponent } from './shared/components/draggable-window/draggable-window.component';
import { IframeComponent } from './shared/components/iframe/iframe.component';
import { Constants } from './shared/constants';
import { GlobalHandlerError } from './shared/global-handler-error';
import { MaterialModule } from './shared/material/material.module';
import { RepresentativeMoleculeModule } from './shared/representative-molecule/representative-molecule.module';
import { DraggableWindowService } from './shared/services/draggable-window.service';
import { SharedModule } from './shared/shared.module';
import { TestComponent } from './test/test.component';
import { WebEditorComponent } from './web-editor/web-editor.component';
import { WelcomeModule } from './welcome/welcome.module';
import { CloneApplicationComponent } from './workarea/clone-application/clone-application.component';
import { LeapxlExportComponent } from './workarea/leapxl-export/leapxl-export.component';
import {
  LeapxlImportSpreadsheetDataComponent,
} from './workarea/leapxl-import/leapxl-import-spreadsheet-data/leapxl-import-spreadsheet-data.component';
import { LeapxlImportComponent } from './workarea/leapxl-import/leapxl-import.component';
import {
  PreviewRepresentativeMoleculeComponent,
} from './workarea/preview-representative-molecule/preview-representative-molecule.component';
import { UnsavedDataApologyComponent } from './workarea/unsaved-data-apology/unsaved-data-apology.component';
import { UnsavedDataComponent } from './workarea/unsaved-data/unsaved-data.component';
import { VersioningHistoryDialogService } from './workarea/versioning-history/versioning-history-dialog.service';
import { WorkareaModule } from './workarea/workarea.module';
import { WorkAreaService } from './workarea/workarea.service';

const DEFAULT_ACE_CONFIG: AceConfigInterface = {};

const dbConfig: DBConfig = {
  name: 'LeapXLDb',
  version: 1,
  objectStoresMeta: [
    {
      store: 'publish-app',
      storeConfig: { keyPath: 'guid', autoIncrement: false },
      storeSchema: [
        { name: 'guid', keypath: 'guid', options: { unique: false } },
        { name: 'version', keypath: 'version', options: { unique: false } },
        { name: 'data', keypath: 'data', options: { unique: false } },
      ],
    },
  ],
};

@NgModule({
  declarations: [
    AppComponent,
    MoleculeParametersDialogComponent,
    DialogComponent,
    UnsavedDataComponent,
    UnsavedDataApologyComponent,
    InitDbComponent,
    CommitCreatorComponent,
    MonitoringComponent,
    LeapxlExportComponent,
    LeapxlImportComponent,
    LeapxlImportSpreadsheetDataComponent,
    DraggableWindowComponent,
    TestComponent,
    EventsTransactionComponent,
    RequestsLogComponent,
    DebugToggleComponent,
    ChatComponent,
    RequestsLogComponent,
    CloneApplicationComponent,
    BackgroundTaskSnackbarComponent,
    TFADialogComponent,
    WebEditorComponent,
  ],
  imports: [
    ConfigurationModule,
    DndModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    TreeModule,
    HotkeyModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    DynamicFormModule,
    AuthModule,
    WorkareaModule,
    FileUploadModule,
    WelcomeModule,
    AdminModule,
    AngularDraggableModule,
    NgxGraphModule,
    MaterialModule,
    ServiceWorkerModule.register('sw.js', {
      enabled: false,
    }),
    // MsAdalAngular6Module,
    MsAdalAngular6Module.forRoot(getAdalConfig),
    NgxIndexedDBModule.forRoot(dbConfig),
    ClickOutsideModule,
    NgxCaptureModule,
    RepresentativeMoleculeModule,
    AceModule,
    AngularSplitModule,
  ],
  entryComponents: [
    DialogComponent,
    MoleculeParametersDialogComponent,
    MoleculeSettingsComponent,
    MonitoringComponent,
    EventsTransactionComponent,
    UnsavedDataApologyComponent,
    BackgroundTaskSnackbarComponent,
    LeapxlExportComponent,
    LeapxlImportSpreadsheetDataComponent,
    PreviewRepresentativeMoleculeComponent,
    LeapxlImportComponent,
    CloneApplicationComponent,
    UnsavedDataComponent,
    RequestsLogComponent,
    DraggableWindowComponent,
    TFADialogComponent,
    IframeComponent,
    ChatComponent,
  ],
  providers: [
    httpInterceptorProviders,
    BuilderService,
    AuthGuard,
    UnsavedDataGuard,
    BusService,
    ApiMoleculesService,
    DialogService,
    MoleculeManagmentService,
    WorkAreaService,
    VersioningHistoryDialogService,
    AnimationsService,
    ToolsService,
    LocalStorageService,
    CustomIconsService,
    ApiLicenseService,
    ApiHierarchyService,
    ApiAdminService,
    RuntimeService,
    DraggableWindowService,
    NgxImageCompressService,
    NgxCaptureService,
    TFADialogService,
    Title,
    // AppConfig,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeApp,
    //   deps: [AppConfig],
    //   multi: true
    // },
    // {
    //   provide: 'adalConfig',
    //   useFactory: msAdalAngular6ConfigFactory,
    //   deps: []
    // },
    // MsAdalAngular6Service,
    {
      provide: ErrorHandler,
      useClass: GlobalHandlerError,
    },
    {
      provide: ACE_CONFIG,
      useValue: DEFAULT_ACE_CONFIG,
    },
  ],
  
  bootstrap: [AppComponent],
})
export class AppModule {
}

let adalConfig: any; // will be initialized by APP_INITIALIZER
export function msAdalAngular6ConfigFactory() {
  return adalConfig; // will be invoked later when creating MsAdalAngular6Service
}

export function initializeApp(appConfig: AppConfig) {
  console.log(appConfig);
  const promise = appConfig.load()
  .then(() => {
    adalConfig = {
      tenant: AppConfig.adalSettings.tenant,
      clientId: AppConfig.adalSettings.clientId,
      redirectUri: AppConfig.adalSettings.redirectUri,
      endpoints: AppConfig.adalSettings.endpoints,
      navigateToLoginRequestUrl: false,
      cacheLocation: AppConfig.adalSettings.cacheLocation,
    };
  });
  return () => promise;
}

export function getAdalConfig() {
  const environmentRequest = new XMLHttpRequest();
  // request.open('GET', './assets/config/adal-configuration.' + environment + '.json', false);
  try {
    if (window.location.href.includes('http://localhost:4200/')) {
      Constants.Environment.apiUrl = environment.apiUrl;
      Constants.Environment.debug = environment.debug;
      Constants.Environment.domain = environment.domain;
      Constants.Environment.https = environment.https;
      Constants.Environment.hubConnectionUrl = environment.hubConnectionUrl;
      Constants.Environment.production = environment.production;
      environmentRequest.open('GET', './environments/local/environment.ts', false);
    } else {
      environmentRequest.open('GET', './environments/environment.ts', false);
    }
    
    environmentRequest.send(null);
    
    if (environmentRequest.status === 200) {
      const apiUrlSection = environmentRequest.responseText.substring(
        environmentRequest.responseText.indexOf('apiUrl: \''),
        environmentRequest.responseText.length,
      );
      
      const apiUrl = apiUrlSection.substring(apiUrlSection.indexOf('apiUrl: \''), apiUrlSection.indexOf('\','));
      
      const request = new XMLHttpRequest();
      request.open('GET', apiUrl.replace('apiUrl: \'', '') + 'Accounts/ADALConfiguration', false);
      request.send(null);
      
      if (request.status === 200) {
        // test data
        // return {
        //   'tenant': '8e9ab507-56f8-4bb2-9255-9da5e3669bec',
        //   'clientId': 'bb94e447-ee45-4086-8e0e-13cac5f0c007',
        //   'redirectUri': 'http://localhost:4200/login/external/aad',
        //   'popUp': false,
        //   'endpoints': {
        //     'api': 'bb94e447-ee45-4086-8e0e-13cac5f0c007'
        //   },
        //   'navigateToLoginRequestUrl': false,
        //   'cacheLocation': 'localStorage',
        //   'expireOffsetSeconds': 3600
        // };
        
        const config = JSON.parse(request.responseText);
        const adalConfig = config.api;
        adalConfig.navigateToLoginRequestUrl = false;
        adalConfig.cacheLocation = 'localStorage';
        return adalConfig;
      } else {
        return {
          tenant: 'none',
          clientId: 'none',
          redirectUri: 'none',
          popUp: false,
          endpoints: {
            api: 'none',
          },
          navigateToLoginRequestUrl: false,
          cacheLocation: 'localStorage',
        };
      }
    } else {
      return {
        tenant: 'none',
        clientId: 'none',
        redirectUri: 'none',
        popUp: false,
        endpoints: {
          api: 'none',
        },
        navigateToLoginRequestUrl: false,
        cacheLocation: 'localStorage',
      };
    }
  } catch (e) {
    console.warn(e);
    return {
      tenant: 'none',
      clientId: 'none',
      redirectUri: 'none',
      popUp: false,
      endpoints: {
        api: 'none',
      },
      navigateToLoginRequestUrl: false,
      cacheLocation: 'localStorage',
    };
  }
}
