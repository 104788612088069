import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { WjFlexSheet } from '@grapecity/wijmo.angular2.grid.sheet';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { CellRange } from '@grapecity/wijmo.grid';
import * as wjcGridSheet from '@grapecity/wijmo.grid.sheet';
import { Subscription } from 'rxjs';
import { ApiDataSourcesService } from '../../core/services/api-data-sources.service';
import { ToolsService } from '../../core/services/tools.service';
import { Constants } from '../../shared/constants';
import { DatasourceSharedType } from '../../shared/enums/datasource-shared-type.enum';
import { DatasourceType } from '../../shared/enums/datasource-type.enum';
import { DragType } from '../../shared/enums/drag-type.enum';
import { ParticleType } from '../../shared/enums/particle-type.enum';
import { SheetChangeValue } from '../../shared/models/sheet-change';
import { DataElement } from '../../shared/representative-molecule/interfaces/data-element';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { DragService } from '../../shared/representative-molecule/services/drag.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { ConnectionStateService } from '../../shared/services/connection-state.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { WorkAreaService } from '../../workarea/workarea.service';
import { SheetSelection } from '../models/sheet-selection';
import { DataSource, SpreadsheetService } from '../spreadsheet.service';

declare var formulajs: any;

@Component({
  selector: 'spreadsheet-view',
  templateUrl: './spreadsheet.component.html',
  styleUrls: ['./spreadsheet.component.scss'],
})
export class SpreadsheetComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('flexSheet', { static: false })
  flexSheet: wjcGridSheet.FlexSheet;
  data = [];
  selectionFormatState: wjcGridSheet.IFormatState = {};
  subscriptions: Subscription;
  cursorPosition: MouseEvent;
  handleRangeParent: HTMLElement;
  spreadSheetDragImg = document.createElement('img');
  creatingDataSource = false;
  rowColumnChanges = {};
  draggableCells = [];

  constructor(
    private workAreaService: WorkAreaService,
    private spreadsheetService: SpreadsheetService,
    private dataSourcesService: ApiDataSourcesService,
    private snackerService: SnackerService,
    private dragService: DragService,
    private connectionStateService: ConnectionStateService,
    private cobbleService: CobbleService,
    private toolsService: ToolsService,
    private communicationService: CommunicationService,
    private angularZone: NgZone
  ) {}

  ngOnInit() {
    this.spreadSheetDragImg.src = '../../../assets/images/ds_drag_icon.png';
  }

  ReorderSheets() {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }

    this.dataSourcesService.ReorderDataSourceSheet(this.flexSheet.sheets.map(s => s.name)).subscribe();
  }

  AddSheet() {
    if (this.flexSheet) {
      // console.log('added sheet');
      this.spreadsheetService.AddSheet(this.flexSheet.sheets[this.flexSheet.selectedSheetIndex].name, this.flexSheet.selectedSheetIndex);
      this.spreadsheetService.UpdateSheetNames();
    }
  }

  DeleteSheet(sheetIndex: number) {
    if (this.flexSheet) {
      // console.log('deleted sheet');
      this.spreadsheetService.RemoveSheet(sheetIndex);
      this.spreadsheetService.UpdateSheetNames();
    }
  }

  itemFormatter(panel, r, c, cell) {
    if (panel.cellType === wjcGrid.CellType.RowHeader || panel.cellType === wjcGrid.CellType.ColumnHeader) {
      cell.draggable = true;
    }
  }

  ngAfterViewInit() {
    let lastestSelection = [];

    this.spreadsheetService.flexSheet = this.flexSheet;
    this.flexSheet.beginningEdit.addHandler((sender, e: wjcGrid.CellRangeEventArgs) => {
      if (this.spreadsheetService.productionDatasourceOpen) {
        e.cancel = true;
      } else {
        e.cancel = e.row > 0 && this.spreadsheetService.editorDbMode;
      }
    });

    this.flexSheet.hostElement.addEventListener(
      'mousedown',
      e => {
        setTimeout(() => {
          if ((e.target as any).classList.contains('wj-header')) {
            const dataSelected = this.getSelectedCellsData(true);
            dataSelected.dataSourceTypeName = DatasourceType.Spreadsheet;
            this.spreadsheetService.SpreadSheetRangeSelected = dataSelected;

            // select first cell
            // this.flexSheet.select(new CellRange(0, this.spreadsheetService.SpreadSheetRangeSelected.firstColIndex,
            // this.spreadsheetService.SpreadSheetRangeSelected.rows, this.spreadsheetService.SpreadSheetRangeSelected.lastColIndex));
          }
        }, 50);

        const ht = this.flexSheet.hitTest(e);

        try {
          if (this.flexSheet.getSelectedState(ht.row, ht.col) === 0) {
          } else {
            if ((e.target as any).classList.contains('wj-cell')) {
              e.stopPropagation();
            }
          }
        } catch (error) {}
      },
      true
    );

    this.flexSheet.hostElement.addEventListener(
      'mouseup',
      e => {
        // console.log(this.flexSheet.selection);
        console.log('DATA: ', JSON.stringify(this.flexSheet.getCellData(this.flexSheet.selection.row, this.flexSheet.selection.col, true)));
        console.log('VALUE: ', JSON.stringify(this.flexSheet.getCellValue(this.flexSheet.selection.row, this.flexSheet.selection.col)));
        // this.flexSheet.refreshRange(this.flexSheet.selection);

        // console.log(e);
        const targetClassList = (e.target as any).classList;
        if (!targetClassList.contains('wj-cell')) {
          return;
        }

        // removing draggable cells from previous selection
        this.draggableCells.forEach(dc => {
          dc.draggable = false;
          dc.style.cursor = 'default';
        });

        this.draggableCells = [];
        //

        setTimeout(() => {
          // console.log(this.spreadsheetService.SpreadSheetRangeSelected);

          if (this.spreadsheetService.SpreadSheetRangeSelected) {
            this.spreadsheetService.SpreadSheetRangeSelected.dataItems.forEach(cell => {
              const cellGetted = this.flexSheet.cells.getCellElement(cell.row, cell.col);

              if (cellGetted) {
                cellGetted.draggable = true;
                cellGetted.style.cursor = 'grab';
                this.draggableCells.push(cellGetted);
              }
            });
          }
        }, 150);
      },
      true
    );

    this.flexSheet.rowChanged.addHandler((fs: wjcGridSheet.FlexSheet, changeEvent: wjcGridSheet.RowColumnChangedEventArgs) => {
      // console.log('row change', changeEvent);

      this.rowColumnChanges[this.flexSheet.selectedSheetIndex] = this.rowColumnChanges[this.flexSheet.selectedSheetIndex] || [];

      for (let rowIndex = changeEvent.ranges[0].row; rowIndex <= changeEvent.ranges[0].row2; rowIndex++) {
        this.rowColumnChanges[this.flexSheet.selectedSheetIndex].push({
          event: changeEvent.added ? 'Add' : 'Delete',
          index: rowIndex + 1,
          type: 'Row',
        });
      }

      // console.log('changes', this.rowColumnChanges);
    });

    this.flexSheet.columnChanged.addHandler((fs: wjcGridSheet.FlexSheet, changeEvent: wjcGridSheet.RowColumnChangedEventArgs) => {
      // console.log('column change', changeEvent);

      this.rowColumnChanges[this.flexSheet.selectedSheetIndex] = this.rowColumnChanges[this.flexSheet.selectedSheetIndex] || [];

      for (let colIndex = changeEvent.ranges[0].col; colIndex <= changeEvent.ranges[0].col2; colIndex++) {
        this.rowColumnChanges[this.flexSheet.selectedSheetIndex].push({
          event: changeEvent.added ? 'Add' : 'Delete',
          index: wjcGridSheet.FlexSheet.convertNumberToAlpha(colIndex),
          type: 'Column',
        });
      }

      // console.log('changes', this.rowColumnChanges);
    });

    this.flexSheet.hostElement.addEventListener(
      'dragstart',
      e => {
        // console.log(e);
        if (!(e.target as any).classList.contains('wj-cell')) {
          return;
        }

        this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);

        const wv = document.querySelector('.workbook-view');
        if (wv) {
          wv.classList.add('selection-made');
        }

        setTimeout(() => {
          this.dragService.dragginDataElement = true;
          this.dragService.dragText = `Attach ${this.spreadsheetService.SpreadSheetRangeSelected.range} Data Element`;
          if (this.spreadsheetService.dataSourceTypeOpened === DatasourceType.Spreadsheet) {
            this.dragService.dragData = { fromTree: false, dragType: DragType.Spreadsheet };
          } else {
            const dataElements = [];

            this.spreadsheetService.SpreadSheetRangeSelected.dataItems.forEach(dataItem => {
              const dataElement = new DataElement({
                translationId: 0,
                id: this.toolsService.GenerateGuid(),
                particleId: this.toolsService.GenerateGuid(),
                particleType: ParticleType.DataElement,
                context: `${this.spreadsheetService.SpreadSheetRangeSelected.dataSourceName}${Constants.ContextSeparator}${dataItem.value}`,
                dataSourceType: this.spreadsheetService.dataSourceTypeOpened,
                dataSourceId: null,
                dataSourceName: this.spreadsheetService.SpreadSheetRangeSelected.dataSourceName,
                collection: '',
                internalName: dataItem.value,
                reference: dataItem.value,
                applicationId: this.cobbleService.Cobble.id,
              });

              dataElements.push(dataElement);
            });

            this.dragService.dragData = {
              fromTree: false,
              dragType: DragType.DataElement,
              data: dataElements,
            };
          }
        }, 50);

        // console.log('set image');
        e.dataTransfer.setDragImage(this.spreadSheetDragImg, -10, 0);
      },
      true
    );

    this.flexSheet.hostElement.addEventListener(
      'dragend',
      e => {
        const wv = document.querySelector('.workbook-view');
        this.dragService.dragginDataElement = false;
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
        if (wv) {
          wv.classList.remove('selection-made');
        }
      },
      true
    );
  }

  selectRangeOnSpreadsheet(
    firstRow: number,
    firstCol: number,
    lastRow: number,
    lastCol: number,
    dataSourceId: number,
    collection: string,
    show: boolean = true
  ) {
    const wv = document.querySelector('.workbook-view');

    firstRow = firstRow < 0 ? 0 : firstRow;
    lastRow = lastRow < 0 ? 0 : lastRow;

    wv.classList.add('selection-preview');
    setTimeout(() => {
      wv.classList.remove('selection-preview');
    }, 2500);

    if (this.flexSheet && (this.dataSourcesService.openedDataSourceId === dataSourceId || dataSourceId === 0)) {
      const range = new wjcGrid.CellRange(firstRow, firstCol, lastRow, lastCol);
      if (this.flexSheet) {
        this.flexSheet.selectedSheetIndex = collection === '' ? 0 : this.flexSheet.sheets.findIndex(s => s.name === collection);
        this.flexSheet.select(range, show);

        const cellGetted = this.flexSheet.cells.getCellElement(firstRow, firstCol);

        if (cellGetted) {
          cellGetted.draggable = true;
          cellGetted.style.cursor = 'grab';
          this.draggableCells.push(cellGetted);
        }

        const dataSelected = this.getSelectedCellsData();
        this.spreadsheetService.SpreadSheetRangeSelected = dataSelected;
      }
    }
  }

  createNewChange(flexSheet: WjFlexSheet, data: any, type: string): SheetChangeValue {
    let range = '';
    let formatType: string = null;
    let newValue = null;
    if (data.range) {
      range = WjFlexSheet.convertNumberToAlpha(data.col) + (data.row + 1).toString();
      newValue = this.flexSheet.getCellData(data.row, data.col, false);
    } else if (type === 'PASTED') {
      range = WjFlexSheet.convertNumberToAlpha(data.col) + (data.row + 1).toString();
      newValue = data.data;
    } else {
      if (type === 'COLUMN') {
        range = WjFlexSheet.convertNumberToAlpha(data.index);
      } else {
        range = (data.index + 1).toString();
      }
      formatType = data.isAdd ? 'INSERT' : 'DELETE';
    }
    const newChange: SheetChangeValue = new SheetChangeValue(
      this.dataSourcesService.openedDataSourceId,
      flexSheet.selectedSheetIndex,
      range,
      formatType,
      newValue
    );
    return newChange;
  }

  initFlexSheet(flexSheet: WjFlexSheet) {
    // console.log('init flexsheet');
    this.flexSheet = flexSheet;

    this.spreadsheetService.flexSheet = this.flexSheet;
    this.workAreaService.sheetPanelDragged$.subscribe(() => {
      this.flexSheet.refresh();
    });

    this.flexSheet.loaded.addHandler((s, e) => {
      this.spreadsheetService.spreadSheetLoaded = true;
    });
    this.flexSheet.sheets.collectionChanged.addHandler((s, a: any) => {
      if (!this.flexSheet.isUpdating && !this.creatingDataSource) {
        // console.log('collectionchanged', s, a);

        if (
          s.length === 0 ||
          this.dataSourcesService.openedDataSourceId < 1 ||
          this.spreadsheetService.dataSourceTypeOpened !== DatasourceType.Spreadsheet
        ) {
          return;
        }

        switch (a.action) {
          // insert
          case 0:
            this.AddSheet();
            break;

          // delete
          case 1:
            this.DeleteSheet(a.index);
            break;

          // order
          case 3:
            this.ReorderSheets();
            break;
        }
      }
    });

    this.flexSheet.selectedSheetChanged.addHandler((s, e) => {
      // console.log('selectedsheet changed', s, e);

      if (this.dataSourcesService.openedDataSourceId <= 0) {
        return;
      }
      
      if (this.spreadsheetService.spreadsheetData) {
        this.spreadsheetService.spreadsheetData.sheetIndex = e.newValue;
      }

      this.DeselectSpreadsheetCells();

      if (this.spreadsheetService.openedSheets.findIndex(i => i === this.flexSheet.selectedSheetIndex) === -1) {
        this.spreadsheetService.openedSheets.push(this.flexSheet.selectedSheetIndex);
      }

      this.spreadsheetService.SetSheetHash(this.flexSheet.selectedSheetIndex);
      this.dataSourcesService.openedDataSourceSheetIndex = this.flexSheet.selectedSheetIndex;

      // this.flexSheet.columns.forEach(column => {
      //   column.multiLine = true;
      // });

      // force
      this.spreadsheetService.sName = this.flexSheet.selectedSheet.name.replace('&amp;', '&');
    });

    this.flexSheet.refreshing.addHandler((s, e) => {
      // console.log('refreshing', s, e);
    });

    this.flexSheet.updatingView.addHandler((s, e) => {
      // console.log('view updating', s, e);
    });

    flexSheet.sheets.sheetNameChanged.addHandler((s, a: any) => {
      if (!this.flexSheet.isUpdating && !this.creatingDataSource && this.spreadsheetService.dataSourceTypeOpened === DatasourceType.Spreadsheet) {
        this.RenameSheet(this.dataSourcesService.openedDataSourceId, a.index, a.item.name);
      }
    });

    this.subscriptions = this.communicationService.Event.Editor.DataSource.Spreadsheet.$SelectRangeOnSpreadsheet.subscribe(
      (datasource: {
        firstRow: number;
        firstCol: number;
        lastRow: number;
        lastCol: number;
        dataSourceId: number;
        collection: string;
        show: boolean;
      }) => {
        console.log('=event=');
        console.log('$SelectRangeOnSpreadsheet');
        this.selectRangeOnSpreadsheet(
          datasource.firstRow,
          datasource.firstCol,
          datasource.lastRow,
          datasource.lastCol,
          datasource.dataSourceId,
          datasource.collection,
          datasource.show
        );
      }
    );

    this.subscriptions.add(
      this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetModeSwitched.subscribe(specialMode => {
        console.log('=event='); // console.log('$SpreadsheetModeSwitched');
        this.SetSpecialMode();
        this.DeselectSpreadsheetCells();
      })
    );

    this.subscriptions.add(
      this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetVersionSwitched.subscribe(specialMode => {
        console.log('=event='); // console.log('$SpreadsheetModeSwitched');
        this.SetProductionMode();
        this.DeselectSpreadsheetCells();
      })
    );

    this.angularZone.runOutsideAngular(() => {
      this.flexSheet.selectionChanged.addHandler((sender: any, args: wjcGrid.CellRangeEventArgs) => {
        // console.log('selectionChanged', args);
        this.selectionFormatState = this.flexSheet.getSelectionFormatState();
        this.spreadsheetService.setState(this.selectionFormatState);
        this._updateSelection(args.range);
        setTimeout(() => {
          this.SetSpecialMode();
        }, 200);
      });
    });

    // DISABLING CUSTOM LEAPSHEET FUNCTIONS

    this.addFormulaJsToFlexSheet(this.flexSheet);
    // this.spreadsheetService.setSelection({
    //   cellRange: '',
    //   content: '',
    //   fontFamily: '',
    //   fontSize: '',
    //   position: 'A1'
    // });

    this.handleRangeParent = this.flexSheet.hostElement.querySelector(`[wj-part='root']`);
    this.handleRangeParent.addEventListener(
      'mouseup',
      e => {
        // console.log('mouse up');

        if ((e as MouseEvent).which === 1) {
          const ht = this.flexSheet.hitTest(e);
          if (wjcGrid.CellType.ColumnHeader !== ht.cellType) {
            this.cursorPosition = e as MouseEvent;
            const dataSelected = this.getSelectedCellsData();
            dataSelected.dataSourceTypeName = DatasourceType.Spreadsheet;
            this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetRangeSelected.emit(dataSelected);
            this.spreadsheetService.SpreadSheetRangeSelected = dataSelected;
          }
        }
      },
      true
    );

    this.flexSheet.quickAutoSize = true;
  }

  undo() {
    if (this.flexSheet) {
      this.flexSheet.undo();
    }
  }

  DeselectSpreadsheetCells() {
    if (this.flexSheet) {
      try {
        this.flexSheet.select(new CellRange(-1, -1), false);
      } catch (err) {
        console.log(' clear flexhseet selection');
      }
    }
  }

  redo() {
    if (this.flexSheet) {
      this.flexSheet.redo();
    }
  }

  RenameSheet(dataSourceId: number, sheetIndex: number, name: string) {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }

    this.dataSourcesService.RenameSheet(dataSourceId, sheetIndex + 1, name).subscribe(result => {
      this.communicationService.Event.Editor.DataSource.$ReloadDataSourcePanel.emit([]);
    });
    this.snackerService.ShowMessageOnBottom('Workbook Renamed!', 'drive_file_rename_outline', null, true);
  }

  newBook(name: string) {
    this.creatingDataSource = true;
    this.dataSourcesService.openedDataSourceId = 0;
    this.dataSourcesService.openedDataSourceSheetIndex = 0;

    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }

    this.dataSourcesService.CreateDataSource(name, DatasourceSharedType.Personal).subscribe(result => {
      this.dataSourcesService.isLoading = false;
      const children = [];

      result.tables.forEach(sheet => {
        children.push({
          id: Math.floor(Math.random() * (9999999 - 1111111)) + 1111111,
          index: sheet.id,
          isDatasource: false,
          name: sheet.name,
          children: [],
        });
      });

      this.communicationService.Event.Editor.DataSource.$dataSourceUploaded.emit({
        id: result.id,
        isDatasource: true,
        name: result.name,
        children: children,
      });

      this.dataSourcesService.openedDataSourceId = result.id;
      this.communicationService.Event.Editor.DataSource.$dataSourceUploadState.emit(false);

      this.spreadsheetService.clearFlexSheet();
      this.spreadsheetService.wName = name;
      this.flexSheet.addUnboundSheet('Sheet1', 120, 60);
      this.spreadsheetService.openedSheets = [0];
      this.spreadsheetService.SetSheetHash(0);
      this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoaded.emit(false);
      setTimeout(() => {
        this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoaded.emit(true);
      }, 300);
      setTimeout(() => {
        this.creatingDataSource = false;
      }, 1000);
    });
  }

  save(params: any[]) {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    const sheets = [];

    // console.log('openedSheets', this.spreadsheetService.openedSheets);

    this.spreadsheetService.setSavingChanges(true);
    this.spreadsheetService.openedSheets.forEach(index => {
      const sheetData = this.spreadsheetService.GetCellsData(index);
      // console.log(sheetData.hash);
      // console.log(this.spreadsheetService.sheetHash);

      if (
        (this.spreadsheetService.sheetHash[index] && sheetData.hash !== this.spreadsheetService.sheetHash[index].initHash) ||
        this.rowColumnChanges[index]
      ) {
        sheets.push({
          collection: this.flexSheet.sheets[index].name,
          cells: sheetData.data,
          changes: this.rowColumnChanges[index],
        });
      }
    });

    if (sheets.length > 0) {
      this.spreadsheetService.sheetHash = {};
      this.spreadsheetService.openedSheets = [this.flexSheet.selectedSheetIndex];
      this.spreadsheetService.SetSheetHash(this.flexSheet.selectedSheetIndex);
      this.dataSourcesService.SaveSheetChanges(sheets).subscribe(result => {
        this.spreadsheetService.setSavingChanges(false);
        this.snackerService.ShowMessageOnBottom('Workbook saved!', 'save', null, true);
        this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetSaved.emit(true);
        this.rowColumnChanges = {};
        this.flexSheet.refresh();
      });
    } else {
      this.spreadsheetService.setSavingChanges(false);
    }
  }

  download(params: any[]) {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    this.snackerService.ShowMessageOnBottom('Downloading Spreadsheet...', 'downloading');

    if (this.spreadsheetService.SpreadSheetContainsUnsavedChanges()) {
      this.snackerService.ShowMessageOnBottom('Spreadsheet contains unsaved changes', 'save_as');
    } else {
      this.spreadsheetService.DownloadDataSourceFile(this.dataSourcesService.openedDataSourceId);
    }
  }

  refresh() {
    this.spreadsheetService.refreshOpenedSpreadsheet();
  }

  applyBoldStyle() {
    if (this.flexSheet) {
      this.flexSheet.applyCellsStyle({
        fontWeight: this.selectionFormatState.isBold ? 'none' : 'bold',
      });
      this.selectionFormatState.isBold = !this.selectionFormatState.isBold;

      this.spreadsheetService.setState(this.selectionFormatState);
    }
  }

  applyColorStyle(style: any) {
    if (this.flexSheet) {
      this.flexSheet.applyCellsStyle(style);
    }
  }

  applyBorderStyle() {
    if (this.flexSheet) {
      this.flexSheet.applyCellsStyle({
        color: 'red',
        borderTopColor: 'black',
        borderBottomColor: 'black',
        borderLeftColor: 'black',
        borderRightColor: 'black',
        borderTopStyle: 'solid',
        borderBottomStyle: 'solid',
        borderLeftStyle: 'solid',
        borderRightStyle: 'solid',
        borderTopWidth: '1px',
        borderBottomWidth: '1px',
        borderLeftWidth: '1px',
        borderRightWidth: '1px',
      });
    }
  }

  applyItalicStyle() {
    if (this.flexSheet) {
      this.flexSheet.applyCellsStyle({
        fontStyle: this.selectionFormatState.isItalic ? 'none' : 'italic',
      });
      this.selectionFormatState.isItalic = !this.selectionFormatState.isItalic;

      this.spreadsheetService.setState(this.selectionFormatState);
    }
  }

  applyUnderlineStyle() {
    if (this.flexSheet) {
      this.flexSheet.applyCellsStyle({
        textDecoration: this.selectionFormatState.isUnderline ? 'none' : 'underline',
      });
      this.selectionFormatState.isUnderline = !this.selectionFormatState.isUnderline;

      this.spreadsheetService.setState(this.selectionFormatState);
    }
  }

  applyFontFamily(font: string) {
    if (this.flexSheet) {
      this.flexSheet.applyCellsStyle(
        {
          fontFamily: font,
        },
        this.flexSheet.sheets[this.flexSheet.selectedSheetIndex].selectionRanges
      );

      this.flexSheet.refreshRange(this.flexSheet.selection);
    }
  }

  applyFontSize(fontSize: string) {
    if (this.flexSheet) {
      this.flexSheet.applyCellsStyle(
        {
          fontSize: fontSize,
        },
        this.flexSheet.sheets[this.flexSheet.selectedSheetIndex].selectionRanges
      );

      this.flexSheet.refreshRange(this.flexSheet.selection);
    }
  }

  applyTextAlignment(textAlign: string) {
    if (this.flexSheet) {
      this.flexSheet.applyCellsStyle({
        textAlign: textAlign,
      });
      this.selectionFormatState.textAlign = textAlign;

      this.spreadsheetService.setState(this.selectionFormatState);
    }
  }

  applyMergeCells() {
    if (this.flexSheet) {
      this.flexSheet.mergeRange();
      this.selectionFormatState = this.flexSheet.getSelectionFormatState();
      this.spreadsheetService.setState(this.selectionFormatState);
    }
  }

  draggingColumn(event) {
    // console.log(event);
  }

  SetProductionMode() {
    if (this.spreadsheetService.productionDatasourceOpen) {
      this.flexSheet.frozenRows = 1;
    } else {
      this.flexSheet.frozenRows = 0;
    }
  }

  SetSpecialMode() {
    if (this.spreadsheetService.editorDbMode) {
      this.flexSheet.headersVisibility = wjcGrid.HeadersVisibility.None;
      this.flexSheet.frozenRows = 1;
    } else {
      this.flexSheet.headersVisibility = wjcGrid.HeadersVisibility.All;
      this.flexSheet.frozenRows = 0;
    }
  }

  addFunctionToFlexSheet(functionName: string, f: any, description = 'External function added') {
    if (this.flexSheet) {
      this.flexSheet.addFunction(
        functionName,
        (...params: any[][][]) => {
          const result = (<any>f).apply(null, params)[0][0];
          // console.log('external function', functionName, params, result);
          // console.log('result', result);
          return result;
        },
        description
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private addProperties(obj, newObj) {
    Object.keys(obj).forEach((key, i) => {
      let newKey = key;
      if (key.substr(0, 1) === '_') {
        newKey = key.substr(1, key.length - 1);
      }
      if (this.isObject(obj[key])) {
        newObj[newKey] = {};
        this.addProperties(obj[key], newObj[newKey]);
      }
      if (Array.isArray(obj[key])) {
        newObj[newKey] = [];
        obj[key].forEach(element => {
          if (this.isObject(element)) {
            newObj[newKey].push({});
          }
          if (Array.isArray(element)) {
            newObj[newKey].push([]);
          }
          this.addProperties(element, newObj[newKey][newObj[newKey].length - 1]);
        });
      }
      newObj[newKey] = obj[key];
    });
  }

  private isObject(variable) {
    if (Object.prototype.toString.call(variable) === '[object]') {
      return true;
    }
    return false;
  }

  private _isCellBottomRight(flexSheet, e) {
    const ht = flexSheet.hitTest(e);
    // console.log(ht);
    if (ht.cellType === wjcGrid.CellType.Cell && ht.edgeBottom && ht.edgeRight) {
      return true;
    } else {
      return false;
    }
  }

  private getSelectedCellsData(isHeaderSelected = false) {
    const dataSourceData = new DataSource();
    console.log('selection', this.flexSheet.selection);

    const finalColIdx = this.flexSheet.selection.rightCol + 1;
    const finalRowIdx = this.flexSheet.selection.bottomRow + 1;
    dataSourceData.dataSourceId = this.dataSourcesService.openedDataSourceId;
    dataSourceData.dataSourceName = this.spreadsheetService.wName;
    dataSourceData.collection = this.flexSheet.selectedSheet.name.replace('&amp;', '&');
    dataSourceData.firstColIndex = this.flexSheet.selection.leftCol;
    dataSourceData.firstRowIndex = isHeaderSelected ? 0 : this.flexSheet.selection.topRow;
    dataSourceData.lastColIndex = finalColIdx - 1;
    dataSourceData.lastRowIndex = isHeaderSelected ? 0 : finalRowIdx - 1;
    dataSourceData.cols = finalColIdx - this.flexSheet.selection.leftCol;
    dataSourceData.rows = finalRowIdx - this.flexSheet.selection.topRow;
    dataSourceData.dataSourceType = DatasourceType.Spreadsheet;
    dataSourceData.range = isHeaderSelected
      ? wjcGridSheet.FlexSheet.convertNumberToAlpha(this.flexSheet.selection.leftCol) +
        ':' +
        wjcGridSheet.FlexSheet.convertNumberToAlpha(dataSourceData.lastColIndex)
      : wjcGridSheet.FlexSheet.convertNumberToAlpha(this.flexSheet.selection.leftCol) +
        (this.flexSheet.selection.topRow + 1) +
        ':' +
        wjcGridSheet.FlexSheet.convertNumberToAlpha(dataSourceData.lastColIndex) +
        (dataSourceData.lastRowIndex + 1);

    dataSourceData.dataItems = [];

    let row = this.flexSheet.selection.topRow;
    for (let colIdx = this.flexSheet.selection.leftCol; colIdx < finalColIdx; colIdx++) {
      for (let rowIdx = this.flexSheet.selection.topRow; rowIdx < finalRowIdx; rowIdx++) {
        row = rowIdx;
        let dragDataItem: { row: number; col: number; value: any };
        let data = this.flexSheet.getCellData(rowIdx, colIdx, false);
        data =
          this.spreadsheetService.dataSourceTypeOpened !== DatasourceType.Spreadsheet && this.spreadsheetService.editorDbMode
            ? this.spreadsheetService.apiDataElementsOpened[data.toString().toLowerCase().replace(/ /g, '')]
            : data;
        dragDataItem = {
          row: rowIdx,
          col: colIdx,
          value: data,
        };
        dataSourceData.dataItems.push(dragDataItem);
      }
    }

    console.log(this.spreadsheetService.dataSourceOpened);

    dataSourceData.isTemplate = this.spreadsheetService.dataSourceOpened ? this.spreadsheetService.dataSourceOpened.isTemplate : '';
    dataSourceData.context = `${dataSourceData.dataSourceName}${Constants.ContextSeparator}${dataSourceData.collection}${Constants.ContextSeparator}${dataSourceData.range}`;
    return dataSourceData;
  }

  private _updateSelection(sel: wjcGrid.CellRange) {
    const sheetSelection = new SheetSelection();
    const flexSheet = this.flexSheet;
    const row = flexSheet.rows[sel.row];
    const rowCnt = flexSheet.rows.length;
    const colCnt = flexSheet.columns.length;
    let r;
    let c;
    let cellStyle;

    // this._updatingSelection = true;
    if (sel.row > -1 && sel.col > -1 && rowCnt > 0 && colCnt > 0 && sel.col < colCnt && sel.col2 < colCnt && sel.row < rowCnt && sel.row2 < rowCnt) {
      r = sel.row >= rowCnt ? rowCnt - 1 : sel.row;
      c = sel.col >= colCnt ? colCnt - 1 : sel.col;
      sheetSelection.content = flexSheet.getCellData(r, c, true);
      sheetSelection.position = wjcGridSheet.FlexSheet.convertNumberToAlpha(sel.col) + (sel.row + 1);
      cellStyle = flexSheet.selectedSheet.getCellStyle(sel.row, sel.col);
      // if (cellStyle) {
      //   this.cboFontName.selectedIndex = this._checkFontfamily(
      //     cellStyle.fontFamily
      //   );
      //   this.cboFontSize.selectedIndex = this._checkFontSize(
      //     cellStyle.fontSize
      //   );
      // } else {
      //   this.cboFontName.selectedIndex = 0;
      //   this.cboFontSize.selectedIndex = 5;
      // }

      if (sel.col !== -1 && sel.col2 !== -1 && sel.row !== -1 && sel.row2 !== -1) {
        sheetSelection.cellRange =
          wjcGridSheet.FlexSheet.convertNumberToAlpha(sel.leftCol) +
          (sel.topRow + 1) +
          ':' +
          wjcGridSheet.FlexSheet.convertNumberToAlpha(sel.rightCol) +
          (sel.bottomRow + 1);
      }
    } else {
      sheetSelection.content = '';
      sheetSelection.position = '';
      sheetSelection.cellRange = '';
    }

    this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetSelection.emit(sheetSelection);
    // this._updatingSelection = false;
  }

  private createFileFromBase64String(base64: string, name: string) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const tmpFile = new File([blob], name, {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    return tmpFile;
  }

  private addFormulaJsToFlexSheet(fSheet: wjcGridSheet.FlexSheet) {
    const flexsheetFormulas = [
      'ABS',
      'ACOS',
      'AND',
      'ASIN',
      'ATAN',
      'ATAN2',
      'AVERAGE',
      'CEILING',
      'CHAR',
      'CHOOSE',
      'CODE',
      'COLUMN',
      'COLUMNS',
      'CONCATENATE',
      'COS',
      'COUNT',
      'COUNTA',
      'COUNTBLANK',
      'COUNTIF',
      // 'COUNTIFS',
      'DATE',
      'DATEDIFF',
      'DAY',
      'DCOUNT',
      'EXP',
      'FALSE',
      'FIND',
      'FLOOR',
      'HLOOKUP',
      'HOUR',
      'IF',
      // 'INDEX',
      'LEFT',
      'LEN',
      'LN',
      'LOWER',
      'MAX',
      'MID',
      'MIN',
      'MOD',
      'MONTH',
      'NOT',
      'NOW',
      'OR',
      'PI',
      'POWER',
      'PRODUCT',
      'PROPER',
      'RAND',
      'RANK',
      'RATE',
      'REPLACE',
      'REPT',
      'RIGHT',
      'ROUND',
      'ROUNDDOWN',
      'ROUNDUP',
      'ROW',
      'ROWS',
      'SEARCH',
      'SIN',
      'SQRT',
      'STDEV',
      'STDEVP',
      'SUBSTITUTE',
      'SUBTOTAL',
      'SUM',
      'SUMIF',
      'SUMIFS',
      'SUMPRODUCT',
      'TAN',
      'TEXT',
      'TIME',
      'TODAY',
      'TRIM',
      'TRUE',
      'TRUNC',
      'UPPER',
      'VALUE',
      'VAR',
      'VARP',
      'YEAR',
    ];

    console.log(formulajs);
    Object.entries(formulajs).forEach(([keyIndex, f]) => {
      if (!flexsheetFormulas.includes(keyIndex)) {
        this.addFunctionToFlexSheet(keyIndex, f, 'FormulaJs Function');
      }
    });

    // this.flexSheet.unknownFunction.addHandler(
    //   (sender: any, e: wjcGridSheet.UnknownFunctionEventArgs) => {
    //     // console.log(this.flexSheet.evaluate(e.funcName), e);
    //     // console.log('unknownFunction', e);
    //     // e.value = 'unknownFunction';
    //   }
    // );
  }
}
