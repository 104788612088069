import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { WjColorPicker } from '@grapecity/wijmo.angular2.input';
import { FileUploader } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { ApiDataSourcesService } from '../../core/services/api-data-sources.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { DatasourceSharedType } from '../../shared/enums/datasource-shared-type.enum';
import { CommunicationService } from '../../shared/services/communication.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { WorkAreaService } from '../../workarea/workarea.service';
import { SheetSelection } from '../models/sheet-selection';
import { SpreadsheetService } from '../spreadsheet.service';

@Component({
  selector: 'spreadsheet-ribbon',
  templateUrl: './spreadsheet-ribbon.component.html',
  styleUrls: ['./spreadsheet-ribbon.component.scss'],
})
export class SpreadsheetRibbonComponent implements OnInit, OnDestroy {
  @Output()
  ribbonClicked = new EventEmitter<any>();
  @ViewChild('colorPicker', { static: true })
  colorPicker: WjColorPicker;
  selectionFormatState: any = {};
  selection = new SheetSelection();
  saveAsName = '';
  isSaving = false;
  uploader: FileUploader = new FileUploader({});
  workBookName = '';
  importFile: any;
  userId: number;
  userCompanyId: number;
  fontSizes = ['10', '12', '14', '18', '24', '36', '48', '52', '64', '80', '100', '120'];
  fontFamilies = [
    'Georgia',
    'serif',
    'Palatino Linotype',
    'Book Antiqua',
    'Palatino',
    'Times New Roman',
    'Times',
    'Arial',
    'Helvetica',
    'sans-serif',
    'Arial Black',
    'Gadget',
    'Avant Garde',
    'Garamond',
    'Century Schoolbook',
    'Bookman',
    'Comic Sans MS',
    'cursive',
    'MS Sans Serif',
    'San Francisco',
    'Impact',
    'Segoe UI',
    'Charcoal',
    'Lucida Sans Unicode',
    'Lucida Grande',
    'Tahoma',
    'Trebuchet MS',
    'Verdana',
    'Geneva',
    'Courier New',
    'Courier',
    'monospace',
    'Lucida //console',
    'Monaco',
  ].sort();
  ableToDownload = false;
  debug = false;
  private _applyFillColor = false;
  private subscriptions: Subscription;

  constructor(
    public spreadsheetService: SpreadsheetService,
    public dataSourcesService: ApiDataSourcesService,
    public workareaService: WorkAreaService,
    private snackerService: SnackerService,
    public clientStorageService: ClientStorageService,
    private communicationService: CommunicationService,
    private localStorageService: LocalStorageService
  ) {
    this.debug = this.localStorageService.IsDebug();
    this.subscriptions = this.spreadsheetService.getState().subscribe(selectionFormatState => {
      this.selectionFormatState = selectionFormatState;
    });

    this.subscriptions.add(
      this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetSelection.subscribe(selection => {
        console.log('=event='); // console.log('$SpreadsheetSelection');
        this.selection = selection;
        this.communicationService.Event.System.App.$RefreshUI.emit(true);
      })
    );
    this.subscriptions.add(
      this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetReloaded.subscribe(selection => {
        console.log('=event=');
        this.SetDownloadPermission();
      })
    );
    this.userId = clientStorageService.getUserId();
    this.userCompanyId = clientStorageService.getCompanyId();
  }

  ngOnInit() {
    this.spreadsheetService.savingChanges.subscribe(state => {
      this.isSaving = null;
      this.isSaving = state;
    });

    setTimeout(() => {
      this.importFile = document.getElementById('importSpreadsheetFile');
      this.importFile.addEventListener('change', () => {
        this.loadWorkbook();
      });

      this.SetDownloadPermission();
    }, 500);
  }

  SetDownloadPermission() {
    const datasourceInfo = this.spreadsheetService.ObtainDatasourceInfo();

    if (datasourceInfo) {
      this.ableToDownload =
        this.userId === datasourceInfo.ownerId ||
        datasourceInfo.sharedType === DatasourceSharedType.Global ||
        (datasourceInfo.sharedType === DatasourceSharedType.Company && datasourceInfo.companyId === this.userCompanyId);
    }
  }

  SelectionDataChanged(event: any) {
    if (this.spreadsheetService.flexSheet.selection.row < 0 || this.spreadsheetService.flexSheet.selection.col < 0) {
      this.snackerService.ShowMessageOnBottom('Please select a cell');
    } else {
      this.spreadsheetService.flexSheet.setCellData(
        this.spreadsheetService.flexSheet.selection.row,
        this.spreadsheetService.flexSheet.selection.col,
        event.target.value
      );
    }
  }

  CreateWorkBook() {
    if (this.workBookName !== '') {
      this.sendCommand('newBook', [this.workBookName]);
      this.workBookName = '';
    }
  }

  ToggleExpand() {
    this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoaded.emit(false);
    setTimeout(() => {
      this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoaded.emit(true);
    }, 300);
    
    const { dataSourceId, filename, sheetIndex, production, blob } = this.spreadsheetService.spreadsheetData;
    this.spreadsheetService.LoadDataSource(dataSourceId, filename, sheetIndex, production, blob, false);
    
    if (this.workareaService.sheetAreaSize > 70) {
      this.workareaService.sheetAreaSize = 25;
      this.workareaService.canvasAreaSize = 75;
    } else {
      this.workareaService.sheetAreaSize = 100;
      this.workareaService.canvasAreaSize = 0;
    }
  }

  sendCommand(action: string, params?: any[]) {
    if (action === 'save' && params[1] === 'saveAs') {
      if (!this.validate()) {
        this.snackerService.ShowMessageOnBottom('The file name should have this extension: ".xlsx"');
        return;
      } else {
        this.saveAsName = '';
      }
    }
    this.command({ methodName: action, params: params });
  }

  validate() {
    const fileExt = this.saveAsName.split('.');
    if (fileExt[1] && fileExt[1] === 'xlsx') {
      return true;
    }
    return false;
  }

  colorPickerInit(colorPicker: WjColorPicker) {
    const self = this;
    const ua = window.navigator.userAgent;
    let blurEvt: string;

    if (colorPicker) {
      // if the browser is firefox, we should bind the blur event. (TFS #124387)
      // if the browser is IE, we should bind the focusout event. (TFS #124500)
      blurEvt = /firefox/i.test(ua) ? 'blur' : 'focusout';
      // Hide the color picker control when it lost the focus.
      colorPicker.hostElement.addEventListener(blurEvt, () => {
        setTimeout(() => {
          if (!colorPicker.containsFocus()) {
            self._applyFillColor = false;
            colorPicker.hostElement.style.display = 'none';
          }
        }, 0);
      });

      // Initialize the value changed event handler for the color picker control.
      colorPicker.valueChanged.addHandler(() => {
        if (self._applyFillColor) {
          this.sendCommand('applyColorStyle', [{ backgroundColor: colorPicker.value }]);
          // self.flexSheet.applyCellsStyle({ backgroundColor: colorPicker.value });
        } else {
          this.sendCommand('applyColorStyle', [{ color: colorPicker.value }]);
          // self.flexSheet.applyCellsStyle({ color: colorPicker.value });
        }
      });
    }
  }

  showColorPicker(e, isFillColor) {
    const offset = this._cumulativeOffset(e.target);

    if (this.colorPicker) {
      this.colorPicker.hostElement.style.display = 'inline';
      this.colorPicker.hostElement.style.left = offset.left + 'px';
      this.colorPicker.hostElement.style.top = offset.top + e.target.clientHeight + 2 + 'px';
      this.colorPicker.hostElement.focus();
    }

    this._applyFillColor = isFillColor;
  }

  FontFamilyChanged(e: any) {
    this.sendCommand('applyFontFamily', [e.target.value]);
  }

  FontSizeChanged(e: any) {
    this.sendCommand('applyFontSize', [e.target.value]);
  }

  Upload() {
    this.importFile.click();
  }

  loadWorkbook() {
    let reader;
    const file = this.importFile.files[0];
    // console.log(file);

    if (file) {
      reader = new FileReader();
      reader.onload = e => {
        this.spreadsheetService.flexSheet.loadAsync(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  OpenFile() {
    document.getElementById('excelFileSelector').click();
  }

  excelFileSelected(eventData: any[]) {
    const file = eventData[0];
    this.spreadsheetService.openExcelFile(file);
  }

  // Get the absolute position of the dom element.
  private _cumulativeOffset(element) {
    let top = 0,
      left = 0;

    do {
      top += element.offsetTop || 0;
      left += element.offsetLeft || 0;
      element = element.offsetParent;
    } while (element);

    return {
      top: top,
      left: left,
    };
  }

  private command(action: any) {
    // console.log('ribbon.component -> action: ' + action);
    this.ribbonClicked.emit(action);
  }
}
