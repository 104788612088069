import { ComponentRef, Injectable } from '@angular/core';
import { DraggableWindowComponent } from '../components/draggable-window/draggable-window.component';
import { DraggableWindow } from '../draggable-window';

@Injectable({
  providedIn: 'root',
})
export class DraggableWindowManagerService {
  windows: ComponentRef<DraggableWindowComponent>[] = [];
  WindowsComponents = {};
  openedWindows = [];
  
  constructor() {
  }
  
  
  ShowWindowAndStore(window: DraggableWindow) {
    window.Show();
    this.WindowsComponents[window.Id] = window.component;
  }
  
  show(window: ComponentRef<DraggableWindowComponent>) {
    this.windows.push(window);
    window.instance.appear();
  }
  
  hide(window: ComponentRef<DraggableWindowComponent>) {
    const windowIdx = this.windows.findIndex((w) => w === window);
    if (windowIdx !== -1) {
      this.windows.splice(windowIdx, 1);
    }
    window.instance.ngOnDestroy();
    window.destroy();
  }
  
  hideAll() {
    for (const window of this.windows) {
      window.destroy();
    }
    this.windows.length = 0;
  }
}
