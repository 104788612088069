import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { ApiAdminService } from '../../core/services/api-admin.service';
import { ToolsService } from '../../core/services/tools.service';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-mobile-layouts-panel',
  templateUrl: './mobile-layouts-panel.component.html',
  styleUrls: ['./mobile-layouts-panel.component.scss'],
})
export class MobileLayoutsPanelComponent implements OnInit {
  initialDevice = 'iPhone XR';
  devicesResolutionsList: {
    Device: string;
    Product: string;
    Height: number;
    Width: number;
    WidthPadding: number;
    HeightPadding: number;
    PixelHeight: number;
    PixelWidth: number;
    DeviceTemplate: string;
  }[] = [];
  ResolutionsList: {
    Devices: string;
    Product: string;
    Height: number;
    Width: number;
    WidthPadding: number;
    HeightPadding: number;
    PixelHeight: number;
    PixelWidth: number;
    DeviceTemplate: string;
  }[] = [];

  deviceSelected: {
    Device: string;
    Product: string;
    Height: number;
    Width: number;
    WidthPadding: number;
    HeightPadding: number;
    PixelHeight: number;
    PixelWidth: number;
    DeviceTemplate: string;
  } = null;

  subscriptions = new Subscription();

  // region DEVICES

  devices = [
    {
      Device: 'Tablet',
      Product: 'iPad Mini',
      Height: 1024,
      Width: 768,
      PixelHeight: 1024,
      PixelWidth: 768,
      OperatingSystem: 'iOS',
      WidthPadding: 25,
      HeightPadding: 90,
      DeviceTemplate: 'ipad silver',
    },
    {
      Device: 'Phone',
      Product: 'iPhone 5',
      Height: 568,
      Width: 320,
      PixelHeight: 1136,
      PixelWidth: 640,
      OperatingSystem: 'iOS',
      WidthPadding: 22,
      HeightPadding: 105,
      DeviceTemplate: 'iphone5s silver',
    },
    {
      Device: 'Other',
      Product: 'iPod Touch',
      Height: 568,
      Width: 320,
      PixelHeight: 1136,
      PixelWidth: 640,
      OperatingSystem: 'iOS',
      WidthPadding: 22,
      HeightPadding: 105,
      DeviceTemplate: 'iphone5s silver',
    },
    {
      Device: 'Tablet',
      Product: 'Samsung Galaxy Tab 10',
      Height: 1280,
      Width: 800,
      PixelHeight: 1280,
      PixelWidth: 800,
      OperatingSystem: 'Android',
      WidthPadding: 15,
      HeightPadding: 50,
      DeviceTemplate: 'nexus5',
    },
    {
      Device: 'Phone',
      Product: 'iPhone 6/6S',
      Height: 667,
      Width: 375,
      PixelHeight: 1334,
      PixelWidth: 750,
      OperatingSystem: 'iOS',
      WidthPadding: 24,
      HeightPadding: 105,
      DeviceTemplate: 'iphone8',
    },
    {
      Device: 'Phone',
      Product: 'iPhone 7',
      Height: 667,
      Width: 375,
      PixelHeight: 1334,
      PixelWidth: 750,
      OperatingSystem: 'iOS',
      WidthPadding: 24,
      HeightPadding: 105,
      DeviceTemplate: 'iphone8',
    },
    {
      Device: 'Phone',
      Product: 'iPhone 8',
      Height: 667,
      Width: 375,
      PixelHeight: 1334,
      PixelWidth: 750,
      OperatingSystem: 'iOS',
      WidthPadding: 24,
      HeightPadding: 105,
      DeviceTemplate: 'iphone8',
    },
    {
      Device: 'Tablet',
      Product: 'Chromebook Pixel',
      Height: 850,
      Width: 1280,
      PixelHeight: 1700,
      PixelWidth: 2560,
      OperatingSystem: 'Android',
      WidthPadding: 15,
      HeightPadding: 50,
      DeviceTemplate: 'nexus5',
    },
    {
      Device: 'Phone',
      Product: 'iPhone XR',
      Height: 896,
      Width: 414,
      PixelHeight: 1792,
      PixelWidth: 828,
      OperatingSystem: 'iOS',
      WidthPadding: 26,
      HeightPadding: 26,
      DeviceTemplate: 'iphone-x',
    },
    {
      Device: 'Phone',
      Product: 'Google Pixel',
      Height: 732,
      Width: 412,
      PixelHeight: 1920,
      PixelWidth: 1080,
      OperatingSystem: 'Android',
      WidthPadding: 15,
      HeightPadding: 50,
      DeviceTemplate: 'nexus5',
    },
    {
      Device: 'Phone',
      Product: 'iPhone 6 Plus/6S Plus',
      Height: 736,
      Width: 414,
      PixelHeight: 1920,
      PixelWidth: 1080,
      OperatingSystem: 'iOS',
      WidthPadding: 26,
      HeightPadding: 112,
      DeviceTemplate: 'iphone8plus black',
    },
    {
      Device: 'Phone',
      Product: 'iPhone 7 Plus',
      Height: 736,
      Width: 414,
      PixelHeight: 1920,
      PixelWidth: 1080,
      OperatingSystem: 'iOS',
      WidthPadding: 26,
      HeightPadding: 112,
      DeviceTemplate: 'iphone8plus black',
    },
    {
      Device: 'Phone',
      Product: 'iPhone 8 Plus',
      Height: 736,
      Width: 414,
      PixelHeight: 1920,
      PixelWidth: 1080,
      OperatingSystem: 'iOS',
      WidthPadding: 26,
      HeightPadding: 112,
      DeviceTemplate: 'iphone8plus black',
    },
    {
      Device: 'Phone',
      Product: 'Nexus 5X',
      Height: 732,
      Width: 412,
      PixelHeight: 1920,
      PixelWidth: 1080,
      OperatingSystem: 'Android',
      WidthPadding: 15,
      HeightPadding: 50,
      DeviceTemplate: 'nexus5',
    },
    {
      Device: 'Phone',
      Product: 'One Plus 3',
      Height: 853,
      Width: 480,
      PixelHeight: 1920,
      PixelWidth: 1080,
      OperatingSystem: 'Android',
      WidthPadding: 15,
      HeightPadding: 50,
      DeviceTemplate: 'nexus5',
    },
    {
      Device: 'Tablet',
      Product: 'Nexus 7 (2013)',
      Height: 960,
      Width: 600,
      PixelHeight: 1920,
      PixelWidth: 1200,
      OperatingSystem: 'Android',
      WidthPadding: 15,
      HeightPadding: 50,
      DeviceTemplate: 'nexus5',
    },
    {
      Device: 'Tablet',
      Product: 'iPad Air 1 & 2',
      Height: 1024,
      Width: 768,
      PixelHeight: 2048,
      PixelWidth: 1536,
      OperatingSystem: 'iOS',
      WidthPadding: 25,
      HeightPadding: 90,
      DeviceTemplate: 'ipad silver',
    },
    {
      Device: 'Tablet',
      Product: 'iPad Mini 2 & 3',
      Height: 1024,
      Width: 768,
      PixelHeight: 2048,
      PixelWidth: 1536,
      OperatingSystem: 'iOS',
      WidthPadding: 25,
      HeightPadding: 90,
      DeviceTemplate: 'ipad silver',
    },
    {
      Device: 'Tablet',
      Product: 'iPad Third & Fourth Generation',
      Height: 1024,
      Width: 768,
      PixelHeight: 2048,
      PixelWidth: 1536,
      OperatingSystem: 'iOS',
      WidthPadding: 25,
      HeightPadding: 90,
      DeviceTemplate: 'ipad silver',
    },
    {
      Device: 'Tablet',
      Product: 'Nexus 9',
      Height: 1024,
      Width: 768,
      PixelHeight: 2048,
      PixelWidth: 1536,
      OperatingSystem: 'Android',
      WidthPadding: 15,
      HeightPadding: 50,
      DeviceTemplate: 'nexus5',
    },
    {
      Device: 'Phone',
      Product: 'Google Pixel 3',
      Height: 824,
      Width: 412,
      PixelHeight: 2160,
      PixelWidth: 1080,
      OperatingSystem: 'Android',
      WidthPadding: 15,
      HeightPadding: 50,
      DeviceTemplate: 'nexus5',
    },
    {
      Device: 'Phone',
      Product: 'iPhone X',
      Height: 812,
      Width: 375,
      PixelHeight: 2436,
      PixelWidth: 1125,
      OperatingSystem: 'iOS',
      WidthPadding: 26,
      HeightPadding: 26,
      DeviceTemplate: 'iphone-x',
    },
    {
      Device: 'Phone',
      Product: 'iPhone XS',
      Height: 812,
      Width: 375,
      PixelHeight: 2436,
      PixelWidth: 1125,
      OperatingSystem: 'iOS',
      WidthPadding: 26,
      HeightPadding: 26,
      DeviceTemplate: 'iphone-x',
    },
    {
      Device: 'Phone',
      Product: 'Google Pixel 2 XL',
      Height: 732,
      Width: 412,
      PixelHeight: 2560,
      PixelWidth: 1440,
      OperatingSystem: 'Android',
      WidthPadding: 15,
      HeightPadding: 50,
      DeviceTemplate: 'nexus5',
    },
    {
      Device: 'Phone',
      Product: 'Google Pixel XL',
      Height: 732,
      Width: 412,
      PixelHeight: 2560,
      PixelWidth: 1440,
      OperatingSystem: 'Android',
      WidthPadding: 15,
      HeightPadding: 50,
      DeviceTemplate: 'nexus5',
    },
    {
      Device: 'Phone',
      Product: 'LG G5',
      Height: 853,
      Width: 480,
      PixelHeight: 2560,
      PixelWidth: 1440,
      OperatingSystem: 'Android',
      WidthPadding: 15,
      HeightPadding: 50,
      DeviceTemplate: 'nexus5',
    },
    {
      Device: 'Phone',
      Product: 'Nexus 6P',
      Height: 732,
      Width: 412,
      PixelHeight: 2560,
      PixelWidth: 1440,
      OperatingSystem: 'Android',
      WidthPadding: 15,
      HeightPadding: 50,
      DeviceTemplate: 'nexus5',
    },
    {
      Device: 'Phone',
      Product: 'Samsung Galaxy Note 5',
      Height: 853,
      Width: 480,
      PixelHeight: 2560,
      PixelWidth: 1440,
      OperatingSystem: 'Android',
      WidthPadding: 10,
      HeightPadding: 45,
      DeviceTemplate: 'note8',
    },
    {
      Device: 'Phone',
      Product: 'Samsung Galaxy S7',
      Height: 640,
      Width: 360,
      PixelHeight: 2560,
      PixelWidth: 1440,
      OperatingSystem: 'Android',
      WidthPadding: 10,
      HeightPadding: 45,
      DeviceTemplate: 'note8',
    },
    {
      Device: 'Phone',
      Product: 'Samsung Galaxy S7 Edge',
      Height: 640,
      Width: 360,
      PixelHeight: 2560,
      PixelWidth: 1440,
      OperatingSystem: 'Android',
      WidthPadding: 10,
      HeightPadding: 45,
      DeviceTemplate: 'note8',
    },
    {
      Device: 'Phone',
      Product: 'iPhone XS Max',
      Height: 896,
      Width: 414,
      PixelHeight: 2688,
      PixelWidth: 1242,
      OperatingSystem: 'iOS',
      WidthPadding: 26,
      HeightPadding: 26,
      DeviceTemplate: 'iphone-x',
    },
    {
      Device: 'Tablet',
      Product: 'iPad Pro',
      Height: 1366,
      Width: 1024,
      PixelHeight: 2732,
      PixelWidth: 2048,
      OperatingSystem: 'iOS',
      WidthPadding: 25,
      HeightPadding: 90,
      DeviceTemplate: 'ipad silver',
    },
    {
      Device: 'Phone',
      Product: 'Google Pixel 3 XL',
      Height: 847,
      Width: 412,
      PixelHeight: 2960,
      PixelWidth: 1440,
      OperatingSystem: 'Android',
      WidthPadding: 15,
      HeightPadding: 50,
      DeviceTemplate: 'nexus5',
    },
    {
      Device: 'Phone',
      Product: 'Samsung Galaxy Note 9',
      Height: 740,
      Width: 360,
      PixelHeight: 2960,
      PixelWidth: 1440,
      OperatingSystem: 'Android',
      WidthPadding: 10,
      HeightPadding: 45,
      DeviceTemplate: 'note8',
    },
    {
      Device: 'Phone',
      Product: 'Samsung Galaxy S8',
      Height: 740,
      Width: 360,
      PixelHeight: 2960,
      PixelWidth: 1440,
      OperatingSystem: 'Android',
      WidthPadding: 10,
      HeightPadding: 45,
      DeviceTemplate: 'note8',
    },
    {
      Device: 'Phone',
      Product: 'Samsung Galaxy S8+',
      Height: 740,
      Width: 360,
      PixelHeight: 2960,
      PixelWidth: 1440,
      OperatingSystem: 'Android',
      WidthPadding: 10,
      HeightPadding: 45,
      DeviceTemplate: 'note8',
    },
    {
      Device: 'Phone',
      Product: 'Samsung Galaxy S9',
      Height: 740,
      Width: 360,
      PixelHeight: 2960,
      PixelWidth: 1440,
      OperatingSystem: 'Android',
      WidthPadding: 10,
      HeightPadding: 45,
      DeviceTemplate: 'note8',
    },
    {
      Device: 'Phone',
      Product: 'Samsung Galaxy S9+',
      Height: 740,
      Width: 360,
      PixelHeight: 2960,
      PixelWidth: 1440,
      OperatingSystem: 'Android',
      WidthPadding: 10,
      HeightPadding: 45,
      DeviceTemplate: 'note8',
    },
  ];

  // endregion

  constructor(
    public workAreaService: WorkAreaService,
    public cobbleService: CobbleService,
    private adminService: ApiAdminService,
    public toolsService: ToolsService,
    private communicationService: CommunicationService
  ) {}

  ngOnInit() {
    this.ProcessDevices(this.devices);
    // this.adminService.GetSystemValue('DeviceScreenSizes').subscribe(devices => {
    //   this.ProcessDevices(devices);
    // });

    this.subscriptions.add(
      this.communicationService.Event.System.App.$AppLoaded.subscribe(condition => {
        console.log('=event=');
        this.StopTestMobileView();
      })
    );
  }

  ProcessDevices(devices: any[]) {
    this.deviceSelected = devices.find(d => d.Product === this.initialDevice);
    this.SetDeviceTemplate();
    this.devicesResolutionsList = devices;

    devices.forEach(device => {
      const resolution = this.ResolutionsList.find(r => r.Height === device.Height && r.Width === device.Width);
      if (resolution) {
        resolution.Devices = `${resolution.Devices}, ${device.Product}`;
      } else {
        (device as any).Devices = device.Product;
        this.ResolutionsList.push(device as any);
      }
    });
  }

  SetDeviceTemplate() {
    this.workAreaService.mobileLayoutHeight = this.deviceSelected.Height;
    this.workAreaService.mobileLayoutWidth = this.deviceSelected.Width;
    this.workAreaService.mobileDeviceTemplate = this.deviceSelected.DeviceTemplate;
    this.workAreaService.mobileHeightPadding = this.deviceSelected.HeightPadding;
    this.workAreaService.mobileWidthPadding = this.deviceSelected.WidthPadding;
  }

  TestMobileView(event: any, theme = 'light') {
    this.workAreaService.RunMobileTest(true, theme);
    this.RotateMobileEmulator();
  }

  StopTestMobileView() {
    this.workAreaService.mobileEmulatorSize = 'normal';
    this.workAreaService.RunMobileTest(false);
  }

  RotateMobile(portrait = true) {
    if (this.workAreaService.mobilePortrait !== portrait) {
      this.workAreaService.mobilePortrait = portrait;
      this.RotateMobileEmulator();
      this.communicationService.Event.System.App.$RefreshUI.emit(true);

      if (!this.workAreaService.showMobileLayout) {
        this.workAreaService.RefreshMobileTestApp();
      }
    }
  }

  RotateMobileEmulator() {
    this.workAreaService.loadMobileTest = false;

    const emulatorElement = document.getElementById('emulator');
    const iframeElement = document.getElementById('iframeContainer');

    if (emulatorElement) {
      if (this.workAreaService.mobilePortrait) {
        emulatorElement.classList.remove('landscape');
      } else {
        emulatorElement.classList.add('landscape');
      }
    }

    setTimeout(() => {
      if (this.workAreaService.mobilePortrait) {
        if (iframeElement) {
          iframeElement.style.top = this.workAreaService.mobileHeightPadding + 'px';
          iframeElement.style.left = this.workAreaService.mobileWidthPadding + 'px';
        }
      } else {
        if (iframeElement) {
          const templateMobileElement = document.getElementById('templateMobile');
          const centerRotationX = this.workAreaService.mobileLayoutHeight / 2;
          const centerRotationY = this.workAreaService.mobileLayoutWidth / 2;

          const rotetedCoordinates = this.RotateCoordinates(
            +templateMobileElement.style.left.replace('px', ''),
            +templateMobileElement.style.top.replace('px', ''),
            centerRotationX,
            centerRotationY,
            -90
          );

          const rotatedY = rotetedCoordinates[0];
          const rotatedX = rotetedCoordinates[1] - this.workAreaService.mobileLayoutWidth;

          iframeElement.style.top = rotatedY + 'px';
          iframeElement.style.left = `-${rotatedX}px`;
        }
      }

      if (iframeElement) {
        iframeElement.style.height =
          (this.workAreaService.mobilePortrait ? this.workAreaService.mobileLayoutHeight : this.workAreaService.mobileLayoutWidth).toString() + 'px';

        iframeElement.style.width =
          (this.workAreaService.mobilePortrait ? this.workAreaService.mobileLayoutWidth : this.workAreaService.mobileLayoutHeight).toString() + 'px';
      }

      this.workAreaService.loadMobileTest = true;
      this.communicationService.Event.System.App.$RefreshUI.emit(true);
    }, 600);
  }

  HideLayoutsPanel() {
    this.workAreaService.editorPreferences.layoutsPanel = false;
    this.workAreaService.showMobileLayout = true;
    this.communicationService.Event.Editor.Preferences.$PreferenceChange.emit('layoutsPanel');
  }

  RotateCoordinates(x, y, centerx, centery, degrees) {
    const newx = (x - centerx) * Math.cos((degrees * Math.PI) / 180) - (y - centery) * Math.sin((degrees * Math.PI) / 180) + centerx;
    const newy = (x - centerx) * Math.sin((degrees * Math.PI) / 180) + (y - centery) * Math.cos((degrees * Math.PI) / 180) + centery;
    return [newx, newy];
  }

  WorkareaDetection(enable: boolean) {
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(enable);
  }

  SizeEmulatorChange(event: any) {
    // console.log(event);
    if (event) {
      this.workAreaService.mobileEmulatorSize = event;
    }
  }

  RefreshUI() {
    console.log('enter');
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    this.communicationService.Event.System.App.$RefreshUI.emit(true);
  }

  ShowPreviewOptions() {
    document.querySelector('.emulator-preview-options').classList.add('display-options');
  }

  HidePreviewOptions() {
    setTimeout(() => {
      document.querySelector('.emulator-preview-options').classList.remove('display-options');
    }, 500);
  }

  DeviceChanged() {
    this.SetDeviceTemplate();
    this.workAreaService.RefreshMobileTestApp();
    this.RotateMobileEmulator();
    this.communicationService.Event.System.App.$RefreshUI.emit(true);
  }
}
