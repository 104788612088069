import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FieldConfig } from '../../models/field-config.model';

@Component({
  selector: 'app-dynamic-checkbox',
  template: `
      <div class="panel-viewer-body">

          <div style="display: none;"
               class="dynamic-field form-input"
               [formGroup]="group">
              <mat-form-field>
                  <input matInput type="text" [formControlName]="config.name">
              </mat-form-field>
          </div>
          <mat-checkbox (change)="CheckboxChange($event)" [(ngModel)]="!!config.value">{{
              config.label
              }}</mat-checkbox>
      </div>
  `,
  styleUrls: ['./dynamic-checkbox.component.css'],
})
export class DynamicCheckboxComponent implements OnInit {
  config: FieldConfig;
  group: FormGroup;
  
  constructor() {
  }
  
  ngOnInit() {
    // console.log('config', this.config);
  }
  
  CheckboxChange(event: MatCheckboxChange) {
    this.Save(event.checked);
  }
  
  Save(value: boolean) {
    this.group.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    const control = this.group.controls[this.config.name];
    this.group.markAsDirty();
    control.setValue(value);
  }
}
