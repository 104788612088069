import * as IMask from 'imask';
import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class FormattingMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    data?: any,
    dataElementsData?: any,
  ) {
    try {
      const inputMaskData = rule && rule.mask ? rule.mask : '';
      
      data = this.DataActionModifierApply(data, this.Modifier, { inputMaskData });
      
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    } catch (error) {
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  private Modifier(value: string, rule: { inputMaskData: string }): string {
    let option;
    
    if (rule.inputMaskData.includes('$.')) {
      option = {
        lazy: false,
        mask: '$num',
        blocks: {
          num: {
            mask: Number,
            thousandsSeparator: ',',
            radix: '.',
          },
        },
      };
    } else if (rule.inputMaskData.includes('#.')) {
      option = {
        lazy: false,
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            thousandsSeparator: ',',
            radix: '.',
          },
        },
      };
    } else {
      option = {
        mask: rule.inputMaskData,
        lazy: false,
      };
    }
    
    var mask = IMask.createMask(option);
    return mask.resolve(value);
  }
}
