import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { DialogComponent } from './dialog.component';

@Injectable()
export class DialogService {
  constructor(public dialog: MatDialog) {
  }
  
  openDialog(element: any): BehaviorSubject<any> {
    const dialogRef = this.dialog.open(DialogComponent, { data: element });
    dialogRef.afterClosed().subscribe(result => {
      return result;
    });
    return null;
  }
}
