export enum DragType {
  DataElement = 'DataElement',
  Spreadsheet = 'Spreadsheet',
  Event = 'Event',
  Style = 'Style',
  Theme = 'Theme',
  Molecule = 'Molecule',
  View = 'View',
  WorkGroup = 'WorkGroup',
  RepresentativeMolecule = 'RepresentativeMolecule'
}
