import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';
import { BusService } from '../services/bus.service';

declare const window: any;

export class DateMaskingMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId,
    repMoleculeId: string,
    busProcessorKey: string,
    busService: BusService,
  ) {
    try {
      // console.log(`molecule working, date masking`);
    } catch (error) {
      // console.log(error);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
    // elementContext.maskingFunction = false;
    // elementContext.mask = false;
    // elementContext.showMask = false;
    //
    // window.services.savingService
    //   .updateProperties([
    //     {
    //       elementId: elementContext.id,
    //       property: 'maskingFunction',
    //       value: false,
    //       path: '',
    //     },
    //     {
    //       elementId: elementContext.id,
    //       property: 'mask',
    //       value: false,
    //       path: '',
    //     },
    //     {
    //       elementId: elementContext.id,
    //       property: 'showMask',
    //       value: false,
    //       path: '',
    //     },
    //   ])
    //   .subscribe();
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
