import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Edge, Layout, Node, Orientation } from '@swimlane/ngx-graph';
import { JsonEditorOptions } from 'ang-jsoneditor';
import * as shape from 'd3-shape';
import { cloneDeep, debounce, uniq, uniqBy } from 'lodash-es';
import { Subject, Subscription } from 'rxjs';
import { BusService } from '../core/molecular/services/bus.service';
import { ProcessorService } from '../core/molecular/services/processor.service';
import { ToolsService } from '../core/services/tools.service';
import { DagreNodesOnlyLayout } from '../monitoring/customDagreNodesOnly';
import { Constants } from '../shared/constants';
import { DatasourceType } from '../shared/enums/datasource-type.enum';
import { Bus } from '../shared/representative-molecule/interfaces/bus';
import { LeapXLEvent } from '../shared/representative-molecule/interfaces/leapxl-event';
import { IRepresentativeMolecule } from '../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../shared/services/communication.service';
import { DraggableWindowService } from '../shared/services/draggable-window.service';
import { JsonTreeComponent } from '../workarea/json-tree/json-tree.component';
import { WorkAreaService } from '../workarea/workarea.service';

@Component({
  selector: 'app-events-transaction',
  templateUrl: './events-transaction.component.html',
  styleUrls: ['./events-transaction.component.scss'],
  animations: [
    trigger('zoomIn', [
      transition(':enter', [style({ transform: 'scale(0)', opacity: 0 }), animate('100ms', style({ transform: 'scale(1)', opacity: 1 }))]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('100ms', style({ transform: 'translateY(-5%)', opacity: 0 })),
      ]),
    ]),
    trigger('add', [
      transition(':enter', [style({ transform: 'translateY(10%)' }), animate('200ms', style({ transform: 'translateY(0)' }))]),
      // transition(':leave', [
      //   style({ transform: 'translateY(0)', opacity: 1 }),
      //   animate('100ms', style({ transform: 'translateY(-5%)', opacity: 0 }))
      // ])
    ]),
    trigger('logoOut', [
      transition(':leave', [style({ transform: 'scale(1)', opacity: 1 }), animate('200ms', style({ transform: 'scale(13)', opacity: 0 }))]),
    ]),
    trigger('flyInOut', [
      transition('void => fly', [
        animate(400, keyframes([style({ transform: 'translateY(15%)', opacity: 0 }), style({ transform: 'translateY(0)', opacity: 1 })])),
      ]),
    ]),
  ],
})
export class EventsTransactionComponent implements OnInit, OnDestroy {
  @Input()
  viewId: number = null;
  
  @Input()
  repMoleculeId: string = null;
  
  @Input()
  eventName: string = null;
  
  repMoleculeHtmlElements = {};
  subscriptions: Subscription = new Subscription();
  processorRunning = [];
  public nodes: Node[] = [];
  public links: Edge[] = [];
  public graphNodes: Node[] = [];
  public graphLinks: Edge[] = [];
  public editorGraphNodes: Node[] = [];
  public editorGraphLinks: Edge[] = [];
  inDebounce = null;
  public layoutSettings = {
    orientation: Orientation.TOP_TO_BOTTOM,
    marginX: 10,
    marginY: 10,
    edgePadding: 5,
    rankPadding: 50,
    nodePadding: 5,
    // curveDistance: 200,
    // multigraph: true,
    // compound: true
  };
  public curve: any = shape.curveBundle;
  public layout: Layout = new DagreNodesOnlyLayout();
  public editorOptions: JsonEditorOptions;
  startProcessParent = null;
  update$: Subject<boolean> = new Subject();
  zoomToFit$: Subject<boolean> = new Subject();
  center$: Subject<boolean> = new Subject();
  showDataWindow = false;
  locationDataWindowLeft = 100;
  locationDataWindowTop = 100;
  jsonData = {};
  disableClosed = false;
  buildingTransactions = false;
  startProcessTimestamp = null;
  clearOnUserAction = true;
  editorEventsProcessed = {};
  evensDataWindowOpened = [];
  displayProcessDesign = false;
  zoomLevel = 0.6;
  lastZoomLevel = 0.6;
  enableZoom = true;
  minZoomLevel = 0.1;
  maxZoomLevel = 4.0;
  autoCenter = true;
  autoZoom = true;
  startingProcesses = [];
  lastEventReceivedTimestamp = null;
  clearGraphOnResponse = debounce(() => {
    this.ClearGraphData();
  }, 500);
  lastTimeProcessorRun = new Date();
  userActionEvents = ['click', 'keyup', 'option-selected'];
  
  constructor(
    private communicationService: CommunicationService,
    public toolsService: ToolsService,
    private draggableWindowService: DraggableWindowService,
    private processorService: ProcessorService,
    private workAreaService: WorkAreaService,
    private busService: BusService,
    private cobbleService: CobbleService,
  ) {
    const data = this.draggableWindowService.GetData();
    
    if (data) {
      if (data.repMoleculeId) {
        this.repMoleculeId = data.repMoleculeId;
      }
      if (data.viewId) {
        this.viewId = data.viewId;
      }
      if (data.eventName) {
        this.eventName = data.eventName;
      }
      if (data.processDesign !== null && data.processDesign !== undefined) {
        this.displayProcessDesign = data.processDesign;
      } else {
        this.displayProcessDesign = !this.cobbleService.Cobble.running;
      }
    }
  }
  
  ngOnInit() {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.sortObjectKeys = false;
    this.editorOptions.history = false;
    this.editorOptions.mode = 'view';
    this.editorOptions.navigationBar = false;
    this.editorOptions.statusBar = false;
    this.editorOptions.mainMenuBar = false;
    this.editorOptions.expandAll = false;
    
    if (!this.displayProcessDesign) {
      this.subscriptions = this.communicationService.Event.Runtime.MolecularEngine.$ProcessorStartRunning.subscribe((start: IStartProcess) => {
        console.log('=event=');
        this.ProcessorStart(start);
      });
      
      this.subscriptions.add(
        this.communicationService.Event.Runtime.MolecularEngine.$ParticleProcessCompleted.subscribe(
          (done: { key: string; particleId: string; event: string; elementId: any; data: any; endProccess: boolean }) => {
            console.log('=event=');
            this.ParticleProcessedCompleted(done);
          },
        ),
      );
      
      this.subscriptions.add(
        this.communicationService.Event.Runtime.MolecularEngine.$StopProcessor.subscribe(
          (done: { particlesChannel: { bus: Bus; data: any }; repMolecule: IRepresentativeMolecule; key: string; data: any }) => {
            // this.ProcessorStop(done);
          },
        ),
      );
      
      this.subscriptions.add(
        this.communicationService.Event.Runtime.Emulator.$ProcessorStartRunning.subscribe((start: IStartProcess) => {
          console.log('=event=');
          // is user fired
          if (this.userActionEvents.includes(start.event.EventType)) {
            this.ClearGraphData();
          }
          
          // if (((new Date() as any) - (this.lastTimeProcessorRun as any)) > 500) {
          //   this.ClearGraphData();
          // }
          this.ProcessorStart(start);
          this.lastTimeProcessorRun = new Date();
        }),
      );
      
      this.subscriptions.add(
        this.communicationService.Event.Runtime.Emulator.$ParticleProcessCompleted.subscribe(
          (done: { particlesChannel: { bus: Bus; data: any }; repMolecule: IRepresentativeMolecule; key: string; data: any }) => {
            console.log('=event=');
            this.ParticleProcessedCompleted(done);
          },
        ),
      );
      
      this.subscriptions.add(
        this.communicationService.Event.Runtime.Emulator.$StopProcessor.subscribe(
          (done: { particlesChannel: { bus: Bus; data: any }; repMolecule: IRepresentativeMolecule; key: string; data: any }) => {
            console.log('=event=');
            // this.ProcessorStop(done);
          },
        ),
      );
    } else {
      this.subscriptions = this.communicationService.Event.Editor.$ConstructEventsFlow.subscribe(data => {
        this.repMoleculeId = data.repMoleculeId || null;
        this.viewId = data.viewId || null;
        this.eventName = data.eventName || null;
        this.GenerateEventsFlow();
      });
      console.log('=event=');
      this.GenerateEventsFlow();
    }
  }
  
  ParticleProcessedCompleted(done: any) {
    // console.log('$ParticleProcessCompleted');
    
    const process = this.processorRunning.find(pr => pr.busKey === done.key);
    
    if (process && process.particles) {
      const particle = process.particles.find(p => p.particleId === done.particleId);
      particle.data = cloneDeep(done.data);
      particle.completed = true;
    }
  }
  
  ProcessorStart(start: IStartProcess) {
    this.buildingTransactions = true;
    console.log('start', start);
    
    // is user fired
    if (start.event.TriggeredByUser && this.userActionEvents.includes(start.event.EventType)) {
      this.ProcessorStop(null);
    }
    
    if (this.startProcessParent) {
    } else {
      this.CloseDataOpenedWindows();
      this.processorRunning = [];
      this.nodes = [];
      this.links = [];
      this.startProcessParent = start;
      this.startProcessTimestamp = Date.now();
      
      this.graphNodes = this.graphNodes.filter(
        gn => gn.data.nodeId !== this.startProcessParent.event.EventType + this.startProcessParent.event.SourceId,
      );
      this.graphLinks = this.graphLinks.filter(
        gl => gl.data.nodeId !== this.startProcessParent.event.EventType + this.startProcessParent.event.SourceId,
      );
      
      if (this.clearOnUserAction) {
        if (this.startProcessParent && this.userActionEvents.includes(this.startProcessParent.event.EventType)) {
          this.graphNodes = [];
          this.graphLinks = [];
        }
      }
    }
    
    console.log(start);
    
    // console.log('$ProcessorStartRunning');
    const runningProcess = {
      eventName: start.event.EventName,
      eventType: start.event.EventType,
      eventData: start.event.Data,
      eventSourceId: start.event.SourceId,
      busKey: start.busKey,
      busName: start.bus.Name,
      busReceptor: start.bus.Receptor,
      busReceptorData: start.bus.ReceptorData,
      busId: start.bus.id,
      parentBusId: start.event.TriggeredByBusId,
      particles: [],
      repMoleculeName: start.repMolecule.Properties.name,
      repMoleculeType: start.repMolecule.Type,
      repMoleculeId: start.repMolecule.Id,
      repMoleculeIcon: start.repMolecule.Icon,
      processType: 'molecule',
      timestamp: Date.now(),
      timeDifference: this.startProcessTimestamp ? Date.now() - this.startProcessTimestamp : 0,
      nodeId: this.startProcessParent.event.EventType + this.startProcessParent.event.SourceId,
    };
    
    start.bus.Particles.forEach((particle: any) => {
      // console.log(particle);
      runningProcess.particles.push({
        busId: start.bus.id,
        particleId: particle.ParticleId,
        name: particle.DisplayName || particle.DataSourceName || particle.EventName + '-' + particle.SourceId,
        type: particle.ParticleType,
        completed: false,
        showData: false,
        data: null,
      });
    });
    
    this.startingProcesses.push(runningProcess);
    this.processorRunning.push(runningProcess);
    // console.log('running', this.processorRunning);
    
    this.Throttle(
      (func, delay, context) => {
        this.UpdateMolecularProcessFlow(start);
      },
      2000,
      this,
      null,
    );
  }
  
  ProcessorStop(done: any) {
    this.startProcessParent = null;
    this.startProcessTimestamp = null;
  }
  
  GenerateEventsFlow() {
    this.editorEventsProcessed = {};
    this.editorGraphNodes = [];
    this.editorGraphLinks = [];
    
    if (this.viewId) {
      this.GenerateEventFlowForView(this.viewId);
    } else if (this.repMoleculeId) {
      if (this.eventName) {
        this.GenerateEventFlowForEvent(this.eventName, this.repMoleculeId);
      } else {
        const flow = this.GenerateEventFlowForRepresentativeMolecule(this.repMoleculeId);
        
        console.log(flow);
        setTimeout(() => {
          this.editorGraphLinks = flow.links;
          this.editorGraphNodes = flow.nodes;
        }, 200);
      }
    } else {
      this.editorGraphLinks = [];
      this.editorGraphNodes = [];
    }
  }
  
  GenerateEventFlowForView(viewId: number) {
    this.editorEventsProcessed = {};
    const viewRepMolecules = this.busService.GetViewMolecules(this.cobbleService.Cobble, viewId);
    let nodes = [];
    let links = [];
    
    viewRepMolecules.forEach(repMolecule => {
      const flow = this.GenerateEventFlowForRepresentativeMolecule(repMolecule.Id.toString());
      if (flow) {
        nodes = nodes.concat(flow.nodes);
        links = links.concat(flow.links);
      }
    });
    
    const uniqs = uniq(
      uniqBy(links, 'source')
      .map(l => l.source)
      .concat(uniqBy(links, 'target').map(l => l.target)),
    );
    nodes = nodes.filter(n => uniqs.includes(n.id));
    nodes = uniqBy(nodes, 'id');
    
    // region remove unnecessary links
    links = links.filter(l => nodes.map(n => n.id).includes(l.source) && nodes.map(n => n.id).includes(l.target));
    // endregion
    
    setTimeout(() => {
      console.log(nodes);
      this.editorGraphNodes = nodes;
      this.editorGraphLinks = links;
    }, 300);
  }
  
  GenerateEventFlowForEvent(eventName: string, repMoleculeId: string) {
    const flow = this.GenerateEventFlow(eventName, repMoleculeId);
    if (flow) {
      const uniqs = uniq(
        uniqBy(flow.links, 'source')
        .map(l => l.source)
        .concat(uniqBy(flow.links, 'target').map(l => l.target)),
      );
      let nodes = flow.nodes.filter(n => uniqs.includes(n.id));
      nodes = uniqBy(nodes, 'id');
      
      setTimeout(() => {
        this.editorGraphLinks = flow.links;
        this.editorGraphNodes = nodes;
      }, 200);
    }
  }
  
  GenerateEventFlowForRepresentativeMolecule(repMoleculeId: string): { nodes: any[]; links: any[] } {
    const repMolecule = this.busService.Get(repMoleculeId);
    let nodes = [];
    let links = [];
    
    let repMoleculeEvents = [];
    
    repMolecule.Buses.forEach(b => {
      repMoleculeEvents = repMoleculeEvents.concat(b.GetEvents().map(e => e.EventName));
    });
    repMoleculeEvents = repMoleculeEvents.concat(repMolecule.Events);
    
    repMoleculeEvents = uniq(repMoleculeEvents);
    
    repMoleculeEvents.forEach(e => {
      const flow = this.GenerateEventFlow(e, repMoleculeId, 'Molecule');
      
      if (flow) {
        nodes = nodes.concat(flow.nodes);
        links = links.concat(flow.links);
      }
    });
    
    const uniqs = uniq(
      uniqBy(links, 'source')
      .map(l => l.source)
      .concat(uniqBy(links, 'target').map(l => l.target)),
    );
    nodes = nodes.filter(n => uniqs.includes(n.id));
    nodes = uniqBy(nodes, 'id');
    
    return {
      nodes,
      links,
    };
  }
  
  GenerateEventFlow(eventName: string, repMoleculeId: string, source = 'Molecule', parentId: string = ''): { nodes: any[]; links: any[] } {
    // region evaluate processed events
    const key = repMoleculeId;
    
    this.editorEventsProcessed[key] = this.editorEventsProcessed[key] || [];
    
    if (this.editorEventsProcessed[key].includes(eventName)) {
      if (parentId !== '') {
        const newLink = {
          source: parentId,
          target: key.toString() + eventName.slice(0, 2),
          label: this.toolsService.GenerateGuid() + '-connector',
          data: {},
        };
        
        console.log('created link', newLink);
        
        return {
          nodes: [],
          links: [newLink],
        };
      } else {
        return;
      }
    } else {
      this.editorEventsProcessed[key].push(eventName);
    }
    // endregion
    
    let nodes = [];
    let links = [];
    const repMolecule = this.busService.Get(repMoleculeId);
    const event = repMolecule.GetEventByEventName(eventName);
    
    if (event) {
      const repMoleculesListening = this.busService.GetRepresentativeMoleculesFiredBySpecificEvent(
        this.cobbleService.Cobble,
        eventName,
        event.SourceId,
        this.workAreaService.ActualView.id,
      );
      
      // region parent event
      const parentEventId = this.toolsService.GenerateGuid();
      const eventData = {
        eventName: event.EventName,
        eventType: event.EventType,
        eventSourceId: event.SourceId,
        busName: '',
        busReceptor: '',
        busId: parentEventId,
        parentBusId: parentId,
        particles: [],
        repMoleculeName: repMolecule.Properties.name,
        repMoleculeType: repMolecule.Type,
        repMoleculeId: repMolecule.Id,
        repMoleculeIcon: repMolecule.Icon,
        processType: 'event',
      };
      
      nodes.push({
        id: event.SourceId + eventName.slice(0, 2),
        label: repMolecule.Properties.name + '-' + eventName,
        data: eventData,
      });
      // endregion
      
      repMoleculesListening.forEach(repMol => {
        const buses = repMol.GetBusesInitiatedBySpecificEventName(eventName, event.SourceId);
        
        buses.forEach(bus => {
          const particles = [];
          bus.Particles.forEach((p, index) => {
            if (index > 0) {
              if (p.IsEvent()) {
                const eventFlow = this.GenerateEventFlow((p as LeapXLEvent).EventName, repMol.Id.toString(), (p as LeapXLEvent).EventSource, bus.id);
                if (eventFlow) {
                  nodes = nodes.concat(eventFlow.nodes);
                  links = links.concat(eventFlow.links);
                }
              } else {
                particles.push(p.GetRawObject());
              }
            }
          });
          
          // region add new node
          nodes.push({
            id: bus.id,
            label: bus.id,
            data: {
              eventName: event.EventName,
              eventType: event.EventType,
              eventSourceId: event.SourceId,
              busName: bus.Name,
              busReceptor: bus.Receptor,
              busId: bus.id,
              parentBusId: parentEventId,
              particles: particles,
              repMoleculeName: repMol.Properties.name,
              repMoleculeType: repMol.Type,
              repMoleculeId: repMol.Id,
              repMoleculeIcon: repMol.Icon,
              processType: 'molecule',
            },
          });
          // endregion
        });
      });
      
      // region create links
      nodes.forEach(n => {
        const children = nodes.filter(nc => nc.data.parentBusId === n.data.busId);
        
        children.forEach(c => {
          links.push({
            source: n.id,
            target: c.id,
            label: c.data.eventName,
            data: c.data,
          });
        });
      });
      // endregion
    }
    
    // region remove repeated nodes
    nodes = uniqBy(nodes, 'id');
    // endregion
    
    return {
      nodes,
      links,
    };
  }
  
  TrackRepresentativeMolecule(repMoleculeId: string) {
    this.editorEventsProcessed = {};
    this.repMoleculeId = repMoleculeId;
    const repMolecule = this.busService.Get(repMoleculeId);
    this.workAreaService.ShowElementFocusedMenu(repMolecule);
    this.workAreaService.SelectRepresentativeMolecule(repMolecule);
    const flow = this.GenerateEventFlowForRepresentativeMolecule(repMoleculeId);
    
    setTimeout(() => {
      this.editorGraphLinks = flow.links;
      this.editorGraphNodes = flow.nodes;
    }, 200);
  }
  
  TrackEventFlowForEvent(eventName: string, repMoleculeId: string) {
    this.editorEventsProcessed = {};
    this.GenerateEventFlowForEvent(eventName, repMoleculeId);
  }
  
  DraggableWindowSizeChanged() {
    console.log('size changed');
    
    if (this.enableZoom) {
      this.zoomToFit$.next(true);
      this.center$.next(true);
    }
    
    this.update$.next(true);
  }
  
  ClearGraphData() {
    this.processorRunning = [];
    this.nodes = [];
    this.links = [];
    this.graphLinks = [];
    this.graphNodes = [];
    this.startProcessParent = null;
    this.startProcessTimestamp = null;
    this.CloseDataOpenedWindows();
  }
  
  CloseDataOpenedWindows() {
    this.evensDataWindowOpened.forEach(window => {
      window.Hide();
    });
    this.evensDataWindowOpened = [];
  }
  
  UpdateMolecularProcessFlow(start: IStartProcess) {
    try {
      // this.processorRunning = Object.assign([], this.startingProcesses);
      this.buildingTransactions = true;
      
      console.log('processorRunning', this.processorRunning);
      
      // set parents if not setted
      for (const process of this.processorRunning) {
        if (process.eventSourceId === process.repMoleculeId) {
        } else if (process.parentBusId === '') {
          const parent = this.processorRunning.find(pr => pr.repMoleculeId === process.eventSourceId);
          
          if (parent) {
            process.parentBusId = parent.busId;
          } else {
            const repMolecule = this.busService.Get(process.eventSourceId);
            const repMoleculeParentId = this.toolsService.GenerateGuid();
            
            if (repMolecule) {
              this.processorRunning.push({
                eventName: process.eventName,
                eventType: process.eventType,
                eventData: process.eventData,
                eventSourceId: process.eventSourceId,
                busKey: 'starting-rep-molecule-node',
                busName: 'None',
                busReceptor: '',
                busReceptorData: '',
                busId: repMoleculeParentId,
                parentBusId: '',
                particles: [],
                repMoleculeName: repMolecule.Properties.name,
                repMoleculeType: repMolecule.Type,
                repMoleculeId: repMolecule.Id,
                repMoleculeIcon: repMolecule.Icon,
                processType: 'molecule',
                timestamp: Date.now(),
                timeDifference: 0,
                nodeId: start.event.EventType + process.eventSourceId,
              });
              
              process.parentBusId = repMoleculeParentId;
            }
          }
        }
      }
      //
      
      const eventProcesses = [];
      const eventConnectors = [];
      
      for (let i = 0; i < this.processorRunning.length; i++) {
        const process = this.processorRunning[i];
        
        if (process.parentBusId === '') {
          if (!!!this.nodes.find(n => n.data.busId === process.busId && n.data.busKey === process.busKey)) {
            let label = 'parent';
            
            const parent = this.processorRunning.find(pr => pr.repMoleculeId === process.eventSourceId && pr.busId !== process.busId);
            
            if ((process.eventType === 'init' || parent === undefined) && process.busKey !== 'starting-rep-molecule-node') {
              const sourceNode = this.nodes.find(n => n.id === process.eventSourceId);
              
              if (sourceNode) {
                process.parentBusId = process.eventSourceId;
              } else {
                //
                
                const initId = this.toolsService.GenerateGuid();
                
                //
                const eventProcess = cloneDeep(process);
                eventProcess.processType = 'event';
                eventProcess.busId = process.eventSourceId;
                // eventProcess.parentBusId = initId;
                
                const eventNodeInit: Node = {
                  id: initId,
                  label: 'eventParentInit',
                  data: {
                    processType: 'none',
                    busId: initId,
                    eventName: eventProcess.eventName,
                    parentBusId: '',
                  },
                };
                
                // const eventNode: Node = {
                //   id: process.eventSourceId.toString(),
                //   label: 'eventParent',
                //   data: eventProcess
                // };
                
                this.nodes.push(eventNodeInit);
                eventProcesses.push({
                  processType: 'none',
                  busId: initId,
                  eventName: eventProcess.eventName,
                  parentBusId: '',
                });
                // this.nodes.push(eventNode);
                // eventProcesses.push(eventProcess);
                process.parentBusId = initId;
                //
              }
              
              label = 'event-source';
            }
            
            const node: Node = {
              id: process.busId,
              label: label,
              data: process,
            };
            
            this.nodes.push(node);
          }
        } else {
          const node: Node = {
            id: process.busId,
            label: process.busName,
            data: process,
          };
          
          this.nodes.push(node);
          
          // create event connector
          
          if (!!eventConnectors.find(ec => ec.id === process.parentBusId) || !!this.processorRunning.find(ec => ec.id === process.parentBusId)) {
          } else {
            // const previousProcess = this.processorRunning[i - 1];
            
            const eventProcess = cloneDeep(process);
            eventProcess.processType = 'event';
            eventProcess.busId = process.parentBusId;
            
            const eventNode: Node = {
              id: process.parentBusId,
              label: 'eventConnector',
              data: eventProcess,
            };
            
            eventProcesses.push(eventProcess);
            eventConnectors.push(eventNode);
          }
          //
        }
      }
      
      eventConnectors.forEach(ec => {
        const eventNodeId = this.toolsService.GenerateGuid();
        const parentProcessorRunning = this.processorRunning.find(pr => pr.busId === ec.id);
        const parentNodeZ = this.nodes.find(n => n.id === ec.id);
        
        if (parentNodeZ) {
          parentNodeZ.id = eventNodeId;
          parentNodeZ.data.busId = eventNodeId;
          parentProcessorRunning.id = eventNodeId;
          ec.data.parentBusId = eventNodeId;
        }
      });
      
      console.log('conectors', eventConnectors);
      this.nodes = this.nodes.concat(eventConnectors);
      this.processorRunning = this.processorRunning.concat(eventProcesses);
      
      this.nodes = [...this.nodes];
      
      this.nodes
      .filter(n => n.label === 'eventConnector')
      .forEach(n => {
        if (n.data.parentBusId !== '') {
          const parent = this.nodes.find(node => node.data.busId === n.data.parentBusId);
          
          if (parent.data.parentBusId !== '' && parent.data.particles) {
            const parentParticlesNoEvent = parent.data.particles.filter(p => p.type !== 'Event');
            
            if (parentParticlesNoEvent.length > 0) {
              n.data.eventData = parentParticlesNoEvent[parentParticlesNoEvent.length - 1].data;
            } else {
              n.data.eventData = parent.data.eventData;
            }
            n.data.busReceptor = parent.data.busReceptor;
            
            const prNode = this.processorRunning.find(pr => pr.busId === n.data.parentBusId);
            prNode.eventData = n.data.eventData;
            prNode.busReceptor = n.data.busReceptor;
          }
        }
      });
      
      for (const process of this.processorRunning) {
        if (!process.busId) {
          continue;
        }
        
        // const nodeExists = this.nodes.find(n => n.data.repMoleculeId === process.repMoleculeId);
        
        // if (process.parentBusId === '') {
        //   process.parentBusId = parentId;
        //
        //   this.links.push({
        //     source: parentId,
        //     target: process.busId,
        //     label: process.eventName,
        //     data: process
        //   });
        // }
        
        const busLinks = this.processorRunning.filter(pr => pr.parentBusId === process.busId);
        
        busLinks.forEach(bl => {
          const edge: Edge = {
            source: process.busId,
            target: bl.busId,
            label: bl.eventName,
            data: process,
          };
          
          this.links.push(edge);
        });
      }
      
      this.nodes[0].data.position = 'first';
      const parentBusIds = this.nodes.map(n => n.data.parentBusId);
      
      const endNodes = this.nodes.filter(n => !parentBusIds.includes(n.id)).forEach(n => (n.data.position = 'last'));
      
      // const parentNode = this.nodes.find(n => n.label === 'parent');
      const nodesIds = this.nodes.map(n => n.id);
      this.links = this.links.filter(l => nodesIds.includes(l.target));
      
      // const bNodes = this.nodes;
      // const bLinks = this.links;
      // this.nodes = [];
      // this.links = [];
      // // this.update$.next(true);
      
      const uniqs = uniq(
        uniqBy(this.links, 'source')
        .map(l => l.source)
        .concat(uniqBy(this.links, 'target').map(l => l.target)),
      );
      this.nodes = this.nodes.filter(n => uniqs.includes(n.id));
      this.nodes = uniqBy(this.nodes, 'id');
      
      // region remove unnecessary links
      this.links = this.links.filter(l => this.nodes.map(n => n.id).includes(l.source) && this.nodes.map(n => n.id).includes(l.target));
      // endregion
      
      this.graphNodes = this.graphNodes.concat(this.nodes);
      this.graphLinks = this.graphLinks.concat(this.links);
      
      const filteredLinks = [];
      
      this.graphLinks.forEach(link => {
        if (!!filteredLinks.find(fl => fl.source + fl.target === link.source + link.target)) {
        } else {
          filteredLinks.push(link);
        }
      });
      
      this.graphLinks = filteredLinks;
      
      const uniqsGraph = uniq(
        uniqBy(this.graphLinks, 'source')
        .map(l => l.source)
        .concat(uniqBy(this.graphLinks, 'target').map(l => l.target)),
      );
      this.graphNodes = this.graphNodes.filter(n => uniqsGraph.includes(n.id));
      this.graphNodes = uniqBy(this.graphNodes, 'id');
      
      // region remove unnecessary links
      this.graphLinks = this.graphLinks.filter(
        l => this.graphNodes.map(n => n.id).includes(l.source) && this.graphNodes.map(n => n.id).includes(l.target),
      );
      // endregion
      
      setTimeout(() => {
        // this.links = bLinks;
        // this.nodes = bNodes;
        this.buildingTransactions = false;
        this.update$.next(true);
        // this.zoomToFit$.next(true);
      }, 600);
      
      console.log('nodes', this.graphNodes);
      console.log('links', this.graphLinks);
      console.log('processorRunning', this.processorRunning);
    } catch (e) {
      console.log(e);
      this.graphNodes = [];
      this.graphLinks = [];
      this.nodes = [];
      this.links = [];
      this.buildingTransactions = false;
    }
  }
  
  DisplayJsonTreeData(data: any, event: MouseEvent = null, titleName = null) {
    const title = '[Value] ' + titleName || 'Data';
    this.workAreaService.jsonTreeData = data;
    this.toolsService.DragWindowConfig = {
      changeLayout: false,
      x: 100,
      y: 100,
      width: 300,
      height: 300,
    };
    
    if (event) {
      const windowBounds = document.querySelector('.main-container').getBoundingClientRect();
      
      this.toolsService.DragWindowConfig.y = event.clientY - windowBounds.top - 50;
      this.toolsService.DragWindowConfig.x = event.clientX - windowBounds.left + 35;
    }
    
    const dragWindow = this.draggableWindowService.GenerateWindow(JsonTreeComponent, {
      title: title,
      data: null,
    });
    
    dragWindow.Show();
    this.evensDataWindowOpened.push(dragWindow);
    dragWindow.draggableWindowComponentRef.onDestroy(() => {
      this.evensDataWindowOpened = this.evensDataWindowOpened.filter(w => w.Id !== dragWindow.Id);
    });
  }
  
  clearProcess() {
    this.processorRunning = [];
  }
  
  toggleData(particle: any, event: MouseEvent) {
    // console.log('toggle');
    // particle.showData = !particle.showData;
    this.DisplayData(particle.data, event);
  }
  
  DataTooltip(data: any) {
    return typeof data === 'string' ? data : '';
  }
  
  ParticleRuleTooltip(rule: any) {
    return rule ? JSON.stringify(rule) : '';
  }
  
  DisplayData(data: any, event: MouseEvent, title = '') {
    this.DisplayJsonTreeData(this.ConvertData(data), event, title);
    
    return;
    const windowBounds = document.querySelector('.main-container').getBoundingClientRect();
    
    this.locationDataWindowTop = event.clientY - windowBounds.top - 50;
    this.locationDataWindowLeft = event.clientX - windowBounds.left + 35;
    
    this.jsonData = data;
    this.showDataWindow = true;
    this.disableClosed = true;
    
    setTimeout(() => {
      this.disableClosed = false;
    }, 200);
  }
  
  ConvertData(data: any) {
    if (Array.isArray(data)) {
      const z = {};
      const contextType = data[0].context.split(Constants.ContextSeparator);
      const groupedData =
        contextType[0] === DatasourceType.Api ? this.toolsService.GroupBy(data, d => d.context) : this.toolsService.GroupBy(data, d => d.col);
      
      groupedData.forEach(gData => {
        const colData = {};
        
        gData.sort(this.toolsService.CompareValues('row')).forEach(value => {
          colData[value.row] = value.formattedValue;
        });
        
        const contextArray = gData[0].context.split(Constants.ContextSeparator);
        const colName =
          contextArray[0] === DatasourceType.Spreadsheet ? this.toolsService.ColumnIndexToName(gData[0].col) : contextArray[contextArray.length - 1];
        z[colName] = colData;
      });
      
      console.log(z);
      return z;
    } else {
      return data;
    }
  }
  
  ToggleAutoClear(event: any) {
    this.clearOnUserAction = event.checked;
  }
  
  GetNodeData(busId) {
    const node = this.nodes.find(n => n.data.busId === busId);
    return node ? node.data.eventData : '';
  }
  
  ClickOutsideDataWindow() {
    if (!this.disableClosed) {
      this.showDataWindow = false;
    }
  }
  
  DisableClick(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    return false;
  }
  
  HighlightRepMolecule(repMoleculeId: string, $event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    const repMolecule = this.busService.Get(repMoleculeId);
    repMolecule.Pulse(2500);
    repMolecule.Select();
    return false;
  }
  
  ZoomChange(event) {
    this.lastZoomLevel = event;
  }
  
  LockZoom() {
    this.zoomLevel = this.lastZoomLevel;
    this.minZoomLevel = this.lastZoomLevel;
    this.maxZoomLevel = this.lastZoomLevel;
  }
  
  UnlockZoom() {
    this.minZoomLevel = 0.1;
    this.maxZoomLevel = 4.0;
  }
  
  ToggleZoomLock($event) {
    this.autoZoom = false;
    this.autoCenter = false;
    
    // enabled
    if ($event.checked) {
      this.UnlockZoom();
    } else {
      this.LockZoom();
    }
  }
  
  RepMoleculeContainerHover(repMoleculeId: number, out = false) {
    if (repMoleculeId) {
      let repMoleculeHtmlElement = this.repMoleculeHtmlElements[repMoleculeId];
      
      if (repMoleculeHtmlElement) {
      } else {
        const id = '#gridsterItem-' + repMoleculeId;
        repMoleculeHtmlElement = document.querySelector(id);
        if (repMoleculeHtmlElement) {
          this.repMoleculeHtmlElements[repMoleculeId] = repMoleculeHtmlElement;
        }
      }
      if (repMoleculeHtmlElement) {
        if (out) {
          setTimeout(() => {
            repMoleculeHtmlElement.classList.remove('highlight-event-transaction');
          }, 200);
        } else {
          repMoleculeHtmlElement.classList.add('highlight-event-transaction');
        }
      }
    }
  }
  
  Throttle(func, delay, context, args) {
    clearTimeout(this.inDebounce);
    this.inDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.CloseDataOpenedWindows();
  }
}

export interface IStartProcess {
  event: LeapXLEvent;
  busKey: string;
  bus: Bus;
  repMolecule: IRepresentativeMolecule;
}
