import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BuilderService } from '../../../../core/builder/builder.service';
import { ProcessorService } from '../../../../core/molecular/services/processor.service';
import { WorkAreaService } from '../../../../workarea/workarea.service';
import { RepresentativeMoleculesType } from '../../../enums/representative-molecules-types.enum';
import { DragService } from '../../services/drag.service';
import { BaseMoleculeComponent } from '../base-molecule/base-molecule.component';

@Component({
  selector: 'app-icon-molecule',
  templateUrl: './icon-molecule.component.html',
  styleUrls: ['./icon-molecule.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class IconMoleculeComponent extends BaseMoleculeComponent implements OnInit {
  MoleculeType = 'Representative';
  Type = RepresentativeMoleculesType.Icon;
  context: any;
  
  @ViewChild('iconWrapper', { static: true })
  iconWrapper: ElementRef;
  
  constructor(
    public builderService: BuilderService,
    public dragService: DragService,
    public workAreaService: WorkAreaService,
    public processorService: ProcessorService,
    bottomSheet: MatBottomSheet,
    public el: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
    super(bottomSheet, el, changeDetectorRef);
  }
  
  ngOnInit() {
    super.ngOnInit();
    if (!this.context.RunningMode) {
      this.DetachChangeDetection();
    }
    this.context.Type = RepresentativeMoleculesType.Icon;
    this.RefreshGridsterConfiguration();
  }
  
  AttachEditorEventListeners() {
    const dragoverEventListener = this.renderer.listen(this.iconWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, true);
    });
    const dragleaveEventListener = this.renderer.listen(this.iconWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, false);
    });
    const dropEventListener = this.renderer.listen(this.iconWrapper.nativeElement, 'drop', (evt) => {
      this.DataDropped(evt);
    });
  }
  
  FireClickEvent(e: any) {
    if (!this.context.Properties.enable && this.cobbleService.Cobble.running) {
      return;
    }
    
    this.FireRepresentativeMoleculeEvent('click');
  }
}
