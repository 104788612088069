import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { CobbleUser } from '../../shared/models/cobble-user.model';
import { GenericTreeItem } from '../../shared/models/generic-tree-item';
import { HierarchyNode } from '../../shared/models/hierarchy-node';
import { HierarchyLevel } from '../../shared/models/hierarchylevel.model';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';

import { ApiDataService } from './api-data.service';

@Injectable()
export class ApiHierarchyService extends ApiDataService {
  treeChanged = new Subject<HierarchyLevel>();
  private treeIsPopulatedSource = new BehaviorSubject<boolean>(false);
  private usersSource = new BehaviorSubject<CobbleUser[]>([]);
  users$ = this.usersSource.asObservable();
  
  constructor(http: HttpClient, errorMessengerService: ErrorMessengerService) {
    super('companies', http, errorMessengerService);
  }
  
  setUsersList(users: CobbleUser[]) {
    this.usersSource.next(users);
  }
  
  areUsersAttachedToHierarchy(): boolean {
    const users = this.usersSource.value;
    return users.find(user => user.companyNodes.length > 0) ? true : false;
  }
  
  setTreeIsPopulated(isPopulated: boolean) {
    this.treeIsPopulatedSource.next(isPopulated);
  }
  
  saveElement(resource: HierarchyLevel) {
    return (
      this.http
      // .post(this.apiUrl + `/companynode/`, JSON.stringify(resource))
      .post(this.apiEndpointUrl + `/companynode/`, resource)
      .pipe(
        map(response => this.parseObject(<any>response)),
        catchError(error => this.errorMessengerService.HandleError(error)),
      )
    );
  }
  
  addNewNode(resource: HierarchyNode) {
    return this.http.post(this.apiEndpointUrl + `/companynode/`, resource).pipe(
      map(response => <HierarchyNode>response),
      catchError(error =>
        this.errorMessengerService.HandleError(error, 'Error adding new company node.', resource),
      ),
    );
  }
  
  removeNode(nodeId: number) {
    return this.http
    .delete(this.apiEndpointUrl + `/node/${ nodeId }/remove`)
    .pipe(
      catchError(error =>
        this.errorMessengerService.HandleError(error, `Error removing node.`, nodeId),
      ),
    );
  }
  
  removeUserFromNode(nodeId: number, userId: number) {
    return this.http
    .delete(this.apiEndpointUrl + `/node/${ nodeId }/user/${ userId }/remove`)
    .pipe(
      catchError(error =>
        this.errorMessengerService.HandleError(
          error,
          `Error removing user ${ userId } from node.`,
          nodeId,
        ),
      ),
    );
  }
  
  getTree(cobbleId: number): Observable<HierarchyLevel> {
    return this.http.get(this.apiEndpointUrl + `/companynode/${ cobbleId }`).pipe(
      map(response => this.parseObject(<any>response)),
      catchError(error =>
        this.errorMessengerService.HandleError(error, `Error getting company tree.`, cobbleId),
      ),
    );
  }
  
  getShareTree(companyId: number): Observable<GenericTreeItem> {
    return this.http.get(this.apiEndpointUrl + `/nodeitems/${ companyId }`).pipe(
      map(response => this.parseGenericTreeItem(response)),
      catchError(error =>
        this.errorMessengerService.HandleError(error, `Error getting shared Apps.`, companyId),
      ),
    );
  }
  
  changeCompanyState(companyId: number) {
    return this.http
    .get(this.apiEndpointUrl + `/companynodecreated/${ companyId }`)
    .pipe(
      catchError(error =>
        this.errorMessengerService.HandleError(
          error,
          `Error changing company ${ companyId } State.`,
          companyId,
        ),
      ),
    );
  }
  
  private parseGenericTreeItem(level: any): GenericTreeItem {
    const newLevel: GenericTreeItem = new GenericTreeItem();
    
    Object.assign(newLevel, level);
    newLevel.list = [];
    
    level.list.forEach(element => {
      newLevel.list.push(this.parseGenericTreeItem(element));
    });
    
    return newLevel;
  }
  
  private parseObject(level: any) {
    const newLevel: HierarchyLevel = new HierarchyLevel();
    Object.assign(newLevel, level);
    newLevel.nodes = [];
    newLevel.users = [];
    
    level.nodes.forEach(element => {
      newLevel.nodes.push(this.parseObject(element));
    });
    level.users.forEach(element => {
      newLevel.users.push(element);
    });
    
    return newLevel;
  }
}
