import { ComponentFactoryResolver, ComponentRef, Directive, Input, OnChanges, OnInit, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicAutocompleteComponent } from '../components/dynamic-autocomplete/dynamic-autocomplete.component';
import { DynamicButtonComponent } from '../components/dynamic-button/dynamic-button.component';
import { DynamicCheckboxComponent } from '../components/dynamic-checkbox/dynamic-checkbox.component';
import { DynamicDropdownComponent } from '../components/dynamic-dropdown/dynamic-dropdown.component';
import { DynamicInputComponent } from '../components/dynamic-input/dynamic-input.component';
import { DynamicLabelComponent } from '../components/dynamic-label/dynamic-label.component';
import { DynamicRadioComponent } from '../components/dynamic-radio/dynamic-radio.component';
import { DynamicSelectComponent } from '../components/dynamic-select/dynamic-select.component';
import { FieldConfig } from '../models/field-config.model';

import { Field } from '../models/field.model';

const components: { [type: string]: any } = {
  input: DynamicInputComponent,
  select: DynamicSelectComponent,
  dropdown: DynamicDropdownComponent,
  button: DynamicButtonComponent,
  checkbox: DynamicCheckboxComponent,
  radio: DynamicRadioComponent,
  label: DynamicLabelComponent,
  autocomplete: DynamicAutocompleteComponent,
};

@Directive({
  selector: '[dynamicField]',
})
export class DynamicFieldDirective implements Field, OnChanges, OnInit {
  @Input() config: FieldConfig;
  
  @Input() group: FormGroup;
  
  component: ComponentRef<Field>;
  
  constructor(private resolver: ComponentFactoryResolver, private container: ViewContainerRef) {
  }
  
  ngOnChanges() {
    if (this.component) {
      this.component.instance.config = this.config;
      this.component.instance.group = this.group;
    }
  }
  
  ngOnInit() {
    if (!components[this.config.type]) {
      const supportedTypes = Object.keys(components).join(', ');
      throw new Error(
        `Trying to use an unsupported type (${ this.config.type }).
        Supported types: ${ supportedTypes }`,
      );
    }
    const component = this.resolver.resolveComponentFactory<Field>(components[this.config.type]);
    this.component = this.container.createComponent(component);
    this.component.instance.config = this.config;
    this.component.instance.group = this.group;
  }
}
