import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IRepresentativeMoleculeStyleData } from '../../shared/interfaces/rep-mol-style.interface';
import { CommunicationService } from '../../shared/services/communication.service';
import { DraggableWindowService } from '../../shared/services/draggable-window.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { ThematicService } from '../services/thematic.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-create-style',
  templateUrl: './create-style.component.html',
  styleUrls: ['./create-style.component.scss'],
})
export class CreateStyleComponent implements OnInit {
  @ViewChild('input', { static: true })
  input: ElementRef;
  disableSaveButton = false;
  loading = false;
  style: IRepresentativeMoleculeStyleData = null;
  
  constructor(private snackerService: SnackerService,
              private workAreaService: WorkAreaService,
              private thematicService: ThematicService,
              private communicationService: CommunicationService,
              private draggableWindowService: DraggableWindowService) {
    const data = this.draggableWindowService.GetData();
    
    if (data && data.style) {
      this.style = data.style;
    }
  }
  
  ngOnInit(): void {
    setTimeout(() => {
      this.input.nativeElement.focus();
      this.input.nativeElement.select();
    }, 200);
  }
  
  SaveStyle() {
    
    if (this.loading) {
      return;
    }
    
    this.loading = true;
    this.disableSaveButton = true;
    
    this.thematicService.SaveStyle(this.style);
    
    this.loading = true;
    this.snackerService.ShowMessageOnBottom(`${ this.style.name } added to your Themes library.`, 'add_circle', null, true);
    
    this.communicationService.Event.Editor.WorkArea.$RefreshStylesLibrary.emit();
    const id = this.workAreaService.draggableWindow[this.workAreaService.draggableWindow.length - 1].Id
    this.workAreaService.draggableWindow[this.workAreaService.draggableWindow.length - 1].Hide();
    this.workAreaService.draggableWindow = this.workAreaService.draggableWindow.filter(dw => dw.Id !== id);
  }
}
