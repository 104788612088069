import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-component',
  templateUrl: './empty-component.component.html',
  styleUrls: ['./empty-component.component.scss'],
})
export class EmptyComponent implements OnInit {
  constructor() {
  }
  
  ngOnInit() {
  }
}
