import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';
import { ApiDataService } from './api-data.service';

@Injectable()
export class ApiCompaniesService extends ApiDataService {
  constructor(http: HttpClient, errorMessengerService: ErrorMessengerService) {
    super('companies', http, errorMessengerService);
  }
  
  register(company) {
    // console.log('Creating company...');
    
    return this.http.post(this.apiEndpointUrl + `/register`, company).pipe(
      map(response => <any>response),
      catchError(error =>
        this.errorMessengerService.HandleError(error, 'Error registering Company.', company),
      ),
    );
  }
  
  GetCobblesByCompanies() {
    return this.http.get(this.apiEndpointUrl + `/Cobbles`).pipe(
      map(response => <any>response),
      catchError(error => this.errorMessengerService.HandleError(error, `Error getting Apps.`)),
    );
  }
  
  GetDefaultAppsByCompany(companyId: number) {
    return this.http.get(this.apiEndpointUrl + `/DefaultApps/` + companyId).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(
          error,
          `Error getting Apps for company ${ companyId }.`,
          companyId,
        ),
      ),
    );
  }
}
