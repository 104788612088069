import { Directive } from '@angular/core';
import { AbstractControl, AsyncValidator, AsyncValidatorFn, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ApiAuthService } from '../../core/services/api-auth.service';

export function existingEmailValidator(authService: ApiAuthService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    return authService.emailExists(control.value).toPromise().then(
      isUsable => {
        return isUsable ? null : { 'emailExists': true };
      },
    );
  };
}

@Directive({
  selector: '[appExistingEmailValidator][ngModel]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: ExistingEmailValidatorDirective, multi: true }],
})
export class ExistingEmailValidatorDirective implements AsyncValidator {
  
  constructor(private authService: ApiAuthService) {
  }
  
  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return existingEmailValidator(this.authService)(control);
  }
}
