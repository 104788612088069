export class SheetChange {
  
  constructor(
    public dataSourceId: number,
    public sheetIndex: number,
    public range: string,
  ) {
  
  }
}

export class SheetChangeValue extends SheetChange {
  constructor(
    dataSourceId: number,
    sheetIndex: number,
    range: string,
    public formatType: string,
    public value: string,
  ) {
    super(dataSourceId, sheetIndex, range);
  }
}

export class SheetChangeFormat extends SheetChange {
  constructor(
    workgroupId: number,
    sheetIndex: number,
    range: string,
    public formatType: SheetChangeFormatTypes,
    public value: any,
  ) {
    super(workgroupId, sheetIndex, range);
  }
}

export enum SheetChangeFormatTypes {
  Border = 'BORDER',
  BorderTop = 'BORDER_TOP',
  BorderLeft = 'BORDER_LEFT',
  BorderRight = 'BORDER_RIGHT',
  BorderBottom = 'BORDER_BOTTOM',
  Background = 'BACKGROUND',
  FontColor = 'FONT_COLOR'
}
