import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { TreeModel, TreeNode } from 'angular-tree-component';
import { of as observableOf, Subscription } from 'rxjs';
import { ApiAdminService } from '../../../core/services/api-admin.service';
import { ApiHierarchyService } from '../../../core/services/api-hierarchy.service';
import { ApiMoleculesService } from '../../../core/services/api-molecules.service';
import { ClientStorageService } from '../../../core/services/client-storage.service';
import { TemplateService } from '../../../core/services/template.service';
import { GenericTreeItem } from '../../models/generic-tree-item';
import { HierarchyNode } from '../../models/hierarchy-node';
import { NodeType } from '../../models/nodetype.enum';
import { CobbleService } from '../../representative-molecule/services/cobble.service';
import { CommunicationService } from '../../services/communication.service';
import { DraggableWindowService } from '../../services/draggable-window.service';
import { SnackerService } from '../../services/snacker.service';

@Component({
  selector: 'app-hierarchy-shared',
  templateUrl: './hierarchy-shared.component.html',
  styleUrls: ['./hierarchy-shared.component.scss'],
})
export class HierarchySharedComponent implements OnInit, OnDestroy {
  
  @Input()
  isForAdmin = false;
  
  @Output()
  listChanged = new EventEmitter<boolean>();
  
  treeControl: NestedTreeControl<GenericTreeItem>;
  dataSource: MatTreeNestedDataSource<GenericTreeItem>;
  dropZones: String[] = ['dzUserCobbles'];
  showNewNode = false;
  showNewNestedNode = false;
  usersSubscription: Subscription;
  
  options = {
    useCheckbox: true,
  };
  hierarchyNodes = [];
  entityIdToShare = 0;
  entityNameToShare = '';
  nodesCount = 0;
  selectedNodes: TreeNode[] = [];
  type = 'app';
  
  constructor(
    private hierarchyService: ApiHierarchyService,
    private adminService: ApiAdminService,
    private clientStorageService: ClientStorageService,
    private snackerService: SnackerService,
    private moleculesService: ApiMoleculesService,
    private communicationService: CommunicationService,
    private cobbleService: CobbleService,
    private draggableWindowService: DraggableWindowService,
    private templateService: TemplateService,
  ) {
    this.treeControl = new NestedTreeControl<GenericTreeItem>(
      this._getChildren,
    );
    this.dataSource = new MatTreeNestedDataSource();
    const data = this.draggableWindowService.GetData();
    if (data && data.data) {
      this.entityIdToShare = data.data.entityIdToShare || 0;
      this.entityNameToShare = data.data.entityNameToShare || '';
      this.type = data.data.type || 'app';
    }
  }
  
  ngOnInit() {
    this.usersSubscription = this.adminService.users$.subscribe(res => {
      this.getTree();
    });
  }
  
  ConvertHierarchyNodesToTreeNodes(node: GenericTreeItem) {
    this.nodesCount++;
    const treeNode = {
      name: node.name,
      email: node.email,
      title: node.title,
      children: [],
      data: node.other,
      icon: node.other.id === 1 ? 'home_work' : node.other.type === 'companyNode' ? 'domain' : 'person',
    };
    
    if (node.list.length > 0) {
      node.list.forEach(n => {
        treeNode.children.push(this.ConvertHierarchyNodesToTreeNodes(n));
      });
      
      return treeNode;
    } else {
      return treeNode;
    }
  }
  
  TreeChanged(treeModel: TreeModel) {
    this.GetSelectedNodes(treeModel);
  }
  
  OpenFirstNode(treeModel: TreeModel) {
    treeModel.getFirstRoot().expand();
  }
  
  ShareWithSelectedNodes(treeModel: TreeModel) {
    console.log(this.selectedNodes);
    this.Share();
  }
  
  FilterTree(treeModel: TreeModel, value: string) {
    if (value === '') {
      treeModel.clearFilter();
      treeModel.collapseAll();
      treeModel.getFirstRoot().expand();
    } else {
      treeModel.filterNodes((node) => node.data.name.toLowerCase().includes(value.toLowerCase()) || (node.parent && node.parent.data.name && node.parent.data.name.toLowerCase().includes(value.toLowerCase())));
    }
  }
  
  GetSelectedNodes(treeModel: TreeModel) {
    this.selectedNodes = [];
    
    treeModel.doForAll((node: TreeNode) => {
      // console.log(node);
      if (node.isSelected && node.isAllSelected) {
        if (node.isLeaf) {
          if (this.selectedNodes.find(sn => sn.id === node.parent.id)) {
            // already added
          } else {
            this.selectedNodes.push(node);
          }
        } else {
          this.selectedNodes.push(node);
        }
      }
      
    });
  }
  
  Share() {
    const nodes = [];
    
    this.selectedNodes.forEach(node => {
      nodes.push({
        objectId: this.entityIdToShare,
        id: node.data.data.id,
        added: true,
        type: node.data.data.type,
        sharePermission: null,
      });
    });
    
    if (this.type === 'app') {
      this.moleculesService
      .saveCobbletShare(nodes)
      .subscribe((res) => {
        this.communicationService.Event.Editor.AppsTree.$AppShared.emit(true);
        this.snackerService.ShowMessageOnBottom(`${ this.entityNameToShare } shared${ nodes.length > 1 ? '' : ' to ' + this.selectedNodes[0].data.name }!`, 'ios_share');
      });
    } else {
      this.templateService
      .ShareTemplate(nodes)
      .subscribe((res) => {
        this.snackerService.ShowMessageOnBottom(`${ this.entityNameToShare } shared${ nodes.length > 1 ? '' : ' to ' + this.selectedNodes[0].data.name }!`, 'ios_share');
      });
    }
  }
  
  filterFn(value: string, treeModel: TreeModel) {
    treeModel.filterNodes((node: TreeNode) => this.Fuzzysearch(value, node.data.name));
  }
  
  Fuzzysearch(needle: string, haystack: string) {
    const haystackLC = haystack.toLowerCase();
    const needleLC = needle.toLowerCase();
    
    const hlen = haystack.length;
    const nlen = needleLC.length;
    
    if (nlen > hlen) {
      return false;
    }
    if (nlen === hlen) {
      return needleLC === haystackLC;
    }
    outer: for (let i = 0, j = 0; i < nlen; i++) {
      const nch = needleLC.charCodeAt(i);
      
      while (j < hlen) {
        if (haystackLC.charCodeAt(j++) === nch) {
          continue outer;
        }
      }
      return false;
    }
    return true;
  }
  
  
  // old implementation
  addNode(parentNode: GenericTreeItem, nodeType: string) {
    nodeType === ''
      ? (this.showNewNode = true)
      : (this.showNewNestedNode = true);
  }
  
  removeNode(node: GenericTreeItem) {
    const subscribeRemove = node.other.type === NodeType.User ? this.hierarchyService.removeUserFromNode(node.other.companyNodeId, node.other.id) :
      this.hierarchyService.removeNode(node.other.id);
    
    subscribeRemove.subscribe(result => {
      // console.log(result);
      if (result) {
        this.listChanged.emit(true);
        const data = this.dataSource.data;
        const nodeIsRemoved = this.removeNodeFromParent(data, node.other.id);
        if (nodeIsRemoved) {
          this.dataSource.data = null;
          this.dataSource.data = data;
          return;
        }
        this.snackerService.ShowMessageOnBottom(`Node deleted!`, 'do_not_disturb_on', null, true);
      } else {
        this.snackerService.ShowMessageOnBottom(`Could not delete the node.`, 'do_not_disturb_off');
      }
    });
  }
  
  saveNewNode(
    parentNode: GenericTreeItem,
    newNodeValue: string,
    nodeType: string,
  ) {
    const data = this.dataSource.data;
    const newNode: GenericTreeItem = new GenericTreeItem();
    const newHNode = new HierarchyNode();
    
    // console.log('Parent Node', parentNode);
    
    newNode.name = newNodeValue;
    newNode.list = [];
    newNode.title = '';
    newNode.other = { hasUsers: false, type: 'companyNode' };
    newNode.other['id'] = 0;
    
    newHNode.name = newNodeValue;
    newHNode.description = newNodeValue;
    newHNode.companyId = this.clientStorageService.getCompanyId();
    newHNode.parentCompanyNodeId = parentNode.other.id;
    
    this.hierarchyService.addNewNode(newHNode).subscribe(result => {
      if (result) {
        newNode.other.id = result.id;
        if (nodeType === '') {
          data.find(n => n.name === parentNode.name).list.push(newNode);
        } else {
          parentNode.list.push(newNode);
        }
        this.dataSource.data = null;
        this.dataSource.data = data;
        this.showNewNode = false;
      }
    });
    
    // console.log('parent node', parentNode);
  }
  
  cancelNewNode(nodeType: string) {
    nodeType === ''
      ? (this.showNewNode = false)
      : (this.showNewNestedNode = false);
    this.showNewNode = false;
  }
  
  hasChild = (_: number, _nodeData: GenericTreeItem) => {
    // tslint:disable-next-line:semicolon
    // console.log(_nodeData);
    // console.log(_nodeData.canBeSubleveled);
    return _nodeData.list.length > 0;
  };
  
  ngOnDestroy(): void {
    if (this.usersSubscription) {
      this.usersSubscription.unsubscribe();
    }
  }
  
  private removeNodeFromParent(
    nodes: GenericTreeItem[],
    id?: number,
    nodeType?: string,
  ): Boolean {
    for (let index = 0; index < nodes.length; index++) {
      if (id) {
        if (nodes[index].other.id === id) {
          nodes.splice(index, 1);
          return true;
        } else if (nodes[index].list.length > 0) {
          return this.removeNodeFromParent(nodes[index].list, id, nodeType);
        }
      } else if (nodeType) {
        if (nodes[index].other.type === nodeType) {
          nodes.splice(index, 1);
        } else if (nodes[index].list.length > 0) {
          this.removeNodeFromParent(nodes[index].list, id, nodeType);
        }
      }
    }
    if (id) {
      return false;
    }
    return true;
  }
  
  private getTree() {
    this.hierarchyService.getShareTree(this.cobbleService.Cobble ? this.cobbleService.Cobble.id : 0).subscribe(tree => {
      this.hierarchyNodes = [this.ConvertHierarchyNodesToTreeNodes(tree)];
      console.log(tree);
      const sharedTree = [];
      sharedTree.push(tree);
      this.dataSource.data = sharedTree;
      this.treeControl.expandDescendants(sharedTree[0]);
    });
  }
  
  private _getChildren = (node: GenericTreeItem) => observableOf(node.list);
}

export class GenericTreeFlatNode {
  filename: string;
  type: any;
  level: number;
  expandable: boolean;
}
