/**
 This enum represents a list of possible permissions that can be granted to a user.
 */
export enum Permissions {
  /**
   Allows the user to execute a public cobble (a piece of code that performs a specific task).
   */
  ExecPublicCobble = 'ExecPublicCobble',
  /**
   Allows the user to execute a cobble (a piece of code that performs a specific task).
   */
  ExecCobble = 'ExecCobble',
  /**
   Allows the user to download a spreadsheet.
   */
  DownloadSpreadSheet = 'DownloadSpreadSheet',
  /**
   Allows the user to register an API key for external access.
   */
  APIRegister = 'APIRegister',
  /**
   Allows the user to replace a published data source with a new one.
   */
  ReplacePublishedDatasource = 'ReplacePublishedDataSource',
  /**
   Allows the user to import and replace a workbook.
   */
  ImportReplaceWorkbook = 'ImportReplaceWorkbook',
  /**
   Allows the user to develop cobble code.
   */
  CobbleDevelopment = 'CobbleDevelopment',
  /**
   Allows the user to access administrative features.
   */
  Administration = 'Administration',
  /**
   Grants the user special permissions.
   */
  SpecialPermissions = 'SpecialPermissions',
  /**
   Allows the user to share cobble code.
   */
  ShareCobbles = 'ShareCobbles',
  /**
   Allows the user to edit their smart templates.
   */
  SmartTemplatesMyTemplatesEdit = 'SmartTemplatesMyTemplatesEdit',
  /**
   Disables auto-logoff.
   */
  AutoLogOffDisabled = 'AutoLogOffDisabled',
  /**
   Allows the user to globally edit smart templates.
   */
  SmartTemplatesGlobalEdit = 'SmartTemplatesGlobalEdit',
  /**
   Allows the user to use smart templates.
   */
  SmartTemplatesUse = 'SmartTemplatesUse',
  /**
   Allows the user to access administrative features related to applications.
   */
  AdminApplications = 'AdminApplications',
  /**
   Allows the user to view the version history of a document.
   */
  VersionHistory = 'VersionHistory',
  /**
   Allows the user to undo and redo changes.
   */
  UndoRedo = 'UndoRedo',
  /**
   Allows the user to manage document snapshots.
   */
  ManageSnapshots = 'ManageSnapshots',
  /**
   Allows the user to remove an archived snapshot.
   */
  RemoveArchivedSnapshot = 'RemoveArchivedSnapshot',
  /**
   Allow the user to modify a company theme.
   */
  ModifyCompanyTheme = 'ModifyCompanyTheme',
  /**
   Allow the user to change the an application theme.
   */
  ModifyAppTheme = 'ModifyAppTheme',
  /**
   Allow the user replace the styles of an application with a theme applied.
   */
  ModifyThemedAppStyle = 'ModifyThemedAppStyle',
  /**
   Allow the user to change the an application theme when the application is set to a default theme or locked.
   */
  ModifyThemeOnDefaultThemedApps = 'ModifyThemeOnDefaultThemedApps',
  CreateCustomControl = 'CreateCustomControl',
  UseCustomControl = 'UseCustomControl',
  EditCustomControl = 'EditCustomControl',
  UpdateCustomControl = 'UpdateCustomControl',
  ModifyCustomControlTemplate = 'ModifyCustomControlTemplate',
  UpdateModifiedCustomControlTemplate = 'UpdateModifiedCustomControlTemplate',
  EnableCustomControlDependencies = 'EnableCustomControlDependencies',
  CreateCustomControlTemplateFromImportedApp = 'CreateCustomControlTemplateFromImportedApp',
  EditCustomControlTemplateFromImportedApp = 'EditCustomControlTemplateFromImportedApp',
  EditCustomControlFromImportedApp = 'EditCustomControlFromImportedApp',
  UnrestrictedAPISelfProvisioning = 'UnrestrictedAPISelfProvisioning',
  BusesDebugAccess = 'BusesDebugAccess'
}
