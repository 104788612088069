import { Injectable } from '@angular/core';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { ToolsService } from '../../core/services/tools.service';
import { CommunicationService } from './communication.service';

@Injectable({
  providedIn: 'root',
})
export class LeapXLShortcutsService {
  
  static Shortcuts = {
    OpenSharePanel: {
      hotkey: 'mod+shift+s',
      formatted: '⌘⇧s',
      description: 'Open - Share Panel',
    },
    OpenAppProperties: {
      hotkey: 'mod+shift+a',
      formatted: '⌘⇧a',
      description: 'Open - App Properties Panel',
    },
    OpenSmartTemplates: {
      hotkey: 'mod+shift+t',
      formatted: '⌘⇧t',
      description: 'Open - Smart Templates Panel',
    },
    OpenPreferencesPanel: {
      hotkey: 'mod+shift+p',
      formatted: '⌘⇧p',
      description: 'Open - Preferences Panel',
    },
    OpenToolbarPanel: {
      hotkey: 'mod+shift+o',
      formatted: '⌘⇧o',
      description: 'Open - Toolbar Panel',
    },
    SelectAll: {
      hotkey: 'mod+a',
      formatted: '⌘a',
      description: 'Select All Representative Elements',
    },
    Properties: {
      hotkey: 'mod+z',
      formatted: '⌘z',
      description: 'Open Properties Panel',
    },
    ProcessPanel: {
      hotkey: 'mod+p',
      formatted: '⌘p',
      description: 'Open Process Panel',
    },
    Help: {
      hotkey: 'mod+/',
      formatted: '⌘/',
      description: 'Open Microlearning',
    },
    Copy: {
      hotkey: 'mod+c',
      formatted: '⌘c',
      description: 'Copy Representative Element',
    },
    Cut: {
      hotkey: 'mod+x',
      formatted: '⌘x',
      description: 'Cut Representative Element',
    },
    Paste: {
      hotkey: 'mod+v',
      formatted: '⌘v',
      description: 'Paste Representative Element',
    },
    Duplicate: {
      hotkey: 'mod+d',
      formatted: '⌘d',
      description: 'Duplicate Representative Element',
    },
    CreateComponent: {
      hotkey: 'mod+shift+c',
      formatted: '⌘⇧c',
      description: 'Create Component',
    },
    HideShow: {
      hotkey: 'mod+shift+h',
      formatted: '⌘⇧h',
      description: 'Hide / Show Representative Element',
    },
    LockUnlock: {
      hotkey: 'mod+l',
      formatted: '⌘l',
      description: 'Lock / Unlock Representative Element',
    },
    Delete: {
      hotkey: 'backspace',
      hotkeyAlt: 'del',
      formatted: '⌫',
      description: 'Delete Representative Element',
    },
    DeleteNoWarning: {
      hotkey: 'mod+backspace',
      hotkeyAlt: 'mod+del',
      formatted: '⌘⌫',
      description: 'Delete Representative Element Without Confirmation',
    },
    History: {
      hotkey: 'mod+h',
      formatted: '⌘h',
      description: 'Representative Element History Changes',
    },
    ShowHideLockView: {
      hotkey: 'mod+shift+l',
      formatted: '⌘⇧l',
      description: 'Show / Hide Lock View',
    },
    OpenNavigationViewMenu: {
      hotkey: 'mod+shift+f',
      formatted: '⌘⇧f',
      description: 'Open Navigation View Menu',
    },
    DeselectRepresentativeMolecule: {
      hotkey: 'esc',
      formatted: 'esc',
      description: 'Deselect Representative Elements / Cancel Drag',
    },
  };
  private initialized = false;
  private ignoreElementClasses = [
    'wj-cell',
  ];
  private enabled = true;
  
  constructor(
    private _hotkeysService: HotkeysService,
    private toolsService: ToolsService,
    private genericDialogService: GenericDialogService,
    private communicationService: CommunicationService,
  ) {
    this.InitShortcuts();
  }
  
  InitShortcuts() {
    this.enabled = this.toolsService.IsEditor();
    
    if (!this.enabled) {
      return;
    }
    
    if (this.initialized) {
      return;
    }
    
    this._hotkeysService.add(new Hotkey(LeapXLShortcutsService.Shortcuts.OpenSharePanel.hotkey, (event: KeyboardEvent): boolean => {
      this.communicationService.Event.Editor.$OpenSharePanel.emit();
      
      return false; // Prevent bubbling
    }, undefined, LeapXLShortcutsService.Shortcuts.OpenSharePanel.description));
    
    this._hotkeysService.add(new Hotkey(LeapXLShortcutsService.Shortcuts.OpenSmartTemplates.hotkey, (event: KeyboardEvent): boolean => {
      this.communicationService.Event.Editor.$OpenSmartTemplateWizard.emit();
      
      return false; // Prevent bubbling
    }, undefined, LeapXLShortcutsService.Shortcuts.OpenSmartTemplates.description));
    
    this._hotkeysService.add(new Hotkey(LeapXLShortcutsService.Shortcuts.OpenAppProperties.hotkey, (event: KeyboardEvent): boolean => {
      this.communicationService.Event.Editor.$OpenAppProperties.emit();
      
      return false; // Prevent bubbling
    }, undefined, LeapXLShortcutsService.Shortcuts.OpenAppProperties.description));
    
    this._hotkeysService.add(new Hotkey(LeapXLShortcutsService.Shortcuts.OpenPreferencesPanel.hotkey, (event: KeyboardEvent): boolean => {
      this.communicationService.Event.Editor.$OpenPreferencesPanel.emit();
      
      return false; // Prevent bubbling
    }, undefined, LeapXLShortcutsService.Shortcuts.OpenPreferencesPanel.description));
    
    this._hotkeysService.add(new Hotkey(LeapXLShortcutsService.Shortcuts.OpenToolbarPanel.hotkey, (event: KeyboardEvent): boolean => {
      this.communicationService.Event.Editor.$OpenToolbarPanel.emit();
      
      return false; // Prevent bubbling
    }, undefined, LeapXLShortcutsService.Shortcuts.OpenToolbarPanel.description));
    
    this._hotkeysService.add(new Hotkey(LeapXLShortcutsService.Shortcuts.SelectAll.hotkey, (event: KeyboardEvent): boolean => {
      this.communicationService.Event.Editor.$SelectAllRepresentativeMolecules.emit();
      
      return false; // Prevent bubbling
    }, undefined, LeapXLShortcutsService.Shortcuts.SelectAll.description));
    
    this._hotkeysService.add(new Hotkey(LeapXLShortcutsService.Shortcuts.Properties.hotkey, (event: KeyboardEvent): boolean => {
      this.communicationService.Event.Editor.$OpenPropertiesPanel.emit();
      
      return false; // Prevent bubbling
    }, undefined, LeapXLShortcutsService.Shortcuts.Properties.description));
    
    this._hotkeysService.add(new Hotkey(LeapXLShortcutsService.Shortcuts.ProcessPanel.hotkey, (event: KeyboardEvent): boolean => {
      this.communicationService.Event.Editor.$OpenProcessPanel.emit();
      
      return false; // Prevent bubbling
    }, undefined, LeapXLShortcutsService.Shortcuts.ProcessPanel.description));
    
    this._hotkeysService.add(new Hotkey(LeapXLShortcutsService.Shortcuts.Help.hotkey, (event: KeyboardEvent): boolean => {
      this.communicationService.Event.Editor.$OpenMicrolearningForRepSelection.emit();
      
      return false; // Prevent bubbling
    }, undefined, LeapXLShortcutsService.Shortcuts.Help.description));
    
    this._hotkeysService.add(new Hotkey(LeapXLShortcutsService.Shortcuts.Copy.hotkey, (event: KeyboardEvent): boolean => {
      this.communicationService.Event.Editor.$CopyRepresentativeMolecule.emit();
      
      return false; // Prevent bubbling
    }, undefined, LeapXLShortcutsService.Shortcuts.Copy.description));
    
    this._hotkeysService.add(new Hotkey(LeapXLShortcutsService.Shortcuts.Cut.hotkey, (event: KeyboardEvent): boolean => {
      this.communicationService.Event.Editor.$CutRepresentativeMolecule.emit();
      
      return false; // Prevent bubbling
    }, undefined, LeapXLShortcutsService.Shortcuts.Cut.description));
    
    this._hotkeysService.add(new Hotkey(LeapXLShortcutsService.Shortcuts.Paste.hotkey, (event: KeyboardEvent): boolean => {
      this.communicationService.Event.Editor.$PasteRepresentativeMolecule.emit();
      
      return false; // Prevent bubbling
    }, undefined, LeapXLShortcutsService.Shortcuts.Paste.description));
    
    this._hotkeysService.add(new Hotkey(LeapXLShortcutsService.Shortcuts.Duplicate.hotkey, (event: KeyboardEvent): boolean => {
      this.communicationService.Event.Editor.$DuplicateRepresentativeMolecule.emit();
      
      return false; // Prevent bubbling
    }, undefined, LeapXLShortcutsService.Shortcuts.Duplicate.description));
    
    this._hotkeysService.add(new Hotkey(LeapXLShortcutsService.Shortcuts.CreateComponent.hotkey, (event: KeyboardEvent): boolean => {
      this.communicationService.Event.Editor.$CreateComponent.emit();
      
      return false; // Prevent bubbling
    }, undefined, LeapXLShortcutsService.Shortcuts.CreateComponent.description));
    
    this._hotkeysService.add(new Hotkey(LeapXLShortcutsService.Shortcuts.HideShow.hotkey, (event: KeyboardEvent): boolean => {
      this.communicationService.Event.Editor.$HideRepresentativeMolecule.emit();
      
      return false; // Prevent bubbling
    }, undefined, LeapXLShortcutsService.Shortcuts.HideShow.description));
    
    this._hotkeysService.add(new Hotkey(LeapXLShortcutsService.Shortcuts.LockUnlock.hotkey, (event: KeyboardEvent): boolean => {
      this.communicationService.Event.Editor.$LockUnlockRepresentativeMolecule.emit();
      
      return false; // Prevent bubbling
    }, undefined, LeapXLShortcutsService.Shortcuts.LockUnlock.description));
    
    this._hotkeysService.add(new Hotkey([LeapXLShortcutsService.Shortcuts.Delete.hotkey, LeapXLShortcutsService.Shortcuts.Delete.hotkeyAlt], (event: KeyboardEvent): boolean => {
      
      if (this.ignoreElementClasses.find(c => (event.target as any).classList.contains(c))) {
        return;
      }
      
      this.genericDialogService.OpenConfirmDialog({
        title: 'Delete Element',
        message: `Are you sure you want to delete?, this operation can't be undone`,
        confirmText: 'Ok',
        cancelText: 'Cancel',
      }).then(confirm => {
        if (confirm) {
          this.communicationService.Event.Editor.$DeleteRepresentativeMolecule.emit();
        }
      });
      
      return false; // Prevent bubbling
    }, undefined, LeapXLShortcutsService.Shortcuts.Delete.description));
    
    // this._hotkeysService.add(new Hotkey([LeapXLShortcutsService.Shortcuts.DeleteNoWarning.hotkey, LeapXLShortcutsService.Shortcuts.DeleteNoWarning.hotkeyAlt], (event: KeyboardEvent): boolean => {
    //   this.communicationService.Event.Editor.$OpenPreferencesPanel.emit();
    //
    //   return false; // Prevent bubbling
    // }, undefined, LeapXLShortcutsService.Shortcuts.Delete.description));
    
    this._hotkeysService.add(new Hotkey(LeapXLShortcutsService.Shortcuts.History.hotkey, (event: KeyboardEvent): boolean => {
      this.communicationService.Event.Editor.$OpenRepresentativeMoleculeHistoryChanges.emit();
      
      return false; // Prevent bubbling
    }, undefined, LeapXLShortcutsService.Shortcuts.History.description));
    
    this._hotkeysService.add(new Hotkey(LeapXLShortcutsService.Shortcuts.ShowHideLockView.hotkey, (event: KeyboardEvent): boolean => {
      this.communicationService.Event.Editor.$ToggleLockViewVisibility.emit();
      
      return false; // Prevent bubbling
    }, undefined, LeapXLShortcutsService.Shortcuts.ShowHideLockView.description));
    
    this._hotkeysService.add(new Hotkey(LeapXLShortcutsService.Shortcuts.OpenNavigationViewMenu.hotkey, (event: KeyboardEvent): boolean => {
      this.communicationService.Event.Editor.$OpenNavigationViewMenu.emit();
      return false; // Prevent bubbling
    }, undefined, LeapXLShortcutsService.Shortcuts.OpenNavigationViewMenu.description));
    
    this._hotkeysService.add(new Hotkey(LeapXLShortcutsService.Shortcuts.DeselectRepresentativeMolecule.hotkey, (event: KeyboardEvent): boolean => {
      this.communicationService.Event.Editor.$DeselectAllRepresentativeMolecule.emit();
      return false; // Prevent bubbling
    }, undefined, LeapXLShortcutsService.Shortcuts.DeselectRepresentativeMolecule.description));
    
    this.initialized = true;
  }
}
