import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from './../constants';
import { CommunicationService } from './communication.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectionStateService {
  public IsOnline = true;
  public icon = 'cloud';
  public ShowPopup = false;
  public ErrorPopup = false;
  public ToastMessage = '';
  public connectionWithServerEstablished = false;
  private apiUrl = Constants.Environment.apiUrl + 'admin/';
  
  constructor(protected http: HttpClient, private communicationService: CommunicationService) {
    this.communicationService.Event.System.Connection.$StateChange.subscribe(state => {
      // console.log('$StateChange');
      this.IsOnline = state;
    });
  }
  
  VerifyConnectionWithServer() {
    return this.http.get(this.apiUrl + `version`);
  }
  
  ShowOfflinePopup() {
    this.ToastMessage = 'Internet connection lost';
    this.icon = 'cloud_off';
    this.ShowPopup = true;
    setTimeout(() => {
      this.ShowPopup = false;
    }, 3000);
  }
  
  ShowOnlinePopup() {
    this.ToastMessage = 'Connected';
    this.icon = 'cloud';
    this.ShowPopup = true;
    setTimeout(() => {
      this.ShowPopup = false;
    }, 3000);
  }
  
  ShowNoConnectionStatePopup() {
    this.ToastMessage = 'No internet connection, try again later...';
    this.ShowPopup = true;
    setTimeout(() => {
      this.ShowPopup = false;
    }, 3000);
  }
  
  ShowErrorPopup() {
    this.ToastMessage =
      'No tengo fuelza yo... :(';
    // 'There was an error in the app, some features might not work!';
    this.icon = 'error_outline';
    this.ErrorPopup = true;
    setTimeout(() => {
      this.ErrorPopup = false;
    }, 3000);
  }
}
