import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiDataSourcesService } from '../../core/services/api-data-sources.service';
import { CacheService } from '../../core/services/cache.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { SpreadsheetService } from '../../spreadsheet/spreadsheet.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-datasource-rename',
  templateUrl: './datasource-rename.component.html',
  styleUrls: ['./datasource-rename.component.scss'],
})
export class DatasourceRenameComponent implements OnInit {
  @ViewChild('input', { static: true })
  input: ElementRef;
  dataSourceName = '';
  
  constructor(
    private dataSourceService: ApiDataSourcesService,
    private workAreaService: WorkAreaService,
    private snackerService: SnackerService,
    private communicationService: CommunicationService,
    private spreadsheetService: SpreadsheetService,
    private cacheService: CacheService,
  ) {
  }
  
  ngOnInit() {
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 200);
  }
  
  SaveDataSource() {
    this.dataSourceService.RenameDataSource(
      this.workAreaService.dataSourceRenameId,
      this.dataSourceName,
    ).subscribe((result) => {
      
      // error if contains data
      if (result) {
      
      } else {
        
        if (this.workAreaService.dataSourceRenameId === this.dataSourceService.openedDataSourceId) {
          this.spreadsheetService.wName = this.dataSourceName;
          this.communicationService.Event.System.App.$RefreshUI.emit();
        }
        
        this.communicationService.Event.Editor.DataSource.$ReloadDataSourcePanel.emit([]);
        this.snackerService.ShowMessageOnBottom('DataSource Renamed successfully', 'drive_file_rename_outline', null, true);
        this.cacheService.Clear(true);
        this.communicationService.Event.Editor.$RecreateProcessBuses.emit();
      }
    });
    this.workAreaService.dataSourceRenameWindow.Hide();
  }
}
