import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Permissions } from '../../admin/models/permissions.enum';
import { ApiDataSourcesService } from '../../core/services/api-data-sources.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { DataManagementService } from '../../core/services/data-management.service';
import { UserMenuService } from '../../core/services/user-menu.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-api-setup',
  templateUrl: './api-setup.component.html',
  styleUrls: ['./api-setup.component.scss'],
})
export class ApiSetupComponent implements OnInit, OnDestroy {
  
  DraggableWindowType = 'ApiSetup';
  
  @ViewChild('assigne', { static: false }) assigne;
  @ViewChild('classification', { static: false }) classification;
  @ViewChild('language', { static: false }) language;
  @ViewChild('dialect', { static: false }) dialect;
  @ViewChild('document', { static: false }) document;
  
  assignesList = [];
  classificationsList = [];
  languagesList = [];
  dialectsList = [];
  documentsList = [];
  
  assigneSelected = '';
  classificationSelected = '';
  languageSelected = '';
  dialectSelected = '';
  documentSelected = '';
  unrestrictedAPISelfProvisioning = false;
  userCompanyId = 0;
  
  constructor(private dsService: ApiDataSourcesService,
              private communicationService: CommunicationService,
              private workAreaService: WorkAreaService,
              private dataManagementService: DataManagementService,
              private clientStorageService: ClientStorageService,
              private snackerService: SnackerService,
              private userMenuService: UserMenuService,
  ) {
  }
  
  ngOnInit() {
    this.unrestrictedAPISelfProvisioning = this.userMenuService.checkPermission(Permissions.UnrestrictedAPISelfProvisioning);
    this.userCompanyId = this.clientStorageService.getCompanyId();
    this.getAuthorcontext('');
  }
  
  dropdownSelection(dropdown: string) {
    // console.log('dropdown change', dropdown);
    switch (dropdown) {
      case 'assignee':
        this.classificationSelected = '';
        this.languageSelected = '';
        this.dialectSelected = '';
        this.documentSelected = '';
        setTimeout(() => {
          this.getAuthorcontext(dropdown);
        }, 200);
        break;
      
      case 'classification':
        this.languageSelected = '';
        this.dialectSelected = '';
        this.documentSelected = '';
        setTimeout(() => {
          this.getAuthorcontext(dropdown);
        }, 200);
        break;
      
      case 'language':
        this.dialectSelected = '';
        this.documentSelected = '';
        setTimeout(() => {
          this.getAuthorcontext(dropdown);
        }, 200);
        break;
      
      case 'dialect':
        this.documentSelected = '';
        setTimeout(() => {
          this.getAuthorcontext(dropdown);
        }, 200);
        break;
      
      case 'document':
        
        break;
    }
  }
  
  getAuthorcontext(dropdown: string) {
    // console.log(this.documentsList, this.assignesList, this.dialectsList, this.languagesList);
    this.dsService.GetAuthorContext(this.assigneSelected, this.classificationSelected, this.languageSelected, this.dialectSelected, this.documentSelected).subscribe(Response => {
      
      if ((Response as any).error) {
        Response = [];
      }
      
      let dropdownObtained = '';
      
      switch (dropdown) {
        case 'assignee':
          
          this.classificationsList = Response;
          if (Response.length === 1) {
            this.classificationSelected = Response[0];
            setTimeout(() => {
              this.dropdownSelection('classification');
            }, 50);
          } else {
            setTimeout(() => {
              this.classification.open();
            }, 200);
          }
          
          dropdownObtained = 'Classification';
          break;
        
        case 'classification':
          this.languagesList = Response;
          if (Response.length === 1) {
            this.languageSelected = Response[0];
            setTimeout(() => {
              this.dropdownSelection('language');
            }, 50);
          } else {
            setTimeout(() => {
              this.language.open();
            }, 200);
          }
          
          dropdownObtained = 'Language';
          break;
        
        case 'language':
          this.dialectsList = Response;
          if (Response.length === 1) {
            this.dialectSelected = Response[0];
            setTimeout(() => {
              this.dropdownSelection('dialect');
            }, 50);
          } else {
            setTimeout(() => {
              this.dialect.open();
            }, 200);
          }
          
          dropdownObtained = 'Dialect';
          break;
        
        case 'dialect':
          this.documentsList = Response;
          if (Response.length === 1) {
            this.documentSelected = Response[0];
            setTimeout(() => {
              this.dropdownSelection('document');
            }, 50);
          } else {
            setTimeout(() => {
              this.document.open();
            }, 200);
          }
          
          dropdownObtained = 'Document';
          break;
        
        case '':
          
          if (this.unrestrictedAPISelfProvisioning) {
            this.assignesList = Response;
            if (Response.length === 1) {
              this.assigneSelected = Response[0];
              this.dropdownSelection('assignee');
            } else {
              setTimeout(() => {
                this.assigne.open();
              }, 200);
            }
          } else {
            this.assigneSelected = this.userCompanyId.toString();
            this.dropdownSelection('assignee');
          }
          
          dropdownObtained = 'Assignee';
          break;
      }
      
      if (Response && Response.length === 0) {
        this.snackerService.ShowMessageOnBottom(`No ${ dropdownObtained } available`, 'do_not_disturb_on', null);
      }
      
    });
  }
  
  AddApi() {
    this.dsService.SetAuthorApi(this.assigneSelected, this.classificationSelected, this.languageSelected, this.dialectSelected, this.documentSelected).subscribe(response => {
      
      if (response.error) {
        const errorInfo = {
          exception: '',
          description: '',
        };
        
        try {
          const error = JSON.parse(response.error.errors[1].replace('Response: ', ''));
          
          errorInfo.exception = error.EXCEPTION;
          errorInfo.description = error.DESCRIPTION;
        } catch (err) {
          errorInfo.exception = 'UnableToObtainApi';
          errorInfo.description = 'Can not obtain information from Author Api';
        }
        
        this.dataManagementService.DisplayAuthorApiErrorMessage(errorInfo.exception, errorInfo.description);
        
      } else {
        this.communicationService.Event.Editor.DataSource.$ReloadDataSourcePanel.emit([]);
      }
    });
  }
  
  ngOnDestroy(): void {
    // console.log('closed');
    this.workAreaService.apiSetupDraggableWindow = null;
  }
}
