import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cbb-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  @Input() progressValue = 0;
  @Input() mode = 'determinate';
  
  
  constructor() {
  }
  
  ngOnInit() {
  }
  
}
