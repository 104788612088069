import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BuilderService } from '../../../../core/builder/builder.service';
import { ProcessorService } from '../../../../core/molecular/services/processor.service';
import { WorkAreaService } from '../../../../workarea/workarea.service';
import { LeapXLEventType } from '../../../enums/leapxl-event-type.enum';
import { RepresentativeMoleculesType } from '../../../enums/representative-molecules-types.enum';
import { DragService } from '../../services/drag.service';
import { BaseMoleculeComponent } from '../base-molecule/base-molecule.component';
import { View } from './../../../interfaces/view';

@Component({
  selector: 'app-breadcrumb-molecule',
  templateUrl: './breadcrumb-molecule.component.html',
  styleUrls: ['./breadcrumb-molecule.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [
    trigger('breadcrumbAnimation', [
      transition('void => enter', [style({ transform: 'scale(0)', opacity: 0 }), animate('100ms', style({ transform: 'scale(1)', opacity: 1 }))]),
      transition('enter => void', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('100ms', style({ transform: 'translateY(-5%)', opacity: 0 })),
      ]),
      transition('void => fade', [style({ opacity: 0 }), animate(500)]),
      transition('fade => void', [animate(200, style({ opacity: 0 }))]),
      transition('void => enterUp', [
        style({ transform: 'translateY(10%)', opacity: 0 }),
        animate('400ms', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
      transition('enterUp => void', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('200ms', style({ transform: 'translateY(-5%)', opacity: 0 })),
      ]),
      transition('void => slideUp', [
        style({ transform: 'translateY(10%)', opacity: 0 }),
        animate('400ms', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
      transition('slideUp => void', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('200ms', style({ transform: 'translateY(-5%)', opacity: 0 })),
      ]),
      transition('void => slideRight', [
        style({ transform: 'translateX(-20px)', opacity: 0 }),
        animate('200ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition('slideRight => void', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('200ms ease', style({ transform: 'translateX(30px)', opacity: 0 })),
      ]),
      transition('void => slideLeft', [
        style({ transform: 'translateX(30px)', opacity: 0 }),
        animate('200ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition('slideLeft => void', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('200ms ease', style({ transform: 'translateX(-30px)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class BreadcrumbMoleculeComponent extends BaseMoleculeComponent implements OnInit, OnDestroy {
  MoleculeType = 'Representative';
  Type = RepresentativeMoleculesType.Breadcrumb;

  @ViewChild('breadcrumbWrapper', { static: true })
  labelWrapper: ElementRef;
  animation = 'none';

  constructor(
    public builderService: BuilderService,
    public dragService: DragService,
    public workAreaService: WorkAreaService,
    public processorService: ProcessorService,
    bottomSheet: MatBottomSheet,
    public el: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
    super(bottomSheet, el, changeDetectorRef);
  }

  get BreadcrumbData() {
    if (this.IsEditor) {
      return [{ name: 'View Name 1' }, { name: 'View Name 2' }, { name: 'View Name 3' }, { name: 'View Name 4...' }];
    }

    const breadcrumb = [];
    this.runtimeService.ViewNavigationBreadcrumbs.forEach(viewId => {
      const view = this.cobbleService.Cobble.properties.views.find(v => v.id === viewId);
      const renamedView = this.context.Properties.viewNames ? this.context.Properties.viewNames.find(vn => vn.id === view.id) : null;
      if (view) {
        breadcrumb.push(renamedView || view);
      }
    });

    this.context.Value = breadcrumb.map(v => v.name).join(this.context.Properties.separator);
    return breadcrumb;
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.IsEditor) {
      this.DetachChangeDetection();
    }
    this.context.Type = RepresentativeMoleculesType.Breadcrumb;
    this.RefreshGridsterConfiguration();
    this.Subscriptions = this.context.$UpdateTextValue.subscribe(update => {
      // this.RefreshText();
    });

    if (!this.cobbleService.Cobble.running) {
    } else {
      this.animation = 'this.context.Properties.animation';
    }

    this.Subscriptions.add(
      this.communicationService.Event.Runtime.System.$ViewIdChanged.subscribe(viewId => {
        console.log('=event=');
        this.FireRepresentativeMoleculeEvent(LeapXLEventType.ViewChange, this.cobbleService.Cobble.properties.views.find(v => v.id).name, false);
      })
    );
  }

  AttachEditorEventListeners() {
    const dragoverEventListener = this.renderer.listen(this.labelWrapper.nativeElement, 'dragover', evt => {
      this.drag(evt, true);
    });
    const dragleaveEventListener = this.renderer.listen(this.labelWrapper.nativeElement, 'dragover', evt => {
      this.drag(evt, false);
    });
    const dropEventListener = this.renderer.listen(this.labelWrapper.nativeElement, 'drop', evt => {
      this.DataDropped(evt);
    });
  }

  NavigateToView(view: View) {
    this.communicationService.Event.Runtime.System.$ViewIdChanged.emit(view.id);
  }

  UpdateData() {}

  FireClickEvent(e: any) {
    this.FireRepresentativeMoleculeEvent('click');
  }
}
