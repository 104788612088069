import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class GetRepresentativeMoleculeValue extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    data: any,
  ) {
    try {
      if (rule && rule.repMoleculeIds && rule.repMoleculeIds) {
        const repMolecule = this.busService.Get(rule.repMoleculeIds);
        const value = repMolecule.GetValue;
        data = value;
        // console.log(`molecule working, showing element`);
      } else {
        const repMolecule = this.busService.Get(repMoleculeId);
        const value = repMolecule.GetValue;
        data = value;
      }
      
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
