import { HttpEventType } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileUploader } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { ApiLeapxlImportService } from '../../core/services/api-leapxl-import.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { LeapXLFileFormat } from '../../shared/enums/leapxl-file-format.enum';
import { CommunicationService } from '../../shared/services/communication.service';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { WorkAreaService } from '../workarea.service';
import { LeapxlImportSpreadsheetDataComponent } from './leapxl-import-spreadsheet-data/leapxl-import-spreadsheet-data.component';

@Component({
  selector: 'app-leapxl-import',
  templateUrl: './leapxl-import.component.html',
  styleUrls: ['./leapxl-import.component.scss'],
})
export class LeapxlImportComponent implements OnInit, OnDestroy {
  @ViewChild('passwordInput', { static: false })
  passwordInput: ElementRef;
  imagesLocation = 'assets/images/';
  importPassword = '';
  applicationName = '';
  encryptPassword = '';
  fileName = '';
  encrypt = false;
  uploader: FileUploader = new FileUploader({});
  acceptedFileTypes = '.lxlapp, .leapapp, .leapcmp';
  dataFile = null;
  highlightDropArea = false;
  fileTypeImg = '';
  title = '';
  iconSrc = '';
  uploadingProgress = 0;
  uploading = false;
  importing = false;
  importCompleted = false;
  userPermissions = [];
  subscriptions = new Subscription();
  fileInfo = null;
  isApplication = false;
  
  constructor(
    private leapxlImportService: ApiLeapxlImportService,
    private snackerService: SnackerService,
    private clientStorageService: ClientStorageService,
    private communicationService: CommunicationService,
    private workAreaService: WorkAreaService,
    private dialog: MatDialog,
    private errorMessengerService: ErrorMessengerService,
  ) {
    this.userPermissions = clientStorageService.getPermissions();
    // const dialogRef = this.dialog.open(LeapxlImportSpreadsheetDataComponent, {
    //   data: {}
    // });
  }
  
  ngOnInit() {
    this.subscriptions.add(
      this.communicationService.Event.System.$ProcessImportAppFile.subscribe(changes => {
        console.log('=event=');
        console.log(changes);
        this.ImportFile(changes);
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.System.$ProcessImportAppFileCancel.subscribe(ds => {
        console.log('=event=');
        this.CancelImport();
      }),
    );
  }
  
  HighlightDropArea(e) {
    e.preventDefault();
    e.stopPropagation();
    this.highlightDropArea = true;
  }
  
  HideDropArea(e) {
    e.preventDefault();
    e.stopPropagation();
    this.highlightDropArea = false;
  }
  
  handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    
    const dt = e.dataTransfer;
    const files = dt.files;
    
    this.handleFiles(files);
  }
  
  handleFiles(files: FileList) {
    if (files.length > 0) {
      this.dataFile = files[0];
      this.title = '';
      
      const fileFormat = this.ExtractFileFormatFromName(this.dataFile.name);
      switch (fileFormat) {
        case LeapXLFileFormat.Application:
        case LeapXLFileFormat.LegacyApplication:
          this.title = 'LeapXL Application File';
          this.fileTypeImg = 'leap-file-app.png';
          this.fileName = this.dataFile.name;
          this.applicationName = this.fileName.replace(`.${ fileFormat }`, '');
          this.isApplication = true;
          
          break;
        case LeapXLFileFormat.Component:
          this.title = 'LeapXL Component File';
          this.fileTypeImg = 'leap-file-cmp.png';
          this.fileName = this.dataFile.name;
          this.applicationName = this.fileName.replace(`.${ fileFormat }`, '');
          this.isApplication = false;
          
          break;
      }
      
      this.iconSrc = this.imagesLocation + '/' + this.fileTypeImg;
      
      setTimeout(() => {
        this.passwordInput.nativeElement.focus();
        this.passwordInput.nativeElement.select();
      }, 300);
    } else {
      this.dataFile = null;
    }
  }
  
  UploadFile() {
    console.log(this.dataFile);
    if (this.dataFile && this.importPassword !== '') {
      const splittedFileName: string[] = this.dataFile.name.split('.');
      const extension = splittedFileName.pop();
      const name = splittedFileName.join('.');
      
      console.log(extension);
      this.importing = false;
      this.fileInfo = {
        dataFile: this.dataFile,
        encryptPassword: this.encryptPassword,
        importPassword: this.importPassword,
        name,
        extension,
        applicationName: this.applicationName,
      };
      this.leapxlImportService
      .ImportApplicationSpreadsheetData(this.dataFile, this.encryptPassword, this.importPassword, name, extension, this.applicationName)
      .subscribe(
        response => {
          if (response) {
            switch (response.type) {
              case HttpEventType.Sent:
                this.uploading = true;
                this.uploadingProgress = 0;
                break;
              case HttpEventType.ResponseHeader:
                this.uploading = false;
                this.uploadingProgress = 0;
                this.importing = true;
                break;
              case HttpEventType.DownloadProgress:
                break;
              case HttpEventType.UploadProgress:
                const percent = Math.round(100 * (response.loaded / response.total));
                this.uploadingProgress = percent;
                if (percent >= 100) {
                  setTimeout(() => {
                    this.importing = true;
                  }, 100);
                }
                break;
              case HttpEventType.Response:
                console.log(response);
                this.importing = false;
                
                if (
                  response.body.spreadSheetsData &&
                  response.body.stylesData &&
                  ((Array.isArray(response.body.spreadSheetsData) && response.body.spreadSheetsData.length > 0) ||
                    (Array.isArray(response.body.stylesData) && response.body.stylesData.length > 0))
                ) {
                  const dialogRef = this.dialog.open(LeapxlImportSpreadsheetDataComponent, {
                    data: {
                      ds: response.body,
                      isApplication: extension === LeapXLFileFormat.Application || extension === LeapXLFileFormat.LegacyApplication,
                    },
                  });
                } else {
                  this.ImportFile();
                }
                
                break;
              default:
                this.uploading = false;
                this.uploadingProgress = 0;
                this.importing = false;
                break;
            }
          } else {
            this.uploading = false;
            this.uploadingProgress = 0;
            this.importing = false;
          }
        },
        error => {
          this.uploading = false;
          this.uploadingProgress = 0;
          this.importing = false;
          this.snackerService.ShowMessageOnBottom('Error importing Application', 'file_upload_off');
          this.errorMessengerService.HandleError(error);
        },
        () => {
          this.uploading = false;
          this.uploadingProgress = 0;
          this.importing = false;
        },
      );
    }
  }
  
  ImportFile(dsChanges: { spreadSheetsData: any[]; stylesData: any[] } = null) {
    this.importing = false;
    this.leapxlImportService
    .ImportApplication(
      this.fileInfo.dataFile,
      this.fileInfo.encryptPassword,
      this.fileInfo.importPassword,
      this.fileInfo.name,
      this.fileInfo.extension,
      this.fileInfo.applicationName,
      dsChanges,
    )
    .subscribe(
      response => {
        if (response) {
          switch (response.type) {
            case HttpEventType.Sent:
              this.uploading = true;
              this.uploadingProgress = 0;
              break;
            case HttpEventType.ResponseHeader:
              this.uploading = false;
              this.uploadingProgress = 0;
              this.importing = true;
              break;
            case HttpEventType.DownloadProgress:
              break;
            case HttpEventType.UploadProgress:
              const percent = Math.round(100 * (response.loaded / response.total));
              this.uploadingProgress = percent;
              if (percent >= 100) {
                setTimeout(() => {
                  this.importing = true;
                }, 100);
              }
              break;
            case HttpEventType.Response:
              this.importing = false;
              this.importCompleted = true;
              setTimeout(() => {
                this.importCompleted = false;
                if (this.workAreaService.importAppWindow) {
                  this.workAreaService.importAppWindow.Hide();
                }
              }, 1500);
              
              this.snackerService.ShowMessageOnBottom(
                `${ this.isApplication ? 'App' : 'Component' } imported successfully!`,
                'upload_file',
                4000,
                true,
              );
              
              this.communicationService.Event.Editor.AppsTree.$RefreshAppsTree.emit();
              
              if (this.isApplication) {
                this.communicationService.Event.System.Update.$UpdateDashboardApps.emit(true);
              }
              
              setTimeout(() => {
                this.communicationService.Event.Editor.WorkArea.$CloseAllDraggableWindows.emit();
              }, 150);
              
              break;
            default:
              this.uploading = false;
              this.uploadingProgress = 0;
              this.importing = false;
              break;
          }
        } else {
          this.uploading = false;
          this.uploadingProgress = 0;
          this.importing = false;
        }
      },
      error => {
        this.uploading = false;
        this.uploadingProgress = 0;
        this.importing = false;
        this.snackerService.ShowMessageOnBottom('Error importing Application', 'file_upload_off');
        this.errorMessengerService.HandleError(error);
      },
      () => {
        this.uploading = false;
        this.uploadingProgress = 0;
        this.importing = false;
        
      },
    );
  }
  
  CancelImport() {
    this.uploading = false;
    this.uploadingProgress = 0;
    this.importing = false;
    this.importCompleted = false;
    this.fileInfo = null;
    this.communicationService.Event.Editor.WorkArea.$CloseAllDraggableWindows.emit();
  }
  
  ExtractFileFormatFromName(filename: string): string {
    return filename.split('.')[filename.split('.').length - 1];
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
