import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { ToolsService } from '../../core/services/tools.service';

@Component({
  selector: 'app-datasource-rearrange',
  templateUrl: './datasource-rearrange.component.html',
  styleUrls: ['./datasource-rearrange.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DatasourceRearrangeComponent implements OnInit {
  
  fields = [];
  width = 300;
  height = 300;
  orderedItems = [];
  includeCheckbox = true;
  selectAll = true;
  formStyle = 'vertical';
  padding = 7;
  separation = 4;
  formLines = 4;
  
  constructor(public dialogRef: MatDialogRef<DatasourceRearrangeComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private toolsService: ToolsService,
              private clientStorageService: ClientStorageService) {
  }
  
  ngOnInit(): void {
    if (this.data) {
      if (this.data.dataElements && this.data.dataElements.length > 0) {
        this.data.dataElements.forEach(de => {
          de.leapid = this.toolsService.GenerateGuid();
          this.fields.push({ cols: 1, rows: 1, name: de.InternalName, include: true, leapid: de.leapid });
        });
      } else if (this.data.dataSource) {
        this.data.dataSource.dataItems.forEach(di => {
          di.leapid = this.toolsService.GenerateGuid();
          this.fields.push({ cols: 1, rows: 1, name: di.value, include: true, leapid: di.leapid });
        });
      }
    }
    
    this.formLines = Math.ceil(this.fields.length / 8);
    this.RefreshInputsSizing();
  }
  
  LinesChange() {
    this.RefreshInputsSizing();
  }
  
  StyleChange() {
    
    if (this.formStyle === 'vertical') {
      this.formLines = Math.ceil(this.formLines / 2);
    } else {
      if (this.fields.length > 6) {
        this.formLines = this.formLines * 3;
      } else {
        this.formLines = this.fields.length;
      }
    }
    
    this.LinesChange();
  }
  
  RefreshInputsSizing() {
    const cols = this.formStyle === 'vertical' ? this.formLines : Math.ceil((this.fields.length / this.formLines));
    const rows = this.formStyle === 'horizontal' ? this.formLines : Math.ceil(this.fields.length / this.formLines);
    
    console.log(cols, rows);
    
    this.width = (195 * cols);
    this.height = rows * 41;
    
    this.toolsService.GenerateClassDynamically(`.form-arrange ul`, `grid-template-rows: repeat(${ rows }, auto); !important`);
  }
  
  ToggleAll(checked: boolean) {
    this.fields.forEach(f => f.include = checked);
  }
  
  ToggleRearrangePanel(event: any) {
    const condition = event.checked ? 'Y' : 'N';
    // set flag on backend
    this.clientStorageService.setHideRearrangeDatasource(condition);
  }
  
  Create() {
    console.log(this.fields);
    
    if (this.data.dataElements && this.data.dataElements.length > 0) {
      this.fields.filter(f => f.include).forEach((field) => {
        const item = this.data.dataElements.find(di => di.leapid === field.leapid);
        this.orderedItems.push(item);
      });
      
      this.data.dataElements = this.orderedItems;
    } else if (this.data.dataSource) {
      
      let min = Math.min(...this.data.dataSource.dataItems.map(di => di.col));
      const minIndex = min;
      this.fields.filter(f => f.include).forEach((field) => {
        const item = this.data.dataSource.dataItems.find(di => di.leapid === field.leapid);
        this.orderedItems.push(item);
        min++;
      });
      
      this.data.dataSource.dataItems = this.orderedItems;
      // this.data.dataSource.cols = this.orderedItems.length;
      this.data.dataSource.firstColIndex = minIndex;
      this.data.dataSource.lastColIndex = Math.max(...this.data.dataSource.dataItems.map(di => di.col));
      
    }
    
    this.dialogRef.close({ dataSource: this.data.dataSource, dataElements: this.data.dataElements, formStyle: this.formStyle, padding: this.padding > 0 ? this.padding : 1, separation: this.separation > 0 ? this.separation : 1, formLines: this.formLines > 0 ? this.formLines : 1 });
  }
}
