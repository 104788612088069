import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit, OnDestroy {
  @Input() optionSelected = '';
  @Input() placeholder = '';
  @Input() closeOnOptionSelected = true;
  @Input() highlight: boolean = false;
  @Input() highlightBorder: boolean = false;
  @Output() isOpen = new EventEmitter<boolean>();
  isDropdownOpen = false;
  documentClickSubscription: Subscription;
  uuid = '';
  
  constructor() {
  }
  
  ngOnInit(): void {
    this.uuid = Math.random().toString(36).slice(-6);
    
    this.documentClickSubscription = fromEvent(document, 'click')
    .subscribe((event: Event) => {
      const target = (event.target as HTMLElement);
      const isInsideDropdown = target.closest('.dropdown-window');
      const buttonId = target.closest('.dropdown-button')?.id;
      const checkboxIsClicked = target.classList.contains('mat-checkbox-inner-container');
      const inputClicked = target.classList.contains('mat-input-element');
      
      if ((this.uuid === buttonId &&
        (target.classList.contains('dropdown-button') ||
          target.classList.contains('dropdown-window') ||
          target.classList.contains('mat-icon')) ||
        (!this.closeOnOptionSelected && isInsideDropdown)) || checkboxIsClicked || inputClicked) {
        return;
      }
      
      this.isDropdownOpen = false;
      this.isOpen.emit(this.isDropdownOpen);
    });
  }
  
  ngOnDestroy(): void {
    this.documentClickSubscription?.unsubscribe();
  }
  
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    this.isOpen.emit(this.isDropdownOpen);
  }
}
