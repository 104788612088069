export const chartTemplate = [
  {
    icon: 'insert_chart_outlined',
    moleculeType: 'Representative',
    name: 'Chart',
    type: 'Chart',
    textToDisplay: '',
    buses: [],
    debounce: {
      events: [],
    },
    minValue: null,
    maxValue: null,
    moleculesAllow: [],
    creationFromTemplate: false,
    events: ['init', 'unique'],
    defaultEvent: 'init',
    receptors: ['value-input', 'value-output', 'tooltip-input', 'badge-value-input', 'badge-value-output',
      'none'],
    dataSource: {},
    styleMetadata: {
      styles: [],
      specificStyles: {
        dimension: null,
        font: null,
        hover: null,
        frame: null,
        appearance: null,
      },
      manualAdjustedProperties: [],
    },
    advanced: true,
    editableProperties: ['cols', 'rows', 'x', 'y', 'opacity', 'shadow', 'backgroundType'],
    properties: {
      loadingText: '',
      align: 'right',
      allowEmptyValues: false,
      progressType: 'progress',
      progressColor: '#EA5C2F',
      progressBackgroundColor: '#E5957C',
      progressDiameter: 50,
      progressMode: 'indeterminate',
      progressStrokeWidth: 5,
      hover: {
        hoverBackground: 'white',
        hoverBackgroundOpacity: 1,
        hoverBorderRadius: 0,
        hoverBorderStyle: 'none',
        hoverBorderColor: 'transparent',
        hoverBorderWidth: 0,
        hoverTextDecoration: null,
        hoverPointer: null,
      },
      options: [
        {
          value: 'value 1',
          text: 'Text 1',
        },
      ],
      show: true,
      responsive: {
        desktop: {
          customGuidelines: {
            x: [],
            y: [],
          },
          id: 0,
          cols: 70,
          rows: 50,
          colsQty: 0,
          rowsQty: 0,
          layer: 1,
          centerPositioning: false,
          x: 0,
          y: 0,
          font: {
            fontSize: 16,
            fontColor: '#000000',
            fontFamily: 'inherit',
            fontStyle: 'inherit',
            fontWeight: 'normal',
          },
          chartOptions: {
            animations: true,
            switchRowCols: false,
            ngxChartType: 'verticalBarChart',
            width: null,
            height: null,
            closedCurveType: 'Linear Closed',
            curveType: 'Linear',
            schemeType: 'ordinal',
            colorScheme: 'cool',
            rangeFillOpacity: 0.15,
            showXAxis: true,
            showYAxis: true,
            gradient: false,
            showLegend: true,
            legendTitle: 'Legend',
            legendPosition: 'right',
            showXAxisLabel: true,
            tooltipDisabled: false,
            showText: true,
            xAxisLabel: 'X Axis',
            showYAxisLabel: true,
            yAxisLabel: 'Y Axis',
            showGridLines: true,
            barPadding: 8,
            groupPadding: 10,
            roundDomains: false,
            maxRadius: 10,
            minRadius: 3,
            showSeriesOnHover: true,
            roundEdges: true,
            xScaleMin: null,
            xScaleMax: null,
            yScaleMin: null,
            yScaleMax: null,
            showDataLabel: true,
            noBarWhenZero: true,
            trimXAxisTicks: true,
            trimYAxisTicks: true,
            rotateXAxisTicks: true,
            maxXAxisTickLength: 16,
            maxYAxisTickLength: 16,
            showLabels: true,
            explodeSlices: false,
            doughnut: false,
            arcWidth: 0.25,
            autoScale: true,
            timeline: false,
            margin: false,
            marginTop: 40,
            marginRight: 40,
            marginBottom: 40,
            marginLeft: 40,
            gaugeMin: 0,
            gaugeMax: 100,
            gaugeLargeSegments: 10,
            gaugeSmallSegments: 5,
            gaugeTextValue: '',
            gaugeUnits: 'Units',
            gaugeAngleSpan: 240,
            gaugeStartAngle: -120,
            gaugeShowAxis: true,
            gaugePreviousValue: 0,
            linearGaugeValue: 0,
            innerPadding: 10,
            pinched: true,
            inverted: false,
            dynamic: true,
            labelColor: '#fff',
            labelSize: 13,
            funnelBottomWidth: 0.33333,
            funnelPinchLevels: 1,
            curveHeight: 20,
          },
          
          tableOptions: {
            collapse: true,
            bodyRoundCorners: false,
            search: true,
            header: true,
            verticallyCenter: true,
            rowSeparator: false,
            columnSeparator: false,
            rowPadding: true,
            tableOrder: [],
            tableWidth: {},
          },
        },
        smartphone: {
          customGuidelines: {
            x: [],
            y: [],
          },
          id: null,
          cols: null,
          rows: null,
          colsQty: null,
          rowsQty: null,
          layer: null,
          centerPositioning: false,
          x: null,
          y: null,
          font: {
            fontSize: null,
            fontColor: null,
            fontFamily: null,
            fontStyle: null,
            fontWeight: null,
          },
          chartOptions: {
            animations: null,
            switchRowCols: null,
            ngxChartType: null,
            width: null,
            height: null,
            closedCurveType: null,
            curveType: null,
            schemeType: null,
            colorScheme: null,
            rangeFillOpacity: null,
            showXAxis: null,
            showYAxis: null,
            gradient: null,
            showLegend: null,
            legendTitle: null,
            legendPosition: null,
            showXAxisLabel: null,
            tooltipDisabled: null,
            showText: null,
            xAxisLabel: null,
            showYAxisLabel: null,
            yAxisLabel: null,
            showGridLines: null,
            barPadding: null,
            groupPadding: null,
            roundDomains: null,
            maxRadius: null,
            minRadius: null,
            showSeriesOnHover: null,
            roundEdges: null,
            xScaleMin: null,
            xScaleMax: null,
            yScaleMin: null,
            yScaleMax: null,
            showDataLabel: null,
            noBarWhenZero: null,
            trimXAxisTicks: null,
            trimYAxisTicks: null,
            rotateXAxisTicks: null,
            maxXAxisTickLength: null,
            maxYAxisTickLength: null,
            showLabels: null,
            explodeSlices: null,
            doughnut: null,
            arcWidth: null,
            autoScale: null,
            timeline: null,
            margin: null,
            marginTop: null,
            marginRight: null,
            marginBottom: null,
            marginLeft: null,
            gaugeMin: null,
            gaugeMax: null,
            gaugeLargeSegments: null,
            gaugeSmallSegments: null,
            gaugeTextValue: null,
            gaugeUnits: null,
            gaugeAngleSpan: null,
            gaugeStartAngle: null,
            gaugeShowAxis: null,
            gaugePreviousValue: null,
            linearGaugeValue: null,
            innerPadding: null,
            pinched: null,
            inverted: null,
            dynamic: null,
            labelColor: null,
            labelSize: null,
            funnelBottomWidth: null,
            funnelPinchLevels: null,
            curveHeight: null,
          },
          tableOptions: {
            search: true,
            header: true,
            verticallyCenter: null,
            rowSeparator: null,
            columnSeparator: null,
            rowPadding: null,
            tableOrder: [],
            tableWidth: {},
          },
        },
      },
      opacity: 1,
      orientation: 'col',
      enable: true,
      altText: '',
      dateRange: false,
      search: false,
      multiSelect: false,
      autoDataRefresh: true,
      ignoreValueDataIndex: false,
      textDecoration: 'none',
      name: 'Chart',
      background: {
        backgroundColor: '#ffffff',
        backgroundTypeImage: false,
        backgroundImageUrl: '',
        backgroundOpacity: 1,
      },
      maskType: 'none',
      mask: '',
      badge: {
        badgeEnable: true,
        badgeBackgroundColor: '#f22525',
        badgeFontFamily: 'Source Sans Pro',
        badgeFontColor: 'white',
        badgeStyle: 'circle',
        badgePosition: 'right',
      },
      bordersValues: {
        borderWidth: 1,
        borderStyle: 'none',
        borderColor: '#212529',
      },
      shadowValues: {
        hShadow: 0,
        vShadow: 0,
        shadowColor: '#212529',
        shadowBlur: 0,
      },
      preferences: {},
      view: 1,
      chartLibrary: 'ngxCharts',
      chartType: 'bar',
      chartShowLines: true,
      chartLegend: true,
      chartScales: true,
      chartLabels: true,
      chartOrientation: 'left',
      tabindex: 0,
      textToDisplay: 'Textbox',
      tooltip: '',
      placeholder: 'Enter text',
      minItemCols: 5,
      maxItemCols: 200,
    },
    molecules: [],
    placeholders: [],
    isSelected: false,
    enable: true,
    value: null,
    children: [],
    replaceableByRepresentative: true,
    loading: false,
    autoGenerationInfo: [],
    moleculeSubType: 'Chart',
    optionsMenu: {
      configuration: true,
      molecules: true,
      compound: false,
      cobblet: true,
      remove: true,
      versioning: true,
      dataSource: true,
      duplicate: true,
    },
  },
];
