import { Injectable } from '@angular/core';
import { AppInjector } from '../../app-injector';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { CobbleService } from '../representative-molecule/services/cobble.service';
import { CommunicationService } from './communication.service';

@Injectable({
  providedIn: 'root',
})
export class CrossOriginIframeService {
  
  public MessageType = {
    EmulatorEventsFlow: 'EmulatorEventsFlow',
  };
  private cobbleService: CobbleService;
  
  constructor(private communicationService: CommunicationService,
              private clientStorageService: ClientStorageService) {
  }
  
  SendToParent(type: string, value: any) {
    window.parent.postMessage({ type, data: value }, '*');
  }
  
  MessageReceived(event: MessageEvent) {
    switch (event.data.type) {
      case this.MessageType.EmulatorEventsFlow:
        this.HandleEmulatorEventsFlowDataReceived(event.data.data);
        break;
    }
  }
  
  private HandleEmulatorEventsFlowDataReceived(data: any) {
    const injector = AppInjector.getInjector();
    this.cobbleService = this.cobbleService || injector.get(CobbleService);
    
    if (this.cobbleService.Cobble.id > 0 &&
      this.cobbleService.Cobble.running === undefined &&
      data.data.userId === this.clientStorageService.getUserId() &&
      data.data.appId === this.cobbleService.Cobble.id) {
      switch (data.type) {
        case 'emulatorProcessorStart':
          this.communicationService.Event.Runtime.Emulator.$ProcessorStartRunning.emit(data.data.processData);
          break;
        case 'emulatorParticleProcessed':
          this.communicationService.Event.Runtime.Emulator.$ParticleProcessCompleted.emit(data.data.completedProcess);
          break;
        case 'emulatorProcessorStop':
          this.communicationService.Event.Runtime.Emulator.$StopProcessor.emit(data.data.processData);
          break;
      }
    }
  }
}
