import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { SnackerService } from '../../services/snacker.service';

@Component({
  selector: 'app-background-task-snackbar',
  templateUrl: './background-task-snackbar.component.html',
  styleUrls: ['./background-task-snackbar.component.scss'],
  animations: [
    trigger('enterUp', [
      transition(':enter', [
        style({ transform: 'translateY(10%)', opacity: 0 }),
        animate('400ms', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('100ms', style({ transform: 'translateY(-5%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class BackgroundTaskSnackbarComponent implements OnInit {
  
  constructor(public snackerService: SnackerService) {
  }
  
  ngOnInit(): void {
  }
  
  RemoveTaskSnackbar(task: any) {
    console.log(task);
    this.snackerService.ToggleBackgroundTask(task.id);
  }
}
