import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiDataService } from '../../core/services/api-data.service';
import { IUserGuideInfo } from '../../shared/interfaces/user-guide-info.interface';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentationService extends ApiDataService {
  
  constructor(
    http: HttpClient,
    errorMessengerService: ErrorMessengerService) {
    super('UserGuides', http, errorMessengerService);
  }
  
  GetDocumentationInfo(guideLinkId: string, guideLinkType: string): Observable<IUserGuideInfo> {
    return this.http.post(this.apiEndpointUrl + '/GetUserGuide', {
      guideLinkId,
      guideLinkType,
    }).pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error getting documentation source for ${ guideLinkId }`,
        );
        return of(err);
      }),
    );
  }
  
  SearchDocumentationInfoByTags(tag: string): Observable<IUserGuideInfo[]> {
    return this.http.get(this.apiEndpointUrl + '/Tag/' + tag).pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error getting documentation source tag for ${ tag }`,
        );
        return of(err);
      }),
    );
  }
  
  ObtainStartPageDocumentation(): Observable<IUserGuideInfo> {
    return this.http.get(this.apiEndpointUrl + '/StartPage/').pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error getting start page`,
        );
        return of(err);
      }),
    );
  }
  
  ToggleStartPageVisibility(condition: string): any {
    return this.http.put(this.apiEndpointUrl + '/DoNotShowStartPage/' + condition, {}).pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error setting start page`,
        );
        return of(err);
      }),
    );
  }
}
