import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BusService } from '../../../core/molecular/services/bus.service';
import { DragType } from '../../../shared/enums/drag-type.enum';
import { RepresentativeMoleculesType } from '../../../shared/enums/representative-molecules-types.enum';
import { ActionMolecule } from '../../../shared/representative-molecule/interfaces/action-molecules';
import { Bus } from '../../../shared/representative-molecule/interfaces/bus';
import { RepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule';
import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../../../shared/representative-molecule/services/cobble.service';
import { DragService } from '../../../shared/representative-molecule/services/drag.service';
import { CommunicationService } from '../../../shared/services/communication.service';
import { SnackerService } from '../../../shared/services/snacker.service';
import { WorkAreaService } from '../../../workarea/workarea.service';
import { FieldConfig } from '../../models/field-config.model';

@Component({
  selector: 'app-dynamic-select',
  template: `
		<div
			class="panel-viewer-body"
			[ngClass]="{
        'drop-placeholder': dragService.dragginFromViewsPanel || this.elements.length === 0,
        hovering: hovering
      }"
			(dragenter)="hovering = true"
			(dragover)="hovering = true"
			(dragleave)="hovering = false"
			(drop)="HandleDrop($event)"
		>
			<ul
				*ngIf="elements.length > 0; else dropPlaceholderTemplate"
				class="selected-options-list"
			>
				<li
					(click)="IdentifyElement(element)"
					matTooltip="Click to navigate"
					*ngFor="let element of elements"
				>
					<i class="material-icons-round type-icon">{{ element.icon }}</i>
					<span class="name">{{ element.name }}</span>
					<i
						matTooltip="Remove"
						(click)="RemoveElement(element.id, $event)"
						class="material-icons-round remove-icon"
					>close</i>
				</li>
			</ul>
			<ng-template #dropPlaceholderTemplate>
				<div>
					<span> Drop {{ this.config.label }} </span>
				</div>
			</ng-template>
		</div>
  `,
  styleUrls: ['./dynamic-select.component.scss'],
})
export class DynamicSelectComponent implements OnInit {
  config: FieldConfig;
  group: FormGroup;
  bus: Bus;
  repMolecule: RepresentativeMolecule;
  particle: ActionMolecule;
  elements = [];
  acceptedDrops: string;
  icon: string;
  hovering = false;
  NavigationPlaceholderId = 99999;
  navigationView = {
    id: this.NavigationPlaceholderId,
    name: 'Placeholder - Navigation',
    locked: false,
    squish: false,
  };
  
  constructor(
    public dragService: DragService,
    private busService: BusService,
    private cobbleService: CobbleService,
    private snackerService: SnackerService,
    private workAreaService: WorkAreaService,
    private communicationService: CommunicationService,
  ) {
  }
  
  ngOnInit() {
    setTimeout(() => {
      this.repMolecule = this.group.controls['molecule'].value.element as RepresentativeMolecule;
      this.bus =
        this.workAreaService.elementsSelected.length > 1
          ? this.workAreaService.shadowBuses.find((b) => b.id === this.config.busId)
          : this.repMolecule.Buses.find((b) => b.id === this.config.busId);
      
      if (this.bus) {
        this.particle = this.bus.Particles.find((p) => p.ParticleId === this.config.particleId) as ActionMolecule;
        
        const values = this.particle.Rule[this.config.name];
        if (values) {
          switch (this.config.name) {
            case 'workgroup':
            case 'workgroupIds':
            case 'repMoleculeIds':
            case 'replaceWithId':
            case 'workgroupToReplaceId':
              if (this.config.isMultiple) {
                values.forEach((valueId) => {
                  const repMoelcule = this.busService.Get(valueId);
                  
                  if (repMoelcule) {
                    this.elements.push({
                      name: repMoelcule.Properties.name + ' [' + this.ViewName(repMoelcule.Properties.view) + ']',
                      id: repMoelcule.Id,
                      type: 'rep-molecules',
                      icon: repMoelcule.Icon,
                    });
                  }
                });
              } else {
                const repMoelcule = this.busService.Get(values);
                
                if (repMoelcule) {
                  this.elements.push({
                    name: repMoelcule.Properties.name + ' [' + this.ViewName(repMoelcule.Properties.view) + ']',
                    id: repMoelcule.Id,
                    type: 'rep-molecule',
                    icon: repMoelcule.Icon,
                  });
                }
              }
              break;
            
            case 'view':
              if (this.config.isMultiple) {
                values.forEach((valueId) => {
                  const view = this.cobbleService.Cobble.properties.views.find((v) => v.id === valueId);
                  this.elements.push({
                    name: view.name,
                    id: view.id,
                    type: 'view',
                  });
                });
              } else {
                const view =
                  values === this.NavigationPlaceholderId
                    ? this.navigationView
                    : this.cobbleService.Cobble.properties.views.find((v) => v.id === values);
                if (view) {
                  this.elements.push({
                    name: view.name,
                    id: view.id,
                    type: 'view',
                  });
                }
              }
              break;
          }
        }
      }
    }, 100);
  }
  
  ViewName(viewId: number) {
    const view = this.cobbleService.Cobble.properties.views.find((v) => v.id === viewId);
    return view ? view.name : '';
  }
  
  HandleDrop(event: any) {
    // console.log('config', this.config);
    // console.log('drop', this.dragService.dragData);
    // console.log('praticle', this.particle);
    this.hovering = false;
    this.dragService.dragginFromViewsPanel = false;
    
    switch (this.config.name) {
      case 'workgroup':
      case 'workgroupIds':
      case 'repMoleculeIds':
      case 'replaceWithId':
      case 'repMoleculeId':
      case 'workgroupToReplaceId':
        this.acceptedDrops = DragType.RepresentativeMolecule;
        
        if (this.dragService.dragData.dragType === this.acceptedDrops) {
          const representativeMolecule = this.busService.Get(this.dragService.dragData.data.id);
          
          if (this.config.isMultiple || (!this.config.isMultiple && this.elements.length === 0)) {
            const exists = this.elements.find((e) => e.id === representativeMolecule.Id);
            
            if (!exists) {
              this.elements.push({
                name: representativeMolecule.Properties.name + ' [' + this.ViewName(representativeMolecule.Properties.view) + ']',
                id: representativeMolecule.Id,
                type: 'rep-molecule',
                icon: representativeMolecule.Icon,
              });
            }
          } else {
            this.elements = [
              {
                name: representativeMolecule.Properties.name + ' [' + this.ViewName(representativeMolecule.Properties.view) + ']',
                id: representativeMolecule.Id,
                type: 'rep-molecules',
                icon: representativeMolecule.Icon,
              },
            ];
          }
        } else {
          this.snackerService.ShowMessageOnBottom('Only Representative molecules accepted', 'do_not_disturb_on');
        }
        break;
      
      case 'view':
        const navigationView = this.navigationView;
        let view = null;
        console.log('view');
        this.acceptedDrops = DragType.View;
        
        
        if (this.dragService.dragData.dragType === this.acceptedDrops) {
          
          if (this.cobbleService.Cobble.properties.views.find((v) => v.id === this.dragService.dragData.data.id)) {
            view = this.cobbleService.Cobble.properties.views.find((v) => v.id === this.dragService.dragData.data.id);
          } else if (this.dragService.dragData.data.id === this.NavigationPlaceholderId) {
            view = navigationView;
          } else {
            return;
          }
          
          if (this.config.isMultiple || (!this.config.isMultiple && this.elements.length === 0)) {
            const exists = this.elements.find((e) => e.id === view.id);
            
            if (!exists) {
              this.elements.push({
                name: view.name,
                id: view.id,
                type: 'view',
              });
            }
          } else {
            this.elements = [
              {
                name: view.name,
                id: view.id,
                type: 'view',
              },
            ];
          }
        } else {
          this.snackerService.ShowMessageOnBottom('Only Views accepted', 'do_not_disturb_on');
        }
        break;
    }
    
    this.SaveSelection();
  }
  
  SaveSelection() {
    this.group.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    const control = this.group.controls[this.config.name];
    const value = this.config.isMultiple ? this.elements.map((e) => e.id) : this.elements.length === 0 ? null : this.elements[0].id;
    this.group.markAsDirty();
    control.setValue(value);
  }
  
  RemoveElement(id: any, event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.elements = this.elements.filter((e) => e.id !== id);
    this.SaveSelection();
  }
  
  IdentifyElement(element: any) {
    switch (element.type) {
      case 'view':
        this.communicationService.Event.Editor.Views.$SwitchView.emit(element.id);
        break;
      
      case 'rep-molecules':
      case 'rep-molecule':
        this.HighlightRepMolecule(this.busService.Get(element.id));
        break;
    }
  }
  
  HighlightRepMolecule(repMolecule: IRepresentativeMolecule) {
    if (repMolecule.Type === RepresentativeMoleculesType.WorkGroup) {
      this.communicationService.Event.Editor.Views.$SwitchToRepresentativeMoleculeView.emit(repMolecule);
    } else {
      const parentRepMolecule = this.busService.Get(repMolecule.ParentId.toString());
      this.communicationService.Event.Editor.Views.$SwitchToRepresentativeMoleculeView.emit(parentRepMolecule);
    }
    
    setTimeout(() => {
      this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(null);
      this.workAreaService.ShowElementFocusedMenu(repMolecule);
    }, 200);
  }
}
