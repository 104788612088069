export const radioTemplate = [
  {
    icon: 'radio_button_checked',
    moleculeType: 'Representative',
    name: 'Radio',
    type: 'Radio',
    moleculesAllow: ['DataAction', 'Behavior', 'Configuration', 'Compounds', 'Representative'],
    events: ['init', 'option-selected', 'hover', 'unique'],
    defaultEvent: 'option-selected',
    buses: [],
    debounce: {
      events: [],
    },
    minValue: null,
    maxValue: null,
    dataSource: {},
    styleMetadata: {
      styles: [],
      specificStyles: {
        dimension: null,
        font: null,
        hover: null,
        frame: null,
        appearance: null,
      },
      manualAdjustedProperties: [],
    },
    creationFromTemplate: false,
    receptors: [
      'value-input',
      'value-output',
      'tooltip-input',
      'options-list-input',
      'options-list-output',
      'badge-value-input',
      'badge-value-output',
      'none',
    ],
    advanced: false,
    editableProperties: [
      'textToDisplay',
      'badge',
      'textToDisplay',
      'cols',
      'rows',
      'x',
      'y',
      'tabindex',
      'font',
      'hover',
      'opacity',
      'borders',
      'shadow',
      'background',
      'backgroundType',
    ],
    properties: {
      align: 'left', horizontalPadding: 0,
      verticalPadding: 0,
      progressType: 'progress',
      progressColor: '#EA5C2F',
      progressBackgroundColor: '#E5957C',
      progressDiameter: 50,
      progressMode: 'indeterminate',
      progressStrokeWidth: 5,
      wrapText: false,
      allowManualEntry: false,
      alwaysRenderComponent: false,
      sliderVertical: false,
      sliderStep: 1,
      sliderThumbLabel: false,
      sliderTicks: false,
      sliderColor: '#EA5C2F',
      viewNames: [],
      location: null,
      allowEmptyValues: false,
      hover: {
        hoverBackground: 'white',
        hoverBackgroundOpacity: 1,
        hoverBorderRadius: 0,
        hoverBorderStyle: 'none',
        hoverBorderColor: 'transparent',
        hoverBorderWidth: 0,
        hoverTextDecoration: null,
        hoverPointer: null,
        hoverFontColor: 'black',
      },
      options: [
        {
          id: '2kj34g5f7d8',
          value: 'value 1',
          text: 'Text 1',
        },
      ],
      show: true,
      opacity: 1,
      orientation: 'col',
      enable: true,
      altText: 'Select Option',
      
      name: 'Radio',
      legend: true,
      loadingText: '',
      background: {
        backgroundColor: '#ffffff',
        backgroundTypeImage: false,
        backgroundImageUrl: '',
        backgroundOpacity: 1,
      },
      bordersValues: {
        borderWidth: 1,
        borderStyle: 'none',
        borderColor: '#212529',
      },
      icon: {
        iconType: 'radio_button_checked',
        iconColor: 'black',
        iconSize: 45,
      },
      shadowValues: {
        hShadow: 0,
        vShadow: 0,
        shadowColor: '#212529',
        shadowBlur: 0,
      },
      preferences: {},
      view: 1,
      chartLibrary: 'ngxCharts',
      chartType: 'line',
      tabindex: 0,
      textToDisplay: 'Enter Text',
      tooltip: '',
      placeholder: 'Enter Text',
      tableOptions: {
        tableOrder: [],
        tableWidth: {},
      },
      minItemCols: 2,
      maxItemCols: 200,
      responsive: {
        desktop: {
          customGuidelines: {
            x: [],
            y: [],
          },
          id: 0,
          cols: 38,
          rows: 12,
          colsQty: 0,
          rowsQty: 0,
          layer: 1,
          centerPositioning: false,
          x: 0,
          y: 0,
          font: {
            fontSize: 14,
            fontColor: '#000000',
            fontFamily: 'inherit',
            fontStyle: 'inherit',
            fontWeight: 'normal',
          },
          tableOptions: {
            collapse: true,
            bodyRoundCorners: false,
            search: true,
            header: true,
            verticallyCenter: true,
            rowSeparator: false,
            columnSeparator: false,
            rowPadding: true,
            tableOrder: [],
            tableWidth: {},
          },
        },
        smartphone: {
          customGuidelines: {
            x: [],
            y: [],
          },
          id: null,
          cols: null,
          rows: null,
          colsQty: null,
          rowsQty: null,
          layer: null,
          centerPositioning: false,
          x: null,
          y: null,
          font: {
            fontSize: null,
            fontColor: null,
            fontFamily: null,
            fontStyle: null,
            fontWeight: null,
          },
          tableOptions: {
            search: true,
            header: true,
            verticallyCenter: null,
            rowSeparator: null,
            columnSeparator: null,
            rowPadding: null,
            tableOrder: [],
            tableWidth: {},
          },
        },
      },
    },
    molecules: [],
    placeholders: [],
    isSelected: false,
    enable: true,
    value: null,
    children: [],
    replaceableByRepresentative: true,
    loading: false,
    autoGenerationInfo: [],
    moleculeSubType: 'Radio',
    optionsMenu: {
      configuration: true,
      molecules: true,
      compound: true,
      cobblet: true,
      remove: true,
      versioning: true,
      dataSource: true,
      duplicate: true,
    },
  },
];
