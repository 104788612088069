import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class WelcomeService {
  private doneStepAccessSource = new BehaviorSubject<boolean>(false);
  doneStepAccess$ = this.doneStepAccessSource.asObservable();
  
  constructor() {
  }
  
  setDoneStepAccess(access: boolean) {
    this.doneStepAccessSource.next(access);
  }
  
  getDoneStepAccess() {
    return this.doneStepAccessSource.value;
  }
}
