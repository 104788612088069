import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FileUploader } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { Permissions } from '../../admin/models/permissions.enum';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { EditorStateService } from '../../core/services/editor-state.service';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { TemplateService } from '../../core/services/template.service';
import { ToolsService } from '../../core/services/tools.service';
import { UserMenuService } from '../../core/services/user-menu.service';
import { Constants } from '../../shared/constants';
import { DragType } from '../../shared/enums/drag-type.enum';
import { LeapXLFileFormat } from '../../shared/enums/leapxl-file-format.enum';
import { IRepresentativeMoleculeStyleData } from '../../shared/interfaces/rep-mol-style.interface';
import { ThemeExport } from '../../shared/interfaces/theme-export.interface';
import { Theme } from '../../shared/interfaces/theme.interface';
import { User } from '../../shared/models/user';
import { RepresentativeMolecule } from '../../shared/representative-molecule/interfaces/representative-molecule';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { DragService } from '../../shared/representative-molecule/services/drag.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { DraggableWindowManagerService } from '../../shared/services/draggable-window-manager.service';
import { DraggableWindowService, DraggableWindowType } from '../../shared/services/draggable-window.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { ApiThematicService } from '../services/api-thematic.service';
import { ThematicService } from '../services/thematic.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-themes-panel',
  templateUrl: './themes-panel.component.html',
  styleUrls: ['./themes-panel.component.scss'],
})
export class ThemesPanelComponent implements OnInit, OnDestroy {
  
  styleSectionIcons = {
    all: 'format_align_justify',
    dimension: 'photo_size_select_small',
    font: 'font_download',
    hover: 'mouse',
    frame: 'format_shapes',
    appearance: 'format_color_fill',
    basic: 'style',
  };
  uploader: FileUploader = new FileUploader({});
  acceptedStyleFileTypes = '';
  @ViewChild('styleFileUploader', { static: true })
  styleFileUploader: ElementRef;
  userId = 0;
  styleInput = '';
  filteredUserStyles: IRepresentativeMoleculeStyleData[] = [];
  filteredSharedStyles: IRepresentativeMoleculeStyleData[] = [];
  filteredApplicationStyles: IRepresentativeMoleculeStyleData[] = [];
  filteredThemes: Theme[] = [];
  subscription: Subscription;
  themes: Theme[] = [];
  stylesPanelSectionsState = {
    user: false,
    shared: false,
    application: false,
    theme: false,
  };
  
  deleteThemePermission = false;
  editPermission = false;
  importingStyle = false;
  importingTheme = false;
  lastUsedStyles = {
    all: '',
    theme: '',
  };
  
  constructor(
    private communicationService: CommunicationService,
    private toolsService: ToolsService,
    private editorStateService: EditorStateService,
    private workAreaService: WorkAreaService,
    private draggableWindowService: DraggableWindowService,
    private dragService: DragService,
    private draggableWindowManagerService: DraggableWindowManagerService,
    private templateService: TemplateService,
    private dialogService: GenericDialogService,
    public thematicService: ThematicService,
    private clientStorageService: ClientStorageService,
    private snackerService: SnackerService,
    private renderer: Renderer2,
    private apiThematic: ApiThematicService,
    private userMenuService: UserMenuService,
    public cobbleService: CobbleService,
  ) {
    this.userId = this.clientStorageService.getUserId();
    this.deleteThemePermission = this.userMenuService.checkPermission(Permissions.ModifyThemeOnDefaultThemedApps);
    this.editPermission = this.userMenuService.checkPermission(Permissions.ModifyThemedAppStyle) || this.deleteThemePermission;
  }
  
  get supportedFileType() {
    return this.importingTheme ? `.${ LeapXLFileFormat.Theme }` : this.importingStyle ? `.${ LeapXLFileFormat.Style }` : '';
  }
  
  ngOnInit(): void {
    this.subscription = this.communicationService.Event.Editor.Preferences.$PreferenceChange
    .subscribe(preference => {
      if (preference === 'compactToolBarPosition') {
        this.setSectionHeight();
      }
    });
    
    this.subscription.add(this.communicationService.Event.Editor.$ReloadThemesPanel.subscribe(() => {
      console.log('=event=');
      this.LoadThemesPanel();
    }));
    
    this.subscription.add(
      this.communicationService.Event.Editor.$LastUsedElement
      .subscribe(lastUsedElements => {
        if (!lastUsedElements.style) {
          return;
        }
        
        for (const [key, value] of Object.entries(this.lastUsedStyles)) {
          if (lastUsedElements.style[key]) {
            this.lastUsedStyles[key] = lastUsedElements.style[key];
          }
        }
      }),
    );
    
    this.LoadThemesPanel();
    this.setLastUsedElements();
    this.setSectionHeight();
  }
  
  LoadThemesPanel() {
    this.apiThematic.GetThemesByUser().subscribe(userThemes => {
      this.themes = userThemes.sort(this.toolsService.CompareValues('name'));
    });
  }
  
  CreateNewStyle(event: MouseEvent) {
    if (!this.workAreaService.stylePropertiesWindow) {
      this.OpenPropertiesPanel(null, event, true);
    }
  }
  
  CreateNewTheme(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    
    this.OpenThemesConfiguration(`Create New Theme`, false, '');
  }
  
  OpenThemesConfiguration(title: string, showSearch: boolean, searchLabel: string, theme = null) {
    this.draggableWindowService.OpenDraggableWindow(
      title,
      DraggableWindowType.AppThemeConfigurationComponent,
      null,
      {
        showThemeSearch: showSearch,
        themesFieldLabel: searchLabel,
        maxHeight: '330px',
        theme,
      },
    );
  }
  
  DeleteStyle(style: IRepresentativeMoleculeStyleData, event: MouseEvent) {
    this.dialogService.OpenConfirmDialog({
      title: `Delete ${ style.name } Style`,
      message: `Are you sure you want to delete ${ style.name } Style?, this operation can't be undone`,
      confirmText: 'Delete',
      cancelText: 'Cancel',
    }).then((result) => {
      if (result) {
        this.thematicService.VerifyStyleUses(style.styleId).subscribe(uses => {
          if (uses.length > 0) {
            this.dialogService.OpenConfirmDialog({
              title: 'Style in use',
              message: `Unable to delete style. \n\nThis style is being used by other representative molecules:\n
          ${ uses.map(u => `- App ${ u.application }: ${ u.molecule }`).join('\n') }`,
              confirmText: 'Ok',
              cancelText: '',
            });
          } else {
            this.thematicService.DeleteStyle(style.styleId);
          }
        });
      }
    });
  }
  
  DeleteTheme(theme: Theme) {
    this.dialogService.OpenConfirmDialog({
      title: `Delete ${ theme.name } Theme`,
      message: `Are you sure you want to delete ${ theme.name } Theme?, this operation can't be undone`,
      confirmText: 'Delete',
      cancelText: 'Cancel',
    }).then((result) => {
      if (result) {
        this.thematicService.VerifyThemeUses(theme.id).subscribe(uses => {
          if (uses.applications.length > 0 || uses.companies.length > 0) {
            let message = 'Unable to delete theme. \n\nThis theme is being used by:\n';
            
            if (uses.applications.length > 0) {
              message += '\n';
              message += `${ uses.applications.map(app => `- App: ${ app.application }`).join('\n') }`;
              message += '\n';
            }
            
            if (uses.companies.length > 0) {
              message += '\n';
              message += `${ uses.companies.map(cp => `- Company: ${ cp }`).join('\n') }`;
            }
            
            this.dialogService.OpenConfirmDialog({
              title: 'Theme in use',
              message: message,
              confirmText: 'Ok',
              cancelText: '',
            });
          } else {
            this.apiThematic.DeleteTheme(theme.id).subscribe(() => {
              this.snackerService.ShowMessageOnBottom('Theme Deleted Successfully', 'check_circle', null, true);
              this.LoadThemesPanel();
            });
          }
        });
      }
    });
  }
  
  ExportStyle(style: IRepresentativeMoleculeStyleData, event: MouseEvent) {
    this.toolsService.ExportToTextFile(style.name, style, LeapXLFileFormat.Style);
    this.snackerService.ShowMessageOnBottom('Style Exported Successfully', 'download', null, true);
  }
  
  ExportTheme(theme: Theme) {
    const themeToExport: ThemeExport = theme;
    Object.values(themeToExport.representativeMoleculeStyles).forEach(typeStyle => {
      typeStyle.style = this.thematicService.GetStyle(typeStyle.styleId);
    });
    
    this.toolsService.ExportToTextFile(theme.name, theme, LeapXLFileFormat.Theme);
    this.snackerService.ShowMessageOnBottom('Theme Exported Successfully', 'download', null, true);
  }
  
  PreviewRepresentativeMoleculeStyle(style: IRepresentativeMoleculeStyleData, event: MouseEvent) {
    console.log('preview');
    const repMoleculeTemplate = this.templateService.GetRepresentativeTemplateByType(style.representativeMoleculeType);
    const repMolecule = new RepresentativeMolecule(cloneDeep(repMoleculeTemplate));
    repMolecule.ApplyStyle([style]);
    
    this.draggableWindowService.OpenDraggableWindow(
      `Preview Style [${ style.representativeMoleculeType } - ${ style.section.toLocaleUpperCase() } - ${ style.name }]`,
      DraggableWindowType.PreviewRepresentativeMoleculeComponent,
      event,
      { repMolecule },
    );
  }
  
  OpenPropertiesPanel(style: IRepresentativeMoleculeStyleData = null, event: MouseEvent = null, newStyle = false) {
    if (newStyle) {
    
    } else {
      const repMoleculeTemplate = this.templateService.GetRepresentativeTemplateByType(style.representativeMoleculeType);
      const repMolecule = new RepresentativeMolecule(cloneDeep(repMoleculeTemplate));
      repMolecule.ApplyStyle([style]);
    }
    
    this.draggableWindowService.OpenDraggableWindow(
      newStyle ? 'Create New Style' : `Style [${ style.representativeMoleculeType } - ${ style.name }] Properties`,
      DraggableWindowType.StyleProperties,
      event,
      { style, newStyle },
    );
    
    this.communicationService.Event.Editor.WorkArea.$RefreshPropertiesStyles.emit();
  }
  
  OpenPropertiesThemePanel(theme: Theme) {
    this.OpenThemesConfiguration(`Theme Properties`, false, '', theme);
  }
  
  SelectFile(ref: HTMLInputElement, event: MouseEvent, importStyle: boolean) {
    this.importingStyle = importStyle;
    this.importingTheme = !importStyle;
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      ref.click();
    }, 100);
  }
  
  AddStyleImported(style: IRepresentativeMoleculeStyleData) {
    style.category = 'User';
    this.thematicService.SaveStyle(style);
    this.styleFileUploader.nativeElement.value = '';
    this.snackerService.ShowMessageOnBottom('Style Imported Successfully', 'add_circle', null, true);
  }
  
  AddThemeImported(themeImported: ThemeExport) {
    themeImported.id = 0;
    const themeName = themeImported.name + `_${ this.toolsService.GenerateGuid() }`;
    
    let stylesCreated = 0;
    
    Object.values(themeImported.representativeMoleculeStyles).forEach(typeStyle => {
      // first create all the styles
      this.apiThematic.SaveStyle(typeStyle.style).subscribe(style => {
        typeStyle.styleId = style.styleId;
        typeStyle.style.name = `${ typeStyle.style.name }_${ themeImported.name }`;
        stylesCreated++;
        
        if (stylesCreated === Object.keys(themeImported.representativeMoleculeStyles).length) {
          themeImported.name = themeName;
          // create the theme with the new style ids references
          this.thematicService.CreateTheme(themeImported).subscribe(() => {
            this.communicationService.Event.Editor.WorkArea.$RefreshStylesLibrary.emit();
            this.LoadThemesPanel();
            this.snackerService.ShowMessageOnBottom('Theme Imported Successfully', 'add_circle', null, true);
          });
        }
      });
    });
  }
  
  HandleStyleFile(files: FileList) {
    this.toolsService.ReadJSONFileToData<any>(files, this.importingStyle ? [LeapXLFileFormat.Style] : [LeapXLFileFormat.Theme]).subscribe(data => {
      if (data) {
        if (data && data.properties && this.importingStyle) {
          data.styleId = null;
          this.AddStyleImported(data);
        }
        if (data && this.importingTheme) {
          this.AddThemeImported(data);
        }
      } else {
        this.snackerService.ShowMessageOnBottom('Error Importing File', 'error');
      }
    });
  }
  
  DragStartHandler(event: MouseEvent, style: IRepresentativeMoleculeStyleData) {
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
    // console.log('drag start', event);
    this.dragService.StartDrag();
    (event.target as any).style.opacity = 0.3;
    
    this.dragService.dragText = `Apply ${ style.name } style`;
    this.dragService.dragginStyle = true;
    
    this.dragService.dragData = { dragType: DragType.Style, style, type: 'assign' };
  }
  
  DragStartHandlerTheme(event: MouseEvent, theme: Theme) {
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
    this.dragService.StartDrag();
    (event.target as any).style.opacity = 0.3;
    this.dragService.dragText = 'Theme';
    this.dragService.dragginStyle = true;
    this.dragService.dragData = { dragType: DragType.Theme, theme, type: 'assign' };
  }
  
  DragEndHandler(event: MouseEvent) {
    (event.target as any).style.opacity = 1;
    this.dragService.StopDragging();
    setTimeout(() => {
      this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    }, 300);
  }
  
  OnHeaderDrop(event) {
    if (this.dragService.dragData && this.dragService.dragData.dragType === DragType.Style) {
      this.thematicService.ShareStyleWithCompany(this.dragService.dragData.style.styleId, true);
      this.snackerService.ShowMessageOnBottom('Style shared with the company', 'ios_share');
      this.communicationService.Event.Editor.WorkArea.$RefreshStylesLibrary.emit();
    }
  }
  
  UnshareStyle(style: IRepresentativeMoleculeStyleData, event: MouseEvent) {
    this.thematicService.ShareStyleWithCompany(style.styleId, false);
    this.snackerService.ShowMessageOnBottom('Style unshared', 'do_not_disturb_on');
    this.communicationService.Event.Editor.WorkArea.$RefreshStylesLibrary.emit();
  }
  
  OnDrop(event: any) {
    console.log(event);
  }
  
  toggleSection(section: string, event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    
    for (let [key, value] of Object.entries(this.stylesPanelSectionsState)) {
      if (key !== section) {
        this.stylesPanelSectionsState[key] = false;
      }
    }
    
    this.stylesPanelSectionsState[section] = !this.stylesPanelSectionsState[section];
    this.setSectionHeight();
  }
  
  setSectionHeight() {
    const windowHeight = window.innerHeight;
    
    setTimeout(() => {
      const themesContainer = document.querySelectorAll('#themesPanel .themes-container');
      const categoriesContainer = document.querySelector('#themesPanel .categories-container');
      const headersHeight = Constants.SidePanelSectionHeaderHeight * 4;
      const separatorsHeight = Constants.SeparatorSectionHeaderHeight * 3;
      const toolbarHeight = this.workAreaService.editorPreferences.compactToolBarPosition === 'top' ? Constants.ToolbarHeight : 0;
      
      if (this.styleInput) {
        if (themesContainer) {
          themesContainer.forEach(container => {
            this.renderer.setStyle(container, 'max-height', 'fit-content');
            this.renderer.setStyle(container, 'overflow', 'hidden');
          });
        }
        
        if (categoriesContainer) {
          this.renderer.setStyle(
            categoriesContainer,
            'max-height',
            `${ windowHeight - (headersHeight + separatorsHeight + toolbarHeight) + 8 }px`,
          );
          this.renderer.setStyle(categoriesContainer, 'overflow', 'auto');
        }
        
        return;
      }
      
      if (themesContainer) {
        themesContainer.forEach(container => {
          this.renderer.setStyle(
            container,
            'max-height',
            `${ windowHeight - (105 + headersHeight + separatorsHeight + toolbarHeight) }px`,
          );
          this.renderer.setStyle(container, 'overflow', 'auto');
        });
      }
      
      if (categoriesContainer) {
        this.renderer.setStyle(categoriesContainer, 'max-height', 'fit-content');
        this.renderer.setStyle(categoriesContainer, 'overflow', 'hidden');
      }
    }, 100);
  }
  
  filterStyle(value: string) {
    this.styleInput = value;
    this.filteredUserStyles = [];
    this.filteredSharedStyles = [];
    this.filteredApplicationStyles = [];
    this.filteredThemes = [];
    
    for (let [key, value] of Object.entries(this.stylesPanelSectionsState)) {
      if (value) {
        this.stylesPanelSectionsState[key] = false;
      }
    }
    
    if (!this.styleInput) {
      this.stylesPanelSectionsState.user = true;
      this.setSectionHeight();
      return;
    }
    
    this.thematicService.UserLibrary.forEach(userStyle => {
      if (userStyle.name.toLowerCase().includes(this.styleInput)) {
        this.filteredUserStyles.push(userStyle);
        this.stylesPanelSectionsState.user = true;
      }
    });
    
    this.thematicService.CompanyLibrary.forEach(sharedStyle => {
      if (sharedStyle.name.toLowerCase().includes(this.styleInput)) {
        this.filteredSharedStyles.push(sharedStyle);
        this.stylesPanelSectionsState.shared = true;
      }
    });
    
    this.thematicService.ApplicationLibrary.forEach(applicationStyle => {
      if (applicationStyle.name.toLowerCase().includes(this.styleInput)) {
        this.filteredApplicationStyles.push(applicationStyle);
        this.stylesPanelSectionsState.application = true;
      }
    });
    
    this.themes.forEach(theme => {
      if (theme.name.toLowerCase().includes(this.styleInput)) {
        this.filteredThemes.push(theme);
        this.stylesPanelSectionsState.theme = true;
      }
    });
    
    this.setSectionHeight();
  }
  
  setLastUsedElements() {
    if (!this.workAreaService.lastUsedElements.style) {
      return;
    }
    
    for (const [key, value] of Object.entries(this.lastUsedStyles)) {
      if (this.workAreaService.lastUsedElements.style[key]) {
        this.lastUsedStyles[key] = this.workAreaService.lastUsedElements.style[key];
      }
    }
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setSectionHeight();
  }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
