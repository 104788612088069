import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class NotNumericMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    dataBus: any,
  ) {
    try {
      dataBus = dataBus || '';
      
      if (this.ExistsRepMoleculeAttached) {
        dataBus = this.RepMoleculeAttached.GetValue;
      }
      
      dataBus = this.DataActionModifierApply(dataBus, this.Modifier, repMoleculeId);
      
      
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  private Modifier(value: string, repMoleculeId: number): string {
    const type = this.toolsService.GetObjectType(value);
    if (type === 'string') {
      if (/[0-9 -]/.test(value)) {
        value = value.replace(/[0-9]+/g, '');
        const molecule = this.busService.Get(repMoleculeId.toString());
        molecule.Disallow = true;
        setTimeout(() => {
          molecule.Disallow = false;
        }, 300);
      }
    }
    return value;
  }
}
