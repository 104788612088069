import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

declare const window: any;

export class PopulatableMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(particleId: string, repMoleculeId: string) {
    try {
    } catch (error) {
      // console.log(error);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
