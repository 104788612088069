export class HubConnectionDto {
  id: string;
  date: string;
  appRuntimeId: string;
  message: string;
  data: any;
  type: string;
  icon: string;
  url: string;
  userId: number;
  user: string;
  initials: string;
  productionDataSource: boolean;
  creationDate: string;

  public constructor(fields?: {
    id?: string;
    date?: string;
    message?: string;
    data?: any;
    type?: string;
    icon?: string;
    url?: string;
    userId?: number;
    user?: string;
    creationDate?: string;
    productionDataSource?: boolean;
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}
