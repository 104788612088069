import { Injector } from '@angular/core';
import { cloneDeep, isEmpty, isEqual } from 'lodash-es';
import { AppInjector } from '../../../app-injector';
import { ToolsService } from '../../../core/services/tools.service';
import { ParticleType } from '../../enums/particle-type.enum';
import { CommunicationService } from '../../services/communication.service';
import { CobbleService } from '../services/cobble.service';
import { ActionMolecule } from './action-molecules';
import { DataElement } from './data-element';
import { LeapXLEvent } from './leapxl-event';

export class Particle {
  id: string;
  ParticleType: string;
  ParticleId: string;
  TriggeredByBusId: string;
  Enable?: boolean;
  Touched: boolean;
  Priority: number;
  AvoidRun = false;
  protected injector: Injector;
  protected cloneDeep = cloneDeep;
  protected toolsService: ToolsService;
  protected communicationService: CommunicationService;
  protected cobbleService: CobbleService;
  
  constructor(particleId: string) {
    this.injectServices();
    this.AssingParticleId(particleId);
  }
  
  GetName(): string {
    return '';
  }
  
  GetDisplayValues(): string {
    return '';
  }
  
  SetPriority(priority: number) {
    this.Priority = priority;
  }
  
  public Clone() {
    return new Particle('');
  }
  
  public CloneAndAssignIds(): Particle {
    const particle = this.Clone();
    particle.AssingId();
    particle.AssingParticleId();
    return particle;
  }
  
  public AssingParticleId(id: string = null) {
    this.ParticleId = id || this.toolsService.GenerateGuid();
  }
  
  public AssingId(id: string = null) {
    this.id = id || this.toolsService.GenerateGuid();
  }
  
  public IsEvent(): boolean {
    return this.ParticleType === ParticleType.Event;
  }
  
  public IsActionMolecule(): boolean {
    return this.ParticleType === ParticleType.Molecule;
  }
  
  public IsDataElement(): boolean {
    return this.ParticleType === ParticleType.DataElement;
  }
  
  public SetTriggerBy(busId: string) {
    console.log('trigger by');
    this.TriggeredByBusId = busId || '';
  }
  
  IsSameParticle(particleToCompare: Particle) {
    switch (particleToCompare.ParticleType) {
      case ParticleType.Molecule:
        return (
          (particleToCompare as ActionMolecule).ParticleType === this.ParticleType &&
          (particleToCompare as ActionMolecule).InternalMoleculeName === (this as unknown as ActionMolecule).InternalMoleculeName &&
          ((isEmpty((particleToCompare as ActionMolecule).Rule) && isEmpty(
              (this as unknown as ActionMolecule).Rule)) ||
            isEqual((particleToCompare as ActionMolecule).Rule, (this as unknown as ActionMolecule).Rule))
        );
        break;
      
      case ParticleType.Event:
        return (
          (particleToCompare as LeapXLEvent).ParticleType === this.ParticleType &&
          (particleToCompare as LeapXLEvent).EventType === (this as unknown as LeapXLEvent).EventType &&
          (particleToCompare as LeapXLEvent).EventSource === (this as unknown as LeapXLEvent).EventSource &&
          (particleToCompare as LeapXLEvent).SourceId === (this as unknown as LeapXLEvent).SourceId
        );
        break;
      
      case ParticleType.DataElement:
        return (
          (particleToCompare as DataElement).ParticleType === this.ParticleType &&
          (particleToCompare as DataElement).TranslationId === (this as unknown as DataElement).TranslationId &&
          (particleToCompare as DataElement).Context === (this as unknown as DataElement).Context
        );
        break;
    }
  }
  
  IsSameEventParticle(particleToCompare: Particle) {
    switch (particleToCompare.ParticleType) {
      case ParticleType.Event:
        return (
          (particleToCompare as LeapXLEvent).ParticleType === this.ParticleType &&
          (particleToCompare as LeapXLEvent).EventType === (this as unknown as LeapXLEvent).EventType &&
          (particleToCompare as LeapXLEvent).EventSource === (this as unknown as LeapXLEvent).EventSource &&
          (particleToCompare as LeapXLEvent).EventName.toLowerCase() === (this as unknown as LeapXLEvent).EventName.toLowerCase()
        );
        break;
      
      case ParticleType.Molecule:
      case ParticleType.DataElement:
        return false;
        break;
    }
  }
  
  IsSameSpecificEvent(particleToCompare: Particle) {
    switch (particleToCompare.ParticleType) {
      case ParticleType.Event:
        return (
          (particleToCompare as LeapXLEvent).ParticleType === this.ParticleType &&
          (particleToCompare as LeapXLEvent).EventType === (this as unknown as LeapXLEvent).EventType &&
          (particleToCompare as LeapXLEvent).EventSource === (this as unknown as LeapXLEvent).EventSource &&
          (particleToCompare as LeapXLEvent).EventName.toLowerCase() === (this as unknown as LeapXLEvent).EventName.toLowerCase() &&
          (particleToCompare as LeapXLEvent).SourceId.toString() === (this as unknown as LeapXLEvent).SourceId.toString()
        );
        break;
      
      case ParticleType.Molecule:
      case ParticleType.DataElement:
        return false;
        break;
    }
  }
  
  GetRawObject(): any {
    return {
      id: this.id,
      ParticleType: this.ParticleType,
      ParticleId: this.ParticleId,
    };
  }
  
  GlobalIdentifier() {
    return this.ParticleId;
  }
  
  protected injectServices() {
    this.injector = AppInjector.getInjector();
    this.toolsService = this.injector.get(ToolsService);
    this.communicationService = this.injector.get(CommunicationService);
    this.cobbleService = this.injector.get(CobbleService);
  }
}
