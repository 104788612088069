import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector, ViewContainerRef } from '@angular/core';
import { EditorStateService } from '../../core/services/editor-state.service';
import { ToolsService } from '../../core/services/tools.service';
import { WorkAreaService } from '../../workarea/workarea.service';
import { Constants } from '../constants';
import { DraggableWindow } from '../draggable-window';
import { CommunicationService } from './communication.service';
import { DraggableWindowManagerService } from './draggable-window-manager.service';

@Injectable({
  providedIn: 'root',
})
export class DraggableWindowService {
  
  private container: ViewContainerRef;
  private data: {};
  private actualWindow: DraggableWindow;
  
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private draggableWindowManagerService: DraggableWindowManagerService,
    private workAreaService: WorkAreaService,
    private communicationService: CommunicationService,
    private editorStateService: EditorStateService,
    private toolsService: ToolsService,
  ) {
    this.communicationService.Event.Editor.WorkArea.$CloseAllDraggableWindows.subscribe(() => {
      
      if (this.workAreaService.draggableWindow) {
        this.workAreaService.draggableWindow.forEach(dWindow => {
          dWindow.Hide();
        });
      }
      
      this.workAreaService.draggableWindow = [];
      
      if (this.workAreaService.appDetailsWindow) {
        this.workAreaService.appDetailsWindow.Hide();
      }
      this.workAreaService.appDetailsWindow = null;
    });
  }
  
  public GenerateWindow(
    componentType: any,
    options?: { title?: string; data: any, type?: string },
    windows: DraggableWindow[] = [],
  ) {
    let title = '';
    let type = '';
    if (options) {
      title = options.title || Constants.Defaults.DraggableWindow.Title;
      this.data = options.data || Constants.Defaults.DraggableWindow.Data;
      type = options.type || '';
    }
    console.log(windows);
    const windowExist = windows.find(w => type && type !== '' && w.type === type);
    if (windowExist) {
      this.VerifyWindowPosition(windowExist);
      return windowExist;
    } else {
      const newWindow = new DraggableWindow(
        this.toolsService.GenerateGuid(),
        this.container,
        this.componentFactoryResolver,
        this.appRef,
        this.injector,
        componentType,
        title,
        Object.assign({}, this.data),
        type,
      );
      
      this.actualWindow = newWindow;
      return this.actualWindow;
    }
  }
  
  VerifyWindowPosition(window: DraggableWindow) {
    const newPosition = {
      x: 400,
      y: 60,
    };
    
    const draggableWindow = document.getElementById(window.draggableWindowComponentRef.instance.id);
    
    if (draggableWindow) {
      const position = JSON.parse(draggableWindow.style.transform.replace('translate(', '[').replace('px, ', ',').replace('px)', ']'));
      
      const x = position[0];
      const y = position[1];
      
      if (x > (window as any).innerWidth || y > (window as any).innerHeight) {
        window.draggableWindowComponentRef.instance.SetPosition(newPosition);
      }
    }
  }
  
  public SetContainer(container: ViewContainerRef) {
    if (this.container) {
    } else {
      // console.log('setting container', container);
      this.container = container;
    }
  }
  
  public GetData(): any {
    const cData = Object.assign({}, this.data);
    this.data = null;
    return cData;
  }
  
  public GetActualWindow(): DraggableWindow {
    return this.actualWindow;
  }
  
  public Close() {
    if (this.actualWindow) {
      this.actualWindow.Hide();
    }
  }
  
  OpenDraggableWindow(title = '', windowType: DraggableWindowType, event: MouseEvent = null, data = null, multipleInstances = false, config: {
    height?: number,
    width?: number,
    x?: number,
    y?: number,
    microlearning?: any
    resizable?: boolean,
    autoHeight?: boolean
  } = {}) {
    
    console.log('here');
    this.communicationService.Event.Editor.$WorkAreaDetectionDisableFor.emit(300);
    let windowConfig = this.editorStateService.WindowSize(windowType);
    Object.assign(windowConfig, config);
    
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      windowConfig.x = event.clientX;
      windowConfig.y = event.clientY;
    }
    
    if (!windowConfig) {
      return;
    }
    
    this.toolsService.DragWindowConfig = windowConfig;
    this.toolsService.DragWindowConfig.x = this.toolsService.DragWindowConfig.x + this.toolsService.DragWindowConfig.width > window.innerWidth ? window.innerWidth - this.toolsService.DragWindowConfig.width - 20 : this.toolsService.DragWindowConfig.x;
    this.toolsService.DragWindowConfig.y = this.toolsService.DragWindowConfig.y + this.toolsService.DragWindowConfig.height > window.innerHeight ? window.innerHeight - this.toolsService.DragWindowConfig.height : this.toolsService.DragWindowConfig.y;
    this.toolsService.DragWindowConfig.y = this.toolsService.DragWindowConfig.y - 100;
    
    this.toolsService.DragWindowConfig.x = this.toolsService.DragWindowConfig.x < 0 ? 10 : this.toolsService.DragWindowConfig.x;
    this.toolsService.DragWindowConfig.y = this.toolsService.DragWindowConfig.y < 0 ? 50 : this.toolsService.DragWindowConfig.y;
    
    
    console.log(windowConfig);
    const dragWindow = this.GenerateWindow(
      windowConfig.component,
      {
        title: title,
        data: data,
        type: windowConfig.name,
      },
      multipleInstances ? [] : this.workAreaService.draggableWindow,
    );
    
    const windowExists = this.workAreaService.draggableWindow.find(dw => dw.Id === dragWindow.Id);
    
    if (!multipleInstances && windowExists) {
      this.RemovesFocusDraggableWindows();
      windowExists.Show();
      windowExists.FocusWindow();
    } else {
      
      switch (windowType) {
        case DraggableWindowType.InitialAssist:
          this.workAreaService.initialAssistPageWindow = dragWindow;
          break;
        case DraggableWindowType.TemplateWizard:
          this.workAreaService.smartTemplateWindow = dragWindow;
          break;
        case DraggableWindowType.ProcessPanel:
          dragWindow.draggableWindowComponentRef.instance.subscriptions.add(this.communicationService.Event.Editor.$RepMoleculeSelected.subscribe(repMolecule => {
            dragWindow.draggableWindowComponentRef.instance.title = this.workAreaService.elementsSelected.length > 1 ? `Selection (${ this.workAreaService.elementsSelected.length }) Process` : repMolecule.Properties.name + ' Process';
          }));
          break;
        case DraggableWindowType.RepresentativeProperties:
          dragWindow.draggableWindowComponentRef.instance.subscriptions.add(this.communicationService.Event.Editor.$RepMoleculeSelected.subscribe(repMolecule => {
            dragWindow.draggableWindowComponentRef.instance.title = this.workAreaService.elementsSelected.length > 1 ? `Selection (${ this.workAreaService.elementsSelected.length }) Properties` : repMolecule.Properties.name + ' Properties';
          }));
          break;
      }
      
      this.workAreaService.draggableWindow.push(dragWindow);
      this.draggableWindowManagerService.ShowWindowAndStore(dragWindow);
    }
  }
  
  OpenUniqueDraggableWindow(title = '', windowType: DraggableWindowType, storeWindowReference: DraggableWindow, event: MouseEvent = null, data = null, config: {
    height?: number,
    width?: number,
    x?: number,
    y?: number,
    microlearning?: any
    resizable?: boolean,
    autoHeight?: boolean
  } = {}) {
    
    this.communicationService.Event.Editor.$WorkAreaDetectionDisableFor.emit(300);
    
    if (storeWindowReference) {
      storeWindowReference.Hide();
      storeWindowReference = null;
    }
    
    let windowConfig = this.editorStateService.WindowSize(windowType);
    Object.assign(windowConfig, config);
    
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      windowConfig.x = event.clientX;
      windowConfig.y = event.clientY;
    }
    
    if (!windowConfig) {
      return;
    }
    
    this.toolsService.DragWindowConfig = windowConfig;
    this.toolsService.DragWindowConfig.x = this.toolsService.DragWindowConfig.x + this.toolsService.DragWindowConfig.width > window.innerWidth ? window.innerWidth - this.toolsService.DragWindowConfig.width - 20 : this.toolsService.DragWindowConfig.x;
    this.toolsService.DragWindowConfig.y = this.toolsService.DragWindowConfig.y + this.toolsService.DragWindowConfig.height > window.innerHeight ? window.innerHeight - this.toolsService.DragWindowConfig.height : this.toolsService.DragWindowConfig.y;
    this.toolsService.DragWindowConfig.y = this.toolsService.DragWindowConfig.y - 100;
    
    this.toolsService.DragWindowConfig.x = this.toolsService.DragWindowConfig.x < 0 ? 10 : this.toolsService.DragWindowConfig.x;
    this.toolsService.DragWindowConfig.y = this.toolsService.DragWindowConfig.y < 0 ? 50 : this.toolsService.DragWindowConfig.y;
    
    const dragWindow = this.GenerateWindow(
      windowConfig.component,
      {
        title: title,
        data: data,
        type: windowConfig.name,
      },
    );
    
    this.draggableWindowManagerService.ShowWindowAndStore(dragWindow);
    return dragWindow;
  }
  
  RemovesFocusDraggableWindows() {
    document.querySelectorAll('.focus-window').forEach(element => element.classList.remove('focus-window'));
  }
}

export enum DraggableWindowType {
  PreviewRepresentativeMoleculeComponent = 'PreviewRepresentativeMoleculeComponent',
  DevToolsToolbarComponent = 'DevToolsToolbarComponent',
  DevTools = 'DevTools',
  DevToolsBreakpointData = 'DevToolsBreakpointData',
  AppThemeConfigurationComponent = 'AppThemeConfigurationComponent',
  Microlearning = 'Microlearning',
  HierarchyShared = 'HierarchyShared',
  AppProperties = 'AppProperties',
  DevConsole = 'DevConsole',
  ApiRegister = 'ApiRegister',
  LeapxlImport = 'LeapxlImport',
  CloneApplicationComponent = 'CloneApplicationComponent',
  LeapxlExport = 'LeapxlExport',
  Snapshots = 'Snapshots',
  BugReport = 'BugReport',
  AppDetails = 'AppDetails',
  DataAudit = 'DataAudit',
  DataProvenance = 'DataProvenance',
  DataDrillDown = 'DataDrillDown',
  ProcessPanel = 'ProcessPanel',
  Loop = 'Loop',
  Welcome = 'Welcome',
  RenameDatasource = 'RenameDatasource',
  RenameSheet = 'RenameSheet',
  ApiSetup = 'ApiSetup',
  PreferencesPanel = 'PreferencesPanel',
  UnifiedDbConnection = 'UnifiedDbConnection',
  ApiDocumentation = 'ApiDocumentation',
  UnsavedData = 'UnsavedData',
  RepresentativeProperties = 'RepresentativeProperties',
  StyleProperties = 'StyleProperties',
  CreateComponent = 'CreateComponent',
  CreateStyle = 'CreateStyle',
  SeparateBus = 'SeparateBus',
  CreateCompound = 'CreateCompound',
  LibraryMoleculeDocumentation = 'LibraryMoleculeDocumentation',
  EventSource = 'EventSource',
  DatasourceConnected = 'DatasourceConnected',
  TemplateWizard = 'TemplateWizard',
  EventsTransaction = 'EventsTransaction',
  RequestsLog = 'RequestsLog',
  CustomControlEditor = 'CustomControlEditor',
  CustomControlHelp = 'CustomControlHelp',
  TextComponent = 'TextComponent',
  AutoGenerationApp = 'AutoGenerationApp',
  InitialAssist = 'InitialAssist'
}
