import { Constants } from '../../../shared/constants';
import {
  IRepresentativeMolecule,
} from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class ReplaceMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    dataBus: any,
    dataElementsData: any,
  ) {
    try {
      dataBus = dataBus || '';
      
      console.log(dataElementsData);
      
      const wordsToReplace = rule.toreplace.split(',');
      let replaceBy = rule.replaceby || Constants.Defaults.DataItemValue;
      
      if (this.ExistsRepMoleculeAttached) {
        replaceBy = this.RepMoleculeAttached.GetValue || '';
        dataBus = this.DataActionModifierApply(dataBus, this.Modifier, { wordsToReplace, replaceBy });
      } else if (dataElementsData) {
        
        dataElementsData.forEach(de => {
          replaceBy = this.toolsService.ExtractValuesByType(de).string || Constants.Defaults.DataItemValue;
          dataBus = this.DataActionModifierApply(dataBus, this.Modifier, { wordsToReplace, replaceBy });
        });
      } else {
        dataBus = this.DataActionModifierApply(dataBus, this.Modifier, { wordsToReplace, replaceBy });
      }
      
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  private Modifier(value: string, data: { wordsToReplace: string, replaceBy: string }): string {
    const type = this.toolsService.GetObjectType(value);
    if (type === 'string') {
      for (const word of data.wordsToReplace) {
        // const replaceRegExp = new RegExp(word.trim(), 'g');
        // value = value.replace(replaceRegExp, data.replaceBy.trim());
        value = (value as any).replaceAll(word.trim(), data.replaceBy.trim());
      }
    }
    return value;
  }
}
