import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BuilderService } from '../../../../core/builder/builder.service';
import { ProcessorService } from '../../../../core/molecular/services/processor.service';
import { ToolsService } from '../../../../core/services/tools.service';
import { WorkAreaService } from '../../../../workarea/workarea.service';
import { RepresentativeMoleculesType } from '../../../enums/representative-molecules-types.enum';
import { DragService } from '../../services/drag.service';
import { BaseMoleculeComponent } from '../base-molecule/base-molecule.component';

@Component({
  selector: 'app-checkbox-molecule',
  templateUrl: './checkbox-molecule.component.html',
  styleUrls: ['./checkbox-molecule.component.scss'],
})
export class CheckboxMoleculeComponent extends BaseMoleculeComponent
  implements OnInit {
  MoleculeType = 'Representative';
  Type = RepresentativeMoleculesType.Checkbox;
  
  @ViewChild('checkboxWrapper', { static: true })
  checkboxWrapper: ElementRef;
  backgroundOpacity;
  
  constructor(
    public builderService: BuilderService,
    public dragService: DragService,
    public workAreaService: WorkAreaService,
    public processorService: ProcessorService,
    toolsService: ToolsService,
    bottomSheet: MatBottomSheet,
    public el: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
    super(bottomSheet, el, changeDetectorRef);
  }
  
  ngOnInit() {
    super.ngOnInit();
    this.context.Type = RepresentativeMoleculesType.Checkbox;
    this.RefreshGridsterConfiguration();
    this.Subscriptions = this.context.$UpdateTextValue.subscribe((update) => {
      // this.RefreshText();
    });
    
    this.ApplyOpacityToBackground();
    
    return;
    // set checkbox color
    if (this.context.Properties.background.backgroundColor !== '#EA5C2F') {
      let opacity = 'FF';
      if (this.context.Properties.background.backgroundOpacity >= 0) {
        const i =
          Math.round(this.context.Properties.background.backgroundOpacity * 100) /
          100;
        const alpha = Math.round(i * 255);
        opacity = (alpha + 0x10000).toString(16).substr(-2);
      }
      
      console.log(opacity);
      this.toolsService.GenerateClassDynamically(`#gridsterItem-${ this.context.Id } .mat-checkbox-checked .mat-checkbox-background`, `background-color: ${ this.context.Properties.background.backgroundColor }${ opacity };`);
    }
  }
  
  AttachEditorEventListeners() {
    const dragoverEventListener = this.renderer.listen(this.checkboxWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, true);
    });
    const dragleaveEventListener = this.renderer.listen(this.checkboxWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, false);
    });
    const dropEventListener = this.renderer.listen(this.checkboxWrapper.nativeElement, 'drop', (evt) => {
      this.DataDropped(evt);
    });
  }
  
  UpdateData(): void {
    this.ApplyOpacityToBackground();
  }
  
  ApplyOpacityToBackground() {
    this.backgroundOpacity = this.toolsService.ApplyOpacityToColor(this.context.Properties.background.backgroundColor, this.context.Properties.background.backgroundOpacity);
  }
  
  FireCheckChangeEvent(e: any) {
    this.FireRepresentativeMoleculeEvent('state-change', e.checked ? 'Y' : 'N');
    if (e.checked) {
      this.FireRepresentativeMoleculeEvent('state-checked', 'Y');
    } else {
      this.FireRepresentativeMoleculeEvent('state-unchecked', 'N');
    }
  }
}
