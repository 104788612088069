import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { catchError, map } from 'rxjs/operators';
import { ApiDataService } from '../../core/services/api-data.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { IRepresentativeMoleculeStyleData } from '../../shared/interfaces/rep-mol-style.interface';
import { Theme } from '../../shared/interfaces/theme.interface';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';

@Injectable({
  providedIn: 'root',
})
export class ApiThematicService extends ApiDataService {
  
  cachedStyles = {};
  private apiThemesUrl = this.apiUrl + 'Themes/';
  
  constructor(http: HttpClient,
              errorMessengerService: ErrorMessengerService,
              private clientStorageService: ClientStorageService) {
    super('Styles', http, errorMessengerService);
  }
  
  SaveStyle(style: IRepresentativeMoleculeStyleData): Observable<IRepresentativeMoleculeStyleData> {
    return this.http.post(this.apiEndpointUrl, style).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error saving style`, style),
      ),
    );
  }
  
  ShareStyle(styleId: number, shared: boolean): Observable<any> {
    return this.http.put(this.apiEndpointUrl + `/ShareWithCompany/${ styleId }/${ shared }`, {}).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error sharing style`, styleId),
      ),
    );
  }
  
  AssignStyleToRepresentativeMolecule(styleId: number, repMoleculeId: number, applicationId: number): Observable<any> {
    return this.http.post(this.apiEndpointUrl + '/AssignToMolecule', {
      styleId,
      moleculeId: repMoleculeId,
      applicationId,
    }).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error assign style`),
      ),
    );
  }
  
  AssignStyleToRepresentativeMolecules(data: { styleId: number, moleculeId: number, applicationId: number }[]): Observable<any> {
    return this.http.post(this.apiEndpointUrl + '/AssignToMolecules', data).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error assign style`),
      ),
    );
  }
  
  AssignStylesToRepresentativeMolecules(data: { styleId: number, repMoleculeId: number, applicationId: number }[]): Observable<any> {
    return this.http.post(this.apiEndpointUrl + '/AssignToMolecules', data).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error assign styles to molecules`),
      ),
    );
  }
  
  AssignThemeToApp(themeId: number, appId: number): Observable<any> {
    return this.http.post(this.apiThemesUrl + 'AssignToApp', {
      themeId,
      applicationId: appId,
    }).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error assign theme`),
      ),
    );
  }
  
  DeleteStyle(styleId: number) {
    return this.http.delete(this.apiEndpointUrl + '/' + styleId).pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(
          err,
          `Error deleting style ${ styleId }`,
        );
        return of(err);
      }),
    );
  }
  
  VerifyStyleUses(styleId: number): Observable<any[]> {
    return this.http.get(this.apiEndpointUrl + '/AssignedStyles/' + styleId).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error verifying styles`),
      ),
    );
  }
  
  VerifyThemeUses(themeId: number): Observable<{ companies: string[], applications: any[] }> {
    return this.http.get(this.apiThemesUrl + 'AssignedThemes/' + themeId).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error verifying themes`),
      ),
    );
  }
  
  GetStyle(styleId: number): Observable<any> {
    const style = this.cachedStyles[styleId];
    
    if (style) {
      return of(style);
    } else {
      return this.http.get(this.apiEndpointUrl + '/' + styleId).pipe(
        map((response) => {
          this.cachedStyles[styleId] = styleId;
          return <any>response;
        }),
        catchError((error) =>
          this.errorMessengerService.HandleError(error, `Error getting style`),
        ),
      );
    }
  }
  
  GetStylePromised(styleId: number): Promise<IRepresentativeMoleculeStyleData> {
    return new Promise((resolve) => {
      this.GetStyle(styleId).subscribe(style => {
        return resolve(style);
      });
    });
  }
  
  UnAssignStyleFromRepresentativeMolecule(styleId: number, repMoleculeId: number): Observable<any> {
    return this.http.post(this.apiEndpointUrl + '/UnAssignToMolecule', {
      styleId,
      moleculeId: repMoleculeId,
    }).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error assign style`),
      ),
    );
  }
  
  UnAssignThemeFromApp(themeId: number, appId: number): Observable<any> {
    return this.http.post(this.apiThemesUrl + 'UnAssignToApp', {
      themeId,
      applicationId: appId,
    }).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error unassigning theme`),
      ),
    );
  }
  
  GetAllStyles(): Observable<IRepresentativeMoleculeStyleData[]> {
    return this.http.get(this.apiEndpointUrl).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error gettting styles`),
      ),
    );
  }
  
  SearchTheme(creatorId: number, companyId: number): Observable<Theme[]> {
    return this.http.post(this.apiThemesUrl + 'Find', {
      creatorId,
      companyId,
    }).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error creating theme`),
      ),
    );
  }
  
  GetAllThemes() {
    return this.SearchTheme(null, null);
  }
  
  GetThemesByUser(userId: number = this.clientStorageService.getUserId()) {
    return this.SearchTheme(userId, null);
  }
  
  GetThemesByCompany(companyId = this.clientStorageService.getCompanyId()) {
    return this.SearchTheme(null, companyId);
  }
  
  CreateTheme(theme: Theme): Observable<Theme> {
    return this.http.post(this.apiThemesUrl + 'Create', theme).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error creating theme`),
      ),
    );
  }
  
  UpdateTheme(theme: Theme): Observable<Theme> {
    return this.http.put(this.apiThemesUrl, theme).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error updating theme`),
      ),
    );
  }
  
  GetTheme(themeId: number): Observable<Theme> {
    return this.http.get(this.apiThemesUrl + themeId).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error obtaining theme`),
      ),
    );
  }
  
  DeleteTheme(themeId: number): Observable<Theme> {
    return this.http.delete(this.apiThemesUrl + themeId).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error deleting theme`),
      ),
    );
  }
}
