import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { BuilderService } from '../../../../core/builder/builder.service';
import { ProcessorService } from '../../../../core/molecular/services/processor.service';
import { WorkAreaService } from '../../../../workarea/workarea.service';
import { LeapXLEventType } from '../../../enums/leapxl-event-type.enum';
import { RepresentativeMoleculesType } from '../../../enums/representative-molecules-types.enum';
import { DragService } from '../../services/drag.service';
import { BaseMoleculeComponent } from '../base-molecule/base-molecule.component';

@Component({
  selector: 'app-progress-molecule',
  templateUrl: './progress-molecule.component.html',
  styleUrls: ['./progress-molecule.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ProgressMoleculeComponent extends BaseMoleculeComponent implements OnInit, AfterViewInit, OnDestroy {
  MoleculeType = 'Representative';
  Type = RepresentativeMoleculesType.Progress;
  
  @ViewChild('progressWrapper', { static: true })
  progressWrapper: ElementRef;
  
  @ViewChild('progressBar') progressBar: ElementRef<HTMLElement>;
  
  constructor(
    public builderService: BuilderService,
    public dragService: DragService,
    public workAreaService: WorkAreaService,
    public processorService: ProcessorService,
    bottomSheet: MatBottomSheet,
    public el: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
    super(bottomSheet, el, changeDetectorRef);
  }
  
  get progressBarMode() {
    return this.context.Properties.progressMode as ProgressBarMode;
  }
  
  get progressSpinnerMode() {
    return this.context.Properties.progressMode as ProgressSpinnerMode;
  }
  
  ngOnInit() {
    super.ngOnInit();
    if (!this.context.RunningMode) {
      this.DetachChangeDetection();
    }
    this.context.Type = RepresentativeMoleculesType.Progress;
    this.RefreshGridsterConfiguration();
    this.setColors();
  }
  
  ngAfterViewInit() {
    this.setProgressBarHeight();
  }
  
  UpdateData() {
    this.setColors();
    this.setProgressBarHeight();
  }
  
  setColors() {
    const { progressType, progressColor, progressBackgroundColor } = this.context.Properties;
    this.toolsService.GenerateClassDynamically(
      `${ progressType === 'progress' ? `#gridsterItem-${ this.context.Id } .mat-progress-bar .mat-progress-bar-fill::after` : `#gridsterItem-${ this.context.Id } .mat-progress-spinner circle, .mat-spinner circle` }`,
      `${ progressType === 'progress' ? `background-color: ${ progressColor }` : `stroke: ${ progressColor }` }`,
    );
    this.toolsService.GenerateClassDynamically(
      `#gridsterItem-${ this.context.Id } .mat-progress-bar-buffer`,
      `background-color: ${ progressBackgroundColor }`,
    );
  }
  
  setProgressBarHeight() {
    if (!this.progressBar) {
      return;
    }
    const progressBar = document.querySelector(`#gridsterItem-${ this.context.Id } .mat-progress-bar`);
    this.renderer.setStyle(progressBar, 'height', `${ this.context.Properties.progressStrokeWidth }px`);
  }
  
  AttachEditorEventListeners() {
    this.renderer.listen(this.progressWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, true);
    });
    this.renderer.listen(this.progressWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, false);
    });
    this.renderer.listen(this.progressWrapper.nativeElement, 'drop', (evt) => {
      this.DataDropped(evt);
    });
  }
  
  FireClickEvent(e: any) {
    this.FireRepresentativeMoleculeEvent(LeapXLEventType.Click);
  }
}
