import { Constants } from '../../../shared/constants';
import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class LogUserMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(particleId: string, repMoleculeId: string, busProcessorKey: string, rule: any, logic: any, data: any) {
    try {
      // console.log(`molecule working, login user`);
      setTimeout(() => {
        this.authService.loginCobble().subscribe((response) => {
          console.log('login mole');
          
          if (response.successful) {
            response.user.enabled2FATypes = response.user.enabled2FATypes || [];
            
            if (response && response.user && response.user.enabled2FATypes.length === 0) {
              localStorage.removeItem(`${ Constants.LocalStoragePrefix }aad_redirect_uri`);
              this.Done(particleId, busProcessorKey, repMoleculeId, data);
            } else if (response && response.user && response.user.enabled2FATypes.length > 0) {
              // run 2fa code
              this.tfaDialogService.openDialog(response).subscribe((result) => {
                if (result) {
                  this.Done(particleId, busProcessorKey, repMoleculeId, data);
                } else {
                  this.Done(particleId, busProcessorKey, repMoleculeId, data, true);
                }
              });
            }
          } else {
            console.log('Error login in');
            this.snackerService.ShowMessageOnBottom('Error login in', 'vpn_key_alert');
            this.Done(particleId, busProcessorKey, repMoleculeId, data, true);
          }
        });
      }, 200);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
