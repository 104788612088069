export enum DatasourceType {
  Spreadsheet = 'Spreadsheet',
  Database = 'Database',
  Api = 'Api',
  System = 'System',
  LeapXL = 'LeapXL',
  Custom = 'Custom',
  LocalDataStore = 'LocalDataStore',
  UnifiedDatabase = 'UnifiedDatabase',
  InternetMessaging = 'InternetMessaging',
}
