import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PropertyVersioningDto } from '../../dtos/versioning-dto';
import { ParticleType } from '../../enums/particle-type.enum';
import { Constants } from './../../constants';
import { DataElement } from './data-element';
import { Particle } from './particle';
import { IRepresentativeMolecule } from './representative-molecule.interface';

export class ActionMolecule extends Particle {
  ParticleType = ParticleType.Molecule;
  InternalMoleculeName: string;
  DisplayName: string;
  MoleculeType: string;
  Icon: string;
  Rule: any;
  Logic: any;
  MoleculeId: number;
  DataElements: DataElement[] = [];
  OriginalDataElements: DataElement[] = [];
  RepMoleculeIds: number[] = [];
  
  constructor(molecule: any = {}) {
    super(molecule.particleId || molecule.ParticleId || '');
    
    this.AssingId(molecule.id || molecule.Id);
    this.MoleculeId = molecule.moleculeId || molecule.MoleculeId || 0;
    this.InternalMoleculeName = molecule.molecule || molecule.Molecule || molecule.InternalMoleculeName || molecule.internalMoleculeName || Constants.Defaults.InternalName;
    this.DisplayName = molecule.name || molecule.Name || molecule.DisplayName || molecule.displayName || '';
    this.Icon = molecule.icon || molecule.Icon || 'calendar_view_day';
    this.Rule = molecule.rule || molecule.Rule || {};
    this.Logic = molecule.logic || molecule.Logic || {};
    this.TriggeredByBusId = molecule.TriggeredByBusId || molecule.triggeredByBusId || '';
    const dataElements: [] = molecule.dataElements || molecule.DataElements || [];
    this.RepMoleculeIds = molecule.repMoleculeIds || molecule.RepMoleculeIds || [];
    this.Touched = molecule.touched === true || molecule.Touched === true;
    this.Priority = molecule.priority || molecule.Priority || 0;
    this.AvoidRun = molecule.avoidRun || molecule.AvoidRun || false;
    
    dataElements.forEach(de => {
      const dataElement = new DataElement(de);
      this.DataElements.push(dataElement);
    });
  }
  
  public GetDataElementsContext(): string[] {
    if (this.DataElements.length > 0) {
      return this.DataElements.map(de => de.Context);
    } else {
      return [];
    }
  }
  
  public CloneAndAssignIds(): ActionMolecule {
    const newMolecule = this.Clone();
    newMolecule.AssingId();
    newMolecule.AssingParticleId();
    return newMolecule;
  }
  
  public CloneDataElements() {
    const dataElements = [];
    
    this.DataElements.forEach(de => {
      dataElements.push(new DataElement(de));
    });
    
    return dataElements;
  }
  
  public Clone(): ActionMolecule {
    const newMolecule = new ActionMolecule({
      MoleculeId: this.MoleculeId,
      InternalMoleculeName: this.InternalMoleculeName,
      DisplayName: this.DisplayName,
      TriggeredByBusId: this.TriggeredByBusId,
      Icon: this.Icon,
      Rule: this.cloneDeep(this.Rule),
      Logic: this.Logic,
      DataElements: this.DataElements,
      OriginalDataElements: this.OriginalDataElements,
      RepMoleculeIds: this.RepMoleculeIds,
      Priority: this.Priority,
      ParticleId: this.ParticleId,
      id: this.id,
    });
    return newMolecule;
  }
  
  GetName(): string {
    return this.DisplayName;
  }
  
  GetDisplayValues(): string {
    const particleValues = JSON.stringify(this.Rule);
    return particleValues === '{}' ? 'No Value' : particleValues;
  }
  
  public GetDataElement(particleId: string) {
    return this.DataElements.find(de => de.ParticleId === particleId) || null;
  }
  
  public RemoveDataElement(dataElement: DataElement) {
    
    if (
      dataElement.RangeParticleId !== '' &&
      dataElement.RangeParticleId !== null &&
      dataElement.RangeParticleId !== undefined
    ) {
      const particle = this.GetDataElement(dataElement.RangeParticleId);
      
      if (particle) {
        particle.RangeParticleId = '';
      }
    }
    
    this.DataElements = this.DataElements.filter(de => de.ParticleId !== dataElement.ParticleId);
    this.Touched = true;
    
    if (this.MoleculeId > 0) {
      this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(this.MoleculeId);
    }
  }
  
  UpdateParameter(
    repMolecule: IRepresentativeMolecule,
    busId: string,
    parameterName: string,
    parameterValue: any,
    previousValue: any,
  ): Observable<any> {
    if (Number.isInteger(parameterValue as any)) {
      parameterValue = parseInt(parameterValue, 10);
    }
    
    let path = `buses`;
    path = `${ path }[${ busId }].Particles[${ this.id }].Rule`;
    
    this.Rule[parameterName] = parameterValue;
    this.Touched = true;
    
    this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(repMolecule.ParentId);
    
    if (repMolecule.Versioning[`${ parameterName }-${ path }`]) {
      
      return repMolecule.SavePropertyFromVersioning(new PropertyVersioningDto({
        elementId: repMolecule.Id.toString(),
        property: parameterName,
        value: parameterValue,
        change: 'Edit',
        path: path,
        name: `${ this.GetName() }`,
      })).pipe(map((result) => {
        return repMolecule.SavePropertyFromVersioning(new PropertyVersioningDto({
          elementId: repMolecule.Id.toString(),
          property: 'touched',
          value: true,
          change: 'Touched',
          path: path.replace('.Rule', ''),
          name: `${ this.GetName() }`,
        })).subscribe();
      }));
      
    } else {
      
      return repMolecule.SavePropertyFromVersioning(new PropertyVersioningDto({
        elementId: repMolecule.Id.toString(),
        property: parameterName,
        value: previousValue ? previousValue : '',
        change: 'First Version',
        version: 1.0,
        path: path,
        name: `${ this.GetName() }`,
      })).pipe(
        map((result) => {
          return repMolecule.SavePropertyFromVersioning(new PropertyVersioningDto({
            elementId: repMolecule.Id.toString(),
            property: parameterName,
            value: parameterValue,
            change: 'Edit',
            version: 2.0,
            path: path,
            name: `${ this.GetName() }`,
          })).pipe(map(editResult => {
            return repMolecule.SavePropertyFromVersioning(new PropertyVersioningDto({
              elementId: repMolecule.Id.toString(),
              property: 'touched',
              value: true,
              change: 'Touched',
              path: path.replace('.Rule', ''),
              name: `${ this.GetName() }`,
            })).subscribe();
          })).subscribe();
        }),
      );
    }
    
  }
  
  GetRawObject(): any {
    
    const object = {
      id: this.id,
      ParticleType: this.ParticleType,
      InternalMoleculeName: this.InternalMoleculeName,
      TriggeredByBusId: this.TriggeredByBusId,
      DisplayName: this.DisplayName,
      ParticleId: this.ParticleId,
      MoleculeType: this.MoleculeType,
      Icon: this.Icon,
      Rule: this.Rule,
      Logic: this.Logic,
      MoleculeId: this.MoleculeId,
      Priority: this.Priority,
      Touched: this.Touched,
      RepMoleculeIds: this.RepMoleculeIds,
      DataElements: [],
    };
    
    if (this.DataElements.length > 0) {
      this.DataElements.forEach(de => object.DataElements.push(de.GetRawObject()));
    }
    
    return object;
  }
  
  IncludesContext(context: string): boolean {
    return !!this.DataElements.find(de => de.IncludesContext(context));
  }
  
  ContainsContext(context: string): boolean {
    return !!this.DataElements.find(de => de.HaveSpecificContext(context));
  }
  
  AddRepMoleculeId(repMoleculeId: number) {
    this.RepMoleculeIds = [repMoleculeId];
  }
  
  RemoveRepMolecules() {
    this.RepMoleculeIds = [];
  }
  
  AddDataElement(dataElement: DataElement, positionIndex = 0, replaceDataSource = false): void {
    this.DataElements.splice(positionIndex, replaceDataSource ? 1 : 0, new DataElement(dataElement));
  }
  
  AddDataElements(dataElements: DataElement[], positionIndex = 0, replaceDataSource = false): void {
    dataElements.forEach((de, index) => {
      this.AddDataElement(de, positionIndex + index, replaceDataSource);
    });
  }
  
  ContainsDataElementParticle(particleId: string) {
    return !!this.GetDataElementParticle(particleId);
  }
  
  IsActionMoleculeType(internalMoleculeName: string) {
    return this.InternalMoleculeName === internalMoleculeName;
  }
  
  GetDataElementParticle(particleId: string) {
    return this.DataElements.find(de => de.ParticleId === particleId);
  }
  
}
