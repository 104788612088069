import { Constants } from '../../../shared/constants';
import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class EncodeMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    data?: any,
    dataElementsData?: any,
  ) {
    try {
      // IF MOLECULE IS ATTACHED
      if (this.ExistsRepMoleculeAttached) {
        data = this.RepMoleculeAttached.GetValue;
      }
      
      // IF DATA ELEMENTS ARE ATTACHED
      if (dataElementsData) {
        data = this.toolsService.ExtractValuesByType(dataElementsData).string || Constants.Defaults.DataItemValue;
      } else {
        data = data || '';
      }
      
      let encodeType = '';
      if (rule && rule.encodeType) {
        encodeType = rule.encodeType;
      }
      console.log({ data });
      
      const dataProcessed = this.toolsService.ExtractValuesByType(data);
      console.log(this.toolsService.GetObjectType(dataProcessed.file));
      const dataType = this.toolsService.GetObjectType(data);
      
      switch (dataType) {
        case 'filelist':
          debugger;
          data = this.toolsService.ExtractValuesByType(data).file;
          const reader = new FileReader();
          reader.onload = (event: any) => {
            this.toolsService.DecodeBase64(event.target.result);
            this.Done(particleId, busProcessorKey, repMoleculeId, event.target.result);
          };
          
          switch (rule.encodeType) {
            case 'b64':
              reader.readAsDataURL(data);
              break;
            case 'utf8':
              reader.readAsText(data, 'utf-8');
              break;
            case 'utf16':
              reader.readAsText(data, 'utf-16');
              break;
            case 'hex':
              reader.readAsBinaryString(data);
              break;
            case 'ascii':
              reader.readAsText(data, 'ascii');
              break;
            default:
              reader.readAsDataURL(data);
              break;
          }
          
          break;
        
        default:
          data = this.DataActionModifierApply(data, this.Modifier, { encodeType });
          this.Done(particleId, busProcessorKey, repMoleculeId, data);
          break;
      }
    } catch (error) {
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  private Modifier(value: any, rule: { encodeType: string }): string {
    let valueEncoded = value;
    
    switch (rule.encodeType) {
      case 'b64':
        valueEncoded = this._toolService.EncodeBase64(value);
        break;
      case 'uri':
        valueEncoded = this._toolService.EncodeURI(value);
        break;
      case 'utf8':
        valueEncoded = this._toolService.EncodeUTF8(value);
        break;
      case 'utf16':
        valueEncoded = this._toolService.EncodeUTF16(value);
        break;
      case 'hex':
        valueEncoded = this._toolService.EncodeHEX(value);
        break;
      case 'ascii':
        valueEncoded = this._toolService.EncodeASCII(value);
        break;
      case 'html':
        valueEncoded = this._toolService.EncodeHTML(value);
        break;
      case 'xml':
        valueEncoded = this._toolService.EncodeXML(value);
        break;
      
      default:
        break;
    }
    
    return valueEncoded;
  }
}
