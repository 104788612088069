import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterContentInit,
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSlider, MatSliderChange } from '@angular/material/slider';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { faDatabase, faFileCode, faFileExcel, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import {
  CompactType,
  DisplayGrid,
  GridsterConfig,
  GridsterItem,
  GridsterItemComponentInterface,
  GridType,
} from '@leapxl/gridster';
import * as Selection from '@simonwep/selection-js/dist/selection.min.js';
import { cloneDeep } from 'lodash-es';
import { FileUploader } from 'ng2-file-upload';
import { NgxCaptureService } from 'ngx-capture';
import { forkJoin, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Permissions } from '../../admin/models/permissions.enum';
import { BuilderService } from '../../core/builder/builder.service';
import { Receptor } from '../../core/molecular/receptors.enum';
import { BusService } from '../../core/molecular/services/bus.service';
import { EventsService } from '../../core/molecular/services/events.service';
import { ApiDataSourcesService } from '../../core/services/api-data-sources.service';
import { ApiDataTranslatorService } from '../../core/services/api-data-translator.service';
import { ApiFileService } from '../../core/services/api-file.service';
import { ApiMoleculesService } from '../../core/services/api-molecules.service';
import { ApiPropertiesService } from '../../core/services/api-properties.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { EditorStateService } from '../../core/services/editor-state.service';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { TemplateService } from '../../core/services/template.service';
import { ToolsService } from '../../core/services/tools.service';
import { Constants } from '../../shared/constants';
import { HubConnectionDto } from '../../shared/dtos/hub-connection-dto';
import { PropertyVersioningDto } from '../../shared/dtos/versioning-dto';
import { DatasourceType } from '../../shared/enums/datasource-type.enum';
import { DragType } from '../../shared/enums/drag-type.enum';
import { KEY_CODE } from '../../shared/enums/keycodes.enum';
import { LeapXLEventType } from '../../shared/enums/leapxl-event-type.enum';
import { LeapXLFileFormat } from '../../shared/enums/leapxl-file-format.enum';
import { MoleculesType } from '../../shared/enums/molecules-type.enum';
import { ParticleType } from '../../shared/enums/particle-type.enum';
import { RepresentativeMoleculesType } from '../../shared/enums/representative-molecules-types.enum';
import { IRepresentativeMoleculeStyleData } from '../../shared/interfaces/rep-mol-style.interface';
import { View } from '../../shared/interfaces/view';
import { Bus } from '../../shared/representative-molecule/interfaces/bus';
import { DataElement } from '../../shared/representative-molecule/interfaces/data-element';
import { LeapXLEvent } from '../../shared/representative-molecule/interfaces/leapxl-event';
import {
  RepresentativeMolecule,
} from '../../shared/representative-molecule/interfaces/representative-molecule';
import {
  IRepresentativeMolecule,
} from '../../shared/representative-molecule/interfaces/representative-molecule.interface';
import {
  StepperMoleculeComponent,
} from '../../shared/representative-molecule/molecules/stepper-molecule/stepper-molecule.component';
import {
  WorkgroupMoleculeComponent,
} from '../../shared/representative-molecule/molecules/workgroup-molecule/workgroup-molecule.component';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { DragService } from '../../shared/representative-molecule/services/drag.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { ConnectionStateService } from '../../shared/services/connection-state.service';
import { DraggableWindowManagerService } from '../../shared/services/draggable-window-manager.service';
import { DraggableWindowService, DraggableWindowType } from '../../shared/services/draggable-window.service';
import { FactoryParticleService } from '../../shared/services/factory-particle.service';
import { FactoryService } from '../../shared/services/factory.service';
import { HubConnectionService } from '../../shared/services/hub-connection.service';
import { LeapXLShortcutsService } from '../../shared/services/leapxl-shortcuts.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { workgroupTemplate } from '../../shared/templates/workgroup';
import { DataSource, SpreadsheetService } from '../../spreadsheet/spreadsheet.service';
import { SpreadsheetComponent } from '../../spreadsheet/spreadsheet/spreadsheet.component';
import {
  BottomSheetOptionsComponent,
} from '../bottom-sheet/bottom-sheet-options/bottom-sheet-options.component';
import { BottomSheetService } from '../bottom-sheet/bottom-sheet.service';
import { DatasourceDialogComponent } from '../datasource-dialog/datasource-dialog.component';
import { DatasourceRearrangeComponent } from '../datasource-rearrange/datasource-rearrange.component';
import { DocumentationComponent } from '../documentation/documentation.component';
import { BottomSheetOption } from '../interfaces/bottom-sheet-option.interface';
import { MoleculeRequest } from '../interfaces/molecule-request';
import { DocumentationService } from '../services/documentation.service';
import { SmartTemplatesService } from '../services/smart-templates.service';
import { ThematicService } from '../services/thematic.service';
import { VersioningHistoryDialogService } from '../versioning-history/versioning-history-dialog.service';
import { WorkAreaService } from '../workarea.service';
import { MoleculeManagmentService } from './../../core/services/molecule-managment.service';
import { UserMenuService } from './../../core/services/user-menu.service';

declare var panzoom: any;

@Component({
  selector: 'app-cobblet',
  templateUrl: './cobble.component.html',
  styleUrls: ['./cobble.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state(
        'void',
        style({
          opacity: 0,
        }),
      ),
      transition('void <=> *', animate(300)),
    ]),
    trigger('fadeInOutMenu', [
      state(
        'void',
        style({
          opacity: 0,
        }),
      ),
      transition('void <=> *', animate(150)),
    ]),
    trigger('viewChange', [
      transition(':enter', [style({ opacity: 0 }), animate('100ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('100ms', style({ opacity: 0 }))]),
    ]),
    trigger('fade', [
      transition(':enter', [style({ opacity: '0' }), animate('0.4s ease-out', style({ opacity: '1' }))]),
      transition(':leave', [style({ opacity: '1' }), animate('0.2s ease-out', style({ opacity: '0' }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CobbleComponent implements OnInit, AfterContentInit, OnDestroy, AfterViewChecked {
  @ViewChild(MatMenuTrigger, { static: false })
  contextMenu: MatMenuTrigger;
  
  @ViewChild('tab', { static: false })
  tabMenu: MatMenuTrigger;
  
  @ViewChild('navigationViewsMenuTrigger', { read: MatMenuTrigger, static: false })
  navigationViewMenu: MatMenuTrigger;
  
  @ViewChild('addRepresentativeMoleculeMenuTrigger', { read: MatMenuTrigger, static: false })
  addRepresentativeMoleculeMenu: MatMenuTrigger;
  
  @ViewChild('styleFileUploader', { read: MatMenuTrigger, static: true })
  styleFileUploader: ElementRef;
  // public scrollbarConfig: PerfectScrollbarConfigInterface = {
  //   handlers: ['click-rail', 'drag-thumb', 'wheel', 'touch']
  // };
  @ViewChild('canvasContainer', { static: false }) screen: any;
  dragSelectionRepMoleculeId: number;
  bottomSheetSubscription: Subscription;
  options: GridsterConfig;
  ImgSource = '';
  faDatabase = faDatabase;
  faFileExcel = faFileExcel;
  faFileCode = faFileCode;
  faFileUpload = faFileUpload;
  userName: string;
  cobbleId: number;
  showLoadingResponsive = false;
  subscription: Subscription;
  isCobbleLoaded = false;
  bottomSheet: MatBottomSheet;
  prepareToFinishAnim = false;
  isLoaded = false;
  debug = false;
  isLoading = false;
  WorkgroupMoleculeComponent = WorkgroupMoleculeComponent;
  StepperMoleculeComponent = StepperMoleculeComponent;
  children: IRepresentativeMolecule[] = [];
  dragStart;
  sizeStart;
  dragSelection = null;
  canvasSize = {
    width: 4000,
    height: 4000,
  };
  uploader: FileUploader = new FileUploader({});
  acceptedStyleFileTypes = `.${ LeapXLFileFormat.Style }`;
  availableRepMolecules = [];
  dragStartX = 0;
  dragStartY = 0;
  previousScrollLeft = 0;
  previousScrollTop = 0;
  draggingWorkarea = false;
  workareaEl = null;
  panZoom = null;
  panZoomEnabled = false;
  isSuperAdmin = false;
  userId = 0;
  LeapXLShortcutsService = LeapXLShortcutsService;
  altMenuPosition = {
    x: 600,
    y: 200,
  };
  screenshotRetry = 0;
  editCustomControl = false;
  editCustomControlFromImportedApp = false;
  @ViewChild(SpreadsheetComponent, { static: false })
  private spreadsheet: SpreadsheetComponent;
  
  constructor(
    public builderService: BuilderService,
    private moleculesService: ApiMoleculesService,
    private route: ActivatedRoute,
    public busService: BusService,
    private router: Router,
    public workAreaService: WorkAreaService,
    private versioningService: VersioningHistoryDialogService,
    public fileService: ApiFileService,
    private bottomSheetService: BottomSheetService,
    private shortcutsService: LeapXLShortcutsService,
    public spreadsheetService: SpreadsheetService,
    private genericDialogService: GenericDialogService,
    public dataSourcesService: ApiDataSourcesService,
    public toolsService: ToolsService,
    public templateService: TemplateService,
    private clientStorageService: ClientStorageService,
    private titleService: Title,
    public thematicService: ThematicService,
    private snackerService: SnackerService,
    public cobbleService: CobbleService,
    public dragService: DragService,
    public hubConnectionService: HubConnectionService,
    public draggableWindowService: DraggableWindowService,
    private dataTranslatorService: ApiDataTranslatorService,
    private eventsService: EventsService,
    private ref: ChangeDetectorRef,
    bottomSheet: MatBottomSheet,
    private propertiesService: ApiPropertiesService,
    private connectionStateService: ConnectionStateService,
    public communicationService: CommunicationService,
    private editorStateService: EditorStateService,
    private factoryService: FactoryService,
    private factoryParticleService: FactoryParticleService,
    private angularZone: NgZone,
    private documentationService: DocumentationService,
    private dragableWindowManagerService: DraggableWindowManagerService,
    private captureService: NgxCaptureService,
    private localStorageService: LocalStorageService,
    private smartTemplatesService: SmartTemplatesService,
    private moleculeManagmentService: MoleculeManagmentService,
    private userMenuService: UserMenuService,
  ) {
    this.userId = this.clientStorageService.getUserId();
    this.isSuperAdmin = this.userMenuService.isAdminUser;
    this.userName = this.clientStorageService.getUserName();
    this.bottomSheet = bottomSheet;
    this.debug = this.localStorageService.IsDebug();
    this.panZoomEnabled = false;
    this.editCustomControl = this.userMenuService.checkPermission(Permissions.EditCustomControl);
    this.editCustomControlFromImportedApp = this.userMenuService.checkPermission(
      Permissions.EditCustomControlFromImportedApp);
    
    window.addEventListener('beforeunload', e => {
      // now it saves periodically, was unreliable
      // this.editorStateService.SaveEditorState();
      
      if (propertiesService.SavingInProcess()) {
        workAreaService.ShowUnsavedDataWindow();
        e.preventDefault();
        e.returnValue = 'Do you really want to leave?';
      } else {
        this.hubConnectionService.CobbleClosed();
      }
    });
    
    Object.values(RepresentativeMoleculesType)
    .sort()
    .forEach(type => {
      const template = this.templateService.GetRepresentativeTemplateByType(type);
      
      if (template) {
        this.availableRepMolecules.push({
          type,
          icon: template.icon,
        });
      }
    });
  }
  
  @HostListener('window:devtools', ['$event'])
  DevToolsEvent() {
    // console.log('devtools event');
    
    const devtoolsOpen = this.localStorageService.Get('devtools');
    
    if (devtoolsOpen) {
      this.snackerService.ShowMessageOnBottom(
        'Opening the BROWSER CONSOLE can affect LeapXL performance and be a SECURITY RISK');
      localStorage.removeItem(`${ Constants.LocalStoragePrefix }devtools`);
    }
  }
  
  @HostListener('wheel', ['$event'])
  ScrollEvent(event: WheelEvent) {
    return;
    if (this.workAreaService.editorPreferences.zoom) {
      event.stopPropagation();
      event.preventDefault();
      
      if (event.deltaY < 0) {
        this.zoomIn();
      } else if (event.deltaY > 0) {
        this.zoomOut();
      }
    }
  }
  
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.target as any).nodeName === 'INPUT') {
      return;
    }
    
    switch (event.keyCode) {
      case KEY_CODE.ESC:
        console.log('ESC press');
        this.communicationService.Event.System.KeyPress.$ESCKey.emit(true);
        if (this.workAreaService.elementsSelected.length === 0 && !this.workAreaService.arrowKeysMovementDisabled) {
          // this.workAreaService.HideElementFocusedMenu();
          // this.workAreaService.draggableWindow.forEach(dw => {
          //   dw.Hide();
          // });
          // this.workAreaService.draggableWindow = [];
        }
        break;
      case KEY_CODE.ENTER:
        // console.log('Enter press');
        this.communicationService.Event.System.KeyPress.$EnterKey.emit(true);
        break;
      case KEY_CODE.UP_ARROW:
      case KEY_CODE.DOWN_ARROW:
        if (event.keyCode === KEY_CODE.UP_ARROW) {
          this.communicationService.Event.System.KeyPress.$UPKey.emit(true);
        } else {
          this.communicationService.Event.System.KeyPress.$DOWNKey.emit(true);
        }
        
        if (!this.workAreaService.arrowKeysMovementDisabled && this.workAreaService.primaryElementsSelected.length >= 1) {
          this.workAreaService.elementsSelected.forEach(es => {
            const position = {
              x: es.ResponsiveProperties().x,
              y: es.ResponsiveProperties().y,
              cols: es.ResponsiveProperties().cols,
              rows: es.ResponsiveProperties().rows,
            };
            
            position.y = position.y + (event.keyCode === KEY_CODE.UP_ARROW ? -1 : 1);
            
            if (this.workAreaService.CanPositionElement(es.Id, position)) {
              es.ResponsiveProperties().y = position.y;
              
              es.SavePropertyFromVersioning(
                new PropertyVersioningDto({
                  elementId: es.Id.toString(),
                  property: 'y',
                  value: position.y,
                  path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                  change: 'Edit',
                  name: 'Position Y',
                }),
              )
              .subscribe();
              
              if (es.Type === RepresentativeMoleculesType.WorkGroup) {
                this.communicationService.Event.System.App.$AppChanged.emit(true);
                this.communicationService.Event.System.App.$RefreshUI.emit(true);
                this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(es);
              }
              this.workAreaService.AdjustElementFocusedMenuPosition(es);
              
              const parent = this.busService.Get(es.ParentId.toString());
              if (parent.GridsterConfig && parent.GridsterConfig.api) {
                parent.GridsterConfig.api.optionsChanged();
              }
              
              es.$UpdateValue.emit(true);
            }
          });
        }
        
        break;
      case KEY_CODE.LEFT_ARROW:
      case KEY_CODE.RIGHT_ARROW:
        if (event.keyCode === KEY_CODE.LEFT_ARROW) {
          this.communicationService.Event.System.KeyPress.$LEFTKey.emit(true);
        } else {
          this.communicationService.Event.System.KeyPress.$RIGHTKey.emit(true);
        }
        
        if (!this.workAreaService.arrowKeysMovementDisabled && this.workAreaService.primaryElementsSelected.length >= 1) {
          this.workAreaService.elementsSelected.forEach(es => {
            const position = {
              x: es.ResponsiveProperties().x,
              y: es.ResponsiveProperties().y,
              cols: es.ResponsiveProperties().cols,
              rows: es.ResponsiveProperties().rows,
            };
            
            position.x = position.x + (event.keyCode === KEY_CODE.LEFT_ARROW ? -1 : 1);
            
            if (this.workAreaService.CanPositionElement(es.Id, position)) {
              es.ResponsiveProperties().x = position.x;
              
              es.SavePropertyFromVersioning(
                new PropertyVersioningDto({
                  elementId: es.Id.toString(),
                  property: 'x',
                  value: position.x,
                  path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                  change: 'Edit',
                  name: 'Position X',
                }),
              )
              .subscribe();
              
              if (es.Type === RepresentativeMoleculesType.WorkGroup) {
                this.communicationService.Event.System.App.$AppChanged.emit(true);
                this.communicationService.Event.System.App.$RefreshUI.emit(true);
                this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(es);
              }
              this.workAreaService.AdjustElementFocusedMenuPosition(es);
              
              const parent = this.busService.Get(es.ParentId.toString());
              if (parent.GridsterConfig && parent.GridsterConfig.api) {
                parent.GridsterConfig.api.optionsChanged();
              }
              
              es.$UpdateValue.emit(true);
            }
          });
        }
        break;
      
      default:
        break;
    }
    
    if (this.workAreaService.elementsSelected.length > 0) {
      this.communicationService.Event.Editor.$RefreshRepresentativePropertiesPanel.emit(true);
      this.ref.markForCheck();
    }
  }
  
  ngOnInit() {
    this.subscription = this.communicationService.Event.System.App.$AppChanged.pipe(debounceTime(500))
    .subscribe(result => {
      console.log('$AppChanged');
      
      console.log('=event=');
      setTimeout(() => {
        this.options.api.optionsChanged();
        if (result) {
          this.refreshChildren();
        } else {
          this.UpdateCobbleBackground();
        }
        this.ref.markForCheck();
      }, 0);
    });
    
    this.subscription.add(
      this.communicationService.Event.System.App.$UpdateAppColsSize.subscribe(maxWgCols => {
        console.log('=event=');
        this.options.maxCols = maxWgCols;
        this.options.minCols = maxWgCols;
        
        this.options.api.optionsChanged();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.System.App.$AppResponsiveAdjusting.subscribe(condition => {
        console.log('=event=');
        this.cobbleService.ResponsiveChangeInProcess = condition;
        this.showLoadingResponsive = condition;
        this.ref.markForCheck();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$ReloadElementsOnApp.subscribe(elementsIds => {
        console.log('=event=');
        this.ReloadElements(elementsIds);
        this.workAreaService.HideUnsavedApologyWindow();
        this.propertiesService.CancelSaving();
        this.propertiesService.backupUnsavedProperties = [];
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.DataSource.Spreadsheet.$CloseSpreadsheetPanel.subscribe(
        elementsIds => {
          console.log('=event=');
          this.closeWorkbook();
        }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.WorkArea.$Zoom.subscribe(data => {
        console.log('=event=');
        this.updateZoom(data.level, data.xOrigin, data.yOrigin, data.setLevel);
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.WorkArea.$ResetZoom.subscribe(origin => {
        console.log('=event=');
        this.zoomToDefault(origin.xOrigin, origin.yOrigin);
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.WorkArea.$ResetPan.subscribe(origin => {
        console.log('=event=');
        this.panToDefault();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.WorkArea.$ResetPanZoom.subscribe(origin => {
        console.log('=event=');
        this.zoomToDefault(origin.xOrigin, origin.yOrigin);
        this.panToDefault();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.WorkArea.$TogglePanZoom.subscribe(enable => {
        console.log('=event=');
        if (enable) {
          this.LoadPanZoom();
        } else {
          this.DisablePanZoom();
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$AppUpdated.subscribe((updates: HubConnectionDto[]) => {
        console.log('=event=');
        if (!this.workAreaService.collaborators.find(c => c.userId === updates[0].userId)) {
          this.workAreaService.collaborators.push({
            user: updates[0].user,
            userId: updates[0].userId,
            initials: updates[0].initials,
          });
        }
        this.workAreaService.historyChanges = [];
        
        const moleculesToUpdate = updates.map(u => u.data.moleculeId);
        
        moleculesToUpdate.forEach(moleculeId => {
          const update = updates.find(u => u.data.moleculeId);
          
          if (moleculeId === this.cobbleId && update.data.changedElementName && update.data.changedElementName === 'children') {
            const repMolToRemove = this.busService.GetChannelArray()
            .filter(repMol => repMol.ParentId === this.cobbleId)
            .map(repMol => repMol.Id)
            .filter(id => !update.data.value.map(v => v.id)
            .includes(id));
            repMolToRemove.forEach(id => {
              this.busService.Remove(id.toString());
            });
          }
          
          this.busService.Remove(moleculeId.toString());
        });
        
        setTimeout(() => {
          this.moleculesService.GetParentWithChildren(moleculesToUpdate)
          .subscribe((molecules: any[]) => {
            molecules.forEach(molecule => {
              this.builderService.BuildMolecule(molecule)
              .then(repMolecule => {
                this.communicationService.Event.Editor.WorkArea.$PropertiesUpdated.emit();
              });
              this.communicationService.Event.Editor.$ReloadApp.emit(true);
              this.angularZone.runOutsideAngular(() => {
                const collaboratorElement = document.querySelector(`#collaborator-${ updates[0].userId }`);
                
                if (collaboratorElement) {
                  collaboratorElement.classList.add('collaborator-pulse');
                  setTimeout(() => {
                    collaboratorElement.classList.remove('collaborator-pulse');
                  }, 5000);
                }
                
                setTimeout(() => {
                  moleculesToUpdate.forEach((moleculeId, index) => {
                    this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(moleculeId);
                    const moleculeElement = document.querySelector(`#gridsterItem-${ moleculeId }`);
                    
                    if (moleculeElement) {
                      moleculeElement.classList.add('collaborator-pulse');
                      setTimeout(() => {
                        moleculeElement.classList.remove('collaborator-pulse');
                      }, 2000);
                    }
                    
                    if (index === moleculesToUpdate.length - 1) {
                      this.UpdateView();
                    }
                  });
                }, 500);
              });
            });
          });
        }, 100);
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$AppOpened.subscribe((open: HubConnectionDto) => {
        console.log('=event=');
        if (this.cobbleService.Cobble.id === open.data.cobbleId) {
          if (!this.workAreaService.collaborators.find(c => c.userId === open.userId)) {
            this.workAreaService.collaborators.push({
              user: open.user,
              userId: open.userId,
              initials: open.initials,
            });
          }
          
          setTimeout(() => {
            const collaboratorElement = document.querySelector(`#collaborator-${ open.userId }`);
            
            if (collaboratorElement) {
              collaboratorElement.classList.add('collaborator-pulse');
              setTimeout(() => {
                collaboratorElement.classList.remove('collaborator-pulse');
              }, 5000);
            }
          }, 200);
        }
      }),
    );
    this.subscription.add(
      this.communicationService.Event.System.App.$RefreshUI.subscribe(condition => {
        console.log('=event=');
        this.ref.markForCheck();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$AppClosed.subscribe((closed: HubConnectionDto) => {
        console.log('=event=');
        if (this.cobbleService.Cobble.id === closed.data.cobbleId) {
          // console.log('same App closed');
          
          this.workAreaService.collaborators = this.workAreaService.collaborators.filter(
            c => c.userId !== closed.userId);
        }
      }),
    );
    this.subscription.add(
      this.communicationService.Event.System.App.$ReloadApp.subscribe(type => {
        console.log('=event=');
        this.ReloadCobble(type);
        this.communicationService.Event.Editor.$ReloadApp.emit(true);
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.Preferences.$PreferenceChange.subscribe((property: string) => {
        console.log('=event=');
        console.log('$PreferenceChange');
        if (property === 'grid') {
          this.options.displayGrid = this.workAreaService.editorPreferences.grid ? DisplayGrid.Always : DisplayGrid.None;
          this.options.api.optionsChanged();
          this.refreshChildren();
        }
        if (property === 'push') {
          this.options.pushItems = this.workAreaService.editorPreferences.push;
          
          this.busService.DirectChildrenElements(this.cobbleService.Cobble.id)
          .forEach((child: IRepresentativeMolecule) => {
            if (child.GridsterConfig) {
              child.GridsterConfig.pushItems = this.workAreaService.editorPreferences.push;
              if (child.GridsterConfig.api) {
                child.GridsterConfig.api.optionsChanged();
              }
            }
          });
          
          this.options.api.optionsChanged();
          this.refreshChildren();
        }
        if (property === 'displayLockViewElements') {
          this.refreshChildren();
        }
        if (property === 'oneDatasourceOpenAtTime') {
          this.communicationService.Event.Editor.DataSource.$OpenOneSectionAtTime.emit();
        }
        
        if (property === 'zoom') {
          this.communicationService.Event.Editor.WorkArea.$TogglePanZoom.emit(
            this.workAreaService.editorPreferences.zoom);
        }
        
        this.ref.markForCheck();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.System.Update.$ChangesOnMolecules.pipe(debounceTime(200))
      .subscribe((molecule: IRepresentativeMolecule) => {
        console.log('$ChangesOnMolecules');
        if (!molecule || (molecule && (molecule.ParentId === this.cobbleService.Cobble.id || molecule.Id === this.cobbleService.Cobble.id))) {
          console.log('cobble changes on molecules event');
          this.angularZone.runOutsideAngular(() => {
            this.refreshChildren();
          });
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$ReloadApp.pipe(debounceTime(100))
      .subscribe(() => {
        console.log('=event=');
        console.log('$ReloadApp');
        this.angularZone.runOutsideAngular(() => {
          this.refreshChildren();
        });
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoaded.subscribe(
        condition => {
          console.log('=event=');
          this.isLoaded = condition;
          this.ref.markForCheck();
        }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$CaptureCanvas.subscribe(condition => {
        console.log('=event=');
        this.CaptureCanvas();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoading.subscribe(
        condition => {
          console.log('=event=');
          if (condition) {
            this.spreadsheetService.productionDatasourceOpen = false;
          }
          this.isLoading = condition;
          this.ref.markForCheck();
        }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetSaved.subscribe(saved => {
        console.log('=event=');
        this.ref.markForCheck();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.DataSource.$dataSourceLoaded.subscribe(result => {
        console.log('=event=');
        if (result) {
          this.OpenSpreadSheetPanel();
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$OpenNavigationViewMenu.subscribe(event => {
        console.log('=event=');
        this.navigationViewMenu.openMenu();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$SelectAllRepresentativeMolecules.subscribe(event => {
        console.log('=event=');
        if (this.workAreaService.elementsSelected.length > 0) {
          this.SelectAllElements(this.workAreaService.elementsSelected[0], true, false);
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$OpenPropertiesPanel.subscribe(event => {
        console.log('=event=');
        if (this.workAreaService.elementsSelected.length > 0) {
          this.ShowMoleculeProperties(null, this.workAreaService.elementsSelected[0]);
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$OpenProcessPanel.subscribe(event => {
        console.log('=event=');
        if (this.workAreaService.elementsSelected.length > 0) {
          this.ShowMoleculesPanel(null, this.workAreaService.elementsSelected[0]);
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$OpenMicrolearningForRepSelection.subscribe(event => {
        console.log('=event=');
        if (this.workAreaService.elementsSelected.length > 0) {
          this.OpenHelp(null, this.workAreaService.elementsSelected[0]);
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$OpenMicrolearning.subscribe(event => {
        console.log('=event=');
        this.OpenUserGuideStartPageDocumentation();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$CopyRepresentativeMolecule.subscribe(event => {
        console.log('=event=');
        if (this.workAreaService.elementsSelected.length > 0) {
          this.CopyRepMolecule(this.workAreaService.elementsSelected[0]);
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$CutRepresentativeMolecule.subscribe(event => {
        console.log('=event=');
        if (this.workAreaService.elementsSelected.length > 0) {
          this.CutRepMolecule(this.workAreaService.elementsSelected[0]);
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$PasteRepresentativeMolecule.subscribe(event => {
        console.log('=event=');
        if (this.workAreaService.elementsSelected.length > 0) {
          this.PasteRepMolecule(null, this.workAreaService.elementsSelected[0]);
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$DuplicateRepresentativeMolecule.subscribe(event => {
        console.log('=event=');
        if (this.workAreaService.elementsSelected.length > 0) {
          this.DuplicateRepMolecule(this.workAreaService.elementsSelected[0]);
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$CreateComponent.subscribe(event => {
        console.log('=event=');
        if (this.workAreaService.elementsSelected.length > 0) {
          this.CreateComponent(null, this.workAreaService.elementsSelected[0]);
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$LockUnlockRepresentativeMolecule.subscribe(event => {
        console.log('=event=');
        if (this.workAreaService.elementsSelected.length > 0) {
          this.ToggleLockRepMolecule(this.workAreaService.elementsSelected[0]);
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$HideRepresentativeMolecule.subscribe(event => {
        console.log('=event=');
        if (this.workAreaService.elementsSelected.length > 0) {
          this.HideRepMolecule(this.workAreaService.elementsSelected[0]);
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$DeleteRepresentativeMolecule.subscribe(event => {
        console.log('=event=');
        if (this.workAreaService.elementsSelected.length > 0) {
          this.RemoveRepMolecule(this.workAreaService.elementsSelected[0]);
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$OpenRepresentativeMoleculeHistoryChanges.subscribe(event => {
        console.log('=event=');
        if (this.workAreaService.elementsSelected.length > 0) {
          this.OpenHistory(null, this.workAreaService.elementsSelected[0]);
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$ToggleLockViewVisibility.subscribe(event => {
        console.log('=event=');
        this.ToggleLockViewDisplay();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.$DeselectAllRepresentativeMolecule.subscribe(event => {
        console.log('=event=');
        this.dragService.CancelDrag();
        this.workAreaService.DeselectAllRepresentativeMolecules();
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.WorkArea.$SnapshotRestoreStart.subscribe(appId => {
        console.log('=event=');
        if (this.cobbleService.Cobble.id === appId) {
          this.communicationService.Event.Editor.WorkArea.$ShowLoadingOverlay.emit({
            display: true,
            showSpinner: true,
            iconAnimated: true,
            spinnerType: 'bar',
            message: `Restoring Snapshot`,
            icon: 'settings_backup_restore',
            iconColor: 'mediumseagreen',
          });
        }
      }),
    );
    
    this.subscription.add(
      this.communicationService.Event.Editor.WorkArea.$SnapshotRestoreStop.subscribe(event => {
        console.log('=event=');
        this.communicationService.Event.System.App.$ReloadApp.emit();
        this.communicationService.Event.Editor.WorkArea.$HideLoadingOverlay.emit();
        this.snackerService.ShowMessageOnBottom('Application Restored', 'cloud_done', 4000);
      }),
    );
    
    this.workAreaService.openingProgress = 0;
    this.spreadsheetService.initLoadState.subscribe(isInit => {
      if (isInit) {
        this.isLoading = false;
        this.isLoaded = false;
      }
    });
    
    this.cobbleService.ShowLoadingCobble = true;
    this.cobbleService.LoadingAnimationRunning = true;
    this.prepareToFinishAnim = false;
    
    // region GRIDSTER CONFIG
    this.options = {
      mouseEvents: true,
      collision: false,
      // initCallback: RunCobbleComponent.gridInit,
      // destroyCallback: RunCobbleComponent.gridDestroy,
      // gridSizeChangedCallback: RunCobbleComponent.gridSizeChanged,
      // itemChangeCallback: RunCobbleComponent.itemChange,
      // itemResizeCallback: RunCobbleComponent.itemResize,
      // itemRemovedCallback: RunCobbleComponent.itemRemoved,
      // itemValidateCallback: RunCobbleComponent.itemValidate,
      dragStartCallback: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
        // console.log(item, itemComponent);
        // console.log(itemComponent);
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
      },
      gridType: this.cobbleService.Cobble.deviceType === 'desktop' ? GridType.Fit : GridType.ScrollVertical,
      compactType: CompactType.None,
      margin: 0,
      outerMargin: true,
      outerMarginTop: null,
      outerMarginRight: null,
      outerMarginBottom: null,
      outerMarginLeft: null,
      useTransformPositioning: true,
      mobileBreakpoint: 0,
      scrollSensitivity: 0,
      scrollSpeed: 0,
      minCols: 1000,
      maxCols: 1000,
      minRows: 1000,
      maxRows: 1000,
      maxItemCols: 1000,
      minItemCols: 1,
      maxItemRows: 1000,
      minItemRows: 1,
      maxItemArea: 1050000,
      minItemArea: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      fixedColWidth: 105,
      fixedRowHeight: 105,
      keepFixedHeightInMobile: false,
      keepFixedWidthInMobile: false,
      enableEmptyCellClick: true,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: true,
      itemChangeCallback: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
        console.log('item changed', item);
        if (item.change === 'dragStart') {
          // this.workAreaService.elementClicked = this.busService.Get(item.id);
        }
      },
      emptyCellClickCallback: (event: MouseEvent, item: GridsterItem) => {
        console.log(event);
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
        this.communicationService.Event.Editor.$WorkareaClick.emit(true);
        this.workAreaService.mobileEmulatorSize = 'normal';
        this.workAreaService.RunMobileTest(false);
        
        if ((event.target as any).classList.contains('work-area-gridster')) {
          this.workAreaService.HideDraggableWindows();
          this.workAreaService.EndSettingTabOrder();
        }
        
        if (!this.workAreaService.isDragSelectionRunning) {
          if ((event.target as any).classList.contains('work-area-gridster')) {
            // console.log('hiding');
            this.workAreaService.HideElementFocusedMenu();
          }
        }
        
        setTimeout(() => {
          if (
            !this.dragService.dragBetweenWG.processing &&
            this.dragService.dragBetweenWG.dragging &&
            (event.target as any).classList.contains('work-area-gridster')
          ) {
            console.log('create wg');
            const repMolecule = this.busService.Get(
              this.dragService.dragBetweenWG.repMoleculeIdDragged.toString());
            
            if (repMolecule.Type === RepresentativeMoleculesType.WorkGroup || repMolecule.Type === RepresentativeMoleculesType.Stepper) {
              this.dragService.dragBetweenWG.targetWgId = this.cobbleService.Cobble.id;
              this.dragService.ProcessRepMoleculeDragBetweenWG(null, {
                x: Math.floor(event.offsetX / 4),
                y: Math.floor(event.offsetY / 4),
              });
            } else {
              const repMoleculeTemplate = this.templateService.GetMoleculeTemplateByMoleculeType(
                RepresentativeMoleculesType.WorkGroup);
              
              let cols = repMolecule.ResponsiveProperties().cols + 20;
              let rows = repMolecule.ResponsiveProperties().rows + 20;
              
              const padding = 4;
              let minX = 0;
              let minY = 0;
              
              minX = this.workAreaService.dragStartPositions[repMolecule.Id].x;
              minY = this.workAreaService.dragStartPositions[repMolecule.Id].y;
              
              this.dragService.dragBetweenWG.repMoleculeIdsDragged.forEach(id => {
                const repMoleculeDragged = this.workAreaService.dragStartPositions[id];
                
                minX = repMoleculeDragged.x < minX ? repMoleculeDragged.x : minX;
                minY = repMoleculeDragged.y < minY ? repMoleculeDragged.y : minY;
                
                if (repMoleculeDragged.x + repMoleculeDragged.cols > cols) {
                  cols = repMoleculeDragged.x + repMoleculeDragged.cols;
                }
                
                if (repMoleculeDragged.y + repMoleculeDragged.rows > rows) {
                  rows = repMoleculeDragged.y + repMoleculeDragged.rows;
                }
              });
              
              rows += padding * 2;
              cols += padding * 2;
              
              this.builderService
              .GetMolecule(
                new RepresentativeMolecule(this.cobbleService.Cobble),
                repMoleculeTemplate,
                {
                  x: Math.floor(event.offsetX / 4),
                  y: Math.floor(event.offsetY / 4),
                },
                [
                  {
                    name: `rows`,
                    value: rows - minY,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                  },
                  {
                    name: `cols`,
                    value: cols - minX,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                  },
                  {
                    name: `rowsQty`,
                    value: rows - minY,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                  },
                  {
                    name: `colsQty`,
                    value: cols - minX,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                  },
                ],
                [],
                0,
                false,
                false,
              )
              .then((newWorkgroup: IRepresentativeMolecule) => {
                this.dragService.dragBetweenWG.targetWgId = newWorkgroup.Id;
                this.dragService.dragBetweenWG.repMoleculeIdsDragged.forEach(id => {
                  this.dragService.dragBetweenWG.repMoleculeIdDragged = id;
                  
                  const repMoleculeDragged = this.workAreaService.dragStartPositions[id];
                  
                  repMoleculeDragged.x = repMoleculeDragged.x - minX + padding;
                  repMoleculeDragged.y = repMoleculeDragged.y - minY + padding;
                  
                  this.dragService.ProcessRepMoleculeDragBetweenWG(null, repMoleculeDragged);
                });
              });
            }
          }
        }, 150);
      },
      emptyCellDropCallback: (event: any, position: GridsterItem) => {
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
        this.onDrop(event, position);
      },
      itemInitCallback: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
      },
      enableEmptyCellDrag: true,
      emptyCellDragMaxCols: 50,
      emptyCellDragMaxRows: 50,
      ignoreMarginInRow: false,
      draggable: {
        enabled: true,
        stop: (element: any, component: any, event: MouseEvent) => {
          this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
          const molecule = this.busService.Get(element.id);
          setTimeout(() => {
            this.communicationService.Event.Editor.$RefreshCustomGuidelines.emit(element.id);
            molecule.DisplayCustomGuidelines = true;
          }, 50);
          
          if (this.dragStart) {
          } else {
            return;
          }
          setTimeout(() => {
            if (molecule.ResponsiveProperties().x !== this.dragStart.x || molecule.ResponsiveProperties().y !== this.dragStart.y) {
              if (molecule) {
                this.propertiesService
                .SaveProperties([
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'x',
                    value: element.x,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Position Changed`,
                    name: 'Position - X',
                  }),
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'y',
                    value: element.y,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Position Changed`,
                    name: 'Position - Y',
                  }),
                ])
                .subscribe();
              }
            } else {
              this.workAreaService.ShowElementFocusedMenu(molecule, event);
              console.log('select wg');
              this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
              this.ref.markForCheck();
            }
          }, 50);
          
          this.communicationService.Event.Editor.$RefreshRepresentativePropertiesPanel.emit(true);
        },
        start: (element: any, itemComponent: any, event: MouseEvent) => {
          if ((event.target as any).classList.contains('ticks') || (event.target as any).classList.contains(
            'point')) {
            return;
          }
          
          const repMolecule = this.busService.Get(element.id);
          repMolecule.DisplayCustomGuidelines = false;
          this.workAreaService.HideElementFocusedMenu();
          this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
          this.workAreaService.elementClicked = this.busService.Get(element.id);
          this.dragService.dragBetweenWG.dragging = this.workAreaService.elementClicked.EnableDragBetweenWG;
          this.dragService.dragBetweenWG.repMoleculeIdDragged = this.workAreaService.elementClicked.Id;
          this.dragService.dragBetweenWG.sourceWgId = this.workAreaService.elementClicked.ParentId;
          this.dragStart = {
            x: element.x,
            y: element.y,
          };
          
          setTimeout(() => {
            this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
          }, 40);
        },
      },
      resizable: {
        enabled: true,
        stop: (element: any, itemComponent: any, event: MouseEvent) => {
          this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
          console.log(itemComponent);
          
          // console.log('resize end', element);
          const molecule = this.busService.Get(element.id);
          
          setTimeout(() => {
            this.workAreaService.ShowElementFocusedMenu(molecule);
            
            if (molecule.ResponsiveProperties().cols !== this.sizeStart.cols || molecule.ResponsiveProperties().rows !== this.sizeStart.rows) {
              const propertiesToSave: PropertyVersioningDto[] = [];
              
              // address columns
              if (molecule.ResponsiveProperties().cols !== this.sizeStart.cols) {
                let newWgCols =
                  molecule.ResponsiveProperties().cols > this.sizeStart.cols
                    ? molecule.GridsterConfig.minCols + (molecule.ResponsiveProperties().cols - this.sizeStart.cols)
                    : molecule.GridsterConfig.minCols - (this.sizeStart.cols - molecule.ResponsiveProperties().cols);
                
                // console.log('newWgCols', newWgCols);
                // console.log('element cols', element.cols);
                newWgCols = element.cols;
                
                if (!this.workAreaService.editorPreferences.resizeAll) {
                  const elementsToSave = [];
                  const colsContracted = this.sizeStart.cols - newWgCols;
                  // console.log('cols contracted', colsContracted);
                  
                  if (colsContracted > 0) {
                    const startElementsOnEdge = [];
                    for (let i = 1; i <= colsContracted; i++) {
                      const elementsResized = this.busService
                      .DirectChildrenElements(element.id)
                      .filter(e => e.ResponsiveProperties().x + e.ResponsiveProperties().cols > newWgCols)
                      .sort((a, b) =>
                        a.ResponsiveProperties().x + a.ResponsiveProperties().cols > b.ResponsiveProperties().x + b.ResponsiveProperties().cols
                          ? -1
                          : 1,
                      );
                      
                      // console.log('elements affected', elementsResized);
                      
                      const startElements = [];
                      elementsResized.forEach(e => {
                        const elementsToRight = [];
                        
                        elementsResized.forEach(eCompare => {
                          const yFinal = eCompare.ResponsiveProperties().y + eCompare.ResponsiveProperties().rows;
                          const yStart = eCompare.ResponsiveProperties().y;
                          
                          if (
                            eCompare.ResponsiveProperties().x + eCompare.ResponsiveProperties().cols >
                            e.ResponsiveProperties().x + e.ResponsiveProperties().cols &&
                            ((yFinal >= e.ResponsiveProperties().y && yFinal <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
                              (yStart >= e.ResponsiveProperties().y && yStart <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
                              (yStart <= e.ResponsiveProperties().y && yFinal >= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
                              (yStart >= e.ResponsiveProperties().y && yFinal <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows))
                          ) {
                            elementsToRight.push(eCompare);
                          }
                        });
                        
                        if (elementsToRight.length === 0) {
                          startElements.push(e);
                        }
                      });
                      
                      // console.log('start elements', startElements);
                      
                      startElements.forEach(startElement => {
                        startElementsOnEdge.push(startElement);
                        let elementsChained = [
                          ...new Set(
                            [startElement].concat(
                              this.workAreaService.GetChainedElementsWest(startElement, 1))),
                        ] as IRepresentativeMolecule[];
                        
                        // console.log('elements on chain', elementsChained);
                        
                        if (elementsChained.filter(e => e.ResponsiveProperties().x < 1).length > 0) {
                          // console.log('shrink chain');
                          
                          elementsChained = elementsChained.sort(
                            (a, b) => (a.ResponsiveProperties().x > b.ResponsiveProperties().x ? 1 : -1));
                          
                          elementsChained.forEach(elementToShrink => {
                            // console.log('=====================================================');
                            
                            const elementToShrinkPositioning = elementToShrink.ResponsiveProperties();
                            
                            const colsDifference =
                              elementToShrinkPositioning.cols -
                              (elementToShrinkPositioning.cols * (this.sizeStart.cols - i)) / (this.sizeStart.cols - i + 1);
                            
                            const leftElements = this.GetLeftElements(elementToShrink)
                            .sort((a, b) =>
                              a.ResponsiveProperties().x + a.ResponsiveProperties().cols > b.ResponsiveProperties().x + b.ResponsiveProperties().cols
                                ? 1
                                : -1,
                            );
                            
                            const isAnyOfChainedElementsForElementOnEdge =
                              this.workAreaService.GetChainedElementsWest(elementToShrink, 2)
                              .filter(e => e.ResponsiveProperties().x < 1).length > 0;
                            
                            if (isAnyOfChainedElementsForElementOnEdge || elementToShrinkPositioning.x <= 1) {
                              // =========setting cols for shrinked elments
                              
                              // console.log('new el cols', elementToShrinkPositioning.x + elementToShrinkPositioning.cols - colsDifference, this.sizeStart.cols - i);
                              
                              elementToShrinkPositioning.cols = Math.floor(
                                elementToShrinkPositioning.cols - colsDifference);
                              // elementToShrinkPositioning.cols =
                              //   Math.ceil(
                              //     elementToShrinkPositioning.x +
                              //       elementToShrinkPositioning.cols -
                              //       colsDifference
                              //   ) >
                              //   this.sizeStart.cols - i
                              //     ? this.sizeStart.cols -
                              //       i -
                              //       elementToShrinkPositioning.x +
                              //       1
                              //     : Math.floor(
                              //         elementToShrinkPositioning.cols -
                              //           colsDifference
                              //       );
                              // ==========================================
                              
                              if (leftElements.length > 0) {
                                const overlapingme =
                                  leftElements[leftElements.length - 1].ResponsiveProperties().x +
                                  leftElements[leftElements.length - 1].ResponsiveProperties().cols >
                                  elementToShrinkPositioning.x + 1;
                                if (overlapingme) {
                                  const newX = (elementToShrinkPositioning.x * (this.sizeStart.cols - i)) / (this.sizeStart.cols - i + 1);
                                  // console.log('diff', newX);
                                  elementToShrinkPositioning.x = Math.round(newX);
                                } else {
                                  elementToShrinkPositioning.x = Math.floor(
                                    leftElements[leftElements.length - 1].ResponsiveProperties().x +
                                    leftElements[leftElements.length - 1].ResponsiveProperties().cols,
                                  );
                                }
                              } else {
                                elementToShrinkPositioning.x = 0;
                              }
                              
                              // if (startElement.Id === elementToShrink.Id) {
                              //   elementToShrinkPositioning.cols =
                              //     newWgCols - elementToShrinkPositioning.x;
                              // }
                            } else {
                              elementToShrinkPositioning.x = elementToShrinkPositioning.x - 1;
                            }
                            elementToShrinkPositioning.x = elementToShrinkPositioning.x < 0 ? 0 : elementToShrinkPositioning.x;
                            
                            if (elementsToSave.filter(ets => ets.Id === elementToShrink.Id).length === 0) {
                              elementsToSave.push(elementToShrink);
                            }
                          });
                        } else {
                          elementsChained.forEach(e => {
                            if (elementsToSave.filter(ets => ets.Id === e.Id).length === 0) {
                              elementsToSave.push(e);
                            }
                            
                            e.ResponsiveProperties().x = e.ResponsiveProperties().x - 1;
                          });
                          
                          // console.log('push chain');
                        }
                      });
                    }
                    
                    startElementsOnEdge.forEach((see: IRepresentativeMolecule) => {
                      if (see.ResponsiveProperties().x + see.ResponsiveProperties().cols + 1 >= newWgCols) {
                        see.ResponsiveProperties().cols = newWgCols - see.ResponsiveProperties().x;
                      }
                    });
                    
                    elementsToSave.forEach((e: IRepresentativeMolecule) => {
                      propertiesToSave.push(
                        new PropertyVersioningDto({
                          elementId: e.Id.toString(),
                          property: 'cols',
                          value: e.ResponsiveProperties().cols,
                          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                          change: `Size Changed`,
                          name: 'Width',
                        }),
                      );
                      propertiesToSave.push(
                        new PropertyVersioningDto({
                          elementId: e.Id.toString(),
                          property: 'x',
                          value: e.ResponsiveProperties().x,
                          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                          change: `Position Changed`,
                          name: 'Position - X',
                        }),
                      );
                    });
                  }
                } else {
                  this.ShrinkElementsHorizontally(this.busService.DirectChildrenElements(element.id),
                    molecule,
                    element.cols);
                }
                
                if (molecule.ResponsiveProperties().x !== this.sizeStart.x) {
                  propertiesToSave.push(
                    new PropertyVersioningDto({
                      elementId: element.id,
                      property: 'x',
                      value: molecule.ResponsiveProperties().x,
                      path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                      change: `Size Changed`,
                      name: 'Position - X',
                    }),
                  );
                }
                
                molecule.ResponsiveProperties().colsQty = newWgCols;
                molecule.GridsterConfig.minCols = molecule.GridsterConfig.maxCols = newWgCols;
                molecule.GridsterConfig.maxItemCols = molecule.GridsterConfig.maxCols = newWgCols;
                
                propertiesToSave.push(
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'cols',
                    value: newWgCols,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Size Changed`,
                    name: 'Width',
                  }),
                );
                
                propertiesToSave.push(
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'colsQty',
                    value: molecule.ResponsiveProperties().colsQty,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Size Changed`,
                    name: 'WG Width',
                  }),
                );
                
                if (
                  molecule.StyleMetadata.styles.length > 0 &&
                  !molecule.StyleMetadata.manualAdjustedProperties.map(p => p.property)
                  .includes('cols')
                ) {
                  molecule.StyleMetadata.manualAdjustedProperties.push({
                    property: 'cols',
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                  });
                  molecule
                  .SavePropertyFromVersioning(
                    new PropertyVersioningDto({
                      elementId: molecule.Id.toString(),
                      property: 'styleMetadata',
                      value: molecule.StyleMetadata,
                      path: '',
                      change: 'Style edited',
                      name: 'cols',
                    }),
                  )
                  .subscribe();
                }
                
                // if (molecule.StyleMetadata.styles.length > 0 && !molecule.StyleMetadata.manualAdjustedProperties.map(p => p.property).includes('colsQty')) {
                //   molecule.StyleMetadata.manualAdjustedProperties.push({ property: 'colsQty', path: `properties.responsive.${this.cobbleService.Cobble.deviceType}` });
                //   molecule.SavePropertyFromVersioning(new PropertyVersioningDto({
                //     elementId: molecule.Id.toString(),
                //     property: 'styleMetadata',
                //     value: molecule.StyleMetadata,
                //     path: '',
                //     change: 'Style edited',
                //     name: 'rows'
                //   })).subscribe();
                // }
              }
              
              // address rows
              if (molecule.ResponsiveProperties().rows !== this.sizeStart.rows) {
                let newWgRows =
                  molecule.ResponsiveProperties().rows > this.sizeStart.rows
                    ? molecule.GridsterConfig.minRows + (molecule.ResponsiveProperties().rows - this.sizeStart.rows)
                    : molecule.GridsterConfig.minRows - (this.sizeStart.rows - molecule.ResponsiveProperties().rows);
                
                newWgRows = element.rows;
                
                if (!this.workAreaService.editorPreferences.resizeAll) {
                  const elementsToSave = [];
                  const rowsContracted = this.sizeStart.rows - newWgRows;
                  // console.log('cols contracted', rowsContracted);
                  
                  if (rowsContracted > 0) {
                    for (let i = 1; i <= rowsContracted; i++) {
                      const elementsResized = this.busService
                      .DirectChildrenElements(element.id)
                      .filter(e => e.ResponsiveProperties().y + e.ResponsiveProperties().rows > newWgRows)
                      .sort((a, b) =>
                        a.ResponsiveProperties().y + a.ResponsiveProperties().rows > b.ResponsiveProperties().y + b.ResponsiveProperties().rows
                          ? -1
                          : 1,
                      );
                      
                      // console.log('elements affected', elementsResized);
                      
                      const startElements = [];
                      elementsResized.forEach(e => {
                        const elementsToRight = [];
                        
                        elementsResized.forEach(eCompare => {
                          const xFinal = eCompare.ResponsiveProperties().x + eCompare.ResponsiveProperties().cols;
                          const xStart = eCompare.ResponsiveProperties().x;
                          
                          if (
                            eCompare.ResponsiveProperties().y + eCompare.ResponsiveProperties().rows >
                            e.ResponsiveProperties().y + e.ResponsiveProperties().rows &&
                            ((xFinal >= e.ResponsiveProperties().x && xFinal <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
                              (xStart >= e.ResponsiveProperties().x && xStart <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
                              (xStart <= e.ResponsiveProperties().x && xFinal >= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
                              (xStart >= e.ResponsiveProperties().x && xFinal <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols))
                          ) {
                            elementsToRight.push(eCompare);
                          }
                        });
                        
                        if (elementsToRight.length === 0) {
                          startElements.push(e);
                        }
                      });
                      
                      // console.log('start elements', startElements);
                      
                      startElements.forEach(startElement => {
                        let elementsChained = [
                          ...new Set(
                            [startElement].concat(
                              this.workAreaService.GetChainedElementsNorth(startElement, 1))),
                        ] as IRepresentativeMolecule[];
                        
                        // console.log('elements on chain', elementsChained);
                        
                        if (elementsChained.filter(e => e.ResponsiveProperties().y < 1).length > 0) {
                          // console.log('shrink chain');
                          
                          elementsChained = elementsChained.sort(
                            (a, b) => (a.ResponsiveProperties().y > b.ResponsiveProperties().y ? 1 : -1));
                          
                          elementsChained.forEach(elementToShrink => {
                            // console.log('=====================================================');
                            
                            const elementToShrinkPositioning = elementToShrink.ResponsiveProperties();
                            
                            const rowsDifference =
                              elementToShrinkPositioning.rows -
                              (elementToShrinkPositioning.rows * (this.sizeStart.rows - i)) / (this.sizeStart.rows - i + 1);
                            
                            const northElements = this.GetNorthElements(elementToShrink)
                            .sort((a, b) =>
                              a.ResponsiveProperties().y + a.ResponsiveProperties().rows > b.ResponsiveProperties().y + b.ResponsiveProperties().rows
                                ? 1
                                : -1,
                            );
                            
                            const isAnyOfChainedElementsForElementOnEdge =
                              this.workAreaService.GetChainedElementsNorth(elementToShrink, 2)
                              .filter(e => e.ResponsiveProperties().y < 1).length > 0;
                            
                            if (isAnyOfChainedElementsForElementOnEdge || elementToShrinkPositioning.y <= 1) {
                              // =========setting rows for shrinked elments
                              elementToShrinkPositioning.rows = Math.floor(
                                elementToShrinkPositioning.rows - rowsDifference);
                              // ========================================== here
                              
                              if (northElements.length > 0) {
                                const overlapingme =
                                  northElements[northElements.length - 1].ResponsiveProperties().y +
                                  northElements[northElements.length - 1].ResponsiveProperties().rows >
                                  elementToShrinkPositioning.y + 1;
                                if (overlapingme) {
                                  const newY = (elementToShrinkPositioning.y * (this.sizeStart.rows - i)) / (this.sizeStart.rows - i + 1);
                                  // console.log('diff', newY);
                                  elementToShrinkPositioning.y = Math.round(newY);
                                } else {
                                  elementToShrinkPositioning.y = Math.floor(
                                    northElements[northElements.length - 1].ResponsiveProperties().y +
                                    northElements[northElements.length - 1].ResponsiveProperties().rows,
                                  );
                                }
                              } else {
                                elementToShrinkPositioning.y = 0;
                              }
                            } else {
                              elementToShrinkPositioning.y = elementToShrinkPositioning.y - 1;
                            }
                            elementToShrinkPositioning.y = elementToShrinkPositioning.y < 0 ? 0 : elementToShrinkPositioning.y;
                            
                            if (elementsToSave.filter(ets => ets.Id === elementToShrink.Id).length === 0) {
                              elementsToSave.push(elementToShrink);
                            }
                          });
                        } else {
                          elementsChained.forEach(e => {
                            if (elementsToSave.filter(ets => ets.Id === e.Id).length === 0) {
                              elementsToSave.push(e);
                            }
                            
                            e.ResponsiveProperties().y = e.ResponsiveProperties().y - 1;
                          });
                          
                          // console.log('push chain');
                        }
                      });
                    }
                    
                    elementsToSave.forEach((e: IRepresentativeMolecule) => {
                      propertiesToSave.push(
                        new PropertyVersioningDto({
                          elementId: e.Id.toString(),
                          property: 'rows',
                          value: e.ResponsiveProperties().rows,
                          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                          change: `Size Changed`,
                          name: 'Height',
                        }),
                      );
                      propertiesToSave.push(
                        new PropertyVersioningDto({
                          elementId: e.Id.toString(),
                          property: 'y',
                          value: e.ResponsiveProperties().y,
                          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                          change: `Position Changed`,
                          name: 'Position - Y',
                        }),
                      );
                    });
                  }
                } else {
                  this.ShrinkElementsVertically(this.busService.DirectChildrenElements(element.id), molecule,
                    element.rows);
                }
                
                if (molecule.ResponsiveProperties().y !== this.sizeStart.y) {
                  propertiesToSave.push(
                    new PropertyVersioningDto({
                      elementId: element.id,
                      property: 'y',
                      value: molecule.ResponsiveProperties().y,
                      path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                      change: `Size Changed`,
                      name: 'Position - Y',
                    }),
                  );
                }
                
                molecule.ResponsiveProperties().rowsQty = newWgRows - (molecule.Properties.offsetRows || 0);
                molecule.GridsterConfig.minRows = molecule.ResponsiveProperties().rowsQty;
                molecule.GridsterConfig.maxItemRows = molecule.GridsterConfig.maxRows = molecule.ResponsiveProperties().rowsQty;
                
                propertiesToSave.push(
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'rows',
                    value: newWgRows,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Size Changed`,
                    name: 'Height',
                  }),
                );
                
                propertiesToSave.push(
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'rowsQty',
                    value: molecule.ResponsiveProperties().rowsQty,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Size Changed`,
                    name: 'WG Height',
                  }),
                );
                
                // if (molecule.StyleMetadata.styles.length > 0 && !molecule.StyleMetadata.manualAdjustedProperties.map(p => p.property).includes('rows')) {
                //   molecule.StyleMetadata.manualAdjustedProperties.push({ property: 'rows', path: `properties.responsive.${this.cobbleService.Cobble.deviceType}` });
                //   molecule.SavePropertyFromVersioning(new PropertyVersioningDto({
                //     elementId: molecule.Id.toString(),
                //     property: 'styleMetadata',
                //     value: molecule.StyleMetadata,
                //     path: '',
                //     change: 'Style edited',
                //     name: 'rows'
                //   })).subscribe();
                // }
                
                // if (molecule.StyleMetadata.styles.length > 0 && !molecule.StyleMetadata.manualAdjustedProperties.map(p => p.property).includes('rowsQty')) {
                //   molecule.StyleMetadata.manualAdjustedProperties.push({ property: 'rowsQty', path: `properties.responsive.${this.cobbleService.Cobble.deviceType}` });
                //   molecule.SavePropertyFromVersioning(new PropertyVersioningDto({
                //     elementId: molecule.Id.toString(),
                //     property: 'styleMetadata',
                //     value: molecule.StyleMetadata,
                //     path: '',
                //     change: 'Style edited',
                //     name: 'rowsQty'
                //   })).subscribe();
                // }
                if (
                  molecule.StyleMetadata.styles.length > 0 &&
                  !molecule.StyleMetadata.manualAdjustedProperties.map(p => p.property)
                  .includes('rows')
                ) {
                  molecule.StyleMetadata.manualAdjustedProperties.push({
                    property: 'rows',
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                  });
                  molecule
                  .SavePropertyFromVersioning(
                    new PropertyVersioningDto({
                      elementId: molecule.Id.toString(),
                      property: 'styleMetadata',
                      value: molecule.StyleMetadata,
                      path: '',
                      change: 'Style edited',
                      name: 'rows',
                    }),
                  )
                  .subscribe();
                }
                
                if (
                  molecule.StyleMetadata.styles.length > 0 &&
                  !molecule.StyleMetadata.manualAdjustedProperties.map(p => p.property)
                  .includes('rowsQty')
                ) {
                  molecule.StyleMetadata.manualAdjustedProperties.push({
                    property: 'rowsQty',
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                  });
                  molecule
                  .SavePropertyFromVersioning(
                    new PropertyVersioningDto({
                      elementId: molecule.Id.toString(),
                      property: 'styleMetadata',
                      value: molecule.StyleMetadata,
                      path: '',
                      change: 'Style edited',
                      name: 'rowsQty',
                    }),
                  )
                  .subscribe();
                }
              }
              
              this.communicationService.Event.Editor.$RefreshCustomGuidelines.emit(element.id);
              molecule.DisplayCustomGuidelines = true;
              
              molecule.GridsterConfig.api.optionsChanged();
              
              this.propertiesService.SaveProperties(propertiesToSave)
              .subscribe();
              
              this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(molecule.Id);
            }
          }, 100);
          
          this.communicationService.Event.Editor.$RefreshRepresentativePropertiesPanel.emit(true);
        },
        start: (element: any) => {
          const repMolecule = this.busService.Get(element.id);
          repMolecule.DisplayCustomGuidelines = false;
          
          this.sizeStart = {
            cols: element.cols,
            rows: element.rows,
            x: element.x,
            y: element.y,
          };
          setTimeout(() => {
            this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
          }, 50);
        },
      },
      swap: false,
      pushItems: false,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: {
        north: true,
        east: true,
        south: true,
        west: true,
      },
      pushResizeItems: true,
      displayGrid: DisplayGrid.None,
      disableWindowResize: false,
      disableWarnings: false,
      scrollToNewItems: false,
    };
    // endregion
    
    this.route.paramMap.subscribe(params => {
      window['zoomFactor'] = 0;
      window['zoomScale'] = 0;
      const initView = this.toolsService.GetQueryParams('view');
      
      this.workAreaService.openingProgress = 0;
      this.cobbleId = +params.get('id');
      this.communicationService.Event.Editor.SidePanels.$Open.emit(1);
      this.communicationService.Event.Editor.SidePanels.$Open.emit(2);
      this.isLoaded = false;
      this.isLoading = false;
      this.isCobbleLoaded = false;
      this.workAreaService.activeCobble.next(this.cobbleId);
      this.workAreaService.progressBarMode = 'indeterminate';
      this.cobbleService.ClearCobbleSession();
      this.workAreaService.historyChanges = [];
      this.workAreaService.collaborators = [];
      
      this.moleculesService.GetApp(this.cobbleId)
      .subscribe(
        (cobble: any) => {
          // this.communicationService.Event.Editor.DataSource.$ReloadDataSourcePanel.emit([]);
          // app empty or not found
          if (cobble && cobble.length === 0) {
            this.snackerService.ShowMessageOnBottom('App not found!', 'warning');
            this.router.navigate(['/workarea']);
          }
          // app found
          else {
            this.workAreaService.progressBarMode = 'determinate';
            cobble[0].deviceType = this.workAreaService.lastDeviceTypeSelected;
            this.cobbleService.SetCobble(cobble[0]);
            this.thematicService.GetLibrary();
            // region Get App on demand
            //  const editorState = this.editorStateService.GetEditorState();
            //  const viewIdSelected = editorState ? editorState.view.id : this.cobbleService.Cobble.properties.views[0].id;
            //  this.moleculesService.GetViewRepresentativeMolecules(viewIdSelected).subscribe(repMolecules => {
            //    for (let idx = 0; idx < repMolecules.length; idx++) {
            //      this.builderService.BuildMolecule(repMolecules[idx], null, null, false);
            //    }
            //
            //    this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(null);
            //    this.communicationService.Event.Editor.Views.$ViewsPanelChange.emit(1);
            //    this.communicationService.Event.Editor.Views.$RefreshViewsPanelUI.emit(true);
            //
            //    this.moleculesService.GetApp(this.cobbleId).subscribe((app: any) => {
            //      for (let idx = 1; idx < app.length; idx++) {
            //        this.builderService.BuildMolecule(app[idx], null, null, false);
            //      }
            //
            //      this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(null);
            //      this.communicationService.Event.Editor.Views.$ViewsPanelChange.emit(1);
            //      this.communicationService.Event.Editor.Views.$RefreshViewsPanelUI.emit(true);
            //    });
            //  });
            // endregion
            
            // region Selecting view
            const editorState = this.editorStateService.GetEditorState();
            let viewSelected = initView
              ? this.cobbleService.Cobble.properties.views.find(v => v.id === +initView)
              : editorState && !!this.cobbleService.Cobble.properties.views.find(
                v => v.id === editorState.view.id)
                ? editorState.view
                : this.cobbleService.Cobble.properties.views[0];
            viewSelected = viewSelected || this.cobbleService.Cobble.properties.views[0];
            
            this.workAreaService.actualEditorViews = [viewSelected];
            // end region
            
            this.workAreaService.topBarTitle = this.cobbleService.Cobble.properties.name;
            
            this.titleService.setTitle(
              this.cobbleService.Cobble.properties.name !== '' ? `Leap | ${ this.cobbleService.Cobble.properties.name }` : 'Leap',
            );
            
            setTimeout(() => {
              const contextmenus = document.querySelectorAll('.context-actions.non-visible');
              contextmenus.forEach(m => {
                m.classList.remove('non-visible');
              });
            }, 1000);
            
            const k = cobble.length / 100;
            for (let idx = 0; idx < cobble.length; idx++) {
              this.builderService.BuildMolecule(cobble[idx], null, null, false);
              this.workAreaService.openingProgress += k;
            }
            this.isCobbleLoaded = true;
            this.workAreaService.cobbleLoaded.next(this.isCobbleLoaded);
            this.workAreaService.openingProgress = 100;
            this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(null);
            this.communicationService.Event.Editor.Views.$ViewsPanelChange.emit(1);
            this.communicationService.Event.Editor.Views.$RefreshViewsPanelUI.emit(true);
            
            if (this.cobbleService.Cobble.deviceType === 'smartphone') {
              this.communicationService.Event.System.App.$AppResponsiveAdjusting.emit(true);
              this.communicationService.Event.System.App.$ReloadApp.emit(
                this.cobbleService.Cobble.deviceType);
            }
            
            this.communicationService.Event.System.App.$AppLoaded.emit(true);
            this.UpdateCobbleBackground();
            
            // const cobbleDs = this.busService.GetDataSourcesForApp();
            // open spreadsheet on init
            // if (cobbleDs.length >= 1 && !this.debug) {
            // const spreadsheetDS = cobbleDs.find(ds => ds.dataSourceType === DatasourceType.Spreadsheet);
            // if (spreadsheetDS) {
            // this.spreadsheetService.LoadDataSource(
            //   1397,
            //   'spreadsheetDS.dataSourceName',
            //   0
            // );
            // }
            // }
            
            this.cobbleService.ShowLoadingCobble = false;
            this.cobbleService.LoadingAnimationRunning = false;
            this.workAreaService.SwitchLeftPanelTab(this.cobbleService.Cobble.isApplication ? 0 : 2);
            this.hubConnectionService.AppOpened();
            setTimeout(() => {
              // console.log('done loading app');
              this.editorStateService.RestoreEditorState();
              
              this.ShowInitialHelp();
              this.communicationService.Event.Editor.DataSource.$ReloadDataSourcePanel.emit([]);
              this.ref.markForCheck();
              this.EvaluateAndReAddBrokenCustomEvents();
              
              // disbale panzoom at start
              setTimeout(() => {
                this.LoadPanZoom();
                this.DisablePanZoom();
              }, 2000);
            }, 1000);
          }
        },
        error => {
          this.router.navigate(['/workarea']);
        },
      );
      
      this.shortcutsService.InitShortcuts();
    });
    
    // region DRAG SELECTION
    
    this.dragSelection = Selection.create({
      mode: 'cover',
      
      // Class for the selection-area0
      class: 'drag-selection',
      
      // All elements in this container can be selected
      selectables: ['.workgroup-child', '.workgroup'],
      
      // The container is also the boundary in this case
      boundaries: ['.work-area-gridster'],
    })
    .on('beforestart', selectionEvent => {
      // console.log('selectionevent', selectionEvent);
      
      if (!selectionEvent.oe.ctrlKey && selectionEvent.oe.target.classList.contains('work-area-gridster')) {
        return false;
      }
      
      this.workAreaService.isDragSelectionInsideWorkgroup = !selectionEvent.oe.target.classList.contains(
        'work-area-gridster');
      this.dragSelectionRepMoleculeId = this.workAreaService.isDragSelectionInsideWorkgroup
        ? +selectionEvent.oe.target.dataset.repmolid
        : +this.cobbleService.Cobble.id;
      
      if (!this.workAreaService.isDragSelectionInsideWorkgroup && !selectionEvent.oe.ctrlKey && !selectionEvent.oe.metaKey) {
        this.workAreaService.DeselectAllRepresentativeMolecules();
      }
      
      if ((selectionEvent.oe as MouseEvent).button !== 0) {
        // console.log(selectionEvent);
        return false;
      }
    })
    .on('start', ({ inst, selected, oe }) => {
      const event = oe as MouseEvent;
      // console.log('oe', event);
      // console.log('START DRAG SELECTION');
      // console.log('selected', selected);
      this.workAreaService.isDragSelectionRunning = true;
      this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
      // Remove class if the user isn't pressing the control key or ⌘ key
      if (!event.ctrlKey && !event.metaKey) {
        // Unselect all elements
        for (const el of selected) {
          el.classList.remove('selected');
          inst.removeFromSelection(el);
        }
        
        // Clear previous selection
        inst.clearSelection();
      }
    })
    .on('move', (evt: { changed: { added: any[]; removed: any[] }; oe: MouseEvent }) => {
      if (evt.changed.removed.length > 0) {
        // console.log('removed', evt.changed.removed);
      }
      
      evt.changed.removed.forEach(repMolEl => {
        const repMolecule = this.busService.Get(repMolEl.dataset.repmolid);
        if (repMolecule.ParentId === this.dragSelectionRepMoleculeId) {
          if (
            (this.workAreaService.isDragSelectionInsideWorkgroup && repMolecule.Type !== RepresentativeMoleculesType.WorkGroup) ||
            (!this.workAreaService.isDragSelectionInsideWorkgroup && repMolecule.Type === RepresentativeMoleculesType.WorkGroup) ||
            evt.oe.ctrlKey ||
            evt.oe.metaKey
          ) {
            this.workAreaService.DeselectRepresentativeMolecule(repMolEl.dataset.repmolid);
          }
        }
      });
      
      evt.changed.added.forEach(repMolEl => {
        const repMolecule = this.busService.Get(repMolEl.dataset.repmolid);
        // console.log(this.dragSelectionRepMoleculeId, repMolecule.ParentId);
        if (repMolecule && repMolecule.ParentId === this.dragSelectionRepMoleculeId) {
          if (
            (this.workAreaService.isDragSelectionInsideWorkgroup && repMolecule.Type !== RepresentativeMoleculesType.WorkGroup) ||
            (!this.workAreaService.isDragSelectionInsideWorkgroup && repMolecule.Type === RepresentativeMoleculesType.WorkGroup) ||
            evt.oe.ctrlKey ||
            evt.oe.metaKey
          ) {
            this.workAreaService.SelectRepresentativeMolecule(repMolecule, true);
          }
        }
      });
      
      // Add a custom class to the elements that where selected.
      // for (const el of added) {
      //   el.classList.add('selected');
      // }
      //
      // // Remove the class from elements that where removed
      // // since the last selection
      // for (const el of removed) {
      //   el.classList.remove('selected');
      // }
    })
    .on('stop', ({ inst }) => {
      // inst.keepSelection();
      
      setTimeout(() => {
        // console.log('STOP DRAG SELECTION');
        this.workAreaService.isDragSelectionRunning = false;
        // removed to avoid lag on group selection
        // this.communicationService.Event.Editor.Views.$RefreshViewsPanelUI.emit(true);
        // this.communicationService.Event.Editor.EventsTree.$RefreshEventsTree.emit(true);
        // this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanel.emit();
      }, 150);
      
      this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
      
      if (this.workAreaService.elementsSelected.length > 0) {
        this.workAreaService.AdjustElementFocusedMenuPosition(
          this.workAreaService.primaryElementsSelected[0]);
        this.workAreaService.showElementFocusedMenu = true;
      }
    });
    // endregion
    this.workAreaService.HideDraggableWindows();
    this.workareaEl = document.querySelector('.work-area-content') as any;
  }
  
  ShowInitialHelp() {
    if (this.cobbleService.Cobble.id > 0) {
      const showInitialAssistPage = this.clientStorageService.getInitialAssistPage() !== 'N';
      const isAppEmpty = this.busService.GetChannelArray().length === 1;
      
      if (isAppEmpty && showInitialAssistPage) {
        this.draggableWindowService.OpenDraggableWindow(
          `Welcome`,
          DraggableWindowType.InitialAssist,
          null,
          null,
          false,
          {},
        );
      }
      
      return;
      //region disable show other pages
      const showStartPage = this.clientStorageService.getGuideStartPage() === 'N';
      const showSmartTemplateStartPage = this.clientStorageService.gethideSmartTemplateStartPage() !== 'Y';
      
      if (showSmartTemplateStartPage && isAppEmpty) {
        this.smartTemplatesService.autoOpen = true;
        this.communicationService.Event.Editor.$OpenSmartTemplateWizard.emit();
      }
      
      setTimeout(() => {
        if (showStartPage && isAppEmpty) {
          this.OpenUserGuideStartPageDocumentation();
        }
      }, 100);
      //endregion
    }
  }
  
  UpdateView() {
    const view = this.workAreaService.actualEditorViews[0];
    
    if (view) {
      const viewId = view.id;
      this.workAreaService.SwitchToView(999);
      
      setTimeout(() => {
        this.workAreaService.SwitchToView(viewId);
      }, 400);
    }
  }
  
  LoadPanZoom(activatePan = false) {
    // if (!this.workAreaService.editorPreferences.zoom) {
    //   return;
    // }
    
    window['zoomFactor'] = 0;
    window['zoomScale'] = 0;
    
    const workareaElement = document.querySelector('.work-area-gridster');
    
    if (workareaElement) {
      this.panZoom = panzoom(workareaElement, {
        maxZoom: 1.8,
        minZoom: 0.4,
        zoomSpeed: 0.025,
        beforeMouseDown: e => {
          const shouldIgnore = e.ctrlKey || e.metaKey;
          return shouldIgnore;
        },
      });
      
      this.panZoom.on('zoomend', e => {
        const transform = e.getTransform();
        this.CalcZoomDragOffset(transform.scale);
      });
      
      this.panZoom.on('zoom', e => {
        const transform = e.getTransform();
        this.CalcZoomDragOffset(transform.scale);
      });
      
      this.panZoom.on('panstart', e => {
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
      });
      
      this.panZoom.on('panend', e => {
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
      });
      
      if (activatePan) {
        this.panZoom.smoothMoveTo(20, 20);
        this.panZoomEnabled = true;
      }
    } else {
      console.warn('PanZoom not working, could not find workarea gridster element');
    }
  }
  
  CalcZoomDragOffset(scale: number) {
    this.workAreaService.zoomLevel = Math.round(scale * 50);
    
    if (scale < 0.6) {
      window['zoomFactor'] = scale;
    } else if (scale > 1) {
      window['zoomFactor'] = scale * -0.3;
    } else {
      window['zoomFactor'] = scale * -0.02;
    }
    window['zoomScale'] = scale;
    
    if (scale >= 0.99 && scale <= 1.1) {
      window['zoomFactor'] = 0;
      window['zoomScale'] = 0;
    }
  }
  
  DisablePanZoom() {
    this.zoomToDefault();
    this.panToDefault();
    this.panZoom?.dispose();
    window['zoomFactor'] = 0;
    window['zoomScale'] = 0;
    this.panZoomEnabled = false;
  }
  
  MouseEnterTopBar() {
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
  }
  
  SetCanvasSize(height: number, width: number) {
    this.canvasSize = {
      height: height,
      width: width,
    };
    this.ref.markForCheck();
  }
  
  EvaluateAndReAddBrokenCustomEvents() {
    this.busService.GetChannelArray()
    .forEach(repMol => {
      const repMolecule = repMol as IRepresentativeMolecule;
      repMolecule.GetAllEvents()
      .forEach(event => {
        //
        // if (event.EventName.includes(' Case Venue Changed')) {
        //   debugger
        // }
        
        if (event.EventSource === 'Custom' && !this.eventsService.CustomEventExists(event)) {
          this.eventsService.CustomEvents.push(event);
        }
      });
    });
  }
  
  ResetCanvasSize() {
    this.SetCanvasSize(4000, 4000);
  }
  
  CaptureCanvas() {
    const viewWgs = this.busService.GetViewWorkgroupsMolecules(this.cobbleService.Cobble,
      this.workAreaService.ActualView.id);
    
    const yMinWG =
      viewWgs.length > 0
        ? viewWgs.reduce((prev, current) =>
          prev.Properties.responsive[this.cobbleService.Cobble.deviceType].y < current.Properties.responsive[this.cobbleService.Cobble.deviceType].y
            ? prev
            : current,
        )
        : null;
    
    const xMinWg =
      viewWgs.length > 0
        ? viewWgs.reduce((prev, current) =>
          prev.Properties.responsive[this.cobbleService.Cobble.deviceType].x < current.Properties.responsive[this.cobbleService.Cobble.deviceType].x
            ? prev
            : current,
        )
        : null;
    const yMaxWG =
      viewWgs.length > 0
        ? viewWgs.reduce((prev, current) =>
          prev.Properties.responsive[this.cobbleService.Cobble.deviceType].y +
          prev.Properties.responsive[this.cobbleService.Cobble.deviceType].rows >
          current.Properties.responsive[this.cobbleService.Cobble.deviceType].y +
          current.Properties.responsive[this.cobbleService.Cobble.deviceType].rows
            ? prev
            : current,
        )
        : null;
    
    const xMaxWg =
      viewWgs.length > 0
        ? viewWgs.reduce((prev, current) =>
          prev.Properties.responsive[this.cobbleService.Cobble.deviceType].x +
          prev.Properties.responsive[this.cobbleService.Cobble.deviceType].cols >
          current.Properties.responsive[this.cobbleService.Cobble.deviceType].x +
          current.Properties.responsive[this.cobbleService.Cobble.deviceType].cols
            ? prev
            : current,
        )
        : null;
    
    const ySize = yMaxWG.ResponsiveProperties().y + yMaxWG.ResponsiveProperties().rows - yMinWG.ResponsiveProperties().y;
    const xSize = xMaxWg.ResponsiveProperties().x + xMaxWg.ResponsiveProperties().cols - xMinWg.ResponsiveProperties().x;
    
    const xMinCanvas = {
      desktop: 1000,
      smartphone: 350,
    };
    const yMinCanvas = {
      desktop: 600,
      smartphone: 500,
    };
    
    const elementsXSize = xSize * 4;
    const elementsYSize = ySize * 4;
    
    this.SetCanvasSize(
      elementsYSize < yMinCanvas[this.cobbleService.Cobble.deviceType] ? yMinCanvas[this.cobbleService.Cobble.deviceType] : elementsYSize,
      elementsXSize < xMinCanvas[this.cobbleService.Cobble.deviceType] ? xMinCanvas[this.cobbleService.Cobble.deviceType] : elementsXSize,
    );
    
    setTimeout(() => {
      this.captureService.getImage(document.getElementById('work-area-wrapper'), true)
      .subscribe(
        image => {
          this.snackerService.ShowMessageOnBottom('Screenshot completed', 'camera_enhance');
          
          this.toolsService.UrlToFile(image, this.toolsService.GenerateGuid() + '.png', 'image/png')
          .then(imageFile => {
            // console.log(imageFile);
            
            const previewType = this.cobbleService.Cobble.deviceType === 'desktop' ? 'previewDesktopUrl' : 'previewMobileUrl';
            this.fileService.uploadFile([imageFile], 2, this.cobbleService.Cobble.id)
            .subscribe(result => {
              this.propertiesService
              .SaveProperty(
                new PropertyVersioningDto({
                  elementId: this.cobbleService.Cobble.id.toString(),
                  property: previewType,
                  value: result,
                  path: '',
                  change: `Preview Image`,
                  name: this.cobbleService.Cobble.properties.name,
                }),
              )
              .subscribe(r => {
                this.cobbleService.Cobble[previewType] = result;
                this.communicationService.Event.System.App.$AppEdited.emit();
              });
            });
          });
          this.ResetCanvasSize();
          this.screenshotRetry = 0;
        },
        error => {
          console.error(error);
          this.ResetCanvasSize();
          this.communicationService.Event.Editor.$ErrorCaptureCanvas.emit();
          if (this.screenshotRetry === 0) {
            this.screenshotRetry++;
            this.communicationService.Event.Editor.$RetryCaptureCanvas.emit();
            this.CaptureCanvas();
          } else {
            this.screenshotRetry = 0;
          }
        },
      );
    }, 200);
  }
  
  GetLeftElements(e: IRepresentativeMolecule) {
    const elementsFound = [];
    this.busService.DirectChildrenElements(e.ParentId)
    .forEach(eCompare => {
      const yFinal = eCompare.ResponsiveProperties().y + eCompare.ResponsiveProperties().rows;
      const yStart = eCompare.ResponsiveProperties().y;
      
      if (
        ((yFinal >= e.ResponsiveProperties().y && yFinal <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
          (yStart >= e.ResponsiveProperties().y && yStart <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
          (yStart <= e.ResponsiveProperties().y && yFinal >= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
          (yStart >= e.ResponsiveProperties().y && yFinal <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows)) &&
        eCompare.ResponsiveProperties().x + eCompare.ResponsiveProperties().cols <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols &&
        eCompare.Id !== e.Id
      ) {
        // console.log('Elements found', eCompare);
        elementsFound.push(eCompare);
      }
    });
    
    return elementsFound;
  }
  
  GetNorthElements(e: IRepresentativeMolecule) {
    const elementsFound = [];
    this.busService.DirectChildrenElements(e.ParentId)
    .forEach(eCompare => {
      const xFinal = eCompare.ResponsiveProperties().x + eCompare.ResponsiveProperties().cols;
      const xStart = eCompare.ResponsiveProperties().x;
      
      if (
        ((xFinal >= e.ResponsiveProperties().x && xFinal <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
          (xStart >= e.ResponsiveProperties().x && xStart <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
          (xStart <= e.ResponsiveProperties().x && xFinal >= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
          (xStart >= e.ResponsiveProperties().x && xFinal <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols)) &&
        eCompare.ResponsiveProperties().y + eCompare.ResponsiveProperties().rows <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows &&
        eCompare.Id !== e.Id
      ) {
        // console.log('Elements found', eCompare);
        elementsFound.push(eCompare);
      }
    });
    
    return elementsFound;
  }
  
  ShrinkElementsHorizontally(elements: IRepresentativeMolecule[], parent: IRepresentativeMolecule, parentCols: number) {
    const propertiesToSave = [];
    
    elements.forEach(child => {
      let gridRatio = 1;
      
      if (this.sizeStart.cols !== parent.ResponsiveProperties().colsQty) {
        // console.log('wg cols b', this.sizeStart.cols);
        // console.log('wg cols qty b', parent.ResponsiveProperties().colsQty);
        
        gridRatio = this.sizeStart.cols / parent.ResponsiveProperties().colsQty;
      }
      
      // console.log('grid ratio', gridRatio);
      
      let ratioColsChange = ((child.ResponsiveProperties().cols * parentCols) / this.sizeStart.cols) * gridRatio;
      let ratioXChange = ((child.ResponsiveProperties().x * parentCols) / this.sizeStart.cols) * gridRatio;
      // console.log('ratio cols change', ratioColsChange);
      // console.log('ratio x change', ratioXChange);
      
      if (Math.round(ratioColsChange) + Math.round(ratioXChange) > parentCols) {
        ratioColsChange = Math.floor(ratioColsChange);
        ratioXChange = Math.floor(ratioXChange);
      } else {
        ratioColsChange = Math.round(ratioColsChange);
        ratioXChange = Math.round(ratioXChange);
      }
      
      ratioColsChange = ratioColsChange < 1 ? 1 : ratioColsChange;
      ratioXChange = ratioXChange < 0 ? 0 : ratioXChange;
      
      child.ResponsiveProperties().cols = ratioColsChange;
      child.ResponsiveProperties().x = ratioXChange;
      
      propertiesToSave.push(
        new PropertyVersioningDto({
          elementId: child.Id.toString(),
          property: 'x',
          value: child.ResponsiveProperties().x,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: `Position Changed`,
          name: 'Position - X',
        }),
      );
      propertiesToSave.push(
        new PropertyVersioningDto({
          elementId: child.Id.toString(),
          property: 'cols',
          value: child.ResponsiveProperties().cols,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: `Size Changed`,
          name: 'Width',
        }),
      );
    });
    
    this.propertiesService.SaveProperties(propertiesToSave)
    .subscribe();
  }
  
  ShrinkElementsVertically(elements: IRepresentativeMolecule[], parent: IRepresentativeMolecule, parentRows: number) {
    const propertiesToSave = [];
    
    elements.forEach(child => {
      let gridRatio = 1;
      
      if (this.sizeStart.rows !== parent.ResponsiveProperties().rowsQty) {
        // console.log('wg cols b', this.sizeStart.rows);
        // console.log('wg cols qty b', parent.ResponsiveProperties().rowsQty);
        
        gridRatio = this.sizeStart.rows / parent.ResponsiveProperties().rowsQty;
      }
      
      // console.log('grid ratio', gridRatio);
      
      let ratioRowsChange = ((child.ResponsiveProperties().rows * parentRows) / this.sizeStart.rows) * gridRatio;
      let ratioYChange = ((child.ResponsiveProperties().y * parentRows) / this.sizeStart.rows) * gridRatio;
      
      if (Math.round(ratioRowsChange) + Math.round(ratioYChange) > parentRows) {
        ratioRowsChange = Math.floor(ratioRowsChange);
        ratioYChange = Math.floor(ratioYChange);
      } else {
        ratioRowsChange = Math.round(ratioRowsChange);
        ratioYChange = Math.round(ratioYChange);
      }
      
      ratioRowsChange = ratioRowsChange < 1 ? 1 : ratioRowsChange;
      ratioYChange = ratioYChange < 0 ? 0 : ratioYChange;
      
      child.ResponsiveProperties().rows = ratioRowsChange;
      child.ResponsiveProperties().y = ratioYChange;
      
      propertiesToSave.push(
        new PropertyVersioningDto({
          elementId: child.Id.toString(),
          property: 'y',
          value: child.ResponsiveProperties().y,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: `Position Changed`,
          name: 'Position - Y',
        }),
      );
      propertiesToSave.push(
        new PropertyVersioningDto({
          elementId: child.Id.toString(),
          property: 'rows',
          value: child.ResponsiveProperties().rows,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: `Size Changed`,
          name: 'Height',
        }),
      );
    });
    
    this.propertiesService.SaveProperties(propertiesToSave)
    .subscribe();
  }
  
  ReloadCobble(deviceType = 'desktop') {
    window['zoomFactor'] = 0;
    window['zoomScale'] = 0;
    
    // setting id to avoid UI change flick when responsive device is switch
    this.cobbleService.ClearCobble();
    this.busService.Clear();
    this.SetGridOptions(deviceType);
    this.moleculesService.GetApp(this.cobbleId)
    .subscribe((cobble: any[]) => {
      this.cobbleService.SetCobble(cobble[0]);
      this.cobbleService.Cobble.deviceType = deviceType;
      
      // run outside to improve UI performance
      this.angularZone.runOutsideAngular(() => {
        for (let idx = 0; idx < cobble.length; idx++) {
          // console.log(cobble[idx]);
          this.builderService.BuildMolecule(cobble[idx], null, null, false);
        }
      });
      
      this.UpdateCobbleBackground();
      
      setTimeout(() => {
        this.communicationService.Event.System.App.$AppResponsiveAdjusting.emit(false);
        this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(null);
        // console.log('reloading cobble done');
        this.ref.markForCheck();
        this.communicationService.Event.Editor.$RefreshRepresentativePropertiesPanel.emit(true);
        this.editorStateService.HideRepMolNoVisible();
      }, 1000);
    });
  }
  
  ReloadElements(moleculesIds: string[]) {
    moleculesIds.forEach(id => {
      this.busService.Remove(id);
    });
    
    this.moleculesService.GetParentWithChildren(moleculesIds)
    .subscribe((molecules: any[]) => {
      molecules.forEach(molecule => {
        this.builderService.BuildMolecule(molecule);
        this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(molecule.id);
        
        setTimeout(() => {
          const moleculeElement = document.querySelector(`#gridsterItem-${ molecule.id }`);
          if (moleculeElement) {
            moleculeElement.classList.add('collaborator-pulse');
            setTimeout(() => {
              moleculeElement.classList.remove('collaborator-pulse');
            }, 2000);
          }
        }, 500);
      });
      
      setTimeout(() => {
        this.snackerService.ShowMessageOnBottom('Editor resetted!');
        this.workAreaService.UnblockEditor();
      }, 300);
    });
  }
  
  SetGridOptions(deviceType = 'desktop') {
    this.options.maxCols = this.options.minCols = this.options.minRows = this.options.maxRows = 1000;
    if (this.options.api) {
      this.options.api.optionsChanged();
    }
    this.ref.markForCheck();
  }
  
  UpdateCobbleBackground() {
    // console.log('update bg');
    
    this.ImgSource = 'url(' + this.cobbleService.Cobble.properties.imageUrl + ')';
    
    if (this.cobbleService.Cobble.properties.backgroundTypeImage) {
      if (this.cobbleService.Cobble.properties.imageUrl.indexOf(
        'http') > -1 || this.cobbleService.Cobble.properties.imageUrl.indexOf('https') > -1) {
        this.ImgSource = 'url(' + this.cobbleService.Cobble.properties.imageUrl + ')';
        this.communicationService.Event.System.App.$AppChanged.emit(true);
      } else {
        try {
          this.fileService.getFileInformation(this.cobbleService.Cobble.properties.imageUrl)
          .subscribe(
            result => {
              if (result && result.contentType.indexOf('image') > -1) {
                this.ImgSource = 'url(' + 'data:image/png;base64,' + result.dataFile + ')';
              }
              this.communicationService.Event.System.App.$AppChanged.emit(true);
            },
            error => {
            },
          );
        } catch (error) {
          this.ImgSource = '/assets/images/no_image.png';
        }
      }
    }
  }
  
  PropertyChecked(event: any) {
    console.log(event);
  }
  
  onContextMenu(event: MouseEvent) {
    console.log(event);
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    this.contextMenu.closeMenu();
    this.addRepresentativeMoleculeMenu.closeMenu();
    
    event.stopPropagation();
    event.preventDefault();
    
    this.workAreaService.contextMenuPosition.event = event;
    this.workAreaService.contextMenuPosition.x = event.clientX + 'px';
    this.workAreaService.contextMenuPosition.y = event.clientY + 'px';
    
    if ((event.target as any).classList.contains('work-area-gridster')) {
      this.workAreaService.DeselectAllRepresentativeMolecules();
      this.contextMenu.menuData = {
        element: this.cobbleService.Cobble,
        add: false,
        isRepresentativeMolecule: false,
      };
      this.contextMenu.openMenu();
      return;
    }
    // console.log('contextmenu');
    
    this.workAreaService.elementClicked = (event.target as any).classList.contains('workgroup-gridster')
      ? this.busService.Get((event.target as any).dataset.repmolid)
      : (event.target as any).dataset.repmolid && (event.target as any).dataset.repmolid !== ''
        ? this.busService.Get((event.target as any).dataset.repmolid)
        : this.workAreaService.elementClicked;
    const element = this.workAreaService.elementClicked;
    
    if (element) {
      if (element.Type === RepresentativeMoleculesType.WorkGroup && event.metaKey) {
        this.altMenuPosition.x = event.clientX;
        this.altMenuPosition.y = event.clientY;
        this.addRepresentativeMoleculeMenu.openMenu();
      } else {
        const add = event.ctrlKey || event.metaKey;
        this.contextMenu.menuData = { element: element, add, isRepresentativeMolecule: true };
        this.contextMenu.openMenu();
      }
    }
    
    return false;
  }
  
  ToggleLockViewDisplay() {
    this.workAreaService.editorPreferences.displayLockViewElements = !this.workAreaService.editorPreferences.displayLockViewElements;
    this.communicationService.Event.Editor.Preferences.$PreferenceChange.emit('displayLockViewElements');
    this.communicationService.Event.Editor.$SaveEditorState.emit();
    this.snackerService.ShowMessageOnBottom('Lock View visibility changed', 'layers_clear', null, true);
  }
  
  SelectAllElements(repMoleculeClicked: IRepresentativeMolecule, add: boolean, onlySameType = false) {
    // console.log(add);
    const childrenSametype = onlySameType
      ? this.busService
      .DirectChildrenElements(repMoleculeClicked.ParentId)
      .filter(c => c.Id !== repMoleculeClicked.Id && c.Type === repMoleculeClicked.Type)
      : this.busService.DirectChildrenElements(repMoleculeClicked.ParentId)
      .filter(c => c.Id !== repMoleculeClicked.Id);
    
    let children = childrenSametype;
    
    if (add) {
      children = childrenSametype.concat(this.workAreaService.elementsSelected);
    } else {
      children.unshift(repMoleculeClicked);
    }
    ``;
    const alreadySelected = !!this.workAreaService.elementsSelected.find(
      es => es.Id === repMoleculeClicked.Id);
    
    if (this.workAreaService.elementsSelected.length === 0) {
      this.workAreaService.ShowElementFocusedMenu(repMoleculeClicked);
      children = children.filter(c => c.Id !== repMoleculeClicked.Id);
    } else {
      if (!add) {
        document.querySelectorAll(`.element-selected`)
        .forEach(selectedElement => {
          selectedElement.classList.remove('element-selected');
        });
        this.workAreaService.DeselectAllRepresentativeMolecules(true);
      }
      
      if (!alreadySelected) {
        this.workAreaService.SelectRepresentativeMolecule(repMoleculeClicked, add);
        children = children.filter(c => c.Id !== repMoleculeClicked.Id);
      }
    }
    
    children.forEach(c => {
      if (c.Type === repMoleculeClicked.Type || !onlySameType) {
        // console.log('add to selected');
        this.workAreaService.SelectRepresentativeMolecule(c, true, true);
        this.workAreaService.dragStartPositions[c.Id] = {
          x: c.ResponsiveProperties().x,
          y: c.ResponsiveProperties().y,
          rows: c.ResponsiveProperties().rows,
          cols: c.ResponsiveProperties().cols,
        };
      }
    });
    
    this.workAreaService.dragStartPositions[repMoleculeClicked.Id] = {
      x: repMoleculeClicked.ResponsiveProperties().x,
      y: repMoleculeClicked.ResponsiveProperties().y,
      rows: repMoleculeClicked.ResponsiveProperties().rows,
      cols: repMoleculeClicked.ResponsiveProperties().cols,
    };
    
    this.workAreaService.AdjustElementFocusedMenuPosition(repMoleculeClicked);
    this.workAreaService.showElementFocusedMenu = true;
  }
  
  ToggleTabOrder(repMoleculeClicked: IRepresentativeMolecule, add: boolean, event: MouseEvent) {
    if (this.workAreaService.elementsSelected.length === 0) {
      repMoleculeClicked.ToggleTabOrderDisplay();
    } else {
      if (repMoleculeClicked.DisplayTabOrder) {
        this.workAreaService.elementsSelected.forEach(es => es.HideTabOrderDisplay());
      } else {
        this.workAreaService.elementsSelected.forEach(es => es.ShowTabOrderDisplay());
      }
    }
  }
  
  SetTabOrder(repMoleculeClicked: IRepresentativeMolecule, reset: boolean = false) {
    this.workAreaService.StartSettingTabOrder(repMoleculeClicked, reset);
  }
  
  ReorganizeByTab(repMoleculeClicked: IRepresentativeMolecule, add: boolean, event: MouseEvent) {
    const parentIds = [...new Set(this.workAreaService.elementsSelected.map(element => element.ParentId))];
    
    if (parentIds.length > 1) {
      this.snackerService.ShowMessageOnBottom(`Can't reorganize from multiple sources`, 'save_as');
      return;
    } else {
      const elementsToOrganize = this.workAreaService.elementsSelected.filter(
        es => es.Properties.tabindex > 0);
      
      let orderedPositions = cloneDeep(elementsToOrganize.map(e => e.ResponsiveProperties()));
      
      orderedPositions.forEach(op => {
        // console.log(op.id, Math.hypot(op.x, op.y));
        op.hyp = Math.hypot(op.x, op.y);
      });
      
      // orderedPositions = orderedPositions.sort((a, b) => {
      //   if (Math.hypot(a.x, a.y) < Math.hypot(b.x + b.y)) {
      //     return 1;
      //   } else {
      //     return -1;
      //   }
      // });
      // orderedPositions = orderedPositions.sort((a, b) => {
      //   return Math.hypot(a.x, a.y) - Math.hypot(b.x + b.y);
      // });
      
      orderedPositions = orderedPositions.sort((a, b) => {
        return a.hyp - b.hyp;
      });
      
      // orderedPositions = orderedPositions.sort((a, b) => {
      //   if (a.x > b.x) {
      //     return 1;
      //   } else if (a.x < b.x) {
      //     return -1;
      //   }
      //
      //   // Else go to the 2nd item
      //   if (a.y < b.y) {
      //     return -1;
      //   } else if (a.y > b.y) {
      //     return 1;
      //   } else { // nothing to split them
      //     return 0;
      //   }
      // });
      
      // console.log(orderedPositions);
      
      const tabPositions = {};
      
      elementsToOrganize.forEach((es, index) => {
        tabPositions[es.Properties.tabindex] = Object.assign({}, es.ResponsiveProperties());
      });
      // console.log(tabPositions);
      
      const propertiesToSave = [];
      
      Object.keys(tabPositions)
      .forEach((tabIndex, index) => {
        const repMolPosition = tabPositions[tabIndex];
        const repMolecule = this.busService.Get(repMolPosition.id);
        
        repMolecule.Properties.responsive[this.cobbleService.Cobble.deviceType].x = orderedPositions[index].x;
        repMolecule.Properties.responsive[this.cobbleService.Cobble.deviceType].y = orderedPositions[index].y;
        
        propertiesToSave.push(
          new PropertyVersioningDto({
            elementId: repMolecule.Id.toString(),
            property: 'x',
            value: orderedPositions[index].x,
            path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
            change: `Position Changed`,
            name: 'X',
          }),
        );
        
        propertiesToSave.push(
          new PropertyVersioningDto({
            elementId: repMolecule.Id.toString(),
            property: 'y',
            value: orderedPositions[index].y,
            path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
            change: `Position Changed`,
            name: 'Y',
          }),
        );
      });
      
      this.propertiesService.SaveProperties(propertiesToSave)
      .subscribe();
      this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(repMoleculeClicked.ParentId);
    }
  }
  
  ngAfterContentInit() {
    // this.zoomPanService.EasyScroller(
    //   document.getElementsByClassName('work-area-wrapper')[0]
    // );
  }
  
  openUploadDialog() {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    this.genericDialogService.openDialog(
      {
        type: DatasourceType.Spreadsheet,
      },
      DatasourceDialogComponent,
    );
  }
  
  openNewWorkbook() {
    this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoading.emit(true);
    this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoaded.emit(false);
    this.spreadsheetService.clearFlexSheet();
    this.spreadsheetService.wName = 'Empty Sheet';
    this.spreadsheetService.flexSheet.addUnboundSheet('Empty Sheet', 200, 50);
    this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoading.emit(false);
    this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoaded.emit(true);
  }
  
  closeWorkbook() {
    this.spreadsheetService.sName = undefined;
    this.spreadsheetService.wName = undefined;
    this.dataSourcesService.openedDataSourceId = -1;
    this.dataSourcesService.openedDataSourceSheetIndex = -2;
    this.spreadsheetService.clearFlexSheet();
    this.spreadsheetService.clearSheetChanges();
    this.CloseSpreadSheetPanel();
  }
  
  ribbonClicked(event: any) {
    // console.log(event);
    
    const methodName = event.methodName;
    const params = event.params;
    
    if (this.spreadsheet[methodName]) {
      if (params) {
        if (params[1]) {
          this.spreadsheet[methodName](params[0], params[1]);
        } else {
          this.spreadsheet[methodName](params[0]);
        }
      } else {
        this.spreadsheet[methodName]();
      }
    }
  }
  
  DownloadSpreadsheet() {
    if (this.spreadsheet && this.spreadsheet['download']) {
      this.spreadsheet['download'](null);
    }
  }
  
  splitDragEnd(event: any) {
    this.workAreaService.canvasAreaSize = event.sizes[0];
    this.workAreaService.sheetAreaSize = event.sizes[1];
    this.workAreaService.sheetPanelDragged();
  }
  
  onDrop(event: any, position: GridsterItem) {
    console.log(event, this.dragService.dragData);
    
    const notAllowedDatasources = ['System', 'LeapXL'];
    
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    if (this.dragService.dragData === undefined) {
      return;
    }
    if (
      this.dragService.dragData &&
      this.dragService.dragData.moleculeType &&
      this.dragService.dragData.moleculeType === MoleculesType.Cobblet &&
      this.cobbleService.Cobble.id === this.dragService.dragData.id
    ) {
      this.snackerService.ShowMessageOnBottom('Component not allowed', 'block');
      return;
    }
    
    if (this.dragService.dragData.dragType === DragType.Spreadsheet || this.dragService.dragData.dragType === DragType.DataElement) {
      if (
        Array.isArray(this.dragService.dragData.data) &&
        this.dragService.dragData.data.length > 0 &&
        notAllowedDatasources.includes(this.dragService.dragData.data[0].DataSourceType)
      ) {
        if (this.dragService.dragData.data[0].DataSourceType === 'System') {
          this.snackerService.ShowMessageOnBottom(
            `${ this.dragService.dragData.data[0].InternalName } provides data from the computer or device on which the app is being used and is not an external datasource. Please use with GUI controls and processes.`,
            null,
            9000,
          );
        }
        
        if (this.dragService.dragData.data[0].DataSourceType === 'LeapXL') {
          this.snackerService.ShowMessageOnBottom(
            `${ this.dragService.dragData.data[0].InternalName } provides values directly from the running app and is not an external datasource. Please use with GUI controls and processes.`,
            null,
            8000,
          );
        }
        
        return;
      }
      
      if (this.dragService.dragData.dragType === DragType.Spreadsheet && this.spreadsheetService.SpreadSheetContainsUnsavedChanges()) {
        this.snackerService.ShowMessageOnBottom(
          'Please save or revert the changes on the spreadsheet before create an element', 'save_as', 5000);
        return;
      }
      
      let dataSource = this.spreadsheetService.SpreadSheetRangeSelected;
      this.bottomSheetService.Options = [];
      const repMoleculesIcons = {
        Chart: 'insert_chart',
        Table: 'view_comfy',
        'Add Form': 'playlist_add',
        'Update Form': 'playlist_add_check',
      };
      const repMoleculesAvailable: string[] = [RepresentativeMoleculesType.Table];
      
      repMoleculesAvailable.push(RepresentativeMoleculesType.Chart);
      if (
        (this.dragService.dragData.dragType === DragType.Spreadsheet && (dataSource.cols === 1 || dataSource.rows === 1)) ||
        this.dragService.dragData.dragType === DragType.DataElement
      ) {
        repMoleculesAvailable.push('Add Form');
        repMoleculesAvailable.push('Update Form');
      }
      
      repMoleculesAvailable.forEach(molecule => {
        this.bottomSheetService.Options.push({
          id: molecule.indexOf(
            'Form') > -1 ? molecule : this.workAreaService.libraryMoleculeIds[`${ molecule }-Representative`],
          name: `${ molecule }`,
          description: `Create ${ molecule } with this datasource`,
          icon: [repMoleculesIcons[molecule]],
        });
      });
      
      let dataElements = [];
      
      if (this.dragService.dragData.dragType === DragType.DataElement) {
        dataElements = this.dragService.dragData.data as DataElement[];
        dataSource = {
          dataSourceType: dataElements[0].DataSourceType,
        } as any;
      }
      
      this.bottomSheetSubscription = this.communicationService.Event.Editor.$BottomSheetOptionSelected.subscribe(
        (option: BottomSheetOption) => {
          console.log(dataSource, dataElements);
          
          switch (option.name) {
            case 'Add Form':
            case 'Update Form':
              const hide = this.clientStorageService.getHideRearrangeDatasource();
              if (this.clientStorageService.getHideRearrangeDatasource() === 'Y') {
                this.CreateForm(dataSource, position, 'form_' + option.name.split(' ')[0].toLowerCase(),
                  dataElements);
              } else {
                this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
                this.genericDialogService
                .openDialog(
                  {
                    dataSource,
                    dataElements,
                  },
                  DatasourceRearrangeComponent,
                )
                .then(result => {
                  this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
                  if (result) {
                    this.CreateForm(
                      result.dataSource,
                      position,
                      'form_' + option.name.split(' ')[0].toLowerCase(),
                      result.dataElements,
                      result.formStyle,
                      result.padding,
                      result.separation,
                      result.formLines,
                    );
                  }
                });
              }
              break;
            case 'Chart':
            case 'Table':
              this.CreateTableChart(position, option.name, dataElements);
              break;
            default:
              break;
          }
          
          this.bottomSheetSubscription.unsubscribe();
        });
      
      const bs = this.bottomSheet.open(BottomSheetOptionsComponent);
      bs.afterDismissed()
      .subscribe(result => {
        this.bottomSheetService.Options = [];
        this.bottomSheetSubscription.unsubscribe();
      });
    } else if (this.dragService.dragData.dragType === DragType.Theme) {
      this.communicationService.Event.Editor.$SetAppTheme.emit({ theme: this.dragService.dragData.theme });
      this.workAreaService.SaveLastUsedElement('style', this.dragService.dragData.dragType,
        this.dragService.dragData.theme.name);
    } else {
      // console.log('event dropped', event);
      
      const deviceConfiguration = this.cobbleService.Cobble.deviceConfigurations[this.cobbleService.Cobble.deviceType];
      
      if (this.cobbleService.Cobble.deviceType === 'smartphone') {
        position.x = 0;
      } else if (this.cobbleService.Cobble.deviceType === 'tablet') {
        if (position.x > deviceConfiguration.minItemCols && position.x < deviceConfiguration.maxItemCols) {
          position.x = 0;
        } else if (position.x > deviceConfiguration.maxItemCols) {
          position.x = deviceConfiguration.maxItemCols + 1;
        }
      }
      
      console.log(this.dragService.dragData);
      if (
        this.dragService.dragData.moleculeType === MoleculesType.Representative ||
        this.dragService.dragData.moleculeType === MoleculesType.Cobblet
      ) {
        const repMoleculeTemplate =
          this.dragService.dragData.type === RepresentativeMoleculesType.Stepper ||
          this.dragService.dragData.type === RepresentativeMoleculesType.WorkGroup ||
          this.dragService.dragData.moleculeType === MoleculesType.Cobblet
            ? this.dragService.dragData
            : this.templateService.GetMoleculeTemplateByMoleculeType(RepresentativeMoleculesType.WorkGroup);
        
        this.builderService
        .GetMolecule(new RepresentativeMolecule(this.cobbleService.Cobble), repMoleculeTemplate, position, [],
          [], 0,
          false, false)
        .then((newElement: IRepresentativeMolecule) => {
          // if element dropped on workarea is not wg create wg and then rep molecule
          if (
            this.dragService.dragData.type !== RepresentativeMoleculesType.WorkGroup &&
            this.dragService.dragData.type !== MoleculesType.Cobblet &&
            this.dragService.dragData.type !== RepresentativeMoleculesType.Stepper
          ) {
            this.builderService
            .GetMolecule(newElement, this.dragService.dragData, { x: 2, y: 2 } as any, [], [], 0, false,
              false)
            .then((repMolecule: IRepresentativeMolecule) => {
              this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(newElement);
              setTimeout(() => {
                this.workAreaService.ShowElementFocusedMenu(repMolecule, event, true);
              }, 400);
            });
          } else {
            this.eventsService.CreateEventsTable();
            
            setTimeout(() => {
              this.workAreaService.ShowElementFocusedMenu(newElement, event, true);
            }, 500);
            
            if (this.dragService.dragData.type === MoleculesType.Cobblet) {
              this.communicationService.Event.Editor.EventsTree.$RefreshCustomEvents.emit();
            }
          }
        });
      }
    }
    this.dragService.dragData.dragType = '';
  }
  
  CreateForm(
    dataSource: DataSource,
    position: GridsterItem,
    context: string,
    dataElements: DataElement[],
    formStyle = 'vertical',
    padding = 1,
    separation = 3,
    formLines = 10,
  ) {
    // console.log(dataElements);
    
    if (dataElements && dataElements.length) {
      dataElements.forEach(de => this.dataTranslatorService.ChangeTranslationNames(de));
    }
    
    // console.log(dataElements);
    
    // region get indexes
    const firstIndex = dataSource.dataSourceType === DatasourceType.Spreadsheet ? (dataSource.rows === 1 ? 0 : dataSource.firstRowIndex) : 0;
    
    const lastIndex =
      dataSource.dataSourceType === DatasourceType.Spreadsheet
        ? dataSource.rows === 1
          ? dataSource.dataItems.length - 1
          : dataSource.lastRowIndex
        : dataElements.length - 1;
    const iterable = dataSource.dataSourceType === DatasourceType.Spreadsheet ? (dataSource.rows === 1 ? 'col' : 'row') : 'col';
    const elementsCount = dataSource.dataSourceType === DatasourceType.Spreadsheet ? dataSource.dataItems.length : dataElements.length;
    const collection = dataSource.dataSourceType === DatasourceType.Spreadsheet ? dataSource.collection : dataElements[0].Collection;
    // endregion
    
    let textboxCols = 38;
    let textboxRows = 12;
    let labelCols = 38;
    let labelRows = 12;
    let buttonCols = 38;
    let buttonRows = 12;
    let yInit = 15;
    
    switch (formStyle) {
      case 'vertical':
        labelRows = 7;
        textboxCols = 50;
        break;
      case 'horizontal':
        break;
    }
    
    const labelStyle = this.cobbleService.Cobble.appTheme.representativeMoleculeStyles
      ? this.cobbleService.Cobble.appTheme.representativeMoleculeStyles[RepresentativeMoleculesType.Label]
      : null;
    const textboxStyle = this.cobbleService.Cobble.appTheme.representativeMoleculeStyles
      ? this.cobbleService.Cobble.appTheme.representativeMoleculeStyles[RepresentativeMoleculesType.Textbox]
      : null;
    const buttonStyle = this.cobbleService.Cobble.appTheme.representativeMoleculeStyles
      ? this.cobbleService.Cobble.appTheme.representativeMoleculeStyles[RepresentativeMoleculesType.Button]
      : null;
    
    if (labelStyle) {
      const labelStyleData = this.thematicService.GetStyle(labelStyle.styleId);
      if (labelStyleData && (labelStyleData.section === 'all' || labelStyleData.section === 'dimension')) {
        labelCols = labelStyleData.properties.responsive[this.cobbleService.Cobble.deviceType].cols;
        labelRows = labelStyleData.properties.responsive[this.cobbleService.Cobble.deviceType].rows;
      }
    }
    if (textboxStyle) {
      const textboxStyleData = this.thematicService.GetStyle(textboxStyle.styleId);
      if (textboxStyleData && (textboxStyleData.section === 'all' || textboxStyleData.section === 'dimension')) {
        textboxCols = textboxStyleData.properties.responsive[this.cobbleService.Cobble.deviceType].cols;
        textboxRows = textboxStyleData.properties.responsive[this.cobbleService.Cobble.deviceType].rows;
      }
    }
    if (buttonStyle) {
      const buttonStyleData = this.thematicService.GetStyle(buttonStyle.styleId);
      if (buttonStyleData && (buttonStyleData.section === 'all' || buttonStyleData.section === 'dimension')) {
        buttonCols = buttonStyleData.properties.responsive[this.cobbleService.Cobble.deviceType].cols;
        buttonRows = buttonStyleData.properties.responsive[this.cobbleService.Cobble.deviceType].rows;
      }
    }
    
    let wgLimitRows = textboxRows * formLines;
    
    // region wg row/cols
    let y = padding + yInit;
    let x = padding;
    
    let wgRows = 0;
    let wgCols = 0;
    
    switch (formStyle) {
      case 'vertical':
        const rows = Math.ceil(elementsCount / formLines);
        
        wgLimitRows = (textboxRows + separation) * rows + labelRows * rows;
        wgRows =
          elementsCount * (textboxRows + separation + labelRows) > wgLimitRows
            ? wgLimitRows + padding * 2
            : elementsCount * (textboxRows + separation + labelRows) + 1 + buttonRows + padding * 2;
        
        wgCols =
          elementsCount * (textboxRows + separation + labelRows) > wgLimitRows
            ? Math.ceil(
            (elementsCount * (textboxRows + separation + labelRows)) / wgLimitRows) * (textboxCols + 2) + padding * 2
            : Math.ceil(textboxCols) + padding * 2;
        break;
      case 'horizontal':
        wgLimitRows = (textboxRows + separation) * formLines;
        
        wgRows =
          elementsCount * (textboxRows + separation) > wgLimitRows
            ? wgLimitRows + padding * 2
            : elementsCount * (textboxRows + separation) + 1 + buttonRows + padding * 2;
        
        wgCols =
          elementsCount * (textboxRows + separation) > wgLimitRows
            ? Math.ceil(
            (elementsCount * (textboxRows + separation)) / wgLimitRows) * (textboxCols + 1) * 2 + padding * 2
            : Math.ceil(textboxCols + labelCols) + padding * 2;
        break;
    }
    // endregion
    
    wgRows = wgRows + yInit + 5;
    // region wg properties
    const wgElement: MoleculeRequest = {
      cobbleId: this.cobbleService.Cobble.id,
      parentId: this.cobbleService.Cobble.id,
      moleculeTemplateId: this.workAreaService.libraryMoleculeIds[`${ RepresentativeMoleculesType.WorkGroup }-Representative`],
      properties: [
        {
          name: 'contexts',
          value: [context],
          path: ``,
        },
        {
          name: 'x',
          value: position.x,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
        },
        {
          name: 'y',
          value: position.y,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
        },
        {
          name: 'cols',
          value: wgCols,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
        },
        {
          name: 'rows',
          value: wgRows,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
        },
        {
          name: 'colsQty',
          value: wgCols,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
        },
        {
          name: 'rowsQty',
          value: wgRows,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
        },
        {
          name: 'view',
          value: this.workAreaService.ActualView.id,
          path: 'properties',
        },
        {
          name: 'name',
          value: `${ collection } Form WorkGroup`,
          path: 'properties',
        },
        {
          name: 'borderRadius',
          value: 4,
          path: 'properties.bordersValues',
        },
        {
          name: 'hoverBorderRadius',
          value: 3,
          path: 'properties.hover',
        },
        {
          name: 'borderWidth',
          value: 1,
          path: 'properties.bordersValues',
        },
        {
          name: 'borderColor',
          value: '#e3e3e3',
          path: 'properties.bordersValues',
        },
        {
          name: 'borderStyle',
          value: 'solid',
          path: 'properties.bordersValues',
        },
        {
          name: 'backgroundColor',
          value: '#fafafa',
          path: 'properties.background',
        },
        {
          name: 'maxItemCols',
          value: this.cobbleService.Cobble.deviceConfigurations[this.cobbleService.Cobble.deviceType].maxItemCols,
          path: 'properties',
        },
      ],
      children: [],
    };
    // endregion
    
    // region wg placeholder
    const wgPlaceholder = workgroupTemplate[0];
    this.busService.Remove((9999999999).toString());
    (wgPlaceholder as any).id = 9999999999;
    (wgPlaceholder as any).parentId = this.cobbleService.Cobble.id;
    (wgPlaceholder as any).loading = true;
    (wgPlaceholder as any).properties.responsive[this.cobbleService.Cobble.deviceType].x = position.x;
    (wgPlaceholder as any).properties.responsive[this.cobbleService.Cobble.deviceType].y = position.y;
    (wgPlaceholder as any).properties.maxItemCols = 100;
    (wgPlaceholder as any).properties.responsive[this.cobbleService.Cobble.deviceType].cols = wgCols;
    (wgPlaceholder as any).properties.responsive[this.cobbleService.Cobble.deviceType].rows = wgRows;
    (wgPlaceholder as any).properties.view = this.workAreaService.ActualView.id;
    (wgPlaceholder as any).optionsMenu = {
      configuration: false,
      molecules: false,
      compound: false,
      cobblet: false,
      remove: false,
      versioning: false,
      dataSource: false,
    };
    // endregion
    
    this.builderService.CreateAndAddRepresentativeMoleculeToBus(wgPlaceholder, 0);
    this.refreshChildren();
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    this.ref.markForCheck();
    
    let toCreate: MoleculeRequest[] = [];
    const translationSubscriptions = [];
    const textboxes = [];
    console.log('iterable', iterable);
    
    // region label/textboxes creation
    for (let i = firstIndex; i <= lastIndex; i++) {
      const textValue = this.toolsService.ClearText(
        dataSource.dataSourceType === DatasourceType.Spreadsheet ? dataSource.dataItems[i].value : dataElements[i].InternalName,
      );
      // const textValue = this.toolsService.ClearText(
      //   dataSource.dataSourceType === DatasourceType.Spreadsheet
      //     ? dataSource.dataItems.find((di) => di[iterable] === i).value
      //     : dataElements[i].InternalName
      // );
      
      // region label request creation
      toCreate.push(
        this.CreateFormMoleculeRequest(
          textValue,
          x,
          y,
          labelCols,
          labelRows,
          RepresentativeMoleculesType.Label,
          [],
          [
            {
              name: 'fontSize',
              value: 13,
              path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }.font`,
            },
            {
              name: 'fontColor',
              value: '#747272',
              path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }.font`,
            },
            {
              name: 'fontWeight',
              value: 'bold',
              path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }.font`,
            },
          ],
        ),
      );
      // endregion
      
      let xTextboxPosition = x;
      let yTextboxPosition = y;
      
      switch (formStyle) {
        case 'vertical':
          yTextboxPosition = y + labelRows;
          break;
        case 'horizontal':
          xTextboxPosition = x + labelCols + 2;
          break;
      }
      
      // region textbox request creation
      textboxes.push(
        this.CreateFormMoleculeRequest(
          textValue,
          xTextboxPosition,
          yTextboxPosition,
          textboxCols,
          textboxRows,
          RepresentativeMoleculesType.Textbox,
          [],
          [
            {
              name: 'orientation',
              value: iterable,
              path: `properties`,
            },
          ],
        ),
      );
      // endregion
      
      // region handle datasource
      const translation = {
        dataSourceId: null,
        applicationId: this.cobbleService.Cobble.id,
        dataSourceType: dataSource.dataSourceType,
        specialMode: this.spreadsheetService.editorDbMode,
        context: '',
        reference: '',
      };
      
      if (dataSource.dataSourceType === DatasourceType.Spreadsheet) {
        const firstColIndex = iterable === 'col' ? dataSource.dataItems[i].col : this.spreadsheetService.SpreadSheetRangeSelected.firstColIndex;
        const firstRowIndex = iterable === 'row' ? dataSource.dataItems[i].row : this.spreadsheetService.SpreadSheetRangeSelected.firstRowIndex;
        
        translation.dataSourceId = dataSource.dataSourceId;
        translation.context = `${ DatasourceType.Spreadsheet }${ Constants.ContextSeparator }${
          this.spreadsheetService.SpreadSheetRangeSelected.dataSourceName
        }${ Constants.ContextSeparator }${ this.spreadsheetService.SpreadSheetRangeSelected.collection }${ Constants.ContextSeparator }${
          iterable === 'col' ? this.toolsService.ColumnIndexToName(firstColIndex + 1) : firstRowIndex + 1
        }`;
        translation.reference = `${ this.toolsService.ColumnIndexToName(
          firstColIndex + 1) }${ firstRowIndex + 1 }`;
      } else {
        translation.context = dataElements[i].Context;
        translation.reference = dataElements[i].Reference;
      }
      translationSubscriptions.push(this.dataTranslatorService.CreateTranslation([translation]));
      
      // endregion
      
      console.log(i, lastIndex);
      switch (formStyle) {
        case 'vertical':
          if (y - padding + (i === lastIndex ? buttonRows : textboxRows + labelRows + separation) >= wgRows - yInit - padding * 2) {
            y = padding + yInit;
            x = x + textboxCols + 1 + 2;
          } else {
            y = y + (textboxRows + labelRows) + separation;
          }
          break;
        case 'horizontal':
          if (y - padding + (textboxRows + separation) + 1 > wgRows - padding * 2) {
            y = padding + yInit;
            x = x + textboxCols + labelCols + 1 + 2;
          } else {
            y = y + (textboxRows + separation);
          }
          break;
      }
    }
    // endregion
    
    forkJoin(translationSubscriptions)
    .subscribe((translations: any[]) => {
      const buttonRequest = this.CreateFormMoleculeRequest(
        context === 'form_add' ? 'Submit' : 'Update',
        wgCols - (buttonCols + padding),
        y + 1,
        buttonCols,
        buttonRows - 1,
        RepresentativeMoleculesType.Button,
        [],
        [
          {
            name: 'borderRadius',
            value: 3,
            path: `properties.bordersValues`,
          },
        ],
      );
      
      this.moleculesService.GetMolecules([buttonRequest])
      .subscribe((buttonResponse: any) => {
        const buttonMolecule = buttonResponse.molecules[0];
        
        let customEventId = '';
        let customEvent = null;
        
        if (dataElements && dataElements.length > 0) {
          customEventId = this.toolsService.GenerateIdFromContext(dataElements[0].Context);
          customEvent = this.eventsService.CustomEvents.find(ce => ce.EventName === customEventId);
          
          if (customEvent) {
            // exists
          } else {
            customEvent = this.factoryParticleService.GenerateCustomEvent(customEventId);
            this.eventsService.CustomEvents.push(customEvent);
          }
        } else if (dataSource && dataSource.dataItems.length > 0) {
          customEvent = this.factoryParticleService.GenerateCustomEvent(
            this.toolsService.GenerateIdFromContext(dataSource.context) + '-' + context);
        }
        
        textboxes.forEach((textbox, index) => {
          const dataElement = new DataElement({
            translationId: translations[index][0].id,
            id: this.toolsService.GenerateGuid(),
            particleId: this.toolsService.GenerateGuid(),
            particleType: ParticleType.DataElement,
            context: translations[index][0].context,
            dataSourceType: dataSource.dataSourceType,
            dataSourceId: dataSource.dataSourceId,
            dataSourceName: dataSource.dataSourceName,
            collection: dataSource.collection,
            internalName: translations[index][0].internalName,
            reference: translations[index][0].internalName,
            applicationId: this.cobbleService.Cobble.id,
          });
          
          // const required = this.spreadsheetService.dataSourceTypeOpened === DatasourceType.Spreadsheet ? false : this.dataSourcesService.GetDataSourceNodeFromTree(dataElement.Context).required;
          const required = false;
          
          this.templateService
          .GetActionMoleculeProperties([
            'AddToDatasourceMolecule',
            'FilterByDataElementReferenceMolecule',
            'GetElementsDatasourceDataMolecule',
            'UpdateDatasourceDataMolecule',
            'ClearValueMolecule',
          ])
          .subscribe(properties => {
            let buses = [];
            
            // region contextual defaults
            if (context === 'form_add') {
              const busToAdd = this.factoryService.SetRepresentativeMoleculeBusDefaults(
                [textbox],
                buttonMolecule,
                ['form_add'],
                properties,
                false,
                [dataElement],
                null,
                customEvent,
              );
              buses.push(busToAdd[0]);
            } else {
              const busesToAdd = this.factoryService.SetRepresentativeMoleculeBusDefaults(
                [textbox],
                buttonMolecule,
                ['form_update'],
                properties,
                false,
                [dataElement],
              );
              buses = buses.concat(busesToAdd);
            }
            // endregion
            
            // region button bus
            const notificationMolProperty = this.templateService.GetActionMoleculePropertie(
              'NotificationMolecule');
            
            const buttonBus = new Bus({
              id: this.toolsService.GenerateGuid(),
              Name: `Button ${ buttonMolecule.properties.textToDisplay }`,
              Receptor: Receptor.ValueOutput,
              Particles: [],
            });
            
            buttonBus.AddParticle(
              this.factoryParticleService.GenerateEvent(LeapXLEventType.Click, 'Molecule',
                buttonMolecule.id));
            buttonBus.AddParticle(
              this.factoryParticleService.GenerateActionMolecule(notificationMolProperty, 0, [], {
                message: 'Processing Data...',
              }),
            );
            
            buttonMolecule.buses = [buttonBus];
            // endregion
            
            textbox.properties.push(
              {
                name: 'buses',
                path: '',
                value: buses,
              },
              {
                name: 'required',
                path: '',
                value: required,
              },
            );
          });
        });
        
        toCreate = toCreate.concat(textboxes);
        toCreate.push(
          this.CreateFormMoleculeRequest(
            `${ collection } Form`,
            10,
            4,
            wgCols - 20,
            8,
            RepresentativeMoleculesType.H3,
            [],
            [
              {
                name: 'name',
                path: 'properties',
                value: 'Table Title',
              },
              {
                name: 'view',
                value: this.workAreaService.ActualView.id,
                path: 'properties',
              },
              {
                name: 'fontSize',
                value: 20,
                path: 'properties.responsive.desktop.font',
              },
              {
                name: 'fontColor',
                value: '#636363',
                path: 'properties.responsive.desktop.font',
              },
              {
                name: 'fontFamily',
                value: 'Titillium Web',
                path: 'properties.responsive.desktop.font',
              },
              {
                name: 'fontWeight',
                value: 'bold',
                path: 'properties.responsive.desktop.font',
              },
              {
                name: 'textToDisplay',
                value: `${ collection } Form`,
                path: 'properties',
              },
            ],
          ),
          this.CreateFormMoleculeRequest(
            '',
            0,
            15,
            wgCols,
            2,
            RepresentativeMoleculesType.H5,
            [],
            [
              {
                name: 'name',
                path: 'properties',
                value: 'Separator',
              },
              {
                name: 'view',
                value: this.workAreaService.ActualView.id,
                path: 'properties',
              },
              {
                name: 'textToDisplay',
                value: '',
                path: 'properties',
              },
              {
                name: 'backgroundColor',
                value: '#ebebeb',
                path: 'properties.background',
              },
            ],
          ),
        );
        wgElement.children = toCreate;
        
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
        this.moleculesService.GetFormMolecules([wgElement])
        .subscribe((response: any) => {
          // response.molecules.forEach(molecule => {
          //   this.builderService.CreateAndAddRepresentativeMoleculeToBus(molecule, 0);
          //   this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
          //   this.refreshChildren();
          //   this.ref.markForCheck();
          // });
          
          this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
          const changestoSave = [];
          
          this.busService.Remove((9999999999).toString());
          
          // region assign event ids
          const workgroupChildrenIds = [];
          response.molecules.forEach(molecule => {
            if (molecule.type === RepresentativeMoleculesType.Textbox) {
              workgroupChildrenIds.push({
                id: molecule.id,
              });
              
              molecule.buses.forEach(bus => {
                bus.Particles.forEach(p => {
                  if (p.ParticleType === ParticleType.Molecule) {
                    p.MoleculeId = molecule.id;
                    changestoSave.push(
                      new PropertyVersioningDto({
                        elementId: molecule.id,
                        property: 'buses',
                        value: molecule.buses,
                        path: ``,
                        change: 'Assigning ids',
                        name: '',
                      }),
                    );
                  }
                });
              });
            } else if (molecule.type === RepresentativeMoleculesType.WorkGroup) {
              workgroupChildrenIds.push({
                id: buttonMolecule.id,
              });
              
              molecule.children = workgroupChildrenIds;
              buttonMolecule.parentId = molecule.id;
              
              // Assigning button parent directly in the DB
              this.moleculesService.SetParent(buttonMolecule.id, molecule.id)
              .subscribe();
              //
              
              changestoSave.push(
                new PropertyVersioningDto({
                  elementId: buttonMolecule.id,
                  property: 'parentId',
                  value: molecule.id,
                  path: ``,
                  change: 'Assigning parent',
                  name: '',
                }),
              );
              // changestoSave.push(
              //   new PropertyVersioningDto({
              //     elementId: molecule.id,
              //     property: 'children',
              //     value: molecule.children,
              //     path: ``,
              //     change: 'Assigning children molecules',
              //     name: '',
              //   }),
              // );
            } else if (molecule.type === RepresentativeMoleculesType.Label) {
              workgroupChildrenIds.push({
                id: molecule.id,
              });
            }
            
            const repMolecule = this.builderService.CreateAndAddRepresentativeMoleculeToBus(molecule, 0);
            
            repMolecule.SetAppDefaultTheme();
          });
          // endregion
          
          const buttonRepMolecule = this.builderService.CreateAndAddRepresentativeMoleculeToBus(
            buttonMolecule, 0);
          buttonRepMolecule.SetAppDefaultTheme();
          
          changestoSave.push(
            new PropertyVersioningDto({
              elementId: buttonRepMolecule.Id.toString(),
              property: 'buses',
              value: buttonRepMolecule.Buses,
              path: ``,
              change: 'Assigning Bus to button',
              name: '',
            }),
          );
          
          this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
          this.propertiesService.SaveProperties(changestoSave)
          .subscribe(result => {
            this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
            this.refreshChildren();
            this.ref.markForCheck();
          });
          this.ref.markForCheck();
          this.communicationService.Event.Editor.EventsTree.$RefreshCustomEvents.emit();
        });
        
        this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanel.emit(true);
        this.communicationService.Event.Editor.EventsTree.$RefreshEventsTree.emit();
      });
    });
  }
  
  CreateTableChart(position: GridsterItem, type: string, dataElements: DataElement[]) {
    console.log(this.eventsService.CustomEvents);
    // console.log(type);
    let switchRowCols = false;
    let sheetName = '';
    
    const dbModeBehavior =
      this.spreadsheetService.editorDbMode ||
      (this.spreadsheetService.SpreadSheetRangeSelected &&
        this.spreadsheetService.SpreadSheetRangeSelected.firstRowIndex === 0 &&
        this.spreadsheetService.SpreadSheetRangeSelected.lastRowIndex === 0);
    let dataSourceType = DatasourceType.Spreadsheet;
    
    let wgCols = 20;
    switch (this.cobbleService.Cobble.deviceType) {
      case 'tablet':
        wgCols = this.cobbleService.Cobble.deviceConfigurations['tablet'].maxItemCols;
        break;
      // case 'smartphone':
      //   wgCols = this.cobbleService.Cobble.deviceConfigurations['smartphone'].maxItemCols;
      //   break;
      default:
        const cols =
          type === RepresentativeMoleculesType.Chart
            ? 170
            : (dataElements.length > 0 ? dataElements.length : this.spreadsheetService.SpreadSheetRangeSelected.cols) * 34 + 6;
        
        wgCols =
          position.x + cols > this.cobbleService.Cobble.deviceConfigurations.desktop.maxItemCols
            ? this.cobbleService.Cobble.deviceConfigurations.desktop.maxItemCols - position.x - 1
            : cols;
        break;
    }
    const apiHeaderDataElements: DataElement[] = [];
    
    const elementCols = type === RepresentativeMoleculesType.Chart ? 164 : wgCols - 6;
    
    const tableColumnWidth = {};
    
    const colStartIndex = dataElements.length > 0 ? 0 : this.spreadsheetService.SpreadSheetRangeSelected.firstColIndex + 1;
    const colEndIndex = dataElements.length > 0 ? dataElements.length - 1 : this.spreadsheetService.SpreadSheetRangeSelected.lastColIndex + 1;
    
    for (let col = colStartIndex; col <= colEndIndex; col++) {
      tableColumnWidth[col] = {
        columnName: '',
        columnWidth: 120,
        columnShow: true,
      };
    }
    
    let elementName = `${
      dataElements.length > 0
        ? dataElements[0].Context.split(Constants.ContextSeparator)[1]
        : this.spreadsheetService.SpreadSheetRangeSelected.collection
    } ${ type }`;
    
    sheetName = `${
      dataElements.length > 0
        ? dataElements[0].Context.split(Constants.ContextSeparator)[1]
        : this.spreadsheetService.SpreadSheetRangeSelected.dataSourceName
    }`;
    const elementsTranslations = [];
    let dragFromHeader = false;
    const dbModeDataElements = [];
    
    if (dataElements.length > 0) {
      dataElements.forEach(dataElement => {
        elementsTranslations.push({
          dataSourceId: null,
          applicationId: this.cobbleService.Cobble.id,
          dataSourceType: dataElement.DataSourceType,
          specialMode: dbModeBehavior,
          context: dataElement.Context,
          reference: dataElement.Reference,
        });
        dataSourceType = dataElement.DataSourceType as DatasourceType;
      });
      
      dataElements.forEach(dataElement => {
        const headerContext = this.factoryService.GetApiLiteralHeadingsContext(dataElement.Context);
        const headerDataElement = new DataElement(dataElement);
        headerDataElement.AssingId();
        headerDataElement.AssingParticleId();
        headerDataElement.Context = headerContext;
        headerDataElement.Reference = headerContext.split(Constants.ContextSeparator)[1];
        headerDataElement.DataSourceType = DatasourceType.Custom;
        headerDataElement.DataSourceName = DatasourceType.Custom;
        headerDataElement.Reference = headerContext.split(Constants.ContextSeparator)[1];
        apiHeaderDataElements.push(headerDataElement);
        
        elementsTranslations.push({
          dataSourceId: null,
          applicationId: this.cobbleService.Cobble.id,
          dataSourceType: DatasourceType.Custom,
          specialMode: dbModeBehavior,
          context: headerContext,
          reference: headerDataElement.Reference,
        });
      });
    } else {
      const elementDataSource = {
        collection: this.spreadsheetService.SpreadSheetRangeSelected.collection,
        cols: this.spreadsheetService.SpreadSheetRangeSelected.cols,
        dataSourceId: this.spreadsheetService.SpreadSheetRangeSelected.dataSourceId,
        dataSourceName: this.spreadsheetService.SpreadSheetRangeSelected.dataSourceName,
        firstColIndex: this.spreadsheetService.SpreadSheetRangeSelected.firstColIndex,
        firstRowIndex: this.spreadsheetService.SpreadSheetRangeSelected.firstRowIndex,
        lastColIndex: this.spreadsheetService.SpreadSheetRangeSelected.lastColIndex,
        lastRowIndex: this.spreadsheetService.SpreadSheetRangeSelected.lastRowIndex,
        range: this.spreadsheetService.SpreadSheetRangeSelected.range,
        rows: this.spreadsheetService.SpreadSheetRangeSelected.rows,
        baseZero: false,
        particleId: this.toolsService.GenerateGuid(),
        context: this.spreadsheetService.SpreadSheetRangeSelected.context,
        dataSourceType: this.spreadsheetService.SpreadSheetRangeSelected.dataSourceTypeName,
        reference: this.spreadsheetService.SpreadSheetRangeSelected.range,
        applicationId: this.cobbleService.Cobble.id,
      };
      
      dataSourceType = elementDataSource.dataSourceType as DatasourceType;
      
      if (type === RepresentativeMoleculesType.Chart) {
        const firstReference = elementDataSource.range.split(':')[0];
        const secondReference = elementDataSource.range.split(':')[1];
        
        elementsTranslations.push({
          dataSourceId: elementDataSource.dataSourceId,
          applicationId: this.cobbleService.Cobble.id,
          dataSourceType: elementDataSource.dataSourceType,
          specialMode: dbModeBehavior,
          context: `${ elementDataSource.dataSourceType }${ Constants.ContextSeparator }${ elementDataSource.dataSourceName }${ Constants.ContextSeparator }${ elementDataSource.collection }${ Constants.ContextSeparator }${ firstReference }`,
          reference: firstReference,
        });
        elementsTranslations.push({
          dataSourceId: elementDataSource.dataSourceId,
          applicationId: this.cobbleService.Cobble.id,
          dataSourceType: elementDataSource.dataSourceType,
          specialMode: dbModeBehavior,
          context: `${ elementDataSource.dataSourceType }${ Constants.ContextSeparator }${ elementDataSource.dataSourceName }${ Constants.ContextSeparator }${ elementDataSource.collection }${ Constants.ContextSeparator }${ secondReference }`,
          reference: secondReference,
        });
      } else if (type === RepresentativeMoleculesType.Table) {
        const separatedRange = this.toolsService.SeparateRangeReference(elementDataSource.range);
        
        dragFromHeader = separatedRange.firstRow === 0 && separatedRange.lastRow === 0;
        
        const headerFirstReference = `${ separatedRange.firstColumn }${ separatedRange.firstRow === 0 ? '' : separatedRange.firstRow }`;
        const headerSecondReference = `${ separatedRange.lastColumn }${ separatedRange.firstRow === 0 ? '' : separatedRange.firstRow }`;
        
        const bodyFirstReference = `${ separatedRange.firstColumn }${ separatedRange.firstRow === 0 ? '' : separatedRange.firstRow + 1 }`;
        const bodySecondReference = `${ separatedRange.lastColumn }${
          elementDataSource.rows === 1 ? separatedRange.lastRow + 1 : separatedRange.lastRow
        }`;
        
        if (dbModeBehavior) {
          const firstRowIndex = Math.min.apply(
            Math,
            this.spreadsheetService.SpreadSheetRangeSelected.dataItems.map(o => {
              return o.row;
            }),
          );
          const row = this.spreadsheetService.SpreadSheetRangeSelected.dataItems.filter(
            a => a.row === firstRowIndex);
          
          row.forEach(di => {
            const dataElement = new DataElement(this.spreadsheetService.SpreadSheetRangeSelected);
            dataElement.ApplicationId = this.cobbleService.Cobble.id;
            dataElement.Reference = this.toolsService.ColumnIndexToName(di.col + 1);
            dataElement.Context = `${ this.spreadsheetService.SpreadSheetRangeSelected.dataSourceType }${ Constants.ContextSeparator }${ this.spreadsheetService.SpreadSheetRangeSelected.dataSourceName }${ Constants.ContextSeparator }${ this.spreadsheetService.SpreadSheetRangeSelected.collection }${ Constants.ContextSeparator }${ dataElement.Reference }`;
            dbModeDataElements.push(dataElement);
            
            elementsTranslations.push({
              dataSourceId: dataElement.DataSourceId,
              applicationId: dataElement.ApplicationId,
              dataSourceType: dataElement.DataSourceType,
              specialMode: dbModeBehavior,
              context: dataElement.Context,
              reference: dataElement.Reference,
            });
          });
        } else {
          if (dragFromHeader) {
            elementsTranslations.push({
              dataSourceId: elementDataSource.dataSourceId,
              applicationId: this.cobbleService.Cobble.id,
              dataSourceType: elementDataSource.dataSourceType,
              specialMode: dbModeBehavior,
              context: `${ elementDataSource.dataSourceType }${ Constants.ContextSeparator }${ elementDataSource.dataSourceName }${ Constants.ContextSeparator }${ elementDataSource.collection }${ Constants.ContextSeparator }${ separatedRange.firstColumn }`,
              reference: separatedRange.firstColumn,
            });
          } else {
            elementsTranslations.push({
              dataSourceId: elementDataSource.dataSourceId,
              applicationId: this.cobbleService.Cobble.id,
              dataSourceType: elementDataSource.dataSourceType,
              specialMode: dbModeBehavior,
              context: `${ elementDataSource.dataSourceType }${ Constants.ContextSeparator }${ elementDataSource.dataSourceName }${ Constants.ContextSeparator }${ elementDataSource.collection }${ Constants.ContextSeparator }${ headerFirstReference }`,
              reference: headerFirstReference,
            });
            elementsTranslations.push({
              dataSourceId: elementDataSource.dataSourceId,
              applicationId: this.cobbleService.Cobble.id,
              dataSourceType: elementDataSource.dataSourceType,
              specialMode: dbModeBehavior,
              context: `${ elementDataSource.dataSourceType }${ Constants.ContextSeparator }${ elementDataSource.dataSourceName }${ Constants.ContextSeparator }${ elementDataSource.collection }${ Constants.ContextSeparator }${ headerSecondReference }`,
              reference: headerSecondReference,
            });
            elementsTranslations.push({
              dataSourceId: elementDataSource.dataSourceId,
              applicationId: this.cobbleService.Cobble.id,
              dataSourceType: elementDataSource.dataSourceType,
              specialMode: dbModeBehavior,
              context: `${ elementDataSource.dataSourceType }${ Constants.ContextSeparator }${ elementDataSource.dataSourceName }${ Constants.ContextSeparator }${ elementDataSource.collection }${ Constants.ContextSeparator }${ bodyFirstReference }`,
              reference: bodyFirstReference,
            });
            elementsTranslations.push({
              dataSourceId: elementDataSource.dataSourceId,
              applicationId: this.cobbleService.Cobble.id,
              dataSourceType: elementDataSource.dataSourceType,
              specialMode: dbModeBehavior,
              context: `${ elementDataSource.dataSourceType }${ Constants.ContextSeparator }${ elementDataSource.dataSourceName }${ Constants.ContextSeparator }${ elementDataSource.collection }${ Constants.ContextSeparator }${ bodySecondReference }`,
              reference: bodySecondReference,
            });
          }
        }
      }
    }
    
    this.dataTranslatorService.CreateTranslation(elementsTranslations)
    .subscribe(translations => {
      const buses = [];
      
      if (type === RepresentativeMoleculesType.Table) {
        this.templateService
        .GetActionMoleculeProperties([
          'DatasourcePaginateDataMolecule',
          // 'PaginateDataMolecule',
          'GetElementsDatasourceDataMolecule',
          'FilterByDataElementReferenceMolecule',
        ])
        .subscribe(properties => {
          let bodyDataElements = [];
          
          const datasourcePaginatedProperties = properties.find(
            p => p.type === 'DatasourcePaginateDataMolecule');
          // const paginatedProperties = properties.find(p => p.type === 'PaginateDataMolecule');
          const getDataElementsProperties = properties.find(
            p => p.type === 'GetElementsDatasourceDataMolecule');
          const filterElementsProperties = properties.find(
            p => p.type === 'FilterByDataElementReferenceMolecule');
          
          // table from tree
          if (dataElements.length > 0) {
            const dataSourceType = dataElements[0].DataSourceType;
            
            dataElements.forEach((dataElement, index) => {
              const translation = translations.find(
                t => t.context.toLowerCase() === dataElement.Context.toLowerCase());
              
              dataElement.TranslationId = translation.id;
              dataElement.InternalName = translation.internalName;
              dataElement.DataSourceId = translation.dataSourceId;
              dataElement.Reference = translation.internalName;
              bodyDataElements.push(dataElement);
            });
            
            // header bus for api tables
            apiHeaderDataElements.forEach(hde => {
              const headerTranslation = translations.find(
                t => t.context.toLowerCase() === hde.Context.toLowerCase());
              
              if (headerTranslation) {
                hde.DataSourceId = headerTranslation.dataSourceId || Constants.Defaults.DataSourceId;
                hde.TranslationId = headerTranslation.id || Constants.Defaults.TranslationId;
                hde.InternalName = headerTranslation.internalName || Constants.Defaults.InternalName;
              }
            });
            
            const headerBusId = this.toolsService.GenerateGuid();
            const valueBusId = this.toolsService.GenerateGuid();
            
            const busToAdd = new Bus({
              id: headerBusId,
              Name: `Process Workgroup ${ elementName }`,
              Receptor: Receptor.HeaderInput,
              LinkBusId: valueBusId,
              Particles: [],
            });
            
            busToAdd.AddParticle(this.factoryParticleService.GenerateEvent(LeapXLEventType.Init));
            busToAdd.AddParticle(
              this.factoryParticleService.GenerateActionMolecule(getDataElementsProperties, 0,
                apiHeaderDataElements));
            
            buses.push(busToAdd);
            
            if (dataSourceType !== DatasourceType.Api) {
              busToAdd.AddParticle(this.factoryParticleService.GenerateEvent(LeapXLEventType.Reload));
            }
            //
            
            const eventId = this.toolsService.GenerateIdFromContext(dataElements[0].Context);
            let customEvent = this.eventsService.CustomEvents.find(ce => ce.EventName === eventId);
            
            if (customEvent) {
              // exists
            } else {
              customEvent = this.factoryParticleService.GenerateCustomEvent(eventId);
              this.eventsService.CustomEvents.push(customEvent);
            }
            
            const firstBus = new Bus({
              id: valueBusId,
              Name: `Process Workgroup ${ elementName }`,
              Receptor: Receptor.ValueInput,
              LinkBusId: headerBusId,
              Particles: [],
            });
            
            firstBus.AddParticle(new LeapXLEvent(customEvent));
            
            if (dataSourceType === DatasourceType.Api) {
              firstBus.AddParticle(
                this.factoryParticleService.GenerateActionMolecule(datasourcePaginatedProperties, 0, [], {
                  paginate: 50,
                }),
              );
            } else {
              firstBus.AddParticle(
                this.factoryParticleService.GenerateActionMolecule(datasourcePaginatedProperties, 0, [], {
                  paginate: 50,
                }),
              );
            }
            
            firstBus.AddParticle(
              this.factoryParticleService.GenerateActionMolecule(
                dataSourceType === DatasourceType.Api ? filterElementsProperties : getDataElementsProperties,
                0,
                bodyDataElements,
              ),
            );
            buses.push(firstBus);
          } else {
            const headerDataElemnts = [];
            
            if (dbModeBehavior) {
              dbModeDataElements.forEach(de => {
                const translation = translations.find(
                  t => t.context.toLowerCase() === de.Context.toLowerCase());
                de.TranslationId = translation.id;
              });
            } else {
              if (dragFromHeader) {
                const headerDataElement = new DataElement({
                  translationId: translations[0].id,
                  id: this.toolsService.GenerateGuid(),
                  particleId: this.toolsService.GenerateGuid(),
                  particleType: ParticleType.DataElement,
                  context: translations[0].context,
                  dataSourceType: DatasourceType.Spreadsheet,
                  dataSourceId: this.spreadsheetService.SpreadSheetRangeSelected.dataSourceId,
                  dataSourceName: this.spreadsheetService.SpreadSheetRangeSelected.dataSourceName,
                  collection: this.spreadsheetService.SpreadSheetRangeSelected.collection,
                  internalName: translations[0].internalName,
                  reference: translations[0].internalName,
                  applicationId: this.cobbleService.Cobble.id,
                });
                
                headerDataElemnts.push(headerDataElement);
              } else {
                const firstHeaderDataElement = new DataElement({
                  translationId: translations[0].id,
                  id: this.toolsService.GenerateGuid(),
                  particleId: this.toolsService.GenerateGuid(),
                  particleType: ParticleType.DataElement,
                  context: translations[0].context,
                  dataSourceType: DatasourceType.Spreadsheet,
                  dataSourceId: this.spreadsheetService.SpreadSheetRangeSelected.dataSourceId,
                  dataSourceName: this.spreadsheetService.SpreadSheetRangeSelected.dataSourceName,
                  collection: this.spreadsheetService.SpreadSheetRangeSelected.collection,
                  internalName: translations[0].internalName,
                  reference: translations[0].internalName,
                  applicationId: this.cobbleService.Cobble.id,
                });
                
                const secondHeaderDataElement = new DataElement({
                  translationId: translations[1].id,
                  id: this.toolsService.GenerateGuid(),
                  particleId: this.toolsService.GenerateGuid(),
                  particleType: ParticleType.DataElement,
                  context: translations[1].context,
                  dataSourceType: DatasourceType.Spreadsheet,
                  dataSourceId: this.spreadsheetService.SpreadSheetRangeSelected.dataSourceId,
                  dataSourceName: this.spreadsheetService.SpreadSheetRangeSelected.dataSourceName,
                  collection: this.spreadsheetService.SpreadSheetRangeSelected.collection,
                  internalName: translations[1].internalName,
                  reference: translations[1].internalName,
                  applicationId: this.cobbleService.Cobble.id,
                });
                
                headerDataElemnts.push(firstHeaderDataElement);
                if (firstHeaderDataElement.Context !== secondHeaderDataElement.Context) {
                  firstHeaderDataElement.RangeParticleId = secondHeaderDataElement.ParticleId;
                  secondHeaderDataElement.RangeParticleId = firstHeaderDataElement.ParticleId;
                  
                  headerDataElemnts.push(secondHeaderDataElement);
                }
              }
            }
            
            const headerBusId = this.toolsService.GenerateGuid();
            const valueBusId = this.toolsService.GenerateGuid();
            
            const busToAdd = new Bus({
              id: headerBusId,
              Name: `Process Workgroup ${ elementName }`,
              LinkBusId: valueBusId,
              Receptor: Receptor.HeaderInput,
              Particles: [],
            });
            
            busToAdd.AddParticle(this.factoryParticleService.GenerateEvent(LeapXLEventType.Init));
            busToAdd.AddParticle(
              this.factoryParticleService.GenerateActionMolecule(
                getDataElementsProperties,
                0,
                dbModeBehavior ? dbModeDataElements : headerDataElemnts,
              ),
            );
            
            buses.push(busToAdd);
            
            if (!dbModeBehavior) {
              if (dragFromHeader) {
                bodyDataElements = headerDataElemnts;
              } else {
                const firstBodyDataElement = new DataElement({
                  translationId: translations[2].id,
                  id: this.toolsService.GenerateGuid(),
                  particleId: this.toolsService.GenerateGuid(),
                  particleType: ParticleType.DataElement,
                  context: translations[2].context,
                  dataSourceType: DatasourceType.Spreadsheet,
                  dataSourceId: this.spreadsheetService.SpreadSheetRangeSelected.dataSourceId,
                  dataSourceName: this.spreadsheetService.SpreadSheetRangeSelected.dataSourceName,
                  collection: this.spreadsheetService.SpreadSheetRangeSelected.collection,
                  internalName: translations[2].internalName,
                  reference: translations[2].internalName,
                  applicationId: this.cobbleService.Cobble.id,
                });
                
                const secondBodyDataElement = new DataElement({
                  translationId: translations[3].id,
                  id: this.toolsService.GenerateGuid(),
                  particleId: this.toolsService.GenerateGuid(),
                  particleType: ParticleType.DataElement,
                  context: translations[3].context,
                  dataSourceType: DatasourceType.Spreadsheet,
                  dataSourceId: this.spreadsheetService.SpreadSheetRangeSelected.dataSourceId,
                  dataSourceName: this.spreadsheetService.SpreadSheetRangeSelected.dataSourceName,
                  collection: this.spreadsheetService.SpreadSheetRangeSelected.collection,
                  internalName: translations[3].internalName,
                  reference: translations[3].internalName,
                  applicationId: this.cobbleService.Cobble.id,
                });
                
                bodyDataElements.push(firstBodyDataElement);
                
                if (firstBodyDataElement.Context !== secondBodyDataElement.Context) {
                  firstBodyDataElement.RangeParticleId = secondBodyDataElement.ParticleId;
                  secondBodyDataElement.RangeParticleId = firstBodyDataElement.ParticleId;
                  bodyDataElements.push(secondBodyDataElement);
                }
              }
            }
            
            const firstBus = new Bus({
              id: valueBusId,
              Name: `Process Workgroup ${ elementName }`,
              LinkBusId: headerBusId,
              Receptor: 'value-input',
              Particles: [],
            });
            
            if (dataSourceType === DatasourceType.Api) {
              const eventId = this.toolsService.GenerateIdFromContext(dataElements[0].Context);
              let customEventApi = this.eventsService.CustomEvents.find(ce => ce.EventName === eventId);
              
              if (customEventApi) {
                // exists
              } else {
                customEventApi = this.factoryParticleService.GenerateCustomEvent(eventId);
                this.eventsService.CustomEvents.push(customEventApi);
              }
              
              firstBus.AddParticle(new LeapXLEvent(customEventApi));
            } else {
              firstBus.AddParticle(this.factoryParticleService.GenerateEvent(LeapXLEventType.Reload));
            }
            
            firstBus.AddParticle(
              this.factoryParticleService.GenerateActionMolecule(datasourcePaginatedProperties, 0, [], {
                paginate: 50,
              }),
            );
            firstBus.AddParticle(
              this.factoryParticleService.GenerateActionMolecule(
                getDataElementsProperties,
                0,
                dbModeBehavior ? dbModeDataElements : bodyDataElements,
              ),
            );
            
            buses.push(firstBus);
          }
          
          ////////////////////////////////////////////////////////////////
          
          // removing search event firing from creation (table do auto search now using cache data)
          
          // const secondBus = new Bus({
          //   id: this.toolsService.GenerateGuid(),
          //   Name: `Process Workgroup ${elementName}`,
          //   Receptor: 'search-output',
          //   Particles: []
          // });
          //
          // secondBus.AddParticle(this.factoryParticleService.GenerateEvent(LeapXLEventType.Search));
          // secondBus.AddParticle(this.factoryParticleService.GenerateEvent(LeapXLEventType.Reload));
          
          const thirdBus = new Bus({
            id: this.toolsService.GenerateGuid(),
            Name: `Process Workgroup ${ elementName }`,
            Receptor: Receptor.None,
            Particles: [],
          });
          
          thirdBus.AddParticle(this.factoryParticleService.GenerateEvent(LeapXLEventType.PageChanged));
          thirdBus.AddParticle(this.factoryParticleService.GenerateEvent(LeapXLEventType.Reload));
          
          if (dataSourceType !== DatasourceType.Api) {
            const fourthBus = new Bus({
              id: this.toolsService.GenerateGuid(),
              Name: `Process Workgroup ${ elementName }`,
              Receptor: Receptor.None,
              Particles: [],
            });
            
            fourthBus.AddParticle(this.factoryParticleService.GenerateEvent(LeapXLEventType.Init));
            fourthBus.AddParticle(this.factoryParticleService.GenerateEvent(LeapXLEventType.Reload));
            buses.push(fourthBus);
            // buses.push(secondBus);
          }
          
          // buses.push(thirdBus);
        });
      } else if (type === RepresentativeMoleculesType.Chart) {
        this.templateService.GetActionMoleculeProperties(['GetElementsDatasourceDataMolecule'])
        .subscribe(properties => {
          let chartDataElements = [];
          
          if (dataElements.length > 0) {
            dataElements.forEach((dataElement, index) => {
              dataElement.TranslationId = translations[index].id;
              dataElement.InternalName = translations[index].internalName;
              dataElement.Reference = translations[index].internalName;
              chartDataElements.push(dataElement);
            });
          } else {
            const firstDataElement = new DataElement({
              translationId: translations[0].id,
              id: this.toolsService.GenerateGuid(),
              particleId: this.toolsService.GenerateGuid(),
              particleType: ParticleType.DataElement,
              context: translations[0].context,
              dataSourceType: DatasourceType.Spreadsheet,
              dataSourceId: this.spreadsheetService.SpreadSheetRangeSelected.dataSourceId,
              dataSourceName: this.spreadsheetService.SpreadSheetRangeSelected.dataSourceName,
              collection: this.spreadsheetService.SpreadSheetRangeSelected.collection,
              internalName: translations[0].internalName,
              reference: translations[0].internalName,
              applicationId: this.cobbleService.Cobble.id,
            });
            
            const secondDataElement = new DataElement({
              translationId: translations[1].id,
              id: this.toolsService.GenerateGuid(),
              particleId: this.toolsService.GenerateGuid(),
              particleType: ParticleType.DataElement,
              context: translations[1].context,
              dataSourceType: DatasourceType.Spreadsheet,
              dataSourceId: this.spreadsheetService.SpreadSheetRangeSelected.dataSourceId,
              dataSourceName: this.spreadsheetService.SpreadSheetRangeSelected.dataSourceName,
              collection: this.spreadsheetService.SpreadSheetRangeSelected.collection,
              internalName: translations[1].internalName,
              reference: translations[1].internalName,
              applicationId: this.cobbleService.Cobble.id,
            });
            
            elementName = `${ this.toolsService.Capitalize(
              translations[1].context.split(Constants.ContextSeparator)[2]) } Chart`;
            switchRowCols = this.toolsService.GetSpreadsheetRangeOrientation(firstDataElement.Context,
              secondDataElement.Context) === 'row';
            
            firstDataElement.RangeParticleId = secondDataElement.ParticleId;
            secondDataElement.RangeParticleId = firstDataElement.ParticleId;
            chartDataElements = [firstDataElement, secondDataElement];
          }
          
          const busToAdd = new Bus({
            id: this.toolsService.GenerateGuid(),
            Name: `Process Workgroup ${ elementName }`,
            Receptor: 'value-input',
            Particles: [],
          });
          
          busToAdd.AddParticle(this.factoryParticleService.GenerateEvent(LeapXLEventType.Init));
          busToAdd.AddParticle(
            this.factoryParticleService.GenerateActionMolecule(properties[0], 0, chartDataElements));
          
          buses.push(busToAdd);
        });
      }
      
      const wgElement: MoleculeRequest = {
        cobbleId: this.cobbleService.Cobble.id,
        parentId: this.cobbleService.Cobble.id,
        moleculeTemplateId: this.workAreaService.libraryMoleculeIds[`${ RepresentativeMoleculesType.WorkGroup }-Representative`],
        properties: [
          {
            name: 'x',
            value: position.x,
            path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          },
          {
            name: 'y',
            value: position.y,
            path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          },
          {
            name: 'cols',
            value: wgCols + 10,
            path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          },
          {
            name: 'rows',
            value: 142,
            path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          },
          {
            name: 'colsQty',
            value: wgCols + 10,
            path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          },
          {
            name: 'rowsQty',
            value: 142,
            path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          },
          {
            name: 'view',
            value: this.workAreaService.ActualView.id,
            path: 'properties',
          },
          {
            name: 'borderStyle',
            value: 'solid',
            path: 'properties.bordersValues',
          },
          {
            name: 'borderWidth',
            value: 1,
            path: 'properties.bordersValues',
          },
          {
            name: 'borderRadius',
            value: 4,
            path: 'properties.bordersValues',
          },
          {
            name: 'hoverBorderRadius',
            value: 4,
            path: 'properties.hover',
          },
          {
            name: 'borderColor',
            value: '#e3e3e3',
            path: 'properties.bordersValues',
          },
          {
            name: 'backgroundColor',
            value: '#fafafa',
            path: 'properties.background',
          },
          {
            name: 'hShadow',
            value: 1,
            path: 'properties.shadowValues',
          },
          {
            name: 'vShadow',
            value: 1,
            path: 'properties.shadowValues',
          },
          {
            name: 'shadowColor',
            value: '#ebf0f5',
            path: 'properties.shadowValues',
          },
          {
            name: 'shadowBlur',
            value: 6,
            path: 'properties.shadowValues',
          },
          {
            name: 'maxItemCols',
            value: this.cobbleService.Cobble.deviceConfigurations[this.cobbleService.Cobble.deviceType].maxItemCols,
            path: 'properties',
          },
        ],
        children: [
          this.CreateFormMoleculeRequest(
            type,
            8,
            21,
            elementCols,
            114,
            RepresentativeMoleculesType[type],
            [],
            [
              {
                name: 'buses',
                path: '',
                value: buses,
              },
              {
                name: 'name',
                path: 'properties',
                value: elementName,
              },
              {
                name: 'tableWidth',
                value: tableColumnWidth,
                path: `properties.responsive.desktop.tableOptions`,
              },
              {
                name: 'switchRowCols',
                value: switchRowCols,
                path: `properties.responsive.desktop.chartOptions`,
              },
              {
                name: 'tableWidth',
                value: tableColumnWidth,
                path: `properties.responsive.smartphone.tableOptions`,
              },
              {
                name: 'selectRow',
                value: 'true',
                path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }.tableOptions`,
              },
              {
                name: 'itemsCount',
                value: 'true',
                path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }.tableOptions`,
              },
              {
                name: 'centerPagination',
                value: 'true',
                path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }.tableOptions`,
              },
              // disable search for table creation
              // {
              //   name: 'search',
              //   value: dataElements.length === 0,
              //   path: `properties.responsive.desktop.tableOptions`
              // },
              // {
              //   name: 'search',
              //   value: dataElements.length === 0,
              //   path: `properties.responsive.smartphone.tableOptions`
              // },
              {
                name: 'view',
                value: this.workAreaService.ActualView.id,
                path: 'properties',
              },
              {
                name: 'ignoreValueDataIndex',
                value: dbModeBehavior && type === RepresentativeMoleculesType.Table && dataSourceType === DatasourceType.Spreadsheet,
                path: 'properties',
              },
            ],
          ),
          this.CreateFormMoleculeRequest(
            type,
            10,
            4,
            wgCols - 20,
            8,
            RepresentativeMoleculesType.H3,
            [],
            [
              {
                name: 'name',
                path: 'properties',
                value: 'Table Title',
              },
              {
                name: 'view',
                value: this.workAreaService.ActualView.id,
                path: 'properties',
              },
              {
                name: 'fontSize',
                value: 20,
                path: 'properties.responsive.desktop.font',
              },
              {
                name: 'fontColor',
                value: '#636363',
                path: 'properties.responsive.desktop.font',
              },
              {
                name: 'fontFamily',
                value: 'Titillium Web',
                path: 'properties.responsive.desktop.font',
              },
              {
                name: 'fontWeight',
                value: 'bold',
                path: 'properties.responsive.desktop.font',
              },
              {
                name: 'textToDisplay',
                value: sheetName,
                path: 'properties',
              },
            ],
          ),
          this.CreateFormMoleculeRequest(
            type,
            0,
            15,
            wgCols + 10,
            2,
            RepresentativeMoleculesType.H5,
            [],
            [
              {
                name: 'name',
                path: 'properties',
                value: 'Separator',
              },
              {
                name: 'view',
                value: this.workAreaService.ActualView.id,
                path: 'properties',
              },
              {
                name: 'textToDisplay',
                value: '',
                path: 'properties',
              },
              {
                name: 'backgroundColor',
                value: '#ebebeb',
                path: 'properties.background',
              },
            ],
          ),
        ],
      };
      
      this.moleculesService.GetMolecules([wgElement])
      .subscribe((response: any) => {
        response.molecules.forEach(molecule => {
          const changestoSave = [];
          
          molecule.buses.forEach(bus => {
            let particlesAffected = 0;
            bus.Particles.forEach((p, index) => {
              if (p.ParticleType === ParticleType.Molecule) {
                p.MoleculeId = molecule.id;
                particlesAffected++;
              } else if (p.ParticleType === ParticleType.Event) {
                p.SourceId = p.SourceId === 0 || p.SourceId === '' ? molecule.id : p.SourceId;
                particlesAffected++;
              }
            });
            
            if (particlesAffected > 0) {
              changestoSave.push(
                new PropertyVersioningDto({
                  elementId: molecule.id,
                  property: 'buses',
                  value: molecule.buses,
                  path: ``,
                  change: 'Assigning ids',
                  name: '',
                }),
              );
            }
          });
          
          const repMolecule = this.builderService.CreateAndAddRepresentativeMoleculeToBus(molecule, 0);
          
          repMolecule.SetAppDefaultTheme();
          
          this.eventsService.CreateEventsTable();
          this.propertiesService.SaveProperties(changestoSave)
          .subscribe();
          this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
          this.refreshChildren();
          this.ref.markForCheck();
        });
        
        this.eventsService.SaveEvents()
        .subscribe(() => {
          this.ref.markForCheck();
          
          this.communicationService.Event.Editor.EventsTree.$RefreshCustomEvents.emit();
          this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanel.emit(true);
          this.communicationService.Event.Editor.EventsTree.$RefreshEventsTree.emit();
        });
      });
    });
  }
  
  CreateFormMoleculeRequest(
    value: string,
    x: number,
    y: number,
    cols: number,
    rows: number,
    representativeType: string,
    children: MoleculeRequest[] = [],
    properties: any[],
    parentId = null,
  ): MoleculeRequest {
    properties = properties.concat([
      {
        name: 'textToDisplay',
        value: value,
        path: 'properties',
      },
      {
        name: 'placeholder',
        value: value,
        path: 'properties',
      },
      {
        name: 'view',
        value: this.workAreaService.ActualView.id,
        path: 'properties',
      },
      {
        name: 'name',
        value: `${ representativeType } - ${ value }`,
        path: 'properties',
      },
      {
        name: 'y',
        value: y,
        path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
      },
      {
        name: 'x',
        value: x,
        path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
      },
      {
        name: 'rows',
        value: rows,
        path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
      },
      {
        name: 'cols',
        value: cols,
        path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
      },
    ]);
    
    return {
      cobbleId: this.cobbleService.Cobble.id,
      parentId: parentId,
      moleculeTemplateId: this.workAreaService.libraryMoleculeIds[`${ representativeType }-Representative`],
      properties: properties,
      children: children,
    };
  }
  
  updateZoom(zoomLevel = this.workAreaService.zoomLevel, xOrigin = 0, yOrigin = 0, setLevel = false) {
    // this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    
    let factor = zoomLevel;
    if (setLevel) {
      const currentScale = this.panZoom.getTransform().scale;
      factor = zoomLevel / currentScale;
    }
    this.panZoom.smoothZoom(xOrigin, yOrigin, factor);
    
    return;
    const min = 0.3;
    const max = 2;
    const level = zoomLevel / 50;
    const actualZoom = level > max ? max : level < min ? min : level;
    const gridsterEl = document.querySelector('.work-area-gridster') as any;
    (gridsterEl as any).style.transformOrigin = `${ xOrigin }px ${ yOrigin }px`;
    gridsterEl.classList.add('animated-transform');
    gridsterEl.style.transform = `scale(${ actualZoom })`;
    
    setTimeout(() => {
      gridsterEl.classList.remove('animated-transform');
    }, 500);
  }
  
  zoomChanged(event: MatSliderChange) {
    console.log(event.value);
    const factor = event.value / 50;
    this.updateZoom(factor, 0, 0, true);
    
    return;
    this.workAreaService.zoomLevel = event.value;
    this.updateZoom();
  }
  
  zoomIn() {
    this.updateZoom(1.2, 0, 0);
    return;
    if (this.workAreaService.zoomLevel <= 100) {
      this.workAreaService.zoomLevel = this.workAreaService.zoomLevel + 5;
      this.updateZoom();
    }
  }
  
  zoomOut() {
    this.updateZoom(0.8, 0, 0);
    
    return;
    if (this.workAreaService.zoomLevel >= 1) {
      this.workAreaService.zoomLevel = this.workAreaService.zoomLevel - 5;
      this.updateZoom();
    }
  }
  
  zoomToDefault(xOrigin = 0, yOrigin = 0) {
    if (this.panZoom) {
      const transform = this.panZoom.getTransform();
      
      if (xOrigin > 0 || yOrigin > 0) {
        this.updateZoom(1, transform.x + xOrigin, transform.y + yOrigin, true);
      } else {
        this.updateZoom(1, 0, 0, true);
      }
    }
    
    window['zoomFactor'] = 0;
    window['zoomScale'] = 0;
    
    return;
    this.workAreaService.zoomLevel = 50;
    this.updateZoom(this.workAreaService.zoomLevel, xOrigin, yOrigin);
  }
  
  panToDefault() {
    if (this.panZoom) {
      this.panZoom.moveTo(0, 0);
    }
  }
  
  ResetPanZoom() {
    const contentEl = document.querySelector('.work-area-content');
    contentEl.scrollTop = 0;
    contentEl.scrollLeft = 0;
    this.panToDefault();
    this.zoomToDefault();
  }
  
  formatZoom(value: number) {
    const zoomLevel = (this as any as MatSlider).percent * 100;
    return `${ Math.round(zoomLevel) }%`;
  }
  
  public OpenSpreadSheetPanel() {
    this.workAreaService.canvasAreaSize = 75;
    this.workAreaService.sheetAreaSize = 25;
    this.ref.markForCheck();
  }
  
  public CloseSpreadSheetPanel() {
    this.spreadsheetService.productionDatasourceOpen = false;
    this.workAreaService.canvasAreaSize = 100;
    this.workAreaService.sheetAreaSize = 0;
    this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoaded.emit(false);
    this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetLoading.emit(false);
    this.ref.markForCheck();
  }
  
  SpreadsheetModeSwitched(e: MatSlideToggleChange) {
    this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetModeSwitched.emit(
      this.spreadsheetService.editorDbMode);
  }
  
  DataSourceVersionSwitch(e: MatSlideToggleChange) {
    this.communicationService.Event.Editor.DataSource.Spreadsheet.$SpreadsheetVersionSwitched.emit(
      this.spreadsheetService.productionDatasourceOpen);
    
    if (this.spreadsheetService.productionDatasourceOpen) {
      this.spreadsheetService.ReloadProductionDataSourceOnSpreadSheetPanel();
    } else {
      this.spreadsheetService.ReloadDataSourceOnSpreadSheetPanel();
    }
  }
  
  public TrackById(index, item) {
    if (!item) {
      return null;
    }
    return item.Id;
  }
  
  OpenUserGuideStartPageDocumentation() {
    let config = {};
    
    if (this.clientStorageService.getGuideStartPage() === 'N' && this.smartTemplatesService.autoOpen) {
      config = {
        changeLayout: false,
        x: 50,
        y: 80,
        width: 700,
        height: 500,
      };
    }
    
    this.documentationService.ObtainStartPageDocumentation()
    .subscribe(userGuide => {
      this.workAreaService.userGuideId = userGuide.guideLinkId;
      this.workAreaService.userGuideType = userGuide.guideLinkType;
      
      this.draggableWindowService.OpenDraggableWindow(
        `Welcome - Help`,
        DraggableWindowType.Welcome,
        null,
        {
          id: this.workAreaService.userGuideId,
          type: this.workAreaService.userGuideType,
          noShowNext: true,
        },
        false,
        config,
      );
    });
  }
  
  ngOnDestroy(): void {
    if (this.workAreaService.smartTemplateWindow) {
      this.workAreaService.smartTemplateWindow.Hide();
    }
    this.workAreaService.HideDraggableWindows();
    this.editorStateService.SaveEditorState();
    // console.log(this.communicationService.Event.Editor.DataSource.$ReloadDataSourcePanel.observers);
    // console.log(this.communicationService.Event.Editor.DataSource.$ReloadDataSourcePanel.closed);
    // console.log(this.communicationService.Event.Editor.DataSource.$ReloadDataSourcePanel.isStopped);
    this.dataSourcesService.usedDataSourceTranslations = [];
    // this.workAreaService.SwitchLeftPanelTab(0);
    this.workAreaService.editorPreferences.layoutsPanel = false;
    this.workAreaService.showMobileLayout = true;
    this.workAreaService.mobileEmulatorSize = 'normal';
    this.workAreaService.RunMobileTest(false);
    this.workAreaService.loadingOverlay.display = false;
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    this.workAreaService.HideElementFocusedMenu();
    this.hubConnectionService.CobbleClosed();
    this.subscription.unsubscribe();
    this.draggableWindowService.Close();
    this.dataSourcesService.openedDataSourceId = 0;
    this.dataSourcesService.openedDataSourceSheetIndex = 0;
    if (this.bottomSheetSubscription) {
      this.bottomSheetSubscription.unsubscribe();
    }
    if (this.dragSelection) {
      this.dragSelection.destroy();
    }
    this.cobbleService.ClearCobbleSession();
    this.communicationService.Event.System.App.$AppUnloaded.emit(true);
    this.communicationService.Event.Editor.WorkArea.$ChangeTopBarPosition.emit();
  }
  
  ngAfterViewChecked() {
    // // console.log('Change detection triggered!');
  }
  
  CopyRepresentativeMoleculesStyle(repMolecule: IRepresentativeMolecule, section = 'all', event: MouseEvent) {
    if (this.workAreaService.elementsSelected.length >= 2) {
      console.log(this.thematicService.CopyStyle(this.workAreaService.firstElementSelected, section,
        this.workAreaService.secondElementSelected));
    } else {
      this.thematicService.CopyStyle(repMolecule, section, this.workAreaService.secondElementSelected);
    }
  }
  
  RemoveRepMolecule(repMolecule: IRepresentativeMolecule) {
    
    if (repMolecule.IsOnlyWorkgroupInWorkArea() && !this.cobbleService.Cobble.isApplication) {
      return;
    }
    
    if (this.workAreaService.elementsSelected.length > 1) {
      this.moleculeManagmentService.RemoveRepresentativeMolecules(
        this.workAreaService.elementsSelected.map(es => es.Id));
    } else {
      this.moleculeManagmentService.RemoveRepresentativeMolecule(repMolecule.Id);
    }
    
    this.workAreaService.HideElementFocusedMenu();
  }
  
  SaveRepresentativeMoleculeStyle(repMolecule: IRepresentativeMolecule, section = 'all', event: MouseEvent) {
    let style = null;
    if (this.workAreaService.elementsSelected.length >= 2) {
      style = this.thematicService.ObtainRepresentativeMoleculeStyle(
        this.workAreaService.firstElementSelected,
        section,
        this.workAreaService.secondElementSelected,
      );
    } else {
      style = repMolecule.GetStyle(section, this.workAreaService.secondElementSelected);
    }
    this.CreateStyle(event, style);
  }
  
  ExportRepresentativeMoleculesStyle(repMolecule: IRepresentativeMolecule, section = 'all') {
    this.thematicService.ExportStyle(repMolecule, section);
  }
  
  ToggleLockRepMolecule(repMolecule: IRepresentativeMolecule) {
    this.contextMenu.closeMenu();
    
    let newState = null;
    let repMolecules = [];
    const propertiesToSave = [];
    
    if (repMolecule.Properties.dragEnabled === false) {
      newState = true;
    } else {
      newState = false;
    }
    
    if (this.workAreaService.elementsSelected.length > 1) {
      repMolecules = this.workAreaService.elementsSelected;
    } else {
      repMolecules.push(repMolecule);
    }
    
    repMolecules.forEach((es: IRepresentativeMolecule) => {
      es.Properties.dragEnabled = newState;
      es.Properties.resizeEnabled = newState;
      
      propertiesToSave.push(
        new PropertyVersioningDto({
          elementId: es.Id.toString(),
          property: 'dragEnabled',
          value: newState,
          path: `properties`,
          change: `Lock/Unlock State`,
          name: 'Lock',
        }),
      );
      propertiesToSave.push(
        new PropertyVersioningDto({
          elementId: es.Id.toString(),
          property: 'resizeEnabled',
          value: newState,
          path: `properties`,
          change: `Lock/Unlock State`,
          name: 'Lock',
        }),
      );
    });
    
    this.propertiesService.SaveProperties(propertiesToSave)
    .subscribe();
    
    this.snackerService.ShowMessageOnBottom(
      `Representative molecule ${ !newState ? 'locked' : 'unlocked' }`,
      `${ !newState ? 'lock' : 'lock_open' }`,
      null,
      true,
    );
  }
  
  PasteRepresentativeMoleculeStyle(repMolecule: IRepresentativeMolecule) {
    if (this.workAreaService.elementsSelected.length > 0) {
      this.workAreaService.elementsSelected.forEach((es: any) => {
        es.hyp = Math.hypot(
          es.ResponsiveProperties().x - this.workAreaService.firstElementSelected.ResponsiveProperties().x,
          es.ResponsiveProperties().y - this.workAreaService.firstElementSelected.ResponsiveProperties().y,
        );
      });
      
      this.workAreaService.elementsSelected = this.workAreaService.elementsSelected.sort((a: any, b: any) => {
        return a.hyp - b.hyp;
      });
      
      this.workAreaService.elementsSelected.forEach(es => {
        es.PasteStyle();
      });
    } else {
      repMolecule.PasteStyle();
    }
  }
  
  CreateStyle(event: MouseEvent, style: IRepresentativeMoleculeStyleData) {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    this.draggableWindowService.OpenDraggableWindow(
      'Save Style',
      DraggableWindowType.CreateStyle,
      event,
      { style },
    );
  }
  
  HandleStyleFile(files: FileList) {
    if (files.length > 0) {
      const dataFile = files[0];
      const reader = new FileReader();
      
      reader.onload = (event: any) => {
        const styleData = JSON.parse(event.target.result) as IRepresentativeMoleculeStyleData;
        
        if (styleData && styleData.properties) {
          if (this.workAreaService.elementsSelected.length > 0) {
            this.workAreaService.elementsSelected.forEach(es => {
              es.ResetStyles();
              es.SetStyle([styleData]);
            });
          } else {
            if (this.workAreaService.elementClicked) {
              this.workAreaService.elementClicked.ResetStyles();
              this.workAreaService.elementClicked.SetStyle([styleData]);
            }
          }
          this.styleFileUploader.nativeElement.value = '';
        }
      };
      
      reader.readAsText(dataFile);
    }
  }
  
  DragStartHandler(event: MouseEvent, repMolecule: IRepresentativeMolecule, section = 'all') {
    setTimeout(() => {
      this.toolsService.ToggleMaterialOverlayBackdrop(false);
    }, 50);
    const style = this.thematicService.CopyStyle(repMolecule, section);
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
    // console.log('drag start', event);
    this.dragService.StartDrag();
    (event.target as any).style.opacity = 0.3;
    
    this.dragService.dragText = `Apply ${ style.name } style`;
    this.dragService.dragginStyle = true;
    
    this.dragService.dragData = {
      dragType: DragType.Style,
      style,
      type: 'copy',
    };
  }
  
  DragEndHandler(event: MouseEvent) {
    this.toolsService.ToggleMaterialOverlayBackdrop(true);
    (event.target as any).style.opacity = 1;
    this.dragService.StopDragging();
    setTimeout(() => {
      this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    }, 300);
  }
  
  AlignLeft() {
    const firstES = this.workAreaService.firstElementSelected;
    this.workAreaService.elementsSelected.forEach((es, index) => {
      if (firstES.Id === es.Id) {
        return;
      }
      es.ResponsiveProperties().x = firstES.ResponsiveProperties().x;
      es.SavePropertyFromVersioning(
        new PropertyVersioningDto({
          elementId: es.Id.toString(),
          property: 'x',
          value: es.ResponsiveProperties().x,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: 'Edit',
          name: 'Position X',
        }),
      )
      .subscribe();
    });
    
    firstES.RefreshParent();
    this.snackerService.ShowMessageOnBottom('Representative molecules aligned to the left',
      'align_horizontal_left',
      null, true);
  }
  
  AlignCenter() {
    const firstES = this.workAreaService.firstElementSelected;
    const centerX = firstES.ResponsiveProperties().x + firstES.ResponsiveProperties().cols - firstES.ResponsiveProperties().cols / 2;
    this.workAreaService.elementsSelected.forEach((es, index) => {
      if (firstES.Id === es.Id) {
        return;
      }
      
      es.ResponsiveProperties().x = centerX - es.ResponsiveProperties().cols / 2;
      es.SavePropertyFromVersioning(
        new PropertyVersioningDto({
          elementId: es.Id.toString(),
          property: 'x',
          value: es.ResponsiveProperties().x,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: 'Edit',
          name: 'Position X',
        }),
      )
      .subscribe();
    });
    
    firstES.RefreshParent();
    this.snackerService.ShowMessageOnBottom('Representative molecules aligned to the center',
      'align_horizontal_center',
      null, true);
  }
  
  AlignRight() {
    const firstES = this.workAreaService.firstElementSelected;
    const maxX = firstES.ResponsiveProperties().x + firstES.ResponsiveProperties().cols;
    this.workAreaService.elementsSelected.forEach((es, index) => {
      if (firstES.Id === es.Id) {
        return;
      }
      es.ResponsiveProperties().x = maxX - es.ResponsiveProperties().cols;
      es.SavePropertyFromVersioning(
        new PropertyVersioningDto({
          elementId: es.Id.toString(),
          property: 'x',
          value: es.ResponsiveProperties().x,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: 'Edit',
          name: 'Position X',
        }),
      )
      .subscribe();
    });
    
    firstES.RefreshParent();
    this.snackerService.ShowMessageOnBottom('Representative molecules aligned to the right',
      'align_horizontal_right',
      null, true);
  }
  
  AlignVertically() {
    const firstES = this.workAreaService.elementsSelected[0];
    this.workAreaService.elementsSelected.forEach((es, index) => {
      if (index === 0) {
        return;
      }
      es.ResponsiveProperties().y = firstES.ResponsiveProperties().y;
      es.SavePropertyFromVersioning(
        new PropertyVersioningDto({
          elementId: es.Id.toString(),
          property: 'y',
          value: es.ResponsiveProperties().y,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: 'Edit',
          name: 'Position Y',
        }),
      )
      .subscribe();
    });
    
    firstES.RefreshParent();
    this.snackerService.ShowMessageOnBottom('Representative molecules aligned vertically',
      'align_vertical_center',
      null, true);
  }
  
  DistributeVerticallyMinimum() {
    
    const initValue = 9999999;
    
    let minimun = initValue;
    
    if (this.workAreaService.elementsSelected.length <= 2) {
      return;
    }
    
    const orderVertically = this.OrderByY(this.workAreaService.elementsSelected);
    
    orderVertically.forEach((repMolecule, index) => {
      
      if (index === 0) {
        return;
      }
      
      const gap = repMolecule.ResponsiveProperties().y - (orderVertically[index - 1].ResponsiveProperties().y +
        orderVertically[index - 1].ResponsiveProperties().rows);
      
      minimun = gap < minimun && gap > 0 ? gap : minimun;
      
    });
    
    this.DistributeVertically(minimun === initValue ? 0 : minimun, [orderVertically[0]]);
  }
  
  DistributeVerticallyMaximum() {
    let maximum = 0;
    
    if (this.workAreaService.elementsSelected.length <= 2) {
      return;
    }
    
    const orderVertically = this.OrderByY(this.workAreaService.elementsSelected);
    
    orderVertically.forEach((repMolecule, index) => {
      
      if (index === 0) {
        return;
      }
      
      const gap = repMolecule.ResponsiveProperties().y - (orderVertically[index - 1].ResponsiveProperties().y +
        orderVertically[index - 1].ResponsiveProperties().rows);
      
      maximum = gap > maximum ? gap : maximum;
      
    });
    
    this.DistributeVertically(maximum, [orderVertically[0]]);
  }
  
  DistributeVerticallyEvenly() {
    let gaps = [];
    
    if (this.workAreaService.elementsSelected.length <= 2) {
      return;
    }
    
    const orderVertically = this.OrderByY(this.workAreaService.elementsSelected);
    
    orderVertically.forEach((repMolecule, index) => {
      
      if (index === 0) {
        return;
      }
      
      const gap = repMolecule.ResponsiveProperties().y - (orderVertically[index - 1].ResponsiveProperties().y +
        orderVertically[index - 1].ResponsiveProperties().rows);
      
      if (gap > 0) {
        gaps.push(gap);
      }
    });
    
    const average = gaps.length > 0 ? gaps.reduce((a, b) => a + b) / gaps.length : 0;
    
    this.DistributeVertically(average, [orderVertically[0]]);
  }
  
  DistributeVerticallyEvenlySpaced() {
    if (this.workAreaService.elementsSelected.length <= 1) {
      return;
    }
    
    const orderVertically = this.OrderByY(this.workAreaService.elementsSelected);
    
    const parent = this.busService.GetParent(orderVertically[0].Id.toString());
    const parentRows = parent.ResponsiveProperties().rows;
    const repMoleculeRows = orderVertically.map(repMolecule => repMolecule.ResponsiveProperties().rows)
    .reduce((a, b) => a + b);
    
    const availableSpace = parentRows - repMoleculeRows;
    const gap = availableSpace / (orderVertically.length + 1);
    
    this.DistributeVertically(gap, [], false);
  }
  
  
  DistributeVerticallyAuto() {
    let gap = 0;
    
    const orderVertically = this.OrderByY(this.workAreaService.elementsSelected);
    const firstES = orderVertically[0];
    
    for (let i = 1; i < orderVertically.length; i++) {
      gap = orderVertically[i].ResponsiveProperties().y - (firstES.ResponsiveProperties().y + firstES.ResponsiveProperties().rows);
      
      if (gap > 0) {
        break;
      }
    }
    
    this.DistributeVertically(gap, [firstES]);
  }
  
  DistributeVerticallyFirstSecondSelected() {
    const firstES = this.workAreaService.firstElementSelected;
    const secondES = this.workAreaService.secondElementSelected;
    const gap = secondES.ResponsiveProperties().y - (firstES.ResponsiveProperties().y + firstES.ResponsiveProperties().rows);
    this.DistributeVertically(gap, [firstES, secondES]);
  }
  
  DistributeVertically(space: number | string, avoidRepMolecules: IRepresentativeMolecule[] = [], startOnFirstReMolecule = true) {
    this.contextMenu.closeMenu();
    
    let gap = +space;
    
    if (gap <= 0) {
      gap = 0;
    }
    
    const avoidRepMoleculeIds = avoidRepMolecules.map(repMolecule => repMolecule.Id);
    
    const sortedRepMolecules = this.OrderByY(this.workAreaService.elementsSelected);
    
    let fits = true;
    
    //region evaluate fitting
    const parent = this.busService.GetParent(sortedRepMolecules[0].Id.toString());
    const previousY = startOnFirstReMolecule ? [sortedRepMolecules[0].ResponsiveProperties().y] : [0];
    
    sortedRepMolecules.forEach((es, index) => {
      
      if ((avoidRepMoleculeIds.includes(es.Id) || index === 0) && startOnFirstReMolecule) {
        return;
      }
      
      const nextY = index === 0 ? gap + es.ResponsiveProperties().rows + gap :
        previousY[index - 1] +
        sortedRepMolecules[index - 1].ResponsiveProperties().rows +
        gap;
      
      previousY.push(nextY);
      
      if (nextY > parent.ResponsiveProperties().rows && fits) {
        fits = false;
      }
      
    });
    // endregion
    
    if (!fits) {
      this.snackerService.ShowMessageOnBottom('Vertical distribution does not fits in Workgroup', 'warning',
        3000);
      return;
    }
    
    const previousPositions = sortedRepMolecules.map(srm => {
      return {
        id: srm.ResponsiveProperties().id,
        y: srm.ResponsiveProperties().y,
      };
    });
    
    sortedRepMolecules
    .forEach((es, index) => {
      if ((avoidRepMoleculeIds.includes(es.Id) || index === 0) && startOnFirstReMolecule) {
        return;
      }
      
      const y = index === 0 ? gap :
        sortedRepMolecules[index - 1].ResponsiveProperties().y +
        sortedRepMolecules[index - 1].ResponsiveProperties().rows +
        gap;
      
      es.ResponsiveProperties().y = Math.round(y);
      
      es.SavePropertyFromVersioning(
        new PropertyVersioningDto({
          elementId: es.Id.toString(),
          property: 'y',
          value: es.ResponsiveProperties().y,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: 'Edit',
          name: 'Position Y',
        }),
      )
      .subscribe();
    });
    
    this.workAreaService.elementsSelected[0].RefreshParent();
    this.snackerService.ShowMessageOnBottom(
      'Representative molecules distributed vertically',
      'format_line_spacing',
      5500,
      true,
      {
        text: 'Revert',
        icon: 'undo',
        callback: () => {
          sortedRepMolecules.forEach(repMolecule => {
            
            const previousPosition = previousPositions.find(pp => pp.id === repMolecule.Id);
            if (previousPosition) {
              repMolecule.ResponsiveProperties().y = previousPosition.y;
              
              repMolecule.SavePropertyFromVersioning(
                new PropertyVersioningDto({
                  elementId: repMolecule.Id.toString(),
                  property: 'y',
                  value: repMolecule.ResponsiveProperties().y,
                  path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                  change: 'Revert position change',
                  name: 'Position Y',
                }),
              )
              .subscribe();
            }
          });
          
          sortedRepMolecules[0].RefreshParent();
        },
      });
  }
  
  
  DistributeHorizontallyMinimum() {
    const initValue = 9999999;
    
    let minimun = initValue;
    
    if (this.workAreaService.elementsSelected.length <= 2) {
      return;
    }
    
    const orderHorizontally = this.OrderByX(this.workAreaService.elementsSelected);
    
    orderHorizontally.forEach((repMolecule, index) => {
      
      if (index === 0) {
        return;
      }
      
      const gap = repMolecule.ResponsiveProperties().x - (orderHorizontally[index - 1].ResponsiveProperties().x +
        orderHorizontally[index - 1].ResponsiveProperties().cols);
      
      minimun = gap < minimun && gap > 0 ? gap : minimun;
      
    });
    
    this.DistributeHorizontally(minimun === initValue ? 0 : minimun, [orderHorizontally[0]]);
  }
  
  DistributeHorizontallyMaximum() {
    let maximum = 0;
    
    if (this.workAreaService.elementsSelected.length <= 2) {
      return;
    }
    
    const orderHorizontally = this.OrderByX(this.workAreaService.elementsSelected);
    
    orderHorizontally.forEach((repMolecule, index) => {
      
      if (index === 0) {
        return;
      }
      
      const gap = repMolecule.ResponsiveProperties().x - (orderHorizontally[index - 1].ResponsiveProperties().x +
        orderHorizontally[index - 1].ResponsiveProperties().cols);
      
      maximum = gap > maximum ? gap : maximum;
      
    });
    
    this.DistributeHorizontally(maximum, [orderHorizontally[0]]);
  }
  
  
  DistributeHorizontallyEvenly() {
    
    let gaps = [];
    
    if (this.workAreaService.elementsSelected.length <= 2) {
      return;
    }
    
    const orderHorizontally = this.OrderByX(this.workAreaService.elementsSelected);
    
    orderHorizontally.forEach((repMolecule, index) => {
      
      if (index === 0) {
        return;
      }
      
      const gap = repMolecule.ResponsiveProperties().x - (orderHorizontally[index - 1].ResponsiveProperties().x +
        orderHorizontally[index - 1].ResponsiveProperties().cols);
      
      if (gap > 0) {
        gaps.push(gap);
      }
    });
    
    const average = gaps.length > 0 ? gaps.reduce((a, b) => a + b) / gaps.length : 0;
    
    this.DistributeHorizontally(average, [orderHorizontally[0]]);
  }
  
  
  DistributeHorizontallyEvenlySpaced() {
    if (this.workAreaService.elementsSelected.length <= 1) {
      return;
    }
    
    const orderHorizontally = this.OrderByX(this.workAreaService.elementsSelected);
    
    const parent = this.busService.GetParent(orderHorizontally[0].Id.toString());
    const parentRows = parent.ResponsiveProperties().cols;
    const repMoleculeRows = orderHorizontally.map(repMolecule => repMolecule.ResponsiveProperties().cols)
    .reduce((a, b) => a + b);
    
    const availableSpace = parentRows - repMoleculeRows;
    const gap = availableSpace / (orderHorizontally.length + 1);
    
    this.DistributeHorizontally(gap, [], false);
  }
  
  DistributeHorizontallyAuto() {
    let gap = 0;
    const orderHorizontally = this.OrderByX(this.workAreaService.elementsSelected);
    
    const firstES = orderHorizontally[0];
    
    for (let i = 1; i < orderHorizontally.length; i++) {
      gap = orderHorizontally[i].ResponsiveProperties().x - (firstES.ResponsiveProperties().x + firstES.ResponsiveProperties().cols);
      
      if (gap > 0) {
        break;
      }
    }
    
    this.DistributeHorizontally(gap, [firstES]);
  }
  
  DistributeHorizontallyFirstSecondSelected() {
    const firstES = this.workAreaService.firstElementSelected;
    const secondES = this.workAreaService.secondElementSelected;
    const gap = secondES.ResponsiveProperties().x - (firstES.ResponsiveProperties().x + firstES.ResponsiveProperties().cols);
    this.DistributeHorizontally(gap, [firstES, secondES]);
  }
  
  DistributeHorizontally(space: number | string, avoidRepMolecules: IRepresentativeMolecule[] = [], startOnFirstReMolecule = true) {
    this.contextMenu.closeMenu();
    
    let gap = +space;
    
    if (gap < 0) {
      gap = 0;
    }
    
    const avoidRepMoleculeIds = avoidRepMolecules.map(repMolecule => repMolecule.Id);
    
    const sortedRepMolecules = this.OrderByX(this.workAreaService.elementsSelected);
    
    let fits = true;
    
    //region evaluate fitting
    const parent = this.busService.GetParent(sortedRepMolecules[0].Id.toString());
    const previousX = startOnFirstReMolecule ? [sortedRepMolecules[0].ResponsiveProperties().x] : [0];
    
    sortedRepMolecules.forEach((es, index) => {
      
      if ((avoidRepMoleculeIds.includes(es.Id) || index === 0) && startOnFirstReMolecule) {
        return;
      }
      
      const nextX = index === 0 ? gap + es.ResponsiveProperties().cols + gap :
        previousX[index - 1] +
        sortedRepMolecules[index - 1].ResponsiveProperties().cols +
        gap;
      
      previousX.push(nextX);
      
      if (nextX > parent.ResponsiveProperties().cols && fits) {
        fits = false;
      }
      
    });
    // endregion
    
    if (!fits) {
      this.snackerService.ShowMessageOnBottom('Horizontal distribution does not fits in Workgroup', 'warning',
        3000);
      return;
    }
    
    const previousPositions = sortedRepMolecules.map(srm => {
      return {
        id: srm.ResponsiveProperties().id,
        x: srm.ResponsiveProperties().x,
      };
    });
    
    sortedRepMolecules
    .forEach((es, index) => {
      if ((avoidRepMoleculeIds.includes(es.Id) || index === 0) && startOnFirstReMolecule) {
        return;
      }
      
      const newX = index === 0 ? gap :
        sortedRepMolecules[index - 1].ResponsiveProperties().x +
        sortedRepMolecules[index - 1].ResponsiveProperties().cols +
        gap;
      
      es.ResponsiveProperties().x = Math.round(newX);
      
      es.SavePropertyFromVersioning(
        new PropertyVersioningDto({
          elementId: es.Id.toString(),
          property: 'x',
          value: es.ResponsiveProperties().x,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: 'Edit',
          name: 'Position X',
        }),
      )
      .subscribe();
    });
    
    this.workAreaService.elementsSelected[0].RefreshParent();
    this.snackerService.ShowMessageOnBottom(
      'Representative molecules distributed horizontally',
      'format_line_spacing',
      5500,
      true,
      {
        text: 'Revert',
        icon: 'undo',
        callback: () => {
          
          sortedRepMolecules.forEach(repMolecule => {
            
            const previousPosition = previousPositions.find(pp => pp.id === repMolecule.Id);
            
            if (previousPosition) {
              repMolecule.ResponsiveProperties().x = previousPosition.x;
              repMolecule.SavePropertyFromVersioning(
                new PropertyVersioningDto({
                  elementId: repMolecule.Id.toString(),
                  property: 'x',
                  value: repMolecule.ResponsiveProperties().x,
                  path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                  change: 'Revert position change',
                  name: 'Position X',
                }),
              )
              .subscribe();
            }
          });
          
          sortedRepMolecules[0].RefreshParent();
        },
      });
  }
  
  OrderByHyp(repMolecules: IRepresentativeMolecule[]) {
    return repMolecules.sort((a, b) => Math.hypot(a.ResponsiveProperties().x, a.ResponsiveProperties().y) -
      Math.hypot(b.ResponsiveProperties().x, b.ResponsiveProperties().y));
  }
  
  OrderByY(repMolecules: IRepresentativeMolecule[]) {
    return repMolecules
    .sort((a, b) => a.ResponsiveProperties().y - b.ResponsiveProperties().y);
  }
  
  OrderByX(repMolecules: IRepresentativeMolecule[]) {
    return repMolecules
    .sort((a, b) => a.ResponsiveProperties().x - b.ResponsiveProperties().x);
  }
  
  OrderByMin(repMolecules: IRepresentativeMolecule[]) {
    const elements = repMolecules.map(rm => {
      return {
        x: rm.ResponsiveProperties().x,
        y: rm.ResponsiveProperties().y,
        xIndex: null,
        yIndex: null,
        id: rm.Id,
        index: null,
        hypIndex: null,
      };
    });
    
    const orderByY = this.OrderByY(repMolecules);
    
    orderByY.forEach((rm, index) => {
      const element = elements.find(e => e.id === rm.Id);
      element.yIndex = index;
    });
    
    const orderByX = this.OrderByX(repMolecules);
    
    orderByX.forEach((rm, index) => {
      const element = elements.find(e => e.id === rm.Id);
      element.xIndex = index;
    });
    
    const orderByHyp = this.OrderByHyp(repMolecules);
    
    orderByHyp.forEach((rm, index) => {
      const element = elements.find(e => e.id === rm.Id);
      element.hypIndex = index;
    });
    
    console.log(elements);
    const finalOrder = repMolecules
    .sort((a, b) =>
      (elements.find(e => e.id === a.Id).xIndex + elements.find(e => e.id === a.Id).yIndex + elements.find(
        e => e.id === a.Id).hypIndex) -
      (elements.find(e => e.id === b.Id).xIndex + elements.find(e => e.id === b.Id).yIndex + elements.find(
        e => e.id === b.Id).hypIndex));
    
    console.log(finalOrder);
    
    return finalOrder;
  }
  
  CopyRepMolecule(repMolecule: IRepresentativeMolecule) {
    this.contextMenu.closeMenu();
    this.workAreaService.cutRepMolecule = null;
    this.workAreaService.copyRepMolecule = repMolecule;
    this.snackerService.ShowMessageOnBottom('Representative molecule copied', 'content_copy', null, true);
  }
  
  CutRepMolecule(repMolecule: IRepresentativeMolecule) {
    this.contextMenu.closeMenu();
    this.workAreaService.copyRepMolecule = null;
    this.workAreaService.cutRepMolecule = repMolecule;
    this.snackerService.ShowMessageOnBottom('Representative molecule cut', 'content_cut', null, true);
  }
  
  PasteRepMolecule(event?: MouseEvent, repMolecule?: IRepresentativeMolecule) {
    this.contextMenu.closeMenu();
    
    const position = {
      x: 4,
      y: 4,
    };
    
    if (event) {
      const gridsterEl = document.querySelector(
        `#gridsterItem-${ repMolecule.Id } .workgroup-gridster`) as any;
      const curWidth = gridsterEl.offsetWidth;
      const curHeight = gridsterEl.offsetHeight;
      const curColWidth = curWidth / repMolecule.ResponsiveProperties().cols;
      const curRowHeight = curHeight / repMolecule.ResponsiveProperties().rows;
      
      const left = this.workAreaService.contextMenuPosition.event.offsetX;
      const top = this.workAreaService.contextMenuPosition.event.offsetY;
      position.x = Math.round(left / curColWidth);
      position.y = Math.round(top / curRowHeight);
    }
    
    if (this.workAreaService.cutRepMolecule) {
      // same parent
      if (this.workAreaService.cutRepMolecule.ParentId === repMolecule.Id) {
        this.dragService.dragBetweenWG.repMoleculeIdDragged = this.workAreaService.cutRepMolecule.Id;
        this.builderService.DuplicateProcess(this.workAreaService.cutRepMolecule, position, repMolecule);
        this.moleculeManagmentService.RemoveRepresentativeMolecule(this.workAreaService.cutRepMolecule.Id);
      } else {
        this.dragService.dragBetweenWG.repMoleculeIdDragged = this.workAreaService.cutRepMolecule.Id;
        this.dragService.SwapRepMolecule(
          this.workAreaService.cutRepMolecule,
          this.busService.Get(this.workAreaService.cutRepMolecule.ParentId),
          repMolecule,
          position.x,
          position.y,
        );
      }
      this.workAreaService.cutRepMolecule = null;
    } else {
      this.workAreaService.cutRepMolecule = null;
      this.dragService.dragBetweenWG.repMoleculeIdDragged = this.workAreaService.copyRepMolecule.Id;
      
      if (repMolecule.Id === this.workAreaService.copyRepMolecule.Id) {
        repMolecule = this.busService.GetParent(this.workAreaService.copyRepMolecule.Id);
        this.communicationService.Event.Editor.$DeselectAllRepresentativeMolecule.emit();
      }
      
      this.builderService.DuplicateProcess(this.workAreaService.copyRepMolecule, position, repMolecule);
    }
    
    this.snackerService.ShowMessageOnBottom('Representative molecule pasted', 'content_paste_go', null, true);
  }
  
  MoveToView(view: any, repMolecule: IRepresentativeMolecule) {
    this.communicationService.Event.Editor.Views.$SwapRepMoleculeView.emit({
      repMoleculeId: repMolecule.Id,
      viewId: view.id,
    });
  }
  
  OpenHelp(event?: any, repMolecule?: IRepresentativeMolecule) {
    this.toolsService.DragWindowConfig = this.editorStateService.WindowSize('LibraryMoleculeDocumentation');
    
    this.workAreaService.userGuideId = repMolecule.Type;
    this.workAreaService.userGuideType = 'Molecule';
    
    if (this.workAreaService.userGuideWindow) {
      this.communicationService.Event.Documentation.$Get.emit();
    } else {
      this.workAreaService.userGuideWindow = this.draggableWindowService.GenerateWindow(
        DocumentationComponent, {
          title: `Microlearning`,
          data: {
            id: repMolecule.Type,
            type: 'Molecule',
          },
        });
      
      this.dragableWindowManagerService.ShowWindowAndStore(this.workAreaService.userGuideWindow);
    }
  }
  
  ReplaceRepMolecule(type: string, repMolecule: IRepresentativeMolecule) {
    this.contextMenu.closeMenu();
    const moleculeTemplate = this.templateService.GetMoleculeTemplateByMoleculeType(type);
    moleculeTemplate['dragType'] = DragType.Molecule;
    
    this.communicationService.Event.Editor.WorkArea.$AddRepMolecule.emit({
      parent: repMolecule,
      moleculeTemplate,
      event: this.workAreaService.contextMenuPosition.event,
      position: null,
      properties: [],
    });
    
    this.snackerService.ShowMessageOnBottom('Representative molecule replaced', 'swap_horizontal_circle',
      null, true);
  }
  
  AddWorkGroup(event: MouseEvent) {
    const moleculeTemplate = this.templateService.GetMoleculeTemplateByMoleculeType('WorkGroup');
    moleculeTemplate['dragType'] = DragType.Molecule;
    this.dragService.dragData = moleculeTemplate;
    
    const left = this.workAreaService.contextMenuPosition.event.offsetX;
    const top = this.workAreaService.contextMenuPosition.event.offsetY;
    
    this.onDrop(event, {
      cols: 1,
      rows: 1,
      x: Math.round(left / 4),
      y: Math.round(top / 4),
    });
    
    setTimeout(() => {
      this.snackerService.ShowMessageOnBottom('WorkGroup Added', 'add_circle', null, true);
    }, 700);
  }
  
  AddRepMolecule(type: string, repMolecule?: IRepresentativeMolecule, event?: MouseEvent) {
    this.contextMenu.closeMenu();
    
    const position = {
      cols: 1,
      rows: 1,
      x: 0,
      y: 0,
    };
    
    if (repMolecule) {
      const gridsterEl = document.querySelector(
        `#gridsterItem-${ repMolecule.Id } .workgroup-gridster`) as any;
      const curWidth = gridsterEl.offsetWidth;
      const curHeight = gridsterEl.offsetHeight;
      const curColWidth = curWidth / repMolecule.ResponsiveProperties().cols;
      const curRowHeight = curHeight / repMolecule.ResponsiveProperties().rows;
      
      const left = this.workAreaService.contextMenuPosition.event.offsetX;
      const top = this.workAreaService.contextMenuPosition.event.offsetY;
      position.x = Math.round(left / curColWidth);
      position.y = Math.round(top / curRowHeight);
    } else if (event) {
      position.x = event.offsetX;
      position.y = event.offsetY;
    }
    
    const moleculeTemplate = this.templateService.GetMoleculeTemplateByMoleculeType(type);
    moleculeTemplate['dragType'] = DragType.Molecule;
    
    this.communicationService.Event.Editor.WorkArea.$AddRepMolecule.emit({
      parent: repMolecule,
      moleculeTemplate,
      event: this.workAreaService.contextMenuPosition.event,
      position,
      properties: [],
    });
  }
  
  DuplicateRepMolecule(repMolecule: IRepresentativeMolecule) {
    this.contextMenu.closeMenu();
    this.builderService.Duplicate(repMolecule);
  }
  
  HideRepMolecule(repMolecule: IRepresentativeMolecule) {
    this.contextMenu.closeMenu();
    
    if (this.workAreaService.elementsSelected.length > 1) {
      this.workAreaService.elementsSelected.forEach(es => {
        es.ToggleVisibilityFromEditorLayer(false);
      });
    } else {
      repMolecule.ToggleVisibilityFromEditorLayer(false);
    }
    
    this.snackerService.ShowMessageOnBottom('Representative molecule hidden', 'visibility_off');
  }
  
  OpenHistory(event?: MouseEvent, repMolecule?: IRepresentativeMolecule) {
    this.contextMenu.closeMenu();
    
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    
    this.toolsService.mousePosition = {
      x: event ? event.clientX : 600,
      y: event ? event.clientY : 200,
    };
    this.versioningService.openDialog(repMolecule.Id);
  }
  
  CreateComponent(event?: MouseEvent, repMolecule?: IRepresentativeMolecule) {
    this.contextMenu.closeMenu();
    this.workAreaService.SelectRepresentativeMolecule(repMolecule);
    
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    this.draggableWindowService.OpenDraggableWindow(
      'Create Component',
      DraggableWindowType.CreateComponent,
      event,
    );
  }
  
  ShowMoleculesPanel(event?: MouseEvent, repMolecule?: IRepresentativeMolecule) {
    this.contextMenu.closeMenu();
    if (this.workAreaService.elementsSelected.length <= 1) {
      this.workAreaService.SelectRepresentativeMolecule(repMolecule);
    }
    
    this.draggableWindowService.OpenDraggableWindow(
      `${ repMolecule.Properties.name } - ${ repMolecule.Type } Process Panel`,
      DraggableWindowType.ProcessPanel,
      event,
      null,
    );
  }
  
  OpenWebEditor(event?: MouseEvent, repMolecule?: IRepresentativeMolecule) {
    this.contextMenu.closeMenu();
    this.draggableWindowService.OpenDraggableWindow('Custom Control Editor',
      DraggableWindowType.CustomControlEditor,
      null, {
        repMolecule: repMolecule,
      });
  }
  
  ShowMoleculeProperties(event?: any, repMolecule?: IRepresentativeMolecule) {
    this.contextMenu.closeMenu();
    
    this.workAreaService.SelectRepresentativeMolecule(repMolecule, false);
    setTimeout(() => {
      this.draggableWindowService.OpenDraggableWindow(
        `${ repMolecule.Type } - ${ repMolecule.Properties.name } Properties`,
        DraggableWindowType.RepresentativeProperties,
        event,
        null,
      );
    }, 50);
  }
  
  DistributeHorizontallyf() {
    const firstES = this.workAreaService.firstElementSelected;
    const secondES = this.workAreaService.secondElementSelected;
    const space = secondES.ResponsiveProperties().x - (firstES.ResponsiveProperties().x + firstES.ResponsiveProperties().cols);
    
    if (space <= 0) {
      this.snackerService.ShowMessageOnBottom('Negative space');
      return;
    }
    this.workAreaService.elementsSelected
    .sort((a, b) => a.ResponsiveProperties().x - b.ResponsiveProperties().x)
    .forEach((es, index) => {
      if (es.Id === firstES.Id || es.Id === secondES.Id) {
        return;
      }
      es.ResponsiveProperties().x =
        this.workAreaService.elementsSelected[index - 1].ResponsiveProperties().x +
        this.workAreaService.elementsSelected[index - 1].ResponsiveProperties().rows +
        space;
      
      es.SavePropertyFromVersioning(
        new PropertyVersioningDto({
          elementId: es.Id.toString(),
          property: 'x',
          value: es.ResponsiveProperties().x,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: 'Edit',
          name: 'Position X',
        }),
      )
      .subscribe();
    });
    
    firstES.RefreshParent();
    
    this.snackerService.ShowMessageOnBottom('Representative molecules distributed horizontally',
      'format_letter_spacing', null, true);
  }
  
  NavigateView(view: View) {
    this.communicationService.Event.Editor.Views.$SwitchView.emit(view.id);
    this.snackerService.ShowMessageOnBottom(`View ${ this.workAreaService.ActualView.name }`, 'layers');
  }
  
  DragEnd(event: MouseEvent) {
    this.draggingWorkarea = false;
    this.workareaEl.style.cursor = 'grab';
  }
  
  DragStart(event: MouseEvent) {
    if (!this.workAreaService.editorPreferences.zoom || event.ctrlKey) {
      return false;
    }
    
    this.draggingWorkarea = true;
    this.workareaEl.style.cursor = 'grabbing';
    
    this.dragStartX = event.x - this.workareaEl.offsetLeft;
    this.dragStartY = event.y - this.workareaEl.offsetTop;
    this.previousScrollLeft = this.workareaEl.scrollLeft;
    this.previousScrollTop = this.workareaEl.scrollTop;
  }
  
  DragCanvas(event: MouseEvent) {
    if (this.draggingWorkarea) {
      requestAnimationFrame(() => {
        const currentX = event.x - this.workareaEl.offsetLeft;
        const currentY = event.y - this.workareaEl.offsetTop;
        
        const scrollX = this.previousScrollLeft + (this.dragStartX - currentX);
        const scrollY = this.previousScrollTop + (this.dragStartY - currentY);
        
        this.workareaEl.scroll(scrollX, scrollY);
      });
    }
  }
  
  private refreshChildren() {
    // console.log('refresh children');
    
    // console.log('views', this.cobbleService.Cobble.properties.views);
    // const lockedView = this.cobbleService.Cobble.properties.views.find(
    //   (v) => v.locked
    // );
    
    if (this.workAreaService.ActualView) {
      this.children = this.busService
      .GetViewWorkgroupsAndStepperMolecules(
        this.cobbleService.Cobble,
        this.workAreaService.ActualView.id,
        this.workAreaService.editorPreferences.displayLockViewElements,
      )
      .filter(repMol => repMol.EditorVisible);
    } else {
      this.children = [];
    }
    
    console.log('children', this.children);
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    this.ref.markForCheck();
    this.eventsService.CreateEventsTable();
    setTimeout(() => {
      this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
      this.ref.markForCheck();
    }, 200);
  }
}
