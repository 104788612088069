import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { GenerationAppWizardComponent } from '../generation-app-wizard/generation-app-wizard.component';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-initial-assist',
  templateUrl: './initial-assist.component.html',
  styleUrls: ['./initial-assist.component.scss'],
})
export class InitialAssistComponent implements OnInit {
  
  constructor(private clientStorageService: ClientStorageService,
              private workAreaService: WorkAreaService,
              private communicationService: CommunicationService,
              private dialog: MatDialog) {
  }
  
  ngOnInit(): void {
  }
  
  CloseWindow() {
    this.workAreaService.initialAssistPageWindow.Hide();
    this.workAreaService.initialAssistPageWindow = null;
  }
  
  OpenSmartTemplates() {
    this.communicationService.Event.Editor.$OpenSmartTemplateWizard.emit();
    this.CloseWindow();
  }
  
  OpenMicrolearning() {
    this.communicationService.Event.Editor.$OpenMicrolearning.emit();
    this.CloseWindow();
  }
  
  OpenAppGeneration() {
    this.dialog.open(GenerationAppWizardComponent, {
      height: '650px',
      width: '700px',
      disableClose: false,
      panelClass: 'custom-dialog-container',
      data: [],
    });
    
    setTimeout(() => {
      this.communicationService.Event.Editor.DataSource.$ApiNodesSelected.emit([]);
    }, 200);
    this.CloseWindow();
  }
  
  DoNotShow(e: any) {
    const condition = e.checked ? 'N' : 'Y';
    this.clientStorageService.setInitialAssistPage(condition);
  }
}
