import { DatasourceType } from '../../../shared/enums/datasource-type.enum';
import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class UpdateDatasourceDataMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(particleId: string, repMoleculeId: string, busProcessorKey: string, rule: any, logic: any, data: any) {
    try {
      const dataItems = [];
      
      console.log('update molecule');
      
      if (this.ExistsRepMoleculeAttached) {
        data = this.RepMoleculeAttached.GetValue;
      }
      
      // if (this.DataElements.length === 0) {
      //   const repMolecule = this.busService.Get(repMoleculeId);
      //   const buses = repMolecule.GetBusByReceptorAndMoleculeNameType(['value-input'], 'FilterByDataElementReferenceMolecule');
      //
      //   if (buses.length > 0) {
      //     const takeDataElementsMolecule = buses[0].GetActionMoleculeParticle('FilterByDataElementReferenceMolecule');
      //     this.DataElements = takeDataElementsMolecule.DataElements;
      //   }
      // }
      
      data = data === undefined || data === null || data === {} ? '' : data;
      
      let odePriority = false;
      
      if (this.OriginalDataElements.length > 0 &&
        this.OriginalDataElements[0].DataSourceType === DatasourceType.Spreadsheet) {
        const de = this.OriginalDataElements[0];
        const bk = this.toolsService.BreakContext(de.Context);
        
        odePriority = bk.row > 0;
      }
      
      const dataElements = odePriority ? this.OriginalDataElements : this.DataElements;
      
      switch (this.toolsService.GetObjectType(data)) {
        case 'object':
          // console.log('object');
          dataElements.forEach(de => {
            dataItems.push(this.CreateDataItem(de, repMoleculeId, data));
          });
          break;
        case 'array':
          // console.log('array');
          
          dataElements.forEach(de => {
            if (de.DataSourceType === DatasourceType.LocalDataStore) {
              dataItems.push(this.CreateDataItem(de, repMoleculeId, data));
            } else {
              data.forEach(d => {
                dataItems.push(this.CreateDataItem(de, repMoleculeId, d));
              });
            }
          });
          
          // data.forEach((d) => {
          //   this.DataElements.forEach((de) => {
          //     if (de.DataSourceType === DatasourceType.LocalDataStore) {
          //       dataItems.push(this.CreateDataItem(de, repMoleculeId, data));
          //     } else {
          //       dataItems.push(this.CreateDataItem(de, repMoleculeId, d));
          //     }
          //   });
          // });
          break;
        case 'number':
        case 'string':
          // console.log('string');
          dataElements.forEach(de => {
            dataItems.push(this.CreateDataItem(de, repMoleculeId, data));
          });
          break;
        default:
          break;
      }
      
      // console.log('data items', dataItems);
      
      const subscription = this.communicationService.Event.Runtime.Data.$ModifyDataResult.subscribe(response => {
        // console.log('$ModifyDataResult');
        console.log('=event=');
        if (response.keys && response.keys.includes(repMoleculeId)) {
          subscription.unsubscribe();
          
          let responseDataElements = response.dataElements;
          
          //if add to local data store return same dataelements
          if (this.DataElements.length === 1 && this.DataElements[0].DataSourceType === DatasourceType.LocalDataStore) {
            responseDataElements = data;
          }
          
          this.Done(particleId, busProcessorKey, repMoleculeId, responseDataElements);
        }
      });
      this.dataSourcesService.UpdateDataItemsForDataSource(dataItems, repMoleculeId, this.busService.Get(repMoleculeId), this.EventKey);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
