export const iframeTemplate = [
  {
    icon: 'preview',
    moleculeType: 'Representative',
    name: 'Iframe',
    type: 'Iframe',
    moleculesAllow: ['DataAction', 'Behavior', 'Configuration', 'Compounds', 'Representative'],
    buses: [],
    debounce: {
      events: [],
    },
    minValue: null,
    maxValue: null,
    creationFromTemplate: false,
    dataSource: {},
    styleMetadata: {
      styles: [],
      specificStyles: {
        dimension: null,
        font: null,
        hover: null,
        frame: null,
        appearance: null,
      },
      manualAdjustedProperties: [],
    },
    receptors: ['value-input', 'value-output', 'badge-value-input', 'badge-value-output', 'none'],
    events: ['init', 'completed', 'success', 'next', 'update', 'save', 'delete', 'cancel', 'error'],
    defaultEvent: 'keyup',
    editableProperties: ['cols', 'rows', 'x', 'y', 'tabindex', 'opacity', 'backgroundType'],
    properties: {
      align: 'left', horizontalPadding: 0,
      verticalPadding: 0,
      progressType: 'progress',
      progressColor: '#EA5C2F',
      progressBackgroundColor: '#E5957C',
      progressDiameter: 50,
      progressMode: 'indeterminate',
      progressStrokeWidth: 5,
      wrapText: false,
      allowManualEntry: false,
      alwaysRenderComponent: false,
      sliderVertical: false,
      sliderStep: 1,
      sliderThumbLabel: false,
      sliderTicks: false,
      sliderColor: '#EA5C2F',
      viewNames: [],
      location: null,
      allowEmptyValues: false,
      hover: {
        hoverBackground: 'transparent',
        hoverBorderRadius: 5,
        hoverBorderStyle: 'none',
        hoverBorderColor: 'black',
        hoverBorderWidth: 2,
        hoverFontColor: 'black',
      },
      badge: {
        badgeEnable: true,
        badgeBackgroundColor: '#f22525',
        badgeFontFamily: 'Source Sans Pro',
        badgeFontColor: 'white',
        badgeStyle: 'circle',
        badgePosition: 'right',
      },
      options: [
        {
          value: 'value 1',
          text: 'Text 1',
        },
      ],
      maskType: 'none',
      mask: '',
      show: true,
      opacity: 1,
      orientation: 'col',
      enable: true,
      altText: '',
      dateRange: false,
      search: false,
      multiSelect: false,
      autoDataRefresh: false,
      ignoreValueDataIndex: false,
      textDecoration: 'none',
      source: '',
      name: 'Iframe',
      legend: true,
      loadingText: '',
      background: {
        backgroundColor: '#ffffff',
        backgroundTypeImage: false,
        backgroundImageUrl: '',
        backgroundOpacity: 1,
      },
      bordersValues: {
        borderWidth: 1,
        borderStyle: 'none',
        borderRadius: 5,
        borderColor: '#D3D5D9',
      },
      icon: {
        iconType: 'stars',
        iconColor: 'black',
        iconSize: 45,
      },
      shadowValues: {
        hShadow: 0,
        vShadow: 0,
        shadowColor: '#212529',
        shadowBlur: 0,
      },
      preferences: {},
      view: 1,
      chartLibrary: 'ngxCharts',
      chartType: 'line',
      tabindex: 0,
      textToDisplay: 'Enter Text',
      tooltip: '',
      placeholder: 'Enter Text',
      tableOrder: [],
      minItemCols: 2,
      maxItemCols: 2000,
      responsive: {
        desktop: {
          customGuidelines: {
            x: [],
            y: [],
          },
          id: 0,
          cols: 100,
          rows: 100,
          colsQty: 0,
          rowsQty: 0,
          layer: 1,
          centerPositioning: false,
          x: 0,
          y: 0,
          font: {
            fontSize: 14,
            fontColor: '#000000',
            fontFamily: 'inherit',
            fontStyle: 'inherit',
            fontWeight: 'normal',
          },
          tableOptions: {
            collapse: true,
            bodyRoundCorners: false,
            search: true,
            header: true,
            verticallyCenter: true,
            rowSeparator: false,
            columnSeparator: false,
            rowPadding: true,
            tableOrder: [],
            tableWidth: {},
          },
        },
        smartphone: {
          customGuidelines: {
            x: [],
            y: [],
          },
          id: null,
          cols: null,
          rows: null,
          colsQty: null,
          rowsQty: null,
          layer: null,
          centerPositioning: false,
          x: null,
          y: null,
          font: {
            fontSize: null,
            fontColor: null,
            fontFamily: null,
            fontStyle: null,
            fontWeight: null,
          },
          tableOptions: {
            search: true,
            header: true,
            verticallyCenter: null,
            rowSeparator: null,
            columnSeparator: null,
            rowPadding: null,
            tableOrder: [],
            tableWidth: {},
          },
        },
      },
    },
    molecules: [],
    placeholders: [],
    isSelected: false,
    enable: true,
    value: null,
    children: [],
    replaceableByRepresentative: true,
    loading: false,
    autoGenerationInfo: [],
    moleculeSubType: 'Iframe',
    optionsMenu: {
      configuration: true,
      molecules: true,
      compound: true,
      cobblet: true,
      remove: true,
      versioning: true,
      dataSource: true,
      duplicate: true,
    },
  },
];
