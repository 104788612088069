import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { of, Subject } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { debounceTime, delay, flatMap, map } from 'rxjs/operators';
import { Constants } from '../../shared/constants';
import { IRepresentativeMolecule } from '../../shared/representative-molecule/interfaces/representative-molecule.interface';

@Component({
  selector: 'app-select-icon',
  templateUrl: './select-icon.component.html',
  styleUrls: ['./select-icon.component.scss']
})
export class SelectIconComponent implements OnInit {
  @Input() selectedIcon: string;
  @Input() hasStyleApplied: boolean = false;
  @Input() isMoleculeProperty: boolean = false;
  @Input() repMolecule: IRepresentativeMolecule = null;
  @Input() manualAdjustedProperty = null;
  @Output() iconChange: EventEmitter<string> = new EventEmitter<string>();
  iconsList = [];
  public iconSearch = new Subject<any>();
  subscriptions = new Subscription;

  constructor(
    private ref: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.filterIcons('');
    this.subscriptions.add(
      this.iconSearch
      .pipe(
        map(event => event.target.value),
        debounceTime(150),
        flatMap(search => of(search).pipe(delay(50))),
      ).subscribe(value => {
        this.filterIcons(value);
      })
    );
  }
  
  SelectIcon(icon: string) {
    this.iconChange.emit(icon);
  }
  
  private filterIcons(icon: string) {
    if (icon === '') {
      this.iconsList = Constants.IconsLibrary;
      this.ref.markForCheck();
      return;
    }
    
    const iconName = (icon as any).replaceAll(' ', '_') as string;
    this.iconsList = Constants.IconsLibrary.filter(i => i.includes(iconName));
    this.ref.markForCheck();
  }
  
  GetIconName(icon: string) {
    return (icon as any).replaceAll('_', ' ') as string;
  }
}
