import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class PaginateDataMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    data?: any,
    dataElementsData?: any,
  ) {
    console.log('paginated');
    try {
      const pagination =
        rule.paginate ||
        this.toolsService.ExtractValuesByType(data).number ||
        50;
      
      const repMolecule = this.busService.Get(repMoleculeId);
      
      if (!repMolecule.PageChanged) {
        repMolecule.ValueSource = data;
      }
      
      
      if (!repMolecule.PageChanged && data && this.toolsService.ExtractValuesByType(data).array) {
        if (this.toolsService.GetObjectType(data) === 'array') {
          const paginatedData = this.toolsService.PaginateData(this.toolsService.ExtractValuesByType(data).array, pagination, repMolecule.PageNumber);
          
          data = paginatedData.data;
          
          this.AssignPaginationMetadata(repMolecule, paginatedData);
        }
        
      } else if (repMolecule.PageChanged && repMolecule.ValueSource && this.toolsService.GetObjectType(repMolecule.ValueSource) === 'array') {
        const paginatedData = this.toolsService.PaginateData(repMolecule.ValueSource, pagination, repMolecule.PageNumber);
        data = paginatedData.data;
        this.AssignPaginationMetadata(repMolecule, paginatedData);
      }
      
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    } catch (error) {
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  private AssignPaginationMetadata(repmolecule: IRepresentativeMolecule, paginationData: any) {
    repmolecule.ValueMetaData = {
      TranslationIds: [],
      Contexts: [],
      SearchFilter: null,
      Offset: null,
      Limits: null,
      Value: paginationData.value,
      OperationType: 'row',
      PageCount: paginationData.pageCount,
      FilterIndex: 0,
      FilterValue: '',
      PageNumber: paginationData.pageNumber,
      PageSize: paginationData.pageSize,
      TotalItemCount: paginationData.totalItems,
    };
  }
}
