import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DisplayGrid, GridsterItem, GridsterItemComponentInterface } from '@leapxl/gridster';
import IMask from 'imask';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AppInjector } from '../../../../app-injector';
import { BuilderService } from '../../../../core/builder/builder.service';
import { Receptor } from '../../../../core/molecular/receptors.enum';
import { BusService } from '../../../../core/molecular/services/bus.service';
import { EventsService } from '../../../../core/molecular/services/events.service';
import {
  MolecularEngineConnectorService,
} from '../../../../core/molecular/services/molecular-engine-connector.service';
import { ProcessorService } from '../../../../core/molecular/services/processor.service';
import { ApiDataSourcesService } from '../../../../core/services/api-data-sources.service';
import { ApiDataTranslatorService } from '../../../../core/services/api-data-translator.service';
import { ApiMoleculesService } from '../../../../core/services/api-molecules.service';
import { ApiPropertiesService } from '../../../../core/services/api-properties.service';
import { DevToolsService } from '../../../../core/services/dev-tools.service';
import { EditorStateService } from '../../../../core/services/editor-state.service';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { MoleculeManagmentService } from '../../../../core/services/molecule-managment.service';
import { TemplateService } from '../../../../core/services/template.service';
import { ToolsService } from '../../../../core/services/tools.service';
import { RuntimeService } from '../../../../running-area/services/runtime.service';
import { SpreadsheetService } from '../../../../spreadsheet/spreadsheet.service';
import {
  BottomSheetOptionsComponent,
} from '../../../../workarea/bottom-sheet/bottom-sheet-options/bottom-sheet-options.component';
import { BottomSheetService } from '../../../../workarea/bottom-sheet/bottom-sheet.service';
import { DataQualityDialogService } from '../../../../workarea/data-quality/data-quality-dialog.service';
import { BottomSheetOption } from '../../../../workarea/interfaces/bottom-sheet-option.interface';
import { WorkAreaService } from '../../../../workarea/workarea.service';
import { Constants } from '../../../constants';
import { PropertyVersioningDto } from '../../../dtos/versioning-dto';
import { DatasourceType } from '../../../enums/datasource-type.enum';
import { DragType } from '../../../enums/drag-type.enum';
import { LeapXLEventType } from '../../../enums/leapxl-event-type.enum';
import { MoleculesType } from '../../../enums/molecules-type.enum';
import { ParticleType } from '../../../enums/particle-type.enum';
import { Placeholders } from '../../../enums/placeholders.enum';
import { RepresentativeMoleculeTypeIcons } from '../../../enums/representative-molecule-type-icons';
import { RepresentativeMoleculesType } from '../../../enums/representative-molecules-types.enum';
import { CommunicationService } from '../../../services/communication.service';
import { ConnectionStateService } from '../../../services/connection-state.service';
import { DraggableWindowManagerService } from '../../../services/draggable-window-manager.service';
import { DraggableWindowService, DraggableWindowType } from '../../../services/draggable-window.service';
import { FactoryParticleService } from '../../../services/factory-particle.service';
import { FactoryService } from '../../../services/factory.service';
import { SnackerService } from '../../../services/snacker.service';
import { ActionMolecule } from '../../interfaces/action-molecules';
import { Bus } from '../../interfaces/bus';
import { DataElement } from '../../interfaces/data-element';
import { LeapXLEvent } from '../../interfaces/leapxl-event';
import { RepresentativeMolecule } from '../../interfaces/representative-molecule';
import { IRepresentativeMolecule } from '../../interfaces/representative-molecule.interface';
import { CobbleService } from '../../services/cobble.service';
import { DragService } from '../../services/drag.service';

@Directive()
export abstract class BaseMoleculeComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  public context: IRepresentativeMolecule;
  
  //region SERVICES
  builderService: BuilderService;
  dragService: DragService;
  workAreaService: WorkAreaService;
  toolsService: ToolsService;
  processorService: ProcessorService;
  templateService: TemplateService;
  bottomSheetService: BottomSheetService;
  spreadSheetService: SpreadsheetService;
  communicationService: CommunicationService;
  dataQualityDialogService: DataQualityDialogService;
  busService: BusService;
  cobbleService: CobbleService;
  dataSourcesService: ApiDataSourcesService;
  connectionStateService: ConnectionStateService;
  propertiesService: ApiPropertiesService;
  draggableWindowManagerService: DraggableWindowManagerService;
  draggableWindowService: DraggableWindowService;
  dataTranslatorService: ApiDataTranslatorService;
  moleculesService: ApiMoleculesService;
  moleculeMangementService: MoleculeManagmentService;
  particlesFactoryService: FactoryParticleService;
  factoryService: FactoryService;
  eventsService: EventsService;
  runtimeService: RuntimeService;
  snackerService: SnackerService;
  localStorageService: LocalStorageService;
  editorStateService: EditorStateService;
  molecularEngineConnectorService: MolecularEngineConnectorService;
  devToolsService: DevToolsService;
  //endregion services
  
  bottomSheet: MatBottomSheet;
  disableHover = false;
  isHoverVisible = false;
  gradientTop: number;
  gradientLeft: number;
  gradientRadius: number;
  Math = Math;
  showAltText = false;
  refreshingText = false;
  replacedMoleculeId = 0;
  Subscriptions: Subscription;
  bottomSheetSubscription: Subscription;
  bottomsheetAnimationTimeout = 500;
  public;
  IsDebug = false;
  destroy$ = new Subject();
  DataAssociationDefinitions: any = {};
  IsEditor = false;
  protected inDebounce = null;
  
  constructor(bottomSheet: MatBottomSheet, public el: ElementRef<HTMLElement>, private changeDetectorRef: ChangeDetectorRef) {
    const injector = AppInjector.getInjector();
    this.builderService = injector.get(BuilderService);
    this.dragService = injector.get(DragService);
    this.workAreaService = injector.get(WorkAreaService);
    this.processorService = injector.get(ProcessorService);
    this.bottomSheetService = injector.get(BottomSheetService);
    this.spreadSheetService = injector.get(SpreadsheetService);
    this.busService = injector.get(BusService);
    this.cobbleService = injector.get(CobbleService);
    this.snackerService = injector.get(SnackerService);
    this.dataSourcesService = injector.get(ApiDataSourcesService);
    this.moleculesService = injector.get(ApiMoleculesService);
    this.communicationService = injector.get(CommunicationService);
    this.dataTranslatorService = injector.get(ApiDataTranslatorService);
    this.dataQualityDialogService = injector.get(DataQualityDialogService);
    this.propertiesService = injector.get(ApiPropertiesService);
    this.connectionStateService = injector.get(ConnectionStateService);
    this.toolsService = injector.get(ToolsService);
    this.templateService = injector.get(TemplateService);
    this.draggableWindowService = injector.get(DraggableWindowService);
    this.draggableWindowManagerService = injector.get(DraggableWindowManagerService);
    this.moleculeMangementService = injector.get(MoleculeManagmentService);
    this.particlesFactoryService = injector.get(FactoryParticleService);
    this.factoryService = injector.get(FactoryService);
    this.eventsService = injector.get(EventsService);
    this.localStorageService = injector.get(LocalStorageService);
    this.runtimeService = injector.get(RuntimeService);
    this.molecularEngineConnectorService = injector.get(MolecularEngineConnectorService);
    this.editorStateService = injector.get(EditorStateService);
    this.devToolsService = injector.get(DevToolsService);
    this.bottomSheet = bottomSheet;
    
    if (this.cobbleService.Cobble.running) {
      this.EnableHover();
    }
    
    this.IsDebug = this.localStorageService.IsDebug();
    this.IsEditor = !this.cobbleService.Cobble.running;
  }
  
  get gradientStyle() {
    const top = this.gradientTop;
    const left = this.gradientLeft;
    const gradientRadius = this.isHoverVisible ? 50 : 0;
    
    return {
      position: 'absolute',
      'height.px': gradientRadius,
      'width.px': gradientRadius,
      'top.px': top,
      'left.px': left,
      zIndex: 9999,
      background: 'radial-gradient(circle closest-side, #9b27af, transparent)',
      transition: 'width 0.2s ease, height 0.2s ease',
    };
  }
  
  get hoverStyle() {
    let opacity = 'FF';
    if (this.context.Properties.hover.hoverBackgroundOpacity) {
      const i = Math.round(this.context.Properties.hover.hoverBackgroundOpacity * 100) / 100;
      const alpha = Math.round(i * 255);
      opacity = (alpha + 0x10000).toString(16)
      .substr(-2);
    }
    return {
      background: this.isHoverVisible
        ? `${ this.context.Properties.hover.hoverBackground }${ opacity }`
        : this.context.Properties.background.backgroundColor,
      // background: this.isHoverVisible
      //        ? `${this.context.Properties.hover.hoverBackground}${opacity}`
      //        : this.context.Type === RepresentativeMoleculesType.Icon
      //          ? 'transparent'
      //          : this.context.Properties.background.backgroundColor,
      
      'borderRadius.px': this.isHoverVisible ? this.context.Properties.hover.hoverBorderRadius : this.context.Properties.bordersValues.borderRadius,
      
      borderStyle: this.isHoverVisible ? this.context.Properties.hover.hoverBorderStyle : this.context.Properties.bordersValues.borderStyle,
      
      borderColor: this.isHoverVisible ? this.context.Properties.hover.hoverBorderColor : this.context.Properties.bordersValues.borderColor,
      
      textDecoration:
        this.isHoverVisible && this.context.Properties.hover.hoverTextDecoration
          ? this.context.Properties.hover.hoverTextDecoration
          : this.context.Properties.textDecoration,
      
      opacity: this.context.Properties.background.backgroundOpacity,
      
      'borderWidth.px': this.isHoverVisible ? this.context.Properties.hover.hoverBorderWidth : this.context.Properties.bordersValues.borderWidth,
      
      color: this.isHoverVisible
        ? (this.context.Properties.hover.hoverFontColor || (this.context.Properties.hover as any).hoverfontColor)
          ? (this.context.Properties.hover.hoverFontColor || (this.context.Properties.hover as any).hoverfontColor)
          : this.context.ResponsiveProperties().font.fontColor
        : this.context.ResponsiveProperties().font.fontColor,
      transitionProperty: 'background,border,color',
      transitionDuration: '0.2s',
      transitionTimingFunction: 'ease',
      // transition: 'all 0.2s ease',
    };
  }
  
  DetachChangeDetection() {
    // this.changeDetectorRef.detach();
  }
  
  onMouseEnter(e: MouseEvent) {
    // console.log('mouseneter');
    if (this.disableHover) {
      return;
    }
    
    if (!this.context.Properties.enable) {
      return;
    }
    
    // console.log('hover');
    this.isHoverVisible = true;
    if (this.IsDebug) {
      console.log('hover event disabled');
    } else {
      this.FireRepresentativeMoleculeEvent('hover');
    }
  }
  
  onMouseLeave() {
    // console.log('mouseleave');
    
    if (this.disableHover) {
      return;
    }
    this.isHoverVisible = false;
  }
  
  ngOnInit() {
    this.SetDataAssociationDefinitions();
    if (this.context.Initialized && this.context.DatasourceUpdated) {
      this.RunBusesToRefreshData();
      this.context.DatasourceUpdated = false;
    }
    
    this.communicationService.Event.System.App.$RefreshUI.emit(true);
    
    this.Init();
    
    this.FireInitEvents();
    
    // init repmolecules with data on editor, only charts and tables because performance
    if (!this.cobbleService.Cobble.running) {
      this.Subscriptions.add(
        this.communicationService.Event.Editor.$DropOnRepresentativeMolecule.subscribe((data) => {
          if (this.context.Id === data.repMoleculeId) {
            this.DataDropped(data.event);
          }
        }),
      );
      
      this.Subscriptions.add(
        this.communicationService.Event.Editor.WorkArea.$AddRepMolecule.subscribe((data) => {
          // console.log('dettaching wg');
          if (this.context.Id === data.parent.Id) {
            this.OnDrop(data.moleculeTemplate, data.event, data.position, data.properties);
          }
        }),
      );
      
      switch (this.context.Type) {
        case RepresentativeMoleculesType.Chart:
        case RepresentativeMoleculesType.Table:
          this.context.FireDataSourceBus();
          break;
      }
    }
    
    if (this.context.RunningMode) {
      this.AttachRunningEventListeners();
    } else {
      this.AttachEditorEventListeners();
    }
  }
  
  AttachRunningEventListeners() {
  }
  
  AttachEditorEventListeners() {
  }
  
  Init() {
    
    this.Subscriptions = this.context.$UpdateValue.pipe(
      debounceTime(this.cobbleService.Cobble.running ? 0 : 200))
    .pipe(takeUntil(this.destroy$))
    .subscribe((value) => {
      // console.log('renderized', this.context.Renderized, this.context.Id);
      console.log('update value');
      this.UpdateData();
    });
    
    this.Subscriptions.add(
      this.context.$FireEvent.pipe(takeUntil(this.destroy$))
      .subscribe((data: { event: string; value: any }) => {
        console.log('fire event');
        this.FireRepresentativeMoleculeEvent(data.event, data.value);
      }),
    );
    
    this.Subscriptions.add(
      this.context.$RefreshParentUI.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        console.log('refresh parent');
        this.communicationService.Event.Editor.$RefreshRepresentativeMoleculeUI.emit(this.context.ParentId);
      }),
    );
    
    this.Subscriptions.add(
      this.context.$Resized.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.Throttle(
          (func, delay, context2) => {
            this.RepMoleculeResized();
          },
          this.cobbleService.Cobble.running ? 0 : 30,
          this,
          null,
        );
      }),
    );
    
    this.Subscriptions.add(
      this.communicationService.Event.Editor.$RepresentativeMoleculeDetection
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: { repMoleculeId: number; state: boolean }) => {
        // console.warn('REP MOL DETECTION CHANGE', enable);
        
        if (data.repMoleculeId && data.repMoleculeId > 0) {
          if (data.repMoleculeId === this.context.Id) {
            if (data.state) {
              this.changeDetectorRef.reattach();
              this.changeDetectorRef.markForCheck();
              this.changeDetectorRef.detectChanges();
            } else {
              this.changeDetectorRef.detach();
            }
          }
        } else {
          if (data.state) {
            this.changeDetectorRef.reattach();
            this.changeDetectorRef.markForCheck();
            this.changeDetectorRef.detectChanges();
          } else {
            this.changeDetectorRef.detach();
          }
        }
      }),
    );
    
    this.Subscriptions.add(
      this.communicationService.Event.Runtime.System.$DataSourceCRUDUpdate.pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        
        if (!this.context.Properties.autoDataRefresh) {
          return;
        }
        
        const avoidDatasourceTypes = [DatasourceType.Custom, DatasourceType.System, DatasourceType.LeapXL,
          DatasourceType.LocalDataStore, DatasourceType.InternetMessaging];
        
        if (this.context.Type !== RepresentativeMoleculesType.Custom && this.context.Renderized && !!data.find(
          (d) => this.context.ContainsDataElementsWithContexts((d as any).contexts, [Receptor.ValueInput]))) {
          // avoid refreshing dropdown if data didn't modified their values
          if (this.context.Type === RepresentativeMoleculesType.Dropdown) {
            const buses = this.context.GetBusByReceptorAndMoleculeNameType([Receptor.OptionsListInput],
              'GetElementsDatasourceDataMolecule');
            
            let datasourceAffected = false;
            if (data.length > 0 && buses.length > 0) {
              const bus = buses[0];
              
              data[0].contexts.forEach((context) => {
                if (bus.ContainsSpecificContext(context)) {
                  datasourceAffected = true;
                }
              });
            }
            
            if (!datasourceAffected) {
              return;
            }
          }
          
          // to avoid loop with rep molecules sending data and receiving data
          let existsDataModification = false;
          const initBuses = this.context.GetBusesByEventType('init');
          
          initBuses.forEach((initBus) => {
            const initBusEvents = initBus.GetEvents();
            // initBusEvents.shift();
            const busesListening: Bus[] = [];
            
            initBusEvents.forEach((ibe) => {
              const busListening = this.context.GetBusInitiatedByEventName(ibe.EventName);
              
              if (busListening) {
                busesListening.push(busListening);
              }
            });
            
            const updatedContexts = (data.map((d) => d.contexts) as any).flat();
            
            // const contextAffected = [];
            //
            // updatedContexts.forEach(uc => {
            //   let context = uc.split(Constants.ContextSeparator);
            //   context.pop();
            //   context = context.join(Constants.ContextSeparator);
            //
            //   if (contextAffected.find(ca => ca.includes(context))) {
            //   } else {
            //     contextAffected.push(context);
            //   }
            // });
            
            busesListening.forEach((bl) => {
              if (
                bl.GetActionMoleculeParticle('AddToDatasourceMolecule') ||
                bl.GetActionMoleculeParticle('UpdateDatasourceDataMolecule') ||
                bl.GetActionMoleculeParticle('DeleteDatasourceDataMolecule')
              ) {
                updatedContexts.forEach((context) => {
                  if (context && (initBus.IncludeContext(context) || context.includes(
                    DatasourceType.Spreadsheet)) && !avoidDatasourceTypes.includes(
                    context.split(Constants.ContextSeparator)[0])) {
                    existsDataModification = true;
                  }
                });
              }
            });
          });
          //
          if (!existsDataModification && this.context.Properties.autoDataRefresh) {
            // console.log('$DataSourceCRUDUpdate');
            this.context.UnsetOldValue();
            this.RunBusesToRefreshData(...data.map(d => d.contexts));
          }
        }
      }),
    );
    
    this.Subscriptions.add(
      this.communicationService.Event.Editor.$RefreshRepresentativeMoleculeUI.subscribe((repMolculeId) => {
        if (this.context.Id === repMolculeId) {
          this.RefreshUI();
        }
      }),
    );
    
    this.Subscriptions.add(
      this.communicationService.Event.Editor.$RefreshRepresentativeMoleculeUI.subscribe((repMolculeId) => {
        if (this.context.Id === repMolculeId) {
          this.RefreshUI();
        }
      }),
    );
    
    this.context.Renderized = true;
  }
  
  RunBusesToRefreshData(avoidBusesWithContexts: string[] = []) {
    
    if (!this.context.Properties.autoDataRefresh) {
      return;
    }
    
    const initBusExists = this.context.GetBusByReceptorAndParticleEventType(Receptor.ValueInput,
      LeapXLEventType.Init);
    
    if (initBusExists) {
      const molecules = initBusExists.GetActionMoleculeParticlesByInternalName(
        'GetElementsDatasourceDataMolecule');
      if (molecules.length > 0) {
        this.FireRepresentativeMoleculeEvent(LeapXLEventType.Init);
      }
    } else {
      const reloadBusExists = this.context.GetBusByReceptorAndParticleEventType(Receptor.ValueInput,
        LeapXLEventType.Reload);
      
      if (reloadBusExists) {
        this.FireRepresentativeMoleculeEvent(LeapXLEventType.Reload);
      } else {
        const dataBus = this.context.GetBusByReceptor(Receptor.ValueInput);
        
        if (dataBus) {
          
          if (dataBus.GetActionMoleculeParticle('AddToDatasourceMolecule') ||
            dataBus.GetActionMoleculeParticle('UpdateDatasourceDataMolecule') ||
            dataBus.GetActionMoleculeParticle('DeleteDatasourceDataMolecule')) {
            // avoid auto update
          } else {
            const molecules = dataBus.GetActionMoleculeParticlesByInternalName(
              'GetElementsDatasourceDataMolecule');
            
            if (molecules.length > 0) {
              const firstParticle = dataBus.FirstParticle();
              const omitEvents = [
                LeapXLEventType.RowSelected,
              ];
              
              if (firstParticle.IsEvent() && !omitEvents.includes(
                (firstParticle as LeapXLEvent).EventType as any)) {
                
                const event = firstParticle as LeapXLEvent;
                let avoidEvent = false;
                
                const eventBuses = this.eventsService.GetBusesForEvent(
                  `${ event.EventSource }-${ event.SourceId }-${ event.EventType }`)
                .buses
                .map(b => b.bus);
                
                const addMolecules = [];
                
                avoidBusesWithContexts.forEach(context => {
                  
                  eventBuses.forEach(b => {
                    addMolecules.push(
                      ...b.GetActionMoleculesByInternalNameAndContext(['AddToDatasourceMolecule'], context));
                  });
                  
                  if (addMolecules.length > 0) {
                    avoidEvent = true;
                  }
                });
                
                if (avoidEvent) return;
                
                this.communicationService.Event.Runtime.MolecularEngine.$LeapXLEvent.emit(
                  firstParticle as LeapXLEvent);
              }
            }
          }
        }
      }
    }
  }
  
  FireInitEvents() {
    this.runtimeService.ViewRepMoleculesInitialized.push(this.context.Id);
    
    if (!this.context.Initialized && this.context.Events.includes('init')) {
      // console.log('fire init');
      // setTimeout(() => {
      //   switch (this.context.Type) {
      //     case RepresentativeMoleculesType.Chart:
      //     case RepresentativeMoleculesType.Table:
      //       this.FireRepresentativeMoleculeEvent('init');
      //       break;
      //     default:
      //       break;
      //   }
      // }, 300);
      this.FireRepresentativeMoleculeEvent('init');
      console.log('fire init event');
    }
    
    this.context.Initialized = true;
  }
  
  FireRepresentativeMoleculeEvent(eventName: string, data = this.context.Value, triggeredByUser = false) {
    if (data === null) {
      data = this.context.Value;
    }
    
    if (this.cobbleService.Cobble.running || eventName === 'init') {
      this.context.FireEvent(eventName, data, triggeredByUser);
    }
  }
  
  //   public addAlpha(originalColor, alpha) {
  //     const alphaFixed = Math.round(alpha * 255);
  //     const alphaHex = Long.toHexString(alphaFixed);
  //     if (alphaHex.length() == 1) {
  //         alphaHex = "0" + alphaHex;
  //     }
  //     originalColor = originalColor.replace("#", "#" + alphaHex);
  
  //     return originalColor;
  // }
  
  ngAfterViewInit() {
    // clear value when rep molecule get initialized
    if (this.runtimeService.ChangingView) {
      // this.context.Value = null;
    }
    
    this.gradientRadius = 50;
    
    // if previwing theme style
    if (this.context?.Id === 0) {
      this.EnableHover();
    }
    
    if (this.workAreaService.focusRepresentativeMolecule && this.workAreaService.focusRepresentativeMolecule.Id === this.context.Id) {
      this.workAreaService.focusRepresentativeMolecule = null;
      setTimeout(() => {
        this.context.ScrollIntoFocus();
      }, 500);
    }
  }
  
  public OnDrop(droppedMolecule: any, event: MouseEvent, position: GridsterItem, properties = [], subparentId = 0) {
    console.log('dropped', droppedMolecule);
    
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    if (this.dragService.dragginBus) {
      this.dragService.dragginBus = false;
      const bus = this.dragService.dragData as Bus;
      bus.Dragging = false;
      
      if (bus.RepresentativeMoleculeId !== this.context.Id) {
        const sourceRepMolecule = this.busService.Get(bus.RepresentativeMoleculeId.toString());
        sourceRepMolecule.RemoveBus(bus.id);
        
        bus.MoveBusFromRepresentativeMolecule(sourceRepMolecule.Id, this.context.Id);
        
        this.context.AddBus(bus);
        sourceRepMolecule.SaveProperty('buses', 'Bus Removed');
        this.context.SaveProperty('buses', 'Bus Added');
        this.context.Pulse(1500);
        
        this.snackerService.ShowMessageOnBottom('Bus Moved', 'swap_horiz', 2000, true);
      }
      return;
    }
    
    
    const repMoleculesAvailable = [
      RepresentativeMoleculesType.Label,
      RepresentativeMoleculesType.Button,
      RepresentativeMoleculesType.Badge,
      RepresentativeMoleculesType.Breadcrumb,
      RepresentativeMoleculesType.Textbox,
      RepresentativeMoleculesType.QrCode,
      RepresentativeMoleculesType.Textarea,
      RepresentativeMoleculesType.Datepicker,
      RepresentativeMoleculesType.Dropdown,
      RepresentativeMoleculesType.Chart,
      RepresentativeMoleculesType.Table,
      RepresentativeMoleculesType.Image,
      RepresentativeMoleculesType.Iframe,
      RepresentativeMoleculesType.Checkbox,
      RepresentativeMoleculesType.Icon,
      RepresentativeMoleculesType.Radio,
      RepresentativeMoleculesType.Stepper,
      RepresentativeMoleculesType.Progress,
      RepresentativeMoleculesType.Slider,
      RepresentativeMoleculesType.Custom,
    ];
    
    const repMoleculesIcons = RepresentativeMoleculeTypeIcons;
    
    let elementsToDropOn = [this.context];
    
    if (this.workAreaService.elementsSelected.length > 1 && this.workAreaService.elementsSelected.find(
      (es) => es.Id === this.context.Id)) {
      elementsToDropOn = this.workAreaService.elementsSelected;
    }
    
    // console.log('elementsToDropOn', elementsToDropOn);
    if (droppedMolecule.dragType === DragType.Spreadsheet) {
      if (this.spreadSheetService.SpreadSheetContainsUnsavedChanges()) {
        this.snackerService.ShowMessageOnBottom(
          'Please save or revert the changes on the spreadsheet before create an element', 'save_as', 5000);
        return;
      }
      
      this.bottomSheetService.Options = [];
      
      if (this.context.Type === RepresentativeMoleculesType.WorkGroup) {
        repMoleculesAvailable.forEach((molecule) => {
          this.bottomSheetService.Options.push({
            id: this.workAreaService.libraryMoleculeIds[`${ molecule }-Representative`],
            name: `${ molecule }`,
            description: `Create ${ molecule } with this datasource`,
            icon: [repMoleculesIcons[molecule]],
          });
        });
        
        this.bottomSheetSubscription = this.communicationService.Event.Editor.$BottomSheetOptionSelected.subscribe(
          (option: BottomSheetOption) => {
            // console.log('option', option);
            
            this.bottomSheetSubscription.unsubscribe();
            
            let dataSourceType = DatasourceType.Spreadsheet;
            let datasourceData = null;
            let textName = '';
            let required = false;
            const translationsToGet = [];
            
            setTimeout(() => {
              if (droppedMolecule.dragType === DragType.DataElement || this.spreadSheetService.dataSourceTypeOpened !== DatasourceType.Spreadsheet) {
                droppedMolecule.data.forEach((dataElement) => {
                  dataSourceType = (dataElement as DataElement).DataSourceType;
                  datasourceData = {};
                  datasourceData.context = (dataElement as DataElement).Context;
                  textName = (dataElement as DataElement).Reference;
                  datasourceData.dataSourceId = null;
                  datasourceData.dataSourceName = '';
                  datasourceData.collection = '';
                  // required = (dataElement as DataElement).Required;
                  required = false;
                  
                  translationsToGet.push({
                    dataSourceId: datasourceData.dataSourceId,
                    applicationId: this.cobbleService.Cobble.id,
                    dataSourceType: dataSourceType,
                    specialMode: this.spreadSheetService.editorDbMode,
                    context: datasourceData.context,
                    reference: textName,
                  });
                });
              } else {
                textName = this.spreadSheetService.SpreadSheetRangeSelected.dataItems[0].value;
                datasourceData = {
                  collection: this.spreadSheetService.SpreadSheetRangeSelected.collection,
                  cols: this.spreadSheetService.SpreadSheetRangeSelected.cols,
                  dataSourceId: this.spreadSheetService.SpreadSheetRangeSelected.dataSourceId,
                  dataSourceName: this.spreadSheetService.SpreadSheetRangeSelected.dataSourceName,
                  firstColIndex: this.spreadSheetService.SpreadSheetRangeSelected.firstColIndex,
                  firstRowIndex: this.spreadSheetService.SpreadSheetRangeSelected.firstRowIndex,
                  lastColIndex: this.spreadSheetService.SpreadSheetRangeSelected.lastColIndex,
                  lastRowIndex: this.spreadSheetService.SpreadSheetRangeSelected.lastRowIndex,
                  range: this.spreadSheetService.SpreadSheetRangeSelected.range,
                  rows: this.spreadSheetService.SpreadSheetRangeSelected.rows,
                  baseZero: true,
                };
                
                const separatedRange = this.toolsService.SeparateRangeReference(datasourceData.range);
                const firstReference = `${ separatedRange.firstColumn }${ separatedRange.firstRow === 0 ? '' : separatedRange.firstRow }`;
                const secondReference = `${ separatedRange.lastColumn }${ separatedRange.lastRow === 0 ? '' : separatedRange.lastRow }`;
                
                translationsToGet.push({
                  dataSourceId: datasourceData.dataSourceId,
                  applicationId: this.cobbleService.Cobble.id,
                  dataSourceType: DatasourceType.Spreadsheet,
                  specialMode: this.spreadSheetService.editorDbMode,
                  context: `${ DatasourceType.Spreadsheet }${ Constants.ContextSeparator }${ datasourceData.dataSourceName }${ Constants.ContextSeparator }${ datasourceData.collection }${ Constants.ContextSeparator }${ firstReference }`,
                  reference: firstReference,
                });
                
                if (firstReference !== secondReference) {
                  translationsToGet.push({
                    dataSourceId: datasourceData.dataSourceId,
                    applicationId: this.cobbleService.Cobble.id,
                    dataSourceType: DatasourceType.Spreadsheet,
                    specialMode: this.spreadSheetService.editorDbMode,
                    context: `${ DatasourceType.Spreadsheet }${ Constants.ContextSeparator }${ datasourceData.dataSourceName }${ Constants.ContextSeparator }${ datasourceData.collection }${ Constants.ContextSeparator }${ secondReference }`,
                    reference: secondReference,
                  });
                }
              }
              
              this.dataTranslatorService.CreateTranslation(translationsToGet)
              .subscribe((translations) => {
                let buses = [];
                const dataElements = [];
                
                // region assign translation ids
                if (droppedMolecule.dragType === DragType.DataElement) {
                  const treeNode = this.dataSourcesService.GetDataSourceNodeFromTree(
                    droppedMolecule.data[0].Context) || { required: false };
                  required = treeNode.required;
                  droppedMolecule.data.forEach((dataElement, index) => {
                    const translation = translations.find(
                      (t) => t.context.toLowerCase() === dataElement.Context.toLowerCase());
                    dataElement.TranslationId = translation.id;
                    dataElement.InternalName = translation.internalName;
                    dataElement.Reference = translation.internalName;
                    dataElements.push(dataElement);
                  });
                } else {
                  const firstDataElement = new DataElement({
                    translationId: translations[0].id,
                    id: this.toolsService.GenerateGuid(),
                    particleId: this.toolsService.GenerateGuid(),
                    particleType: ParticleType.DataElement,
                    context: translations[0].context,
                    dataSourceType: dataSourceType,
                    dataSourceId: datasourceData.dataSourceId,
                    dataSourceName: datasourceData.dataSourceName,
                    collection: datasourceData.collection,
                    internalName: translations[0].internalName,
                    reference: translations[0].internalName,
                    applicationId: this.cobbleService.Cobble.id,
                  });
                  
                  dataElements.push(firstDataElement);
                  
                  if (translations.length > 1) {
                    const secondParticleId = this.toolsService.GenerateGuid();
                    firstDataElement.RangeParticleId = this.spreadSheetService.editorDbMode ? null : secondParticleId;
                    dataElements.push(
                      new DataElement({
                        translationId: translations[1].id,
                        id: this.toolsService.GenerateGuid(),
                        particleId: secondParticleId,
                        particleType: ParticleType.DataElement,
                        context: translations[1].context,
                        dataSourceType: DatasourceType.Spreadsheet,
                        dataSourceId: datasourceData.dataSourceId,
                        dataSourceName: datasourceData.dataSourceName,
                        collection: datasourceData.collection,
                        internalName: translations[1].internalName,
                        reference: translations[1].internalName,
                        applicationId: this.cobbleService.Cobble.id,
                        rangeParticleId: this.spreadSheetService.editorDbMode ? null : firstDataElement.ParticleId,
                      }),
                    );
                  }
                }
                // endregion
                
                this.templateService
                .GetActionMoleculeProperties([
                  'AddToDatasourceMolecule',
                  'FilterByDataElementReferenceMolecule',
                  'GetElementsDatasourceDataMolecule',
                  'UpdateDatasourceDataMolecule',
                  'ClearValueMolecule',
                ])
                .subscribe((moleculeProperties) => {
                  const busesToAdd = this.factoryService.SetRepresentativeMoleculeBusDefaults(
                    [
                      {
                        id: 0,
                        properties: [
                          {
                            name: 'textToDisplay',
                            value: textName,
                          },
                          {
                            name: 'type',
                            value: option.name,
                          },
                        ],
                      },
                    ],
                    null,
                    this.context.Contexts,
                    moleculeProperties,
                    false,
                    dataElements,
                    this.context,
                  );
                  
                  buses = busesToAdd;
                  
                  const propertiesToAdd = [
                    {
                      name: 'buses',
                      value: buses,
                      path: '',
                    },
                    {
                      name: 'required',
                      value: required,
                      path: '',
                    },
                    {
                      name: 'placeholder',
                      value: textName,
                      path: 'properties',
                    },
                    {
                      name: 'textToDisplay',
                      value: textName,
                      path: 'properties',
                    },
                    {
                      name: 'view',
                      value: this.workAreaService.ActualView.id,
                      path: 'properties',
                    },
                  ];
                  
                  if (option.name === RepresentativeMoleculesType.Table && dataSourceType === DatasourceType.Spreadsheet) {
                    const tableColumnWidth = {};
                    
                    for (
                      let col = this.spreadSheetService.SpreadSheetRangeSelected.firstColIndex + 1;
                      col <= this.spreadSheetService.SpreadSheetRangeSelected.lastColIndex + 1;
                      col++
                    ) {
                      tableColumnWidth[col] = {
                        columnName: '',
                        columnWidth: 120,
                        columnShow: true,
                      };
                    }
                    propertiesToAdd.push({
                      name: 'tableWidth',
                      value: [tableColumnWidth],
                      path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }.tableOptions`,
                    });
                  }
                  
                  this.OnDrop(
                    {
                      id: option.id,
                      type: option.name,
                      dragType: 'Molecule',
                      moleculeType: 'Representative',
                    },
                    event,
                    position,
                    propertiesToAdd,
                  );
                });
              });
            }, this.bottomsheetAnimationTimeout);
          });
      } else {
        this.bottomSheetService.Options.push({
          id: 'DataSource',
          name: `Set DataSource`,
          description: `Replace DataSource for ${ this.context.Properties.name }`,
          icon: ['vertical_align_bottom'],
        });
        
        if (this.context.Type === RepresentativeMoleculesType.Table) {
          this.bottomSheetService.Options.push({
            id: 'AddDataSource',
            name: `Add Column`,
            description: `Add DataSource for ${ this.context.Properties.name }`,
            icon: ['addchart'],
          });
        }
        
        repMoleculesAvailable.forEach((molecule) => {
          this.bottomSheetService.Options.push({
            id: this.workAreaService.libraryMoleculeIds[`${ molecule }-Representative`],
            name: `${ molecule }`,
            description: `Replace with ${ molecule } and datasource`,
            icon: [repMoleculesIcons[molecule]],
          });
        });
        
        this.bottomSheetSubscription = this.communicationService.Event.Editor.$BottomSheetOptionSelected.subscribe(
          (option: BottomSheetOption) => {
            this.bottomSheetSubscription.unsubscribe();
            
            setTimeout(() => {
              elementsToDropOn.forEach((es, index) => {
                let datasource = null;
                const tableColumnWidth = {};
                let dataElements = [];
                let dataSourceType = DatasourceType.Spreadsheet;
                // console.log('droppedMolecule.dragType', droppedMolecule.dragType);
                
                if (droppedMolecule.dragType === DragType.DataElement) {
                  dataElements = droppedMolecule.data;
                  dataSourceType = (droppedMolecule.data as DataElement).DataSourceType;
                  datasource = {};
                  datasource.context = (droppedMolecule.data as DataElement).Context;
                  datasource.dataSourceId = null;
                  datasource.dataSourceName = '';
                  datasource.collection = '';
                } else {
                  datasource = {
                    collection: this.spreadSheetService.SpreadSheetRangeSelected.collection,
                    cols: this.spreadSheetService.SpreadSheetRangeSelected.cols,
                    dataSourceId: this.spreadSheetService.SpreadSheetRangeSelected.dataSourceId,
                    dataSourceName: this.spreadSheetService.SpreadSheetRangeSelected.dataSourceName,
                    firstColIndex: this.spreadSheetService.SpreadSheetRangeSelected.firstColIndex,
                    firstRowIndex: this.spreadSheetService.SpreadSheetRangeSelected.firstRowIndex,
                    lastColIndex: this.spreadSheetService.SpreadSheetRangeSelected.lastColIndex,
                    lastRowIndex: this.spreadSheetService.SpreadSheetRangeSelected.lastRowIndex,
                    range: this.spreadSheetService.SpreadSheetRangeSelected.range,
                    rows: this.spreadSheetService.SpreadSheetRangeSelected.rows,
                    baseZero: true,
                    particleId: this.toolsService.GenerateGuid(),
                    context: this.spreadSheetService.SpreadSheetRangeSelected.context,
                    dataSourceType: this.spreadSheetService.SpreadSheetRangeSelected.dataSourceTypeName,
                    reference: this.spreadSheetService.SpreadSheetRangeSelected.range,
                    applicationId: this.cobbleService.Cobble.id,
                  };
                  
                  for (
                    let col = this.spreadSheetService.SpreadSheetRangeSelected.firstColIndex + 1;
                    col <= this.spreadSheetService.SpreadSheetRangeSelected.lastColIndex + 1;
                    col++
                  ) {
                    tableColumnWidth[col] = {
                      columnName: this.spreadSheetService.SpreadSheetRangeSelected.dataItems.find(
                        (c) => c.col === col - 1 && c.row === 0)
                        ? this.spreadSheetService.SpreadSheetRangeSelected.dataItems.find(
                          (c) => c.col === col - 1 && c.row === 0).value
                        : '',
                      columnWidth: 120,
                      columnShow: true,
                    };
                  }
                  
                  const dataElement = new DataElement(datasource);
                  dataElement.Reference = datasource.reference.split(':')[0];
                  dataElement.Context = `${ dataElement.DataSourceName }${ Constants.ContextSeparator }${ dataElement.Collection }${ Constants.ContextSeparator }${ dataElement.Reference }`;
                  
                  if (dataElement.Reference) {
                    dataElements.push(dataElement);
                  }
                  if (datasource.range.split(':')[0] !== datasource.range.split(':')[1]) {
                    const secondDataElement = new DataElement(datasource);
                    secondDataElement.Reference = datasource.reference.split(':')[1];
                    secondDataElement.Context = `${ dataElement.DataSourceName }${ Constants.ContextSeparator }${ dataElement.Collection }${ Constants.ContextSeparator }${ dataElement.Reference }`;
                    if (secondDataElement.Reference) {
                      dataElements.push(secondDataElement);
                    }
                  }
                }
                
                if (option.id === 'DataSource') {
                  if (es.Properties.responsive.desktop.tableOptions && es.Properties.responsive.smartphone.tableOptions) {
                    es.Properties.responsive.desktop.tableOptions.tableWidth = es.Properties.responsive.smartphone.tableOptions.tableWidth =
                      tableColumnWidth;
                  }
                  
                  this.SetDataSourceMolecules(dataElements, datasource, dataSourceType, es)
                  .subscribe((buses) => {
                    const propertiesToSave = [
                      new PropertyVersioningDto({
                        elementId: es.Id.toString(),
                        property: 'buses',
                        value: es.Buses,
                        path: '',
                        change: 'Assigning datasources',
                        name: es.Properties.name,
                      }),
                      new PropertyVersioningDto({
                        elementId: es.Id.toString(),
                        property: 'tableWidth',
                        value: tableColumnWidth,
                        path: 'properties.responsive.smartphone.tableOptions',
                        change: 'Columns configuration changed',
                        name: es.Properties.name,
                      }),
                      new PropertyVersioningDto({
                        elementId: es.Id.toString(),
                        property: 'tableWidth',
                        value: tableColumnWidth,
                        path: 'properties.responsive.desktop.tableOptions',
                        change: 'Columns configuration changed',
                        name: es.Properties.name,
                      }),
                    ];
                    this.propertiesService.SaveProperties(propertiesToSave)
                    .subscribe();
                    console.log('here update');
                    
                    this.UpdateData();
                    this.communicationService.Event.Editor.$RefreshRepresentativePropertiesPanel.emit(true);
                    es.RefreshDatasourceConnected();
                    this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanel.emit();
                    this.eventsService.CreateEventsTable();
                    setTimeout(() => {
                      es.FireDataSourceBus();
                    }, 200);
                    this.snackerService.ShowMessageOnBottom('Data Source updated!', 'update', null, true);
                  });
                } else if (option.id === 'AddDataSource') {
                  let dataBus = es.GetBusByReceptorAndMoleculeNameType([Receptor.ValueInput],
                    'GetElementsDatasourceDataMolecule');
                  
                  if (dataBus.length === 0) {
                    dataBus = es.GetBusByReceptorAndMoleculeNameType([Receptor.ValueInput],
                      'FilterByDataElementReferenceMolecule');
                  }
                  
                  if (dataBus.length > 0) {
                    const actionMolecule =
                      dataBus[0].GetActionMoleculeParticle('GetElementsDatasourceDataMolecule') ||
                      dataBus[0].GetActionMoleculeParticle('FilterByDataElementReferenceMolecule');
                    
                    this.dragService.ConvertAndAssignDataSourceToMolecule(
                      dataBus[0].id,
                      es.Id,
                      actionMolecule.ParticleId,
                      this.spreadSheetService.SpreadSheetRangeSelected,
                      actionMolecule.DataElements.length,
                      false,
                    );
                  } else {
                  }
                } else {
                  if (index === 0) {
                    this.replacedMoleculeId = es.Id;
                    // console.log('setting datasource');
                    
                    this.SetDataSourceMolecules(dataElements, datasource, dataSourceType, es, true,
                      option.name)
                    .subscribe((buses) => {
                      // console.log('datasource set');
                      this.OnDrop(
                        {
                          id: option.id,
                          type: option.name,
                          moleculeType: 'Representative',
                          dragType: 'Molecule',
                        },
                        event,
                        position,
                        [
                          {
                            name: 'tableWidth',
                            value: tableColumnWidth,
                            path: `properties.responsive.desktop.tableOptions`,
                          },
                          {
                            name: 'tableWidth',
                            value: tableColumnWidth,
                            path: `properties.responsive.smartphone.tableOptions`,
                          },
                        ],
                      );
                    });
                  }
                }
              });
            }, this.bottomsheetAnimationTimeout);
          });
        
        this.communicationService.Event.Editor.$RecreateProcessBuses.emit();
      }
      
      const bs = this.bottomSheet.open(BottomSheetOptionsComponent);
      bs.afterDismissed()
      .subscribe((result) => {
        this.bottomSheetService.Options = [];
        this.bottomSheetSubscription.unsubscribe();
      });
    } else if (droppedMolecule.dragType === DragType.DataElement) {
      this.bottomSheetService.Options = [];
      
      if (this.context.Type === RepresentativeMoleculesType.WorkGroup) {
        repMoleculesAvailable.forEach((molecule) => {
          this.bottomSheetService.Options.push({
            id: this.workAreaService.libraryMoleculeIds[`${ molecule }-Representative`],
            name: `${ molecule }`,
            description: `Create ${ molecule } with this datasource`,
            icon: [repMoleculesIcons[molecule]],
          });
        });
        
        this.bottomSheetSubscription = this.communicationService.Event.Editor.$BottomSheetOptionSelected.subscribe(
          (option: BottomSheetOption) => {
            // console.log('option', option);
            
            this.bottomSheetSubscription.unsubscribe();
            let dataSourceType = 'Spreadsheet';
            let datasourceData = null;
            let textName = '';
            let required = false;
            const translationsToGet = [];
            
            setTimeout(() => {
              if (droppedMolecule.dragType === DragType.DataElement || this.spreadSheetService.dataSourceTypeOpened !== DatasourceType.Spreadsheet) {
                droppedMolecule.data.forEach((dataElement) => {
                  dataSourceType = (dataElement as DataElement).DataSourceType;
                  datasourceData = {};
                  datasourceData.context = (dataElement as DataElement).Context;
                  textName = (dataElement as DataElement).Reference;
                  datasourceData.dataSourceId = (dataElement as DataElement).DataSourceId;
                  datasourceData.dataSourceName = '';
                  datasourceData.collection = '';
                  // required = (dataElement as DataElement).Required;
                  required = false;
                  
                  translationsToGet.push({
                    dataSourceId: datasourceData.dataSourceId,
                    applicationId: this.cobbleService.Cobble.id,
                    dataSourceType: dataSourceType,
                    specialMode: this.spreadSheetService.editorDbMode,
                    context: datasourceData.context,
                    reference: textName,
                  });
                });
              } else {
                textName = this.spreadSheetService.SpreadSheetRangeSelected.dataItems[0].value;
                datasourceData = {
                  collection: this.spreadSheetService.SpreadSheetRangeSelected.collection,
                  cols: this.spreadSheetService.SpreadSheetRangeSelected.cols,
                  dataSourceId: this.spreadSheetService.SpreadSheetRangeSelected.dataSourceId,
                  dataSourceName: this.spreadSheetService.SpreadSheetRangeSelected.dataSourceName,
                  firstColIndex: this.spreadSheetService.SpreadSheetRangeSelected.firstColIndex,
                  firstRowIndex: this.spreadSheetService.SpreadSheetRangeSelected.firstRowIndex,
                  lastColIndex: this.spreadSheetService.SpreadSheetRangeSelected.lastColIndex,
                  lastRowIndex: this.spreadSheetService.SpreadSheetRangeSelected.lastRowIndex,
                  range: this.spreadSheetService.SpreadSheetRangeSelected.range,
                  rows: this.spreadSheetService.SpreadSheetRangeSelected.rows,
                  baseZero: true,
                };
                
                const separatedRange = this.toolsService.SeparateRangeReference(datasourceData.range);
                const firstReference = `${ separatedRange.firstColumn }${ separatedRange.firstRow }`;
                const secondReference = `${ separatedRange.lastColumn }${ separatedRange.lastRow }`;
                
                translationsToGet.push({
                  dataSourceId: datasourceData.dataSourceId,
                  applicationId: this.cobbleService.Cobble.id,
                  dataSourceType: DatasourceType.Spreadsheet,
                  specialMode: this.spreadSheetService.editorDbMode,
                  context: `${ `Spreadsheet` }${ Constants.ContextSeparator }${ datasourceData.dataSourceName }${ Constants.ContextSeparator }${
                    datasourceData.collection
                  }${ Constants.ContextSeparator }${ firstReference }`,
                  reference: firstReference,
                });
                
                if (firstReference !== secondReference) {
                  translationsToGet.push({
                    dataSourceId: datasourceData.dataSourceId,
                    applicationId: this.cobbleService.Cobble.id,
                    dataSourceType: DatasourceType.Spreadsheet,
                    specialMode: this.spreadSheetService.editorDbMode,
                    context: `${ 'Spreadsheet' }${ Constants.ContextSeparator }${ datasourceData.dataSourceName }${ Constants.ContextSeparator }${
                      datasourceData.collection
                    }${ Constants.ContextSeparator }${ secondReference }`,
                    reference: secondReference,
                  });
                }
              }
              
              this.dataTranslatorService.CreateTranslation(translationsToGet)
              .subscribe((translations) => {
                const buses = [];
                
                this.templateService
                .GetActionMoleculeProperties(
                  ['AddToDatasourceMolecule', 'FilterByDataElementReferenceMolecule',
                    'GetElementsDatasourceDataMolecule'])
                .subscribe((moleculeProperties) => {
                  const dataElements = [];
                  
                  if (droppedMolecule.dragType === DragType.DataElement) {
                    // required = this.dataSourcesService.GetDataSourceNodeFromTree(droppedMolecule.data[0].Context).required;
                    
                    required = false;
                    droppedMolecule.data.forEach((dataElement, index) => {
                      const translation = translations.find(
                        (t) => t.context.toLowerCase() === dataElement.Context.toLowerCase());
                      dataElement.TranslationId = translation.id;
                      dataElement.InternalName = translation.internalName;
                      dataElement.Reference = translation.internalName;
                      dataElements.push(dataElement);
                    });
                  } else {
                    const firstDataElement = new DataElement({
                      translationId: translations[0].id,
                      id: this.toolsService.GenerateGuid(),
                      particleId: this.toolsService.GenerateGuid(),
                      particleType: ParticleType.DataElement,
                      context: translations[0].context,
                      dataSourceType: dataSourceType,
                      dataSourceId: datasourceData.dataSourceId,
                      dataSourceName: datasourceData.dataSourceName,
                      collection: datasourceData.collection,
                      internalName: translations[0].internalName,
                      reference: translations[0].internalName,
                      applicationId: this.cobbleService.Cobble.id,
                    });
                    
                    dataElements.push(firstDataElement);
                    
                    if (translations.length > 1) {
                      const secondParticleId = this.toolsService.GenerateGuid();
                      firstDataElement.RangeParticleId = secondParticleId;
                      dataElements.push(
                        new DataElement({
                          translationId: translations[1].id,
                          id: this.toolsService.GenerateGuid(),
                          particleId: secondParticleId,
                          particleType: ParticleType.DataElement,
                          context: translations[1].context,
                          dataSourceType: DatasourceType.Spreadsheet,
                          dataSourceId: datasourceData.dataSourceId,
                          dataSourceName: datasourceData.dataSourceName,
                          collection: datasourceData.collection,
                          internalName: translations[1].internalName,
                          reference: translations[1].internalName,
                          applicationId: this.cobbleService.Cobble.id,
                          rangeParticleId: firstDataElement.ParticleId,
                        }),
                      );
                    }
                  }
                  
                  // default for api forms extra
                  if (dataElements.length === 1 && dataElements[0].DataSourceType === DatasourceType.Api) {
                    const eventId = this.toolsService.GenerateIdFromContext(dataElements[0].Context);
                    
                    const firstBus = new Bus({
                      id: this.toolsService.GenerateGuid(),
                      Name: `Process Workgroup ${ textName }`,
                      Receptor: Receptor.ValueInput,
                      Particles: [],
                    });
                    
                    const customEvent = this.eventsService.CustomEvents.find(
                      (ce) => ce.EventName === eventId);
                    
                    firstBus.AddParticle(new LeapXLEvent(customEvent));
                    firstBus.AddParticle(
                      this.particlesFactoryService.GenerateActionMolecule(moleculeProperties[0], 0,
                        dataElements));
                    
                    buses.push(firstBus);
                  } else {
                    const firstBus = new Bus({
                      id: this.toolsService.GenerateGuid(),
                      Name: `Process Workgroup ${ textName }`,
                      Receptor: Receptor.ValueOutput,
                      Particles: [],
                    });
                    
                    firstBus.AddParticle(
                      this.particlesFactoryService.GenerateActionMolecule(moleculeProperties[2], 0,
                        dataElements));
                    
                    const secondBus = new Bus({
                      id: this.toolsService.GenerateGuid(),
                      Name: `Process Workgroup ${ textName } (2)`,
                      Receptor: Receptor.ValueInput,
                      Particles: [],
                    });
                    
                    secondBus.AddParticle(
                      this.particlesFactoryService.GenerateEvent(LeapXLEventType.AppBroadcast));
                    secondBus.AddParticle(
                      this.particlesFactoryService.GenerateActionMolecule(moleculeProperties[0], 0,
                        dataElements));
                    
                    const thirdBus = new Bus({
                      id: this.toolsService.GenerateGuid(),
                      Name: `Process Workgroup ${ textName } (3)`,
                      Receptor: option.name === RepresentativeMoleculesType.Dropdown ? Receptor.OptionsListInput : Receptor.ValueInput,
                      Particles: [],
                    });
                    
                    secondBus.AddParticle(this.particlesFactoryService.GenerateEvent(LeapXLEventType.Init));
                    secondBus.AddParticle(
                      this.particlesFactoryService.GenerateActionMolecule(moleculeProperties[1], 0,
                        dataElements));
                    
                    buses.push(firstBus);
                    buses.push(secondBus);
                    buses.push(thirdBus);
                  }
                  
                  const propertiesToAdd = [
                    {
                      name: 'buses',
                      value: buses,
                      path: '',
                    },
                    {
                      name: 'required',
                      value: required,
                      path: '',
                    },
                    {
                      name: 'placeholder',
                      value: textName,
                      path: 'properties',
                    },
                    {
                      name: 'textToDisplay',
                      value: textName,
                      path: 'properties',
                    },
                    {
                      name: 'view',
                      value: this.workAreaService.ActualView.id,
                      path: 'properties',
                    },
                  ];
                  
                  if (option.name === RepresentativeMoleculesType.Table && dataSourceType === DatasourceType.Spreadsheet) {
                    const tableColumnWidth = {};
                    
                    for (
                      let col = this.spreadSheetService.SpreadSheetRangeSelected.firstColIndex + 1;
                      col <= this.spreadSheetService.SpreadSheetRangeSelected.lastColIndex + 1;
                      col++
                    ) {
                      tableColumnWidth[col] = {
                        columnName: '',
                        columnWidth: 120,
                        columnShow: true,
                      };
                    }
                    propertiesToAdd.push({
                      name: 'tableWidth',
                      value: [tableColumnWidth],
                      path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }.tableOptions`,
                    });
                  }
                  
                  this.OnDrop(
                    {
                      id: option.id,
                      type: option.name,
                      dragType: 'Molecule',
                      moleculeType: 'Representative',
                    },
                    event,
                    position,
                    propertiesToAdd,
                  );
                });
              });
            }, this.bottomsheetAnimationTimeout);
          });
      } else {
        this.bottomSheetService.Options.push({
          id: 'DataSource',
          name: `Set DataSource`,
          description: `Replace DataSource for ${ this.context.Properties.name }`,
          icon: ['vertical_align_bottom'],
        });
        this.bottomSheetService.Options.push({
          id: 'ReceiveData',
          name: `Receive Data`,
          description: `Receive Data from Datasource`,
          icon: ['east'],
        });
        
        if (this.context.Type === RepresentativeMoleculesType.Table) {
          this.bottomSheetService.Options.push({
            id: 'AddDataSource',
            name: `Add Column`,
            description: `Add DataSource for ${ this.context.Properties.name }`,
            icon: ['addchart'],
          });
        }
        
        repMoleculesAvailable.forEach((molecule) => {
          this.bottomSheetService.Options.push({
            id: this.workAreaService.libraryMoleculeIds[`${ molecule }-Representative`],
            name: `${ molecule }`,
            description: `Replace with ${ molecule } and datasource`,
            icon: [repMoleculesIcons[molecule]],
          });
        });
        
        this.bottomSheetSubscription = this.communicationService.Event.Editor.$BottomSheetOptionSelected.subscribe(
          (option: BottomSheetOption) => {
            this.bottomSheetSubscription.unsubscribe();
            
            setTimeout(() => {
              elementsToDropOn.forEach((es, index) => {
                let datasource = null;
                const tableColumnWidth = {};
                let dataElements = [];
                let dataSourceType = DatasourceType.Spreadsheet;
                // console.log('droppedMolecule.dragType', droppedMolecule.dragType);
                
                if (droppedMolecule.dragType === DragType.DataElement) {
                  dataElements = droppedMolecule.data;
                  dataSourceType = (droppedMolecule.data as DataElement).DataSourceType;
                  datasource = {};
                  datasource.context = (droppedMolecule.data as DataElement).Context;
                  datasource.dataSourceId = null;
                  datasource.dataSourceName = '';
                  datasource.collection = '';
                } else {
                  datasource = {
                    collection: this.spreadSheetService.SpreadSheetRangeSelected.collection,
                    cols: this.spreadSheetService.SpreadSheetRangeSelected.cols,
                    dataSourceId: this.spreadSheetService.SpreadSheetRangeSelected.dataSourceId,
                    dataSourceName: this.spreadSheetService.SpreadSheetRangeSelected.dataSourceName,
                    firstColIndex: this.spreadSheetService.SpreadSheetRangeSelected.firstColIndex,
                    firstRowIndex: this.spreadSheetService.SpreadSheetRangeSelected.firstRowIndex,
                    lastColIndex: this.spreadSheetService.SpreadSheetRangeSelected.lastColIndex,
                    lastRowIndex: this.spreadSheetService.SpreadSheetRangeSelected.lastRowIndex,
                    range: this.spreadSheetService.SpreadSheetRangeSelected.range,
                    rows: this.spreadSheetService.SpreadSheetRangeSelected.rows,
                    baseZero: true,
                    particleId: this.toolsService.GenerateGuid(),
                    context: this.spreadSheetService.SpreadSheetRangeSelected.context,
                    dataSourceType: this.spreadSheetService.SpreadSheetRangeSelected.dataSourceTypeName,
                    reference: this.spreadSheetService.SpreadSheetRangeSelected.range,
                    applicationId: this.cobbleService.Cobble.id,
                  };
                  
                  for (
                    let col = this.spreadSheetService.SpreadSheetRangeSelected.firstColIndex + 1;
                    col <= this.spreadSheetService.SpreadSheetRangeSelected.lastColIndex + 1;
                    col++
                  ) {
                    tableColumnWidth[col] = {
                      columnName: this.spreadSheetService.SpreadSheetRangeSelected.dataItems.find(
                        (c) => c.col === col - 1 && c.row === 0)
                        ? this.spreadSheetService.SpreadSheetRangeSelected.dataItems.find(
                          (c) => c.col === col - 1 && c.row === 0).value
                        : '',
                      columnWidth: 120,
                      columnShow: true,
                    };
                  }
                  
                  const dataElement = new DataElement(datasource);
                  dataElement.Reference = datasource.reference.split(':')[0];
                  dataElement.Context = `${ dataElement.DataSourceName }${ Constants.ContextSeparator }${ dataElement.Collection }${ Constants.ContextSeparator }${ dataElement.Reference }`;
                  
                  dataElements.push(dataElement);
                  if (datasource.range.split(':')[0] !== datasource.range.split(':')[1]) {
                    const secondDataElement = new DataElement(datasource);
                    secondDataElement.Reference = datasource.reference.split(':')[1];
                    secondDataElement.Context = `${ dataElement.DataSourceName }${ Constants.ContextSeparator }${ dataElement.Collection }${ Constants.ContextSeparator }${ dataElement.Reference }`;
                    dataElements.push(secondDataElement);
                  }
                }
                
                if (option.id === 'DataSource') {
                  if (es.Properties.responsive.desktop.tableOptions && es.Properties.responsive.smartphone.tableOptions) {
                    es.Properties.responsive.desktop.tableOptions.tableWidth = es.Properties.responsive.smartphone.tableOptions.tableWidth =
                      tableColumnWidth;
                  }
                  
                  this.SetDataSourceMolecules(dataElements, datasource, dataSourceType, es)
                  .subscribe((buses) => {
                    const propertiesToSave = [
                      new PropertyVersioningDto({
                        elementId: es.Id.toString(),
                        property: 'buses',
                        value: es.Buses,
                        path: '',
                        change: 'Assigning datasources',
                        name: es.Properties.name,
                      }),
                      new PropertyVersioningDto({
                        elementId: es.Id.toString(),
                        property: 'tableWidth',
                        value: tableColumnWidth,
                        path: 'properties.responsive.smartphone.tableOptions',
                        change: 'Columns configuration changed',
                        name: es.Properties.name,
                      }),
                      new PropertyVersioningDto({
                        elementId: es.Id.toString(),
                        property: 'tableWidth',
                        value: tableColumnWidth,
                        path: 'properties.responsive.desktop.tableOptions',
                        change: 'Columns configuration changed',
                        name: es.Properties.name,
                      }),
                    ];
                    this.propertiesService.SaveProperties(propertiesToSave)
                    .subscribe();
                    console.log('here update');
                    
                    this.UpdateData();
                    this.communicationService.Event.Editor.$RefreshRepresentativePropertiesPanel.emit(true);
                    es.RefreshDatasourceConnected();
                    this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanel.emit();
                    this.eventsService.CreateEventsTable();
                    setTimeout(() => {
                      es.FireDataSourceBus();
                    }, 200);
                    this.snackerService.ShowMessageOnBottom('Data Source updated!', 'update', null, true);
                  });
                } else if (option.id === 'AddDataSource') {
                  let dataBus = es.GetBusByReceptorAndMoleculeNameType([Receptor.ValueInput],
                    'GetElementsDatasourceDataMolecule');
                  
                  if (dataBus.length === 0) {
                    dataBus = es.GetBusByReceptorAndMoleculeNameType([Receptor.ValueInput],
                      'FilterByDataElementReferenceMolecule');
                  }
                  
                  if (dataBus.length > 0) {
                    const actionMolecule =
                      dataBus[0].GetActionMoleculeParticle('GetElementsDatasourceDataMolecule') ||
                      dataBus[0].GetActionMoleculeParticle('FilterByDataElementReferenceMolecule');
                    if (actionMolecule) {
                      actionMolecule.Touched = true;
                    }
                    
                    this.dragService.AssignDataElementToMolecule(
                      dataBus[0].id,
                      es.Id,
                      actionMolecule.ParticleId,
                      dataElements,
                      actionMolecule.DataElements.length,
                      false,
                      false,
                    )
                    .then(() => {
                      this.communicationService.Event.Editor.$RecreateEventsTable.emit();
                      this.context.FireDataSourceBus();
                    });
                  } else {
                  }
                } else if (option.id === 'ReceiveData') {
                  const translationsToGet = [];
                  
                  droppedMolecule.data.forEach((dataElement) => {
                    translationsToGet.push({
                      dataSourceId: (dataElement as DataElement).DataSourceId,
                      applicationId: (dataElement as DataElement).ApplicationId,
                      dataSourceType: (dataElement as DataElement).DataSourceType,
                      specialMode: false,
                      context: (dataElement as DataElement).Context,
                      reference: (dataElement as DataElement).Reference,
                    });
                  });
                  
                  this.dataTranslatorService.CreateTranslation(translationsToGet)
                  .subscribe((translations) => {
                    dataElements = [];
                    droppedMolecule.data.forEach((dataElement) => {
                      const translation = translations.find(
                        (t) => t.context.toLowerCase() === dataElement.Context.toLowerCase());
                      dataElement.TranslationId = translation.id;
                      dataElement.InternalName = translation.internalName;
                      dataElement.Reference = translation.internalName;
                      dataElements.push(dataElement);
                    });
                    this.templateService
                    .GetActionMoleculeProperties(
                      ['FilterByDataElementReferenceMolecule', 'GetElementsDatasourceDataMolecule'])
                    .subscribe((moleculeProperties) => {
                      let dataBus = es.GetBusByReceptorAndMoleculeNameType([Receptor.ValueInput],
                        'FilterByDataElementReferenceMolecule') as any;
                      
                      if (dataBus.length > 0) {
                        dataBus.forEach((b) => {
                          es.RemoveBus(b.id);
                        });
                      }
                      
                      dataBus = new Bus({
                        id: this.toolsService.GenerateGuid(),
                        Name: `Process ${ this.context.Properties.name }`,
                        Receptor: Receptor.ValueInput,
                        Particles: [],
                      });
                      
                      console.log('receive data');
                      const takeDataElementMoleculeProperties = moleculeProperties[0];
                      const getDatasourceDataMoleculeProperties = moleculeProperties[1];
                      
                      let customEvent = null;
                      
                      if (dataElements[0].IsVolunteer) {
                        customEvent = this.particlesFactoryService.GenerateEvent(
                          LeapXLEventType.InterimVolunteer);
                      } else {
                        const eventId = this.toolsService.GenerateIdFromContext(dataElements[0].Context);
                        customEvent = this.eventsService.CustomEvents.find((ce) => ce.EventName === eventId);
                      }
                      
                      if (customEvent) {
                        dataBus.AddParticle(new LeapXLEvent(customEvent));
                        dataBus.AddParticle(
                          this.particlesFactoryService.GenerateActionMolecule(
                            takeDataElementMoleculeProperties, this.context.Id, dataElements),
                        );
                      } else {
                        dataBus = new Bus({
                          id: this.toolsService.GenerateGuid(),
                          Name: `Process ${ this.context.Properties.name }`,
                          Receptor: Receptor.ValueInput,
                          Particles: [],
                        });
                        
                        dataBus.AddParticle(
                          this.particlesFactoryService.GenerateEvent(LeapXLEventType.Init, 'Molecule',
                            this.context.Id));
                        dataBus.AddParticle(
                          this.particlesFactoryService.GenerateActionMolecule(
                            getDatasourceDataMoleculeProperties, this.context.Id, dataElements),
                        );
                      }
                      
                      this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanel.emit(true);
                      this.context.AddBus(dataBus);
                      this.context.SaveProperty('buses', 'Bus added')
                      .subscribe();
                    });
                  });
                } else {
                  if (index === 0) {
                    this.replacedMoleculeId = es.Id;
                    // console.log('setting datasource');
                    
                    this.SetDataSourceMolecules(dataElements, datasource, dataSourceType, es)
                    .subscribe((buses) => {
                      // console.log('datasource set');
                      this.OnDrop(
                        {
                          id: option.id,
                          type: option.name,
                          moleculeType: 'Representative',
                          dragType: 'Molecule',
                        },
                        event,
                        position,
                        [
                          {
                            name: 'tableWidth',
                            value: tableColumnWidth,
                            path: `properties.responsive.desktop.tableOptions`,
                          },
                          {
                            name: 'tableWidth',
                            value: tableColumnWidth,
                            path: `properties.responsive.smartphone.tableOptions`,
                          },
                        ],
                      );
                    });
                  }
                }
                
                this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(es.Id);
              });
              this.communicationService.Event.Editor.$RecreateProcessBuses.emit();
            }, this.bottomsheetAnimationTimeout);
          });
      }
      
      const bs = this.bottomSheet.open(BottomSheetOptionsComponent);
      bs.afterDismissed()
      .subscribe((result) => {
        this.bottomSheetService.Options = [];
        this.bottomSheetSubscription.unsubscribe();
      });
    } else if (droppedMolecule.dragType === DragType.Molecule) {
      if (droppedMolecule.moleculeType === 'Behavior' || droppedMolecule.moleculeType === 'DataAction') {
        droppedMolecule.busId = null;
      }
      
      let elementsProcessed = 0;
      
      properties = properties || [];
      elementsToDropOn.forEach((es) => {
        const avoidSourceReplacement = [];
        
        // add defaults if parent is stepper
        if (es.Type === RepresentativeMoleculesType.Stepper) {
          const defaultBus = {
            id: this.toolsService.GenerateGuid(),
            Name: `Stepper Data Received`,
            Receptor: Receptor.ValueInput,
            Particles: [this.particlesFactoryService.GenerateEvent(LeapXLEventType.DataReceived, 'Molecule',
              es.Id)],
          };
          avoidSourceReplacement.push(es.Id.toString());
          const existPropertiesBuses = !!properties.find((p) => p.name === 'buses');
          
          let propertiesBuses = {
            name: 'buses',
            path: '',
            value: [],
          };
          
          if (existPropertiesBuses) {
            propertiesBuses = properties.find((p) => p.name === 'buses');
            properties = properties.filter((p) => p.name !== 'buses');
          }
          
          propertiesBuses.value.push(defaultBus);
          
          properties = properties.filter((p) => p.name !== 'buses');
          properties.push(propertiesBuses);
        }
        //
        
        this.builderService
        .GetMolecule(
          es,
          droppedMolecule,
          droppedMolecule.moleculeType === MoleculesType.Representative || droppedMolecule.moleculeType === MoleculesType.Cobblet
            ? elementsToDropOn.length > 1
              ? {
                x: es.ResponsiveProperties().x,
                y: es.ResponsiveProperties().y,
              }
              : position
            : null,
          Object.assign([], properties),
          [],
          subparentId,
          true,
          false,
        )
        .then((molecule: RepresentativeMolecule) => {
          // console.log('molecule', molecule);
          elementsProcessed++;
          const changestoSave = [];
          
          if (molecule && molecule.MoleculeType && molecule.MoleculeType === MoleculesType.Representative) {
            es.AddChild(molecule.Id);
            
            setTimeout(() => {
              const das = [];
              if (molecule.Buses.length > 0) {
                molecule.Buses.forEach((bus) => {
                  bus.Particles.forEach((p) => {
                    if (p.IsActionMolecule()) {
                      if ((p as ActionMolecule).DataElements.length > 0) {
                        das.push((p as ActionMolecule).DataElements);
                      }
                      (p as ActionMolecule).MoleculeId = molecule.Id;
                    } else if (p.IsEvent() && !avoidSourceReplacement.includes(
                      (p as LeapXLEvent).SourceId.toString())) {
                      (p as LeapXLEvent).SourceId =
                        ((p as LeapXLEvent).SourceId > 0 && (p as LeapXLEvent).SourceId !== this.replacedMoleculeId) ||
                        (p as LeapXLEvent).EventSource === 'Custom'
                          ? (p as LeapXLEvent).SourceId
                          : molecule.Id;
                    } else if (p.IsDataElement()) {
                      das.push(p);
                    }
                  });
                });
                
                if (!avoidSourceReplacement.includes(es.Id.toString())) {
                  molecule.ReplaceOwnEventIds(es.Id, molecule.Id);
                  changestoSave.push(
                    new PropertyVersioningDto({
                      elementId: molecule.Id.toString(),
                      property: 'buses',
                      value: molecule.Buses,
                      path: ``,
                      change: 'Updating events source',
                      name: '',
                    }),
                  );
                  
                  this.propertiesService.SaveProperties(changestoSave)
                  .subscribe();
                }
                
                this.snackerService.ShowMessageOnBottom('Representative molecule created', 'add_circle', null,
                  true);
              }
              
              this.communicationService.Event.Editor.DataSource.$UpdateDownloableDataSources.emit(true);
              if (das.length > 0) {
                this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanel.emit(true);
                this.communicationService.Event.Editor.EventsTree.$RefreshEventsTree.emit();
              }
              
              if (molecule.SubParentId > 0) {
                this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(
                  this.busService.Get(molecule.ParentId.toString()));
              }
              
              this.workAreaService.repMoleculeJustCreated = true;
              this.workAreaService.ShowElementFocusedMenu(molecule, event, true);
              this.communicationService.Event.Editor.$RecreateProcessBuses.emit();
            }, 100);
          }
          
          if (elementsProcessed === elementsToDropOn.length) {
            this.communicationService.Event.Editor.$RecreateProcessBuses.emit();
          }
        });
      });
    } else if (droppedMolecule.dragType === DragType.Event) {
      elementsToDropOn.forEach((es) => {
        const newBus = es.GenerateNewBus();
        // console.log('bus generated');
        newBus.AddParticle(new LeapXLEvent(droppedMolecule.data));
        
        // console.log('event added');
        es.SaveProperty('buses', 'Event Added')
        .subscribe();
        
        if (elementsToDropOn.length === 1) {
          this.ShowProcessPanel();
        }
      });
      this.communicationService.Event.Editor.$RecreateProcessBuses.emit();
      // placeholders
    } else if (droppedMolecule.dragType === DragType.View) {
      elementsToDropOn.forEach((es) => {
        
        es.AddPlaceholder(droppedMolecule.data.value);
        
        if (droppedMolecule.data.value === Placeholders.Navigation) {
          this.particlesFactoryService.SetupDefaultsForMolecule(
            {
              type: 'NavigateViewMolecule',
            },
            es,
            {
              view: droppedMolecule.data.id,
            },
            false,
          );
        }
        
        es.SaveProperty('placeholders', 'Placeholder Change')
        .subscribe();
      });
      
      this.snackerService.ShowMessageOnBottom('Placeholder Added', 'grid_guides');
      this.communicationService.Event.Editor.$RecreateProcessBuses.emit();
    } else if (droppedMolecule.dragType === DragType.Style) {
      if (droppedMolecule.type === 'copy') {
        if (this.workAreaService.elementsSelected.length > 1) {
          this.workAreaService.elementsSelected.forEach((es) => {
            es.PasteStyle();
          });
        } else {
          this.context.PasteStyle();
        }
      } else {
        if (this.workAreaService.elementsSelected.length > 1) {
          this.workAreaService.elementsSelected.forEach((es) => {
            es.AddStyle(droppedMolecule.style.styleId);
            es.ApplyAssignedStyle();
          });
          
          this.workAreaService.elementsSelected[0].RefreshParent();
        } else {
          this.context.AddStyle(droppedMolecule.style.styleId);
          this.context.ApplyAssignedStyle();
          this.context.RefreshParent();
        }
        
        // this.communicationService.Event.Editor.WorkArea.$RefreshStylesLibrary.emit();
      }
    } else if (droppedMolecule.dragType === DragType.Theme) {
      this.communicationService.Event.Editor.$SetAppTheme.emit({ theme: this.dragService.dragData.theme });
      this.workAreaService.SaveLastUsedElement('style', this.dragService.dragData.dragType,
        this.dragService.dragData.theme.name);
    }
    
    this.dragService.StopDragging();
  }
  
  DataDropped(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (this.cobbleService.Cobble.running) {
      return false;
    }
    this.context.DragOver = false;
    this.OnDrop(this.dragService.dragData, event, null);
    if (this.dragService.dragData.dragType === DragType.Style) {
      this.workAreaService.SaveLastUsedElement(this.dragService.dragData.dragType,
        this.dragService.dragData.style.section, this.dragService.dragData.style.name);
    } else if (this.dragService.dragData.dragType === DragType.Theme) {
      this.workAreaService.SaveLastUsedElement('style', this.dragService.dragData.dragType,
        this.dragService.dragData.theme.name);
    }
  }
  
  RefreshGridsterConfiguration() {
    if (this.context.RunningMode) {
      this.context.GridsterConfig = null;
    } else {
      if (
        this.context.Type === RepresentativeMoleculesType.Table ||
        this.context.Type === RepresentativeMoleculesType.WorkGroup ||
        this.context.Type === RepresentativeMoleculesType.Stepper
      ) {
        setTimeout(() => {
          this.context.GridsterConfig.enableEmptyCellDrop = true;
          this.context.GridsterConfig.emptyCellDropCallback = (event: any, position: GridsterItem) => {
            // console.log(this.context);
            
            this.context.DragOver = false;
            this.OnDrop(this.dragService.dragData, event, position);
          };
          this.context.GridsterConfig.itemInitCallback = (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
            // console.log(item, itemComponent);
          };
          this.context.GridsterConfig.displayGrid = DisplayGrid.None;
          if (this.context.GridsterConfig.api) {
            this.context.GridsterConfig.api.optionsChanged();
          }
        }, 100);
      }
    }
  }
  
  drag(event: any, condition) {
    // if (!condition) {
    //   const dragOverElements = document.getElementsByClassName(
    //     'dragover'
    //   ) as any;
    
    //   // console.log('dragOverElements', dragOverElements);
    
    //   if (dragOverElements && dragOverElements.length > 0) {
    //     for (let i = 0; i < dragOverElements.length; i++) {
    //       dragOverElements[0].classList.remove('dragover');
    //     }
    //   }
    // }
    
    event.preventDefault();
    event.stopPropagation();
    
    if (!this.cobbleService.Cobble.running) {
      this.context.DragOver = condition;
    }
  }
  
  SetDataSourceMolecules(
    dataElements: DataElement[],
    datasourceData: any,
    dataSourceType: string,
    repMolecule: IRepresentativeMolecule,
    replaceRepMolecule = false,
    replacingWithType = '',
  ): Observable<Bus[]> {
    repMolecule.MarkAsTouched();
    const subject = new Subject<Bus[]>();
    const translationsToGet = [];
    // console.log('dataElements', dataElements);
    
    if (dataSourceType === DatasourceType.Spreadsheet) {
      translationsToGet.push({
        dataSourceId: datasourceData.dataSourceId,
        applicationId: this.cobbleService.Cobble.id,
        dataSourceType: dataSourceType,
        specialMode: this.spreadSheetService.editorDbMode,
        context: `${ dataSourceType }${ Constants.ContextSeparator }${ datasourceData.dataSourceName }${ Constants.ContextSeparator }${ datasourceData.collection }${ Constants.ContextSeparator }${ dataElements[0].Reference }`,
        reference: dataElements[0].Reference,
      });
      
      if (dataElements.length > 1 && dataElements[0].Reference !== dataElements[1].Reference) {
        translationsToGet.push({
          dataSourceId: datasourceData.dataSourceId,
          applicationId: this.cobbleService.Cobble.id,
          dataSourceType: DatasourceType.Spreadsheet,
          specialMode: this.spreadSheetService.editorDbMode,
          context: `${ DatasourceType.Spreadsheet }${ Constants.ContextSeparator }${ datasourceData.dataSourceName }${ Constants.ContextSeparator }${ datasourceData.collection }${ Constants.ContextSeparator }${ dataElements[1].Reference }`,
          reference: dataElements[1].Reference,
        });
      }
    } else {
      dataElements.forEach((dataElement) => {
        translationsToGet.push({
          dataSourceId: null,
          applicationId: this.cobbleService.Cobble.id,
          dataSourceType: dataElement.DataSourceType,
          specialMode: this.spreadSheetService.editorDbMode,
          context: `${ dataElement.Context }`,
          reference: dataElement.InternalName,
        });
      });
    }
    
    setTimeout(() => {
      this.dataTranslatorService.CreateTranslation(translationsToGet)
      .subscribe((translations) => {
        const buses = [];
        
        this.templateService
        .GetActionMoleculeProperties(['AddToDatasourceMolecule', 'FilterByDataElementReferenceMolecule',
          'GetElementsDatasourceDataMolecule'])
        .subscribe((moleculeProperties) => {
          const dataElementsCreated = [];
          
          const parentContext = this.busService.Get(repMolecule.ParentId.toString()).Contexts;
          
          if (dataElements[0].DataSourceType === DatasourceType.Spreadsheet) {
            const firstDataElement = new DataElement({
              translationId: translations[0].id,
              id: this.toolsService.GenerateGuid(),
              particleId: this.toolsService.GenerateGuid(),
              particleType: ParticleType.DataElement,
              context: translations[0].context,
              dataSourceType: dataSourceType,
              dataSourceId: datasourceData.dataSourceId,
              dataSourceName: datasourceData.dataSourceName,
              collection: datasourceData.collection,
              internalName: translations[0].internalName,
              reference: translations[0].internalName,
              applicationId: this.cobbleService.Cobble.id,
            });
            
            let secondDataElement = null;
            
            if (translations.length > 1) {
              secondDataElement = new DataElement({
                translationId: translations[1].id,
                id: this.toolsService.GenerateGuid(),
                particleId: this.toolsService.GenerateGuid(),
                particleType: ParticleType.DataElement,
                context: translations[1].context,
                dataSourceType: DatasourceType.Spreadsheet,
                dataSourceId: datasourceData.dataSourceId,
                dataSourceName: datasourceData.dataSourceName,
                collection: datasourceData.collection,
                internalName: translations[1].internalName,
                reference: translations[1].internalName,
                applicationId: this.cobbleService.Cobble.id,
                rangeParticleId: this.spreadSheetService.editorDbMode ? null : firstDataElement.ParticleId,
              });
              firstDataElement.RangeParticleId = this.spreadSheetService.editorDbMode ? null : secondDataElement.ParticleId;
            }
            dataElementsCreated.push(firstDataElement);
            if (secondDataElement) {
              dataElementsCreated.push(secondDataElement);
            }
          } else {
            dataElements.forEach((dataElement, index) => {
              const translation = translations.find(
                (t) => t.context.toLowerCase() === dataElement.Context.toLowerCase());
              
              dataElement.TranslationId = translation.id;
              dataElement.InternalName = translation.internalName;
              dataElement.Reference = translation.internalName;
              dataElementsCreated.push(dataElement);
            });
          }
          
          const getElementsMoleculeProperties = moleculeProperties.find(
            (p) => p.type === 'GetElementsDatasourceDataMolecule');
          
          if (
            replaceRepMolecule &&
            repMolecule.Type !== RepresentativeMoleculesType.Dropdown &&
            replacingWithType === RepresentativeMoleculesType.Dropdown
          ) {
            const newBus = repMolecule.GenerateNewBus(false, null, Receptor.OptionsListInput);
            newBus.AddParticle(
              this.particlesFactoryService.GenerateEvent(LeapXLEventType.Init, 'Molecule', repMolecule.Id));
            newBus.AddParticle(
              this.particlesFactoryService.GenerateActionMolecule(getElementsMoleculeProperties,
                repMolecule.Id, dataElementsCreated),
            );
          } else {
            repMolecule.Buses.forEach((bus) => {
              bus.Particles.forEach((p) => {
                if (p.IsActionMolecule() && (p as ActionMolecule).DataElements.length > 0) {
                  (p as ActionMolecule).DataElements = dataElementsCreated;
                  (p as ActionMolecule).Touched = true;
                }
              });
            });
          }
          
          const addToDatasourceBus = repMolecule.GetBusByReceptorAndMoleculeNameType([Receptor.ValueOutput],
            'AddToDatasourceMolecule')[0];
          const getDatasourceBus = repMolecule.GetBusByReceptorAndParticleEventType(
            repMolecule.MoleculeSubType === RepresentativeMoleculesType.Dropdown ||
            (replaceRepMolecule &&
              repMolecule.Type !== RepresentativeMoleculesType.Dropdown &&
              replacingWithType === RepresentativeMoleculesType.Dropdown)
              ? Receptor.OptionsListInput
              : Receptor.ValueInput,
            'init',
          );
          const filterDatasourceBus = repMolecule.GetBusByReceptorAndParticleEventType(Receptor.ValueInput,
            'App Broadcast');
          
          if (parentContext.length > 0) {
            if (parentContext.includes('form_update')) {
              if (getDatasourceBus) {
                const getDatasourceMolecule = getDatasourceBus.GetActionMoleculeParticle(
                  'GetElementsDatasourceDataMolecule');
                
                if (getDatasourceMolecule) {
                  (getDatasourceMolecule as ActionMolecule).DataElements = dataElementsCreated;
                  (getDatasourceMolecule as ActionMolecule).Touched = true;
                } else {
                  getDatasourceBus.AddParticle(
                    this.particlesFactoryService.GenerateActionMolecule(moleculeProperties[1], repMolecule.Id,
                      dataElementsCreated),
                  );
                }
              } else {
                const busToAdd = repMolecule.GenerateNewBus(
                  false,
                  null,
                  repMolecule.MoleculeSubType === RepresentativeMoleculesType.Dropdown ? Receptor.OptionsListInput : Receptor.ValueInput,
                );
                
                busToAdd.AddParticle(repMolecule.GenerateEvent(LeapXLEventType.Init));
                busToAdd.AddParticle(
                  this.particlesFactoryService.GenerateActionMolecule(moleculeProperties[1], repMolecule.Id,
                    dataElementsCreated),
                );
              }
              
              if (filterDatasourceBus) {
                if (
                  replaceRepMolecule &&
                  repMolecule.Type !== RepresentativeMoleculesType.Dropdown &&
                  replacingWithType === RepresentativeMoleculesType.Dropdown
                ) {
                } else {
                  const filterDatasourceMolecule = filterDatasourceBus.GetActionMoleculeParticle(
                    'FilterByDataElementReferenceMolecule');
                  
                  if (filterDatasourceMolecule) {
                    (filterDatasourceMolecule as ActionMolecule).DataElements = dataElementsCreated;
                    (filterDatasourceMolecule as ActionMolecule).Touched = true;
                  } else {
                    filterDatasourceBus.AddParticle(
                      this.particlesFactoryService.GenerateActionMolecule(moleculeProperties[0],
                        repMolecule.Id, dataElementsCreated),
                    );
                  }
                }
              } else {
                const busToAdd = repMolecule.GenerateNewBus(false, null, Receptor.ValueInput);
                busToAdd.AddParticle(
                  this.particlesFactoryService.GenerateEvent(LeapXLEventType.AppBroadcast));
                busToAdd.AddParticle(
                  this.particlesFactoryService.GenerateActionMolecule(moleculeProperties[0], repMolecule.Id,
                    dataElementsCreated),
                );
              }
              
              const populatingMolecules = [RepresentativeMoleculesType.Dropdown];
              
              const repMoleculePopulating = this.busService
              .ChildrenElements(this.context.Id)
              .find(
                (repMol) =>
                  populatingMolecules.includes(repMol.Type as RepresentativeMoleculesType) &&
                  !!repMol.GetBusByReceptorAndParticleEventType(Receptor.ValueOutput,
                    LeapXLEventType.AppBroadcast),
              );
              
              if (repMoleculePopulating) {
                const getDatasourceDataMolecule = repMoleculePopulating
                .GetBusByReceptorAndParticleEventType(Receptor.ValueOutput, LeapXLEventType.AppBroadcast)
                .GetActionMoleculeParticle('GetElementsDatasourceDataMolecule');
                
                if (getDatasourceDataMolecule) {
                  getDatasourceDataMolecule.Touched = true;
                  getDatasourceDataMolecule.AddDataElements(dataElementsCreated);
                  this.context.SaveProperty('buses', 'Data Element added')
                  .subscribe();
                }
              }
            } else {
              if (addToDatasourceBus) {
                if (
                  replaceRepMolecule &&
                  repMolecule.Type !== RepresentativeMoleculesType.Dropdown &&
                  replacingWithType === RepresentativeMoleculesType.Dropdown
                ) {
                } else {
                  const addToDatasourceMolecule = addToDatasourceBus.GetActionMoleculeParticle(
                    'AddToDatasourceMolecule');
                  
                  if (addToDatasourceMolecule) {
                    (addToDatasourceMolecule as ActionMolecule).DataElements = dataElementsCreated;
                    (addToDatasourceMolecule as ActionMolecule).Touched = true;
                  } else {
                    addToDatasourceBus.AddParticle(
                      this.particlesFactoryService.GenerateActionMolecule(moleculeProperties[2],
                        repMolecule.Id, dataElementsCreated),
                    );
                  }
                }
              } else {
                const busToAdd = repMolecule.GenerateNewBus(false, null, Receptor.ValueOutput);
                busToAdd.AddParticle(
                  this.particlesFactoryService.GenerateActionMolecule(moleculeProperties[2], repMolecule.Id,
                    dataElementsCreated),
                );
                buses.push(busToAdd);
              }
            }
          } else {
            if (addToDatasourceBus) {
              const addToDatasourceMolecule = addToDatasourceBus.GetActionMoleculeParticle(
                'AddToDatasourceMolecule');
              
              if (addToDatasourceMolecule) {
                (addToDatasourceMolecule as ActionMolecule).DataElements = dataElementsCreated;
                (addToDatasourceMolecule as ActionMolecule).Touched = true;
              } else {
                addToDatasourceBus.AddParticle(
                  this.particlesFactoryService.GenerateActionMolecule(moleculeProperties[2], repMolecule.Id,
                    dataElementsCreated),
                );
              }
            } else {
              if (repMolecule.Type !== RepresentativeMoleculesType.Chart) {
                const busToAdd = repMolecule.GenerateNewBus(false, null, Receptor.ValueOutput);
                busToAdd.AddParticle(
                  this.particlesFactoryService.GenerateActionMolecule(moleculeProperties[2], repMolecule.Id,
                    dataElementsCreated),
                );
              }
            }
            
            if (getDatasourceBus) {
              const getDatasourceMolecule = getDatasourceBus.GetActionMoleculeParticle(
                'GetElementsDatasourceDataMolecule');
              
              if (getDatasourceMolecule) {
                (getDatasourceMolecule as ActionMolecule).DataElements = dataElementsCreated;
                (getDatasourceMolecule as ActionMolecule).Touched = true;
              } else {
                getDatasourceBus.AddParticle(
                  this.particlesFactoryService.GenerateActionMolecule(moleculeProperties[1], repMolecule.Id,
                    dataElementsCreated),
                );
              }
            } else {
              const busToAdd = repMolecule.GenerateNewBus(
                false,
                null,
                repMolecule.MoleculeSubType === RepresentativeMoleculesType.Dropdown ? Receptor.OptionsListInput : Receptor.ValueInput,
              );
              
              busToAdd.AddParticle(repMolecule.GenerateEvent(LeapXLEventType.Init));
              busToAdd.AddParticle(
                this.particlesFactoryService.GenerateActionMolecule(moleculeProperties[1], repMolecule.Id,
                  dataElementsCreated));
            }
            
            if (filterDatasourceBus) {
              const filterDatasourceMolecule = filterDatasourceBus.GetActionMoleculeParticle(
                'FilterByDataElementReferenceMolecule');
              
              if (filterDatasourceMolecule) {
                (filterDatasourceMolecule as ActionMolecule).DataElements = dataElementsCreated;
                (filterDatasourceMolecule as ActionMolecule).Touched = true;
              } else {
                filterDatasourceBus.AddParticle(
                  this.particlesFactoryService.GenerateActionMolecule(moleculeProperties[0], repMolecule.Id,
                    dataElementsCreated),
                );
              }
            } else {
              if (repMolecule.Type !== RepresentativeMoleculesType.Chart) {
                const busToAdd = repMolecule.GenerateNewBus(false, null, Receptor.ValueInput);
                busToAdd.AddParticle(
                  this.particlesFactoryService.GenerateEvent(LeapXLEventType.AppBroadcast));
                busToAdd.AddParticle(
                  this.particlesFactoryService.GenerateActionMolecule(moleculeProperties[0], repMolecule.Id,
                    dataElementsCreated),
                );
              }
            }
          }
          
          // console.log('firing event');
          
          subject.next(buses);
        });
      });
    }, 50);
    return subject.asObservable();
  }
  
  UpdateData() {
  }
  
  UpdateContextMenuPosition(elementId: number = this.context.Id) {
    const contextMenu = document.querySelector(`#contextMenu-${ elementId } .context-actions`);
    const gridsterItem = document.querySelector(`#gridsterItem-${ elementId }`);
    // console.log('updated position');
    
    if (contextMenu && gridsterItem) {
      contextMenu.classList.remove('non-visible');
      (contextMenu as any).style.transform = (gridsterItem as any).style.transform;
    }
  }
  
  Throttle(func, delay, context, args) {
    clearTimeout(this.inDebounce);
    this.inDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }
  
  RefreshText() {
    this.refreshingText = true;
    setTimeout(() => {
      this.refreshingText = false;
      setTimeout(() => {
        if (this.cobbleService.Cobble.running) {
          this.toolsService.TextFit();
        }
        this.communicationService.Event.System.App.$RefreshUI.emit();
      }, 50);
    }, 50);
  }
  
  ExistsProccessPanelWindowOpen() {
    return document.querySelectorAll('app-process-properties').length > 0;
  }
  
  ShowProcessPanel(event?: MouseEvent) {
    this.workAreaService.SelectRepresentativeMolecule(this.context);
    if (!this.ExistsProccessPanelWindowOpen()) {
      this.draggableWindowService.OpenDraggableWindow(
        `Process`,
        DraggableWindowType.ProcessPanel,
        event,
      );
    }
  }
  
  RefreshUI() {
    // console.log('refresh rep mol ui');
    this.changeDetectorRef.detectChanges();
    this.changeDetectorRef.markForCheck();
  }
  
  RepMoleculeResized() {
    // console.log('rep mol resized');
  }
  
  RemoveOldChildRepMolecules() {
  }
  
  ngOnDestroy(): void {
    setTimeout(() => {
      this.RemoveOldChildRepMolecules();
    }, 500);
    this.destroy$.next();
    this.destroy$.complete();
    this.context.Renderized = false;
    this.DisableHover();
    
    if (this.bottomSheetSubscription !== undefined) {
      this.bottomSheetSubscription.unsubscribe();
    }
    
    if (this.Subscriptions) {
      // console.log('unsubscribe');
      this.Subscriptions.unsubscribe();
      this.Subscriptions = undefined;
    }
    this.context.SearchFilter = '';
  }
  
  EnableHover() {
    this.disableHover = false;
    this.el.nativeElement.addEventListener('mouseenter', this.onMouseEnter.bind(this));
    this.el.nativeElement.addEventListener('mouseleave', this.onMouseLeave.bind(this));
  }
  
  DisableHover() {
    this.disableHover = true;
    this.el.nativeElement.removeEventListener('mouseenter', this.onMouseEnter.bind(this));
    this.el.nativeElement.removeEventListener('mouseleave', this.onMouseLeave.bind(this));
  }
  
  GetMaskOptions() {
    let options = {
      mask: this.context.Properties.mask,
      lazy: false,
    } as any;
    
    switch (this.context.Properties.maskType) {
      case 'currency':
        options = {
          lazy: false,
          mask: '$num',
          blocks: {
            num: {
              mask: Number,
              thousandsSeparator: ',',
              radix: '.',
            },
          },
        };
        break;
      case 'amount':
        options = {
          lazy: false,
          mask: 'num',
          blocks: {
            num: {
              mask: Number,
              thousandsSeparator: ',',
              radix: '.',
            },
          },
        };
        break;
      case 'percentage':
        options = {
          lazy: false,
          mask: 'num%',
          blocks: {
            num: {
              mask: Number,
              thousandsSeparator: ',',
              radix: '.',
            },
          },
        };
        break;
      case 'date':
        options = {
          lazy: false,
          mask: 'month/day/year',
          blocks: {
            month: {
              mask: IMask.MaskedRange,
              from: 1,
              to: 12,
            },
            day: {
              mask: IMask.MaskedRange,
              from: 1,
              to: 31,
            },
            year: {
              mask: IMask.MaskedRange,
              from: 1900,
              to: 2999,
            },
          },
        };
        break;
      case 'time24':
        options = {
          lazy: false,
          mask: 'hour:min',
          blocks: {
            hour: {
              mask: IMask.MaskedRange,
              from: 0,
              to: 24,
            },
            min: {
              mask: IMask.MaskedRange,
              from: 0,
              to: 59,
            },
          },
        };
        break;
    }
    
    return options;
  }
  
  HasMasking() {
    return (
      this.context.Properties.maskType &&
      this.context.Properties.mask &&
      this.context.Properties.maskType !== 'none' &&
      this.context.Properties.mask !== ''
    );
  }
  
  private SetDataAssociationDefinitions() {
    if (this.cobbleService.Cobble.moleculeDataAssociationDefinitions) {
      const definitions = this.cobbleService.Cobble.moleculeDataAssociationDefinitions.filter(
        (d) => d.molecule === this.context.Type);
      
      definitions.forEach((d) => {
        this.DataAssociationDefinitions[d.propertyKey] = d.dataIndex;
      });
    }
  }
}
