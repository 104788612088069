import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class DeleteDatasourceDataMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(particleId: string, repMoleculeId: string, busProcessorKey: string, rule: any, logic: any, data: any) {
    try {
      const dataItems = [];

      if (this.ExistsRepMoleculeAttached) {
        data = this.RepMoleculeAttached.GetValue;
      }
      data = data === undefined || data === null || data === {} ? '' : data;

      if ((Array.isArray(data) && data.length === 0) || data === undefined || data === null) {
        data = '';
      }

      switch (this.toolsService.GetObjectType(data)) {
        case 'object':
          // console.log('object');
          this.DataElements.forEach(de => {
            const dataElement = de;
            dataItems.push(this.CreateDataItem(dataElement, repMoleculeId, data));
          });
          break;
        case 'array':
          // console.log('array');
          data.forEach(d => {
            this.DataElements.forEach(de => {
              const dataElement = de;
              dataItems.push(this.CreateDataItem(dataElement, repMoleculeId, d));
            });
          });
          break;
        case 'number':
        case 'string':
          // console.log('array');
          this.DataElements.forEach(de => {
            const dataElement = de;
            dataItems.push(this.CreateDataItem(dataElement, repMoleculeId, data));
          });
          break;
        default:
          break;
      }

      const subscription = this.communicationService.Event.Runtime.Data.$ModifyDataResult.subscribe(response => {
        console.log('$ModifyDataResult');
        console.log('=event=');
        if (response.keys && response.keys.includes(repMoleculeId)) {
          subscription.unsubscribe();
          this.Done(particleId, busProcessorKey, repMoleculeId, response.dataElements);
        }
      });

      console.log('delete', this.EventKey);

      this.dataSourcesService.DeleteDataItemsForDataSource(dataItems, repMoleculeId, this.busService.Get(repMoleculeId), this.EventKey);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }

  public AfterRemove(elementContext: any, data?: any) {}

  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {}
}
