import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Permissions } from '../../admin/models/permissions.enum';
import { ParticleType } from '../../shared/enums/particle-type.enum';
import { LeapProcessMetadata } from '../../shared/interfaces/leap-process-metadata.interface';
import { LeapXLEvent } from '../../shared/representative-molecule/interfaces/leapxl-event';
import { Particle } from '../../shared/representative-molecule/interfaces/particle';
import { IRepresentativeMolecule } from '../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { DraggableWindowManagerService } from '../../shared/services/draggable-window-manager.service';
import { DraggableWindowService, DraggableWindowType } from '../../shared/services/draggable-window.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { WorkAreaService } from '../../workarea/workarea.service';
import { BusService } from '../molecular/services/bus.service';
import { EditorStateService } from './editor-state.service';
import { LocalStorageService } from './local-storage.service';
import { ToolsService } from './tools.service';
import { UserMenuService } from './user-menu.service';

@Injectable({
  providedIn: 'root',
})
export class DevToolsService implements OnDestroy {
  subscriptions = new Subscription();
  public;
  isPaused = false;
  Enabled = false;
  particleId = '';
  busEventInitializer: LeapXLEvent = null;
  pauseOnNext = false;
  onPath = false;
  busesDebugAccessPermission = false;
  private leapDevToolsDataKey = 'leap-dev-tools';
  private debugAppData: {
    breakpoints: {
      [string: string]: {
        start: boolean;
        end: boolean;
        busId: string;
      };
    };
    appId: number;
  };
  private isDebug = false;
  private processPaused: LeapProcessMetadata[] = [];
  private history: {
    [particleId: string]: {
      data: any;
    };
  } = {};
  private activeBusId = '';
  private activeRepMoleculeId = '';
  private processKeyPath = '';
  private stepOver = false;
  private busReferenceTranslator: {
    [cloneId: string]: string;
  } = {};
  
  constructor(
    private communicationService: CommunicationService,
    private localStorageService: LocalStorageService,
    private cobbleService: CobbleService,
    private busService: BusService,
    private toolsService: ToolsService,
    private editorStateService: EditorStateService,
    private draggableWindowService: DraggableWindowService,
    private workAreaService: WorkAreaService,
    private dragableWindowManagerService: DraggableWindowManagerService,
    private snackerService: SnackerService,
    private userMenuService: UserMenuService,
  ) {
    this.isDebug = this.localStorageService.IsDebug();
    this.busesDebugAccessPermission = this.userMenuService.checkPermission(Permissions.BusesDebugAccess);
    
    
    if ((this.toolsService.IsPreview() && (this.toolsService.IsDevEnvironment() || this.toolsService.IsLocalEnvironment())) || this.busesDebugAccessPermission || this.isDebug) {
      this.Enabled = true;
    }
    
    this.subscriptions.add(
      this.communicationService.Event.System.App.$AppLoaded.subscribe(() => {
        console.log('=event=');
        this.LoadDebugData();
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.System.DevTools.$OpenDevContextMenu.subscribe(() => {
        console.log('=event=');
        this.LoadDebugData();
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.System.DevTools.$ProcessPaused.subscribe(processMetadata => {
        console.log('=event=');
        this.OpenActiveBus();
      }),
    );
  }
  
  LoadDebugData() {
    if (!this.Enabled) {
      return;
    }
    
    const appData = this.GetAppDebugStoreData();
    this.debugAppData = appData;
  }
  
  AddHistory(particleId: string, data: any) {
    this.history[particleId] = {
      data,
    };
  }
  
  AddParticleBreakpoint(particleId: string, busId: string, position = 'start') {
    const debugData = this.GetDebugStoreData();
    const appData = debugData.find(d => d.appId === this.cobbleService.Cobble.id);
    
    if (appData) {
      const particleBreakpoint = {
        start: false,
        end: false,
        busId: busId,
      };
      particleBreakpoint[position] = true;
      appData.breakpoints[particleId] = particleBreakpoint;
    } else {
      const newBreakpoint = {
        appId: this.cobbleService.Cobble.id,
        breakpoints: {},
      };
      
      const particleBreakpoint = {
        start: false,
        end: false,
        busId: busId,
      };
      particleBreakpoint[position] = true;
      newBreakpoint.breakpoints[particleId] = particleBreakpoint;
      debugData.push(newBreakpoint);
    }
    
    this.localStorageService.Set(this.leapDevToolsDataKey, debugData);
    this.LoadDebugData();
  }
  
  RemoveParticleBreakpoint(particleId: string, busId, position = 'start') {
    const debugData = this.GetDebugStoreData();
    const appData = debugData.find(d => d.appId === this.cobbleService.Cobble.id);
    
    if (appData) {
      delete appData.breakpoints[particleId];
      this.localStorageService.Set(this.leapDevToolsDataKey, debugData);
      this.LoadDebugData();
    }
  }
  
  ToggleParticleBreakpoint(particleId: string, busId: string, position = 'start') {
    if (this.HasBreakpointForBus(particleId, busId, position)) {
      this.RemoveParticleBreakpoint(particleId, busId, position);
    } else {
      this.AddParticleBreakpoint(particleId, busId, position);
    }
  }
  
  GetDebugStoreData(): any[] {
    const debugData = this.localStorageService.Get(this.leapDevToolsDataKey);
    return JSON.parse(debugData) || [];
  }
  
  PauseProcess(processMetadata: LeapProcessMetadata) {
    console.log('try pause');
    if (this.toolsService.IsEditor()) {
      return;
    }
    console.warn('LeapXL Dev Tools: process paused', processMetadata);
    
    if (this.busEventInitializer === null && processMetadata.init) {
      this.busEventInitializer = processMetadata.particle as LeapXLEvent;
    }
    
    this.SetBusAndRepMolecule(processMetadata.elementId.toString(), processMetadata.particle.TriggeredByBusId || processMetadata.busId);
    this.onPath = true;
    this.processKeyPath = processMetadata.key;
    this.processPaused.push(processMetadata);
    this.isPaused = true;
    this.stepOver = false;
    this.particleId = processMetadata.particle.GlobalIdentifier();
    this.AddHistory(processMetadata.particle.GlobalIdentifier(), processMetadata.data);
    this.communicationService.Event.System.DevTools.$ProcessPaused.emit(processMetadata);
    this.snackerService.ShowMessageOnBottom('Process Paused', 'pause');
  }
  
  ContinuePausedProcess() {
    this.processPaused.forEach(pausedProcess => {
      this.particleId = '';
      this.isPaused = false;
      this.pauseOnNext = false;
      
      if (pausedProcess) {
        switch ((pausedProcess.particle as Particle).ParticleType) {
          case ParticleType.Event:
            if (pausedProcess.init) {
              this.communicationService.Event.System.DevTools.$ResumeBreakpointInitEvent.emit(pausedProcess);
            } else {
              this.communicationService.Event.System.DevTools.$ResumeBreakpointEvent.emit(pausedProcess);
            }
            
            break;
          case ParticleType.Molecule:
            console.warn('LeapXL Dev Tools: process continue', pausedProcess);
            // end
            this.communicationService.Event.Runtime.MolecularEngine.$ParticleProcessCompleted.emit(pausedProcess);
            
            // start
            // this.communicationService.Event.System.DevTools.$ResumeBreakpointMolecule.emit(pausedProcess);
            break;
        }
      }
    });
    
    if (this.processPaused.length > 0) {
      this.snackerService.ShowMessageOnBottom('Process Resumed', 'resume');
    }
    
    this.processPaused = [];
  }
  
  PauseOnNext() {
    this.pauseOnNext = true;
  }
  
  StepOver() {
    console.log('step over');
    this.isPaused = false;
    this.stepOver = true;
    this.ContinuePausedProcess();
    this.pauseOnNext = false;
  }
  
  StopDebugger() {
    console.log('stop');
    this.ContinuePausedProcess();
    this.ClearDebugger();
  }
  
  ClearDebugger(busId?: string) {
    
    if (busId && this.processPaused.length > 0 && !this.IsBusPaused(busId)) {
      return;
    }
    
    this.processPaused = [];
    this.history = {};
    this.isPaused = false;
    this.onPath = false;
    this.processKeyPath = '';
    this.stepOver = false;
    this.pauseOnNext = false;
    this.particleId = '';
  }
  
  IsBusPaused(busId: string) {
    return this.processPaused.findIndex(pp => pp.busId === busId) >= 0;
  }
  
  SetBusAndRepMolecule(repMoleculeId: string, busId: string) {
    this.activeBusId = busId;
    this.activeRepMoleculeId = repMoleculeId;
  }
  
  RemoveAllBreakpoints() {
    console.log('stop debugger');
    this.StopDebugger();
    if (Object.keys(this.debugAppData.breakpoints).length === 0) {
    } else {
      const debugData = this.GetDebugStoreData();
      const appData = debugData.find(d => d.appId === this.cobbleService.Cobble.id);
      appData.breakpoints = {};
      
      this.localStorageService.Set(this.leapDevToolsDataKey, debugData);
      this.LoadDebugData();
    }
  }
  
  GetPausedProcessMetadata() {
    return this.processPaused[0];
  }
  
  GetAppDebugStoreData() {
    const debugData = this.GetDebugStoreData();
    const appData = debugData.find(d => d.appId === this.cobbleService.Cobble.id);
    
    return (
      appData || {
        appId: this.cobbleService.Cobble.id,
        breakpoints: {},
      }
    );
  }
  
  HasAnyBreakpoint(particleId: string, position = 'start') {
    return this.Enabled && this.debugAppData && !!this.debugAppData.breakpoints[particleId];
  }
  
  GetBreakpointBusForMolecule(repMoleculeId: string) {
    const repMolecule = this.busService.Get(repMoleculeId);
    const breakpointBuses = repMolecule.Buses.filter(b => this.BusHasRuntimeBreakpoint(b.id));
    
    if (breakpointBuses.length > 0) {
      return breakpointBuses[0];
    } else {
      return null;
    }
  }
  
  BusHasRuntimeBreakpoint(busId: string) {
    if (!this.Enabled) {
      return false;
    }
    
    if (!(this.debugAppData && Object.keys(this.debugAppData.breakpoints).length > 0)) {
      return false;
    }
    
    let breakpointExists = false;
    
    Object.keys(this.debugAppData.breakpoints).forEach(particleId => {
      const breakpoint = this.debugAppData.breakpoints[particleId];
      if (breakpoint.busId === this.GetBusTranslation(busId) || breakpoint.busId === busId) {
        breakpointExists = true;
      }
    });
    
    return breakpointExists;
  }
  
  HasBreakpointForBus(particleId: string, busId: string, position = 'start') {
    return this.debugAppData && !!this.debugAppData.breakpoints[particleId] && this.debugAppData.breakpoints[particleId].busId === busId;
  }
  
  HasRuntimeBreakpointOrStepOver(particleId: string, repMoleculeId: string = '', position = 'start'): boolean {
    return (
      this.Enabled &&
      ((this.debugAppData && !!this.debugAppData.breakpoints[particleId] && !!this.debugAppData.breakpoints[particleId][position]) ||
        (this.stepOver && repMoleculeId === this.activeRepMoleculeId) ||
        this.pauseOnNext)
    );
  }
  
  HasRuntimeBreakpointOrStepOverForBus(particleId: string, busId: string, repMoleculeId: string = '', position = 'start'): boolean {
    return (
      this.Enabled &&
      ((this.stepOver && busId === this.activeBusId) ||
        (this.debugAppData &&
          !!this.debugAppData.breakpoints[particleId] &&
          !!this.debugAppData.breakpoints[particleId][position] &&
          (this.debugAppData.breakpoints[particleId].busId === this.GetBusTranslation(busId) ||
            this.debugAppData.breakpoints[particleId].busId === busId)
        ) ||
        (this.stepOver && repMoleculeId === this.activeRepMoleculeId) ||
        this.pauseOnNext)
    );
  }
  
  HasRuntimeBreakpoint(particleId: string, position = 'start') {
    return this.Enabled && this.debugAppData && !!this.debugAppData.breakpoints[particleId] && !!this.debugAppData.breakpoints[particleId][position];
  }
  
  HasRuntimeBreakpointForBus(particleId: string, busId: string, position = 'start') {
    return (
      this.Enabled &&
      this.debugAppData &&
      !!this.debugAppData.breakpoints[particleId] &&
      !!this.debugAppData.breakpoints[particleId][position] &&
      this.debugAppData.breakpoints[particleId].busId === this.GetBusTranslation(busId)
    );
  }
  
  ParticleHasHistoryData(particleId: string) {
    return !!this.history[particleId];
  }
  
  GetHistoryDataForParticle(particleId: string) {
    if (this.ParticleHasHistoryData(particleId)) {
      return this.history[particleId].data;
    } else {
      return [];
    }
  }
  
  AddBusTranslation(originalBusId: string, cloneBusId: string) {
    this.busReferenceTranslator[cloneBusId] = originalBusId;
  }
  
  GetBusTranslation(clonedBusId: string) {
    return this.busReferenceTranslator[clonedBusId];
  }
  
  CanBusReRun(): boolean {
    return (
      this.Enabled && !this.isPaused && this.activeBusId && this.activeBusId !== '' && this.activeRepMoleculeId && this.activeRepMoleculeId !== ''
    );
  }
  
  ReRunActiveBus() {
    console.log('stop debugger');
    this.StopDebugger();
    
    if (this.activeRepMoleculeId && this.activeBusId) {
      const repMolecule = this.busService.Get(this.activeRepMoleculeId);
      const activeBus = repMolecule.GetBus(this.GetBusTranslation(this.activeBusId));
      const firstParticle = activeBus.FirstParticle();
      
      if (firstParticle.IsEvent()) {
        (firstParticle as LeapXLEvent).Priority = 0;
        (firstParticle as LeapXLEvent).Data = this.GetHistoryDataForParticle(firstParticle.id);
        this.communicationService.Event.Runtime.MolecularEngine.$LeapXLEvent.emit(firstParticle as LeapXLEvent);
      }
    }
  }
  
  OpenDevToolsForBus(repMolecule: IRepresentativeMolecule) {
    if (this.isPaused) {
      this.OpenActiveBus();
    } else {
      if (repMolecule.Buses.length === 0) {
        return;
      }
      
      const bus = this.GetBreakpointBusForMolecule(repMolecule.Id.toString());
      
      if (bus) {
        this.OpenActiveBus(repMolecule.Id.toString(), bus.id);
      } else {
        this.OpenActiveBus(repMolecule.Id.toString(), repMolecule.Buses[0].id);
      }
    }
  }
  
  OpenActiveBus(repMoleculeId = this.activeRepMoleculeId, busId: string = null) {
    if (!this.cobbleService.Cobble.running) {
      return;
    }
    
    busId = busId || this.GetBusTranslation(this.activeBusId) || this.activeBusId;
    
    if (!(repMoleculeId && busId)) {
      return;
    }
    
    if (this.workAreaService.devToolsWindow && document.querySelector('app-bus')) {
      this.communicationService.Event.System.DevTools.$UpdateBus.emit({
        repMoleculeId: repMoleculeId,
        busId: busId,
      });
      return;
    }
    
    const repMolecule = this.busService.Get(repMoleculeId);
    const bus = repMolecule.GetBus(busId);
    
    const toolbar = document.querySelector('app-dev-tools-toolbar');
    
    if (toolbar) {
    
    } else {
      this.draggableWindowService.OpenDraggableWindow(
        'Dev Tools',
        DraggableWindowType.DevTools,
        null,
        {
          bus,
          repMoleculeId: repMolecule.Id,
        });
    }
  }
  
  DisplayHistoryData(particleId: string, particle: Particle = null) {
    if (!this.cobbleService.Cobble.running) {
      return;
    }
    
    const data = this.GetHistoryDataForParticle(particleId);
    // this.communicationService.Event.System.DevTools.$DisplayHistoryData.emit(data);
    
    this.draggableWindowService.OpenDraggableWindow(
      `Dev Tools - ${ particle ? particle.GetName() + ' ' : '' }Data`,
      DraggableWindowType.DevToolsBreakpointData,
      null,
      {
        data: data,
        isDetached: true,
      },
      true,
    );
  }
  
  LogData() {
    console.log('processPaused', this.processPaused);
    console.log('stepOver', this.stepOver);
    console.log('pauseOnNext', this.pauseOnNext);
    console.log('isPaused', this.isPaused);
    console.log('debugAppData', this.debugAppData);
    console.log('history', this.history);
    console.log('busReferenceTranslator', this.busReferenceTranslator);
  }
  
  ngOnDestroy(): void {
    console.log('stop debugger');
    this.StopDebugger();
  }
}
