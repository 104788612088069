import { RepresentativeMoleculesType } from '../../../shared/enums/representative-molecules-types.enum';
import { RepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule';
import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class SetElementsValueMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(particleId: string, repMoleculeId: string, busProcessorKey: string, rule: any, logic: any, data: any) {
    try {
      data = data === undefined || data === null || data === {} ? '' : data;
      
      if (data.elementsValue && data.elements) {
        data.elements.forEach((element) => {
          element.context.methods.updateValue(element, data.elementsValue.find((ev) => ev.elementId === element.id).value);
        });
      } else if (data.relatedData && data.elements) {
        data.elements
        // .filter(
        //   (child: RepresentativeMolecule) =>
        //     child.DataSource && child.DataSource.dataSourceId > 0
        // )
        .forEach((molecule: RepresentativeMolecule) => {
          // console.log('molecule', molecule.Type, molecule);
          
          let colIndex = 0;
          let rowIndex = 0;
          
          // colIndex = molecule.DataSource.firstColIndex + 1;
          // rowIndex = molecule.DataSource.firstRowIndex + 1;
          //
          // if (molecule.ContainMolecule(['SubmittableMolecule'])) {
          //   // if (
          //   //   submitMolecule.rule.collection &&
          //   //   submitMolecule.rule.collection !== '' &&
          //   //   submitMolecule.rule.collection !==
          //   //     molecule.DataSource.collection &&
          //   //   molecule.Type !== RepresentativeMoleculesType.Dropdown
          //   // ) {
          //   // } else {
          //   //   if (
          //   //     submitMolecule.rule.colIndex &&
          //   //     submitMolecule.rule.colIndex !== ''
          //   //   ) {
          //   //     colIndex = Number.isInteger(+submitMolecule.rule.colIndex)
          //   //       ? +submitMolecule.rule.colIndex
          //   //       : this.toolsService.ColumnNameToIndex(
          //   //           submitMolecule.rule.colIndex
          //   //         );
          //   //     // console.log('exist col', colIndex);
          //   //     colIndex = molecule.DataSource.baseZero
          //   //       ? colIndex + 1
          //   //       : colIndex;
          //   //   }
          //   //   if (
          //   //     submitMolecule.rule.rowIndex &&
          //   //     submitMolecule.rule.rowIndex !== ''
          //   //   ) {
          //   //     rowIndex = +submitMolecule.rule.rowIndex;
          //   //     rowIndex = molecule.DataSource.baseZero
          //   //       ? rowIndex + 1
          //   //       : rowIndex;
          //   //     // console.log('exist rowIndex', rowIndex);
          //   //   }
          //   // }
          // }
          
          const type = data.relatedData.length <= 1 ? 'col' : data.relatedData[0].col === data.relatedData[1].col ? 'row' : 'col';
          
          // console.log('colindex', colIndex);
          // console.log('row index', rowIndex);
          
          let value = null;
          const colValue = data.relatedData.find((cell) => cell.col === colIndex);
          
          const rowValue = data.relatedData.find((cell) => cell.row === rowIndex);
          
          if (colValue && rowValue) {
            const cellValue = type === 'col' ? colValue : rowValue;
            value = cellValue;
          } else {
            if (rowValue) {
              value = rowValue;
            } else if (colValue) {
              value = colValue;
            }
          }
          
          // console.log('colvalue', colValue);
          // console.log('rowvalue', rowValue);
          // console.log('value', value);
          
          if (value) {
            switch (molecule.Type) {
              case RepresentativeMoleculesType.Label:
              case RepresentativeMoleculesType.H1:
              case RepresentativeMoleculesType.H2:
              case RepresentativeMoleculesType.H3:
              case RepresentativeMoleculesType.H4:
              case RepresentativeMoleculesType.H5:
              case RepresentativeMoleculesType.Textbox:
              case RepresentativeMoleculesType.Textarea:
              case RepresentativeMoleculesType.Dropdown:
              case RepresentativeMoleculesType.Checkbox:
              case RepresentativeMoleculesType.Datepicker:
                molecule.PopulatedData = {
                  collection: data.relatedDataCollection as string,
                  finalCol: value.col,
                  firstCol: value.col,
                  finalRow: value.row,
                  firstRow: value.row,
                  value: molecule.Value,
                };
                
                // console.log('molecule', molecule);
                
                // if (molecule.ContainMolecule(['PopulatableMolecule'])) {
                //   // console.log('populatable');
                //
                //   molecule.PopulatedData.value = value.value;
                //   molecule.UpdateValue(value.value);
                // }
                //
                // if (molecule.ContainMolecule(['TimestampMolecule'])) {
                //   molecule.PopulatedData.value = new Date().toLocaleString();
                //   molecule.UpdateValue(new Date().toLocaleString());
                // }
                //
                // if (molecule.ContainMolecule(['UsernameMolecule'])) {
                //   molecule.PopulatedData.value = this.clientStorageService.getUserName();
                //   molecule.UpdateValue(
                //     this.clientStorageService.getUserName()
                //   );
                // }
                break;
              
              case RepresentativeMoleculesType.Datepicker:
                molecule.UpdateValue(value.value);
                break;
              
              case RepresentativeMoleculesType.Radio:
                // todo: set radio values
                // const values = element.datasource.references.type === 'row' ?  data.relatedData.filter(cell => cell.col === element.datasource.references.firstCol) : data.relatedData.filter(cell => cell.row === element.datasource.references.firstRow);
                
                break;
              
              case RepresentativeMoleculesType.Checkbox:
                break;
              
              case RepresentativeMoleculesType.Dropdown:
                break;
            }
          }
        });
      }
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, data);
    }
  }
  
  public AfterRemove(elementContext: any, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
}
