import { Injectable } from '@angular/core';
import { MoleculesType } from '../../shared/enums/molecules-type.enum';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { ConnectionStateService } from '../../shared/services/connection-state.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { WorkAreaService } from '../../workarea/workarea.service';
import { BusService } from '../molecular/services/bus.service';
import { ApiDataSourcesService } from './api-data-sources.service';
import { ApiMoleculesService } from './api-molecules.service';
import { ApiPropertiesService } from './api-properties.service';
import { ToolsService } from './tools.service';

@Injectable()
export class MoleculeManagmentService {
  
  inDebounce = null;
  
  constructor(
    private busService: BusService,
    private moleculesService: ApiMoleculesService,
    private cobbleService: CobbleService,
    private propertiesService: ApiPropertiesService,
    private connectionStateService: ConnectionStateService,
    private toolsService: ToolsService,
    private dataSourcesService: ApiDataSourcesService,
    private snackerService: SnackerService,
    private workAreaService: WorkAreaService,
    private communicationService: CommunicationService,
  ) {
  }
  
  AddCompoundMoleculeToRepresentativeMolecule(
    moleculeIndex: string,
    compoundMolecule: {
      molecules: any;
    },
    moleculeName: string,
  ) {
    const molecule = this.busService.Get(moleculeIndex);
    let moleculesStringify = JSON.stringify(compoundMolecule.molecules);
    
    moleculesStringify = moleculesStringify.replace(
      new RegExp(`#ELEMENT_ID#`, 'g'),
      molecule.Id.toString(),
    );
    
    for (let i = 0; i < compoundMolecule.molecules.ids; i++) {
      moleculesStringify = moleculesStringify.replace(
        new RegExp(`#ID${ i }#`, 'g'),
        this.toolsService.GenerateGuid(),
      );
    }
    
    this.propertiesService.SaveOwnProperty(
      moleculeIndex,
      'molecules',
      `Compound Molecule ${ moleculeName } Added`,
    )
    .subscribe();
  }
  
  public RemoveRepresentativeMolecules(moleculeIds: number[]) {
    console.log('remove rep molecules', moleculeIds);
    
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
    
    let childrenIds = [];
    
    moleculeIds.forEach(moleculeId => {
      childrenIds = childrenIds.concat(this.busService.GetAllChildrenIds(moleculeId));
    });
    
    this.moleculesService.DeleteRepresentativeMolecule(childrenIds)
    .subscribe(result => {
      this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
      
      if (moleculeIds.length > 0) {
        const molecule = this.busService.Get(moleculeIds[0].toString());
        if (molecule) {
          const name = molecule.Properties.name;
          const parent = this.busService.Get(molecule.ParentId.toString());
          
          moleculeIds.forEach(moleculeId => {
            this.busService.Remove(moleculeId.toString());
          });
          
          this.Throttle(
            (func, delay, context) => {
              // timeout to avoid lag
              console.log('saving');
              setTimeout(() => {
                if (parent.Type === MoleculesType.Cobble) {
                  this.cobbleService.SaveCobbleChildren();
                } else {
                  // parent.SavePropertyFromVersioning(new PropertyVersioningDto({
                  //   elementId: parent.Id.toString(),
                  //   property: 'children',
                  //   value: this.busService.GetChildrenElementIds(parent.Id),
                  //   path: '',
                  //   change: 'Remove Molecule',
                  //   name: name,
                  // })).subscribe();
                }
                
                this.communicationService.Event.Editor.$MoleculeRemoved.emit(moleculeIds[0]);
                this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanel.emit(true);
              }, 200);
            },
            400,
            this,
            null,
          );
        }
        
      }
    });
    
  }
  
  public RemoveRepresentativeMolecule(moleculeId: number) {
    console.log('remove rep molecule', moleculeId);
    
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
    const childrenIds = this.busService.GetAllChildrenIds(moleculeId);
    this.moleculesService.DeleteRepresentativeMolecule(childrenIds)
    .subscribe(result => {
      this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    });
    
    const molecule = this.busService.Get(moleculeId.toString());
    if (molecule) {
      const name = molecule.Properties.name;
      const parent = this.busService.Get(molecule.ParentId.toString());
      this.busService.Remove(moleculeId.toString());
      
      this.Throttle(
        (func, delay, context) => {
          // timeout to avoid lag
          console.log('saving');
          setTimeout(() => {
            if (parent.Type === MoleculesType.Cobble) {
              this.cobbleService.SaveCobbleChildren();
            } else {
              // parent.SavePropertyFromVersioning(new PropertyVersioningDto({
              //   elementId: parent.Id.toString(),
              //   property: 'children',
              //   value: this.busService.GetChildrenElementIds(parent.Id),
              //   path: '',
              //   change: 'Remove Molecule',
              //   name: name,
              // })).subscribe();
            }
            
            this.communicationService.Event.Editor.$MoleculeRemoved.emit(moleculeId);
            this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanel.emit(true);
          }, 200);
        },
        400,
        this,
        null,
      );
    }
  }
  
  Throttle(func, delay, context, args) {
    clearTimeout(this.inDebounce);
    this.inDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }
}
