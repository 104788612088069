import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SelectIconComponent } from '../select-icon/select-icon.component';

@Component({
  selector: 'app-select-icon-dialog',
  templateUrl: './select-icon-dialog.component.html',
  styleUrls: ['./select-icon-dialog.component.scss'],
})
export class SelectIconDialogComponent implements OnInit {
  
  constructor(
    public dialogRef: MatDialogRef<SelectIconComponent>,
  ) {
  }
  
  ngOnInit(): void {
  }
  
  IconSelected(icon: any) {
    this.dialogRef.close(icon);
  }
}
